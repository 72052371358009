const getIp = async () => {
	return await (() => {
		return new Promise((resolve,reject) => {
			fetch('https://api.ipify.org?format=json')
				.then(res => res.json())
				.then(res => resolve(res&&res?.ip))
				.catch(err => reject(err))
		});
	})();
}

export {getIp};