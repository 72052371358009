import React from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import KpiGoalManager from './../../kpi/KpiGoalManager/KpiGoalManager.component'
import UserGoalFactory from './../../../shared/factories/user-goal.factory'
import UserProfileService from './../../../shared/services/UserProfile.service'

import './AgentKpiGoalsIntake.scss'

class AgentKpiGoalsIntake extends React.Component {
  state = {
    isLoading: true,
    UserGoal: null,
  }

  #fetch = async () => {
    let UserGoal

    try {
      UserGoal = await UserGoalFactory.findByUserId(
        UserProfileService.getUserId()
      )
    } catch (ex) {
      console.log('err: ', ex)
    }

    UserGoal = UserGoal ? UserGoal : null

    if (this.state.UserGoal !== UserGoal) {
      UserGoal.calculate()
      this.setState({ UserGoal }, () =>
        this.#onValidityChange(UserGoal?.isComplete)
      )
    } else if (!UserGoal) {
      UserGoal = UserGoalFactory.create({
        user_id: UserProfileService.getUserId(),
        paradigm: 'EARNINGS',
      })
      UserGoal.calculate()
      this.setState({ UserGoal }, () =>
        this.#onValidityChange(UserGoal?.isComplete)
      )
    }
  }

  #onValidityChange = (changed) => {
    if (typeof this.props?.onValidityChange === 'function')
      this.props.onValidityChange(changed)
  }

  componentDidMount() {
    this.#fetch()
  }

  componentWillUnmount() {}

  render() {
    return (
      <MDBContainer id="AgentKpiGoalsIntake" fluid>
        <h2>
          <span className="intro-step">4</span>Enter KPIs & Set Goals
        </h2>
        <MDBRow>
          <MDBCol size="12">
            <div className="content-wrapper mb-5">
              <KpiGoalManager
                UserGoal={this.state.UserGoal}
                isOnboarding={true}
                onValidityChange={this.#onValidityChange}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default AgentKpiGoalsIntake
