import axios from 'axios'
import env from './../environments/environment'
import AuthService from './../shared/services/Auth.service'
import ActivityStore from './../shared/stores/activity.store'
const baseUrl = env.api.base_url.replace(/\/api(\/)?$/, '')

const getTimezone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch (ex) {
    console.log({ ex })
  }
}

const setRefreshToken = async () => {
  try {
    await AuthService.refreshToken()
  } catch (ex) {
    if (ex === 'NO_USER') window.location.href = '/login'
  }
}

const serialize = (obj, prefix) => {
  var str = [],
    p
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p]
      str.push(
        v !== null && typeof v === 'object'
          ? serialize(v, k)
          : encodeURIComponent(k) + '=' + encodeURIComponent(v)
      )
    }
  }
  return str.join('&')
}

const request = async (method, endpoint, req) => {
  ActivityStore.recordRequest()

  let isMultipart = false,
    queryStr = {}

  if (req && typeof req === 'object') {
    if (req.constructor.name === 'FormData') {
      isMultipart = true
    } else {
      Object.keys(req).forEach((prop) => {
        switch (`${prop}`.toLowerCase().trim().replace(/_/g, '')) {
          case 'orderby':
            queryStr.order_by = req[prop]
            break
          case 'pagination':
            queryStr.pagination = req[prop]
            break
          case 'search':
            queryStr.search = req[prop]
            break
          case 'expand':
            queryStr.expand =
              req[prop] && Array.isArray(req[prop]) ? req[prop] : [req[prop]]
            queryStr.expand = queryStr.expand.filter((n) => !!n)
            if (!queryStr.expand.length) delete queryStr.expand
            break
          case 'query':
            queryStr = {
              ...queryStr,
              ...(req[prop] && typeof req[prop] === 'object' ? req[prop] : {}),
            }
            break
          default:
            break
        }
      })
    }
  }

  // Append timezone, if possible.
  let tz = getTimezone()
  queryStr.tz = tz || 'America/New_York'

  if (Object.keys(queryStr).length) endpoint += '?' + serialize(queryStr)

  let pending =
    method === 'post' || method === 'put'
      ? getClientInstance(isMultipart)[`${method}`.trim().toLowerCase()](
          endpoint,
          req
        )
      : getClientInstance(isMultipart)[`${method}`.trim().toLowerCase()](
          endpoint
        )

  return new Promise((resolve, reject) => {
    pending
      .then((res) =>
        resolve({
          data: res?.data,
          status: res?.status,
          headers: res?.headers,
        })
      )
      .catch((err) =>
        reject({
          message: err?.response?.data?.message,
          status: err?.response?.status,
          headers: err?.response?.headers,
        })
      )
  })
}

function getClientInstance(isMultipart) {
  return axios.create({
    accept: isMultipart ? 'multipart/form-data' : 'application/json',
    baseURL: baseUrl,
    timeout: 60000,
    headers: { Authorization: 'Bearer ' + AuthService.getToken() },
  })
}

const ApiClient = {
  request: request,
  setRefreshToken: setRefreshToken,
  isForbidden: (ex) =>
    ex &&
    ((ex?.status && ex.status === 403) ||
      (ex?.response && ex.response.status === 403)),
}

export default ApiClient
