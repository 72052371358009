import React from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { MDBContainer, MDBBtn, MDBIcon, MDBRow, MDBCol } from 'mdbreact'
import AgentContractManager from './../../components/contracting/AgentContractManager/AgentContractManager.component'
import CarrierContactsModal from './../../components/modals/CarrierContactsModal/CarrierContactsModal.component'
import UplineContactsModal from './../../components/modals/UplineContactsModal/UplineContactsModal.component'
import UserProfileService from './../../shared/services/UserProfile.service'
import UserNotesWidget from './../../components/UserNotes/UserNotesWidget.component'
import CommissionLevelSerivce from './../../shared/services/CommissionLevel.service'
import ContentModal from './../../components/modals/ContentModal/ContentModal.component'
import MediaUploaderModal from '../../components/modals/MediaUploaderModal'

import './AgentContracting.scss'

const fetchCommissionData = async () => {
  return new Promise(async (resolve, reject) => {
    let cl
    try {
      cl = await CommissionLevelSerivce.get(
        UserProfileService.get('commission_id')
      )
      resolve(cl && cl?.com_url ? cl.com_url : '')
    } catch (ex) {
      console.error('Error: Failed to fetch commissions data.', ex)
      resolve('')
    }
  })
}

class AgentContractingPage extends React.Component {
  state = {
    comm_level: null,
    e_agent_center: false,
    is_FFM_Cert_uploaded: false,
  }

  _goTo = (event, link, queryParams) => {
    event.preventDefault()

    if (typeof this.props?.history?.push === 'function') {
      const url = new URL(link, window.location.origin)

      // Add query parameters if provided
      if (queryParams) {
        Object.keys(queryParams).forEach((key) => {
          url.searchParams.append(key, queryParams[key])
        })
      }

      this.props.history.push(url.pathname + url.search, {
        from: '/agent-contracting',
      })
    } else {
      window.location = link
    }

    return false
  }

  _uploadFFMDocument = () => {
    MediaUploaderModal.open({
      uploadType: 'ffm_document',
      modalContentTop: (
        <div className="text--center fw--500">
          <h6 className="fw--500">Upload your FFM Document</h6>
        </div>
      ),
      validationRules: {
        contentType: ['application/pdf'],
      },
      preventAutoClose: true,
      onSuccess: () => {
        console.log('media uploader success')
        this.setState({ is_FFM_Cert_uploaded: true })
      },
    })
      .then()
      .catch((err) => {
        if (err) console.log('Failed to complete uploading FFM documents.', err)
      })
  }

  _toggleEagentCenterModal = () =>
    this.setState((prevState) => ({
      ...prevState,
      e_agent_center: !this.state.e_agent_center,
    }))

  _renderStatesAvailBtn = () => {
    if (parseInt(UserProfileService.getCurrentUserTypeId()) !== 111)
      return (
        <MDBBtn
          color="indigo"
          className="btn-block mb-1"
          href="https://www.ahcpsales.com/state-carrier-map/"
          target="_blank"
        >
          <MDBIcon icon="map-signs" className="mr-1" /> States Availability
        </MDBBtn>
      )
    return <></>
  }

  _renderCommSchedBtn = () => {
    if (
      this.state.comm_level &&
      UserProfileService.getUserDetails()?.com_code &&
      isNaN(UserProfileService.getUserDetails()?.com_code) &&
      parseInt(UserProfileService.getUserDetails()?.com_code) !== 0
    )
      return (
        <MDBBtn
          tag="a"
          color="indigo"
          className="btn-block mb-1"
          target="_blank"
          href={this.state.comm_level}
        >
          <MDBIcon icon="calendar-alt" className="mr-1" /> Commission Schedule
        </MDBBtn>
      )
  }

  _renderCarrierContact = () => <CarrierContactsModal />

  _renderNotes = () => {
    return UserProfileService.isAssumed() ? (
      <>
        <UserNotesWidget userId={UserProfileService.getUserId()} private={1} />
        <UserNotesWidget userId={UserProfileService.getUserId()} />
      </>
    ) : (
      <UserNotesWidget userId={UserProfileService.getUserId()} />
    )
  }

  _renderUplineContactBtn = () => <UplineContactsModal />

  _renderCommStmtBtn = () => {
    if (
      this.state.comm_level &&
      UserProfileService.getUserDetails()?.com_code &&
      isNaN(UserProfileService.getUserDetails()?.com_code) &&
      parseInt(UserProfileService.getUserDetails()?.com_code) !== 0
    )
      return (
        <MDBBtn
          color="indigo"
          className="btn-block mb-1"
          target="_blank"
          onClick={() => this._toggleEagentCenterModal()}
        >
          <MDBIcon icon="calendar-alt" className="mr-1" /> eAgent Center
          Commissions
        </MDBBtn>
      )
  }

  _renderSupportingLinks = () => {
    if (UserProfileService.isA(['agency-owner', 'non-agent'], true)) {
      return (
        <ul className="support-link-list">
          <li>
            <div>
              <a href="/" onClick={(event) => this._goTo(event, '/eo-policy')}>
                View Agent E&O Policy
              </a>
            </div>
          </li>
          <li>
            <div>
              <a
                href="/"
                onClick={(event) => this._goTo(event, '/state-licenses')}
              >
                View Agent State Licenses
              </a>
            </div>
          </li>
          <li onClick={(event) => this._uploadFFMDocument()}>
            <div>
              Upload FFM Certification&nbsp;
              {this.state.is_FFM_Cert_uploaded ? (
                <MDBIcon far icon="check-circle" />
              ) : (
                <></>
              )}
            </div>
          </li>
          <li
            onClick={(event) =>
              this._goTo(event, '/agent-resources', { id: '872' })
            }
          >
            <div>View State License Info & How-To’s</div>
          </li>
        </ul>
      )
    }

    return <></>
  }

  componentDidMount() {
    Promise.all([
      new Promise((resolve, reject) =>
        fetchCommissionData().then((url) =>
          resolve({ comm_level: url !== this.state.comm_level ? url : null })
        )
      ),
    ]).then((responses) => {
      let stateUpd = {}
      responses
        .filter((r) => !!r && typeof r === 'object')
        .forEach(
          (response) =>
            (stateUpd[Object.keys(response).shift()] =
              Object.values(response).shift())
        )
      if (Object.keys(stateUpd).length > 0)
        this.setState((prevState) => ({ ...prevState, ...stateUpd }))
    })
  }

  render() {
    return (
      <React.Fragment>
        <DashboardLayout>
          <main id="AgentContractingPage" className="mainSection">
            <MDBContainer fluid className="mt-5">
              <h2>Contracting</h2>
              <hr />
              <MDBRow>
                <MDBCol size="12" md="6">
                  {this._renderStatesAvailBtn()}
                  {this._renderCommSchedBtn()}
                  {this._renderCarrierContact()}
                  {this._renderUplineContactBtn()}
                  {this._renderCommStmtBtn()}
                  {this._renderSupportingLinks()}
                </MDBCol>
                <MDBCol size="12" md="6">
                  {this._renderNotes()}
                </MDBCol>
              </MDBRow>
              <br />
              <div
                className="mb-5"
                style={{
                  backgroundColor: '#FFF',
                  border: 'thin solid white',
                  borderRadius: '0.3rem',
                }}
              >
                <AgentContractManager />
              </div>
            </MDBContainer>
            <ContentModal
              isOpen={!!this.state.e_agent_center}
              contentSlug={'eagent-center-comms'}
              onClose={() => this._toggleEagentCenterModal()}
              wrapperClass="eagent-content-modal"
            />
          </main>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export default AgentContractingPage
