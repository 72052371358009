import { makeAutoObservable, toJS } from 'mobx'
import UserPerformanceEntryService from './../../../shared/services/UserPerformanceEntry.service'
import UserProfileService from './../../../shared/services/UserProfile.service'
import UserEventAttendanceService from './../../../shared/services/UserEventAttendance.service'
import CalendarEventService from './../../../shared/services/CalendarEvent.service'
import { BehaviorSubject } from 'rxjs'
import KpiMetricService from './../../../shared/services/KpiMetric.service'
import moment from 'moment'

const timer = []

const getPerformance = () => ({
  hours_worked: 0,
  fact_finders: 0,
  appts_set: 0,
  calls: 0,
  spoken_to: 0,
  events_attended: 0,
  presentations_offered: 0,
  referrals_requested: 0,
  life_quoted: 0,
})

const getEmpty = () => ({
  events: [],
  performance: getPerformance(),
  attendance: [],
})

class MetricsIntakeStore {
  constructor() {
    makeAutoObservable(this)
  }

  kpiDefs = {}
  performance = {}
  events = []
  attendance = []

  metrics = {}
  pagination = {
    performance: { per_page: 10, page: 0 },
    attendance: { per_page: 25, page: 0 },
  }
  lastDate

  history = {}

  fetchingSub = new BehaviorSubject(false)
  fetching = false

  getCurrentPerformance = () => this.performanceSub

  getIsFetching = () => this.fetchingSub

  fetch = async (type, pagination) => {
    const getEvents = async () => {
      return new Promise((resolve, reject) => {
        CalendarEventService.getBefore(moment().format('YYYY-MM-DD'), {
          order_by: { starts_at: 'DESC' },
          pagination: pagination ? pagination : this.pagination.attendance,
        })
          .then((events) => resolve(events))
          .catch(() => resolve([]))
      })
    }

    const getPerformance = async (user_id) => {
      try {
        let response = await UserPerformanceEntryService.search({
          search: { user_id },
          orderBy: { entry_date: 'DESC' },
          pagination: pagination ? pagination : this.pagination.performance,
        })

        this.pagination.performance = response.pagination
        return response
      } catch (ex) {
        console.error(ex)
      }
    }

    const getAttendance = async (event_id) => {
      return new Promise((resolve, reject) => {
        UserEventAttendanceService.search({
          search: { event_id, user_id: UserProfileService.getUserId() },
          pagination: false,
        })
          .then((attendance) =>
            resolve((attendance && attendance.models) || [])
          )
          .catch(() => resolve([]))
      })
    }

    return new Promise((resolve, reject) => {
      if (type === 'performance') {
        getPerformance(UserProfileService.getUserId())
          .then((performance) => resolve(performance))
          .catch(() => resolve([]))
      }
      if (type === 'attendance') {
        getEvents()
          .then((events) => {
            this.pagination.attendance = events.pagination
            getAttendance(events.models.map((e) => e.event_id))
              .then((attendance) => resolve({ events, attendance }))
              .finally(() => resolve({ events: [], attendance: [] }))
          })
          .catch(() => resolve({ events: [], attendance: [] }))
      }
    })
  }

  fetchByDate = async (date) => {
    const setEvents = async (ed) => {
      return new Promise((resolve, reject) => {
        CalendarEventService.dayByDate(ed)
          .then((events) => resolve(events?.models || []))
          .catch(() => resolve([]))
      })
    }

    const setPerformance = async (user_id, entry_date) => {
      return new Promise((resolve, reject) => {
        UserPerformanceEntryService.search({ search: { user_id, entry_date } })
          .then((perf) => {
            if ((perf = perf && perf.models.shift()))
              ['user_id', 'created_at', 'updated_at']
                .filter((key) => perf.hasOwnProperty(key))
                .forEach((key) => delete perf[key])
            resolve(
              perf || {
                hours_worked: 0,
                fact_finders: 0,
                appts_set: 0,
                calls: 0,
                spoken_to: 0,
                events_attended: 0,
                presentations_offered: 0,
                referrals_requested: 0,
                life_quoted: 0,
              }
            )
          })
          .catch(() =>
            resolve({
              hours_worked: 0,
              fact_finders: 0,
              appts_set: 0,
              calls: 0,
              spoken_to: 0,
              events_attended: 0,
              presentations_offered: 0,
              referrals_requested: 0,
              life_quoted: 0,
            })
          )
      })
    }

    const setAttendance = async (event_id) => {
      return new Promise((resolve, reject) => {
        UserEventAttendanceService.search({
          search: { event_id, user_id: UserProfileService.getUserId() },
        })
          .then((attendance) =>
            resolve((attendance && attendance.models) || [])
          )
          .catch(() => resolve([]))
      })
    }

    const request = async (ed) => {
      return Promise.all([
        new Promise((resolve, reject) => {
          setEvents(ed)
            .then((events) => {
              this.history[ed] = this.history[ed]
                ? toJS(this.history[ed])
                : getEmpty()

              this.events = events
              this.history[ed] = { ...this.history[ed], events }

              setAttendance(events.map((e) => e.event_id))
                .then((attendance) => {
                  this.history[ed] = this.history[ed]
                    ? toJS(this.history[ed])
                    : getEmpty()

                  this.attendance = attendance
                  this.history[ed] = { ...this.history[ed], attendance }
                })
                .finally(() => resolve())
            })
            .catch(() => resolve())
        }),

        new Promise((resolve, reject) => {
          setPerformance(UserProfileService.getUserId(), ed)
            .then((performance) => {
              this.history[ed] = this.history[ed]
                ? toJS(this.history[ed])
                : getEmpty()
              this.history[ed] = { ...this.history[ed], performance }

              this.performance = performance
            })
            .finally(() => resolve())
        }),
      ])
    }

    this.lastDate = date
    if (this.history.hasOwnProperty(date)) {
      const { events, performance, attendance } = toJS(this.history[date])
      this.performance = performance
      this.attendance = attendance
      this.events = events
      return this.history[date]
    }

    this.fetching = true
    this.fetchingSub.next(true)

    return new Promise((resolve, reject) => {
      request(date).finally(() => {
        resolve()
        this.fetching = false
        this.fetchingSub.next(false)
      })
    })
  }

  metricsHaveChanged = (date, metrics) => {
    if (!this.history.hasOwnProperty(date)) return false

    let diff = [],
      skip = ['id', 'user_id', 'entry_date', 'created_at', 'updated_at']

    Object.keys(this.history[date].performance)
      .filter((n) => skip.indexOf(n) < 0)
      .forEach((key) =>
        diff.push(
          parseInt(this.history[date].performance[key]) !==
            parseInt(metrics[key])
            ? {
                [key]: {
                  from: this.history[date].performance[key],
                  to: metrics[key],
                },
              }
            : false
        )
      )

    return diff.filter((n) => !!n).length > 0
  }

  setMetrics = async (metrics) => {
    if (!this.metricsHaveChanged(this.lastDate, metrics)) return

    const removeNull = () => {
      if (metrics && typeof metrics === 'object') {
        let cleaned = { ...metrics }
        cleaned.hours_worked = !isNaN(cleaned?.hours_worked)
          ? cleaned.hours_worked
          : 0
        cleaned.fact_finders = !isNaN(cleaned?.fact_finders)
          ? cleaned.fact_finders
          : 0
        cleaned.calls = !isNaN(cleaned?.calls) ? cleaned.calls : 0
        cleaned.spoken_to = !isNaN(cleaned?.spoken_to) ? cleaned.spoken_to : 0
        cleaned.events_attended = !isNaN(cleaned?.events_attended)
          ? cleaned.events_attended
          : 0
        cleaned.appts_set = !isNaN(cleaned?.appts_set) ? cleaned.appts_set : 0
        cleaned.presentations_offered = !isNaN(cleaned?.presentations_offered)
          ? cleaned.presentations_offered
          : 0
        cleaned.referrals_requested = !isNaN(cleaned?.referrals_requested)
          ? cleaned.referrals_requested
          : 0
        cleaned.life_quoted = !isNaN(cleaned?.life_quoted)
          ? cleaned.life_quoted
          : 0
        return cleaned
      }

      return metrics
    }

    let date = this.lastDate,
      isNew = !this.history[date]?.performance?.id,
      updates = removeNull(),
      result

    try {
      if (isNew) {
        result = await UserPerformanceEntryService.store({
          ...updates,
          user_id: UserProfileService.getUserId(),
          entry_date: date,
        })
      } else {
        result = await UserPerformanceEntryService.update(
          this.history[date].performance.id,
          { ...updates, entry_date: date }
        )
      }
    } catch (ex) {
      console.error('FAILED TO update performance metrics', ex)
    }

    if (result && isNew)
      this.history[date].performance = {
        ...this.history[date].performance,
        ...result,
        entry_date: date,
      }
  }

  attendEvent = async (eventId) => {
    UserEventAttendanceService.attend(eventId, UserProfileService.getUserId())
    let attendance = [...toJS(this.attendance)].concat([{ event_id: eventId }])
    this.attendance = attendance
    this.history[this.lastDate].attendance = attendance
  }

  unattendEvent = async (eventId) => {
    UserEventAttendanceService.unattend(eventId, UserProfileService.getUserId())
    let attendance = toJS(this.attendance).filter(
      (evt) => evt.event_id !== eventId
    )

    this.attendance = attendance
    this.history[this.lastDate].attendance = attendance
  }

  fetchDefinitions = async () => {
    return KpiMetricService.search().then((kpis) => {
      let defs = {}
      kpis.models.forEach(
        (kpi) => (defs[kpi.metric_name] = kpi?.description || null)
      )
      this.kpiDefs = defs
      return defs
    })
  }

  storeMetrics = async ({ metrics, id, date }) => {
    const metricKeys = Object.keys(getPerformance())

    Object.keys(metrics).forEach((prop) => {
      if (metricKeys.includes(prop))
        metrics[prop] = isNaN(metrics[prop]) ? 0 : parseInt(metrics[prop])
      else delete metrics[prop]
    })

    let result
    try {
      if (!id) {
        result = await UserPerformanceEntryService.store({
          ...metrics,
          user_id: UserProfileService.getUserId(),
          entry_date: date,
        })
      } else {
        result = await UserPerformanceEntryService.update(
          this.history[date].performance.id,
          { ...metrics, entry_date: date }
        )
      }
    } catch (ex) {
      console.error('FAILED TO update performance metrics', ex)
    }

    if (result && !id) {
      if (!this.performance.id || isNaN(this.performance.id))
        this.performance.id = result.id

      this.history[date].performance = {
        ...toJS(this.history[date].performance),
        ...result,
        entry_date: date,
      }
    }
  }

  debouncedSave = () => {
    if (timer.length) {
      window.clearTimeout(timer[0])
      timer.pop()
    }

    const metrics = toJS(this.performance),
      id = metrics && isNaN(metrics?.id) ? false : metrics.id,
      date = this.lastDate

    timer.push(setTimeout(() => this.storeMetrics({ metrics, id, date }), 300))
  }

  onMetricChange = async (evt) => {
    const { name, value } = evt.target
    if (isNaN(value) || value === null) return

    this.performance[name] = parseInt(value)
    this.history[this.lastDate].performance = {
      ...(this.history[this.lastDate].performance
        ? toJS(this.history[this.lastDate].performance)
        : {}),
      [name]: parseInt(value),
    }

    this.debouncedSave()
  }
}

export default new MetricsIntakeStore()
