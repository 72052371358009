import React from 'react'
import {observer} from 'mobx-react-lite'
import LoadingSpinner from './../../../../components/shared/LoadingSpinner.component'
import ConfirmDeleteModalComponent from './../../../../components/adminShared/ConfirmDeleteModal/ConfirmDeleteModal.component'
import {UIInput, UIInputButton, UIInputToggleSwitch} from './../../../../components/forms/form-fields'
import {MDBIcon,MDBBtn,MDBCollapse} from 'mdbreact'
import Store from './../../store';

import './PackagesTable.component.scss'

const PackagesTable = () => 
{

	const onConfirmed = async () =>
		await Store.deletePackage()

	const renderEditPackage = (Package) =>
	{
		if (Store.EditPackage)
			return <td></td>

		return (
				<td>
					<MDBBtn className='edit-package-btn' onClick={() => Store.EditPackage = Package}><MDBIcon icon='edit' /></MDBBtn>
				</td>
			)
	}

	const renderDeletePackage = (Package) =>
	{
		return (
				<td>
					<MDBBtn className='delete-package-btn' onClick={() => Store.DeletePackage = Package}><MDBIcon icon='trash' /></MDBBtn>
				</td>
			)
	}

	const renderTableRow = (Package, idx) =>
		{
		const isEditing = Store.EditPackage && parseInt(Store.EditPackage.id()) === parseInt(Package.id());

		return (
				<React.Fragment key={`admin-package-${Package.id()}-${idx}`}>
					<tr>
						{
							isEditing
								?	<>
										<td>
											<UIInput 
												name='edit_position'
												required
												value={Package.get('position')}
												onChange={evt => {
													const position = evt.target.value;
													if (position === '' || (!isNaN(position) && position > 0))
														Package.set('position', position);
												}}
											/>
										</td>
										<td>
											<UIInput 
												name='edit_name'
												required
												value={Package.get('pkg_name')}
												onChange={evt => Package.set('pkg_name', evt.target.value)}
											/>
										</td>
										<td>
											<UIInput 
												name='edit_pkg_descriptor'
												required
												value={Package.get('pkg_descriptor')}
												onChange={evt => Package.set('pkg_descriptor', evt.target.value)}
											/>
										</td>
										<td>
							        <UIInputButton
							          input={
													<UIInput 
														name='edit_price_descriptor'
														required
														value={Package.get('price_descriptor')}
														onChange={evt => Package.set('price_descriptor', evt.target.value)}
													/>
							          }
							          btnPosition={'right'}
							          button={<MDBBtn className='save-package-btn' disabled={(`${Package.get('pkg_name')}`.trim().length === 0 || isNaN(Package.get('position')) || Package.get('position') < 1)} onClick={() => Store.savePackage(Package)}><MDBIcon icon='save' /></MDBBtn>}
							        />
										</td>
									</>
								: <>
										<td>{Package.get('position')}</td>
										<td>{Package.get('pkg_name')}</td>
										<td>{Package.get('pkg_descriptor')}</td>
										<td>{Package.get('price_descriptor')}</td>
									</>
						}
						{renderEditPackage(Package)}
						{renderDeletePackage(Package)}
					</tr>
					<tr>
						<td colSpan='6'>
							<MDBCollapse isOpen={isEditing}>
								<table width='100%' className='edit-package-table'>
									<tbody>
										<tr>
											<td>
												<UIInput 
													name='edit_price_descriptor'
													label='Stripe Price IDs'
													value={Package.get('price_ids')}
													onChange={evt => Package.set('price_ids', evt.target.value)}
												/>
											</td>
											<td>
												Usertype: {Store.getPackageUsertype(Package)}
											</td>
											<td>
												<label>Available to Enroll? (Locked)</label>
												<UIInputToggleSwitch 
														width='100'
														disabled={true}
														labelOn={false?<i className='fa fa-spin fa-spinner'></i>:'Yes'}
														labelOff={false?<i className='fa fa-spin fa-spinner'></i>:'No'}
														value={Package.get('can_enroll')}
														onChange={evt => Package.set('can_enroll', evt===true?1:0)}
													/>
											</td>
											<td>
												<label>Hide from Non-Leaders?</label>
												<UIInputToggleSwitch 
														width='100'
														disabled={false}
														labelOn={false?<i className='fa fa-spin fa-spinner'></i>:'Yes'}
														labelOff={false?<i className='fa fa-spin fa-spinner'></i>:'No'}
														value={Package.get('mgr_only')}
														onChange={evt => Package.set('mgr_only', evt===true?1:0)}
													/>
											</td>
										</tr>
									</tbody>
								</table>
							</MDBCollapse>
						</td>
					</tr>
				</React.Fragment>
			)
	}

	const renderTableHead = () =>
	{
		return (
				<thead>
					<tr>
						<th>Order</th>
						<th>Package Name</th>
						<th>Package Description</th>
						<th>Price Description</th>
						<th>Edit</th>
						<th>Delete</th>
					</tr>
				</thead>
			)
	}

	const renderTableBody = () =>
	{
		return (			
				<tbody>{Store.Packages.map(renderTableRow)}</tbody>
			)
	}

	const renderTable = () =>
	{
		return (
				<div className='table-wrapper'>
					<h5>Agent Packages</h5>
					<table width='100%' className='package-table'>
						{renderTableHead()}
						{renderTableBody()}
					</table>
				</div>
			)
	}


	return (
			<>
        <div id="PackagesTableComponent" className={Store.isFetching?'is-loading':''}>
	        <div className="loading-spinner">
	          <LoadingSpinner size="md" isActive={true} />
	        </div>
					{renderTable()}
				</div>
	      <ConfirmDeleteModalComponent
	        confirm={Store.DeletePackage && Store.DeletePackage?.get('pkg_name') ? <>{Store.DeletePackage.get('pkg_name')}<br />{Store.DeletePackage?.get('pkg_descriptor')}</>:null}
	        onConfirm={onConfirmed}
	        shouldEnable={true}
	        onCancel={() => Store.DeletePackage = null}
	      />
			</>
		)
}

export default observer(PackagesTable)