import env from './../../environments/environment'
import UserProfileService from './../services/UserProfile.service'
import StorageService from './../services/Storage.service'

const isJson = (str) => {
  try {
    JSON.parse(str)
    return true
  } catch (ex) {
    return false
  }
}

const doesAgentHaveOutstandingLeaderRequirements = () => {
  // Non-Recruiter agents are not privy to this requirement.
  if (!UserProfileService.isA('recruiter-group')) return false

  // Career & Premier agents are not required to
  // fulfill the corporate leader requirements.
  if (UserProfileService.isA(['career-agent', 'premier-agent'])) return false

  const data = isJson(StorageService.get('user---required-alerts'))
    ? JSON.parse(StorageService.get('user---required-alerts'))
    : false
  if (!data) return false
  if (typeof data !== 'object') return false
  if (Object.values(data)[0])
    return (
      Object.values(data)[0]?.leader_terms ||
      Object.values(data)[0]?.leader_trainings
    )
  return false
}

/*
 * @param {Object} link
 *	  NavLink object.
 * @return {boolean}
 *   True/false value indicating if the NavLink object has
 *   blocking requirements.
 * 	Returns true if 'blocks' exist. 'Blocks' are definitions
 *   that will block an authenticated user access.
 */
const linkHasBlocks = (link) =>
    !!(link?.blocks && typeof link.blocks === 'object'),
  /*
   * @param {Object} link
   *	  NavLink object.
   * @return {boolean}
   *   True/false value indicating if the NavLink object has
   *   Usertype blocking requirements.
   * 	Returns true if 'blocks' exist.
   */
  shouldBlockUsertypes = (link) =>
    !!(
      Array.isArray(link.blocks?.usertypes) && link.blocks.usertypes.length > 0
    ),
  /*
   * @param {Object} link
   *	  NavLink object.
   * @return {boolean}
   *   True/false value indicating if the authenticated
   * 	user has the required blocking usertypes.
   * 	Only one of the usertypes of the usertypes array is required.
   * 	Returns true if user has blocking usertype.
   */
  evalBlockUsertypes = (link) =>
    !!UserProfileService.isA(link.blocks.usertypes),
  /*
   * @param {Object} link
   *	  NavLink object.
   * @return {boolean}
   *   True/false value indicating if the authenticated
   * 	user has the required blocking user model properties.
   * 	Returns true if NavLink definition object
   * 	has user property requirements.
   */
  shouldBlockProperties = (link) =>
    !!(Array.isArray(link.blocks?.profile) && link.blocks.profile.length > 0),
  /*
   * @param {Object} link
   *	  NavLink object.
   * @return {boolean}
   *   True/false value indicating if the authenticated
   * 	user properties are true || 1.
   * 	Returns true if NavLink definition object
   * 	has user property requirements are met.
   */
  evalBlockProperties = (link) =>
    !!link.blocks.profile.every(
      (a) =>
        UserProfileService.get(a) === true ||
        `${UserProfileService.get(a)}` === '1'
    ),
  /*
   * @param {Object} link
   *	  NavLink object.
   * @return {boolean}
   *   True/false value indicating if NavLink object
   * 	has requirements for authenticated users.
   * 	Returns true if NavLink definition object
   * 	has requirements.
   */
  linkHasRequires = (link) =>
    !!(link?.requires && typeof link.requires === 'object'),
  /*
   * @param {Object} link
   *	  NavLink object.
   * @return {boolean}
   *   True/false value indicating if the NavLink
   * 	requires by usertype.
   * 	Returns true if NavLink definition object
   * 	has usertype requirements.
   */
  shouldRequireUsertypes = (link) =>
    !!(
      Array.isArray(link.requires?.usertypes) &&
      link.requires.usertypes.length > 0
    ),
  /*
   * @param {Object} link
   *	  NavLink object.
   * @return {boolean}
   *   True/false value indicating if the authenticated
   * 	user meeting the NavLink usertype requirements.
   * 	Returns true if NavLink definition object
   * 	has usertypes requirements are met. Only one usertype
   * 	is required.
   */
  evalRequireUsertypes = (link) =>
    !!UserProfileService.isA(link.requires.usertypes),
  /*
   * @param {Object} link
   *	  NavLink object.
   * @return {boolean}
   *   True/false value indicating if the NavLink
   * 	requires by user properties.
   * 	Returns true if NavLink definition object
   * 	has user property requirements.
   */
  shouldRequireProperties = (link) =>
    !!(
      Array.isArray(link.requires?.profile) && link.requires.profile.length > 0
    ),
  /*
   * @param {Object} link
   *	  NavLink object.
   * @return {boolean}
   *   True/false value indicating if the authenticated
   * 	user has the required properties and the
   * 	required properties are true || 1.
   * 	Returns true if NavLink definition object
   * 	user property requirements are met.
   */
  evalRequireProperties = (link) =>
    !!link.requires.profile.every(
      (a) =>
        UserProfileService.get(a) === true ||
        `${UserProfileService.get(a)}` === '1'
    ),
  /*
   * @param {Object} link
   *	  NavLink object.
   * @return {boolean}
   *   True/false value indicating if the NavLink
   * 	is only permitted in specific environments.
   *		This is only used when preventing NavLinks from
   * 	being access in production.  Used when developing
   *   features that can only be access in beta, dev or
   * 	local environments.
   */
  shouldCheckEnv = (link) =>
    !!(Array.isArray(link.requires?.env) && link.requires.env.length > 0),
  /*
   * @param {Object} link
   *	  NavLink object.
   * @return {boolean}
   *   True/false value indicating if the NavLink
   * 	environment requirement is met.
   */
  evalCheckEnv = (link) => !!(link.requires.env.indexOf(env.env) < 0),
  /*
   * @param {Object} link
   *	  NavLink object.
   * @return {boolean}
   *   True/false value indicating if the NavLink
   * 	overrides by usertypes is being met by the
   * 	parent authenticated user.
   */
  evalOverridesUsertypes = (link) =>
    !!(
      Array.isArray(link?.overrides?.usertypes) &&
      link.overrides.usertypes.length > 0 &&
      UserProfileService.isA(link.overrides.usertypes, true)
    ),
  /*
   * @param {Object} link
   *	  NavLink object.
   * @return {boolean}
   *   True/false value indicating if the NavLink
   * 	overrides by user property is being met by the
   * 	parent authenticated user.
   */
  evalOverridesProperties = (link) =>
    !!(
      Array.isArray(link?.overrides?.profile) &&
      link.overrides.profile.length > 0 &&
      link.overrides.profile.every(
        (a) =>
          UserProfileService.get(a, true) === true ||
          `${UserProfileService.get(a, true)}` === '1'
      )
    ),
  /*
   * @param {Object} link
   *	  NavLink object.
   * @return {boolean}
   *   True/false value indicating if NavLink object
   * 	has requirements for authenticated users.
   * 	Returns true if NavLink definition object
   * 	has requirements.
   */
  linkHasReadonly = (link) =>
    !!(link?.readonly && typeof link.readonly === 'object'),
  /*
   * @param {Object} link
   *	  NavLink object.
   * @return {boolean}
   *   True/false value indicating if the NavLink object has
   *   Usertype readonly requirements.
   * 	Returns true if 'readonly' exist.
   */
  shouldReadonlyUsertypes = (link) =>
    !!(
      Array.isArray(link.readonly?.usertypes) &&
      link.readonly.usertypes.length > 0
    ),
  /*
   * @param {Object} link
   *	  NavLink object.
   * @return {boolean}
   *   True/false value indicating if the authenticated
   * 	user has the required readonly usertypes.
   * 	Only one of the usertypes of the usertypes array is required.
   * 	Returns true if user has readonly usertype.
   */
  evalReadonlyUsertypes = (link) =>
    !!UserProfileService.isA(link.readonly.usertypes)

/*
 * The `canAccessLink` function determines if an authenticated user has the
 * correct level of access required to be granted access to a particular
 * NavLink.  The canAccessLink function accepts a NavLink definition object
 * from the nav-links.constants file (./src/constants/nav-links.constants).
 *
 * Each NavLink object defines the url or route for the link action,
 * requirements for the link (usertype, onboarding, etc), and which
 * links can be overridden.
 *
 * @param {Object}  link
 *		NavLink definition object
 * @param {string}  link.url
 * 	URL for NavLink.
 * 	This NavLink property does not exist if 'children' are present.
 * @param {string}  link.label
 * 	Button or <a> label for NavLink.
 * @param {string}  link.icon
 * 	Icon name for NavLink.
 * 	See: https://mdbootstrap.com/docs/b4/react/content/icons-list/
 * @param {object}  link.requires
 * @param {array}   link.requires.profile
 *		Array of strings with property names to
 * 	test for requirement on User model.
 * 	Ex: ['has_onboarded']
 * 		UserProfileService.get('has_onboarded')  	// if truthy, pass.
 * @param {array}   link.requires.usertypes
 *		Array of strings with usertype slugs to
 * 	test for requirement on User model.
 * 	Ex: ['affiliate-agent']
 * 		UserProfileService.isA('affiliate-agent')  // if true, pass.
 * @param {object}  link.blocks
 * @param {array}   link.blocks.usertypes
 *		Array of strings with usertype slugs to
 * 	test for preventing access against User model.
 * 	Ex: ['affiliate-agent']
 * 		UserProfileService.isA('affiliate-agent')  // if true, block/fail.
 * @param {object}  link.overrides
 * @param {array}   link.overrides.usertypes
 *		Array of strings with usertype slugs to
 * 	test for overriding access to a User model.
 *		This property is used when a user logs into another
 *   user's account.  When accessing the child user account,
 * 	the overrides.usertype array defines which usertypes
 * 	are permitted access to the NavLink even when the
 * 	authenticated child user does not have access to a link.
 *		Example: Agent's that have not yet completed onboarding are not
 * 	permitted to access the 'Carrier Contracting & Commissions'
 * 	NavLink. (ie: UserProfileService.get('has_onboarded') === 0).
 * 	However, admin users or upline users may access 'Carrier
 * 	Contracting & Commissions' NavLink while logged in as the
 * 	child user account.
 * 	The 'overrides' object of the NavLink definition instructs
 * 	us as to which NavLinks can be access by admin/upline users
 * 	when a child user does not yet have access.
 * 	Ex: ['system-admin']
 * 		UserProfileService.isA('system-admin')     // if true, pass.
 * @param {array}   link.children
 *		The 'children' property is an array of NavLink objects
 * 	defining all the possible nested menu links.
 * @param {boolean} inclOverrides
 * 	Boolean argument indicating if the `overrides` property
 * 	of the link definition object should be taken into
 * 	consideration when determining if the user should
 * 	have access to the link.  This argument is only
 * 	applicable when logged in as a downline agent.
 * 	If this value is not true, the link.overrides values
 * 	will not be evaluated.
 * @return {boolean} Value indicating if the authenticated user
 *
 */
function canAccessLink(link, inclOverrides) {
  // Does this link definition block Users?
  if (linkHasBlocks(link)) {
    // A. Check against usertypes.
    if (shouldBlockUsertypes(link))
      if (
        evalBlockUsertypes(link) &&
        (!inclOverrides || (inclOverrides && !evalOverridesUsertypes(link)))
      )
        return false

    // B. Check against user profile properties.
    if (shouldBlockProperties(link)) {
      if (
        evalBlockProperties(link) &&
        (!inclOverrides || (inclOverrides && !evalOverridesProperties(link)))
      )
        return false
    }
  }

  // Does this link definition have User requirements?
  if (linkHasRequires(link)) {
    // A. Check against usertypes.
    if (shouldRequireUsertypes(link)) {
      if (
        !evalRequireUsertypes(link) &&
        (!inclOverrides || (inclOverrides && !evalOverridesUsertypes(link)))
      )
        return false
    }

    // B. Check against user profile properties.
    if (shouldRequireProperties(link)) {
      if (
        !evalRequireProperties(link) &&
        (!inclOverrides || (inclOverrides && !evalOverridesProperties(link)))
      )
        return false
    }

    // C. Check against env properties.
    if (shouldCheckEnv(link)) if (evalCheckEnv(link)) return false
  }

  return true
}

/*
 * The `canAccessChildrenLinks` function determines if the authenticated
 * user has to correct level of access to be granted access to at least 1
 * link from the `link.children` array.  The canAccessChildrenLinks accepts
 * a NavLink definition object from the
 * nav-links.constants file (./src/constants/nav-links.constants).
 *
 * The @param here are the same as the canAccessLink above.
 *
 */

function canAccessChildrenLinks(link, inclOverrides) {
  if (link && Array.isArray(link?.children))
    for (let n in link.children)
      if (canAccessLink(link.children[n])) return true
  return false
}

/**
 * Check this link is readonly for the authenticated user
 * @param {Object} link
 *      NavLink definition object
 * @returns {boolean}
 */

function canReadonlyLink(link) {
  if (linkHasReadonly(link)) {
    // A. Check against usertypes.
    if (shouldReadonlyUsertypes(link)) {
      if (evalReadonlyUsertypes(link)) {
        return true
      }
    }
  }

  return false
}

/*
 * The `canReadonlyChildrenLinks` function determines if the authenticated
 * user has to correct level of readonly to be granted readonly to at least 1
 * link from the `link.children` array.  The canReadonlyChildrenLinks accepts
 * a NavLink definition object from the
 * nav-links.constants file (./src/constants/nav-links.constants).
 *
 * The @param here are the same as the canReadonlyLink above.
 *
 */

function canReadonlyChildrenLinks(link) {
  if (link && Array.isArray(link?.children))
    for (let n in link.children)
      if (canReadonlyLink(link.children[n])) return true
  return false
}

function isLinkDisabled(link) {
  if (link && link?.disabled === true) return true

  if (link && typeof link?.disabled === 'function') return link.disabled()

  return false
}

function isLinkLocked(link) {
  if (!link || !link?.locked) return false

  if (link.locked === true) return true

  if (typeof link.locked === 'function') return link.locked()

  switch (link.locked) {
    case 'HAS_OUTSTANDING_LEADER_REQUIREMENTS':
      return doesAgentHaveOutstandingLeaderRequirements()
    default:
      return false
  }
}

export {
  canAccessLink,
  canAccessChildrenLinks,
  canReadonlyLink,
  canReadonlyChildrenLinks,
  isLinkDisabled,
  isLinkLocked,
}
