import { AgentContractingPage } from './index'

import { RouteFactory } from './../../shared/router/Route.factory'
import {
  RequiresAuthGuard,
  RequiresOnboardingGuard,
} from './../../shared/router/guards'
import { AdminOverride } from './../../shared/router/overrides'

export default [
  RouteFactory.create(AgentContractingPage, '/agent-contracting', [
    new RequiresAuthGuard(true),
    RequiresOnboardingGuard,
    AdminOverride,
  ], {
    name: 'Contracting'
  }),
  RouteFactory.create(AgentContractingPage, '/docu-sign', [
    new RequiresAuthGuard(true),
    RequiresOnboardingGuard,
    AdminOverride,
  ], {
    name: 'Contracting'
  }),
]

/*
More Ideally:
import {AgentContractingPage} from './index';
import {RouteFactory, RouterGuards, RouterOverrides} from './../../shared/router';

export default [
	RouteFactory.create(
		AgentContractingPage, 
		'/agent-contracting', 
		[
			RouterGuards.RequiresAuthGuard, 
			RouterGuards.RequiresOnboardingGuard, 
			RouterOverrides.AdminOverride
		]
	),
	RouteFactory.create(
		AgentContractingPage, 
		'/docu-sign', 
		[
			RouterGuards.RequiresAuthGuard, 
			RouterGuards.RequiresOnboardingGuard, 
			RouterOverrides.AdminOverride
		]
	)
];
*/
