import React from 'react'
import {
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from 'mdbreact'
import TechToolsService from './../../shared/TechTools.service'
import UserProfileService from './../../../../shared/services/UserProfile.service'

import './TechToolWidget.scss'

class TechToolWidget extends React.Component {
  state = {
    isOpen: false,
  }

  #isAffiliateAgent = !!(
    [111, 133].indexOf(parseInt(UserProfileService.getCurrentUserTypeId())) > -1
  )
  #isAssociateAgent = !!(
    [223, 224].indexOf(parseInt(UserProfileService.getCurrentUserTypeId())) > -1
  )
  #isSignatureAgent = !this.#isAffiliateAgent && !this.#isAssociateAgent

  #extractToolSource = (source, imageUris) => {
    if (!source || typeof source !== 'object') return false

    const {
      tool_icon,
      tool_title,
      tool_subtitle,
      tool_content,
      tool_links,
      fullscreen,
    } = source

    return {
      toolIcon: tool_icon,
      toolTitle: tool_title,
      toolSubtitle: tool_subtitle,
      toolContent: tool_content,
      toolLinks: tool_links,
      fullscreen: fullscreen,
      imgPrefix: imageUris && typeof imageUris === 'object' && imageUris.prefix,
      imgSuffix: imageUris && typeof imageUris === 'object' && imageUris.suffix,
    }
  }

  #renderLinkButton = (link, j) => {
    // console.log("A: ",link.tl_title,(link.is_affiliate_agent && this.#isAffiliateAgent));
    // console.log("B: ",link.tl_title,(link.is_associate_agent && this.#isAssociateAgent));
    // console.log("C: ",link.tl_title,(link.is_signature_agent && this.#isSignatureAgent));

    // this broke bc the admin form clears out the boolean access indicators.

    // if ((link.is_affiliate_agent && this.#isAffiliateAgent) || (link.is_associate_agent && this.#isAssociateAgent) || (link.is_signature_agent && this.#isSignatureAgent))
    // console.log("A: ",parseInt(UserProfileService.getCurrentUserTypeId()),link.tl_title,this.#isAffiliateAgent,this.#isAssociateAgent,this.#isSignatureAgent);

    if (
      (link.is_affiliate_agent && this.#isAffiliateAgent) ||
      (link.is_associate_agent && this.#isAssociateAgent) ||
      (link.is_signature_agent && this.#isSignatureAgent)
    ) {
      if (TechToolsService.isCustomHandler(link.tl_link))
        return (
          <MDBBtn
            key={j}
            color="unique"
            onClick={() =>
              TechToolsService.runCustomHandler(link.tl_link, this.props.source)
            }
          >
            {link.tl_title}
          </MDBBtn>
        )
      return (
        <MDBBtn key={j} color="unique" href={link.tl_link} target="_blank">
          {link.tl_title}
        </MDBBtn>
      )
    }
  }

  toggleCollapse = () => () => {
    let isOpen = !this.state.isOpen
    this.setState({ isOpen })
  }

  render() {
    const {
      toolIcon,
      toolTitle,
      toolSubtitle,
      toolContent,
      toolLinks,
      fullscreen,
      imgPrefix,
      imgSuffix,
    } = this.#extractToolSource(
      this.props && this.props.source,
      this.props && this.props.imageUris
    )

    return (
      <MDBCard
        narrow
        className="mt-5 TechToolWidgetComponent gradient-card-header blue-gradient"
      >
        <MDBCardImage className="view view-cascade" cascade tag="div">
          <MDBRow className="tech-tool-header">
            <MDBCol
              sm="12"
              md={fullscreen ? '7' : '12'}
              className={fullscreen ? 'tt-img' : 'tt-img tt-fs'}
            >
              {(toolIcon && (
                <img
                  src={`${imgPrefix}${toolIcon}${imgSuffix}`}
                  alt="Tech Tool"
                />
              )) ||
                toolTitle}
            </MDBCol>
            <MDBCol
              sm="12"
              md={fullscreen ? '5' : '12'}
              className={fullscreen ? 'tt-toggle' : 'tt-toggle tt-fs'}
            >
              <MDBBtn color="primary" onClick={this.toggleCollapse()}>
                View Details
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBCardImage>
        <MDBCardBody cascade className={this.state.isOpen ? 'open' : ''}>
          {toolSubtitle && <div className="tt-subtitle">{toolSubtitle}</div>}
          <MDBCollapse isOpen={this.state.isOpen}>
            <div
              className="mb-4"
              dangerouslySetInnerHTML={{ __html: toolContent }}
            />
            {toolLinks &&
              toolLinks.length > 0 &&
              toolLinks.map((link, j) => {
                return (
                  link.tl_type === 'Youtube' && (
                    <div className="iframe-wrapper">
                      <iframe
                        width="100%"
                        src={link.tl_link}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  )
                )
              })}
            <hr />
            <div className="text-center">
              {toolLinks &&
                toolLinks.length > 0 &&
                toolLinks.map(
                  (link, j) =>
                    link.tl_type === 'Button' && this.#renderLinkButton(link, j)
                )}
            </div>
          </MDBCollapse>
        </MDBCardBody>
      </MDBCard>
    )
  }
}

export default TechToolWidget
