import React, { useEffect, useState } from 'react'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
import ReactGA from 'react-ga4'
import env from './../../environments/environment'
import appConstants from './../../constants/appConstants'
import VideoPlayerModal from './../VideoPlayerModal/VideoPlayerModal.component'
import CareerRequestPopupModal from './../modals/CareerRequestModal/CareerRequestModal.component'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import {
  phoneToView,
  phoneToStorage,
} from './../../shared/formatters/telephone.formatter'
import './Navbar.scss'

const NAV_LINKS = {
  CAREERS: {
    LEARN_MORE: 'https://careers.usabg.com/usabgcareers',
    OPPORTUNITY_VIDEO:
      'https://player.vimeo.com/video/729389367?h=500b5be574&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    AGENT_TESTIMONIALS: 'https://vimeo.com/showcase/8843553',
  },
}

const MARKETING_PHONE_NUMBER = '13522512476'

const navbarElements = {}

class NavbarStore {
  init = null
  contactData = {}

  isHidingNavbar = false
  isAnimating = false
  isSidebarOpen = false

  showOppVideo = false
  careerModalIsOpen = false

  $elements = {}

  initialize = async ($elements) => {
    if ($elements)
      Object.keys($elements).forEach(
        (el) => (navbarElements[el] = $elements[el])
      )

    let sticky = navbarElements?.$navbar && navbarElements.$navbar?.offsetTop
    window.onscroll = () => this.onScroll(sticky)
  }

  onScroll = (sticky) => {
    let shouldBeHidden = this.getShouldBeHidden(sticky)
    if (shouldBeHidden && !this.isHidingNavbar) this.hideNavbar()
    else if (!shouldBeHidden && this.isHidingNavbar) this.showNavbar()
  }

  hideNavbar = () => {
    const close = () => {
      this.isHidingNavbar = true

      if (navbarElements?.$navbar?.classList?.contains('sticky') === false)
        navbarElements.$navbar.classList.add('sticky')

      this.isAnimating = false
    }

    if (this.isSidebarOpen) {
      this.isAnimating = true
      this.closeSidebar()
      setTimeout(() => {
        close()
      }, 300)
    } else if (!this.isAnimating) {
      close()
    }
  }

  showNavbar = () => {
    this.isHidingNavbar = false

    if (navbarElements?.$navbar?.classList?.contains('sticky'))
      navbarElements.$navbar.classList.remove('sticky')
  }

  openSidebar = () => {
    this.isSidebarOpen = true

    if (
      navbarElements?.$toggleBtn &&
      navbarElements.$toggleBtn.classList.contains('active') === false
    )
      navbarElements.$toggleBtn.classList.add('active')
    if (
      navbarElements.$mobileMenu &&
      !navbarElements.$mobileMenu.classList.contains('active')
    )
      navbarElements.$mobileMenu.classList.add('active')
  }

  toggleSidebar = () => {
    if (this.isSidebarOpen) return this.closeSidebar()
    this.openSidebar()
  }

  closeSidebar = () => {
    this.isSidebarOpen = false

    if (
      navbarElements?.$toggleBtn &&
      navbarElements.$toggleBtn.classList.contains('active')
    )
      navbarElements.$toggleBtn.classList.remove('active')
    if (
      navbarElements?.$mobileMenu &&
      navbarElements.$mobileMenu.classList.contains('active')
    )
      navbarElements.$mobileMenu.classList.remove('active')
  }

  getShouldBeHidden = (sticky) => {
    return (
      window.pageYOffset >=
      (!isNaN(parseInt(sticky)) ? parseInt(sticky) + 50 : 0)
    )
  }

  constructor() {
    makeAutoObservable(this)
  }
}

const Store = new NavbarStore()

const Navbar = ({ phone }) => {
  const [productMenuOpened, setProductMenuOpened] = useState(false)
  const [careerMenuOpened, setCareerMenuOpened] = useState(false)

  useEffect(() => {
    Store.initialize({
      $navbar: document.getElementById('stickyNavbar'),
      $toggleBtn: document.getElementById('toggler-btn'),
      $mobileMenu: document.getElementById('menuBar'),
      $asideToggle: document.getElementById('asideToggle'),
      $asideToggleBtn: document.getElementById('asideToggleBtn'),
      $subMenu1: document.querySelector('.rd-navbar-submenu1'),
      $subMenu2: document.querySelector('.rd-navbar-submenu2'),
    })
  }, [])

  const getPhone = () => {
    if (phone) return phone
    if (phone === false) return ''
    return MARKETING_PHONE_NUMBER
  }

  const onCtaClick = (id, event) => {
    console.log(`'Get Your Quote!' CTA.${id} click event`, event)

    try {
      ReactGA.event({
        category: 'Marketing CTA',
        action: 'Call Button Clicked',
        label: `'Get Your Quote!' (CTA.${id})`,
      })
    } catch (ex) {
      console.error(`Failed to send ga.event for cta click.`)
    }
  }

  const phoneRaw = phoneToStorage(getPhone()),
    phoneView = phoneToView(getPhone())

  return (
    <>
      <header className="page-head">
        <div className="rd-navbar-wrap" style={{ height: '50px' }}>
          <nav
            data-layout="rd-navbar-fixed"
            data-sm-layout="rd-navbar-fixed"
            data-md-device-layout="rd-navbar-fixed"
            data-lg-layout="rd-navbar-static"
            data-lg-device-layout="rd-navbar-static"
            data-stick-up-clone="false"
            data-md-stick-up-offset="53px"
            data-lg-stick-up-offset="53px"
            data-md-stick-up="true"
            data-lg-stick-up="true"
            className="rd-navbar rd-navbar-corporate-light rd-navbar-original rd-navbar-static"
          >
            <div id="stickyNavbar">
              <div className="rd-navbar-inner">
                <div className="rd-navbar-group bg-white">
                  <div className="rd-navbar-panel">
                    <button
                      onClick={Store.toggleSidebar}
                      data-rd-navbar-toggle=".rd-navbar-nav-wrap"
                      className="rd-navbar-toggle toggle-original"
                      id="toggler-btn"
                    >
                      <span></span>
                    </button>
                    <Link to="/" className="rd-navbar-brand brand">
                      <img
                        alt="USABG"
                        src={appConstants.logos.plain.md}
                        className="mainLogo img-fluid"
                      />
                      <div className="rd-navbar-statement">
                        A non-government site powered by Richard Banville LLC, a
                        health insurance agency.
                      </div>
                    </Link>
                    {getPhone() ? (
                      <div className="cta-container">
                        <div className="cta-wrapper">
                          <a
                            className="d-sm-none d-md-none d-lg-none d-xl-none"
                            href={`tel:${phoneRaw}`}
                            data-phone-standard={`"${phoneRaw}"`}
                            data-phone-visual={`"${phoneView}"`}
                            onClick={(event) => onCtaClick('A1', event)}
                          >
                            &nbsp;Call a Licensed Agent!&nbsp;
                            <wbr />
                            <span className="phone-number">{phoneView}</span>
                          </a>
                          <a
                            className="d-none d-sm-inline d-md-inline d-lg-inline d-xl-inline"
                            href={`tel:${phoneRaw}`}
                            data-phone-standard={`"${phoneRaw}"`}
                            data-phone-visual={`"${phoneView}"`}
                            onClick={(event) => onCtaClick('A1', event)}
                          >
                            &nbsp;Call a Licensed Agent!&nbsp;
                            <wbr />
                            <span className="phone-number">{phoneView}</span>
                          </a>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    className="rd-navbar-nav-wrap toggle-original-elements"
                    id="menuBar"
                  >
                    <div className="rd-navbar-nav-inner">
                      <ul className="rd-navbar-nav">
                        <li></li>
                        <li className="active">
                          <Link to="/">Home</Link>
                        </li>
                        {/*
                        <li>
                          <HashLink to="/#about-us">About Us</HashLink>
                        </li>
                        */}
                        <li
                          className={`rd-navbar--has-dropdown rd-navbar-submenu rd-navbar-submenu1 ${
                            productMenuOpened ? 'opened' : ''
                          }`}
                          onClick={(event) => {
                            setProductMenuOpened(!productMenuOpened)
                            setCareerMenuOpened(false)
                          }}
                        >
                          <HashLink to="/#">Products</HashLink>
                          <ul className="rd-navbar-dropdown rd-navbar-dropdown1 rd-navbar-open-right">
                            <li>
                              <HashLink to="/#quote-health-insurance">
                                Health Insurance
                              </HashLink>
                            </li>
                            <li>
                              <HashLink to="/#quote-life-insurance">
                                Life Insurance
                              </HashLink>
                            </li>
                            <li>
                              <HashLink to="/#quote-senior-insurance">
                                Senior Insurance
                              </HashLink>
                            </li>
                            <li>
                              <HashLink to="/#quote-small-biz-insurance">
                                Small Business Insurance
                              </HashLink>
                            </li>
                          </ul>
                          <span className="rd-navbar-submenu-toggle"></span>
                        </li>

                        <li
                          className={`rd-navbar--has-dropdown rd-navbar-submenu rd-navbar-submenu2 ${
                            careerMenuOpened ? 'opened' : ''
                          }`}
                          onClick={(event) => {
                            setCareerMenuOpened(!careerMenuOpened)
                            setProductMenuOpened(false)
                          }}
                        >
                          <HashLink to="/#">Careers</HashLink>
                          <ul className="rd-navbar-dropdown rd-navbar-dropdown2 rd-navbar-open-right">
                            <li>
                              <Link
                                to="#"
                                onClick={() => (Store.careerModalIsOpen = true)}
                              >
                                Learn More
                              </Link>
                            </li>
                            {/*
                              <li onClick={() => (Store.showOppVideo = true)}>
                                <HashLink to="/#opportunityVideo">
                                  Opportunity Video
                                </HashLink>
                              </li>
                            */}
                            <li>
                              <Link
                                to={{
                                  pathname:
                                    NAV_LINKS.CAREERS.AGENT_TESTIMONIALS,
                                }}
                                target="_BLANK"
                                rel="noopener noreferrer"
                              >
                                Agent Testimonials
                              </Link>
                            </li>
                          </ul>
                          <span className="rd-navbar-submenu-toggle"></span>
                        </li>
                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>
                        <li>
                          <Link to="/login">Login</Link>
                        </li>

                        <li className="supplemental">
                          <div>
                            <img
                              src={`https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Flogos%2Flogo.bbb.png?alt=media`}
                              className="bbb"
                              alt=""
                            />
                          </div>
                          <div className="yearsField">
                            <img
                              src={appConstants.badges.experience}
                              className="years"
                              alt=""
                            />
                          </div>
                          {getPhone() ? (
                            <div className="cta-wrapper">
                              <a
                                className="d-sm-none d-md-none d-lg-none d-xl-none"
                                href={`tel:${phoneRaw}`}
                                data-phone-standard={`"${phoneRaw}"`}
                                data-phone-visual={`"${phoneView}"`}
                                onClick={(event) => onCtaClick('B1', event)}
                              >
                                &nbsp;Call a Licensed Agent!&nbsp;
                                <wbr />
                                <span className="phone-number">
                                  {phoneView}
                                </span>
                              </a>
                              <a
                                className="d-none d-sm-inline d-md-inline d-lg-inline d-xl-inline"
                                href={`mailto:referrals@teambanville.com`}
                                data-phone-standard={`"${phoneRaw}"`}
                                data-phone-visual={`"${phoneView}"`}
                                onClick={(event) => onCtaClick('B2', event)}
                              >
                                &nbsp;Call a Licensed Agent!&nbsp;
                                <wbr />
                                <span className="phone-number">
                                  {phoneView}
                                </span>
                              </a>
                            </div>
                          ) : (
                            <></>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <CareerRequestPopupModal
          isOpen={Store.careerModalIsOpen}
          onClose={() => {
            Store.careerModalIsOpen = false
          }}
        />
        <VideoPlayerModal
          open={!!Store.showOppVideo}
          onClose={() => {
            Store.showOppVideo = false
          }}
          videoUrl={NAV_LINKS.CAREERS.OPPORTUNITY_VIDEO}
          videoTitle={'USABG Opportunity Video'}
        />
      </header>
      <div className="header-bottom-margin" />
    </>
  )
}

export default observer(Navbar)
