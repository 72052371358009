import React from 'react'
import { observer } from 'mobx-react-lite'
import LoadingSpinner from './../../../../components/shared/LoadingSpinner.component'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import moment from 'moment'
import { TermFactory, UserTermFactory } from './../../../../shared/factories'
import { makeAutoObservable } from 'mobx'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCollapse,
  MDBIcon,
  MDBBtn,
} from 'mdbreact'
import { getIp } from './../../../../shared/utilities/getIp.function'

import './TermsReviewer.component.scss'

const fetchTerms = async () => {
  try {
    return (
      await TermFactory.search({
        search: { active: 1, is_10x: 1, is_hidden: 0 },
        order_by: { id: 'DESC' },
        pagination: { per_page: 1, page: 0 },
      })
    )?.models.shift()
  } catch (ex) {
    return false
  }
}

const fetchUserTerms = async (userId, termId) => {
  try {
    return (
      await UserTermFactory.search({
        search: { user_id: userId, term_id: termId },
      })
    )?.models.shift()
  } catch (ex) {
    return false
  }
}

const hasSigned = (UserTerm) => !!(UserTerm && UserTerm.get('userterm_file'))

class TermsStore {
  constructor() {
    makeAutoObservable(this)
  }

  isFetching = false
  isSigning = false
  Term = null
  UserTerm = null
  hasSigned = false

  hasAccepted = () => !!this.UserTerm

  hasAcceptedAndSigned = () =>
    this.hasAccepted() && !!this.UserTerm?.get('userterm_file')

  fetch = async (userId) => {
    this.isFetching = true
    this.Term = await fetchTerms()
    this.UserTerm = this.Term
      ? await fetchUserTerms(userId, this.Term.id())
      : false
    this.hasSigned = hasSigned(this.UserTerm)
    this.isFetching = false
  }

  acceptAndSign = async () => {
    this.isSigning = true

    if (!this.UserTerm) {
      this.UserTerm = UserTermFactory.create({
        user_id: UserProfileService.getUserId(),
        term_id: this.Term.id(),
        userterm_term: this.Term.get('term_name'),
        userterm_user: [
          UserProfileService.get('u_fname'),
          UserProfileService.get('u_lname'),
        ]
          .map((n) => n && `${n}`.trim())
          .filter((n) => !!n)
          .join(' ')
          .trim(),
        userterm_signature: [
          UserProfileService.get('u_fname'),
          UserProfileService.get('u_lname'),
        ]
          .map((n) => n && `${n}`.trim())
          .filter((n) => !!n)
          .join(' ')
          .trim(),
      })
      await this.UserTerm.save()

      if (await this.UserTerm.sign(await getIp()))
        this.hasSigned = hasSigned(this.UserTerm)
    }

    this.isSigning = false
  }

  sign = async () => {
    this.isSigning = true
    if (this.UserTerm) {
      if (await this.UserTerm.sign(await getIp()))
        this.hasSigned = hasSigned(this.UserTerm)
    }

    this.isSigning = false
  }
}

const BeastModeTermsStore = new TermsStore()

const renderAcceptance = (onAccepted) => {
  return (
    <MDBBtn
      disabled={BeastModeTermsStore.isFetching || BeastModeTermsStore.isSigning}
      onClick={() => {
        BeastModeTermsStore.acceptAndSign()
        onAccepted()
      }}
    >
      {BeastModeTermsStore.isSigning ? (
        <i className="fa fa-spin fa-spinner"></i>
      ) : (
        <MDBIcon icon="file-signature" />
      )}
      <br />
      Accept & Sign up for BeastMode 10x CRM!
    </MDBBtn>
  )
}

const renderSignButton = (onAccepted) => {
  return (
    <MDBBtn
      disabled={BeastModeTermsStore.isFetching || BeastModeTermsStore.isSigning}
      onClick={() => {
        BeastModeTermsStore.sign()
        onAccepted()
      }}
    >
      {BeastModeTermsStore.isSigning ? (
        <i className="fa fa-spin fa-spinner"></i>
      ) : (
        <MDBIcon icon="file-signature" />
      )}
      <br />
      Accept & Sign up for BeastMode 10x CRM!
    </MDBBtn>
  )
}

const TermsReviewer = ({ onCancel, onAccepted }) => {
  const showSpinner = !!(
    BeastModeTermsStore.isFetching ||
    !BeastModeTermsStore.Term ||
    !BeastModeTermsStore.Term.get('term_link')
  )

  return (
    <MDBContainer id="TermsReviewerComponent">
      <MDBRow>
        <MDBCol>
          <div className="content-wrapper">
            <MDBCollapse isOpen={showSpinner}>
              <div
                className={`terms-loading-wrapper ${
                  showSpinner ? 'is-loading' : ''
                }`}
              >
                <LoadingSpinner size="md" isActive={true} />
              </div>
            </MDBCollapse>
            <MDBCollapse isOpen={!showSpinner}>
              {BeastModeTermsStore.Term &&
              BeastModeTermsStore.Term?.get('term_link') ? (
                <>
                  <MDBRow className="mb-4 accept-and-sign">
                    <MDBCol size="12" sm="6" className="text--left">
                      BeastMode 10x CRM Terms
                      <br />
                      <a
                        href="/beast-mode-signup"
                        onClick={(event) => {
                          event.preventDefault()
                          onCancel()
                          return false
                        }}
                      >
                        <small>Back to Packages</small>
                      </a>
                    </MDBCol>
                    <MDBCol size="12" sm="6" className="text--right">
                      {BeastModeTermsStore.hasAcceptedAndSigned() ? (
                        <ul>
                          <li>
                            Accepted By:{' '}
                            {BeastModeTermsStore.UserTerm.get(
                              'userterm_signature'
                            )}
                          </li>
                          <li>
                            Accepted At:{' '}
                            {moment(
                              BeastModeTermsStore.UserTerm.get(
                                'userterm_updated'
                              )
                            ).format('MM/DD/YYYY, h:mm a')}
                          </li>
                          <li>
                            Accepted IP:{' '}
                            {BeastModeTermsStore.UserTerm.get('userterm_ip')}
                          </li>
                          <li>
                            <MDBBtn
                              disabled={
                                BeastModeTermsStore.isFetching ||
                                BeastModeTermsStore.isSigning
                              }
                              onClick={() => onAccepted()}
                            >
                              Go to Signup
                            </MDBBtn>
                          </li>
                        </ul>
                      ) : !BeastModeTermsStore.hasAccepted() ? (
                        renderAcceptance(onAccepted)
                      ) : (
                        renderSignButton(onAccepted)
                      )}
                    </MDBCol>
                    <MDBCol size="12">
                      <iframe
                        title="USA Benefits Group Leader Guidelines"
                        src={BeastModeTermsStore.Term?.get('term_link')}
                        width="100%"
                        height="1200px"
                        frameBorder="0"
                      ></iframe>
                    </MDBCol>
                  </MDBRow>
                </>
              ) : (
                <></>
              )}
            </MDBCollapse>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

const BeastModeTermsReviewer = observer(TermsReviewer)

export { BeastModeTermsStore, BeastModeTermsReviewer }
