import React, { Component, Fragment } from 'react';
import DashboardLayout from "../../components/Admin/DashboardLayout.jsx";
import {MDBBtn, MDBContainer, MDBDataTable, MDBIcon, MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import {toast} from "react-toastify";
import AdminApi from "../../api/admin-api/admin-api.js";


class Coverages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coveragesList: '',
            loading: '',
            error: '',
            modal: false,
            confirmModal: false,
            viewdata: '',
            data: {
                columns: [
                    {
                        label: 'Name',
                        field: 'name',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Active',
                        field: 'active',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Include In Incentive Reports',
                        field: 'incentive',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Update',
                        field: 'update',
                        sort: 'disabled',
                        width: 270
                    },
                    {
                        label: 'Delete',
                        field: 'delete',
                        sort: 'disabled',
                        width: 270
                    }
                ],
                rows : [],
            }
        } // state end
    }


    componentDidMount() {
        this.fetchCoveragesData();
    }

    //convert date from sec to current date
    toDateTime = (secs) => {
        let t = new Date(1970, 0, 1); // Epoch
        t.setSeconds(secs);
        return t;
    }

    //toggle modal
    toggle = () => {
        this.setState({modal: !this.state.modal});
    };

    //calling delete confirm modal
    confirm_DeleteCoverage = (data) => {
        this.confirm_toggle(data);
    }

    //Toggle delete confirm modal
    confirm_toggle = (data = {}) => {
        this.setState({confirmModal: !this.state.confirmModal, ...data});
    }

    //forming rows for data table from fetched data
    setRows = () => {
        const {coveragesList} = this.state;
        if(coveragesList && coveragesList.length) {
            let rows = coveragesList.map((row) => {
                const {coverage_name, coverage_active, coverage_incentive, id} = row;
                const self = this;

                function editCoverage() {
                    self.props.history.push(`/add-coverage/${id}/edit`);
                }

                function deleteCoverage() {
                    self.confirm_DeleteCoverage({viewdata: row});
                }

                const editClick = (
                    <div className={'d-flex align-items-center justify-content-center'}>
                        <div className={'cursor-pointer d-inline-block'} role={'button'} onClick={editCoverage}>
                            <MDBIcon icon="edit" size="2x" className="blue-text p-1" />
                        </div>
                    </div>
                );

                const deleteClick = (
                    <div className={'d-flex align-items-center justify-content-center'}>
                        <div className={'cursor-pointer d-inline-block'} role={'button'} onClick={deleteCoverage}>
                            <MDBIcon icon="trash" size="2x" className="red-text p-1" />
                        </div>
                    </div>
                );

                return {
                    name: coverage_name ? coverage_name : 'N/A',
                    active: coverage_active ? 'Yes' : 'No',
                    incentive: coverage_incentive ? 'Yes' : 'No',
                    update: editClick,
                    delete: deleteClick,
                }
            });
            this.setState({
                data: {...this.state.data, rows: rows}
            });
        }
    }

    //Fetch data
    fetchCoveragesData = async () => {
        this.setState({loading: true});
        AdminApi.getCoverages().then((result) => {
            this.setState({coveragesList: result.data.data || []});
        }).then(() => {
            this.setState({loading: false});
            this.setRows();
        }).catch(error => {
            this.setState({loading: false});
            toast.error(error.message, {position: toast.POSITION.TOP_RIGHT});
        });
    }

    //Delete data
    _DeleteCoverage = async (id) => {
        this.setState({deleting: true});
        const data = {type: "coverages", id: id};

        AdminApi.deleteCoverage(data).then((result) => {
            this.setState({deleting: false});
            this.confirm_toggle();
            toast.success("Coverage successfully deleted", {position: toast.POSITION.TOP_RIGHT});
            this.fetchCoveragesData();
        }).catch(error => {
            this.setState({deleting: false});
            toast.error(`${error.message}`, {position: toast.POSITION.TOP_RIGHT});
        });
    }


    render() {
        toast.configure();
        const{data, confirmModal, loading} = this.state;

        const createNew = () => {
            this.props.history.push('/add-coverage');
        };

        return (
            <Fragment>
                <DashboardLayout>
                <main className="mainSection pb-5">
                    <MDBContainer fluid className="mt-5">
                        <h2>Coverages
                            <div className="d-inline-block">
                                <MDBBtn tag="a" floating gradient="blue" title={'Add New Coverage'} onClick={createNew}>
                                    <MDBIcon icon="plus" />
                                </MDBBtn>
                            </div>
                            <MDBBtn tag="a" color={'indigo'} title={'Products'} className={'float-right'} onClick={() => {this.props.history.push('/admin-products')}}>
                                Products
                            </MDBBtn>
                            <MDBBtn tag="a" color={'indigo'} title={'Products'} className={'float-right'} onClick={() => {this.props.history.push('/admin-carriers')}}>
                                Carriers
                            </MDBBtn>
                          {/*<a href="admin-products"><MDBBtn color="indigo" className="float-right">Products</MDBBtn></a>
                          <a href="admin-carriers"><MDBBtn color="indigo" className="float-right">Carriers</MDBBtn></a>*/}
                        </h2>
                        <hr />

                        {loading ?
                            <h4 className={'p4'}>Loading...</h4> :
                            <div className="content-wrapper"><MDBDataTable striped bordered small data={data} className="text-capitalize" /></div>
                        }

                        <MDBModal size={"sm"} isOpen={confirmModal} toggle={this.confirm_toggle}>
                            <MDBModalHeader toggle={this.confirm_toggle}>
                                Delete Coverage
                            </MDBModalHeader>
                            <MDBModalBody>
                                <div className="h5">Are you sure you want to delete this Coverage?</div>
                                <MDBBtn color="secondary" size={'sm'} onClick={this.confirm_toggle}>
                                    Close
                                </MDBBtn>
                                <MDBBtn color="danger" size={'sm'} onClick={() => {this._DeleteCoverage(this.state.viewdata.id)}}>
                                    {this.state.deleting ? 'Deleting...' : 'Delete'}
                                </MDBBtn>
                            </MDBModalBody>
                        </MDBModal>
                    </MDBContainer>
                </main>
                </DashboardLayout>
            </Fragment>
          );
    }
}

export default Coverages;
