import React, { Component, Fragment } from 'react'
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBSpinner,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from 'mdbreact'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'
import appConstants from '../../constants/appConstants'
import { SketchPicker } from 'react-color'

import './AddCalendar.scss'

class AddCalendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      saving: false,
      calendar: '',
      cal_id: '',
      cal_name: '',
      // cal_url: '',
      cal_provider: '',
      // cal_email: '',
      cal_timezone: '',
      // cal_dev_key: '',
      cal_color: '#9095aa',
      errorMessage: '',
      formError: false,
    }
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params })

    if (params && params.id) {
      this.fetchCalendarData(params.id)
    }
  }

  //Change event for input fields
  onChange = (event) =>
    this.setState({ [event.target.name]: event.target.value })

  GoogleTimezone = appConstants.GoogleTimezone
  GoogleTimezonesList =
    this.GoogleTimezone.length > 0 &&
    this.GoogleTimezone.map((item, i) => {
      return (
        <option key={i} value={`${item.name}`}>
          {item.name}
        </option>
      )
    })

  validate = async () => {
    const { cal_name, cal_id, cal_timezone } = this.state,
      regHttpLink = /^(http(s)?:\/\/).*/i,
      regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

    if (!cal_name) {
      this.setState({ formError: true, errorMessage: 'Calendar name required' })
      return
    }

    /*
				const regLink 		= 		/^((http(s)?:\/\/)(www\.)?)+[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/g;
        if (!regLink.test(cal_url)) {
          this.setState({formError: true, errorMessage: 'Invalid URL'});
          return;
        }
        */

    // if (!(cal_url && cal_url.trim())) {
    //   this.setState({
    //     formError: true,
    //     errorMessage: 'Calendar Link Required!',
    //   })
    //   return
    // }

    // if (cal_url && !regHttpLink.test(cal_url)) {
    //   const { cal_url } = this.state
    //   this.setState({ cal_url: `http://${cal_url}` })
    // }

    // if (!cal_email) {
    //   this.setState({
    //     formError: true,
    //     errorMessage: 'Calendar Email required',
    //   })
    //   return
    // }
    // if (!regEmail.test(cal_email)) {
    //   this.setState({ formError: true, errorMessage: 'Invalid Email format' })
    //   return
    // }

    if (!cal_id) {
      this.setState({ formError: true, errorMessage: 'Please Set Calendar ID' })
      return
    }

    if (!cal_timezone) {
      this.setState({ formError: true, errorMessage: 'Please Select Timezone' })
      return
    }
    // if (!cal_dev_key) {
    //   this.setState({ formError: true, errorMessage: 'Calendar Key required' })
    //   return
    // }

    this.setState({ formError: false, errorMessage: '' })
  }

  //create calendar
  createCalendar = async () => {
    await this.validate()
    const {
      cal_name,
      // cal_url,
      cal_provider,
      cal_id,
      // cal_email,
      cal_timezone,
      // cal_dev_key,
      cal_color,
      errorMessage,
      formError,
      loading,
    } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }
    const data = {
      cal_name: cal_name,
      // cal_url: cal_url,
      cal_provider: cal_provider,
      cal_id: cal_id,
      // cal_email: cal_email,
      cal_timezone: cal_timezone,
      // cal_dev_key: cal_dev_key,
      cal_color: cal_color,
      table_name: 'calendars',
    }

    let form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })
    this.setState({ saving: true })

    AdminApi.createCalendar(form_data)
      .then((result) => {
        this.setState({ loading: false })
        this.setState({ saving: false })
        this.props.history.push('/admin-calendar')
        toast.success('Calendar created successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        this.setState({ saving: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchCalendarData = async (id) => {
    this.setState({ loading: true })
    AdminApi.getCalendarById(id)
      .then((result) => {
        this.setState({ loading: false })
        if (result && result.data && result.data.data) {
          const content = result.data.data

          this.setState({
            cal_name: content.cal_name,
            // cal_url: content.cal_url,
            cal_provider: content.cal_provider,
            cal_id: content.cal_id,
            // cal_email: content.cal_email,
            cal_timezone: content.cal_timezone,
            // cal_dev_key: content.cal_dev_key,
            cal_color: content.cal_color,
          })
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  updateCalendar = async () => {
    await this.validate()
    const id = this.state.urlParams.id
    const {
      cal_name,
      // cal_url,
      cal_provider,
      cal_id,
      // cal_email,
      cal_timezone,
      // cal_dev_key,
      cal_color,
      errorMessage,
      formError,
      loading,
    } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }
    const data = {
      cal_name: cal_name,
      cal_provider: cal_provider,
      cal_id: cal_id,
      // cal_email: cal_email,
      cal_timezone: cal_timezone,
      // cal_dev_key: cal_dev_key,
      cal_color: cal_color,
      table_name: 'calendars',
      id: id,
    }

    let form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })
    this.setState({ saving: true })
    AdminApi.createCalendar(form_data)
      .then((result) => {
        this.setState({ loading: false })
        this.setState({ saving: false })
        this.props.history.push('/admin-calendar')
        toast.success('Calendar updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        this.setState({ saving: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  render() {
    toast.configure()
    const { urlParams, loading, cal_color } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>
                  <a href="/admin-calendar">List</a>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>
                  {!!(urlParams && urlParams.id)
                    ? 'Edit Calendar'
                    : 'Add Calendar'}
                </MDBBreadcrumbItem>
              </MDBBreadcrumb>
              <h2>
                {!!(urlParams && urlParams.id)
                  ? 'Edit Calendar'
                  : 'Add Calendar'}
              </h2>
              <hr />

              {loading ? (
                <div className="loadingOverlay">
                  <h4 className={'loader-text p4'}>
                    <MDBSpinner />
                  </h4>
                </div>
              ) : (
                ''
              )}
              <MDBRow>
                <MDBCol size="6">
                  <form className="content-wrapper">
                    <div className="form-row">
                      <div className="form-group col-md-9">
                        <label className="grey-text" htmlFor="cal_name">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="cal_name"
                          id="cal_name"
                          value={this.state.cal_name}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label className="grey-text" htmlFor="cal_color">
                          Calendar Color
                        </label>
                        <MDBDropdown dropup className="cal-color-picker">
                          <MDBDropdownToggle className="p-0 m-0 rounded">
                            <div
                              style={{ backgroundColor: cal_color }}
                              className="p-2 rounded"
                            >
                              {cal_color}
                            </div>
                          </MDBDropdownToggle>
                          <MDBDropdownMenu basic className="p-0">
                            <SketchPicker
                              color={cal_color}
                              onChange={(v) =>
                                this.onChange({
                                  target: { name: 'cal_color', value: v.hex },
                                })
                              }
                            />
                          </MDBDropdownMenu>
                        </MDBDropdown>
                      </div>
                    </div>

                    <div className="form-row">
                      {/*                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="cal_email">
                          Calendar Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="cal_email"
                          id="cal_email"
                          value={this.state.cal_email}
                          onChange={this.onChange}
                        />
                      </div>*/}
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="cal_provider">
                          Calendar Provider
                        </label>
                        <select
                          className="form-control"
                          name="cal_provider"
                          id="cal_provider"
                          value={this.state.cal_provider}
                          onChange={this.onChange}
                        >
                          <option value="office365">Office 365</option>
                          <option value="google">Google</option>
                        </select>
                      </div>
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="cal_timezone">
                          Timezone
                        </label>
                        <select
                          className="form-control"
                          name="cal_timezone"
                          id="cal_timezone"
                          value={this.state.cal_timezone}
                          onChange={this.onChange}
                        >
                          {this.GoogleTimezonesList}
                        </select>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label className="grey-text" htmlFor="cal_id">
                          Calendar ID
                        </label>
                        <textarea
                          className="form-control"
                          name="cal_id"
                          id="cal_id"
                          value={this.state.cal_id}
                          onChange={this.onChange}
                        ></textarea>
                      </div>
                      {/*                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="cal_dev_key">
                          Calendar Key
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="cal_dev_key"
                          id="cal_dev_key"
                          value={this.state.cal_dev_key}
                          onChange={this.onChange}
                        />
                      </div>*/}
                    </div>

                    <div className="form-row text-center mt-4">
                      <MDBBtn
                        color="unique"
                        type="button"
                        className="btn-block"
                        onClick={
                          this.state.urlParams && this.state.urlParams.id
                            ? this.updateCalendar
                            : this.createCalendar
                        }
                      >
                        {this.state.saving ? 'Saving...' : 'Save'}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AddCalendar
