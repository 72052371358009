import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout.jsx'
import { MDBContainer, MDBDataTable, MDBIcon } from 'mdbreact'
import { toast } from 'react-toastify'

class AdminReports extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  render() {
    toast.configure()

    const data = {
      columns: [
        {
          label: 'Report Name',
          field: 'name',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'View Report',
          field: 'view',
          sort: 'asc',
          width: 270,
        },
      ],
      rows: [
        {
          name: 'AV Applications',
          view: (
            <a href="reports-avapps">
              <MDBIcon
                icon="binoculars"
                size="2x"
                className="d-flex justify-content-center blue-text"
              />
            </a>
          ),
        },
        // {
        //   name: 'Agents By States',
        //   view: (
        //     <a href="">
        //       <MDBIcon icon="binoculars" size="2x" className="d-flex justify-content-center blue-text" />
        //     </a>
        //   )
        // },
        {
          name: 'Factors Milestones',
          view: (
            <a href="factor-milestones">
              <MDBIcon
                icon="binoculars"
                size="2x"
                className="d-flex justify-content-center blue-text"
              />
            </a>
          ),
        },
        {
          name: 'GoTo Meeting',
          view: (
            <a href="gotomeetings">
              <MDBIcon
                icon="binoculars"
                size="2x"
                className="d-flex justify-content-center blue-text"
              />
            </a>
          ),
        },
        // {
        //   name: 'USABG Signature Producer Agreement',
        //   view: (
        //     <a href="">
        //       <MDBIcon icon="binoculars" size="2x" className="d-flex justify-content-center blue-text" />
        //     </a>
        //   )
        // },
        // {
        //   name: 'USABG Signature Producer E&O Program',
        //   view: (
        //     <a href="">
        //       <MDBIcon icon="binoculars" size="2x" className="d-flex justify-content-center blue-text" />
        //     </a>
        //   )
        // },
        // {
        //   name: 'USABG Signature Non-Captive',
        //   view: (
        //     <a href="">
        //       <MDBIcon icon="binoculars" size="2x" className="d-flex justify-content-center blue-text" />
        //     </a>
        //   )
        // },
        {
          name: 'User Reports',
          view: (
            <a href="user-reports">
              <MDBIcon
                icon="binoculars"
                size="2x"
                className="d-flex justify-content-center blue-text"
              />
            </a>
          ),
        },
        {
          name: 'UserType Reports',
          view: (
            <a href="usertype-reports">
              <MDBIcon
                icon="binoculars"
                size="2x"
                className="d-flex justify-content-center blue-text"
              />
            </a>
          ),
        },
      ],
    }

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <h2>Admin Reports</h2>
              <hr />
              <div className="content-wrapper">
                <MDBDataTable
                  striped
                  bordered
                  small
                  order={['name', 'asc']}
                  data={data}
                />
              </div>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AdminReports
