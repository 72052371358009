import { BehaviorSubject } from 'rxjs';
import UserProfileService from "./../../../shared/services/UserProfile.service";
import UserService from "./../../../shared/services/User.service";
import AdminApi from './../../../api/admin-api/admin-api';

const prefixPattern = /^js:#/

const usabgEmailStatusSubject = new BehaviorSubject(null);
const deniedAccessEventSubject = new BehaviorSubject(null);

const setupUsabgEmail = async (args) => 
{
	// 1. Must NOT have a USABG email already.
	if (checkUsabgEmailExists()) return;

	// 2. Must be a Sig Agent or higher.
	// 3. Must not be a USABG corporate employee (non-agent).
	if (UserProfileService.isA(['affiliate-group','associate-group']) || !UserProfileService.isA(['agent']))
		return usabgEmailStatusSubject.next("You must be a Signature Agent or Higher to request your USABG corporate email.");

	// 4. Create new USABG email.
	try {
		UserProfileService.set('u_company_email', (await UserService.requestCorporateEmail(UserProfileService.getUserId()))?.u_company_email);
		return usabgEmailStatusSubject.next('success');
	}
	catch (ex) {
		return usabgEmailStatusSubject.next(`${ex}`.trim().replace(/(failed|error):( )?/ig,'')+" Please contact Broker Support for further assistance.");
	}
}

const denyAffiliateAgent = async (args) => 
{
	args.denial = "Affiliate Access Denied";
	deniedAccessEventSubject.next(args);
}

const denyAssociateAgent = async (args) => 
{
	args.denial = "Associate Access Denied";
	deniedAccessEventSubject.next(args);
}

const customHandlers = {
	setupusabgemail:  						setupUsabgEmail,
	affiliatedenied:  						denyAffiliateAgent,
	associatedenied: 							denyAssociateAgent
};

const checkUsabgEmailExists = () =>
{
	const emailExists = userDetails => 
		(userDetails ? [userDetails.u_email, userDetails.u_company_email, userDetails.u_quote_email].filter(n => n && /@usabg\.(com|net)$/.test(`${n}`)).length > 0 : false);

	let userDetails 	= 	UserProfileService.getUserDetails(),
			status 				= 	emailExists(userDetails) ? 'exists' : null;

	usabgEmailStatusSubject.next(status);

	return status === 'exists';
}

const isCustomHandler = method =>
	method && customHandlers.hasOwnProperty(`${method}`.trim().toLowerCase().replace(prefixPattern,''));

const runCustomHandler = async (method, args) =>
	customHandlers[`${method}`.trim().toLowerCase().replace(prefixPattern,'')](args);

const TechToolsService = {
	getUsabgEmailStatus:  				() => usabgEmailStatusSubject,
	getDeniedAccessEvent: 				() => deniedAccessEventSubject,
	resetUsabgEmailStatus: 				() => usabgEmailStatusSubject.next(null),
	resetAccessDenied:  					() => deniedAccessEventSubject.next(null),
	isCustomHandler:  						isCustomHandler,
	runCustomHandler:  						runCustomHandler
}

export default TechToolsService;