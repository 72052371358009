import env from "./../environments/environment";

const stripePrices = {
	local: {
		one_time: 		'price_1JervOCB1dfLO0v796ffCcYH',
		recurring: 		'price_1JervOCB1dfLO0v7FpeQ5Idq',
		crm: 					'price_1MOYILCB1dfLO0v7aifcgevK'
	},
	beta: {
		one_time: 		'price_1JervOCB1dfLO0v796ffCcYH',
		recurring: 		'price_1JervOCB1dfLO0v7FpeQ5Idq',
		crm: 					'price_1MOYILCB1dfLO0v7aifcgevK'
	},
	production: {
		one_time: 		'price_1JAH5oCB1dfLO0v77XqfWIuV',
		recurring: 		'price_1J8qcjCB1dfLO0v7AqYYcx88'
	}
}

export default stripePrices[env.env];