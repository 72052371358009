import BaseModelFactory from './base.factory'
import UsertypeService from './../services/Usertype.service'
import Usertype from './../models/usertype.model'

class UsertypeFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: UsertypeService, Model: Usertype }
    )
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: UsertypeService, Model: Usertype }
    )
  static create = (payload) =>
    this._create(payload, { Service: UsertypeService, Model: Usertype })
}

export default UsertypeFactory
