import React from 'react'
import env from './../environments/environment'
import { makeAutoObservable, observe, toJS } from 'mobx'
import ReactGA from 'react-ga4'
import { RouteObserver } from './../core/router/RouteMatcher'
import TagManager from 'react-gtm-module'
import UserProfileService from './../shared/services/UserProfile.service'

function isSubsite(RouteDef) {
  if (RouteDef) {
    if (/\/:u_login\/:pageName\??/.test(RouteDef?.path)) return true
    if (['Subsite Enrollment', 'Subsite'].includes(RouteDef?.name)) return true
  }
  return false
}

function getTrackingId(provider) {
  return `${(env && provider && env.integrations?.[provider]?.tag) || ''}`
}

class AnalyticsStore {
  currentPath = ''
  currentPageName = ''

  trackingIds = { google_tag_manager: '', google_analytics: '', user_log: '' }
  initialized = {
    google_tag_manager: false,
    google_analytics: false,
    user_log: false,
  }

  getTrackingId(provider) {
    return this.trackingIds[provider]
  }

  setTrackingId(provider, trackingId) {
    return !!(this.trackingIds[provider] = trackingId || null)
  }

  isInitialized(provider) {
    return !!(
      this.initialized.hasOwnProperty(provider) && this.initialized[provider]
    )
  }

  initialize(provider) {
    if (this.isInitialized(provider)) return true

    if (!getTrackingId(provider)) return false

    switch (provider) {
      case 'google_analytics':
        ReactGA.initialize([{ trackingId: getTrackingId(provider) }])
        console.log('Initialized Google Analytics: ', getTrackingId(provider))
        break
      case 'google_tag_manager':
        TagManager.initialize({ gtmId: getTrackingId(provider) })
        console.log('Initialized Google Tag Manager: ', getTrackingId(provider))
        break
      case 'user_log':
        break
    }

    return (this.initialized[provider] = true)
  }

  send(provider, payload) {
    if (!this.isInitialized(provider))
      if (!this.initialize(provider)) return false

    switch (provider) {
      case 'google_analytics':
        return ReactGA.send(payload)
      case 'google_tag_manager':
        return
      case 'user_log':
        return UserProfileService.track(payload)
      default:
        return false
    }
  }

  constructor() {
    makeAutoObservable(this)
  }
}

const Store = new AnalyticsStore()

class AnalyticsEvent {
  _provider
  _RouteDef

  sendByRouteDef() {
    // Set Page Title.
    const name = this._RouteDef && this._RouteDef?.name
    document.title = 'USABG' + (name ? ` - ${name}` : '')

    // Initialize GTM, if needed.
    if (!Store.isInitialized('google_tag_manager'))
      Store.send('google_tag_manager', {})

    // Send Page View
    Store.send(this._provider, {
      hitType: 'pageview',
      page: this._RouteDef.path,
    })

    // Send Tracking Event
    Store.send('user_log', {
      event_type: 'ui.pages.loaded',
      event_descrip: this._RouteDef?.name ? `${this._RouteDef.name}` : '---',
      payload: { path: this._RouteDef.path },
    })

    Store.currentPath = this._RouteDef.path
    Store.currentPageName = this._RouteDef.name
  }

  send() {
    if (this._RouteDef) this.sendByRouteDef()
  }

  constructor(provider, { RouteDef }) {
    if (
      !['google_analytics', 'google_tag_manager', 'user_log'].includes(
        (this._provider = provider)
      )
    )
      throw new Error(
        'Invalid Analytics provider supplied in AnalyticsEvent constructor.'
      )

    this._RouteDef = RouteDef
  }
}

class AnalyticsTracker extends React.Component {
  hasPathChanged = (RouteDef) => Store.currentPath !== RouteDef?.path

  onPathChanged = (RouteDef) => {
    if (!isSubsite(RouteDef))
      return new AnalyticsEvent('google_analytics', { RouteDef }).send()
  }

  updateFromRouteDef(RouteDef) {
    if (!RouteDef) return
    if (!RouteDef.path) return

    if (this.hasPathChanged()) this.onPathChanged(RouteDef)
  }

  componentDidMount() {
    const RouteObservable = toJS(RouteObserver)
    if (RouteObservable && RouteObservable?.to)
      this.updateFromRouteDef(RouteObservable?.to)

    observe(RouteObserver, (change) =>
      this.updateFromRouteDef(change?.newValue)
    )
  }

  render() {
    return <></>
  }
}

export { AnalyticsEvent, AnalyticsTracker }
