import React from 'react'
import './Loader.scss'

const Loader = () => (
  <div className="loader-container">
    <div className={'custom-loader'}>
      <div className="loader"></div>
    </div>
  </div>
)

export default Loader
