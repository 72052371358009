import React, { Component, Fragment } from 'react'
import DashboardLayout from '../components/Admin/DashboardLayout'
import {
  MDBBtn,
  MDBContainer,
  MDBDataTable,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from 'mdbreact'
import { toast } from 'react-toastify'
import AdminApi from '../api/admin-api/admin-api'

class Divisions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      divisionsList: {},
      loading: '',
      error: '',
      modal: false,
      confirmModal: false,
      imageUrls: {},
      viewdata: '',
      data: {
        columns: [
          {
            label: 'Name',
            field: 'name',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Owner',
            field: 'owner',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Logo',
            field: 'logo',
            sort: 'disabled',
            width: 150,
          },
          {
            label: 'Email',
            field: 'email',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Carriers',
            field: 'carriers',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Update',
            field: 'update',
            sort: 'disabled',
            width: 270,
          },
          {
            label: 'Delete',
            field: 'delete',
            sort: 'disabled',
            width: 270,
          },
        ],
        rows: [],
      },
    } // state end
  }

  componentDidMount() {
    this.fetchDivisionsData()
  }

  //convert date from sec to current date
  toDateTime = (secs) => {
    let t = new Date(1970, 0, 1) // Epoch
    t.setSeconds(secs)
    return t
  }

  //toggle modal
  toggle = () => {
    this.setState({ modal: !this.state.modal })
  }

  //calling delete confirm modal
  confirm_DeleteDivision = (data) => {
    this.confirm_toggle(data)
  }

  //Toggle delete confirm modal
  confirm_toggle = (data = {}) => {
    this.setState({ confirmModal: !this.state.confirmModal, ...data })
  }

  //forming rows for data table from fetched data
  setRows = () => {
    const { divisionsList, imageUrls } = this.state
    if (divisionsList && divisionsList.length) {
      let rows = divisionsList.map((row) => {
        const { divisioncarriers, d_owner_name, d_email, d_name, d_logo, id } =
          row
        const self = this

        function editDivision() {
          self.props.history.push(`/add-division/${id}/edit`)
        }

        function deleteDivision() {
          self.confirm_DeleteDivision({ viewdata: row })
        }

        const image = d_logo ? (
          <img
            src={`${imageUrls.prefix}${d_logo}${imageUrls.suffix}`}
            className="mx-auto d-block"
            style={{ height: '50px' }}
            alt="division logo"
          />
        ) : (
          <span>N/A</span>
        )

        const editClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={editDivision}
            >
              <MDBIcon icon="edit" size="2x" className="blue-text p-1" />
            </div>
          </div>
        )

        const deleteClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={deleteDivision}
            >
              <MDBIcon icon="trash" size="2x" className="red-text p-1" />
            </div>
          </div>
        )

        return {
          name: d_name,
          // owner: `${division_owner.u_fname} ${division_owner.u_lname}`,
          owner: d_owner_name ? d_owner_name : 'N/A',
          logo: image,
          email: (
            <span searchvalue={d_email ? d_email.trim().toLowerCase() : ''}>
              {d_email ? d_email.trim() : 'N/A'}
            </span>
          ),
          carriers: divisioncarriers ? divisioncarriers.split(',').length : 0,
          update: editClick,
          delete: deleteClick,
        }
      })
      this.setState({
        data: { ...this.state.data, rows: rows },
      })
    }
  }

  //Fetch data
  fetchDivisionsData = async () => {
    this.setState({ loading: true })
    AdminApi.getDivisions()
      .then((result) => {
        this.setState(
          {
            divisionsList: result.data.data || [],
            imageUrls: result.data.imageUrl,
            loading: false,
          },
          this.setRows
        )
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  //Delete data
  _DeleteDivision = async (id) => {
    this.setState({ deleting: true })
    const data = { type: 'divisions', id: id }

    AdminApi.deleteDivision(data)
      .then((result) => {
        this.setState({ deleting: false })
        this.confirm_toggle()
        toast.success('Division successfully deleted', {
          position: toast.POSITION.TOP_RIGHT,
        })
        this.fetchDivisionsData()
      })
      .catch((error) => {
        this.setState({ deleting: false })
        toast.error(`${error.message}`, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  render() {
    toast.configure()
    const { data, confirmModal, loading } = this.state

    const createNew = () => {
      this.props.history.push('/add-division')
    }

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <h2>
                Divisions
                <div className="d-inline-block">
                  <MDBBtn
                    tag="a"
                    floating
                    gradient="blue"
                    title={'Add New Division'}
                    onClick={createNew}
                  >
                    <MDBIcon icon="plus" />
                  </MDBBtn>
                </div>
              </h2>
              <hr />
              {loading ? (
                <h4 className={'p4'}>Loading...</h4>
              ) : (
                <div className="content-wrapper">
                  <MDBDataTable
                    sortRows={['email']}
                    entriesOptions={[100, 150, 200]}
                    entries={100}
                    striped
                    bordered
                    small
                    data={data}
                    className={'text-capitalize'}
                  />
                </div>
              )}

              <MDBModal
                size={'sm'}
                isOpen={confirmModal}
                toggle={this.confirm_toggle}
              >
                <MDBModalHeader toggle={this.confirm_toggle}>
                  Delete Division
                </MDBModalHeader>
                <MDBModalBody>
                  <div className="h5">
                    Are you sure you want to delete this Division?
                  </div>
                  <MDBBtn
                    color="secondary"
                    size={'sm'}
                    onClick={this.confirm_toggle}
                  >
                    Close
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    size={'sm'}
                    onClick={() => {
                      this._DeleteDivision(this.state.viewdata.id)
                    }}
                  >
                    {this.state.deleting ? 'Deleting...' : 'Delete'}
                  </MDBBtn>
                </MDBModalBody>
              </MDBModal>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default Divisions
