import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import KbArticlesForm from './../KbArticlesForm/KbArticlesForm.component'
import {
  MDBCollapse,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBBtnGroup,
  MDBBtn,
  MDBCardImage,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
  MDBRow,
  MDBCol,
} from 'mdbreact'
import { UIDataTable } from './../../../../components/content'
import Store from './../../stores/Knowledgebase.store'

import './KbArticlesList.component.scss'

const KbArticlesList = () => {
  const [tableColumns, setTableColumns] = useState([
      {
        label: 'Category',
        field: 'category_id',
        sort_col: 'category_id',
      },
      {
        label: 'Subject',
        field: 'subject',
        sort_col: 'subject',
      },
      {
        label: 'Question',
        field: 'question',
        sort_col: 'question',
      },
      {
        label: 'Keywords',
        field: 'keywords',
        sort_col: 'keywords',
      },
      {
        label: 'Actions',
        field: 'actions',
      },
    ]),
    [orderBy, setOrderBy] = useState({ col: 'id', dir: 'DESC' })

  const _renderColumnHeader = (col) => {
    const getOrderBy = () => toJS(Store.articlesOrderBy)

    const getSortCol = () =>
      (col?.sort_col ? col.sort_col : col?.field) || false

    const getSortDir = (sortCol) => {
      const orderBy = getOrderBy()
      if (orderBy && orderBy.hasOwnProperty(sortCol) && orderBy[sortCol])
        return orderBy[sortCol]
      return false
    }

    const isColSorted = (sortCol) => {
      return !!getSortDir(sortCol)
    }

    const onSortUp = () => {
      const sortCol = getSortCol(),
        sortDir = getSortDir(sortCol)

      if (sortDir === false || sortDir === 'DESC') {
        // not currently sorted OR sorted DESC.
        // change to ASC
        return Store.searchArticles({ orderBy: { [sortCol]: 'ASC' } })
      } else if (sortDir === 'ASC') {
        // already sorted ASC, remove sorting.
        return Store.searchArticles({ orderBy: { id: 'ASC' } })
      }
    }

    const onSortDown = () => {
      const sortCol = getSortCol(),
        sortDir = getSortDir(sortCol)

      if (sortDir === false || sortDir === 'ASC') {
        // not currently sorted OR sorted ASC.
        // change to DESC
        return Store.searchArticles({ orderBy: { [sortCol]: 'DESC' } })
      } else if (sortDir === 'DESC') {
        // already sorted DESC, remove sorting.
        return Store.searchArticles({ orderBy: { id: 'DESC' } })
      }
    }

    const sortCol = getSortCol(),
      sortDir = getSortDir(sortCol)

    return (
      <div
        className={`table-col-sorter ${col?.sort_col ? 'is-sortable' : ''} ${
          sortDir ? 'sort-' + sortDir.toLowerCase() : ''
        }`}
      >
        <label>{col.label}</label>
        <div className="sorter">
          <div onClick={() => onSortUp()} className="up">
            <MDBIcon icon="angle-up" />
          </div>
          <div onClick={() => onSortDown()} className="down">
            <MDBIcon icon="angle-down" />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div id="KbArticlesListComponent">
      <MDBRow>
        <MDBCol>
          <h6>Knowledgebase Articles</h6>
        </MDBCol>
      </MDBRow>
      <MDBContainer>
        <MDBRow>
          <MDBCol className="article-form-wrapper">
            <KbArticlesForm />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBRow>
        <MDBCol>
          <MDBBtn
            className="kba-new"
            color="info"
            disabled={
              Store.isSaving ||
              Store.isFetching ||
              Store.isLoading ||
              !!Store.Article
            }
            onClick={() => Store.startNewArticle()}
          >
            <MDBIcon icon="plus" />
            &nbsp;New
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <UIDataTable
        columns={tableColumns.map((col) => ({
          label: _renderColumnHeader(col),
          field: col.field,
        }))}
        rows={(Store.Articles || []).map((Article, idx) => ({
          category: Store.Categories[Article.get('category_id')]?.get('c_name'),
          subject: Article.get('subject'),
          question: Article.get('question'),
          keywords: (Article.get('keywords')
            ? JSON.parse(Article.get('keywords'))
            : []
          ).map((kw, idx) => (
            <span
              className="kb-keyword"
              key={`kb-kw-item-${idx}-${Article.id()}`}
            >
              {kw && `${kw}`.toLowerCase().replace(/-/g, ' ')}
            </span>
          )),
          action: (
            <MDBBtnGroup className="kba-action-btns">
              <MDBBtn
                type="button"
                className="kba-edit"
                onClick={() => Store.editArticle(Article)}
              >
                <MDBIcon icon="edit" />
                &nbsp;Edit
              </MDBBtn>
            </MDBBtnGroup>
          ),
        }))}
        pagination={Store.articlesPagination}
        onPageSelect={Store.searchArticles}
        onSearch={Store.searchArticles}
        onChange={Store.searchArticles}
      />
    </div>
  )
}

export default observer(KbArticlesList)
