import { observer } from 'mobx-react-lite'
import React from 'react'
import { MDBContainer, MDBCard, MDBCardHeader, MDBCardBody } from 'mdbreact'

import AgentPackages from './../../components/shared/AgentPackages.component'

import './AgentPackages.scss'

const AgentPackagesPage = (props) => {
  return (
    <main id="AgentPackagesPage" className="adminStyle mainSection">
      <MDBContainer fluid className="mt-5">
        <div style={{ position: 'relative' }}>
          <h2 className="h2-responsive">USABG Agent Packages</h2>
          <hr />
          <MDBCard>
            <MDBCardHeader color="indigo"></MDBCardHeader>
            <MDBCardBody>
              <AgentPackages can_enroll={true} />
            </MDBCardBody>
          </MDBCard>
        </div>
      </MDBContainer>
    </main>
  )
}

export default observer(AgentPackagesPage)
