const allowedPattern = /^[a-zA-Z0-9 .,;:!?"'()&@[\]<>#$%^&*()_+{}|\:;"'?\/>.<,\\-]+$/;

const preventInputUnicodeCharacters = (string) => {
  const filtered = string.split('').filter(char => allowedPattern.test(char)).join('');
  const hasIllegalCharacters = filtered.length !== string.length;
  return {
    hasIllegalCharacters,
    fixedString: filtered,
  };
};

export default preventInputUnicodeCharacters;
    