import RouteDefinition from './Route.model'
import {
  RequiresAuthGuard,
  RequiresUsertypesGuard,
  BlockedUsertypesGuard,
  RequiresOnboardingGuard,
} from './guards'

function createFromRouteMap(routeMap, routeConfig) {
  const RouteDef = new RouteDefinition()

  RouteDef.config = routeConfig
  RouteDef.guards = [
    //new RequiresAuthGuard(routeMap?.guards?.requiresAuth || false),
    Array.isArray(routeMap?.guards) &&
    !!routeMap?.guards
      .filter((G) => G && `${G.constructor.name}`.includes('RequiresAuthGuard'))
      ?.shift()
      ? routeMap.guards
          .filter(
            (G) => G && `${G.constructor.name}`.includes('RequiresAuthGuard')
          )
          ?.shift()
      : new RequiresAuthGuard(routeMap?.guards?.requiresAuth || false),
    new RequiresUsertypesGuard(routeMap?.guards?.reqUsertypeIds || null),
    new BlockedUsertypesGuard(routeMap?.guards?.blockedUsertypeIds || null),
    new RequiresOnboardingGuard(routeMap?.guards?.requiresOnboarding || false),
  ].filter((n) => !!n)
  RouteDef.name = routeMap?.name
  RouteDef.path = routeMap?.path
  RouteDef.pattern = routeMap?.pattern
  RouteDef.component = routeMap?.component
  RouteDef.readonly = routeMap?.readonly

  if (!RouteDef.isValid)
    throw new Error(
      `Invalid Route Definition used in RouteFactory::createFromRouteMap()`
    )

  return RouteDef
}

function createComponentPath(component, path, guards, routeConfig) {
  const RouteDef = new RouteDefinition()

  RouteDef.config = routeConfig
  RouteDef.guards = guards
  if (path && typeof path === 'object') {
    RouteDef.path = path.path
    RouteDef.pattern = path.pattern
  } else {
    RouteDef.path = path
  }
  if (routeConfig && routeConfig?.name) RouteDef.name = routeConfig.name
  if (routeConfig && routeConfig?.pattern)
    RouteDef.pattern = routeConfig.pattern
  RouteDef.component = component

  if (!RouteDef.isValid)
    throw new Error(
      `Invalid Route Definition used in RouteFactory::createComponentPath()`
    )

  return RouteDef
}

function create() {
  if (
    arguments.length &&
    (typeof arguments[0] === 'object' || typeof arguments[0] === 'function')
  ) {
    // if the passed object is already a RouteDefinition instance, pass it along.
    if (
      typeof arguments[0] === 'object' &&
      arguments[0].constructor.name === 'RouteDefinition'
    )
      return arguments[0]

    if (arguments.length === 2) {
      if (typeof arguments[1] === 'string')
        return createComponentPath(arguments[0], arguments[1])
      return createFromRouteMap(
        arguments[0],
        typeof arguments[1] === 'object' ? { ...arguments[1] } : {}
      )
    }

    if (arguments.length > 2) {
      const [routeObj, path, guards, routeConfig] = arguments
      return createComponentPath(routeObj, path, guards, routeConfig)
    }

    return createFromRouteMap(arguments[0])
  }
}

export class RouteFactory {
  static create = create
}
