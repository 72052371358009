import BaseModelFactory from './base.factory'
import UsersCarrierContractService from './../services/UsersCarrierContract.service'
import UsersCarrierContract from './../models/users-carrier-contract.model'

class UsersCarrierContractFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: UsersCarrierContractService, Model: UsersCarrierContract }
    )
  static findAll = async () =>
    await this._findAllBy(
      {},
      { Service: UsersCarrierContractService, Model: UsersCarrierContract }
    )
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: UsersCarrierContractService, Model: UsersCarrierContract }
    )
  static findAllById = async (id) =>
    await this._findAllBy(
      { id: id },
      { Service: UsersCarrierContractService, Model: UsersCarrierContract }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: UsersCarrierContractService,
      Model: UsersCarrierContract,
    })
}

export default UsersCarrierContractFactory
