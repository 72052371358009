import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import Store from './UserEvent.store'
import { MDBAlert, MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import moment from 'moment'

import './UserEvent.component.scss'

const getEventOutcome = (eventType) =>
  (eventType ? `${eventType}`.toLowerCase().trim().split('.') : []).pop() ||
  false

const determineColorClass = (Event) => {
  let eventType = Event && Event.get('event_type'),
    outcome = eventType && getEventOutcome(eventType)

  if (outcome) {
    // Standard Error
    if (['failure', 'error', 'fail'].includes(outcome)) return 'danger'

    // Standard Success
    if (['success', 'pass', 'passing'].includes(outcome)) return 'success'

    // Standard Warnings
    if (['warn'].includes(outcome)) return 'warning'

    // Event Specific
    if (['agent.terms.accepted'].includes(eventType)) return 'info'
  }

  return 'secondary'
}

const UserEvent = ({ Event }) => {
  const color = determineColorClass(Event)

  return (
    <MDBAlert className="container-fluid UserEventComponent" color={color}>
      <MDBContainer>
        <MDBRow>
          <MDBCol className="ulog-event-type">{Event.get('event_type')}</MDBCol>
          <MDBCol className="ulog-event-descrip">
            {Event.get('event_descrip') || '---'}
          </MDBCol>
          <MDBCol className="ulog-related-model">
            {Event.get('related_model') || '---'}
          </MDBCol>
          <MDBCol className="ulog-payload">
            {Event.get('payload') ? (
              <code>
                {Event.get('payload')
                  ? typeof Event.get('payload') === 'string'
                    ? Event.get('payload')
                    : JSON.stringify(Event.get('payload'))
                  : '{}'}
              </code>
            ) : (
              '---'
            )}
          </MDBCol>
          <MDBCol className="ulog-created-at">
            {moment(Event.get('created_at')).format('h:mm a on M/D/YYYY')}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBAlert>
  )
}

export default observer(UserEvent)
