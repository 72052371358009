import React, { Component, Fragment } from 'react'
import DashboardLayout from '../components/Admin/DashboardLayout'
import {
  MDBContainer,
  MDBDataTable,
  MDBIcon,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBModalHeader,
  MDBModalBody,
  MDBModal,
} from 'mdbreact'
import { toast } from 'react-toastify'
import AdminApi from '../api/admin-api/admin-api'
import moment from 'moment'

class AdminProspects extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      notesModal: false,
      currentNotes: '',
      prospectsList: [],
      items: {
        default: '1',
      },
      data: {
        columns: [
          {
            label: 'Name',
            field: 'name',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Recruiter',
            field: 'recruiter',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Email',
            field: 'email',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Phone',
            field: 'phone',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'City',
            field: 'city',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'State',
            field: 'state',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Claimed On',
            field: 'claimed',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Notes',
            field: 'notes',
            sort: 'disabled',
            width: 150,
          },
        ],
        rows: [],
      },
    }
  }

  componentDidMount() {
    this.fetchProspectsByStatus('claimed')
  }

  togglePills = (type, tab) => (e) => {
    e.preventDefault()
    if (!this.state.loading) {
      if (this.state.items[type] !== tab) {
        let items = { ...this.state.items }
        items[type] = tab
        this.setState({
          items,
        })
        if (tab === '1') {
          this.fetchProspectsByStatus('claimed')
        }
        if (tab === '2') {
          this.fetchProspectsByStatus('unclaimed')
        }
        if (tab === '3') {
          this.fetchProspectsByStatus('enrolled')
        }
      }
    }
  }

  // resource data API
  fetchProspectsByStatus = async (status) => {
    this.setState({ loading: true })
    AdminApi.getProspectByStatus(status)
      .then((result) => {
        if (result && result.data && result.data.data) {
          this.setState({ prospectsList: result.data.data })
        }
      })
      .then(() => {
        this.setState({
          data: { ...this.state.data, rows: [] },
        })
        this.setRows()
        this.setState({ loading: false })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  //forming rows for data table from fetched data
  setRows = () => {
    const { prospectsList } = this.state
    if (prospectsList && prospectsList.length) {
      let rows = prospectsList.map((row) => {
        const {
          pro_city,
          pro_claimed_on,
          pro_email,
          pro_fname,
          pro_notes,
          pro_phone,
          pro_recruiter,
          pro_state,
        } = row
        const self = this

        function viewNotes() {
          self.setState({ currentNotes: pro_notes })
          self.confirm_notes({ viewdata: row })
        }

        const viewClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={viewNotes}
            >
              <MDBIcon icon="pencil-alt" size="2x" className="blue-text p-1" />
            </div>
          </div>
        )

        const claimedOn = (
          <span
            searchvalue={
              pro_claimed_on ? moment(pro_claimed_on).format('YYYY/MM/DD') : ''
            }
          >
            {pro_claimed_on
              ? moment(pro_claimed_on).format('MM/DD/YYYY')
              : 'N/A'}
          </span>
        )

        return {
          name: `${pro_fname}`,
          recruiter: pro_recruiter,
          email: pro_email,
          phone: pro_phone,
          city: pro_city,
          state: pro_state,
          claimed: claimedOn,
          notes: viewClick,
        }
      })
      this.setState({
        data: { ...this.state.data, rows: rows },
      })
    }
  }

  confirm_notes = (data) => {
    this.confirm_toggle(data)
  }

  confirm_toggle = (data = {}) => {
    this.setState({ notesModal: !this.state.notesModal, ...data })
  }

  render() {
    toast.configure()
    const { loading, data, notesModal } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              <h2>USABG Prospects</h2>
              <hr />
              <MDBNav className="mt-5 nav-pills">
                <MDBNavItem>
                  <MDBNavLink
                    link
                    to="#"
                    active={this.state.items['default'] === '1'}
                    onClick={this.togglePills('default', '1')}
                  >
                    Claimed
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    link
                    to="#"
                    active={this.state.items['default'] === '2'}
                    onClick={this.togglePills('default', '2')}
                  >
                    Unclaimed
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    link
                    to="#"
                    active={this.state.items['default'] === '3'}
                    onClick={this.togglePills('default', '3')}
                  >
                    Enrolled
                  </MDBNavLink>
                </MDBNavItem>
              </MDBNav>
              <MDBTabContent activeItem={this.state.items['default']}>
                {loading ? (
                  <h2>Loading...</h2>
                ) : (
                  <>
                    <MDBTabPane className="content-wrapper" tabId="1">
                      <h3>Claimed Prospects</h3>
                      <MDBDataTable
                        sortRows={['claimed']}
                        striped
                        bordered
                        small
                        exportToCSV
                        data={data}
                        className="text-capitalize"
                      />
                    </MDBTabPane>
                    <MDBTabPane className="content-wrapper" tabId="2">
                      <h3>Unclaimed Prospects</h3>
                      <MDBDataTable
                        sortRows={['claimed']}
                        striped
                        bordered
                        small
                        exportToCSV
                        data={data}
                        className="text-capitalize"
                      />
                    </MDBTabPane>
                    <MDBTabPane className="content-wrapper" tabId="3">
                      <h3>Enrolled Prospects</h3>
                      <MDBDataTable
                        sortRows={['claimed']}
                        striped
                        bordered
                        small
                        exportToCSV
                        data={data}
                        className="text-capitalize"
                      />
                    </MDBTabPane>
                  </>
                )}
              </MDBTabContent>

              <MDBModal isOpen={notesModal} toggle={this.confirm_toggle}>
                <MDBModalHeader toggle={this.confirm_toggle}>
                  Notes
                </MDBModalHeader>
                <MDBModalBody>
                  <div>{this.state.currentNotes}</div>
                </MDBModalBody>
              </MDBModal>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AdminProspects
