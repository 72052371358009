import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import LoadingSpinner from './../../shared/LoadingSpinner.component'
import UploadService from './../../../shared/services/Upload.service'
import ContentHtml from './../../../components/content/ContentHtml/ContentHtml.component'
import { b64toBlob } from './../../../shared/utilities/b64toBlob.function'
import { Link } from 'react-router-dom'
import { UIInput, UIDropdown } from './../../../components/forms/form-fields'
import { MDBInput } from 'mdbreact'
import {
  MDBFileInput,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBAlert,
  MDBDataTable,
  MDBIcon,
  MDBModalHeader,
  MDBModalBody,
  MDBModal,
  MDBCollapse,
  MDBContainer,
} from 'mdbreact'
import { allowedSizeByUploadType } from '../../../constants/allowedMediaSize'

import './Modal.component.scss'

const Modal = ({ isOpen, onResolve, onReject, Store }) => {
  const [showValidity, setShowValidity] = useState(false)

  const cancelModal = () => {
    Store.outcome?.status === true ? onResolve() : onReject()
  }

  const shouldShowLoadingSpinner = () => {
    return !!Store.isLoading
  }

  const renderLoadingSpinner = () => (
    <div className="loading-wrapper">
      <LoadingSpinner size="md" isActive={true} />
    </div>
  )

  const isPaymentMethodValid = () => !!Store.paymentMethods().length

  const requestPurchase = async ($event) => {
    if ($event) $event.preventDefault()

    Store.purchase.customer = Store.customer.id
    Store.purchase.user_id = Store.User?.id()
    Store.purchase.meta.ledger_id = Store.Ledger?.id()

    if (!(await Store.validatePurchase())) {
      if (!showValidity) setShowValidity(true)
      return
    }

    if (showValidity) setShowValidity(false)

    await Store.purchaseViaStripe()

    return !!Store.outcome.status
  }

  const isDisabled = () => {
    if (Store.outcome.status === true) return true
    if (Store.isPurchasing) return true
    return false
  }

  const showOutcome = () => {
    if (Store.outcome.status === true) return true

    if (Store.outcome.status === false) {
      if (Store.outcome.message || Store.outcome.errors.length) return true
    }

    return false
  }

  const getOutcomeMessage = () => {
    if (Store.outcome.status === true || Store.outcome.message)
      return `${
        Store.outcome.status === true
          ? 'Great! Your lead BUCK$ have been purchased & deposited into your account.'
          : Store.outcome.message
      }`
    return Store.outcome.errors.map((err, i) => (
      <li key={`lc-stripe-err-${i}`}>{err}</li>
    ))
  }

  const getDescriptor = (pm) => {
    pm = pm
      ? pm
      : Store.purchase.source && Store.paymentMethods(Store.purchase.source)
    return pm
      ? `${pm.card.brand} ending in ${pm.card.last4} (${pm.card.exp_month}/${pm.card.exp_year})`
      : ''
  }

  const renderPurchaseForm = () => {
    return (
      <MDBContainer>
        <MDBRow className="mb-3 pt-4">
          <MDBCol lg="6" className="offset-lg-3">
            <form onSubmit={($event) => requestPurchase($event)} noValidate>
              <div className="bucks-buy-input is-active">
                <h6>1. Please select payment method.</h6>
                {Store.paymentMethods().map((pm, idx) => (
                  <MDBInput
                    checked={
                      Store.purchase.source && Store.purchase.source === pm?.id
                    }
                    onClick={(e) =>
                      console.log(
                        'clicked on ',
                        (Store.purchase.source = pm.id)
                      )
                    }
                    label={getDescriptor(pm)}
                    type="radio"
                    id={`bucks_payment_radio_${idx + 1}`}
                    key={`bucks_payment_radio_${idx + 1}`}
                  />
                ))}
              </div>
              <hr />
              <div
                className={`bucks-buy-input ${
                  !!Store.purchase.source ? 'is-active' : ''
                }`}
              >
                <h6>2. Enter amount to purchse.</h6>
                <UIInput
                  name="amount"
                  type="currency"
                  value={Store.purchase.amount}
                  label="Enter purchase amount"
                  onChange={(e) =>
                    (Store.purchase.amount =
                      (e = e?.target?.value) === null || isNaN(e)
                        ? ''
                        : parseInt(e))
                  }
                  required
                  showValidity={showValidity}
                  disabled={!Store.purchase.source || isDisabled()}
                />
              </div>
              <div className="pb-3 pt-3">
                <ContentHtml contentSlug="lead-credit-purchase-statement" />
              </div>
              <MDBBtn
                disabled={!Store.purchase.source || isDisabled()}
                color="indigo"
                type="submit"
                block
              >
                Purchase USABG BUCK$
              </MDBBtn>
            </form>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mb-3">
          <MDBCol lg="6" className="offset-lg-3">
            <MDBCollapse isOpen={showOutcome()}>
              <div className="pb-2 pt-2 text--center">
                <MDBAlert
                  color={Store.outcome.status === true ? 'success' : 'danger'}
                >
                  {getOutcomeMessage()}
                </MDBAlert>
                <br />
                <span
                  onClick={() => cancelModal()}
                  style={{ color: '#999', fontWeight: 400 }}
                >
                  Close
                </span>
              </div>
            </MDBCollapse>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }

  const renderInvalidPaymentAlert = () => {
    return (
      <div>
        <MDBAlert color="danger" className="text--center">
          <div>
            <h4>
              <strong>
                Oh no! It seems you need to setup your payment method first.
              </strong>
            </h4>
          </div>
        </MDBAlert>
        <p>
          To update your billing details,{' '}
          <Link to="/account-billinginfo">
            <strong>Click Here!</strong>
          </Link>
        </p>
      </div>
    )
  }

  return (
    <div className="LeadCreditPurchaseModalComponent">
      <MDBModal size="lg" isOpen={isOpen} toggle={cancelModal}>
        <MDBModalHeader toggle={cancelModal}>{Store.title}</MDBModalHeader>
        <MDBModalBody>
          {shouldShowLoadingSpinner()
            ? renderLoadingSpinner()
            : isPaymentMethodValid()
            ? renderPurchaseForm()
            : renderInvalidPaymentAlert()}
        </MDBModalBody>
      </MDBModal>
    </div>
  )
}

export default observer(Modal)
