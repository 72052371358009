import React from 'react'
import { observer } from 'mobx-react-lite'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import { MDBContainer } from 'mdbreact'

import './BeastModeSetupPage.scss'

const BeastModeSetupPage = () => {
  return (
    <DashboardLayout>
      <main id="BeastModeSetupPage" className="mainSection">
        <MDBContainer fluid className="mt-5">
          <h2 className="text-center">BeastMode 10x CRM Setup</h2>
          <hr />
        </MDBContainer>
      </main>
    </DashboardLayout>
  )
}

export default observer(BeastModeSetupPage)
