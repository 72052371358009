import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { MDBBtn, MDBRow, MDBCol, MDBIcon, MDBSelect } from 'mdbreact'
import ReportsStore from './../../store/ReportsPage.store'
import UserProfileService from './../../../../shared/services/UserProfile.service'

import './ReportFilters.scss'

const ReportFilters = ({
  category,
  coverages,
  isFetching,
  onChange,
  onChangeDivision,
  opts,
}) => {
  opts = opts && typeof opts === 'object' ? opts : {}
  const [cvrges, setCvrges] = useState(
    coverages
      ? Array.isArray(coverages)
        ? coverages
        : `${coverages}`.split(/-/)
      : []
  )
  const isCoverageSelected = (coverage) => cvrges.indexOf(coverage) > -1

  const onFilterChange = (changed) => {
    let c = cvrges
      ? Array.isArray(cvrges)
        ? cvrges
        : `${cvrges}`.split(/-/)
      : []

    if (isCoverageSelected(changed)) c.splice(c.indexOf(changed), 1)
    else c.push(changed)

    c.sort()

    onChange({ coverages: c.join('-') })
    setCvrges(c.join('-'))
  }

  const [divisions, setDivisions] = useState([])

  const canSelectDivision = () => {
    if (UserProfileService.isA('system-admin')) return true

    let details = UserProfileService.getUserDetails()
    return details && parseInt(details.u_upline_id) === 0
  }

  const shouldEnableMedAdvBtn = () =>
    category &&
    ['division-totals', 'region-totals', 'district-totals', 'career-totals'].includes(
      `${category}`.trim().toLowerCase()
    )

  useEffect(() => {
    if (
      canSelectDivision() &&
      (!category || category.indexOf('division') > -1)
    ) {
      ReportsStore.getDivisions().then((deps) => setDivisions(deps))
      onChangeDivision('all')
    }
  }, [category, onChangeDivision])

  return (
    <>
      <MDBRow
        id="ReportFilters"
        className={shouldEnableMedAdvBtn() ? 'enable-med-adv' : ''}
      >
        <MDBCol>
          <MDBBtn
            className={
              'btn-orange btn-block ' +
              (isCoverageSelected('ACA') ? ' btn-active' : '')
            }
            disabled={isFetching}
            onClick={() => onFilterChange('ACA')}
          >
            <MDBIcon icon="check" />
            ACA LIVES
          </MDBBtn>
        </MDBCol>
        <MDBCol>
          <MDBBtn
            className={
              'btn-red btn-block ' +
              (isCoverageSelected('HEALTH') ? ' btn-active' : '')
            }
            disabled={isFetching}
            onClick={() => onFilterChange('HEALTH')}
          >
            <MDBIcon icon="check" />
            HEALTH
          </MDBBtn>
        </MDBCol>
        <MDBCol>
          <MDBBtn
            className={
              'btn-green btn-block ' +
              (isCoverageSelected('LIFE') ? ' btn-active' : '')
            }
            disabled={isFetching}
            onClick={() => onFilterChange('LIFE')}
          >
            <MDBIcon icon="check" />
            LIFE
          </MDBBtn>
        </MDBCol>
        <MDBCol>
          <MDBBtn
            className={
              'btn-purple btn-block ' +
              (isCoverageSelected('MED_ADV') ? ' btn-active' : '')
            }
            disabled={isFetching}
            onClick={() => onFilterChange('MED_ADV')}
          >
            <MDBIcon icon="check" />
            MED ADVANTAGE
          </MDBBtn>
        </MDBCol>
        <MDBCol>
          <MDBBtn
            className={
              'btn-blue btn-block ' +
              (isCoverageSelected('SENIOR') ? ' btn-active' : '')
            }
            disabled={isFetching}
            onClick={() => onFilterChange('SENIOR')}
          >
            <MDBIcon icon="check" />
            SENIOR
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </>
  )
}

export default observer(ReportFilters)
