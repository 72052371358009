import React, { Component } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import PageHeading from './../../components/shared/PageHeading.component'
import { MDBContainer, MDBCol, MDBRow } from 'mdbreact'
import {
  LeaderTermsStore,
  LeaderTermsReviewer,
} from './components/LeaderTermsReviewer/LeaderTermsReviewer.component'
import { ResetGuidelineButton } from './components/ResetGuidelineBtn/ResetGuidelineBtn'
import { ResetAllGuidelineButton } from './components/ResetAllGuidelineBtn/ResetAllGuidelineBtn'
import UserProfileService from './../../shared/services/UserProfile.service'

import './GuidelinesPage.scss'

class ManagerGuidlines extends Component {
  componentDidMount() {
    LeaderTermsStore.fetch(UserProfileService.getUserId())
  }

  render() {
    return (
      <DashboardLayout>
        <main id="LeaderGuidelinesPage" className="mainSection pb-5">
          <MDBContainer fluid className="mt-5">
            <PageHeading
              label="USA Benefits Group Leader Guidelines"
              url={this.props?.match?.url}
              from={this.props?.location?.state?.from}
              btnGroup={
                <>
                  <ResetGuidelineButton />
                  <ResetAllGuidelineButton />
                </>
              }
            />
            <MDBRow>
              <MDBCol lg="12">
                <LeaderTermsReviewer />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </main>
      </DashboardLayout>
    )
  }
}

export default ManagerGuidlines
