import { makeAutoObservable } from 'mobx'
import AdminApi from './../../../api/admin-api/admin-api'
import { SubsiteLinkFactory } from './../../../shared/factories'

export const defaultTestimonials = [
  {
    author: 'Shannon Gunn',
    testimonial: `Seriously, these Ladies went to bat for me from the minute we got the terrible news about non coverage Thursday night at the 11th hour before my mastectomy. Their relentless calls and emails went up the chain of command at my insurance company and I have no doubt that their drive to help me is part of why things have had a dramatic turnaround this afternoon. 
    So shout out to USA benefits and Jessica Banville Rick Banville and their Team!! Highly recommend!`,
  },
  {
    author: 'Dan B in Spring Hill, FL',
    testimonial: `They found us the best coverage at the best price. I highly recommend them!`,
  },
  {
    author: 'Ron P in Hobbs, NM',
    testimonial: `Thank you for making Medicare simple.`,
  },
]
class WebsiteInfoStore {
  isLoading = false
  isSaving = false
  siteDetails = {}

  MenuSiteLinks = null
  ResourceSiteLinks = null

  constructor() {
    makeAutoObservable(this)
  }

  fetchSiteDetails = async () => {
    this.isLoading = true

    return AdminApi.getUserSiteDetails()
      .then((result) => {
        if (result?.data?.data) {
          let details = { ...(result?.data?.data || {}) }

          if (details.testimonials) {
            details.testimonials =
              typeof details.testimonials === 'string'
                ? JSON.parse(details.testimonials)
                : details.testimonials
          } else {
            details.testimonials = []
          }
          if (details.how_hear_options) {
            details.how_hear_options =
              typeof details.how_hear_options === 'string'
                ? JSON.parse(details.how_hear_options)
                : details.how_hear_options
          } else {
            details.how_hear_options = ['']
          }

          this.siteDetails = details
        }
        if (!this.siteDetails?.s_about)
          this.siteDetails.s_about =
            "USA Benefits Group is a nationwide network of independent health and life insurance professionals that have been serving individuals, small-business owners and the self-employed since 1988. We offer Health Insurance, Critical Illness, Accident Plans, GAP Plans, Dental Plans, Rx Drug Plans, Life Insurance, Medicare Supplements, Annuities and many other insurance products. USA Benefits Group provides a comprehensive and ongoing training program to it's agents so they are better prepared to serve their clients. Our agents represent you, the client, and they are committed to meeting your needs and matching you up with the most appropriate and affordable insurance solutions."

        this.isLoading = false
      })
      .catch((error) => {
        this.siteDetails = {}
        this.isLoading = false
      })
  }

  fetchSiteLinks = async () => {
    if (this.siteDetails?.site_id) {
      const SiteLinks = await SubsiteLinkFactory.findAllBySiteId(
          this.siteDetails?.site_id
        ),
        MenuSiteLinks = SiteLinks.filter(
          (SiteLink) => SiteLink.get('link_type') === 'navigation'
        ).shift(),
        ResourceSiteLinks = SiteLinks.filter(
          (SiteLink) => SiteLink.get('link_type') === 'resources'
        ).shift()

      this.MenuSiteLinks = MenuSiteLinks
        ? MenuSiteLinks
        : SubsiteLinkFactory.create({
            link_type: 'navigation',
            site_id: this.siteDetails?.site_id,
          })
      this.ResourceSiteLinks = ResourceSiteLinks
        ? ResourceSiteLinks
        : SubsiteLinkFactory.create({
            link_type: 'resources',
            site_id: this.siteDetails?.site_id,
          })
    }
  }
}

export default new WebsiteInfoStore()
