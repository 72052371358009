import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import AgentProfileIntake from './../../agents/AgentProfileIntake/AgentProfileIntake.component'
import AgentContractingIntake from './../../agents/AgentContractingIntake/AgentContractingIntake.component'
import AgentTrainingIntake from './../../agents/AgentTrainingIntake/AgentTrainingIntake.component'
import AgentKpiGoalsIntake from './../../agents/AgentKpiGoalsIntake/AgentKpiGoalsIntake.component'
import OnboardingStageManager from './../OnboardingStageManager/OnboardingStageManager.component'
import UserProfileService from './../../../shared/services/UserProfile.service'
import OnboardingStore from './../Onboarding.store'

import './AgentOnboarder.scss'

const AgentOnboarder = ({ spec }) => {
  const userId = UserProfileService.getUserId()

  useEffect(() => {
    if (userId) {
      OnboardingStore.fetchStageMeta().then((activeStage) =>
        OnboardingStore.goTo(activeStage || OnboardingStore.getInitial())
      )
    }
  }, [userId])

  const getClass = () => 
    `${OnboardingStore.activeStage?.name}`.replace(/_/g, '-')

  const activeStage = OnboardingStore.activeStage?.name
    ? `${OnboardingStore.activeStage?.name}`
    : false

  const renderStageComponents = (stageName) => {
    if (activeStage !== stageName) {
      let prevStage = OnboardingStore.getPrev(),
        nextStage = OnboardingStore.getNext()
      if (stageName !== prevStage && stageName !== nextStage) return <></>
    }

    switch (stageName) {
      case 'agent_profile':
        return (
          <AgentProfileIntake
            onValidityChange={(isValid) =>
              OnboardingStore.setValidity('agent_profile', !!isValid)
            }
          />
        )

      case 'contracting':
        return (
          <AgentContractingIntake
            step="2"
            label="Carrier Contracting & Agent Specialization"
            validationKey="requested"
            onValidityChange={(isValid) =>
              OnboardingStore.setValidity('contracting', !!isValid)
            }
            preventInit={false}
          />
        )

      case 'training':
        return (
          <AgentTrainingIntake
            step="3"
            label="USABG Orientation Videos"
            onValidityChange={(isValid) =>
              OnboardingStore.setValidity('training', !!isValid)
            }
          />
        )

      case 'set_kpi_goals':
        return (
          <AgentKpiGoalsIntake
            onValidityChange={(isValid) =>
              OnboardingStore.setValidity('set_kpi_goals', !!isValid)
            }
          />
        )

      case 'writing_numbers':
        return (
          <AgentContractingIntake
            step={
              UserProfileService.isA(['affiliate-group', 'associate-group'])
                ? '3'
                : '5'
            }
            label="Input Writing Numbers"
            validationKey="completed"
            onValidityChange={(isValid) =>
              OnboardingStore.setValidity('writing_numbers', !!isValid)
            }
            preventInit={true}
          />
        )

      default:
        return
    }
  }

  return (
    <MDBContainer id="AgentOnboarder" fluid>
      <MDBRow>
        <MDBCol size="12" className="onboarding-manager-wrapper">
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12">
                <h3>Onboarding Center</h3>
                <div>Complete your enrollment to start writing business.</div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <OnboardingStageManager />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="12" className="col-1-a">
          <MDBContainer className="cont-1-a">
            <MDBRow className="row-2-a">
              <MDBCol size="12" className="col-2-a">
                <div id="onboarding-manager-stage" className={getClass()}>
                  <div className="agent-profile">
                    {renderStageComponents('agent_profile')}
                  </div>
                  <div className="contracting">
                    {renderStageComponents('contracting')}
                  </div>
                  <div className="training">
                  	{renderStageComponents('training')}
                  </div>
                  <div className="set-kpi-goals">
                    {renderStageComponents('set_kpi_goals')}
                  </div>
                  <div className="writing-numbers">
                    {renderStageComponents('writing_numbers')}
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(AgentOnboarder)
