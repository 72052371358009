import React from 'react'
import { observer } from 'mobx-react-lite'
import { MDBIcon, MDBCollapse, MDBAlert } from 'mdbreact'
import UsertypeBlockModal from './../modals/UsertypeBlockModal/UsertypeBlockModal.component'
import UsertypeBlockModalStore from './../modals/UsertypeBlockModal/UsertypeBlockModal.store'
import { Link } from 'react-router-dom'

import './DashboardOverlay.scss'

const DashboardOverlay = () => {
  const render = () => {
    if (UsertypeBlockModalStore.isReadonly) {
      return (
        <div id="DashboardOverlayComponent">
          <MDBCollapse isOpen={!UsertypeBlockModalStore.isOpen}>
            <MDBAlert color="warning" className="text--center font--lato">
              <MDBIcon icon="exclamation-triangle" />
              &nbsp;Expand your account access by upgrading to a Signature Agent
              today!{' '}
              <Link to="/account-billinginfo">
                <strong>Click Here!</strong>
              </Link>
            </MDBAlert>
          </MDBCollapse>
          <UsertypeBlockModal />
        </div>
      )
    }
  }

  return render()
}

export default observer(DashboardOverlay)
