import React from 'react'
import Store from '../store'
import { observer } from 'mobx-react-lite'
import SpecializationSelector from './SpecializationSelector.component'
import CarrierPrioritiesTable from './CarrierPrioritiesTable.component'

const CarrierPrioritiesContent = () => {
  return Store.isLoading ? (
    <h2>Loading...</h2>
  ) : (
    <>
      <div className="content-wrapper mb-4">
        <SpecializationSelector />
      </div>
      <CarrierPrioritiesTable />
    </>
  )
}

export default observer(CarrierPrioritiesContent)
