import React from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
} from 'mdbreact'
import { convertToCurrencyWithoutSign } from './../../../../shared/formatters/convertToCurrencyWithoutSign.formatter'
import MilestoneStore from './../../Milestone.store'
import LoadingSpinner from './../../../../components/shared/LoadingSpinner.component'

import './KOTHYearly.scss'
import { autoLogin, getAgentAccessToHeadshot } from '../../../../shared/utilities/getHeadshot.function'

const labels = [
  '1st Place (YTD)',
  '2nd Place (YTD)',
  'King of the Hill ' + (moment().format('YYYY') - 1),
  'KOTH ' + (moment().format('YYYY') - 2),
  'KOTH ' + (moment().format('YYYY') - 3),
]

const KOTHYearly = (props) => {
  const source = props?.source,
    kothType = `${source}_koth_yearly`,
    getLabel = (agent) => {
      if (`${agent.mi_rank}` === '2')
        return (
          <span style={{ fontWeight: 500, fontSize: '1.75rem' }}>
            {source === 'agent' ? 'Agent' : 'BA Team'} {labels[agent.mi_rank]}
          </span>
        )
      return (
        <span style={{ fontSize: '1.25rem' }}>{labels[agent.mi_rank]}</span>
      )
    }

  const renderKOTH = (agent) => (
    <>
      <div
        className={agent.user_id && getAgentAccessToHeadshot(agent.user_id) ? "img-wrapper cursor-pointer" : "img-wrapper"}
        onClick={() => {
          if(agent.user_id && getAgentAccessToHeadshot(agent.user_id)) autoLogin(agent.user_id)
        }}
      >
        <img
          src={agent.image}
          className="mx-auto d-block img-fluid rounded-circle"
          style={{ height: '135px' }}
          alt={agent.name ? agent.name : ''}
        />
      </div>
      <h5 className="text-center">
        {[agent.u_nickname ? agent.u_nickname : agent.mi_fname, agent.mi_lname]
          .join(' ')
          .trim()}
      </h5>
      <h5 className="text-center">
        {agent.mi_value ? convertToCurrencyWithoutSign(agent.mi_value) : 0}{' '}
        Points
      </h5>
    </>
  )

  const renderWidget = () => {
    return (
      <MDBContainer
        id="KOTHYearly"
        fluid
        className={MilestoneStore.loading[kothType] ? 'is-loading' : ''}
      >
        <div className="loading-spinner desktop-only">
          <LoadingSpinner size="md" />
        </div>
        <MDBRow className="mb-3_5">
          {MilestoneStore.milestones[kothType] &&
          MilestoneStore.milestones[kothType].milestone.length > 0 ? (
            MilestoneStore.milestones[kothType].milestone.map((agent, i) => (
              <MDBCol
                key={'koth-yearly-' + i}
                size="12"
                md={`${i}` === '2' ? '4' : '2'}
              >
                <MDBCard>
                  <MDBCardHeader color="primary-color-dark text-center">
                    {getLabel(agent)}
                  </MDBCardHeader>
                  <MDBCardBody>
                    <div className="loading-spinner mobile-only">
                      <LoadingSpinner size="md" />
                    </div>
                    {renderKOTH(agent)}
                  </MDBCardBody>
                </MDBCard>
                {`${i}` === '4' ? (
                  <>
                    <div
                      style={{
                        textAlign: 'right',
                        fontWeight: 500,
                        fontSize: '12px',
                      }}
                    >
                      {MilestoneStore.milestones[kothType] ? (
                        `${MilestoneStore.milestones[kothType]?.updates}.  `
                      ) : (
                        <></>
                      )}
                      {MilestoneStore.milestones[kothType] &&
                      MilestoneStore.milestones[kothType]?.activity ? (
                        `Last Updated: ${MilestoneStore.milestones[kothType]?.activity}  `
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </MDBCol>
            ))
          ) : (
            <MDBCol size="12">No Data!</MDBCol>
          )}
        </MDBRow>
      </MDBContainer>
    )
  }

  if (MilestoneStore.milestones[kothType] === null) {
    MilestoneStore.milestones[kothType] = {
      milestone: [
        {
          mi_fname: '',
          mi_lname: '',
          u_nickname: '',
          mi_date: moment().format('YYYY-MM-DD'),
          mi_value: 0,
          mi_rank: 0,
          u_picture: null,
        },
        {
          mi_fname: '',
          mi_lname: '',
          u_nickname: '',
          mi_date: moment().format('YYYY-MM-DD'),
          mi_value: 0,
          mi_rank: 1,
          u_picture: null,
        },
        {
          mi_fname: '',
          mi_lname: '',
          u_nickname: '',
          mi_date: moment().format('YYYY-MM-DD'),
          mi_value: 0,
          mi_rank: 2,
          u_picture: null,
        },
        {
          mi_fname: '',
          mi_lname: '',
          u_nickname: '',
          mi_date: moment().format('YYYY-MM-DD'),
          mi_value: 0,
          mi_rank: 3,
          u_picture: null,
        },
        {
          mi_fname: '',
          mi_lname: '',
          u_nickname: '',
          mi_date: moment().format('YYYY-MM-DD'),
          mi_value: 0,
          mi_rank: 4,
          u_picture: null,
        },
      ],
    }
    MilestoneStore.fetchMilestone(kothType)
  }

  return renderWidget()
}

export default observer(KOTHYearly)
