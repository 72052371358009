import React, { Component } from 'react'
import Login from './../../components/authentication/Login/Login.component'
import ForgotPassword from './../../components/authentication/ForgotPassword/ForgotPassword.component'
import ResetPassword from './../../components/authentication/ResetPassword/ResetPassword.component'
import AuthStore from './../../components/authentication/Auth.store'
import appConstants from './../../constants/appConstants'
import { Link } from 'react-router-dom'
import StorageService from './../../shared/services/Storage.service'
import UserProfileService from './../../shared/services/UserProfile.service'
import '../../api/FirebaseAuth'
import { MDBRow, MDBCol } from 'mdbreact'
import { Subscription } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'
import getQueryParam from './../../shared/utilities/getQueryParam.function'
import bgImage from './../../assets/images/bg-login.jpg'
import './AuthPage.scss'

const validateCredentials = async (userId, usertypeId) => {
  const authDetails = await AuthStore.redirectAuthenticated(),
    usertypeMatch =
      !isNaN(usertypeId) &&
      parseInt(usertypeId) === parseInt(authDetails?.usertype_id),
    userIdMatch =
      !isNaN(userId) && parseInt(userId) === parseInt(authDetails?.id)

  if (usertypeMatch && userIdMatch) return authDetails
  return false
}

const validateUserAuth = async () =>
  await validateCredentials(
    AuthStore.getUserId(false),
    AuthStore.getUsertypeId(false)
  )

const validateChildAuth = async () =>
  await validateCredentials(
    AuthStore.getUserId(true),
    AuthStore.getUsertypeId(true)
  )

class AuthPage extends Component {
  state = {
    authState: null,
  }

  __subscriptions$ = new Subscription()

  _onLogin = (usertypeId) => {
    StorageService.set('check_version', true)

    window.location =
      parseInt(UserProfileService.get('has_onboarded')) !== 1
        ? '/welcome'
        : '/dashboard'
  }

  _onForgot = () => {}

  _onReset = () => setTimeout(() => this._onAuthStateChange('login'), 3500)

  _onAuthStateChange = async (authState) => {
    if (this.state.authState !== authState) {
      this.setState({ authState }, () => {
        if (
          `${this.props?.location?.pathname}`.trim().toLowerCase() !==
          `/${this.state.authState}`
        )
          this.props.history.push(`/${this.state.authState}`)
      })
    }
  }

  _listen = async () => {
    // If the user has a token stored, lets check
    // token validity & auto-login if possible.
    if (AuthStore.isAuthenticated()) {
      // If the agent/leader is currently in an
      // assumed account, we'll need logout of
      // the child account first.
      if (AuthStore.isAssumed()) await AuthStore.unassume()

      const validated = await validateUserAuth()
      if (validated) return this._onLogin(validated.usertype_id)
    }

    this.__subscriptions$.add(
      AuthStore.setInitialState(this.props?.location?.pathname)
        .pipe(distinctUntilChanged())
        .subscribe(this._onAuthStateChange)
    )
  }

  componentDidMount() {
    AuthStore.reset_code = getQueryParam('oobCode', this.props.location.search)
    AuthStore.reset_mode = getQueryParam('mode', this.props.location.search)
    AuthStore.reset_api_key = getQueryParam(
      'apiKey',
      this.props.location.search
    )

    AuthStore.fetchTerms()

    this._listen()
  }

  componentWillUnmount() {
    this.__subscriptions$.unsubscribe()
  }

  render() {
    return (
      <>
        <div
          style={{ backgroundImage: `url(${bgImage})` }}
          className="one-screen-page bg-gray-darker bg-image"
          id="AuthPage"
        >
          <div className="page">
            <div className="page-inner">
              <header className="auth-head">
                <div className="divider-primary"></div>
                <div className="auth-head-inner">
                  <div
                    className="shell text-center"
                    style={{ marginTop: '10vh' }}
                  >
                    <a
                      href="index.html"
                      className="brand brand-md brand-inverse"
                    >
                      <img
                        src={appConstants.logos.master.md}
                        style={{ height: '210px' }}
                        alt="USA Benefits Group"
                      />
                    </a>
                  </div>
                </div>
              </header>

              <section className="container-fluid auth-body">
                <MDBRow>
                  <MDBCol
                    size="12"
                    sm="6"
                    lg="4"
                    className="offset-sm-3 offset-lg-4"
                  >
                    {this.state.authState === 'login' && (
                      <Login onSuccess={this._onLogin} />
                    )}
                    {this.state.authState === 'forgot-password' && (
                      <ForgotPassword onSuccess={this._onForgot} />
                    )}
                    {this.state.authState === 'reset-password' && (
                      <ResetPassword onSuccess={this._onReset} />
                    )}
                  </MDBCol>
                </MDBRow>
              </section>

              <section className="auth-foot">
                <div className="auth-foot-inner">
                  <div className="shell text-center">
                    <div className="range">
                      <div className="cell-xs-12">
                        <p className="rights">
                          <span>&nbsp;&#169;&nbsp;</span>
                          <span id="copyright-year">
                            {new Date().getFullYear()}
                          </span>
                          <span>USABG</span>
                          <Link
                            to={'/privacy-policy'}
                            className="link-white-v2"
                          >
                            Privacy Policy
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divider-primary"></div>
              </section>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default AuthPage
