import BaseModelInstance from './base.model'
import { toast } from 'react-toastify'
import { UserContractCart as ModelDefinition } from './model-definitions'
import UserContractCartItemFactory from './../factories/user-contract-cart-item.factory'
import UserContractCartMetaFactory from './../factories/user-contract-cart-meta.factory'
import UserContractingCartService from './../services/UserContractCart.service'

class UserContractCart extends BaseModelInstance {
  afterSet = (key, value) => {}

  #children = {}

  item = (useApi) => {
    if (useApi === true) {
      return {
        all: async () =>
          (this.#children.item = await UserContractCartItemFactory.search({
            search: { cart_id: this.id() },
            pagination: false,
          })),
        group: async (itemGroup) =>
          (await UserContractCartItemFactory.search({
            search: { item_group: itemGroup, cart_id: this.id() },
            pagination: false,
          })) ||
          {} ||
          [],
        create: async (data) =>
          await UserContractCartItemFactory.create({
            ...(data ? data : {}),
            cart_id: this.id(),
          }),
      }
    }

    return Array.isArray(this.#children?.item) ? this.#children.item : []
  }

  meta = (useApi) => {
    if (useApi === true) {
      return {
        all: async () =>
          (await UserContractCartMetaFactory.search({
            search: { cart_id: this.id() },
            pagination: false,
          })) ||
          {} ||
          [],
        key: async (metaKey) =>
          (await UserContractCartMetaFactory.search({
            search: { meta_key: metaKey, cart_id: this.id() },
            pagination: false,
          })) ||
          {} ||
          [],
        create: async (data) =>
          await UserContractCartMetaFactory.create({
            ...(data ? data : {}),
            cart_id: this.id(),
          }),
      }
    }

    return Array.isArray(this.#children?.meta) ? this.#children.meta : []
  }

  #_checkout = null
  checkout = async (Carriers, force) => {
    // if (this.#_checkout !== null) {
    //   if (force !== true) return this.#_checkout
    // }

    let PacketItems,
      packetContractTypes = {}
    try {
      PacketItems =
        (await this.api().preview(
          this.id(),
          (Carriers || []).map((Carrier) => Carrier.id())
        )) || []
      PacketItems.forEach(
        (CartItem) =>
          (packetContractTypes[CartItem.item_group] = CartItem.contract_type)
      )
      PacketItems = PacketItems.map(UserContractCartItemFactory.create)
    } catch (ex) {
      toast.error(
        `Oh no! We failed to load your contracting cart. ${ex}`.replace(
          /Error: /g,
          ''
        ),
        { autoClose: 12500, position: toast.POSITION.TOP_RIGHT }
      )
      console.error(`Failed to preview user contract cart.  ${ex}`)
      return { error: ex }
    }

    this.#_checkout = { PacketItems, packetContractTypes }
    return this.#_checkout
  }

  remove = async () => {
    this.#_checkout = null
    return this.#_checkout
  }

  #_packets = {}
  getPacket = async (packetId, force) => {
    if (this.#_packets.hasOwnProperty(packetId) && this.#_packets[packetId]) {
      if (force !== true) return this.#_packets[packetId]
    }

    let Packet
    try {
      Packet = await this.api().checkout(this.id(), packetId)
    } catch (ex) {
      console.error(`Failed to checkout contract packet. ex: ${ex}`)
    }

    if (Packet && `${Packet?.id}`.toUpperCase() === `${packetId}`.toUpperCase())
      this.#_packets[packetId] = Packet

    return Packet
  }

  openPacket = async (packetId, link) => {
    let open
    try {
      open = await this.api().open(this.id(), packetId, link)
    } catch (ex) {
      console.error(`Failed to open contract packet. ex: ${ex}`)
    }
    return open
  }

  completePacket = async (packetId) => {
    let complete
    try {
      complete = await this.api().complete(this.id(), packetId)
    } catch (ex) {
      console.error(`Failed to complete contract packet. ex: ${ex}`)
    }

    return complete
  }

  close = async (force, request) => {
    if (force) {
      try {
        return await UserContractingCartService.close(this.id(), request)
      } catch (ex) {
        console.error(`${ex}`)
        return false
      }
    }
  }

  expireAdobeAgreement = async (agreementId, request) => {
    try {
      return await UserContractingCartService.expireAdobeAgreement(
        this.id(),
        agreementId,
        request
      )
    } catch (ex) {
      console.error(`${ex}`)
      return false
    }
  }

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }
}

export default UserContractCart
