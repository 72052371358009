import React from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import PageHeading from './../../components/shared/PageHeading.component'
import GrowthReportWidget from './components/GrowthReportWidget/GrowthReportWidget.component'

import './GrowthReport.page.scss'

const GrowthReportPage = () => {
  const props = {}

  return (
    <DashboardLayout>
      <main id="GrowthReportPage" className="adminStyle mainSection mt-5 pb-5">
        <MDBContainer fluid className="mt-2">
          <PageHeading
            label="Growth Report"
            url={props?.match?.url}
            from={props?.location?.state?.from}
            goTo={(url) =>
              props.history.push(url, { from: '/reports/growth-report' })
            }
          />
          <MDBRow>
            <MDBCol size="12">
              <GrowthReportWidget />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </main>
    </DashboardLayout>
  )
}

export default GrowthReportPage
