import React, { Component } from 'react'
import { observer } from 'mobx-react-lite'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { MDBContainer } from 'mdbreact'
import PageHeading from '../../components/shared/PageHeading.component'
import Store from './store'
import CarrierPrioritiesContent from './components/CarrierPrioritiesContent.component'
import './CarrierPriorities.page.scss'

class CarrierPrioritiesPage extends Component {
  componentDidMount() {
    Store.init()
  }

  render() {
    return (
      <DashboardLayout>
        <main id="CarrierPrioritiesPage" className="mainSection pb-3">
          <MDBContainer fluid className="mt-5">
            <PageHeading label="Carrier Priorities" />
          </MDBContainer>
          <MDBContainer fluid>
            <CarrierPrioritiesContent />
          </MDBContainer>
        </main>
      </DashboardLayout>
    )
  }
}

export default CarrierPrioritiesPage
