import { makeAutoObservable } from 'mobx'
import AdminApi from './../../../api/admin-api/admin-api.js'
import { BehaviorSubject } from 'rxjs'
import UserProfileService from './../../../shared/services/UserProfile.service'
import ReportService from './../../../shared/services/Report.service'
import { subNavOpts } from './../variables'

class ReportsStore {
  constructor() {
    makeAutoObservable(this)
  }

  fetchParams = {}

  tableData = new BehaviorSubject({ columns: [], rows: [] })
  // getTableData = () => this.tableData;

  isFetching = false
  isExporting = false

  fetchPromise = async (
    category,
    report,
    start,
    stop,
    coverages,
    outputMode,
    opts,
    pagination,
    orderBy
  ) => {
    if (typeof category !== 'undefined') this.fetchParams.category = category

    if (typeof report !== 'undefined') this.fetchParams.report = report

    if (typeof start !== 'undefined') this.fetchParams.start = start

    if (typeof stop !== 'undefined') this.fetchParams.stop = stop

    if (typeof coverages !== 'undefined') this.fetchParams.coverages = coverages

    if (typeof outputMode !== 'undefined')
      this.fetchParams.output_mode = outputMode

    this.fetchParams.opts =
      opts && typeof opts === 'object' ? opts : this.fetchParams.opts
    if (!this.fetchParams.opts || typeof this.fetchParams.opts !== 'object')
      this.fetchParams.opts = {}

    if (pagination) {
      if (pagination?.per_page)
        this.fetchParams.opts.per_page = pagination.per_page
      if (pagination?.page) this.fetchParams.opts.page = pagination.page
    }

    if (orderBy && typeof orderBy === 'object' && Object.keys(orderBy).length)
      this.fetchParams.opts.order_by = {
        [Object.keys(orderBy)[0]]: Object.values(orderBy)[0],
      }

    this.isFetching = true

    report = this.fetchParams.report
      ? subNavOpts[this.fetchParams.category][this.fetchParams.report]
      : subNavOpts[this.fetchParams.category]

    let result
    try {
      result = await AdminApi.getReportData(
        this.fetchParams.category,
        report,
        this.fetchParams.start,
        this.fetchParams.stop,
        this.fetchParams.coverages,
        this.fetchParams.opts
      )
    } catch (ex) {
      this.isFetching = false
      console.error(ex)
    }

    let data = result?.data?.data || [],
      rows = [],
      columns = [],
      totals = false
    pagination = false

    if (typeof data === 'object' && Array.isArray(data?.rows)) {
      // NEW.
      rows = data.rows
      columns = []
      if (data.pagination) pagination = data.pagination
      if (data.totals && Object.keys(data.totals).length > 0)
        totals = data.totals
    } else if (
      Array.isArray(data?.data) &&
      data?.pagination &&
      typeof data.pagination === 'object'
    ) {
      // PREV.
      rows = data.data
      columns = []
      if (data.pagination) pagination = data.pagination
      if (data.totals && Object.keys(data.totals).length > 0)
        totals = data.totals
    } else {
      // OLDEST.
      rows = result?.data?.payload?.rows || data || []
      columns = result?.data?.payload?.columns || false
    }

    if (rows || columns) {
      this.tableData.next({ rows, columns, config: this.fetchParams })
      this.isFetching = false

      const results = { rows, columns, config: this.fetchParams }
      if (pagination) results.pagination = pagination
      if (totals) results.totals = totals
      return results
    }

    this.isFetching = false
    return false
  }

  fetch = async (
    category,
    report,
    start,
    stop,
    coverages,
    outputMode,
    opts
  ) => {
    if (typeof category !== 'undefined') this.fetchParams.category = category

    if (typeof report !== 'undefined') this.fetchParams.report = report

    if (typeof start !== 'undefined') this.fetchParams.start = start

    if (typeof stop !== 'undefined') this.fetchParams.stop = stop

    if (typeof coverages !== 'undefined') this.fetchParams.coverages = coverages

    if (typeof outputMode !== 'undefined')
      this.fetchParams.output_mode = outputMode

    this.fetchParams.opts =
      opts && typeof opts === 'object' ? opts : this.fetchParams.opts

    this.isFetching = true

    let result
    try {
      result = await AdminApi.getReportData(
        this.fetchParams.category,
        this.fetchParams.report
          ? subNavOpts[this.fetchParams.category][this.fetchParams.report]
          : subNavOpts[this.fetchParams.category],
        this.fetchParams.start,
        this.fetchParams.stop,
        this.fetchParams.coverages,
        this.fetchParams.opts
      )
    } catch (ex) {
      this.isFetching = false
      console.error(ex)
    }

    let data = result?.data?.data || [],
      rows = result?.data?.payload?.rows || data || [],
      columns = result?.data?.payload?.columns || false

    if (rows || columns) {
      this.tableData.next({ rows, columns, config: this.fetchParams })
      this.isFetching = false
      return { rows, columns, config: this.fetchParams }
    }

    this.isFetching = false
    return false
  }

  export = async ({
    category,
    report,
    start,
    stop,
    coverages,
    orderBy,
    search,
  }) => {
    report = report ? subNavOpts[category][report] : subNavOpts[category]

    this.isExporting = true

    let result
    try {
      result = await ReportService.export({
        category,
        report,
        start,
        stop,
        coverages,
        orderBy,
        search,
      })
    } catch (ex) {
      console.error(ex)
    }

    this.isExporting = false

    if (result && result.url) {
      window.open(result.url)
      return true
    }

    return false
  }

  canSelectDivision = () => {
    if (UserProfileService.isA('system-admin')) return true

    let details = UserProfileService.getUserDetails()
    return details && parseInt(details.u_upline_id) === 0
  }

  updAndFetch = async (params) => {
    if (params && typeof params === 'object') {
      Object.keys(params).forEach((key) => {
        this.fetchParams[key] = params[key]
      })
      return this.fetch()
    }
  }

  fetchDrillDown = async (name, config) => {
    let method, results
    switch (name) {
      case 'agent-district-points':
        method = this._agentDistrictPoints
        break
      case 'agent-region-points':
        method = this._agentRegionPoints
        break
      case 'agent-division-points':
        method = this._agentDivisionPoints
        break
      case 'agent-career-points':
        method = this._agentCareerPoints
        break
      default:
        break
    }

    if (method && typeof method) {
      try {
        results = await method(config)
      } catch (ex) {
        console.log('Failed to fetch additional reporting data. ', ex)
        return false
      }
    }

    return results
  }

  _agentCareerPoints = async (config) => {
    let result
    if (config?.user_id) {
      result = await AdminApi.getReportData(
        'career-points',
        'getAgentCareerPoints',
        this.fetchParams.start,
        this.fetchParams.stop,
        this.fetchParams.coverages,
        { user_id: config?.user_id }
      )
    }

    return result?.data?.data
  }

  _agentDistrictPoints = async (config) => {
    let result
    if (config?.user_id) {
      result = await AdminApi.getReportData(
        'district-points',
        'getAgentDistrictPoints',
        this.fetchParams.start,
        this.fetchParams.stop,
        this.fetchParams.coverages,
        { user_id: config?.user_id }
      )
    }

    return result?.data?.data
  }

  _agentRegionPoints = async (config) => {
    let result
    if (config?.user_id) {
      result = await AdminApi.getReportData(
        'region-points',
        'getAgentRegionPoints',
        this.fetchParams.start,
        this.fetchParams.stop,
        this.fetchParams.coverages,
        { user_id: config?.user_id }
      )
    }

    return result?.data?.data
  }

  _agentDivisionPoints = async (config) => {
    let result
    if (config?.user_id) {
      result = await AdminApi.getReportData(
        'division-points',
        'getAgentDivisionPoints',
        this.fetchParams.start,
        this.fetchParams.stop,
        this.fetchParams.coverages,
        { user_id: config?.user_id }
      )
    }

    return result?.data?.data
  }

  getDivisions = async () => {
    let divisionList = []
    try {
      divisionList = await AdminApi.getDivisions()
    } catch (ex) {
      divisionList = []
    }

    divisionList = divisionList && divisionList.data && divisionList.data.data
    divisionList = Array.isArray(divisionList) ? divisionList : []
    divisionList = divisionList.map((division) => ({
      text: `${division.d_name}`,
      value: `${division.id}`,
    }))

    if (divisionList.length > 0)
      divisionList.unshift({ value: 'all', text: 'All Divisions' })

    return divisionList
  }
}

export default new ReportsStore()
