import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  MDBBtn,
  MDBAlert,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from 'mdbreact'
import ContentHtml from './../../content/ContentHtml/ContentHtml.component'
import UserProfileService from './../../../shared/services/UserProfile.service'
import UserContractCartItemFactory from './../../../shared/factories/user-contract-cart-item.factory'
import ACMStore from './../AgentContractManager.store'

import './ContractingCartItemList.scss'

const ContractingCartItemList = ({ activeSpecs }) => {
  const getCarrierByCartItem = (CartItem) =>
    (ACMStore?.Carriers || [])
      .filter(
        (Carrier) =>
          parseInt(Carrier.id()) === parseInt(CartItem.get('carrier_id'))
      )
      .shift()

  const removeCarrierCartItem = async (CartItem, Carrier) => {
    if (CartItem) {
      const isNew = typeof CartItem?.isNew === 'function' && !!CartItem.isNew(),
        cartId = CartItem.get('cart_id'),
        contractId =
          !isNew && !isNaN(CartItem?.get('user_contract_id'))
            ? parseInt(CartItem?.get('user_contract_id'))
            : null,
        Contract =
          contractId > 0
            ? ACMStore.Contracts.filter(
                (Contract) => parseInt(Contract.id()) === contractId
              ).shift()
            : null

      // can not delete started contracts.
      if (Contract) {
        if (Contract.get('disposition') !== 'unsent') {
          alert(
            'Can not delete this contract cart item since the contract has already been started.'
          )
          return
        }
      }

      ACMStore.remove('CartItem', CartItem)
      await CartItem.delete()

      if (Contract) await Contract.delete()

      let CartItems = await UserContractCartItemFactory.findAllByCartId(cartId)

      if (
        CartItems.filter((CI) => CI.get('item_status') !== 'ABANDONED')
          .length === 0
      ) {
        ACMStore.Cart.set('cart_status', 'OPEN')
        await ACMStore.Cart.save()
      }
    }
  }

  const renderTagOrAction = (Cart, CartItem, Carrier) => {
    const isAdmin = UserProfileService.isA(
        ['system-admin', 'internal-admin'],
        true
      ),
      cartIsOnboarding = Cart && parseInt(Cart?.get('cart_onboarding')) === 1

    if (Cart && !Cart?.isNew()) {
      if (Cart.get('cart_status') === 'CLOSED') {
        if (CartItem.get('item_status') === 'DRAFT')
          return (
            <MDBAlert className="full-width" color="secondary">
              Not Yet Started.
            </MDBAlert>
          )
        if (CartItem.get('item_status') === 'EXECUTED')
          return (
            <MDBAlert className="full-width" color="info">
              Not Yet Completed.
            </MDBAlert>
          )
        if (CartItem.get('item_status') === 'COMPLETED')
          return (
            <MDBAlert className="full-width" color="success">
              Completed
            </MDBAlert>
          )
      } else if (Cart.get('cart_status')) {
        // Draft Items can be deleted by anyone
        // on NON-ONBOARDING Carts -OR- BY ADMINS
        // on ONBOARDING Carts.
        if (CartItem.get('item_status') === 'DRAFT') {
          return (
            <>
              <MDBAlert
                className={
                  !cartIsOnboarding || (cartIsOnboarding && isAdmin)
                    ? 'half-width'
                    : 'full-width'
                }
                color="secondary"
              >
                Not Yet Started.
              </MDBAlert>
              {!cartIsOnboarding || (cartIsOnboarding && isAdmin) ? (
                <button
                  className="alert alert-danger half-width"
                  onClick={() => removeCarrierCartItem(CartItem, Carrier)}
                >
                  Remove&nbsp;
                  <MDBIcon icon="trash" />
                </button>
              ) : (
                <></>
              )}
            </>
          )
        }

        // Executed Items can be deleted by ADMINS only
        else if (CartItem.get('item_status') === 'EXECUTED') {
          return (
            <>
              <MDBAlert
                className={isAdmin ? 'half-width' : 'full-width'}
                color="info"
              >
                Not Yet Completed.
              </MDBAlert>
              {isAdmin ? (
                <button
                  className="alert alert-danger half-width"
                  onClick={() => removeCarrierCartItem(CartItem, Carrier)}
                >
                  Remove&nbsp;
                  <MDBIcon icon="trash" />
                </button>
              ) : (
                <></>
              )}
            </>
          )
        } else if (CartItem.get('item_status') === 'COMPLETED')
          return (
            <MDBAlert className="full-width" color="success">
              Completed
            </MDBAlert>
          )
      }
    }
  }

  const renderCartItem = (CartItem, idx) => {
    const Carrier = getCarrierByCartItem(CartItem)

    return (
      <li
        key={`contracting-cart-list-item-carrier-${CartItem.get(
          'carrier_id'
        )}-${idx}`}
      >
        <div className="cart-item cart-item-profit">
          {Carrier?.renderPriorityIndicator(
            UserProfileService.get('usertype_id')
          ) || 'X'}
        </div>
        <div className="cart-item cart-item-carrier">
          {Carrier?.get('c_name')}
        </div>
        <div className="cart-item cart-item-description">
          {Carrier?.get('c_description')}
        </div>
        <div className={'cart-item cart-item-removal'}>
          {renderTagOrAction(ACMStore?.Cart, CartItem, Carrier)}
        </div>
      </li>
    )
  }

  const renderCartItemList = () => {
    return (
      <ul
        className={
          'contract-cart-item-list ' +
          (['ACTIVE', 'OPEN'].includes(ACMStore?.Cart?.get('cart_status'))
            ? 'is-removable'
            : '')
        }
      >
        <li>
          <div className="cart-item cart-item-profit">Profit:</div>
          <div className="cart-item cart-item-carrier">Carrier:</div>
          <div className="cart-item cart-item-description">Description:</div>
          <div className={'cart-item cart-item-removal'}></div>
        </li>
        {(ACMStore?.CartItems || [])
          .filter(
            (CartItem) =>
              CartItem?.get('item_status') &&
              CartItem.get('item_status') !== 'ABANDONED'
          )
          .map(renderCartItem)}
      </ul>
    )
  }

  return (
    <MDBContainer id="ContractingCartItemListComponent" fluid>
      <MDBRow>
        <MDBCol size="12">
          <ContentHtml contentSlug="contracting-cart-list-how-to" />
        </MDBCol>
        <MDBCol size="12">{renderCartItemList()}</MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(ContractingCartItemList)
