import { makeAutoObservable } from 'mobx'
import { canAccessLink } from './../../shared/utilities/linkAccessFilter.function'
import {
  MAJOR_NAV_LINKS,
  MINOR_NAV_LINKS,
} from './../../constants/nav-links.constants'
import { getUrl } from './AgentNavLinks.component'
import UserAlertsStore from './../users/UserAlertsIndicator/UserAlerts.store'
import UserProfileService from './../../shared/services/UserProfile.service'

class NavigationStore {
  constructor() {
    makeAutoObservable(this)
  }

  major = []
  // minor = []

  build = () => {
    let major = MAJOR_NAV_LINKS.filter((link) => canAccessLink(link, true)),
      minor = Object.values(MINOR_NAV_LINKS)

    major.sort((a, b) => `${a.label}`.localeCompare(`${b.label}`, 'en'))
    minor.sort((a, b) => `${a?.label}`.localeCompare(`${b?.label}`, 'en'))

    major.forEach((item) => {
      if (item?.children?.length > 0) {
        item.children.sort((a, b) =>
          (a.label ?? '').localeCompare(b.label ?? '', 'en')
        )
      }
    })

    minor.forEach((item) => {
      if (item?.children?.length > 0) {
        item.children.sort((a, b) =>
          (a.label ?? '').localeCompare(b.label ?? '', 'en')
        )
      }
    })

    this.major = major

    this.minor = minor
      .map((link) => {
        // 1. Ensure this minor nav link is permitted.
        if (!canAccessLink(link, true)) return false

        // 2. Ensure this minor nav link has viable children records.
        // heres the other menu bug.
        let children = Array.isArray(link?.children)
          ? link.children.filter((child) => canAccessLink(child, true))
          : null
        if (Array.isArray(children) && children.length === 0) return false

        return { ...link, children }
      })
      .filter((n) => !!n)
  }

  onLockedClick = (link, data) => {
    data = data && typeof data === 'object' ? data : {}

    switch (link.locked) {
      case 'HAS_OUTSTANDING_LEADER_REQUIREMENTS':
        const { location } = data
        return UserAlertsStore.checkRequirements(
          UserProfileService.getUserId(),
          location?.pathname + (location?.search ? location.search : ''),
          true
        )
    }
  }
}

export default new NavigationStore()
