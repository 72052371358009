let goalBlocks,
  origFormulas = {},
  formulas = {},
  calcValues = {},
  recalc = [],
  output = {}

// evaluate formula for value.
const _evalFormula = (fieldName) => {
  let v = eval(formulas[fieldName]) // eslint-disable-line no-eval
  v = !isNaN(parseFloat(v)) ? parseFloat(v).toFixed(3) : v
  if (goalBlocks[fieldName].questionId)
    calcValues[goalBlocks[fieldName].questionId] = v

  // store values in final output (calculated) object.
  output[fieldName] = v

  // remove the formula no longer needing calculation.
  delete formulas[fieldName]
}

const _getFormulaCalcValues = (fieldName) => {
  let vals = {}

  if (!origFormulas.hasOwnProperty(fieldName))
    origFormulas[fieldName] = formulas[fieldName]

  formulas[fieldName]
    .replace(/[^A-Za-z]/g, '')
    .split('')
    .forEach(
      (i) =>
        (vals[i] = calcValues.hasOwnProperty(i) ? calcValues[i] : undefined)
    )

  return vals
}

const canCalculate = () => {
  // make sure there are no incomplete
  // fields that require numeric input
  // to complete calculation formulas.
  if (goalBlocks && typeof goalBlocks === 'object') {
    let invalid = Object.keys(goalBlocks).filter(
      (k) => !goalBlocks[k].formula && isNaN(parseFloat(goalBlocks[k].value))
    )
    return invalid.length === 0
  }

  return false
}

const clearCalculated = () => {
  Object.keys(goalBlocks)
    .filter((k) => goalBlocks[k].formula)
    .forEach((k) => (goalBlocks[k].value = ''))
  return goalBlocks
}

const calculate = (gBlocks) => {
  // 1. Set the GoalBlocks object so the
  // entire function group can access the
  // goal values.
  goalBlocks = gBlocks
  Object.keys(goalBlocks)
    .filter((k) => !isNaN(goalBlocks[k].value))
    .forEach(
      (k) => (goalBlocks[k].value = parseFloat(goalBlocks[k].value).toFixed(3))
    )

  // 2. Make sure we have all the required
  // values needed before we attempt to calcuate
  // the values.
  if (!canCalculate()) return clearCalculated()

  // 3. Reset all previously calculated values.
  formulas = {}
  origFormulas = {}
  calcValues = {}
  recalc = [
    'goal_calls',
    'metric_calls_to_client',
    'metric_avg_client_earnings',
  ]
  output = {}

  // 4. Identify all required formula values.
  Object.keys(goalBlocks).forEach((k) => {
    if (goalBlocks[k].formula) {
      formulas[k] = goalBlocks[k].formula
      formulas[k]
        .replace(/[^A-Za-z]/g, '')
        .split('')
        .filter((i) => !calcValues.hasOwnProperty(i))
        .forEach((i) => (calcValues[i] = undefined))
    }
  })

  // 5. Collect the native formula values.
  // These are the values that were entered
  // by the user and DO NOT require any
  // calculations.
  Object.keys(goalBlocks)
    .filter((i) => goalBlocks[i].questionId)
    .forEach(
      (k) =>
        (calcValues[goalBlocks[k].questionId] = !isNaN(
          parseFloat(goalBlocks[k].value)
        )
          ? parseFloat(goalBlocks[k].value)
          : undefined)
    )

  // 6. Update formulas, replacing the questionIds
  // in each formula with the native, user-supplied values.
  Object.keys(formulas).forEach((k) => {
    let vals = _getFormulaCalcValues(k)

    if (Object.keys(vals).filter((v) => isNaN(vals[v])).length) recalc.push(k)
    else
      Object.keys(vals).forEach(
        (v) => (formulas[k] = formulas[k].replace(new RegExp(v, 'g'), vals[v]))
      )
  })

  // 7. Iterate through the formulas object
  // and attempt to evaluate all formulas where
  // the only required forumla values are native,
  // user-supplied values.
  recalc = recalc.filter((val, idx, self) => self.indexOf(val) === idx)
  Object.keys(formulas)
    .filter((k) => recalc.indexOf(k) < 0)
    .forEach((k) => _evalFormula(k))
  recalc
    .filter((fieldName) => origFormulas.hasOwnProperty(fieldName))
    .forEach((fieldName) => {
      formulas[fieldName] = origFormulas[fieldName]

      let vals = _getFormulaCalcValues(fieldName)
      if (!Object.keys(vals).filter((v) => isNaN(vals[v])).length)
        Object.keys(vals).forEach(
          (v) =>
            (formulas[fieldName] = formulas[fieldName].replace(
              new RegExp(v, 'g'),
              vals[v]
            ))
        )

      _evalFormula(fieldName)
    })

  // 8. Complete the remaining formulas that
  // require dynamic values from previous formula
  // calculations.
  Object.keys(formulas).forEach((k) => {
    let vals = _getFormulaCalcValues(k)
    Object.keys(vals).forEach(
      (v) => (formulas[k] = formulas[k].replace(new RegExp(v, 'g'), vals[v]))
    )

    _evalFormula(k)
  })

  // 9. Prep number formatting for input masks
  Object.keys(output).forEach((k) => {
    if (goalBlocks[k].min) {
      if (goalBlocks[k].value === null || goalBlocks[k].value === undefined) {
        goalBlocks[k].value = output[k]
      }
      goalBlocks[k].minValue = output[k]
    } else {
      goalBlocks[k].value = output[k]
    }
  })

  return goalBlocks
}

export default calculate
