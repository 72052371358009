import React from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import ContentHtml from './../../components/content/ContentHtml/ContentHtml.component'
import moment from 'moment'
import PageHeading from './../../components/shared/PageHeading.component'
import MetricsIntakeStore from './store'
import MetricsHistoryTable from './components/MetricsHistoryTable/MetricsHistoryTable.component'
import PerformanceForm from './components/PerformanceForm/PerformanceForm.component'
import AttendanceForm from './components/AttendanceForm/AttendanceForm.component'
import UserKpiMessaging from './components/UserKpiMessaging/UserKpiMessaging.component'
import { MDBDatePicker, MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import WeeklyMarketingCommitmentForm from './components/WeeklyMarketingCommitmentForm/WeeklyMarketingCommitmentForm.component'

import './KpiTrackingPage.scss'

class KpiTrackingPage extends React.Component {
  _setPerformanceDate = async (perfDate, force) => {
    if (
      !this.state.isLoading &&
      (this.state.performanceDate !== perfDate || force)
    ) {
      this.setState({ isLoading: true, performanceDate: perfDate })
      await MetricsIntakeStore.fetchByDate(perfDate)
      this.setState({ isLoading: false })
    }
  }

  constructor() {
    super()
    this.state = {
      isLoading: false,
      performanceDate: moment().format('YYYY-MM-DD'),
    }
  }

  componentDidMount() {
    MetricsIntakeStore.fetchDefinitions()

    this._setPerformanceDate(moment().format('YYYY-MM-DD'), true)
  }

  render() {
    return (
      <>
        <DashboardLayout>
          <main id="KpiTrackingPage" className="adminStyle mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <PageHeading
                label="Key Performance Indicator (KPI) Tracking"
                url={this.props?.match?.url}
                from={this.props?.location?.state?.from}
                goTo={(url) =>
                  this.props.history.push(url, { from: '/kpi-tracking' })
                }
              />
              <MDBRow className="date-intake">
                <MDBCol size="10">
                  <label className="text-indigo" htmlFor="performance_date">
                    Performance Entry Date:
                  </label>
                </MDBCol>
                <MDBCol size="2">
                  <MDBDatePicker
                    id="performance_date"
                    disabled={this.state.isLoading}
                    format={'MM/DD/YYYY'}
                    name="performance_date"
                    value={
                      moment(
                        this.state.performanceDate,
                        'YYYY-MM-DD'
                      ).toDate() || ''
                    }
                    getValue={(value) =>
                      this._setPerformanceDate(
                        moment(value).format('YYYY-MM-DD')
                      )
                    }
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="forms-intake">
                <MDBCol size="12" sm="4">
                  <ContentHtml contentId="86" />
                  <UserKpiMessaging />
                </MDBCol>
                <MDBCol size="12" sm="8">
                  <PerformanceForm />
                  <WeeklyMarketingCommitmentForm />
                  <AttendanceForm />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
            <MDBContainer fluid className="mt-5">
              <MDBRow className="forms-intake">
                <MDBCol size="12" style={{ padding: 0 }}>
                  <MetricsHistoryTable
                    editByDate={(date) => this._setPerformanceDate(date)}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </>
    )
  }
}

export default KpiTrackingPage
