import React, {useState} from 'react'
import { observer } from 'mobx-react-lite'
import AdminApi from './../../../../api/admin-api/admin-api'
import ToolTip from './../../../../components/shared/ToolTip/ToolTip.component'
import moment from "moment"
import {
	UIInput
} from './../../../../components/forms/form-fields'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon
} from 'mdbreact'
import Store from './../../shared/Enrollment.store';

import './IntakeFormIdentity.scss'

const FIELDS = ['u_fname','u_lname','u_email', 'npn'];

const EmailValidation = {int: null, callback: async ({value, upline}) => {
	return new Promise(async (resolve, reject) => {

		let testResults;
		try {
			testResults = await Promise.all([
					AdminApi.checkEmailExist({email: value}),
					AdminApi.isValidProspects({id: upline?.id, email: value})
				]);
		}
		catch (ex) {
			console.log(ex);
		}

		testResults = (Array.isArray(testResults) ? testResults : []).filter(result => (result && parseInt(result?.status) === 200)).map(result => {
			return result && result?.data;
		});

		let emailExists = testResults.shift()?.data,
				prospectManager = testResults.shift()?.data;

		if (emailExists && typeof emailExists === 'object')
			return resolve(['EMAIL_EXISTS',emailExists]);

		if (prospectManager && prospectManager?.manager_name)
			return resolve(['IS_PROSPECT',prospectManager]);

		return resolve(true)
	})
}};

const getAgentStatus = (active) =>
{
	if (parseInt(active) === 1)
		return 'Active';
	if (parseInt(active) === 0)
		return 'Disabled';
	if (parseInt(active) === -1)
		return 'Protected';
	if (parseInt(active) === -2)
		return 'Blacklisted';
}

const IntakeFormIdentity = ({upline, onComplete}) =>
{
	const [submitted, setSubmitted] = useState(false);

	const renderEmailExistsError = (emailExists) => 
	{
		return (
				<ToolTip label={'Email Exists'} placement='≈' isVisible={true} content={
					<table className='email-exists-table'>
						<thead>
							<tr>
								<th colSpan='2'>Invalid Email!</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Email:</td>
								<td>{emailExists?.u_email}</td>
							</tr>
							<tr>
								<td>Upline:</td>
								<td>{emailExists?.u_upline}</td>
							</tr>
							{
								emailExists?.u_enrolled !== '0000-00-00'
									? <tr>
											<td>Enrolled:</td>
											<td>{moment(emailExists?.u_enrolled).format('MM/DD/YYYY')}</td>
										</tr>
									: <></>
							}
							<tr>
								<td>Status:</td>
								<td>{getAgentStatus(emailExists?.u_active)}</td>
							</tr>
							{
								!emailExists?.u_active
									?	<tr>
											<td colSpan='2'>Contact Broker Support for details.</td>
										</tr>
									: <></>
							}
						</tbody>
					</table>
				} />
			)
	}

	const renderProspectExistsError = (prospectExists) => 
	{
		return (
				<ToolTip label={'Email Designated'} placement='bottom' isVisible={true} content={
					<table className='email-exists-table'>
						<thead>
							<tr>
								<th colSpan='2'>Invalid Email!</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td colSpan='2'>This email is designated as a prospect for {prospectExists.manager_name}.</td>
							</tr>
						</tbody>
					</table>
				} />
			)
	}

	const checkCanEmailEnroll = async ({value}) =>
	{
		if (value) {

			// if timer is set, clear it out.
			if (EmailValidation.int) {
				window.clearTimeout(EmailValidation.int);
				EmailValidation.int = null;
			}

			return new Promise((resolve, reject) => {
				EmailValidation.int = setTimeout(async () => {

					let result = await EmailValidation.callback({value, upline});

					window.clearTimeout(EmailValidation.int);
					EmailValidation.int = null;

					if (result === true)
						return resolve({
													isValid: true,
													errors: []
												})

					if (result[0] === 'EMAIL_EXISTS') {
						return resolve({
													isValid: false,
													errors: [renderEmailExistsError(result[1])]
												})
					}

					if (result[0] === 'IS_PROSPECT') {
						return resolve({
													isValid: false,
													errors: [renderProspectExistsError(result[1])]
												})
					}
				},250);
			});
		}
	}

	const onKeyDown = (evt) =>
	{
		try {
			if (evt.key.toLowerCase() === 'enter' && !submitted)
				onSubmit();
		}
		catch (ex) {}
	}

	const onSubmit = (evt) =>
	{
		if (evt)
			evt.preventDefault();

		if (!submitted)
			setSubmitted(true)

		if (Store.isValid(FIELDS))
			onComplete('IntakeFormProfile');

		return false;
	}

  return (
    <MDBContainer 
		tag='form' 
		fluid id='IntakeFormIdentityComponent' 
		className='intake-form' 
		onSubmit={onSubmit} 
		noValidate
	>
		<MDBRow>
			<MDBCol 
				size="12" 
				className="stage-heading"
			>
				<h3>
					<strong>Account Profile</strong>
				</h3>
			</MDBCol>
		</MDBRow>
    	<MDBRow>
    		<MDBCol size='12' sm='6'>
    			<UIInput 
					label='Legal First Name'
					name='u_fname'
					type='text'
					onChange={(evt) => Store.intake.u_fname = evt.target.value}
					required={true}
					value={Store.intake.u_fname}
					rules={{required: true, minLength: 2, maxLength: 255, format: 'name'}}
					showValidity={submitted}
					onValidityChange={(validity) => Store.validity.u_fname = validity}
					onKeyDown={onKeyDown}
				/>
        	</MDBCol>
    		<MDBCol size='12' sm='6'>
    			<UIInput 
					label='Legal Last Name'
					name='u_lname'
					type='text'
					onChange={(evt) => Store.intake.u_lname = evt.target.value}
					required={true}
					value={Store.intake.u_lname}
					rules={{required: true, minLength: 2, maxLength: 255, format: 'name'}}
					showValidity={submitted}
					onValidityChange={(validity) => Store.validity.u_lname = validity}
					onKeyDown={onKeyDown}
    			/>
        	</MDBCol>
    		<MDBCol size='12' sm='6'>
    			<UIInput 
					label='Email Address'
					name='u_email'
					type='email'
					onChange={(evt) => Store.intake.u_email = evt.target.value}
					required={true}
					value={Store.intake.u_email}
					rules={{required: true, maxLength: 255, format: 'email', callback: async ({value}) => await checkCanEmailEnroll({value})}}
					showValidity={submitted}
					onValidityChange={(validity) => Store.validity.u_email = validity}
					onKeyDown={onKeyDown}
    			/>
        	</MDBCol>
			<MDBCol size='12' sm='6'>
				<UIInput 
					label="NPN Number"
					name='npn'
					type='text'
					onChange={(evt) => Store.intake.npn = evt.target.value}
					required={true}
					value={Store.intake.npn}
					rules={{required: true, maxLength: 255, pattern: /^\d+$/ }}
					showValidity={submitted}
					onValidityChange={(validity) => Store.validity.npn = validity}
					onKeyDown={onKeyDown}
    			/>
			</MDBCol>
      	</MDBRow>
      	<MDBRow className='intake-action-btns'>
      	<MDBCol size='12' sm='6' className='offset-sm-3'>
      		<MDBBtn type='submit' className={'intake-action-btn '+(!Store.isValid(FIELDS)?'appear-disabled':'')}>
      			Next: Enter Profile Details&nbsp;<MDBIcon icon="angle-double-right" size="lg" />
      		</MDBBtn>
      	</MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(IntakeFormIdentity)
