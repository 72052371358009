import BaseModelFactory from './base.factory'
import UserTermService from './../services/UserTerm.service'
import UserTerm from './../models/user-term.model'

class UserTermFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: UserTermService, Model: UserTerm }
    )
  static findAllByUserId = async (userId) =>
    await this._findBy(
      { user_id: userId },
      { Service: UserTermService, Model: UserTerm }
    )
  static create = (payload) =>
    this._create(payload, { Service: UserTermService, Model: UserTerm })
}

export default UserTermFactory
