import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { MDBInput, MDBCol, MDBBtn, MDBAlert, MDBIcon } from 'mdbreact'
// import AvReferralsService from '../../../../shared/services/AvReferrals.service'
import UserProfileService from '../../../../shared/services/UserProfile.service'
import {
  UIDatePickerInput,
  UIInputButton,
} from './../../../../components/forms/form-fields'
import moment from 'moment'

import './AdminReferralForm.scss'

const AdminReferralForm = ({
  avId,
  referralPartnerId,
  Store,
  onPaymentChange,
}) => {
  const emptyPayment = {
    av_id: avId,
    referral_partner_id: referralPartnerId,
    amt_total: null,
    amt_paid: null,
    amt_balance: null,
    notes: [],
    paid_at: null,
    id: null,
    created_at: undefined,
    updated_at: undefined,
  }
  const [btnAttr, setBtnAttr] = useState({
      label: 'Save Referral Partner Payment',
      disabled: false,
    }),
    // [amountPaid, setAmountPaid] = useState(null),
    // [amountBalance, setAmountBalance] = useState(null),
    // [amountTotal, setAmountTotal] = useState(null),
    [notes, setNotes] = useState(null),
    [deleting, setDeleting] = useState(null),
    [payment, setPayment] = useState(emptyPayment),
    paymentId = Store.payment && Store.payment?.id, //toJS(Store.payment)
    payments = toJS(Store.payments)
  useEffect(() => {
    onPaymentChange({ ...payment, ...(toJS(Store.payment) || emptyPayment) })
    setPayment({ ...payment, ...(toJS(Store.payment) || emptyPayment) })
    setNotes((toJS(Store.payment) || emptyPayment)?.notes[0]?.note || '')
  }, [paymentId])

  const calcBalance = (total, paid) => {
    let balance = [isNaN(total), total === null].includes(true)
      ? null
      : [isNaN(paid), paid === null].includes(true)
      ? total
      : total - paid
    if (!isNaN(balance) && balance !== null)
      return parseFloat(balance).toFixed(2)
    return null
  }

  const updateAvReferral = async () => {
    setBtnAttr({ label: 'Loading...', disabled: true })

    let isValid = true
    const payload = { ...payment },
      isNew = !payload?.id || isNaN(payload.id)

    payload.amt_total =
      payload.amt_total && !isNaN(payload.amt_total)
        ? parseFloat(payload.amt_total).toFixed(2)
        : null
    payload.amt_paid =
      payload.amt_paid && !isNaN(payload.amt_paid)
        ? parseFloat(payload.amt_paid).toFixed(2)
        : null

    payload.paid_at =
      (
        (payload.paid_at && `${payload.paid_at}`.match(/^\d{4}-\d{2}-\d{2}/)) ||
        []
      ).shift() || false
    if (!payload.paid_at) delete payload.paid_at
    payload.referral_partner_id =
      payload?.referral_partner_id && !isNaN(payload.referral_partner_id)
        ? payload.referral_partner_id
        : referralPartnerId

    if (
      isNaN(payload.amt_total) ||
      payload.amt_total < 0 ||
      payload.amt_total === null
    ) {
      setBtnAttr(
        (btnAttr.label = { label: 'Invalid Amount Total', disabled: true })
      )
      isValid = false
    }

    if (
      isNaN(payload.amt_paid) ||
      payload.amt_paid < 0 ||
      payload.amt_paid === null
    ) {
      if (payload.amt_paid < 0) {
        setBtnAttr(
          (btnAttr.label = { label: 'Invalid Amount Paid', disabled: true })
        )
        isValid = false
      }
    }

    payload.amt_balance = calcBalance(payload.amt_total, payload.amt_paid)
    if (
      isNaN(payload.amt_balance) ||
      payload.amt_balance < 0 ||
      payload.amt_balance === null
    ) {
      if (payload.amt_balance < 0) {
        setBtnAttr(
          (btnAttr.label = { label: 'Invalid Amount Balance', disabled: true })
        )
        isValid = false
      }
    }

    if (payload.notes && typeof payload.notes === 'string') {
      try {
        payload.notes = JSON.parse(payload.notes)
      } catch (ex) {
        payload.notes = []
      }
    } else if (!Array.isArray(payload.notes)) {
      payload.notes = []
    }

    if (notes && `${notes}`.trim().length > 0) {
      payload.notes =
        payload.notes && Array.isArray(payload.notes) ? payload.notes : []
      payload.notes.push({
        noted_id: UserProfileService.getUserId(true),
        noted_by: [
          UserProfileService.get('u_fname', true),
          UserProfileService.get('u_lname', true),
        ]
          .join(' ')
          .trim(),
        noted_at: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
        note: notes,
      })
    }

    if (!isNew) payload.id = payment.id

    if (isValid) {
      // console.log("SAVE: ",{...payload,notes:JSON.stringify(payload.notes)});

      if (payload.notes && typeof payload.notes === 'object')
        payload.notes = JSON.stringify(payload.notes)

      try {
        let result = await Store[isNew ? 'storePayment' : 'updatePayment'](
          payload
        )
        setPayment((prevPayment) => ({ ...prevPayment, ...result }))
        onPaymentChange({ ...payment, ...result })
        setBtnAttr({ label: 'Saved!', disabled: true })
      } catch (ex) {
        console.error(`Failed to save referral partner payment.  ${ex}`)
        setBtnAttr({ label: `${ex}` })
      }
    }

    setTimeout(
      () =>
        setBtnAttr({ label: 'Save Referral Partner Payment', disabled: false }),
      2000
    )

    // if (!referralPartnerId || !avId) return

    // setBtnAttr({ label: 'Loading...', disabled: true })
    // if (
    //   !isNaN(amountTotal) &&
    //   amountTotal !== null &&
    //   [!!paidAt, !!amountTotal, !!amountPaid].includes(true)
    // ) {
    //   const payload = {
    //     av_id: avId,
    //   }

    //   payload.amt_total = amountTotal
    //   payload.amt_paid =
    //     !isNaN(amountPaid) && amountPaid !== null ? amountPaid : 0
    //   payload.amt_balance = amountTotal - payload.amt_paid

    //   if (paidAt) payload.paid_at = paidAt
    //   if (notes) payload.notes = notes

    //   try {
    //     await AvReferralsService.store(payload)
    //     setBtnAttr({ label: 'SUCCESS!', disabled: true })
    //   } catch (err) {
    //     setBtnAttr({ label: 'Error!', disabled: true })
    //     console.error(err)
    //   }
    // } else {
    //   !!referralPartnerId
    //     ? setBtnAttr(
    //         (btnAttr.label = {
    //           label: 'Fill Required Fields...',
    //           disabled: true,
    //         })
    //       )
    //     : setBtnAttr(
    //         (btnAttr.label = { label: 'Referral Not Found!', disabled: true })
    //       )
    // }
    // setTimeout(
    //   () => setBtnAttr({ label: 'Update Referral Pay', disabled: false }),
    //   2000
    // )
  }

  const deleteNote = async (pId, idx) => {
    const p = Store.payments.filter((pt) => pt.id === pId)[0]
    if (!p) return
    const paymentToDelete = { ...toJS(p) }
    paymentToDelete.notes.splice(idx, 1)
    paymentToDelete.notes = JSON.stringify(paymentToDelete.notes)
    await Store.updatePayment(paymentToDelete)
    setPayment(toJS(Store.payment))
    setNotes(toJS(Store.payment)?.notes[0]?.note || '')
  }

  const deletePayment = async (id) => {
    try {
      setDeleting(id)
      await Store.deletePayment(id)
      if (!Store.payments.length) return setPayment(emptyPayment)
      setPayment(toJS(Store.payment))
      onPaymentChange(toJS(Store.payment))
      setNotes(toJS(Store.payment)?.notes[0]?.note || '')
    } catch (ex) {
      console.error(`Failed to delete referral partner payment.  ${ex}`)
    }
    setDeleting(null)
  }

  const PaidAt = (
    <UIDatePickerInput
      name="paid_at"
      label="Paid On"
      required={true}
      value={
        payment?.paid_at
          ? moment(payment.paid_at.split('T').shift()).format('MM/DD/YYYY')
          : null
      }
      onChange={(e) =>
        setPayment((prevPayment) => ({
          ...prevPayment,
          paid_at: e?.target?.value
            ? moment(e.target.value).format('YYYY-MM-DD')
            : '',
        }))
      }
    />
  )
  const setToToday = () =>
    setPayment((prevPayment) => ({
      ...prevPayment,
      paid_at: moment().format('YYYY-MM-DD'),
    }))
  const amt_balance =
    !isNaN(payment.amt_balance) &&
    payment.amt_balance !== null &&
    payment.amt_balance >= 0
      ? parseFloat(payment.amt_balance).toFixed(2)
      : false

  return (
    <form
      id="AdminReferralFormComponent"
      className={
        'content-wrapper ' + (Store.isLoading.payment ? 'is-loading' : '')
      }
    >
      <div className="heading fw--500 fs--1rem">
        Manage Referral Partner Payment
      </div>
      <MDBCol size="6">
        <MDBInput
          type="number"
          label="Amount Total *"
          min="0"
          name="amt_total"
          value={(payment && payment?.amt_total) || ''}
          onChange={(e) => {
            let val = e.target.value
            setPayment({
              ...payment,
              amt_total: val,
              amt_balance: calcBalance(val, payment.amt_paid),
            })
          }}
        />
      </MDBCol>
      <MDBCol size="6">
        <MDBInput
          type="number"
          label="Amount Paid"
          min="0"
          name="amt_paid"
          value={(payment && payment?.amt_paid) || ''}
          onChange={(e) => {
            let val = e.target.value
            setPayment({
              ...payment,
              amt_paid: val,
              amt_balance: calcBalance(payment.amt_total, val),
            })
          }}
        />
      </MDBCol>
      <MDBCol size="8">
        <UIInputButton
          input={PaidAt}
          btnContent={<div className="fs--075rem fw--500">Set to Today</div>}
          onClick={setToToday}
          btnPosition={'right'}
        />
      </MDBCol>
      <MDBCol
        size="4"
        style={{ lineHeight: '0.8rem' }}
        className="text--center"
      >
        <MDBAlert
          color={
            amt_balance !== false
              ? parseFloat(amt_balance) === 0
                ? 'success'
                : 'danger'
              : 'secondary'
          }
        >
          <small>Amount Balance:</small>
          <br />
          <span className="text--right fs--075rem fw--500">
            {amt_balance === false ? 'N/A' : `$${amt_balance}`}
          </span>
        </MDBAlert>
      </MDBCol>
      <MDBCol size="12">
        <MDBInput
          type="textarea"
          label="Notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
        <ul className="payment-notes">
          {Array.isArray(payments) && payments.length > 0 ? (
            <>
              <li>
                <span>Payments</span>
              </li>
              {payments.map((p) => {
                return (
                  <li key={`referral-partner-payment-${p.id}`}>
                    <table width="100%">
                      <tr>
                        <td>Paid:</td>
                        <td>
                          ${p.amt_paid} at{' '}
                          {moment()
                            .utc(p.paid_at, 'YYYY-MM-DD')
                            .format('MM/DD/YYYY')}
                        </td>
                        <td title="Delete payment" className="text-right">
                          {p.id === deleting ? (
                            <>
                              <span>
                                <i className="fa fa-spin fa-spinner"></i>
                              </span>
                            </>
                          ) : (
                            <MDBIcon
                              className="cursor-pointer text--red"
                              icon="trash"
                              onClick={() => deletePayment(p.id)}
                            />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Notes:</td>
                        <td colSpan={2}>
                          {p.notes.map((note, idx) => {
                            return (
                              <div key={`referral-partner-payment-note-${idx}`}>
                                <div className="d-flex justify-content-between">
                                  <span>{note.note}</span>
                                  <span title="Delete note">
                                    <MDBIcon
                                      className="cursor-pointer text--red"
                                      icon="trash"
                                      onClick={() => deleteNote(p.id, idx)}
                                    />
                                  </span>
                                </div>
                                <div className="d-flex justify-content-between align-items-baseline">
                                  <b>{note.noted_by}</b>
                                  <small>
                                    {moment()
                                      .utc(note.noted_at, 'YYYY-MM-DD HH:mm:ss')
                                      .format('MM/DD/YYYY h:mm a')}
                                  </small>
                                </div>
                              </div>
                            )
                          })}
                        </td>
                      </tr>
                    </table>
                  </li>
                )
              })}
              {/* {payment.notes.map((note, idx) => {
                return (
                  <li key={`referral-partner-payment-${idx}`}>
                    <table width="100%">
                      <tr>
                        <td colSpan={2}>{note.note}</td>
                        <td className="text--right">
                          <MDBIcon
                            className="cursor-pointer text--red"
                            icon="trash"
                            onClick={() => deleteNote(idx)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="fs--075rem text--left">
                          {note.noted_by}
                        </td>
                        <td className="fs--075rem text--right">
                          {moment()
                            .utc(note.noted_at, 'YYYY-MM-DD HH:mm:ss')
                            .format('MM/DD/YYYY h:mm a')}
                        </td>
                      </tr>
                    </table>
                  </li>
                )
              })} */}
            </>
          ) : (
            <></>
          )}
        </ul>
      </MDBCol>
      <MDBCol size="12">
        <MDBBtn
          disabled={btnAttr.disabled}
          onClick={() => updateAvReferral()}
          color="primary"
          block
        >
          {btnAttr.label}
        </MDBBtn>
      </MDBCol>
      <div className="loading-wrapper">
        <div>
          <i className="fa fa-spin fa-spinner"></i>
        </div>
        <div>Loading Referral Partner Payment Details</div>
      </div>
    </form>
  )
}

export default observer(AdminReferralForm)
