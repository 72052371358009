import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import React from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import LeaderboardsStore from './store/LeaderboardsPage.store'
import LeaderboardConfigurator from './components/LeaderboardConfigurator/LeaderboardConfigurator.component'
import CurrentTopPip from './components/CurrentTopPip/CurrentTopPip.component'
import BreakTheBarDetails from './../../components/shared/BreakTheBarDetails/BreakTheBarDetails.component'
import LeaderboardsResultsTable from './components/LeaderboardsResultsTable/LeaderboardsResultsTable.component'
import ContentHtml from '../../components/content/ContentHtml/ContentHtml.component'

import './LeaderboardsPage.scss'

const LeaderboardsPageComponent = () => {
  let type
  switch (toJS(LeaderboardsStore.fetchParams).type) {
    case 'points':
      type = 'Points'
      break
    case 'av':
      type = 'AV'
      break
    case 'lives':
      type = 'ACA Lives'
      break
    default:
      break
  }

  return (
    <>
      <DashboardLayout>
        <main id="LeaderboardsPage" className="adminStyle mainSection pb-5">
          <MDBContainer fluid className="mt-2">
            <MDBRow className="report-title">
              <MDBCol size="12" md="5">
                <h2>{type} Leaderboard</h2>
                <ContentHtml contentSlug="weekly-break-bar-instruction" />
              </MDBCol>
              <MDBCol size="12" md="7">
                {type === 'Points' ? (
                  <BreakTheBarDetails
                    agentScope={LeaderboardsStore.getAgentScope()}
                  />
                ) : (
                  <></>
                )}
              </MDBCol>
            </MDBRow>
            <hr />
          </MDBContainer>
          <CurrentTopPip />
          <LeaderboardConfigurator />
          <LeaderboardsResultsTable isFetching={LeaderboardsStore.isFetching} />
        </main>
      </DashboardLayout>
    </>
  )
}

const LPC = observer(LeaderboardsPageComponent)

class LeaderboardsPage extends React.Component {
  render() {
    return <LPC />
  }
}

export default LeaderboardsPage
