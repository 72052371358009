import { observer } from 'mobx-react-lite'
import React from 'react'
import { MDBIcon, MDBPopper } from 'mdbreact'

import './ToolTip.scss'

const ToolTip = ({
  label,
  icon,
  content,
  placement,
  iconPosition,
  isVisible,
  className,
  iconColor,
}) => {
  placement = placement ? placement : 'top'

  const renderIcon = ({ style }) => {
    style = style && typeof style === 'object' ? style : {}

    if (typeof icon === 'string') return <MDBIcon icon={icon} style={style} />

    if (icon !== false) return <MDBIcon icon="fas fa-question" style={style} />
  }

  const renderLabel = ({ style }) => {
    style = style && typeof style === 'object' ? style : {}

    if (typeof label === 'string') return <span style={style}>{label}</span>

    if (label) return label
  }

  const renderTrigger = () => {
    if (iconPosition === 'left')
      return (
        <>
          {renderIcon({
            style: {
              marginRight: '3px',
              ...(iconColor ? { color: iconColor } : {}),
            },
          })}
          {renderLabel({
            style: { ...(iconColor ? { color: iconColor } : {}) },
          })}
        </>
      )

    return (
      <>
        {renderLabel({
          style: {
            marginRight: '3px',
            ...(iconColor ? { color: iconColor } : {}),
          },
        })}
        {renderIcon({ style: { ...(iconColor ? { color: iconColor } : {}) } })}
      </>
    )
  }

  return (
    <MDBPopper
      domElement
      placement={placement}
      isVisible={isVisible}
      clickable
      className={`ToolTipComponent ${className || ''}`}
    >
      <div className="ui-tooltip-trigger" title="Details...">
        {renderTrigger()}
      </div>
      <div className="ui-tooltip-content">{<>{content}</>}</div>
    </MDBPopper>
  )
}

export default observer(ToolTip)
