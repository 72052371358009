import BaseModelFactory from './base.factory'
import BATeamService from './../services/BATeam.service'
import BATeam from './../models/ba-team.model'

class BATeamFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: BATeamService, Model: BATeam }
    )
  static findById = async (id) =>
    await this._findBy({ id: id }, { Service: BATeamService, Model: BATeam })
  static findAllById = async (id) =>
    await this._findAllBy({ id: id }, { Service: BATeamService, Model: BATeam })
  static create = (payload) =>
    this._create(payload, { Service: BATeamService, Model: BATeam })
}

export default BATeamFactory
