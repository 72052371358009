import BaseModelInstance from './base.model'
import { AgentPackageFeature as ModelDefinition } from './model-definitions'

class AgentPackageFeature extends BaseModelInstance {
  afterSet = (key, value) => {}

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }
}

export default AgentPackageFeature
