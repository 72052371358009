import { makeAutoObservable } from 'mobx'
import UserMetaService from '../../../shared/services/UserMeta.service';
import UserProfileService from '../../../shared/services/UserProfile.service';

class UpdatePaymentMethodModalStore {
  constructor() {
    makeAutoObservable(this)
  }

  isOpen = false;
  initCalled = 0;

  async init() {
    if (this.initCalled === 0) {
      const metaData = await UserMetaService.search({
        search: {
          user_id: UserProfileService.getUserId(),
          meta_key: `billing---update-payment-method`,
        },
      }) 
  
      this.isOpen = metaData?.models?.shift()?.meta_value === "1"
      this.initCalled = this.initCalled + 1
    }
  }

  toggleModal() {
    this.isOpen = !this.isOpen;
  }
}

export default new UpdatePaymentMethodModalStore()
