import BaseModelFactory from './base.factory'
import CarrierPriorityService from './../services/CarrierPriority.service'
import CarrierPriority from './../models/carrier-priority.model'

class CarrierPriorityFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: CarrierPriorityService, Model: CarrierPriority }
    )
  static findAll = async () =>
    await this._findAllBy(
      {},
      { Service: CarrierPriorityService, Model: CarrierPriority }
    )
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: CarrierPriorityService, Model: CarrierPriority }
    )
  static findAllByCarrierId = async (id) =>
    await this._findAllBy(
      { carrier_id: id },
      { Service: CarrierPriorityService, Model: CarrierPriority }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: CarrierPriorityService,
      Model: CarrierPriority,
    })
}

export default CarrierPriorityFactory
