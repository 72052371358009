import RestApi from "./../../api/rest-api-client";
const srvcEndpoint = "/stripe/customers";
export default {
	search: 		async (req, opts) 			=> 		RestApi.request("GET", 		srvcEndpoint, req, opts),
	get: 				async (id, req, opts) 	=> 		RestApi.request("GET", 		srvcEndpoint, id, req, opts),
	store:  		async (data, opts) 			=> 		RestApi.request("POST", 	srvcEndpoint, data, opts),
	update:  		async (id, data, opts) 	=> 		RestApi.request("PUT", 		srvcEndpoint, id, data, opts),
	delete: 		async (id, opts)  			=> 		RestApi.request("DELETE", srvcEndpoint, id, opts),

	createByUserId: 					async (id, data) 	=> 	RestApi.request("POST", 	srvcEndpoint, id, data, {endpoint: `/stripe/customers/${id}`}),
	setDefaultPaymentMethod:  async (id, data) 	=> 	RestApi.request("PUT", 		srvcEndpoint, id, data, {endpoint: `/stripe/customers/${id}/default-payment-method/${data.payment_method_id}`}),
	deletePaymentMethod: 	 		async (id, data) 	=> 	RestApi.request("DELETE", srvcEndpoint, id, {endpoint: `/stripe/customers/${id}/payment-methods/${data.payment_method_id}`}),
	notifyPaymentAdded:  			async (id, data) 	=> 	RestApi.request("POST", 	srvcEndpoint, id, data, {endpoint: `/stripe/customers/${id}/payment-method-added`}),
};
