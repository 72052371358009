import React, { Component, Fragment } from 'react'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import SignatureAgentBenefitsTable from './inc/SignatureAgentBenefits.table'
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact'
import UserProfileService from './../../shared/services/UserProfile.service'
import AdminContentEditPopupComponent from './../../components/adminShared/AdminContentEditPopupComponent'

class SignatureAgentBenefits extends Component {
  state = {
    create: false,
  }

  render() {
    return (
      <Fragment>
        <DashboardLayout>
          <main className="adminStyle mainSection">
            <MDBContainer fluid className="mt-5">
              <h2>
                USA Benefits Group Signature Agent Benefits
                {UserProfileService.isA([
                  'system-admin',
                  'internal-admin',
                  'agency-owner',
                ]) ? (
                  <div className="d-inline-block">
                    <MDBBtn
                      size="sm"
                      tag="a"
                      floating
                      onClick={() => this.setState({ create: true })}
                      gradient="blue"
                      title={'Add New Signature Agent Benefit'}
                    >
                      <div className="text-dark text-capitalize">
                        <AdminContentEditPopupComponent
                          type="RTE"
                          actionType="add"
                          slugName="order-leads"
                          color="primary"
                          callback={this.fetchAgentLeadsData}
                          isLink={true}
                          forceOpen={this.state.create}
                        />
                      </div>
                    </MDBBtn>
                  </div>
                ) : (
                  <></>
                )}
              </h2>
              <hr />
            </MDBContainer>
            <MDBContainer fluid className="pb-5">
              <MDBRow className="agent-goals-profile">
                <MDBCol>
                  <SignatureAgentBenefitsTable history={this.props.history} />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default SignatureAgentBenefits
