import React, { Component, Fragment } from 'react';
import DashboardLayout from "../../components/Admin/DashboardLayout.jsx";
import {MDBBtn, MDBContainer, MDBDataTable, MDBIcon, MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import {toast} from "react-toastify";
import AdminApi from "../../api/admin-api/admin-api.js";



class Usertypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userTypesList: '',
            loading: '',
            error: '',
            modal: false,
            confirmModal: false,
            viewdata: '',
            data: {
                columns: [
                    {
                        label: 'Name',
                        field: 'name',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Update',
                        field: 'update',
                        sort: 'asc',
                        width: 270
                    },
                    {
                        label: 'Delete',
                        field: 'delete',
                        sort: 'asc',
                        width: 270
                    }
                ],
                rows: [],
            }
        } // state end
    }

    componentDidMount() {
        this.fetchUserTypesData();
    }

    //convert date from sec to current date
    toDateTime = (secs) => {
        let t = new Date(1970, 0, 1); // Epoch
        t.setSeconds(secs);
        return t;
    }

    //toggle modal
    toggle = () => {
        this.setState({modal: !this.state.modal});
    };

    //calling delete confirm modal
    confirm_DeleteUserType = (data) => {
        this.confirm_toggle(data);
    }

    //Toggle delete confirm modal
    confirm_toggle = (data = {}) => {
        this.setState({confirmModal: !this.state.confirmModal, ...data});
    }

    //forming rows for data table from fetched data
    setRows = () => {
        const {userTypesList} = this.state;
        if(userTypesList && userTypesList.length) {
            let rows = userTypesList.map((row) => {
                const {displayname, id} = row;
                const self = this;

                function editUserType() {
                    self.props.history.push(`/add-usertype/${id}/edit`);
                }

                function deleteUserType() {
                    self.confirm_DeleteUserType({viewdata: row});
                }


                const editClick = (
                    <div className={'d-flex align-items-center justify-content-center'}>
                        <div className={'cursor-pointer d-inline-block'} role={'button'} onClick={editUserType}>
                            <MDBIcon icon="edit" size="2x" className="blue-text p-1" />
                        </div>
                    </div>
                );

                const deleteClick = (
                    <div className={'d-flex align-items-center justify-content-center'}>
                        <div className={'cursor-pointer d-inline-block'} role={'button'} onClick={deleteUserType}>
                            <MDBIcon icon="trash" size="2x" className="red-text p-1" />
                        </div>
                    </div>
                );

                return {
                    name: displayname,
                    update: editClick,
                    delete: deleteClick
                }
            });
            this.setState({
                data: {...this.state.data, rows: rows}
            });
        }
    }

    //Fetch data
    fetchUserTypesData = async () => {
        this.setState({loading: true});
        AdminApi.getUserTypes().then((result) => {
            this.setState({userTypesList: result.data.data || []});
        }).then(() => {
            this.setState({loading: false});
            this.setRows();
        }).catch(error => {
            this.setState({loading: false});
            toast.error("Something went wrong!", {position: toast.POSITION.TOP_RIGHT});
        });
    }

    //Delete data
    _DeleteUserType = async (id) => {
        this.setState({deleting: true});
        const data = {type: "usertypes", id: id};

        AdminApi.deleteUserType(data).then((result) => {
            this.setState({deleting: false});
            this.confirm_toggle();
            toast.success("UserType successfully deleted", {position: toast.POSITION.TOP_RIGHT});
            this.fetchUserTypesData();
        }).catch(error => {
            this.setState({deleting: false});
            toast.error(`${error.message}`, {position: toast.POSITION.TOP_RIGHT});
        });
    }

    render() {
        toast.configure();
        const{data, confirmModal, loading} = this.state;

        const createNew = () => {
            this.props.history.push('/add-usertype');
        };

        return (
            <Fragment>
                <DashboardLayout>
                <main className="mainSection pb-5">
                    <MDBContainer fluid className="mt-5">
                        <h2>Usertypes
                            <div className="d-inline-block">
                                <MDBBtn tag="a" floating gradient="blue" title={'Add New Usertype'} onClick={createNew}>
                                    <MDBIcon icon="plus" />
                                </MDBBtn>
                            </div>
                        </h2>
                        <hr />
                        {loading ?
                            <h4 className={'p4'}>Loading...</h4> :
                            <div className="content-wrapper"><MDBDataTable responsive striped order={['name', 'asc']} entriesOptions={[100, 150, 200]} entries={100}  bordered small data={data} className="text-capitalize" /></div>
                        }
                        <MDBModal size={"sm"} isOpen={confirmModal} toggle={this.confirm_toggle}>
                            <MDBModalHeader toggle={this.confirm_toggle}>
                                Delete UserType
                            </MDBModalHeader>
                            <MDBModalBody>
                                <div className="h5">Are you sure you want to delete this UserType?</div>
                                <MDBBtn color="secondary" size={'sm'} onClick={this.confirm_toggle}>
                                    Close
                                </MDBBtn>
                                <MDBBtn color="danger" size={'sm'} onClick={() => {this._DeleteUserType(this.state.viewdata.id)}}>
                                    {this.state.deleting ? 'Deleting...' : 'Delete'}
                                </MDBBtn>
                            </MDBModalBody>
                        </MDBModal>
                    </MDBContainer>
                </main>
                </DashboardLayout>
            </Fragment>
          );
    }
}

export default Usertypes;
