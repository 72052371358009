import BaseRouterGuard from './BaseRouter.guard'
import UserProfileService from './../services/UserProfile.service'
import UsertypeService from './../services/Usertype.service'

export class RequiresAuthGuard extends BaseRouterGuard {
  _isRequired = true

  get isRequired() {
    return this._isRequired
  }

  constructor(isRequired) {
    super()
    this._isRequired = isRequired !== false
  }

  // return FALSE if FAILS, stops GUARDS chain.
  // return TRUE if PASSES & you want to stop GUARDS chain.
  // return undefined if PASSES & you want to continue GUARDS chain.
  authorize() {
    // Temporary, until instance handling is in place.
    return (
      (this.isRequired &&
        parseInt(UserProfileService.get('id')) > 0 &&
        parseInt(UserProfileService.get('usertype_id')) > 0) ||
      !this.isRequired
    )
    // Proposed
    // return !!(this.User?.isNew && this.User.isNew() === false && this.User?.get('usertype_id') && !isNaN(this.User?.get('usertype_id')))
  }
}

export class RequiresUsertypesGuard extends BaseRouterGuard {
  _usertypes = []

  constructor(usertypes) {
    super()

    this._usertypes = (
      Array.isArray(usertypes) ? usertypes : [usertypes]
    ).filter((n) => !!n).map(n => n && !isNaN(n) ? UsertypeService.idToName(n) : n);
  }

  authorize() {
    return (
      this._usertypes.length === 0 ||
      (this._usertypes.length > 0 && UserProfileService.isA(this._usertypes))
    )
  }
}

export class BlockedUsertypesGuard extends BaseRouterGuard {
  constructor(params) {
    super(params || {})
  }
  authorize() {
    return
  }
}

export class RequiresOnboardingGuard extends BaseRouterGuard {
  _isRequired = true

  get isRequired() {
    return this._isRequired
  }

  constructor(isRequired) {
    super()
    this._isRequired = isRequired !== false
  }

  authorize() {
    if (this._isRequired)
      return parseInt(UserProfileService.get('has_onboarded')) === 1
        ? undefined
        : false
  }
}