import React from 'react'
import { observer } from 'mobx-react-lite'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import ContentHtmlCard from './../../../../components/content/ContentHtmlCard/ContentHtmlCard.component'
import { renderVideo } from '../../../../components/content/ContentVideo/renderVideo'
import TrainingValidator from '../../../TrainingPages/components/TrainingValidator/TrainingValidator.component'

const USE_INTRO_CONTENT = false

const AgentWelcomeIntro = ({
  isAffiliateAgent,
  isAssociateAgent,
  isSignatureAgent,
  video,
  init,
}) => {
  const introContentID = isAffiliateAgent
      ? 88
      : isAssociateAgent
      ? 83
      : isSignatureAgent
      ? 91
      : null,
    stepsContentID = isAffiliateAgent
      ? 94
      : isAssociateAgent
      ? 93
      : isSignatureAgent
      ? 92
      : null
  if (!video) return <></>
  return (
    <main id="AgentWelcomeIntroPage" className="mainSection">
      <MDBContainer>
        {USE_INTRO_CONTENT ? (
          <MDBRow>
            <MDBCol
              size="12"
              sm={video ? '4' : '0'}
              md={video ? '4' : '0'}
              lg={video ? '4' : '0'}
              className="video-wrapper"
            >
              {renderVideo(video.training_link, video.training_name)}
            </MDBCol>
            <MDBCol size={video ? '8' : '12'}>
              <ContentHtmlCard contentId={introContentID} fullHeight />
            </MDBCol>
          </MDBRow>
        ) : (
          <MDBRow>
            <MDBCol
              size="12"
              md="8"
              xl="10"
              className="video-wrapper offset-md-2 offset-xl-1"
            >
              {renderVideo(video.training_link, video.training_name)}
            </MDBCol>
          </MDBRow>
        )}
        {video && (!video.training_code || video.hasCompleted.current) ? (
          <></>
        ) : (
          <div className="my-4">
            <TrainingValidator
              trainings={video ? [video] : []}
              onSuccess={init}
              isOnboarding={true}
            />
          </div>
        )}
        <br />
        <MDBRow>
          <MDBCol size="12">
            <ContentHtmlCard contentId={stepsContentID} />
          </MDBCol>
        </MDBRow>
        <br />
      </MDBContainer>
    </main>
  )
}

export default observer(AgentWelcomeIntro)
