import React, { useState } from 'react'
import { MDBIcon, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact'

export const CarrierPrioritiesDataTable = ({ priorities }) => {
  const [order, setOrder] = useState()
  const columns = [
    {
      label: 'Logo',
      field: 'logo',
    },
    {
      label: 'Carrier',
      field: 'name',
      sortable: true,
    },
    {
      label: 'Description',
      field: 'description',
      sortable: true,
    },
  ]

  const _onToggle = (col) => () => {
    if (
      !columns.filter(
        (column) => column.field === col.field && column.sortable === true
      ).length
    )
      return
    if (!order || order.col !== col.field) {
      setOrder({
        col: col.field,
        dir: 'asc',
      })
      return
    }
    if (order.dir === 'asc') {
      setOrder({
        col: col.field,
        dir: 'desc',
      })
      return
    }
    setOrder(null)
  }
  const _onSort = (dir, col) => (e) => {
    e.stopPropagation()
    if (order && col.field === order.col && dir === order.dir) {
      setOrder(null)
      return
    }
    setOrder({
      col: col.field,
      dir,
    })
  }
  const _renderHeaderLabel = (col) => {
    return {
      label: (
        <div
          className={`table-col-sorter d-flex justify-content-between align-items-center ${
            col.sortable ? 'is-sortable' : ''
          } ${
            order && order.col && order.dir && order.col === col.field
              ? 'sort-' + order.dir.toLowerCase()
              : ''
          }`}
          onClick={_onToggle(col)}
        >
          <label>{col.label}</label>
          <div className="sorter">
            <div onClick={_onSort('asc', col)} className="up">
              <MDBIcon icon="angle-up" />
            </div>
            <div onClick={_onSort('desc', col)} className="down">
              <MDBIcon icon="angle-down" />
            </div>
          </div>
        </div>
      ),
      field: col.field,
    }
  }
  return (
    <MDBTable striped bordered small className="text-capitalize">
      <MDBTableHead columns={columns.map(_renderHeaderLabel)} />
      {priorities && priorities.length ? (
        <MDBTableBody
          rows={
            order
              ? priorities.filter(Boolean).sort((a, b) => {
                  return order.dir === 'asc'
                    ? a[order.col].localeCompare(b[order.col])
                    : b[order.col].localeCompare(a[order.col])
                })
              : priorities.filter(Boolean)
          }
        ></MDBTableBody>
      ) : (
        <MDBTableBody>
          <tr>
            <td colSpan={columns.length} className="text-center">
              No Data Found
            </td>
          </tr>
        </MDBTableBody>
      )}
    </MDBTable>
  )
}
