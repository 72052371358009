import React from 'react'
import { observer } from 'mobx-react-lite'
import { MDBBtn, MDBIcon } from 'mdbreact'
import ACMStore from './../AgentContractManager.store'

import './SaveContractingCartButton.scss'

const SaveContractingCartButton = ({ onCheckout }) => {
  const getCarrierByCartItem = (CartItem) =>
      (ACMStore?.Carriers || [])
        .filter(
          (Carrier) =>
            parseInt(Carrier.id()) === parseInt(CartItem.get('carrier_id'))
        )
        .shift(),
    verifyCartItemCarrier = (CartItem) => getCarrierByCartItem(CartItem),
    MinimumCartItems = ACMStore?.CartItems.map(verifyCartItemCarrier).filter(
      (CartItem) => !!CartItem
    )

  return (
    <div id="SaveContractingCartButtonComponent">
      <MDBBtn
        block
        className="start-checkout-btn"
        onClick={() => onCheckout(MinimumCartItems)}
        disabled={!MinimumCartItems || MinimumCartItems.length < 1}
      >
        {ACMStore.Cart?.get('cart_status') === 'OPEN'
          ? 'Start Contracting'
          : ACMStore.Cart?.get('cart_status') === 'ACTIVE'
          ? 'Continue Contracting'
          : 'Contracting Completed'}
        &nbsp;
        <MDBIcon icon="arrow-right" />
      </MDBBtn>
    </div>
  )
}

export default observer(SaveContractingCartButton)
