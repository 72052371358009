import React from 'react'
import { MDBCollapse, MDBRow, MDBCol, MDBBtn } from 'mdbreact'
import UserProfileService from './../../../shared/services/UserProfile.service'
import { TermSetFactory, TermFactory } from './../../../shared/factories'
import { getIp } from './../../../shared/utilities/getIp.function'

import './AgentTerms.scss'

const ENABLE_TERM_SETS = false

class AgentTermAcceptance extends React.Component {
  state = {
    isAccepting: false,
    isFetching: false,
    TermSets: {},
  }

  _fetchTerms = async () => {
    const fetchTermDocs = async () => {
      try {
        return await TermFactory.search({
          search: { is_enroll: 1 },
          pagination: false,
        })
      } catch (ex) {
        console.error('Failed to load enrollment terms.', ex)
      }
    }

    const fetchTermSets = async () => {
      try {
        return await TermSetFactory.search({
          search: { usertype_id: [91] },
          pagination: false,
        })
      } catch (ex) {
        console.error('Failed to load enrollment term sets.', ex)
      }
    }

    if (!this.state.isFetching) this.setState({ isFetching: true })

    const Response = await Promise.all([fetchTermDocs(), fetchTermSets()]),
      Terms = Response.shift(),
      TermSets = (Response.shift() || []).filter((TermSet, idx) => idx === 0)

    this.setState((prevState) => {
      prevState.TermSets = {}

      TermSets.forEach((TermSet) => {
        TermSet.parseTermStmt({ Terms })
        prevState.TermSets[TermSet.get('usertype_id')] = TermSet
      })

      prevState.isFetching = false

      return prevState
    })
  }

  _acceptTerms = async () => {
    const User = await UserProfileService.instance()
    if (User) {
      if (!this.state.isAccepting) this.setState({ isAccepting: true })

      let results
      try {
        results = await User.signTerms({
          term_slugs: [
            'sig-agent-terms',
            'sig-producer-eo',
            'non-captive-non-solicit',
            'subscriber-agreement',
          ],
          accepted_ip: await getIp(),
        })
      } catch (ex) {}

      if (
        this.props?.onAcceptance &&
        typeof this.props.onAcceptance === 'function'
      )
        this.props.onAcceptance()

      this.setState({ isAccepting: false })
    }
  }

  componentDidMount() {
    this._fetchTerms()
  }

  render() {
    return (
      <div id="AgentTermAcceptanceComponent">
        <MDBRow>
          <MDBCol size="12">
            <MDBCollapse isOpen={this.state.isFetchingTerms}>
              <div className="loading-spinner">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <i className="fa fa-spin fa-spinner" />
                      </td>
                      <td>Loading Terms</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </MDBCollapse>
            <MDBCollapse isOpen={!this.state.isFetchingTerms}>
              <ul>
                {Array.isArray(this.state.TermSets['91']?.get('term_set')) ? (
                  this.state.TermSets['91']
                    .get('term_set')
                    .map((term, idx) => (
                      <li
                        key={`sig-agent-term-${idx}`}
                        dangerouslySetInnerHTML={{ __html: term }}
                      ></li>
                    ))
                ) : (
                  <></>
                )}
              </ul>
              {this.props?.canAccept ? (
                <MDBBtn
                  block
                  disabled={this.state.isFetching || this.state.isAccepting}
                  onClick={() => this._acceptTerms()}
                >
                  {this.state.isAccepting ? (
                    <>
                      <i className="fa fa-spin fa-spinner"></i>
                      &nbsp;Accepting...
                    </>
                  ) : (
                    'Accept Terms'
                  )}
                </MDBBtn>
              ) : (
                <></>
              )}
            </MDBCollapse>
          </MDBCol>
        </MDBRow>
      </div>
    )
  }
}

export default AgentTermAcceptance
