import BaseModelFactory from './base.factory'
import UserEventLogService from './../services/UserEventLog.service'
import UserEventLog from './../models/user-event-log.model'

class UserEventLogFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: UserEventLogService, Model: UserEventLog }
    )
  static findAll = async () =>
    await this._findAllBy(
      {},
      { Service: UserEventLogService, Model: UserEventLog }
    )
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: UserEventLogService, Model: UserEventLog }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: UserEventLogService,
      Model: UserEventLog,
    })
}

export default UserEventLogFactory
