import { makeAutoObservable, toJS } from 'mobx'
import UserProfileService from './../../../shared/services/UserProfile.service'
import UserService from './../../../shared/services/User.service'
import UsertypeService from './../../../shared/services/Usertype.service'
import UserDocumentService from './../../../shared/services/UserDocument.service'
import {
  LedgerTransactionFactory,
  UserFactory,
  CarrierFactory,
  CarrierFmoFactory,
  LeadVendorFactory,
} from './../../../shared/factories'
import moment from 'moment'
import appConstants from '../../../constants/appConstants'

const LC_CATEGORIES = {
  'AWARD-LEVEL-1': { label: 'Award Level 1', permitted: ['AgentAchievement'] },
  'AWARD-LEVEL-2': { label: 'Award Level 2', permitted: ['AgentAchievement'] },
  'AWARD-LEVEL-3': { label: 'Award Level 3', permitted: ['AgentAchievement'] },
  'AWARD-LEVEL-4': { label: 'Award Level 4', permitted: ['AgentAchievement'] },
  'AWARD-LEVEL-5': { label: 'Award Level 5', permitted: ['AgentAchievement'] },
  'AWARD-LEVEL-6': { label: 'Award Level 6', permitted: ['AgentAchievement'] },
  'AWARD-LEVEL-7': { label: 'Award Level 7', permitted: ['AgentAchievement'] },
  'AWARD-LEVEL-8': { label: 'Award Level 8', permitted: ['AgentAchievement'] },
  'AWARD-LEVEL-9': { label: 'Award Level 9', permitted: ['AgentAchievement'] },
  'AWARD-LEVEL-10': {
    label: 'Award Level 10',
    permitted: ['AgentAchievement'],
  },
  'AWARD-LEVEL-11': {
    label: 'Award Level 11',
    permitted: ['AgentAchievement'],
  },
  'AWARD-LEVEL-12': {
    label: 'Award Level 12',
    permitted: ['AgentAchievement'],
  },
  'AWARD-LEVEL-13': {
    label: 'Award Level 13',
    permitted: ['AgentAchievement'],
  },
  'AWARD-LEVEL-14': {
    label: 'Award Level 14',
    permitted: ['AgentAchievement'],
  },
  'AWARD-LEVEL-15': {
    label: 'Award Level 15',
    permitted: ['AgentAchievement'],
  },
  'AWARD-LEVEL-16': {
    label: 'Award Level 16',
    permitted: ['AgentAchievement'],
  },
  'AWARD-LEVEL-17': {
    label: 'Award Level 17',
    permitted: ['AgentAchievement'],
  },
  'AWARD-LEVEL-18': {
    label: 'Award Level 18',
    permitted: ['AgentAchievement'],
  },
  'AWARD-LEVEL-19': {
    label: 'Award Level 19',
    permitted: ['AgentAchievement'],
  },
  'AWARD-LEVEL-20': {
    label: 'Award Level 20',
    permitted: ['AgentAchievement'],
  },
  'AWARD-LEVEL-21': {
    label: 'Award Level 21',
    permitted: ['AgentAchievement'],
  },
  'AWARD-LEVEL-22': {
    label: 'Award Level 22',
    permitted: ['AgentAchievement'],
  },
  'AWARD-LEVEL-23': {
    label: 'Award Level 23',
    permitted: ['AgentAchievement'],
  },
  'AWARD-LEVEL-24': {
    label: 'Award Level 24',
    permitted: ['AgentAchievement'],
  },
  'AWARD-LEVEL-25': {
    label: 'Award Level 25',
    permitted: ['AgentAchievement'],
  },
  'AWARD-LEVEL-26': {
    label: 'Award Level 26',
    permitted: ['AgentAchievement'],
  },
  'AWARD-LEVEL-27': {
    label: 'Award Level 27',
    permitted: ['AgentAchievement'],
  },
  'AWARD-LEVEL-28': {
    label: 'Award Level 28',
    permitted: ['AgentAchievement'],
  },
  'AWARD-LEVEL-29': {
    label: 'Award Level 29',
    permitted: ['AgentAchievement'],
  },
  'AWARD-LEVEL-30': {
    label: 'Award Level 30',
    permitted: ['AgentAchievement'],
  },
  'BAR-BREAKER': { label: 'Bar Breaker', permitted: ['AgentAchievement'] },
  'TOP-AGENT': { label: 'Top Agent', permitted: ['AgentAchievement'] },
  'WHEEL-OF-LOVE': { label: 'Wheel of Love', permitted: ['AgentAchievement'] },
  'FIRST-SALE': { label: 'First Sale', permitted: ['AgentAchievement'] },
  'ONBOARDING-COMPLETE': {
    label: 'Onboarding Complete',
    permitted: ['AgentAchievement'],
  },
  // 'SIG-AGENT-COMPLETE': {
  //   label: 'Sig Agent Complete',
  //   permitted: ['AgentAchievement'],
  // },
  'MOST-APPS-PER-CLIENT': {
    label: 'Most Apps per Client',
    permitted: ['AgentAchievement'],
  },
  'DDL-HOST': { label: 'DDL Host', permitted: ['BanvilleBonus'] },
  'PERFORMANCE-SPIFF': {
    label: 'Performance Spiff',
    permitted: ['AgentTransfer', 'CarrierBonus', 'FmoBonus'],
  },
  'BANVILLE-BONUS': {
    label: 'Banville Bonus',
    permitted: ['CashDeposit', 'Purchase '],
  },
  REIMBURSEMENT: {
    label: 'Reimbursement',
    permitted: ['AgentTransfer', 'CashDeposit', 'Purchase '],
  },
  EXPENSE: {
    label: 'BUCK$ Expense',
    permitted: [
      'SwagStoreTransfer',
      'TravelRequest',
      'RymaxTransfer',
      'LeadVendorTransfer',
      'CashWithdrawal',
    ],
  },
  CREDIT: { label: 'BUCK$ Credit', permitted: ['Purchase '] },
}

const getDownline = async (uplineId) => {
  let users = []

  try {
    users = users
      .concat(await UserService.getDownline(uplineId))
      .filter((user) => parseInt(user?.u_active) === 1)
      .map((user) => ({
        id: user.id,
        email: user.u_email,
        name: [user.u_fname, user.u_lname].join(' ').trim(),
        usertype_id: user.usertype_id,
        usertype: UsertypeService.idToName(user.usertype_id),
        signature: user.signature,
      }))
  } catch (ex) {
    console.error(`${ex}`)
  }

  return users
}

const getUser = async (userId) => {
  let User = null

  try {
    User = userId && (await UserFactory.findById(userId))
  } catch (ex) {
    console.error(`${ex}`)
  }

  return User || null
}

const getCarriers = async () => {
  let carriers = []

  try {
    carriers = carriers
      .concat((await CarrierFactory.search({ pagination: false })) || [])
      .filter((Carrier) => parseInt(Carrier?.get('c_active')) === 1)
      .map((Carrier) => ({
        id: Carrier.id(),
        name: Carrier.get('c_name'),
        description: Carrier.get('c_description'),
      }))
  } catch (ex) {
    console.error(`${ex}`)
  }

  return carriers
}

const getFmos = async () => {
  let fmos = []

  try {
    fmos = fmos
      .concat((await CarrierFmoFactory.search({ pagination: false })) || [])
      .map((Fmo) => ({ id: Fmo.id(), name: Fmo.get('fmo_name') }))
  } catch (ex) {
    console.error(`${ex}`)
  }

  return fmos
}

const getLeadVendors = async () => {
  let leadVendors = []

  try {
    leadVendors = leadVendors
      .concat((await LeadVendorFactory.search({ pagination: false })) || [])
      .map((LeadVendor) => ({
        id: LeadVendor.id(),
        name: LeadVendor.get('vendor_name'),
        options: LeadVendor.get('options'),
        deleted_at: LeadVendor.get('deleted_at'),
      }))
      .map((leadVendor) => ({
        ...leadVendor,
        options: leadVendor?.options
          ? leadVendor.options === 'string'
            ? JSON.parse(leadVendor.options)
            : leadVendor.options
          : {},
      }))
  } catch (ex) {
    console.error(`${ex}`)
  }

  return leadVendors
}

const getOrCreateWalletDocumentMeta = async (User, metaKey) => {
  // check user instance for required metas.
  // let UserMeta = User.meta()
  //   .filter((Meta) => Meta.get('meta_key') === metaKey)
  //   .shift()
  // if (UserMeta) return UserMeta

  // not found, search remote.
  let UserMeta = ((await (await User.meta(true)).key(metaKey)) || []).shift()
  if (UserMeta) {
    User.children('meta', UserMeta)
    return UserMeta
  }

  // not found, create new.
  UserMeta = await User.meta(true).create({ meta_key: metaKey })
  User.children('meta', UserMeta)
  if (UserMeta) return UserMeta

  return false
}

const REQUISITE_DOCUMENTS = {
    wallet_w9: async ({ User }) =>
      getOrCreateWalletDocumentMeta(User, 'wallet---wallet-w9'),
    wallet_dd: async ({ User }) =>
      getOrCreateWalletDocumentMeta(User, 'wallet---wallet-dd'),
  },
  TRANSFER_REQUISITES = {
    documents: {
      TravelRequest: ['wallet_w9', 'wallet_dd'],
      CashWithdrawal: ['wallet_w9', 'wallet_dd'],
    },
  }

async function getTransferPrerequisites(form, params) {
  const { User } = params || {},
    preReqs = { documents: null }

  if (TRANSFER_REQUISITES.documents?.[form])
    preReqs.documents = TRANSFER_REQUISITES.documents[form]

  if (Array.isArray(preReqs?.documents) && preReqs.documents.length) {
    let documents = {}
    ;(
      await Promise.all(
        preReqs.documents
          .filter(
            (preReq) => typeof REQUISITE_DOCUMENTS?.[preReq] === 'function'
          )
          .map((preReq) => {
            documents[`wallet---${preReq.replace(/_/g, '-')}`] = null
            return REQUISITE_DOCUMENTS?.[preReq]
          })
          .map((preReqFunc) => preReqFunc({ User }))
      )
    ).forEach((UserMeta) => {
      if (UserMeta && UserMeta.get('meta_key'))
        documents[UserMeta.get('meta_key')] = UserMeta
    })

    preReqs.documents = documents

    return preReqs
  }

  return null
}

class TransferStore {
  Users = []
  LeadVendors = []
  Carriers = []
  Fmos = []
  Agent = null
  Transaction = null
  Ledger = null
  minimumRequired = null
  availableStates = null
  transfer = {
    form: '',
    ledger_id: '',
    recipient_id: '',
    recipient_name: '',
    carrier_id: '',
    carrier_name: '',
    lead_vendor_id: '',
    lead_vendor_name: '',
    fmo_id: '',
    fmo_name: '',
    purpose: '',
    category: '',
    amount: '',
    lead_order_state: '',
  }
  transferRequirements = null
  pipLevel = null
  prevPipLevel = null
  pipPoints = null
  prevPipPoints = null
  teamPipPoints = null
  prevTeamPipPoints = null
  commissionLevelGoal = null
  prevCommissionLevelGoal = null
  commissionLevelRequirements = null
  meetsCommissionLevelRequirements = false
  isValid = false
  isValidating = false
  isSaving = false
  isTransitioning = false
  showErrors = false
  outcomeMsg = ''
  showErrorMsg = false
  showSuccessMsg = false
  travelRequestReceipts = []
  isDeletingTravelRequestReceipt = false

  constructor() {
    makeAutoObservable(this)
  }

  get isReadOnly() {
    return (
      this.isTransitioning ||
      ['CLOSED', 'CANCELED'].includes(this.Transaction?.get('trans_state'))
    )
  }

  get canUserModify() {
    return UserProfileService.isA(
      [
        'divisional-leader-admin',
        'internal-admin',
        'internal-staff',
        'system-admin',
      ],
      true
    )
  }

  get form() {
    return this.transfer.form
  }

  set form(form) {
    this.isTransitioning = true
    this.resolveTransferPrerequisites(form).finally(
      () => (this.isTransitioning = false)
    )
    this.transfer.form = form
  }

  get isDisabled() {
    if (this.isSaving) return true
    if (this.isValidating) return true
    if (this.isReadOnly) return true
    if (this.isTransitioning) return true

    if (this.Transaction && this.Transaction?.isNew() === true) return false

    // Prevent non-admin users from modifying form data.
    // Remaining users can modify all or some of transaction data.
    return !this.Transaction ? false : !this.canUserModify
  }

  setAgent(Agent, Ledger) {
    this.transfer.recipient_id = Agent?.id()
    this.transfer.recipient_name = Agent?.id()
    this.transfer.ledger_id = Ledger?.id()
    this.Ledger = Ledger || null
    this.Agent = Agent || null
  }

  fetch = async (transactionId) => {
    const current_year = Math.min(new Date().getFullYear(), 2025)
    const previous_year = Math.min(new Date().getFullYear() - 1, 2025)

    this.commissionLevelGoal =
      appConstants.commission_level_requirements[current_year]
    this.prevCommissionLevelGoal =
      appConstants.commission_level_requirements[previous_year]

    const [Users, Carriers, Fmos, LeadVendors, pipLevel, prevPipLevel] =
      await Promise.all([
        getDownline(UserProfileService.getUserId()),
        getCarriers(),
        getFmos(),
        getLeadVendors(),
        UserProfileService.getPipLevel(),
        UserProfileService.getPipLevelByYear(new Date().getFullYear() - 1),
      ])
    this.Users = Users.sort((a, b) => a.name.localeCompare(b.name))
    this.Carriers = Carriers.sort((a, b) => a.name.localeCompare(b.name))
    this.Fmos = Fmos.sort((a, b) => a.name.localeCompare(b.name))
    this.LeadVendors = LeadVendors.sort((a, b) => a.name.localeCompare(b.name))
    this.pipLevel =
      pipLevel && pipLevel?.level && !isNaN(pipLevel.level)
        ? pipLevel.level
        : !isNaN(pipLevel) && pipLevel > 0
        ? parseInt(pipLevel)
        : 0
    this.prevPipLevel =
      prevPipLevel && prevPipLevel?.level && !isNaN(prevPipLevel.level)
        ? prevPipLevel.level
        : !isNaN(prevPipLevel) && prevPipLevel > 0
        ? parseInt(prevPipLevel)
        : 0
    if (
      UserProfileService.isA([
        'district-leader',
        'regional-leader',
        'divisional-leader',
      ])
    ) {
      const [teamPipPoints, prevTeamPipPoints] = await Promise.all([
        UserProfileService.getTeamPipLevel(),
        UserProfileService.getTeamPipLevelByYear(new Date().getFullYear() - 1),
      ])
      this.teamPipPoints =
        teamPipPoints && teamPipPoints?.points && !isNaN(teamPipPoints.points)
          ? teamPipPoints.points
          : 0
      this.prevTeamPipPoints =
        prevTeamPipPoints &&
        prevTeamPipPoints?.points &&
        !isNaN(prevTeamPipPoints.points)
          ? prevTeamPipPoints.points
          : 0
    }

    this.pipPoints =
      pipLevel && pipLevel?.points && !isNaN(pipLevel.points)
        ? pipLevel.points
        : 0
    this.prevPipPoints =
      prevPipLevel && prevPipLevel?.points && !isNaN(prevPipLevel.points)
        ? prevPipLevel.points
        : 0

    switch (UsertypeService.idToName(UserProfileService.get('usertype_id'))) {
      case 'district-leader':
        this.meetsCommissionLevelRequirements =
          this.pipPoints >= this.commissionLevelGoal.district.leader ||
          this.prevPipPoints >= this.prevCommissionLevelGoal.district.leader ||
          this.teamPipPoints >= this.commissionLevelGoal.district.team ||
          this.prevTeamPipPoints >= this.prevCommissionLevelGoal.district.team
        break
      case 'regional-leader':
        this.meetsCommissionLevelRequirements =
          this.pipPoints >= this.commissionLevelGoal.region.leader ||
          this.prevPipPoints >= this.prevCommissionLevelGoal.region.leader ||
          this.teamPipPoints >= this.commissionLevelGoal.region.team ||
          this.prevTeamPipPoints >= this.prevCommissionLevelGoal.region.team
        break
      case 'divisional-leader':
        this.meetsCommissionLevelRequirements =
          this.pipPoints >= this.commissionLevelGoal.division.leader ||
          this.prevPipPoints >= this.prevCommissionLevelGoal.division.leader ||
          this.teamPipPoints >= this.commissionLevelGoal.division.team ||
          this.prevTeamPipPoints >= this.prevCommissionLevelGoal.division.team
        break
      default:
        this.meetsCommissionLevelRequirements =
          this.pipPoints >= this.commissionLevelGoal.agent ||
          this.prevPipPoints >= this.prevCommissionLevelGoal.agent
        break
    }

    if (transactionId) {
      let Transaction = (
        Array.isArray(this.Transactions) ? this.Transactions : []
      )
        .filter((T) => `${T.id()}` === `${transactionId}`)
        .shift()
      if (!Transaction)
        Transaction = await LedgerTransactionFactory.findById(transactionId)
      if (Transaction) this.edit(Transaction)
    }
  }

  category = (cat) => LC_CATEGORIES[cat]?.label

  categories = (filter) => {
    if (filter)
      return Object.keys(LC_CATEGORIES).filter((key) =>
        LC_CATEGORIES[key].permitted.includes(filter)
      )
    return Object.keys(LC_CATEGORIES)
  }

  reset = () => {
    this.minimumRequired = null
    this.availableStates = null
    this.transfer = {
      form: '',
      ledger_id: '',
      recipient_id: '',
      recipient_name: '',
      carrier_id: '',
      carrier_name: '',
      fmo_id: '',
      fmo_name: '',
      purpose: '',
      category: '',
      amount: '',
      lead_order_state: '',
    }
  }

  create = (Ledger) => {
    this.Ledger = Ledger || null
    this.transfer = {
      form: '',
      ledger_id: Ledger?.id() || '',
      recipient_id: '',
      carrier_id: '',
      carrier_name: '',
      fmo_id: '',
      fmo_name: '',
      purpose: '',
      category: '',
      amount: '',
      lead_order_state: '',
    }
  }

  edit = (Transaction) => {
    this.Transaction = Transaction

    const transfer = {
      form: this.Transaction?.get('type') || '',
      recipient_id: this.Transaction?.get('recipient_id') || '',
      recipient_name: '',
      carrier_id: this.Transaction?.get('carrier_id') || '',
      carrier_name: '',
      fmo_id: this.Transaction?.get('fmo_id') || '',
      fmo_name: '',
      purpose: this.Transaction?.get('purpose'),
      category: this.Transaction?.get('category'),
      amount:
        parseInt(this.Transaction?.get('credit')) > 0
          ? parseInt(this.Transaction?.get('credit'))
          : parseInt(this.Transaction?.get('debit')),
      trans_state: this.Transaction?.get('trans_state'),
      lead_order_state: '',
    }

    this.transfer = { ...this.transfer, ...transfer }
  }

  validate = async (opts) => {
    const { required } = { required: {}, ...(opts || {}) }
    const form = this.form
    this.showSuccessMsg = false
    this.isValidating = true
    this.showErrors = true

    // 1. Check for field completeness.
    let recipient_id =
        !!(!isNaN(this.transfer.ledger_id) && this.transfer.ledger_id > 0) &&
        !!(
          !isNaN(this.transfer.recipient_id) && this.transfer.recipient_id > 0
        ),
      carrier_id = !!(
        !isNaN(this.transfer.carrier_id) && this.transfer.carrier_id > 0
      ),
      fmo_id = !!(!isNaN(this.transfer.fmo_id) && this.transfer.fmo_id > 0),
      category = this.transfer.category,
      categories = this.categories(this.form),
      validity = {
        purpose:
          !!(
            this.transfer.purpose &&
            `${this.transfer.purpose}`.trim().length > 5
          ) || required?.purpose === false,
        category:
          !!((categories && categories.length === 1) || category) ||
          required?.category === false,
        amount: !!(
          !isNaN(this.transfer.amount) &&
          this.transfer.amount > 0 &&
          (!this.minimumRequired ||
            parseInt(this.transfer.amount) >= parseInt(this.minimumRequired))
        ),
        ...(this.form === 'AgentTransfer' ? { recipient_id } : {}),
        ...(this.form === 'CarrierBonus' ? { carrier_id } : {}),
        ...(this.form === 'FmoBonus' ? { fmo_id } : {}),
      },
      outcomeMsg = Object.keys(validity)
        .filter((v) => !validity[v])
        .map((v) =>
          v === 'recipient_id'
            ? 'Agent'
            : v === 'purpose'
            ? 'Reason, purpose or detail'
            : `${v.charAt(0).toUpperCase()}${v.substr(1)}`
        )

    outcomeMsg =
      outcomeMsg.length > 2
        ? [outcomeMsg.pop(), outcomeMsg.join(', ')].reverse().join(' and ')
        : outcomeMsg.length > 1
        ? outcomeMsg.join(' and ')
        : outcomeMsg.shift()

    // 2. If invalid fields exist, notify user.
    if (!!(outcomeMsg = (outcomeMsg || '').replace(/_id ?/g, ' ').trim())) {
      if ((this.showErrorMsg = !!outcomeMsg))
        this.outcomeMsg = `Please complete or correct the following fields: ${outcomeMsg}.`
      this.isValidating = false
      return false
    }

    if (!category && categories && categories.length)
      this.transfer.category = categories.shift()

    this.outcomeMsg = ''
    this.showErrorMsg = false
    this.isValidating = false
    return true
  }

  store = async () => {
    const form = this.form
    this.isSaving = true

    const Transaction = LedgerTransactionFactory.create({
      ledger_id: this.Ledger.id(),
      type: (form || '').replace(/([a-z0–9])([A-Z])/g, '$1-$2').toLowerCase(),
    })
    Transaction.set(
      'credit',
      [
        'AgentAchievement',
        'AgentTransfer',
        'CarrierBonus',
        'FmoBonus',
        'Purchase ',
        'CashDeposit',
      ].includes(form)
        ? parseInt(this.transfer.amount)
        : 0
    )
    Transaction.set(
      'debit',
      [
        'SwagStoreTransfer',
        'TravelRequest',
        'RymaxTransfer',
        'CashWithdrawal',
        'LeadVendorTransfer',
      ].includes(form)
        ? parseInt(this.transfer.amount)
        : 0
    )
    Transaction.set('ledger_id', this.transfer.ledger_id)
    Transaction.set('purpose', this.transfer.purpose)
    Transaction.set('category', this.transfer.category)
    Transaction.set('type', this.form)
    Transaction.set(
      'doner_id',
      this.form === 'AgentTransfer' ? this.transfer.doner_id : null
    )
    Transaction.set(
      'carrier_id',
      this.form === 'CarrierBonus' ? this.transfer.carrier_id : null
    )
    Transaction.set(
      'fmo_id',
      this.form === 'FmoBonus' ? this.transfer.fmo_id : null
    )
    Transaction.set(
      'vendor_id',
      this.form === 'LeadVendorTransfer' ? this.transfer.lead_vendor_id : null
    )
    Transaction.set('is_expire', 0)
    Transaction.set('transact_at', moment().format('YYYY-MM-DD'))

    if (form === 'CashWithdrawal') {
      Transaction.set(
        'can_override',
        UserProfileService.isA(
          [
            'divisional-leader-admin',
            'internal-admin',
            'internal-staff',
            'system-admin',
          ],
          true
        )
          ? 1
          : 0
      )
      if (
        UserProfileService.isA(
          [
            'divisional-leader-admin',
            'internal-admin',
            'internal-staff',
            'system-admin',
          ],
          true
        )
      )
        Transaction.set('upline_id', `${UserProfileService.getUserId(true)}`)
    } else if (form === 'LeadVendorTransfer') {
      Transaction.set('lead_order_state', this.transfer.lead_order_state)
    }

    try {
      await Transaction.save()
      this.Transaction = null
      this.showSuccessMsg = true
      this.outcomeMsg = 'BUCK$ transfer request has been submitted.'
      setTimeout(() => this.reset(), 500)
    } catch (ex) {
      this.Transaction = null
      this.showErrorMsg = true
      this.outcomeMsg = `Failed to transfer BUCK$. ${ex}`.replace(
        /(Error:( )?)+/gi,
        ' '
      )
    }

    this.isSaving = false
  }

  save = async () => {
    const form = this.form
    this.isSaving = true

    const Transaction = this.Transaction
    Transaction.set(
      'credit',
      [
        'AgentAchievement',
        'AgentTransfer',
        'CarrierBonus',
        'FmoBonus',
        'Purchase ',
        'CashDeposit',
      ].includes(form)
        ? parseInt(this.transfer.amount)
        : 0
    )
    Transaction.set(
      'debit',
      [
        'SwagStoreTransfer',
        'TravelRequest',
        'RymaxTransfer',
        'CashWithdrawal',
        'LeadVendorTransfer',
      ].includes(form)
        ? parseInt(this.transfer.amount)
        : 0
    )
    Transaction.set('purpose', this.transfer.purpose)
    Transaction.set('category', this.transfer.category)
    if (
      this.transfer.trans_state &&
      UserProfileService.isA(
        [
          'divisional-leader-admin',
          'internal-admin',
          'internal-staff',
          'system-admin',
        ],
        true
      )
    )
      Transaction.set('trans_state', this.transfer.trans_state)
    Transaction.set(
      'doner_id',
      this.form === 'AgentTransfer' ? this.transfer.doner_id : null
    )
    Transaction.set(
      'carrier_id',
      this.form === 'CarrierBonus' ? this.transfer.carrier_id : null
    )
    Transaction.set(
      'fmo_id',
      this.form === 'FmoBonus' ? this.transfer.fmo_id : null
    )
    Transaction.set(
      'vendor_id',
      this.form === 'LeadVendorTransfer' ? this.transfer.lead_vendor_id : null
    )
    Transaction.set('is_expire', 0)
    Transaction.set('transact_at', moment().format('YYYY-MM-DD'))

    try {
      await Transaction.save()
      this.Transaction = Transaction
      this.showSuccessMsg = true
      this.outcomeMsg = 'BUCK$ transaction has been saved.'
      this.isSaving = false
      return new Promise((resolve, reject) =>
        setTimeout(() => {
          this.reset()
          resolve(true)
        }, 500)
      )
    } catch (ex) {
      this.Transaction = null
      this.showErrorMsg = true
      this.outcomeMsg = `Failed to save BUCK$ transfer. ${ex}`.replace(
        /(Error:( )?)+/gi,
        ' '
      )
      this.isSaving = false
      return false
    }
  }

  resolveAgent = async () => {
    let User = this.Agent
    const [userId1, userId2] = [User?.id(), this.Ledger?.get('user_id')].map(
      (id) => (id !== null && !isNaN(id) ? parseInt(id) : null)
    )

    // userId1: The User ID from User instance on $this Store (this.Agent).
    // userId2: The userId from the Ledger instance on $this Store (this.Ledger.get('user_id')).

    if (userId2 && userId2 !== userId1) User = await getUser(userId2)
    else if (!userId2) User = null

    this.Agent = User
  }

  isTransferReqMet = (key) => {
    let UserMeta = null
    const preReqs =
      this.transferRequirements &&
      this.transferRequirements?.documents &&
      Object.keys(this.transferRequirements.documents).length
        ? this.transferRequirements
        : null
    if (
      key &&
      preReqs &&
      preReqs?.documents &&
      preReqs?.documents.hasOwnProperty(key)
    )
      UserMeta = preReqs.documents[key]

    return (
      UserMeta &&
      UserMeta?.get('meta_value') &&
      !isNaN(UserMeta.get('meta_value'))
    )
  }

  areTransferReqsMet = () => {
    let preReqs = null,
      preReqsAreMet = []
    if (
      this.transferRequirements &&
      this.transferRequirements?.documents &&
      Object.keys(this.transferRequirements.documents).length
    )
      preReqs = this.transferRequirements

    if (preReqs) {
      // 1. Are all documents complete?
      preReqsAreMet.push(
        Object.values(preReqs.documents).every(
          (UserMeta) =>
            UserMeta?.get('meta_value') && !isNaN(UserMeta.get('meta_value'))
        )
      )
    }

    return preReqsAreMet.length && preReqsAreMet.every((val) => val)
  }

  resolveTransferPrerequisites = async (form) => {
    this.transferRequirements = {}

    await this.resolveAgent()

    let transferRequirements = {},
      preReqs = await getTransferPrerequisites(form, { User: this.Agent })

    if (preReqs && typeof preReqs === 'object') {
      if (preReqs?.documents && Object.keys(preReqs.documents).length > 0)
        transferRequirements.documents = preReqs.documents
    }

    this.transferRequirements = Object.keys(transferRequirements).length
      ? transferRequirements
      : null

    return Promise.resolve()

    // let d = transferRequirements && transferRequirements?.documents;
    // const [Meta1,Meta2] = (Object.values(d||{}))||[];
    // console.log(Meta1,Meta1?.all());
    // console.log(Meta2,Meta2?.all());
  }

  end = () => {
    this.Users = []
    this.LeadVendors = []
    this.Carriers = []
    this.Fmos = []
    this.Agent = null
    this.Transaction = null
    this.Ledger = null
    this.transfer = {
      form: '',
      ledger_id: '',
      recipient_id: '',
      recipient_name: '',
      carrier_id: '',
      carrier_name: '',
      lead_vendor_id: '',
      lead_vendor_name: '',
      fmo_id: '',
      fmo_name: '',
      purpose: '',
      category: '',
      amount: '',
    }
    this.transferRequirements = null

    this.pipLevel = null
    this.prevPipLevel = null
    this.isValid = false
    this.isValidating = false
    this.isTransitioning = false
    this.isSaving = false
    this.showErrors = false
    this.outcomeMsg = ''
    this.showErrorMsg = false
    this.showSuccessMsg = false
    this.travelRequestReceipts = []
    this.isDeletingTravelRequestReceipt = false
  }
}

export default new TransferStore()
