import BaseModelFactory from './base.factory'
import TermSetService from './../services/TermSet.service'
import TermSet from './../models/term-set.model'

class TermSetFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: TermSetService, Model: TermSet }
    )
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: TermSetService, Model: TermSet }
    )
  static findAllById = async (id) =>
    await this._findAllBy(
      { id: id },
      { Service: TermSetService, Model: TermSet }
    )
  static findByUsertypeId = async (usertypeId) =>
    await this._findBy(
      { usertype_id: usertypeId },
      { Service: TermSetService, Model: TermSet }
    )
  static create = (payload) =>
    this._create(payload, { Service: TermSetService, Model: TermSet })
}

export default TermSetFactory
