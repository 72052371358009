import React, { Component, Fragment } from 'react'
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBFileInput,
  MDBInput,
  MDBListGroup,
  MDBListGroupItem,
  MDBSpinner,
} from 'mdbreact'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'
import appConstants from '../../constants/appConstants'

class AddDivision extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      d_address1: '',
      d_address2: '',
      d_carriers: [],
      d_city: '',
      d_email: '',
      d_state: '',
      d_zip: '',
      d_phone: '',
      d_fax: '',
      d_name: '',
      d_owner_id: '',
      d_logo: '',
      division_owners: [],
      carriersList: [],
      formError: false,
      errorMessage: '',
      imageUrls: {},
    }
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params })
    this.fetchDivisionOwners()
  }

  //Change event for input fields
  onChange = (event) => {
    // console.log(event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  States = appConstants.States
  StatesList =
    this.States.length > 0 &&
    this.States.map((item, i) => {
      return (
        <option key={i} value={item.id}>
          {item.name}
        </option>
      )
    })

  /*DivisionOwners = this.state.division_owners;
    divisionOwners = this.DivisionOwners.length > 0 && this.DivisionOwners.map((item, i) => {
        return (<option key={i} value={item.name}>{item.name}</option>)
    });*/

  validate = async () => {
    const { d_carriers, d_email, d_name, d_owner_id, d_logo } = this.state
    const allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i
    const regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

    if (!(d_name && d_name.trim())) {
      this.setState({ formError: true, errorMessage: 'Division Name Required' })
      return
    }

    if (!d_owner_id) {
      this.setState({
        formError: true,
        errorMessage: 'Division Owner Required',
      })
      return
    }

    if (!(d_email && d_email.trim())) {
      this.setState({ formError: true, errorMessage: 'Email Required' })
      return
    }

    if (!regEmail.test(d_email)) {
      this.setState({ formError: true, errorMessage: 'Invalid Email format' })
      return
    }

    if (!d_logo) {
      this.setState({ formError: true, errorMessage: 'Division Logo Required' })
      return
    }
    if (
      typeof d_logo !== 'string' &&
      !allowedImageExtensions.test(d_logo.name)
    ) {
      this.setState({ formError: true, errorMessage: 'Invalid file type' })
      return
    }

    if (!d_carriers || !d_carriers.length) {
      this.setState({
        formError: true,
        errorMessage: 'Please select at least one carrier',
      })
      return
    }

    this.setState({ formError: false, errorMessage: '' })
  }

  //create divisions
  createDivision = async () => {
    await this.validate()
    const {
      loading,
      d_address1,
      d_address2,
      d_carriers,
      d_city,
      d_email,
      d_state,
      d_zip,
      d_phone,
      d_fax,
      d_name,
      d_owner_id,
      d_logo,
      formError,
      errorMessage,
    } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }
    const data = {
      d_name: d_name,
      d_owner_id: d_owner_id,
      d_email: d_email,
      d_logo: d_logo,
      d_carriers: d_carriers.toString(),
    }

    if (d_address1 && d_address1.trim()) {
      data['d_address1'] = d_address1
    }
    if (d_address2 && d_address2.trim()) {
      data['d_address2'] = d_address2
    }
    if (d_city && d_city.trim()) {
      data['d_city'] = d_city
    }
    if (d_state && d_state.trim()) {
      data['d_state'] = d_state
    }
    if (d_zip && d_zip.trim()) {
      data['d_zip'] = d_zip
    }
    if (d_phone && d_phone.trim()) {
      data['d_phone'] = d_phone
    }
    if (d_fax && d_fax.trim()) {
      data['d_fax'] = d_fax
    }

    const form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })

    AdminApi.createDivision(form_data)
      .then((result) => {
        this.setState({ loading: false })
        this.props.history.push('/admin-divisions')
        toast.success('Division created successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchDivisionsData = async (id) => {
    AdminApi.getDivisionById(id)
      .then((result) => {
        this.setState({ loading: false })
        if (result && result.data && result.data.data) {
          const content = result.data.data
          this.setState({
            d_address1: content.d_address1,
            d_address2: content.d_address2,
            d_carriers: content.divisioncarriers.split(','),
            d_city: content.d_city,
            d_email: content.d_email,
            d_state: content.d_state,
            d_zip: content.d_zip,
            d_phone: content.d_phone,
            d_fax: content.d_fax,
            d_name: content.d_name,
            d_owner_id: content.d_owner_id,
            d_logo: content.d_logo,
            imageUrls: result.data.imageUrl,
          })
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  updateDivision = async () => {
    await this.validate()
    const id = this.state.urlParams.id
    const {
      loading,
      d_address1,
      d_address2,
      d_carriers,
      d_city,
      d_email,
      d_state,
      d_zip,
      d_phone,
      d_fax,
      d_name,
      d_owner_id,
      formError,
      errorMessage,
      d_logo,
    } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }
    const data = {
      d_name: d_name,
      d_owner_id: d_owner_id,
      d_email: d_email,
      d_carriers: d_carriers.toString(),
      d_address1: d_address1 ? d_address1.trim() : '',
      d_address2: d_address2 ? d_address2.trim() : '',
      d_city: d_city ? d_city.trim() : '',
      d_state: d_state ? d_state.trim() : '',
      d_zip: d_zip ? d_zip.trim() : '',
      d_phone: d_phone ? d_phone.trim() : '',
      d_fax: d_fax ? d_fax.trim() : '',
      id: id,
    }

    if (d_logo && typeof d_logo !== 'string') {
      data['d_logo'] = d_logo
    }

    const form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })
    AdminApi.updateDivision(form_data)
      .then((result) => {
        this.setState({ loading: false })
        this.props.history.push('/admin-divisions')
        toast.success('Division updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchDivisionOwners = async () => {
    this.setState({ loading: true })
    AdminApi.getDivisionOwners()
      .then((result) => {
        if (result && result.data && result.data.data) {
          const content = result.data.data
          this.setState({
            division_owners: content,
          })
        }
        this.fetchCarriers()
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchCarriers = async () => {
    const {
      match: { params },
    } = this.props
    AdminApi.getCarriers()
      .then((result) => {
        if (result && result.data && result.data.data) {
          const content = result.data.data
          this.setState({
            carriersList: content,
          })

          if (params && params.id) {
            this.fetchDivisionsData(params.id)
          } else {
            this.setState({ loading: false })
          }
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  _handleCarrierSelect = (event) => {
    event.persist()
    const { d_carriers } = this.state
    if (d_carriers.indexOf(event.target.value) > -1) {
      d_carriers.splice(d_carriers.indexOf(event.target.value), 1)
    } else {
      d_carriers.push(event.target.value)
    }
    this.setState({ d_carriers })
  }

  render() {
    toast.configure()
    const { urlParams, loading, d_carriers, imageUrls, d_logo } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>
                  <a href="/admin-divisions">List</a>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>
                  {!!(urlParams && urlParams.id)
                    ? 'Edit Division'
                    : 'Add Division'}
                </MDBBreadcrumbItem>
              </MDBBreadcrumb>
              <h2>
                {!!(urlParams && urlParams.id)
                  ? 'Edit Division'
                  : 'Add Division'}
              </h2>
              <hr />
              {loading ? (
                <div className="loadingOverlay">
                  <h4 className={'loader-text p4'}>
                    <MDBSpinner />
                  </h4>
                </div>
              ) : (
                ''
              )}
              <MDBRow>
                <MDBCol size="6">
                  <form className="content-wrapper">
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="d_name">
                          Division Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="d_name"
                          id="d_name"
                          value={this.state.d_name}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="grey-text">Divison Owner</label>
                        <select
                          className="form-control"
                          name="d_owner_id"
                          id="d_owner_id"
                          value={this.state.d_owner_id}
                          onChange={this.onChange}
                        >
                          <option>Select Division Leaders</option>
                          {this.state.division_owners.length > 0 &&
                            this.state.division_owners.map((item, i) => {
                              return (
                                <option key={i} value={item.id}>
                                  {item.u_name}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="d_address1">
                          Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="d_address1"
                          id="d_address1"
                          value={this.state.d_address1}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="d_address2">
                          Address 2
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="d_address2"
                          id="d_address2"
                          value={this.state.d_address2}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="d_city">
                          City
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="d_city"
                          id="d_city"
                          value={this.state.d_city}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="d_state">
                          State
                        </label>
                        <select
                          className="form-control"
                          name="d_state"
                          id="d_state"
                          value={this.state.d_state}
                          onChange={this.onChange}
                        >
                          {this.StatesList}
                        </select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="d_zip">
                          Zip
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="d_zip"
                          id="d_zip"
                          value={this.state.d_zip}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="d_email">
                          E-mail
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="d_email"
                          id="d_email"
                          value={this.state.d_email}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="d_phone">
                          Phone
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="d_phone"
                          id="d_phone"
                          value={this.state.d_phone}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="d_fax">
                          Fax
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="d_fax"
                          id="d_fax"
                          value={this.state.d_fax}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="grey-text">Division Logo</label>
                        <MDBFileInput
                          name="d_logo"
                          value={this.state.d_logo}
                          getValue={(value) => {
                            this.setState({ d_logo: value[0] })
                          }}
                        />
                        {!!(
                          urlParams &&
                          urlParams.id &&
                          this.state.d_logo &&
                          typeof this.state.d_logo == 'string'
                        ) && (
                          <div>
                            <a
                              href={this.state.d_logo}
                              target="_blank"
                              className="d-inline-block"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={`${imageUrls.prefix}${d_logo}${imageUrls.suffix}`}
                                alt=""
                                style={{ maxWidth: '50px' }}
                              />
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <MDBListGroup style={{ width: '22rem' }}>
                          <MDBListGroupItem active>Carriers</MDBListGroupItem>

                          {this.state.carriersList.length > 0 &&
                            this.state.carriersList.map((item, i) => {
                              return (
                                <MDBListGroupItem hover key={item.id}>
                                  <MDBInput
                                    checked={
                                      d_carriers.indexOf(item.id.toString()) >
                                      -1
                                    }
                                    label={item.c_name}
                                    type="checkbox"
                                    id={item.id.toString()}
                                    value={item.id}
                                    onChange={(event) => {
                                      this._handleCarrierSelect(event)
                                    }}
                                  />
                                </MDBListGroupItem>
                              )
                            })}

                          {/*<MDBListGroupItem href="#" hover>
                                                <MDBInput label="Ambetter" type="checkbox" id="checkbox1" />
                                            </MDBListGroupItem>
                                            <MDBListGroupItem href="#" hover>
                                                <MDBInput label="Best One Dental" type="checkbox" id="checkbox1" />
                                            </MDBListGroupItem>
                                            <MDBListGroupItem href="#" hover>
                                                <MDBInput label="CareSource" type="checkbox" id="checkbox1" />
                                            </MDBListGroupItem>*/}
                        </MDBListGroup>
                      </div>
                    </div>
                    <div className="form-row text-center mt-4">
                      <MDBBtn
                        color="unique"
                        type="button"
                        className="btn-block"
                        onClick={() => {
                          this.state.urlParams && this.state.urlParams.id
                            ? this.updateDivision()
                            : this.createDivision()
                        }}
                      >
                        {loading ? 'Saving...' : 'Save'}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AddDivision
