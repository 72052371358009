import React from 'react';
import DashboardLayout from './../../components/Admin/DashboardLayout';
import {MDBContainer, MDBRow, MDBCol} from "mdbreact";

import './CommissionsPage.scss';

class CommissionsPage extends React.Component 
{

	state = {}

	componentDidMount () 
	{}

  render () 
  {
    return (
      <React.Fragment>
        <DashboardLayout>
          <main id="CommissionsPage" className="mainSection">
            <MDBContainer fluid className="mt-5">
              <h2 className="text-center">Commissions Schedule</h2>
              <hr/>
            </MDBContainer>
            <MDBContainer fluid className="mt-5">
              <MDBRow>
              	<MDBCol size="12">
              	</MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export default CommissionsPage;