import { makeAutoObservable } from 'mobx';

class UserEventStore {
  constructor () {
    makeAutoObservable(this);
  }

  Events = []
  User = null;
  pagination = {per_page: 50};
  orderBy = {created_at: 'DESC'};
  isFetching = false;

  init = async (User) =>
  {
    this.User = User;

    await this.fetch()

    return User;
  }

  fetch = async () =>
  {
    this.isFetching = true;

    try {
      let {models,pagination} = await this.User.history(true).search({pagination: this.pagination, orderBy: this.orderBy});
      this.Events = models;
      this.pagination = pagination;
    }
    catch(ex) {
      console.error(`${ex}`)
      this.Events = [];
    }

    this.isFetching = false;
  }
}

export default new UserEventStore();