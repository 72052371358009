import React, { Component } from 'react'
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBFileInput,
  MDBIcon,
  MDBSpinner,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
} from 'mdbreact'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {
  phoneToStorage,
  phoneToView,
} from './../../shared/formatters/telephone.formatter'
import { email as emailPattern } from './../../shared/patterns/email.pattern'
import { phone as phonePattern } from './../../shared/patterns/telephone.pattern'
import AdminApi from '../../api/admin-api/admin-api'
import { toast } from 'react-toastify'
import CKEditor from 'ckeditor4-react'

import './EditTechTools.scss'

const customHandlerPattern = /^#[A-Za-z]/

class EditTechTools extends Component {
  state = {
    loading: false,
    techTools: '',
    tool_title: '',
    tool_subtitle: '',
    tool_icon: '',
    tool_content: '',
    tool_links: [],
    imageURls: {},
    errorsExist: false,
  }

  #errors = {}

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params })

    if (params && params.id) this.#fetchTechToolsData(params.id)
  }

  #onChange = (event) =>
    this.setState({ [event.target.name]: event.target.value })

  #validate = () => {
    this.#errors = {}
    const { tool_title, tool_icon } = this.state,
      // regHttpLink 							= 	/^(http(s)?:\/\/).*/i,
      allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i

    if (!tool_title || !tool_title.trim())
      this.#errors.tool_title = 'TechTools Title Required'

    if (
      tool_icon &&
      typeof tool_icon !== 'string' &&
      !allowedImageExtensions.test(tool_icon.name)
    )
      this.#errors.tool_icon = 'Invalid file type for image'

    if (Object.keys(this.#errors).length) {
      this.setState({ errorsExist: true })
      return false
    }

    this.setState({ errorsExist: false })
    return true
  }

  #stripPrefixLink = (link) => `${link}`.trim().replace(/^(js|mailto|tel):/, '')

  #formatView = (link) => (phonePattern.test(link) ? phoneToView(link) : link)

  #prefixLink = (link) => {
    link = this.#stripPrefixLink(link)

    if (emailPattern.test(link)) return `mailto:${link}`

    if (phonePattern.test(link)) return `tel:${phoneToStorage(link)}`

    if (customHandlerPattern.test(link)) return `js:${link}`

    return link
  }

  #getLinks = (links) => {
    return (Array.isArray(links) ? links : []).map((link) => ({
      ...link,
      tl_link: this.#prefixLink(link.tl_link),
      is_affiliate_agent:
        link.tl_link &&
        `${link.tl_link}`.trim().toLowerCase().indexOf('affiliatedenied') > -1
          ? 1
          : 0,
      is_associate_agent:
        link.tl_link &&
        `${link.tl_link}`.trim().toLowerCase().indexOf('associatedenied') > -1
          ? 1
          : 0,
      is_signature_agent:
        link.tl_link &&
        `${link.tl_link}`.trim().toLowerCase().indexOf('affiliatedenied') < 0 &&
        `${link.tl_link}`.trim().toLowerCase().indexOf('associatedenied') < 0
          ? 1
          : 0,
    }))
  }

  #onSubmit = async () => {
    if (!this.#validate()) return

    const { tool_title, tool_subtitle, tool_links, tool_content, tool_icon } =
        this.state,
      id = this.state.urlParams && this.state.urlParams.id,
      data = {
        tool_title,
        tool_subtitle,
        tool_icon,
        tool_content,
        tool_links: JSON.stringify(this.#getLinks(tool_links)),
      }

    if (tool_icon && typeof tool_icon !== 'string') data.tool_icon = tool_icon

    if (parseInt(id) > 0) data.id = id

    let form_data = new FormData()
    for (let key in data) form_data.append(key, data[key])

    this.setState({ loading: true })

    AdminApi.createUpdateTechTools(form_data)
      .then((result) => {
        this.setState({ loading: false })
        this.props.history.push('/admin-techtools')
        toast.success(
          'TechTool ' + (id ? 'updated' : 'created') + ' successfully',
          { position: toast.POSITION.TOP_RIGHT }
        )
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  #fetchTechToolsData = async (id) => {
    this.setState({ loading: true })

    AdminApi.getTechToolsById(id)
      .then((result) => {
        this.setState({ loading: false })
        if (result && result.data && result.data.data) {
          const data = result.data.data
          let updatedToolLinks = Array.isArray(data.tool_links)
            ? data.tool_links.map((item, i) => ({
                ...item,
                indexId: i,
                tl_link: this.#formatView(this.#stripPrefixLink(item.tl_link)),
              }))
            : data.tool_links
          this.setState({
            tool_title: data.tool_title,
            tool_subtitle: data.tool_subtitle,
            tool_icon: data.tool_icon,
            tool_content: data.tool_content,
            tool_links: updatedToolLinks || [],
            imageURls: result.data.imageUrl,
          })
        }
      })
      .catch(() => {
        this.setState({ loading: false })
        toast.error('Unable to fetch TechTools.', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  _handleRemoveLink = (i) => {
    let tool_links = [...this.state.tool_links]
    tool_links.splice(i, 1)
    tool_links.map((item, i) => {
      return (item.indexId = i)
    })
    this.setState({ tool_links })
  }

  _handleAddNewLink = () => {
    let tool_links = [...this.state.tool_links]
    tool_links.push({
      indexId: tool_links.length,
      tl_type: 'Button',
      tl_title: '',
      tl_link: '',
    })
    this.setState({ tool_links })
  }

  _onLinkItemTypeChange = (event, i) => {
    let tool_links = [...this.state.tool_links]
    tool_links[i].tl_type = event.target.value
    this.setState({ tool_links })
  }

  _onLinkItemTitleChange = (event, i) => {
    let tool_links = [...this.state.tool_links]
    tool_links[i].tl_title = event.target.value
    this.setState({ tool_links })
  }

  _onLinkItemURLChange = (event, i) => {
    let tool_links = [...this.state.tool_links]
    tool_links[i].tl_link = event.target.value
    this.setState({ tool_links })
  }

  render() {
    toast.configure()

    const {
      tool_title,
      tool_subtitle,
      tool_links,
      tool_content,
      tool_icon,
      urlParams,
      imageURls,
      loading,
    } = this.state

    return (
      <React.Fragment>
        <DashboardLayout>
          <main id="EditTechToolsPage" className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>
                  <a href="/admin-techtools">List</a>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>
                  {!!(urlParams && urlParams.id)
                    ? 'Edit TechTools'
                    : 'Add TechTools'}
                </MDBBreadcrumbItem>
              </MDBBreadcrumb>
              <h2>
                {!!(urlParams && urlParams.id)
                  ? 'Edit TechTools'
                  : 'Add TechTools'}
              </h2>
              <hr />
              {loading ? (
                <div className="loadingOverlay">
                  <h4 className={'loader-text p4'}>
                    <MDBSpinner />
                  </h4>
                </div>
              ) : (
                ''
              )}
              <MDBRow>
                <MDBCol size="12" md="8">
                  <form className="content-wrapper">
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="tool_title">
                          TechTools Title
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="tool_title"
                          id="tool_title"
                          value={tool_title}
                          onChange={this.#onChange}
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="tool_subtitle">
                          TechTools Subtitle
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="tool_subtitle"
                          id="tool_subtitle"
                          value={tool_subtitle}
                          onChange={this.#onChange}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label className="grey-text">Content</label>
                        <CKEditor
                          config={{ allowedContent: true }}
                          onChange={({ name, editor }) => {
                            const data = editor.getData()
                            this.setState({ tool_content: data })
                          }}
                          data={tool_content}
                        />
                      </div>
                    </div>
                    <div className="form-row mt-5">
                      <div className="form-group col-md-12">
                        <label className="grey-text">
                          Upload TechTools Icon
                        </label>
                        <MDBFileInput
                          name="tool_icon"
                          value={tool_icon}
                          getValue={(value) =>
                            this.setState({ tool_icon: value[0] })
                          }
                        />
                        {!!(
                          urlParams &&
                          urlParams.id &&
                          tool_icon &&
                          typeof tool_icon == 'string'
                        ) && (
                          <div>
                            <a
                              href={tool_icon}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="d-inline-block"
                            >
                              <img
                                src={`${imageURls.prefix}${tool_icon}${imageURls.suffix}`}
                                style={{ maxWidth: '50px' }}
                                alt="techTools"
                              />
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-12">
                        <label className="grey-text h6">TechTools Links</label>
                      </div>
                    </div>
                    {tool_links.map((item, i) => {
                      return (
                        <div className="form-row" key={i}>
                          <div className="form-group col-md-2">
                            <label
                              className="grey-text"
                              htmlFor={'link_type' + i}
                            >
                              Link Type
                            </label>
                            <select
                              className="form-control"
                              id={'link_type' + i}
                              value={item.tl_type}
                              onChange={(event) => {
                                this._onLinkItemTypeChange(event, i)
                              }}
                            >
                              <option value="Button">Button</option>
                              <option value="Youtube">YouTube</option>
                            </select>
                          </div>
                          {item.tl_type === 'Button' && (
                            <div className="form-group col-md-3">
                              <label
                                className="grey-text"
                                htmlFor={'link_title' + i}
                              >
                                Link Title
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id={'link_title' + i}
                                value={item.tl_title}
                                onChange={(event) => {
                                  this._onLinkItemTitleChange(event, i)
                                }}
                              />
                            </div>
                          )}
                          <div className="form-group col-md-6">
                            <label
                              className="grey-text"
                              htmlFor={'tool_links' + i}
                            >
                              Link
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id={'tool_links' + i}
                              value={item.tl_link}
                              onChange={(event) => {
                                this._onLinkItemURLChange(event, i)
                              }}
                            />
                          </div>
                          <div className="form-group col-md-1 align-self-end">
                            <MDBBtn
                              size={'sm'}
                              tag="a"
                              floating
                              color="primary"
                              title={'Remove Link'}
                              className="m-0"
                              onClick={() => {
                                this._handleRemoveLink(i)
                              }}
                            >
                              <MDBIcon icon="minus" />
                            </MDBBtn>
                          </div>
                        </div>
                      )
                    })}
                    <div className="form-row">
                      <div className="form-group col-md-2">
                        <MDBBtn
                          size="sm"
                          tag="a"
                          floating
                          gradient="blue"
                          title="Add New Link"
                          onClick={this._handleAddNewLink}
                        >
                          <MDBIcon icon="plus" />
                        </MDBBtn>
                      </div>
                    </div>
                    <div className="form-row text-center mt-4">
                      <MDBBtn
                        color="unique"
                        type="button"
                        className="btn-block"
                        onClick={() => this.#onSubmit()}
                      >
                        {loading ? 'Saving...' : 'Save'}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
                <MDBCol size="12" md="4">
                  <MDBCard>
                    <MDBCardHeader>Tool Link Settings</MDBCardHeader>
                    <MDBCardBody>
                      <table>
                        <thead>
                          <tr>
                            <th colSpan="2">Blocking Agent Access</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <pre>#affiliateDenied</pre>
                            </td>
                            <td>
                              Prompts user with access warning message & block
                              access to tech tool for `Affiliate Agents`.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <pre>#associateDenied</pre>
                            </td>
                            <td>
                              Prompts user with access warning message & block
                              access to tech tool for `Associate Agents`.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export default EditTechTools
