import { makeAutoObservable } from 'mobx'
import { toJS } from 'mobx'
import UserContractCartFactory from './../../shared/factories/user-contract-cart.factory'
import UserContractCartItemFactory from './../../shared/factories/user-contract-cart-item.factory'
import UserContractCartService from './../../shared/services/UserContractCart.service'
// import CarrierFmoFactory from './../../shared/factories/carrier-fmo.factory'
// import CarrierFactory from './../../shared/factories/carrier.factory'
import UserProfileService from './../../shared/services/UserProfile.service'

/*
const fetchUserCartByUserId = async (userId) => {
  try {
    return await UserContractCartFactory.findAllByUserId(userId)
  } catch (ex) {
    return []
  }
}

const fetchUserCartByUserAndCarrierId = async (userId, carrierIds) => {
  try {
    return await UserContractCartFactory.findAllByUserAndCarrierId(
      userId,
      carrierIds
    )
  } catch (ex) {
    return []
  }
}
*/

class CheckoutStore {
  constructor() {
    makeAutoObservable(this)
  }

  Cart = null
  CartItems = []
  CartItemGroup = null
  groupIds = []
  checkout = {}
  groupContractTypes = {}

  loading = {
    'create.cart': false,
    'fetch.cart': false,
    'fetch.cart-items': false,
    'delete.cart-item': false,
    'cart.preview': false,
    'cart.checkout': false,
  }

  startCheckout = async (carrierIds) => {
    this.isPreviewing = true
    this.loading['cart.preview'] = true

    // 1. does an existing open cart exist?
    const Cart = await this.fetchOpenCart(),
      // 2. if a cart doesn't exist, create one.
      // 3. pass cart_id & carrier_ids to preview method.
      // this will prepare the contracting cart listing.
      CartItems = Cart
        ? await this.previewCart(Cart, carrierIds)
        : await this.previewCart(await this.createCart(), carrierIds)

    this.CartItems = CartItems
    return CartItems
  }

  createCart = async () => {
    let Cart
    this.loading['create.cart'] = true

    try {
      Cart = await UserContractCartService.store({
        user_id: UserProfileService.getUserId(),
      })
    } catch (ex) {
      console.error(`Failed to create user contracting cart. ex: ${ex}`)
    }

    this.loading['create.cart'] = false
    return Cart && Cart.id ? this.fetchOpenCart(Cart && Cart.id) : null
  }

  fetchOpenCart = async (cartId) => {
    let Cart
    this.loading['fetch.cart'] = true

    try {
      Cart = cartId
        ? await UserContractCartFactory.findById(cartId)
        : await UserContractCartFactory.findOpenByUserId(
            UserProfileService.getUserId()
          )
    } catch (ex) {
      console.error(`Failed to fetch user contracting cart. ex: ${ex}`)
    }

    this.Cart = Cart

    if ((cartId = Cart ? parseInt(Cart.get('id')) : null)) this.CartItems = []

    this.loading['fetch.cart'] = false
    return Cart
  }

  fetchCartItems = async (cartId) => {
    let CartItems
    this.loading['fetch.cart-items'] = true

    try {
      CartItems = await UserContractCartItemFactory.findAllByCartId(cartId)
    } catch (ex) {
      console.error(`Failed to fetch user contracting cart items. ex: ${ex}`)
    }

    this.CartItems = CartItems
    this.loading['fetch.cart-items'] = false
    return CartItems
  }

  deleteCartItem = async (CartItem) => {
    this.loading['delete.cart-item'] = true

    let cartItemId = parseInt(CartItem.get('id'))

    try {
      await CartItem.delete()
    } catch (ex) {
      console.error(`Failed to delete user contracting cart items. ex: ${ex}`)
    }

    if (cartItemId)
      this.CartItems = toJS(this.CartItems).filter(
        (CartItem) => parseInt(CartItem.get('id')) !== cartItemId
      )

    this.loading['delete.cart-item'] = false
    return true
  }

  previewCart = async (Cart, carrierIds) => {
    this.isPreviewing = true
    this.loading['cart.preview'] = true

    let CartItems
    try {
      CartItems =
        (await UserContractCartService.preview(Cart.get('id'), carrierIds)) ||
        []
      CartItems.forEach(
        (CartItem) =>
          (this.groupContractTypes[CartItem.item_group] =
            CartItem.contract_type)
      )
      CartItems = CartItems.map(UserContractCartItemFactory.create)
    } catch (ex) {
      console.error(`Failed to preview user contract cart.  ${ex}`)
    }

    this.Cart = Cart
    this.CartItems = CartItems
    this.groupIds = (Array.isArray(CartItems) ? CartItems : [])
      .map((CartItem) => CartItem.get('item_group'))
      .filter((val, idx, self) => self.indexOf(val) === idx)
    this.isPreviewing = false
    this.loading['cart.preview'] = false
    return CartItems
  }

  checkoutCart = async (groupId) => {
    const Cart = toJS(this.Cart)

    if (Cart) {
      this.loading['cart.checkout'] = true
      this.isBuilding = true

      let CartItemGroup
      try {
        CartItemGroup = await Cart.checkoutByGroupId(groupId)
      } catch (ex) {
        console.error(`Failed to checkout contract cart group. ex: ${ex}`)
      }

      if (CartItemGroup) {
        this.CartItemGroup = CartItemGroup
        this.checkoutItemGroup = CartItemGroup
        this.groupId = groupId
      }

      this.isBuilding = false
      this.loading['cart.checkout'] = false
    }
  }
  // CheckoutStore.groupId

  // ------------------------------------------------------------------
  // OLD STUFF.
  // NOT YET CLEARED FOR REMOVAL.
  // ------------------------------------------------------------------
  groupId = null
  group = null
  groups = null
  checkouts = {}
  isBuilding = false
  isCheckingOut = false
  Carriers = {}
  FMOs = {}

  downloading = {
    state_license: false,
    eopolicy_pdf: false,
    voided_check_img: false,
    headshot: false,
  }

  /*
  fetchContractingCart = async () => {
    this.groups = []

    let Contracts = await fetchUserCartByUserId(UserProfileService.getUserId()),
      groups = {},
      carrierIds = []

    Contracts.forEach((Contract) => {
      if (!groups.hasOwnProperty(Contract.get('cart_group')))
        groups[Contract.get('cart_group')] = []
      groups[Contract.get('cart_group')].push(Contract)
      if (
        Contract.get('carrier_id') &&
        carrierIds.indexOf(Contract.get('carrier_id')) < 0
      )
        carrierIds.push(Contract.get('carrier_id'))
    })

    if (carrierIds.length > 0) this.fetchCarriers(carrierIds)

    this.groups = Object.values(groups).reverse()
    return Contracts
  }

  fetchCarriers = async (carrierIds) => {
    let Carriers
    try {
      Carriers = await CarrierFactory.findAllById(carrierIds)
    } catch (ex) {
      Carriers = []
    }

    let fmoIds = []
    this.Carriers = {}
    Carriers.forEach((Carrier) => {
      this.Carriers[Carrier.get('id')] = Carrier

      if (
        Carrier.get('fmo_id') &&
        !isNaN(Carrier.get('fmo_id')) &&
        fmoIds.indexOf(Carrier.get('fmo_id')) < 0
      )
        fmoIds.push(Carrier.get('fmo_id'))
    })

    if (fmoIds.length > 0) this.fetchFMOs(fmoIds)

    return toJS(this.Carriers)
  }

  fetchFMOs = async (fmoIds) => {
    let FMOs
    try {
      FMOs = await CarrierFmoFactory.findAllById(fmoIds)
    } catch (ex) {
      FMOs = []
    }
    this.FMOs = {}
    FMOs.forEach((fmo) => (this.FMOs[fmo.id] = fmo))
    return toJS(FMOs)
  }
  */

  download = async (content) => {
    this.downloading[content] = true

    let s = window.setTimeout(() => {
      this.downloading[content] = false
      window.clearTimeout(s)
    }, 1500)
  }
}

export default new CheckoutStore()
