import React from 'react'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import PageHeading from './../../components/shared/PageHeading.component'
import { MDBContainer, MDBBtn, MDBCol, MDBRow } from 'mdbreact'
import ContentHtmlCard from './../../components/content/ContentHtmlCard/ContentHtmlCard.component'
import KbAdmin from './components/KbAdmin/KbAdmin.component'
import Store from './stores/Knowledgebase.store'

import './Knowledgebase.page.scss'

class KnowledgebasePage extends React.Component {
  componentDidMount() {
    Store.init()
  }

  render() {
    return (
      <DashboardLayout>
        <main id="KbAdminPage" className="mainSection pb-5">
          <MDBContainer fluid className="mt-4">
            <PageHeading
              label={'Knowledgebase Admin'}
              url={this.props?.match?.url}
              from={this.props?.location?.state?.from || '/dashboard'}
            />
          </MDBContainer>
          <KbAdmin />
        </main>
      </DashboardLayout>
    )
  }
}

export default KnowledgebasePage
