import { observer } from 'mobx-react-lite'
import React from 'react'
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBInput,
} from 'mdbreact'
import CarrierMgmtStore from './../../CarrierMgmt.store'
import UserProfileService from '../../../../shared/services/UserProfile.service'
import './CarrierNotesForm.scss'
import { format } from 'date-fns'

const CarrierNotesForm = () => {
  const renderLockOut = () => {
    if (
      !CarrierMgmtStore.carrier ||
      (CarrierMgmtStore.carrier && isNaN(CarrierMgmtStore.carrier?.id))
    )
      return <div className="lockout-window">lockout</div>
  }

  const create = () => {
    CarrierMgmtStore.note = { message: '' }
  }

  const onChange = (event) => {
    CarrierMgmtStore.note[event.target.name] = event.target.value
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    CarrierMgmtStore.saveNote()
  }

  const renderList = () => {
    if (!CarrierMgmtStore.carrier || !CarrierMgmtStore.carrier.notes)
      return <></>
    let notes = CarrierMgmtStore.getNotes()
    let userId = UserProfileService.getUserId(true)
    return notes.length ? (
      notes.map((note) => (
        <div className="note-item" key={note.id}>
          {`${userId}` === `${note.author_id}` ? (
            <div className="actions">
              <MDBBtn
                disabled={!note}
                onClick={() => (CarrierMgmtStore.note = note)}
              >
                <MDBIcon icon="edit" />
              </MDBBtn>
              <MDBBtn
                disabled={!note}
                onClick={() => CarrierMgmtStore.deleteNote(note.id)}
              >
                <MDBIcon icon="trash" className="red-text" />
              </MDBBtn>
            </div>
          ) : (
            <></>
          )}
          <div>{note.message}</div>
          <div className="note-footer">
            <small>{format(new Date(note.created_at), 'MM/dd/yyyy')}</small>
            <span className="author-name">{note.author_name}</span>
          </div>
        </div>
      ))
    ) : (
      <></>
    )
  }

  const renderForm = () => {
    return (
      <form noValidate onSubmit={onSubmit}>
        <MDBRow>
          <MDBCol size="12">
            <MDBInput
              label="Note"
              name="message"
              type="textarea"
              value={CarrierMgmtStore.note?.message || ''}
              rows={4}
              onChange={(event) =>
                onChange({
                  target: {
                    name: 'message',
                    value: event.target.value,
                  },
                })
              }
              required
            ></MDBInput>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12" sm="7">
            <MDBBtn className="btn-block" type="submit">
              {CarrierMgmtStore.getIsLoading('update.carrier') ? (
                <span>
                  Saving...&nbsp;
                  <i className="fa fa-spin fa-spinner" />
                </span>
              ) : (
                'Save Carrier Note'
              )}
            </MDBBtn>
          </MDBCol>
          <MDBCol size="12" sm="5">
            <MDBBtn
              className="btn-block"
              onClick={() => (CarrierMgmtStore.note = null)}
            >
              Cancel
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </form>
    )
  }

  return (
    <MDBCard id="CarrierNotesFormComponent">
      {renderLockOut()}
      <MDBCardHeader>
        Carrier Notes&nbsp;
        {CarrierMgmtStore.getIsLoading('fetch.notes') && (
          <span>
            <i className="fa fa-spin fa-spinner" />
          </span>
        )}
        <MDBBtn
          className="card-header-btn"
          disabled={!!(CarrierMgmtStore.note || !CarrierMgmtStore.carrier?.id)}
          onClick={create}
        >
          <MDBIcon icon="plus" />
        </MDBBtn>
      </MDBCardHeader>
      <MDBCardBody>
        {CarrierMgmtStore.note ? renderForm() : renderList()}
      </MDBCardBody>
    </MDBCard>
  )
}

export default observer(CarrierNotesForm)
