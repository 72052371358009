import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBDataTable,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
} from 'mdbreact'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'

class MyProducts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      confirmModal: false,
      confirmDeleteModal: false,
      formError: false,
      errorMessage: '',
      urlParams: {},
      userRoles: {},
      userData: {},
      coverageDetail: {},
      myProductsList: [],
      myProducts: {
        columns: [
          {
            label: 'Product',
            field: 'productName',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Edit',
            field: 'edit',
            sort: 'asc',
            width: 270,
          },
          {
            label: 'Delete',
            field: 'delete',
            sort: 'asc',
            width: 270,
          },
        ],
        rows: [],
      },
      availableProductsList: [],
      availableProducts: {
        columns: [
          {
            label: 'Product',
            field: 'productName',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Add Product',
            field: 'add',
            sort: 'asc',
            width: 270,
          },
        ],
        rows: [],
      },
    }
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params }, () => {
      this.fetchMyProducts(this.state.urlParams.id)
    })
  }

  confirm_DeleteProduct = (data) => {
    this.confirm_delete_toggle(data)
  }

  //Toggle delete confirm modal
  confirm_delete_toggle = (data = {}) => {
    this.setState({
      confirmDeleteModal: !this.state.confirmDeleteModal,
      ...data,
    })
  }

  _DeleteMyProduct = async (id) => {
    this.setState({ deleting: true })
    const data = { userproductcoverage_id: id }

    AdminApi.deleteAgentsProductWebsiteArea(data)
      .then((result) => {
        this.setState({ deleting: false })
        this.confirm_delete_toggle()
        toast.success('Product successfully deleted', {
          position: toast.POSITION.TOP_RIGHT,
        })
        this.fetchMyProducts(this.state.urlParams.id)
      })
      .catch((error) => {
        this.setState({ deleting: false })
        toast.error(`${error.message}`, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchMyProducts = async (cId) => {
    this.setState({ loading: true })
    AdminApi.showProductsByCoverage(cId)
      .then((result) => {
        if (result && result.data && result.data.data) {
          this.setState(
            {
              myProductsList: result.data.data,
              coverageDetail: result.data.coverage_detail,
            },
            this.setMyProductRows
          )
        }
        this.fetchAvailableProducts(cId)
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  setMyProductRows = () => {
    const { myProductsList } = this.state
    if (myProductsList && myProductsList.length) {
      let rows = myProductsList.map((row) => {
        const { p_name, userproductcoverage_id } = row
        const self = this

        function editmyProduct() {
          self.props.history.push(`${userproductcoverage_id}/edit`)
        }
        const editClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={editmyProduct}
            >
              <MDBIcon
                icon="edit"
                size="2x"
                className="d-flex justify-content-center blue-text"
              />
            </div>
          </div>
        )

        function deleteProduct() {
          self.confirm_DeleteProduct({ viewdata: row })
        }
        const deleteClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={deleteProduct}
            >
              <MDBIcon icon="trash" size="2x" className="red-text p-1" />
            </div>
          </div>
        )

        return {
          productName: p_name,
          edit: editClick,
          delete: deleteClick,
        }
      })
      this.setState({
        myProducts: { ...this.state.myProducts, rows: rows },
      })
    }
  }

  fetchAvailableProducts = async (cId) => {
    AdminApi.showAvailableProductsByCoverage(cId)
      .then((result) => {
        if (result && result.data && result.data.data) {
          this.setState(
            { availableProductsList: result.data.data },
            this.setAvailableProductRows
          )
        }
        this.setState({ loading: false })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  confirm_AddProduct = (data) => {
    this.confirm_toggle(data)
  }

  //Toggle delete confirm modal
  confirm_toggle = (data = {}) => {
    this.setState({ confirmModal: !this.state.confirmModal, ...data })
  }

  addToMyProduct = async (id) => {
    this.setState({ adding: true })
    const data = {
      coverage_id: parseInt(this.state.urlParams.id),
      product_id: parseInt(id),
    }

    AdminApi.addToMyProductsWebsiteArea(data)
      .then((result) => {
        this.setState({ adding: false })
        this.confirm_toggle()
        toast.success('Product successfully added', {
          position: toast.POSITION.TOP_RIGHT,
        })
        this.fetchMyProducts(this.state.urlParams.id)
      })
      .catch((error) => {
        this.setState({ adding: false })
        toast.error(`${error.message}`, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  setAvailableProductRows = () => {
    const { availableProductsList } = this.state
    if (availableProductsList && availableProductsList.length) {
      let rows = availableProductsList.map((row) => {
        const { p_name } = row
        const self = this

        function addToMyProduct() {
          // self.props.history.push(`/${id}/add-contract`);
          self.confirm_AddProduct({ viewdata: row })
        }

        const addClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={addToMyProduct}
            >
              <MDBIcon
                icon="plus"
                size="2x"
                className="d-flex justify-content-center blue-text"
              />
            </div>
          </div>
        )

        return {
          productName: p_name,
          add: addClick,
        }
      })
      this.setState({
        availableProducts: { ...this.state.availableProducts, rows: rows },
      })
    }
  }

  render() {
    toast.configure()
    const { loading, confirmModal, confirmDeleteModal } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            {loading ? (
              <h2>
                <MDBIcon
                  className="red-text ml-3"
                  icon="cog"
                  spin
                  size="2x"
                  fixed
                />
                <span className="sr-only">Loading...</span>
              </h2>
            ) : (
              <MDBContainer fluid className="mt-5">
                <h2>{this.state.coverageDetail.coverage_name}</h2>
                <MDBCard className="mt-5 mb-5">
                  <div className="header pt-3 indigo lighten-1">
                    <MDBRow className="d-flex justify-content-start">
                      <h3 className="white-text mt-3 mb-4 pb-1 mx-5">
                        My Products
                      </h3>
                    </MDBRow>
                  </div>
                  <MDBCardBody className="mx-4 mt-4">
                    <MDBDataTable
                      responsive
                      striped
                      order={['carrier', 'asc']}
                      bordered
                      small
                      data={this.state.myProducts}
                    />
                  </MDBCardBody>
                </MDBCard>

                <MDBCard className="mt-5 mb-5">
                  <div className="header pt-3 indigo lighten-1">
                    <MDBRow className="d-flex justify-content-start">
                      <h3 className="white-text mt-3 mb-4 pb-1 mx-5">
                        Available Products
                      </h3>
                    </MDBRow>
                  </div>
                  <MDBCardBody className="mx-4 mt-4">
                    <MDBDataTable
                      responsive
                      striped
                      order={['carrier', 'asc']}
                      bordered
                      small
                      data={this.state.availableProducts}
                      className="text-truncate"
                    />
                  </MDBCardBody>
                </MDBCard>

                <MDBModal
                  size={'sm'}
                  isOpen={confirmModal}
                  toggle={this.confirm_toggle}
                >
                  <MDBModalHeader toggle={this.confirm_toggle}>
                    Add Product
                  </MDBModalHeader>
                  <MDBModalBody>
                    <div className="h5">
                      Are you sure you want to Add this Product?
                    </div>
                    <MDBBtn
                      color="secondary"
                      size={'sm'}
                      onClick={this.confirm_toggle}
                    >
                      Close
                    </MDBBtn>
                    <MDBBtn
                      color="danger"
                      size={'sm'}
                      onClick={() => {
                        this.addToMyProduct(this.state.viewdata.id)
                      }}
                    >
                      {this.state.deleting ? 'Adding...' : 'Add'}
                    </MDBBtn>
                  </MDBModalBody>
                </MDBModal>

                <MDBModal
                  size={'sm'}
                  isOpen={confirmDeleteModal}
                  toggle={this.confirm_delete_toggle}
                >
                  <MDBModalHeader toggle={this.confirm_delete_toggle}>
                    Delete Product
                  </MDBModalHeader>
                  <MDBModalBody>
                    <div className="h5">
                      Are you sure you want to delete this Product?
                    </div>
                    <MDBBtn
                      color="secondary"
                      size={'sm'}
                      onClick={this.confirm_delete_toggle}
                    >
                      Close
                    </MDBBtn>
                    <MDBBtn
                      color="danger"
                      size={'sm'}
                      onClick={() => {
                        this._DeleteMyProduct(
                          this.state.viewdata.userproductcoverage_id
                        )
                      }}
                    >
                      {this.state.deleting ? 'Deleting...' : 'Delete'}
                    </MDBBtn>
                  </MDBModalBody>
                </MDBModal>
              </MDBContainer>
            )}
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default MyProducts
