import React from 'react'
import { observer } from 'mobx-react-lite'
import { MDBRow, MDBCol } from 'mdbreact'
import TermHistoryReport from './TermHistoryReport.component';
import TermSetReport from './TermSetReport.component';
import UserProfileService from './../../../shared/services/UserProfile.service';
import Store from './AgentTerms.store'

import './AgentTerms.scss'

const ENABLE_TERM_SETS = false;

const AgentTerms = ({ hideHeader, userId }) => {

	if (Store.hasLoaded === null) {
		Store.hasLoaded = false;
		Store.fetch(userId||UserProfileService.getUserId());
	}
	else if (Store.userId && userId && `${Store.userId}` !== `${userId}`) {
		Store.hasLoaded = false;
		Store.fetch(userId||UserProfileService.getUserId());
	}

  return (
    <div id="AgentTermsComponent">
      <MDBRow>
        <MDBCol size="12">
        	<TermHistoryReport hideHeader={hideHeader} />
        	<br />
        </MDBCol>
        {
        	ENABLE_TERM_SETS 
        		? <MDBCol size="12">
        				<TermSetReport hideHeader={hideHeader} />
        			</MDBCol>
        		: <></>
        }
      </MDBRow>
    </div>
  )
}

export default observer(AgentTerms)
