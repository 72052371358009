import React, { Component, Fragment } from 'react'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import AdminTermsTable from './components/AdminTermsTable/AdminTermsTable.component'
import UsertypeService from './../../shared/services/Usertype.service'
import UserProfileService from './../../shared/services/UserProfile.service'
import TermService from './../../shared/services/Term.service'
import ConfirmDeleteModalComponent from './../../components/adminShared/ConfirmDeleteModal/ConfirmDeleteModal.component'
import { MDBBtn, MDBContainer, MDBIcon } from 'mdbreact'

import './AdminTerms.page.scss'

class Terms extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      terms: [],
      usertypes: [],
      confirmDelete: false,
    }
  }

  componentDidMount() {
    Promise.all([this.fetchTermsData(), this.fetchUserTypes()]).finally(() =>
      this.setState({ isLoading: false })
    )
  }

  fetchUserTypes = async () => {
    let Usertypes = []
    try {
      Usertypes =
        (await UsertypeService.search({ pagination: { per_page: 999 } }))
          ?.models || []
    } catch (ex) {
      console.log('Failed to fetch usertype in AdminTerms.')
    }

    if (Array.isArray(Usertypes)) this.setState({ usertypes: Usertypes })
  }

  fetchTermsData = async () => {
    this.setState({ loading: true })

    let Terms = [],
      search = { active: 1, is_hidden: 0 }
    try {
      if (UserProfileService.isA(['system-admin'])) delete search.is_hidden

      Terms =
        (
          await TermService.search({
            search,
            pagination: { per_page: 999 },
            order_by: { created_at: 'DESC' },
          })
        )?.models || []
    } catch (ex) {
      console.log('Failed to fetch terms in AdminTerms.')
    }

    if (Array.isArray(Terms)) this.setState({ terms: Terms })
  }

  onChange = async (change) => {
    const termId = change && change?.id
    if (!isNaN(termId)) {
      delete change.id
      TermService.update(termId, change)

      this.setState((prevState) => ({
        ...prevState,
        terms: this.state.terms.map((term) => {
          return parseInt(term.id) === parseInt(termId)
            ? { ...term, ...change }
            : term
        }),
      }))
    }
  }

  onDeactivate = async (term) => {
    const termId =
      this.state.confirmDelete && !isNaN(this.state.confirmDelete?.id)
        ? parseInt(this.state.confirmDelete.id)
        : false

    this.setState({
      confirmDelete: null,
      terms: this.state.terms.filter(
        (term) => parseInt(term.id) !== parseInt(termId)
      ),
    })

    if (termId > 0) TermService.update(termId, { active: 0 })
  }

  render() {
    const createNew = () => {
      this.props.history.push('/add-term')
    }

    return (
      <Fragment>
        <DashboardLayout>
          <main id="AdminTermsComponent" className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <h2>
                Terms
                <div className="d-inline-block">
                  <MDBBtn
                    tag="a"
                    floating
                    gradient="blue"
                    title={'Add Term'}
                    onClick={createNew}
                  >
                    <MDBIcon icon="plus" />
                  </MDBBtn>
                </div>
              </h2>
              <hr />
              <div className="content-wrapper">
                {this.state.isLoading ? (
                  <h4 className={'p4'}>Loading...</h4>
                ) : (
                  <AdminTermsTable
                    rows={this.state.terms || []}
                    usertypes={this.state.usertypes}
                    onChange={this.onChange}
                    onViewSigs={(id) =>
                      this.props.history.push(`/${id}/view-signatures`)
                    }
                    onEdit={(id) =>
                      this.props.history.push(`/add-term/${id}/edit`)
                    }
                    onDelete={(term) => this.setState({ confirmDelete: term })}
                  />
                )}
              </div>
            </MDBContainer>
            <ConfirmDeleteModalComponent
              confirm={
                this.state.confirmDelete
                  ? this.state.confirmDelete.term_name
                  : null
              }
              onConfirm={this.onDeactivate}
              shouldEnable={true}
              onCancel={() => this.setState({ confirmDelete: null })}
            />
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default Terms

// ALTER TABLE terms ADD COLUMN is_leader BOOLEAN DEFAULT '0' AFTER active;
// ALTER TABLE terms ADD COLUMN is_enroll BOOLEAN DEFAULT '0' AFTER active;
// ALTER TABLE terms ADD COLUMN usertype_id INT NULL AFTER active;
