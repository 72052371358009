import React from 'react'
import env from './../environments/environment'
import Navbar from '../components/newhome/Navbar'
import Footer from '../components/newhome/Footer'
import productImg1 from '../assets/images/tc.png'
import productImg2 from '../assets/images/uhc.png'
import productImg3 from '../assets/images/philly.png'
import productImg4 from '../assets/images/ng.png'

const Products = () => (
  <div className="page contact-page-custom-wrapper">
    <Navbar />
    <section
      style={{
        backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fsubsites%2Fimg.premiere-default.jpg?alt=media)`,
      }}
      className="section-30 section-sm-40 section-md-66 section-lg-bottom-90 bg-gray-dark page-title-wrap"
    >
      <div className="shell">
        <div className="page-title">
          <h2 className={'text-white'}>Our Products</h2>
        </div>
      </div>
    </section>
    <section className="section-50 bg-whisper-1 section-sm-90 section-lg-top-120 section-lg-bottom-145 bg-mine-shaft bg-mine-shaft-varinat-1">
      <div className="shell isotope-wrap">
        <div className="range">
          <div className="cell-xs-12 text-center">
            <h3 className="text-spacing--25">Individual Major Medical</h3>
            <hr />
            <div className="shell">
              <div className="range">
                <div className="cell-xs-6 cell-md-3">
                  <div className="link-image-wrap">
                    <span className="link-image">
                      <img
                        src={productImg1}
                        alt="team-corp"
                        width="126"
                        height="68"
                      />
                    </span>
                  </div>
                </div>
                <div className="cell-xs-6 cell-md-3">
                  <div className="link-image-wrap">
                    <span className="link-image">
                      <img src={productImg2} alt="" width="126" height="100" />
                    </span>
                  </div>
                </div>
                <div className="cell-xs-6 cell-md-3">
                  <div className="link-image-wrap">
                    <span className="link-image">
                      <img src={productImg3} alt="" width="169" height="68" />
                    </span>
                  </div>
                </div>
                <div className="cell-xs-6 cell-md-3">
                  <div className="link-image-wrap">
                    <span className="link-image">
                      <img src={productImg4} alt="" width="138" height="55" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
)

export default Products
