import BaseModelFactory from './base.factory'
import CarrierFmoService from './../services/CarrierFmo.service'
import CarrierFmo from './../models/carrier-fmo.model'

class CarrierFmoFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: CarrierFmoService, Model: CarrierFmo }
    )
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: CarrierFmoService, Model: CarrierFmo }
    )
  static findAllById = async (id) =>
    await this._findAllBy(
      { id: id },
      { Service: CarrierFmoService, Model: CarrierFmo }
    )
  static create = (payload) =>
    this._create(payload, { Service: CarrierFmoService, Model: CarrierFmo })
}

export default CarrierFmoFactory
