import React, { useEffect, useState } from "react";
import {
    MDBContainer,
    MDBRow,
    MDBCol,
  } from 'mdbreact';
import { phoneToView, phoneToStorage } from "../../../shared/formatters/telephone.formatter";
import UsertypeService from "../../../shared/services/Usertype.service";

import './UplineContactInfo.component.scss'

const getUplineTypeName = (str) => {
    // Step 1: Replace hyphens with spaces
    str = str.replace(/-/g, ' ');
  
    // Step 2: Split the string into words
    const words = str.split(' ');
  
    // Step 3: Capitalize the first letter of each word
    const capitalizedWords = words.map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
  
    // Step 4: Join the words back into a single string
    return capitalizedWords.join(' ');
}

const UplineContactInfo = ({isDirectUpline, upline}) => {
    const [uplineType, setUplineType] = useState('');
    const [uplineEmails, setUplineEmails] = useState([]);
    const [uplinePhones, setUplinePhones] = useState([]);

    const getValidItems = (items) => items.filter((item) => !!item);

    const getUniqueItems = (items) => [...new Set(items)];

    const getUplineType = (isDirectUpline, upline) => {
        if (isDirectUpline) return 'My Direct Upline';
        const uplineTypeName = UsertypeService.idToName(upline.usertype_id);
        return getUplineTypeName(uplineTypeName);
    };
    
    const getUplineEmails = (upline) => getUniqueItems(getValidItems([upline.u_email, upline.u_company_email]));
    
    const getUplinePhones = (upline) => getUniqueItems(getValidItems([upline.u_phone, upline.u_mobile]).map((item) => {return phoneToView(item)}));
    
    useEffect(() => {
        setUplineType(getUplineType(isDirectUpline, upline));
        setUplineEmails(getUplineEmails(upline));
        setUplinePhones(getUplinePhones(upline));
    }, [isDirectUpline, upline]);

    return (
        <MDBContainer id="UplineContactInfoComponent" fluid>
            <MDBRow>
                <MDBCol size="12" sm="4" md="3" className="upline-type">
                    <p className={isDirectUpline ? "direct-upline" : "" }>{uplineType}</p>
                </MDBCol>
                <MDBCol size="12" sm="8" md="9">
                    <MDBRow className="upline-contact-info-body">
                        <MDBCol size="12" sm="4" className="upline-info">
                            <div>
                                <p className="upline-name">{upline.u_fname + ' ' + upline.u_lname}</p>
                                <p className="upline-title">{upline.u_marketing_title}</p>
                            </div>
                        </MDBCol>
                        <MDBCol size="12" sm="8" className="mt-2">
                            {
                                uplineEmails.map((email, index) => {
                                    return (
                                        <div key={index}>
                                            <p>Email {index + 1}: <a href={`mailto:${email}`}>{email}</a></p>
                                        </div>
                                    )
                                })
                            }
                            {
                                uplinePhones.map((phone, index) => {
                                    return (
                                        <div key={index}>
                                            <p>Phone {index + 1}: <a href={`tel:${phoneToStorage(phone)}`}>{phoneToView(phone)}</a></p>
                                        </div>
                                    )
                                })
                            }
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
}

export default UplineContactInfo;