import React from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import PAGE_CONFIG from './../../../../constants/public-facing'

import './CorporateIdentity.component.scss'

const CorporateIdentity = ({ subsiteData }) => {
  const whoAreWe = `${
    subsiteData?.s_about
      ? subsiteData?.s_about
      : "USA Benefits Group is a nationwide network of independent health and life insurance professionals that have been serving individuals, small-business owners and the self-employed since 1988. We offer Health Insurance, Critical Illness, Accident Plans, GAP Plans, Dental Plans, Rx Drug Plans, Life Insurance, Medicare Supplements, Annuities and many other insurance products. USA Benefits Group provides a comprehensive and ongoing training program to it's agents so they are better prepared to serve their clients. Our agents represent you, the client, and they are committed to meeting your needs and matching you up with the most appropriate and affordable insurance solutions."
  }`
    .trim()
    .replace(/'/g, "'")

  return (
    <MDBContainer id="CorporateIdentityComponent" fluid>
      <MDBRow className="who-we-are">
        <MDBCol style={{ position: 'relative' }}>
          <h4 className="font---lato fw--700">
            Who is{' '}
            {subsiteData?.s_about
              ? [
                  subsiteData.u_nickname
                    ? subsiteData.u_nickname
                    : subsiteData.u_fname,
                  subsiteData.u_lname,
                ].join(' ')
              : 'USABG Benefits Group'}
            ?
          </h4>
          <p dangerouslySetInnerHTML={{ __html: whoAreWe }}></p>
          {PAGE_CONFIG?.compliance?.enabled &&
          PAGE_CONFIG?.compliance?.entity_name ? (
            <div
              style={{
                color: '#666',
                fontSize: '15px',
                textAlign: 'center',
                padding: '2px',
                fontWeight: 400,
                fontFamily: 'lato',
              }}
            >
              {PAGE_CONFIG.compliance.entity_name}
            </div>
          ) : (
            <></>
          )}
        </MDBCol>
      </MDBRow>
      <MDBRow className="mission-stmt">
        <MDBCol>
          <h3 className="font---lato fw--700">Our Mission Statement</h3>
          <p>
            USA Benefits Group is a nationwide insurance agency dedicated to the
            principles of service, integrity, professionalism and diversity. We
            provide an extensive range of insurance products and pledge to honor
            our commitment to our producers, clients and carriers by holding
            ourselves to the highest ethical standards. Everything that we do is
            designed to ensure that our representatives present quality products
            with a Servant’s Heart and a goal of exceeding expectations.
          </p>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default CorporateIdentity
