import BaseModelInstance from './base.model'
import { UserTermSet as ModelDefinition } from './model-definitions'
import UsersTermSetsService from './../services/UsersTermSets.service'

class UserTermSet extends BaseModelInstance {
  afterSet = (key, value) => {}


  sign = async (ipAddress) =>
  {
  	const res = await UsersTermSetsService.sign(this.id(),{accepted_ip: ipAddress});
  	if (res && res?.signed_at && res?.userterm_file) {
  		this.set('userterm_file', res.userterm_file)
			if (!this.get('userterm_ip'))
				this.set('userterm_ip',ipAddress)
  		return true;
  	}

  	return false;
  }


  constructor(attribs) {
    super(ModelDefinition, attribs)
  }
}

export default UserTermSet

