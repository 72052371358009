import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { MDBCollapse, MDBRow, MDBCol, MDBIcon, MDBAlert } from 'mdbreact'
import ContractingPacket from './../ContractingPacket/ContractingPacket.component'
import UserProfileService from './../../../shared/services/UserProfile.service'
import ACMStore from './../AgentContractManager.store'
import ContentHtml from './../../content/ContentHtml/ContentHtml.component'

import './OnboardingCheckout.scss'

const OnboardingCheckout = ({ onValidityChange }) => {
  const completedContracts =
    ACMStore?.CartItems?.length > 0 &&
    ACMStore.CartItems.every(
      (CartItem) =>
        CartItem.get('item_status') === 'COMPLETED' ||
        CartItem.get('item_status') === 'ABANDONED'
    )

  useEffect(() => {
    if (typeof onValidityChange === 'function')
      onValidityChange(completedContracts)
  }, [completedContracts, onValidityChange])

  return (
    <>
      <div id="OnboardingCheckoutComponent">
        <MDBRow>
          <MDBCol size="12">
            <MDBCollapse isOpen={!completedContracts}>
              <ContentHtml contentId="103" />
            </MDBCollapse>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12">
            <MDBCollapse isOpen={!completedContracts}>
              {ACMStore.packets.map((packetId, idx) => (
                <ContractingPacket
                  key={`contracting-packet-${idx}`}
                  packetId={packetId}
                  Packet={ACMStore?.Packet}
                />
              ))}
            </MDBCollapse>
            <MDBCollapse isOpen={completedContracts}>
              <MDBAlert color="success">
                <div className="content-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="2">
                          Contracts Requested!
                          <hr />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <MDBIcon icon="thumbs-up" />
                        </td>
                        <td>
                          <h5>
                            Nice Work, {UserProfileService.get('u_fname')}!
                          </h5>
                          <p>
                            The good news is you're past the hard part. Click
                            the green{' '}
                            <span>
                              Start Next Stage&nbsp;
                              <MDBIcon icon="arrow-right" />
                            </span>{' '}
                            button above to move on to the next stage of your
                            onboarding journey.
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </MDBAlert>
            </MDBCollapse>
          </MDBCol>
        </MDBRow>
      </div>
    </>
  )
}

export default observer(OnboardingCheckout)
