import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import { toast } from "react-toastify";

am4core.useTheme(am4themes_animated);

const Gauge = ({value, name}) => {
  const am_key = `gauge_${(name||Math.random())}`

  useLayoutEffect(() => {

      if (value < 0 || value > 1) {
          toast.error(`Invalid value provided!`, { position: toast.POSITION.TOP_RIGHT });
          if (value < 0) value = 0;
          if (value > 1) value = 1;
          //return;
      }
      // create chart
      var chart = am4core.create(am_key, am4charts.GaugeChart);
      chart.innerRadius = -15;

      var axis = chart.xAxes.push(new am4charts.ValueAxis());
      axis.min = 0;
      axis.max = 1;
      axis.strictMinMax = true;
      axis.renderer.labels.template.disabled = true;
      //axis.numberFormatter.numberFormat = "#a";

      let ds = new am4core.DropShadowFilter();
      ds.blur = 10;
      ds.opacity = 0.5;
      axis.filters.push(ds)

      var colorSet = new am4core.ColorSet();

      var gradient = new am4core.LinearGradient();
      gradient.stops.push({ color: am4core.color("green") })
      gradient.stops.push({ color: am4core.color("yellow") })
      gradient.stops.push({ color: am4core.color("red") })

      axis.renderer.line.stroke = gradient;
      axis.renderer.line.strokeWidth = 15;
      axis.renderer.line.strokeLinecap = "round";
      axis.renderer.line.strokeOpacity = 1;

      axis.renderer.grid.template.disabled = true;


      var hand = chart.hands.push(new am4charts.ClockHand());
      hand.radius = am4core.percent(102);
      hand.startWidth = 16;
      hand.pin.radius = 8;
      hand.parent.zIndex = 100;

      hand.showValue(parseFloat(value), 0, am4core.ease.cubicOut);

  }, []);

  return (

      <div id={am_key} style={{ width: "100%" }}></div>
  );
}
export default Gauge

