import React, { useEffect, useState } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import {
  MDBCard,
  MDBCardHeader,
  MDBIcon,
  MDBCardBody,
  MDBListGroup,
  MDBListGroupItem,
} from 'mdbreact'
import MilestoneWidget from './../MilestoneWidget/MilestoneWidget.component'
import LoadingSpinner from './../../../../components/shared/LoadingSpinner.component'
import MilestoneStore from './../../Milestone.store'

import './MilestoneList.scss'

const MilestoneList = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [award, setAward] = useState(null)

  useEffect(() => {
    if (props.listType && MilestoneStore.milestones[props.listType] === null) {
      MilestoneStore.fetchMilestone(props.listType)

      // Not currently using this feature.  Award
      // Winners will be built into a different widget, eventually.

      if (
        props.listType === 'rick_ring_race' ||
        props.listType === 'leader_ring_race' ||
        props.listType === 'agent_ring_race'
      ) {
        setAward(`${props.listType}_award`)
        MilestoneStore.fetchMilestone(`${props.listType}_award`)
      }
    }
  }, [props])

  const isLoading = () => {
    return (
      MilestoneStore.loading[props.listType] &&
      (award ? MilestoneStore.loading[award] : false)
    )
  }

  const renderTitle = () => (
    <>
      <div className="title">
        <div className="list-title">{props.listTitle}</div>
        {props.subTitle && <div className="sub-title">{props.subTitle}</div>}
      </div>
      {toJS(
        MilestoneStore.milestones[props.listType] &&
          MilestoneStore.milestones[props.listType].milestone
      )?.length > 3 && <MDBIcon icon="angle-down" className="float-right" />}
    </>
  )

  const renderWidget = (ms, i, awarded = false) => (
    <MilestoneWidget
      data={ms}
      key={i}
      name={awarded ? award : props.listTitle}
      className={awarded ? 'awarded' : ''}
    />
  )

  const toggleBoxHeight = () => setIsOpen((prevState) => !prevState)

  const renderList = () => {
    return (
      <>
        <MDBCard
          className={
            'mb-5 p-0 MilestoneList ' + (isOpen ? 'is-open' : 'is-closed')
          }
        >
          <MDBCardHeader
            color="primary-color-dark"
            tag="h4"
            className="cursor-pointer"
            onClick={() => toggleBoxHeight()}
          >
            {renderTitle()}
          </MDBCardHeader>
          <MDBCardBody
            style={
              isOpen
                ? {
                    height: `${
                      (toJS(
                        MilestoneStore.milestones[props.listType] &&
                          MilestoneStore.milestones[props.listType].milestone
                      )?.length > 3
                        ? MilestoneStore.milestones[props.listType].length
                        : 3) * 150
                    }px`,
                  }
                : {}
            }
            className={isLoading() ? 'is-loading' : ''}
          >
            <div className="loading-spinner">
              <LoadingSpinner size="md" isActive={!!isLoading()} />
            </div>
            <MDBListGroup>
              {award &&
              MilestoneStore.milestones[award] &&
              MilestoneStore.milestones[award]?.milestone?.length > 0 ? (
                MilestoneStore.milestones[award].milestone?.map(
                  (milestone, index) => {
                    return renderWidget(milestone, index, true)
                  }
                )
              ) : (
                <></>
              )}
              {MilestoneStore.milestones[props.listType] &&
              MilestoneStore.milestones[props.listType]?.milestone?.length >
                0 ? (
                MilestoneStore.milestones[props.listType].milestone.map(
                  (milestone, index) => {
                    return renderWidget(milestone, index, false)
                  }
                )
              ) : (
                // ) : award ? (
                //   MilestoneStore.milestones[award] &&
                //   MilestoneStore.milestones[award].length === 0 && (
                //     <MDBListGroupItem>No Data!</MDBListGroupItem>
                //   )
                <MDBListGroupItem>No Data!</MDBListGroupItem>
              )}
            </MDBListGroup>
          </MDBCardBody>
        </MDBCard>
        <div style={{ textAlign: 'right', fontWeight: 500, fontSize: '12px' }}>
          {isLoading() ? (
            'Fetching ...'
          ) : MilestoneStore.milestones[props.listType] ? (
            `${MilestoneStore.milestones[props.listType]?.updates}.  `
          ) : (
            <></>
          )}
          {isLoading() ? (
            'Fetching ...'
          ) : MilestoneStore.milestones[props.listType] &&
            MilestoneStore.milestones[props.listType]?.activity ? (
            `Last Updated: ${
              MilestoneStore.milestones[props.listType]?.activity
            }  `
          ) : (
            <></>
          )}
        </div>
      </>
    )
  }

  return renderList()
}

export default observer(MilestoneList)
