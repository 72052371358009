import React, { Component } from 'react'
import {
  MDBBtn,
  MDBContainer,
  MDBDataTable,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from 'mdbreact'
import DashboardLayout from '../components/Admin/DashboardLayout'
import AdminApi from '../api/admin-api/admin-api'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'

class AdminDashboardContentPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      toggleStateA: false,
      modal: false,
      confirmModal: false,
      viewdata: '',
      pageList: [],
      data: {
        columns: [
          {
            label: 'Page Name',
            field: 'name',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Created At',
            field: 'createdAt',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Actions',
            field: 'actions',
            sort: 'disabled',
            width: 150,
          },
        ],
        rows: [],
      },
    }
  }

  componentDidMount() {
    this.fetchContentData()
  }

  handleResize = () =>
    this.setState({
      windowWidth: window.innerWidth,
    })

  handleToggleClickA = () => {
    this.setState({
      toggleStateA: !this.state.toggleStateA,
    })
  }

  fetchContentData = async () => {
    this.setState({ loading: true })
    AdminApi.getContentRequest()
      .then((result) => {
        this.setState({ pageList: result.data.data || [] })
      })
      .then(() => {
        this.setRows()
        this.setState({ loading: false })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  setRows = () => {
    const { pageList } = this.state
    if (pageList && pageList.length) {
      let rows = pageList.map((row) => {
        const { page_name, created_at, id } = row
        const submittedDate = (
          <span
            searchvalue={
              created_at ? moment(created_at).format('YYYY/MM/DD') : ''
            }
          >
            {created_at ? moment(created_at).format('MM/DD/YYYY') : 'N/A'}
          </span>
        )
        const self = this

        function edit() {
          self.props.history.push(`/content-pages/${id}/edit`)
        }

        function deletePage() {
          self.confirm_DeletePage({ viewdata: row })
        }

        const actions = (
          <div className={'d-flex align-items-center justify-content-center'}>
            {/*<MDBBtn color="default" rounded size="sm" onClick={view}>
                        View
                    </MDBBtn>*/}
            <div
              className={'cursor-pointer d-inline-block mr-3'}
              role={'button'}
              onClick={edit}
            >
              <i className={'fa fa-edit fa-2x blue-text p-1'}></i>
            </div>
            <div
              className={'cursor-pointer d-inline-block'}
              onClick={deletePage}
            >
              <i className={'fa fa-trash fa-2x red-text p-1'}></i>
            </div>
          </div>
        )
        return {
          name: `${page_name}`,
          createdAt: submittedDate,
          actions: actions,
        }
      })
      this.setState({
        data: { ...this.state.data, rows: rows },
      })
    }
  }

  toggle = (data = {}) => {
    this.setState({ modal: !this.state.modal, ...data })
  }

  confirm_toggle = (data = {}) => {
    this.setState({ confirmModal: !this.state.confirmModal, ...data })
  }

  toDateTime = (secs) => {
    let t = new Date(1970, 0, 1) // Epoch
    t.setSeconds(secs)
    return t
  }

  onChange = (event) => {
    /*const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value
        });*/
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  Update_pages = async (event) => {
    /*event.preventDefault();

        var data = JSON.stringify({
            docId: viewdata.docId,
            status: formValues.status,
            notes: formValues.notes
        });
        console.log("submitted data>>>", data)
        const response = await fetch('https://us-central1-usabg-3ef10.cloudfunctions.net/updatePageRequest', {
            method: 'post',
            body: data,
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response=>{
                alert("pages sucessfully updated")
            })
            .catch( alert("getting error"))

        console.log('updated response->', response)
        const json = await response.json();
        if (json) {
            window.location.reload(false);
        }*/
  }

  confirm_DeletePage = (data) => {
    this.confirm_toggle(data)
  }

  DeletePage = async (id) => {
    this.setState({ deleting: true })
    // const {viewdata} = this.state;
    var data = {
      type: 'agency_content',
      id: id,
    }

    try {
      await AdminApi.deletePage(data).then((response) => {
        this.setState({ deleting: false })
        if (response.status === 200) {
          this.confirm_toggle()
          toast.success('Page successfully deleted', {
            position: toast.POSITION.TOP_RIGHT,
          })
          this.fetchContentData()
        }
      })
    } catch (e) {
      this.setState({ deleting: false })
      this.setState({ deleting: false })
      this.confirm_toggle()
      let error = e.message
      toast.error(`${error}`, { position: toast.POSITION.TOP_RIGHT })
    }

    /*const response = await fetch('https://us-central1-usabg-3ef10.cloudfunctions.net/deletePage', {
            method: 'post',
            body: data,
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response=>{
                this.setState({deleting: false});
                this.confirm_toggle();
                toast.success("Page successfully deleted", {position: toast.POSITION.TOP_RIGHT});
                this.fetchContentData();
            })*/
  }

  render() {
    toast.configure()
    const { modal, confirmModal, viewdata, data, loading } = this.state

    const createNew = () => {
      this.props.history.push('/content-pages/new')
    }
    console.log(modal)
    return (
      <>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              <h2>
                Pages
                <div className="d-inline-block">
                  {/*<MDBBtn color="primary" rounded size="sm" onClick={createNew}>
                                        Create New
                                    </MDBBtn>*/}
                  <MDBBtn tag="a" floating gradient="blue" onClick={createNew}>
                    <MDBIcon icon="plus" />
                  </MDBBtn>
                </div>
              </h2>
              {loading ? (
                <h4 className={'p4'}>Loading...</h4>
              ) : (
                <MDBDataTable
                  sortRows={['name', 'createdAt']}
                  striped
                  bordered
                  small
                  order={['name', 'asc']}
                  data={data}
                  entriesOptions={[100, 150, 200]}
                  entries={100}
                  className="text-capitalize"
                />
              )}
              <MDBModal size={'fluid'} isOpen={modal} toggle={this.toggle}>
                <MDBModalHeader toggle={this.toggle}>Page Info</MDBModalHeader>
                <MDBModalBody>
                  <form>
                    <div className="form-row">
                      <label>Page Name</label>
                      <textarea
                        required
                        disabled={true}
                        className="form-control"
                        value={viewdata ? viewdata.page_name : ''}
                      ></textarea>
                    </div>
                    <div className="form-row">
                      <label>Content</label>
                      <hr />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: viewdata ? viewdata.content : '',
                        }}
                      ></div>
                    </div>
                    <div className="form-row">
                      <MDBBtn color="default" onClick={this.Update_pages}>
                        Save
                      </MDBBtn>
                      <MDBBtn color="danger" onClick={this.DeletePage}>
                        Delete Request
                      </MDBBtn>
                    </div>
                  </form>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn color="secondary" onClick={this.toggle}>
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModal>

              <MDBModal
                size={'sm'}
                isOpen={confirmModal}
                toggle={this.confirm_toggle}
              >
                <MDBModalHeader toggle={this.confirm_toggle}>
                  Delete Page
                </MDBModalHeader>
                <MDBModalBody>
                  <div className="h5">
                    Are you sure you want to delete this page?
                  </div>
                  <MDBBtn
                    color="secondary"
                    size={'sm'}
                    onClick={this.confirm_toggle}
                  >
                    Close
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    size={'sm'}
                    onClick={() => {
                      this.DeletePage(this.state.viewdata.id)
                    }}
                  >
                    {this.state.deleting ? 'Deleting...' : 'Delete'}
                  </MDBBtn>
                </MDBModalBody>
              </MDBModal>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </>
    )
  }
}

export default withRouter(AdminDashboardContentPage)
