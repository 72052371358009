import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  MDBContainer,
  MDBCard,
  MDBCardHeader,
  MDBCardFooter,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBCollapse,
  MDBBtn,
  MDBIcon,
} from 'mdbreact'
import MessageService from './../../shared/services/Message.service'
import UserProfileService from '../../shared/services/UserProfile.service'
import PaginationComponent from './../../components/adminShared/Pagination.component'
import { Subscription } from 'rxjs'
import moment from 'moment'

import './MessageViewer.scss'

const today = moment().startOf('day'),
lastDate = moment(new Date()).subtract(7, 'days').endOf('day'),
timezoneOffset = new Date().getTimezoneOffset();

class MessageViewer extends React.Component {
  state = {
    fetching: true,
    messages: null,
    pagination: null,

    show_additional: false,
    message: null,
    isEmpty: null,
  }

  #divisionId = null
  #placeholderMessage = {
    content:
      "<p><strong>All caught up!</strong><br />To read past messages, click 'View More'</p>\n",
    created_at: new Date(),
    creator: null,
    title: 'No new messages',
    updated_at: new Date(),
  }

  #_subscriptions$ = new Subscription()

  async getHomepageMessages() {
    const message_data = await MessageService.getHomepageMessages(
      0,
      10,
      this.props?.division_id
    )
    this.#onMessageFetch(message_data)
  }

  componentDidMount() {
    MessageService.clearMessages()

    if (!this.props.isHomepageBoard)
      this.#_subscriptions$.add(
        MessageService.getMessages(0, 10, this.props?.division_id).subscribe(
          (messages) => {
            if (typeof messages === 'object' && Object.keys(messages).length) {
              let message_data = { ...messages }
              message_data.messages = message_data.messages?.filter(
                (n) => !n.start_date || moment(n.start_date).isBefore(today)
              )

              this.#onMessageFetch(message_data)
            }
          }
        )
      )
    else this.getHomepageMessages()
  }

  componentWillUnmount() {
    this.#_subscriptions$.unsubscribe()
  }

  #onMessageFetch = async (messages) => {
    if (typeof messages === 'object' && Object.keys(messages).length) {

      // set default/initial message if one was not defined.
      let msgs,
        message = this.state.message

      if (
        parseInt(this.props?.division_id) !== this.#divisionId &&
        !this.props.isHomepageBoard
      ) {
        message = null
        this.#divisionId = parseInt(this.props?.division_id)
      }

      message = message
        ? message
        : messages && messages.messages && messages.messages.length
        ? messages.messages
            .filter((n) => {
              if (n.end_date) 
                return moment(new Date(new Date(n.end_date).getTime() + timezoneOffset * 60000)).isAfter(today)
              else {
                if (n.start_date) 
                  return moment(new Date(new Date(n.start_date).getTime() + timezoneOffset * 60000)).isAfter(lastDate)
                else return moment(new Date(new Date(n.created_at).getTime() + timezoneOffset * 60000)).isAfter(lastDate)
              }
            })
            .shift()
        : null

      // remove the currently displayed message from the message list.
      msgs = message
        ? messages.messages.filter(
            (msg) => parseInt(msg.id) !== parseInt(message.id)
          )
        : messages.messages

      // set message placeholder is all messages have expired.
      let isEmpty = !message
      message =
        message && Object.keys(message).length
          ? message
          : this.#placeholderMessage

      this.setState(
        Object.assign(
          { fetching: false },
          { ...messages, message, messages: msgs, isEmpty }
        )
      )
    }
  }

  #fetchDashboardMessages = async (page = 0, perPage = 4) => {
    if (!this.state.fetching) this.setState({ fetching: true })

    MessageService.getMessages(page, perPage, this.props?.division_id)
  }

  render() {
    if (this.props.hideIfEmpty === true && this.state.isEmpty === true)
      return <></>

    // Build Message Array.
    const pagination =
        this.state.pagination && Object.keys(this.state.pagination)
          ? this.state.pagination
          : { page: 0, per_page: 0, total: 0 },
      messages = (
        this.state.messages && Array.isArray(this.state.messages)
          ? this.state.messages
          : []
      ).map((msg, i) => {
        return (
          <React.Fragment key={'msg-idx-' + i}>
            <MDBRow>
              <MDBCol size="12" className="historical-msg">
                <h5 className="text-primary">{msg.title}</h5>
                <div dangerouslySetInnerHTML={{ __html: msg.content }}></div>
              </MDBCol>
            </MDBRow>
            <hr className={'my-1'} />
          </React.Fragment>
        )
      })

    const className = this.props.headerColor
        ? `bg-${this.props.headerColor}`
        : '',
      message = this.state.message && this.state.message.content,
      createdBy = message && this.state.message.creator,
      createdAt =
        createdBy && this.state.message.created_at
          ? moment(new Date(new Date(this.state.message.created_at).getTime() + timezoneOffset * 60000)).format('MM/DD/YYYY')
          : false,
      startDate = 
        createdBy && this.state.message.start_date
          ? moment(new Date(new Date(this.state.message.start_date).getTime() + timezoneOffset * 60000)).format('MM/DD/YYYY')
          : false

    return (
      <MDBContainer id="MessageViewerComponent" className={className} fluid>
        <MDBCard>
          <MDBCardHeader>
            <h5 className="text-uppercase text-truncate text-center">
              {this.props.viewerName ? `${this.props.viewerName} ` : ''}Message
              Board - Hot Off The Press!
            </h5>
            {UserProfileService.isA(
              ['internal-admin', 'internal-staff', 'system-admin'],
              true
            ) &&
              !this.props.isHomepageBoard && (
                <MDBIcon
                  fas
                  icon="edit"
                  className="message-edit"
                  onClick={(event) => {
                    if (this.state.message)
                      this.props.history.push(
                        `/manage-messaging?id=${this.state.message.id}`
                      )
                  }}
                />
              )}
          </MDBCardHeader>
          <MDBCardBody>
            {
              <div
                className="body-content"
                dangerouslySetInnerHTML={{
                  __html: message
                    ? message.substring(
                        0,
                        message.split('</p>', 2).join('</p>').length
                      ) + '</div>'
                    : '',
                }}
              ></div>
            }
            <MDBRow className="controls">
              <MDBCol size="12" sm="6">
                {message ? (
                  <div className="creator">
                    <table>
                      <tbody>
                        <tr>
                          <td>{createdBy ? 'Created By:' : ''}</td>
                          <td>{createdBy}</td>
                        </tr>
                        <tr>
                          <td>{createdAt ? 'Posted On:' : ''}</td>
                          <td>
                            {
                              startDate && moment(new Date(new Date(this.state.message.start_date).getTime() + timezoneOffset * 60000)).isAfter(lastDate) 
                              ? startDate 
                              : createdAt
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <></>
                )}
              </MDBCol>
              <MDBCol size="12" sm="6">
                {messages && messages.length ? (
                  <MDBBtn
                    className="view-more-btn"
                    size="sm"
                    color="red"
                    type="button"
                    onClick={() =>
                      this.setState({
                        show_additional: !this.state.show_additional,
                      })
                    }
                  >
                    {this.state.show_additional ? 'View Less' : 'View More'}
                  </MDBBtn>
                ) : (
                  <></>
                )}
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
          <MDBCardFooter className={this.state.show_additional ? 'show' : ''}>
            <MDBCollapse isOpen={this.state.show_additional}>
              {messages}
              {pagination && parseInt(pagination.total) && (
                <>
                  <div className="pagination-wrapper">
                    <PaginationComponent
                      pagination={pagination}
                      onPageSelect={(page, perPage) =>
                        this.#fetchDashboardMessages(page, perPage)
                      }
                    />
                  </div>
                </>
              )}
            </MDBCollapse>
          </MDBCardFooter>
        </MDBCard>
      </MDBContainer>
    )
  }
}

export default withRouter(MessageViewer)
