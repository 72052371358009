import React from 'react'
import {
  MDBIcon,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownToggle,
} from 'mdbreact'
import { toast } from 'react-toastify'
import AdminApi from './../../../../api/admin-api/admin-api'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import appConstants from './../../../../constants/appConstants'

import './AgentUsertypeSelector.scss'

const getIsUsertypePaid = (uId) => {
  return appConstants.paid_subscriptions.includes(uId) === true
    ? 'paid'
    : appConstants.unpaid_subscriptions.includes(uId)
    ? 'unpaid'
    : ''
}

class AgentUsertypeSelector extends React.Component {
  state = {
    isPending: false,
    isSaving: false,
    usertypes: [],
  }

  #currentUsertype = ''
  #currentUsertypeId = null
  #changeUsertype = ''
  #changeUsertypeId = null
  #usertypeOptions = []
  #isAdmin = null

  set usertype(uId) {
    if (parseInt(uId) === this.#currentUsertypeId) return

    if (isNaN(parseInt(uId))) {
      this.#currentUsertype = ''
      return
    }

    uId = this.#getUsertypeById(uId)

    if (uId) {
      this.#currentUsertypeId = uId ? parseInt(uId.id) : ''
      this.#currentUsertype = uId ? uId.displayname : ''
    }
  }

  get usertype() {
    if (this.#changeUsertype) return this.#changeUsertype

    if (
      (!this.#currentUsertype && this.props?.agent?.usertype_id) ||
      this.#currentUsertypeId !== parseInt(this.props?.agent?.usertype_id)
    )
      this.usertype = this.props?.agent?.usertype_id

    return this.#currentUsertype
  }

  set usertypes(usertypeOpts) {
    usertypeOpts = Array.isArray(usertypeOpts) ? usertypeOpts : []

    if (typeof this.#isAdmin !== 'boolean')
      this.#isAdmin =
        [35, 36, 219, 220].indexOf(
          parseInt(UserProfileService.getCurrentUserTypeId())
        ) > -1

    // Hide If Not Admin.
    // 92, 111, 35, 36, 219, 220
    if (this.#isAdmin === false) {
      // if the current usertype is either 'Limited to Contracting' or 'Limited to Training'
      // limit the usertype options to these same usertypes.
      if ([91, 149].indexOf(parseInt(this.#currentUsertypeId)) > -1) {
        usertypeOpts = usertypeOpts.filter(
          (u) => [91, 149].indexOf(parseInt(u.id)) > -1
        )
      } else {
        usertypeOpts = usertypeOpts.filter(
          (u) => parseInt(u.id) === parseInt(this.#currentUsertypeId)
        )
      }
    } else if (
      this.#isAdmin &&
      [35, 219].indexOf(parseInt(UserProfileService.getCurrentUserTypeId())) < 0
    ) {
      usertypeOpts = usertypeOpts.filter(
        (u) => [35, 219].indexOf(parseInt(u.id)) < 0
      )
    }

    this.#usertypeOptions = usertypeOpts
  }

  get usertypes() {
    if (this.#usertypeOptions.length || this.#currentUsertypeId === null)
      return this.#usertypeOptions

    this.usertypes = this.props?.usertypes

    return this.#usertypeOptions
  }

  #getUsertypeById = (uId) => {
    if (Array.isArray(this.props?.usertypes))
      return this.props.usertypes
        .filter((u) => parseInt(uId) === parseInt(u.id))
        .shift()
  }

  #checkSubscriptionType = (usertype_id) => {
    let old_subscription = getIsUsertypePaid(this.props.agent.usertype_id),
      new_subscription = getIsUsertypePaid(usertype_id)

    // don't allow if old subscription is paid and new subscription is unpaid
    if (old_subscription === 'paid' && new_subscription === 'unpaid') {
      return false
    }
    // don't allow if old subscription is unpaid and new subscription is paid
    else if (old_subscription === 'unpaid' && new_subscription === 'paid') {
      return false
    }
    // allow if both are same
    else if (old_subscription === 'paid' && new_subscription === 'paid') {
      return true
    }
    // allow if both are same
    else if (old_subscription === 'unpaid' && new_subscription === 'unpaid') {
      return true
    }
    // allow in other cases
    else {
      return true
    }
  }

  #updateUsertype = async (usertype) => {
    if (!this.#checkSubscriptionType(usertype?.id)) {
      toast.error('Subscription update is not allowed!', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return false
    }
    if (this.state.isSaving) return

    if (parseInt(this.#currentUsertypeId) === parseInt(usertype?.id)) {
      this.#changeUsertypeId = null
      this.#changeUsertype = ''
      if (this.state.isPending) this.setState({ isPending: false })
      return
    }

    this.#changeUsertypeId = parseInt(usertype.id)
    this.#changeUsertype = usertype.displayname
    this.setState({ isPending: true })
  }

  #revertChange = () => {
    if (this.state.isSaving) return

    this.#changeUsertypeId = null
    this.#changeUsertype = ''

    if (this.state.isPending) this.setState({ isPending: false })
  }

  #saveChange = () => {
    this.setState({ isPending: false, isSaving: true })

    AdminApi.updateAgentsUsertype({
      id: this.props.agent.id,
      usertype_id: this.#changeUsertypeId,
    })
      .then((result) => {
        this.props.agent.usertype_id = this.#changeUsertypeId
        this.#changeUsertypeId = null
        this.#changeUsertype = ''

        if (typeof this.props.onChange === 'function')
          this.props.onChange({ status: true })

        this.setState({ isSaving: false })
      })
      .catch((err) => {
        if (typeof this.props.onChange === 'function')
          this.props.onChange({ status: err })

        this.setState({ isPending: true, isSaving: false })
      })
  }

  #setUsertypeOptions = () =>
    this.setState({ usertypes: this.usertype ? this.usertypes : [] })

  componentDidMount() {
    this.#setUsertypeOptions()
  }

  render() {
    return (
      <>
        <div
          className={
            'AgentUsertypeSelector ' +
            [
              this.state.isPending ? 'pending-change' : '',
              this.state.isSaving ? 'saving-change' : '',
            ].join(' ')
          }
        >
          <MDBDropdown>
            <MDBDropdownToggle
              caret
              className="agent-usertype-toggle"
              disabled={
                !this.props.canChangeUsertype ||
                !this.usertypes ||
                this.usertypes.length < 2
              }
            >
              <span>{this.usertype}</span>
            </MDBDropdownToggle>
            <MDBDropdownMenu basic>
              {this.state.usertypes
                .filter((usertype) => this.#checkSubscriptionType(usertype?.id))
                .map((usertype, i) => (
                  <MDBDropdownItem
                    key={'ut-' + i}
                    onClick={() => this.#updateUsertype(usertype)}
                    className={
                      this.props.agent.usertype_id === usertype.id
                        ? 'selected'
                        : ''
                    }
                  >
                    <span>{usertype.displayname}</span>
                  </MDBDropdownItem>
                ))}
            </MDBDropdownMenu>
          </MDBDropdown>
          <div
            className={
              'action-btns ' + (this.state.isSaving ? 'is-saving' : '')
            }
          >
            <button onClick={this.#saveChange}>
              <MDBIcon icon="save" size="1x" />
            </button>
            <button onClick={this.#revertChange}>
              <MDBIcon icon="times" size="1x" />
            </button>
            <div className="save-icon">
              <span>
                <i className="fa fa-spin fa-spinner" />
              </span>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default AgentUsertypeSelector
