import React, { Component, Fragment } from 'react';
import { MDBContainer, MDBBtn, MDBRow, MDBCol, MDBBreadcrumb, MDBBreadcrumbItem, MDBInput, MDBFileInput} from "mdbreact";
import DashboardLayout from "../../components/Admin/DashboardLayout";

class AddLeadCompany extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() {
        return (
            <Fragment>
                <DashboardLayout>
                <main className="adminStyle mainSection pb-5">
                    <MDBContainer fluid className="mt-5">
                    <MDBBreadcrumb>
                        <MDBBreadcrumbItem><a href="admin-leadorders">List</a></MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>Add Lead Company</MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                    <h2>Add Lead Company</h2>
                    <hr />
                    <MDBRow>
                        <MDBCol size="4">
                            <form className="content-wrapper">
                                <MDBInput label="Name" />
                                <MDBFileInput /> 


                                <div className="text-center mt-4">
                                <MDBBtn className="btn-block" color="unique" type="submit" onClick={this.handleSubmit}>
                                    Save
                                </MDBBtn>
                                </div>
                            </form>
                        </MDBCol>
                    </MDBRow>
                    </MDBContainer>
                </main>
                </DashboardLayout>
            </Fragment>
         );
    }
}

export default AddLeadCompany;