import React from 'react'
import AgentIdentity from './../AgentIdentity/AgentIdentity.component'
import SubsiteNavigation from './../SubsiteNavigation/SubsiteNavigation.component'

import './SubsiteSidebar.component.scss';

const SubsiteSidebar = ({subsiteData, licenses}) => 
{
	return (
      <div id="SubsiteSidebarComponent">
      	<AgentIdentity subsiteData={subsiteData} />
      	<SubsiteNavigation subsiteData={subsiteData} licenses={licenses} />
      </div>
		)
}

export default SubsiteSidebar;