import { MDBFormInline, MDBInput } from 'mdbreact'
import React from 'react'

export const AdditionalCarrierFilter = ({ onChange, value }) => {
  const filterOptions = ['ALL', 'HEALTH', 'SENIOR', 'LIFE']
  const onClick = (v) => (e) => {
    onChange(v)
  }
  return (
    <MDBFormInline className="float-right pr-1 mr-4 font-weight-normal h6">
      {filterOptions.map((option) => {
        return (
          <MDBInput
            key={option}
            gap
            onClick={onClick(option)}
            checked={value === option ? true : false}
            label={option}
            type="radio"
            id={`option-${option}`}
            value={value}
            containerClass="mr-2"
          />
        )
      })}
    </MDBFormInline>
  )
}
