import React from 'react'
import { observer } from 'mobx-react-lite'
import { UIInput, UISelect } from './../../../../components/forms/form-fields'
import PaginationControls from './../../../../components/content/PaginationControls/PaginationControls.component'
import Store from './../../shared/Referrals.store'
import { MDBBtn, MDBIcon } from 'mdbreact'

import './List.component.scss'

const List = () => {
  const onChange = (evt) => {
    const agentId = evt.target.value

    if (agentId === null) {
      if (Store.searchAgentId !== null) {
        Store.searchAgentId = null
        Store.searchReferralPartners({ pagination: { page: 0 } })
      }
      return
    } else if (!isNaN(agentId) && agentId > 0) {
      if (parseInt(Store.searchAgentId) !== parseInt(agentId)) {
        Store.searchAgentId = agentId
        Store.searchReferralPartners({ pagination: { page: 0 } })
      }
      return
    }
  }

  return (
    <div id="ListComponent">
      <ul
        className={`referral-partner-options ${
          !Store.ReferralPartner ? 'show-details' : ''
        }`}
      >
        <li>
          <UISelect
            label="Filter By Agent"
            name="agent_id"
            options={[
              {
                value: null,
                text: 'All Agents',
                checked: Store.searchAgentId === null,
              },
            ].concat(
              Object.values(Store.Agents)
                .map((Agent) => ({
                  value: `${Agent.id()}`,
                  text: [Agent.get('u_fname'), Agent.get('u_lname')]
                    .filter((n) => n)
                    .join(' ')
                    .trim(),
                  checked:
                    parseInt(Agent.id()) === parseInt(Store.searchAgentId),
                }))
                .sort((a, b) => a.text.localeCompare(b.text, 'en'))
            )}
            onChange={onChange}
            rules={{ required: true }}
          />
        </li>
      </ul>
      <ul
        className={`referral-partner-list ${
          !Store.ReferralPartner ? 'show-details' : ''
        }`}
      >
        <li>
          <div className="rp-id">ID</div>
          <div className="rp-name">Referral Partner</div>
          <div className="rp-agent-name">Agent</div>
          <div className="rp-phone">Phone</div>
          <div className="rp-email">Email</div>
          <div className="rp-licensed">Is Licensed?</div>
          <div className="rp-w9">W9 On File?</div>
          <div className="rp-products">Products Sold</div>
          <div className="rp-btns"></div>
        </li>
        {Store.ReferralPartners.map((ReferralPartner, idx) => {
          return (
            <li key={`referral-partner-list-${ReferralPartner.id()}-${idx}`}>
              <div className="rp-id">{ReferralPartner.id()}</div>
              <div className="rp-name">{ReferralPartner.get('name')}</div>
              <div className="rp-agent-name">
                {Store.getAgentNameByReferralPartner(ReferralPartner)}
              </div>
              <div className="rp-phone">{ReferralPartner.get('phone')}</div>
              <div className="rp-email">{ReferralPartner.get('email')}</div>
              <div className="rp-licensed">
                {parseInt(ReferralPartner.get('is_licensed')) === 1
                  ? 'Yes'
                  : 'No'}
              </div>
              <div className="rp-w9">
                {parseInt(ReferralPartner.get('w9_onfile')) === 1
                  ? 'Yes'
                  : 'No'}
              </div>
              <div className="rp-products">
                {ReferralPartner.get('products_sold')}
              </div>
              <div className="rp-btns">
                <MDBBtn
                  disabled={
                    Store.isLoading ||
                    Store.isSearching ||
                    Store.isSaving ||
                    (Store.ReferralPartner &&
                      parseInt(Store.ReferralPartner.id()) !==
                        parseInt(ReferralPartner.id()))
                  }
                  onClick={() => Store.edit(ReferralPartner)}
                >
                  <MDBIcon far icon="eye" />
                </MDBBtn>
              </div>
            </li>
          )
        })}
      </ul>
      <div className="pagination-wrapper">
        <PaginationControls
          pagination={Store.referralPagination}
          onPageSelect={(pagination) =>
            Store.searchReferralPartners({ pagination })
          }
        />
      </div>
    </div>
  )
}

export default observer(List)
