import React, { Component, Fragment } from 'react'
import { MDBContainer } from 'mdbreact'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import PageHeading from '../../components/shared/PageHeading.component'
import { MDBBtn, MDBIcon, MDBDataTable } from 'mdbreact'
import TrainingQuizService from '../../shared/services/TrainingQuiz.service'
import TrainingService from '../../shared/services/Training.service'
import ConfirmDeleteModalComponent from '../../components/adminShared/ConfirmDeleteModal/ConfirmDeleteModal.component'
import LoadingSpinner from '../../components/shared/LoadingSpinner.component'
import { toast } from 'react-toastify'

import './TrainingQuizes.page.scss'

const COLUMNS = [
  {
    label: 'Training',
    field: 'training',
    sort: 'asc',
  },
  {
    label: 'Edit',
    field: 'edit',
    sort: 'asc',
  },
  {
    label: 'Delete',
    field: 'delete',
    sort: 'asc',
  },
]

class TrainingQuizesPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      isDeleting: false,
      deletedQuiz: null,
      training_quizes: { columns: [], rows: [] },
    }
  }

  _formatRows = (results) => {
    results = results ? results : this._results

    return results.map((row) => {
      let r = {
        training: row.training_name,
        edit: (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={() => this.edit(row.id)}
            >
              <MDBIcon
                icon="edit"
                size="2x"
                className="d-flex justify-content-center blue-text p-1"
              />
            </div>
          </div>
        ),
        delete: (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={() => this.delete(row)}
            >
              <MDBIcon
                icon="trash"
                size="2x"
                className="d-flex justify-content-center red-text p-1"
              />
            </div>
          </div>
        ),
      }

      return r
    })
  }

  create() {
    this.props.history.push('/add-training-quiz')
  }

  edit(id) {
    this.props.history.push(`/add-training-quiz/${id}/edit`)
  }

  async delete(quiz) {
    this.setState({
      isDeleting: true,
      deletedQuiz: quiz.id,
    })
  }

  async deleteCode() {
    this.setState({
      isLoading: true,
      isDeleting: false,
    })
    await TrainingQuizService.update(this.state.deletedQuiz, {
      deleted_at: new Date().toISOString().slice(0, -5),
    })
    toast.success('Training Quiz has been deleted successfully.', {
      position: toast.POSITION.TOP_RIGHT,
    })
    await this.fetchTrainingData()
    this.setState({
      isLoading: false,
      deletedQuiz: null,
    })
  }

  componentDidMount() {
    this.fetchTrainingData()
  }

  async fetchTrainingData() {
    this.setState({ isLoading: true })
    const training_quizes = await TrainingQuizService.search({
        pagination: false,
      }),
      trainings = await TrainingService.search({ pagination: false })
    const training_quizes_with_names = training_quizes.models.map((quiz) => {
      quiz.training_name =
        trainings.models.find((training) => training.id === quiz.training_id)
          ?.training_name || ''
      return quiz
    })
    this.setState({
      training_quizes: {
        columns: COLUMNS,
        rows: this._formatRows(training_quizes_with_names),
      },
    })
    this.setState({ isLoading: false })
  }

  renderCreateButton() {
    return (
      <MDBBtn
        color="info"
        disabled={this.state.isLoading || this.state.isAdding}
        id="CreateTrainingQuizesButtonComponent"
        onClick={() => this.create()}
        floating
        size="sm"
      >
        <MDBIcon icon="plus" />
      </MDBBtn>
    )
  }

  render() {
    return (
      <Fragment>
        <DashboardLayout>
          {this.state.isLoading ? (
            <LoadingSpinner isActive={true} size="md" />
          ) : (
            <main id="TrainingQuizesPage" className="mainSection pb-5">
              <MDBContainer fluid className="mt-5">
                <PageHeading
                  label="Training Quizzes"
                  url={this.props?.match?.url}
                  from={this.props?.location?.state?.from}
                  btnGroup={this.renderCreateButton()}
                />
              </MDBContainer>
              <MDBContainer fluid>
                <div className="content-wrapper">
                  <MDBDataTable
                    responsive
                    striped
                    bordered
                    small
                    data={this.state.training_quizes}
                    className="text-capitalize usabg-datatable"
                  />
                </div>
                {this.state.isDeleting ? (
                  <ConfirmDeleteModalComponent
                    confirm={this.state.isDeleting}
                    onConfirm={() => this.deleteCode()}
                    shouldEnable={true}
                    onCancel={() =>
                      this.setState({ deletedQuiz: null, isDeleting: false })
                    }
                  />
                ) : (
                  ''
                )}
              </MDBContainer>
            </main>
          )}
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default TrainingQuizesPage
