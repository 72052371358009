import React, { useState, useEffect, lazy, Suspense } from 'react'

import AdminApi from '../api/admin-api/admin-api'
import StorageService from './../shared/services/Storage.service'
import { toast } from 'react-toastify'
import env from '../environments/environment'
import Loader from '../components/Loader/Loader'

const Navbar = lazy(() => import('../components/newhome/Navbar'))
const Banner = lazy(() => import('../components/newhome/Banner'))
const InsuranceType = lazy(() => import('../components/newhome/InsuranceType'))
const About = lazy(() => import('../components/newhome/About'))
const Counter = lazy(() => import('../components/newhome/Counter'))
const Team = lazy(() => import('../components/newhome/Team'))
const Testimonials = lazy(() => import('../components/newhome/Testimonials'))
const QuotationTabs = lazy(() => import('../components/newhome/QuotationTabs'))
const Mission = lazy(() => import('../components/newhome/Mission'))
const Products = lazy(() => import('../components/newhome/Products'))
const Footer = lazy(() => import('../components/newhome/Footer'))

const Home = ({ location }) => {
  const [content, setContent] = useState(null)
  const [config, setConfig] = useState(null)
  const [currentHash, setCurrentHash] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await AdminApi.getHomepageData()
        if (result && result.data && result.data.data) {
          let homepageContent = result.data.data.find(
            (e) => e.page_name === 'Homa_Page'
          )
          let parsedConfig
          if (
            homepageContent &&
            /^[\],:{}\s]*$/.test(
              homepageContent.content
                .replace(/\\["\\/bfnrtu]/g, '@')
                .replace(
                  /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g,
                  ']'
                )
                .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
            )
          ) {
            parsedConfig = JSON.parse(homepageContent.content)
          }

          if (parsedConfig) {
            parsedConfig.insurances = parsedConfig.insurances.map((item) => {
              if (item.name === 'Small Business') {
                item.image = item.image.replace(
                  'totemic-bonus-239316.appspot.com',
                  env.integrations.firebase.config.storageBucket
                )
              }
              return item
            })
          }

          setContent(homepageContent)
          setConfig(parsedConfig)
        }
      } catch (error) {
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (
      location?.hash &&
      currentHash !== `${location.hash}`.trim().toLowerCase()
    ) {
      setCurrentHash(`${location.hash}`.trim().toLowerCase())
    }
  }, [location, currentHash])

  return (
    <>
      <Suspense fallback={<Loader />}>
        <div className="page">
          <Navbar />
          <Banner />
          {config ? (
            <>
              {config.showBelowBannerSection && (
                <InsuranceType
                  insurances={config.insurances}
                  insuranceType={
                    (
                      `${currentHash}`.match(
                        /(small-biz|health|life|senior)/i
                      ) || []
                    ).shift() || 'health'
                  }
                />
              )}

              {config.showAboutSection && (
                <About config={config.missionStatementConfig} />
              )}

              {config.showCounterSection && (
                <Counter config={config.counterConfig} id="#counter" />
              )}

              {/* <Team /> */}

              <div className={'testimonial'}>
                <Testimonials />
              </div>

              <div id="quote-small-biz-insurance"></div>
              <div id="quote-health-insurance"></div>
              <div id="quote-senior-insurance"></div>
              <div id="quote-life-insurance"></div>

              <QuotationTabs
                insuranceType={
                  (
                    `${currentHash}`.match(/(small-biz|health|life|senior)/i) ||
                    []
                  ).shift() || 'health'
                }
                insurances={config.insurances}
                customerType={
                  `${currentHash}`.includes('small-biz')
                    ? 'business'
                    : 'individual'
                }
                onSuccess={(response) => {
                  StorageService.set('modals.quote-popup', new Date().getTime())
                }}
              />

              {config.showMissionStatement && (
                <Mission config={config.aboutConfig} />
              )}

              {config.showProjectSection && (
                <Products config={config.projects} />
              )}
            </>
          ) : (
            <div className={'custom-loader'}>
              <div className="loader"></div>
            </div>
          )}
          <Footer />
        </div>
      </Suspense>
    </>
  )
}

export default Home
