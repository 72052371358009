import { observer } from 'mobx-react-lite'
import React from 'react'
import { MDBCard, MDBCardHeader, MDBCardBody } from 'mdbreact'
import CarrierMgmtStore from './../../CarrierMgmt.store'
import { UIList } from '../../../../components/forms/form-fields'

import './CarrierCoveragesForm.scss'

const CarrierCoveragesForm = ({ table }) => {
  const renderLockOut = () => {
    if (
      !CarrierMgmtStore.carrier ||
      (CarrierMgmtStore.carrier && isNaN(CarrierMgmtStore.carrier?.id))
    )
      return <div className="lockout-window">lockout</div>
  }

  const onChange = (event) => {
    const CarrierCoverage = CarrierMgmtStore.coverages
      .filter(
        (cov) => parseInt(cov.coverage_id) === parseInt(event.target.name)
      )
      .shift()

    if (event.target.value === true) {
      if (!CarrierCoverage)
        CarrierMgmtStore.toggleCoverage(parseInt(event.target.name))
    } else if (event.target.value === false) {
      if (CarrierCoverage)
        CarrierMgmtStore.toggleCoverage(parseInt(event.target.name))
    }
  }

  const renderList = () => {
    const isChecked = (covId) =>
      CarrierMgmtStore.coverages.filter(
        (cov) => parseInt(cov.coverage_id) === parseInt(covId)
      ).length > 0

    return (
      <UIList
        list={CarrierMgmtStore.source.coverages}
        label={'coverage_name'}
        value={'id'}
        onChange={onChange}
        checkedHandle={isChecked}
      />
    )
  }

  return (
    <MDBCard id="CarrierCoveragesFormComponent">
      {renderLockOut()}
      <MDBCardHeader>Carrier Coverages</MDBCardHeader>
      <MDBCardBody>{renderList()}</MDBCardBody>
    </MDBCard>
  )
}

export default observer(CarrierCoveragesForm)
