import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { MDBBtn } from 'mdbreact'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import UserService from './../../../../shared/services/User.service'
import UserMetaService from '../../../../shared/services/UserMeta.service'

const ResetEnrollmentButton = ({ onReset }) => {
  const [isLoading, setIsLoading] = useState(false)

  const meetsRequirement =
    UserProfileService.isAssumed() &&
    (UserProfileService.isA('internal-admin', true) ||
      UserProfileService.isA('system-admin', true) ||
      UserProfileService.isA('agency-owner', true))

  const resetEnrollment = async () => {
    setIsLoading(true)

    let today = new Date().toISOString().slice(0, 10),
      promises = []

    try {
      promises.push(
        UserMetaService.store({
          user_id: UserProfileService.getUserId(),
          meta_key: `profile---enroll-date-change`,
          meta_value: JSON.stringify({
            changed_on: today,
            user_id: UserProfileService.getUserId(true),
          }),
        })
      )
      promises.push(
        UserService.update(UserProfileService.getUserId(), {
          u_enrolled: today,
        })
      )
      await Promise.all(promises)
      onReset(true)
      setIsLoading(false)
      UserProfileService.track({
        event_type: 'user.reset-enrollment-date.success',
      })
    } catch (error) {
      onReset(error.message)
      setIsLoading(false)
      UserProfileService.track({
        event_type: 'user.reset-enrollment-date.failure',
      })
    }
  }

  const renderConfig = () => {
    if (meetsRequirement)
      return (
        <div className="reset-agent-enrollment-btn">
          <MDBBtn
            disabled={isLoading}
            className="btn-block"
            onClick={resetEnrollment}
            sm="true"
            color="danger"
          >
            Reset Enrollment Date
          </MDBBtn>
        </div>
      )
    return <></>
  }

  return <>{renderConfig()}</>
}

export default observer(ResetEnrollmentButton)
