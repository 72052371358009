import React from 'react'
import { MDBContainer, MDBRow, MDBIcon } from 'mdbreact'

import './GetAQuote.component.scss'

const GetAQuote = ({ subsiteData }) => {
  const openLink = (event) => {
    if (event) event.preventDefault()

    if (event?.currentTarget && event.currentTarget.getAttribute('href')) {
      window.open(event.currentTarget.getAttribute('href'))
      return false
    }

    window.location.href = `/${subsiteData.u_login}/quote`
    return false
  }

  return (
    <MDBContainer id="GetAQuoteComponent" fluid>
      <MDBRow>
        <a
          className="col"
          name="health"
          href={
            subsiteData?.quote_link_health ||
            (subsiteData?.u_login
              ? `/${subsiteData.u_login}/quote?type=health`
              : '#')
          }
          onClick={openLink}
        >
          <div className="quote-icon">
            <MDBIcon icon="medkit" />
          </div>
          <div className="font---lato fw--700 quote-label">
            HEALTH
            <br />
            INSURANCE
          </div>
          <div className="font---lato fw--500 quote-link">Get a quote</div>
        </a>
        <a
          className="col"
          name="life"
          href={
            subsiteData?.quote_link_life ||
            (subsiteData?.u_login
              ? `/${subsiteData.u_login}/quote?type=life`
              : '#')
          }
          onClick={openLink}
        >
          <div className="quote-icon">
            <MDBIcon icon="heartbeat" />
          </div>
          <div className="font---lato fw--700 quote-label">
            LIFE
            <br />
            INSURANCE
          </div>
          <div className="font---lato fw--500 quote-link">Get a quote</div>
        </a>
        <a
          className="col"
          name="senior"
          href={
            subsiteData?.quote_link_senior ||
            (subsiteData?.u_login
              ? `/${subsiteData.u_login}/quote?type=senior`
              : '#')
          }
          onClick={openLink}
        >
          <div className="quote-icon">
            <MDBIcon icon="user-md" />
          </div>
          <div className="font---lato fw--700 quote-label">
            SENIOR
            <br />
            INSURANCE
          </div>
          <div className="font---lato fw--500 quote-link">Get a quote</div>
        </a>
        <a
          className="col"
          name="aca"
          href={
            subsiteData?.quote_link_aca ||
            (subsiteData?.u_login
              ? `/${subsiteData.u_login}/quote?type=aca`
              : '#')
          }
          onClick={openLink}
        >
          <div className="quote-icon">
            <MDBIcon icon="hospital" />
          </div>
          <div className="font---lato fw--700 quote-label">
            AFFORDABLE
            <br />
            CARE ACT
          </div>
          <div className="font---lato fw--500 quote-link">Get a quote</div>
        </a>
      </MDBRow>
    </MDBContainer>
  )
}

export default GetAQuote
