import React, { useEffect, useState, useCallback, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact'

import './VideoPlayerModal.scss'

const VideoPlayerModal = (props) => {
  let debounceTimer = useRef(null)
  const [dimensions, setDimensions] = useState({ height: '90%', width: '90%' })

  const close = () => {
    if (typeof props?.onClose === 'function') props.onClose()
  }

  const setModalDimensions = useCallback(() => {
    let height = window?.innerHeight * 0.9 - 100,
      width = (height * 16) / 9
    setDimensions({ height, width })
  }, [])

  const windowResizeHandler = useCallback(() => {
    if (debounceTimer) window.clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => {
      setModalDimensions()
      if (debounceTimer) window.clearTimeout(debounceTimer)
    }, 250)
  }, [])

  const unbind = useCallback(() => {
    window.removeEventListener('resize', windowResizeHandler, false)
  }, [windowResizeHandler])

  const bind = useCallback(() => {
    unbind()
    window.addEventListener('resize', windowResizeHandler, false)
  }, [unbind, windowResizeHandler])

  useEffect(() => {
    if (props.open) {
      setModalDimensions()
      bind()
    }
    return () => unbind
  }, [props.open, bind, unbind, setModalDimensions])

  return (
    <MDBModal
      className="VideoPlayerModal"
      isOpen={!!(props?.open && props?.videoUrl)}
      toggle={close}
      size="fluid"
    >
      <MDBModalHeader color="indigo" toggle={close}>
        USABG Welcome
        <br />
        <span>{props?.videoTitle}</span>
      </MDBModalHeader>
      <MDBModalBody>
        <div className="video-wrapper">
          {props?.videoUrl ? (
            <div style={{ width: dimensions.width, height: dimensions.height }}>
              <iframe
                src={props?.videoUrl.replace(/(<([^>]+)>)/gi, '')}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title={`video-${props?.videoTitle}`}
              ></iframe>
            </div>
          ) : (
            <></>
          )}
        </div>
      </MDBModalBody>
    </MDBModal>
  )
}

export default observer(VideoPlayerModal)
