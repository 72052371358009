import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBIcon,
  MDBCol,
  MDBBtn,
  MDBInput,
} from 'mdbreact'
import { toast } from 'react-toastify'
import UserProfileService from './../../shared/services/UserProfile.service'
import AuthService from './../../shared/services/Auth.service'

import './AccountResetPassword.scss'

class AccountResetPassword extends Component {
  state = {
    loading: false,
    formError: false,
    errorMessage: '',
    newPassword: '',
    confirmNewPassword: '',
  }

  #validate = () => {
    const { newPassword, confirmNewPassword } = this.state,
      passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
      passwordWithCharacterRegex =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]{8,}$/

    if (!newPassword.trim()) {
      // this.setState({formError: true, errorMessage: 'Password required'});
      toast.error('Password required', { position: toast.POSITION.TOP_RIGHT })
      return false
    }

    if (
      !(
        passwordRegex.test(newPassword) ||
        passwordWithCharacterRegex.test(newPassword)
      )
    ) {
      // this.setState({formError: true, errorMessage: 'Password must be at least 8 characters, combination of words and numbers'});
      toast.error(
        'Password must be at least 8 characters, combination of words and numbers',
        { position: toast.POSITION.TOP_RIGHT }
      )
      return false
    }

    if (newPassword.trim() !== confirmNewPassword) {
      // this.setState({formError: true, errorMessage: 'Password do not match'});
      toast.error('Passwords do not match', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return false
    }

    this.setState({ formError: false, errorMessage: '' })
    return true
  }

  #updatePassword = async () => {
    if (!this.#validate() || this.state.loading) return

    if (!this.state.loading) this.setState({ loading: true })

    try {
      await AuthService.manualResetPassword(
        UserProfileService.getUserId(),
        this.state.newPassword
      )
      toast.success('Password Updated Successfully', {
        position: toast.POSITION.TOP_RIGHT,
      })
      this.setState({ newPassword: '', confirmNewPassword: '' })
    } catch (ex) {
      toast.error(`Failed to reset account password. ${ex}`, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }

    if (this.state.loading) this.setState({ loading: false })
  }

  render() {
    toast.configure()
    const { newPassword, confirmNewPassword } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main id="AccountResetPasswordPage" className="mainSection pt-5">
            <MDBContainer fluid>
              <MDBRow>
                <MDBCol>
                  <h2>Reset Your Account Password</h2>
                  <hr />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol lg="5">
                  <MDBCard className="mb-5">
                    <div className="header pt-3 indigo lighten-1">
                      <MDBRow className="d-flex justify-content-start">
                        <h3 className="white-text mt-3 mb-4 pb-1 mx-5">
                          <MDBIcon icon="lock" className="mr-3" /> Password
                          Reset
                        </h3>
                      </MDBRow>
                    </div>
                    <MDBCardBody className="mx-4 mt-4">
                      <form>
                        <div className="form-group">
                          <MDBInput
                            label="New Password"
                            group
                            type="text"
                            value={newPassword}
                            getValue={(value) => {
                              if (this.state.newPassword !== value)
                                this.setState({ newPassword: value })
                            }}
                            validate
                          />
                        </div>
                        <div className="form-group">
                          <MDBInput
                            label="Re-type Password"
                            group
                            type="text"
                            value={confirmNewPassword}
                            getValue={(value) => {
                              if (this.state.confirmNewPassword !== value)
                                this.setState({ confirmNewPassword: value })
                            }}
                            validate
                          />
                        </div>
                        <div className="form-group">
                          <MDBBtn
                            color="success"
                            onClick={() => {
                              this.#updatePassword()
                            }}
                          >
                            Update
                          </MDBBtn>
                        </div>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AccountResetPassword
