import React, { Component, Fragment } from 'react'
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBSpinner,
} from 'mdbreact'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import AdminApi from '../../api/admin-api/admin-api'
import { toast } from 'react-toastify'

class AddLeadSources extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      lead_source_name: '',
      formError: false,
      errorMessage: '',
    }
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params })

    if (params && params.id) {
      this.fetchLeadSourceData(params.id)
    }
  }

  //Change event for input fields
  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  validate = async () => {
    const { lead_source_name } = this.state

    if (!(lead_source_name && lead_source_name.trim())) {
      this.setState({
        formError: true,
        errorMessage: 'Lead Source Name Required',
      })
      return
    }

    this.setState({ formError: false, errorMessage: '' })
  }

  //create lead source
  createLeadSource = async () => {
    await this.validate()
    const { loading, formError, errorMessage } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }
    this.setState({ loading: true })

    const data = {
      ls_name: this.state.lead_source_name,
      table_name: 'leadsource',
    }

    const form_data = new FormData()
    for (let key in data) {
      form_data.append(key, data[key])
    }

    AdminApi.createLeadSource(form_data)
      .then((result) => {
        this.setState({ loading: false })
        this.props.history.push('/admin-leads')
        toast.success('Lead Source created successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        console.log(error)
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchLeadSourceData = async (id) => {
    this.setState({ loading: true })
    AdminApi.getLeadSourceById(id)
      .then((result) => {
        this.setState({ loading: false })
        if (result && result.data && result.data.data) {
          const content = result.data.data.ls_name
          this.setState({
            editData: result.data.data || [],
            lead_source_name: content,
          })
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  updateLeadSource = async () => {
    await this.validate()
    const id = this.state.urlParams.id
    const { loading, formError, errorMessage } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }
    this.setState({ loading: true })

    const data = {
      ls_name: this.state.lead_source_name,
      table_name: 'leadsource',
      id: id,
    }

    const form_data = new FormData()
    for (let key in data) {
      form_data.append(key, data[key])
    }
    AdminApi.updateLeadSource(form_data)
      .then((result) => {
        this.setState({ loading: false })
        this.props.history.push('/admin-leads')
        toast.success('Lead Source updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  render() {
    toast.configure()
    const { urlParams, loading } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>
                  <a href="/admin-leads">List</a>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>
                  {!!(urlParams && urlParams.id)
                    ? 'Edit Lead Source'
                    : 'Add Lead Source'}
                </MDBBreadcrumbItem>
              </MDBBreadcrumb>
              <h2>
                {!!(urlParams && urlParams.id)
                  ? 'Edit Lead Source'
                  : 'Add Lead Source'}
              </h2>
              <hr />
              {loading ? (
                <div className="loadingOverlay">
                  <h4 className={'loader-text p4'}>
                    <MDBSpinner />
                  </h4>
                </div>
              ) : (
                ''
              )}
              <MDBRow>
                <MDBCol size="6">
                  <form className="content-wrapper">
                    <div className="form-row">
                      <label className="grey-text" htmlFor="lead_source_name">
                        Lead Source Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="lead_source_name"
                        id="lead_source_name"
                        value={this.state.lead_source_name}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="form-row text-center mt-4">
                      <MDBBtn
                        color="unique"
                        type="button"
                        className="btn-block"
                        onClick={() => {
                          this.state.urlParams && this.state.urlParams.id
                            ? this.updateLeadSource()
                            : this.createLeadSource()
                        }}
                      >
                        {loading ? 'Saving...' : 'Save'}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AddLeadSources
