import BaseModelInstance from './base.model'
import { UserDocument as ModelDefinition } from './model-definitions'
import UserDocumentService from './../services/UserDocument.service'

class UserDocument extends BaseModelInstance {
  afterSet = (key, value) => {}

  download = async () => {
    return await UserDocumentService.getBase64(this.id())
  }

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }
}

export default UserDocument
