import BaseModelInstance from './base.model'
import { LeadVendor as ModelDefinition } from './model-definitions'

class LeadVendor extends BaseModelInstance {
  afterSet = (key, value) => {}

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }
}

export default LeadVendor
