import { makeAutoObservable, toJS } from 'mobx'
import UserStateLicenseService from './../../../shared/services/UserStateLicense.service'
import UserProfileService from './../../../shared/services/UserProfile.service'
import moment from 'moment'

const serializeDate = (dateVal, watch) => {
  if (dateVal && typeof dateVal === 'string') {
    if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(dateVal)) {
      // dateVal = moment(dateVal.split('T')[0], 'YYYY-MM-DDThh:mm:ss.SSSZ').format('YYYY-MM-DD');
      dateVal = dateVal.split('T').shift()
    }
  }

  if (dateVal && typeof dateVal === 'object') {
    dateVal = moment(dateVal).utc().format('YYYY-MM-DD')
  }

  return dateVal
}

const toDateObj = (dateVal) => {
  if (dateVal && typeof dateVal === 'string') {
    if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(dateVal)) {
      dateVal = moment(dateVal.split('T')[0], 'YYYY-MM-DD').toDate()
    } else if (/^\d{4}-\d{2}-\d{2}$/.test(dateVal)) {
      dateVal = moment(dateVal, 'YYYY-MM-DD').toDate()
    }
  }

  if (dateVal && typeof dateVal === 'object') return dateVal
}

class StateLicenseStore {
  constructor() {
    makeAutoObservable(this)
  }

  _licenses = []
  residentLicenseId = null

  getLicenses = () => toJS(this._licenses)

  getValidLicenses = (watch) => {
    try {
      return toJS(this._licenses).filter(
        (lic) =>
          !!lic?.id &&
          !!lic?.state_abrev &&
          !!lic?.license_num &&
          !!lic?.effective_date &&
          !!lic?.license_img
      )
    } catch (ex) {
      return []
    }
  }

  fetchLicenses = async () => {
    let licenses = []
    try {
      licenses = await UserStateLicenseService.search({
        search: { user_id: UserProfileService.getUserId() },
        pagination: false,
      })
      licenses = licenses && licenses?.models ? licenses.models : []
    } catch (ex) {}

    this.residentLicenseId = licenses
      .filter((l) => parseInt(l.is_resident_state) === 1)
      .shift()?.id
    this._licenses = licenses
  }

  saveLicense = async (license) => {
    const getChanged = (orig) => {
      let fields = [
          'effective_date',
          'expiration_date',
          'license_num',
          'state_abrev',
          'license_img',
          'is_resident_state',
        ],
        compA = {},
        compB = {}

      fields.forEach((fld) => {
        compA[fld] = null
        compB[fld] = null
        if (fld === 'effective_date' || fld === 'expiration_date') {
          const year = new Date(license[fld]).getFullYear()
          if (
            license[fld] &&
            (!moment(license[fld], moment.ISO_8601, true).isValid() ||
              year <= 1600 ||
              year >= 10000)
          ) {
            console.error('Error: Date format is not valid')
            throw new Error(
              `Unable to update agent state license. Date format is not valid`
            )
          }
        }
        if (orig.hasOwnProperty(fld)) compA[fld] = orig[fld] ? orig[fld] : null
        if (license.hasOwnProperty(fld))
          compB[fld] = license[fld] ? license[fld] : null
      })
      if (JSON.stringify(compA) !== JSON.stringify(compB)) {
        fields.forEach((fld) => {
          if (['effective_date', 'expiration_date'].indexOf(fld) > -1) {
            if (
              serializeDate(compA[fld], fld === 'effective_date') ===
              serializeDate(compB[fld])
            )
              delete compB[fld]
            if (compB[fld]) compB[fld] = serializeDate(compB[fld])
            else delete compB[fld]
          } else if (JSON.stringify(compA[fld]) === JSON.stringify(compB[fld]))
            delete compB[fld]
        })

        if (Object.keys(compB).length) return compB
      }

      return false
    }

    // 1. has record changed?
    let id = license && license?.id,
      origLicense = this.getLicense(license && license?.id) || {},
      licenseExists =
        parseInt(origLicense?.id) > 0 ? parseInt(origLicense.id) : false,
      changesExist = getChanged(origLicense),
      results = null

    if (changesExist) {
      if (changesExist?.effective_date)
        changesExist.effective_date = serializeDate(changesExist.effective_date)
      if (changesExist?.expiration_date)
        changesExist.expiration_date = serializeDate(
          changesExist.expiration_date
        )

      if (licenseExists) {
        try {
          results = await UserStateLicenseService.update(id, changesExist)
          results = { ...results, ...changesExist }

          if (results?.effective_date)
            results.effective_date = toDateObj(results.effective_date)
          if (results?.expiration_date)
            results.expiration_date = toDateObj(results.expiration_date)

          this._licenses = this.getLicenses().map((lic) =>
            parseInt(results.id) === parseInt(lic.id)
              ? { ...lic, ...results }
              : lic
          )
          return results
        } catch (ex) {
          console.error('ex: ', ex)
          throw new Error(`Unable to update agent state license. ${ex}`)
        }
      } else {
        try {
          results = await UserStateLicenseService.store({
            ...changesExist,
            user_id: UserProfileService.getUserId(),
          })
          results = { ...results, ...changesExist }

          if (results?.effective_date)
            results.effective_date = toDateObj(results.effective_date)
          if (results?.expiration_date)
            results.expiration_date = toDateObj(results.expiration_date)

          this._licenses = [...this.getLicenses(), results]
          return results
        } catch (ex) {
          console.error('ex: ', ex)
          throw new Error(`Unable to create agent state license. ${ex}`)
        }
      }
    }
  }

  deleteLicense = async (licenseId) => {
    try {
      await UserStateLicenseService.delete(licenseId)
      this.fetchLicenses()
      return true
    } catch (ex) {
      return false
    }
  }

  getLicense = (licenseId) =>
    this.getLicenses()
      .filter((license) => parseInt(license.id) === parseInt(licenseId))
      .shift() || {}

  setResidentId = async (license) => {
    try {
      await UserStateLicenseService.setResidentId(license.id)
    } catch (ex) {
      return
    }

    this.residentLicenseId = license.id
    this._licenses = this.getLicenses().map((l) => ({
      ...l,
      is_resident_state: `${l.id}` === `${this.residentLicenseId}`,
    }))
  }
}

export default new StateLicenseStore()
