import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import KpiDashboard from './components/KpiDashboard/KpiDashboard.component'
// import KpiConfigurator from './components/KpiConfigurator/KpiConfigurator.component'
import KpiDashboardStore from './store'
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import PageHeading from './../../components/shared/PageHeading.component'
import UserProfileService from './../../shared/services/UserProfile.service'

import './KpiDashboardPage.scss'

const KpiDashboardComponent = ({ props }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [outcome, setOutcome] = useState(false)
  const { getAgent } = KpiDashboardStore

  useEffect(() => {
    setIsFetching(true)
    getAgent(UserProfileService.getUserId())
      .then((outcome) => {
        if (outcome) setOutcome((o) => outcome)
      })
      .finally(() => setIsFetching(false))
  }, [getAgent])

  return (
    <React.Fragment>
      <DashboardLayout>
        <main id="KpiDashboardPage" className="mainSection">
          <MDBContainer fluid className="mt-5">
            <PageHeading
              label="Key Performance Indicator (KPI) Dashboard"
              url={props?.match?.url}
              from={props?.location?.state?.from}
              goTo={(url) =>
                props.history.push(url, { from: '/kpi-dashboard' })
              }
            />
          </MDBContainer>
          {
            // <KpiConfigurator
            //   onPublish={onConfiguratorChangeEvent}
            //   isFetching={isFetching}
            // />
          }
          {isFetching ? (
            <h2>
              <MDBIcon
                className="red-text ml-3"
                icon="cog"
                spin
                size="2x"
                fixed
              />
              <span className="sr-only">Loading...</span>
            </h2>
          ) : (
            <>
              <MDBContainer fluid className="mt-5">
                <MDBRow>
                  <MDBCol size="12">
                    {outcome && Object.keys(outcome).length ? (
                      <KpiDashboard data={outcome} isFetching={isFetching} />
                    ) : (
                      <></>
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </>
          )}
        </main>
      </DashboardLayout>
    </React.Fragment>
  )
}

const KDC = observer(KpiDashboardComponent)

class KpiDashboardPage extends React.Component {
  render() {
    return <KDC props={this.props} />
  }
}

export default KpiDashboardPage
