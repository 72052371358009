import React from 'react'
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardFooter,
  MDBIcon,
} from 'mdbreact'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import BillingService from './../../shared/Billing.service'
import PaymentMethodForm from './../../../../components/Stripe/PaymentMethodForm/PaymentMethodForm.component'
import { Subscription } from 'rxjs'

import './PaymentMethods.scss'

class PaymentMethods extends React.Component {
  state = {
    updating: false,
    defaultMethod: null,
    customerId: null,
    paymentMethods: [],
  }

  #_subscriptions$ = new Subscription()

  #setPaymentMethods = (paymentMethods) =>
    this.setState({ paymentMethods: paymentMethods })

  #setCustomer = (customer) => {
    let stateUpd = {
      customerId: null,
      defaultMethod: null,
      paymentMethods: [],
    }

    if (customer && customer.id) {
      stateUpd.customerId = customer.id
      if (customer.expands) {
        if (customer.expands.paymentMethod)
          stateUpd.paymentMethods = customer.expands.paymentMethod
        if (
          customer.expands?.customer?.invoice_settings?.default_payment_method
        )
          stateUpd.defaultMethod =
            customer.expands.customer.invoice_settings.default_payment_method
      }
    }

    this.setState(stateUpd)
  }

  #setDefaultPaymentMethod = async (pm) => {
    if (this.state.customerId && pm.id) {
      this.setState({ updating: true })
      BillingService.setDefaultPaymentMethod(
        this.state.customerId,
        pm.id
      ).finally(() => this.setState({ updating: false }))

      // // Temp work-around while moving customers to Stripe from cart32/iBoom merchants.
      // /*TODO:REMOVE*/
      if (UserProfileService.get('merchant_history', true))
        UserProfileService.set('merchant_history', '', true)
    }
  }

  #deletePaymentMethod = async (pm) => {
    if (this.state.customerId && pm.id) {
      this.setState({ updating: true })
      await BillingService.deletePaymentMethod(
        this.state.customerId,
        pm.id
      ).finally(() => this.setState({ updating: false }))
    }
  }

  componentDidMount() {
    this.#_subscriptions$.add(
      BillingService.getCustomer().subscribe((customer) =>
        this.#setCustomer(customer)
      )
    )
  }

  componentWillUnmount() {
    this.#_subscriptions$.unsubscribe()
  }

  render() {
    const paymentMethods = Array.isArray(this.state.paymentMethods)
      ? this.state.paymentMethods.map((pm) => {
          pm.default = pm.id === this.state.defaultMethod
          return pm
        })
      : []

    return (
      <MDBCard id="PaymentMethodsComponent">
        <MDBCardHeader>Account Billing</MDBCardHeader>
        <MDBCardBody className="payment-method">
          <h6>Payment Methods:</h6>
          {paymentMethods && paymentMethods.length ? (
            <>
              <table>
                <thead>
                  <tr>
                    <th>Card Type</th>
                    <th>Card Last Four:</th>
                    <th>Card Exp:</th>
                    <th className="action">
                      <small>Default</small>
                    </th>
                    <th className="action">
                      <small>Delete</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paymentMethods.map((pm) => {
                    return (
                      <tr
                        key={pm.id}
                        className={pm.default ? 'default-method' : ''}
                      >
                        <td>
                          {pm.card.brand.charAt(0).toUpperCase() +
                            pm.card.brand.toLowerCase().substring(1)}
                          {pm.default ? '*' : ''}
                        </td>
                        <td>{pm.card.last4}</td>
                        <td>{pm.card.exp_month + '/' + pm.card.exp_year}</td>
                        <td className="action">
                          {pm.default ? (
                            <MDBIcon icon="check" />
                          ) : (
                            <button
                              className="default-btn"
                              disabled={pm.default || this.state.updating}
                              onClick={() => this.#setDefaultPaymentMethod(pm)}
                            >
                              <MDBIcon icon="dollar-sign" />
                            </button>
                          )}
                        </td>
                        <td className="action">
                          {pm.default ? (
                            <></>
                          ) : (
                            <button
                              className="delete-btn"
                              disabled={pm.default || this.state.updating}
                              onClick={() => this.#deletePaymentMethod(pm)}
                            >
                              <MDBIcon icon="trash-alt" />
                            </button>
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </>
          ) : (
            <div className="no-methods">
              You have no payment methods on record.
              <br />
              <small>
                Note: Not all users have their payment method on our automated
                system. You may enter it now, or contact Broker Support for
                additional details.
              </small>
            </div>
          )}
          {this.state.customerId ? (
            <>
              <h6>Update Payment Method:</h6>
              <PaymentMethodForm
                formType={'SaveCard'}
                userId={UserProfileService.getUserId()}
                customerId={this.state.customerId}
                onSuccess={() => {
                  // /*TODO:REMOVE*/
                  if (UserProfileService.get('merchant_history', true))
                    UserProfileService.set('merchant_history', '', true)

                  BillingService.notifySysAdminPayment(
                    this.state.customerId
                  ).finally(() =>
                    BillingService.fetchCustomer(this.state.customerId)
                  )
                }}
              />
            </>
          ) : (
            <></>
          )}
        </MDBCardBody>
        <MDBCardFooter></MDBCardFooter>
      </MDBCard>
    )
  }
}

export default PaymentMethods
