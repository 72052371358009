import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { MDBContainer, MDBRow, MDBCol, MDBAlert } from 'mdbreact'
import Gauge from './../../../../components/gauge/Gauge'
import KpiDashboardStore from './../../store/index'

import './KpiDashboard.scss'

const KpiDashboard = (props) => {
  const data = props?.data

  const { getKpis } = KpiDashboardStore
  const [kpis, setKpis] = useState({})

  const getLabelByKey = (key) =>
    `${kpis && kpis.hasOwnProperty(key) ? kpis[key]?.label : ''}`

  const getDescriptionByKey = (key) =>
    `${kpis && kpis.hasOwnProperty(key) ? kpis[key]?.description : ''}`

  const inverse = (val) => Math.abs(1 - parseFloat(val))

  const renderGauge = (key) => {
    return (
      <div className="kpi-chart">
        <Gauge
          name={key}
          value={inverse(
            parseFloat(data[key].forecast.outcome) > 1
              ? 1
              : parseFloat(data[key].forecast.outcome)
          )}
        />
      </div>
    )
  }

  const renderWarning = (isGoal) => (
    <>
      {isGoal ? (
        <MDBAlert className="incomplete-goals" color="danger">
          Complete your goals to start forecasting.
        </MDBAlert>
      ) : (
        <small>Unable to forecast</small>
      )}
    </>
  )

  const renderGauges = () => {
    let gauges = []

    for (let key in data) {
      if (key !== 'overview') {
        gauges.push(
          <MDBCol key={'key_' + Math.random()} size="12" sm="6" md="4" lg="4">
            <MDBContainer fluid className="kpi-widget-container">
              <MDBRow>
                <MDBCol size="12" className="label">
                  <h4>{getLabelByKey(key)}</h4>
                </MDBCol>
                <MDBCol size="12" className="description">
                  <p>{getDescriptionByKey(key)}</p>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="6" className="avg-pw">
                  <table width="100%">
                    <tbody>
                      <tr>
                        <td>
                          <div>AVG</div>
                          <small>Per Week</small>
                        </td>
                        <td>
                          <span>
                            {Math.round(data[key]?.completed?.weekly)}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </MDBCol>
                <MDBCol size="6" className="goal-pw">
                  <table width="100%">
                    <tbody>
                      <tr>
                        <td>
                          <div>GOAL</div>
                          <small>Per Week</small>
                        </td>
                        <td>
                          <span>{Math.round(data[key]?.goal?.weekly)}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="12" className="tracking">
                  {data[key]?.forecast?.outcome ? (
                    <>
                      <h6>Are you on track?</h6>
                      <span
                        style={{
                          position: 'absolute',
                          top: '3px',
                          right: '10px',
                        }}
                      >
                        {parseFloat(data[key].forecast.outcome) > 1 ? '>' : ''}
                        {(
                          (parseFloat(data[key].forecast.outcome) > 1
                            ? 1
                            : parseFloat(data[key].forecast.outcome)) * 100
                        ).toFixed(2)}
                        %
                      </span>
                      {renderGauge(key)}
                    </>
                  ) : (
                    <>{renderWarning(!data[key]?.goals?.weekly)}</>
                  )}
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCol>
        )
      }
    }

    return gauges
  }

  useEffect(() => {
    getKpis().then(setKpis)
  }, [getKpis])

  return (
    <MDBContainer id="KpiDashboardComponent" fluid>
      <MDBRow>{renderGauges()}</MDBRow>
    </MDBContainer>
  )
}

export default observer(KpiDashboard)
