import { makeAutoObservable, toJS } from 'mobx'
import AdminApi from './../../../api/admin-api/admin-api'
import {
  TermFactory,
  TermSetFactory,
  UserTermFactory,
  UserTermSetFactory,
} from './../../../shared/factories'
import UserTermService from './../../../shared/services/UserTerm.service'

const getIp = async () => {
  return await (() => {
    return new Promise((resolve, reject) => {
      fetch('https://api.ipify.org?format=json')
        .then((res) => res.json())
        .then((res) => resolve(res && res?.ip))
        .catch((err) => reject(err))
    })
  })()
}

const fetchTerms = async () => {
  try {
    return await TermFactory.search({ pagination: false })
  } catch (ex) {
    console.error('Failed to load terms in AgentTermsStore.', ex)
  }
}

const fetchTermSets = async (termSetIds) => {
  try {
    return await TermSetFactory.search({
      search: { id: termSetIds },
      pagination: false,
    })
  } catch (ex) {
    console.error('Failed to load term sets in AgentTermsStore.', ex)
  }
}

const fetchUserTerms = async (userId) => {
  try {
    return await UserTermFactory.search({
      search: { user_id: userId },
      pagination: false,
    })
  } catch (ex) {
    console.error('Failed to load user terms in AgentTermsStore.', ex)
  }
}

const fetchUserTermSets = async (userId) => {
  try {
    return await UserTermSetFactory.search({
      search: { user_id: userId },
      pagination: false,
    })
  } catch (ex) {
    console.error('Failed to load user term sets in AgentTermsStore.', ex)
  }
}

class AgentTermsStore {
  constructor() {
    makeAutoObservable(this)
  }

  hasLoaded = null
  isLoading = false
  isExporting = false
  isSigningTermSet = false

  userId = null
  UserTerms = []
  Terms = []
  UserTermSets = []
  SignedTermSets = []
  TermSets = []

  exportError = null
  _willExport = []

  fetch = async (userId) => {
    this.isLoading = true

    if (this.userId && userId && `${this.userId}` !== `${userId}`) {
      this.UserTerms = []
      this.Terms = []
      this.UserTermSets = []
      this.SignedTermSets = []
      this.TermSets = []
    }

    this.userId = userId || this.userId

    const Responses = await Promise.all([
      fetchUserTerms(this.userId),
      fetchUserTermSets(this.userId),
      fetchTerms(),
    ])

    this.UserTerms = Responses.shift()
    this.UserTermSets = Responses.shift()
    const Terms = Responses.shift()
    this.Terms = Terms

    const termSetIds = this.UserTermSets.map((UserTermSet) =>
      UserTermSet.get('set_id')
    ).filter((n) => !isNaN(n) && n)
    if (termSetIds.length) {
      this.TermSets = await fetchTermSets(termSetIds)
      this.TermSets.forEach((TermSet) => TermSet.parseTermStmt({ Terms }))
    }

    if (this.UserTermSets && this.UserTerms) {
      this.UserTermSets.forEach(
        (UserTermSet) =>
          (this.SignedTermSets = this.UserTerms.map((UserTerm) =>
            UserTerm.get('userterm_file') &&
            `${UserTerm.get('userterm_file')}`.indexOf(
              `term_set_${UserTermSet.get('set_id')}`
            ) > -1
              ? UserTermSet
              : false
          ).filter((N) => !!N))
      )
    }

    this.isLoading = false
    this.hasLoaded = true
  }

  exportToCSV = async (reportName) => {
    this.isExporting = true
    const data = {
      report_name: reportName,
      report_params: { user_id: this.userId },
    }

    AdminApi.exportCsvReport(data)
      .then((result) => {
        if (result && result.data && result.data.data && result.data.data.url)
          window.location.href = result.data.data.url
        this.isExporting = false
      })
      .catch((error) => (this.isExporting = false))

    this.isExporting = false
  }

  isTermSetSigned = (UserTermSet) => {
    return (
      this.SignedTermSets.map((S) => parseInt(S.id())).indexOf(
        parseInt(UserTermSet.id())
      ) > -1
    )
  }

  signTermSet = async (UserTermSet) => {
    this.isSigningTermSet = true
    if (UserTermSet) {
      const hasSigned = await UserTermSet.sign(await getIp())
      if (hasSigned) this.fetch()
    }
    this.isSigningTermSet = false
  }

  toggleWillExport = (UserTerm) => {
    if (this.willExport(UserTerm))
      this._willExport = this._willExport.filter(
        (id) => id !== parseInt(UserTerm.id())
      )
    else if (UserTerm) this._willExport.push(parseInt(UserTerm.id()))
  }

  willExport = (UserTerm) => {
    return UserTerm && this._willExport.indexOf(parseInt(UserTerm.id())) > -1
  }

  canExport = () => this._willExport.filter((n) => n && !isNaN(n)).length > 0

  exportToPDF = async (reportName) => {
    this.isExporting = true
    this.exportError = null

    try {
      const { export_url } = await UserTermService.export({
        id: this._willExport,
      })
      if (export_url) {
        this.isExporting = false
        window.open(export_url)
        this._willExport = []
        return true
      }
    } catch (ex) {
      this.isExporting = false
      this.exportError = `${ex}`
      return false
    }

    this.isExporting = false
    this.exportError = 'Invalid export PDF from the server.  Please try again.'
  }
}

export default new AgentTermsStore()
