import React from 'react'
import { observer } from 'mobx-react-lite'
import { MDBModalHeader, MDBModalBody, MDBModal } from 'mdbreact'
import UserProfileService from './../../../shared/services/UserProfile.service'
import UsertypeService from './../../../shared/services/Usertype.service'
import Store from './UsertypeBlockModal.store'
import { Link } from 'react-router-dom'

import './UsertypeBlockModal.component.scss'

const ucwords = (str) =>
  str &&
  `${str}`
    .replace(/^([a-z])|[\s_-]+([a-z])/g, ($1) => $1.toUpperCase())
    .replace(/-/g, ' ')

const DEFAULT_MSG = "{usertype} accounts do not have access to the <strong>{pageName}</strong> Page.";

const UsertypeBlockModal = () => {
  const { isOpen, pageName, toggle } = Store

  const msg = () =>
    (Store.errMsg ? Store.errMsg : DEFAULT_MSG).replace(/{usertype}/g,ucwords(UsertypeService.idToName(UserProfileService.get('usertype_id')))).replace(/{pageName}/g,pageName);

  return (
    <div className="UsertypeBlockModalComponent">
      <MDBModal size="lg" isOpen={Store.isOpen} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>
          Oh no! You don't have access ...
        </MDBModalHeader>
        <MDBModalBody>
          <div className="text-center">
            <p>
              Your account type is currently{' '}
              <strong>
                {ucwords(
                  UsertypeService.idToName(
                    UserProfileService.get('usertype_id')
                  )
                )}
              </strong>
              .
            </p>
            <p dangerouslySetInnerHTML={{ __html: msg() }}></p>
            <p>
              Want to access <strong>{pageName}</strong>?
              <br />
              Upgrade your account to a Signature Agent Now{' '}
              <Link to="/account-billinginfo">
                <strong>Click Here!</strong>
              </Link>
            </p>
          </div>
        </MDBModalBody>
      </MDBModal>
    </div>
  )
}

export default observer(UsertypeBlockModal)
