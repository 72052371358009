import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import React, { useState } from 'react'
import {
  MDBBtn,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
} from 'mdbreact'
import CarrierMgmtStore from './../../CarrierMgmt.store'

import './CarrierCommissionCodesForm.scss'

const CARRIER_COMM_CODES = [
  'USABG505',
  'USABG405',
  'USABG305',
  'USABG205',
  'USABG30',
  'USABG20',
  'USABG10',
]

const CarrierCommissionCodesForm = ({ table }) => {
  const [load, setLoad] = useState(false)

  const renderLockOut = () => {
    if (
      !CarrierMgmtStore.carrier ||
      (CarrierMgmtStore.carrier && isNaN(CarrierMgmtStore.carrier?.id))
    )
      return <div className="lockout-window">lockout</div>
    if (load === false) setLoad(true)
  }

  const onChange = (event) => {
    let comCode = event.target.name,
      value = event.target.value
    CarrierMgmtStore.carrierCommLevels[comCode] = {
      ...toJS(CarrierMgmtStore.carrierCommLevels[comCode]),
      override: value,
      modified: true,
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    await CarrierMgmtStore.saveCarrierCommCodes()
  }

  const renderCommCodeInput = (comCode) => {
    let commLevel = CarrierMgmtStore.commLevels[comCode],
      carrCommLevel = CarrierMgmtStore.carrierCommLevels[comCode]

    if (commLevel) {
      return (
        <MDBRow key={`carr-comm-code-row-${comCode}`}>
          <MDBCol size="6" className="comm-code-label">
            <label>
              {commLevel.com_name}
              <br />
              <small>{comCode}</small>
            </label>
          </MDBCol>
          <MDBCol size="6" className="comm-code-input">
            <MDBInput
              type="text"
              name={comCode}
              value={(carrCommLevel && carrCommLevel?.override) || ''}
              onChange={onChange}
            />
          </MDBCol>
        </MDBRow>
      )
    }
  }

  const renderInputs = () => CARRIER_COMM_CODES.map(renderCommCodeInput)

  return (
    <MDBCard id="CarrierCommissionCodesFormComponent">
      {renderLockOut()}
      <MDBCardHeader>Commission Codes</MDBCardHeader>
      <MDBCardBody>
        <form noValidate onSubmit={onSubmit}>
          {renderInputs()}
          <MDBRow>
            <MDBCol size="12">
              <MDBBtn className="btn-block" type="submit">
                {CarrierMgmtStore.getIsLoading('update.comm-codes') ? (
                  <span>
                    Saving...&nbsp;
                    <i className="fa fa-spin fa-spinner" />
                  </span>
                ) : (
                  'Save Carrier Commission Codes'
                )}
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </form>
      </MDBCardBody>
    </MDBCard>
  )
}

export default observer(CarrierCommissionCodesForm)
