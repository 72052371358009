import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import LoadingSpinner from './../../shared/LoadingSpinner.component'
import ContentHtml from './../../content/ContentHtml/ContentHtml.component'
import { b64toBlob } from './../../../shared/utilities/b64toBlob.function'
import {
  MDBFileInput,
  MDBBtn,
  MDBModalHeader,
  MDBModalBody,
  MDBModal,
  MDBCollapse
} from 'mdbreact'

import './ContentModal.component.scss'

const ContentModal = ({
  isOpen,
  onClose, 
  contentLabel,
  contentId,
  contentSlug,
  wrapperClass
}) => {
  const [isLoading, setIsLoading] = useState(true),
  			[pageName, setPageName] = useState('');

  const toggleModal = () => {
  	if (onClose)
  		onClose();
  }

  contentLabel = contentLabel ? contentLabel : ''

  const contentProps = {};
  if (contentId)
  	contentProps.contentId = contentId
  if (contentSlug)
  	contentProps.contentSlug = contentSlug

  wrapperClass = wrapperClass ? `${wrapperClass}` : "";

  return (
    <div className={`ContentModalComponent ${wrapperClass}`}>
      <MDBModal size="lg" isOpen={isOpen} toggle={toggleModal}>
        <MDBModalHeader toggle={toggleModal}>{contentLabel||pageName}</MDBModalHeader>
        <MDBModalBody className={isLoading ? 'is-loading' : ''}>
	        <div className="loading-wrapper">
	          <LoadingSpinner size="md" isActive={true} />
	        </div>
	        <MDBCollapse isOpen={!isLoading} className='content-modal-wrapper'>
						<ContentHtml {...contentProps} onLoad={contentResponse => {

							let pName = Array.isArray(contentResponse) ? contentResponse.shift() : contentResponse;
							pName = (pName && typeof pName === 'object' && pName?.page_name);
							if (pName && !contentLabel)
								setPageName(pName)
							
							setIsLoading(false);
						}} />
						<br />
						<MDBBtn block onClick={toggleModal} className='btn btn-info'>OK</MDBBtn>
	        </MDBCollapse>
        </MDBModalBody>
      </MDBModal>
    </div>
  )
}

export default observer(ContentModal)
