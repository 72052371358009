import { makeAutoObservable, toJS } from 'mobx'
import UsertypeFactory from './../../../shared/factories/usertype.factory';
import AgentPackageFactory from './../../../shared/factories/agent-package.factory';
import AgentPackageFeatureFactory from './../../../shared/factories/agent-package-feature.factory';
import AgentPackagesFeaturesFactory from './../../../shared/factories/agent-packages-features.factory';

const getPackageFeatures = (Package, Feature, PackageFeatures) => 
	PackageFeatures.filter(PF => parseInt(PF.get('feature_id')) === parseInt(Feature.id()) && parseInt(PF.get('pkg_id')) === parseInt(Package.id()));

const getPackageFeature = (Package, Feature, PackageFeatures) => 
	getPackageFeatures(Package, Feature, PackageFeatures).shift();

class AdminPackagesStore {
  constructor() {
    makeAutoObservable(this)
  }

  _saving = [];
  isFetching = false;

  Packages = [];
  Features = [];
  PackageFeatures = [];

  EditPackage = null;
  DeletePackage = null;
  EditFeature = null;
  DeleteFeature = null;
  NewFeature = AgentPackageFeatureFactory.create({feature: ""});

  Usertypes = {}

  savingIdx = (Package, Feature) =>
  	this._saving.indexOf([(Package&&Package.id())||0,(Feature&&Feature.id())||0].join('-'))

  isSaving = (Package, Feature) =>
  	this.savingIdx(Package, Feature) > -1

  startSaving = (Package, Feature) =>
  {
  	if (!this.isSaving(Package, Feature))
  		this._saving.push([Package.id(),Feature.id()].join('-'))
  }

  stopSaving = (Package, Feature) =>
  {
  	let idx = this.savingIdx(Package, Feature);
  	if (idx > -1) this._saving.splice(idx, 1)
  }

	getPackageUsertype = Package =>
	{
		if (Package && Package.get('usertype_id')) {
			if (this.Usertypes[Package.get('usertype_id')])
				return this.Usertypes[Package.get('usertype_id')].get('displayname');
		}

		return "N/A";
	}

  packageIncludesFeature = (Package, Feature) =>
  	this.PackageFeatures.filter(PF => parseInt(PF.get('feature_id')) === parseInt(Feature.id()) && parseInt(PF.get('pkg_id')) === parseInt(Package.id())).length > 0;

  addPackageFeature = async (Package, Feature) =>
  {
  	this.startSaving(Package, Feature)

  	const PackageFeature = getPackageFeature(Package, Feature, this.PackageFeatures);

  	if (!PackageFeature) {
  		let PF = AgentPackagesFeaturesFactory.create({feature_id: Feature.id(), pkg_id: Package.id()});
  		if (PF) await PF.save();
  		this.PackageFeatures.push(PF)
  	}

  	this.stopSaving(Package, Feature)

  	return true;
  }

  removePackageFeature = async (Package, Feature) =>
  {
  	this.startSaving(Package, Feature)

  	const PackageFeature = getPackageFeature(Package, Feature, this.PackageFeatures),
  				pkgFeatureId = PackageFeature && PackageFeature.id();

  	if (pkgFeatureId)
  		this.PackageFeatures = this.PackageFeatures.filter(PF => parseInt(PF.id()) !== parseInt(pkgFeatureId))

  	if (PackageFeature)
  		await PackageFeature.delete();

  	this.stopSaving(Package, Feature)

  	return true;
  }

  deleteFeature = async () =>
  {
  	if (this.DeleteFeature) {
  		this.Features = this.Features.filter(Feature => parseInt(Feature.id()) !== parseInt(this.DeleteFeature.id()));

  		const PackageFeatures = this.Packages.map(Package => {
  			const PackageFeature = getPackageFeature(Package, this.DeleteFeature, this.PackageFeatures)
  			if (PackageFeature)
  				return {Package, Feature: this.DeleteFeature, PackageFeature}
  			return false;
  		}).filter(PF => !!PF);

  		if (PackageFeatures && Array.isArray(PackageFeatures) && PackageFeatures.length > 0)
  			await Promise.all(PackageFeatures.map(({Package, Feature}) => this.removePackageFeature(Package, Feature)))

  		this.DeleteFeature.delete();
  		this.DeleteFeature = null;
  	}
  }

  createFeature = async (NewFeature) =>
	{
		if (NewFeature.get('feature')) {
			NewFeature.set('position', 0);

			this.Features.forEach(F => {
				if (parseInt(F.get('position')) >= parseInt(NewFeature.get('position')))
					NewFeature.set('position', parseInt(F.get('position'))+1)
			});

			await NewFeature.save()

			this.Features.push(NewFeature);
			this.NewFeature = AgentPackageFeatureFactory.create({feature: ""});
		}
	}

  saveFeature = async () =>
  {
  	if (this.EditFeature) {

  		const reorder = !!toJS(this.EditFeature.changed)?.position,
  					{from,to} = reorder ? toJS(this.EditFeature.changed).position : {};

  		this.EditFeature.save();

  		if (reorder) {
  			if (from < to) {
  				// increasing the position.
  				this.Features.filter(Feature => parseInt(Feature.id()) !== parseInt(this.EditFeature.id()) && Feature.get('position') >= from && Feature.get('position') <= to).forEach(Feature => Feature.set('position', Feature.get('position')-1))
  				this.Features.sort((a,b) => `${a.get('position')}`.localeCompare(`${b.get('position')}`, 'en', {numeric: true}));
  				// re-position all others, after sorting, to ensure no gaps or duplicates.
  				let position = 1,
  						repositioned = this.Features.filter(Feature => {
													  					Feature.set('position', position)
													  					position++;
													  					return !!(Feature.changed && Feature.changed?.position);
													  				}).map(Feature => Feature.save());
  				if (repositioned && repositioned.length > 0)
  					await Promise.all(repositioned);
  			}
  			else if (to < from) {
  				// decreasing the feature position.
  				this.Features.filter(Feature => parseInt(Feature.id()) !== parseInt(this.EditFeature.id()) && Feature.get('position') >= to && Feature.get('position') <= from).forEach(Feature => Feature.set('position', parseInt(Feature.get('position'))+1))
  				this.Features.sort((a,b) => `${a.get('position')}`.localeCompare(`${b.get('position')}`, 'en', {numeric: true}));
  				// re-position all others, after sorting, to ensure no gaps or duplicates.
  				let position = 1,
  						repositioned = this.Features.filter(Feature => {
													  					Feature.set('position', position)
													  					position++;
													  					return !!(Feature.changed && Feature.changed?.position);
													  				}).map(Feature => Feature.save());
  				if (repositioned && repositioned.length > 0)
  					await Promise.all(repositioned);
  			}
  		}
  	}

  	this.EditFeature = null;
  }

  deletePackage = async () =>
  {
  	if (this.DeletePackage) {
  		this.Packages = this.Packages.filter(Package => parseInt(Package.id()) !== parseInt(this.DeletePackage.id()));

  		const PackageFeatures = this.Features.map(Feature => getPackageFeature(this.DeletePackage, Feature, this.PackageFeatures))
  		if (PackageFeatures && Array.isArray(PackageFeatures) && PackageFeatures.length > 0)
  			await Promise.all(PackageFeatures.map((Feature) => this.removePackagePackage(this.DeletePackage, Feature)))

  		this.DeletePackage.delete();
  		this.DeletePackage = null;
  	}
  }

  savePackage = async () =>
  {
  	if (this.EditPackage) {

  		const reorder = !!toJS(this.EditPackage.changed)?.position,
  					{from,to} = reorder ? toJS(this.EditPackage.changed).position : {};

  		if (!!toJS(this.EditPackage.changed)?.price_ids) 
  			this.EditPackage.set('price_ids', `${this.EditPackage.get('price_ids')}`.trim().replace(/ /g,',').split(',').map(s => s&&`${s}`.trim().length?`${s}`.trim():false).filter(n => !!n).join(','))

  		this.EditPackage.save();

  		if (reorder) {
  			if (from < to) {
  				// increasing the position.
  				this.Packages.filter(Package => parseInt(Package.id()) !== parseInt(this.EditPackage.id()) && Package.get('position') >= from && Package.get('position') <= to).forEach(Package => {
  					Package.set('position', Package.get('position')-1);
  					Package.save();
  				})
  			}
  			else if (to < from) {
  				// decreasing the position.
  				this.Packages.filter(Package => parseInt(Package.id()) !== parseInt(this.EditPackage.id()) && Package.get('position') >= to && Package.get('position') <= from).forEach(Package => {
  					Package.set('position', parseInt(Package.get('position'))+1);
  					Package.save();
  				})
  			}
  		}
  	}

  	this.Packages.sort((a,b) => `${a.get('position')}`.localeCompare(`${b.get('position')}`, 'en', {numeric: true}));
  	this.EditPackage = null;
  }

  fetchUsertypes = async () => 
  {
  	const usertypeIds = this.Packages.map(Pkg => Pkg.get('usertype_id')).filter(usertypeId => !isNaN(usertypeId) && usertypeId > 0)
  	if (!usertypeIds || !usertypeIds.length) return;

  	usertypeIds.forEach(usertypeId => this.Usertypes[usertypeId] = null);

  	try {
  		(await UsertypeFactory.search({search: {id: usertypeIds}, pagination: false})).forEach(Usertype => this.Usertypes[Usertype.id()] = Usertype)
  	}
  	catch (ex) {}
  }

  fetch = async () => 
  {
  	this.isFetching = true;
  	const [Packages, Features, PackageFeatures] = await Promise.all([
  			AgentPackageFactory.search({pagination: false}),
  			AgentPackageFeatureFactory.search({pagination: false}),
  			AgentPackagesFeaturesFactory.search({pagination: false})
  		]);

  	this.Packages = (Packages||[]).sort((a,b) => `${a.get('position')}`.localeCompare(`${b.get('position')}`, 'en', {numeric: true}));
  	this.Features = (Features||[]).sort((a,b) => `${a.get('position')}`.localeCompare(`${b.get('position')}`, 'en', {numeric: true}));
  	this.PackageFeatures = PackageFeatures||[];

  	this.fetchUsertypes()

  	this.isFetching = false;
  }
}

export default new AdminPackagesStore()