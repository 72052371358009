import React from 'react';
import {observer} from 'mobx-react-lite'
import {MDBAlert} from "mdbreact";
import AuthStore from './../Auth.store';

import './AuthMessages.scss';

const AuthMessages = () =>
{
	return (
		<div id="AuthMessagesComponent" className={AuthStore.error||AuthStore.success?'show-message':''}>
			<MDBAlert color={AuthStore.success?'success':'danger'}>{AuthStore.error||AuthStore.success}</MDBAlert>
    </div>
  )
}

export default observer(AuthMessages);
