import React, { Component } from 'react'
import { MDBCol, MDBBtn } from 'mdbreact'

class TrainingAnswer extends Component {
  state = {
    answer: null,
  }

  componentDidMount() {
    this.setState({
      answer: this.props.answer,
    })
  }

  clickHandle = (event) => {
    this.props.onClickAnswer(this.state.answer)
  }

  render() {
    return (
      <MDBCol className="mb-2" size="12" md="6">
        <MDBBtn
          color={this.state.answer?.checked ? 'primary' : 'blue'}
          type="button"
          className="btn-block"
          onClick={(evt) => this.clickHandle(evt)}
        >
          {this.state.answer?.label}
        </MDBBtn>
      </MDBCol>
    )
  }
}

export default TrainingAnswer
