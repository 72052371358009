import React from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import getQueryParam from './../../shared/utilities/getQueryParam.function'
import makeQueryParams from './../../shared/utilities/makeQueryParams.function'
import ReportsStore from './store/ReportsPage.store'
import { getDefaultStart, getDefaultStop } from './helpers'
import ReportsResultsTable from './components/ReportsResultsTable/ReportsResultsTable.component'
import CategorySelectorDropdown from './components/CategorySelectorDropdown/CategorySelectorDropdown.component'
import ReportOverview from './components/ReportOverview/ReportOverview.component'
import ReportSelector from './components/ReportSelector/ReportSelector.component'
import ReportFilters from './components/ReportFilters/ReportFilters.component'
import ReportDatesSetter from './components/ReportDatesSetter/ReportDatesSetter.component'
import ReportDatesSelector from './components/ReportDatesSelector/ReportDatesSelector.component'
import { subNavOpts, tableColumns } from './variables'

import './ReportsPage.scss'

const DEFAULT_PER_PAGE = 250

const DRAGGABLE_TABLE = {
  _enable: false,
}

const onMouseDown = (e) => {
  DRAGGABLE_TABLE.isDown = true
  DRAGGABLE_TABLE._slider.classList.add('active')
  DRAGGABLE_TABLE.startX = e.pageX - DRAGGABLE_TABLE._slider.offsetLeft
  DRAGGABLE_TABLE.scrollLeft = DRAGGABLE_TABLE._slider.scrollLeft
}

const onMouseLeave = (e) => {
  DRAGGABLE_TABLE.isDown = false
  DRAGGABLE_TABLE._slider.classList.remove('active')
}

const onMouseUp = () => {
  DRAGGABLE_TABLE.isDown = false
  DRAGGABLE_TABLE._slider.classList.remove('active')
}

const onMouseMove = (e) => {
  if (!DRAGGABLE_TABLE.isDown) return
  e.preventDefault()
  const x = e.pageX - DRAGGABLE_TABLE._slider.offsetLeft
  const walk = (x - DRAGGABLE_TABLE.startX) * 2 //scroll-fast
  DRAGGABLE_TABLE._slider.scrollLeft = DRAGGABLE_TABLE.scrollLeft - walk
}

const isColumnSortable = (c, cat, report) => {
  if (c) {
    let sortableCols = []
    try {
      sortableCols = tableColumns[cat][report]
        .map((col) => col?.sort_col)
        .filter((n) => n)
    } catch (ex) {}

    if (
      !sortableCols ||
      !sortableCols.length ||
      !sortableCols.includes(`${c}`.toLowerCase())
    )
      return false

    return true
  }

  return false
}

class ReportsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: false,
      isExporting: false,
      category: this.category(),
      report: this.report(),
      coverages: this.coverages(),
      start: this.start(),
      stop: this.stop(),
      outputMode: this.outputMode(),
      page: this.page(),
      perPage: this.perPage(),
      total: 0,
      search: this.search(),
      orderByCol: this.orderByCol(),
      orderByDir: this.orderByDir(),
      enablePagination: null,
    }
  }

  category = () => {
    let cat = ''
    try {
      cat = window.location.pathname
        ? `${window.location.pathname}`.split(/\//g)[2]
        : ''
    } catch (ex) {}
    if (cat) return cat

    try {
      cat = this.props?.location?.pathname
        ? `${this.props?.location?.pathname}`.split(/\//g)[2]
        : ''
    } catch (ex) {}
    if (cat) return cat

    return ''
  }

  report = () => {
    try {
      return window.location.pathname
        ? `${window.location.pathname}`.split(/\//g)[3]
        : ''
    } catch (ex) {}
  }

  coverages = () => {
    let c = ''
    try {
      c = getQueryParam('coverages')
    } catch (ex) {}
    return c ? `${c}`.trim().toUpperCase() : ''
  }

  start = () => {
    let c = ''
    try {
      c = getQueryParam('start')
    } catch (ex) {}
    return c ? `${c}`.trim() : getDefaultStart()
  }

  stop = () => {
    let c = ''
    try {
      c = getQueryParam('stop')
    } catch (ex) {}
    return c ? `${c}`.trim() : getDefaultStop()
  }

  outputMode = () => {
    let c = ''
    try {
      c = getQueryParam('output_mode')
    } catch (ex) {}
    return c ? `${c}`.trim().toUpperCase() : 'weekly'
  }

  pagination = () => {
    let c = '',
      p = ''

    try {
      c = getQueryParam('per_page')
      p = getQueryParam('page')
    } catch (ex) {}
    c = c && !isNaN(`${c}`.trim()) ? parseInt(c) : false
    p = p && !isNaN(`${p}`.trim()) ? parseInt(p) : false
    if (!isNaN(c) || !isNaN(p))
      return {
        per_page: !isNaN(c) && c ? c : DEFAULT_PER_PAGE,
        page: !isNaN(p) && p ? p : 1,
      }
    return
  }

  page = () => {
    const pagination = this.pagination()
    if (pagination && pagination?.page) return pagination.page
    return 1
  }

  perPage = () => {
    const pagination = this.pagination()
    if (pagination && pagination?.per_page) return pagination.per_page
    return DEFAULT_PER_PAGE
  }

  search = () => {
    let c = ''
    try {
      c = getQueryParam('search')
    } catch (ex) {}
    return c ? `${c}`.trim() : ''
  }

  orderByCol = () => {
    let c = ''
    try {
      c = getQueryParam('order_by_col')
    } catch (ex) {}

    // check to see if this value
    // is a sortable column for the
    // current report.
    if (c && !isColumnSortable(c)) return ''

    return c ? `${c}`.trim() : ''
  }

  orderByDir = () => {
    let c = ''
    try {
      c = getQueryParam('order_by_dir')
    } catch (ex) {}
    return c ? `${c}`.trim() : ''
  }

  divisionId = () => {
    let c = ''
    try {
      c = getQueryParam('division_id')
    } catch (ex) {}
    if (c && `${c}`.trim()) return `${c}`.trim()
    return
  }

  update = (goTo) => {
    this.setState(
      (prevState) => ({ ...prevState, ...goTo }),
      () => {
        this.fetch({
          category: this.state.category,
          report: this.state.report,
          coverages: this.state.coverages,
          start: this.state.start,
          stop: this.state.stop,
          outputMode: this.state.outputMode,
          opts: { ...(this.state.opts || {}), search: this.state.search },
          pagination: { per_page: this.state.perPage, page: this.state.page },
          orderBy:
            this.state.orderByCol && this.state.orderByDir
              ? { [this.state.orderByCol]: this.state.orderByDir }
              : null,
        })

        var newurl =
            window.location.protocol +
            '//' +
            window.location.host +
            `/reporting/${this.state.category}` +
            (this.state.report ? `/${this.state.report}` : ''),
          queryStr = {}

        if (this.state.coverages) queryStr.coverages = this.state.coverages

        if (this.state.start) queryStr.start = this.state.start

        if (this.state.stop) queryStr.stop = this.state.stop

        if (this.state.outputMode) queryStr.output_mode = this.state.outputMode

        if (this.state.perPage && !isNaN(this.state.perPage))
          queryStr.per_page = this.state.perPage

        if (this.state.page && !isNaN(this.state.page))
          queryStr.page = this.state.page

        if (this.state.search) queryStr.search = this.state.search

        if (this.state.orderByCol && this.state.orderByDir) {
          queryStr.order_by_col = this.state.orderByCol
          queryStr.order_by_dir = this.state.orderByDir
        }

        newurl += '?' + makeQueryParams(queryStr)
        window.history.pushState({ path: newurl }, '', newurl)
      }
    )
  }

  onChange = (changed) => {
    let goTo = {}

    if (
      changed?.category &&
      `${changed.category}`.trim().toLowerCase() !== this.category()
    ) {
      goTo.category = `${changed.category}`.trim().toLowerCase()
      goTo.report = `${changed.report}`.trim().toLowerCase()
    } else if (
      changed.hasOwnProperty('report') &&
      `${changed.report}`.trim().toLowerCase() !== this.report()
    ) {
      goTo.category = this.category()
      goTo.report = `${changed.report}`.trim().toLowerCase()
    }

    if (
      changed.hasOwnProperty('coverages') &&
      `${changed?.coverages}`.trim().toUpperCase() !== this.coverages()
    )
      goTo.coverages = `${changed?.coverages}`.trim().toUpperCase()

    if (
      changed.hasOwnProperty('start') &&
      `${changed?.start}`.trim() !== this.start()
    )
      goTo.start = `${changed?.start}`.trim()

    if (
      changed.hasOwnProperty('stop') &&
      `${changed?.stop}`.trim() !== this.stop()
    )
      goTo.stop = `${changed?.stop}`.trim()

    if (
      changed.hasOwnProperty('output_mode') &&
      `${changed?.output_mode}`.trim() !== this.outputMode()
    )
      goTo.outputMode = `${changed?.output_mode}`.trim()

    if (
      changed.hasOwnProperty('per_page') &&
      `${changed?.per_page}`.trim() !== `${this.perPage()}`
    )
      goTo.perPage = parseInt(changed.per_page)

    if (
      changed.hasOwnProperty('page') &&
      `${changed?.page}`.trim() !== `${this.page()}`
    )
      goTo.page = parseInt(changed.page)

    if (
      changed.hasOwnProperty('search') &&
      `${changed?.search}`.trim() !== this.search()
    )
      goTo.search = `${changed?.search}`.trim()

    if (
      changed.hasOwnProperty('order_by_col') &&
      `${changed?.order_by_col}`.trim() !== this.orderByCol()
    )
      goTo.orderByCol = changed?.order_by_col
        ? `${changed.order_by_col}`.trim()
        : ''

    if (
      changed.hasOwnProperty('order_by_dir') &&
      `${changed?.order_by_dir}`.trim() !== this.orderByDir()
    )
      goTo.orderByDir = changed?.order_by_dir
        ? `${changed.order_by_dir}`.trim()
        : ''

    // if the category or report have changed,
    // the current page (pagination) should reset.
    if (goTo?.category || goTo?.report) goTo.page = 1

    // if the category or report have changed,
    // the sorted column may no longer be sortable.
    if (
      (goTo?.category || goTo?.report) &&
      this.state.orderByCol &&
      !isColumnSortable(
        this.state.orderByCol,
        goTo.category || this.state.category,
        goTo.report || this.state.report
      )
    )
      goTo.orderByCol = goTo.orderByDir = false

    if (Object.keys(goTo).length) this.update(goTo)
  }

  fetch = async (params) => {
    const isReportRequired = (cat) => {
      let subNav =
        cat && subNavOpts.hasOwnProperty(cat) ? subNavOpts[cat] : false
      return !!(subNav && typeof subNav === 'object')
    }

    if (params?.category && params?.start && params?.stop) {
      this.setState({ isFetching: true }, async () => {
        let tableData = this.state.tableData,
          pagination

        if (
          params?.category &&
          !params?.report &&
          isReportRequired(params?.category)
        )
          return this.setState({ isFetching: false })

        try {
          tableData = await ReportsStore.fetchPromise(
            params?.category,
            params?.report,
            params?.start,
            params?.stop,
            params?.coverages,
            params?.outputMode,
            params?.opts,
            params?.pagination,
            this.state.orderByCol && this.state.orderByDir
              ? { [this.state.orderByCol]: this.state.orderByDir }
              : null
          )
        } catch (ex) {
          console.error('ERROR: Failed to fetch report results. ', {
            category: params?.category,
            report: params?.report,
            start: params?.start,
            stop: params?.stop,
            coverages: params?.coverages,
            outputMode: params?.outputMode,
            opts: params?.opts,
            pagination: params?.pagination,
            orderBy:
              this.state.orderByCol && this.state.orderByDir
                ? { [this.state.orderByCol]: this.state.orderByDir }
                : null,
          })
        }

        if (tableData && typeof tableData?.pagination === 'object') {
          pagination = tableData.pagination
          delete tableData.pagination
          if (pagination?.per_page) {
            pagination.perPage = pagination.per_page
            delete pagination.per_page
          }
        }

        this.setState(
          (prevState) =>
            pagination
              ? {
                  ...prevState,
                  isFetching: false,
                  tableData,
                  ...pagination,
                  enablePagination: true,
                }
              : {
                  ...prevState,
                  isFetching: false,
                  tableData,
                  enablePagination: false,
                },
          () => {
            this._initDraggableTable()
          }
        )
      })
    }
  }

  onChangeDivision = (division_id) => {
    if (this.divisionId() !== division_id)
      this.update({ opts: { division_id } })
  }

  onExport = async () => {
    this.setState({ isExporting: true })
    try {
      await ReportsStore.export({
        category: this.state?.category,
        report: this.state?.report,
        start: this.state?.start,
        stop: this.state?.stop,
        coverages: this.state?.coverages,
        orderBy:
          this.state.orderByCol && this.state.orderByDir
            ? { [this.state.orderByCol]: this.state.orderByDir }
            : null,
        search: this.state?.search,
      })
    } catch (ex) {
      console.error('ERROR: Failed to export report results. ', {
        category: this.state?.category,
        report: this.state?.report,
        start: this.state?.start,
        stop: this.state?.stop,
        coverages: this.state?.coverages,
        outputMode: this.state?.outputMode,
        opts: this.state?.opts,
        pagination: this.state?.pagination,
        orderBy:
          this.state.orderByCol && this.state.orderByDir
            ? { [this.state.orderByCol]: this.state.orderByDir }
            : null,
      })
    }

    this.setState({ isExporting: false })
  }

  componentDidMount() {
    this.fetch({
      category: this.category(),
      report: this.report(),
      coverages: this.coverages(),
      start: this.start(),
      stop: this.stop(),
      outputMode: this.outputMode(),
      pagination: { per_page: this.perPage(), page: this.page() },
      opts: { ...{ search: this.search() || '' } },
    })
  }

  _slider = null
  _int = null

  _removeDraggableTable = () => {
    if (DRAGGABLE_TABLE._slider) {
      DRAGGABLE_TABLE._slider.removeEventListener('mousedown', onMouseDown)
      DRAGGABLE_TABLE._slider.removeEventListener('mouseleave', onMouseLeave)
      DRAGGABLE_TABLE._slider.removeEventListener('mouseup', onMouseUp)
      DRAGGABLE_TABLE._slider.removeEventListener('mousemove', onMouseMove)
    }
  }

  _initDraggableTable = () => {
    if (DRAGGABLE_TABLE._enable) {
      DRAGGABLE_TABLE._slider = document.querySelector('div.reports-table')
      DRAGGABLE_TABLE.isDown = false
      DRAGGABLE_TABLE.startX = ''
      DRAGGABLE_TABLE.scrollLeft = ''

      if (!DRAGGABLE_TABLE._slider) return

      this._removeDraggableTable()

      DRAGGABLE_TABLE._slider.addEventListener('mousedown', onMouseDown)
      DRAGGABLE_TABLE._slider.addEventListener('mouseleave', onMouseLeave)
      DRAGGABLE_TABLE._slider.addEventListener('mouseup', onMouseUp)
      DRAGGABLE_TABLE._slider.addEventListener('mousemove', onMouseMove)
    }
  }

  componentWillUnmount() {
    this._removeDraggableTable()
  }

  render() {
    return (
      <>
        <DashboardLayout>
          <main id="ReportsPage" className="adminStyle mainSection pb-5">
            <MDBContainer fluid className="mt-2">
              <MDBRow className="report-title">
                <MDBCol size="12">
                  <h2 className="reporting-title">
                    <div>Reporting: </div>
                    <CategorySelectorDropdown
                      category={this.state.category}
                      report={this.state.report}
                      onChange={this.onChange}
                    />
                  </h2>
                </MDBCol>
              </MDBRow>
              <hr />
              <MDBRow>
                <MDBCol size="12" md="6">
                  <ReportOverview
                    category={this.state.category}
                    report={this.state.report}
                    coverages={this.state.coverages}
                    start={this.state.start}
                    stop={this.state.stop}
                    output_mode={this.state.outputMode}
                  />
                  <ReportFilters
                    category={this.state.category}
                    coverages={this.state.coverages}
                    onChange={this.onChange}
                    isFetching={this.state.isFetching}
                    division_id={''}
                    onChangeDivision={this.onChangeDivision}
                    opts={this.state.opts}
                  />
                </MDBCol>
                <MDBCol size="12" md="6">
                  <ReportDatesSetter
                    start={this.state.start}
                    stop={this.state.stop}
                    onChange={this.onChange}
                    isFetching={this.state.isFetching}
                  />
                </MDBCol>
                <MDBCol size="12">
                  <ReportSelector
                    category={this.state.category}
                    report={this.state.report}
                    coverages={this.state.coverages}
                    start={this.state.start}
                    stop={this.state.stop}
                    output_mode={this.state.outputMode}
                    onChange={this.onChange}
                  />
                </MDBCol>
                <MDBCol size="12">
                  <ReportDatesSelector
                    start={this.state.start}
                    stop={this.state.stop}
                    onChange={this.onChange}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
            {
              <ReportsResultsTable
                data={this.state.tableData}
                pagination={{
                  per_page: this.state.perPage,
                  page: this.state.page,
                  total: this.state?.total || 0,
                }}
                enablePagination={this.state.enablePagination}
                search={this.state.search}
                orderByCol={this.state.orderByCol}
                orderByDir={this.state.orderByDir}
                isFetching={this.state.isFetching}
                isExporting={this.state.isExporting}
                onChange={(changed) => {
                  if (changed && typeof changed === 'object') {
                    Object.keys(changed)
                      .filter(
                        (key) =>
                          ![
                            'per_page',
                            'page',
                            'search',
                            'order_by_col',
                            'order_by_dir',
                          ].includes(key)
                      )
                      .forEach((key) => delete changed[key])
                    if (Object.keys(changed).length) this.onChange(changed)
                  }
                }}
                onExport={() => this.onExport()}
                canExport={
                  (!isNaN(this.state.total) && this.state.total > 0) ||
                  (this.state.enablePagination === false &&
                    this.state.tableData?.rows?.length > 0)
                }
              />
            }
          </main>
        </DashboardLayout>
      </>
    )
  }
}

export default ReportsPage
