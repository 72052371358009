import React, { Component, Fragment } from 'react'
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBSwitch,
  MDBSpinner,
} from 'mdbreact'
import { UIDropdown } from '../../components/forms/form-fields'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'
import appConstants from '../../constants/appConstants'
import { TermLengthsFactory } from '../../shared/factories'
import TermLengthsService from '../../shared/services/TermLengths.service'

const TermLengths = appConstants.termLength.map((item) => ({
  ...item,
  value: `${item.value}`,
}))

const COVERAGE_CATEGORIES = appConstants.coverage_categories

class AddCoverage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      coverage_name: '',
      coverage_term_length_id: null,
      min_length: null,
      max_length: null,
      default_length: null,
      coverage_active: 0,
      coverage_incentive: 0,
      coverage_category: null,
      formError: false,
      errorMessage: '',
    }
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params })

    if (params && params.id) {
      this.fetchCoveragesData(params.id)
    }
  }

  handleSwitchChange = (nr) => () => {
    let switchNumber = `${nr}`
    this.setState({
      [switchNumber]: this.state[switchNumber] ? 0 : 1,
    })
  }

  //Change event for input fields
  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  validate = async () => {
    const { coverage_name } = this.state

    if (!(coverage_name && coverage_name.trim())) {
      this.setState({ formError: true, errorMessage: 'Coverage Name Required' })
      return
    }

    this.setState({ formError: false, errorMessage: '' })
  }

  //create coverages
  createCoverage = async () => {
    await this.validate()
    const {
      coverage_name,
      coverage_active,
      coverage_incentive,
      coverage_category,
      loading,
      formError,
      errorMessage,
    } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }
    const data = {
      coverage_name: coverage_name,
      coverage_active: coverage_active ? coverage_active : 0,
      coverage_incentive: coverage_incentive ? coverage_incentive : 0,
      coverage_category: coverage_category,
      table_name: 'coverages',
    }

    const form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })

    AdminApi.createCoverage(form_data)
      .then(async (result) => {
        await TermLengthsService.store({
          coverage_id: result?.data?.data?.id,
          min_length: parseInt(this.state.min_length),
          max_length: parseInt(this.state.max_length),
          default_length: parseInt(this.state.default_length),
        })

        this.setState({ loading: false })
        this.props.history.push('/admin-coverage')
        toast.success('Coverage created successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchCoveragesData = async (id) => {
    AdminApi.getCoverageById(id)
      .then(async (result) => {
        const coverageTermLength = (
          await TermLengthsFactory.search({ search: { coverage_id: id } })
        )?.models.shift()
        this.setState({ loading: false })
        if (result && result.data && result.data.data) {
          const content = result.data.data
          this.setState({
            coverage_name: content.coverage_name,
            coverage_active: content.coverage_active,
            coverage_incentive: content.coverage_incentive,
            coverage_category: content.coverage_category,
            min_length: coverageTermLength?.get('min_length'),
            max_length: coverageTermLength?.get('max_length'),
            default_length: coverageTermLength?.get('default_length'),
            coverage_term_length_id: coverageTermLength?.id(),
          })
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  updateCoverage = async () => {
    await this.validate()
    const id = this.state.urlParams.id
    const {
      coverage_name,
      coverage_active,
      coverage_incentive,
      coverage_category,
      loading,
      formError,
      errorMessage,
    } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }
    const data = {
      coverage_name: coverage_name,
      coverage_active: coverage_active ? coverage_active : 0,
      coverage_incentive: coverage_incentive ? coverage_incentive : 0,
      coverage_category: coverage_category,
      table_name: 'coverages',
      id: id,
    }

    const form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })
    AdminApi.updateCoverage(form_data)
      .then(async (result) => {
        if (this.state.coverage_term_length_id) {
          await TermLengthsService.update(this.state.coverage_term_length_id, {
            min_length: parseInt(this.state.min_length),
            max_length: parseInt(this.state.max_length),
            default_length: parseInt(this.state.default_length),
          })
        } else {
          await TermLengthsService.store({
            coverage_id: id,
            min_length: parseInt(this.state.min_length),
            max_length: parseInt(this.state.max_length),
            default_length: parseInt(this.state.default_length),
          })
        }
        this.setState({ loading: false })
        this.props.history.push('/admin-coverage')
        toast.success('Coverage updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  getCategories = (category) => {
    return COVERAGE_CATEGORIES.map((name) => ({
      text: <>{name}</>,
      value: `${name}`,
      checked: !!(category && `${category}` === `${name}`),
    }))
  }

  render() {
    toast.configure()
    const {
      urlParams,
      loading,
      coverage_name,
      coverage_active,
      coverage_incentive,
      coverage_category,
    } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>
                  <a href="/admin-coverage">List</a>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>
                  {!!(urlParams && urlParams.id)
                    ? 'Edit Coverage'
                    : 'Add Coverage'}
                </MDBBreadcrumbItem>
              </MDBBreadcrumb>
              <h2>
                {!!(urlParams && urlParams.id)
                  ? 'Edit Coverage'
                  : 'Add Coverage'}
              </h2>
              <hr />
              {loading ? (
                <div className="loadingOverlay">
                  <h4 className={'loader-text p4'}>
                    <MDBSpinner />
                  </h4>
                </div>
              ) : (
                ''
              )}
              <MDBRow>
                <MDBCol size="12">
                  <form className="content-wrapper">
                    <div className="form-row">
                      <div className="form-group md-4">
                        <label className="grey-text" htmlFor={'coverage_name'}>
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="coverage_name"
                          id="coverage_name"
                          value={coverage_name}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group md-4">
                        <UIDropdown
                          label="Min Term Length"
                          name="min_length"
                          options={TermLengths.map((item) => ({
                            ...item,
                            checked:
                              parseInt(item.value) ===
                              parseInt(this.state.min_length),
                          }))}
                          onChange={(evt) => {
                            this.setState({ min_length: evt.target.value })
                          }}
                          value={this.state.min_length}
                          search
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group md-4">
                        <UIDropdown
                          label="Max Term Length"
                          name="max_length"
                          options={TermLengths.map((item) => ({
                            ...item,
                            checked:
                              parseInt(item.value) ===
                              parseInt(this.state.max_length),
                          }))}
                          onChange={(evt) => {
                            this.setState({ max_length: evt.target.value })
                          }}
                          value={this.state.max_length}
                          search
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group md-4">
                        <UIDropdown
                          label="Default Term Length"
                          name="default_length"
                          options={TermLengths.map((item) => ({
                            ...item,
                            checked:
                              parseInt(item.value) ===
                              parseInt(this.state.default_length),
                          }))}
                          onChange={(evt) => {
                            this.setState({ default_length: evt.target.value })
                          }}
                          value={this.state.default_length}
                          search
                        />
                      </div>
                    </div>
                    <label className="grey-text">Currently Active?</label>
                    <MDBSwitch
                      checked={!!coverage_active}
                      onChange={this.handleSwitchChange('coverage_active')}
                    />
                    <br />
                    <label className="grey-text">
                      Include In Incentive Reports?
                    </label>
                    <MDBSwitch
                      checked={!!coverage_incentive}
                      onChange={this.handleSwitchChange('coverage_incentive')}
                    />

                    <MDBRow className="mt-3">
                      <MDBCol size="12" sm="3">
                        <UIDropdown
                          label="Category"
                          name="category"
                          options={this.getCategories(coverage_category)}
                          value={coverage_category || ''}
                          selected={coverage_category || ''}
                          onChange={(evt) =>
                            this.setState({
                              coverage_category: evt.target.value,
                            })
                          }
                        />
                      </MDBCol>
                    </MDBRow>

                    <div className="mt-4">
                      <MDBBtn
                        color="unique"
                        type="button"
                        className="ml-0 btn-block"
                        onClick={() => {
                          this.state.urlParams && this.state.urlParams.id
                            ? this.updateCoverage()
                            : this.createCoverage()
                        }}
                      >
                        {loading ? 'Saving...' : 'Save'}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AddCoverage
