import React from 'react'
import LeaderboardsStore from './../../store/LeaderboardsPage.store'
import CurrentTopPipAgents from './../CurrentTopPipAgents/CurrentTopPipAgents.component'
import CurrentTopPipBATeams from './../CurrentTopPipBATeams/CurrentTopPipBATeams.component'

import './CurrentTopPip.scss'

const TOGGLE_PIP_WIDGETS = false

const CurrentTopPip = () =>
  TOGGLE_PIP_WIDGETS ? (
    LeaderboardsStore.fetchParams.agent_type === 'ba_team_id' ? (
      <CurrentTopPipBATeams />
    ) : (
      <CurrentTopPipAgents />
    )
  ) : (
    <>
      <CurrentTopPipAgents />
      <CurrentTopPipBATeams />
    </>
  )

export default CurrentTopPip
