import React from 'react'
import { observer } from 'mobx-react-lite'
import env from './../../environments/environment'
import PLACEHOLDER from './../../assets/images/user_placeholder.png'
import { useBreakTheBarSetting } from './../../components/shared/useBreakTheBarSetting'

const BA_TEAM_LOGO_SRC = `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/{BA_TEAM}?alt=media`

function getTeamLogo(baTeam, strict = false) {
  baTeam = baTeam
    ? typeof baTeam === 'string'
      ? { team_logo: baTeam }
      : baTeam
    : false

  if (baTeam) {
    if (typeof baTeam === 'object' && baTeam?.team_logo)
      return BA_TEAM_LOGO_SRC.replace(
        '{BA_TEAM}',
        encodeURIComponent(decodeURIComponent(baTeam.team_logo))
      )
    if (typeof baTeam === 'string' && baTeam)
      return BA_TEAM_LOGO_SRC.replace(
        '{BA_TEAM}',
        encodeURIComponent(decodeURIComponent(baTeam))
      )
  }

  if (strict) return false

  return PLACEHOLDER
}

function _renderer({
  BATeam,
  className,
  mustBreakBar,
  totalPoints,
  isVisible,
  threshold,
}) {
  const hasBrokenBar =
    mustBreakBar === true ? totalPoints >= threshold : isVisible
  isVisible =
    typeof isVisible === 'undefined'
      ? !!(!mustBreakBar || (mustBreakBar && hasBrokenBar))
      : isVisible

  if (typeof BATeam === 'string')
    return (
      <div className="ba-team-logo">
        {isVisible && !!(!mustBreakBar || (mustBreakBar && hasBrokenBar)) ? (
          <img
            alt={'Benefit Advisor Team'}
            className={className}
            src={getTeamLogo(BATeam)}
          />
        ) : (
          <></>
        )}
      </div>
    )

  if (BATeam && typeof BATeam === 'object') {
    if (BATeam?.get && typeof BATeam.get === 'function')
      return (
        <div className="ba-team-logo">
          {isVisible && !!(!mustBreakBar || (mustBreakBar && hasBrokenBar)) ? (
            <img
              className={className}
              alt={BATeam.get('team_name') || 'Benefit Advisor Team'}
              src={getTeamLogo(BATeam.get('team_logo'))}
            />
          ) : (
            <></>
          )}
        </div>
      )

    return (
      <div className="ba-team-logo">
        {isVisible && !!(!mustBreakBar || (mustBreakBar && hasBrokenBar)) ? (
          <img
            className={className}
            alt={BATeam?.name || BATeam?.team_name || 'Benefit Advisor Team'}
            src={getTeamLogo(BATeam?.team_logo || BATeam?.pic || null)}
          />
        ) : (
          <></>
        )}
      </div>
    )
  }

  return (
    <div className="ba-team-logo">
      {isVisible && !!(!mustBreakBar || (mustBreakBar && hasBrokenBar)) ? (
        <img
          square
          alt={'Benefit Advisor Team'}
          className={className}
          src={getTeamLogo()}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

function renderTeamLogo(BATeam) {
  return _renderer({
    BATeam,
    className: BATeam.className || '',
    isVisible: true,
  })
}

const TeamLogo = observer(({ team }) => {
  // get the threshold to evaluate agent performance against.
  const { threshold } = useBreakTheBarSetting('ba_team'),
    { team_logo, team_name, className, mustBreakBar, totalPoints } = team || {}

  return _renderer({
    BATeam: team,
    team_logo,
    team_name,
    className,
    mustBreakBar,
    totalPoints,
    threshold,
  })
})

export { getTeamLogo, renderTeamLogo, TeamLogo }
