import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import AuthStore from './../Auth.store'
import { MDBCollapse, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact'

import './TermsViewer.component.scss'

const TermsViewer = ({ isVisible, isViewable }) => {
  const [viewTerms, setViewTerms] = useState(false)

  if (!isVisible) return <></>

  return (
    <div id="TermsViewerComponent">
      <div className="accept-terms">
        <small>
          By logging in you are agreeing that you read and agree to USABG’s
          updated 2023 Signature Agent Terms.{' '}
          {isViewable ? (
            <button
              className="view-terms-btn"
              type="button"
              onClick={() => setViewTerms(true)}
            >
              View Terms
            </button>
          ) : (
            <></>
          )}
        </small>
      </div>
      <MDBModal isOpen={viewTerms} toggle={() => setViewTerms(false)}>
        <MDBModalHeader toggle={() => setViewTerms(false)}>
          USA Benefit Group Terms
        </MDBModalHeader>
        <MDBModalBody>
          <MDBCollapse isOpen={AuthStore.isFetchingTerms}>
            <div className="loading-spinner">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <i className="fa fa-spin fa-spinner" />
                    </td>
                    <td>Loading Terms</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </MDBCollapse>
          <MDBCollapse isOpen={!AuthStore.isFetchingTerms}>
            <ul>
              {Array.isArray(AuthStore.TermSets['91']?.get('term_set')) ? (
                AuthStore.TermSets['91']
                  .get('term_set')
                  .map((term, idx) => (
                    <li
                      key={`sig-agent-term-${idx}`}
                      dangerouslySetInnerHTML={{ __html: term }}
                    ></li>
                  ))
              ) : (
                <></>
              )}
            </ul>
          </MDBCollapse>
        </MDBModalBody>
      </MDBModal>
    </div>
  )
}

export default observer(TermsViewer)
