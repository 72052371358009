import CarrierFactory from './../../../shared/factories/carrier.factory'
import CONTRACTING from './../../../constants/contracting.constants'
import UserProfileService from './../../../shared/services/UserProfile.service'
import CarrierContractService from './../../../shared/services/CarrierContract.service'
import UsersCarrierContractService from './../../../shared/services/UsersCarrierContract.service'
import UsersCarrierContractFactory from './../../../shared/factories/users-carrier-contract.factory'
import CarrierService from '../../../shared/services/Carrier.service'

const LAST_CONTRACT_VIEW_META_KEY =
  CONTRACTING.onboarding.LAST_CONTRACT_VIEW_META_KEY

/*
 * @function		getIds
 * @argument		(array)								Array of instantiated object to extract ID value from.
 * @argument		(string)							Indicates field name of ID value to extract.
 * @return			(array<int|string>) 	Array of integer or string values, indicating unique IDs from selected property.
 */
export const getIds = (arr, id) =>
  (arr || [])
    .filter(
      (i) =>
        (!i?.isNew() && !isNaN(i?.get(id)) && i?.get(id) > 0) ||
        (i?.get(id) && typeof i?.get(id) === 'string')
    )
    .map((i) => (isNaN(i.get(id)) ? `${i.get(id)}` : parseInt(i.get(id))))

/*
 * @function		fetchCart
 * @argument		(User)								User instance used to fetch User Cart instances.
 * @argument		isOnboarding <boolean> Indicates if the cart being created is for onboarding.
 * @return			(array<Cart>) 				Array of Cart instances.
 */
export const fetchCart = async (User, isOnboarding) => {
  let Cart

  if (User) {
    const Carts = ((await User.cart().all()) || []).sort((CA, CB) =>
      parseInt(CA.id()) < parseInt(CB.id())
        ? 1
        : parseInt(CA.id()) > parseInt(CB.id())
        ? -1
        : 0
    )
    if (Array.isArray(Carts)) {
      if (isOnboarding === true) {
        Cart = Carts.filter(
          (Cart) => parseInt(Cart.get('cart_onboarding')) === 1
        ).shift()
      } else {
        Cart = Carts.filter(
          (Cart) => Cart.get('cart_status') === 'ACTIVE'
        ).pop()
        Cart = Cart
          ? Cart
          : Carts.filter((Cart) => Cart.get('cart_status') === 'OPEN').pop()
      }
    }

    if (!Cart) {
      Cart = await User.cart().create({
        cart_status: 'OPEN',
        cart_onboarding: !!isOnboarding ? 1 : 0,
      })
      await Cart.save()
    }
  }

  return Cart
}

/*
 * @function    fetchCartItems
 * @argument    (Cart)                User contracting cart instance used to fetch User Cart Item instances.
 * @return      (array<CartItem>)     Array of Cart Item instances.
 */
export const fetchCartItems = async (Cart) => {
  if (Cart) return await Cart.item(true).all()
  return []
}

/*
 * @function    fetchContract
 * @argument    (CartItem)            User contracting cart item instance used to fetch User Contract instance.
 * @return      (array<CartItem>)     Array of Cart Item instances.
 */
export const fetchContract = async (CartItem) => {
  if (CartItem && CartItem.get('user_contract_id'))
    return await UsersCarrierContractFactory.findById(
      CartItem.get('user_contract_id')
    )
}

/*
 * @function		fetchCarriers
 * @return			(array<Carrier>) 			Array of Carrier instances.
 */
const fetchCarriers = async () => {
  return new Promise(async (resolve, reject) => {
    let Results = await CarrierFactory.search({
        pagination: false,
        method: 'getWithPriorities',
      }),
      Carriers = {}

    if (Array.isArray(Results)) {
      const res = (await CarrierService.getWithCoverageCategory()).models
      let CarrierCategories = {}
      res.forEach(
        (r) =>
          (CarrierCategories[r.id] = [...(CarrierCategories[r.id] || []), r])
      )
      Results.forEach((Carrier) => {
        if (!Carriers.hasOwnProperty(Carrier.id()))
          Carriers[Carrier.id()] = Carrier
        Carriers[Carrier.id()].addPriority(Carrier.raw())
        Carriers[Carrier.id()].addCoverageCategory(
          CarrierCategories[Carrier.id()] || []
        )
      })
    }

    resolve(Object.values(Carriers))
  })
}

/*
 * @function		fetchAgentSpecialization
 */
const fetchAgentActiveContractingView = async (User) => {
  let UserMeta = null

  if (User) {
    UserMeta = await User?.meta(true).key(LAST_CONTRACT_VIEW_META_KEY)
    if (Array.isArray(UserMeta)) UserMeta = UserMeta.shift()

    if (!UserMeta) {
      UserMeta = await User?.meta(true).create({
        meta_key: LAST_CONTRACT_VIEW_META_KEY,
      })
      await UserMeta.save()
    }
  }

  return UserMeta || null
}

/*
 * @function		fetchAgentContracts
 */
const fetchAgentContracts = async (User) => {
  const Contracts = await User?.contract().all(),
    carrierIds = (Contracts || [])
      .map((Contract) => Contract.get('carrier_id'))
      .filter((n) => !isNaN(n))
      .filter((val, idx, self) => self.indexOf(val) !== idx),
    CarrierContracts = (
      await CarrierContractService.search({
        search: { carrier_id: carrierIds },
        pagination: false,
      })
    )?.models

  return Contracts.map((Contract) => {
    Contract.contractType = CarrierContracts.filter(
      (CContract) =>
        parseInt(CContract.carrier_id) === parseInt(Contract.get('carrier_id'))
    ).shift()?.contract_type
    return Contract
  })
}

/*
 * @function		fetchAgentSpecialization
 */
const fetchAgentSpecialization = async (User) => {
  let UserMeta = null

  if (User) {
    UserMeta = await User?.meta(true).key('profile---specializations')
    if (Array.isArray(UserMeta)) UserMeta = UserMeta.shift()

    if (!UserMeta) {
      UserMeta = await User?.meta(true).create({
        meta_key: 'profile---specializations',
      })
      await UserMeta.save()
    }
  }

  return UserMeta || null
}

/*
 * @function		fetchAgentCertifications
 */
const fetchAgentCertifications = async (User) => User?.certification().all()

/*
 * @function		fetchAgentExternalCarriers
 */
const fetchAgentExternalCarriers = async (User) => User?.externalCarrier().all()

/*
 * @function		fetchDependencies
 */
export const fetchDependencies = async (User) => {
  return await Promise.all([
    // Dependency 1: Complete Carrier List.
    fetchCarriers(),

    // Dependency 2: Agent Contracts
    fetchAgentContracts(User),

    // Dependency 3: Agent Specializations
    fetchAgentSpecialization(User),

    // Dependency 4: Agent Specializations
    fetchAgentActiveContractingView(User),

    // Dependency 5: Agent Certifications
    fetchAgentCertifications(User),

    // Dependency 6: Agent External Carriers.
    fetchAgentExternalCarriers(User),
  ]).then((Responses) => {
    const [Carriers, Contracts, Specs, ActiveView, Certs, ExternalCarriers] =
      Responses
    return {
      Carriers,
      Contracts,
      Specs,
      ActiveView,
      Certs: {
        ahip: (Certs || [])
          .filter((Cert) => Cert.get('certification') === 'ahip')
          .shift(),
        limra: (Certs || [])
          .filter((Cert) => Cert.get('certification') === 'limra')
          .shift(),
      },
      ExternalCarriers,
    }
  })
}

/*
 * @function		replaceOrPush
 */
export const replaceOrPush = (arr, val, id) => {
  const isArray = Array.isArray(val),
    _replaceOrPush = (v) => {
      let idx = arr
        .map((a, i) =>
          parseInt(a?.get(id)) === parseInt(v?.get(id)) ? i : false
        )
        .filter((a) => !isNaN(a))
        .shift()

      // replace on stack.
      if (idx) {
        arr[idx] = v
        return 0
      }

      // push onto stack
      else {
        arr.push(v)
        return 1
      }
    }

  val = (Array.isArray(val) ? val : [val])
    .filter((i) => i && typeof i === 'object')
    .map(_replaceOrPush)
  return !isArray ? val.shift() : val
}

/*
 * @function	isAdobeContract
 * @argument 	(UserCarrierContract)
 * @return 		boolean
 */
export const isAdobeContract = (UCC) =>
  !!(
    UCC &&
    UCC?.get('contract_id') &&
    !!/CBJCHB[A-Za-z_\-0-9]{37,39}/.test(`${UCC.get('contract_id')}`)
  )

/*
 * @function	isAdminUser
 * @return 		boolean
 */
export const isAdminUser = () =>
  UserProfileService.isA(
    ['internal-admin', 'internal-staff', 'system-admin', 'agency-owner'],
    true
  )

/*
 * @function	dispositionToWaitingOn
 * @return 		<string> (agent || usabg || '') indicating who is responsible for the next contracting action.
 */
export const dispositionToWaitingOn = (disposition) => {
  switch (`${disposition}`.trim().toLowerCase()) {
    case 'pdf-unsent':
    case 'pdf-pending':
    case 'pdf-awaiting':
    case 'acr-unsent':
    case 'acr-pending':
    case 'acr-awaiting':
    case 'link-unsent':
    case 'link-pending':
    case 'link-awaiting':
    case 'unsent':
    case '-unsent':
    case 'pending':
    case '-pending':
    case 'awaiting':
    case '-awaiting':
      return 'agent'

    case 'reviewing':
    case '-reviewing':
    case 'pdf-reviewing':
      return 'usabg'

    default:
      return ''
  }
}

/*
 * @function	dispositionToWaitingOn
 * @return 		<string> (agent || usabg || '') indicating who is responsible for the next contracting action.
 */
export const dispositionToStatus = (contractType, disposition) => {
  switch (`${contractType}-${disposition}`.trim().toLowerCase()) {
    case 'pdf-unsent':
      return 'Waiting on agent to start contract'
    case 'pdf-pending':
      return 'Waiting on agent to start contract'
    case 'pdf-reviewing':
      return 'Quality control reviewing'
    case 'pdf-awaiting':
      return 'Awaiting agent writing number'
    case 'pdf-completed':
      return 'Contract Completed'

    case 'acr-unsent':
      return 'Waiting on agent to start contract'
    case 'acr-pending':
      return 'Waiting on agent signature'
    case 'acr-reviewing':
      return 'Quality control reviewing'
    case 'acr-awaiting':
      return 'Awaiting agent writing number'
    case 'acr-completed':
      return 'Contract Completed'

    case 'link-unsent':
      return 'Waiting on agent to start contract'
    case 'link-pending':
      return 'Waiting on agent to start contract'
    case 'link-reviewing':
      return 'Quality control reviewing'
    case 'link-awaiting':
      return 'Awaiting agent writing number'
    case 'link-completed':
      return 'Contract Completed'

    case 'unsent':
    case '-unsent':
      return 'Waiting on agent to start contract'
    case 'pending':
    case '-pending':
      return 'Waiting on agent signature'
    case 'reviewing':
    case '-reviewing':
      return 'Quality control reviewing'
    case 'awaiting':
    case '-awaiting':
      return 'Awaiting agent writing number'
    case 'completed':
    case '-completed':
      return 'Contract Completed'
    default:
      return ''
  }
}

export const updateContract = async (Contract, changed) => {
  const userContractId = Contract
    ? !isNaN(Contract)
      ? parseInt(Contract)
      : Contract?.id()
    : null
  if (parseInt(userContractId) > 0 && changed && typeof changed === 'object') {
    if (await UsersCarrierContractService.update(userContractId, changed))
      return true
  }

  return false
}

export const track = UserProfileService.track

// export const track = async ({event_type, event_descrip, related_id, related_model, payload}) =>
//  UserProfileService.track({event_type, event_descrip, related_id, related_model, payload})
