import React from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { MDBIcon, MDBBtn } from 'mdbreact'
import CarrierStore from './../Carrier.store'
import { isAdminUser } from './../functions'

import './RenderContractDates.scss'

const RenderContractDates = ({ Contract, canEdit }) => {
  let dates = {
      pwk_sent_to_agent_at: Contract?.get('pwk_sent_to_agent_at'),
      pwk_signed_at: Contract?.get('pwk_signed_at'),
      pwk_sent_to_carrier_at: Contract?.get('pwk_sent_to_carrier_at'),
      pwk_completed_at:
        Contract?.get('disposition') === 'completed' &&
        Contract?.get('pwk_completed_at'),
      pwk_expired_at:
        Contract?.get('disposition') === 'expired' &&
        Contract?.get('pwk_expired_at'),
    },
    labels = {
      pwk_sent_to_agent_at: 'Agent Started Pwk',
      pwk_signed_at: 'Agent Signed Pwk',
      pwk_sent_to_carrier_at: 'Pwk Sent to Carrier',
      pwk_completed_at: 'Pwk Completed At',
      pwk_expired_at: 'Pwk Archived At',
    }

  // if (Contract && !Contract?.c_type) {
  //   let carrier = CarrierStore.getCarriers()
  //     .filter((c) => `${c.id}` === `${Contract.carrier_id}`)
  //     .shift()
  //   Contract.c_type = Carrier && Carrier?.contractType
  // }

  if (!dates.pwk_completed_at) {
    delete dates.pwk_completed_at
    delete labels.pwk_completed_at
  }

  if (!dates.pwk_expired_at) {
    delete dates.pwk_expired_at
    delete labels.pwk_expired_at
  }

  switch (Contract?.contractType) {
    case 'LINK':
      // labels.pwk_signed_at = 'Agent Started Contract'
      if (labels.pwk_signed_at)
      	labels.pwk_signed_at = 'Agent Clicked Contract Link';
      if (!dates.pwk_sent_to_carrier_at) {
	      delete dates.pwk_sent_to_carrier_at
	      delete labels.pwk_sent_to_carrier_at
      }
      break
    case 'ACR':
      // labels.pwk_signed_at = 'Agent Started Contract'
      if (!dates.pwk_sent_to_carrier_at) {
	      delete dates.pwk_sent_to_carrier_at
	      delete labels.pwk_sent_to_carrier_at
      }
      break
    default:
      break
  }

  return (
    <table className="RenderContractDatesComponent">
      <tbody>
        {Contract &&
          Object.keys(dates).map((d) => (
            <tr key={'user-contract-dates-' + Contract.id() + '-' + d}>
              <td>{`${labels[d]}: `}</td>
              <td>
                {dates[d] ? moment(dates[d]).format('MM/DD/YYYY h:mm a') : '--'}
              </td>
              {d === 'pwk_sent_to_agent_at' && isAdminUser() && canEdit ? (
                <td rowSpan="3">
                  <MDBBtn
                    onClick={() => CarrierStore.examineAgentContract(Contract)}
                  >
                    <MDBIcon icon="edit" />
                  </MDBBtn>
                </td>
              ) : (
                <></>
              )}
            </tr>
          ))}
      </tbody>
    </table>
  )
}

export default observer(RenderContractDates)
