import React, { Component } from 'react'
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBContainer,
} from 'mdbreact'
import DashboardLayout from '../components/Admin/DashboardLayout'
import AdminApi from '../api/admin-api/admin-api'
import { withRouter } from 'react-router-dom'
import { EditorState } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import CKEditor from 'ckeditor4-react'

class AdminDashboardNewContentPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      toggleStateA: false,
      modal: false,
      templateConfig: {
        type: 'HOME',
      },
      saving: false,
      data: {
        columns: [
          {
            label: 'Page Name',
            field: 'name',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Created At',
            field: 'createdAt',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Actions',
            field: 'actions',
            sort: 'asc',
            width: 150,
          },
        ],
        rows: [],
      },
    }
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params })
    const editorState = EditorState.createEmpty()

    if (params && params.id) this.fetchQuoteData(params.id)
    // https://us-central1-usabg-3ef10.cloudfunctions.net/3ixjA5Am4JB3kVCQq14V
    this.setState({ editorState })
  }

  fetchQuoteData = async (id) => {
    this.setState({ loading: true })
    AdminApi.getContentById(id)
      .then((result) => {
        this.setState({ loading: false })
        if (result && result.data && result.data.data) {
          const content = result.data.data.content
          let templateConfig
          try {
            if (
              content &&
              /^[\],:{}\s]*$/.test(
                content
                  .replace(/\\["\\/bfnrtu]/g, '@')
                  .replace(
                    /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g,
                    ']'
                  )
                  .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
              )
            ) {
              templateConfig = JSON.parse(content)
            }
          } catch (e) {
            // console.log(e);
          }
          this.setState({
            editData: result.data.data || [],
            page_name: result.data.data.page_name,
            templateConfig: templateConfig || {
              type: 'HOME',
            },
            homeTemplate: !!templateConfig,
            content: result.data.data.content || '',
            editorContent: result.data.data.content || '',
          })
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  updatePage = async () => {
    const id = this.state.urlParams.id
    const { loading } = this.state
    if (loading) {
      return
    }
    this.setState({ loading: true, saving: true })

    const data = {
      page_name: this.state.page_name,
      content: this.state.homeTemplate
        ? JSON.stringify(this.state.templateConfig)
        : this.state.editorContent,
      author_id: 1,
      table_name: 'agency_content',
      id: id,
    }

    let form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    AdminApi.updatePageById(form_data)
      .then(() => {
        this.setState({ loading: false })

        // this.props.history.goBack();
        this.props.history.push('/content-pages')
        toast.success('Page updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false, saving: false })

        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }
  createPage = async () => {
    const { loading } = this.state
    if (loading) {
      return
    }
    const data = {
      page_name: this.state.page_name,
      content: this.state.homeTemplate
        ? JSON.stringify(this.state.templateConfig)
        : this.state.editorContent,
      author_id: 1,
      table_name: 'agency_content',
    }

    let form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true, saving: true })
    AdminApi.createPage(form_data)
      .then(() => {
        this.setState({ loading: false, saving: false })
        // this.props.history.goBack();
        this.props.history.push('/content-pages')
        toast.success('Page created successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false, saving: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }
  uploadImage = async () => {
    const { loadingImage } = this.state
    if (loadingImage) {
      return
    }
    const data = {
      page_name: this.state.page_name,
      content: this.state.homeTemplate
        ? JSON.stringify(this.state.templateConfig)
        : this.state.editorContent,
      author_id: 1,
      table_name: 'agency_content',
    }

    let form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true, saving: true })
    AdminApi.createPage(form_data)
      .then(() => {
        this.setState({ loading: false, saving: false })
        this.props.history.goBack()
        toast.success('Page created successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false, saving: false })
        console.log(error)
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  onChange = (event) => {
    /*const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value
        });*/
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  renderBelowBannerSection = () => {
    const { templateConfig } = this.state

    return (
      <>
        <div className="form-check" style={{ paddingLeft: '0' }}>
          <input
            className="form-check-input"
            type="checkbox"
            value={templateConfig.showBelowBannerSection}
            checked={templateConfig.showBelowBannerSection}
            onClick={(e) => {
              templateConfig.showBelowBannerSection =
                !templateConfig.showBelowBannerSection
              this.setState({
                templateConfig: JSON.parse(JSON.stringify(templateConfig)),
              })
            }}
            id="flexCheckDefault1"
          />
          <label className="form-check-label" htmlFor="flexCheckDefault1">
            Show Insurance Section
          </label>
        </div>
        <br />

        {templateConfig.showBelowBannerSection && (
          <>
            <label>
              Insurances (
              {templateConfig.insurances ? templateConfig.insurances.length : 0}
              ){' '}
              <MDBBtn
                color="default"
                type="button"
                size={'sm'}
                onClick={() => {
                  if (!templateConfig.insurances) {
                    templateConfig.insurances = []
                  }
                  templateConfig.insurances.push({
                    name: '',
                    description: '',
                    image: '',
                  })
                  this.setState({ templateConfig: templateConfig })
                }}
              >
                Add New
              </MDBBtn>
            </label>

            {templateConfig.insurances &&
              templateConfig.insurances.map((insurance, i) => {
                return (
                  <MDBCard className={'mb-1'}>
                    <MDBCardBody>
                      <div className="form-row">
                        <MDBBtn
                          color="danger"
                          type="button"
                          style={{
                            position: 'absolute',
                            right: '0',
                            top: '5px',
                          }}
                          size={'sm'}
                          onClick={() => {
                            templateConfig.insurances.splice(i, 1)
                            this.setState({ templateConfig: templateConfig })
                          }}
                        >
                          Remove
                        </MDBBtn>
                        <label>Name</label>
                        <textarea
                          required
                          value={insurance.name}
                          onChange={(e) => {
                            insurance.name = e.target.value
                            this.setState({ templateConfig: templateConfig })
                          }}
                          className="form-control"
                        ></textarea>
                      </div>
                      <br />
                      <div className="form-row">
                        <label>Icon</label>
                        <textarea
                          required
                          value={insurance.icon}
                          onChange={(e) => {
                            insurance.icon = e.target.value
                            this.setState({ templateConfig: templateConfig })
                          }}
                          className="form-control"
                        ></textarea>
                      </div>
                      <br />
                      <div className="form-row">
                        <label>Image</label>
                        <br />
                        {insurance.image ? (
                          <>
                            <img
                              src={insurance.image}
                              width="398"
                              height="311"
                              alt=""
                              className="img-responsive"
                            />
                            <div>
                              <MDBBtn
                                color="danger"
                                type="button"
                                size={'sm'}
                                onClick={() => {
                                  insurance.image = null
                                  this.setState({
                                    templateConfig: templateConfig,
                                  })
                                }}
                              >
                                Remove
                              </MDBBtn>
                            </div>
                          </>
                        ) : (
                          <input
                            type="file"
                            multiple={false}
                            onChange={(e) => {
                              const file = e.target.files[0]
                              let form_data = new FormData()
                              form_data.append('file', file)
                              insurance.loadingImage = true
                              this.setState({ templateConfig: templateConfig })
                              AdminApi.uploadImage(form_data)
                                .then((result) => {
                                  if (
                                    result &&
                                    result.data &&
                                    result.data.data &&
                                    result.data.data.file
                                  ) {
                                    insurance.image = result.data.data.file
                                  }
                                  insurance.loadingImage = true
                                  setTimeout(() => {
                                    this.setState({
                                      templateConfig: templateConfig,
                                    })
                                  }, 2000)
                                })
                                .catch((error) => {
                                  insurance.loadingImage = true
                                  this.setState({
                                    templateConfig: templateConfig,
                                  })
                                  toast.error('Something went wrong!', {
                                    position: toast.POSITION.TOP_RIGHT,
                                  })
                                })
                            }}
                          />
                        )}
                      </div>
                      <br />
                      <div className="form-row">
                        <label>Description</label>
                        <textarea
                          required
                          value={insurance.description}
                          onChange={(e) => {
                            insurance.description = e.target.value
                            this.setState({ templateConfig: templateConfig })
                          }}
                          className="form-control"
                        ></textarea>
                      </div>
                      <br />

                      <div className="form-row">
                        <label># Tag</label>
                        <textarea
                          required
                          value={insurance.hashTag}
                          onChange={(e) => {
                            insurance.hashTag = e.target.value
                            this.setState({ templateConfig: templateConfig })
                          }}
                          className="form-control"
                        ></textarea>
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                )
              })}
          </>
        )}
      </>
    )
  }
  renderProjectSection = () => {
    const { templateConfig } = this.state

    return (
      <>
        <div className="form-check" style={{ paddingLeft: '0' }}>
          <input
            className="form-check-input"
            type="checkbox"
            value={templateConfig.showProjectSection}
            checked={templateConfig.showProjectSection}
            onClick={(e) => {
              templateConfig.showProjectSection =
                !templateConfig.showProjectSection
              if (!templateConfig.projects) {
                templateConfig.projects = []
              }
              this.setState({
                templateConfig: JSON.parse(JSON.stringify(templateConfig)),
              })
            }}
            id="flexCheckDefault6"
          />
          <label className="form-check-label" htmlFor="flexCheckDefault6">
            Show Project Section
          </label>
        </div>
        <br />

        {templateConfig.showProjectSection && (
          <>
            <label>
              Projects (
              {templateConfig.projects ? templateConfig.projects.length : 0}){' '}
              <MDBBtn
                color="default"
                type="button"
                size={'sm'}
                onClick={() => {
                  if (!templateConfig.projects) {
                    templateConfig.projects = []
                  }
                  templateConfig.projects.push({ image: '', link: '' })
                  this.setState({ templateConfig: templateConfig })
                }}
              >
                Add New
              </MDBBtn>
            </label>

            {templateConfig.projects &&
              templateConfig.projects.map((project, i) => {
                return (
                  <MDBCard className={'mb-1'}>
                    <MDBCardBody>
                      <div className="form-row">
                        <MDBBtn
                          color="danger"
                          type="button"
                          style={{
                            position: 'absolute',
                            right: '0',
                            top: '5px',
                          }}
                          size={'sm'}
                          onClick={() => {
                            templateConfig.projects.splice(i, 1)
                            this.setState({ templateConfig: templateConfig })
                          }}
                        >
                          Remove
                        </MDBBtn>
                        <label>Link</label>
                        <textarea
                          required
                          value={project.link}
                          onChange={(e) => {
                            project.link = e.target.value
                            this.setState({ templateConfig: templateConfig })
                          }}
                          className="form-control"
                        ></textarea>
                      </div>
                      <br />
                      <div className="form-row">
                        <label>Image</label>
                        <br />
                        {project.image ? (
                          <>
                            <img
                              src={project.image}
                              width="398"
                              height="311"
                              alt=""
                              className="img-responsive"
                            />
                            <div>
                              <MDBBtn
                                color="danger"
                                type="button"
                                size={'sm'}
                                onClick={() => {
                                  project.image = null
                                  this.setState({
                                    templateConfig: templateConfig,
                                  })
                                }}
                              >
                                Remove
                              </MDBBtn>
                            </div>
                          </>
                        ) : (
                          <input
                            type="file"
                            multiple={false}
                            onChange={(e) => {
                              const file = e.target.files[0]
                              let form_data = new FormData()
                              form_data.append('file', file)
                              project.loadingImage = true
                              this.setState({ templateConfig: templateConfig })
                              AdminApi.uploadImage(form_data)
                                .then((result) => {
                                  if (
                                    result &&
                                    result.data &&
                                    result.data.data &&
                                    result.data.data.file
                                  ) {
                                    project.image = result.data.data.file
                                  }
                                  project.loadingImage = true
                                  setTimeout(() => {
                                    this.setState({
                                      templateConfig: templateConfig,
                                    })
                                  }, 2000)
                                })
                                .catch((error) => {
                                  project.loadingImage = true
                                  this.setState({
                                    templateConfig: templateConfig,
                                  })
                                  toast.error('Something went wrong!', {
                                    position: toast.POSITION.TOP_RIGHT,
                                  })
                                })
                            }}
                          />
                        )}
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                )
              })}
          </>
        )}
      </>
    )
  }
  renderMissionStatement = () => {
    const { templateConfig } = this.state

    return (
      <>
        <div className="form-check" style={{ paddingLeft: '0' }}>
          <input
            className="form-check-input"
            type="checkbox"
            value={templateConfig.showMissionStatement}
            checked={templateConfig.showMissionStatement}
            onClick={(e) => {
              templateConfig.showMissionStatement =
                !templateConfig.showMissionStatement
              if (!templateConfig.missionStatementConfig) {
                templateConfig.missionStatementConfig = {}
              }
              this.setState({
                templateConfig: JSON.parse(JSON.stringify(templateConfig)),
              })
            }}
            id="flexCheckDefault4"
          />
          <label className="form-check-label" htmlFor="flexCheckDefault4">
            Show Mission Statement Section
          </label>
        </div>
        <br />

        {templateConfig.showMissionStatement && (
          <>
            <br />
            <div className="form-row">
              <label>Title</label>
              <textarea
                required
                value={templateConfig.missionStatementConfig.title}
                onChange={(e) => {
                  templateConfig.missionStatementConfig.title = e.target.value
                  this.setState({ templateConfig: templateConfig })
                }}
                className="form-control"
              ></textarea>
            </div>
            <br />
            <div className="form-row">
              <label>Description</label>
              <textarea
                required
                value={templateConfig.missionStatementConfig.description}
                onChange={(e) => {
                  templateConfig.missionStatementConfig.description =
                    e.target.value
                  this.setState({ templateConfig: templateConfig })
                }}
                className="form-control"
              ></textarea>
            </div>
          </>
        )}
      </>
    )
  }
  renderCounterSection = () => {
    const { templateConfig } = this.state

    return (
      <>
        <div className="form-check" style={{ paddingLeft: '0' }}>
          <input
            className="form-check-input"
            type="checkbox"
            value={templateConfig.showCounterSection}
            checked={templateConfig.showCounterSection}
            onClick={(e) => {
              templateConfig.showCounterSection =
                !templateConfig.showCounterSection
              if (!templateConfig.counterConfig) {
                templateConfig.counterConfig = []
              }
              this.setState({
                templateConfig: JSON.parse(JSON.stringify(templateConfig)),
              })
            }}
            id="flexCheckDefault7"
          />
          <label className="form-check-label" htmlFor="flexCheckDefault7">
            Show Counter Config Section
          </label>
        </div>
        <br />

        {templateConfig.showCounterSection && (
          <>
            <label>
              Counter (
              {templateConfig.counterConfig
                ? templateConfig.counterConfig.length
                : 0}
              ){' '}
              <MDBBtn
                color="default"
                type="button"
                size={'sm'}
                onClick={() => {
                  if (!templateConfig.counterConfig) {
                    templateConfig.counterConfig = []
                  }
                  templateConfig.counterConfig.push({
                    count: '',
                    name: '',
                    icon: '',
                  })
                  this.setState({ templateConfig: templateConfig })
                }}
              >
                Add New
              </MDBBtn>
            </label>
            <br />
            {templateConfig.counterConfig && (
              <>
                {templateConfig.counterConfig.map((m, i) => {
                  return (
                    <>
                      <div className="form-row">
                        <MDBBtn
                          color="danger"
                          type="button"
                          style={{
                            position: 'absolute',
                            right: '0',
                            top: '5px',
                          }}
                          size={'sm'}
                          onClick={() => {
                            templateConfig.counterConfig.splice(i, 1)
                            this.setState({ templateConfig: templateConfig })
                          }}
                        >
                          Remove
                        </MDBBtn>
                        <label>Count</label>
                        <textarea
                          required
                          value={m.count}
                          onChange={(e) => {
                            m.count = e.target.value
                            this.setState({ templateConfig: templateConfig })
                          }}
                          className="form-control"
                        ></textarea>
                      </div>
                      <br />
                      <div className="form-row">
                        <label>Name</label>
                        <textarea
                          required
                          value={m.name}
                          onChange={(e) => {
                            m.name = e.target.value
                            this.setState({ templateConfig: templateConfig })
                          }}
                          className="form-control"
                        ></textarea>
                      </div>
                      <br />
                      <div className="form-row">
                        <label>Icon</label>
                        <textarea
                          required
                          value={m.icon}
                          onChange={(e) => {
                            m.icon = e.target.value
                            this.setState({ templateConfig: templateConfig })
                          }}
                          className="form-control"
                        ></textarea>
                      </div>
                    </>
                  )
                })}
              </>
            )}
          </>
        )}
      </>
    )
  }
  renderAboutSection = () => {
    const { templateConfig } = this.state

    return (
      <>
        <div className="form-check" style={{ paddingLeft: '0' }}>
          <input
            className="form-check-input"
            type="checkbox"
            value={templateConfig.showAboutSection}
            checked={templateConfig.showAboutSection}
            onClick={(e) => {
              templateConfig.showAboutSection = !templateConfig.showAboutSection
              if (!templateConfig.aboutConfig) {
                templateConfig.aboutConfig = {
                  description: '',
                  aboutPoints: [],
                }
              }
              this.setState({
                templateConfig: JSON.parse(JSON.stringify(templateConfig)),
              })
            }}
            id="flexCheckDefault2"
          />
          <label className="form-check-label" htmlFor="flexCheckDefault2">
            Show About Section
          </label>
        </div>
        {templateConfig.showAboutSection && (
          <>
            <br />
            <div className="form-row">
              <label>Name</label>
              <textarea
                required
                value={templateConfig.aboutConfig.name}
                onChange={(e) => {
                  templateConfig.aboutConfig.name = e.target.value
                  this.setState({ templateConfig: templateConfig })
                }}
                className="form-control"
              ></textarea>
            </div>
            <br />
            <div className="form-row">
              <label>Description</label>
              <textarea
                required
                value={templateConfig.aboutConfig.description}
                onChange={(e) => {
                  templateConfig.aboutConfig.description = e.target.value
                  this.setState({ templateConfig: templateConfig })
                }}
                className="form-control"
              ></textarea>
            </div>
            <br />
            <div className="form-row">
              <label>Youtube Url</label>
              <textarea
                required
                value={templateConfig.aboutConfig.youtubeUrl}
                onChange={(e) => {
                  templateConfig.aboutConfig.youtubeUrl = e.target.value
                  this.setState({ templateConfig: templateConfig })
                }}
                className="form-control"
              ></textarea>
            </div>
            <br />

            <div className="form-row">
              <label># Tag</label>
              <textarea
                required
                value={templateConfig.aboutConfig.hashTag}
                onChange={(e) => {
                  templateConfig.aboutConfig.hashTag = e.target.value
                  this.setState({ templateConfig: templateConfig })
                }}
                className="form-control"
              ></textarea>
            </div>
            <br />

            <label>
              About statements (
              {templateConfig.aboutConfig &&
              templateConfig.aboutConfig.aboutPoints
                ? templateConfig.aboutConfig.aboutPoints.length
                : 0}
              ){' '}
              <MDBBtn
                color="default"
                type="button"
                size={'sm'}
                onClick={() => {
                  if (!templateConfig.aboutConfig.aboutPoints) {
                    templateConfig.aboutConfig.aboutPoints = []
                  }
                  templateConfig.aboutConfig.aboutPoints.push({ name: '' })
                  this.setState({ templateConfig: templateConfig })
                }}
              >
                Add New
              </MDBBtn>
            </label>
            <br />

            {templateConfig.aboutConfig.aboutPoints &&
              templateConfig.aboutConfig.aboutPoints.map((aboutPoint, i) => {
                return (
                  <MDBCard className={'mb-1'}>
                    <MDBCardBody>
                      <div className="form-row">
                        <MDBBtn
                          color="danger"
                          type="button"
                          style={{
                            position: 'absolute',
                            right: '0',
                            top: '5px',
                          }}
                          size={'sm'}
                          onClick={() => {
                            aboutPoint.splice(i, 1)
                            this.setState({ templateConfig: templateConfig })
                          }}
                        >
                          Remove
                        </MDBBtn>
                        <label>Name</label>
                        <textarea
                          required
                          value={aboutPoint.name}
                          onChange={(e) => {
                            aboutPoint.name = e.target.value
                            this.setState({ templateConfig: templateConfig })
                          }}
                          className="form-control"
                        ></textarea>
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                )
              })}
          </>
        )}
      </>
    )
  }
  renderHomeTemplateForm = () => {
    return (
      <>
        {this.renderBelowBannerSection()}
        <hr />
        {this.renderAboutSection()}
        <hr />
        {this.renderCounterSection()}
        <hr />
        {this.renderMissionStatement()}
        <hr />
        {this.renderProjectSection()}
      </>
    )
  }

  render() {
    toast.configure()
    const { urlParams, page_name, saving } = this.state

    const goBack = () => {
      this.props.history.goBack()
    }
    return (
      <>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              <h2>
                {!!(urlParams && urlParams.id) ? 'Edit Page' : 'Add Page'}
                <div className="float-right">
                  <MDBBtn color="danger" rounded size="sm" onClick={goBack}>
                    Cancel
                  </MDBBtn>
                </div>
              </h2>
              {!!(urlParams && urlParams.id) ? (
                <>
                  <MDBBreadcrumb>
                    <MDBBreadcrumbItem>
                      <a href="/content-pages">Content</a>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>
                      Editing "{page_name}"
                    </MDBBreadcrumbItem>
                  </MDBBreadcrumb>
                  <div className="form-check" style={{ paddingLeft: '0' }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={this.state.homeTemplate}
                      checked={this.state.homeTemplate}
                      onClick={(e) => {
                        this.setState({
                          homeTemplate: !this.state.homeTemplate,
                        })
                      }}
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      User Home Template
                    </label>
                  </div>
                </>
              ) : (
                <>
                  <div className="form-check" style={{ paddingLeft: '0' }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={this.state.homeTemplate}
                      checked={this.state.homeTemplate}
                      onClick={(e) => {
                        this.setState({
                          homeTemplate: !this.state.homeTemplate,
                        })
                      }}
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      User Home Template
                    </label>
                  </div>
                  <br />
                  <div className="form-row">
                    <label>Page Name</label>
                    <textarea
                      required
                      onChange={(e) => {
                        this.setState({ page_name: e.target.value })
                      }}
                      className="form-control"
                      value={page_name}
                    ></textarea>
                  </div>
                </>
              )}
              <br />
              <br />
              {this.state.homeTemplate ? (
                this.renderHomeTemplateForm()
              ) : (
                <CKEditor
                  config={{
                    allowedContent: true,
                  }}
                  onChange={({ editor }) => {
                    const data = editor.getData()
                    this.setState({ editorContent: data })
                  }}
                  data={this.state.editorContent}
                />
              )}

              <div className="mt-3">
                <MDBBtn
                  color="unique"
                  type="button"
                  onClick={
                    this.state.urlParams && this.state.urlParams.id
                      ? this.updatePage
                      : this.createPage
                  }
                >
                  {saving ? 'Saving...' : 'Save'}
                </MDBBtn>
              </div>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </>
    )
  }
}

export default withRouter(AdminDashboardNewContentPage)
