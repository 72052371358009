import {makeAutoObservable} from "mobx";
import AdobeSignApi from './../../../shared/services/AdobeSignApi';
import env from './../../../environments/environment';
import getQueryProperty from './../helpers/getQueryProperty.function';
import {BehaviorSubject} from 'rxjs';

const getRedirectUrl = () => 
{
	if (env && env?.env === "local")
		return "https://localhost:3000/oauth/adobe-sign";
	if (env && env?.env === "beta")
		return "https://beta.usabg.com/oauth/adobe-sign"
	return "https://usabg.com/oauth/adobe-sign"
}

const ADOBE_SIGN_SCOPES = ['user_login:self','agreement_read:account','agreement_write:account','agreement_send:account','library_read:account','library_write:account'];

class AdobeSignOauthStore {
  constructor() {
    makeAutoObservable(this)
  }

	authCode = {code: null, state: null};
	authError = {error: null, error_description: null};

	authSubject = new BehaviorSubject(false);
	docsSubject = new BehaviorSubject([]);

	setAuthData = (params) =>
	{
		this.authCode.code = getQueryProperty('code', params);
		this.authCode.state = getQueryProperty('state', params);
		this.authError.error = getQueryProperty('error', params);
		this.authError.error_description = getQueryProperty('error_description', params);		
	}

	getAuthDataError = () => 
	{
		if (this.authError?.error && this.authError?.error_description)
			return this.authError?.error_description
		return false;
	}
 
  getSigninUrl = () => 
  {
		// #acctState = 'S6YQD7KDA556DIV6NAU4ELTGSIV26ZNMXDSF7WIEEP0ZLQCLDQ89OYG78C3K9SROC8DXCGRVSGKU1IT1';
		// // This value will be returned to the client as a parameter at the end of the authorization process. While not required, use of the state parameter is highly recommended to protect against CSRF, as described in the RFC.
		// // Store this value temporarily in session storage/local storage.
		return `https://secure.na4.adobesign.com/public/oauth/v2?redirect_uri=${getRedirectUrl()}&response_type=code&client_id=${env.integrations.adobe_sign.client_id}&scope=${encodeURIComponent(ADOBE_SIGN_SCOPES.join(' '))}`; // &state=${this.#acctState}
		
		// Invalid Response
		// [[ UN-ACCOUNTED FOR !! ]]
		// http://localhost:3000/oauth/adobe-sign?error_description=Scopes+not+enabled+for+this+application&error=access_denied
		// 
		// https://beta.usabg.com/oauth/adobe-sign?error_description=CUSTOMER+applications+can+only+be+used+by+someone+in+the+same+account&error=access_denied
  }

  reset = () => 
  {
		this.authCode.code = null;
		this.authCode.state = null;
		this.authError.error = null;
		this.authError.error_description = null;
  }

  authorize = async (codeData) => 
  {
		let response;
		try {
			response = await AdobeSignApi.authorize(this.authCode.code, getRedirectUrl())
			this.reset();
		}
		catch (ex) {
			this.reset();
			return `${ex}`;
		}

		if (response && response?.status === 'success')
			return true;

		return 'An unknown error occurred while attemping to authorize Adobe Sign.  Please contact support.';
  }

  getAuthChange = (bypass) => 
  {
  	if (bypass === true)
  		return this.authSubject;

  	return this.authSubject;
  }

  getDocuments = (bypass) =>
  {
  	if (bypass === true)
  		return this.docsSubject;

  	AdobeSignApi
  		.getDocuments()
  		.then(docs => this.docsSubject.next(docs))
  		.catch(err => this.docsSubject.next(`An unknown error occurred while attemping to fetch Adobe Sign Documents. ${err}`));

		return this.docsSubject;
  }
}

export default new AdobeSignOauthStore()

