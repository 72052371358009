import React from 'react'
import { toast } from 'react-toastify'
import AdminApi from './../../../../api/admin-api/admin-api'
import SubmitSalesService from './../../shared/SubmitSales.service'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import { MDBBtn, MDBSpinner, MDBCard, MDBRow, MDBCardBody } from 'mdbreact'

import './SalesGuidelines.scss'

class SalesGuidelines extends React.Component {
  state = {
    fetching: true,
    guidelines: null,
    submitting: false,
    hasAccepted: false,
  }

  /*
   * Component Accessor Methods ----------------------
   *
   */

  /*
   * Component Public Methods ------------------------
   *
   */

  /*
   * Component Private Methods -----------------------
   *
   */
  #close = () => this.props.open('form')

  #fetchPageData = async (id) => {
    AdminApi.getContentById(id)
      .then((result) => {
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.content
        )
          this.setState({
            guidelines: result.data.data.content || '',
            fetching: false,
          })
      })
      .catch((error) =>
        toast.error(error.message, { position: toast.POSITION.BOTTOM_RIGHT })
      )
  }

  #checkUserHasAcceptedGuidelines = async () => {
    let accepted = await SubmitSalesService.hasUserAcceptedGuidelines(
      UserProfileService.getUserId()
    )
    this.props.hasAccepted(accepted)

    // dont show acceptance guidelines if the
    // sale is being viewed by an admin user.
    if (UserProfileService.isAssumed()) return this.props.open('form')

    if (this.state.submitting === true) this.setState({ submitting: false })

    this.setState({ hasAccepted: !!accepted })
    return this.props.open(accepted ? 'form' : 'guidelines')
  }

  #acceptGuidelines = () => {
    this.setState({ submitting: true })
    SubmitSalesService.acceptGuidelines()
      .catch((error) =>
        toast.error(error.message, { position: toast.POSITION.BOTTOM_RIGHT })
      )
      .finally(() => this.#checkUserHasAcceptedGuidelines())
  }

  #renderAcceptBtn = () => {
    if (!this.state.fetching && this.state.guidelines) {
      if (UserProfileService.isAssumed())
        return (
          <MDBBtn
            disabled={this.state.submitting}
            color="primary"
            className="btn-block"
            onClick={() => this.#close()}
            style={{ marginBottom: '1rem' }}
          >
            Close Sales Guidelines
          </MDBBtn>
        )
      return (
        <MDBBtn
          disabled={this.state.submitting}
          color="primary"
          className="btn-block"
          onClick={() =>
            this.state.hasAccepted ? this.#close() : this.#acceptGuidelines()
          }
          style={{ marginBottom: '1rem' }}
        >
          {this.state.hasAccepted
            ? 'Close Sales Guidelines'
            : this.state.submitting
            ? 'Accepting ...'
            : 'Accept Sales Guidelines'}
        </MDBBtn>
      )
    }
  }

  /*
   * Component Event Methods -------------------------
   *
   */
  componentDidMount() {
    this.#checkUserHasAcceptedGuidelines()
    this.#fetchPageData(31)
  }

  componentWillUnmount() {}

  render() {
    toast.configure()

    const { fetching, guidelines } = this.state

    return (
      <div className="SalesGuidelines">
        <MDBCard>
          <div className="header pt-3 indigo lighten-1">
            <MDBRow className="d-flex justify-content-start">
              <h3 className="white-text mt-3 mb-4 pb-1 mx-5">
                USABG Sales Guidelines
              </h3>
            </MDBRow>
          </div>
          <MDBCardBody className="mx-4 mt-4">
            {fetching ? (
              <div className="loadingOverlay">
                <h4 className={'loader-text p4'}>
                  <MDBSpinner />
                </h4>
              </div>
            ) : (
              ''
            )}
            <div>
              {!fetching && guidelines ? (
                <div dangerouslySetInnerHTML={{ __html: guidelines }}></div>
              ) : (
                <></>
              )}
            </div>
            {this.#renderAcceptBtn()}
          </MDBCardBody>
        </MDBCard>
      </div>
    )
  }
}

export default SalesGuidelines
