import React from 'react'
import { observer } from 'mobx-react-lite'
import AgentPackages from './../../../../components/shared/AgentPackages.component'
import Store from './../../shared/Enrollment.store'
import {
  MDBContainer,
	MDBBtn,
	MDBCol,
	MDBCollapse,
	MDBRow
} from 'mdbreact'

import './IntakeFormPackageSelection.scss'

const IntakeFormPackageSelection = ({onComplete}) =>
{
	const selectPackages = (usertypeId) => 
	{
		Store.usertypeId = usertypeId;
		onComplete(parseInt(usertypeId) === 111 ? true : 'IntakeFormAcceptance')
	}

  return (
    <MDBContainer fluid id='IntakeFormPackageSelectionComponent' className='intake-form'>
			<MDBRow>
	      <MDBCol size="12" className="stage-heading">
	        <h3>
	          <strong>Agent Package Selection</strong>
	        </h3>
	      </MDBCol>
			</MDBRow>
			<MDBRow>
      	<MDBCol size='12' style={{padding:'0'}}>
          <AgentPackages 
          		canEnroll={true} 
            	pkgSelectors={
            		{
            			111: (<MDBCollapse isOpen={Store.isAuthenticated()}><MDBBtn
                    className="pkg-btn mt-2 mb-2"
                    onClick={() => selectPackages(111, [])}
                    disabled={Store.isCompleting}
                  >
                  	{
                  		Store.isCompleting
                  			? <><i className='fa fa-spin fa-spinner'></i></>
                  			: <>Become an<br />Affiliate Agent</>
                  	}
                  </MDBBtn></MDBCollapse>),
            			91: (<MDBCollapse isOpen={Store.isAuthenticated()}><MDBBtn
                    className="pkg-btn mt-2 mb-2"
                    onClick={() => selectPackages(91, [])}
                    disabled={Store.isCompleting}
                  >
                  	{
                  		Store.isCompleting
                  			? <><i className='fa fa-spin fa-spinner'></i></>
                  			: <>Become a<br />Signature Agent</>
                  	}
                  </MDBBtn></MDBCollapse>)
            		}
            	}
          	/>
        </MDBCol>
			</MDBRow>
    </MDBContainer>
  )
}

export default observer(IntakeFormPackageSelection)
