import React, {useEffect, useState, useCallback} from 'react';
import {observer} from "mobx-react-lite";
import AdobeSignOauthStore from './../../stores/AdobeSignOauth.store';
import {MDBCard, MDBCardHeader, MDBCardBody, MDBCollapse, MDBAlert} from "mdbreact";

const clearQueryString = () => 
{
	if (window.history.pushState) {
    var path = window.location.protocol + "//" + window.location.host + window.location.pathname;
 		window.history.replaceState({path},'',path);
	}
}

const AdobeSignAuthorizaton = (props) => 
{
	const [outcome, setOutcome] = useState(null);

	const getQueryString = () => 
		(props.props && props.props?.location.search) || "";

	const authorize = useCallback(() => {
		clearQueryString();

		AdobeSignOauthStore
				.authorize()
				.then(outcome => setOutcome(outcome))
	}, []);

	const checkShouldAuthorize = (params) => {
		if (!params)
			return false;

		AdobeSignOauthStore.setAuthData(params);

		if (AdobeSignOauthStore.getAuthDataError()) {
			setOutcome(AdobeSignOauthStore.getAuthDataError());
			return false;
		}

		return true;
	}

	const shouldAuth = checkShouldAuthorize(getQueryString());

  useEffect(() => {
  	if (shouldAuth)
	  	authorize();
  }, [shouldAuth, authorize]);

	return <>
		<MDBCard>
			<MDBCardHeader>
				Adobe Sign Integration
			</MDBCardHeader>
			<MDBCardBody>
	      <a href={AdobeSignOauthStore.getSigninUrl()}>Sign In to AdobeSign</a>
	      <MDBCollapse isOpen={!!outcome}>
	        <MDBAlert color={outcome === true ? 'success' : 'danger'}>
	        	{outcome === true ? 'Success.  Adobe Sign has been authorized.' : outcome}
	        </MDBAlert>
	      </MDBCollapse>
			</MDBCardBody>
		</MDBCard>
	</>	
}

export default observer(AdobeSignAuthorizaton);