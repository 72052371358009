import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
} from 'mdbreact'
import {
  checkValidity,
  getErrorMessage,
  getSuccessMessage,
  isDirty,
} from './../shared/validator.function'
import './UISelect.component.scss'

const UISelect = ({
  className,
  disabled,
  id,
  label,
  name,
  size,
  type,
  getValue,
  onChange,
  required,
  hint,
  value,
  options,
  onValidityChange,
  rules,
  showValidity,
  search,
  dropdown,
}) => {
  rules = rules && typeof rules === 'object' ? rules : {}
  if (required) rules.required = true

  const isValid =
    !rules.required ||
    (Array.isArray(options) ? options : []).filter(
      (o) => `${o.value}` === `${value}`
    ).length > 0

  const [uiState, setUiState] = useState({
    isDirty: false,
    isPristine: true,
    isTouched: false,
    validity: { isValid, errors: isValid ? [] : ['required'] },
  })
  const [opts, setOpts] = useState()
  const [changedOptions, setChangedOptions] = useState(false)

  const _onChange = onChange,
    _getValue = getValue
  getValue = async (evt) => {
    const origEvt = evt,
      v = evt && evt.length > 0 ? evt[0] : undefined
    if (value === v) return

    evt = { target: { name, value: v } }

    setUiState((prevState) =>
      !uiState.isDirty && isDirty(value, evt.target.value)
        ? { ...prevState, isDirty: true, isPristine: false }
        : { ...prevState, isDirty: false }
    )
    ;(typeof _getValue === 'function' ? _getValue : (evt) => {})(origEvt)
    ;(typeof _onChange === 'function' ? _onChange : (evt) => {})(evt)

    checkValidity(evt.target.value, rules).then((validity) => {
      setUiState((prevState) => ({ ...setUiState, validity }))
      ;(typeof onValidityChange === 'function'
        ? onValidityChange
        : (validity) => {})(validity)
    })
  }

  const inputProps = {
    className,
    disabled,
    id,
    label,
    name,
    size,
    type,
    getValue,
    required,
    hint,
  }
  const emptyInputProps = {
    className,
    disabled,
    id,
    label,
    name,
    size,
    type,
  }
  Object.keys(inputProps)
    .filter((key) => typeof inputProps[key] === 'undefined')
    .forEach((key) => delete inputProps[key])

  if (inputProps?.label && inputProps?.required && typeof label === 'string') {
    inputProps.label = `${inputProps.label.replace(/[\ \*]+$/, ' ')} *`
    // console.log('l ',inputProps.label);
  }

  useEffect(() => {
    checkValidity(value, rules).then((validity) => {
      setUiState((prevState) => ({ ...setUiState, validity }))
      ;(typeof onValidityChange === 'function'
        ? onValidityChange
        : (validity) => {})(validity)
    })
  }, [value])
  useEffect(() => {
    setOpts([])
    setChangedOptions(!changedOptions)
  }, [value, options])
  useEffect(() => {
    setOpts(options)
  }, [changedOptions])

  // console.log(opts && opts.length > 0 ? inputProps : emptyInputProps);

  return (
    <div
      className={[
        'ui-input',
        'ui-input-select',
        uiState.validity.isValid ? 'is-valid' : 'is-invalid',
        uiState.isDirty ? 'is-dirty' : '',
        uiState.isPristine ? 'is-pristine' : '',
        uiState.isTouched ? 'is-touched' : '',
        disabled ? 'is-disabled' : '',
        showValidity ? 'show-validity' : '',
        dropdown ? 'ui-input-dropdown' : '',
      ].join(' ')}
    >
      {opts && opts.length > 0 ? (
        <div>
          <MDBSelect {...inputProps}>
            <MDBSelectInput selected={value} />
            <MDBSelectOptions search={search}>
              {opts.map((option, idx) => {
                return (
                  <MDBSelectOption
                    key={idx}
                    value={option.value}
                    checked={option.checked}
                  >
                    {option.text}
                  </MDBSelectOption>
                )
              })}
            </MDBSelectOptions>
          </MDBSelect>
        </div>
      ) : (
        <MDBSelect {...emptyInputProps}>
          <MDBSelectInput />
          <MDBSelectOptions />
        </MDBSelect>
      )}
      <div className="ui-input-validation">
        <span className="success-msg">
          {showValidity ? getSuccessMessage() : ''}
        </span>
        <span className="error-msg">
          {showValidity ? getErrorMessage(uiState.validity.errors) : ''}
        </span>
      </div>
    </div>
  )
}

export default observer(UISelect)
