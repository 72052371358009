import React, { Component, Fragment } from 'react'
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBSwitch,
  MDBSpinner,
} from 'mdbreact'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'

class AddUsertype extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      name: '',
      displayname: '',
      is_agent: 0,
      is_manager: 0,
      is_staff: 0,
      is_pending: 0,
      has_mailbox: 0,
      has_downline: 0,
      can_recruit: 0,
      incentives: 0,
      subsite: 0,
      admin_area: 0,
      agency_info: 0,
      create_user: 0,
      usertypes: 0,
      commissions: 0,
      divisions: 0,
      carriers: 0,
      contracting: 0,
      products: 0,
      reports: 0,
      submit_av: 0,
      resources: 0,
      training: 0,
      contests: 0,
      terms: 0,
      calendars: 0,
      emails: 0,
      can_search: 0,
      formError: false,
      errorMessage: '',
    }
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params })

    if (params && params.id) {
      this.fetchUserTypesData(params.id)
    }

    // this.setState({editorState});
  }

  //Change event for input fields
  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSwitchChange = (nr) => () => {
    let switchNumber = `${nr}`
    this.setState({
      [switchNumber]: this.state[switchNumber] ? 0 : 1,
    })
  }

  validate = async () => {
    const { name, displayname } = this.state

    if (!(name && name.trim())) {
      this.setState({ formError: true, errorMessage: 'Name Required!' })
      return
    }

    if (!(displayname && displayname.trim())) {
      this.setState({ formError: true, errorMessage: 'Display Name Required!' })
      return
    }

    this.setState({ formError: false, errorMessage: '' })
  }

  //create userTypes
  createUserType = async () => {
    await this.validate()
    const {
      loading,
      name,
      displayname,
      is_agent,
      is_manager,
      is_staff,
      is_pending,
      has_mailbox,
      has_downline,
      can_recruit,
      incentives,
      subsite,
      admin_area,
      agency_info,
      create_user,
      usertypes,
      commissions,
      divisions,
      carriers,
      contracting,
      products,
      reports,
      submit_av,
      resources,
      training,
      contests,
      terms,
      calendars,
      emails,
      can_search,
      formError,
      errorMessage,
    } = this.state
    if (loading) {
      return
    }

    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }

    const data = {
      name: name,
      displayname: displayname,
      is_agent: is_agent,
      is_manager: is_manager,
      is_staff: is_staff,
      is_pending: is_pending,
      has_mailbox: has_mailbox,
      has_downline: has_downline,
      can_recruit: can_recruit,
      incentives: incentives,
      subsite: subsite,
      admin_area: admin_area,
      agency_info: agency_info,
      create_user: create_user,
      usertypes: usertypes,
      commissions: commissions,
      divisions: divisions,
      carriers: carriers,
      contracting: contracting,
      products: products,
      reports: reports,
      submit_av: submit_av,
      resources: resources,
      training: training,
      contests: contests,
      terms: terms,
      calendars: calendars,
      emails: emails,
      can_search: can_search,
      table_name: 'usertypes',
    }

    const form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })

    AdminApi.createUserType(form_data)
      .then((result) => {
        this.setState({ loading: false })
        this.props.history.push('/admin-usertypes')
        toast.success('UserType created successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchUserTypesData = async (id) => {
    this.setState({ loading: true })
    AdminApi.getUserTypeById(id)
      .then((result) => {
        this.setState({ loading: false })
        if (result && result.data && result.data.data) {
          const content = result.data.data
          this.setState({
            name: content.name,
            displayname: content.displayname,
            is_agent: content.is_agent,
            is_manager: content.is_manager,
            is_staff: content.is_staff,
            is_pending: content.is_pending,
            has_mailbox: content.has_mailbox,
            has_downline: content.has_downline,
            can_recruit: content.can_recruit,
            incentives: content.incentives,
            subsite: content.subsite,
            admin_area: content.admin_area,
            agency_info: content.agency_info,
            create_user: content.create_user,
            usertypes: content.usertypes,
            commissions: content.commissions,
            divisions: content.divisions,
            carriers: content.carriers,
            contracting: content.contracting,
            products: content.products,
            reports: content.reports,
            submit_av: content.submit_av,
            resources: content.resources,
            training: content.training,
            contests: content.contests,
            terms: content.terms,
            calendars: content.calendars,
            emails: content.emails,
            can_search: content.can_search,
          })
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  updateUserType = async () => {
    await this.validate()
    const id = this.state.urlParams.id
    const {
      loading,
      name,
      displayname,
      is_agent,
      is_manager,
      is_staff,
      is_pending,
      has_mailbox,
      has_downline,
      can_recruit,
      incentives,
      subsite,
      admin_area,
      agency_info,
      create_user,
      usertypes,
      commissions,
      divisions,
      carriers,
      contracting,
      products,
      reports,
      submit_av,
      resources,
      training,
      contests,
      terms,
      calendars,
      emails,
      can_search,
      formError,
      errorMessage,
    } = this.state
    if (loading) {
      return
    }

    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }

    const data = {
      name: name,
      displayname: displayname,
      is_agent: is_agent,
      is_manager: is_manager,
      is_staff: is_staff,
      is_pending: is_pending,
      has_mailbox: has_mailbox,
      has_downline: has_downline,
      can_recruit: can_recruit,
      incentives: incentives,
      subsite: subsite,
      admin_area: admin_area,
      agency_info: agency_info,
      create_user: create_user,
      usertypes: usertypes,
      commissions: commissions,
      divisions: divisions,
      carriers: carriers,
      contracting: contracting,
      products: products,
      reports: reports,
      submit_av: submit_av,
      resources: resources,
      training: training,
      contests: contests,
      terms: terms,
      calendars: calendars,
      emails: emails,
      can_search: can_search,
      table_name: 'usertypes',
      id: id,
    }

    const form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })
    AdminApi.updateUserType(form_data)
      .then((result) => {
        this.setState({ loading: false })
        this.props.history.push('/admin-usertypes')
        toast.success('UserType updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  render() {
    toast.configure()
    const { urlParams, loading } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>
                  <a href="/admin-usertypes">List</a>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>
                  {!!(urlParams && urlParams.id)
                    ? 'Edit Usertype'
                    : 'Add Usertype'}
                </MDBBreadcrumbItem>
              </MDBBreadcrumb>
              <h2>
                {!!(urlParams && urlParams.id)
                  ? 'Edit Usertype'
                  : 'Add Usertype'}
              </h2>
              <hr />
              {loading ? (
                <div className="loadingOverlay">
                  <h4 className={'loader-text p4'}>
                    <MDBSpinner />
                  </h4>
                </div>
              ) : (
                ''
              )}
              <MDBRow>
                <MDBCol size="12">
                  <form className="content-wrapper">
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="name">
                          Usertype Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="name"
                          value={this.state.name}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="displayname">
                          Usertype Display Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="displayname"
                          id="displayname"
                          value={this.state.displayname}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-3">
                        <label>Is Agent:</label>
                        <MDBSwitch
                          checked={!!this.state.is_agent}
                          onChange={this.handleSwitchChange('is_agent')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Is Leader:</label>
                        <MDBSwitch
                          checked={!!this.state.is_manager}
                          onChange={this.handleSwitchChange('is_manager')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Is Staff:</label>
                        <MDBSwitch
                          checked={!!this.state.is_staff}
                          onChange={this.handleSwitchChange('is_staff')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Is Pending:</label>
                        <MDBSwitch
                          checked={!!this.state.is_pending}
                          onChange={this.handleSwitchChange('is_pending')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Has mailbox:</label>
                        <MDBSwitch
                          checked={!!this.state.has_mailbox}
                          onChange={this.handleSwitchChange('has_mailbox')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Has downline:</label>
                        <MDBSwitch
                          checked={!!this.state.has_downline}
                          onChange={this.handleSwitchChange('has_downline')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Can recruit:</label>
                        <MDBSwitch
                          checked={this.state.can_recruit}
                          onChange={this.handleSwitchChange('can_recruit')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Include In Incentives:</label>
                        <MDBSwitch
                          checked={this.state.incentives}
                          onChange={this.handleSwitchChange('incentives')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Has Subsite:</label>
                        <MDBSwitch
                          checked={this.state.subsite}
                          onChange={this.handleSwitchChange('subsite')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Admin Access:</label>
                        <MDBSwitch
                          checked={this.state.admin_area}
                          onChange={this.handleSwitchChange('admin_area')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Agency Info:</label>
                        <MDBSwitch
                          checked={this.state.agency_info}
                          onChange={this.handleSwitchChange('agency_info')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Create User:</label>
                        <MDBSwitch
                          checked={this.state.create_user}
                          onChange={this.handleSwitchChange('create_user')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Usertypes:</label>
                        <MDBSwitch
                          checked={this.state.usertypes}
                          onChange={this.handleSwitchChange('usertypes')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Commissions:</label>
                        <MDBSwitch
                          checked={this.state.commissions}
                          onChange={this.handleSwitchChange('commissions')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Divisions:</label>
                        <MDBSwitch
                          checked={this.state.divisions}
                          onChange={this.handleSwitchChange('divisions')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Carriers:</label>
                        <MDBSwitch
                          checked={this.state.carriers}
                          onChange={this.handleSwitchChange('carriers')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Contracting:</label>
                        <MDBSwitch
                          checked={this.state.contracting}
                          onChange={this.handleSwitchChange('contracting')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Products:</label>
                        <MDBSwitch
                          checked={this.state.products}
                          onChange={this.handleSwitchChange('products')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Reports:</label>
                        <MDBSwitch
                          checked={this.state.reports}
                          onChange={this.handleSwitchChange('reports')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Can Submit AV:</label>
                        <MDBSwitch
                          checked={this.state.submit_av}
                          onChange={this.handleSwitchChange('submit_av')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Resources:</label>
                        <MDBSwitch
                          checked={this.state.resources}
                          onChange={this.handleSwitchChange('resources')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Training:</label>
                        <MDBSwitch
                          checked={this.state.training}
                          onChange={this.handleSwitchChange('training')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Contests:</label>
                        <MDBSwitch
                          checked={this.state.contests}
                          onChange={this.handleSwitchChange('contests')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Terms:</label>
                        <MDBSwitch
                          checked={this.state.terms}
                          onChange={this.handleSwitchChange('terms')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Calendars:</label>
                        <MDBSwitch
                          checked={this.state.calendars}
                          onChange={this.handleSwitchChange('calendars')}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Emails:</label>
                        <MDBSwitch
                          checked={this.state.emails}
                          onChange={this.handleSwitchChange('emails')}
                        />
                      </div>

                      <div className="form-group col-md-3">
                        <label>Search:</label>
                        <MDBSwitch
                          checked={this.state.can_search}
                          onChange={this.handleSwitchChange('can_search')}
                        />
                      </div>
                    </div>
                    <div className="form-row text-center mt-4">
                      <MDBBtn
                        color="unique"
                        type="button"
                        className="btn-block"
                        onClick={() => {
                          this.state.urlParams && this.state.urlParams.id
                            ? this.updateUserType()
                            : this.createUserType()
                        }}
                      >
                        {loading ? 'Saving...' : 'Save'}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AddUsertype
