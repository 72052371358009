import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { MDBAlert, MDBSwitch, MDBRow, MDBCol, MDBContainer } from 'mdbreact'

import './ToggleCashWithdrawalByUsertype.component.scss'
import UsertypeService from '../../../shared/services/Usertype.service'
import { toast } from 'react-toastify'

const ToggleCashWithdrawalByUsertype = ({ userType }) => {
  const [cashwithdrawalEnabled, setCashwithdrawalEnabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [userTypeId, setUserTypeId] = useState(null)

  useEffect(() => {
    fetchUserType()
  }, [])

  const fetchUserType = async () => {
    setIsLoading(true)
    let user_type_id = null
    switch (userType) {
      case 'Districts':
        user_type_id = 93
        setUserTypeId(93)
        break
      case 'Regions':
        user_type_id = 38
        setUserTypeId(38)
        break
      case 'Divisions':
        user_type_id = 90
        setUserTypeId(90)
        break
      default:
        break
    }
    const user_type = await UsertypeService.get(user_type_id)
    setCashwithdrawalEnabled(
      user_type.cash_withdrawal_enabled === 1 ? true : false
    )
    setIsLoading(false)
  }

  const toggle = async () => {
    try {
      setCashwithdrawalEnabled(!cashwithdrawalEnabled)
      await UsertypeService.update(userTypeId, {
        cash_withdrawal_enabled: cashwithdrawalEnabled ? 0 : 1,
      })

      if (cashwithdrawalEnabled) {
        toast.success(`Cash Withdrawals for ${userType} have been disabled.`, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.success(`Cash Withdrawals for ${userType} have been enabled.`, {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    } catch (ex) {
      console.error(`${ex}`)
      toast.error(`Something went wrong!`, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  const getColor = () => {
    if (isLoading) return 'warning'
    if (cashwithdrawalEnabled) return 'success'
    if (!cashwithdrawalEnabled) return 'danger'
    return 'secondary'
  }

  return (
    <MDBAlert color={getColor()}>
      <MDBContainer
        fluid
        id="ToggleCashWithdrawalByUsertypeComponent"
        className={
          isLoading
            ? 'is-loading'
            : cashwithdrawalEnabled
            ? 'is-activated'
            : 'is-deactivated'
        }
      >
        <MDBRow>
          <MDBCol size="12" className="label-wrapper">
            <label>
              Enable Cash Withdrawal for {userType}?&nbsp;
              {isLoading ? (
                <>
                  <span>
                    <i className="fa fa-spin fa-spinner"></i>
                  </span>
                </>
              ) : (
                <></>
              )}
              {!isLoading && cashwithdrawalEnabled && (
                <>
                  <br />
                  <small>
                    <strong>(Cash Withdrawals are enabled)</strong>
                  </small>
                </>
              )}
              {!isLoading && !cashwithdrawalEnabled && (
                <>
                  <br />
                  <small>
                    <strong>(Cash Withdrawals are disabled)</strong>
                  </small>
                </>
              )}
            </label>
          </MDBCol>
          <MDBCol size="12" className="switch-wrapper">
            <MDBSwitch
              labelLeft={
                !cashwithdrawalEnabled ? (
                  <span style={{ color: '#a10a0a', fontWeight: 600 }}>
                    Disabled
                  </span>
                ) : (
                  'Disabled'
                )
              }
              labelRight={
                cashwithdrawalEnabled ? (
                  <span style={{ color: '#1c802b', fontWeight: 600 }}>
                    Enabled
                  </span>
                ) : (
                  'Enabled'
                )
              }
              disabled={!!isLoading}
              checked={cashwithdrawalEnabled}
              onChange={toggle}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBAlert>
  )
}

export default observer(ToggleCashWithdrawalByUsertype)
