import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBBadge,
} from 'mdbreact'
import UserProfileService from './../../../shared/services/UserProfile.service'
import ACMStore from './../AgentContractManager.store'

import './AvailableContractsList.scss'
import { AdditionalCarrierFilter } from './AdditionalCarrierFilter'
import UserService from '../../../shared/services/User.service'

const RenderCarrierTable = ({ isLocked, priority, Carriers }) => {
  const renderPill = (indicator) => {
    switch (indicator.props.children) {
      case '$$$':
        return (
          <MDBBadge pill color="primary">
            {'Core'}
          </MDBBadge>
        )
      case '$$':
        return (
          <MDBBadge pill color="danger">
            {'Preferred'}
          </MDBBadge>
        )
      case '$':
        return (
          <MDBBadge pill color="success">
            {'Additional'}
          </MDBBadge>
        )
      default:
        return
    }
  }

  const renderSelectableRow = (Carrier, idx) => {
    return (
      <div
        className={
          'selectable-row ' + (isCarrierSelected(Carrier) ? 'row-selected' : '')
        }
        key={`selectable-cell-carrier-${Carrier.id()}`}
      >
        <div className="selectable-cell cell-name">
          <h6>{Carrier.get('c_name')}</h6>
          <div className="d-flex align-items-center">
            <small className="mr-2">
              {Carrier.renderPriorityIndicator(
                UserProfileService.getCurrentUserTypeId()
              )}
            </small>
            {renderPill(
              Carrier.renderPriorityIndicator(
                UserProfileService.getCurrentUserTypeId()
              )
            )}
          </div>
        </div>

        <div className="selectable-cell cell-descrip">
          <div>{Carrier.get('c_description')}</div>
        </div>

        <div className="selectable-cell cell-action">
          <MDBBtn
            className="toggle-carrier-btn"
            onClick={async () =>
              ACMStore.add(
                'CartItem',
                await ACMStore.Cart.item(true).create({
                  carrier_id: Carrier.id(),
                  item_status: 'DRAFT',
                })
              )
            }
          >
            {isCarrierSelected(Carrier) ? (
              <>
                <MDBIcon icon="check" />
                &nbsp;<span>Added to Cart</span>
              </>
            ) : (
              <>
                <MDBIcon icon="cart-plus" />
                &nbsp;<span>Add to Cart</span>
              </>
            )}
          </MDBBtn>
        </div>
      </div>
    )
  }

  const renderSiblingsRow = (Carriers, idx) => {
    const setPriorityIndicator = () => {
      const indicatorArray = Carriers.map((Carrier) =>
        Carrier.renderPriorityIndicator(
          UserProfileService.getCurrentUserTypeId()
        )
      )

      const priorityIndicators = {
        $$$: [],
        $$: [],
        $: [],
      }

      // Group indicators by priority level
      indicatorArray.forEach((item) => {
        const priority = item.props.children
        if (priority in priorityIndicators) {
          priorityIndicators[priority].push(item)
        }
      })

      // Find the first available indicator based on priority level
      for (const priority of ['$$$', '$$', '$']) {
        if (priorityIndicators[priority].length > 0) {
          return priorityIndicators[priority][0]
        }
      }

      // Return a default indicator if none found
      return null
    }

    return (
      <div
        className={'selectable-sibling-row '}
        key={`selectable-sibling-cell-carrier-${idx}`}
      >
        <div className="selectable-cell cell-name">
          <h6>{Carriers[0].get('c_name')}</h6>
          <div className="d-flex align-items-center">
            <small className="mr-2">{setPriorityIndicator()}</small>
            {renderPill(setPriorityIndicator())}
          </div>
        </div>
        <div className="sibling-rows">
          {Carriers.map((Carrier, idx) => {
            return (
              <div
                className={
                  'sibling-cell-row ' +
                  (isCarrierSelected(Carrier) ? 'row-selected' : '')
                }
                key={`sibling-cell-row-${Carrier.id()}-${idx}`}
              >
                <div className="selectable-cell cell-descrip">
                  {Carrier.get('c_description')}
                </div>

                <div className="selectable-cell cell-action">
                  <MDBBtn
                    className="toggle-carrier-btn"
                    onClick={async () =>
                      ACMStore.add(
                        'CartItem',
                        await ACMStore.Cart.item(true).create({
                          carrier_id: Carrier.id(),
                          item_status: 'DRAFT',
                        })
                      )
                    }
                  >
                    {isCarrierSelected(Carrier) ? (
                      <>
                        <MDBIcon icon="check" />
                        &nbsp;<span>Added to Cart</span>
                      </>
                    ) : (
                      <>
                        <MDBIcon icon="cart-plus" />
                        &nbsp;<span>Add to Cart</span>
                      </>
                    )}
                  </MDBBtn>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const selectedCarrierIds = ACMStore.CartItems.map(
      (CI) => CI.get('item_status') !== 'ABANDONED' && CI.get('carrier_id')
    ).filter((n) => !isNaN(n)),
    isCarrierSelected = (Carrier) =>
      ~selectedCarrierIds.indexOf(parseInt(Carrier.id()))

  if (Carriers.length === 0)
    return (
      <div className="selectable-carriers-table empty-list">
        Wow, you've contracted with all our {priority} Carriers! Great Job!
        <br />
        <strong>
          There are no more available {priority} carrier contracts.
        </strong>
      </div>
    )

  return (
    <div className="selectable-carriers-table">
      {Carriers.map((Carrier, idx) => {
        if (Array.isArray(Carrier)) return renderSiblingsRow(Carrier, idx)
        return renderSelectableRow(Carrier, idx)
      })}
    </div>
  )
}

const AvailableContractsList = ({ activeSpecs }) => {
  const [specialization, setSpecialization] = useState('ALL')
  const [uplines, setUplines] = useState([])

  useEffect(() => {
    getUplines()
  }, [])

  const getUplines = async () => {
    const upline_agents = await UserService.getUpline(
      UserProfileService.getUserId()
    )
    setUplines(upline_agents.map((upline) => `${upline.id}`))
  }

  const isCarrierSelectable = (Carrier) => {
    if (parseInt(Carrier.get('c_active')) !== 1) return false

    if (parseInt(Carrier.get('c_active')) === 1) {
      if (Carrier.get('visibility')?.contracting?.enable) {
        const usertype_id = parseInt(UserProfileService.getCurrentUserTypeId());
        if ([225].includes(usertype_id) && !Carrier.get('visibility')?.contracting?.csr_agent) return false
        if ([111, 133].includes(usertype_id) && !Carrier.get('visibility')?.contracting?.affiliate_agent) return false
        if ([223, 224].includes(usertype_id) && !Carrier.get('visibility')?.contracting?.associate_agent) return false
        if ([91, 92, 149, 37, 35, 38, 90, 93, 130, 129, 226, 227, 228, 219, 36, 222].includes(usertype_id) && !Carrier.get('visibility')?.contracting?.sig_agent) return false
      } else return false
    }

    if (Carrier.get('visibility')?.contracting?.upline_ids?.length > 0) {
      const disabled_carrier = Carrier.get(
        'visibility'
      ).contracting.upline_ids.some((upline_id) => uplines.includes(upline_id))
      if (disabled_carrier) return false
    }

    // remove carriers that ...
    // 	1. are in the current shopping cart.
    // 	2. are in a past 'CLOSED' shopping cart AND disposition != 'unsent' AND disposition != 'expired'.

    // remove carriers in the cart (excluding abandonded).
    // return false if carrier is already in shopping cart.
    if (
      ACMStore.CartItems.filter(
        (Item) =>
          `${Item.get('carrier_id')}` === `${Carrier.id()}` &&
          Item.get('item_status') !== 'ABANDONED'
      ).length > 0
    )
      return false

    // remove carriers already contracted (excluding expired).
    // return false if carrier is already contracted.
    if (
      ACMStore.Contracts.filter(
        (Contract) =>
          `${Contract.get('carrier_id')}` === `${Carrier.id()}` &&
          Contract.get('disposition') !== 'expired' &&
          Contract.get('disposition') !== 'unsent'
      ).length > 0
    )
      return false

    return true
  }

  const loadSelectableCarriers = () => {
    const CarrierGroups = {
      core: [],
      preferred: [],
      additional: [],
    }

    ;(ACMStore?.Carriers || [])
      .filter(isCarrierSelectable)
      .forEach((Carrier) => {
        const carrierType = Carrier.getPriority(
          activeSpecs,
          UserProfileService.get('usertype_id')
        )
        if (carrierType === 'additional') {
          if (specialization !== 'ALL') {
            if (
              Carrier.getCoverageCategory().filter(
                (c) => c.coverage_category === specialization
              ).length === 0
            ) {
              return
            }
          }
        }
        CarrierGroups[carrierType].push(Carrier)
      })

    return CarrierGroups
  }

  const organizeSiblings = (Carriers) => {
    // const siblingIds = {additional: []};
    const CarriersById = {},
      LinkedSiblings = {}
    Carriers.forEach((Carrier) => (CarriersById[Carrier.id()] = Carrier))

    for (let carrierId in CarriersById) {
      if (
        CarriersById.hasOwnProperty(carrierId) &&
        CarriersById[carrierId].get('sibling_ids')
      )
        LinkedSiblings[carrierId] = Array.isArray(
          CarriersById[carrierId].get('sibling_ids')
        )
          ? CarriersById[carrierId].get('sibling_ids')
          : JSON.parse(CarriersById[carrierId].get('sibling_ids'))

      if (
        LinkedSiblings.hasOwnProperty(carrierId) &&
        Array.isArray(LinkedSiblings[carrierId])
      ) {
        LinkedSiblings[carrierId] = LinkedSiblings[carrierId]
          .map((siblingId) => {
            let Sibling
            if (
              CarriersById.hasOwnProperty(siblingId) &&
              CarriersById[siblingId]
            ) {
              Sibling = CarriersById[siblingId]
              delete CarriersById[siblingId]
              return Sibling
            }
            return Sibling
          })
          .filter((Carrier) => Carrier)

        if (LinkedSiblings[carrierId].length === 0) {
          delete LinkedSiblings[carrierId]
        } else {
          LinkedSiblings[carrierId].unshift(CarriersById[carrierId])
          delete CarriersById[carrierId]
        }
      }
    }

    return Object.values(CarriersById)
      .concat(Object.values(LinkedSiblings))
      .sort((CarrierA, CarrierB) => {
        CarrierA = Array.isArray(CarrierA) ? CarrierA[0] : CarrierA
        CarrierB = Array.isArray(CarrierB) ? CarrierB[0] : CarrierB
        return `${CarrierA.get('c_name')}`.localeCompare(
          `${CarrierB.get('c_name')}`,
          'en'
        )
      })
  }

  const organizePrioritySiblings = ({ core, preferred, additional }) => {
    return {
      core: organizeSiblings(core),
      preferred: organizeSiblings(preferred),
      additional: organizeSiblings(additional),
    }
  }

  const renderDropDown = () => {
    const CarrierGroups = organizePrioritySiblings(loadSelectableCarriers())
    const isLoading = false

    const renderTable = (priority) => {
      let isLocked = false
      if (priority === 'preferred')
        isLocked = (CarrierGroups.core.length || 0) > 0
      if (priority === 'additional')
        isLocked =
          parseInt(CarrierGroups.core.length || 0) +
            parseInt(CarrierGroups.preferred.length || 0) >
          0

      const getPriorityIndicator = (priority) => {
        switch (priority && `${priority}`.trim().toLowerCase()) {
          case 'core':
            return '$$$'
          case 'preferred':
            return '$$'
          case 'additional':
            return '$'
          default:
            return ''
        }
      }

      const getPriorityTableName = (priority) => {
        switch (priority && `${priority}`.trim().toLowerCase()) {
          case 'core':
            return 'USABG Core Carriers'
          case 'preferred':
            return 'USABG Preferred Carriers'
          case 'additional':
            return 'Additional Carriers'
          default:
            return ''
        }
      }

      const getPriorityTableDescrip = (priority) => {
        switch (priority && `${priority}`.trim().toLowerCase()) {
          case 'core':
            return 'Core carriers provide agents with the best support, service and commissions. All agents are required to have core carriers in their portfolio.'
          case 'preferred':
            return "Preferred carriers provide additional coverage for your client's protection. We highly recommend all agents have these products to better serve their clients."
          case 'additional':
            return ''
          default:
            return ''
        }
      }

      return (
        <MDBCard className="mb-4 carrier-table-card">
          <MDBCardHeader
            className={
              // CarrierStore.getIsLoading('fetch.carriers') ||
              // CarrierStore.getIsLoading('fetch.agent-carriers') ||
              // CarrierStore.getIsLoading('update.agent-specs') ||
              // CarrierStore.getIsLoading('fetch.agent-specs')
              isLoading ? 'is-loading' : ''
            }
          >
            <MDBRow>
              <MDBCol size="12">
                <div>
                  <strong>
                    <span className="priority-level-indicator">
                      {getPriorityIndicator(priority)}
                    </span>
                  </strong>
                  &nbsp;{getPriorityTableName(priority)}&nbsp;
                  <span className="loader-wheel">
                    <span>
                      <i className="fa fa-spin fa-spinner" />
                    </span>
                    &nbsp;Updating Carriers ...
                  </span>
                  {priority === 'additional' ? (
                    <AdditionalCarrierFilter
                      onChange={(e) => setSpecialization(e)}
                      value={specialization}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <small>{getPriorityTableDescrip(priority)}</small>
              </MDBCol>
            </MDBRow>
          </MDBCardHeader>
          <MDBCardBody>
            <RenderCarrierTable
              isLocked={isLocked}
              priority={priority}
              Carriers={CarrierGroups[priority]}
            />
          </MDBCardBody>
        </MDBCard>
      )
    }

    return (
      <div>
        {renderTable('core')}
        {renderTable('preferred')}
        {renderTable('additional')}
      </div>
    )
  }

  const renderWidget = () => {
    return (
      <MDBContainer className="AvailableContractsListComponent" fluid>
        <MDBRow>
          <MDBCol size="12">{renderDropDown()}</MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }

  return renderWidget()
}

export default observer(AvailableContractsList)
