import BaseModelFactory from './base.factory'
import LeadCreditQuantityPriceService from './../services/LeadCreditQuantityPrice.service'
import LeadCreditQuantityPrice from './../models/lead-credit-quantity-price.model'

class LeadCreditQuantityPriceFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      {
        Service: LeadCreditQuantityPriceService,
        Model: LeadCreditQuantityPrice,
      }
    )
  static findById = async (id) =>
    await this._findBy(
      { id },
      {
        Service: LeadCreditQuantityPriceService,
        Model: LeadCreditQuantityPrice,
      }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: LeadCreditQuantityPriceService,
      Model: LeadCreditQuantityPrice,
    })
}

export default LeadCreditQuantityPriceFactory
