import { observer } from 'mobx-react-lite'
import React from 'react'
import { MDBCard, MDBCardHeader, MDBCardBody } from 'mdbreact'
import CarrierMgmtStore from './../../CarrierMgmt.store'
import { UIList } from '../../../../components/forms/form-fields'

import './CarrierDivisionsForm.scss'

const CarrierDivisionsForm = ({ table }) => {
  const isDivisionCarrierActive = (divId) =>
    CarrierMgmtStore.divisions.filter(
      (div) =>
        parseInt(div.dc_active) === 1 &&
        parseInt(div.division_id) === parseInt(divId)
    ).length > 0

  const renderLockOut = () => {
    if (
      !CarrierMgmtStore.carrier ||
      (CarrierMgmtStore.carrier && isNaN(CarrierMgmtStore.carrier?.id))
    )
      return <div className="lockout-window">lockout</div>
  }

  const onChange = (event) => {
    const divisionId = parseInt(event.target.name),
      isActive =
        event.target.value === true || event.target.value === false
          ? event.target.value
          : undefined,
      DivisionCarrier = CarrierMgmtStore.getDivisionCarrier(divisionId)

    if (typeof isActive === 'boolean') {
      if (isActive === true) {
        if (!DivisionCarrier || !isDivisionCarrierActive(divisionId))
          CarrierMgmtStore.toggleDivision(divisionId)
      } else {
        if (DivisionCarrier && isDivisionCarrierActive(divisionId))
          CarrierMgmtStore.toggleDivision(divisionId)
      }
    }
  }

  const renderList = () => {
    return (
      <UIList
        list={CarrierMgmtStore.source.divisions}
        label={'d_name'}
        value={'id'}
        onChange={onChange}
        checkedHandle={isDivisionCarrierActive}
      />
    )
  }

  return (
    <MDBCard id="CarrierDivisionsFormComponent">
      {renderLockOut()}
      <MDBCardHeader>Division Carriers</MDBCardHeader>
      <MDBCardBody>{renderList()}</MDBCardBody>
    </MDBCard>
  )
}

export default observer(CarrierDivisionsForm)
