import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { RouteObserver, RouteMatcher } from './RouteMatcher'

function RouterStage(props) {
  const pathname = useLocation().pathname,
    RouteDef = RouteMatcher(useLocation().pathname),
    themeClass = `usabg-${
      RouteDef && RouteDef?.requiresAuth() === true ? 'private' : 'public'
    }-theme`

  if (pathname && RouteDef?.path && RouteDef.matches(pathname) === false)
    window.location.href = RouteDef.path
  else if (RouteDef.redirectsTo)
    window.location.href = RouteDef.redirectsTo

  useEffect(() => {
    document
      .querySelector('body')
      .classList.remove('usabg-private-theme', 'usabg-public-theme')
    document.querySelector('body').classList.add(themeClass)
  }, [themeClass])

  RouteObserver.to = RouteDef

  return (
    <>
      <Switch>
        <Route path={RouteDef.path} component={RouteDef.component} exact />
      </Switch>
    </>
  )
}

export default RouterStage
