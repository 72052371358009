import React, { Component, Fragment } from 'react';
import DashboardLayout from "../../components/Admin/DashboardLayout.jsx";
import {MDBBtn, MDBContainer, MDBDataTable, MDBIcon, MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import AdminApi from "../../api/admin-api/admin-api.js";
import {toast} from "react-toastify";



class ResourceTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rt_name: '',
            resourceTypeList: [],
            resourceTypeloading: false,
            loading: '',
            error: '',
            confirmModal: false,
            viewdata: '',
            data: {
                columns: [
                    {
                        label: 'Resource Type',
                        field: 'type',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Update',
                        field: 'update',
                        sort: 'asc',
                        width: 270
                    },
                    {
                        label: 'Delete',
                        field: 'delete',
                        sort: 'asc',
                        width: 270
                    }
                ],
                rows: [],
            }
        } // state end
    }

    componentDidMount() {
        this.fetchResourceTypeData();
    }

    //convert date from sec to current date
    toDateTime = (secs) => {
        let t = new Date(1970, 0, 1); // Epoch
        t.setSeconds(secs);
        return t;
    }

    //calling delete confirm modal
    confirm_DeleteResourceType = (data) => {
        this.confirm_toggle(data);
    }

    //Toggle delete confirm modal
    confirm_toggle = (data = {}) => {
        this.setState({confirmModal: !this.state.confirmModal, ...data});
    }

    //forming rows for data table from fetched data
    setRows = () => {
        const {resourceTypeList} = this.state;
        if(resourceTypeList && resourceTypeList.length) {
            let rows = resourceTypeList.map((row) => {
                const {rt_name, id} = row;
                // const submittedDate = created_at && this.toDateTime(created_at._seconds).toString();
                const self = this;

                function editResourceType() {
                    self.props.history.push(`/add-resourceType/${id}/edit`);
                }

                function deleteResourceType() {
                    self.confirm_DeleteResourceType({viewdata: row});
                }

                const editClick = (
                    <div className={'d-flex align-items-center justify-content-center'}>
                        <div className={'cursor-pointer d-inline-block'} role={'button'} onClick={editResourceType}>
                            <MDBIcon icon="edit" size="2x" className="blue-text p-1" />
                        </div>
                    </div>
                );

                const deleteClick = (
                    <div className={'d-flex align-items-center justify-content-center'}>
                        <div className={'cursor-pointer d-inline-block'} role={'button'} onClick={deleteResourceType}>
                            <MDBIcon icon="trash" size="2x" className="red-text p-1" />
                        </div>
                    </div>
                );

                return {
                    type: rt_name,
                    update: editClick,
                    delete: deleteClick
                }
            });
            this.setState({
                data: {...this.state.data, rows: rows}
            });
        }
    }

    //Fetch data
    fetchResourceTypeData = async () => {
        this.setState({loading: true});
        AdminApi.getResourcesType().then((result) => {
            this.setState({resourceTypeList: result.data.data || []});
        }).then(() => {
            this.setState({loading: false});
            this.setRows();
        }).catch(error => {
            this.setState({loading: false});
            toast.error("Something went wrong!", {position: toast.POSITION.TOP_RIGHT});
        });
    }

    _DeleteResourceType = async (id) => {
        this.setState({deleting: true});
        const data = {type: "resource_types", id: id};

        AdminApi.deleteResource(data).then((result) => {
            this.setState({deleting: false});
            this.confirm_toggle();
            toast.success("Resource Type successfully deleted", {position: toast.POSITION.TOP_RIGHT});
            this.fetchResourceTypeData();
        }).catch(error => {
            this.setState({deleting: false});
            toast.error(`${error.message}`, {position: toast.POSITION.TOP_RIGHT});
        });
    }


    render() {
        toast.configure();
        const{data, confirmModal, loading} = this.state;

        const createNew = () => {
            this.props.history.push('/add-resourceType');
        };

        return (
            <Fragment>
                <DashboardLayout>
                    <main className="mainSection pb-5">
                        <MDBContainer fluid className="mt-5">
                            <h2>Resource Types
                                <div className="d-inline-block">
                                    <MDBBtn tag="a" floating gradient="blue" title={'Add New Resource Type'} onClick={createNew}>
                                        <MDBIcon icon="plus" />
                                    </MDBBtn>
                                </div>
                            </h2>
                            <hr />
                            {loading ?
                                <h4 className={'p4'}>Loading...</h4> :
                                <div className="content-wrapper"><MDBDataTable entriesOptions={[1000, 15000, 2000]} entries={1000} striped bordered small data={data} className="text-capitalize" /></div>
                            }
                            <MDBModal size={"sm"} isOpen={confirmModal} toggle={this.confirm_toggle}>
                                <MDBModalHeader toggle={this.confirm_toggle}>
                                    Delete Resource Type
                                </MDBModalHeader>
                                <MDBModalBody>
                                    <div className="h5">Are you sure you want to delete this Resource Type?</div>
                                    <MDBBtn color="secondary" size={'sm'} onClick={this.confirm_toggle}>
                                        Close
                                    </MDBBtn>
                                    <MDBBtn color="danger" size={'sm'} onClick={() => {this._DeleteResourceType(this.state.viewdata.id)}}>
                                        {this.state.deleting ? 'Deleting...' : 'Delete'}
                                    </MDBBtn>
                                </MDBModalBody>
                            </MDBModal>
                        </MDBContainer>
                    </main>
                </DashboardLayout>
            </Fragment>
        );
    }
}

export default ResourceTypes;
