import React from 'react'
import { observer } from 'mobx-react-lite'
import { MDBAlert, MDBCollapse } from 'mdbreact'
import CarrierStore from './../Carrier.store'

import './SpecRequireContract.scss'

const SpecRequireContract = ({ spec }) => (
  <MDBCollapse
    isOpen={CarrierStore.isSpecActive(spec)}
    className="SpecRequireContractComponent"
  >
    <MDBAlert
      color={
        !CarrierStore._required[spec] ||
        `${CarrierStore._required[spec]}` === '0'
          ? 'success'
          : 'danger'
      }
      className="text-center"
    >
      {!CarrierStore._required[spec] ||
      `${CarrierStore._required[spec]}` === '0' ? (
        <>
          Great! You've contracted enough carriers to earn the{' '}
          <strong>{spec}</strong> specialization.
        </>
      ) : (
        <>
          Requires at least <strong>{CarrierStore._required[spec]}</strong> more{' '}
          <strong>{spec}</strong> carrier contracts to complete the
          specialization.
        </>
      )}
    </MDBAlert>
  </MDBCollapse>
)

export default observer(SpecRequireContract)
