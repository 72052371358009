import BaseModelFactory from './base.factory'
import LeadCreditPackageService from './../services/LeadCreditPackage.service'
import LeadCreditPackage from './../models/lead-credit-package.model'

class LeadCreditPackageFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: LeadCreditPackageService, Model: LeadCreditPackage }
    )
  static findById = async (id) =>
    await this._findBy(
      { id },
      { Service: LeadCreditPackageService, Model: LeadCreditPackage }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: LeadCreditPackageService,
      Model: LeadCreditPackage,
    })
}

export default LeadCreditPackageFactory
