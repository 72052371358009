import React from 'react'
import appConstants from './../../../constants/appConstants'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import AgentContractManager from './../../contracting/AgentContractManager/AgentContractManager.component'
// import CarrierStore from './../../contracting/Carrier.store'

import './AgentContractingIntake.scss'

const permitMultiSpec = () =>
  !!(appConstants && appConstants?.onboarding?.permit_multi_specialization)

class AgentContractingIntake extends React.Component {
  // #onValidityChange = (changed) => {
  //   if (typeof this.props?.onValidityChange === 'function')
  //     this.props.onValidityChange(changed)
  // }

  // componentDidMount() {
  //   CarrierStore.listenToValidityChange(this.#onValidityChange)
  // }

  // componentWillUnmount() {
  //   CarrierStore.stopListeningToValidityChange(this.#onValidityChange)
  // }

  // validationKey === 'requested'
  // validationKey === 'completed'

  render() {
    return (
      <MDBContainer id="AgentContractingIntake" fluid>
        <MDBRow className="onboarding-stage-label">
          <MDBCol size="12" sm="10" className="offset-sm-1">
            <h2>
              <span className="intro-step">{this.props.step}</span>
              {this.props.label}
            </h2>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12">
            <div className="content-wrapper">
              <AgentContractManager
                isShopping={true}
                isOnboarding={true}
                maxContracts={10}
                specInputType={permitMultiSpec() ? 'checkbox' : 'radio'}
                permittedTables={['core', 'preferred']}
                preventInit={!!this.props?.preventInit}
                onValidityChange={this.props.onValidityChange}
                validationKey={this.props.validationKey}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default AgentContractingIntake
