import { makeAutoObservable } from 'mobx'
import appConstants from './../../constants/appConstants'
import AuthService from './../services/Auth.service'
import WorkerBuilder from '../utilities/workerBuilder.class'
import { activityWorker } from '../workers/activityWorker'

const SESSION_LOGOUT = appConstants.session_inactive_logout
const SESSION_WARNING = appConstants.session_inactive_warning

class ActivityStore {
  // public
  showWarningModal = false
  remaining = SESSION_LOGOUT
  worker

  constructor() {
    makeAutoObservable(this)
    this.worker = new WorkerBuilder(activityWorker)
    this.worker.postMessage({
      type: 'init',
      SESSION_LOGOUT,
      SESSION_WARNING,
    })
    this.worker.onmessage = (event) => {
      switch (event.data.type) {
        case 'logout':
          this.logout()
          break
        case 'showWarningModal':
          this.showWarningModal = event.data.showWarningModal
          break
        case 'updateRemaining':
          this.remaining = event.data.remaining
          break
        default:
          break
      }
    }
  }

  logout = () => {
    this.setTimestamp(true)
    AuthService.logout()
    window.location.href = 'login'
  }

  cancelWarning = () => {
    this.setTimestamp()
    this.worker.postMessage({
      type: 'cancelWarning',
    })
  }

  recordRequest = async () => {
    this.setTimestamp()
    this.worker.postMessage({
      type: 'restartTimer',
      token: AuthService.getToken(),
    })
  }

  checkActivity = () => {
    const newTimestamp = Date.now()
    const oldTimestamp = this.getTimestamp()
    const timePeriod = SESSION_LOGOUT * 1000 + SESSION_WARNING
    if (newTimestamp - oldTimestamp > timePeriod) {
      this.logout()
    }
  }

  setTimestamp = (clear = false) => {
    if (clear) {
      window.localStorage.removeItem('recorded_at')
    }
    window.localStorage.setItem('recorded_at', Date.now())
  }

  getTimestamp = () => {
    return window.localStorage.getItem('recorded_at')
  }
}

export default new ActivityStore()
