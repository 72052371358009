import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import React, { useState } from 'react'
import {
  MDBCollapse,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBAlert,
  MDBBtn,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
} from 'mdbreact'
import UserProfileService from './../../../shared/services/UserProfile.service'
import CarrierContractService from './../../../shared/services/CarrierContract.service'
import ACMStore from './../AgentContractManager.store'
import ContentHtml from './../../content/ContentHtml/ContentHtml.component'
import SendToCarrierButton from './../SendToCarrierButton/SendToCarrierButton.component'
import RenderContractDates from './../RenderContractDates/RenderContractDates.component'
import { isAdobeContract, dispositionToStatus } from './../functions'

import './OnboardingWritingNumbers.scss'

const canSend = {}

const OnboardingWritingNumbers = ({
  onValidityChange,
  onExamine,
  activeSpecs,
}) => {
  const isAdmin = UserProfileService.isA([
    'agency-owner',
    'internal-admin',
    'internal-staff',
    'system-admin',
  ])
  const [isGettingContract, setIsGettingContract] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const renderContractLink = (Carrier) => {
    /*

		UNTESTED CODE FROM PREVIOUS COMPONENT.
		EVAL & TEST BEFORE IMPLEMENTING.

    let link = carrier?.link,
      CartItems = toJS(CheckoutStore.CartItems)

    if (!link) {
      carrier.CartItem = carrier.CartItem
        ? carrier.CartItem
        : Array.isArray(CartItems)
        ? CartItems.filter(
            (CartItem) =>
              parseInt(CartItem.get('user_contract_id')) ===
              parseInt(carrier.id)
          ).shift()
        : null
      link = link ? link : carrier.CartItem && carrier.CartItem.get('link')
    }

    carrier.link = link
    if (carrier.link) {
      // ADD_CONTRACT_LINK_HERE
      // return <a href={carrier.link} target='_BLANK'>Access Carrier Contract</a>
    }
		*/
  }

  const renderEditButton = (Carrier) => {
    const Contract = (toJS(ACMStore.Contracts) || [])
      .filter(
        (Contract) =>
          parseInt(Contract.get('carrier_id')) === parseInt(Carrier.id())
      )
      .shift()

    if (
      UserProfileService.isA(
        ['agency-owner', 'non-agent', 'system-admin'],
        true
      )
    ) {
      return (
        <div>
          <MDBBtn
            disabled={isLoading}
            className="writing-num-btn"
            onClick={() => (ACMStore.Contract = Contract)}
          >
            {isGettingContract &&
            parseInt(isGettingContract) === parseInt(Contract?.get('id')) ? (
              <>
                <i className="fa fa-spin fa-spinner" />
              </>
            ) : (
              <MDBIcon icon="pencil-alt" />
            )}
            &nbsp;
            <span>Edit Contract</span>
          </MDBBtn>
        </div>
      )
    } else if (
      Contract &&
      ['unsent', 'expired'].indexOf(Contract?.get('disposition')) < 0
    ) {
      // If the pwk-sent-to-carrier is not yet set,
      // do not permit the agent to enter their writing num.
      if (Contract?.get('pwk_sent_to_carrier_at'))
        return (
          <div>
            <MDBBtn
              disabled={isLoading}
              className="writing-num-btn"
              onClick={async () => {
                if (!isGettingContract) {
                  setIsGettingContract(parseInt(Contract.get('id')))
                  const CarrierContract = (
                    await CarrierContractService.search({
                      search: { carrier_id: Contract.get('carrier_id') },
                      pagination: false,
                    })
                  )?.models?.shift()
                  Contract.contract_type = CarrierContract?.contract_type
                  ACMStore.Contract = Contract
                  setIsGettingContract(false)
                }
              }}
            >
              {isGettingContract &&
              parseInt(isGettingContract) === parseInt(Contract?.get('id')) ? (
                <>
                  <i className="fa fa-spin fa-spinner" />
                </>
              ) : (
                <MDBIcon icon="pencil-alt" />
              )}
              &nbsp;
              <span>
                {Contract?.get('agent_code') ? 'Update' : 'Enter'} Writing Num.
              </span>
            </MDBBtn>
          </div>
        )

      return (
        <div style={{ flex: '0 0 100%', maxWidth: '100%' }}>
          <MDBAlert
            className="fw--500 text--center blocked-writing-num"
            color="secondary"
          >
            Can not enter writing number until contract is sent to carrier.
          </MDBAlert>
        </div>
      )
    }

    return <></>
  }

  const fetchContractPdf = () => {}

  const renderDownloadButton = (Carrier) => {
    const Contracts = toJS(ACMStore.Contracts),
      Contract = Contracts.filter(
        (Contract) =>
          parseInt(Contract.get('carrier_id')) === parseInt(Carrier.id())
      ).shift()

    const showBtn = () => {
      if (isAdmin && Contract && isAdobeContract(Contract))
        return (
          Contract.get('disposition') !== 'unset' &&
          Contract.get('disposition') !== 'pending' &&
          Contract?.get('contract_id') !== 'legacy' &&
          Contract?.get('contract_id') !== 'sales_tracker'
        )

      return false
    }

    return showBtn() ? (
      <div>
        <MDBBtn
          disabled={!!isDownloading}
          className="download-btn"
          onClick={() => fetchContractPdf(Carrier)}
        >
          {!!isDownloading ? (
            <i className="fa fa-spin fa-spinner"></i>
          ) : (
            <MDBIcon icon="file-pdf" />
          )}
          &nbsp;
          <span>
            Download{' '}
            {/*{CarrierStore._carrierContractTypes[Carrier?.carrier_id]}*/}
          </span>
        </MDBBtn>
      </div>
    ) : (
      <></>
    )
  }

  const renderDispositionCell = (Carrier) => {
    const Contract = toJS(ACMStore.Contracts)
        .filter(
          (Contract) =>
            parseInt(Contract.get('carrier_id')) === parseInt(Carrier.id())
        )
        .shift(),
      disposition = Contract?.get('disposition'),
      agent_code = Contract?.get('agent_code')

    return (
      <div className={`disposition ${disposition}`}>
        <MDBIcon icon="clock" />
        <MDBIcon icon="flag-checkered" />
        &nbsp;
        {dispositionToStatus(
          Contract?.contractType,
          Contract?.get('disposition')
        )}
        {renderContractLink(Carrier)}
        <br />
        {disposition === 'awaiting' ? (
          <MDBAlert color="warning" className="requires-attention">
            <MDBIcon icon="exclamation-triangle" />
            &nbsp;Requires Your Attention
          </MDBAlert>
        ) : (
          <></>
        )}
        {(disposition === 'completed' || disposition === 'expired') &&
        agent_code ? (
          <div className="agent-writing-code">Writing Number: {agent_code}</div>
        ) : (
          <></>
        )}
      </div>
    )
  }

  const renderContractDatesCell = (Contract) => {
    return (
      <div className="contract-dates">
        <RenderContractDates Contract={Contract} canEdit={false} />
      </div>
    )
  }

  const renderTable = () => {
    const Contracts = toJS(ACMStore.Contracts),
      Carriers = (Contracts || []).map(
        (Contract) => ACMStore.CarriersById[Contract.get('carrier_id')]
      )

    if (isLoading) {
      return (
        <div
          style={{ padding: '5px' }}
          className="agent-contracts-loading text-center"
        >
          <span>
            <i className="fa fa-spin fa-spinner"></i>&nbsp;Loading Agent
            Contracts ...{' '}
          </span>
        </div>
      )
    }

    const rows = () => {
      return (Carriers || []).map((Carrier, idx) => {
        const Contract = Contracts.filter(
            (Contract) =>
              parseInt(Contract.get('carrier_id')) === parseInt(Carrier.id())
          ).shift(),
          dispoCell = renderDispositionCell(Carrier),
          datesCell = renderContractDatesCell(Contract),
          priority = Carrier.getPriority(
            activeSpecs,
            UserProfileService.get('usertype_id')
          )

        return (
          <div
            key={`agent-carrier-table-${Carrier.id()}`}
            className={`onboarding-carrier-table-row carrier-is-${priority}`}
          >
            <div className="index">
              <div>{idx + 1}</div>
              <span className="is-priority is-core">$$$</span>
              <span className="is-priority is-preferred">$$</span>
              <span className="is-priority is-standard">$</span>
            </div>
            <div className="identity">
              <h6>{Carrier.get('c_name')}</h6>
              <small>{Carrier.get('c_description')}</small>
              <br />
              <MDBAlert
                color="success"
                className="carrier-priority-pill is-priority is-core"
              >
                Core Carrier
              </MDBAlert>
              <MDBAlert
                color="info"
                className="carrier-priority-pill is-priority is-preferred"
              >
                Preferred Carrier
              </MDBAlert>
              <MDBAlert
                color="secondary"
                className="carrier-priority-pill is-priority is-standard"
              >
                Standard Carrier
              </MDBAlert>
            </div>
            <div className="dispo-dates-splitter">
              {dispoCell}
              {datesCell}
            </div>
            {dispoCell}
            {datesCell}
            <div className="contract-action-btns">
              {renderEditButton(Carrier)}
              {renderDownloadButton(Carrier)}
              {UserProfileService.isA(
                ['agency-owner', 'internal-admin', 'system-admin'],
                true
              ) ? (
                <SendToCarrierButton Contract={Contract} />
              ) : (
                <></>
              )}
            </div>
          </div>
        )
      })
    }

    return <div className="agent-carriers-table">{rows()}</div>
  }

  const isValid =
    ACMStore?.Contracts?.length > 0
      ? ACMStore.Contracts.filter(
          (Contract) => Contract.get('disposition') === 'completed'
        ).length > 0
      : false
  onValidityChange(isValid)

  return (
    <div id="OnboardingWritingNumbersComponent">
      <MDBCollapse isOpen={isValid}>
        <MDBAlert color="success">
          <div className="content-wrapper">
            <ContentHtml
              contentSlug="onboarding-writing-codes-how-to"
              limit="1"
            />
          </div>
        </MDBAlert>
      </MDBCollapse>

      <MDBCard>
        <MDBCardHeader>
          <MDBRow>
            <MDBCol size="12">
              <div>
                <MDBIcon icon="file-contract" />
                &nbsp;My Carrier Contracts
              </div>
              <small>
                These are your current & past contracts. After starting new
                carrier contracts, this is where you will update your Agent
                Writing Number.
              </small>
            </MDBCol>
          </MDBRow>
        </MDBCardHeader>
        <MDBCardBody>{renderTable()}</MDBCardBody>
      </MDBCard>
    </div>
  )
}

export default observer(OnboardingWritingNumbers)
