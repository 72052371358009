import React from 'react'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
} from 'mdbreact'
import { Subscription } from 'rxjs'
import LoadingSpinner from './../../../../components/shared/LoadingSpinner.component'
import { convertToCurrency } from './../../../../shared/formatters/convertToCurrency.formatter'
import { convertToCurrencyWithoutSign } from './../../../../shared/formatters/convertToCurrencyWithoutSign.formatter'
import LeaderboardsStore from './../../store/LeaderboardsPage.store'
import PIPAwardLevel from './../../../../shared/utilities/PipAwardLevel.class'
import { ProfileImage } from './../../helpers/profileImage.function'
import { TeamLogo } from './../../../../shared/utilities/getTeamLogo.function'
import DivisionLogo from './../../../../components/divisions/DivisionLogo/DivisionLogo.component'

import './LeaderboardsResultsTable.scss'
import getQueryParam from '../../../../shared/utilities/getQueryParam.function'
import makeQueryParams from '../../../../shared/utilities/makeQueryParams.function'
import { autoLogin, getAgentAccessToHeadshot } from '../../../../shared/utilities/getHeadshot.function'

const CSR_REC_AWARDS = true

const toFieldname = (str) =>
  str && typeof str === 'string'
    ? str.replace(/[^(\w|\-)+]/gi, '').toLowerCase()
    : ''

const reservedFields = [
  'div_id',
  'div_logo',
  'div_name',
  'total',
  'u_name',
  'division_logo',
  'team_logo',
  'team_name',
  'usertype_id',
  'u_picture',
  'av_points',
  'week_points',
  'ytd_points',
  'rank',
  'user_id',
  'star',
  'best',
]

const formatNum = (num) => {
  switch (LeaderboardsStore.fetchParams.type) {
    case 'points':
      return isNaN(parseFloat(num)) ? num : convertToCurrencyWithoutSign(num)
    case 'av':
      return isNaN(parseFloat(num)) ? num : convertToCurrency(num)
    case 'lives':
      return isNaN(parseInt(num)) ? num : parseInt(num)
    default:
      return num
  }
}

class LeaderboardsResultsTable extends React.Component {
  state = {
    payload: null,
    tableData: {
      columns: [],
      rows: [],
    },
    showAwards: false,
    showUpline: true,
    toggleDivLogo: true,
    reservedFields,
    sortableCols: ['u_name'],
  }

  __subscriptions$ = new Subscription()

  isReserved = (str, watch) =>
    this.state.reservedFields.indexOf(`${str}`.trim().toLowerCase()) > -1

  _createRowObject = (row, columns, totals) => {
    let main = {},
      keys = Object.keys(row).filter(
        (k) => Object.keys(main).indexOf(k) < 0 && !this.isReserved(k)
      )

    columns.forEach((col) => {
      main[col.field] = row[col.field] || ''
    })

    return { keys, main }
  }

  _modifyRow_Totals = (row, totals, keys) => {
    keys.forEach((key) => {
      if (totals.hasOwnProperty(toFieldname(key)))
        totals[toFieldname(key)] += parseFloat(row[key])
    })

    return totals
  }

  _modifyRow_FormatValues = (row, main, keys) => {
    keys.forEach(
      (key) =>
        (main[toFieldname(key)] = this.isReserved(key)
          ? row[key]
          : formatNum(row[key]))
    )
    return main
  }

  _modifyRow_DivLogoOrUpline = (main, row) => {
    if (LeaderboardsStore.fetchParams.agent_type === 'sub_agent_id') {
      if (this.isReserved('division_logo')) {
        main.division_logo = (
          <div className="profile-container">
            <div className="img-wrapper">
              <TeamLogo
                team={{
                  team_logo: row.team_logo,
                  team_name: row.team_name,
                  className: 'leaderboard-division-img',
                }}
              />
            </div>
          </div>
        )
        if (main.hasOwnProperty('up_name')) delete main.up_name
      } else {
        if (main.hasOwnProperty('division_logo')) delete main.division_logo
      }
      if (main?.up_name)
        main.up_name = <div className="text-center">{row.team_name}</div>
      if (main.hasOwnProperty('team_logo')) delete main.team_logo
      if (main.hasOwnProperty('team_name')) delete main.team_name
    } else {
      if (this.isReserved('division_logo')) {
        main.division_logo = (
          <DivisionLogo
            divisionId={row?.div_id}
            divisionLogo={row?.div_logo}
            divisionName={row?.div_name}
            className="leaderboard-division-img"
          />
        )
        if (main.hasOwnProperty('up_name')) delete main.up_name
      } else {
        if (main.hasOwnProperty('division_logo')) delete main.division_logo
      }
      if (main?.up_name)
        main.up_name = <div className="text-center">{main.up_name}</div>
    }

    return main
  }

  _modifyRow_AppendAward = (main, row) => {
    if (this.state.showAwards)
      main.award_level = PIPAwardLevel.getUsertypeAwardBadge(
        row.usertype_id,
        (`${row.ytd_points}` || 0).replace(/[^\d]/g, ''),
        { size: '100px', display: 'block', margin: 'auto' }
      )

    return main
  }

  _modifyRow_FirstToBreakBar = (main, row) => {
    // Identify the first table row
    // where the agent broke-the-bar.
    if (LeaderboardsStore.fetchParams.agent_type === 'ba_team_id') {
      main.u_name = (
        <>
          <div 
            className={row.user_id && getAgentAccessToHeadshot(row.user_id) ? "profile-container cursor-pointer" : "profile-container"}
            onClick={() => {
              if(row.user_id && getAgentAccessToHeadshot(row.user_id)) autoLogin(row.user_id)
            }}
          >
            <div className="img-wrapper">
              <TeamLogo
                team={{
                  team_logo: row?.team_logo || row?.u_picture,
                  team_name: row.team_name,
                  mustBreakBar: LeaderboardsStore.fetchParams.type === 'points',
                  totalPoints: row.total,
                }}
              />
            </div>
            <div className="identity-wrapper">
              <p className="text-center">{row.u_name}</p>
            </div>
          </div>
        </>
      )
    } else {
      main.u_name = (
        <ProfileImage
          usertypeId={row.usertype_id}
          agentName={row.u_name}
          agentPic={row.u_picture}
          mustBreakBar={LeaderboardsStore.fetchParams.type === 'points'}
          totalPoints={row.total}
          firstToBreakBar={!!row?.star}
          bestWeek={!!row?.best}
          agentId={row?.user_id || row?.id}
          agentScope={LeaderboardsStore.getAgentScope()}
        />
      )
    }

    return main
  }

  _modifyRow_AppendTotal = (main, row, totals) => {
    // Append the table's total
    // w/ the row total (if row total exists).
    if (row.hasOwnProperty('total')) {
      main.total = formatNum(row.total)
      if (!totals.hasOwnProperty('total')) totals.total = 0
      totals.total += parseFloat(row.total)
    }

    return main
  }

  _calculateTotals = (totals, columns) => {
    if (Object.keys(totals).length) {
      Object.keys(totals)
        .filter((t) => !this.isReserved(t) && !isNaN(parseFloat(totals[t])))
        .forEach((t) => (totals[t] = formatNum(totals[t])))
      totals.total = formatNum(totals.total)
      return (() => {
        let t = {}
        columns.forEach((col) => {
          t[col.field] = totals.hasOwnProperty(col.field)
            ? totals[col.field]
            : ''
        })
        return t
      })()
    }
  }

  _toggleDivisionUplineColumn = () => {
    this.setState(
      (prevState) => {
        let reservedFields = this.state.reservedFields

        if (reservedFields.includes('division_logo')) {
          reservedFields.splice(reservedFields.indexOf('division_logo'), 1)
          reservedFields.push('up_name')
        } else if (reservedFields.includes('up_name')) {
          reservedFields.splice(reservedFields.indexOf('up_name'), 1)
          reservedFields.push('division_logo')
        }

        return { ...prevState, reservedFields }
      },
      () => {
        this.setState((prevState) => ({
          ...prevState,
          ...this._buildTableData(JSON.parse(this.state.payload)),
        }))
      }
    )
  }

  _renderToggleDivisionUplineLabel = (field) => {
    if (LeaderboardsStore.fetchParams.agent_type === 'sub_agent_id')
      return (
        <div className={`header-toggle-btn-group ${field}`}>
          <span
            className="header-toggle-btn"
            onClick={this._toggleDivisionUplineColumn}
          >
            BA Team Logo&nbsp;
            <MDBIcon far icon="image" className="header-icon" />
          </span>
          <span>|</span>
          <span
            className="header-toggle-btn"
            onClick={this._toggleDivisionUplineColumn}
          >
            BA Team Name&nbsp;
            <MDBIcon icon="user-alt" className="header-icon" />
          </span>
        </div>
      )
    return (
      <div className={`header-toggle-btn-group ${field}`}>
        <span
          className="header-toggle-btn"
          onClick={this._toggleDivisionUplineColumn}
        >
          Division&nbsp;
          <MDBIcon far icon="image" className="header-icon" />
        </span>
        <span>|</span>
        <span
          className="header-toggle-btn"
          onClick={this._toggleDivisionUplineColumn}
        >
          Agent Upline&nbsp;
          <MDBIcon icon="user-alt" className="header-icon" />
        </span>
      </div>
    )
  }

  renderToggleDivisionUplineHeader = () => {
    if (this.isReserved('division_logo')) {
      return {
        label: this._renderToggleDivisionUplineLabel('division_logo'),
        field: 'division_logo',
      }
    }

    return {
      label: this._renderToggleDivisionUplineLabel('up_name'),
      field: 'up_name',
    }
  }

  _isColumnSortable = (c) => {
    if (c) {
      const { sortableCols } = this.state
      if (
        !sortableCols ||
        !sortableCols.length ||
        !sortableCols.includes(`${c}`.toLowerCase())
      )
        return false

      return true
    }

    return false
  }

  _orderByCol = () => {
    let c = ''
    try {
      c = getQueryParam('order_by_col')
    } catch (ex) {}

    // check to see if this value
    // is a sortable column for the
    // current report.
    if (c && !this._isColumnSortable(c)) return ''

    return c ? `${c}`.trim() : ''
  }

  _orderByDir = () => {
    let c = ''
    try {
      c = getQueryParam('order_by_dir')
    } catch (ex) {}
    return c ? `${c}`.trim() : ''
  }

  _renderColumnHeader = (col) => {
    const orderByCol = this._orderByCol()
    const isColSorted = col?.sort_col
        ? col.sort_col === orderByCol
        : col.field === orderByCol,
      sortDir = isColSorted && this._orderByDir()
    const onSortUp = (e) => {
      e.stopPropagation()
      this._onSort(
        sortDir && `${sortDir}` === 'ASC'
          ? { order_by_col: false, order_by_dir: false }
          : { order_by_col: col.sort_col || col.field, order_by_dir: 'ASC' }
      )
    }

    const onSortDown = (e) => {
      e.stopPropagation()
      this._onSort(
        sortDir && `${sortDir}` === 'DESC'
          ? { order_by_col: false, order_by_dir: false }
          : { order_by_col: col.sort_col || col.field, order_by_dir: 'DESC' }
      )
    }
    const onToggle = () => {
      this._onSort(
        sortDir
          ? `${sortDir}` === 'ASC'
            ? { order_by_col: col.sort_col || col.field, order_by_dir: 'DESC' }
            : `${sortDir}` === 'DESC'
            ? { order_by_col: false, order_by_dir: false }
            : { order_by_col: col.sort_col || col.field, order_by_dir: 'ASC' }
          : { order_by_col: col.sort_col || col.field, order_by_dir: 'ASC' }
      )
    }
    return (
      <div
        className={`table-col-sorter ${col?.sort_col ? 'is-sortable' : ''} ${
          sortDir ? 'sort-' + sortDir.toLowerCase() : ''
        }`}
        onClick={onToggle}
      >
        <label>{col.label}</label>
        <div className="sorter">
          <div onClick={onSortUp} className="up">
            <MDBIcon icon="angle-up" />
          </div>
          <div onClick={onSortDown} className="down">
            <MDBIcon icon="angle-down" />
          </div>
        </div>
      </div>
    )
  }
  _onSort = (sort) => {
    let newurl = `${window.location.protocol}//${window.location.host}/leaderboards`
    if (sort.order_by_col && sort.order_by_dir) {
      newurl += '?' + makeQueryParams(sort)
    }

    window.history.pushState({ path: newurl }, '', newurl)
    this._setTableData(JSON.parse(this.state.payload))
  }
  _buildTableData = (tableData) => {
    let showAwards = !!(
        !tableData.config?.categories &&
        (CSR_REC_AWARDS ||
          ['user_id', 'ba_team_id'].includes(tableData?.config.agent_type))
      ),
      showUpline =
        ['weekly', 'monthly'].indexOf(tableData.config?.output_mode) < 0,
      totals = {},
      columns = [
        { label: 'Rank', field: 'rank' },
        { label: 'Agent', field: 'u_name' },
        this.renderToggleDivisionUplineHeader(),
        { label: 'Award Level', field: 'award_level' },
      ]

    tableData.columns = tableData.columns
      .filter((col) => !this.isReserved(col, true))
      .filter((col) =>
        ['up_name', 'division_logo'].includes(col)
          ? this.state.reservedFields.includes(col)
          : true
      )
      .map((col) => {
        return col.label && col.field
          ? col
          : { label: col, field: toFieldname(col) }
      })

    tableData.columns.forEach((col) => (totals[col.field] = 0))
    columns.forEach(
      (col) => (totals[col.field] = col.field === 'u_name' ? 'Totals:' : '')
    )

    tableData.columns.push({ label: 'Total', field: 'total' })
    const orderByCol = this._orderByCol()
    if (orderByCol) {
      const orderByDir = this._orderByDir().toLowerCase() === 'asc' ? 1 : -1
      tableData.rows = tableData.rows.sort((a, b) => {
        return a[orderByCol] > b[orderByCol]
          ? orderByDir
          : a[orderByCol] < b[orderByCol]
          ? -orderByDir
          : 0
      })
    }
    tableData.rows = tableData.rows.map((row) => {
      // Create a modified row object that
      // will be displayed on the MDBDataTable.
      let { keys, main } = this._createRowObject(row, columns)

      // Append/sum the row to the totals object.
      totals = { ...totals, ...this._modifyRow_Totals(row, totals, keys) }

      // Format the numeric values on the row object.
      main = { ...main, ...this._modifyRow_FormatValues(row, main, keys) }

      // Replace/add the division logo OR the agent upline.
      main = { ...this._modifyRow_DivLogoOrUpline(main, row) }

      // Include the agent award, if active.
      main = { ...main, ...this._modifyRow_AppendAward(main, row) }

      // Indicate the agent that first broke the bar.
      main = { ...main, ...this._modifyRow_FirstToBreakBar(main, row) }

      // Append the row total, if exists
      main = { ...main, ...this._modifyRow_AppendTotal(main, row, totals) }

      // Return the modified row object.
      return main
    })

    columns = columns.concat(tableData.columns)
    columns = columns.map((column) => {
      if (this.state.sortableCols.includes(column.field)) {
        column.sort_col = column.field
      }
      return column
    })
    totals = this._calculateTotals(totals, columns)
    if (totals) tableData.rows.push(totals)
    return {
      showAwards,
      showUpline,
      tableData: {
        rows: tableData.rows,
        columns: columns.map((c) => {
          return {
            ...c,
            label: this._renderColumnHeader(c),
          }
        }),
      },
    }
  }

  _setTableData = (payload) => {
    this.setState((prevState) => ({
      ...prevState,
      payload: JSON.stringify(payload),
      ...this._buildTableData(payload),
    }))
  }

  componentDidMount() {
    this.__subscriptions$.add(
      LeaderboardsStore.getTableData().subscribe(this._setTableData)
    )
  }

  componentWillUnmount() {
    this.__subscriptions$.unsubscribe()
  }

  render() {
    return (
      <>
        <MDBContainer
          id="LeaderboardsResultsTable"
          fluid
          className={
            'content-wrapper ' + (this.props.isFetching ? 'is-fetching' : '')
          }
          style={{ padding: 0 }}
        >
          <div className="loading-spinner">
            <LoadingSpinner size="md" />
          </div>
          <MDBRow>
            <MDBCol size="12" className="table-wrapper">
              <MDBTable
                striped
                bordered
                small
                className={
                  'text-capitalize text-truncate leaderboard-table ' +
                  (!this.state.showAwards ? '' : ' incl-awards') +
                  (this.state.showUpline ? '' : ' incl-upline')
                }
              >
                <MDBTableHead
                  columns={this.state.tableData.columns}
                ></MDBTableHead>
                {this.state.tableData.rows &&
                this.state.tableData.rows.length ? (
                  <MDBTableBody
                    rows={this.state.tableData.rows.filter(Boolean)}
                  ></MDBTableBody>
                ) : (
                  <MDBTableBody>
                    <tr>
                      <td
                        colSpan={this.state.tableData.columns.length}
                        className="text-center"
                      >
                        No Data Found
                      </td>
                    </tr>
                  </MDBTableBody>
                )}
              </MDBTable>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </>
    )
  }
}

export default LeaderboardsResultsTable
