import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment-timezone'
import { MDBContainer, MDBBtn, MDBRow, MDBCol, MDBInput } from 'mdbreact'
import WeeklyMarketingCommitment from '../../../../components/modals/MarketingCommitmentModal'

import './WeeklyMarketingCommitmentForm.scss'

const WeeklyMarketingCommitmentForm = () => {
  const [entry, setEntry] = useState(null)

  const check = async () => {
    const e = await WeeklyMarketingCommitment.getEntry()
    if (e) {
      setEntry({
        ...e,
        entry_date: moment(e.entry_date).format('YYYY-MM-DD'),
      })
    } else {
      setEntry({
        entry_date: WeeklyMarketingCommitment.getThisMonday(),
      })
    }
  }

  const onSubmit = event => 
  {
    event.preventDefault();
    return false;
  }

  useEffect(() => {
    check()
  }, [])

  const onKeyPress = (evt) =>
  {
    // permit numbers only.
    try {
      if (!/\d/.test(`${evt.key}`)) {
        evt.preventDefault();
        return false;
      }

      return true;
    }
    catch(ex){
      console.log(ex);
    }
  }

  const onSave = async (invested) => 
  {
    if (parseInt(invested) === 0 || (invested && !isNaN(invested))) {
      try {
        await WeeklyMarketingCommitment.onSaveCommitment(invested)
      } catch (err) {
        console.log(err)
      }
    }
  }

  return entry ? (
    <MDBContainer id="WeeklyMarketingCommitmentForm" fluid>
      <p>
        Marketing
      </p>
      <form noValidate onSubmit={onSubmit}>
        <MDBRow className="metric-intake">
          <MDBCol size="10">
            <label className="text-indigo" htmlFor="investedInput">
              Week {(entry.entry_date ? moment(entry.entry_date,'YYYY-MM-DD').format('w') : '')} Commitment
            </label>
            <br />
            <span>
              How much are you spending on leads each week?
            </span>
          </MDBCol>
          <MDBCol size="2">
            <MDBInput
              type="text"
              className="form-control"
              id="investedInput"
              name="invested"
              value={isNaN(entry?.invested)?0:entry.invested}
              onKeyPress={onKeyPress}
              onChange={(e) => {
                const invested = !isNaN(e.target.value) && e.target.value !== null ? parseInt(e.target.value) : false;
                if (invested !== false && (isNaN(entry?.invested) || (parseInt(entry.invested) !== invested))) {
                  setEntry({...entry, invested})
                  onSave(invested)
                }
              }}
            />
          </MDBCol>
        </MDBRow>
      </form>
    </MDBContainer>
  ) : (
    <></>
  )
}

export default observer(WeeklyMarketingCommitmentForm)
