import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCollapse,
  MDBAlert,
} from 'mdbreact'
import {
  UIInput,
  UISelect,
  UIDatePickerInput,
  UIInputButton,
} from './../../../../components/forms/form-fields'
import AdminApi from './../../../../api/admin-api/admin-api'
import ToolTip from './../../../../components/shared/ToolTip/ToolTip.component'
import { getRandomPassword } from './../../../../components/shared/GeneratePassword'
import moment from 'moment'
import Store from './../../store'

import './ProfileForm.component.scss'
import UserProfileService from '../../../../shared/services/UserProfile.service'
import { toast } from 'react-toastify'

const MAX_DOB = moment().subtract(18, 'years').toDate()

const EmailValidation = {
  int: null,
  callback: async ({ value, upline }) => {
    return new Promise(async (resolve, reject) => {
      let testResults
      try {
        testResults = await Promise.all([
          AdminApi.checkEmailExist({ email: value }),
          AdminApi.isValidProspects({ id: upline?.id, email: value }),
        ])
      } catch (ex) {
        console.log(ex)
      }

      testResults = (Array.isArray(testResults) ? testResults : [])
        .filter((result) => result && parseInt(result?.status) === 200)
        .map((result) => {
          return result && result?.data
        })

      let emailExists = testResults.shift()?.data,
        prospectManager = testResults.shift()?.data

      if (emailExists && typeof emailExists === 'object')
        return resolve(['EMAIL_EXISTS', emailExists])

      if (prospectManager && prospectManager?.manager_name)
        return resolve(['IS_PROSPECT', prospectManager])

      return resolve(true)
    })
  },
}

const getAgentStatus = (active) => {
  if (parseInt(active) === 1) return 'Active'
  if (parseInt(active) === 0) return 'Disabled'
  if (parseInt(active) === -1) return 'Protected'
  if (parseInt(active) === -2) return 'Blacklisted'
}

const ProfileForm = ({ type, method }) => {
  const [accountType, setAccountType] = useState('division');
  const [methodType, setMethodType] = useState('agent');

  useEffect(() => {
    setAccountType(type);
    setMethodType(method)
  }, [type, method])

  const getDate = (field) => {
    if (Store.User?.get(field) && typeof Store.User.get(field) === 'object')
      return moment(Store.User.get(field)).format('MM/DD/YYYY')

    if (Store.User?.get(field) && typeof Store.User.get(field) === 'string') {
      if (/\d{4}-\d{2}-\d{2}/.test(Store.User.get(field)))
        return moment(Store.User.get(field), 'YYYY-MM-DD').format('MM/DD/YYYY')
    }

    return Store.User?.get(field)
  }

  const checkCanEmailEnroll = async ({ value }) => {
    if (value) {
      // if timer is set, clear it out.
      if (EmailValidation.int) {
        window.clearTimeout(EmailValidation.int)
        EmailValidation.int = null
      }

      return new Promise((resolve, reject) => {
        EmailValidation.int = setTimeout(async () => {
          let result = await EmailValidation.callback({
            value,
            upline: Store.User?.get('u_upline_id'),
          })

          window.clearTimeout(EmailValidation.int)
          EmailValidation.int = null

          if (result === true)
            return resolve({
              isValid: true,
              errors: [],
            })

          if (result[0] === 'EMAIL_EXISTS') {
            return resolve({
              isValid: false,
              errors: [renderEmailExistsError(result[1])],
            })
          }

          if (result[0] === 'IS_PROSPECT') {
            return resolve({
              isValid: false,
              errors: [renderProspectExistsError(result[1])],
            })
          }
        }, 250)
      })
    }
  }

  const renderEmailExistsError = (emailExists) => {
    return (
      <ToolTip
        label={'Email Exists'}
        placement="≈"
        isVisible={true}
        content={
          <table className="email-exists-table">
            <thead>
              <tr>
                <th colSpan="2">Invalid Email!</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Email:</td>
                <td>{emailExists?.u_email}</td>
              </tr>
              <tr>
                <td>Upline:</td>
                <td>{emailExists?.u_upline}</td>
              </tr>
              {emailExists?.u_enrolled !== '0000-00-00' ? (
                <tr>
                  <td>Enrolled:</td>
                  <td>
                    {moment(emailExists?.u_enrolled).format('MM/DD/YYYY')}
                  </td>
                </tr>
              ) : (
                <></>
              )}
              <tr>
                <td>Status:</td>
                <td>{getAgentStatus(emailExists?.u_active)}</td>
              </tr>
              {!emailExists?.u_active ? (
                <tr>
                  <td colSpan="2">Contact Broker Support for details.</td>
                </tr>
              ) : (
                <></>
              )}
            </tbody>
          </table>
        }
      />
    )
  }

  const renderProspectExistsError = (prospectExists) => {
    return (
      <ToolTip
        label={'Email Designated'}
        placement="bottom"
        isVisible={true}
        content={
          <table className="email-exists-table">
            <thead>
              <tr>
                <th colSpan="2">Invalid Email!</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="2">
                  This email is designated as a prospect for{' '}
                  {prospectExists.manager_name}.
                </td>
              </tr>
            </tbody>
          </table>
        }
      />
    )
  }

  const onSubmit = ($evt) => {
    $evt.preventDefault()

    const isValid = Store.isFormValid() && Store.isValidMarketingTitle()
    if (!Store.isValidMarketingTitle()) {
      toast.error('Invalid Marketing title', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }

    Store.showFormValidity = Store.User
      ? Store.showFormValidity || !isValid
      : false

    if (isValid) return Store.createUser()

    return false
  }

  return (
    <form
      id="ProfileFormComponent"
      noValidate
      onSubmit={($evt) => onSubmit($evt)}
    >
      <MDBRow>
        <MDBCol size="12">
          <MDBCollapse isOpen={Store.User?.isNew() === false}>
            <MDBAlert color="success">
              <MDBIcon far icon="check-circle" />
              &nbsp;<strong>Great!</strong>&nbsp;The new user account has been
              created & a welcome email has been sent to{' '}
              {Store.User?.get('u_email')}.
            </MDBAlert>
          </MDBCollapse>

          <MDBCard className="form-group-card">
            <MDBCardHeader>
              <h5 className="font--lato">User Identity</h5>
            </MDBCardHeader>
            <MDBCardBody>
              <MDBRow>
                <MDBCol size="12" sm="6" lg="4">
                  <UIInput
                    name="u_fname"
                    label="Legal First Name"
                    value={Store.User?.get('u_fname') || ''}
                    onChange={(evt) => {
                      if (
                        Store.User &&
                        Store.User?.get('u_fname') !== evt?.target?.value
                      )
                        Store.User.set('u_fname', evt.target.value)
                    }}
                    onValidityChange={(onValidity) =>
                      (Store.formValidity.u_fname = onValidity)
                    }
                    showValidity={Store.showFormValidity}
                    rules={{
                      required: true,
                      minLength: 2,
                      maxLength: 255,
                      format: 'name',
                    }}
                    required
                    disabled={
                      Store.isLoading ||
                      Store.isSaving ||
                      Store.User?.isNew() === false
                    }
                  />
                </MDBCol>
                <MDBCol size="12" sm="6" lg="4">
                  <UIInput
                    name="u_lname"
                    label="Legal Last Name"
                    value={Store.User?.get('u_lname') || ''}
                    onChange={(evt) => {
                      if (
                        Store.User &&
                        Store.User?.get('u_lname') !== evt?.target?.value
                      )
                        Store.User.set('u_lname', evt.target.value)
                    }}
                    onValidityChange={(onValidity) =>
                      (Store.formValidity.u_lname = onValidity)
                    }
                    showValidity={Store.showFormValidity}
                    rules={{
                      required: true,
                      minLength: 2,
                      maxLength: 255,
                      format: 'name',
                    }}
                    required
                    disabled={
                      Store.isLoading ||
                      Store.isSaving ||
                      Store.User?.isNew() === false
                    }
                  />
                </MDBCol>
                <MDBCol size="12" sm="6" lg="4">
                  <UIInput
                    name="u_marketing_title"
                    label="Marketing Title"
                    value={Store.User?.get('u_marketing_title') || ''}
                    onChange={(evt) => {
                      if (
                        Store.User &&
                        Store.User?.get('u_marketing_title') !==
                          evt?.target?.value
                      )
                        Store.User.set('u_marketing_title', evt.target.value)
                    }}
                    onValidityChange={(onValidity) =>
                      (Store.formValidity.u_marketing_title = onValidity)
                    }
                    showValidity={Store.showFormValidity}
                    rules={{ minLength: 2, maxLength: 255 }}
                    disabled={
                      Store.isLoading ||
                      Store.isSaving ||
                      Store.User?.isNew() === false
                    }
                  />
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="12">
          <MDBCard className="form-group-card">
            <MDBCardHeader>
              <h5 className="font--lato">User Profile</h5>
            </MDBCardHeader>
            <MDBCardBody>
              <MDBRow>
                <MDBCol size="12" sm="6" lg="4">
                  <UIInput
                    name="u_email"
                    label="Email Address"
                    value={Store.User?.get('u_email') || ''}
                    onChange={(evt) => {
                      if (
                        Store.User &&
                        Store.User?.get('u_email') !== evt?.target?.value
                      )
                        Store.User.set('u_email', evt.target.value)
                    }}
                    onValidityChange={(onValidity) =>
                      (Store.formValidity.u_email = onValidity)
                    }
                    showValidity={Store.showFormValidity}
                    rules={{
                      required: true,
                      maxLength: 255,
                      format: 'email',
                      callback: async ({ value }) =>
                        await checkCanEmailEnroll({ value }),
                    }}
                    required
                    disabled={
                      Store.isLoading ||
                      Store.isSaving ||
                      Store.User?.isNew() === false
                    }
                  />
                </MDBCol>
                <MDBCol size="12" sm="6" lg="4">
                  <UIInputButton
                    input={
                      <UIInput
                        name="u_password"
                        label="Password"
                        value={Store.User?.get('u_password') || ''}
                        onChange={(evt) => {
                          if (
                            Store.User &&
                            Store.User?.get('u_password') !== evt?.target?.value
                          )
                            Store.User.set('u_password', evt.target.value)
                        }}
                        onValidityChange={(onValidity) =>
                          (Store.formValidity.u_password = onValidity)
                        }
                        showValidity={Store.showFormValidity}
                        rules={{ required: true, minLength: 2, maxLength: 255 }}
                        required
                        disabled={
                          Store.isLoading ||
                          Store.isSaving ||
                          Store.User?.isNew() === false
                        }
                      />
                    }
                    button={
                      <MDBBtn
                        disabled={
                          Store.isLoading ||
                          Store.isSaving ||
                          Store.User?.isNew() === false
                        }
                        onClick={() =>
                          Store.User.set('u_password', getRandomPassword())
                        }
                      >
                        <small>Generate</small>
                      </MDBBtn>
                    }
                    btnPosition={'right'}
                  />
                </MDBCol>
                <MDBCol size="12" sm="6" lg="4">
                  <UIInput
                    name="u_phone"
                    label="Phone"
                    value={Store.User?.get('u_phone') || ''}
                    onChange={(evt) => {
                      if (
                        Store.User &&
                        Store.User?.get('u_phone') !== evt?.target?.value
                      )
                        Store.User.set('u_phone', evt.target.value)
                    }}
                    onValidityChange={(onValidity) =>
                      (Store.formValidity.u_phone = onValidity)
                    }
                    showValidity={Store.showFormValidity}
                    disabled={
                      Store.isLoading ||
                      Store.isSaving ||
                      Store.User?.isNew() === false
                    }
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="12" sm="6" lg="6">
                  <UIDatePickerInput
                    label="Date of Birth"
                    name="u_birthday"
                    id="dp_u_birthday"
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    dateFormat="MM/dd/yyyy"
                    maxDate={MAX_DOB}
                    value={getDate('u_birthday')}
                    onChange={(date) => {
                      if (
                        Store.User &&
                        date?.target?.value &&
                        moment(date.target.value).format('YYYY-MM-DD') !==
                          Store.User.get('u_birthday')
                      )
                        Store.User.set(
                          'u_birthday',
                          moment(date.target.value).format('YYYY-MM-DD')
                        )
                    }}
                    autoComplete="off"
                    onValidityChange={(onValidity) =>
                      (Store.formValidity.u_birthday = onValidity)
                    }
                    showValidity={Store.showFormValidity}
                    required
                    disabled={
                      Store.isLoading ||
                      Store.isSaving ||
                      Store.User?.isNew() === false
                    }
                  />
                </MDBCol>
                <MDBCol size="12" sm="6" lg="6">
                  <UISelect
                    name="u_timezone"
                    label="Timezone"
                    options={Store.timezones.map((tz) => ({
                      value: `${tz.id}`,
                      text: tz.name,
                      checked:
                        `${Store.User?.get('u_timezone') || ''}` === `${tz.id}`,
                    }))}
                    value={Store.User?.get('u_timezone') || ''}
                    onChange={(evt) => {
                      if (
                        Store.User &&
                        Store.User?.get('u_timezone') !== evt?.target?.value
                      )
                        Store.User.set('u_timezone', evt.target.value)
                    }}
                    onValidityChange={(onValidity) =>
                      (Store.formValidity.u_timezone = onValidity)
                    }
                    showValidity={Store.showFormValidity}
                    required
                    disabled={
                      Store.isLoading ||
                      Store.isSaving ||
                      Store.User?.isNew() === false
                    }
                  />
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="12">
          <MDBCard className="form-group-card">
            <MDBCardHeader>
              <h5 className="font--lato">User Address</h5>
            </MDBCardHeader>
            <MDBCardBody>
              <MDBRow>
                <MDBCol size="12" sm="6" lg="3">
                  <UIInput
                    name="u_address1"
                    label="Street Address"
                    value={Store.User?.get('u_address1') || ''}
                    onChange={(evt) => {
                      if (
                        Store.User &&
                        Store.User?.get('u_address1') !== evt?.target?.value
                      )
                        Store.User.set('u_address1', evt.target.value)
                    }}
                    onValidityChange={(onValidity) =>
                      (Store.formValidity.u_address1 = onValidity)
                    }
                    showValidity={Store.showFormValidity}
                    rules={{ required: true, minLength: 2, maxLength: 255 }}
                    required
                    disabled={
                      Store.isLoading ||
                      Store.isSaving ||
                      Store.User?.isNew() === false
                    }
                  />
                </MDBCol>
                <MDBCol size="12" sm="6" lg="2">
                  <UIInput
                    name="u_address2"
                    label="Suite / Apt / Office #"
                    value={Store.User?.get('u_address2') || ''}
                    onChange={(evt) => {
                      if (
                        Store.User &&
                        Store.User?.get('u_address2') !== evt?.target?.value
                      )
                        Store.User.set('u_address2', evt.target.value)
                    }}
                    onValidityChange={(onValidity) =>
                      (Store.formValidity.u_address2 = onValidity)
                    }
                    showValidity={Store.showFormValidity}
                    disabled={
                      Store.isLoading ||
                      Store.isSaving ||
                      Store.User?.isNew() === false
                    }
                  />
                </MDBCol>
                <MDBCol size="12" sm="6" lg="3">
                  <UIInput
                    name="u_city"
                    label="City"
                    value={Store.User?.get('u_city') || ''}
                    onChange={(evt) => {
                      if (
                        Store.User &&
                        Store.User?.get('u_city') !== evt?.target?.value
                      )
                        Store.User.set('u_city', evt.target.value)
                    }}
                    onValidityChange={(onValidity) =>
                      (Store.formValidity.u_city = onValidity)
                    }
                    showValidity={Store.showFormValidity}
                    rules={{ required: true, minLength: 2, maxLength: 255 }}
                    disabled={
                      Store.isLoading ||
                      Store.isSaving ||
                      Store.User?.isNew() === false
                    }
                    required
                  />
                </MDBCol>
                <MDBCol size="12" sm="6" lg="2">
                  <UISelect
                    name="u_state"
                    label="State"
                    options={Store.states.map((st) => ({
                      value: `${st.id}`,
                      text: st.name,
                      checked:
                        `${Store.User?.get('u_state') || ''}` === `${st.id}`,
                    }))}
                    value={Store.User?.get('u_state') || ''}
                    onChange={(evt) => {
                      if (
                        Store.User &&
                        `${Store.User?.get('u_state')}` !==
                          `${evt?.target?.value}`
                      )
                        Store.User.set('u_state', evt.target.value)
                    }}
                    onValidityChange={(onValidity) =>
                      (Store.formValidity.u_state = onValidity)
                    }
                    showValidity={Store.showFormValidity}
                    disabled={
                      Store.isLoading ||
                      Store.isSaving ||
                      Store.User?.isNew() === false
                    }
                    required
                  />
                </MDBCol>
                <MDBCol size="12" sm="6" lg="2">
                  <UIInput
                    name="u_zip"
                    label="Zip Code"
                    value={Store.User?.get('u_zip') || ''}
                    onChange={(evt) => {
                      if (
                        Store.User &&
                        Store.User?.get('u_zip') !== evt?.target?.value
                      )
                        Store.User.set('u_zip', evt.target.value)
                    }}
                    onValidityChange={(onValidity) =>
                      (Store.formValidity.u_zip = onValidity)
                    }
                    showValidity={Store.showFormValidity}
                    rules={{
                      required: true,
                      minLength: 5,
                      maxLength: 5,
                      pattern: /^[0-9]{5}$/,
                    }}
                    disabled={
                      Store.isLoading ||
                      Store.isSaving ||
                      Store.User?.isNew() === false
                    }
                    required
                  />
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="12">
          <MDBCard className="form-group-card">
            <MDBCardHeader>
              <h5 className="font--lato">Agent Setup</h5>
            </MDBCardHeader>
            <MDBCardBody>
              <MDBRow>
                <MDBCol size="12" sm="6" lg="3">
                  {accountType === 'division' ? (
                    <UISelect
                      name="u_devision"
                      label="Division"
                      options={Store.Divisions.filter(
                        (Division) =>
                          !Store.lockedDivisionId ||
                          `${Store.lockedDivisionId}` === `${Division.id()}`
                      ).map((Division) => ({
                        value: `${Division.id()}`,
                        text: Division.get('d_name'),
                        checked:
                          `${Store.User?.get('u_devision') || ''}` ===
                          `${Division.id()}`,
                      }))}
                      value={Store.User?.get('u_devision') || ''}
                      onChange={(evt) => {
                        if (
                          Store.User &&
                          `${Store.User?.get('u_devision')}` !==
                            `${evt?.target?.value}`
                        ) {
                          Store.User.set('u_devision', evt.target.value)
                          Store.getUplineUsers()
                        }
                      }}
                      onValidityChange={(onValidity) =>
                        (Store.formValidity.u_devision = onValidity)
                      }
                      showValidity={Store.showFormValidity}
                      required
                      disabled={
                        Store.isLoading ||
                        Store.isSaving ||
                        Store.lockedDivisionId ||
                        Store.User?.isNew() === false
                      }
                    />
                  ) : accountType === 'region' ? (
                    <UISelect
                      name="region"
                      label="Region"
                      options={Store.Regions.map((Region) => ({
                        value: `${Region.id()}`,
                        text:
                          Region.get('u_fname') + ' ' + Region.get('u_lname'),
                        checked: `${Store.lockedRegionId}` === `${Region.id()}`,
                      }))}
                      value={Store.lockedRegionId || ''}
                      onChange={(evt) => {
                        if (
                          Store.User &&
                          `${Store.User?.get('u_upline_id')}` !==
                            `${evt?.target?.value}`
                        ) {
                          Store.User.set('u_upline_id', evt.target.value)
                          Store.lockedRegionId = evt.target.value
                          Store.getUplineUsers()
                        }
                      }}
                      onValidityChange={(onValidity) =>
                        (Store.formValidity.u_devision = onValidity)
                      }
                      showValidity={Store.showFormValidity}
                      required
                      disabled={
                        Store.isLoading ||
                        Store.isSaving ||
                        UserProfileService.getCurrentUserTypeId() === 38 ||
                        Store.User?.isNew() === false
                      }
                    />
                  ) : (
                    <UISelect
                      name="district"
                      label="District"
                      options={Store.Districts.map((District) => ({
                        value: `${District.id()}`,
                        text:
                          District.get('u_fname') +
                          ' ' +
                          District.get('u_lname'),
                        checked:
                          `${Store.lockedDistrictId}` === `${District.id()}`,
                      }))}
                      value={Store.lockedDistrictId || ''}
                      onChange={(evt) => {
                        if (
                          Store.User &&
                          `${Store.User?.get('u_upline_id')}` !==
                            `${evt?.target?.value}`
                        ) {
                          Store.User.set('u_upline_id', evt.target.value)
                          Store.lockedDistrictId = evt.target.value
                          Store.getUplineUsers()
                        }
                      }}
                      onValidityChange={(onValidity) =>
                        (Store.formValidity.u_devision = onValidity)
                      }
                      showValidity={Store.showFormValidity}
                      required
                      disabled={
                        Store.isLoading ||
                        Store.isSaving ||
                        UserProfileService.getCurrentUserTypeId() === 93 ||
                        Store.User?.isNew() === false
                      }
                    />
                  )}
                </MDBCol>
                <MDBCol size="12" sm="6" lg="4">
                  <UISelect
                    name="u_upline_id"
                    label="Upline"
                    options={Store.UplineUsers.map((User) => ({
                      value: `${User.id()}`,
                      text: `${User.get('u_fname')} ${User.get('u_lname')}`,
                      checked:
                        `${Store.User?.get('u_upline_id') || ''}` ===
                        `${User.id()}`,
                    }))}
                    value={Store.User?.get('u_upline_id') || ''}
                    onChange={(evt) => {
                      if (
                        Store.User &&
                        `${Store.User?.get('u_upline_id')}` !==
                          `${evt?.target?.value}`
                      )
                        Store.User.set('u_upline_id', evt.target.value)
                    }}
                    onValidityChange={(onValidity) =>
                      (Store.formValidity.u_upline_id = onValidity)
                    }
                    showValidity={Store.showFormValidity}
                    required={
                      !(UserProfileService.isA([
                        'system-admin',
                        'internal-admin',
                        'agency-owner',
                      ]) && methodType === 'agent')
                    }
                    disabled={
                      Store.isLoading ||
                      Store.isSaving ||
                      !Store.User?.get('u_devision') ||
                      isNaN(Store.User.get('u_devision')) ||
                      Store.User?.isNew() === false ||
                      (Store.lockUplineIds && Store.lockUplineIds.length === 1)
                    }
                  />
                </MDBCol>
                <MDBCol size="12" sm="6" lg="4">
                  <UIInputButton
                    input={
                      <UIDatePickerInput
                        label="Enrollment Date"
                        name="u_enrolled"
                        id="dp_u_enrolled"
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        dateFormat="MM/dd/yyyy"
                        maxDate={new Date()}
                        value={getDate('u_enrolled')}
                        selected={
                          getDate('u_enrolled')
                            ? moment(
                                getDate('u_enrolled'),
                                'MM/DD/YYYY'
                              ).toDate()
                            : Store.User?.get('u_enrolled')
                        }
                        onChange={(date) => {
                          if (
                            Store.User &&
                            date?.target?.value &&
                            moment(date.target.value).format('YYYY-MM-DD') !==
                              Store.User.get('u_enrolled')
                          )
                            Store.User.set(
                              'u_enrolled',
                              moment(date.target.value).format('YYYY-MM-DD')
                            )
                        }}
                        autocomplete="off"
                        onValidityChange={(onValidity) =>
                          (Store.formValidity.u_enrolled = onValidity)
                        }
                        showValidity={Store.showFormValidity}
                        required
                        disabled={
                          Store.isLoading ||
                          Store.isSaving ||
                          Store.User?.isNew() === false
                        }
                      />
                    }
                    button={
                      <MDBBtn
                        disabled={
                          Store.isLoading ||
                          Store.isSaving ||
                          Store.User?.isNew() === false
                        }
                        onClick={() => {
                          Store.User.set(
                            'u_enrolled',
                            moment().format('YYYY-MM-DD')
                          )
                          Store.formValidity.u_enrolled = { isValid: true }
                        }}
                      >
                        <small>Today</small>
                      </MDBBtn>
                    }
                    btnPosition={'right'}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="12" sm="6" lg="4">
                  <UISelect
                    name="usertype_id"
                    label="Usertype"
                    options={Store.Usertypes.filter(
                      (Usertype) =>
                        !Store.lockUsertypeIds ||
                        Store.lockUsertypeIds.includes(`${Usertype.id()}`)
                    ).map((Usertype) => ({
                      value: `${Usertype.id()}`,
                      text: Usertype.get('displayname'),
                      checked:
                        `${Store.User?.get('usertype_id') || ''}` ===
                        `${Usertype.id()}`,
                    }))}
                    value={Store.User?.get('usertype_id') || ''}
                    onChange={async (evt) => {
                      if (
                        Store.User &&
                        `${Store.User?.get('usertype_id')}` !==
                          `${evt?.target?.value}`
                      ) {
                        if(methodType === 'agent') {
                          if(
                            UserProfileService.isA([
                              'system-admin',
                              'internal-admin',
                              'internal-staff',
                              'agency-owner',
                            ])
                          ) Store.User.set('u_upline_id', null)

                          switch (parseInt(evt.target.value)) {
                            case 225:
                            case 226:
                              setAccountType('division')
                              break;

                            case 227:
                              setAccountType('region')
                              Store.User.set('u_devision', 0)
                              Store.UplineUsers = []
                              break;

                            case 228:
                              setAccountType('district')
                              Store.User.set('u_devision', 0)
                              Store.UplineUsers = []
                              break;

                            default:
                              break;
                          }
                        }
                        Store.User.set('usertype_id', evt.target.value)
                      }
                    }}
                    onValidityChange={(onValidity) =>
                      (Store.formValidity.usertype_id = onValidity)
                    }
                    showValidity={Store.showFormValidity}
                    required
                    disabled={
                      Store.isLoading ||
                      Store.isSaving ||
                      Store.User?.isNew() === false ||
                      (Store.lockUsertypeIds &&
                        Store.lockUsertypeIds.length === 1)
                    }
                  />
                </MDBCol>
                <MDBCol size="12" sm="6" lg="4">
                  <UISelect
                    name="commission_id"
                    label="Commission Level"
                    options={Store.CommLevels.filter(
                      (CommLevel) =>
                        !Store.lockCommLevelIds ||
                        Store.lockCommLevelIds.includes(`${CommLevel.id()}`)
                    ).map((CommLevel) => ({
                      value: `${CommLevel.id()}`,
                      text: CommLevel.get('com_name'),
                      checked:
                        `${Store.User?.get('commission_id') || ''}` ===
                        `${CommLevel.id()}`,
                    }))}
                    value={Store.User?.get('commission_id') || ''}
                    onChange={(evt) => {
                      if (
                        Store.User &&
                        `${Store.User?.get('commission_id')}` !==
                          `${evt?.target?.value}`
                      )
                        Store.User.set('commission_id', evt.target.value)
                    }}
                    onValidityChange={(onValidity) =>
                      (Store.formValidity.commission_id = onValidity)
                    }
                    showValidity={Store.showFormValidity}
                    required
                    disabled={
                      Store.isLoading ||
                      Store.isSaving ||
                      Store.User?.isNew() === false ||
                      (Store.lockCommLevelIds &&
                        Store.lockCommLevelIds.length === 1)
                    }
                  />
                </MDBCol>
                <MDBCol size="12" sm="6" lg="4">
                  <UISelect
                    name="u_active"
                    label="Account Status"
                    options={Store.accountStatuses
                      .filter(
                        (AcctStatus) =>
                          !Store.lockAccountStatuses ||
                          Store.lockAccountStatuses.includes(`${AcctStatus.id}`)
                      )
                      .map((as) => ({
                        value: `${as.id}`,
                        text: as.name,
                        checked:
                          `${Store.User?.get('u_active') || ''}` === `${as.id}`,
                      }))}
                    value={Store.User?.get('u_active') || ''}
                    onChange={(evt) => {
                      if (
                        Store.User &&
                        Store.User?.get('u_active') !== evt?.target?.value
                      )
                        Store.User.set('u_active', evt.target.value)
                    }}
                    onValidityChange={(onValidity) =>
                      (Store.formValidity.u_active = onValidity)
                    }
                    showValidity={Store.showFormValidity}
                    required
                    disabled={
                      Store.isLoading ||
                      Store.isSaving ||
                      Store.User?.isNew() === false ||
                      (Store.lockAccountStatuses &&
                        Store.lockAccountStatuses.length === 1)
                    }
                  />
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="12">
          <MDBBtn
            type="submit"
            className="btn-block"
            disabled={
              Store.User?.isNew() === false || Store.isLoading || Store.isSaving
            }
          >
            {Store.isSaving ? (
              <>
                <i className="fa fa-spin fa-spinner"></i>&nbsp;Saving...
              </>
            ) : (
              <>
                <i className="fa fa-save"></i>&nbsp;Save
              </>
            )}
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </form>
  )
}

export default observer(ProfileForm)
