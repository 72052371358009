import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import AdminContentEditPopupComponent from './../../../components/adminShared/AdminContentEditPopupComponent'
import AgencyContentStore from './../../../shared/stores/agencyContent.store'

import './ContentHtml.scss'

const ContentHtml = ({ contentId, contentSlug, onLoad, limit, editor }) => {
  // Agency-Content Selector ---------------------------------------
  const { fetchByContentObj } = AgencyContentStore
  useEffect(() => {
    fetchByContentObj({ contentId, contentSlug, limit })
      .then((res) => (typeof onLoad === 'function' ? onLoad(res) : null))
      .catch(() => (typeof onLoad === 'function' ? onLoad(false) : null))
  }, [fetchByContentObj, contentId, contentSlug, onLoad, limit])
  // ---------------------------------------------------------------

  const get = () =>
    AgencyContentStore.content[contentId || contentSlug]
      ? Array.isArray(AgencyContentStore.content[contentId || contentSlug])
        ? AgencyContentStore.content[contentId || contentSlug]
        : [AgencyContentStore.content[contentId || contentSlug]]
      : []

  return (
    <div className="ContentHtmlComponent">
      {get().map((entry, idx) => {
        const { id, content } = entry
        return (
          <div
            className="content-html-inner-wrapper"
            key={`content-entry-${idx}`}
          >
            <div dangerouslySetInnerHTML={{ __html: content }} />
            {id || contentId ? (
              <AdminContentEditPopupComponent
                type="RTE"
                color="blue"
                pageId={id || contentId}
                color={editor && editor?.iconColor}
                iconPosition={(editor && editor?.iconPosition) || 'top-right'}
                iconStyle={
                  (editor && editor?.iconStyle) || {
                    transform: 'translate(-3px, 3px)',
                  }
                }
                callback={(contentId) => fetchByContentObj({ contentId })}
              />
            ) : (
              <></>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default observer(ContentHtml)
