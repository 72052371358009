import AdminApi from './../../../api/admin-api/admin-api'
import UserProfileService from './../../../shared/services/UserProfile.service'
import UsersCarrierContractService from '../../../shared/services/UsersCarrierContract.service'
import UserService from './../../../shared/services/User.service'
import DateService from './../../../shared/services/Date.service'
import { BehaviorSubject } from 'rxjs'

const activeSaleSubject = new BehaviorSubject(null)
const recentSalesSubject = new BehaviorSubject([])
const coveragesListSubject = new BehaviorSubject([])
const leadsListSubject = new BehaviorSubject([])
const carriersListSubject = new BehaviorSubject([])
const productsListSubject = new BehaviorSubject([])
const guidelinesSubject = new BehaviorSubject(null)

const getSaleById = async (avId) => {
  return new Promise((resolve, reject) => {
    AdminApi.getAgentSalesById(avId)
      .then((result) =>
        result && result.data && result.data.data
          ? resolve(result.data.data)
          : reject()
      )
      .catch((err) => reject(err))
  })
}

const setActiveSale = async (avId) => {
  return new Promise((resolve, reject) => {
    getSaleById(avId)
      .then((AvSale) => {
        activeSaleSubject.next(AvSale)
        resolve(AvSale)
      })
      .catch((error) => reject(error.message))
  })
}

const saveActiveSale = async (activeSale) => {
  return new Promise((resolve, reject) => {
    AdminApi.submitSales(activeSale)
      .then(() => {
        // Re-fetch the latest sales data.
        SubmitSalesService.fetchRecentSales(false)

        resolve()
      })
      .catch((error) => reject(error.message))
  })
}

const fetchLeadSourcesList = async () => {
  return new Promise((resolve, reject) => {
    AdminApi.getLeadSourcesSortByName()
      .then((result) => {
        result =
          result && result.data && result.data.data ? result.data.data : []
        leadsListSubject.next(result)
        resolve(result)
      })
      .catch((error) => reject(error.message))
  })
}

const fetchCoveragesList = () => {
  return new Promise((resolve, reject) => {
    AdminApi.getCoveragesSortByName()
      .then((result) => {
        result =
          result && result.data && result.data.data ? result.data.data : []
        coveragesListSubject.next(result)
        resolve(result)
      })
      .catch((error) => reject(error.message))
  })
}

const fetchRecentSales = async (proceedToNext) => {
  // this.setState({loading: true});
  AdminApi.getAgentRecentFiveSales()
    .then((result) => {
      if (result && result.data && result.data.data) {
        // this.setState({recentSales: result.data.data});
        recentSalesSubject.next(result.data.data)
      }

      if (proceedToNext) fetchCoveragesList()
    })
    .catch((error) => {
      // this.setState({loading: false});
      // toast.error(error.message, {position: toast.POSITION.TOP_RIGHT});
    })
}

const fetchCarriers = async () => {
  return new Promise((resolve, reject) => {
    AdminApi.getCarriers()
      .then((result) => {
        result =
          result && result.data && result.data.data ? result.data.data : []
        carriersListSubject.next(result)
        resolve(result)
      })
      .catch((error) => reject(error.message))
  })
}

const fetchCarriersByCoverage = async (coverageId) => {
  return new Promise((resolve, reject) => {
    AdminApi.getCarriersByCoverage(coverageId)
      .then((result) => {
        result =
          result && result.data && result.data.data ? result.data.data : []
        carriersListSubject.next(result)
        resolve(result)
      })
      .catch((error) => reject(error.message))
  })
}

// const fetchProductsByCarrier = async (carrierId) => {
//   return new Promise((resolve, reject) => {
//     AdminApi.getProductsByCarrier(carrierId)
//       .then((result) => {
//         result =
//           result && result.data && result.data.data ? result.data.data : []
//         productsListSubject.next(result)
//         resolve(result)
//       })
//       .catch((error) => reject(error.message))
//   })
// }

const fetchDependencies = (avId) => {
  let fetchSale = Promise.resolve(activeSaleSubject.getValue())

  if (parseInt(avId) > 0)
    fetchSale = new Promise((resolve, reject) => {
      setActiveSale(avId)
        .then((AvSale) => resolve(AvSale))
        .catch((error) => reject(error))
    })

  return new Promise((resolve, reject) => {
    fetchSale.then((AvSale) => {
      Promise.all([
        fetchCoveragesList(),
        fetchCarriers(),
        fetchLeadSourcesList(),
      ])
        .then((dependencies) => {
          if (AvSale) {
            // fetchProductsByCarrier(AvSale.carrier_id)
            //   .then((products) => {
            //     resolve({
            //       coverages: dependencies[0],
            //       carriers: dependencies[1],
            //       leads: dependencies[2],
            //       products: products,
            //       activeSale: AvSale,
            //     })
            //   })
            //   .catch((error) => reject(error))
            resolve({
              coverages: dependencies[0],
              carriers: dependencies[1],
              leads: dependencies[2],
              products: [],
              activeSale: AvSale,
            })
          } else {
            resolve({
              coverages: dependencies[0],
              carriers: dependencies[1],
              leads: dependencies[2],
            })
          }
        })
        .catch((error) => reject(error))
    })
  })
}

const checkAgentHasCarrierWritingCode = async (carrierId) => {
  const agentContracts = await UsersCarrierContractService.search({
    search: { carrier_id: carrierId, user_id: UserProfileService.getUserId() },
    pagination: false,
    order_by: { id: 'DESC' },
  })
  const agentContract =
    agentContracts.models && Array.isArray(agentContracts.models)
      ? agentContracts.models
          .filter((uc) => uc.disposition !== 'expired')
          .shift()
      : undefined
  return agentContract
}

const hasUserAcceptedGuidelines = async (userId) => {
  let isAccepted =
    parseInt(userId) > 0
      ? await UserService.getUserMetas(
          'profile---accept-sales-guidelines',
          userId
        )
      : await UserProfileService.getUserMetas(
          'profile---accept-sales-guidelines'
        )
  isAccepted =
    Array.isArray(isAccepted) &&
    (isAccepted = isAccepted.shift()) &&
    isAccepted.meta_value
  if (!userId) guidelinesSubject.next(isAccepted)
  return isAccepted
}

const acceptGuidelines = async () =>
  await UserProfileService.upsertUserMeta(
    'profile---accept-sales-guidelines',
    DateService.dateOnlyToString(new Date())
  )

const resetAcceptance = async (userId) =>
  await UserService.upsertUserMeta(
    'profile---accept-sales-guidelines',
    '',
    userId
  )

const findAgentCarrierContract = async (carrierId) => {
  let agentContracts = null
  try {
    agentContracts = await UsersCarrierContractService.search({
      search: {
        carrier_id: carrierId,
        user_id: UserProfileService.getUserId(),
      },
      pagination: false,
      order_by: { created_at: 'DESC' },
    })
    return agentContracts.models && Array.isArray(agentContracts.models)
      ? agentContracts.models.shift()
      : undefined
  } catch (ex) {
    console.error(`${ex}`)
  }
}

const findUnexpiredAgentCarrierContract = async (carrierId) => {
  const agentContract = await findAgentCarrierContract(carrierId)

  return agentContract &&
    agentContract?.disposition &&
    agentContract.disposition !== 'expired'
    ? agentContract
    : null
}

const SubmitSalesService = {
  getGuidelines: () => guidelinesSubject,
  getActiveSale: () => activeSaleSubject,
  getRecentSales: () => recentSalesSubject,
  getCarriersList: () => carriersListSubject,
  getProductsList: () => productsListSubject,
  getCoveragesList: () => coveragesListSubject,
  getLeadsList: () => leadsListSubject,

  setActiveSale: setActiveSale,
  saveActiveSale: saveActiveSale,

  fetchRecentSales: fetchRecentSales,
  fetchCarriers: fetchCarriers,
  fetchCarriersByCoverage: fetchCarriersByCoverage,
  // fetchProductsByCarrier: fetchProductsByCarrier,
  fetchCoveragesList: fetchCoveragesList,
  fetchLeadSourcesList: fetchLeadSourcesList,
  fetchDependencies: fetchDependencies,

  hasUserAcceptedGuidelines: hasUserAcceptedGuidelines,
  acceptGuidelines: acceptGuidelines,
  resetAcceptance: resetAcceptance,

  findUnexpiredAgentCarrierContract,
  checkAgentHasCarrierWritingCode: checkAgentHasCarrierWritingCode,
}

export default SubmitSalesService
