import React from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from 'mdbreact'

const AwardList = ({ year, awards }) => (
  <div>
    <div>
      <h6>{year}</h6>
    </div>
    <ul>
      {Object.keys(awards || {}).map((awardLevel, idx) => (
        <div key={`pip-award-level-${year}-${idx}}`}>
          <strong>{awardLevel}</strong>&nbsp;
          <small>({moment(awards[awardLevel].ack_on).format('M/D/YY')})</small>
        </div>
      ))}
    </ul>
  </div>
)

export const AwardListModal = ({ data, show, onClose }) => {
  const awardsExist = (d) =>
    d && typeof d === 'object' && Object.keys(d).length > 0
  return (
    <MDBModal isOpen={show} toggle={() => onClose(false)}>
      <MDBModalHeader>Agent Milestone Awards</MDBModalHeader>
      <MDBModalBody>
        {awardsExist(data)
          ? Object.keys(data)
              .filter((year) => awardsExist(data[year]))
              .map((year, idx) => {
                return (
                  <AwardList
                    key={`pip-award--${year}-${idx}`}
                    awards={data[year]}
                    year={year}
                  />
                )
              })
          : 'This agent has not won any milestone awards.'}
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="danger" size="sm" onClick={() => onClose(false)}>
          Close
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  )
}
