import BaseModelFactory from './base.factory'
import RecruitingLeadSourceService from './../services/RecruitingLeadSource.service'
import RecruitingLeadSource from './../models/recruiting-lead-source.model'

class RecruitingLeadSourceFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: RecruitingLeadSourceService, Model: RecruitingLeadSource }
    )
  static findAll = async () =>
    await this._findAllBy(
      {},
      { Service: RecruitingLeadSourceService, Model: RecruitingLeadSource }
    )
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: RecruitingLeadSourceService, Model: RecruitingLeadSource }
    )
  static findAllById = async (id) =>
    await this._findAllBy(
      { id: id },
      { Service: RecruitingLeadSourceService, Model: RecruitingLeadSource }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: RecruitingLeadSourceService,
      Model: RecruitingLeadSource,
    })
}

export default RecruitingLeadSourceFactory
