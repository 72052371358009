import React from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import PageHeading from './../../components/shared/PageHeading.component'
import LeaderReportTable from './components/LeaderReportTable/LeaderReportTable.component'
// import { toast } from 'react-toastify'
import moment from 'moment'
// import { getGrowth } from './helpers'
import getQueryParam from './../../shared/utilities/getQueryParam.function'
// import { LeaderReportCardFactory } from './../../shared/factories'
// import UsertypeService from './../../shared/services/Usertype.service'
import LeaderCardStore from './store/LeaderReportCard.store'
import LeaderGoalStore from './store/LeaderGoalCard.store'
import AgencyContentStore from './../../shared/stores/agencyContent.store'
import './LeaderReportCard.page.scss'
import ContentHtml from '../../components/content/ContentHtml/ContentHtml.component'

const DEFAULT_VIEW_MODE = 'reports',
  LEADER_REPORTS = ['division', 'region', 'district', 'career', 'recruiter'],
  FormattedReports = {},
  StoredReports = {}

function updateQueryString(reportDate) {
  if (reportDate && window.history.pushState) {
    var newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?reportDate=${reportDate}`

    window.history.pushState({ path: newurl }, '', newurl)
  }
}

class LeaderReportCardPage extends React.Component {
  getQueryParams = (params) => {
    const reportDate = /^\d{4}-\d{2}-\d{2}$/.test(
      `${getQueryParam('reportDate', this.props.location.search)}`.trim()
    )
      ? `${getQueryParam('reportDate', this.props.location.search)}`.trim()
      : moment().format('YYYY-MM-DD')

    const sourceType = /^(division|region|district|career|recruiter)$/i.test(
      `${getQueryParam('sourceType', this.props.location.search)}`.trim()
    )
      ? `${getQueryParam('sourceType', this.props.location.search)}`.trim()
      : null

    const goalDate = /^\d{4}-\d{2}-\d{2}$/.test(
      `${getQueryParam('goalDate', this.props.location.search)}`.trim()
    )
      ? `${getQueryParam('goalDate', this.props.location.search)}`.trim()
      : moment().format('YYYY-MM-DD')

    return { reportDate, sourceType, goalDate }
  }

  hasViewInit(viewMode) {
    return this.state.viewMode && this.state?.hasInit?.[viewMode]
  }

  async initView(viewMode, viewModeOpts) {
    let agencyContent = {},
      agencyContentBySlug = null

    if (viewMode === 'goals') {
      agencyContentBySlug = (
        await Promise.all([
          AgencyContentStore.fetchByContentSlugs({
            contentSlugs: [
              'leader-goals-career-points-notes',
              'leader-goals-district-points-notes',
              'leader-goals-region-points-notes',
              'leader-goals-division-points-notes',
              'leader-goals-career-enrolled-notes',
              'leader-goals-district-enrolled-notes',
              'leader-goals-region-enrolled-notes',
              'leader-goals-division-enrolled-notes',
              'leader-goals-career-completed-notes',
              'leader-goals-district-completed-notes',
              'leader-goals-region-completed-notes',
              'leader-goals-division-completed-notes',
              'leader-goals-career-converted-notes',
              'leader-goals-district-converted-notes',
              'leader-goals-region-converted-notes',
              'leader-goals-division-converted-notes',
              'leader-goals-career-slot-investment-notes',
              'leader-goals-district-slot-investment-notes',
              'leader-goals-region-slot-investment-notes',
              'leader-goals-division-slot-investment-notes',
              'leader-goals-career-usabg-investment-notes',
              'leader-goals-district-usabg-investment-notes',
              'leader-goals-region-usabg-investment-notes',
              'leader-goals-division-usabg-investment-notes',
              'leader-goals-career-sig-agents-notes',
              'leader-goals-district-sig-agents-notes',
              'leader-goals-region-sig-agents-notes',
              'leader-goals-division-sig-agents-notes',
              'leader-goals-career-points-notes',
              'leader-goals-district-points-notes',
              'leader-goals-region-points-notes',
              'leader-goals-division-points-notes',
            ],
          }),
          LeaderGoalStore.initStore(this.getQueryParams(), viewModeOpts),
        ])
      ).shift()
    }

    if (viewMode === 'reports') {
      await LeaderCardStore.initStore(this.getQueryParams())
    }

    if (agencyContentBySlug) {
      ;['career', 'district', 'region', 'division'].forEach((sourceType) => {
        Object.keys(agencyContentBySlug)
          .filter((contentSlug) => contentSlug.includes(sourceType))
          .forEach((contentSlug) => {
            if (!agencyContent.hasOwnProperty(sourceType))
              agencyContent[sourceType] = {}
            agencyContent[sourceType][
              contentSlug.replace(`${sourceType}-`, '')
            ] = agencyContentBySlug[contentSlug]
          })
      })
    }

    if (
      (agencyContent =
        !agencyContent || !Object.keys(agencyContent).length
          ? null
          : agencyContent)
    )
      LeaderGoalStore.agencyContent = agencyContent

    this.setState((prevState) => ({
      ...prevState,
      viewMode,
      hasInit: { ...this.state.hasInit, [viewMode]: true },
      viewModeOpts,
    }))
  }

  async changeView(viewMode, viewModeOpts) {
    const newUserId = (viewModeOpts || {})?.userId,
      currUserId = (this.state.viewModeOpts || {})?.userId

    if (currUserId === null || newUserId !== currUserId)
      return this.initView(viewMode, viewModeOpts)

    this.setState((prevState) => ({ ...prevState, viewMode, viewModeOpts }))
  }

  setViewMode(viewMode, viewModeOpts) {
    if (!this.hasViewInit(viewMode, viewModeOpts))
      this.initView(viewMode, viewModeOpts)
    else this.changeView(viewMode, viewModeOpts)
  }

  init() {
    if (!this.state.viewMode) this.setViewMode(DEFAULT_VIEW_MODE)
  }

  constructor(props) {
    super(props)
    this.state = {
      showPrev: false,
      viewMode: null,
      hasInit: {
        goals: false,
        reports: false,
      },
      viewModeOpts: {},
    }
  }

  componentDidMount() {
    this.init()
  }

  render() {
    return (
      <DashboardLayout>
        <main id="LeaderReportCardPage" className="mainSection pb-5">
          <MDBContainer fluid className="mt-4">
            <PageHeading
              label="Leader Report Card"
              url={this.props?.match?.url}
              from={this.props?.location?.state?.from}
              goTo={(url) =>
                this.props.history.push(url, { from: '/reports/leaders' })
              }
            />
            <MDBRow>
              <MDBCol>
                <ContentHtml contentSlug={'lrc-corporate-goals'} />
              </MDBCol>
              <MDBCol>
                <ContentHtml contentSlug={'lrc-team-goals'} />
              </MDBCol>
              <MDBCol size="12">
                <LeaderReportTable
                  onDateChange={(reportDate) => updateQueryString(reportDate)}
                  viewMode={this.state.viewMode}
                  onViewModeChange={(viewMode, viewModeOpts) =>
                    this.setViewMode(viewMode, viewModeOpts)
                  }
                  goTo={(goTo) => this.props.history.push(goTo)}
                  ownerName={this.state.viewModeOpts?.ownerName}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </main>
      </DashboardLayout>
    )
  }
}

export default LeaderReportCardPage
