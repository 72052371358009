import React, { Component, Fragment } from 'react'
import {
  MDBContainer,
  MDBBtn,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBInput,
  MDBAlert,
  MDBCollapse,
} from 'mdbreact'
import DashboardLayout from '../../../components/Admin/DashboardLayout'
import { toast } from 'react-toastify'
import AdminApi from '../../../api/admin-api/admin-api'

class EditProductLink extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      formError: false,
      errorMessage: '',
      urlParams: {},
      productListDetails: {},
      c_website_link: '',
      linkTarget: '_blank',
    }
  }
  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params }, this.fetchProductLinkById)
  }

  // resource data API
  fetchProductLinkById = async () => {
    this.setState({ loading: true })
    AdminApi.getProductLinkDetails(this.state.urlParams.id)
      .then((result) => {
        if (result && result.data && result.data.data) {
          this.setState({
            productListDetails: result.data.data || {},
            c_website_link: result.data.data.upc_url,
            linkTarget: result.data.data.upc_target,
          })
        }
        this.setState({ loading: false })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  validate = async () => {
    const { c_website_link } = this.state
    const regHttpLink = /^(http(s)?:\/\/).*/i

    if (!c_website_link || !c_website_link.trim()) {
      this.setState({
        formError: true,
        errorMessage:
          'URL Required! can not be created OR saved without an agents carrier URL.',
      })
      return
    }

    if (c_website_link && !regHttpLink.test(c_website_link)) {
      const { c_website_link } = this.state
      this.setState({ c_website_link: `http://${c_website_link}` })
    }

    this.setState({ formError: false, errorMessage: '' })
  }

  handleSubmit = async () => {
    await this.validate()
    const { loading, formError, errorMessage, c_website_link, linkTarget } =
      this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }
    const data = {
      userproductcoverage_id: this.state.urlParams.id,
      upc_url: c_website_link,
      upc_target: linkTarget,
    }

    this.setState({ loading: true })

    AdminApi.updateProductLinkDetails(data)
      .then((result) => {
        this.setState({ loading: false })
        toast.success('Product updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
        this.props.history.push('/maintain-products')
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  render() {
    toast.configure()
    const { loading, productListDetails } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              {loading ? (
                <h2>Loading...</h2>
              ) : (
                <>
                  <MDBBreadcrumb>
                    <MDBBreadcrumbItem>
                      <a href="/maintain-products">List</a>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>
                      Edit Product Link
                    </MDBBreadcrumbItem>
                  </MDBBreadcrumb>
                  <h2>
                    Edit{' '}
                    {productListDetails.coverage_name
                      ? productListDetails.coverage_name
                      : ''}{' '}
                    {productListDetails.c_name
                      ? ' ' + productListDetails.c_name
                      : ''}
                  </h2>
                  <MDBRow>
                    <MDBCol size="4">
                      <form>
                        <MDBInput
                          type="text"
                          label="URL"
                          id="c_website_link"
                          value={this.state.c_website_link || ''}
                          getValue={(value) => {
                            this.setState({ c_website_link: value })
                          }}
                        />

                        <div className="text-center mt-4">
                          <MDBBtn
                            color="unique"
                            type="button"
                            onClick={this.handleSubmit}
                          >
                            Save
                          </MDBBtn>
                        </div>
                      </form>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol size="4">
                      <MDBCollapse isOpen={!(this.state.c_website_link || '')}>
                        <br />
                        <MDBAlert color="danger">
                          <div>
                            <MDBIcon icon="exclamation-triangle" />
                            &nbsp;This product listing will not visible without
                            your agent link!
                          </div>
                        </MDBAlert>
                      </MDBCollapse>
                    </MDBCol>
                  </MDBRow>
                </>
              )}
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default EditProductLink
