import { SalesTrackerPage } from './index'

import { RouteFactory } from './../../shared/router/Route.factory'
import {
  RequiresAuthGuard 
} from './../../shared/router/guards'

export default [
  RouteFactory.create(SalesTrackerPage, '/submit-sales', [
    new RequiresAuthGuard(true),
  ], {
    name: 'Sales Tracker'
  }),
  RouteFactory.create(
    SalesTrackerPage,
    {
      path: '/submit-sales/:id/edit',
      pattern: /^\/submit-sales\/\d+\/edit(\/)?$/i,
    },
    [new RequiresAuthGuard(true)], {
      name: 'Sales Tracker'
    }
  ),
]
