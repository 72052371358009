import UserProfileService from './UserProfile.service';
import env from './../../environments/environment';
import { TrackJS } from 'trackjs'

const isActive = () =>
	!!(env?.integrations?.track_js && TrackJS.isInstalled())

const onLogin = () => 
{
	let user = UserProfileService.getUserDetails();
	if (isActive() && user && user?.id){
		let userId = user.id
		TrackJS.configure({userId: userId.toString()});
	}
}

const onLogout = () => 
{}

const setMeta = (prop, value) =>
	isActive() && TrackJS.addMetadata(prop, value);

const track = err =>
	TrackJS.track(new Error(err));

export default {onLogin, onLogout, setMeta, track};