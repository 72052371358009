import BaseModelFactory from './base.factory'
import LeadSourceService from './../services/LeadSource.service'
import LeadSource from './../models/lead-source.model'

class LeadSourceFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: LeadSourceService, Model: LeadSource }
    )
  static findAll = async () =>
    await this._findAllBy({}, { Service: LeadSourceService, Model: LeadSource })
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: LeadSourceService, Model: LeadSource }
    )
  static findAllById = async (id) =>
    await this._findAllBy(
      { id: id },
      { Service: LeadSourceService, Model: LeadSource }
    )
  static create = (payload) =>
    this._create(payload, { Service: LeadSourceService, Model: LeadSource })
}

export default LeadSourceFactory
