import { AgentLedgersPage } from './index'
import { AdminOverride } from './../../shared/router/overrides'
import { RouteFactory } from './../../shared/router/Route.factory'
import {
  RequiresAuthGuard,
  RequiresUsertypesGuard,
  RequiresOnboardingGuard,
} from './../../shared/router/guards'

export default [
  RouteFactory.create(
    AgentLedgersPage,
    '/account-ledger/:transactionId',
    [
      new RequiresAuthGuard(true),
      new RequiresUsertypesGuard([
        'agency-owner',
        'internal-admin',
        'internal-staff',
        'system-admin',
        'csr-agent',
        'limited-to-training-signature-agent',
        'signature-agent-in-need-of-pic',
        'signature-agent-complete',
        'career-agent',
        'premier-agent',
        'district-leader',
        'regional-leader',
        'divisional-leader',
        'division-admin',
        'region-admin',
        'district-admin',
      ]),
      RequiresOnboardingGuard,
      AdminOverride,
    ],
    {
      name: 'My USABG BUCK$ - Account Wallet',
      pattern: /\/account-ledger\/\d+/,
    }
  ),
  RouteFactory.create(
    AgentLedgersPage,
    '/account-ledger',
    [
      new RequiresAuthGuard(true),
      new RequiresUsertypesGuard([
        'agency-owner',
        'internal-admin',
        'internal-staff',
        'system-admin',
        'csr-agent',
        'internal-staff',
        'internal-admin',
        'system-admin',
        'limited-to-training-signature-agent',
        'signature-agent-in-need-of-pic',
        'signature-agent-complete',
        'career-agent',
        'premier-agent',
        'district-leader',
        'regional-leader',
        'divisional-leader',
        'division-admin',
        'region-admin',
        'district-admin',
      ]),
      RequiresOnboardingGuard,
      AdminOverride,
    ],
    {
      name: 'My USABG BUCK$ - Account Wallet',
    }
  ),
  RouteFactory.create(
    AgentLedgersPage,
    '/admin/ledgers/:ledgerId/:transactionId',
    [
      new RequiresAuthGuard(true),
      new RequiresUsertypesGuard([
        'agency-owner',
        'internal-admin',
        'internal-staff',
        'system-admin',
      ]),
      AdminOverride,
    ],
    {
      name: 'Agent Wallet Administration',
      pattern: /\/admin\/ledgers\/\d+\/\d+/,
    }
  ),
  RouteFactory.create(
    AgentLedgersPage,
    '/admin/ledgers/:ledgerId',
    [
      new RequiresAuthGuard(true),
      new RequiresUsertypesGuard([
        'agency-owner',
        'internal-admin',
        'internal-staff',
        'system-admin',
      ]),
      AdminOverride,
    ],
    {
      name: 'Agent Wallet Administration',
      pattern: /\/admin\/ledgers\/\d+/,
    }
  ),
  RouteFactory.create(
    AgentLedgersPage,
    '/admin/ledgers',
    [
      new RequiresAuthGuard(true),
      new RequiresUsertypesGuard([
        'agency-owner',
        'internal-admin',
        'internal-staff',
        'system-admin',
      ]),
      AdminOverride,
    ],
    {
      name: 'Agent Wallet Administration',
    }
  ),
]
