import RestApi from './../../api/rest-api-client'
const srvcEndpoint = '/user_contracting_cart_items'
export default {
  search: async (req, opts) => RestApi.request('GET', srvcEndpoint, req, opts),
  get: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, id, req, opts),
  store: async (data, opts) =>
    RestApi.request('POST', srvcEndpoint, data, opts),
  update: async (id, data, opts) =>
    RestApi.request('PUT', srvcEndpoint, id, data, opts),
  delete: async (id, opts) => RestApi.request('DELETE', srvcEndpoint, id, opts),

  // preview: async (carrier_ids) =>
  //   RestApi.request(
  //     'POST',
  //     srvcEndpoint,
  //     { query: { carrier_ids } },
  //     { endpoint: `/user_contracting_carts/preview` }
  //   ),
  // checkout: async (ids) =>
  //   RestApi.request(
  //     'POST',
  //     srvcEndpoint,
  //     { query: { ids } },
  //     { endpoint: `/user_contracting_carts/checkout` }
  //   ),
}
