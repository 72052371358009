import React from 'react'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
} from 'mdbreact'
import PIPAwardLevel from './../../../../shared/utilities/PipAwardLevel.class'
import moment from 'moment'
import DashboardCard from './../DashboardCard/DashboardCard.component'
import PerformanceRecordService from './../../../../shared/services/PerformanceRecord.service'
import { getHeadshot, autoLogin, getAgentAccessToHeadshot } from './../../../../shared/utilities/getHeadshot.function'
import { renderTeamLogo } from './../../../../shared/utilities/getTeamLogo.function'
import { BehaviorSubject, Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'

import './PerformanceWidget.scss'

const _resizeSubject = new BehaviorSubject(null)
const onResizeHandler = () => _resizeSubject.next(window.innerWidth)
const getElemStyle = (elem) => (elem ? window.getComputedStyle(elem) : null)

class PerformanceWidget extends React.Component {
  state = {
    widgets: {},
  }

  _subscription$ = new Subscription()

  _widgets = {
    health: {
      _: { color: 'blue', text: 'indigo', icon: 'medkit' },
      topAgentOfTheDay: { title: 'Top Agent of the Day', layout: 'agent' },
      lastWeekTopAgent: { title: "Last Week's Top Agent", layout: 'agent' },
      lastWeekTopDistrict: {
        title: "Last Week's Top District",
        layout: 'district',
      },
      lastWeekTopRegion: { title: "Last Week's Top Region", layout: 'region' },
      lastWeekTopDivision: {
        title: "Last Week's Top Division",
        layout: 'division',
      },
      // lastWeekTopBATeam: {
      //   title: "Last Week's Top BA Team",
      //   layout: 'ba_team',
      // },
      recentFirstSales: { title: 'Recent First Sales', layout: 'list' },
    },
    senior: {
      _: { color: 'red', text: 'red', icon: 'prescription' },
      topAgentOfTheDay: { title: 'Top Agent of the Day', layout: 'agent' },
      lastWeekTopAgent: { title: "Last Week's Top Agent", layout: 'agent' },
      lastWeekTopDistrict: {
        title: "Last Week's Top District",
        layout: 'district',
      },
      lastWeekTopRegion: { title: "Last Week's Top Region", layout: 'region' },
      lastWeekTopDivision: {
        title: "Last Week's Top Division",
        layout: 'division',
      },
      // lastWeekTopBATeam: {
      //   title: "Last Week's Top BA Team",
      //   layout: 'ba_team',
      // },
      lastWeekTopRecruiter: {
        title: "Last Week's Top Recruiter",
        layout: 'recruits',
      },
    },
    life: {
      _: { color: 'green', text: 'green', icon: 'heartbeat' },
      topAgentOfTheDay: { title: 'Top Agent of the Day', layout: 'agent' },
      lastWeekTopAgent: { title: "Last Week's Top Agent", layout: 'agent' },
      lastWeekTopDistrict: {
        title: "Last Week's Top District",
        layout: 'district',
      },
      lastWeekTopRegion: { title: "Last Week's Top Region", layout: 'region' },
      lastWeekTopDivision: {
        title: "Last Week's Top Division",
        layout: 'division',
      },
      // lastWeekTopBATeam: {
      //   title: "Last Week's Top BA Team",
      //   layout: 'ba_team',
      // },
      lastWeekNewAgents: { title: 'Welcome New Agents', layout: 'list' },
    },
  }

  _setPerformanceReports = async () => {
    let results = await this._fetchPerformanceReports(),
      topFiveAgents = null,
      topFiveBATeams = null
    if (!results) return

    results
      .map((r) => {
        try {
          r.category =
            typeof r.category === 'string' ? JSON.parse(r.category) : r.category
          r.payload =
            typeof r.payload === 'string' ? JSON.parse(r.payload) : r.payload
        } catch (ex) {
          console.error('ERROR: Failed to parse json for perforance report. ', {
            exception: ex,
            report: r,
          })
        }
        return r
      })
      .forEach((r) => {
        for (let w in this._widgets) {
          if (r.category.indexOf(`${w}`.toUpperCase()) < 0) {
            if (r.name === 'recentFirstSales' && w === 'health') {
            } else if (r.name === 'lastWeekTopRecruiter' && w === 'senior') {
            } else if (r.name === 'lastWeekNewAgents' && w === 'life') {
            } else if (r.name === 'currentWeekTopRecruiters') {
              this._widgets.senior.lastWeekTopRecruiter = Object.assign(
                this._widgets.senior.lastWeekTopRecruiter,
                { list: r.payload }
              )
            } else if (r.name === 'currentWeekTopPointsAgents') {
              topFiveAgents = r.payload
              continue
            } else if (r.name === 'currentWeekTopPointsBATeams') {
              topFiveBATeams = r.payload
              continue
            } else continue
          }

          if (this._widgets[w].hasOwnProperty(r.name)) {
            this._widgets[w][r.name] = Object.assign(
              this._widgets[w][r.name],
              Array.isArray(r.payload) ? { list: r.payload } : r.payload,
              { color: this._widgets[w]._.color }
            )
          }
          break
        }
      })

    if (topFiveAgents && 5 - topFiveAgents.length > 0) {
      for (let i = 5 - topFiveAgents.length; i; i--) topFiveAgents.push(false)
    }

    topFiveAgents =
      topFiveAgents && Array.isArray(topFiveAgents)
        ? topFiveAgents.map((t) => {
            if (t) {
              t.pic = getHeadshot({ u_picture: t.pic, id: t.user_id })
              t.av = t.av ? this._numberFormat(t.av) : 0
              return t
            }

            return {
              name: 'Available!',
              pic: getHeadshot(),
              av: 0,
            }
          })
        : topFiveAgents

    topFiveBATeams =
      topFiveBATeams && Array.isArray(topFiveBATeams)
        ? topFiveBATeams.map((t) => {
            if (t) {
              t.av = t.av ? this._numberFormat(t.av) : 0
              return t
            }

            return {
              name: 'Available!',
              pic: null,
              av: 0,
            }
          })
        : topFiveBATeams

    return new Promise((resolve, reject) => {
      this.setState(
        { widgets: this._widgets, topFiveAgents, topFiveBATeams },
        resolve
      )
    })
  }

  _fetchPerformanceReports = async () => {
    try {
      return await PerformanceRecordService.search({
        search: {
          name: [
            'currentWeekTopRecruiters',
            'lastWeekTopAgent',
            'lastWeekTopDivision',
            'lastWeekTopRecruiter',
            'lastWeekTopRegion',
            'lastWeekTopDistrict',
            // 'lastWeekTopBATeam',
            'recentFirstSales',
            'topAgentOfTheDay',
            'lastWeekNewAgents',
            'currentWeekTopPointsAgents',
            'currentWeekTopPointsBATeams',
          ],
          date: moment().format('YYYY-MM-DD'),
        },
      })
    } catch (ex) {
      console.error('PerfRec EX: ', ex)
      return false
    }
  }

  _numberFormat = (value) =>
    new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value)

  _unbindResizeHandler = () => {
    window.removeEventListener('resize', onResizeHandler, false)
    this._subscription$.unsubscribe()
  }

  _bindResizeHandler = () => {
    window.addEventListener('resize', onResizeHandler, false)
    this._subscription$.add(
      _resizeSubject
        .pipe(debounceTime(250), distinctUntilChanged())
        .subscribe(this._onResizeHandler)
    )
  }

  _onResizeHandler = () => {
    let lineHeight = 24,
      lines = [],
      isMobile = false

    const estimateLines = (elem) =>
        lines.push(Math.round(elem.offsetHeight / lineHeight)),
      PerfWidget = document.getElementById('PerformanceWidget')

    try {
      isMobile = getElemStyle(
        PerfWidget.querySelector('.mobile-card')
      )?.getPropertyValue('display')
      isMobile =
        !isMobile ||
        !(isMobile && `${isMobile}`.toLowerCase().trim() === 'none')
    } catch (ex) {
      console.error(
        `Unable to determine if the browser resolution is mobile or desktop. ${ex}`
      )
      return
    }

    try {
      PerfWidget.querySelectorAll(
        `.${isMobile ? 'mobile' : 'desktop'}-card > .agent-name > div`
      ).forEach(estimateLines)

      let max = Math.max(...lines)
      if (max && !isNaN(max)) {
        PerfWidget.querySelectorAll(
          `.${isMobile ? 'mobile' : 'desktop'}-card > .agent-name`
        ).forEach((elem) => {
          elem.style.height = `${max * lineHeight}px`
          elem.style.paddingBottom = `15px`
        })
      }
    } catch (ex) {
      console.error(
        `Unable to determine or re-apply agent-name div height on performance widget. ${ex}`
      )
    }
  }

  componentDidMount() {
    this._bindResizeHandler()

    this._setPerformanceReports()
      .then(this._onResizeHandler)
      .catch(this._unbindResizeHandler)
  }

  componentWillUnmount() {
    this._unbindResizeHandler()
  }

  renderCategory = (category) => {
    category = Array.isArray(category) ? category : [category]

    if (`${category[0]}`.toLowerCase() === 'health') category.push('group')
    else if (`${category[0]}`.toLowerCase() === 'senior')
      category.push('med Advantage')
    else if (`${category[0]}`.toLowerCase() === 'life')
      category.push('annuities')

    return category
      .map((c) => c.charAt(0).toUpperCase() + c.substr(1))
      .join('/')
  }

  render() {
    return (
      <div id="PerformanceWidget">
        {this.state.topFiveAgents &&
        Array.isArray(this.state.topFiveAgents) &&
        this.state.topFiveAgents.length ? (
          <MDBCard className="mt-3 mb-5 top-5-pip-agents">
            <MDBCardHeader
              color="light-blue darken-4"
              tag="h4"
              className="text-center"
            >
              Week {moment().isoWeek()}: Top 5 Agents
            </MDBCardHeader>
            <MDBCardBody>
              <MDBRow>
                {this.state.topFiveAgents.map((item, i) => (
                  <React.Fragment key={'task-result-' + i}>
                    <MDBCol size="12" className="mobile-card mr-auto ml-auto">
                      <MDBRow>
                        <MDBCol size="5">
                          <div 
                            className={item.user_id && getAgentAccessToHeadshot(item.user_id) ? "cursor-pointer profile-img-wrapper" :  "profile-img-wrapper"}
                            onClick={() => {
                              if(item.user_id && getAgentAccessToHeadshot(item.user_id)) autoLogin(item.user_id)
                            }}
                          >
                            <img
                              alt={item.name}
                              src={item.pic}
                              className="mx-auto d-block img-fluid"
                            />
                          </div>
                        </MDBCol>
                        <MDBCol size="7">
                          <div className="agent-name">
                            <div>{item.name}</div>
                          </div>
                          <div className="agent-points">
                            <div>{item.av} Points</div>
                          </div>
                          <div className="agent-jewel-img">
                            {PIPAwardLevel.getAwardBadge(
                              (`${item.av_total}` || 0).replace(/[^\d]/g, ''),
                              { size: '64px', display: 'block', margin: 'auto' }
                            )}
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol
                      lg="2"
                      md="2"
                      sm="6"
                      className="desktop-card mr-auto ml-auto"
                    >
                      <div className="agent-name">
                        <div>{item.name}</div>
                      </div>
                      <div 
                        className={item.user_id && getAgentAccessToHeadshot(item.user_id) ? "cursor-pointer profile-img-wrapper" : "profile-img-wrapper"}
                        onClick={() => {
                          if(item.user_id && getAgentAccessToHeadshot(item.user_id)) autoLogin(item.user_id)
                        }}
                      >
                        <img
                          alt={item.name}
                          src={item.pic}
                          className="mx-auto d-block img-fluid"
                        />
                      </div>
                      <div className="agent-points">
                        <div>{item.av} Points</div>
                      </div>
                      <div className="agent-jewel-img">
                        {PIPAwardLevel.getAwardBadge(
                          (`${item.av_total}` || 0).replace(/[^\d]/g, ''),
                          { size: '64px', display: 'block', margin: 'auto' }
                        )}
                      </div>
                    </MDBCol>
                  </React.Fragment>
                ))}
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        ) : (
          <></>
        )}

        {this.state.topFiveBATeams &&
        Array.isArray(this.state.topFiveBATeams) &&
        this.state.topFiveBATeams.length ? (
          <MDBCard className="mt-3 mb-5 top-5-pip-agents">
            <MDBCardHeader
              color="light-blue darken-4"
              tag="h4"
              className="text-center"
            >
              Week {moment().isoWeek()}: Top {this.state.topFiveBATeams.length}{' '}
              Benefit Advisor Teams
            </MDBCardHeader>
            <MDBCardBody>
              <MDBRow>
                {this.state.topFiveBATeams.map((item, i) => (
                  <React.Fragment key={'task-result-' + i}>
                    <MDBCol size="12" className="mobile-card mr-auto ml-auto">
                      <MDBRow>
                        <MDBCol size="5">
                          <div className="team-logo-img-wrapper">
                            {renderTeamLogo(item)}
                          </div>
                        </MDBCol>
                        <MDBCol size="7">
                          <div className="agent-name">
                            <div>{item.name}</div>
                          </div>
                          <div className="agent-points">
                            <div>{item.av} Points</div>
                          </div>
                          <div className="agent-jewel-img">
                            {PIPAwardLevel.getAwardBadge(
                              (`${item.av_total}` || 0).replace(/[^\d]/g, ''),
                              { size: '64px', display: 'block', margin: 'auto' }
                            )}
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol
                      lg="2"
                      md="2"
                      sm="6"
                      className="desktop-card mr-auto ml-auto"
                    >
                      <div className="agent-name">
                        <div>{item.name}</div>
                      </div>
                      <div className="team-logo-img-wrapper">
                        {renderTeamLogo(item)}
                      </div>
                      <div className="agent-points">
                        <div>{item.av} Points</div>
                      </div>
                      <div className="agent-jewel-img">
                        {PIPAwardLevel.getAwardBadge(
                          (`${item.av_total}` || 0).replace(/[^\d]/g, ''),
                          { size: '64px', display: 'block', margin: 'auto' }
                        )}
                      </div>
                    </MDBCol>
                  </React.Fragment>
                ))}
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        ) : (
          <></>
        )}

        <MDBRow className="mt-3 mb-5">
          {Object.keys(this.state.widgets).map((category) => (
            <MDBCol size="12" md="4" key={`${category}-column`}>
              <h2
                className={
                  this.state.widgets[category]._.text +
                  '-text text-center widget-title'
                }
                title={this.renderCategory(category)}
              >
                <MDBIcon fas="true" icon="medkit" />{' '}
                {this.renderCategory(category)}
              </h2>
              {Object.keys(this.state.widgets[category])
                .filter((k) => k !== '_')
                .map((widget) => {
                  return (
                    <DashboardCard
                      key={`${category}-${this.state.widgets[category][
                        widget
                      ].title
                        .toLowerCase()
                        .trim()
                        .replace(/\s/g, '-')}`}
                      data={this.state.widgets[category][widget]}
                    />
                  )
                })}
            </MDBCol>
          ))}
        </MDBRow>
      </div>
    )
  }
}

export default PerformanceWidget
