import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { MDBBtn, MDBBtnGroup, MDBIcon, MDBAlert } from 'mdbreact'
import UserProfileService from './../../../shared/services/UserProfile.service'
import MediaUploaderModal from './../../modals/MediaUploaderModal'

import './AgentWalletDocumentsButton.scss'

const AgentWalletDocumentsButton = ({ UserMetas, onUpload }) => {
  const canUpload = [],
    canDownload = []

  Object.values(UserMetas).forEach((UserMeta) => {
    if (UserMeta && UserMeta?.get('meta_key')) {
      canUpload.push(UserMeta.get('meta_key'))
      if (UserMeta.get('meta_value') && !isNaN(UserMeta.get('meta_value')))
        canDownload.push(UserMeta.get('meta_key'))
    }
  })

  const openModal = (uploadType) => {
    const opts = {}

    switch (uploadType) {
      case 'wallet_w9':
        opts.templateUrl = ''
        opts.title = 'Upload your most recent W9'
        break
      case 'wallet_dd':
        opts.templateUrl = ''
        opts.title = 'Upload your direct deposit form.'
        break
    }

    // user fetch or create metas.
    MediaUploaderModal.open({
      uploadType,
      modalContentTop: (
        <div className="text--center fw--500">
          <h6 className="fw--500">{opts.title}</h6>
        </div>
      ),
      validationRules: {
        contentType: ['application/pdf'],
      },
    })
      .then(onUpload)
      .catch((err) => {
        if (err)
          console.log(
            'Failed to complete uploading agent wallet documents.',
            err
          )
      })
  }

  const button = () => {
    return (
      <MDBBtnGroup className="AgentWalletDocumentsButtonComponent">
        {canUpload.includes('wallet---wallet-w9') ? (
          <MDBBtn
            block
            color="info"
            className="w9-btn"
            onClick={() => {
              openModal('wallet_w9')
            }}
          >
            <MDBIcon icon="file-medical" />
            &nbsp;
            <span>Upload Agent W9</span>
          </MDBBtn>
        ) : (
          <></>
        )}
        <MDBAlert
          className="w9-icon"
          color={
            canDownload.includes('wallet---wallet-w9') ? 'success' : 'danger'
          }
        >
          <MDBIcon
            far
            icon={
              canDownload.includes('wallet---wallet-w9')
                ? 'check-circle'
                : 'times-circle'
            }
          />
        </MDBAlert>
        {canUpload.includes('wallet---wallet-dd') ? (
          <MDBBtn
            block
            color="info"
            className="dd-btn"
            onClick={() => {
              openModal('wallet_dd')
            }}
          >
            <MDBIcon icon="file-medical" />
            &nbsp;
            <span>Upload Agent Direct Deposit</span>
          </MDBBtn>
        ) : (
          <></>
        )}
        <MDBAlert
          className="dd-icon"
          color={
            canDownload.includes('wallet---wallet-dd') ? 'success' : 'danger'
          }
        >
          <MDBIcon
            far
            icon={
              canDownload.includes('wallet---wallet-dd')
                ? 'check-circle'
                : 'times-circle'
            }
          />
        </MDBAlert>
      </MDBBtnGroup>
    )
  }

  return button()
}

export default observer(AgentWalletDocumentsButton)
