import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import SalesTrackerStore from './../../store'
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import PointOverrides from './../PointOverrides/PointOverrides.component'
import {
  UIDropdown,
  UIInput,
  UIDatePickerInput,
} from './../../../../components/forms/form-fields'
import appConstants from './../../../../constants/appConstants'
import AV_STATUSES from './../../../../constants/av-status.constants'
import moment from 'moment'
import { toast } from 'react-toastify'
import preventInputUnicodeCharacters from '../../../../shared/utilities/preventInputUnicodeCharacters.function'

import './AvPolicyForm.component.scss'
import TermLengthsService from '../../../../shared/services/TermLengths.service'

const TermLengths = appConstants.termLength.map((item) => ({
    ...item,
    value: `${item.value}`,
  })),
  AppStatusList = AV_STATUSES

const toDate = (dateStr) => {
  if (dateStr) {
    if (typeof dateStr === 'string')
      return moment(`${dateStr}`.split('T').shift()).toDate()
    if (typeof dateStr === 'object') return dateStr
  }
}

const AvPolicyForm = ({ Policy, idx }) => {
  const [trackLives, setTrackLives] = useState(null),
    [sourceCoverageId, setSourceCoverageId] = useState(null),
    [sourceCarrierId, setSourceCarrierId] = useState(null),
    [avPremiumLabel, setAvPremiumLabel] = useState(null),
    [isUpdating, setIsUpdating] = useState({ carrier: false }),
    [avEffDate, setAvEffDate] = useState(null),
    [avTermLengthMax, setAvTermLengthMax] = useState(null),
    [avTermLengthMin, setAvTermLengthMin] = useState(null),
    [coverage, setCoverage] = useState(null),
    [carrierTermMaxLength, setCarrierTermMaxLength] = useState(null),
    [carrierTermMinLength, setCarrierTermMinLength] = useState(null),
    [carrierTermDefaultLength, setCarrierTermDefaultLength] = useState(null),
    policyAvEffDate = Policy?.get('av_eff_date'),
    isPreviousPlicy = SalesTrackerStore.isPreviousPolicy,
    previousPolicyHasEffDate = SalesTrackerStore.previousPolicyHasEffDate

  useEffect(() => {
    if (avEffDate === null && policyAvEffDate)
      setAvEffDate(toDate(policyAvEffDate))
    else if (avEffDate && !policyAvEffDate) setAvEffDate(null)
    else {
      const a =
          avEffDate && typeof avEffDate === 'object'
            ? moment(avEffDate).format('YYYY-MM-DD')
            : (avEffDate ? `${avEffDate}` : '').split('T').shift(),
        p =
          policyAvEffDate && typeof policyAvEffDate === 'object'
            ? moment(policyAvEffDate).format('YYYY-MM-DD')
            : (policyAvEffDate ? `${policyAvEffDate}` : '').split('T').shift()
      if (
        a &&
        p &&
        /\d{4}-\d{2}-\d{2}/.test(a) &&
        /\d{4}-\d{2}-\d{2}/.test(p) &&
        a !== p
      )
        setAvEffDate(toDate(policyAvEffDate))
      else if (avEffDate && !policyAvEffDate) setAvEffDate(null)
      else {
        const a =
            avEffDate && typeof avEffDate === 'object'
              ? moment(avEffDate).format('YYYY-MM-DD')
              : (avEffDate ? `${avEffDate}` : '').split('T').shift(),
          p =
            policyAvEffDate && typeof policyAvEffDate === 'object'
              ? moment(policyAvEffDate).format('YYYY-MM-DD')
              : (policyAvEffDate ? `${policyAvEffDate}` : '').split('T').shift()
        if (
          a &&
          p &&
          /\d{4}-\d{2}-\d{2}/.test(a) &&
          /\d{4}-\d{2}-\d{2}/.test(p) &&
          a !== p
        )
          setAvEffDate(toDate(policyAvEffDate))
      }
    }
  }, [avEffDate, policyAvEffDate])

  const currencyKeypress = (event) => {
    const inputValue = Policy.get(event.target.name) || ''
    const key = event.key

    const isNumeric = key >= '0' && key <= '9'
    const isDecimal = key === '.'
    const decimalExists = inputValue.includes('.')

    // Allow navigation keys and control keys (backspace, delete, etc.)
    if (
      ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(key)
    ) {
      return true
    }

    // Prevent first 0
    if (inputValue === '0' && isNumeric) {
      event.preventDefault()
      Policy.set(event.target.name, event.key)
      return false
    }

    // Prevent initial decimal point without leading zero
    if (inputValue === '' && isDecimal) {
      event.preventDefault()
      return false
    }

    // Prevent leading zeroes unless followed by a decimal point
    if (inputValue.startsWith('0') && !inputValue.includes('.') && isNumeric) {
      event.preventDefault()
      return false
    }

    // Allow numeric input if valid
    if (isNumeric) {
      // Allow input if no decimal exists or if there are less than 2 decimal places
      if (
        !decimalExists ||
        (decimalExists && inputValue.split('.')[1].length < 2)
      ) {
        return true
      }
    }

    // Allow decimal input if no decimal exists yet
    if (isDecimal && !decimalExists) {
      return true
    }

    // Prevent invalid input
    event.preventDefault()
    return false
  }

  const getCarrier = () =>
    SalesTrackerStore.getCarrier(Policy.get('carrier_id'))

  const getCoverage = () =>
    SalesTrackerStore.getCoverage(Policy.get('coverage_id'))

  const getCarrierOptions = () => {
    let Carriers = SalesTrackerStore.Carriers
    Carriers = Carriers && Array.isArray(Carriers) ? Carriers : []
    return Carriers.filter(
      (Carrier) =>
        parseInt(Carrier.get('c_active')) === 1 &&
        Carrier.get('visibility')?.tracker?.enable
    )
      .sort((a, b) => a.get('c_name').localeCompare(b.get('c_name')))
      .map((Carrier) => ({
        text: (
          <>
            {Carrier.get('c_name') + ' '}
            <small>{Carrier.get('c_description')}</small>
          </>
        ),
        value: `${Carrier.id()}`,
        checked: !!(
          carrier_id && parseInt(carrier_id) === parseInt(Carrier.id())
        ),
      }))
  }

  const setAvCarrier = () => {
    const Carrier = getCarrier()
    if (Carrier && Carrier.get('c_name') !== Policy.get('av_carrier'))
      Policy.set('av_carrier', Carrier.get('c_name'))
  }

  const setAvCoverage = () => {
    const Coverage = getCoverage()
    if (Coverage && Coverage.get('coverage_name') !== Policy.get('av_coverage'))
      Policy.set('av_coverage', Coverage.get('coverage_name'))
  }

  const updateCoverage = async (coverageId) => {
    if (!coverageId) return

    Policy.set('coverage_id', parseInt(coverageId))

    const coverageTermLength = (
      await TermLengthsService.search({
        pagination: false,
        search: { coverage_id: coverageId },
      })
    )?.models?.shift()

    Policy.set(
      'av_term_length',
      coverageTermLength?.default_length
        ? coverageTermLength.default_length
        : carrierTermDefaultLength
    )
    setAvTermLengthMax(
      coverageTermLength?.max_length
        ? coverageTermLength.max_length
        : carrierTermMaxLength
    )
    setAvTermLengthMin(
      coverageTermLength?.min_length
        ? coverageTermLength.min_length
        : carrierTermMinLength
    )

    setCoverage(parseInt(coverageId))
    setShouldTrackLives()
    setAvCoverage()
  }

  const updateCarrier = async (carrierId, idx) => {
    Policy.set('carrier_id', carrierId)
    Policy.set('av_term_length', null)
    Policy.set('coverage_id', null)
    setCoverage(null)

    if (!Array.isArray(SalesTrackerStore.CoveragesByCarrier[carrierId])) {
      setIsUpdating((prev) => ({ ...prev, carrier: true }))
      await SalesTrackerStore.fetchCoveragesByCarrier(carrierId)
    }

    const selected_carrier = getCarrier()
    setAvTermLengthMax(selected_carrier?.get('c_term_length_max'))
    setAvTermLengthMin(selected_carrier?.get('c_term_length_min'))
    Policy.set('av_term_length', selected_carrier?.get('c_term_length'))
    setCarrierTermMaxLength(selected_carrier?.get('c_term_length_max'))
    setCarrierTermMinLength(selected_carrier?.get('c_term_length_min'))
    setCarrierTermDefaultLength(selected_carrier?.get('c_term_length'))

    setAvCarrier()
    setPremiumLabel()
    setPointOverrideOptions(idx)
    setIsUpdating((prev) => ({ ...prev, carrier: false }))
  }

  const setShouldTrackLives = () => {
    const Coverage = getCoverage()
    if (Coverage) {
      if (parseInt(Coverage.id()) === 78 || parseInt(Coverage.id()) === 159)
        return setTrackLives(true)
      if (parseInt(Coverage.get('track_lives')) === 1)
        return setTrackLives(true)
    }

    return setTrackLives(false)
  }

  const onEvEffDateChange = (value) => {
    if (value) {
      let valueStr = JSON.parse(JSON.stringify(value)).split('T').shift()
      if (valueStr !== Policy.get('av_eff_date')) {
        Policy.set('av_eff_date', valueStr)
        setAvEffDate(value)
      }
    }
  }

  const setPremiumLabel = () => {
    const Carrier = getCarrier()

    if (Carrier) {
      if (parseInt(Carrier.get('c_term_length_max')) === 1)
        return setAvPremiumLabel('Premium')
      else if (parseInt(Policy.get('av_term_length')) > 1)
        return setAvPremiumLabel('Monthly Premium')
      else return setAvPremiumLabel('Premium')
    }

    setAvPremiumLabel('Monthly Premium')
  }

  const setPointOverrideOptions = (idx) => {
    SalesTrackerStore.setPointOverrideOptions(getCoverage(), getCarrier(), idx)
  }

  if (trackLives === null) setShouldTrackLives()

  if (Policy) {
    if (!Policy.isNew()) {
      if (sourceCoverageId === null)
        setSourceCoverageId(parseInt(Policy.get('coverage_id')))
      if (sourceCarrierId === null)
        setSourceCarrierId(parseInt(Policy.get('carrier_id')))
    }

    if (avPremiumLabel === null) setPremiumLabel()
  }

  const coverage_id = Policy.get('coverage_id'),
    carrier_id = Policy.get('carrier_id'),
    userDetails = {},
    active_av_status = null

  const getCoverageOptions = (carrierId) => {
    let Coverages = SalesTrackerStore.CoveragesByCarrier[carrierId]
    Coverages = Array.isArray(Coverages) ? Coverages : []
    return Coverages.filter(
      (Coverage) =>
        parseInt(Coverage.get('coverage_active')) === 1 ||
        (sourceCoverageId &&
          parseInt(sourceCoverageId) === parseInt(Coverage.id()))
    )
      .map((Coverage) => {
        return {
          text: Coverage.get('coverage_name'),
          value: `${Coverage.id()}`,
          checked: !!(
            coverage_id && parseInt(coverage_id) === parseInt(Coverage.id())
          ),
        }
      })
      .sort((a, b) => a.text.localeCompare(b.text))
  }

  const showToastForIllegalCharacters = (field) => {
    toast.warn(
      `You tried to paste invalid text in ${field}, but we’ve attempted to correct it. Please review for accuracy.`,
      { position: toast.POSITION.TOP_RIGHT, autoClose: 7000 }
    )
  }

  return (
    <form className="AvPolicyFormComponent container-fluid">
      <MDBRow>
        <MDBCol size="12" md="6" xl="4" className="av-input-wrapper">
          <UIDropdown
            label="Carrier"
            name="carrier_id"
            search
            disabled={isUpdating?.carrier}
            className="av-carrier-selector"
            options={getCarrierOptions()}
            selected={Policy.get('av_carrier')}
            onChange={(evt) => {
              const value = parseInt(evt.target.value)
              if (value !== parseInt(Policy.get('carrier_id'))) {
                updateCarrier(value, idx)
              }
            }}
            required={true}
            value={Policy.get('carrier_id')}
            rules={{ required: true }}
            showValidity={SalesTrackerStore.showErrors}
            onValidityChange={(validity) =>
              SalesTrackerStore.setValidity(idx, 'carrier_id', validity.isValid)
            }
          />
        </MDBCol>
        <MDBCol size="12" md="6" xl="4" className="av-input-wrapper">
          <UIDropdown
            label="Coverage Type"
            name="coverage_id"
            search={true}
            disabled={isUpdating?.carrier}
            options={getCoverageOptions(Policy.get('carrier_id'))}
            onChange={(evt) => {
              const value = parseInt(evt.target.value)
              if (value && value !== parseInt(Policy.get('coverage_id')))
                updateCoverage(value)
            }}
            required={true}
            value={Policy.get('coverage_id')}
            rules={{ required: true }}
            showValidity={SalesTrackerStore.showErrors}
            onValidityChange={(validity) =>
              SalesTrackerStore.setValidity(
                idx,
                'coverage_id',
                validity.isValid
              )
            }
          />
        </MDBCol>
        <MDBCol size="12" md="6" xl="4" className="av-input-wrapper">
          <UIInput
            label="Product"
            name="av_product"
            type="text"
            onChange={(evt) => {
              const filteredResult = preventInputUnicodeCharacters(
                evt.target.value
              )
              if (filteredResult.hasIllegalCharacters)
                showToastForIllegalCharacters('Product')
              if (filteredResult.fixedString !== Policy.get('av_product'))
                Policy.set('av_product', filteredResult.fixedString)
            }}
            required={true}
            value={Policy.get('av_product')}
            rules={{ required: true, minLength: 2, maxLength: 255 }}
            showValidity={SalesTrackerStore.showErrors}
            onValidityChange={(validity) =>
              SalesTrackerStore.setValidity(idx, 'av_product', validity.isValid)
            }
            autoComplete="off"
          />
        </MDBCol>
        <MDBCol size="12" md="6" xl="4" className="av-input-wrapper">
          <UIDropdown
            label="Term Length"
            name="av_term_length"
            options={TermLengths.map((item) => ({
              ...item,
              checked:
                Policy.get('av_term_length') !== null &&
                parseInt(item.value) === parseInt(Policy.get('av_term_length')),
            })).filter((item) =>
              !avTermLengthMax || item.checked
                ? item
                : parseInt(avTermLengthMax) < parseInt(item.value)
                ? false
                : parseInt(avTermLengthMin) > parseInt(item.value)
                ? false
                : item
            )}
            onChange={(evt) => {
              if (
                parseInt(evt.target.value) !==
                parseInt(Policy.get('av_term_length'))
              )
                Policy.set('av_term_length', evt.target.value)
              setPremiumLabel()
            }}
            required={true}
            value={Policy.get('av_term_length')}
            rules={{ required: true }}
            showValidity={SalesTrackerStore.showErrors}
            onValidityChange={(validity) =>
              SalesTrackerStore.setValidity(
                idx,
                'av_term_length',
                validity.isValid
              )
            }
            search
          />
        </MDBCol>
        <MDBCol size="12" md="6" xl="4" className="av-input-wrapper">
          <UIDropdown
            label="Application Status"
            name="av_status"
            options={AppStatusList.map((s) => ({
              ...s,
              disabled:
                appConstants.agents.includes(
                  userDetails && userDetails.usertype_id
                ) &&
                active_av_status === 'Submitted' &&
                s.value === 'Withdrawn',
              checked:
                `${Policy.get('av_status')}`.trim().toLowerCase() ===
                `${s.value}`.trim().toLowerCase(),
            }))}
            onChange={(evt) => {
              if (evt.target.value !== Policy.get('av_status'))
                Policy.set('av_status', evt.target.value)
            }}
            required={true}
            value={Policy.get('av_status')}
            rules={{ required: true }}
            showValidity={SalesTrackerStore.showErrors}
            onValidityChange={(validity) =>
              SalesTrackerStore.setValidity(idx, 'av_status', validity.isValid)
            }
            search
          />
        </MDBCol>
        <MDBCol size="12" md="6" xl="4" className="av-input-wrapper">
          <UIInput
            label="Policy # or D.O.B."
            name="av_appid"
            type="text"
            onChange={(evt) => {
              const filteredResult = preventInputUnicodeCharacters(
                evt.target.value
              )
              if (filteredResult.hasIllegalCharacters)
                showToastForIllegalCharacters('Policy # or D.O.B.')
              if (filteredResult.fixedString !== Policy.get('av_appid'))
                Policy.set('av_appid', filteredResult.fixedString)
            }}
            autoComplete="off"
            required={true}
            value={Policy.get('av_appid')}
            rules={{ required: true, minLength: 2, maxLength: 255 }}
            showValidity={SalesTrackerStore.showErrors}
            onValidityChange={(validity) =>
              SalesTrackerStore.setValidity(idx, 'av_appid', validity.isValid)
            }
          />
        </MDBCol>
        <MDBCol size="12" md="6" xl="4" className="av-input-wrapper">
          <UIInput
            label="Application Fee"
            name="av_fee"
            type="text"
            onChange={(evt) => {
              const filteredResult = preventInputUnicodeCharacters(
                evt.target.value
              )
              if (filteredResult.hasIllegalCharacters)
                showToastForIllegalCharacters('Application Fee')
              if (filteredResult.fixedString !== Policy.get('av_fee'))
                Policy.set(
                  'av_fee',
                  isNaN(filteredResult.fixedString) ||
                    !filteredResult.fixedString
                    ? '0'
                    : filteredResult.fixedString
                )
            }}
            autoComplete="off"
            value={Policy.get('av_fee')}
            showValidity={SalesTrackerStore.showErrors}
            onValidityChange={(validity) =>
              SalesTrackerStore.setValidity(idx, 'av_fee', validity.isValid)
            }
            onKeyPress={currencyKeypress}
          />
        </MDBCol>
        <MDBCol size="12" md="6" xl="4" className="av-input-wrapper">
          <UIInput
            label={avPremiumLabel}
            name="av_premium"
            type="text"
            onChange={(evt) => {
              const filteredResult = preventInputUnicodeCharacters(
                evt.target.value
              )
              if (filteredResult.hasIllegalCharacters)
                showToastForIllegalCharacters(avPremiumLabel)
              if (filteredResult.fixedString !== Policy.get('av_premium'))
                Policy.set('av_premium', filteredResult.fixedString)
            }}
            autoComplete="off"
            required={true}
            value={Policy.get('av_premium')}
            rules={{ required: true, minLength: 2, maxLength: 255 }}
            showValidity={SalesTrackerStore.showErrors}
            onValidityChange={(validity) =>
              SalesTrackerStore.setValidity(idx, 'av_premium', validity.isValid)
            }
            onKeyPress={currencyKeypress}
          />
          {parseInt(Policy.get('av_term_length')) === 1 && coverage === 90 && (
            <small className="text-left helper-link">
              Please enter entire premium amount.
            </small>
          )}
          {parseInt(Policy.get('av_term_length')) > 1 && coverage === 90 && (
            <small className="text-left helper-link">
              Please use a term length of 1 month for annuities.
            </small>
          )}
        </MDBCol>
        <MDBCol size="12" md="6" xl="4" className="av-input-wrapper">
          <UIDatePickerInput
            label={'Effective Date'}
            name="av_eff_date"
            id="av_eff_date"
            onChange={(evt) => onEvEffDateChange(evt.target.value)}
            required={
              isPreviousPlicy && !previousPolicyHasEffDate ? false : true
            }
            autoComplete="off"
            showYearDropdown
            yearDropdownItemNumber={5}
            scrollableYearDropdown
            dateFormat="MM/dd/yyyy"
            selected={avEffDate}
            value={
              avEffDate && typeof avEffDate === 'object'
                ? moment(avEffDate).format('MM/DD/YYYY')
                : null
            }
            rules={{
              required:
                isPreviousPlicy && !previousPolicyHasEffDate ? false : true,
            }}
            showValidity={SalesTrackerStore.showErrors}
            onValidityChange={(validity) => {
              if (!(isPreviousPlicy && !previousPolicyHasEffDate)) {
                SalesTrackerStore.setValidity(
                  idx,
                  'av_eff_date',
                  validity.isValid
                )
              }
            }}
          />
          {(isPreviousPlicy && !previousPolicyHasEffDate ? true : false) && (
            <MDBIcon
              className="clear-icon"
              icon="times"
              onClick={() => {
                Policy.set('av_eff_date', null)
                setAvEffDate(null)
              }}
            />
          )}
        </MDBCol>
        {trackLives ? (
          <MDBCol size="12" md="6" xl="4" className="av-input-wrapper">
            <UIInput
              label="Lives"
              name="lives"
              type="number"
              onChange={(evt) => {
                const filteredResult = preventInputUnicodeCharacters(
                  evt.target.value
                )
                if (filteredResult.hasIllegalCharacters)
                  showToastForIllegalCharacters('Lives')
                if (
                  parseInt(filteredResult.fixedString) !==
                  parseInt(Policy.get('lives'))
                )
                  Policy.set('lives', filteredResult.fixedString)
              }}
              required={true}
              value={Policy.get('lives')}
              rules={{ required: true, max: 25, min: 1 }}
              showValidity={SalesTrackerStore.showErrors}
              onValidityChange={(validity) =>
                SalesTrackerStore.setValidity(idx, 'lives', validity.isValid)
              }
            />
          </MDBCol>
        ) : (
          <></>
        )}
      </MDBRow>
      <MDBRow>
        <PointOverrides
          Policy={Policy}
          policyIdx={idx}
          Fields={SalesTrackerStore.PointOverrideFields[idx]}
          carrierId={getCarrier()?.id() || ''}
          coverageId={getCoverage()?.id() || ''}
        />
      </MDBRow>
      <MDBRow>
        <MDBCol size="12" className="av-input-wrapper">
          <UIInput
            label="Comments"
            name="av_comments"
            type="textarea"
            onChange={(evt) => {
              const filteredResult = preventInputUnicodeCharacters(
                evt.target.value
              )
              if (filteredResult.hasIllegalCharacters)
                showToastForIllegalCharacters('Comments')
              if (filteredResult.fixedString !== Policy.get('av_comments'))
                Policy.set('av_comments', filteredResult.fixedString)
            }}
            value={Policy.get('av_comments')}
            showValidity={SalesTrackerStore.showErrors}
            onValidityChange={(validity) =>
              SalesTrackerStore.setValidity(
                idx,
                'av_comments',
                validity.isValid
              )
            }
          />
        </MDBCol>
      </MDBRow>
    </form>
  )
}

export default observer(AvPolicyForm)
