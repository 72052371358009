import BaseModelInstance from './base.model'
import { makeObservable, observable } from 'mobx'
import { deepClone } from './../../shared/utilities/deepClone.function'
import { GoalBlocks } from './../../components/kpi/variables'
import GoalsCalculator from './../../components/kpi/GoalsCalculator.function'
import UserProfileService from '../services/UserProfile.service'

const UserGoalDefinition = {
  permitted: [
    'add_appts_set',
    'add_calls',
    'add_spoken_to',
    'add_fact_finders',
    'adhoc_calls',
    'adhoc_events_attended',
    'adhoc_spoken_to',
    'adhoc_fact_finders',
    'adhoc_appts_set',
    'adhoc_presentations_offered',
    'adhoc_referrals_requested',
    'adhoc_life_quoted',
    'adhoc_hours_worked',
    'goal_apps',
    'goal_calls',
    'goal_points',
    'goal_team_points',
    'goal_enrolled_agents',
    'goal_team_enrolled_agents',
    'goal_spoken_to',
    'goal_clients',
    'goal_earnings',
    'paradigm',
    'prev_apps',
    'prev_calls',
    'prev_enrolled_agents',
    'prev_clients',
    'prev_earnings',
    'prev_points',
    'prev_team_points',
    'prev_team_enrolled_agents',
    'user_id',
  ],
  timestamps: true,
  id: 'id',
  user_id: 'user_id',
  service: 'UserGoalService',
}

const calcTriggers = [
  'goal_earnings',
  'goal_points',
  'goal_enrolled_agents',
  'goal_team_points',
  'goal_team_enrolled_agents',
  'prev_apps',
  'prev_calls',
  'prev_spoken_to',
  'prev_clients',
  'prev_earnings',
  'prev_points',
  'prev_enrolled_agents',
  'prev_team_points',
  'prev_team_enrolled_agents',
]

const isCalcTrigger = (key) => calcTriggers.indexOf(key) > -1

class UserGoal extends BaseModelInstance {
  BlockDefs = {}
  goal_blocks = {}

  calculate = () => {
    this.keys()
      .filter((k) => this.BlockDefs.hasOwnProperty(k))
      .forEach((k) => (this.BlockDefs[k].value = this.get(k)))
    let goal_blocks = GoalsCalculator(this.BlockDefs)
    this.goal_blocks = Object.keys(goal_blocks)
      .filter((k) => {
        if (typeof goal_blocks[k].guards === 'undefined') return true
        if (
          Array.isArray(goal_blocks[k].guards) &&
          goal_blocks[k].guards.length === 0
        )
          return true
        if (UserProfileService.isA(goal_blocks[k].guards)) return true
        return false
      })
      .map((k) => goal_blocks[k])
    Object.keys(this.goal_blocks)
      .filter((key) => !isCalcTrigger(key))
      .forEach((key) => this.set(key, this.goal_blocks[key].value))
  }

  afterSet = (key, value) => {
    if (isCalcTrigger(key)) this.calculate()
  }

  isFieldValid = (field) => {
    const test = (t) => !!(t && this.get(t) && parseFloat(this.get(t)) > 0)
    if (test(field)) return true

    field = ['adhoc_events_attended', 'adhoc_calls'].includes(field)
      ? field === 'adhoc_events_attended'
        ? 'adhoc_calls'
        : 'adhoc_events_attended'
      : false

    return field && test(field)
  }

  isFieldsValid = (fields, test) =>
    (Array.isArray(fields) ? fields : [fields]).every(this.isFieldValid)

  get isComplete() {
    if (
      parseInt(this.get('adhoc_events_attended')) > 0 ||
      parseInt(this.get('adhoc_calls')) > 0
    ) {
      return [
        'adhoc_fact_finders',
        'adhoc_appts_set',
        // These two are either-or
        // 'adhoc_calls',
        // 'adhoc_events_attended'
        'adhoc_spoken_to',
        'adhoc_hours_worked',
        'adhoc_referrals_requested',
        'adhoc_life_quoted',
        'adhoc_presentations_offered',
        'goal_earnings',
        'prev_apps',
        'prev_calls',
        'prev_clients',
        'prev_earnings',
        'prev_points',
      ]
        .concat(
          UserProfileService.isA('recruiter-group')
            ? [
                'prev_team_points',
                'prev_enrolled_agents',
                'prev_team_enrolled_agents',
              ]
            : []
        )
        .filter((n) => !!n)
        .every((k) => parseFloat(this.get(k)) > 0)
    }

    return
  }

  constructor(attribs) {
    super(UserGoalDefinition, attribs)
    this.goal_blocks = {}
    this.BlockDefs = deepClone(GoalBlocks)
    makeObservable(this, { goal_blocks: observable })
  }
}

export default UserGoal
