import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import AdminApi from './../../../../api/admin-api/admin-api'
import { WebsiteInfoStore } from './../../stores'
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCollapse,
  MDBAlert,
  MDBContainer,
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCollapseHeader,
} from 'mdbreact'
import { UIInput } from './../../../../components/forms/form-fields'

import './HowHearOptionsForm.component.scss'

const saveTimer = {}

const HowHearOptionsForm = () => {
  const [formError, setFormError] = useState(null),
    [formSuccess, setFormSuccess] = useState(null)

  const onChangeValue = (event) => {
    const [_name, idx] = `${event.target.name}`.split('_')
    WebsiteInfoStore.siteDetails.how_hear_options[idx] = event.target.value
  }

  const validate = () => {
    const { how_hear_options } = WebsiteInfoStore.siteDetails
    for (let testimonial of how_hear_options) {
      if (!testimonial) {
        setFormError('Please enter correct content.')
        return false
      }
    }

    return true
  }

  const update = async () => {
    if (!validate()) return false

    if (WebsiteInfoStore.isLoading || WebsiteInfoStore.isSaving) return

    let form_data = new FormData()
    const { how_hear_options } = WebsiteInfoStore.siteDetails
    form_data.append('how_hear_options', JSON.stringify(how_hear_options))
    form_data.append('id', WebsiteInfoStore.siteDetails?.site_id)

    WebsiteInfoStore.isSaving = true

    AdminApi.updateWebsiteInfo(form_data)
      .then((result) => {
        setFormSuccess('Successfully updated options.')

        if (saveTimer.timer) {
          window.clearTimeout(saveTimer.timer)
          saveTimer.timer = null
        }

        saveTimer.timer = setTimeout(() => setFormSuccess(null), 4000)

        WebsiteInfoStore.isSaving = false
      })
      .catch(() => {
        setFormError('Failed to update options')
        saveTimer.timer = setTimeout(() => setFormError(null), 4000)
        WebsiteInfoStore.isSaving = false
      })
  }

  const remove = (index) => {
    WebsiteInfoStore.siteDetails.how_hear_options.splice(index, 1)
    if (WebsiteInfoStore.siteDetails.how_hear_options.length === 0) {
      WebsiteInfoStore.siteDetails.how_hear_options = []
      update()
    }
  }

  const add = () => {
    if (!WebsiteInfoStore.siteDetails.how_hear_options)
      WebsiteInfoStore.siteDetails.how_hear_options = []
    WebsiteInfoStore.siteDetails.how_hear_options.unshift('')
  }

  return (
    <form id="HowHearFormComponent" noValidate className="container-fluid mt-5">
      <MDBRow>
        <MDBCol>
          <h5 className="text--blue font--lato fw--500">
            How did you hear about us?
          </h5>
        </MDBCol>
        <MDBCol>
          <MDBBtn
            type="button"
            onClick={add}
            className="btn-success float-right btn-create"
          >
            <MDBIcon icon="plus" />
            &nbsp;New
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      {WebsiteInfoStore.siteDetails.how_hear_options &&
      WebsiteInfoStore.siteDetails.how_hear_options.length > 0 ? (
        <MDBContainer className="accordion md-accordion accordion-1">
          {WebsiteInfoStore.siteDetails.how_hear_options.map((t, index) => {
            return (
              <div key={index}>
                <UIInput
                  label={`Option ${index + 1}`}
                  type="text"
                  className="form-control"
                  name={`option_${index}`}
                  value={t || ''}
                  onChange={onChangeValue}
                  hint="Enter option here."
                />
                <div>
                  <MDBBtn
                    type="button"
                    color="primary"
                    onClick={() => remove(index)}
                    className="btn-sm btn-delete float-right"
                  >
                    <MDBIcon icon="trash" />
                    &nbsp;Remove
                  </MDBBtn>
                </div>
              </div>
            )
          })}
        </MDBContainer>
      ) : (
        <></>
      )}
      {WebsiteInfoStore.siteDetails.how_hear_options &&
      WebsiteInfoStore.siteDetails.how_hear_options.length ? (
        <MDBRow>
          <MDBCol className="form-group">
            <MDBBtn color="indigo" type="button" onClick={update}>
              {WebsiteInfoStore.isSaving ? 'Saving...' : 'Save'}
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      ) : (
        <></>
      )}
      <MDBRow>
        <MDBCol>
          <MDBCollapse isOpen={!!formError}>
            <MDBAlert color="danger">{formError}</MDBAlert>
          </MDBCollapse>
          <MDBCollapse isOpen={!!formSuccess}>
            <MDBAlert color="success">{formSuccess}</MDBAlert>
          </MDBCollapse>
        </MDBCol>
      </MDBRow>
    </form>
  )
}

export default observer(HowHearOptionsForm)
