import { observer } from 'mobx-react-lite'
import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import BATeamForm from './../BATeamForm/BATeamForm.component'
import Store from './../../store'

import './BATeamList.scss'

const BATeamList = (props) => {
  return (
    <MDBContainer fluid className="BATeamListComponent">
      {Store.BATeams.map((BATeam, idx) => (
        <MDBRow key={`ba-team-form-${BATeam?.id()}-${idx}`}>
          <MDBCol>
            <BATeamForm BATeam={BATeam} />
          </MDBCol>
        </MDBRow>
      ))}
    </MDBContainer>
  )
}

export default observer(BATeamList)
