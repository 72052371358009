import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { toast } from 'react-toastify'
import ContentHtml from './../../../components/content/ContentHtml/ContentHtml.component'
import ContentModal from './../../../components/modals/ContentModal/ContentModal.component'
import AgencyContentStore from './../../../shared/stores/agencyContent.store'
import ToolTip from './../../shared/ToolTip/ToolTip.component'
import {
  MDBIcon,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from 'mdbreact'
import NumberFormat from 'react-number-format'
import MaterialInput from '@material-ui/core/Input'

import './KpiGoalManager.scss'
import UserProfileService from '../../../shared/services/UserProfile.service'

const ENABLE_BASELINE_SELECTOR = false,
  ENABLE_AUTO_HEIGHT_COLS = false

const agentGoalsShadow = {}

const KpiGoalManager = ({
  UserGoals,
  UserGoal,
  TotalPoints,
  TotalEnrolled,
  isOnboarding,
  onValidityChange,
  goTo,
}) => {
  const [explainerSlug, setExplainerSlug] = useState(null)
  const [descriptionHeight, setDescriptionHeight] = useState(0)
  const [toolTips, setToolTips] = useState(false)
  const [userGoalId, setUserGoalId] = useState(null)
  const [errorMessage, setErrorMessage] = useState({})

  useEffect(() => {
    TotalPoints &&
      UserGoal.set('prev_team_points', `${TotalPoints}`.replace(/[^0-9.]/g, ''))

    TotalEnrolled &&
      UserGoal.set(
        'prev_team_enrolled_agents',
        `${TotalEnrolled}`.replace(/[^0-9.]/g, '')
      )
  }, [TotalPoints, TotalEnrolled, UserGoal])

  if (!UserGoal) return <></>

  const setValidity = (isValid) => {
    if (typeof isValid !== 'boolean') isValid = !!UserGoal?.isComplete

    if (onValidityChange && typeof onValidityChange === 'function')
      onValidityChange(isValid)
  }

  const onSave = async () => {
    if (Object.keys(errorMessage).filter((e) => !!errorMessage[e]).length) {
      console.log('Form errors exist. ', errorMessage)
      return false
    }

    if (!UserGoal.isValid()) {
      console.log('Goal is invalid.')
      return false
    }

    if (!UserGoal.isComplete) {
      console.log('Goal is incomplete.')
      return false
    }

    if (!UserGoal.hasChanged) {
      console.log('Goal hasnt changed.')
      return false
    }

    let result
    try {
      result = await UserGoal.save()
    } catch (ex) {
      console.error('Failed to save user goal', ex)
      result = `${ex}`
    }

    if (result === true)
      toast.success(
        'Agent Key Performance Indicator (KPI) Goals have been saved!',
        { position: toast.POSITION.TOP_RIGHT }
      )
    else
      toast.error(
        result ||
          'Oh no! An unknown error occurred attempting to save your goals.',
        { position: toast.POSITION.TOP_RIGHT }
      )

    setValidity(result === true ? UserGoal.isComplete : false)
  }

  const setBaselineHistoricalData = (setting) => {
    const values = {
      standard: {
        prev_earnings: '100000',
        prev_points: '20000',
        prev_apps: '416',
        prev_clients: '104',
        prev_calls: '52000',
        prev_spoken_to: '10400',
        goal_earnings: '100000',
        adhoc_fact_finders: 25,
        adhoc_appts_set: 25,
        adhoc_calls: 1000,
        adhoc_spoken_to: 200,
        adhoc_hours_worked: 40,
        adhoc_referrals_requested: 3,
        adhoc_life_quoted: 3,
        adhoc_presentations_offered: 10,
      },
      advanced: {
        prev_earnings: '110000',
        prev_points: '28600',
        prev_apps: '457',
        prev_clients: '114',
        prev_calls: '57200',
        prev_spoken_to: '11440',
        goal_earnings: '110000',
        adhoc_fact_finders: 25,
        adhoc_appts_set: 25,
        adhoc_calls: 1000,
        adhoc_spoken_to: 200,
        adhoc_hours_worked: 40,
        adhoc_referrals_requested: 3,
        adhoc_life_quoted: 3,
        adhoc_presentations_offered: 10,
      },
      aggressive: {
        prev_earnings: '121000',
        prev_points: '31460',
        prev_apps: '503',
        prev_clients: '125',
        prev_calls: '62920',
        prev_spoken_to: '12584',
        goal_earnings: '120000',
        adhoc_fact_finders: 25,
        adhoc_appts_set: 25,
        adhoc_calls: 1000,
        adhoc_spoken_to: 200,
        adhoc_hours_worked: 40,
        adhoc_referrals_requested: 3,
        adhoc_life_quoted: 3,
        adhoc_presentations_offered: 10,
      },
      reset: {
        prev_earnings: '0',
        prev_points: '0',
        prev_apps: '0',
        prev_clients: '0',
        prev_calls: '0',
        prev_spoken_to: '0',
        goal_earnings: '0',
        adhoc_fact_finders: '0',
        adhoc_appts_set: '0',
        adhoc_calls: '0',
        adhoc_spoken_to: '0',
        adhoc_hours_worked: '0',
        adhoc_referrals_requested: '0',
        adhoc_life_quoted: '0',
        adhoc_presentations_offered: '0',
      },
    }

    if (setting !== 'reset' && !Object.values(agentGoalsShadow).length)
      Object.keys(values[setting]).forEach(
        (key) => (agentGoalsShadow[key] = UserGoal.get(key))
      )

    if (setting === 'reset') {
      if (Object.keys(agentGoalsShadow).length)
        Object.keys(agentGoalsShadow).forEach((key) =>
          UserGoal.set(key, agentGoalsShadow[key])
        )
    } else
      Object.keys(values[setting]).forEach((key) =>
        UserGoal.set(key, values[setting][key])
      )
  }

  const fetchToolTips = async () => {
    if (!AgencyContentStore.content['kpis-goals-tool-tips'])
      await AgencyContentStore.fetchByContentObj({
        contentSlug: 'kpis-goals-tool-tips',
      })

    if (!toolTips && AgencyContentStore.content['kpis-goals-tool-tips']) {
      let ttSource = toJS(AgencyContentStore.content['kpis-goals-tool-tips'])
      if (ttSource && Array.isArray(ttSource)) ttSource = ttSource.shift()
      if (ttSource && typeof ttSource === 'object' && ttSource?.content_data) {
        try {
          setToolTips(
            typeof ttSource.content_data === 'string'
              ? JSON.parse(ttSource.content_data)
              : ttSource.content_data
          )
        } catch (ex) {
          console.error(
            'Failed to fetch & parse ToolTips json from AgencyContentStore. ',
            ex
          )
        }
      }
    }
  }

  const matchKpiStepDescriptorHeights = (content) => {
    if (ENABLE_AUTO_HEIGHT_COLS) {
      let heights = [],
        max = 0
      Array.from(
        document.querySelectorAll('.kpi-step-descriptor>.ContentHtmlComponent')
      ).forEach((el) => heights.push(parseInt(el.clientHeight)))
      if (
        heights.length > 1 &&
        heights.filter((val, idx, self) => self.indexOf(val) === idx).length > 1
      )
        max = heights
          .sort((a, b) => `${a}`.localeCompare(`${b}`, 'en', { numeric: true }))
          .pop()

      if (
        !isNaN(max) &&
        max > 0 &&
        parseInt(descriptionHeight) !== parseInt(max)
      )
        setDescriptionHeight(max)
    }
  }

  const getToolTip = (field) => {
    if (field && toolTips && toolTips.hasOwnProperty(field))
      return toolTips[field] ? toolTips[field] : false
    return
  }

  const setPrevGoalData = () => {
    let PrevUserGoal
    ;(UserGoals || []).forEach((UG) => {
      if (PrevUserGoal) {
        if (
          new Date(UG.get('created_at')).getFullYear() >
          new Date(PrevUserGoal.get('created_at')).getFullYear()
        )
          PrevUserGoal = UG
      } else {
        PrevUserGoal = UG
      }
    })

    const copyProps = [
      'adhoc_appts_set',
      'adhoc_events_attended',
      'adhoc_calls',
      'adhoc_fact_finders',
      'adhoc_hours_worked',
      'adhoc_life_quoted',
      'adhoc_presentations_offered',
      'adhoc_referrals_requested',
      'adhoc_spoken_to',
      'goal_apps',
      'goal_calls',
      'goal_clients',
      'goal_earnings',
    ]

    // Auto-fetch & pre-populate.
    // 'prev_apps',
    // 'prev_calls',
    // 'prev_clients',
    // 'prev_earnings',
    // 'prev_points',

    copyProps.forEach((prop) => UserGoal.set(prop, PrevUserGoal.get(prop)))
    UserGoal.calculate()
  }

  const viewPastGoals = (PrevUserGoal) => {
    setUserGoalId(PrevUserGoal.id())
    UserGoal = PrevUserGoal
  }

  const renderHeaderRow = () => {
    const enableLinks = goTo && typeof goTo === 'function'

    return (
      <MDBRow className={'kpi-intro ' + (enableLinks ? 'enable-links' : '')}>
        <MDBCol
          size="12"
          lg={enableLinks ? '8' : '12'}
          xl={enableLinks ? '7' : '12'}
        >
          <ContentHtml contentId="87" />
        </MDBCol>
        {enableLinks ? (
          <MDBCol size="12" lg="4" xl="5">
            <ul className="kpi-dashboard-link">
              <li onClick={() => goTo('/kpi-tracking')} className="btn">
                <MDBIcon icon="edit" />
                &nbsp;Track Your Daily Work
              </li>
              <li onClick={() => goTo('/kpi-dashboard')} className="btn">
                <MDBIcon icon="chart-pie" />
                &nbsp;View Your Goal Progress
              </li>
              {(UserGoals || []).length > 1 ? (
                <MDBDropdown>
                  <MDBDropdownToggle
                    tag="li"
                    className="btn btn-block"
                    caret
                    color="primary"
                  >
                    <MDBIcon icon="calculator" />
                    &nbsp;View Past Goals
                  </MDBDropdownToggle>
                  <MDBDropdownMenu color="primary">
                    {UserGoals.filter(
                      (UG) =>
                        new Date(UG.get('created_at')).getFullYear() !==
                        new Date().getFullYear()
                    ).map((UG, idx) => {
                      return (
                        <MDBDropdownItem
                          key={`view-past-goal-${UG.id()}-${idx}`}
                          onClick={() => viewPastGoals(UG)}
                        >
                          {`${new Date(
                            UG.get('created_at')
                          ).getFullYear()} Goals`}
                        </MDBDropdownItem>
                      )
                    })}
                  </MDBDropdownMenu>
                </MDBDropdown>
              ) : (
                <></>
              )}
            </ul>
          </MDBCol>
        ) : (
          <></>
        )}
      </MDBRow>
    )
  }

  const renderBaselineSetterBtn = () => {
    return ENABLE_BASELINE_SELECTOR ? (
      <>
        <MDBDropdown>
          <MDBDropdownToggle className="btn-block" caret color="primary">
            Select Baseline Data ...
          </MDBDropdownToggle>
          <MDBDropdownMenu color="primary">
            <MDBDropdownItem
              onClick={() => setBaselineHistoricalData('standard')}
            >
              Standard
            </MDBDropdownItem>
            <MDBDropdownItem
              onClick={() => setBaselineHistoricalData('advanced')}
            >
              Advanced
            </MDBDropdownItem>
            <MDBDropdownItem
              onClick={() => setBaselineHistoricalData('aggressive')}
            >
              Aggressive
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => setBaselineHistoricalData('reset')}>
              Reset Values
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </>
    ) : (
      <MDBBtn onClick={() => setBaselineHistoricalData('standard')}>
        Set Baseline Data
      </MDBBtn>
    )
  }

  const renderPrevGoalsSetterBtn = () => {
    if (!UserGoal || (UserGoal && UserGoal.isNew()))
      return (
        <MDBBtn onClick={() => setPrevGoalData()}>
          Copy Most Recent Goals
        </MDBBtn>
      )

    return <></>
  }

  const renderRevenueInput = (field, isDisabled) => {
    let input = UserGoal?.goal_blocks ? UserGoal.goal_blocks[field] : null
    if (input) {
      isDisabled =
        (userGoalId && userGoalId > 0) ||
        !!(isDisabled ? isDisabled : !input.min && !!input.formula)
      const toolTip = getToolTip(field)
      return (
        <div
          key={`block-input-field-${field}`}
          className={
            'd-flex justify-content-between align-items-center kpi-input ' +
            (UserGoal.isFieldValid(field) ? 'is-complete ' : 'is-incomplete ') +
            (isDisabled ? 'is-disabled' : '')
          }
        >
          {toolTip ? (
            <ToolTip
              label={
                <h6>
                  <MDBIcon
                    icon="fas fa-question"
                    style={{ marginRight: '3px' }}
                  />
                  {input?.title}
                </h6>
              }
              icon={false}
              placement={'right'}
              content={toolTip}
              iconPosition="left"
            />
          ) : (
            <h6 className={errorMessage[input.field] ? 'invalid-label' : ''}>
              {input?.title}
              {errorMessage[input.field] ? (
                <ToolTip
                  placement="top"
                  label={
                    <MDBIcon
                      icon="exclamation-circle"
                      className="danger-icon"
                    />
                  }
                  icon={false}
                  className="bg-danger"
                  content={<span>{errorMessage[input.field]}</span>}
                />
              ) : (
                ''
              )}
            </h6>
          )}
          <NumberFormat
            value={input.display(input.value)}
            name={input.field}
            disabled={isDisabled}
            customInput={MaterialInput}
            thousandSeparator={true}
            prefix={(input.mask && input.mask.prefix) || ''}
            decimalScale={input?.decimalScale}
            onChange={(e) => {
              UserGoal.set(
                e.target.name,
                `${e.target.value}`.replace(/[^0-9.]/g, '')
              )
              if (input.minValue) {
                if (
                  parseInt(`${e.target.value}`.replace(/[^0-9.]/g, '')) <
                  input.minValue
                ) {
                  setErrorMessage({
                    ...errorMessage,
                    [input.field]:
                      input.title +
                      ' should be ' +
                      parseInt(Math.ceil(input.minValue)) +
                      ' or higher.',
                  })
                } else {
                  setErrorMessage({
                    ...errorMessage,
                    [input.field]: undefined,
                  })
                }
              }
            }}
          />
        </div>
      )
    }
  }

  const renderAdhocInput = (adhoc, isDisabled) => {
    if (typeof adhoc === 'string') {
      const adhocs = {
        adhoc_fact_finders: {
          name: 'Fact Finders',
          field: 'adhoc_fact_finders',
          question: 'How many Fact Finders will you complete per week?',
        },
        adhoc_appts_set: {
          name: 'Appointments Set',
          field: 'adhoc_appts_set',
          question: 'How many Appointments will you set per week?',
        },
        adhoc_events_attended: {
          name: 'Events Attended',
          field: 'adhoc_events_attended',
          question: 'How many Lead Events will you attend per week?',
        },
        adhoc_calls: {
          name: 'Lead Calls Completed',
          field: 'adhoc_calls',
          question: 'How many Lead Calls will you complete per week?',
        },
        adhoc_spoken_to: {
          name: 'Customers Spoken To (Over 2 min)',
          field: 'adhoc_spoken_to',
          question:
            'How many Lead Event Contacts or Customer Calls will result in a conversation over 2 min per week?',
        },
        adhoc_hours_worked: {
          name: 'Hours Worked',
          field: 'adhoc_hours_worked',
          question: 'How many Hours will you work per week?',
        },
        adhoc_referrals_requested: {
          name: 'Referrals Requested',
          field: 'adhoc_referrals_requested',
          question: 'How many Referrals will you request per week?',
        },
        adhoc_life_quoted: {
          name: 'Life Quotes Completed',
          field: 'adhoc_life_quoted',
          question: 'How many Life Quotes will you complete per week?',
        },
        adhoc_presentations_offered: {
          name: 'Presentations Offered',
          field: 'adhoc_presentations_offered',
          question: 'How many Presentations will your offer per week?',
        },
      }

      adhoc = adhocs.hasOwnProperty(adhoc) ? adhocs[adhoc] : false
    }

    if (!adhoc) return <></>

    if (adhoc && !adhoc?.tool_tip)
      adhoc = { ...adhoc, tool_tip: getToolTip(adhoc && adhoc?.field) }

    return (
      <React.Fragment key={`block-input-field-${adhoc.field}`}>
        <div
          className={
            'd-flex justify-content-between align-items-center kpi-input ' +
            (UserGoal.isFieldValid(adhoc.field)
              ? 'is-complete '
              : 'is-incomplete ') +
            (isDisabled ? 'is-disabled' : '')
          }
        >
          {adhoc?.tool_tip ? (
            <ToolTip
              label={
                <h6>
                  <MDBIcon
                    icon="fas fa-question"
                    style={{ marginRight: '3px' }}
                  />
                  {adhoc?.question}
                </h6>
              }
              icon={false}
              placement={'left'}
              content={adhoc.tool_tip}
              iconPosition="left"
            />
          ) : (
            <h6>{adhoc?.question}</h6>
          )}
          <NumberFormat
            value={UserGoal.get(adhoc.field)}
            name={adhoc.field}
            disabled={(userGoalId && userGoalId > 0) || isDisabled}
            customInput={MaterialInput}
            thousandSeparator={true}
            prefix={''}
            onChange={(e) =>
              UserGoal.set(
                e.target.name,
                `${e.target.value}`.replace(/[^0-9.]/g, '')
              )
            }
          />
        </div>
      </React.Fragment>
    )
  }

  const renderPastPerformance = () => {
    let inputs = Object.keys(UserGoal?.goal_blocks || {}).filter(
        (k) => parseInt(UserGoal.goal_blocks[k].block) === 2
      ),
      isValid = UserGoal.isFieldsValid(inputs)
    return (
      <>
        <div
          className={
            'kpi-step agent-past-performance ' + (isValid ? 'is-valid' : '')
          }
        >
          <h5 className="kpi-step-label">
            1. Collect Your Data&nbsp;
            <span>
              <MDBIcon icon="check-circle" />
            </span>
          </h5>
          <div className="kpi-step-descriptor">
            <ContentHtml
              contentSlug="kpis-collecting-data"
              onLoad={matchKpiStepDescriptorHeights}
            />
          </div>
          <div className="kpi-step-explainer">
            <MDBBtn
              block
              onClick={() => setExplainerSlug('kpis-help-collect-data')}
            >
              How do I collect my data?&nbsp;
              <MDBIcon icon="external-link-square-alt" />
            </MDBBtn>
          </div>
          {(UserGoals || []).length === 0 ? (
            <div className="kpi-baseline-wrapper">
              <div>Don't have past data?</div>
              <div>{renderBaselineSetterBtn()}</div>
            </div>
          ) : !UserGoal || (UserGoal && UserGoal.isNew()) ? (
            <div className="kpi-baseline-wrapper">
              <div>Starting a new year?</div>
              <div>{renderPrevGoalsSetterBtn()}</div>
            </div>
          ) : (
            <div className="kpi-baseline-wrapper">&nbsp;</div>
          )}

          <div className="kpi-input-wrapper">
            {Object.keys(UserGoal?.goal_blocks || {})
              .filter((k) => parseInt(UserGoal.goal_blocks[k].block) === 2)
              .map((fld, idx) => renderRevenueInput(fld))}
          </div>
        </div>
      </>
    )
  }

  const renderCalcMetrics = () => {
    const fields = [
      'prev_earnings',
      'prev_points',
      'prev_apps',
      'prev_clients',
      'prev_calls',
    ]
    if (UserProfileService.isA('recruiter-group')) {
      fields.push('prev_enrolled_agents')
      fields.push('prev_team_points')
      fields.push('prev_team_enrolled_agents')
    }
    let isDisabled = !UserGoal.isFieldsValid(fields),
      inputs = Object.keys(UserGoal?.goal_blocks || {}).filter(
        (k) =>
          (parseInt(UserGoal.goal_blocks[k].block) === 1 &&
            UserGoal.goal_blocks[k].formula !== null) ||
          parseInt(UserGoal.goal_blocks[k].block) === 3
      ),
      isValid = UserGoal.isFieldsValid(
        inputs.filter((k) => k.indexOf('goal_') > -1)
      )
    return (
      <>
        <div
          className={
            'kpi-step agent-calc-metrics ' +
            (isValid ? 'is-valid ' : '') +
            (isDisabled ? 'is-disabled' : '')
          }
        >
          <h5 className="kpi-step-label">
            2. Understand Your Numbers&nbsp;
            <span>
              <MDBIcon icon="check-circle" />
            </span>
          </h5>
          {/*<div className="kpi-step-descriptor" style={{height: `${descriptionHeight}px`}}>*/}
          <div className="kpi-step-descriptor">
            <ContentHtml
              contentSlug="kpis-understanding-data"
              onLoad={matchKpiStepDescriptorHeights}
            />
          </div>
          <div className="kpi-step-explainer">
            <MDBBtn
              block
              onClick={() => setExplainerSlug('kpis-help-understand-data')}
            >
              What do my numbers mean?&nbsp;
              <MDBIcon icon="external-link-square-alt" />
            </MDBBtn>
          </div>
          <div className="kpi-baseline-wrapper">
            <div>&nbsp;</div>
            <div>&nbsp;</div>
          </div>
          <div className="kpi-input-wrapper">
            {inputs.map((fld, idx) => renderRevenueInput(fld, isDisabled))}
          </div>
        </div>
      </>
    )
  }

  const renderActionPlan = () => {
    const fields = [
      'prev_earnings',
      'prev_points',
      'prev_apps',
      'prev_clients',
      'prev_calls',
    ]
    if (UserProfileService.isA('recruiter-group')) {
      fields.push('prev_enrolled_agents')
      fields.push('prev_team_points')
      fields.push('prev_team_enrolled_agents')
    }
    let isDisabled = !UserGoal.isFieldsValid(fields),
      longTermInputs = [
        'goal_earnings',
        'goal_points',
        'goal_enrolled_agents',
        'goal_team_points',
        'goal_team_enrolled_agents',
      ],
      shortTermInputs = (UserGoal?.permitted() || []).filter(
        (k) => k.indexOf('adhoc_') > -1
      ),
      isLongTermValid = UserGoal.isFieldsValid(longTermInputs),
      isShortTermValid = UserGoal.isFieldsValid(shortTermInputs)

    return (
      <>
        <div
          className={
            'kpi-step agent-action-plan ' +
            (isLongTermValid && isShortTermValid ? 'is-valid ' : '') +
            (isDisabled ? 'is-disabled' : '')
          }
        >
          <h5 className="kpi-step-label">
            3. Create Your Action Plan&nbsp;
            <span>
              <MDBIcon icon="check-circle" />
            </span>
          </h5>
          {/*<div className="kpi-step-descriptor" style={{height: `${descriptionHeight}px`}}>*/}
          <div className="kpi-step-descriptor">
            <ContentHtml
              contentSlug="kpis-planning-data"
              onLoad={matchKpiStepDescriptorHeights}
            />
          </div>
          <div className="kpi-step-explainer">
            <MDBBtn
              block
              onClick={() => setExplainerSlug('kpis-help-plan-data')}
            >
              How can I best set my goals?&nbsp;
              <MDBIcon icon="external-link-square-alt" />
            </MDBBtn>
          </div>
          <div className="kpi-baseline-wrapper">
            <div>Long Term (Yearly):</div>
            <div>&nbsp;</div>
          </div>
          <div
            className="kpi-input-wrapper short-wrapper"
            style={{ borderRadius: 0, paddingBottom: '0.5rem' }}
          >
            {Object.keys(UserGoal.goal_blocks)
              .filter((k) =>
                longTermInputs.includes(UserGoal.goal_blocks[k].field)
              )
              .map((fld) => renderRevenueInput(fld, isDisabled))}
          </div>
          <div className="kpi-baseline-wrapper">
            <div>Short Term (Weekly):</div>
            <div>&nbsp;</div>
          </div>
          <div className="kpi-input-wrapper">
            {shortTermInputs.map((fld) => renderAdhocInput(fld, isDisabled))}
          </div>
        </div>
      </>
    )
  }

  fetchToolTips()

  if (userGoalId > 0) {
    UserGoal = (UserGoals || [])
      .filter((UG) => parseInt(UG.id()) === parseInt(userGoalId))
      .shift()
    UserGoal.calculate()
  }

  return (
    <>
      <MDBContainer fluid id="KpiGoalManager">
        {renderHeaderRow()}

        {!userGoalId ? (
          <MDBRow>
            <MDBCol size="12">
              <div
                className={
                  'goals-complete-alert ' + (UserGoal.isComplete ? '' : 'show')
                }
              >
                Please complete <strong>ALL</strong> fields in yellow to
                complete your goals profile.
              </div>
            </MDBCol>
          </MDBRow>
        ) : (
          <MDBRow>
            <MDBCol size="12">
              <div className={'goals-past-alert'}>
                Currently viewing{' '}
                {new Date(UserGoal.get('created_at')).getFullYear()} Agent
                Goals. <br />
                <MDBBtn onClick={() => setUserGoalId(null)}>
                  Click here to go back to {new Date().getFullYear()} Agent
                  Goals
                </MDBBtn>
              </div>
            </MDBCol>
          </MDBRow>
        )}

        <MDBRow>
          <MDBCol size="12" lg="4">
            {renderPastPerformance()}
          </MDBCol>
          <MDBCol size="12" lg="4">
            {renderCalcMetrics()}
          </MDBCol>
          <MDBCol size="12" lg="4">
            {renderActionPlan()}
          </MDBCol>
        </MDBRow>

        <p>&nbsp;</p>

        {!userGoalId ? (
          <MDBRow>
            <MDBCol size="12">
              <MDBBtn
                block
                disabled={
                  !UserGoal.hasChanged ||
                  !!Object.keys(errorMessage).filter((e) => !!errorMessage[e])
                    .length
                }
                onClick={onSave}
              >
                <MDBIcon icon="save" />
                &nbsp;Save Key Performance Indicator (KPI) Goals
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        ) : (
          <></>
        )}

        <ContentModal
          isOpen={!!explainerSlug}
          contentSlug={explainerSlug}
          onClose={() => setExplainerSlug(null)}
        />
      </MDBContainer>
    </>
  )
}

export default observer(KpiGoalManager)
