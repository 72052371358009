import React from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBBtn } from 'mdbreact'
import { toast } from 'react-toastify'
import UserProfileService from './../../shared/services/UserProfile.service'
import UserAlertService from './../../shared/services/UserAlert.service'

import './AccountAlerts.scss'
import UserAlertsStore from '../../components/users/UserAlertsIndicator/UserAlerts.store'

const ALERT_KEYS = {
  'contract.requires-agent-sig': {
    icon: 'exclamation-circle',
    classes: ['alert-danger'],
    show_actions: false,
    auto_acknowledge: (UserAlert) => {
      if (
        UserAlert &&
        !UserAlert?.ackowledged_at &&
        UserProfileService.get('has_onboarded')
      )
        return true
      return false
    },
  },
  'contract.unfinished-cart-packet': {
    icon: 'exclamation-circle',
    classes: ['alert-danger'],
    show_actions: true,
    auto_acknowledge: (UserAlert) => {
      if (
        UserAlert &&
        !UserAlert?.ackowledged_at &&
        UserProfileService.get('has_onboarded')
      )
        return true
      return false
    },
  },
  'contract.requires-writing-code': {
    icon: 'exclamation-circle',
    classes: ['alert-danger'],
    show_actions: true,
    auto_acknowledge: (UserAlert) => {
      if (
        UserAlert &&
        !UserAlert?.ackowledged_at &&
        UserProfileService.get('has_onboarded')
      )
        return true
      return false
    },
  },
  'user.training-stale': {
    icon: 'exclamation-circle',
    classes: ['alert-danger'],
    show_actions: true,
    auto_acknowledge: (UserAlert) => {
      if (
        UserAlert &&
        !UserAlert?.ackowledged_at &&
        UserProfileService.get('has_onboarded')
      )
        return true
      return false
    },
  },
  'user.usertype-upgrade': {
    icon: false,
    classes: ['alert-warning'],
    show_actions: false,
    auto_acknowledge: true,
  },
  'user.usertype-downgrade': {
    icon: false,
    classes: ['alert-warning'],
    show_actions: false,
    auto_acknowledge: true,
  },
  'user.missing-photo': {
    icon: false,
    classes: ['alert-warning'],
    show_actions: true,
    auto_acknowledge: true,
  },
  'billing.failed-payment.sig-agent': {
    icon: 'exclamation-triangle',
    classes: ['alert-danger'],
    show_actions: true,
    auto_acknowledge: true,
  },
  'billing.failed-payment.10x-crm': {
    icon: 'exclamation-triangle',
    classes: ['alert-danger'],
    show_actions: true,
    auto_acknowledge: true,
  },
  'billing.subscription-canceled.sig-agent': {
    icon: 'exclamation-triangle',
    classes: ['alert-danger'],
    show_actions: true,
    auto_acknowledge: true,
  },
  'billing.subscription-canceled.10x-crm': {
    icon: 'exclamation-triangle',
    classes: ['alert-danger'],
    show_actions: true,
    auto_acknowledge: true,
  },
  'billing.subscription-canceled.admin-email': {
    icon: 'exclamation-triangle',
    classes: ['alert-danger'],
    show_actions: true,
    auto_acknowledge: true,
  },
  'billing.subscription-canceled.dyl-voip': {
    icon: 'exclamation-triangle',
    classes: ['alert-danger'],
    show_actions: true,
    auto_acknowledge: true,
  },
}

const getDefinition = (alert) => {
  try {
    return ALERT_KEYS[alert.alert_key]
  } catch (ex) {
    return false
  }
}

class AccountAlert extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabName: 'active',
      isLoading: true,
      alerts: [],
    }
  }

  componentDidMount() {
    this.fetchAccountAlertsData()
  }

  fetchAccountAlertsData = async () => {
    if (!this.isLoading) this.setState({ isLoading: true })
    this.setState(
      {
        alerts: await UserProfileService.fetchAlerts(),
        isLoading: false,
      },
      () => {
        if (Array.isArray(this.state.alerts)) {
          UserAlertsStore.setNumberOfAlerts(this.state.alerts.length)
          this.state.alerts.forEach(async (alert) => {
            let auto_acknowledgeable = false
            if (
              typeof ALERT_KEYS[alert.alert_key].auto_acknowledge === 'function'
            ) {
              auto_acknowledgeable =
                ALERT_KEYS[alert.alert_key].auto_acknowledge(alert)
            } else {
              auto_acknowledgeable =
                ALERT_KEYS[alert.alert_key].auto_acknowledge
            }
            if (auto_acknowledgeable) {
              await UserAlertService.acknowledged(alert.id)
              if (UserAlertsStore.numberOfAlerts > 0)
                UserAlertsStore.setNumberOfAlerts(
                  UserAlertsStore.numberOfAlerts - 1
                )
            }
          })
          let alertIds = this.state.alerts
            .filter((alert) => !alert.seen_at)
            .map((alert) => alert.id)
          if (alertIds.length > 0 && !UserProfileService.isAssumed())
            alertIds.forEach((alertId) => UserAlertService.seen(alertId))
        }
      }
    )
  }

  isActive = (tabName) => this.state.tabName === tabName

  change = (tabName) => {
    if (!this.isActive(tabName)) this.setState({ tabName })
  }

  renderActions = (alert) => {
    switch (alert && alert?.alert_key) {
      case 'user.missing-photo':
        return <MDBBtn href="/account-profile">Upload Now</MDBBtn>
      case 'billing.failed-payment.sig-agent':
        return <MDBBtn href="/account-billinginfo">Update Billing</MDBBtn>
      case 'billing.subscription-canceled.10x-crm':
      case 'billing.subscription-canceled.admin-email':
      case 'billing.subscription-canceled.dyl-voip':
        return <MDBBtn href="https://usabg.atlassian.net/servicedesk/customer/portal/2/group/8/create/9" tag="a" target="_BLANK">Submit Broker Support Ticket</MDBBtn>
      case 'billing.subscription-canceled.sig-agent':
        return (
          <MDBBtn
            onClick={() =>
              alert?.id
                ? UserAlertService.acknowledged(alert.id).then((res) => {
                    if (res && res?.id)
                      this.setState((prevState) => ({
                        ...prevState,
                        alerts: prevState.alerts.map((alert) => {
                          if (parseInt(alert.id) === parseInt(res.id))
                            alert.ackowledged_at = res.ackowledged_at
                          return alert
                        }),
                      }))
                  })
                : null
            }
          >
            Acknowledge
          </MDBBtn>
        )
      case 'billing.failed-payment.10x-crm':
        return (
          <MDBBtn
            tag="a"
            href="https://10x.bmagencybuilders.com/"
            target="_BLANK"
          >
            Update 10x Billing
          </MDBBtn>
        )
      case 'contract.unfinished-cart-packet':
        return (
          <MDBBtn tag="a" href="/welcome">
            Complete Onboard Contracting
          </MDBBtn>
        )
      case 'user.training-stale':
        return (
          <MDBBtn tag="a" href="/trainings">
            Complete Trainings
          </MDBBtn>
        )
      case 'contract.requires-writing-code':
        return (
          <MDBBtn tag="a" href="/agent-contracting">
            Input Writing Number
          </MDBBtn>
        )
      default:
        return <></>
    }
  }

  renderAlertRow = (alert) => {
    let definition = getDefinition(alert) || {}

    const getKeyGroup = (alertKey) =>
      alertKey
        ? alertKey.split('.')[0].charAt(0).toUpperCase() +
          alertKey.split('.')[0].substr(1)
        : ''

    return (
      <li
        key={'alert-id-' + alert.id}
        className={(Array.isArray(definition?.classes)
          ? definition.classes
          : []
        )
          .concat([
            !!definition?.show_actions && !alert.ackowledged_at
              ? 'show-actions'
              : null,
          ])
          .filter((c) => !!c)
          .join(' ')}
      >
        <div className="alert-key">
          {definition?.icon ? (
            <MDBIcon icon={definition.icon}></MDBIcon>
          ) : (
            <></>
          )}
          {getKeyGroup(alert.alert_key)}
        </div>
        <div className="alert-message">{alert.alert_message}</div>
        <div className="alert-action">{this.renderActions(alert)}</div>
      </li>
    )
  }

  renderTab = (tabName) => {
    let alerts = this.state.alerts.filter(
      (alert) =>
        (tabName === 'active' && !alert.ackowledged_at) ||
        (tabName === 'history' && alert.ackowledged_at)
    )

    return (
      <div
        className={
          'tab-pane fade ' + (this.isActive(tabName) ? 'show active' : '')
        }
        role="tabpanel"
      >
        {alerts.length ? (
          <ul>{alerts.map(this.renderAlertRow)}</ul>
        ) : (
          <span>Your alerts are all caught up!</span>
        )}
      </div>
    )
  }

  render() {
    toast.configure()

    return (
      <React.Fragment>
        <DashboardLayout>
          <main id="AccountAlertsPage" className="mainSection mt-5 pb-5">
            <MDBContainer fluid>
              <MDBRow>
                <MDBCol size="12">
                  <h2>Account Alerts</h2>
                  <hr />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="12">
                  <section className="mx-2 p-4">
                    <ul
                      className="nav nav-tabs md-tabs"
                      id="myTabMD"
                      role="tablist"
                    >
                      <li className="nav-item waves-effect waves-light">
                        <a
                          className={
                            'nav-link ' +
                            (this.isActive('active') ? 'active' : '')
                          }
                          id="current-tab-md"
                          data-toggle="tab"
                          href="#current-md"
                          role="tab"
                          aria-controls="home-md"
                          aria-selected={this.isActive('active')}
                          onClick={() => this.change('active')}
                        >
                          Current
                        </a>
                      </li>
                      <li className="nav-item waves-effect waves-light">
                        <a
                          className={
                            'nav-link ' +
                            (this.isActive('history') ? 'active' : '')
                          }
                          id="history-tab-md"
                          data-toggle="tab"
                          href="#history-md"
                          role="tab"
                          aria-controls="profile-md"
                          aria-selected={this.isActive('history')}
                          onClick={() => this.change('history')}
                        >
                          Past
                        </a>
                      </li>
                    </ul>
                    <div
                      className="tab-content card pt-5"
                      id="mymdbTabContentMD"
                    >
                      {this.renderTab('active')}
                      {this.renderTab('history')}
                    </div>
                  </section>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export default AccountAlert
