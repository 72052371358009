const determineOutput = (output) => {
  output = output ? `${output}`.trim().toLowerCase() : null
  if (output && ['json', 'object', 'array', 'boolean'].indexOf(output) < 0)
    output = null
  return output
}

const isString = (str) => typeof str === 'string'

const isObject = (str) => typeof str === 'object'

const isArray = (str) => Array.isArray(str)

const isUndefined = (str) => typeof str === 'undefined'

const isJson = (str) => {
  try {
    return typeof JSON.parse(str) === 'object'
  } catch (ex) {
    return false
  }
}

const metaValueToArray = (metaValue) => {
  metaValue = metaValueToObject(metaValue)
  if (metaValue && isObject(metaValue))
    return isArray(metaValue) ? metaValue : [metaValue]

  return []
}

const metaValueToObject = (metaValue) => {
  if (typeof metaValue === 'object') return metaValue

  if (isString(metaValue) && isJson(metaValue)) return JSON.parse(metaValue)

  return {}
}

const metaValueToJsonString = (metaValue) => {
  if (isString(metaValue))
    return isJson(metaValue) ? metaValue : JSON.stringify(metaValue)

  return JSON.stringify(isUndefined(metaValue) ? {} : metaValue)
}

const metaValueToString = (metaValue) =>
  `${typeof metaValue === 'undefined' || metaValue === null ? '' : metaValue}`

const metaValueToBoolean = (metaValue) =>
  !!(['1', 'true'].indexOf(metaValueToString(metaValue).toLowerCase()) > -1)

export const getMetaValue = (Meta, output) => {
  let metaValue = Meta?.get('meta_value')

  switch ((output = determineOutput(output))) {
    case 'array':
      return metaValueToArray(metaValue)

    case 'object':
      return metaValueToObject(metaValue)

    case 'json':
      return metaValueToJsonString(metaValue)

    case 'string':
      return metaValueToString(metaValue)

    case 'boolean':
      return metaValueToBoolean(metaValue)

    default:
      return metaValue
  }
}
