import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { MDBBtn, MDBIcon, MDBAlert } from 'mdbreact'
import UserProfileService from './../../../shared/services/UserProfile.service'
import MediaUploaderModal from './../../modals/MediaUploaderModal'
import AgentNiprPdbReportList from './../AgentNiprPdbReportList/AgentNiprPdbReportList.component'
import NiprPdbReportStore from './../AgentNiprPdbReportList/AgentNiprPdbReportList.store'

import './AgentNiprPdbReportButton.scss'

const AgentNiprPdbReportButton = () => {
  const openModal = () => {
    MediaUploaderModal.open({
      uploadType: 'nipr_pdb_report',
      modalContentTop: (
        <>
          <div className="text--center fw--500">
            <h6 className="fw--500">
              Upload your most recent NIPR PBD Report.
            </h6>
          </div>
        </>
      ),
      modalContentBottom: (
        <>
          <div className="text--center fw--500 mt-3">
            <h6 className="fw--500">Past NIPR PBD Reports.</h6>
          </div>
          <AgentNiprPdbReportList
            userId={UserProfileService.getUserId()}
            updateParams={(params) => {}}
            isAdmin={UserProfileService.isA(
              ['system-admin', 'internal-admin', 'internal-staff'],
              true
            )}
          />
        </>
      ),
      validationRules: { contentType: ['application/pdf'] },
      preventAutoClose: true,
      onSuccess: () => {
        NiprPdbReportStore.fetch(UserProfileService.getUserId())
      },
    })
      .then(async (filePath) => {})
      .catch((err) => {
        if (err)
          console.log(
            'Failed to complete uploading agent nipr pdb report.',
            err
          )
      })
  }

  const button = () => {
    return (
      <MDBBtn
        block
        color="info"
        className="AgentNiprPdbReportButtonComponent"
        onClick={() => {
          openModal()
        }}
      >
        <MDBIcon icon="file-medical" />
        &nbsp;
        <span>Upload NIPR PDB Reports</span>
      </MDBBtn>
    )
  }

  return button()
}

export default observer(AgentNiprPdbReportButton)
