import './FirebaseAuth';
import ApiClient from "./client.functions";

let srvcEndpoint 					= 		null;

const getOptions = (opt, opts) => 
{
	if (opts && typeof opts === 'object') {
		if (opts.hasOwnProperty(opt) && opts[opt])
			return 										opts[opt];
	}
}

const getEndpoint = (id, opts) => 
{
	let endpoint 					= 		getOptions('endpoint', opts);
	if (endpoint)
		return 										srvcEndpoint + endpoint.replace(new RegExp(srvcEndpoint.replace(/^\//,'^\\/')), '');

	endpoint 							=			srvcEndpoint;
	return 											id ? endpoint+(endpoint.charAt(endpoint.length-1) === "/" ? "" : "/")+id : endpoint;
}

const getMethod = (method, opts) =>
{
	let m;
	if ((m = getOptions('method', opts)))
		return  									`${m}`.trim().toLowerCase();

	return 											`${method}`.trim().toLowerCase();
}

const request = async (endpoint, method, id, req, opts) =>
{
	srvcEndpoint 						=			endpoint;
	if (id && typeof id === 'object') {
		opts 									=			req;
		req 									= 		id;
	}
	id 											=			((!isNaN(id) && parseInt(id) > 0) || (typeof id === 'string' && `${id}`.trim().length > 0)) ? id : false;
	method 									=			getMethod(method, opts);

	if (method && ['get','post','put','delete'].indexOf(`${method}`.trim().toLowerCase()) > -1) {
		endpoint 							= 		getEndpoint(id, Object.assign((opts && typeof opts === 'object' ? opts : {}), (req && typeof req === 'object' ? req : {})));

		let request 					=			() => ApiClient.request(method, endpoint, req),
				retried 					=			false,
				requestHandler 		=			(resolve, reject) => 
																{
																	request()
																		.then(
																				res => resolve(res), 
																				err => {

																					// if we've already retried the request, 
																					// or the error is not 403 - stop here & reject request.
																					if (retried || !ApiClient.isForbidden(err))
																						return reject(err);

																					// attempt to refresh the token & 
																					// re-attempt the request.
																					retried = true;
																					ApiClient
																						.setRefreshToken()
																						.then(() => requestHandler(resolve, reject),() => {throw new Error(err)})
																				});
																}

		return new Promise(requestHandler);
	}

	throw new Error("Invalid request method supplied.");
}

const responseHandler = response =>
{
	let statusCode = response && parseInt(response?.status) > 0 ? parseInt(response.status) : 500;

	if (statusCode === 204)
		return  										true;

	if (statusCode >= 200 && statusCode < 300) {
		if (response.data.pagination === false || (response.data.pagination && typeof response.data.pagination === 'object'))
			return  									{models: response.data.payload, pagination: response.data.pagination};
		return 											response.data.payload;
	}

	throw new Error(response?.message || response?.error || "We're sorry.  An unknown error occured at the server.");
}

const RequestHandler = {
	setServiceEndpoint: 	e => srvcEndpoint = e,
	request: 							async (method, endpoint, id, req, opts) => 
												{
													try { return responseHandler(await request(endpoint, method, id, req, opts)) }	
													catch (ex) { return responseHandler(ex); }
												}
}

export default RequestHandler;
