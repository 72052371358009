import React from 'react';
import DashboardLayout from './../../components/Admin/DashboardLayout';
import {MDBCol, MDBContainer, MDBRow, MDBSelect} from "mdbreact";
import AdminApi from './../../api/admin-api/admin-api';
import MessageViewer from "./../../components/MessageViewer/MessageViewer.component";
import MessageEditor from "./../../components/MessageEditor/MessageEditor.component";
import MessageService from "./../../shared/services/Message.service";
import UserProfileService from "./../../shared/services/UserProfile.service";

import './DivisionMessages.scss';

class DivisionMessagesPage extends React.Component 
{
	state = {
		divisionList: [],
		activeDivisionId: UserProfileService.get('u_devision')
	}

	get canSelectDivision ()
	{
		return 											UserProfileService.isA("internal-staff") || UserProfileService.isA("internal-admin") || UserProfileService.isA("system-admin");
	}

	#getDivisions = async () =>
	{
		let divisionList 			= 		[];
		try 				{ divisionList = await AdminApi.getDivisions() 	}
		catch (ex) 	{ divisionList = []; 	console.error(ex)													}

		divisionList 					=			divisionList && divisionList.data && divisionList.data.data;
		divisionList 					=			Array.isArray(divisionList) ? divisionList : [];
		divisionList 					=			divisionList.map(division => ({text: `${division.d_name}`, value: `${division.id}`}));

		this.setState({divisionList});
	}

	#setActiveDivision = divisionId =>
	{
		if (!isNaN(parseInt(divisionId)) && parseInt(divisionId) !== parseInt(`${this.state.activeDivisionId}`))
			this.setState({activeDivisionId: divisionId},() => MessageService.setDivisionOverride(this.state.activeDivisionId));
	}

	#renderDivisionDropdown = () =>
	{
		if (!this.canSelectDivision)
			return <></>

		if (!this.state.divisionList.length)
			return <span>Loading Divisions ... </span>

		return 	<MDBSelect search
											className="divisions-selector" 
			                options={this.state.divisionList.map(d => ({...d, checked: d.value === `${this.state.activeDivisionId}`}))}
			                getValue={value => this.#setActiveDivision(value.shift())}
			                label="Divisions" />
	}

	componentDidMount () 
	{
    if (this.canSelectDivision)
    	this.#getDivisions()
	}

  render () 
  {
    return (
      <React.Fragment>
        <DashboardLayout>
          <main id="DivisionMessagesPage" className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
            	<MDBRow>
            		<MDBCol size="12" md="8">
            			<h2>Division Messages</h2>
            		</MDBCol>
            		<MDBCol size="12" md="4">
									{this.#renderDivisionDropdown()}
            		</MDBCol>
            	</MDBRow>
              <hr/>
            </MDBContainer>
						<MessageViewer division_id={this.state.activeDivisionId} />
						<MessageEditor division_id={this.state.activeDivisionId} />
          </main>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export default DivisionMessagesPage
