import BaseModelInstance from './base.model'
import { UserEventLog as ModelDefinition } from './model-definitions'

class UserEventLog extends BaseModelInstance {
  afterSet = (key, value) => {}

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }
}

export default UserEventLog
