import React, { useState } from 'react'
import ContentHtml from './../../content/ContentHtml/ContentHtml.component'
import env from '../../../environments/environment'
import {
  MDBModalHeader,
  MDBModalBody,
  MDBModal,
  MDBRow,
  MDBCol,
} from 'mdbreact'
import { create } from 'react-modal-promise'

import './Modal.component.scss'
import UserPerformanceEntryService from '../../../shared/services/UserPerformanceEntry.service'
import UserProfileService from '../../../shared/services/UserProfile.service'
import StorageService from '../../../shared/services/Storage.service'
import moment from 'moment'
import { toast } from 'react-toastify'

// const formBackgroundImage = `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fbg.budding.jpg?alt=media`
const MODAL_ID = 'modals.marketing-commitment',
  formBackgroundImage = `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fbg.bank.jpg?alt=media`

const isJson = (str) => {
  try {
    return !!JSON.parse(str)
  } catch (ex) {
    return false
  }
}

const WeeklyMarketingCommitmentComponent = ({ onSubmit }) => {
  const [invested, setInvested] = useState()
  const storeCommitment = (e) => {
    e.preventDefault()
    if (!invested) {
      toast.error('Please enter weekly marketing commitment!')
      return
    }
    onSubmit(invested)
  }
  return (
    <MDBRow>
      <MDBCol md="6" className="d-sm-none d-md-block text-white">
        <h1 className="fw--700 font--lato">
          Weekly <wbr />
          Marketing <wbr />
          Commitment
        </h1>
      </MDBCol>
      <MDBCol md="6" sm="12">
        <div className="main-content">
          <ContentHtml contentSlug={'weekly-marketing-commitment'} />
          <form
            data-form-output="form-output-global"
            data-form-type="contact"
            onSubmit={storeCommitment}
            noValidate
            className="range range-xs-center range-sm-left inset-lg-right-50 offset-top-20 rd-mailform"
          >
            <div className="range offset-top-40">
              <div className="cell-sm-6 offset-top-20 offset-sm-top-30">
                <div className="form-group form-group-outside">
                  <input
                    id="invested"
                    type="number"
                    name="invested"
                    className="form-control bg-whisper-lighten border-radius-0 mb-2"
                    placeholder={"This Week's Marketing Commitment"}
                    value={invested}
                    onChange={(e) => setInvested(e.target.value)}
                  />
                </div>
              </div>
              <div className="cell-sm-6 offset-top-20 offset-sm-top-30">
                <button
                  type="submit"
                  className="btn btn-icon btn-icon-right btn-primary-contrast btn-custom btn-block"
                >
                  <span>Submit</span>&nbsp;
                  <span className="fa fa-angle-right"></span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </MDBCol>
    </MDBRow>
  )
}

const disregardModal = () => {
  StorageService.set('modals.marketing-investment', new Date().getTime())
  UserProfileService.track({
    event_type: 'ui.modals.marketing-investment',
    event_descrip: 'User skipped entering marketing investment.',
  })
}

const shouldDisregardModal = () => {
  const disregarded = StorageService.get('modals.marketing-investment')

  if (disregarded && !isNaN(disregarded))
    return (
      parseInt(moment(new Date(parseInt(disregarded))).format('w')) ===
      parseInt(moment().startOf('isoWeek').format('w'))
    )

  return false
}

const Modal = ({ isOpen, onResolve, onReject }) => {
  const cancelModal = () => {
    disregardModal()
    onReject()
  }

  return (
    <div id="WeeklyMarketingCommitmentModalComponent">
      <MDBModal
        size="xl"
        isOpen={isOpen}
        toggle={cancelModal}
        keyboard={false}
        disableBackdrop
      >
        <MDBModalHeader toggle={cancelModal}>
          Weekly Marketing Commitment
        </MDBModalHeader>
        <MDBModalBody
          style={{
            backgroundImage: `url(${formBackgroundImage})`,
          }}
        >
          <WeeklyMarketingCommitmentComponent onSubmit={onResolve} />
        </MDBModalBody>
      </MDBModal>
    </div>
  )
}

class WeeklyMarketingCommitmentModal {
  open(props) {
    props = props && typeof props === 'object' ? props : {}
    return new Promise((resolve, reject) => {
      create(Modal)({ ...props, isOpen: true }).then(resolve, reject)
    })
  }
}

const weeklyMC = new WeeklyMarketingCommitmentModal()

class WeeklyMarketingCommitment {
  allowedUserTypes = [90, 38, 93, 129, 130, 92, 35]

  getThisMonday = () => moment().startOf('isoWeek').format('YYYY-MM-DD')

  getStored = () => {
    let stored = StorageService.get(MODAL_ID)
    return stored && isJson(stored) ? JSON.parse(stored) : false
  }

  setStored = () => {
    StorageService.set(MODAL_ID, new Date().getTime())
  }

  getEntry = async () =>
    (
      (
        await UserPerformanceEntryService.search({
          search: {
            user_id: UserProfileService.getUserId(),
            entry_date: this.getThisMonday(),
          },
        })
      )?.models || []
    ).shift()

  hasBeenSuppressed = () => {
    const prev = this.getStored() ? new Date(this.getStored()) : false
    if (prev && typeof prev === 'object')
      return prev > moment().startOf('isoWeek').toDate().getTime()
    return false
  }

  getUserTypeId = () => UserProfileService.get('usertype_id')

  checkIsAllowedUserType = (usertypeId = null) => {
    if (usertypeId) {
      return this.allowedUserTypes.includes(usertypeId)
    } else {
      usertypeId = this.getUserTypeId()
      return this.allowedUserTypes.includes(usertypeId)
    }
  }

  onSaveCommitment = async (invested) => {
    const Entry = (
      (
        await UserPerformanceEntryService.search({
          search: {
            user_id: UserProfileService.getUserId(),
            entry_date: this.getThisMonday(),
          },
        })
      )?.models || []
    ).shift()
    if (Entry) {
      return UserPerformanceEntryService.update(Entry.id, {
        invested: Number(invested),
      })
    } else {
      return UserPerformanceEntryService.store({
        user_id: UserProfileService.getUserId(),
        entry_date: this.getThisMonday(),
        invested: Number(invested),
      })
    }
  }

  check = async () => {
    const usertypeId = this.getUserTypeId()
    if (!usertypeId) return
    if (!this.checkIsAllowedUserType(usertypeId)) return
    if (this.hasBeenSuppressed()) return
    const Entry = await this.getEntry()
    if (Entry) return
    weeklyMC
      .open()
      .then((invested) => this.onSaveCommitment(invested))
      .catch((err) => false)
      .finally(() => this.setStored())
  }
}

export default new WeeklyMarketingCommitment()
