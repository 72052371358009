import moment from 'moment';


const getFormat = d =>
{
	if (typeof d !== 'string')
		return 											false;

	if ((new RegExp(/^\d{4}-\d{2}-\d{2}/)).test(`${d}`))
		return 											'YYYY-MM-DD';
}

/*
* Extracts DATE ONLY, without regard for
* timezone.  Useful for date fields like:
* Date of submission, date of birth, etc.
*
*/
const dateOnly = d =>
{
	if (typeof d === 'string') {
		switch (getFormat(d))
		{
			case "YYYY-MM-DD":
				return 									moment(d, "YYYY-MM-DD").toDate();
			default:
				return 									false;
		}
	}

	return 												false;
}

const dateOnlyToString = (d, format = "YYYY-MM-DD") =>
{
	if (typeof d === 'object' && d instanceof Date)
		return 											moment(d).format(format);

	if (typeof d === 'string')
		return 											moment(d).format(format);
}


const format = (d, format = "MM/DD/YYYY") =>
{
	return 												moment(d).format(format)
}

const DateService = {

	// Returns date string from supplied JS date object.
	format: 											format,


	// Returns JS Date Object, without time data/timezone offset.
	// Useful for activities by date. ie: date of birth.
	dateOnly: 	 									dateOnly,

	// Returns date-only string, w/ default format 'YYYY-MM-DD'
	dateOnlyToString: 						dateOnlyToString,

	// Returns UTC timestamp in seconds.
	getUTCTime:  									() => moment.utc().format("X")
};

export default DateService;