import React from 'react';
import {MDBModal, MDBAlert, MDBModalHeader, MDBModalBody, MDBIcon} from "mdbreact";
import AdobeSignApi from './../../../../shared/services/AdobeSignApi';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import './AdobeDocListModal.scss';

class AdobeDocListModal extends React.Component
{
	#timer = null;

	constructor (props)
	{
		super(props);
		this.state = {
			copied: null,
			docs: [],
			loading: true
		}
	}

	componentDidMount () 
	{
		this.#fetch();
	}

	componentWillUnmount ()
	{
		if (this.#timer)
			window.clearTimeout(this.#timer);
	}

	#copied = docId => 
	{
		this.setState({copied: docId})
		if (this.#timer)
			window.clearTimeout(this.#timer);
		this.#timer = setTimeout(() => this.setState({copied: null}), 3500);
	}

	#close = () =>
	{
		if (typeof this.props.onClose === 'function')
			this.props.onClose();
		else this.setState({show_modal: false});
	}

	#fetch = async () =>
	{
		if (!this.state.loading)
			this.setState({loading: true});

		AdobeSignApi
				.getDocuments()
				.then(docs => this.setState({docs: (docs && docs?.models) || [], loading: false}))
				.catch(err => this.setState({docs: `An error occurred attempting to get the document list from Adobe. ${err}`, loading: false}));
	}

	#renderDocumentList = () =>
	{
		if (this.state.loading)
			return <>Fetching Adobe Templates...</>

		let docs = (Array.isArray(this.state.docs) ? this.state.docs : []);

		return 		<ul className='adobe-doc-list-table'>
							{
								docs.map(doc => (
											<CopyToClipboard key={'doc-'+doc.id} text={`${doc.id}`} onCopy={() => this.#copied(`${doc.id}`)}>
												<li>
													<div>{doc.name}</div>
													<small>{doc.id}</small>
	                        <MDBIcon icon="copy" className="blue-text p-1" />
	                        {`${this.state.copied}` === `${doc.id}` ? <span className='alert-copied'>Copied!</span> : <></>}
												</li>
											</CopyToClipboard>
									))
							}
							</ul>
	}

	render ()
	{
		const isOpen = this.state.hasOwnProperty('show_modal') ? this.state.show_modal : this.props.isOpen;

		return (
			<MDBModal id="AdobeDocListModalComponent" isOpen={isOpen} toggle={this.#close} size="lg">
				<MDBModalHeader  toggle={this.#close}>
					Adobe Document Templates
				</MDBModalHeader>
				<MDBModalBody>
					{
						this.state.docs && typeof this.state.docs === 'string'
							? <MDBAlert color="danger">{this.state.docs}</MDBAlert>
							: this.#renderDocumentList()
					}
				</MDBModalBody>
			</MDBModal>
		)
	}
}

export default AdobeDocListModal;
