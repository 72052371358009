import React from 'react'
import Star from './../../../assets/images/svg/gold-star-vector.svg'
import Ribbon from './../../../assets/images/svg/red-gold-ribbon-vector.svg'
import { getHeadshot, autoLogin, getAgentAccessToHeadshot } from './../../../shared/utilities/getHeadshot.function'
import SystemSettingStore from './../../../shared/stores/systemSetting.store'
import './profileImage.scss'
import { useBreakTheBarSetting } from '../../../components/shared/useBreakTheBarSetting'

function determineAgentScope(agentScope) {
  if (['sig_agent', 'csr_agent', 'ba_team', 'division', 'region', 'district'].includes(agentScope))
    return agentScope
  return
}

function meetsUsertypeRequirement(usertypeId) {
  const restrictedUsertypes = [111, 133, 223, 224]
  return restrictedUsertypes.indexOf(parseInt(usertypeId)) < 0
}

function renderBestWeekRibbon(bestWeek) {
  if (bestWeek)
    return (
      <div className="bonus-indicator">
        <img src={Ribbon} alt="" />
        <div>Personal Best!</div>
      </div>
    )
}

function renderBarBreaker(firstToBreakBar) {
  if (firstToBreakBar)
    return (
      <div className="bonus-indicator">
        <img src={Star} alt="" />
        <div>1st Bar Breaker!</div>
      </div>
    )
}

function renderHeadshot(agent) {
  const { agentPic, agentName, agentId } = agent
  return (
    <div 
      className={agentId && getAgentAccessToHeadshot(agentId) ? "profile-img-wrapper cursor-pointer" : "profile-img-wrapper"}
      onClick={() => {
        if(agentId && getAgentAccessToHeadshot(agentId)) autoLogin(agentId)
      }}
    >
      <img
        src={agentPic}
        alt={agentName}
        className="mx-auto d-block leaderboard-profile-img"
      />
    </div>
  )
}

function renderHeadshotWithName(agent) {
  const { agentPic, agentName, agentId } = agent
  return (
    <div className="profile-container">
      <div className="img-wrapper">
        <div 
          className={agentId && getAgentAccessToHeadshot(agentId) ? "profile-img-wrapper cursor-pointer" : "profile-img-wrapper"}
          onClick={() => {
            if(agentId && getAgentAccessToHeadshot(agentId)) autoLogin(agentId)
          }}
        >
          <img
            src={agentPic}
            alt={agentName}
            className="mx-auto d-block leaderboard-profile-img"
          />
        </div>
      </div>
      <div className="identity-wrapper">
        <p className="text-center">{agentName}</p>
      </div>
    </div>
  )
}

export const ProfileImage = ({
  usertypeId,
  agentName,
  agentPic,
  mustBreakBar,
  totalPoints,
  firstToBreakBar,
  bestWeek,
  agentId,
  agentScope,
}) => {
  // get the threshold to evaluate agent performance against.
  const { threshold } = useBreakTheBarSetting(agentScope)

  // ensure agent profile meets minimum requirements.
  if (!meetsUsertypeRequirement(usertypeId))
    return <p className="text-center">{agentName}</p>

  // if this headshot isn't being controlled by points,
  // we can activated it now..
  if (!mustBreakBar)
    return renderHeadshotWithName({
      agentPic: getHeadshot({ u_picture: agentPic, id: agentId }),
      agentName,
      agentId
    })

  // ensure agent scope is available to be used for
  // determining the applicable BTB points thresholds.
  agentScope = determineAgentScope(agentScope)
  if (!agentScope) return <p className="text-center">{agentName}</p>

  let img

  if (
    totalPoints &&
    totalPoints >=
      (threshold ||
        SystemSettingStore.get('usertypes---break-the-bar-thresholds')[
          agentScope
        ]?.current)
  )
    img = renderHeadshot({
      agentPic: getHeadshot({ u_picture: agentPic, id: agentId }),
      agentName,
      agentId
    })


  return (
    <div className="profile-container">
      <div className="img-wrapper">
        <div>{img}</div>
      </div>
      <div className="identity-wrapper">
        {renderBestWeekRibbon(bestWeek)}
        <p className="text-center">{agentName}</p>
        {renderBarBreaker(firstToBreakBar)}
      </div>
    </div>
  )
}