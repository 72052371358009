import React from 'react'
// import AssociateAgentImage from './../../assets/images/svg/AssociateAgent.svg'
// import AffilateAgentImage from './../../assets/images/svg/AffilateAgent.svg'
import AWARD_LEVELS from './../../constants/award-levels.constants'
import env from './../../environments/environment'

export default class PipAwardLevel {
  static getImageURL(level) {
    return `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Faward-levels%2Flevel.${level}.png?alt=media`
  }

  static getZeroLevelImage(userTypeID) {
    const type = parseInt(userTypeID) === 225 ? 'ba' : 'sig'
    return `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Faward-levels%2Flevel.0.${type}.png?alt=media`
  }

  static associateAgentImage = `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Faward-levels%2Flevel.0.associate.svg?alt=media`
  static affilateAgentImage = `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Faward-levels%2Flevel.0.affilate.svg?alt=media`

  static getAwardLevel = (totalPoints) =>
    AWARD_LEVELS.filter(
      (AwardLevel) =>
        AwardLevel.min <= parseInt(totalPoints) &&
        parseInt(totalPoints) <= AwardLevel.max
    ).shift()?.level || (parseInt(totalPoints) >= 0 ? 0 : undefined)

  static getAwardImage(totalPoints, userTypeID) {
    const level = PipAwardLevel.getAwardLevel(totalPoints)

    if (isNaN(level) || parseInt(level) < 0) return this.getImageURL('NaN')

    if (parseInt(level) === 0) return this.getZeroLevelImage(userTypeID)

    return this.getImageURL(level)
  }

  static getAwardBadge = (totalPoints, styleObj) => {
    styleObj =
      styleObj && typeof styleObj === 'object'
        ? { ...styleObj, objectFit: 'contain' }
        : { objectFit: 'contain' }
    styleObj = styleObj?.size
      ? {
          ...styleObj,
          height: styleObj.size,
          width: styleObj.size,
          size: undefined,
        }
      : styleObj

    return (
      <img
        className="img-fluid pip-award-badge"
        src={PipAwardLevel.getAwardImage(totalPoints, null)}
        style={styleObj}
      />
    )
  }

  static getUsertypeAwardBadge = (usertypeId, totalPoints, styleObj) => {
    styleObj =
      styleObj && typeof styleObj === 'object'
        ? { ...styleObj, objectFit: 'contain' }
        : { objectFit: 'contain' }
    styleObj = styleObj?.size
      ? {
          ...styleObj,
          height: styleObj.size,
          width: styleObj.size,
          size: undefined,
        }
      : styleObj

    if (parseInt(usertypeId) === 111 || parseInt(usertypeId) === 133)
      return (
        <img
          className="img-fluid pip-award-badge"
          src={PipAwardLevel.affilateAgentImage}
          alt="Affiliate Agent Logo"
          style={styleObj}
        />
      )

    if (parseInt(usertypeId) === 223 || parseInt(usertypeId) === 224)
      return (
        <img
          className="img-fluid pip-award-badge"
          src={PipAwardLevel.associateAgentImage}
          alt="Associate Agent Logo"
          style={styleObj}
        />
      )

    const level = PipAwardLevel.getAwardLevel(totalPoints),
      isEmpty = !isNaN(level) && parseInt(level) >= 0 ? false : true

    return (
      <img
        className={`img-fluid pip-award-badge ${isEmpty ? 'empty-img' : ''}`}
        src={PipAwardLevel.getAwardImage(totalPoints, usertypeId)}
        alt="Agent Award"
        style={styleObj}
      />
    )
  }
}
