import React from 'react'
import './Banner.scss'

const INITIAL_SLIDER_POS_TIMESTAMP = (function () {
  let timestamps = [
    '0m0s',
    '0m11s',
    '0m17s',
    '0m24s',
    '0m31s',
    '0m37s',
    '0m46s',
    '0m53s',
  ]
  return timestamps[Math.floor(Math.random() * timestamps.length)]
})()

const Banner = (props) => (
  <section id="HomeVideoBannerComponent">
    <div className="scale-container">
      <div className="video-container">
        {props?.pageTitle?<div className='banner-page-title'><span>{props?.pageTitle}</span></div>:<></>}
        <iframe
          src={`https://player.vimeo.com/video/705363420?background=1#${INITIAL_SLIDER_POS_TIMESTAMP}`}
          width="100%"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title={`video-${props?.videoTitle}`}
        ></iframe>
      </div>
    </div>
  </section>
)

export default Banner
