import { CreateUserPage } from './index'

import { RouteFactory } from './../../shared/router/Route.factory'
import {
  RequiresAuthGuard,
  RequiresUsertypesGuard,
} from './../../shared/router/guards'

export default [
  RouteFactory.create(
    CreateUserPage,
    '/create-admin-or-csr-agent',
    [
      new RequiresAuthGuard(true),
      new RequiresUsertypesGuard([
        'divisional-leader',
        'agency-owner',
        'internal-admin',
        'internal-staff',
        'system-admin',
        'division-admin',
        'regional-leader',
        'region-admin',
        'district-leader',
        'district-admin',
      ]),
    ],
    {
      name: 'Create Admin/BA/CSR Agent',
    }
  ),
  RouteFactory.create(
    CreateUserPage,
    '/admin-create-user',
    [
      new RequiresAuthGuard(true),
      new RequiresUsertypesGuard([
        'agency-owner',
        'internal-admin',
        'internal-staff',
        'system-admin',
      ]),
    ],
    {
      name: 'Create Non-Agent User',
    }
  ),
  // RouteFactory.create(
  //   CreateUserPage,
  //   '/create-division-admin',
  //   [
  //     new RequiresAuthGuard(true),
  //     new RequiresUsertypesGuard([
  //       'divisional-leader',
  //       'agency-owner',
  //       'internal-admin',
  //       'system-admin',
  //       'division-admin',
  //     ]),
  //   ],
  //   {
  //     name: 'Create Division Admin',
  //   }
  // ),
  // RouteFactory.create(
  //   CreateUserPage,
  //   '/create-region-admin',
  //   [
  //     new RequiresAuthGuard(true),
  //     new RequiresUsertypesGuard([
  //       'regional-leader',
  //       'agency-owner',
  //       'internal-admin',
  //       'system-admin',
  //       'region-admin',
  //     ]),
  //   ],
  //   {
  //     name: 'Create Region Admin',
  //   }
  // ),
  // RouteFactory.create(
  //   CreateUserPage,
  //   '/create-district-admin',
  //   [
  //     new RequiresAuthGuard(true),
  //     new RequiresUsertypesGuard([
  //       'district-leader',
  //       'agency-owner',
  //       'internal-admin',
  //       'system-admin',
  //       'district-admin',
  //     ]),
  //   ],
  //   {
  //     name: 'Create District Admin',
  //   }
  // ),
]
