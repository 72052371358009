import { makeAutoObservable, toJS } from 'mobx'
import AdminApi from './../../../api/admin-api/admin-api'
import UserProfileService from './../../../shared/services/UserProfile.service'
import KpiMetricService from './../../../shared/services/KpiMetric.service'

const ucwords = (str) =>
  str
    .replace(/^([a-z])|[\s_-]+([a-z])/g, ($1) => $1.toUpperCase())
    .replace(/-/g, ' ')

const getDivisions = async () => {
  let divisionList = []
  try {
    divisionList = await AdminApi.getDivisions()
  } catch (ex) {
    console.error('Failed to fetch divisions in KpiDashboardStore. ', ex)
  }

  return (
    Array.isArray(divisionList?.data?.data) ? divisionList.data.data : []
  ).map((division) => ({ text: `${division.d_name}`, value: `${division.id}` }))
}

const getAgents = async () => {
  let Users = []
  try {
    Users = await UserProfileService.getDownline()
  } catch (ex) {
    console.error('Failed to fetch agents in KpiDashboardStore. ', ex)
  }

  return (Array.isArray(Users) ? Users : [])
    .filter((n) => !!n)
    .map((User) => ({
      text: ucwords(`${User.u_fname} ${User.u_lname}`),
      value: `${User.id}`,
    }))
    .sort((a, b) =>
      `${a.text}`.charAt(0) > `${b.text}`.charAt(0)
        ? 1
        : `${a.text}`.charAt(0) < `${b.text}`.charAt(0)
        ? -1
        : 0
    )
}

class KpiDashboardStore {
  view
  agentId
  divisionId
  divisionsList = []
  agentsList = []

  constructor() {
    makeAutoObservable(this)
  }

  getDivisions = async () => {
    if (this.divisionsList.length) return this.divisionsList

    this.divisionsList = await getDivisions()
    return toJS(this.divisionsList)
  }

  getAgents = async () => {
    if (this.agentsList.length) return this.agentsList

    this.agentsList = await getAgents()
    return toJS(this.agentsList)
  }

  searchAgents = async () => []

  getDivision = async (divisionId) => {
    try {
      return (await KpiMetricService.getDivisionKpi(divisionId)).outcome
    } catch (ex) {
      console.error('Exception: ', ex)
      return false
    }
  }

  getAgent = async (userId) => {
    try {
      return (await KpiMetricService.getMyKpi(userId)).outcome
    } catch (ex) {
      console.error('Exception: ', ex)
      return false
    }
  }

  getKpis = async () => {
    return new Promise((resolve, reject) => {
      KpiMetricService.search({ pagination: false })
        .then((kpis) => {
          let defs = {}
          kpis.models.forEach((kpi) => (defs[kpi.metric_name] = kpi))
          resolve(defs)
        })
        .catch((er) => resolve({}))
    })
  }

  fetch = async (opts) => {
    if (opts?.division_id !== this.divisionId)
      console.log('fetch division change', opts?.division_id)
    if (opts?.agent_id !== this.agentId)
      console.log('fetch agent change', opts?.agent_id)
  }
}

export default new KpiDashboardStore()
