import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import {
  MDBBtn,
  MDBContainer,
  MDBDataTable,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from 'mdbreact'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'
import ChangeUserStatusInListComponent from '../../components/adminShared/changeUserStatusInListComponent'
import AgentTerms from '../../components/agents/AgentTerms/AgentTerms.component'

class UserReports extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      fetching: false,
      deleting: false,
      updating: false,
      exporting: false,
      confirmModal: false,
      confirmUpdateModal: false,
      userTermsModal: false,
      userTypesList: [],
      usersList: [],
      updatingId: '',
      updatingUsertype: '',
      data: {
        columns: [
          {
            label: 'Name',
            field: 'name',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Usertype',
            field: 'usertype',
            sort: 'asc',
            width: 270,
          },
          {
            label: 'Email',
            field: 'email',
            sort: 'asc',
            width: 270,
          },
          {
            label: 'NPN',
            field: 'npn',
            sort: 'asc',
            width: 270,
          },
          // {
          //     label: 'Password',
          //     field: 'password',
          //     sort: 'asc',
          //     width: 270
          // },
          {
            label: 'Blacklisted?',
            field: 'active',
            sort: 'asc',
            width: 270,
          },
          {
            label: 'View Term History',
            field: 'view',
            sort: 'disabled',
            width: 270,
          },
          {
            label: 'Delete',
            field: 'delete',
            sort: 'asc',
            width: 270,
          },
        ],
        rows: [],
      },
    } // state
  }
  componentDidMount() {
    this.fetchUserTypesList()
  }

  confirm_Updatetoggle = (usertype_id, id) => {
    this.setState({
      updatingUsertype: usertype_id,
      updatingId: id,
      confirmUpdateModal: !this.state.confirmUpdateModal,
    })
  }

  confirm_DeleteUser = (data) => {
    this.confirm_toggle(data)
  }

  confirm_toggle = (data = {}) => {
    this.setState({ confirmModal: !this.state.confirmModal, ...data })
  }

  #toggleUserTermsModal = (id) =>
    this.setState({
      updatingId: id,
      userTermsModal: !this.state.userTermsModal,
    })

  _exportToCSV = async () => {
    this.setState({ exporting: true })
    AdminApi.csvUsersList()
      .then((result) => {
        if (result && result.data && result.data.data && result.data.data.url) {
          window.location.href = result.data.data.url
        }
        this.setState({ exporting: false })
      })
      .catch((error) => {
        this.setState({ exporting: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  _DeleteUser = async (id) => {
    this.setState({ deleting: true })
    const data = { firebase_uid: id }

    AdminApi.deleteUser(data)
      .then((result) => {
        this.setState({ deleting: false })
        this.confirm_toggle()
        toast.success('User successfully deleted', {
          position: toast.POSITION.TOP_RIGHT,
        })
        this.fetchUsers()
      })
      .catch((error) => {
        this.setState({ deleting: false })
        toast.error(`${error.message}`, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  updateUsertype = async () => {
    const { loading, updating, updatingId, updatingUsertype } = this.state

    if (loading || updating) {
      return
    }

    const data = {
      id: updatingId,
      usertype_id: updatingUsertype,
    }

    this.setState({ updating: true })
    AdminApi.updateAgentsUsertype(data)
      .then((result) => {
        this.setState({ updating: false }, this.fetchUsers)
        this.confirm_Updatetoggle('', '')
        toast.success(result?.data?.message || 'Usertype Updated', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ updating: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchUserTypesList = async () => {
    this.setState({ loading: true })
    AdminApi.getUserTypes()
      .then((result) => {
        if (result && result.data && result.data.data) {
          this.setState(
            { userTypesList: result.data.data || [] },
            this.fetchUsers
          )
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchUsers = async () => {
    this.setState({ fetching: true })
    AdminApi.getUsersList()
      .then((result) => {
        if (result && result.data && result.data.data) {
          this.setState(
            {
              usersList: result.data.data || [],
              loading: false,
              fetching: false,
            },
            this.setRows
          )
        }
      })
      .catch((error) => {
        this.setState({ loading: false, fetching: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  setRows = () => {
    const { usersList, userTypesList } = this.state
    if (usersList && usersList.length) {
      let rows = usersList.map((row) => {
        //do not remove u_active as it is being used in child component to active/inactive the status
        // const {u_fname, u_lname, u_email, npn, u_password,  firebase_uid, usertype_id, u_active, id} = row;
        const { u_fname, u_lname, u_email, npn, usertype_id, id } = row
        const self = this
        // function updateUser(selectedUsertype_id) {
        //     self.confirm_Updatetoggle(selectedUsertype_id, id);
        // }
        function deleteUser() {
          self.confirm_DeleteUser({ viewdata: row })
        }

        function viewTermHistory() {
          self.#toggleUserTermsModal(id)
        }

        const viewClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={viewTermHistory}
            >
              <i className={'fa fa-eye fa-2x blue-text p-1'}></i>
            </div>
          </div>
        )

        // const usertypeSelectBox = (
        //     <select className="form-control"
        //             /*defaultValue={usertype_id}*/
        //             onChange={(e) => {updateUser(e.target.value)}}
        //     >
        //         {
        //             userTypesList.length > 0 && userTypesList.map((item,i) => {
        //                 return (<option key={i} value={item.id} id={item.name} selected={item.id === usertype_id ? 'selected': ''}>{item.name}</option>)
        //             })
        //         }
        //     </select>
        // );

        const deleteClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={deleteUser}
            >
              <MDBIcon icon="trash" size="2x" className="red-text p-1" />
            </div>
          </div>
        )

        const activeSwitch = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <ChangeUserStatusInListComponent
              callback={this.fetchUsers}
              selectedRow={row}
            />
          </div>
        )

        return {
          name: `${u_fname} ${u_lname ? u_lname : ''}`,
          email: u_email,
          npn: npn,
          // password: u_password,
          usertype: (
            (userTypesList || [])
              .filter((i) => parseInt(i.id) === parseInt(usertype_id))
              .shift() || {}
          )?.name,
          active: activeSwitch,
          view: viewClick,
          delete: deleteClick,
        }
      })
      this.setState({
        data: { ...this.state.data, rows: rows },
      })
    }
  }

  render() {
    toast.configure()
    const {
      loading,
      fetching,
      updating,
      deleting,
      confirmModal,
      confirmUpdateModal,
      data,
      usersList,
      exporting,
    } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <h2>Admin Report: User Reports</h2>
              <hr />
              {loading || fetching ? (
                <h2>Loading...</h2>
              ) : (
                <>
                  <div className="content-wrapper">
                    <MDBDataTable
                      responsive
                      striped
                      bordered
                      small
                      data={data}
                      className="text-truncate"
                      entries={50}
                      entriesOptions={[50, 100, 150]}
                    />
                  </div>
                  {usersList && usersList.length ? (
                    <div className="row justify-content-between">
                      <div className="pl-3"></div>
                      <div className="mr-2">
                        <MDBBtn color="primary" onClick={this._exportToCSV}>
                          {exporting ? 'Downloading...' : 'Download CSV'}
                        </MDBBtn>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <MDBModal
                    size={'sm'}
                    isOpen={confirmUpdateModal}
                    toggle={this.confirm_Updatetoggle}
                  >
                    <MDBModalHeader toggle={this.confirm_Updatetoggle}>
                      Update Usertype
                    </MDBModalHeader>
                    <MDBModalBody>
                      <div className="h5">
                        Are you sure you want to update this User's Usertype?
                      </div>
                      <MDBBtn
                        color="secondary"
                        size={'sm'}
                        onClick={this.confirm_Updatetoggle}
                      >
                        Close
                      </MDBBtn>
                      <MDBBtn
                        color="primary"
                        size={'sm'}
                        onClick={() => {
                          this.updateUsertype()
                        }}
                      >
                        {updating ? 'Updating...' : 'Update'}
                      </MDBBtn>
                    </MDBModalBody>
                  </MDBModal>

                  <MDBModal
                    size={'sm'}
                    isOpen={confirmModal}
                    toggle={this.confirm_toggle}
                  >
                    <MDBModalHeader toggle={this.confirm_toggle}>
                      Delete User
                    </MDBModalHeader>
                    <MDBModalBody>
                      <div className="h5">
                        Are you sure you want to delete this User?
                      </div>
                      <MDBBtn
                        color="secondary"
                        size={'sm'}
                        onClick={this.confirm_toggle}
                      >
                        Close
                      </MDBBtn>
                      <MDBBtn
                        color="danger"
                        size={'sm'}
                        onClick={() => {
                          this._DeleteUser(this.state.viewdata.firebase_uid)
                        }}
                      >
                        {deleting ? 'Deleting...' : 'Delete'}
                      </MDBBtn>
                    </MDBModalBody>
                  </MDBModal>

                  <MDBModal
                    isOpen={this.state.userTermsModal}
                    toggle={this.#toggleUserTermsModal}
                    size="xl"
                  >
                    <MDBModalHeader
                      toggle={this.#toggleUserTermsModal}
                    ></MDBModalHeader>
                    <MDBModalBody>
                    	<AgentTerms
                        userId={this.state.updatingId}
                        hideHeader={true}
                      />
                    </MDBModalBody>
                  </MDBModal>
                </>
              )}
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default UserReports
