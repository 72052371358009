import React, { useState } from 'react'
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBInput,
} from 'mdbreact'

export const EmailDistributionFilterDropDown = ({
  disabled,
  label,
  name,
  onChange,
  options,
  value,
  onFilter,
}) => {
  const [filter, setFilter] = useState(null)
  const onSearch = (e) => {
    setFilter(e.target.value)
  }
  const onValueChange = (e) => {
    let selected = [...value]
    e = isNaN(parseInt(e)) ? `${e}`.trim() : parseInt(e)
    if (selected.indexOf(e) > -1) selected.splice(selected.indexOf(e), 1)
    else selected.push(e)

    onChange(selected)
  }
  return (
    <MDBDropdown>
      <MDBDropdownToggle disabled={disabled} caret color="indigo">
        {label}
      </MDBDropdownToggle>
      <MDBDropdownMenu basic>
        <MDBInput
          label={`Search ${label}`}
          name={name}
          value={filter}
          onChange={onSearch}
        />
        {options && Array.isArray(options) ? (
          options
            .filter(
              onFilter && typeof onFilter === 'function'
                ? (c) => onFilter(c, filter)
                : (data) => {
                    return filter
                      ? `${data.label}`.toLowerCase().indexOf(filter) > -1
                      : true
                  }
            )
            .map((option, i) => {
              return (
                <MDBInput
                  checked={
                    value && Array.isArray(value)
                      ? value.includes(option.value)
                      : false
                  }
                  key={'st-input-' + i}
                  label={option.label}
                  type="checkbox"
                  id={'state-ckbox-' + option.value}
                  name={'state-ckbox-' + option.value}
                  className={option.checked ? 'is-checked' : ''}
                  onChange={() => onValueChange(option.value)}
                />
              )
            })
        ) : (
          <></>
        )}
      </MDBDropdownMenu>
      <ul className="results">
        {value && Array.isArray(value) && value.length ? (
          value.map((val, i) => {
            return (
              <li onClick={() => onValueChange(val)} key={'st-tag-' + i}>
                {
                  options.filter((option) => `${option.value}` === `${val}`)[0]
                    .label
                }
              </li>
            )
          })
        ) : (
          <li className="all">All {label}</li>
        )}
      </ul>
    </MDBDropdown>
  )
}
