import React from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import SystemSettingStore from './../../../../shared/stores/systemSetting.store'
import { BeastModeTermsReviewer } from './../TermsReviewer/TermsReviewer.component'

import './Terms.component.scss'

const BeastModeTermsComponent = ({ onCancel, pkg }) => {
  const onAccepted = () => {
    const links = SystemSettingStore.get('beastmode---signup-links') || {}
    if (links && pkg && links.hasOwnProperty(pkg)) {
      window.open(links[pkg])
      onCancel()
    }
  }

  return (
    <MDBContainer id="BeastModeTermsComponent" fluid>
      <BeastModeTermsReviewer onAccepted={onAccepted} onCancel={onCancel} />
    </MDBContainer>
  )
}

export default BeastModeTermsComponent
