import React from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout.jsx'
import { MDBRow, MDBCol, MDBContainer, MDBIcon } from 'mdbreact'
import CarrierMgmtStore from './CarrierMgmt.store'
import CarrierLinkBankStore from './CarrierLinkBank.store'
import CarrierEditorForms from './components/CarrierEditorForms/CarrierEditorForms.component'

import './CarrierManagement.scss'

class CarrierCrudPage extends React.Component {
  _loadCarrierById = async (carrierId) => {
    if (
      this.props.match?.params?.id &&
      `${CarrierMgmtStore.getCarrier()?.id}` !== `${carrierId}`
    ) {
      CarrierMgmtStore.fetchCarrier(carrierId)

      if (!isNaN(carrierId) && parseInt(carrierId)) {
        if (CarrierMgmtStore.fmos.length < 2) CarrierMgmtStore.fetchFMOs()
        CarrierMgmtStore.fetchContacts(carrierId)
        CarrierMgmtStore.fetchContracts(carrierId)
        CarrierMgmtStore.fetchPriorities(carrierId)
        CarrierMgmtStore.fetchCoverages(carrierId)
        CarrierMgmtStore.fetchDivisions(carrierId)
        CarrierLinkBankStore.fetchByCarrierId(carrierId)
      }
    }
  }

  componentDidMount() {
    this._loadCarrierById(this.props.match.params.id)
  }

  componentDidUpdate() {
    this._loadCarrierById(this.props.match.params.id)
  }

  backToList = (event) => {
    event.preventDefault()
    this.props.history.push('/admin-carriers')
    return false
  }

  render() {
    return (
      <React.Fragment>
        <DashboardLayout>
          <main id="CarrierCrudPage" className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <MDBRow>
                <MDBCol size="12">
                  <h2>
                    Carrier Editor
                    <div className="d-inline-block float-right">
                      <span
                        onClick={this.backToList}
                        style={{ cursor: 'pointer', fontSize: '14px' }}
                      >
                        <MDBIcon icon="arrow-left" />
                        &nbsp;Back to Carrier List
                      </span>
                    </div>
                  </h2>
                </MDBCol>
              </MDBRow>
              <hr />
              <MDBRow>
                <MDBCol size="12">
                  <div className="content-wrapper">
                    <CarrierEditorForms
                      goTo={(carrierId) =>
                        this.props.history.push(`/admin-carriers/${carrierId}`)
                      }
                    />
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export default CarrierCrudPage
