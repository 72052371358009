import React from 'react'
import { observer } from 'mobx-react-lite'
import Store from './../store'
import moment from "moment";
import {MDBContainer, MDBRow, MDBCol, MDBAlert} from 'mdbreact'

import './VersionsTable.component.scss';

const VersionsTable = () =>
{
	const renderType = ChangeLog =>
	{
		if (ChangeLog.change_type === 'release') {
			return (
					<MDBAlert color='success'>
						<div className='version-number'>Version:<br /><span>{ChangeLog.version}</span></div>
						<div className='release-date'>Released at:<br /><span>{moment(ChangeLog.released_at).format('h:mm a')} {moment(ChangeLog.released_at).format('MM/DD/YYYY')}</span></div>
					</MDBAlert>
				)
		}

		if (ChangeLog.change_type === 'tip') {
			return (
					<MDBAlert color='info'>
						<div className='tech-tip'>Tech Tip!</div>
					</MDBAlert>
				)
		}
	}

	const renderChangeRow = ChangeLog =>
	{
		return (
				<MDBRow key={`change-log-version-${ChangeLog.version}`}>
					<MDBCol size='12' sm="4" lg='3' xl='2' className='change-type'>{renderType(ChangeLog)}</MDBCol>
					<MDBCol size='12' sm="8" lg='9' xl='10' className='change-detail' dangerouslySetInnerHTML={{ __html: ChangeLog.changed }}></MDBCol>
				</MDBRow>
			)
	}

	return (
			<div id="VersionsTableComponent">
				<MDBContainer className="versions-table">
					{
						Store.ChangeLogs.map(renderChangeRow)
					}
				</MDBContainer>
			</div>
		)
}

export default observer(VersionsTable);