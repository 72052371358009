import { makeAutoObservable } from 'mobx'
import ReferralPartnerFactory from './../../../shared/factories/referral-partner.factory'
import ReferralPartnerW9Factory from './../../../shared/factories/referral-partner-w9.factory'
import UserFactory from './../../../shared/factories/user.factory'

const searchReferralPartners = async ({ term, agentId, pagination }) => {
  pagination = pagination && typeof pagination === 'object' ? pagination : {}

  try {
    const search = { order_by: { name: 'ASC' }, pagination }
    if (term || agentId) {
      search.search = {}
      if (term) search.search = { ...search.search, name: `%${term}%` }
      if (agentId) search.search = { ...search.search, agent_id: agentId }
    }

    return await ReferralPartnerFactory.search(search)
  } catch (ex) {
    console.error(`Failed to search referral partners ${ex}.`)
    return { models: [], pagination: {} }
  }
}

const searchReferralPartnerW9s = async ({ referralPartnerId }) => {
  try {
    return await ReferralPartnerW9Factory.findAllByReferralPartnerId(
      referralPartnerId
    )
  } catch (ex) {
    console.error(`Failed to search referral partner w9s ${ex}.`)
    return []
  }
}

const getAgents = async (id) => {
  try {
    return (
      (
        await UserFactory.search({
          search: { id },
          pagination: { per_page: id.length },
        })
      )?.models || []
    )
  } catch (ex) {
    console.error(`Failed to search referral partner agents ${ex}.`)
    return []
  }
}

class ReferralsStore {
  isLoading = false
  isSearching = false
  isSaving = false

  // data models.
  ReferralPartners = []
  referralPagination = {}
  ReferralPartner = null
  W9s = []
  Agents = {}

  searchTerm = ''
  searchAgentId = null

  constructor() {
    makeAutoObservable(this)
  }

  init = async () => {
    this.isLoading = true

    const { models, pagination } = await searchReferralPartners({})
    this.ReferralPartners = models
    this.referralPagination = pagination

    if (Array.isArray(models)) {
      let agentIds = models
        .map((RP) => RP.get('agent_id'))
        .filter((agentId) => agentId && !isNaN(agentId))
        .filter((agentId) => !this.Agents.hasOwnProperty(agentId))
        .filter((val, idx, self) => self.indexOf(val) === idx)
      if (agentIds.length)
        (await getAgents(agentIds)).forEach(
          (Agent) => (this.Agents[Agent.id()] = Agent)
        )
    }

    this.ReferralPartner = models.length > 0 && models[0]
    if (this.ReferralPartner) this.edit(this.ReferralPartner)

    this.isLoading = false
  }

  getAgentNameByReferralPartner = (ReferralPartner) => {
    if (ReferralPartner && this.Agents[ReferralPartner.get('agent_id')])
      return [
        this.Agents[ReferralPartner.get('agent_id')].get('u_fname'),
        this.Agents[ReferralPartner.get('agent_id')].get('u_lname'),
      ]
        .filter((n) => n)
        .join(' ')
        .trim()
    return ''
  }

  searchReferralPartners = async ({ pagination }) => {
    this.isSearching = true

    const Response = await searchReferralPartners({
      term: this.searchTerm,
      agentId: this.searchAgentId,
      pagination,
    })
    this.ReferralPartners = Response.models
    this.referralPagination = Response.pagination

    this.isSearching = false
  }

  edit = async (ReferralPartner) => {
    if (
      this.ReferralPartner &&
      parseInt(this.ReferralPartner.id()) === parseInt(ReferralPartner.id())
    )
      return

    this.isLoading = true
    this.ReferralPartner = ReferralPartner
    this.W9s = await searchReferralPartnerW9s({
      referralPartnerId: ReferralPartner.id(),
    })
    this.isLoading = false
  }
}

export default new ReferralsStore()
