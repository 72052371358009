import React, { useState } from 'react'
import env from './../../../environments/environment'
import placeholder_picture from './../../../assets/images/user_placeholder.png'
import { observer } from 'mobx-react-lite'
import {
  MDBFileInput,
  MDBRow,
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from 'mdbreact'
import ReactCrop from 'react-image-crop'
import profilePicGuidelines from './../../../assets/images/picguidelines.jpg'
import UserProfileService from './../../../shared/services/UserProfile.service'
import UserService from './../../../shared/services/User.service'
import AgentProfileStore from './../AgentProfileIntake/AgentProfile.store'

// import "react-image-crop/dist/ReactCrop.css";
import './AgentHeadshotManager.scss'
const Image = { Ref: null }

const AgentHeadshotManager = ({ onValidityChange }) => {
  const [showModal, setShowModal] = useState(false)
  const [state, setState] = useState({
    profile_pic: null,
    src: null,
    crop: {
      aspect: 1 / 1.2,
      width: 30,
      unit: '%',
    },
    minCropSize: {
      width: 150,
      height: 180,
    },
    uploaded: null,
    u_picture: UserProfileService.get('u_picture'),
  })

  const getIsValid = () =>
    UserProfileService.isA(['affiliate-group', 'associate-group']) ||
    !!UserProfileService.get('u_picture')

  const getCroppedImg = async (image, crop, fileName) => {
    const canvas = document.createElement('canvas'),
      scaleX = image.naturalWidth / image.width,
      scaleY = image.naturalHeight / image.height

    canvas.width = Math.ceil(crop.width * scaleX)
    canvas.height = Math.ceil(crop.height * scaleY)

    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) return
        blob.name = fileName
        resolve(blob)
      }, 'image/jpeg')
    })
  }

  const makeClientCrop = async (crop) => {
    if (Image.Ref && crop.width && crop.height)
      return await getCroppedImg(Image.Ref, crop, 'headshot.jpeg')
  }

  const onSelectFile = (file) => {
    file = file[0]
    const reader = new FileReader()
    reader.addEventListener('load', () =>
      setState((prevState) => ({ ...prevState, src: reader.result }))
    )
    reader.readAsDataURL(file)
  }

  const onImageLoaded = (image) => {
    Image.Ref = image

    try {
      let w = Math.min(image.width, image.height)
      if (w) {
        setState((prevState) => ({
          ...prevState,
          crop: {
            unit: 'px',
            height: w * 0.8 * state.crop.aspect,
            width: w * 0.8,
            x: w * 0.1,
            y: w * 0.1,
          },
        }))
        makeClientCrop({
          unit: 'px',
          height: w * 0.8 * state.crop.aspect,
          width: w * 0.8,
          x: w * 0.1,
          y: w * 0.1,
        })
      }
    } catch (ex) {
      console.log('FAILED image crop: ', { ex })
    }
  }

  const onCropComplete = (crop) => {} //makeClientCrop(crop);

  const onCropChange = (crop, percentCrop) => {
    if (crop.width && crop.height) {
      crop.width =
        crop.width > state.minCropSize.width
          ? crop.width
          : state.minCropSize.width
      crop.height =
        crop.height > state.minCropSize.height
          ? crop.height
          : state.minCropSize.height
      setState((prevState) => ({ ...prevState, crop }))
    }
  }

  const savePhoto = async () => {
    const payload = await makeClientCrop(state.crop)

    if (typeof payload === 'object' && payload instanceof Blob) {
      // Build payload to send file to the server.
      const formData = new FormData()
      formData.append('u_picture', payload)
      formData.append('u_picture_filename', 'headshot.jpeg')

      try {
        await UserService.uploadHeadshot(
          UserProfileService.getUserId(),
          formData
        )
        UserService.get(UserProfileService.getUserId())
          .then((User) => {
            UserProfileService.set('u_picture', User && User?.u_picture)
            setState((prevState) => ({
              ...prevState,
              src: null,
              crop: null,
              uploaded: true,
              u_picture: UserProfileService.get('u_picture'),
            }))
            Image.Ref = null
          })
          .finally(() => {
            // onValidityChange({headshot: getIsValid()})
            AgentProfileStore.setValidity('headshot', getIsValid())
          })
      } catch (ex) {
        console.error('Failed to upload: ', ex)
      }
    }
  }

  // onValidityChange({headshot: getIsValid()})
  AgentProfileStore.setValidity('headshot', getIsValid())

  return (
    <MDBRow id="AgentHeadshotManagerComponent">
      <MDBCol size="12" md="6">
        <div>
          {UserProfileService.get('u_picture') ? (
            <img
              src={`https://firebasestorage.googleapis.com/v0/b/${
                env.integrations.firebase.config.storageBucket
              }/o/${encodeURIComponent(
                UserProfileService.get('u_picture')
              )}?alt=media`}
              alt="headshot"
              style={{ maxHeight: '250px' }}
            />
          ) : (
            <img
              src={placeholder_picture}
              alt="placeholder"
              style={{ maxHeight: '250px' }}
              className="img-fluid d-block headshot-ratio"
            />
          )}
        </div>
      </MDBCol>
      <MDBCol size="12" md="6">
        <form>
          <MDBFileInput name="profile_pic" getValue={onSelectFile} />
          {state.src && (
            <ReactCrop
              src={state.src}
              crop={state.crop}
              ruleOfThirds
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={onCropChange}
            />
          )}
          <MDBRow>
            <MDBCol size="12">
              <MDBBtn
                sm="true"
                onClick={() => setShowModal((prevState) => !prevState)}
                className="btn-block"
                style={{ marginBottom: '5px' }}
                color="indigo"
              >
                Profile Pic Guidelines
              </MDBBtn>
            </MDBCol>
            <MDBCol size="12">
              <MDBBtn
                sm="true"
                color="success"
                className="btn-block"
                onClick={savePhoto}
              >
                Upload Picture
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </form>
      </MDBCol>
      <MDBModal
        isOpen={showModal}
        toggle={() => setShowModal((prevState) => !prevState)}
        size="lg"
      >
        <MDBModalHeader toggle={() => setShowModal((prevState) => !prevState)}>
          Profile Pic Guidelines
        </MDBModalHeader>
        <MDBModalBody>
          <img
            src={profilePicGuidelines}
            className="img-fluid"
            alt="profile pic"
          />
        </MDBModalBody>
      </MDBModal>
    </MDBRow>
  )
}

export default observer(AgentHeadshotManager)
