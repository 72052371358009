import BaseModelInstance from './base.model'
import { ReferralPartner as ModelDefinition } from './model-definitions'
import ReferralPartnerService from './../services/ReferralPartner.service'
import ReportService from './../services/Report.service'
import moment from 'moment'

const exportStmt = async (recordId) => {
  let result
  try {
    result = await ReportService.export({
      category: 'referrals',
      report: 'referralPartnerStatement',
      start: '2000-01-01',
      stop: moment().format('YYYY-MM-DD'),
      coverages: [],
      orderBy: { av_date: 'DESC' },
      search: recordId,
    })
  } catch (ex) {
    console.error(ex)
  }

  if (result && result?.url) return result.url
  return false
}

const emailStmt = async (email, recordId) => {
  try {
    let url = await exportStmt(recordId),
      result = await ReferralPartnerService.emailStmt(recordId, { url })
    return true
  } catch (ex) {
    console.error(ex)
  }

  return false
}

class ReferralPartner extends BaseModelInstance {
  afterSet = (key, value) => {}

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }

  downloadStatment = async () => {
    return await exportStmt(this.id())
  }

  emailStatement = async () => {
    return await emailStmt(this.get('email'), this.id())
  }
}

export default ReferralPartner
