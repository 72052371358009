import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import {
  MDBCollapse,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBSelect,
  MDBInput,
  MDBBtn,
  MDBIcon,
} from 'mdbreact'
import CarrierMgmtStore from './../../CarrierMgmt.store'

import './FMOEditorForm.scss'

const FMOEditorForm = ({ goTo }) => {
  const [fmo, setFmo] = useState(null)

  const createNew = () => setFmo({})

  const viewFmo = (fmoId) =>
    setFmo(
      CarrierMgmtStore.fmos
        .filter((fmo) => `${fmo.id}` === `${fmoId}`)
        .shift() || null
    )

  const onSubmit = (event) => {
    event.preventDefault()

    const completeSave = async () => {
      try {
        await CarrierMgmtStore.saveFMO(
          fmo?.id > 0 ? { ...payload, id: fmo.id } : payload
        )
      } catch (ex) {
        return false
      }

      setFmo(null)
    }

    let payload = {
      fmo_name: fmo?.fmo_name || '',
      contracting_instructions: fmo?.contracting_instructions || '',
    }

    if (`${payload.fmo_name}`.trim().length > 0) completeSave()

    return false
  }

  return (
    <div id="FMOEditorFormComponent">
      <MDBCollapse isOpen={!fmo} className="action-wrapper">
        <MDBContainer fluid>
          <MDBRow>
            <MDBCol size="12" md="6">
              <MDBBtn block onClick={createNew}>
                Create a New FMO&nbsp;
                <MDBIcon icon="plus" />
              </MDBBtn>
            </MDBCol>
            <MDBCol size="12" md="6">
              <MDBSelect
                options={CarrierMgmtStore.fmos.map((i) => ({
                  text: `${i.fmo_name}`,
                  value: `${i.id}`,
                }))}
                label="Update an Existing FMO"
                name="fmo_id"
                getValue={(e) => {
                  if (typeof (e = e.shift()) !== 'undefined') viewFmo(e)
                }}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBCollapse>
      <MDBCollapse isOpen={!!fmo} className="form-wrapper">
        <MDBContainer fluid>
          <MDBRow>
            <MDBCol size="12">
              <form noValidate onSubmit={onSubmit}>
                <MDBInput
                  label="FMO Name"
                  type="text"
                  name="fmo_name"
                  value={fmo?.fmo_name || ''}
                  onChange={(e) => {
                    if (fmo?.fmo_name !== e.target.value)
                      setFmo((prevState) => ({
                        ...prevState,
                        fmo_name: e.target.value,
                      }))
                  }}
                  autoComplete="off"
                />
                <MDBInput
                  label="FMO Contracting Instructions"
                  type="textarea"
                  name="contracting_instructions"
                  value={fmo?.contracting_instructions || ''}
                  onChange={(e) => {
                    if (fmo?.contracting_instructions !== e.target.value)
                      setFmo((prevState) => ({
                        ...prevState,
                        contracting_instructions: e.target.value,
                      }))
                  }}
                  autoComplete="off"
                />
                <MDBBtn
                  disabled={
                    !fmo ||
                    !fmo?.fmo_name ||
                    CarrierMgmtStore.getIsLoading('update.fmo')
                  }
                  type="submit"
                  size="sm"
                >
                  {CarrierMgmtStore.getIsLoading('update.fmo') ? (
                    <>
                      Saving... <i className="fa fa-spin fa-spinner"></i>
                    </>
                  ) : (
                    <>
                      {fmo?.id > 0 ? 'Update ' : 'Create '} FMO&nbsp;
                      <MDBIcon icon="save" />
                    </>
                  )}
                </MDBBtn>
                <MDBBtn onClick={() => setFmo(null)} type="button" size="sm">
                  Cancel Changes&nbsp;
                  <MDBIcon icon="times" />
                </MDBBtn>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBCollapse>
    </div>
  )
}

export default observer(FMOEditorForm)
