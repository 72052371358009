import React from 'react'
import UserProfileService from './../../../shared/services/UserProfile.service'
import LoadingSpinner from './../../shared/LoadingSpinner.component'
import {
  MDBBtn,
  MDBAlert,
  MDBIcon,
  MDBModalHeader,
  MDBModalBody,
  MDBModal,
} from 'mdbreact'
import UserService from './../../../shared/services/User.service'
import ContentHtml from '../../content/ContentHtml/ContentHtml.component'
import UplineContactInfo from './UplineContactInfo.component'

import './UplineContactsModal.component.scss'

class UplineContactsModal extends React.Component {
  state = {
    isLoading: true,
    isOpen: false,
    isEmpty: false,
    uplines: [],
  }

  #toggleContactInfoModal = () => {
    if (!this.state.loading) {
      if (!this.state.isOpen) this.#fetchUplineContactsInfo()
      this.setState({ isOpen: !this.state.isOpen })
    }
  }

  #fetchUplineContactsInfo = async () => {
    const upline_data = ((await UserService.getUpline(UserProfileService.getUserId())) || []).filter((Upline) => parseInt(Upline.u_upline_id) > 0);
    let signature = UserProfileService.get('signature').replace(/\(|\)/g, ' ').trim().split(/\s+/).reverse();
    let uplines = [];
    
    signature.forEach(item => {
      const result = upline_data.find((upline) => upline.id === parseInt(item));
      if(result) uplines.push(result);
    });

    this.setState((prevState) => ({
      ...prevState,
      isLoading: false,
      uplines: uplines,
      isEmpty: uplines?.length === 0,
    }))
  }

  render() {
    return (
      <div id="UplineContactsModal">
        <MDBBtn
          className="btn-block mb-1"
          color="indigo"
          onClick={this.#toggleContactInfoModal}
        >
          <MDBIcon icon="user-circle" className="mr-1" /> Upline Contact Info
        </MDBBtn>

        <MDBModal
          size="lg"
          isOpen={this.state.isOpen}
          toggle={this.#toggleContactInfoModal}
        >
          <MDBModalHeader toggle={this.#toggleContactInfoModal}>
            Upline Contact Info
          </MDBModalHeader>
          <MDBModalBody className={this.state.isLoading ? 'is-loading' : ''}>
            {this.state.isLoading ? (
              <div className="loading-wrapper">
                <LoadingSpinner size="md" isActive={true} />
              </div>
            ) : (
              <>
                <div>
                  <ContentHtml contentSlug="upline-contact-info-header" />
                </div>
                <div>
                  {this.state.isEmpty ? (
                    <MDBAlert color="warning">
                      You do not have any upline agents!
                    </MDBAlert>
                  ) : (
                    this.state.uplines.map((upline, index) => {
                      return (
                        <div key={index}>
                          <UplineContactInfo
                            isDirectUpline={UserProfileService.get('u_upline_id') === upline.id}
                            upline={upline}
                          ></UplineContactInfo>
                          {
                            index < this.state.uplines.length - 1 && (
                              <div className='text-center'>
                                <MDBIcon far icon="arrow-alt-circle-down" size='3x' />
                              </div>
                            )
                          }
                        </div>
                      )
                    })
                  )}
                </div>
                <MDBBtn
                  color="warning"
                  size={'sm'}
                  block
                  onClick={this.#toggleContactInfoModal}
                >
                  Close
                </MDBBtn>
              </>
            )}
          </MDBModalBody>
        </MDBModal>
      </div>
    )
  }
}

export default UplineContactsModal
