export const activityWorker = () => {
  // eslint-disable-next-line no-restricted-globals
  let s = self

  s._warnTimer = null
  s._executeTimer = null
  s.SESSION_LOGOUT = 1
  s.SESSION_WARNING = 1
  s.remaining = s.SESSION_LOGOUT
  s.token = ''
  s.onmessage = (message) => {
    switch (message.data.type) {
      case 'init':
        s.SESSION_LOGOUT = message.data.SESSION_LOGOUT
        s.SESSION_WARNING = message.data.SESSION_WARNING
        s.updateRemaining(message.data.SESSION_LOGOUT)
        break
      case 'restartTimer':
        s.token = message.data.token
        s.restartTimer()
        break
      case 'cancelWarning':
        s.cancelWarning()
        break
      default:
        break
    }
  }
  s.resetTimers = () => {
    if (s._warnTimer) clearTimeout(s._warnTimer)
    if (s._executeTimer) clearTimeout(s._executeTimer)
  }
  s.restartTimer = () => {
    s.resetTimers()
    if (!!s.token) s._warnTimer = setTimeout(s.startWarning, s.SESSION_WARNING)
  }
  s.cancelWarning = () => {
    postMessage({ type: 'showWarningModal', showWarningModal: false })
    s.updateRemaining(s.SESSION_LOGOUT)

    s.restartTimer()
  }
  s.handleCountdown = () => {
    if (s.remaining) {
      s.updateRemaining(s.remaining - 1)
      return
    }

    s.resetTimers()
    postMessage({ type: 'logout' })
  }
  s.startWarning = () => {
    if (!!s.token) {
      postMessage({ type: 'showWarningModal', showWarningModal: true })
      s.updateRemaining(s.SESSION_LOGOUT)
      let duration = s.SESSION_LOGOUT

      s.resetTimers()

      s._executeTimer = setInterval(s.handleCountdown, 1000, duration)
    }
  }
  s.updateRemaining = (v) => {
    s.remaining = v
    postMessage({
      type: 'updateRemaining',
      remaining: v,
    })
  }
}
