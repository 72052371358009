import BaseModelFactory from './base.factory'
import UserTermSetService from './../services/UsersTermSets.service'
import UserTermSet from './../models/user-term-set.model'

class UserTermSetFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: UserTermSetService, Model: UserTermSet }
    )
  static findAllByUserId = async (userId) =>
    await this._findBy(
      { user_id: userId },
      { Service: UserTermSetService, Model: UserTermSet }
    )
  static create = (payload) =>
    this._create(payload, { Service: UserTermSetService, Model: UserTermSet })
}

export default UserTermSetFactory