import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { MDBRow, MDBCol, MDBBtn, MDBContainer, MDBIcon } from 'mdbreact'
import AdminApi from '../../api/admin-api/admin-api'
import { toast } from 'react-toastify'
import appConstants from '../../constants/appConstants'
import UsertypeService from './../../shared/services/Usertype.service'
import CarrierSerivce from './../../shared/services/Carrier.service'
import CommissionLevelSerivce from './../../shared/services/CommissionLevel.service'
import UserProfileService from '../../shared/services/UserProfile.service'
import { EmailDistributionFilterDropDown } from './components/EmailDistributionFilterDropDown'

import './EmailDistributionPage.scss'

const StatesList = Array.isArray(appConstants.States) && appConstants.States

class EmailDistributionPage extends Component {
  state = {
    usertypes: [],
    carriers: [],
    commissions: [],
    isBSTUser: false,

    search: {
      usertypes: '',
      states: '',
      carriers: '',
      commissions: '',
    },

    selected: {
      usertypes: [],
      states: [],
      carriers: [],
      commissions: [],
      address: false,
    },

    loading: false,
    exporting: false,
  }

  componentDidMount() {
    this.#fetchUserTypes()
    this.#fetchCarriers()
    this.#fetchCommissionLevels()
    if ([219, 35, 36].includes(UserProfileService.get('usertype_id')))
      this.setState({ isBSTUser: true })
  }

  #fetchUserTypes = async () => {
    let usertypes
    try {
      usertypes = await UsertypeService.search({
        pagination: false,
        order_by: { displayname: 'ASC' },
      })
    } catch (ex) {
      console.error('Failed to fetch usertypes in EmailDistributionPage.', ex)
    }

    if (usertypes && usertypes?.models)
      return this.setState({ usertypes: usertypes?.models })
  }

  #fetchCarriers = async () => {
    let carriers
    try {
      carriers = await CarrierSerivce.search({
        pagination: false,
        order_by: { c_name: 'ASC' },
      })
    } catch (ex) {
      console.error('Failed to fetch carriers in EmailDistributionPage.', ex)
    }

    if (carriers && carriers?.models)
      return this.setState({ carriers: carriers?.models })
  }

  #fetchCommissionLevels = async () => {
    let commissions
    try {
      commissions = await CommissionLevelSerivce.search({
        pagination: false,
        order_by: { com_name: 'ASC' },
      })
    } catch (ex) {
      console.error(
        'Failed to fetch commission levels in EmailDistributionPage.',
        ex
      )
    }

    if (commissions && commissions?.models)
      return this.setState({ commissions: commissions?.models })
  }

  #downloadAndDelete = async (url, filename) => {
    if (url) window.setTimeout(() => (window.location.href = url))

    if (filename)
      window.setTimeout(() => AdminApi.deleteCsvMailingList({ filename }), 1000)
  }

  #generate = async () => {
    let reqData = {}

    Object.keys(this.state.selected)
      .filter((s) => this.state.selected[s].length)
      .forEach((s) => (reqData[s] = this.state.selected[s]))

    reqData.address = this.state.selected.address
    this.setState({ exporting: true })

    AdminApi.generateCsvMailingList(reqData)
      .then((result) => {
        if (result && result.data && result.data.data)
          this.#downloadAndDelete(
            result.data.data.url,
            result.data.data.filename
          )
      })
      .catch((error) =>
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      )
      .finally(() => this.setState({ exporting: false }))
  }

  #onChange = (filter) => (val) => {
    this.setState({
      selected: {
        ...this.state.selected,
        [filter]: val,
      },
    })
  }

  #onSearchChange = (filter) => (val) => {
    this.state.selected.address
      ? this.setState({
          selected: {
            ...this.state.selected,
            address: false,
          },
        })
      : this.setState({
          selected: {
            ...this.state.selected,
            address: true,
          },
        })
  }

  render() {
    const { loading, usertypes, carriers, exporting, commissions, isBSTUser } =
      this.state
    return (
      <Fragment>
        <DashboardLayout>
          <main id="EmailDistributionPage" className="mainSection">
            <MDBContainer fluid className="mt-5">
              <h2>Email Distribution List Generator</h2>
              <hr />
              {loading ? (
                <h2>Loading...</h2>
              ) : (
                <>
                  <MDBRow>
                    <MDBCol size="12" sm="6" lg="3">
                      <EmailDistributionFilterDropDown
                        label={'Usertypes'}
                        disabled={loading || exporting}
                        options={
                          usertypes
                            ? usertypes.map((usertype) => {
                                return {
                                  value: usertype.id,
                                  label: usertype.displayname,
                                }
                              })
                            : []
                        }
                        onChange={this.#onChange('usertypes')}
                        value={this.state.selected.usertypes}
                      />
                    </MDBCol>
                    <MDBCol size="12" sm="6" lg="3">
                      <EmailDistributionFilterDropDown
                        label={'States'}
                        disabled={loading || exporting}
                        options={
                          StatesList
                            ? StatesList.map((state) => {
                                return {
                                  value: state.id,
                                  label: state.name,
                                }
                              })
                            : []
                        }
                        onChange={this.#onChange('states')}
                        value={this.state.selected.states}
                      />
                    </MDBCol>
                    <hr className="seperator" />
                    <MDBCol size="12" sm="6" lg="3">
                      <EmailDistributionFilterDropDown
                        label={'Carriers'}
                        disabled={loading || exporting}
                        options={
                          carriers
                            ? carriers.map((carrier) => {
                                return {
                                  value: carrier.id,
                                  label: (
                                    <>
                                      {carrier.c_name}
                                      <small>{carrier.c_description}</small>
                                    </>
                                  ),
                                  name: carrier.c_name,
                                  description: carrier.c_description,
                                }
                              })
                            : []
                        }
                        onFilter={(carrier, keyword) => {
                          return keyword
                            ? `${carrier.name} ${carrier.description}`
                                .toLowerCase()
                                .indexOf(keyword) > -1
                            : true
                        }}
                        onChange={this.#onChange('carriers')}
                        value={this.state.selected.carriers}
                      />
                    </MDBCol>
                    <MDBCol size="12" sm="6" lg="3">
                      <EmailDistributionFilterDropDown
                        label={'Commission Levels'}
                        disabled={loading || exporting}
                        options={
                          commissions
                            ? commissions.map((commission) => {
                                return {
                                  value: commission.id,
                                  label: commission.com_name,
                                }
                              })
                            : []
                        }
                        onChange={this.#onChange('commissions')}
                        value={this.state.selected.commissions}
                      />
                    </MDBCol>
                    {isBSTUser && (
                      <MDBCol size="12" sm="6" lg="3">
                        <MDBBtn
                          className="addressButton"
                          disabled={loading || exporting}
                          onClick={this.#onSearchChange()}
                        >
                          <input
                            type="checkbox"
                            id="addressCheckbox"
                            checked={this.state.selected.address}
                            disabled={loading || exporting}
                            onChange={(e) => {
                              this.#onChange('address')(e.target.checked)
                            }}
                          />
                          <p onClick={this.#onSearchChange()}>ADDRESS</p>
                        </MDBBtn>
                      </MDBCol>
                    )}
                  </MDBRow>
                  <MDBRow>
                    <MDBCol
                      size="12"
                      className={
                        'generate-btn ' + (exporting ? 'generating' : '')
                      }
                    >
                      <MDBBtn
                        disabled={loading || exporting}
                        color="primary"
                        className="btn-block"
                        onClick={this.#generate}
                      >
                        <span>
                          {exporting ? 'Building...' : 'Generate Email List'}
                        </span>
                        <MDBIcon icon={exporting ? 'spinner' : 'download'} />
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </>
              )}
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default EmailDistributionPage
