import { makeAutoObservable } from 'mobx'
import ReactGA from 'react-ga4'
import UserProfileService from './../../../shared/services/UserProfile.service'

class SubsiteStore {
  subsiteData = {}
  initialized = {
    google: false,
  }

  _currentPage = null

  constructor() {
    makeAutoObservable(this)
  }

  isAuth = () => {
    try {
      return UserProfileService.getUserId() > 0
    } catch (ex) {
      return false
    }
  }

  set = (subsiteData) => {
    let initGA =
      !this.initialized.google &&
      this.subsiteData &&
      this.subsiteData?.google_tag
        ? this.subsiteData?.google_tag !== subsiteData?.google_tag
        : !!subsiteData?.google_tag

    this.subsiteData = subsiteData

    if (initGA) {
      ReactGA.initialize(this.subsiteData.google_tag)
      this.initialized.google = true
    }
  }

  sendPageView = (page) => {
    if (!this._currentPage) {
      this._currentPage = page
      ReactGA.send({ hitType: 'pageview', page })
    } else if (this._currentPage !== page) {
      this._currentPage = page
      ReactGA.send({ hitType: 'pageview', page })
    }
  }
}

export default new SubsiteStore()
