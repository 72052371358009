import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { MDBBtn, MDBIcon, MDBAlert } from 'mdbreact'
import ACMStore from './../AgentContractManager.store'
import UserProfileService from './../../../shared/services/UserProfile.service'
import { getMetaValue } from './../getMetaValue.function'
import ContractStore from './../Contract.store'
import UsersCarrierContractFactory from './../../../shared/factories/users-carrier-contract.factory'
import MediaUploaderModal from './../../modals/MediaUploaderModal'
import AdditionalDocumentsList from './../AdditionalDocumentsList/AdditionalDocumentsList.component'
import AddDocsStore from './../AdditionalDocumentsList/AdditionalDocuments.store'
import UserDocumentFactory from './../../../shared/factories/user-document.factory'
import { toast } from 'react-toastify'

import './AdditionalDocumentsButton.scss'

const AdditionalDocumentsButton = ({ Contract, Carrier }) => {
  const [formValues, setFormValues] = useState({ contract_id: Contract.id() })

  const openModal = () => {
    MediaUploaderModal.open({
      uploadType: 'contract_add_doc',
      modalContentTop: (
        <>
          <p className="text--center fw--500">
            <h6 className="fw--500">
              Upload additional document or images for {Carrier?.get('c_name')}.
            </h6>
          </p>
        </>
      ),
      modalContentBottom: (
        <>
          <p className="text--center fw--500 mt-3">
            <h6 className="fw--500">Update additional document settings.</h6>
          </p>
          <AdditionalDocumentsList
            Contract={Contract}
            Carrier={Carrier}
            userId={UserProfileService.getUserId()}
            updateParams={(params) =>
              setFormValues((prevFormValues) => ({
                ...prevFormValues,
                ...params,
              }))
            }
          />
        </>
      ),
      appendFormData: formValues,
      validationRules: { contentType: ['application/pdf'] },
      preventAutoClose: true,
      onSuccess: () =>
        AddDocsStore.fetch(UserProfileService.getUserId(), Contract?.get('id')),
    })
      .then(async (filePath) => Contract.reload())
      .catch((err) => {
        if (err)
          console.log(
            'Failed to complete uploading additional contract documents.',
            err
          )
      })
  }

  const button = () => {
    return (
      <div className="AdditionalDocumentsButtonComponent">
        <MDBBtn
          className="addtl-docs-btn"
          onClick={() => {
            openModal()
          }}
        >
          <MDBIcon icon="file-medical" />
          &nbsp;
          <span>Addt'l Docs</span>
        </MDBBtn>
      </div>
    )
  }

  return button()
}

export default observer(AdditionalDocumentsButton)
