export const onboarding = {
  // Maximum number of core contracts to be displayed in the top 'core' contracts table.
  CORE_LIMIT: 8,
  ELECTIVE_MIN_LIMIT: 11,
  ELECTIVE_MAX_LIMIT: 12,
  SIG_AGENT_REQUIRED: 8,
  CSR_REQUIRED: 8,
  ASSOC_REQUIRED: 5,
  AFFILIATE_REQUIRED: 1,
  ENABLE_CORE_AUTO_SELECT: true,
  MAX_EXTERNAL_CARRIERS: 3,
  LAST_CONTRACT_VIEW_META_KEY: 'contracting---last-active-view',
}

export const cart = {
  resetter: {
    permitted: ['internal-staff', 'internal-admin', 'system-admin'],
    destructive: {
      permitted: ['internal-staff', 'internal-admin', 'system-admin'],
    },
  },
}

export default {
  onboarding,
  cart,
}
