import { makeAutoObservable } from 'mobx'
import PromotionCodeFactory from '../../../shared/factories/promotion-code.factory'

class PromoteCodesStore {
  constructor() {
    makeAutoObservable(this)
  }

  PromoteCodes = []

  fetch = async () => {
    this.PromoteCodes = await PromotionCodeFactory.search({ pagination: false, order_by: { end_at: 'DESC' }});
  }
}

export default new PromoteCodesStore()
