import RestApi from './../../api/rest-api-client'
const srvcEndpoint = '/users_carrier_contracts'
export default {
  search: async (req, opts) => RestApi.request('GET', srvcEndpoint, req, opts),
  get: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, id, req, opts),
  store: async (data, opts) =>
    RestApi.request('POST', srvcEndpoint, data, opts),
  update: async (id, data, opts) =>
    RestApi.request('PUT', srvcEndpoint, id, data, opts),
  delete: async (id, opts) => RestApi.request('DELETE', srvcEndpoint, id, opts),

  hasMetRequirements: async (id, req, opts) =>
    RestApi.request(
      'GET',
      srvcEndpoint,
      id,
      { search: req },
      { endpoint: `/${id}/has-met-requirements` }
    ),
  attach: async (id, req, opts) =>
    RestApi.request('POST', srvcEndpoint, id, req, {
      endpoint: `/${id}/attach`,
    }),
  downloadContract: async (id, req, opts) =>
    RestApi.request('POST', srvcEndpoint, id, req, {
      endpoint: `/${id}/download-contract`,
    }),
  sendContractToCarrier: async (id, req, opts) =>
    RestApi.request('POST', srvcEndpoint, id, req, {
      endpoint: `/${id}/send-contract`,
    }),
  getDeliveryAttempts: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, id, req, {
      endpoint: `/${id}/delivery-attempts`,
    }),
}
