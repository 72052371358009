import React, { Component } from 'react'
import {
  MDBCol,
  MDBSelect,
  MDBContainer,
  MDBRow,
  MDBInput,
  MDBBtn,
} from 'mdbreact'
import { toast } from 'react-toastify'
import TrainingsPageService from '../../shared/TrainingsPage.service'

import './TrainingTypeForm.scss'

class TrainingTypeForm extends Component {
  state = {
    errorsExist: false,
    saving: false,
    type: {
      id: null,
      training_type_name: '',
      is_required: 0,
      is_manager: 0,
      active: 1,
      type_order: '',
    },
  }

  _errors = {}

  componentDidMount() {
    if (
      this.props.type &&
      typeof this.props.type === 'object' &&
      Object.keys(this.props.type).length
    )
      this.setState({ type: this.props.type })
  }

  _renderErrorField = (field) => {
    if (field && this._errors.hasOwnProperty(field) && this.state.errorsExist)
      return <span className="error-msg">{this._errors[field]}</span>
    return <></>
  }

  _onChange = (event) => {
    if (`${this.state.type[event.target.name]}` === `${event.target.value}`)
      return

    let type = { ...this.state.type }
    type[event.target.name] = event.target.value
    this.setState({ type })
  }

  _isValid = () => {
    let errors = {}
    Object.keys(this.state.type).forEach((field) => {
      switch (field) {
        case 'training_type_name':
          if (
            !this.state.type[field] ||
            !`${this.state.type[field]}`.trim().length
          )
            errors[field] = 'Required.'
          break
        case 'type_order':
          if (!new RegExp(/^\d+$/).test(`${this.state.type[field]}`.trim()))
            errors[field] = 'Must be a number.'
          break
        default:
      }
    })

    this._errors = errors

    if (Object.keys(errors).length === 0) {
      if (this.state.errorsExist) this.setState({ errorsExist: false })
      return true
    }

    if (!this.state.errorsExist) this.setState({ errorsExist: true })

    return false
  }

  _onSubmit = () => {
    if (!this._isValid()) return

    this.setState({ saving: true })

    try {
      parseInt(this.state.type.id) > 0
        ? TrainingsPageService.updateType(this.state.type.id, this.state.type)
        : TrainingsPageService.createType(this.state.type)
      toast.success('Training type saved.', {
        position: toast.POSITION.TOP_RIGHT,
      })
    } catch (ex) {
      toast.error(
        'An error occurred attempting to save the training type.  ' + ex,
        { position: toast.POSITION.TOP_RIGHT }
      )
    }

    this.setState({ saving: false })
    return !!false
  }

  _isSigAgent = (type) =>
  {
  	return type && parseInt(type?.is_sig_agent) === 1;
  }

  _isLeader = (type) =>
  {
  	return type && parseInt(type?.is_manager) === 1;
  }

  _isRequired = (type) =>
  {
  	return type && parseInt(type?.is_required) === 1;
  }

  render () 
  {
    const { saving, type } = this.state

    const isRequired = this._isRequired(type),
    			isSigAgent = this._isSigAgent(type),
    			isLeader = this._isLeader(type);

    return (
      <MDBContainer id="TrainingVideoForm">
        <MDBRow>
          <MDBCol size="12" md="9">
            <MDBInput
              label="Training Category Name"
              name="training_type_name"
              value={type.training_type_name}
              onChange={this._onChange}
              required
            />
            {this._renderErrorField('training_type_name')}
          </MDBCol>
          <MDBCol size="12" md="3">
            <MDBSelect
              label="Category Enabled?"
              search
              options={[
                {
                  text: 'Yes',
                  value: '1',
                  checked: parseInt(type.active) === 1,
                },
                {
                  text: 'No',
                  value: '0',
                  checked: parseInt(type.active) === 0,
                },
              ]}
              name="active"
              value={type.active}
              getValue={(val) =>
                this._onChange({ target: { name: 'active', value: val[0] } })
              }
              required
            />
            {this._renderErrorField('is_manager')}
          </MDBCol>
          <MDBCol size="12" md="6">
            <MDBSelect
              label="Required Training Type?"
              search
              options={[
                {
                  text: 'None',
                  value: '',
                  checked: !isRequired,
                },
                {
                  text: 'Signature Agents',
                  value: 'sig_agent',
                  checked: isSigAgent
                },
                {
                  text: 'Leaders',
                  value: 'leader',
                  checked: isLeader
                },
              ]}
              name="required_type"
              getValue={(val) => {

              	val = Array.isArray(val) && val.shift();

              	let stateUpd = {};

              	if (val === 'sig_agent') {
              		if (isLeader)
              			stateUpd.is_manager = 0;
              		if (!isSigAgent)
              			stateUpd.is_sig_agent = 1;
              		if (!isRequired)
              			stateUpd.is_required = 1;
              	}
              	else if (val === 'leader') {
              		if (!isLeader)
              			stateUpd.is_manager = 1;
              		if (isSigAgent)
              			stateUpd.is_sig_agent = 0;
              		if (!isRequired)
              			stateUpd.is_required = 1;
              	}
              	else {
              		if (isRequired)
              			stateUpd.is_required = 0;
              		if (isSigAgent)
              			stateUpd.is_sig_agent = 0;
              		if (isLeader)
              			stateUpd.is_manager = 0;
              	}

            		if (Object.keys(stateUpd).length > 0)
            			this.setState({type: {...this.state.type, ...stateUpd}});
              }}
              required
            />
            {this._renderErrorField('is_manager')}
          </MDBCol>
          <MDBCol size="12" md="6">
            <MDBInput
              label="Training Type Order"
              name="type_order"
              value={type.type_order}
              onChange={this._onChange}
              required
            />
            {this._renderErrorField('type_order')}
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12">
            <MDBBtn
              className="btn-block"
              color="unique"
              type="button"
              onClick={() => this._onSubmit()}
            >
              {saving ? 'Saving...' : 'Save'}
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default TrainingTypeForm
