export const fetchReportData = async (Calc, Provider) => {
  const currSearch = { search: Calc.getCurrSearch() },
    prevSearch = { search: Calc.getPrevSearch() }

  console.log('Fetching report widget by dates ', {
    curr: currSearch,
    prev: prevSearch,
  })

  const [current, previous] = await Promise.all([
    Provider.search(currSearch),
    Provider.search(prevSearch),
  ])

  const metrics = [
      'points',
      'enrolled',
      'aca_lives',
      'ma_lives',
      'av',
      'life_av',
    ],
    timespans = ['wtd', 'mtd', 'qtd', 'ytd'],
    growth = {}

  timespans.forEach((timespan) => {
    metrics.forEach((metric) => {
      if (!growth.hasOwnProperty(timespan)) growth[timespan] = {}

      let p = 0,
        c = 0

      try {
        p = previous[timespan][metric]
        p = p !== null && !isNaN(p) ? parseFloat(p) : 0
        c = current[timespan][metric]
        c = c !== null && !isNaN(c) ? parseFloat(c) : 0
      } catch (ex) {}

      growth[timespan][metric] = c === 0 && p === 0 ? 0 : (c - p) / p
    })
  })

  return { current, previous, growth }
}
