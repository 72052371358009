import BaseModelFactory from './base.factory'
import AgentPackagesFeaturesService from './../services/AgentPackagesFeatures.service'
import AgentPackagesFeatures from './../models/agent-packages-features.model'

class AgentPackagesFeaturesFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: AgentPackagesFeaturesService, Model: AgentPackagesFeatures }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: AgentPackagesFeaturesService,
      Model: AgentPackagesFeatures,
    })
}

export default AgentPackagesFeaturesFactory
