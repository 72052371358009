import BaseModelFactory from './base.factory'
import UserExternalCarrierService from './../services/UserExternalCarrier.service'
import UserExternalCarrier from './../models/user-external-carrier.model'

class UserExternalCarrierFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: UserExternalCarrierService, Model: UserExternalCarrier }
    )
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: UserExternalCarrierService, Model: UserExternalCarrier }
    )
  static findAllByUserId = async (id) =>
    await this._findAllBy(
      { user_id: id },
      { Service: UserExternalCarrierService, Model: UserExternalCarrier }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: UserExternalCarrierService,
      Model: UserExternalCarrier,
    })
}

export default UserExternalCarrierFactory
