/*
 * --------------------------------------------------------
 * Define Core Model Properties
 * --------------------------------------------------------
 */
const ModelDefinitions = {
  AgentPackage: {
    permitted: [
      'id',
      'price_ids',
      'price_descriptor',
      'pkg_name',
      'pkg_descriptor',
      'can_enroll',
      'mgr_only',
      'position',
      'usertype_id',
      'created_at',
      'updated_at',
    ],
    required: ['price_ids', 'price_descriptor', 'pkg_name', 'pkg_descriptor'],
    fillable: [
      'price_ids',
      'price_descriptor',
      'pkg_name',
      'pkg_descriptor',
      'can_enroll',
      'mgr_only',
      'position',
      'usertype_id',
    ],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'AgentPackageService',
  },
  AgentPackageFeature: {
    permitted: ['id', 'feature', 'position'],
    required: ['feature'],
    fillable: ['feature', 'position'],
    timestamps: false,
    id: 'id',
    user_id: false,
    service: 'AgentPackageFeatureService',
  },
  AgentPackagesFeatures: {
    permitted: ['pkg_id', 'feature_id'],
    required: ['pkg_id', 'feature_id'],
    fillable: ['pkg_id', 'feature_id'],
    timestamps: false,
    id: 'id',
    user_id: false,
    service: 'AgentPackagesFeaturesService',
  },
  Av: {
    permitted: [
      'av_id',
      'av_group_id',
      'user_id',
      'carrier_id',
      'coverage_id',
      'leadsource_id',
      'sub_agent_id',
      'r_partner_id',
      'pts_override_id',
      'av_leadsource',
      'av_user',
      'av_sub_agent',
      'av_coverage',
      'av_carrier',
      'av_product',
      'av_date',
      'av_eff_date',
      'av_client',
      'av_client_email',
      'av_client_phone',
      'av_state',
      'av_type',
      'av_fee',
      'av_premium',
      'av_term_length',
      'av_points',
      'lives',
      'av_appid',
      'av_comments',
      'av_status',
      'av_created',
      'av_updated',
      'selling_agent_id',
    ],
    required: [
      'user_id',
      'carrier_id',
      'coverage_id',
      'leadsource_id',
      'av_date',
      'av_client',
      'av_client_email',
      'av_client_phone',
      'av_state',
      'av_type',
      'av_fee',
      'av_premium',
      'av_term_length',
      'av_points',
      'lives',
      'av_appid',
      'av_status',
    ],
    fillable: [
      'av_group_id',
      'user_id',
      'carrier_id',
      'coverage_id',
      'leadsource_id',
      'sub_agent_id',
      'r_partner_id',
      'pts_override_id',
      'av_date',
      'av_eff_date',
      'av_client',
      'av_client_email',
      'av_client_phone',
      'av_state',
      'av_type',
      'av_fee',
      'av_premium',
      'av_term_length',
      'lives',
      'av_appid',
      'av_comments',
      'av_status',
      'selling_agent_id',
    ],
    timestamps: false,
    id: 'av_id',
    user_id: 'user_id',
    service: 'AvService',
  },
  BATeam: {
    permitted: ['id', 'owner_id', 'team_name', 'team_logo'],
    required: ['owner_id', 'team_name', 'team_logo'],
    fillable: ['id', 'owner_id', 'team_name', 'team_logo'],
    timestamps: true,
    id: 'id',
    user_id: 'owner_id',
    service: 'BATeamService',
  },
  Carrier: {
    permitted: [
      'id',
      'fmo_id',
      'sibling_ids',
      'c_name',
      'c_term_length',
      'c_term_length_min',
      'c_term_length_max',
      'c_img',
      'c_description',
      'requires_med_adv',
      'requires_ffm',
      'requires_aml',
      'is_automated',
      'contracting_instructions',
      'c_active',
      'visibility',
    ],
    required: [],
    fillable: [],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'CarrierService',
  },
  CarrierContractLink: {
    permitted: [
      'id',
      'carrier_id',
      'user_id',
      'commission_id',
      'document_id',
      'link',
      'mode',
      'created_at',
      'updated_at',
    ],
    required: [],
    fillable: [
      'carrier_id',
      'user_id',
      'commission_id',
      'document_id',
      'link',
      'mode',
    ],
    timestamps: true,
    id: 'id',
    user_id: 'user_id',
    service: 'CarrierContractLinkService',
  },
  CarrierFmo: {
    permitted: ['id', 'fmo_name', 'contracting_instructions'],
    required: [],
    fillable: [],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'CarrierFmoService',
  },
  CarrierPriority: {
    permitted: [
      'id',
      'carrier_id',
      'spec',
      'is_affiliate_core',
      'is_affiliate_preferred',
      'is_associate_core',
      'is_associate_preferred',
      'is_signature_core',
      'is_signature_preferred',
    ],
    required: ['carrier_id', 'spec'],
    fillable: [
      'carrier_id',
      'spec',
      'is_affiliate_core',
      'is_affiliate_preferred',
      'is_associate_core',
      'is_associate_preferred',
      'is_signature_core',
      'is_signature_preferred',
    ],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'CarrierPriorityService',
  },
  CommissionLevel: {
    permitted: [
      'com_name',
      'com_code',
      'com_rate',
      'com_url',
      'com_schedule',
      'can_enroll',
    ],
    required: [],
    fillable: [
      'com_name',
      'com_code',
      'com_rate',
      'com_url',
      'com_schedule',
      'can_enroll',
      'created_at',
      'updated_at',
    ],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'CommissionLevelService',
  },
  Contact: {
    permitted: [
      'id',
      'location_id',
      'contact_name',
      'first_name',
      'last_name',
      'email',
      'phone',
      'dnd',
      'type',
      'source',
      'created_at',
      'updated_at',
    ],
    required: ['contact_name'],
    fillable: ['contact_name'],
    timestamps: false,
    id: 'id',
    user_id: false,
    service: 'ContactService',
  },
  Coverage: {
    permitted: [
      'id',
      'coverage_name',
      'coverage_category',
      'coverage_active',
      'coverage_incentive',
      'track_av',
      'track_lives',
      'track_med_adv',
      'created_at',
      'updated_at',
    ],
    required: [],
    fillable: [],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'CoverageService',
  },
  Division: {
    permitted: [
      'id',
      'd_name',
      'd_owner_id',
      'd_logo',
      'd_address1',
      'd_address2',
      'd_city',
      'd_state',
      'd_zip',
      'd_email',
      'd_phone',
      'd_fax',
      'created_at',
      'updated_at',
    ],
    required: [],
    fillable: [],
    timestamps: true,
    id: 'id',
    user_id: 'd_owner_id',
    service: 'DivisionService',
  },
  KnowledgebaseArticle: {
    permitted: [
      'id',
      'category_id',
      'author_id',
      'status',
      'subject',
      'question',
      'content',
      'keywords',
      'updated_at',
      'created_at',
    ],
    required: [
      'category_id',
      'author_id',
      'status',
      'subject',
      'question',
      'content',
    ],
    fillable: [
      'category_id',
      'author_id',
      'status',
      'subject',
      'question',
      'content',
    ],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'KnowledgebaseArticleService',
  },
  KnowledgebaseCategory: {
    permitted: ['id', 'c_name', 'updated_at', 'created_at'],
    required: ['c_name'],
    fillable: ['c_name'],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'KnowledgebaseCategoryService',
  },
  LeaderGoalCard: {
    permitted: [
      'id',
      'current_total_points',
      'current_total_enrolled',
      'current_total_completed',
      'current_total_converted',
      'current_total_sig_agents',
      'previous_total_points',
      'previous_monthly_goal_points',
      'previous_yearly_goal_points',
      'previous_total_enrolled',
      'previous_monthly_goal_enrolled',
      'previous_yearly_goal_enrolled',
      'previous_total_completed',
      'previous_monthly_goal_completed',
      'previous_yearly_goal_completed',
      'previous_total_converted',
      'previous_monthly_goal_converted',
      'previous_yearly_goal_converted',
      'previous_total_sig_agents',
      'previous_monthly_goal_sig_agents',
      'previous_yearly_goal_sig_agents',
    ],
    required: [],
    fillable: [],
    timestamps: false,
    id: 'id',
    user_id: false,
    service: 'LeaderGoalCardService',
  },
  LeaderReportCard: {
    permitted: [
      'id',
      'source_id',
      'source_type',
      'source_name',
      'source_is_10x',
      'usertype_id',
      'start_at',
      'end_at',
      'timespan_ind',
      'prev_points',
      'curr_points',
      'growth_points',
      'prev_enrolled',
      'curr_enrolled',
      'growth_enrolled',
      'prev_completed',
      'curr_completed',
      'growth_completed',
      'prev_converted',
      'curr_converted',
      'growth_converted',
      'prev_conversion',
      'curr_conversion',
      'growth_conversion',
      'prev_active',
      'curr_active',
      'growth_active',
      'prev_signature',
      'curr_signature',
      'growth_signature',
      'prev_pending',
      'curr_pending',
      'growth_pending',
      'prev_writing',
      'curr_writing',
      'growth_writing',
      'created_at',
      'updated_at',
    ],
    required: [],
    fillable: [],
    timestamps: true,
    id: 'id',
    user_id: 'source_id',
    service: 'LeaderReportCardService',
  },
  LeadCreditPackage: {
    permitted: [
      'id',
      'lead_vendor_id',
      'name',
      'description',
      'links',
      'is_published',
      'is_open_amount',
      'package_url',
      'deleted_at',
      'created_at',
      'updated_at',
    ],
    required: [],
    fillable: [],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'LeadCreditPackageService',
  },
  LeadCreditQuantityPrice: {
    permitted: [
      'id',
      'package_id',
      'quantity',
      'price',
      'deleted_at',
      'created_at',
      'updated_at',
    ],
    required: ['package_id', 'quantity', 'price'],
    fillable: [],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'LeadCreditQuantityPriceService',
  },
  LeadSource: {
    permitted: ['id', 'ls_name', 'created_at', 'updated_at'],
    required: [],
    fillable: [],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'LeadSourceService',
  },
  LeadVendor: {
    permitted: [
      'id',
      'vendor_name',
      'manager_name',
      'manager_email',
      'notes',
      'carrier_id',
      'fmo_id',
      'options',
      'deleted_at',
    ],
    required: [],
    fillable: ['vendor_name', 'manager_name', 'manager_email', 'deleted_at'],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'LeadVendorService',
  },
  Ledger: {
    permitted: [
      'id',
      'user_id',
      'balance',
      'private_notes',
      'public_notes',
      'updated_at',
      'created_at',
    ],
    required: [],
    fillable: [],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'LedgerService',
  },
  LedgerTransaction: {
    permitted: [
      'id',
      'ledger_id',
      'purpose',
      'category',
      'type',
      'trans_state',
      'debit',
      'credit',
      'balance',
      'notes',
      'files',
      'doner_id',
      'carrier_id',
      'fmo_id',
      'price_id', // `price_id` is not in the `ledger_transactions` schema.
      'credit_id',
      'vendor_id',
      'event_log_id',
      'forwarding_id',
      'invoice_id',
      'is_expire',
      'transact_at',
      'updated_at',
      'created_at',
      'can_override', // this is a temp/dynamic field that is used during CRUD, BUT DOES NOT PERSIST.
      'upline_id', // this is a temp/dynamic field that is used during CRUD, BUT DOES NOT PERSIST.
      'source', // this is a temp/dynamic field that is used during CRUD, BUT DOES NOT PERSIST.
      'lead_order_state', // this is a temp/dynamic field that is used during CRUD, BUT DOES NOT PERSIST.
    ],
    required: [],
    fillable: [
      'ledger_id',
      'purpose',
      'category',
      'debit',
      'credit',
      'notes',
      'files',
      'doner_id',
      'carrier_id',
      'fmo_id',
      'credit_id',
      'vendor_id',
      'event_log_id',
      'forwarding_id',
      'invoice_id',
      'transact_at',
    ],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'LedgerTransactionService',
  },
  LedgerTransactionRule: {
    permitted: [
      'id',
      'ledger_id',
      'carrier_id',
      'fmo_id',
      'lead_vendor_id',
      'category',
      'type',
      'is_enabled',
      'on_match',
      'enhanced',
      'deleted_at',
      'updated_at',
      'created_at',
    ],
    required: [],
    fillable: [],
    timestamps: false,
    id: 'id',
    user_id: false,
    service: 'LedgerTransactionRuleService',
  },
  Location: {
    permitted: [
      'id',
      'name',
      'first_name',
      'last_name',
      'email',
      'phone',
      'api_key',
      'created_at',
      'updated_at',
    ],
    required: [],
    fillable: [],
    timestamps: false,
    id: 'id',
    user_id: false,
    service: 'LocationService',
  },
  Opportunity: {
    permitted: ['id', 'name', 'status', 'created_at', 'updated_at'],
    required: [],
    fillable: [],
    timestamps: false,
    id: 'id',
    user_id: false,
    service: 'OpportunityService',
  },
  PointOverride: {
    permitted: [
      'id',
      'carrier_id',
      'coverage_id',
      'field',
      'label',
      'value',
      'points',
      'deleted_at',
      'updated_at',
      'created_at',
    ],
    required: ['field', 'label', 'value', 'points'],
    fillable: [
      'carrier_id',
      'coverage_id',
      'field',
      'label',
      'value',
      'points',
    ],
    timestamps: false,
    id: 'id',
    user_id: false,
    service: 'PointOverrideService',
  },
  PromotionCode: {
    permitted: [
      'id',
      'start_at',
      'end_at',
      'code',
      'created_by',
      'deleted_at',
      'updated_at',
      'created_at',
    ],
    required: ['code', 'created_by'],
    fillable: ['start_at', 'end_at', 'code', 'created_by', 'deleted_at'],
    timestamps: false,
    id: 'id',
    user_id: false,
    service: 'PromotionCodeService',
  },
  RecruitingLeadSource: {
    permitted: ['ls_name'],
    required: ['ls_name'],
    fillable: ['ls_name'],
    timestamps: false,
    id: 'id',
    user_id: false,
    service: 'RecruitingLeadSourceService',
  },
  ReferralPartner: {
    permitted: [
      'agent_id',
      'name',
      'company',
      'email',
      'phone',
      'products_sold',
      'w9_onfile',
      'is_licensed',
    ],
    required: ['agent_id', 'name', 'email', 'phone'],
    fillable: [
      'agent_id',
      'name',
      'company',
      'email',
      'phone',
      'products_sold',
      'w9_onfile',
      'is_licensed',
    ],
    timestamps: false,
    id: 'id',
    user_id: 'agent_id',
    service: 'ReferralPartnerService',
  },
  ReferralPartnerW9: {
    permitted: [
      'referral_partner_id',
      'w9_year',
      'received_at',
      'w9_filepath',
      'created_at',
      'updated_at',
    ],
    required: ['referral_partner_id', 'w9_year', 'received_at'],
    fillable: ['referral_partner_id', 'w9_year', 'received_at', 'w9_filepath'],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'ReferralPartnersW9Service',
  },
  Report: {
    permitted: ['id', 'version', 'report_name', 'report_slug'],
    required: [],
    fillable: [],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'ReportService',
  },
  ReportHistory: {
    permitted: [
      'id',
      'report_id',
      'start_at',
      'end_at',
      'timespan',
      'source_id',
      'source_type',
      'payload',
      'created_at',
      'updated_at',
    ],
    required: [],
    fillable: [],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'ReportHistoryService',
  },
  Subsite: {
    permitted: [
      'site_id',
      's_type',
      'agency_id',
      'user_id',
      's_branded',
      'theme_name',
      'stylesheet_id',
      'layout_id',
      's_logo',
      's_picture',
      's_agent_picture',
      'referral',
      's_powerdialer',
      's_iquote',
      's_quote_form',
      's_about',
      's_web_confrence_link',
      's_fulladdress',
      'quote_link_health',
      'quote_link_life',
      'quote_link_senior',
      'quote_link_aca',
      'rx_card_link',
      's_wc_type',
      's_title',
      's_marketing_video',
      's_meta_tags',
      's_meta_description',
      's_url',
      's_bb_url',
      's_local_url',
      's_recruiting_url',
      's_book_appt_link',
      's_email_override',
      's_phone_override',
      's_navigation',
      's_created',
      's_updated',
      's_appointment_picture',
      's_appointment_marketing_title',
      's_website_picture',
      's_website_picture_show',
      's_default_scheduler_time',
      'site_status',
    ],
    required: [],
    fillable: [
      's_type',
      'agency_id',
      's_branded',
      'theme_name',
      'stylesheet_id',
      'layout_id',
      's_logo',
      's_picture',
      's_agent_picture',
      'referral',
      's_powerdialer',
      's_iquote',
      's_quote_form',
      's_about',
      's_web_confrence_link',
      's_fulladdress',
      'quote_link_health',
      'quote_link_life',
      'quote_link_senior',
      'quote_link_aca',
      'rx_card_link',
      's_wc_type',
      's_title',
      's_marketing_video',
      's_meta_tags',
      's_meta_description',
      's_url',
      's_bb_url',
      's_local_url',
      's_recruiting_url',
      's_book_appt_link',
      's_email_override',
      's_phone_override',
      's_navigation',
      's_created',
      's_updated',
      's_appointment_picture',
      's_appointment_marketing_title',
      's_website_picture',
      's_website_picture_show',
      's_default_scheduler_time',
      'site_status',
    ],
    timestamps: false,
    id: 'site_id',
    user_id: 'user_id',
    service: 'SubsiteService',
  },
  SubsiteLink: {
    permitted: ['site_id', 'link_type', 'links'],
    required: ['site_id', 'link_type', 'links'],
    fillable: ['site_id', 'link_type', 'links'],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'SubsiteLinkService',
  },
  Term: {
    permitted: [
      'id',
      'term_name',
      'term_link',
      'term_slug',
      'active',
      'usertype_id',
      'is_enroll',
      'is_hidden',
      'is_leader',
      'is_10x',
      'created_at',
      'updated_at',
    ],
    required: ['term_name', 'term_link'],
    fillable: [
      'term_name',
      'term_link',
      'term_slug',
      'active',
      'usertype_id',
      'is_enroll',
      'is_hidden',
      'is_leader',
      'is_10x',
    ],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'TermService',
  },
  TermSet: {
    permitted: ['id', 'usertype_id', 'term_set', 'created_at', 'updated_at'],
    required: ['usertype_id', 'term_set'],
    fillable: ['usertype_id', 'term_set'],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'TermSetService',
  },
  UserUsertypes: {
    permitted: [
      'id',
      'user_id',
      'usertype_id',
      'changed_id',
      'changed_by',
      'changed_at',
      'created_at',
      'updated_at',
    ],
    required: ['user_id', 'usertype_id'],
    fillable: [],
    timestamps: true,
    id: 'id',
    user_id: 'user_id',
    service: 'UserUsertypesService',
  },
  Training: {
    permitted: [
      'id',
      'training_name',
      'training_link',
      'training_type_id',
      'training_order',
      'training_code',
      'is_required',
      'requires_quiz',
      'training_output',
      'training_filters',
      'created_at',
      'updated_at',
    ],
    required: [
      'id',
      'training_name',
      'training_link',
      'training_type_id',
      'training_order',
      'training_output',
    ],
    fillable: [
      'training_name',
      'training_link',
      'training_type_id',
      'training_order',
      'training_code',
      'is_required',
      'requires_quiz',
      'training_output',
      'training_filters',
    ],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'TrainingService',
  },
  TrainingQuiz: {
    permitted: ['id', 'training_id', 'deleted_at', 'created_at', 'updated_at'],
    required: ['training_id'],
    fillable: ['training_id', 'deleted_at'],
    timestamps: false,
    id: 'id',
    user_id: false,
    service: 'TrainingQuizService',
  },
  TrainingQuestion: {
    permitted: [
      'id',
      'quiz_id',
      'question',
      'deleted_at',
      'created_at',
      'updated_at',
    ],
    required: ['quiz_id', 'question'],
    fillable: ['quiz_id', 'question', 'deleted_at'],
    timestamps: false,
    id: 'id',
    user_id: false,
    service: 'TrainingQuestionService',
  },
  TrainingAnswer: {
    permitted: [
      'id',
      'question_id',
      'label',
      'is_correct',
      'deleted_at',
      'created_at',
      'updated_at',
    ],
    required: ['question_id', 'label', 'is_correct'],
    fillable: ['question_id', 'label', 'is_correct', 'deleted_at'],
    timestamps: false,
    id: 'id',
    user_id: false,
    service: 'TrainingAnswerService',
  },
  User: {
    permitted: [
      'id',
      'firebase_uid',
      'usertype_id',
      'commission_id',
      'npn',
      'prospect_id',
      'u_fname',
      'u_lname',
      'u_email',
      'u_company_email',
      'u_quote_email',
      'u_login',
      'u_password',
      'u_upline_id',
      'recruited_by',
      'signature',
      'u_birthday',
      'u_address1',
      'u_address2',
      'u_city',
      'u_state',
      'u_zip',
      'u_phone',
      'u_mobile',
      'u_tollfree',
      'u_fax',
      'u_marketing_title',
      'u_picture',
      'u_nickname',
      'profileflag',
      'u_timezone',
      'u_active',
      'u_lastlogin',
      'u_lastactivity',
      'u_notes',
      'u_notes_admin',
      'u_private_notes',
      'u_enrolled',
      'u_enroll_source',
      'u_enroll_source_detail',
      'u_devision',
      'has_onboarded',
      'can_recruit',
      'docusign_contracting',
      'completed_enroll',
      'created_at',
      'updated_at',
    ],
    required: [
      'usertype_id',
      'commission_id',
      'u_fname',
      'u_lname',
      'u_email',
      'u_password',
      'u_upline_id',
      'u_devision',
    ],
    fillable: [],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'UserService',
  },
  UserContractCart: {
    permitted: [
      'id',
      'user_id',
      'cart_status',
      'cart_onboarding',
      'created_at',
      'updated_at',
    ],
    required: ['user_id', 'cart_status', 'cart_onboarding'],
    fillable: ['user_id', 'cart_status', 'cart_onboarding'],
    timestamps: true,
    id: 'id',
    user_id: 'user_id',
    service: 'UserContractCartService',
  },
  UserContractCartItem: {
    permitted: [
      'id',
      'cart_id',
      'carrier_id',
      'user_contract_id',
      'linkbank_id',
      'commission_id',
      'carrier_commission_id',
      'item_group',
      'item_status',
      'link',
      'created_at',
      'updated_at',
    ],
    required: [
      'cart_id',
      'carrier_id',
      'commission_id',
      'item_group',
      'item_status',
    ],
    fillable: [],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'UserContractCartItemService',
  },
  UserContractCartMeta: {
    permitted: [
      'id',
      'cart_id',
      'meta_key',
      'meta_value',
      'created_at',
      'updated_at',
    ],
    required: ['cart_id', 'meta_key'],
    fillable: ['cart_id', 'meta_key', 'meta_value'],
    timestamps: true,
    id: 'id',
    user_id: null,
    service: 'UserContractCartMetaService',
  },
  UsersCarrierContract: {
    permitted: [
      'id',
      'user_id',
      'carrier_id',
      'contract_id',
      'agreement_id',
      'disposition',
      'agent_code',
      'pwk_sent_to_agent_at',
      'pwk_signed_at',
      'pwk_sent_to_carrier_at',
      'pwk_completed_at',
      'pwk_expired_at',
      'created_at',
      'updated_at',
    ],
    required: ['user_id', 'carrier_id'],
    fillable: [],
    timestamps: true,
    id: 'id',
    user_id: 'user_id',
    service: 'UsersCarrierContractService',
  },
  UserCertification: {
    permitted: [
      'id',
      'user_id',
      'certification',
      'effective_date',
      'expiration_date',
      'created_at',
      'updated_at',
    ],
    required: ['user_id', 'certification'],
    fillable: ['expiration_date'],
    timestamps: true,
    id: 'id',
    user_id: 'user_id',
    service: 'UserCertificationService',
  },
  UserDocument: {
    permitted: [
      'id',
      'user_id',
      'relation_id',
      'relation_model',
      'description',
      'filename',
      'filepath',
      'filemime',
      'params',
      'created_at',
      'updated_at',
    ],
    required: ['user_id'],
    fillable: ['user_id', 'relation_id', 'description', 'params'],
    timestamps: true,
    id: 'id',
    user_id: 'user_id',
    service: 'UserDocumentService',
  },
  UserEventLog: {
    permitted: [
      'id',
      'user_id',
      'event_type',
      'event_descrip',
      'related_id',
      'related_model',
      'payload',
      'created_at',
    ],
    required: ['user_id'],
    fillable: ['user_id'],
    timestamps: false,
    id: 'id',
    user_id: 'user_id',
    service: 'UserEventLogService',
  },
  UserExternalCarrier: {
    permitted: [
      'id',
      'user_id',
      'carrier_id',
      'carrier_name',
      'created_at',
      'updated_at',
    ],
    required: ['user_id'],
    fillable: ['user_id'],
    timestamps: true,
    id: 'id',
    user_id: 'user_id',
    service: 'UserExternalCarrierService',
  },
  UserMessage: {
    permitted: [
      'id',
      'user_id',
      'author_id',
      'channel',
      'message',
      'created_at',
      'updated_at',
    ],
    required: ['user_id', 'author_id', 'channel', 'message'],
    fillable: ['user_id', 'author_id', 'channel', 'message'],
    timestamps: true,
    id: 'id',
    user_id: 'user_id',
    service: 'UserMessageService',
  },
  UserMeta: {
    permitted: [
      'id',
      'user_id',
      'meta_key',
      'meta_value',
      'created_at',
      'updated_at',
    ],
    required: ['user_id', 'meta_key'],
    fillable: ['user_id', 'meta_key', 'meta_value'],
    timestamps: true,
    id: 'id',
    user_id: 'user_id',
    service: 'UserMetaService',
  },
  UserEmailHistory: {
    permitted: [
      'id',
      'user_id',
      'reference_id',
      'template_id',
      'recipients',
      'payload',
      'created_at',
      'updated_at',
    ],
    required: ['user_id', 'template_id', 'recipients', 'payload'],
    fillable: [
      'user_id',
      'reference_id',
      'template_id',
      'recipients',
      'payload',
    ],
    timestamps: true,
    id: 'id',
    user_id: 'user_id',
    service: 'UserEmailHistoryService',
  },
  UserTerm: {
    permitted: [
      'id',
      'user_id',
      'term_id',
      'userterm_term',
      'userterm_user',
      'userterm_file',
      'userterm_ip',
      'userterm_signature',
      'userterm_updated',
      'userterm_created',
      'updated_at',
    ],
    required: [
      'user_id',
      'term_id',
      'userterm_term',
      'userterm_user',
      'userterm_signature',
      'userterm_updated',
      'userterm_created',
      'updated_at',
    ],
    fillable: [
      'user_id',
      'term_id',
      'userterm_term',
      'userterm_user',
      'userterm_signature',
      'userterm_updated',
      'userterm_created',
      'updated_at',
    ],
    timestamps: true,
    id: null,
    user_id: 'user_id',
    service: 'UserTermService',
  },
  UserTrainingQuiz: {
    permitted: [
      'id',
      'quiz_id',
      'user_id',
      'answers',
      'passed',
      'deleted_at',
      'created_at',
      'updated_at',
    ],
    required: ['quiz_id', 'user_id', 'answers', 'passed'],
    fillable: ['quiz_id', 'user_id', 'answers', 'passed', 'deleted_at'],
    timestamps: false,
    id: 'id',
    user_id: false,
    service: 'UserTrainingQuizService',
  },
  Usertype: {
    permitted: [
      'id',
      'name',
      'displayname',
      'parent_id',
      'usertype_slug',
      'email_upgrade_id',
      'email_downgrade_id',
      'email_autodowngrade_id',
      'is_agent',
      'is_manager',
      'is_staff_manager',
      'is_staff',
      'is_pending',
      'has_mailbox',
      'has_downline',
      'can_recruit',
      'incentives',
      'subsite',
      'admin_area',
      'agency_info',
      'create_user',
      'usertypes',
      'commissions',
      'divisions',
      'carriers',
      'contracting',
      'products',
      'reports',
      'submit_av',
      'resources',
      'training',
      'contests',
      'terms',
      'calendars',
      'emails',
      'can_search',
      'sortorder',
    ],
    required: ['name', 'displayname', 'parent_id', 'usertype_slug'],
    fillable: [
      'name',
      'displayname',
      'parent_id',
      'usertype_slug',
      'email_upgrade_id',
      'email_downgrade_id',
      'email_autodowngrade_id',
      'is_agent',
      'is_manager',
      'is_staff_manager',
      'is_staff',
      'is_pending',
      'has_mailbox',
      'has_downline',
      'can_recruit',
      'incentives',
      'subsite',
      'admin_area',
      'agency_info',
      'create_user',
      'usertypes',
      'commissions',
      'divisions',
      'carriers',
      'contracting',
      'products',
      'reports',
      'submit_av',
      'resources',
      'training',
      'contests',
      'terms',
      'calendars',
      'emails',
      'can_search',
      'sortorder',
    ],
    timestamps: true,
    id: null,
    user_id: 'user_id',
    service: 'UsertypeService',
  },
  UserTermSet: {
    permitted: [
      'set_id',
      'user_id',
      'accepted_at',
      'accepted_by',
      'accepted_ip',
    ],
    required: [
      'set_id',
      'user_id',
      'accepted_at',
      'accepted_by',
      'accepted_ip',
    ],
    fillable: [],
    timestamps: true,
    id: null,
    user_id: 'user_id',
    service: 'UsersTermSetsService',
  },
  TermLength: {
    permitted: [
      'id',
      'coverage_id',
      'min_length',
      'max_length',
      'default_length',
    ],
    required: ['coverage_id'],
    fillable: [],
    timestamps: true,
    id: 'id',
    user_id: false,
    service: 'TermLengthsService',
  },
}

/*
 * --------------------------------------------------------
 * Define Model Relationships
 * --------------------------------------------------------
 */
ModelDefinitions.User.related = {
  // not in use, still prototyping.
  cart: {
    model: [ModelDefinitions.UserContractCart],
    child_id_field: 'user_id',
  },
}

const {
  AgentPackage,
  AgentPackageFeature,
  AgentPackagesFeatures,
  Av,
  BATeam,
  Carrier,
  CarrierContractLink,
  CarrierFmo,
  CarrierPriority,
  CommissionLevel,
  Contact,
  Coverage,
  Division,
  LeaderGoalCard,
  LeaderReportCard,
  LeadCreditPackage,
  LeadCreditQuantityPrice,
  LeadSource,
  LeadVendor,
  Ledger,
  LedgerTransaction,
  LedgerTransactionRule,
  KnowledgebaseArticle,
  KnowledgebaseCategory,
  Location,
  Opportunity,
  PointOverride,
  PromotionCode,
  RecruitingLeadSource,
  ReferralPartner,
  ReferralPartnerW9,
  Report,
  ReportHistory,
  Subsite,
  SubsiteLink,
  TermSet,
  Term,
  TrainingQuiz,
  TrainingQuestion,
  TrainingAnswer,
  UserUsertypes,
  Training,
  User,
  UserContractCart,
  UserContractCartItem,
  UserContractCartMeta,
  UserDocument,
  UsersCarrierContract,
  UserCertification,
  UserEventLog,
  UserExternalCarrier,
  UserMessage,
  UserMeta,
  UserTerm,
  UserTrainingQuiz,
  Usertype,
  UserTermSet,
  TermLength,
  UserEmailHistory,
} = ModelDefinitions
export {
  AgentPackage,
  AgentPackageFeature,
  AgentPackagesFeatures,
  Av,
  BATeam,
  Carrier,
  CarrierContractLink,
  CarrierFmo,
  CarrierPriority,
  CommissionLevel,
  Contact,
  Coverage,
  Division,
  LeaderGoalCard,
  LeaderReportCard,
  LeadCreditPackage,
  LeadCreditQuantityPrice,
  LeadSource,
  LeadVendor,
  Ledger,
  LedgerTransaction,
  LedgerTransactionRule,
  KnowledgebaseArticle,
  KnowledgebaseCategory,
  Location,
  Opportunity,
  PointOverride,
  PromotionCode,
  RecruitingLeadSource,
  ReferralPartner,
  ReferralPartnerW9,
  Report,
  ReportHistory,
  Subsite,
  SubsiteLink,
  Term,
  TrainingQuiz,
  TrainingQuestion,
  TrainingAnswer,
  UserUsertypes,
  TermSet,
  Training,
  User,
  UserContractCart,
  UserContractCartItem,
  UserContractCartMeta,
  UserDocument,
  UsersCarrierContract,
  UserCertification,
  UserEventLog,
  UserExternalCarrier,
  UserMessage,
  UserMeta,
  UserTerm,
  UserTrainingQuiz,
  Usertype,
  UserTermSet,
  TermLength,
  UserEmailHistory,
}
