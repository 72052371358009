import React from 'react'
import { observer } from 'mobx-react-lite'
import LeadPackageCard from './LeadPackageCard.component'
import UserProfileService from './../../../shared/services/UserProfile.service'
import AdminContentEditPopupComponent from './../../../components/adminShared/AdminContentEditPopupComponent'
import Store from './../store'
import {
  MDBIcon,
  MDBAlert,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCollapse,
  MDBBtn,
  MDBCol,
  MDBRow,
} from 'mdbreact'

import './LeadPackageCards.component.scss'

const LeadPackageCards = () => {
  const canEdit = UserProfileService.isA(
    ['agency-owner', 'system-admin', 'internal-admin'],
    true
  )

  const renderLeadOptions = (item, idx) => {
    return (
      <MDBCol
        size="12"
        sm="6"
        lg="4"
        key={'lead-opt-' + idx}
        className="lead-pkg-wrapper"
      >
        {canEdit && (
          <AdminContentEditPopupComponent
            type="RTE"
            iconPosition="top-right"
            iconStyle={{ transform: 'translate(-18px, 18px)' }}
            actionType="edit"
            pageId={item.id}
            color="white"
            callback={Store.init}
            canDelete={canEdit}
            isLink={true}
          />
        )}
        <LeadPackageCard leadPkgOption={item} />
      </MDBCol>
    )
  }

  const renderLeadVendorPackages = (Package, idx) => {
    return (
      <MDBCol
        size="12"
        sm="6"
        lg="4"
        key={`lc-pkg-${Package.id()}`}
        className="lead-pkg-wrapper"
      >
        {canEdit ? (
          <>
            <a
              className="position-absolute text--white"
              href={Package.getAdminUrl()}
              target="_BLANK"
              rel="noopener noreferrer"
              style={{
                top: 0,
                right: 0,
                zIndex: 999,
              }}
            >
              <i
                className="fa fa-edit cursor-pointer"
                style={{
                  transform: 'translate(-18px, 18px)',
                  top: 0,
                  right: 0,
                  zIndex: 999,
                }}
              ></i>
            </a>
          </>
        ) : (
          <></>
        )}
        <LeadPackageCard Package={Package} />
      </MDBCol>
    )
  }

  const render = () => {
    return (Store.LeadCreditPackages || [])
      .concat(Store.leadOptions || [])
      .sort((OptA, OptB) => {
        const a =
            (OptA && typeof OptA?.id === 'function' && OptA?.get('name')) ||
            OptA?.page_name,
          b =
            (OptB && typeof OptB?.id === 'function' && OptB?.get('name')) ||
            OptB?.page_name
        return `${a}`.localeCompare(`${b}`, 'en')
      })
      .map((Opt, idx) => {
        if (Opt && typeof Opt?.id === 'function')
          return renderLeadVendorPackages(Opt, idx)
        return renderLeadOptions(Opt, idx)
      })
  }

  return <MDBRow>{render()}</MDBRow>
}

export default observer(LeadPackageCards)
