import { makeAutoObservable } from 'mobx'
import { BATeamFactory, UserFactory } from './../../../shared/factories'
import { BehaviorSubject } from 'rxjs'

class BATeamsStore {
  constructor() {
    makeAutoObservable(this)
  }

  BATeam = null
  BATeams = []
  Agents = []

  isFetching = false
  showValidity = false

  isValid = (BATeam) =>
    !!BATeam.get('owner_id') &&
    !isNaN(BATeam.get('owner_id')) &&
    !!BATeam.get('team_name') &&
    !!BATeam.get('team_logo')

  canCreate = () =>
    this.BATeams.filter((BATeam) => !!BATeam.isNew()).length === 0

  fetch = async () => {
    this.isFetching = true

    try {
      let [BATeams, Agents] = await Promise.all([
        BATeamFactory.search({ pagination: false }),
        UserFactory.search({
          search: { usertype_id: [133, 224, 90, 38, 93] },
          pagination: false,
        }),
      ])

      this.BATeams = Array.isArray(BATeams) ? BATeams : []
      this.Agents = Array.isArray(Agents) ? Agents : []
    } catch (ex) {
      console.log(`${ex}`)
      this.BATeams = []
      this.Agents = []
    }

    this.isFetching = false
    return true
  }

  create = () => {
    const BATeam = BATeamFactory.create({
      team_logo: 'ba_teams/placeholder-logo.png',
    })
    this.BATeam = BATeam
    this.BATeams.push(BATeam)
  }

  end = () => {
    this.BATeam = null
    this.BATeams = []
    this.Agents = []

    this.isFetching = false
    this.isFetching = false
    this.showValidity = false
  }
}

export default new BATeamsStore()
