import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { MDBContainer, MDBBtn, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import { months } from './../../variables'
import LeaderboardsStore from './../../store/LeaderboardsPage.store'
import DatePicker from 'react-datepicker'
import './LeaderboardConfigurator.scss'

const today = moment()

const toHuman = (str) =>
  str
    ? typeof str === 'string'
      ? moment(str, 'YYYY-MM-DD').format('MMM DD, YYYY')
      : str
    : null

const customToOutputMode = (start, stop) => {
  let diff = moment(stop, 'YYYY-MM-DD').diff(
    moment(start, 'YYYY-MM-DD'),
    'days'
  )

  // Weekly: Shows mon, tues, weds, thurs, fri, sat, sun
  // Monthly: Shows total
  // Quarterly: Shows weekly total
  return diff < 8 ? 'weekly' : diff < 366 ? 'quarterly' : 'monthly'
  // we should add monthly totals.
}

const LeaderboardConfigurator = (props) => {
  // const boardTypes = ['points','av','lives','med_adv'];
  const boardTypes = [
    { type: 'points', label: 'Points', color: 'indigo' },
    { type: 'av', label: 'Annualized Volume (AV)', color: 'indigo' },
    { type: 'lives', label: 'ACA Lives', color: 'orange' },
    { type: 'med_adv', label: 'Med Advantage', color: 'purple' },
  ]
  const [config, setConfig] = useState({
    type: 'points',
    start: moment(today).startOf('isoweek').format('YYYY-MM-DD'),
    stop: moment(today).endOf('isoweek').format('YYYY-MM-DD'),
    categories: '',
    agent_type: 'user_id',
    output_mode: 'weekly',
  })
  const [customDates, setCustomDates] = useState({
    start: config.start,
    stop: config.stop,
  })

  const renderCurrentDatesHeader = () => {
    let interval = ''

    switch (config.output_mode) {
      case 'weekly':
        interval = `: Week ${moment(config.start, 'YYYY-MM-DD').isoWeek()}`
        break
      case 'monthly':
        interval = `: Month ${
          parseInt(moment(config.start, 'YYYY-MM-DD').month()) + 1
        }`
        break
      case 'quarterly':
        interval = `: Quarter ${moment(config.start, 'YYYY-MM-DD').quarter()}`
        break
      case 'yearly':
        interval = `: Year ${moment(config.start, 'YYYY-MM-DD').year()}`
        break
      case 'custom':
      default:
        return (
          <>
            <h4>Custom Date Reporting</h4>
            <h5>
              {toHuman(config.start)} thru {toHuman(config.stop)}
            </h5>
          </>
        )
    }

    return (
      <>
        <h4>
          {config.output_mode.charAt(0).toUpperCase()}
          {config.output_mode.substr(1)} Reporting{interval}
        </h4>
        <h5>
          {toHuman(config.start)} thru {toHuman(config.stop)}
        </h5>
      </>
    )
  }

  const customDatePickerFrom = () => {
    let customDateFrom = moment(customDates.start, 'YYYY-MM-DD').toDate()
    let Start = new Date('January 1, 1900 00:00:00')
    let End = new Date()
    let yearsRange = moment(End).diff(Start, 'years')
    let years = []
    for (let year = 0; year <= yearsRange; year++)
      years.push(Start.getFullYear() + year)
    years.reverse()

    return (
      <DatePicker
        className="form-control-plaintext"
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {'<'}
            </button>
            <select
              value={moment(date).format('YYYY')}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[parseInt(moment(date).format('M')) - 1]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              type="button"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {'>'}
            </button>
          </div>
        )}
        popperClassName="customDatepickerZIndex"
        dateFormat="MM/dd/yyyy"
        selected={customDateFrom}
        onChange={(date) =>
          // setConfig(config => ({...config, start: moment(date).format("YYYY-MM-DD")}))
          setCustomDates((customDates) => ({
            ...customDates,
            start: moment(date).format('YYYY-MM-DD'),
          }))
        }
      />
    )
  }

  const customDatePickerTo = () => {
    let customDateTo = moment(customDates.stop, 'YYYY-MM-DD').toDate(),
      Start = new Date('January 1, 1900 00:00:00'),
      End = new Date(),
      yearsRange = moment(End).diff(Start, 'years'),
      years = []

    for (let year = 0; year <= yearsRange; year++)
      years.push(Start.getFullYear() + year)
    years.reverse()

    return (
      <DatePicker
        className="form-control-plaintext"
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {'<'}
            </button>
            <select
              value={moment(date).format('YYYY')}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[parseInt(moment(date).format('M')) - 1]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              type="button"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {'>'}
            </button>
          </div>
        )}
        popperClassName="customDatepickerZIndex"
        dateFormat="MM/dd/yyyy"
        selected={customDateTo}
        onChange={(date) =>
          // setConfig(config => ({...config, stop: moment(date).format("YYYY-MM-DD")}))
          setCustomDates((customDates) => ({
            ...customDates,
            stop: moment(date).format('YYYY-MM-DD'),
          }))
        }
      />
    )
  }

  const getPreviousData = (change) => {
    if (LeaderboardsStore.isFetching) return

    switch (change) {
      case 'week':
        setConfig((config) => ({
          ...config,
          start: moment(config.start, 'YYYY-MM-DD')
            .subtract(1, 'weeks')
            .startOf('isoweek')
            .format('YYYY-MM-DD'),
          stop: moment(config.start, 'YYYY-MM-DD')
            .subtract(1, 'weeks')
            .endOf('isoweek')
            .format('YYYY-MM-DD'),
          output_mode: 'weekly',
        }))
        break
      case 'monthly':
        setConfig((config) => ({
          ...config,
          start: moment(config.start, 'YYYY-MM-DD')
            .subtract(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD'),
          stop: moment(config.start, 'YYYY-MM-DD')
            .subtract(1, 'months')
            .endOf('month')
            .format('YYYY-MM-DD'),
          output_mode: 'monthly',
        }))
        break
      case 'quarterly':
        setConfig((config) => ({
          ...config,
          start: moment(config.start, 'YYYY-MM-DD')
            .subtract(1, 'quarter')
            .startOf('quarter')
            .format('YYYY-MM-DD'),
          stop: moment(config.start, 'YYYY-MM-DD')
            .subtract(1, 'quarter')
            .endOf('quarter')
            .format('YYYY-MM-DD'),
          output_mode: 'quarterly',
        }))
        break
      case 'year':
        setConfig((config) => ({
          ...config,
          start: moment(config.start, 'YYYY-MM-DD')
            .subtract(1, 'year')
            .startOf('year')
            .format('YYYY-MM-DD'),
          stop: moment(config.start, 'YYYY-MM-DD')
            .subtract(1, 'year')
            .endOf('year')
            .format('YYYY-MM-DD'),
          output_mode: 'yearly',
        }))
        break
      default:
        break
    }
  }

  const getCurrentData = (change) => {
    if (LeaderboardsStore.isFetching) return

    switch (change) {
      case 'week':
        setConfig((config) => ({
          ...config,
          start: moment(today).startOf('isoweek').format('YYYY-MM-DD'),
          stop: moment(today).endOf('isoweek').format('YYYY-MM-DD'),
          output_mode: 'weekly',
        }))
        break
      case 'monthly':
        setConfig((config) => ({
          ...config,
          start: moment(today).startOf('month').format('YYYY-MM-DD'),
          stop: moment(today).endOf('month').format('YYYY-MM-DD'),
          output_mode: 'monthly',
        }))
        break
      case 'quarterly':
        setConfig((config) => ({
          ...config,
          start: moment(today).startOf('quarter').format('YYYY-MM-DD'),
          stop: moment(today).endOf('quarter').format('YYYY-MM-DD'),
          output_mode: 'quarterly',
        }))
        break
      case 'year':
        setConfig((config) => ({
          ...config,
          start: moment(today).startOf('year').format('YYYY-MM-DD'),
          stop: moment(today).endOf('year').format('YYYY-MM-DD'),
          output_mode: 'yearly',
        }))
        break
      default:
        break
    }
  }

  const getNextData = (change) => {
    if (LeaderboardsStore.isFetching) return

    switch (change) {
      case 'week':
        setConfig((config) => ({
          ...config,
          start: moment(config.stop, 'YYYY-MM-DD')
            .add(1, 'weeks')
            .startOf('isoweek')
            .format('YYYY-MM-DD'),
          stop: moment(config.stop, 'YYYY-MM-DD')
            .add(1, 'weeks')
            .endOf('isoweek')
            .format('YYYY-MM-DD'),
          output_mode: 'weekly',
        }))
        break
      case 'monthly':
        setConfig((config) => ({
          ...config,
          start: moment(config.stop, 'YYYY-MM-DD')
            .add(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD'),
          stop: moment(config.stop, 'YYYY-MM-DD')
            .add(1, 'months')
            .endOf('month')
            .format('YYYY-MM-DD'),
          output_mode: 'monthly',
        }))
        break
      case 'quarterly':
        setConfig((config) => ({
          ...config,
          start: moment(config.stop, 'YYYY-MM-DD')
            .add(1, 'quarter')
            .startOf('quarter')
            .format('YYYY-MM-DD'),
          stop: moment(config.stop, 'YYYY-MM-DD')
            .add(1, 'quarter')
            .endOf('quarter')
            .format('YYYY-MM-DD'),
          output_mode: 'quarterly',
        }))
        break
      case 'year':
        setConfig((config) => ({
          ...config,
          start: moment(config.stop, 'YYYY-MM-DD')
            .add(1, 'year')
            .startOf('year')
            .format('YYYY-MM-DD'),
          stop: moment(config.stop, 'YYYY-MM-DD')
            .add(1, 'year')
            .endOf('year')
            .format('YYYY-MM-DD'),
          output_mode: 'yearly',
        }))
        break
      default:
        break
    }
  }

  const fetchCustom = () => {
    if (
      new Date().getTime() <
      moment(customDates.start, 'YYYY-MM-DD').toDate().getTime()
    )
      toast.error('Start date can not be in the future.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    else if (
      moment(customDates.start, 'YYYY-MM-DD').toDate().getTime() >
      moment(customDates.stop, 'YYYY-MM-DD').toDate().getTime()
    )
      toast.error('End date can not be before start date.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    else
      setConfig((config) => ({
        ...config,
        output_mode: 'custom',
        stop: customDates.stop,
        start: customDates.start,
      }))
  }

  const _toggleAgentType = (agent_type) =>
    !LeaderboardsStore.isFetching &&
    setConfig((config) => ({ ...config, agent_type }))

  useEffect(() => {
    if (!LeaderboardsStore.isFetching) {
      LeaderboardsStore.updAndFetch({
        type: config.type,
        start: config.start,
        stop: config.stop,
        categories: config.categories,
        agent_type: config.agent_type,
        output_mode:
          config.output_mode === 'custom'
            ? customToOutputMode(config.start, config.stop)
            : config.output_mode,
      })
    }
  }, [
    config.type,
    config.start,
    config.stop,
    config.categories,
    config.agent_type,
    config.output_mode,
  ])

  toast.configure()

  return (
    <>
      <MDBContainer id="LeaderboardConfigurator" fluid>
        <MDBRow className="leaderboard-links">
          <MDBCol lg="6" className="float-left">
            <MDBRow>
              <MDBCol size="12" md="8">
                {renderCurrentDatesHeader()}
              </MDBCol>
              <MDBCol size="6" md="2" className="agent-type-selector">
                <MDBBtn
                  block
                  disabled={config?.agent_type === 'ba_team_id'}
                  onClick={() => _toggleAgentType('ba_team_id')}
                  type="button"
                >
                  BA Teams&nbsp;
                  {config?.agent_type === 'ba_team_id' ? (
                    <MDBIcon icon="check-circle" />
                  ) : (
                    <MDBIcon far icon="circle" />
                  )}
                </MDBBtn>
                <MDBBtn
                  block
                  disabled={config?.agent_type === 'user_id'}
                  onClick={() => _toggleAgentType('user_id')}
                  type="button"
                >
                  Sig Agents&nbsp;
                  {config?.agent_type === 'user_id' ? (
                    <MDBIcon icon="check-circle" />
                  ) : (
                    <MDBIcon far icon="circle" />
                  )}
                </MDBBtn>
                <MDBBtn
                  block
                  disabled={config?.agent_type === 'sub_agent_id'}
                  onClick={() => _toggleAgentType('sub_agent_id')}
                  type="button"
                >
                  CSR/BAs&nbsp;
                  {config?.agent_type === 'sub_agent_id' ? (
                    <MDBIcon icon="check-circle" />
                  ) : (
                    <MDBIcon far icon="circle" />
                  )}
                </MDBBtn>
              </MDBCol>
              <MDBCol size="6" md="2" className="agent-type-selector">
                <MDBBtn
                  block
                  disabled={config?.agent_type === 'district'}
                  onClick={() => _toggleAgentType('district')}
                  type="button"
                >
                  District Teams&nbsp;
                  {config?.agent_type === 'district' ? (
                    <MDBIcon icon="check-circle" />
                  ) : (
                    <MDBIcon far icon="circle" />
                  )}
                </MDBBtn>
                <MDBBtn
                  block
                  disabled={config?.agent_type === 'region'}
                  onClick={() => _toggleAgentType('region')}
                  type="button"
                >
                  Region Teams&nbsp;
                  {config?.agent_type === 'region' ? (
                    <MDBIcon icon="check-circle" />
                  ) : (
                    <MDBIcon far icon="circle" />
                  )}
                </MDBBtn>
                <MDBBtn
                  block
                  disabled={config?.agent_type === 'division'}
                  onClick={() => _toggleAgentType('division')}
                  type="button"
                >
                  Division Teams&nbsp;
                  {config?.agent_type === 'division' ? (
                    <MDBIcon icon="check-circle" />
                  ) : (
                    <MDBIcon far icon="circle" />
                  )}
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            <MDBRow className="type-btns">
              {boardTypes
                .sort((typeA, typeB) =>
                  `${typeA.label}`.localeCompare(`${typeB.label}`, 'en')
                )
                .filter(
                  (boardType) =>
                    boardType.type !== LeaderboardsStore.fetchParams.type
                )
                .map((boardType, i) => (
                  <MDBCol
                    size="12"
                    sm="4"
                    key={
                      `${boardType.type}`.replace(/_/g, '-') + '-leaderboard'
                    }
                  >
                    <MDBBtn
                      disabled={LeaderboardsStore.isFetching}
                      onClick={() =>
                        setConfig((config) => ({
                          ...config,
                          type: boardType.type,
                        }))
                      }
                      className={`btn board-type-btn btn-block btn-${boardType.color}`}
                    >
                      {boardType.label}
                    </MDBBtn>
                  </MDBCol>
                ))}
            </MDBRow>
            <MDBRow className="filter-btns">
              <MDBCol sm="4">
                <MDBBtn
                  className={
                    'btn-red btn-block ' +
                    (config.categories === 'HEALTH' ? ' btn-active' : '')
                  }
                  disabled={LeaderboardsStore.isFetching}
                  onClick={() =>
                    !LeaderboardsStore.isFetching &&
                    setConfig((config) => ({
                      ...config,
                      categories:
                        config.categories === 'HEALTH' ? '' : 'HEALTH',
                    }))
                  }
                >
                  <MDBIcon icon="check" />
                  HEALTH
                </MDBBtn>
              </MDBCol>
              <MDBCol sm="4">
                <MDBBtn
                  className={
                    'btn-green btn-block ' +
                    (config.categories === 'LIFE' ? ' btn-active' : '')
                  }
                  disabled={LeaderboardsStore.isFetching}
                  onClick={() =>
                    !LeaderboardsStore.isFetching &&
                    setConfig((config) => ({
                      ...config,
                      categories: config.categories === 'LIFE' ? '' : 'LIFE',
                    }))
                  }
                >
                  <MDBIcon icon="check" />
                  LIFE
                </MDBBtn>
              </MDBCol>
              <MDBCol sm="4">
                <MDBBtn
                  className={
                    'btn-blue btn-block ' +
                    (config.categories === 'SENIOR' ? ' btn-active' : '')
                  }
                  disabled={LeaderboardsStore.isFetching}
                  onClick={() =>
                    !LeaderboardsStore.isFetching &&
                    setConfig((config) => ({
                      ...config,
                      categories:
                        config.categories === 'SENIOR' ? '' : 'SENIOR',
                    }))
                  }
                >
                  <MDBIcon icon="check" />
                  SENIOR
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol lg="6" className="float-right">
            <h4>Custom Timeframe</h4>
            <form>
              <MDBRow className="custom-datepicker">
                <MDBCol>
                  <label className="grey-text">From</label>
                  <div>{customDatePickerFrom()}</div>
                </MDBCol>
                <MDBCol>
                  <label className="grey-text">To</label>
                  <div>{customDatePickerTo()}</div>
                </MDBCol>
              </MDBRow>
              <MDBBtn
                disabled={LeaderboardsStore.isFetching}
                color="indigo"
                className="btn-block"
                onClick={() => fetchCustom()}
              >
                Submit
              </MDBBtn>
            </form>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-5">
          <MDBCol lg="4" className="text-center">
            <h5>Previous</h5>
            <MDBBtn
              tag="a"
              flat
              size="sm"
              className="p-0 pr-2 text-primary"
              onClick={() => {
                getPreviousData('week')
              }}
            >
              Week
            </MDBBtn>{' '}
            |
            <MDBBtn
              tag="a"
              flat
              size="sm"
              className="p-0 pl-2 pr-2 text-primary"
              onClick={() => {
                getPreviousData('monthly')
              }}
            >
              Month
            </MDBBtn>{' '}
            |
            <MDBBtn
              tag="a"
              flat
              size="sm"
              className="p-0 pl-2 pr-2 text-primary"
              onClick={() => {
                getPreviousData('quarterly')
              }}
            >
              Quarter
            </MDBBtn>{' '}
            |
            <MDBBtn
              tag="a"
              flat
              size="sm"
              className="p-0 pl-2 text-primary"
              onClick={() => {
                getPreviousData('year')
              }}
            >
              Year
            </MDBBtn>
          </MDBCol>
          <MDBCol lg="4" className="text-center">
            <h5>Current</h5>
            <MDBBtn
              tag="a"
              flat
              size="sm"
              className="p-0 pr-2 text-primary"
              onClick={() => {
                getCurrentData('week')
              }}
            >
              Week
            </MDBBtn>{' '}
            |
            <MDBBtn
              tag="a"
              flat
              size="sm"
              className="p-0 pl-2 pr-2 text-primary"
              onClick={() => {
                getCurrentData('monthly')
              }}
            >
              Month
            </MDBBtn>{' '}
            |
            <MDBBtn
              tag="a"
              flat
              size="sm"
              className="p-0 pl-2 pr-2 text-primary"
              onClick={() => {
                getCurrentData('quarterly')
              }}
            >
              Quarter
            </MDBBtn>{' '}
            |
            <MDBBtn
              tag="a"
              flat
              size="sm"
              className="p-0 pl-2 text-primary"
              onClick={() => {
                getCurrentData('year')
              }}
            >
              Year
            </MDBBtn>
          </MDBCol>
          <MDBCol lg="4" className="text-center">
            <h5>Next</h5>
            <MDBBtn
              tag="a"
              flat
              size="sm"
              className="p-0 pr-2 text-primary"
              onClick={() => {
                getNextData('week')
              }}
            >
              Week
            </MDBBtn>{' '}
            |
            <MDBBtn
              tag="a"
              flat
              size="sm"
              className="p-0 pl-2 pr-2 text-primary"
              onClick={() => {
                getNextData('monthly')
              }}
            >
              Month
            </MDBBtn>{' '}
            |
            <MDBBtn
              tag="a"
              flat
              size="sm"
              className="p-0 pl-2 pr-2 text-primary"
              onClick={() => {
                getNextData('quarterly')
              }}
            >
              Quarter
            </MDBBtn>{' '}
            |
            <MDBBtn
              tag="a"
              flat
              size="sm"
              className="p-0 pl-2 text-primary"
              onClick={() => {
                getNextData('year')
              }}
            >
              Year
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  )
}

export default observer(LeaderboardConfigurator)
