import React, { Component } from 'react'
import {
  MDBBtn,
  MDBContainer,
  MDBDataTable,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from 'mdbreact'
import DashboardLayout from '../components/Admin/DashboardLayout'
import AdminApi from '../api/admin-api/admin-api'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'

class AdminDashboardContactsPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      toggleStateA: false,
      status: 'Open',
      modal: false,
      viewdata: '',
      contactList: [],
      data: {
        columns: [
          {
            label: 'Name',
            field: 'name',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Email',
            field: 'email',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Submitted',
            field: 'submitted',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'View',
            field: 'view',
            sort: 'disabled',
            width: 270,
          },
        ],
        rows: [],
      }, //data
    } //state
  } //constructor

  componentDidMount() {
    this.fetchContactData()
  }

  handleToggleClickA = () => {
    this.setState({
      toggleStateA: !this.state.toggleStateA,
    })
  }

  toDateTime = (secs) => {
    let t = new Date(1970, 0, 1) // Epoch
    t.setSeconds(secs)
    return t
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal })
  }

  fetchContactData = async () => {
    const { status } = this.state
    this.setState({ loading: true })
    AdminApi.getContactRequest(status === 'Open')
      .then((result) => {
        this.setState({ contactList: result.data.data || [] })
      })
      .then(() => {
        this.setState({ loading: false })
        this.setRows()
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  setRows = () => {
    const { contactList } = this.state
    if (contactList && contactList.length) {
      let rows = contactList.map((row) => {
        const { email, created_at, firstname } = row
        // const submittedDate = created_at && this.toDateTime(created_at._seconds).toString();
        const submittedDate = (
          <span
            searchvalue={
              created_at ? moment(created_at).format('YYYY/MM/DD') : ''
            }
          >
            {created_at ? moment(created_at).format('MM/DD/YYYY') : 'N/A'}
          </span>
        )
        const self = this

        function data() {
          self.toggle()
          self.setState({ viewdata: row })
          // console.log('viewdata--->', row);
        }

        const viewClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={data}
            >
              <i className={'fa fa-eye fa-2x blue-text p-1'}></i>
            </div>
          </div>
        )
        return {
          name: firstname ? (
            <span searchvalue={firstname.trim()}>{firstname}</span>
          ) : (
            <span searchvalue="">N/A</span>
          ),
          email: email ? email : 'N/A',
          submitted: submittedDate,
          view: viewClick,
        }
      })
      this.setState({
        data: { ...this.state.data, rows: rows },
      })
    }
  }

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  onStatusChange = (e) => {
    e.persist()
    this.setState((prevState) => {
      let viewdata = Object.assign({}, prevState.viewdata)
      viewdata.status = e.target.value
      return { viewdata }
    })
  }

  onNotesChange = (e) => {
    e.persist()
    this.setState((prevState) => {
      let viewdata = Object.assign({}, prevState.viewdata)
      viewdata.notes = e.target.value
      return { viewdata }
    })
  }

  Update_contact = async (event) => {
    event.preventDefault()
    this.setState({ updating: true })

    const { viewdata } = this.state
    var data = {
      notes: viewdata.notes,
      status: viewdata.status,
      id: viewdata.id,
      table_name: 'contact_requests',
    }

    let form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    await AdminApi.updateContactRequest(form_data).then((response) => {
      if (response.status === 200) {
        this.setState({ updating: false })
        toast.success('Contact successfully updated', {
          position: toast.POSITION.TOP_RIGHT,
        })
        this.fetchContactData()
        this.toggle()
      } else {
        this.setState({ updating: false })
        toast.error(`Something went wrong!`, {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    })

    /*const response = await fetch('https://us-central1-usabg-3ef10.cloudfunctions.net/updateContactRequest' , {
            method: 'post',
            body: data,
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
        if(response.status===200){
            this.setState({updating: false});
            toast.success("Contact successfully updated", {position: toast.POSITION.TOP_RIGHT});
            this.fetchContactData();
            this.toggle();
        }else{
            this.setState({updating: false});
            toast.error(`Something went wrong!`, {position: toast.POSITION.TOP_RIGHT});
        }*/
    // console.log('updated response->',response)
    /*const json = await response.json();
        if (json) {
            window.location.reload(false);
        }*/
  }

  Deletecontact = async () => {
    this.setState({ deleting: true })
    const { viewdata } = this.state

    var data = {
      type: 'contact_requests',
      id: viewdata.id,
    }

    await AdminApi.deleteContactRequest(data).then((response) => {
      if (response.status === 200) {
        this.setState({ deleting: false })
        toast.success('Contact successfully deleted', {
          position: toast.POSITION.TOP_RIGHT,
        })
        this.fetchContactData()
        this.toggle()
      } else {
        this.setState({ deleting: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    })

    /*const response = await fetch('https://us-central1-usabg-3ef10.cloudfunctions.net/deleteContactRequest' , {
            method: 'post',
            body: data,
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
        if(response.status===200){
            this.setState({deleting: false});
            toast.success("Contact successfully deleted", {position: toast.POSITION.TOP_RIGHT});
            this.fetchContactData();
            this.toggle();
        }else{
            this.setState({deleting: false});
            toast.error("Something went wrong!", {position: toast.POSITION.TOP_RIGHT});
        }*/
  }

  render() {
    toast.configure()
    const data = this.state.data
    const { modal, viewdata } = this.state

    const { status, loading, updating, deleting } = this.state
    return (
      <>
        <DashboardLayout>
          <main className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <h2>
                Contact Requests
                <div className="switch" style={{ float: 'right' }}>
                  <label style={{ fontSize: '16px' }}>
                    Closed Requests
                    <input
                      type="checkbox"
                      checked={status === 'Open'}
                      onChange={() => {
                        if (status === 'Open') {
                          this.setState({ status: 'Closed' }, () => {
                            this.fetchContactData()
                          })
                        } else {
                          this.setState({ status: 'Open' }, () => {
                            this.fetchContactData()
                          })
                        }
                      }}
                    />
                    <span className="lever"></span>
                    Open Requests
                  </label>
                </div>
              </h2>
              <hr />
              {loading ? (
                <h4 className={'p4'}>Loading...</h4>
              ) : (
                <div className="content-wrapper">
                  <MDBDataTable
                    sortRows={['name', 'submitted']}
                    order={['name', 'asc']}
                    striped
                    bordered
                    small
                    data={data}
                    className="text-capitalize"
                  />
                </div>
              )}
              <MDBModal isOpen={modal} toggle={this.toggle}>
                <MDBModalHeader toggle={this.toggle}>
                  Contact Request Info
                </MDBModalHeader>
                <MDBModalBody>
                  <form>
                    <div className="form-row">
                      <label>Status</label>
                      <select
                        className="form-control"
                        value={this.state.viewdata.status}
                        onChange={(event) => this.onStatusChange(event)}
                        name="status"
                      >
                        <option>Open</option>
                        <option>Closed</option>
                      </select>
                    </div>
                    <div className="form-row">
                      <label>Notes</label>
                      <textarea
                        className="form-control"
                        value={
                          this.state.viewdata.notes
                            ? this.state.viewdata.notes
                            : ''
                        }
                        onChange={(event) => this.onNotesChange(event)}
                        name="notes"
                      />
                    </div>
                    <div className="form-row">
                      <MDBBtn color="default" onClick={this.Update_contact}>
                        {updating ? 'Saving...' : 'Save'}
                      </MDBBtn>
                      <MDBBtn color="danger" onClick={this.Deletecontact}>
                        {deleting ? 'Deleting...' : 'Delete Request'}
                      </MDBBtn>
                    </div>
                  </form>
                  Name :{viewdata.firstname} {viewdata.lastname}
                  <br /> E-Mail :{viewdata.email}
                  <br /> Message : {viewdata.message}
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn color="secondary" onClick={this.toggle}>
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModal>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </>
    )
  }
}

export default AdminDashboardContactsPage
