import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  MDBIcon,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from 'mdbreact'
import appConstants from './../../constants/appConstants'
import { UIInput, UIInputButton, UISelect } from './../forms/form-fields'

import './DashboardSearch.scss'

const SearchStateOptions = appConstants.States.map((s) => ({
  value: s.id,
  text: s.name,
}))

const spokenLanguages = appConstants.spoken_languages.map((s) => ({
  value: s,
  text: s,
}))

const DashboardSearchComponent = ({ urlParams, permittedScopes, onSearch }) => {
  const urlSearchScope = (
    (urlParams && urlParams?.searchScope) ||
    ''
  ).toLowerCase()

  const [searchTerm, setSearchTerm] = useState('')
  const [searchScope, setSearchScope] = useState(urlSearchScope || 'users')

  const getPlaceholder = () => {
    if (searchScope === 'users') return 'Search Agent Names'
    if (searchScope === 'states') return 'Search Agent States Licenses'
    if (searchScope === 'languages') return 'Search Agent By Spoken Languages'
  }

  const _onSearchKeyDown = (evt) => {
    if (evt.key === 'Enter' && searchTerm.length > 0) performSearch(searchTerm)
  }

  const performSearch = (searchTerm) => {
    if (searchTerm) {
      onSearch =
        onSearch && typeof onSearch === 'function' ? onSearch : () => {}
      onSearch(`/${searchTerm}/search/${searchScope}`)
    }
  }

  useEffect(() => {
    if (searchTerm === 'English' && searchScope === 'languages') {
      performSearch(searchTerm)
    }
  }, [searchScope])

  const SearchSelector = () => {
    let label = ''
    if (searchScope === 'users') label = 'Agents'
    else if (searchScope === 'states')
      label = (
        <>
          States
          <br />
          Licenses
        </>
      )
    else if (searchScope === 'languages')
      label = (
        <>
          Spoken
          <br />
          Languages
        </>
      )

    return (
      <MDBDropdown>
        <MDBDropdownToggle>
          <table>
            <tbody>
              <tr>
                <td>{label}</td>
                <td>
                  <div>
                    <MDBIcon icon="search" />
                  </div>
                  <div>
                    <MDBIcon icon="caret-down" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </MDBDropdownToggle>
        <MDBDropdownMenu>
          <MDBDropdownItem
            onClick={() => {
              setSearchScope('users')
              setSearchTerm('')
            }}
          >
            Agents
          </MDBDropdownItem>
          <MDBDropdownItem
            onClick={() => {
              setSearchScope('states')
              setSearchTerm('')
            }}
          >
            State Licenses
          </MDBDropdownItem>
          <MDBDropdownItem
            onClick={() => {
              setSearchScope('languages')
              setSearchTerm('English')
            }}
          >
            Spoken Languages
          </MDBDropdownItem>
        </MDBDropdownMenu>
      </MDBDropdown>
    )
  }

  const SearchInput = () => {
    if (searchScope === 'users')
      return (
        <UIInput
          type="text"
          label={getPlaceholder()}
          className="placeholder-color-white"
          value={searchTerm}
          onKeyDown={_onSearchKeyDown}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ color: '#fff' }}
        />
      )
    else if (searchScope === 'states')
      return (
        <UISelect
          label={getPlaceholder()}
          className="placeholder-color-white"
          value={searchTerm}
          getValue={(e) => {
            e = e.shift()
            setSearchTerm(e)
            performSearch(e)
          }}
          options={SearchStateOptions.map((ST) => ({
            ...ST,
            checked: ST.value === searchTerm,
          }))}
          style={{ color: '#fff' }}
        />
      )
    else if (searchScope === 'languages')
      return (
        <UISelect
          label={getPlaceholder()}
          className="placeholder-color-white"
          value={searchTerm}
          getValue={(e) => {
            e = e.shift()
            setSearchTerm(e)
            performSearch(e)
          }}
          options={spokenLanguages.map((ST) => ({
            ...ST,
            checked: ST.value === searchTerm,
          }))}
          style={{ color: '#fff' }}
        />
      )
  }

  return (
    <div id="DashboardSearchComponent">
      <UIInputButton
        input={SearchInput()}
        button={SearchSelector()}
        btnPosition={'right'}
      />
    </div>
  )
}

export const DashboardSearch = observer(DashboardSearchComponent)
