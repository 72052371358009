import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  UIDatePickerInput,
  UIDropdown,
} from './../../../../components/forms/form-fields'
import LeaderReportStore from './../../store/LeaderReportCard.store'
import LeaderGoalStore from './../../store/LeaderGoalCard.store'
import { format } from 'date-fns'
import './LeaderDateSelector.scss'

const minDate = new Date(2022, 0, 1),
  maxDate = new Date()

const getDate = (reportDate) => {
  if (!reportDate) return new Date()

  if (typeof reportDate === 'object') return reportDate

  if (typeof reportDate === 'string')
    reportDate = reportDate.split(/(T| )/).shift()

  if (reportDate) {
    reportDate = reportDate.replace(/(-|\/)/g, ' ').split(' ')
    return new Date(
      reportDate.shift(),
      reportDate.shift() - 1,
      reportDate.shift()
    )
  }

  return new Date(reportDate)
}

const LeaderDateSelector = ({
  reportDate,
  isDisabled,
  onDateChange,
  viewMode,
}) => {
  const onPreviousYearChange = (v) => {
    if ((v = v ? v?.target?.value : false) && !isNaN(v)) {
      v = `${v}-${format(new Date(), 'MM-dd')}`
      if (LeaderGoalStore.goalDate !== v) {
        LeaderGoalStore.setGoalDate(v)
        onDateChange(`${LeaderGoalStore.goalDate}`)
      }
    }
  }

  const goalYear = LeaderGoalStore.getGoalYear(),
    reportYear = new Date().getFullYear(),
    years = Array.from(new Array(3), (v, i) => {
      return {
        text: reportYear - i,
        value: reportYear - i,
        checked: reportYear - i === parseInt(goalYear),
      }
    })

  return viewMode === 'goals' ? (
    <UIDropdown
      label="Goal Year"
      options={years}
      selected={parseInt(goalYear)}
      onChange={onPreviousYearChange}
      value={parseInt(goalYear)}
    />
  ) : (
    <UIDatePickerInput
      label={viewMode === 'goals' ? 'Goal Year' : 'Report Date'}
      name="report_date"
      id="lgr_report_date"
      showYearDropdown
      yearDropdownItemNumber={100}
      scrollableYearDropdown
      dateFormat={viewMode === 'goals' ? 'yyyy' : 'MM/dd/yyyy'}
      minDate={minDate}
      maxDate={maxDate}
      selected={getDate(reportDate)}
      onChange={(date) => {
        LeaderReportStore.setReportDate(date?.target?.value)
        onDateChange(`${LeaderReportStore.reportDate}`)
      }}
      todayButton="Today"
      disabled={isDisabled}
      popperPlacement="auto-left"
    />
  )
}

export default observer(LeaderDateSelector)
