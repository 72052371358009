import { makeAutoObservable } from 'mobx'
import MilestoneService from './../../shared/services/Milestone.service'
import { differenceInCalendarYears, format, parse } from 'date-fns'
import DateManager from './../../shared/utilities/DateManager.class'
import { getHeadshot } from './../../shared/utilities/getHeadshot.function'
import { convertToCurrencyWithoutSign } from './../../shared/formatters/convertToCurrencyWithoutSign.formatter'
import moment from 'moment'
import { getName } from '../../shared/utilities/getName.function'

class MilestoneStore {
  constructor() {
    makeAutoObservable(this)
  }

  milestones = {
    anniversary: null,
    bar_streak: null,
    birthday: null,
    first_bar: null,
    first_sale: null,
    agent_koth_weekly: null,
    agent_koth_yearly: null,
    ba_koth_weekly: null,
    ba_koth_yearly: null,
    streak: null,
    rick_ring_race: null,
    leader_ring_race: null,
    agent_ring_race: null,
    rick_ring_race_award: null,
    leader_ring_race_award: null,
    agent_ring_race_award: null,
    hof: null,
  }

  loading = {
    anniversary: null,
    bar_streak: null,
    birthday: null,
    first_bar: null,
    first_sale: null,
    agent_koth_weekly: null,
    agent_koth_yearly: null,
    ba_koth_weekly: null,
    ba_koth_yearly: null,
    streak: null,
    rick_ring_race: null,
    leader_ring_race: null,
    agent_ring_race: null,
    rick_ring_race_award: null,
    leader_ring_race_award: null,
    agent_ring_race_award: null,
    hof: null,
  }

  fetchMilestone = async (miType) => {
    let response
    if (!miType || !this.milestones.hasOwnProperty(miType)) return false

    this.milestones[miType] = false
    this.loading[miType] = true

    try {
      response = await MilestoneService.get(miType)
    } catch (ex) {
      console.error('FAILED MS: ', ex)
    }

    if (response && typeof response === 'object') {
      if (response.activity) {
        response.activity = DateManager.dateTimeUTCToLocal(response.activity)
        response.activity = response.activity
          ? DateManager.format(response.activity, 'MMM Do, h:mm a')
          : response.activity
      }

      if (Array.isArray(response?.milestone)) {
        switch (miType) {
          case 'anniversary':
            response.milestone = response.milestone.map((item) => {
              return {
                data: [
                  differenceInCalendarYears(
                    new Date(),
                    new Date(item.mi_date)
                  ) +
                    ' Year' +
                    (differenceInCalendarYears(
                      new Date(),
                      new Date(item.mi_date)
                    ) > 1
                      ? 's'
                      : ''),
                  format(
                    parse(item.mi_date, 'yyyy-MM-dd', new Date()),
                    'MMM do'
                  ),
                  getName({
                    u_nickname: item.u_nickname,
                    u_fname: item.mi_fname,
                    u_lname: item.mi_lname,
                  }),
                ],

                image: getHeadshot({ ...item, id: item.user_id }),
                user_id: item.user_id,
              }
            })
            break
          case 'bar_streak':
            response.milestone = response.milestone.map((item) => {
              return {
                data: [
                  item.mi_value + ' Week' + (item.mi_value > 1 ? 's' : ''),
                  getName({
                    u_nickname: item.u_nickname,
                    u_fname: item.mi_fname,
                    u_lname: item.mi_lname,
                  }),
                ],
                image: getHeadshot({ ...item, id: item.user_id }),
                user_id: item.user_id,
              }
            })
            break
          case 'birthday':
            response.milestone = response.milestone.map((item) => {
              return {
                data: [
                  format(
                    new Date(
                      2000,
                      `${item.mi_value}`.split('-')[0] - 1,
                      `${item.mi_value}`.split('-')[1]
                    ),
                    'MMM do'
                  ),
                  getName({
                    u_nickname: item.u_nickname,
                    u_fname: item.mi_fname,
                    u_lname: item.mi_lname,
                  }),
                ],
                image: getHeadshot({ ...item, id: item.user_id }),
                user_id: item.user_id,
              }
            })
            break
          case 'first_bar':
            response.milestone = response.milestone.map((item) => {
              return {
                data: [
                  'Week of ' + format(new Date(item.mi_date), 'PP'),
                  getName({
                    u_nickname: item.u_nickname,
                    u_fname: item.mi_fname,
                    u_lname: item.mi_lname,
                  }),
                ],
                image: getHeadshot({ ...item, id: item.user_id }),
                user_id: item.user_id,
              }
            })
            break
          case 'first_sale':
            response.milestone = response.milestone.map((item) => {
              return {
                data: [
                  format(DateManager.dateOnlyUTCToLocal(item.mi_date), 'PP'),
                  getName({
                    u_nickname: item.u_nickname,
                    u_fname: item.mi_fname,
                    u_lname: item.mi_lname,
                  }),
                ],
                image: getHeadshot({ ...item, id: item.user_id }),
                user_id: item.user_id,
              }
            })
            break
          case 'agent_koth_weekly':
          case 'ba_koth_weekly':
            response.milestone = response.milestone.map((item) => {
              return {
                data: [
                  'Week of ' + format(new Date(item.mi_date), 'PP'),
                  item.mi_value,
                  getName({
                    u_nickname: item.u_nickname,
                    u_fname: item.mi_fname,
                    u_lname: item.mi_lname,
                  }),
                ],
                image: getHeadshot({ ...item, id: item.user_id }),
                user_id: item.user_id,
              }
            })
            break
          case 'agent_koth_yearly':
          case 'ba_koth_yearly':
            response.milestone = response.milestone.map((item) => {
              return {
                ...item,
                image: getHeadshot({ ...item, id: item.user_id }),
              }
            })
            break
          case 'streak':
            response.milestone = response.milestone.map((item) => {
              return {
                data: [
                  item.mi_value + ' Week Streak',
                  getName({
                    u_nickname: item.u_nickname,
                    u_fname: item.mi_fname,
                    u_lname: item.mi_lname,
                  }),
                ],
                image: getHeadshot({ ...item, id: item.user_id }),
                user_id: item.user_id,
              }
            })
            break
          case 'rick_ring_race':
          case 'leader_ring_race':
          case 'agent_ring_race':
            response.milestone = response.milestone
              ? response.milestone.map((item) => {
                  return {
                    data: [
                      convertToCurrencyWithoutSign(item?.mi_value || 0),
                      getName({
                        u_nickname: item.u_nickname,
                        u_fname: item.mi_fname,
                        u_lname: item.mi_lname,
                      }),
                    ],
                    image: getHeadshot({ ...item, id: item.user_id }),
                    user_id: item.user_id,
                  }
                })
              : []
            break
          case 'rick_ring_race_award':
          case 'leader_ring_race_award':
          case 'agent_ring_race_award':
            response.milestone = response.milestone
              ? response.milestone.map((item) => {
                  return {
                    data: [
                      convertToCurrencyWithoutSign(item?.mi_value || 0),
                      getName({
                        u_nickname: item.u_nickname,
                        u_fname: item.mi_fname,
                        u_lname: item.mi_lname,
                      }),
                      moment(item.mi_date).format('MMM DD, YYYY'),
                    ],
                    image: getHeadshot({ ...item, id: item.user_id }),
                    user_id: item.user_id,
                  }
                })
              : []
            break
          case 'hof':
            response.milestone = response.milestone
              ? response.milestone.map((item) => {
                  return Object.values(item).length
                    ? Object.values(item).map((m) => {
                        return {
                          category: m.category,
                          mi_date: moment(m.mi_date).format('MMM DD, YYYY'),
                          mi_name: getName({
                            u_nickname: item.u_nickname,
                            u_fname: item.mi_fname,
                            u_lname: item.mi_lname,
                          }),
                          image: getHeadshot({ ...m, id: m.user_id }),
                          user_id: item.user_id,
                        }
                      })
                    : []
                })
              : []
            break
          default:
            response.milestone = []
            break
        }
      }

      this.milestones[miType] = response
    }

    this.loading[miType] = false
  }
}

export default new MilestoneStore()
