import React from 'react'
import { observer } from 'mobx-react-lite'
import { MDBAlert, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import { getHeadshot } from './../../../../shared/utilities/getHeadshot.function'
import CarrierDocDatesSetter from './../CarrierDocDatesSetter/CarrierDocDatesSetter.component'
import AgentUsertypeSelector from './../../components/AgentUsertypeSelector/AgentUsertypeSelector.component'

import './AgentManagerRow.scss'

const AgentManagerRow = (props) => {
  const isAssumed = UserProfileService.isAssumed()

  const goToProfile = () => props?.viewProfile(props.agent)

  const loginProfile = () => props?.loginTo(props.agent)

  const onChange = (change) => {
    if (typeof props?.onChange === 'function')
      props.onChange({ ...change, agent: props.agent })
  }

  return (
    <>
      <React.Fragment>
        <MDBRow className="AgentManagerRow">
          {/* --- Agent Profile --- */}
          <MDBCol className="agent-profile min-w-6">
            <div className={'d-flex align-items-center justify-content-center'}>
              <div
                className={'cursor-pointer d-inline-block'}
                role={'button'}
                onClick={isAssumed ? goToProfile : loginProfile}
              >
                <img
                  src={getHeadshot(props?.agent)}
                  alt={props?.agent?.u_fname ? props?.agent?.u_fname : 'image'}
                  className="mx-auto d-block agent-profile-img"
                />
                {`${props?.agent?.display.agent}`}
              </div>
            </div>
          </MDBCol>

          {/* --- Agent Upline --- */}
          <MDBCol className="agent-upline min-w-5">
            {props?.agent?.display.upline}
          </MDBCol>

          {/* --- Agent Enrollment Date --- */}
          <MDBCol className="agent-enrollment-date min-w-8">
            {props?.agent?.display.enrollment}
          </MDBCol>

          {/* --- Carrier Contracts --- */}
          <MDBCol className="agent-contracts min-w-8">
            {props?.agent?.id && (
              <CarrierDocDatesSetter
                id={props.agent.id}
                name={`${props.agent.display.agent}`}
                label={`${props.agent.display.contracts}`}
                goTo={(link) => this.props.history.push(link)}
              />
            )}
          </MDBCol>

          {/* --- Agent Completed Trainings --- */}
          <MDBCol className="agent-trainings min-w-8">
            {`${props?.agent?.display.training}`}
          </MDBCol>

          {/* --- KPI Goals Complete --- */}
          <MDBCol className="agent-kpis min-w-8">
            <div className="min-width">
              <MDBAlert
                color={
                  `${props?.agent?.display.kpis}` === '1' ? 'success' : 'danger'
                }
              >
                {`${props?.agent?.display.kpis}` === '1' ? (
                  <>
                    <MDBIcon icon="check" />
                    &nbsp;Yes
                  </>
                ) : (
                  <>
                    <MDBIcon icon="times" />
                    &nbsp;No
                  </>
                )}
              </MDBAlert>
            </div>
          </MDBCol>

          {/* --- Usertype Selector --- */}
          <MDBCol className="agent-usertypes min-w-7">
            <AgentUsertypeSelector
              usertypes={props?.usertypes}
              agent={props?.agent}
              onChange={onChange}
              canChangeUsertype={props?.canChangeUsertype}
            />
          </MDBCol>

          {/* --- Agent Notes --- */}
          <MDBCol className="agent-notes min-w-5">
            <div className={'d-flex align-items-center justify-content-center'}>
              <div
                className={'cursor-pointer d-inline-block'}
                role={'button'}
                onClick={() =>
                  typeof props?.viewNotes === 'function' &&
                  props.viewNotes(props.agent)
                }
              >
                <MDBIcon
                  icon="user-edit"
                  size="1x"
                  className="d-flex justify-content-center red-text"
                />
              </div>
            </div>
          </MDBCol>

          {/* ---  --- */}
          <MDBCol className="agent-history min-w-5">
            <div className={'d-flex align-items-center justify-content-center'}>
              <div
                className={'cursor-pointer d-inline-block'}
                role={'button'}
                onClick={() =>
                  typeof props?.viewHistory === 'function' &&
                  props.viewHistory(props.agent)
                }
              >
                <MDBIcon icon="pencil-alt" className="blue-text p-1" />
              </div>
            </div>
          </MDBCol>

          {/* --- Agent Usertype Assignment Date --- */}
          <MDBCol className="agent-usertype-assignment min-w-8">
            {props?.agent?.display.assignment}
          </MDBCol>
        </MDBRow>
      </React.Fragment>
    </>
  )
}

export default observer(AgentManagerRow)
