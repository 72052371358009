import React from 'react'
import { MDBContainer, MDBTooltip, MDBRow, MDBCol, MDBIcon } from 'mdbreact'

import './AgentRowSorter.scss'

const AgentRowSorter = (props) => {
  return (
    <MDBContainer fluid id="AgentRowSorter">
      <MDBRow>
        <MDBCol
          className={
            'agent-profile has-sorter min-w-6 ' +
            (props.col === 'agent'
              ? 'sorted-' + `${props.dir}`.trim().toLowerCase()
              : '')
          }
        >
          <div>
            <div className="label d-flex align-items-center">Agent Profile</div>
            <div className="sorter">
              <div onClick={() => props.sort('agent', 'ASC')} className="up">
                <MDBIcon icon="angle-up" />
              </div>
              <div onClick={() => props.sort('agent', 'DESC')} className="down">
                <MDBIcon icon="angle-down" />
              </div>
            </div>
          </div>
          <small>Click pic to view agent profile.</small>
        </MDBCol>
        <MDBCol
          className={
            'agent-upline has-sorter min-w-5 ' +
            (props.col === 'upline'
              ? 'sorted-' + `${props.dir}`.trim().toLowerCase()
              : '')
          }
        >
          <div>
            <div className="label">
              Agent
              <br />
              Upline
            </div>
            <div className="sorter">
              <div onClick={() => props.sort('upline', 'ASC')} className="up">
                <MDBIcon icon="angle-up" />
              </div>
              <div
                onClick={() => props.sort('upline', 'DESC')}
                className="down"
              >
                <MDBIcon icon="angle-down" />
              </div>
            </div>
          </div>
        </MDBCol>
        <MDBCol
          className={
            'agent-enrollment-date has-sorter min-w-8 ' +
            (props.col === 'enrollment'
              ? 'sorted-' + `${props.dir}`.trim().toLowerCase()
              : '')
          }
        >
          <div>
            <div className="label">
              Enrollment
              <br />
              Date
            </div>
            <div className="sorter">
              <div
                onClick={() => props.sort('enrollment', 'ASC')}
                className="up"
              >
                <MDBIcon icon="angle-up" />
              </div>
              <div
                onClick={() => props.sort('enrollment', 'DESC')}
                className="down"
              >
                <MDBIcon icon="angle-down" />
              </div>
            </div>
          </div>
        </MDBCol>
        <MDBCol
          className={
            'agent-contracts has-sorter min-w-8 ' +
            (props.col === 'contracts'
              ? 'sorted-' + `${props.dir}`.trim().toLowerCase()
              : '')
          }
        >
          <div>
            <div className="label d-flex align-items-center">Contracts</div>
            <div className="sorter">
              <div
                onClick={() => props.sort('contracts', 'ASC')}
                className="up"
              >
                <MDBIcon icon="angle-up" />
              </div>
              <div
                onClick={() => props.sort('contracts', 'DESC')}
                className="down"
              >
                <MDBIcon icon="angle-down" />
              </div>
            </div>
          </div>
          <div>
            <MDBTooltip
              domElement
              tag="span"
              placement="top"
              clickable
              className="tooltip-wrapper"
            >
              <div className="tooltip-trigger" title="Details">
                Req/Rec/Sent
                <wbr />
                /Comp <MDBIcon size="xs" icon="fas fa-question" />
              </div>
              <div className="tooltip-content">
                <table>
                  <thead>
                    <tr>
                      <th colSpan="2">
                        <u>CONTRACTS:</u>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Requested:</strong>
                      </td>
                      <td>Number of contracts requested by the agent.</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Received:</strong>
                      </td>
                      <td>Number of contracts received from the agent.</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Sent:</strong>
                      </td>
                      <td>
                        Number of contracts sent to carriers on behalf of the
                        agent.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Completed:</strong>
                      </td>
                      <td>Number of complete contracts with writing number.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </MDBTooltip>
          </div>
        </MDBCol>
        <MDBCol
          className={
            'agent-trainings has-sorter min-w-8 ' +
            (props.col === 'training'
              ? 'sorted-' + `${props.dir}`.trim().toLowerCase()
              : '')
          }
        >
          <div>
            <div className="label d-flex align-items-center">Training</div>
            <div className="sorter">
              <div onClick={() => props.sort('training', 'ASC')} className="up">
                <MDBIcon icon="angle-up" />
              </div>
              <div
                onClick={() => props.sort('training', 'DESC')}
                className="down"
              >
                <MDBIcon icon="angle-down" />
              </div>
            </div>
          </div>
          <div>
            <MDBTooltip
              domElement
              tag="span"
              placement="top"
              clickable
              className="tooltip-wrapper"
            >
              <div className="tooltip-trigger" title="Details">
                Completed
                <wbr />
                /Required <MDBIcon size="xs" icon="fas fa-question" />
              </div>
              <div className="tooltip-content">
                <table>
                  <thead>
                    <tr>
                      <th colSpan="2">
                        <u>TRAINING:</u>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Completed:</strong>
                      </td>
                      <td>
                        Number of required training the agent has completed.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Required:</strong>
                      </td>
                      <td>Total number of required trainings.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </MDBTooltip>
          </div>
        </MDBCol>

        <MDBCol
          className={
            'agent-kpis has-sorter min-w-8 ' +
            (props.col === 'kpis'
              ? 'sorted-' + `${props.dir}`.trim().toLowerCase()
              : '')
          }
        >
          <div>
            <div className="label">KPI Goals Completed </div>
            <div className="sorter">
              <div onClick={() => props.sort('kpis', 'ASC')} className="up">
                <MDBIcon icon="angle-up" />
              </div>
              <div onClick={() => props.sort('kpis', 'DESC')} className="down">
                <MDBIcon icon="angle-down" />
              </div>
            </div>
          </div>
        </MDBCol>

        <MDBCol
          className={
            'agent-usertypes has-sorter min-w-7 ' +
            (props.col === 'usertype'
              ? 'sorted-' + `${props.dir}`.trim().toLowerCase()
              : '')
          }
        >
          <div>
            <div className="label">
              Agent
              <br />
              Usertype
            </div>
            <div className="sorter">
              <div onClick={() => props.sort('usertype', 'ASC')} className="up">
                <MDBIcon icon="angle-up" />
              </div>
              <div
                onClick={() => props.sort('usertype', 'DESC')}
                className="down"
              >
                <MDBIcon icon="angle-down" />
              </div>
            </div>
          </div>
        </MDBCol>
        <MDBCol className="agent-notes min-w-5">
          <div>
            <div className="label">
              Agent
              <br />
              Notes
            </div>
          </div>
        </MDBCol>
        <MDBCol className="agent-history min-w-5">
          <div>
            <div className="label">
              Usertype
              <br />
              History
            </div>
          </div>
        </MDBCol>
        <MDBCol
          className={
            'agent-usertype-assignment has-sorter min-w-8 ' +
            (props.col === 'assignment'
              ? 'sorted-' + `${props.dir}`.trim().toLowerCase()
              : '')
          }
        >
          <div>
            <div className="label">
              Usertype
              <br />
              Assignment
            </div>
            <div className="sorter">
              <div
                onClick={() => props.sort('assignment', 'ASC')}
                className="up"
              >
                <MDBIcon icon="angle-up" />
              </div>
              <div
                onClick={() => props.sort('assignment', 'DESC')}
                className="down"
              >
                <MDBIcon icon="angle-down" />
              </div>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default AgentRowSorter
