import React, { useState, useEffect, useCallback } from 'react'
import env from './../../../environments/environment'
import { observer } from 'mobx-react-lite'
import {
  MDBIcon,
  MDBCollapse,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBBtn,
} from 'mdbreact'
import AgentContractManagerStore from './../AgentContractManager.store'
import SpecRequireContract from './../SpecRequireContract/SpecRequireContract.component'
import SpecUnlock from './SpecUnlock.component'
import './SpecializationSelector.scss'
import MediaUploaderModal from './../../modals/MediaUploaderModal'
import UserProfileService from './../../../shared/services/UserProfile.service'

const specIcons = {
  ACA: 'hospital-alt',
  HEALTH: 'user-md',
  SENIOR: 'medkit',
  LIFE: 'heart',
}

const ENABLE_ACA = false

const checkIsSpecActive = (spec, Store) => {
  if (Store) {
    let metaValue = Store?.Specs?.get('meta_value')
    if (metaValue && typeof metaValue === 'string')
      metaValue = JSON.parse(metaValue)
    if (
      metaValue &&
      typeof metaValue === 'object' &&
      typeof metaValue?.requested === 'object'
    ) {
      if (metaValue.requested.hasOwnProperty(spec))
        return !!metaValue.requested[spec]
    }

    return false
  }
}

const SpecializationSelector = ({
  showRequirements,
  inputType,
  isOnboarding,
  readOnly,
  onChange,
}) => {
  const onLockClick = (spec) => {
      /* if (spec === 'SENIOR' || spec === 'LIFE') setUnlockSpec(prevSpec => {return spec;}); */
    },
    setSpecTo = useCallback(
      (spec, isActive) => {
        if (AgentContractManagerStore?.Specs) {
          let mv = AgentContractManagerStore.Specs?.get('meta_value')
          mv = mv && typeof mv === 'string' ? JSON.parse(mv) : mv

          if (!mv)
            mv = {
              requested: {
                HEALTH: false,
                SENIOR: false,
                LIFE: false,
                ACA: false,
              },
            }

          if (mv && typeof mv === 'object') {
            if (`${inputType}`.trim().toLowerCase() === 'radio') {
              // if there is only one selected, you can't un-select it.
              if (mv?.requested && typeof mv.requested === 'object') {
                if (
                  !isActive &&
                  Object.values(mv.requested).filter((v) => !!v).length === 1
                )
                  return
              }

              mv.requested =
                mv?.requested && mv.requested === 'object'
                  ? mv.requested
                  : { HEALTH: false, SENIOR: false, LIFE: false, ACA: false }
            }

            mv.requested = { ...mv.requested, [spec]: !!isActive }
          }

          if (mv) {
            AgentContractManagerStore.Specs.set(
              'meta_value',
              `${mv ? (typeof mv === 'object' ? JSON.stringify(mv) : mv) : ''}`
            )

            AgentContractManagerStore.Specs.save()
          }
        }
      },
      [inputType]
    ),
    isSpecActive = {
      HEALTH: checkIsSpecActive('HEALTH', AgentContractManagerStore),
      SENIOR: checkIsSpecActive('SENIOR', AgentContractManagerStore),
      LIFE: checkIsSpecActive('LIFE', AgentContractManagerStore),
      ACA: checkIsSpecActive('ACA', AgentContractManagerStore),
    },
    isLoading = false,
    // isLoading 		= CarrierStore.getIsLoading('fetch.agent-specs') || CarrierStore.getIsLoading('update.agent-specs'),

    [isCertified, setIsCertified] = useState(null),
    [loadFetchCrtfct, setLoadFetchCrtfct] = useState(false),
    [downloadCrtfct, setDownloadLimraCrtfct] = useState(''),
    [downloadAHIPCrtfct, setDownloadAHIPCrtfct] = useState(''),
    hasCertified = {
      ahip: !!AgentContractManagerStore?.Certs?.ahip?.hasCertified(),
      limra: !!AgentContractManagerStore?.Certs?.limra?.hasCertified(),
    }

  useEffect(() => {
    setIsCertified((prevState) => ({
      ...prevState,
      ahip: hasCertified.ahip,
      limra: hasCertified.limra,
    }))
  }, [hasCertified.ahip, hasCertified.limra])

  let checkSpecsDefined = AgentContractManagerStore?.Specs
    ? AgentContractManagerStore?.Specs?.get('meta_value')
    : false
  checkSpecsDefined = checkSpecsDefined
    ? typeof checkSpecsDefined === 'object'
      ? JSON.stringify(checkSpecsDefined)
      : checkSpecsDefined
    : checkSpecsDefined

  useEffect(() => {
    if ((!!AgentContractManagerStore?.Specs && !checkSpecsDefined) === true)
      setSpecTo('HEALTH', true)
  }, [checkSpecsDefined, setSpecTo])

  const renderDownloadButton = (crtfctType) => {
    const isUrlReady = () => {
      if (crtfctType === 'ahip') return downloadAHIPCrtfct
      if (crtfctType === 'limra') return downloadCrtfct
      return false
    }

    let url
    if (!(url = isUrlReady())) return <></>

    url = `https://firebasestorage.googleapis.com/v0/b/${
      env.integrations.firebase.config.storageBucket
    }/o/${encodeURIComponent(url)}?alt=media`

    return (
      <MDBBtn
        size="sm"
        disabled={false}
        className="green-btn d-flex"
        style={{ boxShadow: 'none' }}
        onClick={(e) => {
          e.preventDefault()

          const downloadLink = document.createElement('a')
          downloadLink.target = '_blank'
          downloadLink.href = url
          downloadLink.download = url

          document.body.appendChild(downloadLink)
          downloadLink.click()
          document.body.removeChild(downloadLink)
        }}
      >
        <MDBIcon icon="download" />
      </MDBBtn>
    )
  }

  const fetchCertificates = async () => {
    const crtfctTypes = { ahip: null, limra: null },
      Metas = (
        await Promise.all(
          Object.keys(crtfctTypes).map((crtfctType) =>
            UserProfileService.getUserMetas(`contracting---${crtfctType}-cert`)
          )
        )
      ).map((Metas) => Metas.shift())

    return Object.keys(crtfctTypes)
      .map((crtfctType) => {
        return Metas.filter(
          (meta) => meta && `${meta?.meta_key}`.includes(crtfctType)
        ).shift()
      })
      .map((meta) => {
        if (meta) {
          // meta.meta_value = meta?.meta_value ? `users/${UserProfileService.getUserId()}/certificates/${meta.meta_value}` : null;
          return {
            check_id: meta?.id,
            check_url: meta?.meta_value,
          }
        }
        return false
      })
  }

  useEffect(() => {
    fetchCertificates().then((checked) => {
      const [ahip, limra] = checked || []
      setDownloadAHIPCrtfct((ahip && ahip.check_url) || '')
      setDownloadLimraCrtfct((limra && limra.check_url) || '')
    })
  }, [loadFetchCrtfct])
  const renderUploadButton = (crtfctType) => {
    if (!(crtfctType = crtfctType ? crtfctType.toLowerCase() : null))
      return <></>

    const onClick = () => {
      MediaUploaderModal.open({
        uploadType: `contracting_${crtfctType.toLowerCase()}`,
        modalContent: (
          <div className="fs--1rem fw--500 text--center">
            {`Upload a new ${crtfctType.toUpperCase()} certificate PDF`}
          </div>
        ),
        validationRules: { contentType: ['application/pdf'] },
      })
        .then(async (filePath) => {
          setLoadFetchCrtfct(!loadFetchCrtfct)
        })
        .catch((err) => {
          if (err)
            console.log(
              `Failed to complete uploading specialization certificate: ${crtfctType}.`,
              err
            )
        })
    }

    return (
      <MDBBtn
        className="d-flex blue-btn"
        onClick={() => onClick()}
        size="sm"
        style={{ boxShadow: 'none' }}
      >
        <MDBIcon className="mt-1 mr-1" icon="upload" />
        &nbsp;
        <span>{`Upload ${crtfctType.toUpperCase()} Certificate`}</span>
      </MDBBtn>
    )
  }

  return (
    <>
      <MDBCard
        id="SpecializationSelector"
        className={isOnboarding ? 'is-onboarding' : ''}
      >
        <MDBCardHeader>
          <div>
            <MDBIcon icon="award" />
            &nbsp;Select Your Specialization
          </div>
          <small>
            {isOnboarding
              ? "Select your area of focus.  We'll help you choose the best carriers for you to get started with.  New agents are recommended to start with just one specialization."
              : "Select one or more areas of focus.  We'll help you narrow down the best carriers for you."}
          </small>
        </MDBCardHeader>
        <MDBCardBody>
          <ul className="row">
            <li
              key={'agent-spec-btn-HEALTH'}
              className={
                (isSpecActive.HEALTH ? 'spec-selected ' : '') +
                ' agent-spec-btn col-md-4'
              }
            >
              <div
                className="spec-lock-down"
                onClick={() => {
                  if (!readOnly) onLockClick('HEALTH')
                }}
              ></div>
              <img
                className="spec-ribbon"
                src={`https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fimg.spec.popular-profitable.png?alt=media`}
                alt="Most Popular & Most Profitable!"
              />
              <button
                className="spec-btn"
                disabled={isLoading}
                title={'HEALTH'}
                onClick={async () => {
                  if (!readOnly) {
                    setSpecTo('HEALTH', !isSpecActive.HEALTH)
                    if (typeof onChange === 'function') onChange()
                  }
                }}
              >
                <div>
                  <MDBIcon size="2x" fas="true" icon={specIcons['HEALTH']} />
                </div>
                <div>
                  {isSpecActive.HEALTH ? (
                    <MDBIcon far icon="check-circle" />
                  ) : (
                    <MDBIcon far icon="circle" />
                  )}
                  &nbsp;{'HEALTH'}
                </div>
              </button>
              {showRequirements ? <SpecRequireContract spec="HEALTH" /> : <></>}
            </li>

            <li
              key={'agent-spec-btn-SENIOR'}
              className={
                (isSpecActive.SENIOR ? 'spec-selected ' : '') +
                (isCertified?.ahip ? '' : 'is-locked ') +
                ' agent-spec-btn col-md-4'
              }
            >
              <SpecUnlock
                AgentCert={AgentContractManagerStore?.Certs?.ahip}
                certification={'ahip'}
                msg={
                  readOnly ? (
                    ''
                  ) : (
                    <>
                      AHIP Certified?
                      <br />
                      Click to Unlock.
                    </>
                  )
                }
                field={'AHIP Effective Date'}
                readOnly={!!readOnly}
                onChange={(AgentCert) => {
                  if (AgentCert && AgentCert.get('certification')) {
                    AgentContractManagerStore.Certs[
                      AgentCert.get('certification')
                    ] = AgentCert
                    hasCertified[AgentCert.get('certification')] =
                      AgentCert.hasCertified()
                  }
                }}
              />
              <button
                className="spec-btn"
                disabled={isLoading}
                title={'SENIOR'}
                onClick={async () => {
                  if (!readOnly) {
                    setSpecTo('SENIOR', !isSpecActive.SENIOR)
                    if (typeof onChange === 'function') onChange()
                  }
                }}
              >
                <div>
                  <MDBIcon size="2x" fas="true" icon={specIcons['SENIOR']} />
                </div>
                <div>
                  {isSpecActive.SENIOR ? (
                    <MDBIcon far icon="check-circle" />
                  ) : (
                    <MDBIcon far icon="circle" />
                  )}
                  &nbsp;{'SENIOR'}
                </div>
              </button>
              {showRequirements ? <SpecRequireContract spec="SENIOR" /> : <></>}
              {/* ahip certificate  */}
              <div className="d-flex justify-content-center mr-1">
                <div className="certificate-uploader">
                  {renderUploadButton('ahip')}
                  {renderDownloadButton('ahip')}
                </div>
              </div>
            </li>

            <li
              key={'agent-spec-btn-LIFE'}
              className={
                (isSpecActive.LIFE ? 'spec-selected ' : '') +
                (isCertified?.limra ? '' : 'is-locked ') +
                ' agent-spec-btn col-md-4'
              }
            >
              <SpecUnlock
                certification={'limra'}
                msg={
                  readOnly ? (
                    ''
                  ) : (
                    <>
                      LIMRA Certified?
                      <br />
                      Click to Unlock.
                    </>
                  )
                }
                field={'LIMRA Effective Date'}
                readOnly={!!readOnly}
                onChange={(AgentCert) => {
                  if (AgentCert && AgentCert.get('certification')) {
                    AgentContractManagerStore.Certs[
                      AgentCert.get('certification')
                    ] = AgentCert
                    hasCertified[AgentCert.get('certification')] =
                      AgentCert.hasCertified()
                  }
                }}
              />
              <button
                className="spec-btn"
                disabled={isLoading}
                title={'LIFE'}
                onClick={async () => {
                  if (!readOnly) {
                    setSpecTo('LIFE', !isSpecActive.LIFE)
                    if (typeof onChange === 'function') onChange()
                  }
                }}
              >
                <div>
                  <MDBIcon size="2x" fas="true" icon={specIcons['LIFE']} />
                </div>
                <div>
                  {isSpecActive.LIFE ? (
                    <MDBIcon far icon="check-circle" />
                  ) : (
                    <MDBIcon far icon="circle" />
                  )}
                  &nbsp;{'LIFE'}
                </div>
              </button>
              {showRequirements ? <SpecRequireContract spec="LIFE" /> : <></>}
              <div className="d-flex justify-content-center mr-1">
                <div className="certificate-uploader">
                  {/* {renderUploadButton('limra')} */}
                  {renderDownloadButton('limra')}
                </div>
              </div>
            </li>
          </ul>
          <MDBCollapse isOpen={ENABLE_ACA && !!isSpecActive.HEALTH}>
            <div
              className={
                (isSpecActive.HEALTH ? 'enable' : '') +
                ' ' +
                (isSpecActive.ACA ? 'spec-selected' : '') +
                ' agent-spec-btn aca-wrapper'
              }
            >
              <button
                className="spec-btn"
                disabled={isLoading}
                title={'ACA'}
                onClick={async () => {
                  if (!readOnly) {
                    setSpecTo('ACA', !isSpecActive.ACA)
                    if (typeof onChange === 'function') onChange()
                  }
                }}
              >
                <div>
                  {isSpecActive.ACA ? (
                    <MDBIcon far icon="check-circle" />
                  ) : (
                    <MDBIcon far icon="circle" />
                  )}
                  &nbsp;Include ACA contract carriers?
                </div>
              </button>
            </div>
          </MDBCollapse>
        </MDBCardBody>
      </MDBCard>
    </>
  )
}

export default observer(SpecializationSelector)
