import AdminApi from "./../../api/admin-api/admin-api";
import { BehaviorSubject } from 'rxjs';
import stripePrices from './../../constants/stripePrices.constants';
import StripeCustomerService from './StripeCustomer.service';

const clientSecretSubject = 		new BehaviorSubject({}),
			paymentStatusSubject= 		new BehaviorSubject(false),
			subscriptionData 		= 		{subscription_id: null, client_secret: null, name: null, email: null, phone: null, customer_id: null};


const createStripeCustomer = (userId, usertypeId, subClass, customerIp) =>
{
	return StripeCustomerService.createByUserId(userId, {usertypeId, subClass, customerIp})
}

const createStripeSubscription = (customerId, priceIds, userIp) =>
	AdminApi.stripeCreateSubscription({customer: customerId, user_ip: userIp, items: priceIds.map(p => {return {price: p}}), payment_behavior: 'default_incomplete', add_invoice_items: [{price: stripePrices.one_time, quantity: 1}]});


const initiateSubscription = ({userId, email, name, phone, usertypeId, subClass, userIp}) =>
{
	return new Promise((resolve, reject) => {
		createStripeCustomer(userId, usertypeId, subClass, userIp)
			.then(customer => {
				let customerId 		= 		customer && customer?.id;
				if (customerId) {
					subscriptionData.customer_id = customerId;
					createStripeSubscription(customerId, [stripePrices.recurring], userIp)
						.then(subscription => {
							subscriptionData.subscription_id 	= 	subscription.data.data.id;
							subscriptionData.client_secret 		= 	subscription.data.data.latest_invoice.payment_intent.client_secret;
							resolve();
						});
				}
			});
	});
}

const confirmPayment = (stripe, cardNumberElement, billingDetails, userIp) =>
{
	const setDefaultPaymentMethod = async (paymentMethodId) =>
	{
		try { await AdminApi.stripeSetInitialDefaultPayMethod(paymentMethodId); }
		catch(ex) {}
	}

	return new Promise((resolve, reject) => {
		const paymentMethod = {
						payment_method: {
							card: cardNumberElement
						}
					};

		if (billingDetails && typeof billingDetails === 'object')
			paymentMethod.payment_method.billing_details = billingDetails;

		stripe
			.confirmCardPayment(subscriptionData.client_secret, paymentMethod)
			.then(paymentResult => {
				if (paymentResult.error) {
					paymentStatusSubject.next(false);
					reject(paymentResult.error.message)
				}
				else if (paymentResult.paymentIntent.status === "succeeded") {
					setDefaultPaymentMethod(paymentResult?.paymentIntent?.payment_method);
					paymentStatusSubject.next(true);
					resolve();
				}
				else {
					paymentStatusSubject.next(false);
					reject();
				}
			});
	});
}

const SubscriptionService = {
	getClientSecret: 							() => clientSecretSubject,
	getPaymentStatus:  						() => paymentStatusSubject,
	getSubscriptionData: 					() => subscriptionData,

	createStripeCustomer: 				createStripeCustomer,
	createStripeSubscription: 		createStripeSubscription,
	initiateSubscription:  				initiateSubscription,
	confirmPayment: 							confirmPayment
};

export default SubscriptionService;
