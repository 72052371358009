import React, { Component, Fragment } from 'react';
import DashboardLayout from "../../components/Admin/DashboardLayout";
import {
    MDBBtn,
    MDBContainer,
    MDBDataTable,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBInput,
    MDBModalHeader, MDBModalBody, MDBModal
} from "mdbreact";
import {toast} from "react-toastify";
import AdminApi from "../../api/admin-api/admin-api";
import moment from "moment";



class ViewSignatures extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breakWidth: 1300,
            windowWidth: 0,
            signatureList: '',
            loading: '',
            error: '',
            modal: false,
            confirmModal: false,
            termName: '',
            viewdata: '',
            userterm: [],
            data: {
                columns: [
                    {
                        label: 'Agent Name',
                        field: 'name',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Signed Date',
                        field: 'signed',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Select',
                        field: 'select',
                        sort: 'disabled',
                        width: 150
                    }

                ],
                rows: []
            }
        }
    }

    componentDidMount() {
        const {match: {params}} = this.props;
        this.setState({urlParams: params});


        if (params && params.id){
            // this.fetchSignatureData(params.id);
            this.fetchTermsData(params.id);
        }
    }

    //convert date from sec to current date
    toDateTime = (secs) => {
        let t = new Date(1970, 0, 1); // Epoch
        t.setSeconds(secs);
        return t;
    }

    //toggle modal
    toggle = () => {
        this.setState({modal: !this.state.modal});
    };

    //calling delete confirm modal
    confirm_DeleteSignature = (data) => {
        this.confirm_toggle(data);
    }

    //Toggle delete confirm modal
    confirm_toggle = (data = {}) => {
        this.setState({confirmModal: !this.state.confirmModal, ...data});
    }

    //forming rows for data table from fetched data
    setRows = () => {
        const {signatureList} = this.state;
        if(signatureList && signatureList.length) {
            let rows = signatureList.map((row) => {
                const {userterm_user, updated_at, id} = row;
                const submittedDate = (
                    <span searchvalue={updated_at ? moment(updated_at).format('YYYY/MM/DD') : ''}>
                        {updated_at ? moment(updated_at).format('MM/DD/YYYY') : 'N/A'}
                    </span>
                );
                const self = this;

                function handleCheckboxSelect(event) {
                    event.persist();
                    const {userterm} = self.state;
                    if (userterm.indexOf(event.target.value) > -1) {
                        userterm.splice(userterm.indexOf(event.target.value), 1);
                    } else {
                        userterm.push(event.target.value);
                    }
                    self.setState({userterm});
                }

                const checkBox = (
                    <MDBInput label="Select" type="checkbox" id={id.toString()} value={id} onClick={(event) => {handleCheckboxSelect(event)}} />
                );

                return {
                    name: userterm_user,
                    signed: submittedDate,
                    select: checkBox,
                }
            });

            this.setState({
                data: {...this.state.data, rows: rows || []}
            });
        }
    }

    //Fetch data
    fetchSignatureData = async (id) => {
        // this.setState({loading: true});
        AdminApi.getSignatures(id).then((result) => {
            this.setState({signatureList: result.data.data || []});
            // this.setState({termName: result.data.data.term_name || ''});
        }).then(() => {
            this.setState({loading: false});
            this.setRows();
        }).catch(error => {
            this.setState({loading: false});
            toast.error("Something went wrong!", {position: toast.POSITION.TOP_RIGHT});
        });
    }

    fetchTermsData = async (id) => {
        this.setState({loading: true});
        AdminApi.getTermsById(id).then((result) => {
            // this.setState({loading: false});
            if (result && result.data && result.data.data) {
                const content = result.data.data;
                this.setState({termName: content.term_name || ''});
            }
            this.fetchSignatureData(id);
        }).catch(error => {
            this.setState({loading: false});
            toast.error("Something went wrong!", {position: toast.POSITION.TOP_RIGHT});
        });
    }

    //Delete data
    _DeleteSignatures = async (id) => {
        const {userterm} = this.state;
        this.setState({deleting: true});
        const data = {'userterm_id': userterm};

        /*let form_data = new FormData();

        for ( let key in data ) {
            form_data.append(key, data[key]);
        }*/

        AdminApi.deleteSignature(data).then((result) => {
            this.setState({deleting: false});
            this.confirm_toggle();
            toast.success("Signature successfully deleted", {position: toast.POSITION.TOP_RIGHT});
            this.fetchSignatureData(this.state.urlParams.id);
        }).catch(error => {
            this.setState({deleting: false});
            toast.error(`${error.message}`, {position: toast.POSITION.TOP_RIGHT});
        });
    }


    render() {
        toast.configure();
        const {loading, confirmModal, data} = this.state;

        return (
            <Fragment>
                <DashboardLayout>
                <main className="mainSection">
                    <MDBContainer fluid className="mt-5">
                    <MDBBreadcrumb>
                        <MDBBreadcrumbItem><a href="/admin-terms">List</a></MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>View Signatures</MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                        {loading ?
                            <h4 className={'p4'}>Loading...</h4> :
                            <>
                                <h2>{this.state.termName}</h2>
                                <MDBBtn color="danger" onClick={this.confirm_DeleteSignature}>Delete</MDBBtn>
                                {data && <MDBDataTable sortRows={['signed']} striped bordered small data={data} className="text-capitalize" />}
                            </>
                        }


                        <MDBModal size={"sm"} isOpen={confirmModal} toggle={this.confirm_toggle}>
                            <MDBModalHeader toggle={this.confirm_toggle}>
                                Delete Signatures
                            </MDBModalHeader>
                            <MDBModalBody>
                                {this.state.userterm && this.state.userterm.length ?
                                    <>
                                        <div className="h5">Are you sure you want to delete selected signature(s)?</div>
                                        <MDBBtn color="secondary" size={'sm'} onClick={this.confirm_toggle}>
                                            Close
                                        </MDBBtn>
                                        <MDBBtn color="danger" size={'sm'} onClick={() => {this._DeleteSignatures(this.state.viewdata.id)}}>
                                            {this.state.deleting ? 'Deleting...' : 'Delete'}
                                        </MDBBtn>
                                    </>
                                    :
                                    <div className="h5">Please select signatures to delete.</div>
                                }

                            </MDBModalBody>
                        </MDBModal>
                    </MDBContainer>
                </main>
                </DashboardLayout>
            </Fragment>
          );
    }
}

export default ViewSignatures;
