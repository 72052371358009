import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import {
  MDBInput,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBSelect,
  MDBSelectOptions,
  MDBSelectOption,
  MDBSelectInput,
} from 'mdbreact'
import CarrierMgmtStore from './../../CarrierMgmt.store'
import {
  phoneToStorage,
  phoneToView,
} from './../../../../shared/formatters/telephone.formatter'

import './CarrierSiblingsForm.scss'

const CarrierSiblingsForm = ({ table, goTo }) => {
  const [view, setView] = useState('list')

  const renderLockOut = () => {
    if (
      !CarrierMgmtStore.carrier ||
      (CarrierMgmtStore.carrier && isNaN(CarrierMgmtStore.carrier?.id))
    )
      return <div className="lockout-window">lockout</div>
  }

  const renderSiblingForm = () => {
    if (CarrierMgmtStore.getIsLoading('fetch.avail-siblings'))
      return (
        <MDBRow>
          <MDBCol size="12">
            Loading Available Siblings&nbsp;
            <i className="fa fa-spin fa-spinner" />
          </MDBCol>
        </MDBRow>
      )

    let stagedSiblings = null

    return (
      <form noValidate>
        <MDBRow>
          <MDBCol size="12" sm="7">
            <MDBSelect multiple getValue={(value) => (stagedSiblings = value)}>
              <MDBSelectInput selected="Search Available Carrier Siblings" />
              <MDBSelectOptions search>
                <MDBSelectOption disabled>
                  Select Sibling Carrier
                </MDBSelectOption>
                {CarrierMgmtStore.availSiblings.map((AvailSig, idx) => {
                  return (
                    <MDBSelectOption
                      key={`avail-sibling-${AvailSig.id}-${idx}`}
                      value={`${AvailSig.id}`}
                    >
                      {AvailSig.c_name}
                    </MDBSelectOption>
                  )
                })}
              </MDBSelectOptions>
            </MDBSelect>
          </MDBCol>
          <MDBCol size="12" sm="5">
            <MDBBtn
              className="btn-block"
              onClick={() =>
                CarrierMgmtStore.linkSiblings(stagedSiblings).finally(() =>
                  setView('list')
                )
              }
            >
              Link Siblings
            </MDBBtn>
            <MDBBtn
              className="btn-block"
              onClick={() => {
                stagedSiblings = null
                setView('list')
              }}
            >
              Cancel
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </form>
    )
  }

  const renderSiblingTable = () => {
    return (
      <table className="siblings-table">
        <thead>
          <tr>
            <th valign="middle">ID</th>
            <th valign="middle">
              <div>
                <div className="label">Name</div>
              </div>
            </th>
            <th valign="middle">
              <div>
                <div className="label">Description</div>
              </div>
            </th>
            <th valign="middle">Edit</th>
            <th valign="middle">Unlink</th>
          </tr>
        </thead>
        <tbody>
          {CarrierMgmtStore.siblings.map((sibling, idx) => (
            <tr key={`sibling-id-${sibling.id}-${idx}`}>
              <td>{sibling.id}</td>
              <td>{sibling.c_name}</td>
              <td>{sibling.c_description}</td>
              <td>
                <MDBBtn
                  disabled={
                    !sibling ||
                    CarrierMgmtStore.getIsLoading('fetch.siblings') ||
                    CarrierMgmtStore.getIsLoading('fetch.carrier')
                  }
                  onClick={() => goTo(sibling.id)}
                >
                  <MDBIcon icon="edit" />
                </MDBBtn>
              </td>
              <td>
                <MDBBtn
                  disabled={
                    !sibling ||
                    CarrierMgmtStore.getIsLoading('fetch.siblings') ||
                    CarrierMgmtStore.getIsLoading('fetch.carrier')
                  }
                  onClick={() => CarrierMgmtStore.unlinkSibling(sibling.id)}
                >
                  <MDBIcon icon="trash" className="red-text" />
                </MDBBtn>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  const renderLoadingWheel = () => {
    if (CarrierMgmtStore.getIsLoading('fetch.siblings'))
      return (
        <span>
          <i className="fa fa-spin fa-spinner" />
        </span>
      )
  }

  return (
    <MDBCard id="CarrierSiblingsFormComponent">
      {renderLockOut()}
      <MDBCardHeader>
        Carrier Siblings (Alternate Carrier Configurations) &nbsp;
        {renderLoadingWheel()}
        <MDBBtn
          className="card-header-btn"
          disabled={!!!CarrierMgmtStore.carrier?.id}
          onClick={() => {
            if (view === 'list') {
              CarrierMgmtStore.fetchAvailSiblings()
              setView('form')
            } else {
              setView('list')
            }
          }}
        >
          <MDBIcon icon="plus" />
        </MDBBtn>
      </MDBCardHeader>
      <MDBCardBody>
        {view === 'form' ? renderSiblingForm() : renderSiblingTable()}
      </MDBCardBody>
    </MDBCard>
  )
}

export default observer(CarrierSiblingsForm)
