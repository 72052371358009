import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import { MDBContainer, MDBIcon, MDBBtn, MDBRow, MDBCol } from 'mdbreact'
import ContentHtml from './../../../../components/content/ContentHtml/ContentHtml.component'
import ContentVideo from './../../../../components/content/ContentVideo/ContentVideo.component'
import {
  UIInputButton,
  UIInput,
} from './../../../../components/forms/form-fields'
import SystemSettingStore from './../../../../shared/stores/systemSetting.store'

import './Packages.component.scss'

const BeastModePackagesComponent = ({ onSelect }) => {
  const isRecruiter = UserProfileService.isA([
      'recruiter-group',
      'agency-owner',
      'internal-admin',
      'system-admin',
    ]),
    isAdmin = UserProfileService.isA([
      'agency-owner',
      'internal-admin',
      'system-admin',
    ]),
    [isSubmitted, setIsSubmitted] = useState(false)

  const updLinks = (event) => {
    event.preventDefault()
    setIsSubmitted(true)

    if (
      !SystemSettingStore.Settings['beastmode---signup-links']?.sales ||
      !SystemSettingStore.Settings['beastmode---signup-links']?.recruiter
    )
      return false
    setIsSubmitted(false)

    SystemSettingStore.save('beastmode---signup-links')

    return false
  }

  return (
    <MDBContainer id="BeastModePackagesComponent" fluid>
      <MDBRow>
        <MDBCol size="12">
          <div style={{ width: 'auto', maxWidth: '900px', margin: 'auto' }}>
            <ContentVideo contentSlug="beastmode-package-signup-video" />
            <br />
          </div>
        </MDBCol>
        <MDBCol>
          <div className="content-wrapper">
            <h5>10x Sales CRM</h5>
            <ContentHtml contentSlug="beastmode-package-features-sales" />
            <MDBBtn block onClick={() => onSelect('sales')}>
              Sign up!
            </MDBBtn>
            {isAdmin ? (
              <form noValidate onSubmit={updLinks}>
                <UIInputButton
                  input={
                    <UIInput
                      label="Update 10x CRM Sales Signup Link"
                      name="sales_link"
                      onChange={(event) => {
                        SystemSettingStore.set('beastmode---signup-links', {
                          ...(SystemSettingStore.Settings[
                            'beastmode---signup-links'
                          ] || {}),
                          sales: event.target.value,
                        })
                      }}
                      value={
                        SystemSettingStore.Settings['beastmode---signup-links']
                          ?.sales
                      }
                    />
                  }
                  btnPosition={'right'}
                  button={
                    <MDBBtn type="submit" className="save-link-btn">
                      <MDBIcon icon="save" />
                    </MDBBtn>
                  }
                />
                {isSubmitted &&
                !SystemSettingStore.Settings['beastmode---signup-links']
                  ?.sales ? (
                  <small className="text--red fw--500">
                    Sales Link is Required!
                  </small>
                ) : (
                  <></>
                )}
              </form>
            ) : (
              <></>
            )}
          </div>
        </MDBCol>
        {isRecruiter ? (
          <MDBCol>
            <div className="content-wrapper">
              <h5>10x Recruiter & Sales CRM</h5>
              <ContentHtml contentSlug="beastmode-package-features-recruiter" />
              <MDBBtn block onClick={() => onSelect('recruiter')}>
                Sign up!
              </MDBBtn>
              {isAdmin ? (
                <form noValidate onSubmit={updLinks}>
                  <UIInputButton
                    input={
                      <UIInput
                        label="Update 10x CRM Recruiter Signup Link"
                        name="recruiter_link"
                        onChange={(event) => {
                          SystemSettingStore.set('beastmode---signup-links', {
                            ...(SystemSettingStore.Settings[
                              'beastmode---signup-links'
                            ] || {}),
                            recruiter: event.target.value,
                          })
                        }}
                        value={
                          SystemSettingStore.Settings[
                            'beastmode---signup-links'
                          ]?.recruiter
                        }
                      />
                    }
                    btnPosition={'right'}
                    button={
                      <MDBBtn type="submit" className="save-link-btn">
                        <MDBIcon icon="save" />
                      </MDBBtn>
                    }
                  />
                  {isSubmitted &&
                  !SystemSettingStore.Settings['beastmode---signup-links']
                    ?.recruiter ? (
                    <small className="text--red fw--500">
                      Recruiter Link is Required!
                    </small>
                  ) : (
                    <></>
                  )}
                </form>
              ) : (
                <></>
              )}
            </div>
          </MDBCol>
        ) : (
          <></>
        )}
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(BeastModePackagesComponent)
