import React from 'react'
import CONTRACTING from './../../../constants/contracting.constants'
import { observer } from 'mobx-react-lite'
import {
  MDBAlert,
  MDBCollapse,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from 'mdbreact'
import ContentHtml from './../../content/ContentHtml/ContentHtml.component'
import ExternalCarrierToggle from './../ExternalCarrierToggle/ExternalCarrierToggle.component'
import UserProfileService from './../../../shared/services/UserProfile.service'
import ACMStore from './../AgentContractManager.store'
import { getMetaValue } from './../getMetaValue.function'

import './OnboardingCartList.scss'

const MAX_EXTERNAL_CARRIERS = CONTRACTING.onboarding.MAX_EXTERNAL_CARRIERS

const OnboardingCartList = ({
  CartItems,
  activeSpecs,
  SelectedCartItems,
  onSelectCartItem,
  isSelectable,
  requiredItems,
  maxLimit,
}) => {
  const CartMeta_EnableExtCarriers = (ACMStore?.CartMetas || [])
      ?.filter(
        (CartMeta) => CartMeta.get('meta_key') === 'cart---enable-ext-carriers'
      )
      .shift(),
    CartMeta_ExtCarriers = (ACMStore?.CartMetas || [])
      ?.filter(
        (CartMeta) => CartMeta.get('meta_key') === 'cart-items---ext-carriers'
      )
      .shift(),
    shouldEnableExternalCarriers = CartMeta_EnableExtCarriers
      ? getMetaValue(CartMeta_EnableExtCarriers, 'boolean') === true
      : false,
    MinimumCartItems = [],
    getCarrierByCartItem = (CartItem) => (ACMStore?.Carriers || [])
        .filter(
          (Carrier) =>
            parseInt(Carrier.id()) === parseInt(CartItem.get('carrier_id'))
        )
        .shift(),
    getExtCarrierByCartItem = (CartItem) =>
      (ACMStore?.ExternalCarriers || [])
        .filter(
          (Carrier) =>
            parseInt(Carrier.get('carrier_id')) ===
            parseInt(CartItem.get('carrier_id'))
        )
        .shift(),
    getReplacementCarrierIdByExtCarrier = (ExternalCarrier) =>
      CartMeta_ExtCarriers &&
      ExternalCarrier?.get('carrier_id') &&
      getMetaValue(CartMeta_ExtCarriers, 'array')
        .filter(
          (e) =>
            parseInt(e.external_id) ===
            parseInt(ExternalCarrier.get('carrier_id'))
        )
        .shift()?.replacement_id,
    getReplacementCarrierById = (replacementId) =>
      (ACMStore?.Carriers || [])
        .filter((Carrier) => parseInt(Carrier.id()) === parseInt(replacementId))
        .shift(),
    isCartItemSelected = (CartItem) =>
      Array.isArray(SelectedCartItems) &&
      SelectedCartItems.map((CI) => parseInt(CI.get('carrier_id'))).indexOf(
        parseInt(CartItem.get('carrier_id'))
      ) > -1,
    renderCartItem = (CartItem) => {
      const Carrier = getCarrierByCartItem(CartItem),
        ExternalCarrier = getExtCarrierByCartItem(CartItem),
        replacementId = getReplacementCarrierIdByExtCarrier(ExternalCarrier),
        ReplacementCarrier = getReplacementCarrierById(replacementId),
        renderIdentity = () => {
          if (ExternalCarrier) {
            // If no replace carrier has been defined ...
            if (!ReplacementCarrier)
              return (
                <>
                  {Carrier?.get('c_name')}{' '}
                  <small className="external">
                    Skipping. Carrier Externally Contracted.
                  </small>
                </>
              )

            // Replacement carrier has been defined ...
            return (
              <>
                {ReplacementCarrier?.get('c_name')}{' '}
                <small className="replaced">
                  Contracting in place of {Carrier?.get('c_name')}.
                </small>
              </>
            )
          }

          return Carrier?.get('c_name')
        }

      if (!ExternalCarrier && !ReplacementCarrier && Carrier)
        MinimumCartItems.push(Carrier)
      else if (ReplacementCarrier) MinimumCartItems.push(ReplacementCarrier)

      return (
        <>
          {Carrier ? (
            <li
              key={`onboarding-cart-list-item-carrier-${CartItem.get(
                'carrier_id'
              )}`}
              className={
                (ReplacementCarrier
                  ? 'is-replaced-carrier '
                  : ExternalCarrier
                  ? 'is-external-carrier '
                  : '') +
                ' ' +
                (isSelectable ? 'is-selectable-carrier ' : '')
              }
            >
              <div className="cart-item cart-item-profit">
                {(ReplacementCarrier || Carrier)?.renderPriorityIndicator(
                  UserProfileService.get('usertype_id')
                ) || 'X'}
              </div>
              <div className="cart-item cart-item-carrier">
                {renderIdentity()}
              </div>
              <div className="cart-item cart-item-description">
                {(ReplacementCarrier || Carrier)?.get('c_description')}
              </div>
              <div className="cart-item cart-item-toggle text--center">
                <button
                  block="true"
                  className={`btn ${
                    isCartItemSelected(CartItem) ? 'active' : ''
                  }`}
                  onClick={() => onSelectCartItem(CartItem)}
                >
                  <div className="unselected">
                    <MDBIcon far icon="square" />
                    <MDBIcon icon="square" />
                  </div>
                  <div className="selected">
                    <MDBIcon far icon="check-square" />
                  </div>
                </button>
              </div>
              <div className="cart-item cart-item-external">
                {ReplacementCarrier ? (
                  <MDBAlert color="info">
                    <table>
                      <tbody>
                        <tr>
                          <td rowSpan="2">
                            <MDBIcon icon="check" />
                            &nbsp;
                          </td>
                          <td>Replacement</td>
                        </tr>
                        <tr>
                          <td>Carrier Set</td>
                        </tr>
                      </tbody>
                    </table>
                  </MDBAlert>
                ) : (
                  <>
                    <MDBCollapse isOpen={!!shouldEnableExternalCarriers}>
                      <ExternalCarrierToggle
                        ExternalCarrier={ExternalCarrier}
                        carrier_id={CartItem.get('carrier_id')}
                        disabled={
                          shouldEnableExternalCarriers &&
                          ACMStore?.ExternalCarriers?.length >=
                            MAX_EXTERNAL_CARRIERS &&
                          !ExternalCarrier
                        }
                      />
                    </MDBCollapse>
                  </>
                )}
              </div>
            </li>
          ) : null}
        </>
      )
    }

  const renderCartItemList = () => {
    // we only show the warning if an External Carrier has been activated (toggled on)
    // and a replacement carrier not yet selected, OR - there have been no external carriers
    // toggled and we're expecting the user to do so.
    let showWarning = shouldEnableExternalCarriers
      ? !ACMStore?.ExternalCarriers?.length ||
        !!(
          ACMStore?.ExternalCarriers &&
          ACMStore.ExternalCarriers.filter(
            (ExtCar) => !getReplacementCarrierIdByExtCarrier(ExtCar)
          ).length > 0
        )
      : shouldEnableExternalCarriers

    return (
      <ul
        className={
          'contract-cart-item-list ' +
          (!!shouldEnableExternalCarriers ? 'show-external ' : ' ') +
          (!!isSelectable ? 'show-toggle ' : ' ')
        }
      >
        <li>
          <div className="cart-item font--lato">
            USABG Core Carriers&nbsp;<span>(Required)</span>
            <small>
              These are USABG's best & most profitable carriers that reach the
              most customers.
            </small>
          </div>
        </li>
        <li>
          <div className="cart-item cart-item-profit">Profit:</div>
          <div className="cart-item cart-item-carrier">Carrier:</div>
          <div className="cart-item cart-item-description">Description:</div>
          <div className={'cart-item cart-item-toggle'}>
            <div className="select-carrier text--center">
              <MDBIcon icon="cart-plus" />
            </div>
          </div>
          <div
            className={
              'cart-item cart-item-external ' +
              (showWarning ? 'show-warning' : '')
            }
          >
            <div className="replace-carrier">
              <div>
                <MDBIcon icon="exclamation-triangle" />
              </div>
              <div>
                Replace
                <br />
                Carrier?
              </div>
            </div>
          </div>
        </li>
        {(ACMStore?.CartItems || [])
          .slice(0, maxLimit && !isNaN(maxLimit) ? parseInt(maxLimit) : 999)
          .map(renderCartItem)}
      </ul>
    )
  }

  return (
    <MDBContainer id="OnboardingCartListComponent" fluid>
      <MDBRow>
        <MDBCol size="12">
          <ContentHtml contentSlug="onboarding-cart-list-how-to" />
        </MDBCol>
        <MDBCol size="12">
          {renderCartItemList()}
          <MDBCollapse
            isOpen={
              !!shouldEnableExternalCarriers &&
              ACMStore?.ExternalCarriers?.length >= MAX_EXTERNAL_CARRIERS
            }
            className="max-external-cars"
          >
            <small>
              You've reached the maximum number of external contracts.
            </small>
          </MDBCollapse>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(OnboardingCartList)
