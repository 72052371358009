const formatUrl = (str) => {
  try {
    str = new URL(str)
  } catch (_) {
    return false
  }
  return str &&
    (str?.protocol === 'http:' || str?.protocol === 'https:') &&
    str?.href
    ? str.href
    : undefined
}

exports.formatUrl = formatUrl
