import BaseModelFactory from './base.factory'
import CarrierService from './../services/Carrier.service'
import Carrier from './../models/carrier.model'

class CarrierFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: CarrierService, Model: Carrier }
    )
  static findAll = async () =>
    await this._findAllBy({}, { Service: CarrierService, Model: Carrier })
  static findById = async (id) =>
    await this._findBy({ id: id }, { Service: CarrierService, Model: Carrier })
  static findAllById = async (id) =>
    await this._findAllBy(
      { id: id },
      { Service: CarrierService, Model: Carrier }
    )
  static create = (payload) =>
    this._create(payload, { Service: CarrierService, Model: Carrier })
}

export default CarrierFactory
