import BaseModelFactory from './base.factory'
import CommissionLevelService from './../services/CommissionLevel.service'
import CommissionLevel from './../models/commission-level.model'

class CommissionLevelFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: CommissionLevelService, Model: CommissionLevel }
    )
  static findAll = async () =>
    await this._findAllBy(
      {},
      { Service: CommissionLevelService, Model: CommissionLevel }
    )
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: CommissionLevelService, Model: CommissionLevel }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: CommissionLevelService,
      Model: CommissionLevel,
    })
}

export default CommissionLevelFactory
