import { AdminReferralsPage } from './index'

import { RouteFactory } from './../../shared/router/Route.factory'
import {
  RequiresAuthGuard,
  RequiresUsertypesGuard,
} from './../../shared/router/guards'

export default [
  RouteFactory.create(
    AdminReferralsPage,
    '/admin-referrals',
    [
      new RequiresAuthGuard(true),
      new RequiresUsertypesGuard([
        'agency-owner',
        'internal-admin',
        'system-admin',
      ]),
    ],
    {
      name: 'Referral Partner Administration',
    }
  ),
]
