import { addDays, getISODay } from 'date-fns'
import moment from 'moment'

export default class DateManager {
  static dateOnlyUTCToLocal = (dateStr) => {
    let newDate =
      typeof dateStr === 'string' && dateStr && moment(dateStr).isValid()
        ? moment(dateStr.match(/\d{4}-\d{2}-\d{2}/)[0], 'YYYY-MM-DD').toDate()
        : null
    if (newDate) return newDate
    if (dateStr && !newDate)
      console.error(
        'FAILED Date conversion in DateManager.dateConversion2.  {Argument: ' +
          dateStr +
          '}'
      )
  }

  static dateTimeUTCToLocal = (dateStr) => {
    let newDate =
      typeof dateStr === 'string' && dateStr && moment(dateStr).isValid()
        ? moment(
            dateStr.match(
              /\d{4}-\d{2}-\d{2}T\d{1,2}:\d{1,2}:\d{1,2}\.\d{3}Z/
            )[0],
            'YYYY-MM-DDTHH:mm:ss:SSSZ'
          ).toDate()
        : null
    if (newDate) return newDate
    if (dateStr && !newDate)
      console.error(
        'FAILED Date conversion in DateManager.dateConversion2.  {Argument: ' +
          dateStr +
          '}'
      )
  }

  static format = (dateObj, formatStr) => moment(dateObj).format(formatStr)

  static getDayInPast = (dayOfWeek, fromDate = new Date()) => {
    // follow the getISODay format (7 for Sunday, 1 for Monday)
    const dayOfWeekMap = {
      Mon: 1,
      Tue: 2,
      Wed: 3,
      Thur: 4,
      Fri: 5,
      Sat: 6,
      Sun: 7,
    }

    // dayOfWeekMap[dayOfWeek] get the ISODay for the desired dayOfWeek
    const targetISODay = dayOfWeekMap[dayOfWeek]
    const fromISODay = getISODay(fromDate)

    // targetISODay >= fromISODay means we need to trace back to last week
    // e.g. target is Wed(3), from is Tue(2)
    // hence, need to -7 the account for the offset of a week
    const offsetDays =
      targetISODay >= fromISODay
        ? -7 + (targetISODay - fromISODay)
        : targetISODay - fromISODay

    return addDays(fromDate, offsetDays)
  }
}
