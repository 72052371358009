import RestApi from './../../api/rest-api-client'
const srvcEndpoint = '/onboardings'
export default {
  stage: {
    get: async (req, opts) =>
      RestApi.request('GET', `${srvcEndpoint}/stages`, req, opts),
    post: async (data, opts) =>
      RestApi.request('POST', `${srvcEndpoint}/stages`, data, opts),
  },
  uncomplete: async (data, opts) =>
    RestApi.request('POST', `${srvcEndpoint}/uncomplete`, data, opts),
  complete: async (data, opts) =>
    RestApi.request('POST', `${srvcEndpoint}/complete`, data, opts),
}
