import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../../components/Admin/DashboardLayout'
import { MDBBtn, MDBContainer, MDBDataTable } from 'mdbreact'
import { toast } from 'react-toastify'
import AdminApi from '../../../api/admin-api/admin-api'
import moment from 'moment'

import ChangeUserStatusInListComponent from '../../../components/adminShared/changeUserStatusInListComponent'

class ViewUserTypeReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      exporting: false,
      agentsList: [],
      usertype: {},
      urlParams: {},
      data: {
        columns: [
          {
            label: 'Agent',
            field: 'agent',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Email',
            field: 'email',
            sort: 'asc',
            width: 270,
          },
          {
            label: 'NPN',
            field: 'npn',
            sort: 'asc',
            width: 270,
          },
          {
            label: 'Upline',
            field: 'upline',
            sort: 'asc',
            width: 270,
          },
          {
            label: 'Division',
            field: 'division',
            sort: 'asc',
            width: 270,
          },
          {
            label: 'Enrolled On',
            field: 'enrolldate',
            width: 270,
          },
          {
            label: 'Enroll Notes',
            field: 'usertype_history',
            sort: 'disabled',
            width: 270,
          },
          {
            label: 'Usertype Assigned',
            field: 'usertype_assigned',
            sort: 'desc',
            width: 270,
          },
          {
            label: 'Blacklisted?',
            field: 'active',
            sort: 'disabled',
            width: 270,
          },
        ],
        rows: [],
      },
    }
  }
  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params }, this.fetchAgentsListByUsertype)
  }

  _exportToCSV = async () => {
    this.setState({ exporting: true })
    AdminApi.csvUsersByUserType(this.state.urlParams.id)
      .then((result) => {
        if (result && result.data && result.data.data && result.data.data.url) {
          window.location.href = result.data.data.url
        }
        this.setState({ exporting: false })
      })
      .catch((error) => {
        this.setState({ exporting: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchAgentsListByUsertype = async () => {
    this.setState({ loading: true })
    AdminApi.getAgentsListByUsertype(this.state.urlParams.id)
      .then((result) => {
        if (result && result.data && result.data.data && result.data.usertype) {
          this.setState(
            {
              agentsList: result.data.data || [],
              usertype: result.data.usertype,
            },
            this.setRows
          )
        }
        this.setState({ loading: false })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  setRows = () => {
    const { agentsList } = this.state
    if (agentsList && agentsList.length) {
      let rows = agentsList.map((row, i) => {
        const {
          u_fname,
          u_lname,
          u_email,
          npn,
          u_upline_name,
          d_name,
          u_enrolled,
          usertype_history,
        } = row

        const enrolled_on = (
          <span
            searchvalue={
              u_enrolled ? moment(u_enrolled).format('YYYY/MM/DD') : ''
            }
          >
            {u_enrolled ? moment(u_enrolled).format('MM/DD/YYYY') : 'N/A'}
          </span>
        )

        const assigned_on = (
          <span
            searchvalue={
              usertype_history &&
              moment(
                usertype_history
                  .substr(usertype_history.lastIndexOf('on') + 3)
                  .replace('<br />', ''),
                'LLL'
              ).format('YYYY/MM/DD')
            }
          >
            {usertype_history &&
              moment(
                usertype_history
                  .substr(usertype_history.lastIndexOf('on') + 3)
                  .replace('<br />', ''),
                'LLL'
              ).format('MM/DD/YYYY')}
          </span>
        )

        const activeSwitch = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <ChangeUserStatusInListComponent
              callback={this.fetchAgentsListByUsertype}
              selectedRow={row}
            />
          </div>
        )

        return {
          agent: `${u_fname ? u_fname : ''} ${u_lname ? u_lname : ''}`,
          email: u_email ? u_email : '',
          npn: npn ? npn : 'N/A',
          upline: u_upline_name ? u_upline_name : 'N/A',
          division: d_name ? d_name : 'N/A',
          enrolldate: enrolled_on,
          usertype_history: (
            <div dangerouslySetInnerHTML={{ __html: usertype_history }}></div>
          ),
          usertype_assigned: usertype_history ? assigned_on : enrolled_on,
          active: activeSwitch,
        }
      })
      this.setState({
        data: { ...this.state.data, rows: rows },
      })
    }
  }

  render() {
    toast.configure()
    const { loading, exporting, data, usertype, agentsList } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5 pb-5">
              {loading ? (
                <h2>Loading...</h2>
              ) : (
                <>
                  <h2>
                    {usertype && usertype.usertype_name
                      ? usertype.usertype_name
                      : ''}
                  </h2>
                  <div className="content-wrapper">
                    <MDBDataTable
                      sortRows={['enrolldate', 'usertype_assigned']}
                      responsive
                      striped
                      bordered
                      small
                      data={data}
                      className="text-capitalize text-truncate"
                      entries={50}
                      entriesOptions={[50, 100, 150]}
                    />
                    {agentsList && agentsList.length ? (
                      <div className="row justify-content-between">
                        <div className="pl-3"></div>
                        <div className="mr-2">
                          <MDBBtn color="primary" onClick={this._exportToCSV}>
                            {exporting ? 'Downloading...' : 'Download CSV'}
                          </MDBBtn>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default ViewUserTypeReport
