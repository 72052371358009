import { makeAutoObservable, toJS } from 'mobx'
import UserEopolicyService from './../../../shared/services/UserEopolicy.service'
import SystemSettingService from './../../../shared/services/SystemSetting.service'
import UserProfileService from './../../../shared/services/UserProfile.service'
import moment from 'moment'

const serializeDate = (dateVal) => {
  if (dateVal && typeof dateVal === 'string') {
    if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(dateVal)) {
      dateVal = moment(dateVal, 'YYYY-MM-DDThh:mm:ss.SSSZ').format('YYYY-MM-DD')
    }
  }

  if (dateVal && typeof dateVal === 'object') {
    dateVal = moment(dateVal).utc().format('YYYY-MM-DD')
  }

  return dateVal
}

const toDateObj = (dateVal) => {
  if (dateVal && typeof dateVal === 'string') {
    if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(dateVal)) {
      dateVal = moment(dateVal.split('T')[0], 'YYYY-MM-DD').toDate()
    } else if (/^\d{4}-\d{2}-\d{2}$/.test(dateVal)) {
      dateVal = moment(dateVal, 'YYYY-MM-DD').toDate()
    }
  }

  if (dateVal && typeof dateVal === 'object') return dateVal
}

const updateEopolicy = async (id, changes) => {
  let results = null
  try {
    results = await UserEopolicyService.update(id, changes)
    results = { ...results, ...changes }

    if (results?.effective_date)
      results.effective_date = toDateObj(results.effective_date)
    if (results?.expiration_date)
      results.expiration_date = toDateObj(results.expiration_date)

    return results
  } catch (ex) {
    console.error('ex: ', ex)
    throw new Error(`Unable to update agent eo policy. ${ex}`)
  }
}

const createEopolicy = async (policy) => {
  let results = null
  try {
    results = await UserEopolicyService.store({
      ...policy,
      user_id: UserProfileService.getUserId(),
    })
    results = { ...results, ...policy }

    if (results?.effective_date)
      results.effective_date = toDateObj(results.effective_date)
    if (results?.expiration_date)
      results.expiration_date = toDateObj(results.expiration_date)

    return results
  } catch (ex) {
    console.error('ex: ', ex)
    throw new Error(`Unable to create agent eo policy. ${ex}`)
  }
}

const GROUP_POLICY = {
	loaded: false,
	url: null
}

class EopolicyStore {
  constructor() {
    makeAutoObservable(this)
  }

  eopolicy = null

  fetchEopolicy = async () => {
    let eopolicy = {}

    try {
      eopolicy = await UserEopolicyService.search({
        search: { user_id: UserProfileService.getUserId() },
        pagination: false,
      })
      eopolicy = eopolicy && eopolicy?.models ? eopolicy.models.shift() : {}
    } catch (ex) {}

    if (eopolicy?.effective_date)
    	eopolicy.effective_date = moment(eopolicy.effective_date.split('T').shift()).toDate();
    if (eopolicy?.expiration_date)
    	eopolicy.expiration_date = moment(eopolicy.expiration_date.split('T').shift()).toDate();

    this.eopolicy = eopolicy || {}

    if (GROUP_POLICY.loaded !== true) {
    	try {
    		const Policy = ((await SystemSettingService.search({search: {setting_key: 'vendors---eo-group-policy'}}))?.models||[]).shift()
    		if (Policy && Policy.setting_value)
    			GROUP_POLICY.url = JSON.parse(Policy.setting_value)?.url
    		GROUP_POLICY.loaded = true;
    	}
    	catch (ex) {
    		GROUP_POLICY.loaded = true;
    	}
    }
  }

  getGroupUrl = () =>
  	GROUP_POLICY.loaded && GROUP_POLICY.url

  saveEopolicy = async (eopolicy) => {
    const getChanged = (orig) => {
      let fields = [
          'effective_date',
          'expiration_date',
          'eopolicy_num',
          'carrier_name',
          'eopolicy_pdf',
          'per_coverage_amt',
          'agg_coverage_amt',
        ],
        compA = {},
        compB = {}

      fields.forEach((fld) => {
        compA[fld] = null
        compB[fld] = null

        if (orig.hasOwnProperty(fld)) compA[fld] = orig[fld] ? orig[fld] : null
        if (eopolicy.hasOwnProperty(fld))
          compB[fld] = eopolicy[fld] ? eopolicy[fld] : null
      })

      if (JSON.stringify(compA) !== JSON.stringify(compB)) {
        fields.forEach((fld) => {
          if (['effective_date', 'expiration_date'].indexOf(fld) > -1) {
            if (serializeDate(compA[fld]) === serializeDate(compB[fld]))
              delete compB[fld]
            else compB[fld] = serializeDate(compB[fld])
          } else if (JSON.stringify(compA[fld]) === JSON.stringify(compB[fld]))
            delete compB[fld]
        })

        if (Object.keys(compB).length) return compB
      }

      return false
    }

    let results,
      eopolicyId =
        this.eopolicy && this.eopolicy?.id > 0 ? this.eopolicy.id : false,
      changes = getChanged(toJS(this.eopolicy))

    if (eopolicyId) {
      if (changes && Object.keys(changes).length)
        results = await updateEopolicy(eopolicyId, changes)
      if (results) this.eopolicy = { ...toJS(this.eopolicy), ...results }
      return toJS(this.eopolicy)
    }

    if (changes && Object.keys(changes).length) {
      this.eopolicy = await createEopolicy(changes)
      return toJS(this.eopolicy)
    }
  }
}

export default new EopolicyStore()
