import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { MDBContainer, MDBBtn } from 'mdbreact'
import { toast } from 'react-toastify'
import LeadPackageCards from './components/LeadPackageCards.component'
import PageHeading from './../../components/shared/PageHeading.component'
import AgentWalletPreview from './components/AgentWalletPreview.component'
import Store from './store'
import UserProfileService from './../../shared/services/UserProfile.service'
import AdminContentEditPopupComponent from './../../components/adminShared/AdminContentEditPopupComponent'
import ContentVideoComponent from '../../components/content/ContentVideo/ContentVideo.component'

import './OrderLeads.scss'

class Leads extends Component {
  state = {
    isLoading: false,
  }

  resolveData = async () => {
    this.setState({ isLoading: true })
    await Store.init()
    this.setState({ isLoading: false })
  }

  componentDidMount() {
    this.resolveData()
  }

  renderHeaderOpts() {
    if (
      UserProfileService.isA(
        ['agency-owner', 'system-admin', 'internal-admin'],
        true
      )
    )
      return (
        <div className="d-inline-block">
          <MDBBtn
            size="sm"
            tag="a"
            floating
            disabled={
              this.state.isLoading ||
              this.state.isPurchasing ||
              this.state.isTransferring
            }
            onClick={() => this.setState({ create: true })}
            gradient="blue"
            title={'Add New Order Leads'}
          >
            <div className="text-dark text-capitalize">
              <AdminContentEditPopupComponent
                type="RTE"
                actionType="add"
                slugName="order-leads"
                color="primary"
                callback={this.resolveData}
                isLink={true}
                forceOpen={this.state.create}
              />
            </div>
          </MDBBtn>
        </div>
      )
  }

  render() {
    toast.configure()

    return (
      <DashboardLayout>
        <main id="OrderLeadsPage" className="mainSection pb-5">
          <MDBContainer fluid className="mt-5">
            <PageHeading
              label={<>Order Leads {this.renderHeaderOpts()}</>}
              url={this.props?.match?.url}
              from={this.props?.location?.state?.from}
              btnGroup={
                <div className="agent-ledger-balance-widget">
                  <AgentWalletPreview />
                </div>
              }
            />
            <MDBContainer fluid className="mt-5">
              <ContentVideoComponent contentSlug={'lead-training-videos'} />
              {this.state.isLoading ? (
                <h2>Loading...</h2>
              ) : (
                <LeadPackageCards />
              )}
            </MDBContainer>
          </MDBContainer>
        </main>
      </DashboardLayout>
    )
  }
}

export default Leads
