import AdminApi from "./../../api/admin-api/admin-api";


const getPageById = async (id) =>
{
	return new Promise((resolve, reject) => {
		AdminApi
				.getPageContentById(id)
				.then(result => {
						if (result && result.data && result.data.data && result.data.data.hasOwnProperty('content'))
							return 					resolve(result.data.data);
						return reject("Unable to fetch the selected page content.");
					})
				.catch(error => reject(error.message));
	});
}


const ContentService = {
	getPageById: 									getPageById
};

export default ContentService;