const getPhone = (subsiteData, callback) =>
{
	callback = callback && typeof callback === 'function' ? callback : p => p;

	if (subsiteData?.s_phone_override)
		return callback(subsiteData.s_phone_override)

	if (subsiteData?.u_phone)
		return callback(subsiteData.u_phone)

	return false;
}

const getEmail = (subsiteData) =>
{
	if (subsiteData?.s_email_override)
		return subsiteData.s_email_override;

	if (subsiteData?.u_company_email)
		return subsiteData.u_company_email;

	if (subsiteData?.u_quote_email)
		return subsiteData.u_quote_email;

	if (subsiteData?.u_email)
		return subsiteData.u_email;

	return false;
}

export {getPhone, getEmail};