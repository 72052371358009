import { subNavOpts } from './../variables'
import moment from 'moment'

const getCategoryReports = (category) => {
  category =
    category && `${category}`.trim().length
      ? `${category}`.trim().toLowerCase()
      : ''

  if (!category || !subNavOpts.hasOwnProperty(category)) return false

  if (typeof subNavOpts[category] === 'object') return subNavOpts[category]
}

const getReportProfile = (category, report) => {
  category =
    category && `${category}`.trim().length
      ? `${category}`.trim().toLowerCase()
      : ''
  report =
    report && `${report}`.trim().length ? `${report}`.trim().toLowerCase() : ''

  if (!category || !subNavOpts.hasOwnProperty(category)) return false

  if (
    typeof subNavOpts[category] === 'object' &&
    report &&
    subNavOpts[category].hasOwnProperty(report)
  )
    return subNavOpts[category][report]
}

const isReportValid = (category, report) => {
  category =
    category && `${category}`.trim().length
      ? `${category}`.trim().toLowerCase()
      : ''
  report =
    report && `${report}`.trim().length ? `${report}`.trim().toLowerCase() : ''

  if (!category || !subNavOpts.hasOwnProperty(category)) return false

  if (
    typeof subNavOpts[category] === 'object' &&
    report &&
    subNavOpts[category].hasOwnProperty(report)
  )
    return true

  return false
}

const getDefaultReport = (category) => {
  let reports = getCategoryReports(category)
  if (reports && typeof reports === 'object')
    return Object.keys(reports).shift()
  return ''
}

const defineSubNavOpt = (category, report) => {
  category =
    category && `${category}`.trim().length
      ? `${category}`.trim().toLowerCase()
      : ''
  report =
    report && `${report}`.trim().length ? `${report}`.trim().toLowerCase() : ''

  // validate category & report usage

  // 1. is the category defined?
  // if not, its an invalid category.
  // override the value (or change?)
  if (!subNavOpts.hasOwnProperty(category)) category = 'my-production'
  const subNavOpt = subNavOpts[category]

  // 2. does category contain sub-nav-opts ?
  // if so, lets determine how to handle the
  // current report.
  if (typeof subNavOpts[category] === 'object') {
    // 2-A. if a category has sub-nav-opts, a report
    // MUST be selcted.  The ReportConfigurator will
    // assign a default value.  We do not need to do that here.
    //
    // 2-B. if the category has sub-nav-opts,
    // but a report wasnt selected, assigned a default
    // report now.
    //
    // Lets make sure the report is a valid report name.
    if (!report || (report && !subNavOpts[category].hasOwnProperty(report))) {
      // invalid report, change to default
      try {
        report = Object.keys(subNavOpts[category]).shift()
      } catch (ex) {
        console.error('FAILED TO VALIDATE REPORT. ', ex)
      }
    }
  }

  // 3. if the category only contains a string,
  // it's the name of the report function to
  // run.  In this case, any value in report is invalid.
  if (typeof subNavOpts[category] === 'string') report = ''

  return { subNavOpt, category, report }
}

const categoryHasReports = (category) =>
  subNavOpts.hasOwnProperty(category) &&
  typeof subNavOpts[category] === 'object'

const DEFAULT_TIMESPAN = 'weekly'
const today = moment()
const getDefaultStart = () => {
  switch (DEFAULT_TIMESPAN) {
    case 'weekly':
      return moment(today).startOf('isoweek').format('YYYY-MM-DD')
    case 'monthly':
      return moment(today).startOf('month').format('YYYY-MM-DD')
    default:
      break
  }
}

const getDefaultStop = () => {
  switch (DEFAULT_TIMESPAN) {
    case 'weekly':
      return moment(today).endOf('isoweek').format('YYYY-MM-DD')
    case 'monthly':
      return moment(today).endOf('month').format('YYYY-MM-DD')
    default:
      break
  }
}

const getGrowth = (prev, curr) => {
  if (!isNaN(prev) && !isNaN(curr))
    return prev > 0 ? ((curr / prev) * 100).toFixed(2) : 0
}

const getGrowthV2 = (prev, curr) => {
  if (!isNaN(prev) && prev !== null && !isNaN(curr) && curr !== null)
    return prev > 0 ? (((curr - prev) / prev) * 100).toFixed(2) : 0
}

export {
  getReportProfile,
  isReportValid,
  getDefaultReport,
  defineSubNavOpt,
  categoryHasReports,
  getDefaultStart,
  getDefaultStop,
  getGrowth,
  getGrowthV2,
}
