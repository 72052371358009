import React, { useState } from 'react'
import LoadingSpinner from '../../shared/LoadingSpinner.component'
import UploadService from '../../../shared/services/Upload.service'
import { b64toBlob } from '../../../shared/utilities/b64toBlob.function'
import {
  MDBFileInput,
  MDBBtn,
  MDBAlert,
  MDBModalHeader,
  MDBModalBody,
  MDBModal,
} from 'mdbreact'
import { allowedSizeByUploadType } from '../../../constants/allowedMediaSize'

import './Modal.component.scss'

const Modal = ({
  isOpen,
  uploadType,
  appendFormData,
  validationRules,

  // Resolve/reject modal promise.
  onResolve,
  onReject,

  // If true, do not close modal after save attempt.
  preventAutoClose,
  // If preventAutoClose == true, call onSuccess handler to invoke external calls.
  onSuccess,

  // Pass string or jsx.
  modalContent,
  // Options (top|bottom), only used with `modalContent` property.
  modalContentPosition,
  // Positional modal content.
  modalContentTop,
  modalContentBottom,
}) => {
  const [isUploading, setIsUploading] = useState(false),
    [uploadData, setUploadData] = useState(false),
    [validation, setValidation] = useState(false)

  const cancelModal = () => {
    onReject()
  }

  const toggleModal = () => {
    onResolve()
  }

  const getContentType = (data) => {
    let match = data && data.split(',')[0].match(/\w+\/\w+/)
    return match && match.shift()
  }

  const validate = (payload) => {
    if (!payload || !payload?.filename || !payload?.data) {
      setValidation(['Missing or invalid file selected. Please try again.'])
      return false
    }

    if (validationRules) {
      if (
        validationRules?.contentType &&
        !validateContentType(
          getContentType(payload.data),
          Array.isArray(validationRules?.contentType)
            ? validationRules.contentType
            : [validationRules.contentType]
        )
      ) {
        setValidation([
          'Invalid filetype selected.  Please select a different file & try again.',
        ])
        return false
      }

      return true
    }

    return true
  }

  const validateContentType = (uploadContentType, permittedContentTypes) => {
    if (permittedContentTypes.length > 0) {
      if (permittedContentTypes.includes(uploadContentType))
        return !!uploadContentType
      console.log(`Invalid filetype: ${uploadContentType}`)
      return false
    }

    return !!uploadContentType
  }

  const onSelectFile = (files) => {
    let receipts = []

    Object.values(files).forEach((file) => {
      const reader = new FileReader()

      reader.addEventListener('load', async () => {
        const payload = { filename: file?.name, data: reader.result }

        // Validate file size
        if (file.size > allowedSizeByUploadType[uploadType]) {
          setValidation([
            'Selected file exceeds max file size.  Please try another file.',
          ])
          return false
        }
        // Validate request, if necessary.
        if (!validate(payload)) return false

        // Build payload to send file to the server.
        const formData = new FormData()
        if (appendFormData && typeof appendFormData === 'object')
          Object.keys(appendFormData).forEach((attrib) =>
            formData.append(attrib, appendFormData[attrib])
          )

        formData.append('upload_type', uploadType)
        formData.append(
          'base_64',
          b64toBlob(payload.data.split(',')[1], getContentType(payload.data))
        )
        formData.append(`filename`, file.name)
        receipts.push(formData)

        if (receipts.length === Object.values(files).length) {
          setUploadData(receipts)
        }
      })
      reader.readAsDataURL(file)
    })
  }

  const uploadSequentially = async (uploadData, index = 0) => {
    if (index < uploadData.length) {
      const url = await UploadService.upload(uploadData[index])
      const remainingUrls = await uploadSequentially(uploadData, index + 1)

      return [url, ...remainingUrls]
    } else return []
  }

  const upload = async () => {
    setIsUploading(true)

    let urls
    try {
      urls = await uploadSequentially(uploadData)

      if (typeof onSuccess === 'function') onSuccess(urls)

      setValidation(true)
    } catch (ex) {
      setIsUploading(false)
      setValidation([`${ex}`])
      return
    }

    setUploadData(true)
    setIsUploading(false)

    // if (preventAutoClose && typeof onSuccess === 'function')
    //   onSuccess(urls)

    setTimeout(() => {
      setUploadData(false)
      if (!preventAutoClose) {
        onResolve(urls)
      } else {
        setIsUploading(false)
        setUploadData(false)
        setValidation(false)
      }
    }, 3500)
  }

  const isDisabled = () => {
    return (
      isUploading || uploadData === true || !uploadType || validation === true
    )
  }

  const hasUploaded = () => {
    return uploadData === true || validation === true
  }

  const notifyInvalidUploadType = () => {
    if (!uploadType)
      return (
        <MDBAlert color="danger" className="text--center">
          <div>
            <h4>
              <strong>Upload type not set! Can't Upload!</strong>
            </h4>
          </div>
        </MDBAlert>
      )
  }

  const showLoadingSpinner = () => {
    if (isUploading)
      return (
        <div className="loading-wrapper">
          <LoadingSpinner size="md" isActive={true} />
        </div>
      )
  }

  const getAlertColor = () => {
    if (isUploading) return 'info'
    if (validation === true) return 'success'
    if (Array.isArray(validation) && validation.length > 0) return 'danger'
    return 'secondary'
  }

  const showTopModalContent = () => {
    if (modalContentTop) return modalContentTop

    if (
      modalContent &&
      (!modalContentPosition || modalContentPosition === 'top')
    )
      return modalContent
  }

  const showBottomModalContent = () => {
    if (modalContentBottom) return modalContentBottom

    if (
      modalContent &&
      (!modalContentPosition || modalContentPosition === 'bottom')
    )
      return modalContent
  }

  const showInput = () => {
    // InputFile.propTypes = {
    //   btnColor: PropTypes.string,
    //   btnTitle: PropTypes.string,
    //   className: PropTypes.string,
    //   multiple: PropTypes.bool,
    //   reset: PropTypes.bool,
    //   resetAriaLabel: PropTypes.string,
    //   resetClassName: PropTypes.string,
    //   reverse: PropTypes.bool,
    //   textFieldTitle: PropTypes.string
    // };

    // InputFile.defaultProps = {
    //   btnTitle: 'Choose file',
    //   textFieldTitle: 'Upload your file',
    //   btnColor: 'primary',
    //   reset: false,
    //   reverse: false
    // };

    return (
      <>
        <MDBFileInput
          reset
          multiple
          getValue={(value) => onSelectFile(value)}
        />
        {Array.isArray(validation) && validation.length ? (
          validation.map((v, i) => (
            <div>
              <small key={`media-upload-modal-error-${i}`}>
                <strong>{v}</strong>
              </small>
            </div>
          ))
        ) : validation === true ? (
          <div>
            <small>
              <strong>
                Upload Success!{' '}
                {preventAutoClose ? '' : 'Closing window momentarily.'}
              </strong>
            </small>
          </div>
        ) : (
          <></>
        )}
      </>
    )
  }

  return (
    <div className="MediaUploaderModalComponent">
      <MDBModal size="lg" isOpen={isOpen} toggle={cancelModal}>
        <MDBModalHeader toggle={cancelModal}>Upload Receipts</MDBModalHeader>
        <MDBModalBody className={isUploading ? 'is-uploading' : ''}>
          {notifyInvalidUploadType()}
          <form>
            {showLoadingSpinner()}
            <MDBAlert
              className={hasUploaded() ? 'has-uploaded' : ''}
              color={getAlertColor()}
            >
              {showTopModalContent()}
              {showInput()}
              {showBottomModalContent()}
            </MDBAlert>
            {uploadData ? (
              <MDBBtn
                color="primary"
                size={'sm'}
                block
                disabled={isDisabled()}
                onClick={upload}
                type="button"
                className="mum-btn"
              >
                {isUploading ? 'Uploading ...' : 'Upload'}
              </MDBBtn>
            ) : (
              <></>
            )}
            <MDBBtn
              color="warning"
              size={'sm'}
              block
              disabled={isUploading}
              onClick={toggleModal}
              type="button"
              className="mum-btn"
            >
              {uploadData === true ||
              validation === true ||
              preventAutoClose === true
                ? 'Close'
                : 'Cancel'}
            </MDBBtn>
          </form>
        </MDBModalBody>
      </MDBModal>
    </div>
  )
}

export default Modal
