import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import AdminApi from './../../../../api/admin-api/admin-api'
import { WebsiteInfoStore } from './../../stores'
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCollapse,
  MDBAlert,
  MDBContainer,
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCollapseHeader,
} from 'mdbreact'
import { UIInput } from './../../../../components/forms/form-fields'

import './TestimonialForm.component.scss'

const saveTimer = {}

const TestimonialForm = () => {
  const [formError, setFormError] = useState(null),
    [formSuccess, setFormSuccess] = useState(null),
    [accordionState, setAccordionState] = useState({ collapseID: 0 })

  const toggleCollapse = (collapseID) => () => {
    setAccordionState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : '',
    }))
  }

  const onChangeValue = (event) => {
    const [name, idx] = `${event.target.name}`.split('_')
    WebsiteInfoStore.siteDetails.testimonials[idx][name] = event.target.value
  }

  const showFormError = (err) => {
    setFormError(err)
    saveTimer.timer = setTimeout(() => setFormError(null), 4000)
  }

  const validate = () => {
    const { testimonials, testimonial_timer } = WebsiteInfoStore.siteDetails
    for (let testimonial of testimonials) {
      if (!testimonial.author) {
        showFormError('Please enter author.')
        return false
      }

      if (!testimonial.testimonial) {
        showFormError('Please enter testimonial content.')
        return false
      }
    }

    if (testimonial_timer) {
      if (testimonial_timer > 60 || testimonial_timer < 0) {
        showFormError('Testimonial timer must be between 0 and 60.')
        return false
      }
    }

    if (!testimonial_timer) WebsiteInfoStore.siteDetails.testimonial_timer = 0

    return true
  }

  const update = async () => {
    if (!(await validate())) return false

    if (WebsiteInfoStore.isLoading || WebsiteInfoStore.isSaving) return

    let form_data = new FormData()
    const { testimonials, testimonial_timer } = WebsiteInfoStore.siteDetails
    form_data.append(
      'testimonials',
      typeof testimonials === 'object'
        ? JSON.stringify(testimonials)
        : testimonials
    )
    form_data.append('id', WebsiteInfoStore.siteDetails?.site_id)
    form_data.append('testimonial_timer', testimonial_timer)

    WebsiteInfoStore.isSaving = true

    AdminApi.updateWebsiteInfo(form_data)
      .then((result) => {
        setFormSuccess('Successfully updated Testimonials.')

        if (saveTimer.timer) {
          window.clearTimeout(saveTimer.timer)
          saveTimer.timer = null
        }

        saveTimer.timer = setTimeout(() => setFormSuccess(null), 4000)

        WebsiteInfoStore.isSaving = false
      })
      .catch(() => {
        showFormError('Failed to update Testimonials')
        WebsiteInfoStore.isSaving = false
      })
  }

  const remove = (index) => {
    WebsiteInfoStore.siteDetails.testimonials.splice(index, 1)

    if (WebsiteInfoStore.siteDetails.testimonials.length === 0) {
      WebsiteInfoStore.siteDetails.testimonials = []
      update()
    }
  }

  const add = () => {
    WebsiteInfoStore.siteDetails.testimonials.unshift({
      author: '',
      testimonial: '',
    })
    setAccordionState({ collapseID: 0 })
  }

  return (
    <form
      id="TestimonialFormComponent"
      noValidate
      className="container-fluid mt-5"
    >
      <MDBRow>
        <MDBCol>
          <h5 className="text--blue font--lato fw--500">Client Testimonials</h5>
        </MDBCol>
        <MDBCol>
          <MDBBtn
            type="button"
            onClick={add}
            className="btn-success float-right btn-create"
          >
            <MDBIcon icon="plus" />
            &nbsp;New
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBContainer className="accordion md-accordion accordion-1">
        {WebsiteInfoStore.siteDetails.testimonials &&
        WebsiteInfoStore.siteDetails.testimonials.length > 0 ? (
          WebsiteInfoStore.siteDetails.testimonials.map((t, index) => {
            return (
              <React.Fragment key={index}>
                <MDBCard style={{ backgroundColor: 'transparent' }}>
                  <MDBCollapseHeader
                    onClick={toggleCollapse(index)}
                    className="text-uppercase blue lighten-3 z-depth-1"
                    tagClassName="d-flex justify-content-between align-items-center"
                  >
                    <span className="white-text font-weight-bold">
                      {t?.author || ''}
                    </span>
                    <MDBIcon
                      icon={
                        `${accordionState.collapseID}` === `${index}`
                          ? 'angle-up'
                          : 'angle-down'
                      }
                      className="white-text"
                      size="2x"
                    />
                  </MDBCollapseHeader>
                  <MDBCollapse
                    id={`testimonial-collapse-${index}`}
                    isOpen={accordionState.collapseID === index}
                  >
                    <MDBCardBody>
                      <MDBRow>
                        <MDBCol>
                          <UIInput
                            label="Testimonial"
                            type="textarea"
                            className="form-control"
                            name={`testimonial_${index}`}
                            value={t?.testimonial || ''}
                            onChange={onChangeValue}
                            rows={5}
                            hint="Enter your Client Testimonial here."
                          />
                          <br />
                          <UIInput
                            label="Author"
                            type="text"
                            className="form-control"
                            name={`author_${index}`}
                            value={t?.author || ''}
                            onChange={onChangeValue}
                            hint="Testimonial Author, Location"
                          />
                          <MDBBtn
                            type="button"
                            color="primary"
                            onClick={() => remove(index)}
                            className="btn-sm btn-delete float-right"
                          >
                            <MDBIcon icon="trash" />
                            &nbsp;Remove
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCollapse>
                </MDBCard>
              </React.Fragment>
            )
          })
        ) : (
          <></>
        )}
        <MDBRow>
          <MDBCol className="form-group" size="12">
            <label>Time in seconds between slides changes</label>
            <input
              type="number"
              className="form-control"
              name="testimonial_timer"
              id="testimonial_timer"
              value={WebsiteInfoStore.siteDetails?.testimonial_timer || ''}
              onChange={(e) => {
                WebsiteInfoStore.siteDetails.testimonial_timer = e.target.value
              }}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBRow>
        <MDBCol className="form-group">
          <MDBBtn color="indigo" type="button" onClick={update}>
            {WebsiteInfoStore.isSaving ? 'Saving...' : 'Save'}
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBCollapse isOpen={!!formError}>
            <MDBAlert color="danger">{formError}</MDBAlert>
          </MDBCollapse>
          <MDBCollapse isOpen={!!formSuccess}>
            <MDBAlert color="success">{formSuccess}</MDBAlert>
          </MDBCollapse>
        </MDBCol>
      </MDBRow>
    </form>
  )
}

export default observer(TestimonialForm)
