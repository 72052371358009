import { makeAutoObservable, toJS } from 'mobx'
import {
  KnowledgebaseArticleFactory,
  KnowledgebaseCategoryFactory,
} from './../../../shared/factories'

class KnowledgebaseStore {
  isLoading = false
  isFetching = false
  isSaving = false

  Article = null
  Articles = []
  articlesPagination = { per_page: 50 }
  articlesSearch = ''
  articlesOrderBy = { id: 'DESC' }

  Category = null
  categoryDetail = {}
  Categories = {}
  categoriesPagination = { per_page: 50 }
  categoriesSearch = ''
  categoriesOrderBy = { c_name: 'ASC' }

  constructor() {
    makeAutoObservable(this)
  }

  init = async () => {
    this.isLoading = true

    const [Articles, Categories] = await Promise.all([
      KnowledgebaseArticleFactory.search({
        pagination: this.articlesPagination,
      }),
      KnowledgebaseCategoryFactory.search({ pagination: false }),
    ])

    this.Articles = (Articles && Articles?.models) || []
    this.articlesPagination = (Articles && Articles?.pagination) || {
      per_page: 50,
    }
    this.Categories = {}
    ;(Categories || []).forEach(
      (Category) => (this.Categories[Category.id()] = Category)
    )

    this.isLoading = false
  }

  fetchCategoryDetails = async (Category) => {
    if (!Category.article() || !Category.article().length)
      (await Category.article(true).all()).forEach((Article) =>
        Category.article().push(Article)
      )

    this.categoryDetail.articles = Category.article().length
  }

  searchArticles = async ({ search, orderBy, pagination }) => {
    if (!this.isLoading) this.isLoading = true

    search = search === false ? {} : search ? search : this.articlesSearch
    orderBy =
      orderBy === false
        ? { id: 'DESC' }
        : orderBy
        ? orderBy
        : this.articlesOrderBy
    pagination =
      pagination === false
        ? pagination
        : pagination
        ? pagination
        : this.articlesPagination

    this.articlesSearch = search
    this.articlesOrderBy = orderBy
    this.articlesPagination = pagination

    const [Articles] = await Promise.all([
      KnowledgebaseArticleFactory.search({
        search,
        order_by: orderBy,
        pagination,
      }),
    ])

    this.Articles = (Articles && Articles?.models) || []
    this.articlesPagination = (Articles && Articles?.pagination) || {
      per_page: 50,
    }

    this.isLoading = false
  }

  startNewArticle = async () => {
    this.Article = await KnowledgebaseArticleFactory.create({
      category_id:
        this.Category?.id() > 0
          ? this.Category.id()
          : Object.keys(this.Categories).shift(),
    })
  }

  searchCategories = async ({ search, orderBy, pagination }) => {
    if (!this.isLoading) this.isLoading = true

    search = search === false ? {} : search ? search : this.categoriesSearch
    orderBy =
      orderBy === false
        ? { id: 'DESC' }
        : orderBy
        ? orderBy
        : this.categoriesOrderBy
    pagination =
      pagination === false
        ? pagination
        : pagination
        ? pagination
        : this.categoriesPagination

    this.categoriesSearch = search
    this.categoriesOrderBy = orderBy
    this.categoriesPagination = pagination

    const [Categories] = await Promise.all([
      KnowledgebaseCategoryFactory.search({
        search,
        order_by: orderBy,
        pagination,
      }),
    ])
    ;(Array.isArray(Categories)
      ? Categories
      : Categories && Array.isArray(Categories?.models)
      ? Categories.models
      : []
    ).forEach((Category) => (this.Categories[Category.id()] = Category))

    this.categoriesPagination = (Categories && Categories?.pagination) || {
      per_page: 50,
    }

    this.isLoading = false
  }

  startNewCategory = async () => {
    this.Category = await KnowledgebaseCategoryFactory.create({ c_name: '' })
  }

  editArticle = async (Article) => {
    this.Article = Article
  }

  editCategory = async (Category) => {
    if (
      !this.Category ||
      (this.Category && this.Category.id() !== Category.id())
    )
      await this.fetchCategoryDetails(Category)

    this.Category = Category
  }

  toArray = (obj) => Object.values(obj && typeof obj === 'object' ? obj : {})
}

export default new KnowledgebaseStore()
