export default {
  footer: {
    locations: {
      enabled: true,
    },
  },
  compliance: {
    enabled: false,
    entity_name:
      'Licensed Agency is Richard Banville LLC d/b/a USA Benefits Group Insurance',
  },
}
