import React from 'react'
import StorageService from './../../../shared/services/Storage.service'
import UserProfileService from './../../../shared/services/UserProfile.service'
import Store from './../store'

const getUserId = () =>
{
  try {
    return UserProfileService.getUserId(true);
  }
  catch (ex) {
    return false;
  }
}

export default class CacheBuster extends React.Component {
  state = {
    isLoading: true, 				// Is fetching meta.json content.
    isLatestVersion: false, // Boolean indicator.
    availVersion: null, 		// Whats found in meta.json.
    runningVersion: null, 	// Whats stored in cookie.
  }

  componentDidMount ()
  {
  	// 1. Clear any previous version check flags.
    StorageService.clear('check_version')

    // 2. Fetch the lastest version indicated in the 
    // meta.json remote file (updates at time of deployment).
    Store.fetchVersionMeta()
    	.then(meta => {

    		// Get the version ## from meta response & the 
    		// current version stored in the cookie.
    		let isLatestVersion 	=		true;
        const availVersion 		= 	meta?.version,
        			runningVersion 	= 	Store.USE_COOKIE_VERSION === true ? Store.getCookie('cookie_app_version') : global?.appVersion;

        // If the current version was not available,
        // update `isLatestVersion` flag to prompt reload.
				if (typeof runningVersion === 'undefined' || runningVersion === '')
					isLatestVersion = false;

       	// If the latest version is different than the current
       	// version, update `isLatestVersion` flag to prompt reload.
        else if (Store.semverHasChanged(availVersion, runningVersion))
        	isLatestVersion = false;

        // Run a safety net to prevent an infinite refresh loop
        if (!isLatestVersion) {
        	let count = StorageService.get('check_version_count');
        	count = isNaN(count) || count === null || typeof count === 'undefined' ? 0 : parseInt(count) + 1;
        	if (count >= 10) {
        		isLatestVersion = true;
        		Store.safetyNet = true;
            console.warn("[warn.esn]",{user_id: getUserId(), availVersion, runningVersion, USE_COOKIE_VERSION: Store.USE_COOKIE_VERSION === true});
        	}

        	StorageService.set('check_version_count', count);
        }

        return this.setState({isLoading: false,isLatestVersion,availVersion,runningVersion})
    	});
  }

  render () 
  {
  	return this.props.children(this.state);
  }
}
