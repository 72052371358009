import React from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import ContentHtml from './../../components/content/ContentHtml/ContentHtml.component'
import { MDBContainer, MDBCard, MDBCardHeader, MDBCardBody } from 'mdbreact'

import './ReferralProgram.page.scss'

class ReferralProgramPage extends React.Component {
  render() {
    return (
      <DashboardLayout>
        <main id="ReferralProgramPage" className="adminStyle mainSection mb-5">
          <MDBContainer fluid className="mt-5">
            <div style={{ position: 'relative' }}>
              <h2 className="h2-responsive">Referral Program</h2>
              <hr />
              <MDBCard>
                <MDBCardHeader color="indigo"></MDBCardHeader>
                <MDBCardBody>
                  <ContentHtml contentId="35" />
                </MDBCardBody>
              </MDBCard>
            </div>
          </MDBContainer>
        </main>
      </DashboardLayout>
    )
  }
}

export default ReferralProgramPage
