import React from 'react'
import { observer } from 'mobx-react-lite'
import { MDBSwitch } from 'mdbreact'
import './UIList.component.scss'

const UIList = ({
  list,
  label,
  value,
  onChange,
  checkedHandle,
  hasVerticalBorder,
}) => {
  return (
    <div>
      <table
        id="UIListComponent"
        width="100%"
        className={hasVerticalBorder && 'hasVerticalBorder'}
      >
        <thead></thead>
        <tbody>
          {list?.map((item, d) => {
            return (
              <tr key={`division-carrier-${d}`}>
                <td>{item[`${label}`]}</td>
                <td className="switch-cell">
                  <MDBSwitch
                    labelLeft={''}
                    labelRight={''}
                    checked={checkedHandle(item[`${value}`])}
                    getValue={(r) =>
                      onChange({ target: { name: item[`${value}`], value: r } })
                    }
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default observer(UIList)
