import React from "react";
import { MDBPagination, MDBPageItem, MDBPageNav, MDBCol, MDBRow } from "mdbreact";

import "./PaginationComponent.scss";

class PaginationComponent extends React.Component {

  state = {
    loading: 										false,
    page: 											0,
    total:  										0,
    total_pages: 								[],
    per_page:  									4,
    intermediate: 							[]
  };

  #onPageSelect = (page, perPage) => 
  {
  	if (isNaN(parseInt(page))) return;
    this.setState({page: page, per_page: perPage},() => this.props.onPageSelect(page, perPage));
  }

  #calculatePages = (page, per_page, total) => 
  {
    let total_pages 				= 		Math.ceil(total/per_page),
    		intermediate 			= 		[];

		if (total_pages > 2) {
			for (let i = page; i < total_pages; i++)
				intermediate.push(i);

			// If many pages exist, truncate the options
			// to the earliest & latest (4) options.
			if (intermediate.length > 10) {
				intermediate = [
					intermediate.shift(),
					intermediate.shift(),
					intermediate.shift(),
					intermediate.shift(),
					intermediate.shift(),
					'...'
				].concat([
					intermediate.pop(),
					intermediate.pop(),
					intermediate.pop(),
					intermediate.pop(),
					intermediate.pop(),
					].reverse());
			}

			// if (intermediate.indexOf(this.state.page) > -1)
			// 	intermediate.splice(intermediate.indexOf(this.state.page), 1);
			
		}

    this.setState({page, per_page, total_pages, total, intermediate});
  }

  #setPaginationProps = () => 
  {
  	let page 							=			0,
  			perPage 					=			4,
  			total 						=			0;

  	if (this.props.hasOwnProperty('pagination') && typeof this.props.pagination === 'object') {
	  	let pagination  		=			this.props.pagination;
			page 								= 		(parseInt(pagination.page) === 0 || pagination.page > 0 ? parseInt(pagination.page) : page);
			perPage 						=			(parseInt(pagination.per_page) === 0 || pagination.per_page > 0 ? parseInt(pagination.per_page) : perPage);
			total 							=			(parseInt(pagination.total) === 0 || pagination.total > 0 ? parseInt(pagination.total) : total);
  		return 										this.#calculatePages(page, perPage, total);
  	}

		page 									= 		(parseInt(this.props.page) === 0 || this.props.page > 0 ? parseInt(this.props.page) : page);
		perPage 							=			(parseInt(this.props.perPage) === 0 || this.props.perPage > 0 ? parseInt(this.props.perPage) : perPage);
		total 								=			(parseInt(this.props.total) === 0 || this.props.total > 0 ? parseInt(this.props.total) : total);
		return 											this.#calculatePages(page, perPage, total);
  }

  componentDidMount ()
  {
  	this.#setPaginationProps();
  }

	render () 
	{
		const {page, total_pages, intermediate} = this.state;

		return (
	    <MDBRow between className="PaginationComponent">
        <div>
          Page <strong>{page+1}</strong> of <strong>{total_pages}</strong>
        </div>
        <MDBCol>
          <MDBPagination className="mb-5 justify-content-end flex-wrap">
            <MDBPageItem disabled={page === 0 ? true : false} onClick={() => {this.#onPageSelect(page-1, this.state.per_page)}}>
              <MDBPageNav>
                <span>Previous</span>
	            </MDBPageNav>
  		      </MDBPageItem>
  		      {
  		      	intermediate.map(p => {
  		      		return 	<MDBPageItem key={'page-idx-'+p} disabled={p === this.state.page} className={p==='...'?'no-click':''} onClick={() => {this.#onPageSelect(p, this.state.per_page)}}>
						            	<MDBPageNav>
						                <span>{p!=='...'?p+1:p}</span>
						            	</MDBPageNav>
						        		</MDBPageItem>
  		      	})
  		      }
        		<MDBPageItem disabled={page === total_pages-1 ? true : false} onClick={() => {this.#onPageSelect(page+1, this.state.per_page)}}>
            	<MDBPageNav>
                <span>Next</span>
            	</MDBPageNav>
        		</MDBPageItem>
    			</MDBPagination>
				</MDBCol>
			</MDBRow>
		)
	}
}

export default PaginationComponent;