import React from 'react'
import {MDBContainer,MDBRow,MDBCol,MDBIcon} from 'mdbreact';

import './ApptBooker.component.scss';

const ApptBooker = ({subsiteData}) => 
{
	const apptLink = subsiteData?.s_book_appt_link;

	const openLink = event => {
		if (event) event.preventDefault()
		window.open(apptLink);
		return false;
	}

	return (
      <MDBContainer id="ApptBookerComponent" fluid>
      	<MDBRow>
	      	<MDBCol>
	      		{
	      			apptLink
	      				? <a className="appt-label" href={apptLink} onClick={openLink} >
				      			Book your appointment now!
				      		</a>
				      	: <>&nbsp;</>
	      		}
	      	</MDBCol>
	      	<MDBCol>
	      		{
	      			apptLink
	      				? <a className="appt-icon" href={apptLink} onClick={openLink} >
				      			<MDBIcon far icon="calendar-alt" />
				      		</a>
				      	: <>&nbsp;</>
	      		}
	      	</MDBCol>
      	</MDBRow>
      </MDBContainer>
		)
}

export default ApptBooker;