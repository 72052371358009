import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import {
  MDBBtn,
  MDBAlert,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from 'mdbreact'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import UserService from './../../../../shared/services/User.service'

const UserActiveButton = ({ onChange }) => {
  const [isLoading, setIsLoading] = useState(false)

  const meetsRequirement =
    UserProfileService.isAssumed() &&
    UserProfileService.isA(
      ['system-admin', 'agency-owner', 'internal-admin'],
      true
    )

  const changeIsActive = async (active) => {
    setIsLoading(true)

    let today = new Date().toISOString().slice(0, 10)

    try {
      await UserService.update(UserProfileService.getUserId(), {
        u_active: active,
      })
      UserProfileService.set('u_active', active)
      onChange(true)
      setIsLoading(false)
      UserProfileService.track({
        event_type: 'user.active-status-change.success',
        payload: { u_active: active },
      })
    } catch (error) {
      onChange(error?.message)
      setIsLoading(false)
      UserProfileService.track({
        event_type: 'user.active-status-change.failure',
        event_descrip: error?.message,
      })
    }
  }

  const getLabel = (ind) => {
    switch (parseInt(ind)) {
      case 1:
        return 'Active'
      case 0:
        return 'Deactivated'
      case -1:
        return 'Protected'
      case -2:
        return 'Blacklisted'
      default:
        return ''
    }
  }

  const getAlert = (ind) => {
    if (isLoading) {
      switch (parseInt(ind)) {
        case 1:
          return (
            <MDBAlert className="user-active-alert" color="success">
              Saving Status Change
            </MDBAlert>
          )
        case 0:
          return (
            <MDBAlert className="user-active-alert" color="warning">
              Saving Status Change
            </MDBAlert>
          )
        case -1:
          return (
            <MDBAlert className="user-active-alert" color="info">
              Saving Status Change
            </MDBAlert>
          )
        case -2:
          return (
            <MDBAlert className="user-active-alert" color="danger">
              Saving Status Change
            </MDBAlert>
          )
        default:
          return ''
      }
    }

    switch (parseInt(ind)) {
      case 1:
        return (
          <MDBAlert className="user-active-alert" color="success">
            Change Account '{getLabel(ind)}' Status
          </MDBAlert>
        )
      case 0:
        return (
          <MDBAlert className="user-active-alert" color="warning">
            Change Account '{getLabel(ind)}' Status
          </MDBAlert>
        )
      case -1:
        return (
          <MDBAlert className="user-active-alert" color="info">
            Change Account '{getLabel(ind)}' Status
          </MDBAlert>
        )
      case -2:
        return (
          <MDBAlert className="user-active-alert" color="danger">
            Change Account '{getLabel(ind)}' Status
          </MDBAlert>
        )
      default:
        return ''
    }
  }

  const renderDropDown = () => {
    const activeAlert = getAlert(UserProfileService.get('u_active'))

    if (meetsRequirement)
      return (
        <div className="user-active-btn">
          <MDBDropdown dropup className="user-active-dropdown">
            <MDBDropdownToggle caret>{activeAlert}</MDBDropdownToggle>
            <MDBDropdownMenu basic>
              <MDBDropdownItem onClick={() => changeIsActive(1)}>
                <MDBAlert className="user-active-alert" color="success">
                  ACTIVE
                </MDBAlert>
              </MDBDropdownItem>
              <MDBDropdownItem onClick={() => changeIsActive(0)}>
                <MDBAlert className="user-active-alert" color="warning">
                  DEACTIVATED
                </MDBAlert>
              </MDBDropdownItem>
              <MDBDropdownItem onClick={() => changeIsActive(-1)}>
                <MDBAlert className="user-active-alert" color="info">
                  PROTECTED
                </MDBAlert>
              </MDBDropdownItem>
              <MDBDropdownItem onClick={() => changeIsActive(-2)}>
                <MDBAlert className="user-active-alert" color="danger">
                  BLACKLISTED
                </MDBAlert>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </div>
      )
    return <></>
  }

  return <>{renderDropDown()}</>
}

export default observer(UserActiveButton)
