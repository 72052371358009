import React, { Component, Fragment } from 'react'
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBSpinner,
  MDBSwitch,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBIcon,
} from 'mdbreact'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'
import UsertypeService from '../../shared/services/Usertype.service'
import { TERM_SLUGS } from './components/AdminTermsTable/AdminTermsTable.component'
import MediaUploaderModal from '../../components/modals/MediaUploaderModal'

import './AddTerm.scss'

class AddResource extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      breakWidth: 1300,
      windowWidth: 0,
      term_name: '',
      term_link: '',
      term_id: null,
      is_enroll: false,
      is_leader: false,
      is_10x: false,
      usertype_id: null,
      term_slug: '',
      usertypes: [],
      errorMessage: '',
      formError: false,
    }
  }

  templateDocument =
    'https://firebasestorage.googleapis.com/v0/b/totemic-bonus-239316.appspot.com/o/terms%2Fterms-template.docx?alt=media'

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params })

    if (params && params.id) {
      this.setState({ term_id: params.id })
      this.fetchTermsData(params.id)
    }

    this.fetchUserTypes()
    // this.setState({editorState});
  }

  //Change event for input fields
  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  validate = async () => {
    // const regLink = /^((http(s)?:\/\/)(www\.)?)+[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g;
    const regHttpLink = /^(http(s)?:\/\/).*/i
    const { term_name, term_link } = this.state

    if (!(term_name && term_name.trim())) {
      this.setState({ formError: true, errorMessage: 'Term Name Required!' })
      return
    }

    if (term_link && !regHttpLink.test(term_link)) {
      const { term_link } = this.state
      this.setState({ term_link: `http://${term_link}` })
    }

    /* if (!regLink.test(this.state.term_link)) {
            this.setState({formError: true, errorMessage: 'Invalid Link format'});
            return;
        }*/

    this.setState({ formError: false, errorMessage: '' })
  }

  //create terms
  createTerms = async () => {
    await this.validate()
    const {
      loading,
      term_name,
      is_enroll,
      is_10x,
      is_leader,
      term_slug,
      usertype_id,
      errorMessage,
      formError,
    } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }
    const data = {
      term_name: term_name,
      is_enroll: is_enroll ? 1 : 0,
      is_10x: is_10x ? 1 : 0,
      is_leader: is_leader ? 1 : 0,
      term_slug: term_slug,
      usertype_id: usertype_id,
      table_name: 'terms',
    }

    const form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })

    AdminApi.createTerms(form_data)
      .then((result) => {
        this.setState({ loading: false })
        toast.success('Term created successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })

        if (result?.data?.data?.id) {
          if (
            !`${window.location.href}`.includes(
              `/add-term/${result.data.data.id}/edit`
            )
          )
            window.location = `/add-term/${result.data.data.id}/edit`
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchTermsData = async (id) => {
    this.setState({ loading: true })
    AdminApi.getTermsById(id)
      .then((result) => {
        this.setState({ loading: false })
        if (result && result.data && result.data.data) {
          const content = result.data.data
          this.setState({
            term_name: content.term_name,
            term_link: content.term_link,
            is_enroll: content.is_enroll === 1 ? true : false,
            is_leader: content.is_leader === 1 ? true : false,
            is_10x: content.is_10x === 1 ? true : false,
            usertype_id: content.usertype_id,
            term_slug: content.term_slug,
          })
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  updateTerms = async () => {
    await this.validate()
    const id = this.state.urlParams.id
    const {
      loading,
      term_name,
      is_enroll,
      is_10x,
      is_leader,
      term_slug,
      usertype_id,
      errorMessage,
      formError,
    } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }
    const data = {
      term_name: term_name,
      is_enroll: is_enroll ? 1 : 0,
      is_10x: is_10x ? 1 : 0,
      is_leader: is_leader ? 1 : 0,
      term_slug: term_slug,
      usertype_id: usertype_id,
      table_name: 'terms',
      id: id,
    }

    const form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })
    AdminApi.updateTerms(form_data)
      .then((result) => {
        this.setState({ loading: false })
        toast.success('Term updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
        this.props.history.push('/admin-terms')
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchUserTypes = async () => {
    let Usertypes = []
    try {
      Usertypes =
        (await UsertypeService.search({ pagination: { per_page: 999 } }))
          ?.models || []
    } catch (ex) {
      console.log('Failed to fetch usertype in AdminTerms.')
    }

    if (Array.isArray(Usertypes)) this.setState({ usertypes: Usertypes })
  }

  openUploadTermModal = () => {
    // user fetch or create metas.
    MediaUploaderModal.open({
      uploadType: 'term',
      modalContentTop: (
        <div className="text--center fw--500">
          <h6 className="fw--500">Upload Term</h6>
        </div>
      ),
      appendFormData: { term_id: this.state.term_id },
      validationRules: {
        contentType: ['application/pdf'],
      },
      preventAutoClose: true,
      onSuccess: () => {
        this.fetchTermsData(this.state.term_id)
        toast.success('Term Document uploaded successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      },
    })
      .then()
      .catch((err) => {
        if (err) {
          console.log(
            'Failed to complete uploading agent wallet documents.',
            err
          )
          toast.error('Failed to upload Term Document', {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      })
  }

  render() {
    toast.configure()
    const { urlParams, loading } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>
                  <a href="/admin-terms">List</a>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>
                  {!!(urlParams && urlParams.id) ? 'Edit Term' : 'Add Term'}
                </MDBBreadcrumbItem>
              </MDBBreadcrumb>
              <div className="float-right">
                <MDBBtn size="sm" color="indigo" href={this.templateDocument}>
                  Download a Template Document
                </MDBBtn>
              </div>
              <h2>
                {!!(urlParams && urlParams.id) ? 'Edit Term' : 'Add Term'}
              </h2>
              <hr />
              {loading ? (
                <div className="loadingOverlay">
                  <h4 className={'loader-text p4'}>
                    <MDBSpinner />
                  </h4>
                </div>
              ) : (
                ''
              )}
              <MDBRow>
                <MDBCol size="12" md="6" className="add-term-field">
                  <form className="content-wrapper">
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="term_name">
                          Term Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="term_name"
                          id="term_name"
                          value={this.state.term_name}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label className="grey-text" htmlFor="term_link">
                          Term Link
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="term_link"
                          id="term_link"
                          disabled
                          value={this.state.term_link}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <MDBSwitch
                          labelLeft={
                            <span className="grey-text">
                              Is Enrollment Term:
                            </span>
                          }
                          labelRight={''}
                          name="is_enroll"
                          checked={this.state.is_enroll}
                          onChange={(event) =>
                            this.setState({
                              is_enroll: !this.state.is_enroll,
                            })
                          }
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <MDBSwitch
                          labelLeft={
                            <span className="grey-text">Is Leader Term:</span>
                          }
                          labelRight={''}
                          name="is_enroll"
                          checked={this.state.is_leader}
                          onChange={(event) =>
                            this.setState({
                              is_leader: !this.state.is_leader,
                            })
                          }
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <MDBSwitch
                          labelLeft={
                            <span className="grey-text">Is 10x Term:</span>
                          }
                          labelRight={''}
                          name="is_10x"
                          checked={this.state.is_10x}
                          onChange={(event) =>
                            this.setState({
                              is_10x: !this.state.is_10x,
                            })
                          }
                        />
                      </div>
                      <div className="form-group col-lg-12 d-flex align-items-center justify-content-between">
                        <label
                          className="grey-text mb-0 mr-2"
                          htmlFor="term_usertype"
                        >
                          Usertype Specific:
                        </label>
                        <MDBDropdown name="term_usertype">
                          <MDBDropdownToggle>
                            {!this.state.usertype_id
                              ? 'All Usertypes'
                              : UsertypeService.idToName(
                                  this.state.usertype_id
                                )}
                          </MDBDropdownToggle>
                          <MDBDropdownMenu>
                            <MDBDropdownItem
                              onClick={() =>
                                this.setState({ usertype_id: null })
                              }
                            >
                              All Usertypes
                            </MDBDropdownItem>
                            {this.state.usertypes.map((usertype, idx) => (
                              <MDBDropdownItem
                                onClick={() =>
                                  this.setState({ usertype_id: usertype.id })
                                }
                                key={`admin-term-usertype-${usertype?.id}-${idx}`}
                              >
                                {usertype?.displayname}
                              </MDBDropdownItem>
                            ))}
                          </MDBDropdownMenu>
                        </MDBDropdown>
                      </div>
                      <div className="form-group col-lg-12 d-flex align-items-center justify-content-between">
                        <label
                          className="grey-text mb-0 mr-2"
                          htmlFor="term_type"
                        >
                          Term Type:
                        </label>
                        <MDBDropdown name="term_type">
                          <MDBDropdownToggle>
                            {!this.state.term_slug
                              ? 'Please Select Term Type'
                              : TERM_SLUGS[this.state.term_slug]}
                          </MDBDropdownToggle>
                          <MDBDropdownMenu>
                            {Object.keys(TERM_SLUGS).map((termSlug, idx) => (
                              <MDBDropdownItem
                                onClick={() =>
                                  this.setState({ term_slug: termSlug })
                                }
                                key={`admin-term-termslug-${termSlug}-${idx}`}
                              >
                                {TERM_SLUGS[termSlug]}
                              </MDBDropdownItem>
                            ))}
                          </MDBDropdownMenu>
                        </MDBDropdown>
                      </div>
                      {this.state.term_id && (
                        <div className="form-group col-lg-12 d-flex align-items-center justify-content-between">
                          <MDBBtn
                            block
                            color="info"
                            className="upload-term-btn"
                            onClick={() => {
                              this.openUploadTermModal()
                            }}
                          >
                            <MDBIcon icon="file-medical" />
                            &nbsp;
                            <span>Upload Term</span>
                          </MDBBtn>
                        </div>
                      )}
                    </div>
                    <div className="form-row text-center">
                      <div className="form-group col-md-12">
                        <MDBBtn
                          color="unique"
                          type="button"
                          className="btn-block"
                          onClick={() => {
                            this.state.urlParams && this.state.urlParams.id
                              ? this.updateTerms()
                              : this.createTerms()
                          }}
                        >
                          {loading ? 'Saving...' : 'Save'}
                        </MDBBtn>
                      </div>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AddResource
