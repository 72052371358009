import React from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { MDBContainer, MDBBtn, MDBBtnGroup, MDBIcon } from 'mdbreact'
import { toast } from 'mdbreact'
import { LedgersStore, AgentLedgerManager } from './../../components/ledgers'
import UserProfileService from './../../shared/services/UserProfile.service'
import env from './../../environments/environment'
import PageHeading from './../../components/shared/PageHeading.component'
import moment from 'moment'

import './AgentLedgers.page.scss'

const TEST_LEDGER_ID = false

class AgentLedgersPage extends React.Component {
  state = {
    layout: null,
    isExporting: false,
  }

  get pageName() {
    switch (this.state.layout) {
      case 'agent':
        return 'My USABG BUCK$ Wallet'
      case 'admin':
        return 'Agent Wallet Administration'
      default:
        return ''
    }
  }

  get pagePath() {
    const path = `${this.props.match?.path ? this.props.match.path : ''}`
      .trim()
      .toLowerCase()
      .split(/ |#|\?/)
      .shift()
    if (path) return path

    switch (this.state.layout) {
      case 'agent':
        return '/account-ledger'
      case 'admin':
        return '/admin/ledgers'
      default:
        return ''
    }
  }

  set layout(layout) {
    if (this.state.layout !== layout) {
      LedgersStore.init(layout)
      this.setState({ layout })
    }
  }

  async exportReport({
    category,
    report,
    start,
    stop,
    coverages,
    orderBy,
    search,
  }) {
    if (this.state.isExporting !== true) this.setState({ isExporting: true })

    let reportUrl
    try {
      reportUrl = await LedgersStore.exportReport({
        category,
        report,
        start,
        stop,
        coverages,
        orderBy,
        search,
      })
    } catch (ex) {
      console.error(ex)
    }

    if (this.state.isExporting !== false) this.setState({ isExporting: false })

    if (reportUrl) {
      window.open(reportUrl)
      return true
    }

    return false
  }

  renderExporters() {
    switch (this.state.layout) {
      case 'agent':
        return (
          <MDBBtnGroup className="ledger-actions">
            <MDBBtn
              onClick={() =>
                this.exportReport({
                  category: '',
                  report: 'myLedgerTransactions',
                  start: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                  stop: moment().format('YYYY-MM-DD'),
                })
              }
            >
              {this.state.isExporting ? (
                <>
                  <i className="fa fa-spin fa-spinner"></i>&nbsp;Exporting
                </>
              ) : (
                <>
                  <MDBIcon icon="chart-line" />
                  &nbsp;Export Transactions (90 Days)
                </>
              )}
            </MDBBtn>
          </MDBBtnGroup>
        )
      case 'admin':
        return (
          <MDBBtnGroup className="ledger-actions">
            <MDBBtn
              onClick={() =>
                this.exportReport({
                  category: '',
                  report: 'ledgerAgencyBalanceSheet',
                  start: '2020-01-01',
                  stop: moment().format('YYYY-MM-DD'),
                })
              }
            >
              {this.state.isExporting ? (
                <>
                  <i className="fa fa-spin fa-spinner"></i>&nbsp;Exporting
                </>
              ) : (
                <>
                  <MDBIcon icon="chart-line" />
                  &nbsp;Export Balance Sheet
                </>
              )}
            </MDBBtn>
            <MDBBtn
              onClick={() =>
                this.exportReport({
                  category: '',
                  report: 'ledgerAgencyTransactions',
                  start: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                  stop: moment().format('YYYY-MM-DD'),
                })
              }
            >
              {this.state.isExporting ? (
                <>
                  <i className="fa fa-spin fa-spinner"></i>&nbsp;Exporting
                </>
              ) : (
                <>
                  <MDBIcon icon="chart-line" />
                  &nbsp;Export Transactions (90 Days)
                </>
              )}
            </MDBBtn>
          </MDBBtnGroup>
        )
      default:
        return <></>
    }
  }

  _determineLayoutFromPath = () =>
    `${this.pagePath}`.includes('/admin/ledgers') ? 'admin' : 'agent'

  async _init() {
    this.layout = this._determineLayoutFromPath()

    const ledgerId = this.props.match?.params?.ledgerId || TEST_LEDGER_ID,
      transactionId = this.props.match?.params?.transactionId

    if (ledgerId && !isNaN(ledgerId)) {
      LedgersStore.findLedgerById(ledgerId).then(async (Ledger) => {
        LedgersStore.Ledger = Ledger
        LedgersStore.searchAgentLedgerTransactions({
          pagination: { per_page: 50 },
        }).finally(() =>
          LedgersStore.editTransaction(
            LedgersStore.LedgerTransactions.filter(
              (T) => transactionId && `${T.id()}` === `${transactionId}`
            ).shift() || null
          )
        )
      })
    }

    if (TEST_LEDGER_ID) {
      LedgersStore.findLedgerById(TEST_LEDGER_ID).then(async (Ledger) => {
        LedgersStore.Ledger = Ledger
        LedgersStore.searchAgentLedgerTransactions({
          pagination: { per_page: 50 },
        }).then(() => {
          LedgersStore.editTransaction(LedgersStore.LedgerTransactions[0])
        })
      })

      // if (Ledger) {
      //   const {models, pagination} = await (Ledger.transaction(true)).search({search: {ledger_id: Ledger.id()}, pagination: {per_page: 50}});
      //   Ledger.LedgerTransactions = models;
      //   Ledger.ledgerTransactionPagination = pagination;
      // }
    }

    LedgersStore.TransferStore.fetch(transactionId)
  }

  _updateParams = (newLedgerId, newTransactionId) => {
    const currParams = [
        this.props.match?.params?.ledgerId &&
          parseInt(this.props.match.params.ledgerId),
        this.props.match?.params?.transactionId &&
          parseInt(this.props.match.params.transactionId),
      ]
        .map((n) => (n && !isNaN(n) ? parseInt(n) : ''))
        .join('.'),
      newParams = [
        this._determineLayoutFromPath() === 'admin' ? newLedgerId : '',
        newTransactionId,
      ].map((n) => (n && !isNaN(n) ? parseInt(n) : ''))

    if (newParams.join('.') !== currParams) {
      const pathname =
        '/' +
        (this._determineLayoutFromPath() === 'admin'
          ? ['admin', 'ledgers']
          : ['account-ledger']
        )
          .concat(newParams)
          .filter((n) => !!n)
          .join('/')
          .replace(/^\/+/, '')
      if (pathname === this.props.location.pathname) return
      this.props.history.push(pathname)
    }
  }

  componentDidMount() {
    this._init()
  }

  componentWillUnmount() {
    LedgersStore.end()
  }

  render() {
    toast.configure()
    return (
      <DashboardLayout>
        <main id="AgentLedgersPage" className="mainSection pb-5">
          <MDBContainer
            fluid
            className={this.state.layout === 'agent' ? 'mt-3' : 'mt-5'}
          >
            {this.state.layout === 'agent' && (
              <div className="buck-logo pb-3 d-flex justify-content-center">
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Flogos%2Fusabg-bucks.logo.png?alt=media`}
                  alt="buck-logo"
                />
              </div>
            )}
            <PageHeading
              label={this.pageName}
              url={this.props?.match?.url}
              from={this.props?.location?.state?.from || '/dashboard'}
              goTo={(url) =>
                this.props.history.push(url, { from: this.pagePath })
              }
              btnGroup={this.renderExporters()}
            />
            <AgentLedgerManager
              toast={toast}
              layout={this.state.layout}
              onOpen={(ledgerId, transactionId) =>
                this._updateParams(ledgerId, transactionId)
              }
              onClose={() => this._updateParams()}
            />
          </MDBContainer>
        </main>
      </DashboardLayout>
    )
  }
}

export default AgentLedgersPage
