import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
} from 'mdbreact'
import { convertToCurrencyWithoutSign } from './../../../../shared/formatters/convertToCurrencyWithoutSign.formatter'
import LoadingSpinner from './../../../../components/shared/LoadingSpinner.component'
import MilestoneStore from './../../Milestone.store'

import './KOTHWeekly.scss'
import { autoLogin, getAgentAccessToHeadshot } from '../../../../shared/utilities/getHeadshot.function'

const rearrangeArray = (arr, fromIndex, toIndex) => {
  const element = arr[fromIndex]
  const newArray = arr.filter((_, index) => index !== fromIndex)
  newArray.splice(toIndex, 0, element)
  return newArray
}

const KOTHWeekly = (props) => {
  const source = props?.source,
    getPlace = (idx) =>
      `${parseInt(idx) + 1}${['st', 'nd', 'rd', 'th', 'th'][idx]} Place`,
    kothType = `${source}_koth_weekly`

  const renderWidget = () => {
    return (
      <MDBContainer
        id="KOTHWeekly"
        fluid
        className={MilestoneStore.loading[kothType] ? 'is-loading' : ''}
      >
        <div className="loading-spinner">
          <LoadingSpinner
            size="md"
            name={`widget.${kothType}`}
            isActive={MilestoneStore.loading[kothType]}
          />
        </div>
        <MDBRow className="mb-3_5 king-of-the-hill">
          {MilestoneStore.milestones[kothType] &&
          MilestoneStore.milestones[kothType].milestone.length > 0 ? (
            rearrangeArray(
              MilestoneStore.milestones[kothType].milestone,
              0,
              2
            ).map((agent, i) => (
              <MDBCol
                key={'koth-weekly-' + i}
                size="12"
                md={`${i}` === '2' ? '4' : '2'}
              >
                <MDBCard>
                  <MDBCardHeader color="primary-color-dark text-center">
                    {i === 2 ? (
                      <span style={{ fontWeight: 500, fontSize: '1.75rem' }}>
                        {source === 'agent' ? 'Agent' : 'BA Team'} Best Week:
                        King of the Hill
                      </span>
                    ) : (
                      <span style={{ fontSize: '1.25rem' }}>
                        {getPlace(i < 2 ? i + 1 : i)}
                      </span>
                    )}
                  </MDBCardHeader>
                  <MDBCardBody>
                    <div
                      className={agent.data[1] && getAgentAccessToHeadshot(agent.data[1]) ? "img-wrapper cursor-pointer" : "img-wrapper"}
                      onClick={() => {
                        if(agent.data[1] && getAgentAccessToHeadshot(agent.data[1])) autoLogin(agent.data[1])
                      }}
                    >
                      <img
                        src={agent.image}
                        className="mx-auto d-block img-fluid rounded-circle"
                        style={{ height: '135px' }}
                        alt={agent.data[2]}
                      />
                    </div>
                    <h5 className="text-center">{agent.data[2]}</h5>
                    <h6 className="text-center">{agent.data[0]}</h6>
                    <h5 className="text-center">
                      {convertToCurrencyWithoutSign(agent.data[1])} Points
                    </h5>
                  </MDBCardBody>
                </MDBCard>
                {`${i}` === '4' ? (
                  <div
                    style={{
                      textAlign: 'right',
                      fontWeight: 500,
                      fontSize: '12px',
                    }}
                  >
                    {MilestoneStore.milestones[kothType] ? (
                      `${MilestoneStore.milestones[kothType]?.updates}.  `
                    ) : (
                      <></>
                    )}
                    {MilestoneStore.milestones[kothType] &&
                    MilestoneStore.milestones[kothType]?.activity ? (
                      `Last Updated: ${MilestoneStore.milestones[kothType]?.activity}  `
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </MDBCol>
            ))
          ) : (
            <MDBCol size="12">No Data!</MDBCol>
          )}
        </MDBRow>
      </MDBContainer>
    )
  }

  if (MilestoneStore.milestones[kothType] === null)
    MilestoneStore.fetchMilestone(kothType)

  return renderWidget()
}

export default observer(KOTHWeekly)
