import React, { useState } from 'react'
import { LedgerTitle } from './../shared'
import { observer } from 'mobx-react-lite'
import { UIInput, UIInputButton } from './../../forms/form-fields'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact'
import LedgerStore from './../store'
import UserProfileService from './../../../shared/services/UserProfile.service'
import moment from 'moment'

import './LedgerNotes.component.scss'

const LedgerNotes = ({ layout }) => {
  const [newNote, setNewNote] = useState({ public: '', private: '' }),
    isNotesReadOnly = !(
      UserProfileService.isA(
        ['agency-owner', 'system-admin', 'internal-admin', 'internal-staff'],
        true
      ) ||
      (UserProfileService.isA('recruiter-group', true) &&
        UserProfileService.isAssumed())
    )

  const getNotes = (notesStr) => {
    if (Array.isArray(notesStr)) return notesStr

    try {
      return JSON.parse(notesStr) || []
    } catch (ex) {
      return []
    }
  }

  const renderNotesPane = (notesPane) => {
    const NotesInput = () => {
      return (
        <UIInput
          type="textarea"
          label="New Note or Comment"
          name={`ledger_${notesPane}_notes`}
          id={`ledger_${notesPane}_notes`}
          value={newNote[notesPane]}
          onChange={(e) => {
            setNewNote({ [notesPane]: e.target.value })
          }}
          style={{ color: '#fff' }}
          rules={{ minLength: 3 }}
        />
      )
    }

    const NotesButton = () => {
      return (
        <>
          <MDBBtn
            disabled={LedgerStore.isLoading || LedgerStore.isSearching}
            onClick={() => {
              if (newNote) {
                LedgerStore.Ledger.set(
                  `${notesPane}_notes`,
                  JSON.stringify(
                    [
                      {
                        message: newNote[notesPane],
                        user_id: UserProfileService.get('id', true),
                        author: [
                          UserProfileService.get('u_fname', true),
                          UserProfileService.get('u_lname', true),
                        ]
                          .join(' ')
                          .trim(),
                        timestamp: moment().utc().toISOString(),
                      },
                    ].concat(
                      getNotes(LedgerStore.Ledger?.get(`${notesPane}_notes`))
                    )
                  )
                )
                LedgerStore.Ledger.save()
                setNewNote({ ...newNote, [notesPane]: '' })
              }
            }}
            className="search-btn"
          >
            <MDBIcon icon="save" />
          </MDBBtn>
        </>
      )
    }

    return (
      <div className="ledger-notes">
        {isNotesReadOnly === false ? (
          <UIInputButton
            input={NotesInput()}
            button={NotesButton()}
            btnPosition={'right'}
          />
        ) : (
          <></>
        )}
        <div className="content-wrapper">
          {getNotes(LedgerStore.Ledger?.get(`${notesPane}_notes`)).map(
            (note, idx) => {
              return (
                <div
                  className="ledger-note-wrapper"
                  key={`ledger-note-${LedgerStore.Ledger?.id()}-${idx}`}
                >
                  <div>{note.message}</div>
                  <div>{note.author}</div>
                  <div>
                    {moment(note.timestamp).format('MM/DD/YYYY HH:mm a')}
                  </div>
                </div>
              )
            }
          )}
        </div>
      </div>
    )
  }

  return (
    <MDBContainer id="LedgerNotesComponent" fluid className="ledger-component">
      <MDBRow>
        <MDBCol size="12">
          <LedgerTitle>Wallet Notes</LedgerTitle>
        </MDBCol>
        <MDBCol size="12">
          <MDBContainer fluid>
            <MDBRow>
              <MDBCol
                size="12"
                sm={isNotesReadOnly === false ? '6' : '12'}
                className="mb-4"
              >
                {isNotesReadOnly === false ? 'Agent Notes (Public):' : ''}
                {renderNotesPane('public')}
              </MDBCol>
              {isNotesReadOnly === false ? (
                <MDBCol size="12" sm="6" className="mb-4">
                  Admin Notes (Private):{renderNotesPane('private')}
                </MDBCol>
              ) : (
                <></>
              )}
            </MDBRow>
          </MDBContainer>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(LedgerNotes)
