import BaseModelInstance from './base.model'
import { ReportHistory as ModelDefinition } from './model-definitions'

class ReportHistory extends BaseModelInstance {
  afterSet = (key, value) => {}

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }
}

export default ReportHistory
