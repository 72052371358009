import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBCol,
  MDBRow,
} from 'mdbreact'

import './PaginationControls.scss'

const PaginationControls = ({ pagination, onPageSelect }) => {
  const getCurrentPage = () => {
    // note: page numbers are 0-index based.
    if (!isNaN(pagination?.page)) return pagination.page + 1
  }

  const getViewing = () => {
    const total = parseInt(getTotal()),
      page = parseInt(getCurrentPage())
    let start = 1,
      end = parseInt(pagination?.per_page)

    if (
      isNaN(total) ||
      isNaN(end) | isNaN(page) ||
      total === null ||
      end === null ||
      page === null
    )
      return false

    if (page === 1) return [1, end > total ? total : end]
    else if (page > 1) {
      return [
        (page - 1) * pagination.per_page + 1,
        page * pagination.per_page > total ? total : page * pagination.per_page,
      ]
    }

    return false
  }

  const getTotal = () => {
    if (pagination?.total && !isNaN(pagination?.total)) return pagination.total
    return
  }

  const getTotalPages = () => {
    if (
      pagination?.total &&
      !isNaN(pagination.total) &&
      pagination?.per_page &&
      !isNaN(pagination.per_page)
    )
      return Math.ceil(pagination?.total / pagination?.per_page)
    return pagination?.total > 0 ? 1 : 0
  }

  const getIntermediatePages = () => {
    let total_pages = getTotalPages(),
      intermediate = []

    if (total_pages > 2) {
      for (let i = 0; i < total_pages; i++) intermediate.push(i)

      // If many pages exist, truncate the options
      // to the earliest & latest (4) options.
      if (intermediate.length > 10) {
        intermediate = [
          intermediate.shift(),
          intermediate.shift(),
          intermediate.shift(),
          intermediate.shift(),
          intermediate.shift(),
          '...',
        ].concat(
          [
            intermediate.pop(),
            intermediate.pop(),
            intermediate.pop(),
            intermediate.pop(),
            intermediate.pop(),
          ].reverse()
        )
      }
    }

    return intermediate
  }

  const canGoPrev = () => !(!pagination?.page || pagination.page < 1)

  const canGoNext = () => !(pagination?.page >= getTotalPages() - 1)

  const goToPrev = () => goTo(pagination.page - 1)

  const goToNext = () => goTo(pagination.page + 1)

  const goTo = (page) => {
    if (isNaN(parseInt(page))) return

    if (onPageSelect && typeof onPageSelect === 'function')
      onPageSelect({ ...pagination, page })
  }

  const isPageActive = (page) => parseInt(pagination?.page) === parseInt(page)

  const renderPageIndicator = () => (
    <MDBCol className="page-indicator">
      {getTotalPages() ? (
        <div>
          Page <strong>{getCurrentPage()}</strong> of{' '}
          <strong>{getTotalPages()}</strong>
          {getViewing() ? (
            <>
              <br />
              <small>
                <strong>
                  Viewing records {getViewing().join(' - ')} of {getTotal()}{' '}
                  total records.
                </strong>
              </small>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </MDBCol>
  )

  const renderPageSelector = () => (
    <MDBCol className="page-selector">
      <MDBPagination className="mb-5 justify-content-end flex-wrap">
        <MDBPageItem disabled={!canGoPrev()} onClick={goToPrev}>
          <MDBPageNav>
            <span>Previous</span>
          </MDBPageNav>
        </MDBPageItem>
        {getIntermediatePages().map((p) => (
          <MDBPageItem
            key={'page-idx-' + p}
            disabled={isPageActive(p)}
            className={
              (isPageActive(p) ? 'current-page ' : '') +
              (p === '...' ? 'no-click ' : '')
            }
            onClick={() => goTo(p)}
          >
            <MDBPageNav>
              <span>{p !== '...' ? p + 1 : p}</span>
            </MDBPageNav>
          </MDBPageItem>
        ))}
        <MDBPageItem disabled={!canGoNext()} onClick={goToNext}>
          <MDBPageNav>
            <span>Next</span>
          </MDBPageNav>
        </MDBPageItem>
      </MDBPagination>
    </MDBCol>
  )

  const renderWidget = () => (
    <MDBRow between className="PaginationControlsComponent">
      {renderPageIndicator()}
      {renderPageSelector()}
    </MDBRow>
  )

  return renderWidget()
}

export default observer(PaginationControls)
