import React, { useEffect } from 'react'
import { makeAutoObservable, toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCol,
  MDBRow,
  MDBAlert,
  MDBIcon,
} from 'mdbreact'
import env from './../../../environments/environment'
import AdminApi from './../../../api/admin-api/admin-api';
import UserProfileService from './../../../shared/services/UserProfile.service'
import AdminContentEditPopupComponent from './../../../components/adminShared/AdminContentEditPopupComponent'
import ContentHtml from './../../../components/content/ContentHtml/ContentHtml.component'
import AgencyContentStore from './../../../shared/stores/agencyContent.store'

import './SignatureAgentBenefits.scss'

class Store {
	content = null;

	constructor(){
		makeAutoObservable(this)
	}

	async fetchByContentObj ({contentSlug}) {
		return await AdminApi.getAgencyContentBySlug({slug: contentSlug, active:1}).then(res => this.content = res?.data?.data);
	}
}

const LocalStore = new Store();

const SignatureAgentBenefitsTable = ({ history, insecure }) => {
  const contentSlug = 'sig-agent-benefits-items',
    isAdmin = insecure ? false : UserProfileService.isA([
      'system-admin', 'internal-admin', 'agency-owner'
    ]);

  const renderSigAgentItem = (entry, idx) => 
  {
    entry = entry && typeof entry === 'object' ? entry : {}

    entry.links =
      entry?.links && typeof entry.links === 'string'
        ? JSON.parse(entry.links)
        : entry.links
    entry.links =
      entry.links && Array.isArray(entry.links)
        ? entry.links.shift()
        : entry.links || null

    const isActive = parseInt(entry?.active) === 1

    return (
      <MDBCol
        size="12"
        sm="6"
        lg="4"
        key={`sab-item-idx-${idx}`}
        className={'benefits-pkg-wrapper ' + (isActive ? '' : 'disabled-pkg')}
      >
      {
      	isAdmin ? <AdminContentEditPopupComponent
          type="RTE"
          iconPosition="top-right"
          iconStyle={{ transform: 'translate(-18px, 18px)' }}
          actionType="edit"
          pageId={entry.id}
          color="blue"
          callback={(contentId) =>
            AgencyContentStore.fetchByContentObj({ contentId })
          }
          canDelete={isAdmin}
          isLink={true}
        />
        : <></> }
        <MDBCard className="mb-3 mt-3">
          <MDBCardHeader tag="h3">
            {entry?.page_name}
            {isActive ? (
              ''
            ) : (
              <>
                &nbsp;<small>(DISABLED)</small>
              </>
            )}
          </MDBCardHeader>
          <MDBCardBody>
            {entry.links && entry.links?.tl_link ? (
              <img
                src={`https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}${entry.links?.tl_link}`}
                alt={entry?.page_name || 'signature agent benefit feature'}
                className="card"
              />
            ) : (
              <></>
            )}
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: entry?.content }}
            ></div>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    )
  }

  const renderItems = () =>
  {
  	return ((insecure?LocalStore.content:AgencyContentStore.content['sig-agent-benefits-items'])||[]).filter((entry) => isAdmin || parseInt(entry?.active) === 1).map(renderSigAgentItem)
  }

  const goToAcctBilling = (event) => {
    event.preventDefault()
    history.push(`/account-billinginfo`)
    return false
  }

  useEffect(() => {
    if (contentSlug) {
    	if (insecure) LocalStore.fetchByContentObj({ contentSlug })
    	else AgencyContentStore.fetchByContentObj({ contentSlug })
    }
  }, [insecure, contentSlug])

  return (
    <MDBContainer
      fluid
      id="SignatureAgentBenefitsTableComponent"
      className="mt-5"
    >
      <MDBRow>
        <MDBCol>
        	{
        		insecure ? <></> : <ContentHtml contentSlug="sig-agent-benefits-intro" limit="1" />
        	}
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBAlert color="info">
            <div>
              You'll find the key benefits below, but USABG offers our Signature
              Agents a wide array of features, benefits & services.
            </div>
            <div>
            {
            	insecure ? <></> : <a
                className="btn btn-sm"
                href="/account-billinginfo"
                onClick={goToAcctBilling}
              >
                Click Here for Details&nbsp;
                <MDBIcon icon="arrow-right" />
              </a>
            }
            </div>
          </MDBAlert>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        {renderItems()}
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(SignatureAgentBenefitsTable)
