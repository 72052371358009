import React, { Component } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import PageHeading from './../../components/shared/PageHeading.component'
import UserEvents from './components/UserEvents/UserEvents.component';
import UserEventStore from './components/UserEvents/UserEvent.store';
import {
  MDBAlert,
  MDBRow,
  MDBCol,
  MDBContainer,
} from 'mdbreact'
import UserProfileService from './../../shared/services/UserProfile.service'

import './UserEventLog.page.scss'


const ColorLegend = () =>
{
  return (
      <div className='container-fluid ColorLegendComponent'>
        <MDBRow>
          <MDBCol><MDBAlert color='success'>Success/Passing Outcome</MDBAlert></MDBCol>
          <MDBCol><MDBAlert color='warning'>Notice/Warning Outcome</MDBAlert></MDBCol>
          <MDBCol><MDBAlert color='danger'>Error/Failing Outcome</MDBAlert></MDBCol>
          <MDBCol><MDBAlert color='info'>Significant Event</MDBAlert></MDBCol>
          <MDBCol><MDBAlert color='secondary'>Standard Event</MDBAlert></MDBCol>
        </MDBRow>
      </div>
    )
}

class UserEventLogPage extends Component 
{
  _fetch = async () =>
    await UserEventStore.init(await UserProfileService.instance());

  componentDidMount ()
  {
    this._fetch();
  }

  render () 
  {
    return (
      <DashboardLayout>
        <main id="UserEventLogPage" className="mainSection pb-5">
          <MDBContainer fluid className="mt-5">
            <PageHeading
              label="User History"
              url={this.props?.match?.url}
              from={this.props?.location?.state?.from}
              btnGroup={<ColorLegend />}
            />
          </MDBContainer>
          <MDBContainer className="pb-5">
            <MDBRow>
              <MDBCol>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <div className='content-wrapper'>
                  <UserEvents />
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </main>
      </DashboardLayout>
    )
  }
}

export default UserEventLogPage
