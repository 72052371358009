import { makeAutoObservable, toJS } from 'mobx'
import UserProfileService from './../../shared/services/UserProfile.service'
import OnboardingService from './../../shared/services/Onboarding.service'
import { ClarityTag } from './../../core/tracking/Clarity.functions'

const getSignature = (arr) =>
  JSON.stringify(
    arr.map((a) =>
      `${a}` === 'true' || a === true
        ? 1
        : `${a}` === 'false' || a === false
        ? 0
        : a
    )
  )

const allOnboardingStages = [
  {
    index: 1,
    label: 'Agent Profile',
    name: 'agent_profile',
    description:
      'Setup your basic profile with headshot photo, NPN Number, State Licenses, and more.',
  },
  {
    index: 2,
    label: 'Contracting',
    name: 'contracting',
    description: 'Select your desired carriers & e-sign to get moving quickly.',
  },
  {
    index: 3,
    label: 'Training',
    name: 'training',
    description: "Watch USABG's core training videos & get up to speed fast.",
  },
  {
    index: 4,
    label: 'Enter KPIs & Set Goals',
    name: 'set_kpi_goals',
    description:
      'Learn our proven peformance & tracking system, and watch your numbers grow.',
  },
  {
    index: 5,
    label: 'Input Writing Numbers',
    name: 'writing_numbers',
    description:
      "Before you can input sales, you'll need to record your appointed carrier writing numbers.",
  },
]

const shortOnboardingStages = [
  {
    index: 1,
    label: 'Agent Profile',
    name: 'agent_profile',
    description:
      'Setup your basic profile with headshot photo, NPN Number, State Licenses, and more.',
  },
  {
    index: 2,
    label: 'Contracting',
    name: 'contracting',
    description: 'Select your desired carriers & e-sign to get moving quickly.',
  },
  {
    index: 3,
    label: 'Input Writing Numbers',
    name: 'writing_numbers',
    description:
      "Before you can input sales, you'll need to record your appointed carrier writing numbers.",
  },
]

const minimalOnboardingStages = [
  {
    index: 1,
    label: 'Agent Profile',
    name: 'agent_profile',
    description:
      'Setup your basic profile with headshot photo, NPN Number, State Licenses, and more.',
  },
]

const getUsertypeOnboardingStages = () => {
  if (UserProfileService.isA(['affiliate-group']))
    return minimalOnboardingStages
  if (UserProfileService.isA(['associate-group'])) return shortOnboardingStages
  return allOnboardingStages
}

class OnboardingStore {
  constructor() {
    makeAutoObservable(this)
  }

  history = []
  stages = getUsertypeOnboardingStages()
  activeStage = {}
  validity = {
    agent_profile: undefined,
    contracting: undefined,
    training: undefined,
    set_kpi_goals: undefined,
    writing_numbers: undefined,
  }

  _fetched = false
  _activeSig = ''
  _validitySig = ''
  _stageMetaTimer = ''
  _stages = null

  getStages = (refreshed) => {
    if (this._stages && !refreshed) return this._stages
    this._stages = getUsertypeOnboardingStages()
    return this._stages
  }

  getStage = (stageName) =>
    this.getStages()
      .filter((s) => s.name === stageName)
      .shift()

  getPrev = (returnStage) => {
    let stage = this.getStage(this.activeStage.name)
    if (stage && stage?.index) {
      stage = this.getStages()
        .filter((s) => parseInt(stage.index) - 1 === parseInt(s.index))
        .shift()
      if (stage) return stage && stage.name
    }

    if (returnStage)
      return this.getStage(this.getStages[0] && this.getStages[0].name)

    return this.getStages[0] && this.getStages[0].name
  }

  getNext = (returnStage) => {
    let stage = this.getStage(this.activeStage.name)
    if (stage && stage?.index) {
      stage = this.getStages()
        .filter((s) => parseInt(stage.index) + 1 === parseInt(s.index))
        .shift()
      if (stage) stage = stage && stage.name
    }

    if (returnStage) return this.getStage(stage)

    return stage || (this.getStages[0] && this.getStages[0].name)
  }

  getInitial = () => this.getStages()[0]

  canGoTo = (stage) => {
    if (stage && typeof stage === 'string') stage = this.getStage(stage)

    if (!stage || typeof stage !== 'object' || !stage?.name) return false

    if (this.activeStage?.name === stage?.name) return false

    const permitted = []
    for (let o in this.getStages()) {
      if (this.getStages()[o].name === this.activeStage?.name) break
      permitted.push(this.getStages()[o].name)
    }
    if (permitted.indexOf(stage.name) > -1) return true

    if (
      stage?.name &&
      this.activeStage?.name &&
      this.validity[this.activeStage?.name] &&
      stage?.name === this.getNext(true)?.name
    )
      return true

    return false
  }

  canRewind = () => this.activeStage?.index > 1

  canAdvance = () => {
    if (this.activeStage?.index < this.getStages().length) {
      // if the user has previously completed onboarding,
      // permit them to visit any onboarding stage.
      if (`${UserProfileService.get('has_onboarded')}` === '1') return true

      // TEMP WORKAROUND THROUGH AUG 2022.
      // if the user is in Sig Agent Limited to Training,
      // permit user to advance first 2 stages.
      if (
        this.activeStage.index < 3 &&
        UserProfileService.isA('limited-to-training-signature-agent')
      )
        return true

      return toJS(this.validity)[this.activeStage?.name]
    }

    return false
  }

  getReadableName = (stage) => {
    if (stage && typeof stage === 'string') stage = this.getStage(stage)

    switch (stage && stage?.name) {
      case 'agent_profile':
        return 'your agent profile'
      case 'contracting':
        return 'your carrier contracting'
      case 'training':
        return 'your core training'
      case 'set_kpi_goals':
        return 'setting your goals'
      case 'writing_numbers':
        return 'entering your agent writing numbers'
      default:
        return ''
    }
  }

  fetchStageMeta = async () => {
    let stageConfig
    try {
      stageConfig = await OnboardingService.stage.get()
    } catch (ex) {
      console.error('ex: ', ex)
    }

    if (stageConfig) {
      if (stageConfig?.active && typeof stageConfig.active === 'object')
        this.activeStage = { ...toJS(this.activeStage), ...stageConfig.active }
      // if (stageConfig?.validity && typeof stageConfig.validity === 'object' && !stagesChanged) {
      //   let as = toJS(this.validity[this.activeStage.name])
      //   this.validity = { ...toJS(this.validity), ...stageConfig.validity }
      //   if (as && this.validity[this.activeStage.name] !== as)
      //     this.validity[this.activeStage.name] = as
      // }

      this._fetched = true
      this._activeSig = getSignature(Object.values(toJS(this.activeStage)))
      this._validitySig = getSignature(Object.values(toJS(this.validity)))
    }

    return stageConfig && stageConfig?.active ? stageConfig.active : undefined
  }

  storeStageMeta = async (fieldSet) => {
    const haveSigsChanged = () => {
      if (!this._fetched) return false

      let sigA = Object.values(toJS(this.activeStage)),
        sigV = Object.values(toJS(this.validity))

      if (!sigA.filter((a) => !!a).length && !sigV.filter((a) => !!a).length)
        return false

      if (this._activeSig !== (sigA = getSignature(sigA))) {
        this._activeSig = sigA
        sigA = true
      }

      if (this._validitySig !== (sigV = getSignature(sigV))) {
        this._validitySig = sigV
        sigV = true
      }

      return sigA === true || sigV === true
    }

    if (haveSigsChanged()) {
      if (this._stageMetaTimer !== false) {
        window.clearTimeout(this._stageMetaTimer)
        this._stageMetaTimer = false
      }

      this._stageMetaTimer = window.setTimeout(() => {
        try {
          OnboardingService.stage.post({
            active: toJS(this.activeStage),
            validity: toJS(this.validity),
          })
        } catch (ex) {}
        window.clearTimeout(this._stageMetaTimer)
        this._stageMetaTimer = false
      }, 300)
    }
  }

  setActiveStage = async (stage) => {
    ClarityTag('onboarding', 'stage-change')
    UserProfileService.track({
      event_type: 'onboarding.stage-change.success',
      payload: {
        from: this.activeStage?.name || this.activeStage,
        to: stage?.name || stage,
      },
    })
    this.activeStage = stage
    this.storeStageMeta()
  }

  goTo = (stage) => {
    if (stage && typeof stage === 'string') stage = this.getStage(stage)

    if (!stage || typeof stage !== 'object' || !stage?.name) return false

    if (!this.activeStage || stage.name !== this.activeStage.name)
      this.setActiveStage(stage)
  }

  setValidity = async (stageName, isValid) => {
    this.validity[stageName] = isValid
    this.storeStageMeta()
  }

  complete = async () => {
    ClarityTag('onboarding', 'complete')
    try {
      await OnboardingService.complete()
      UserProfileService.track({ event_type: 'onboarding.completed.success' })
    } catch (ex) {
      console.log('cant complete. ', ex)
      UserProfileService.track({
        event_type: 'onboarding.completed.failure',
        event_descrip: `${ex}`,
      })
      return false
    }
    return true
  }
}

export default new OnboardingStore()
