import React from 'react'
import { MDBIcon } from 'mdbreact'
import { observer } from 'mobx-react-lite'
import Store from '../store'

const specs = [
  {
    name: 'HEALTH',
    icon: 'user-md',
  },
  {
    name: 'SENIOR',
    icon: 'medkit',
  },
  {
    name: 'LIFE',
    icon: 'heart',
  },
]

const SpecializationSelector = () => {
  return (
    <div id="SpecializationSelector">
      <ul className="row">
        {specs.map((spec) => {
          return (
            <li
              key={`agent-spec-btn-${spec.name}`}
              className={
                (Store.spec === spec.name ? 'spec-selected ' : '') +
                ' agent-spec-btn col-md-4'
              }
            >
              <button
                className="spec-btn"
                title={spec.name}
                onClick={async () => {
                  if (spec.name !== Store.spec) Store.setSpec(spec.name)
                }}
              >
                <div>
                  <MDBIcon size="2x" fas="true" icon={spec.icon} />
                </div>
                <div>
                  {Store.spec === spec.name ? (
                    <MDBIcon far icon="check-circle" />
                  ) : (
                    <MDBIcon far icon="circle" />
                  )}
                  &nbsp;{spec.name}
                </div>
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default observer(SpecializationSelector)
