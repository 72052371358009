import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import OnboardingService from './../../../../shared/services/Onboarding.service'
import { MDBContainer, MDBRow, MDBCol, MDBSwitch } from 'mdbreact'

const HasOnboardedToggleSwitch = ({ onToggle }) => {
  const [isLoading, setIsLoading] = useState(false)

  const meetsRequirement =
    UserProfileService.isA('internal-admin', true) ||
    UserProfileService.isA('system-admin', true) ||
    UserProfileService.isA('agency-owner', true)

  const toggleOnboarding = async () => {
    setIsLoading(true)

    let hasOnboarded = `${UserProfileService.get('has_onboarded')}` === '1'

    try {
      UserProfileService.set('has_onboarded', hasOnboarded ? 0 : 1)

      await (hasOnboarded
        ? OnboardingService.uncomplete()
        : OnboardingService.complete())

      onToggle(!hasOnboarded)
      setIsLoading(false)
      UserProfileService.track({ event_type: 'user.has-onboarded.success' })
    } catch (error) {
      onToggle(error.message)
      setIsLoading(false)
      UserProfileService.track({ event_type: 'user.has-onboarded.failure' })
    }
  }

  const renderButton = () => {
    if (!meetsRequirement) return <></>

    return (
      <MDBContainer
        fluid
        style={{ backgroundColor: 'rgba(255,255,255,0.5)', paddingTop: '10px' }}
      >
        <MDBRow>
          <MDBCol size="6" sm="7" md="9" lg="6">
            <label>
              Has the agent completed onboarding?&nbsp;
              {isLoading ? (
                <>
                  <span>
                    <i className="fa fa-spin fa-spinner"></i>
                  </span>
                </>
              ) : (
                <></>
              )}
            </label>
          </MDBCol>
          <MDBCol size="6" sm="5" md="3" lg="6" style={{ textAlign: 'right' }}>
            <MDBSwitch
              labelLeft={'No'}
              labelRight={'Yes'}
              disabled={!!isLoading}
              checked={`${UserProfileService.get('has_onboarded')}` === '1'}
              onChange={toggleOnboarding}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }

  return renderButton()
}

export default observer(HasOnboardedToggleSwitch)
