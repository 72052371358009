import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { MDBContainer, MDBDataTable, MDBIcon } from 'mdbreact'

class GoToMeeting extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const data = {
      columns: [
        {
          label: 'Meeting Date',
          field: 'name',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'View',
          field: 'view',
          sort: 'asc',
          width: 270,
        },
      ],
      rows: [
        {
          name: 'March 1st',
          view: (
            <a href="view-gotomeetings">
              <MDBIcon
                icon="binoculars"
                size="2x"
                className="d-flex justify-content-center blue-text"
              />
            </a>
          ),
        },
        {
          name: 'Feb 25th',
          view: (
            <a href="view-gotomeetings">
              <MDBIcon
                icon="binoculars"
                size="2x"
                className="d-flex justify-content-center blue-text"
              />
            </a>
          ),
        },
        {
          name: 'Feb 24th',
          view: (
            <a href="view-gotomeetings">
              <MDBIcon
                icon="binoculars"
                size="2x"
                className="d-flex justify-content-center blue-text"
              />
            </a>
          ),
        },
      ],
    }
    return (
      <Fragment>
        <DashboardLayout>
          <main className="adminStyle mainSection">
            <MDBContainer fluid className="mt-5">
              <h2>Admin Report: GoTo Meetings</h2>
              <hr />
              <div className="content-wrapper">
                <MDBDataTable
                  striped
                  bordered
                  small
                  data={data}
                  className="text-capitalize"
                  entries={50}
                  entriesOptions={[50, 100, 150]}
                  exportToCSV
                />
              </div>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default GoToMeeting
