import React, { Component, Fragment } from 'react'
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBSpinner,
} from 'mdbreact'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'

import './AddResourcePage.scss'

class AddResourcePage extends Component {
  state = {
    loading: false,
    resource_name: '',
    resourcetype_id: '',
    resource_link: '',
    resource_description: '',
    resource_group: '',
    resource_order: '',
    resourceTypeList: [],
    resourceTypeloading: false,
    errorMessage: '',
    formError: false,
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params })

    this.fetchResourceTypeData()
  }

  #onChange = (event) =>
    this.setState({ [event.target.name]: event.target.value })

  validate = async () => {
    // const regLink = /^((http(s)?:\/\/)(www\.)?)+[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g;
    const regHttpLink = /^(http(s)?:\/\/).*/i
    const { resource_link, resource_name, resourcetype_id } = this.state

    /*if(!this.state.resource_link) {
            this.setState({formError: true, errorMessage: 'Resource Link Required!'});
            return;
        }

        if (this.state.resource_link && !regLink.test(this.state.resource_link)) {
            this.setState({formError: true, errorMessage: 'Invalid Link format'});
            return;
        }*/

    if (!(resource_name && resource_name.trim())) {
      this.setState({
        formError: true,
        errorMessage: 'Resource Name Required!',
      })
      return
    }
    if (!resourcetype_id) {
      this.setState({ formError: true, errorMessage: 'Resource Id Required!' })
      return
    }

    if (!(resource_link && resource_link.trim())) {
      this.setState({
        formError: true,
        errorMessage: 'Resource Link Required!',
      })
      return
    }

    if (resource_link && !regHttpLink.test(resource_link)) {
      const { resource_link } = this.state
      this.setState({ resource_link: `http://${resource_link}` })
    }

    this.setState({ formError: false, errorMessage: '' })
  }

  //create resource source
  createResource = async () => {
    await this.validate()
    const {
      errorMessage,
      formError,
      loading,
      resource_name,
      resource_link,
      resource_description,
      resourcetype_id,
    } = this.state
    if (loading) {
      return
    }

    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }

    const data = {
      resource_name: resource_name,
      resourcetype_id: resourcetype_id,
      resource_link: resource_link,
      resource_description,
      active: 1,
      table_name: 'resources',
    }

    let form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })

    AdminApi.createResource(form_data)
      .then((result) => {
        this.setState({ loading: false })
        this.props.history.push('/admin-resources')
        toast.success('Resource created successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchResourceData = async (id) => {
    AdminApi.getResourceById(id)
      .then((result) => {
        this.setState({ loading: false })
        if (result && result.data && result.data.data) {
          const data = result.data.data
          this.setState({
            resource_name: data.resource_name,
            resourcetype_id: data.resourcetype_id,
            resource_link: data.resource_link,
            resource_description: data.resource_description,
            resource_group: data.resource_group,
            resource_order: data.resource_order,
          })
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  updateResource = async () => {
    await this.validate()
    const id = this.state.urlParams.id
    const {
      loading,
      errorMessage,
      formError,
      resource_name,
      resource_link,
      resource_description,
      resourcetype_id,
      resource_group,
      resource_order,
    } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }
    const data = {
      resource_name: resource_name,
      resourcetype_id: resourcetype_id,
      resource_link: resource_link,
      resource_description,
      resource_group: resource_group,
      resource_order: isNaN(parseInt(resource_order)) ? 999 : resource_order,
      table_name: 'resources',
      id: id,
    }

    let form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })
    AdminApi.updateResource(form_data)
      .then((result) => {
        this.setState({ loading: false })
        this.props.history.push('/admin-resources')
        toast.success('Resource updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  //Fetch resource type data
  fetchResourceTypeData = async () => {
    const {
      match: { params },
    } = this.props
    this.setState({ loading: true })
    AdminApi.getResourcesType()
      .then((result) => {
        this.setState({ resourceTypeList: result.data.data || [] })
      })
      .then(() => {
        if (params && params.id) {
          this.fetchResourceData(params.id)
        } else {
          this.setState({ loading: false })
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  render() {
    toast.configure()
    const { urlParams, loading } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main id="AddResourcePage" className="mainSection">
            <MDBContainer fluid className="mt-5">
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>
                  <a href="/admin-resources">List</a>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>
                  {!!(urlParams && urlParams.id)
                    ? 'Edit Resource'
                    : 'Add Resource'}
                </MDBBreadcrumbItem>
              </MDBBreadcrumb>
              <h2 className="mb-3">
                {!!(urlParams && urlParams.id)
                  ? 'Edit Resource'
                  : 'Add Resource'}
              </h2>
              <hr />
              {loading ? (
                <div className="loadingOverlay">
                  <h4 className={'loader-text p4'}>
                    <MDBSpinner />
                  </h4>
                </div>
              ) : (
                ''
              )}
              <MDBRow>
                <MDBCol size="12">
                  <form className="content-wrapper">
                    <MDBRow>
                      <MDBCol size="12" md="4">
                        <label className="grey-text" htmlFor="resource_name">
                          Resource Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="resource_name"
                          id="resource_name"
                          value={this.state.resource_name}
                          onChange={this.#onChange}
                        />
                      </MDBCol>
                      <MDBCol size="12" md="3">
                        <label className="grey-text" htmlFor="resource_group">
                          Resource Group
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="resource_group"
                          id="resource_group"
                          value={this.state.resource_group}
                          onChange={this.#onChange}
                        />
                      </MDBCol>
                      <MDBCol size="12" md="3">
                        <label className="grey-text" htmlFor="resourcetype_id">
                          Resource Type
                        </label>
                        <select
                          className="form-control"
                          name="resourcetype_id"
                          id="resourcetype_id"
                          value={this.state.resourcetype_id}
                          onChange={this.#onChange}
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          {this.state.resourceTypeList.map((value) => (
                            <option key={value.id} value={value.id}>
                              {value.rt_name}
                            </option>
                          ))}
                        </select>
                      </MDBCol>
                      <MDBCol size="12" md="2">
                        <label className="grey-text" htmlFor="resourcetype_id">
                          Resource Order
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="resource_order"
                          id="resource_order"
                          value={this.state.resource_order}
                          onChange={this.#onChange}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol size="12" className="mt-4">
                        <label className="grey-text" htmlFor="resource_link">
                          Resource Link
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="resource_link"
                          id="resource_link"
                          value={this.state.resource_link}
                          onChange={this.#onChange}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol size="12" className="mt-4">
                        <label
                          className="grey-text"
                          htmlFor="resource_description"
                        >
                          Resource Description
                        </label>
                        <textarea
                          className="form-control"
                          name="resource_description"
                          id="resource_description"
                          value={this.state.resource_description}
                          onChange={this.#onChange}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol size="12">
                        <MDBBtn
                          color="unique"
                          type="button"
                          className="btn-block mt-5"
                          onClick={() => {
                            this.state.urlParams && this.state.urlParams.id
                              ? this.updateResource()
                              : this.createResource()
                          }}
                        >
                          {loading ? 'Saving...' : 'Save'}
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AddResourcePage
