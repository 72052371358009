import BaseModelFactory from './base.factory'
import KnowledgebaseArticleService from './../services/KnowledgebaseArticle.service'
import KnowledgebaseArticle from './../models/knowledgebase-article.model'

class KnowledgebaseArticleFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: KnowledgebaseArticleService, Model: KnowledgebaseArticle }
    )
  static findById = async (id) =>
    await this._findBy(
      { id },
      { Service: KnowledgebaseArticleService, Model: KnowledgebaseArticle }
    )
  static findAllByCategoryId = async (id) =>
    await this._findAllBy(
      { category_id: id },
      { Service: KnowledgebaseArticleService, Model: KnowledgebaseArticle }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: KnowledgebaseArticleService,
      Model: KnowledgebaseArticle,
    })
}

export default KnowledgebaseArticleFactory
