import React, { Component, Fragment } from 'react'
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBSpinner,
  MDBIcon,
} from 'mdbreact'
import { UIInputCheckbox, UIDropdown } from '../../components/forms/form-fields'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { toast } from 'react-toastify'
import TrainingService from '../../shared/services/Training.service'
import TrainingQuestionService from '../../shared/services/TrainingQuestion.service'
import TrainingAnswerService from '../../shared/services/TrainingAnswer.service'
import TrainingQuizService from '../../shared/services/TrainingQuiz.service'

import './AddTrainingQuiz.page.scss'

class AddTraniningQuiz extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      isQuizSaving: false,
      isQuestionSaving: false,
      isAnswerSaving: false,
      breakWidth: 1300,
      windowWidth: 0,
      term_name: '',
      term_link: '',
      errorMessage: '',
      formError: false,
      trainings: [],
      training_quiz: null,
      training_quiz_id: null,
      training_quiz_existed: false,
      training_questions: [],
      training_answers: [],
      correct_label: null,
    }
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params })

    const trainings = await TrainingService.search({
      pagination: false,
      orderBy: { training_name: 'ASC' },
    })
    this.setState({ trainings: trainings.models })

    if (params && params.id) {
      this.fetchTrainingData(params.id)
      const training_quiz_data = await TrainingQuizService.get(
        parseInt(params.id)
      )
      this.setState({
        training_quiz: training_quiz_data.training_id,
        training_quiz_existed: true,
      })
    }
  }

  getTrainingQuizOptions = () => {
    return this.state.trainings.map((training) => ({
      text: <>{training.training_name}</>,
      value: training.id,
      checked: !!(
        this.state.training_quiz &&
        parseInt(this.state.training_quiz) === parseInt(training.id)
      ),
    }))
  }

  TrainingQuizHandle = async () => {
    this.setState({ isQuizSaving: true })
    try {
      if (this.state.urlParams && this.state.urlParams.id) {
        await TrainingQuizService.update(this.state.urlParams.id, {
          training_id: this.state.training_quiz,
        })
        this.setState({ isQuizSaving: false })
        toast.success('Training Quiz has been updated successfully.', {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        const new_training_quiz = await TrainingQuizService.store({
          training_id: this.state.training_quiz,
        })
        this.setState({
          isQuizSaving: false,
          training_quiz_existed: true,
          training_quiz_id: new_training_quiz.id,
        })
        toast.success('Training Quiz has been created successfully.', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    } catch (error) {
      this.setState({ isQuizSaving: false })
      toast.error('Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  TrainingQuestionHandle = async (question) => {
    if (question.question === '' || !question.question) {
      toast.error('Question is required!', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return
    }

    this.setState({ isQuestionSaving: true })
    try {
      if (question.hasOwnProperty('id')) {
        await TrainingQuestionService.update(question.id, {
          question: question.question,
        })
        this.setState({ isQuestionSaving: false })
        toast.success('Training Question has been updated successfully.', {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        await TrainingQuestionService.store({
          question: question.question,
          quiz_id: this.state.urlParams.id || this.state.training_quiz_id,
        })

        const new_question = await TrainingQuestionService.search({
          search: {
            quiz_id: this.state.urlParams.id || this.state.training_quiz_id,
          },
          pagination: false,
        })

        this.setState({
          isQuestionSaving: false,
          training_questions: new_question.models,
        })

        toast.success('Training Question has been created successfully.', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    } catch (error) {
      this.setState({ isQuestionSaving: false })
      toast.error('Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  addQuestion = () => {
    let quesions = this.state.training_questions
    quesions.push({ question: '' })
    this.setState({ training_questions: quesions })
  }

  deleteQuestion = async (question, idx) => {
    this.setState({ loading: true })
    try {
      if (question.hasOwnProperty('id')) {
        await TrainingQuestionService.update(question.id, {
          deleted_at: new Date().toISOString().slice(0, -5),
        })
        toast.success('Training Question has been deleted successfully.', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }

      let remaining_questions = this.state.training_questions
      remaining_questions.splice(idx, 1)
      this.setState({ training_questions: remaining_questions, loading: false })
    } catch (error) {
      this.setState({ loading: false })
      toast.error('Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  //Change event for input fields
  onChangeAnswers = (event, index) => {
    let answers = this.state.training_answers
    answers[index].label = event.target.value

    this.setState({
      training_answers: answers,
    })
  }

  onChangeQuestion = (event, index) => {
    let questions = this.state.training_questions
    questions[index].question = event.target.value

    this.setState({
      training_questions: questions,
    })
  }

  addAnswer = (event, questionId) => {
    let answers = this.state.training_answers
    answers.push({ question_id: questionId, label: '', is_correct: false })
    this.setState({ training_answers: answers })
  }

  deleteAnswer = async (event, answer, index) => {
    this.setState({ loading: true })
    try {
      if (answer.hasOwnProperty('id')) {
        await TrainingAnswerService.update(answer.id, {
          deleted_at: new Date().toISOString().slice(0, -5),
        })
        toast.success('Training Answer has been deleted successfully.', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }

      let remaining_answers = this.state.training_answers
      remaining_answers.splice(index, 1)
      this.setState({ training_answers: remaining_answers, loading: false })
    } catch (error) {
      this.setState({ loading: false })
      toast.error('Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  saveAnswerSequentially = async (updating_answers, question, index = 0) => {
    if (index < updating_answers.length) {
      const answer = updating_answers[index]
      if (answer.hasOwnProperty('id')) {
        await TrainingAnswerService.update(answer.id, {
          question_id: question.id,
          label: answer.label,
          is_correct: answer.is_correct ? 1 : 0,
        })
      } else {
        await TrainingAnswerService.store({
          question_id: question.id,
          label: answer.label,
          is_correct: answer.is_correct ? 1 : 0,
        })
      }
      await this.saveAnswerSequentially(updating_answers, question, index + 1)
    }
  }

  saveAnswer = async (event, question) => {
    const updating_answers = this.state.training_answers.filter(
      (answer) => answer.question_id === question.id
    )
    if (
      updating_answers.filter((answer) => answer.label === '' || !answer.label)
        .length > 0
    ) {
      toast.error('Labels are required!', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return
    }

    this.setState({ loading: true })
    try {
      await this.saveAnswerSequentially(updating_answers, question)

      // // Wait for all promises to complete
      // await Promise.all(updatePromises);

      const question_ids = this.state.training_questions?.map(
        (question) => question.id
      )
      const answers = await TrainingAnswerService.search({
        search: { question_id: question_ids },
        pagination: false,
      })

      this.setState({
        loading: false,
        training_answers: answers.models,
      })

      toast.success('Training Answers have been updated successfully.', {
        position: toast.POSITION.TOP_RIGHT,
      })
    } catch (error) {
      this.setState({ loading: false })
      toast.error('Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  fetchTrainingData = async (id) => {
    this.setState({ loading: true })
    const questions = await TrainingQuestionService.search({
      search: { quiz_id: id },
      pagination: false,
    })

    if (questions.models.length > 0) {
      const question_ids = questions?.models.map((model) => model.id)
      const answers = await TrainingAnswerService.search({
        search: { question_id: question_ids },
        pagination: false,
      })
      this.setState({
        loading: false,
        training_questions: questions.models,
        training_answers: answers.models,
      })
    } else {
      this.setState({
        loading: false,
      })
    }
  }

  render() {
    toast.configure()
    const { urlParams, loading } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>
                  <a href="/admin/training-quizes">Training Quizzes</a>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>
                  {!!(urlParams && urlParams.id)
                    ? 'Edit Training Quiz'
                    : 'Add Training Quiz'}
                </MDBBreadcrumbItem>
              </MDBBreadcrumb>
              <div className="float-right">
                {/* <MDBBtn size="sm" color="indigo" href={this.templateDocument}>
                  Download a Template Document
                </MDBBtn> */}
              </div>
              <h2>
                {!!(urlParams && urlParams.id)
                  ? 'Edit Training Quiz'
                  : 'Add Training Quiz'}
              </h2>
              <hr />
              {loading ? (
                <div className="loadingOverlay">
                  <h4 className={'loader-text p4'}>
                    <MDBSpinner />
                  </h4>
                </div>
              ) : (
                ''
              )}
              <MDBRow>
                <MDBCol size="12" className="mb-3">
                  <form className="content-wrapper">
                    <div className="form-row">
                      <div className="col-md-12 mb-3">
                        <h5>Training Quiz</h5>
                      </div>
                      <div className="form-group col-md-12">
                        <UIDropdown
                          label="Training Quiz"
                          name="training_quiz"
                          search
                          className="av-carrier-selector"
                          options={this.getTrainingQuizOptions()}
                          selected={this.state.training_quiz}
                          onChange={(evt) => {
                            this.setState({
                              training_quiz: parseInt(evt.target.value),
                            })
                          }}
                          required={true}
                          value={this.state.training_quiz}
                          rules={{ required: true }}
                        />
                      </div>
                    </div>
                    <div className="form-row text-center mt-4">
                      <MDBBtn
                        color="unique"
                        type="button"
                        className="btn-block"
                        onClick={() => {
                          this.TrainingQuizHandle()
                        }}
                      >
                        {this.state.isQuizSaving ? 'Saving...' : 'Save'}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="12" className="mb-2">
                  <div className="ml-2">
                    Add Question
                    <MDBBtn
                      color="info"
                      disabled={!this.state.training_quiz_existed}
                      id="AddTrainingQuestionButton"
                      onClick={(event) => this.addQuestion(event)}
                      floating
                      size="sm"
                    >
                      <MDBIcon icon="plus" />
                    </MDBBtn>
                  </div>
                </MDBCol>
              </MDBRow>
              {this.state.training_questions.map((question, idx) => {
                return (
                  <MDBRow key={idx}>
                    <MDBCol size="12" md="6" className="mb-5">
                      <form className="content-wrapper">
                        <div className="form-row">
                          <div className="col-md-12 mb-3">
                            <h5>Training Question</h5>
                          </div>
                          <div className="form-group col-md-12">
                            <label
                              className="grey-text"
                              htmlFor={`training_question_${idx + 1}`}
                            >
                              Training Question
                            </label>
                            <textarea
                              className="form-control"
                              name={`training_question_${idx + 1}`}
                              id={`training_question_${idx + 1}`}
                              rows={7}
                              value={question?.question}
                              onChange={(evt) =>
                                this.onChangeQuestion(evt, idx)
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row text-center mt-4 question-buttons">
                          <MDBBtn
                            color="unique"
                            disabled={!this.state.training_quiz_existed}
                            type="button"
                            className="btn-block"
                            onClick={() => {
                              this.TrainingQuestionHandle(question)
                            }}
                          >
                            {this.state.isQuestionSaving ? 'Saving...' : 'Save'}
                          </MDBBtn>

                          <MDBBtn
                            color="primary"
                            disabled={!this.state.training_quiz_existed}
                            type="button"
                            className="btn-block"
                            onClick={() => {
                              this.deleteQuestion(question, idx)
                            }}
                          >
                            Delete
                          </MDBBtn>
                        </div>
                      </form>
                    </MDBCol>
                    <MDBCol size="12" md="6" className="mb-5">
                      <form className="content-wrapper">
                        <div className="form-row">
                          <div className="col-md-12 mb-3 d-flex justify-content-between align-items-center">
                            <h5>Training Answers</h5>
                            <MDBBtn
                              color="info"
                              disabled={!question.hasOwnProperty('id')}
                              id="AddTrainingAnswerButton"
                              onClick={(event) =>
                                this.addAnswer(event, question.id)
                              }
                              floating
                              size="sm"
                            >
                              <MDBIcon icon="plus" />
                            </MDBBtn>
                          </div>
                          {this.state.training_answers?.map((answer, index) => {
                            if (answer.question_id === question.id)
                              return (
                                <div
                                  className="form-group col-md-12 d-flex justify-content-between align-items-end"
                                  key={index}
                                >
                                  <div className="w-100">
                                    <label
                                      className="grey-text"
                                      htmlFor={'training_answer_' + (index + 1)}
                                    >
                                      Label
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name={'training_answer_' + (index + 1)}
                                      id={'training_answer_' + (index + 1)}
                                      value={answer?.label}
                                      onChange={(event) =>
                                        this.onChangeAnswers(event, index)
                                      }
                                    />
                                  </div>
                                  <div className="mb-2">
                                    <UIInputCheckbox
                                      label=""
                                      id={`training_answer_${
                                        index + 1
                                      }_correct_toggle`}
                                      name={`training_answer_${
                                        index + 1
                                      }_correct_toggle`}
                                      checked={answer.is_correct}
                                      onChange={(evt) => {
                                        let answers =
                                          this.state.training_answers
                                        answers[index].is_correct =
                                          !answers[index].is_correct
                                        this.setState({
                                          training_answers: answers,
                                        })
                                      }}
                                    />
                                  </div>
                                  <div className="">
                                    <UIInputCheckbox
                                      label=" "
                                      id={`training_answer_${
                                        index + 1
                                      }_correct_toggle`}
                                      name={`training_answer_${
                                        index + 1
                                      }_correct_toggle`}
                                      checked={answer.is_correct}
                                      onChange={(evt) => {
                                        let answers =
                                          this.state.training_answers
                                        answers[index].is_correct =
                                          !answers[index].is_correct
                                        this.setState({
                                          training_answers: answers,
                                        })
                                      }}
                                    />
                                  </div>
                                  <div className="mb-2">
                                    <MDBIcon
                                      icon="trash"
                                      size="lg"
                                      className="red-text cursor-pointer"
                                      onClick={(event) =>
                                        this.deleteAnswer(event, answer, index)
                                      }
                                    />
                                  </div>
                                </div>
                              )
                            else return <></>
                          })}
                          <div className="col-md-12 mt-1 d-flex justify-content-between align-items-center">
                            <MDBBtn
                              color="unique"
                              disabled={
                                this.state.training_answers.filter(
                                  (answer) => answer.question_id === question.id
                                ).length === 0 || !question.hasOwnProperty('id')
                              }
                              type="button"
                              className="btn-block"
                              onClick={(event) => {
                                this.saveAnswer(event, question)
                              }}
                            >
                              {this.state.isAnswerSaving ? 'Saving...' : 'Save'}
                            </MDBBtn>
                          </div>
                        </div>
                      </form>
                    </MDBCol>
                  </MDBRow>
                )
              })}
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AddTraniningQuiz
