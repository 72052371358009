import BaseModelFactory from './base.factory'
import LeadVendorService from './../services/LeadVendor.service'
import LeadVendor from './../models/lead-vendor.model'

class LeadVendorFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: LeadVendorService, Model: LeadVendor }
    )
  static findAll = async () =>
    await this._findAllBy({}, { Service: LeadVendorService, Model: LeadVendor })
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: LeadVendorService, Model: LeadVendor }
    )
  static findAllById = async (id) =>
    await this._findAllBy(
      { id: id },
      { Service: LeadVendorService, Model: LeadVendor }
    )
  static create = (payload) =>
    this._create(payload, { Service: LeadVendorService, Model: LeadVendor })
}

export default LeadVendorFactory
