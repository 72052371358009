import React, { Component, Fragment } from 'react'
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBInput,
  MDBFileInput,
  MDBListGroup,
  MDBListGroupItem,
  MDBSpinner,
} from 'mdbreact'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'

class AddProduct extends Component {
  constructor(props) {
    super(props)
    this.state = {
      prefix: '',
      suffix: '',
      loading: false,
      formError: false,
      errorMessage: '',
      p_coverages: [],
      carrier_id: '',
      p_name: '',
      p_term_length: 1,
      p_term_length_min: 1,
      p_term_length_max: 1,
      p_img: '',
      coveragesList: [],
      carriersList: [],
    }
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params })

    /*if (params && params.id) {
            this.fetchProductsData(params.id);
        }*/

    this.fetchCarriers()
  }

  handleSwitchChange = (nr) => () => {
    let switchNumber = `${nr}`
    this.setState({
      [switchNumber]: this.state[switchNumber] ? 0 : 1,
    })
  }

  //for responsivness of content acc to screen size

  //Change event for input fields
  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  validate = async () => {
    const {
      p_coverages,
      carrier_id,
      p_name,
      p_term_length_min,
      p_term_length_max,
      p_img,
    } = this.state
    const allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i

    if (!(p_name && p_name.trim())) {
      this.setState({ formError: true, errorMessage: 'Product Name Required' })
      return
    }

    if (!carrier_id || !parseInt(carrier_id)) {
      this.setState({ formError: true, errorMessage: 'Carrier Required' })
      return
    }

    if (p_term_length_min > p_term_length_max) {
      this.setState({
        formError: true,
        errorMessage: "Minimum term length can't exceed maximum term length",
      })
      return
    }

    if (!p_img) {
      this.setState({ formError: true, errorMessage: 'Product Logo Required' })
      return
    }
    if (typeof p_img !== 'string' && !allowedImageExtensions.test(p_img.name)) {
      this.setState({ formError: true, errorMessage: 'Invalid file type' })
      return
    }

    if (!p_coverages || !p_coverages.length) {
      this.setState({
        formError: true,
        errorMessage: 'Please select at least one coverage',
      })
      return
    }

    this.setState({ formError: false, errorMessage: '' })
  }

  //create coverages
  createProduct = async () => {
    await this.validate()
    const {
      p_coverages,
      carrier_id,
      p_name,
      p_term_length,
      p_term_length_min,
      p_term_length_max,
      p_img,
      loading,
      formError,
      errorMessage,
    } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }
    const data = {
      p_name: p_name,
      p_coverages: p_coverages.toString(),
      carrier_id: carrier_id,
      p_img: p_img,
      p_term_length: p_term_length,
      p_term_length_min: p_term_length_min,
      p_term_length_max: p_term_length_max,
    }

    const form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })

    AdminApi.createProduct(form_data)
      .then((result) => {
        this.setState({ loading: false })
        this.props.history.push('/admin-products')
        toast.success('Product created successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  updateProduct = async () => {
    await this.validate()
    const id = this.state.urlParams.id
    const {
      p_coverages,
      carrier_id,
      p_name,
      p_term_length,
      p_term_length_min,
      p_term_length_max,
      p_img,
      loading,
      formError,
      errorMessage,
    } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }
    const data = {
      p_name: p_name,
      p_coverages: p_coverages.toString(),
      carrier_id: carrier_id,
      p_term_length: p_term_length,
      p_term_length_min: p_term_length_min,
      p_term_length_max: p_term_length_max,
      id: id,
    }

    if (p_img && typeof p_img !== 'string') {
      data['p_img'] = p_img
    }

    const form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })
    AdminApi.updateProduct(form_data)
      .then((result) => {
        this.setState({ loading: false })
        this.props.history.push('/admin-products')
        toast.success('Product updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchCarriers = async () => {
    this.setState({ loading: true })
    AdminApi.getCarriersList()
      .then((result) => {
        if (result && result.data && result.data.data) {
          const content = result.data.data
          this.setState({
            carriersList: content,
          })
        }
        this.fetchCoverages()
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  fetchCoverages = async () => {
    const {
      match: { params },
    } = this.props
    AdminApi.getCoverages()
      .then((result) => {
        if (result && result.data && result.data.data) {
          const content = result.data.data
          this.setState({
            coveragesList: content,
          })

          if (params && params.id) {
            this.fetchProductsData(params.id)
          } else {
            this.setState({ loading: false })
          }
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  fetchProductsData = async (id) => {
    AdminApi.getProductById(id)
      .then((result) => {
        this.setState({ loading: false })
        if (result && result.data && result.data.data) {
          const content = result.data.data
          const prefix = result.data.imageUrl.prefix
          const suffix = result.data.imageUrl.suffix
          this.setState({
            p_name: content.p_name,
            p_coverages: content.productcoverages
              ? content.productcoverages.split(',')
              : '',
            carrier_id: content.carrier_id,
            p_term_length: content.p_term_length,
            p_term_length_min: content.p_term_length_min,
            p_term_length_max: content.p_term_length_max,
            p_img: prefix + content.p_img + suffix,
          })
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  Months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  MonthsList =
    this.Months.length > 0 &&
    this.Months.map((item, i) => {
      return (
        <option key={i} value={item}>
          {item} {item === 1 ? 'Month' : 'Months'}
        </option>
      )
    })

  _handleCoverageSelect = (event) => {
    event.persist()
    const { p_coverages } = this.state
    if (p_coverages.indexOf(event.target.value) > -1) {
      p_coverages.splice(p_coverages.indexOf(event.target.value), 1)
    } else {
      p_coverages.push(event.target.value)
    }
    this.setState({ p_coverages })
  }

  render() {
    toast.configure()
    const {
      p_coverages,
      carrier_id,
      p_name,
      p_term_length,
      p_term_length_min,
      p_term_length_max,
      p_img,
      carriersList,
      coveragesList,
      urlParams,
      loading,
    } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>
                  <a href="/admin-products">List</a>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>
                  {!!(urlParams && urlParams.id)
                    ? 'Edit Product'
                    : 'Add Product'}
                </MDBBreadcrumbItem>
              </MDBBreadcrumb>
              <h2>
                {!!(urlParams && urlParams.id) ? 'Edit Product' : 'Add Product'}
              </h2>
              <hr />
              {loading ? (
                <div className="loadingOverlay">
                  <h4 className={'loader-text p4'}>
                    <MDBSpinner />
                  </h4>
                </div>
              ) : (
                ''
              )}
              <MDBRow>
                <MDBCol size="6">
                  <form className="content-wrapper">
                    <img src={p_img} alt="product" className="mt-5 mb-5" />
                    <div className="form-row">
                      <div className="col-md-12 mb-3">
                        <label htmlFor={'p_name'}>Product Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="p_name"
                          id="p_name"
                          value={p_name}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6 mb-3">
                        <label htmlFor={'carrier_id'}>Carrier</label>
                        <select
                          className="form-control"
                          name="carrier_id"
                          id="carrier_id"
                          value={carrier_id}
                          onChange={this.onChange}
                        >
                          <option value="0">Select Carriers</option>
                          {carriersList.length > 0 &&
                            carriersList.map((item, i) => {
                              return (
                                <option key={i} value={item.id}>
                                  {item.c_name}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor={'p_term_length'}>Term Length</label>
                        <select
                          className="form-control"
                          name="p_term_length"
                          id="p_term_length"
                          value={p_term_length}
                          onChange={this.onChange}
                        >
                          {this.MonthsList}
                        </select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6 mb-3">
                        <label htmlFor={'p_term_length_min'}>
                          Minimum Term Length
                        </label>
                        <select
                          className="form-control"
                          name="p_term_length_min"
                          id="p_term_length_min"
                          value={p_term_length_min}
                          onChange={this.onChange}
                        >
                          {this.MonthsList}
                        </select>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor={'p_term_length_max'}>
                          Max Term Length
                        </label>
                        <select
                          className="form-control"
                          name="p_term_length_max"
                          id="p_term_length_max"
                          value={p_term_length_max}
                          onChange={this.onChange}
                        >
                          {this.MonthsList}
                        </select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6 mb-3">
                        <label>Product Logo</label>
                        <MDBFileInput
                          name="p_img"
                          value={p_img}
                          getValue={(value) => {
                            this.setState({ p_img: value[0] })
                          }}
                        />
                        {!!(
                          urlParams &&
                          urlParams.id &&
                          p_img &&
                          typeof p_img == 'string'
                        ) && (
                          <div>
                            <a
                              href={p_img}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="d-inline-block"
                            >
                              <img src={p_img} alt="product" />
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-8 mb-2">
                        <MDBListGroup>
                          <MDBListGroupItem active>Coverages</MDBListGroupItem>

                          {coveragesList.length > 0 &&
                            coveragesList.map((item, i) => {
                              return (
                                <MDBListGroupItem hover key={item.id}>
                                  <MDBInput
                                    checked={
                                      p_coverages.indexOf(item.id.toString()) >
                                      -1
                                    }
                                    label={item.coverage_name}
                                    type="checkbox"
                                    id={item.id.toString()}
                                    value={item.id}
                                    onChange={(event) => {
                                      this._handleCoverageSelect(event)
                                    }}
                                  />
                                </MDBListGroupItem>
                              )
                            })}

                          {/*<MDBListGroupItem href="#" active>Coverages</MDBListGroupItem>
                                            <MDBListGroupItem href="#" hover>
                                                <MDBInput label="Accident Medical Plans" type="checkbox" id="checkbox1" />
                                            </MDBListGroupItem>
                                            <MDBListGroupItem href="#" hover>
                                                <MDBInput label="Dental Insurance" type="checkbox" id="checkbox1" />
                                            </MDBListGroupItem>
                                            <MDBListGroupItem href="#" hover>
                                                <MDBInput label="Group Health" type="checkbox" id="checkbox1" />
                                            </MDBListGroupItem>*/}
                        </MDBListGroup>
                      </div>
                    </div>
                    <div className="text-center mt-4">
                      <MDBBtn
                        color="unique"
                        type="button"
                        className="btn-block"
                        onClick={() => {
                          this.state.urlParams && this.state.urlParams.id
                            ? this.updateProduct()
                            : this.createProduct()
                        }}
                      >
                        {loading ? 'Saving...' : 'Save'}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AddProduct
