import React from 'react'
import env from './../../../../environments/environment'
import SwiperCore, { Scrollbar } from 'swiper'
import {
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBContainer,
} from 'mdbreact'

import './Testimonials.scss'

SwiperCore.use([Scrollbar])

const AgentTestimonials = (props) => (
  <section
    id="testimonials"
    data-on="false"
    data-md-on="true"
    style={{
      backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fbg.testimonials.jpg?alt=media)`,
      paddingTop: '60px',
      paddingBottom: '60px',
    }}
    className="rd-parallax bg-gray-base bg-image py-3"
  >
    <MDBContainer fluid>
      <div className="range">
        <div className="cell-xs-12 text-center">
          <h2>
            <span className="sub-head-small text-white font--lato">
              Testimonials
            </span>
          </h2>
        </div>
      </div>
      <MDBCarousel
        activeItem={1}
        length={
          props.data && props.data.testimonials
            ? props.data.testimonials.length
            : 0
        }
        showControls={true}
        interval={
          props.data.testimonial_timer
            ? props.data.testimonial_timer * 1000
            : 6000
        } // MDBCarousel's default interval is 6000
        showIndicators={false}
        className="z-depth-1 pt-5"
        slide
      >
        <MDBCarouselInner>
          {Array.isArray(props?.data?.testimonials) ? (
            props.data.testimonials.map((t, i) => {
              return (
                <MDBCarouselItem itemId={i + 1} key={i}>
                  <blockquote className="quote-minimal quote-minimal-inverse mb-5">
                    <div className="quote-body">
                      <p style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                        <q>
                          “{t.testimonial}”
                          <br />– {t.author}
                        </q>
                      </p>
                    </div>
                  </blockquote>
                </MDBCarouselItem>
              )
            })
          ) : (
            <></>
          )}
        </MDBCarouselInner>
      </MDBCarousel>
    </MDBContainer>
  </section>
)

export default AgentTestimonials
