import { observer } from 'mobx-react-lite'
import React from 'react'
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
} from 'mdbreact'
import { isReportValid, getDefaultReport } from './../../helpers'
import { reportingCategories } from './../../variables'
import UserProfileService from './../../../../shared/services/UserProfile.service'

import './CategorySelectorDropdown.scss'

const ucwords = (str) =>
  str.replace(/^([a-z])|[\s_]+([a-z])/g, ($1) => $1.toUpperCase())

const getReportingCategories = () => {
  return reportingCategories.filter((cat) => {
    if (!cat.usertypeIds) return true
    if (Array.isArray(cat.usertypeIds))
      return cat.usertypeIds.filter((u) => UserProfileService.isA(u)).length > 0
    return false
  })
}

const CategorySelectorDropdown = (props) => {
  const onChange = (category) => {
    let report = props.report
    if (!isReportValid(category, props.report))
      report = getDefaultReport(category)

    props.onChange({ category, report })
  }

  return (
    <MDBDropdown className="cat-picker">
      <MDBDropdownToggle>
        <span>{ucwords(props.category.replace(/-+/g, ' '))}</span>&nbsp;
        <MDBIcon icon="angle-down" />
      </MDBDropdownToggle>
      <MDBDropdownMenu basic>
        {getReportingCategories()
          .sort((a, b) => `${a.label}`.localeCompare(`${b.label}`, 'en'))
          .map((cat) => (
            <MDBDropdownItem
              key={'cat-' + cat.key}
              onClick={() => onChange(cat.key)}
            >
              {cat.label}
            </MDBDropdownItem>
          ))}
      </MDBDropdownMenu>
    </MDBDropdown>
  )
}

export default observer(CategorySelectorDropdown)
