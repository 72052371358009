import React from 'react'
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact'
import ToolTip from './../../../../components/shared/ToolTip/ToolTip.component'
import { Subscription } from 'rxjs'
import CalendarService from './../../shared/Calendar.service'
import moment from 'moment'
import AddToCalendarButtons from './../AddToCalendarButtons/AddToCalendarButtons.component'
import './EventViewer.scss'
import { cleanHTML } from '../../../../shared/formatters/cleanHTML'

class EventViewer extends React.Component {
  state = {
    isOpen: false,
    canAdd: false,
  }

  _currentEvent = {
    source_id: undefined,
    calendar_id: undefined,
    title: undefined,
    start: undefined,
    end: undefined,
    description: undefined,
  }
  __subscriptions$ = new Subscription()

  /*
   * Component Accessor Methods ----------------------
   *
   */
  get isOpen() {
    return !!this.state.isOpen
  }

  /*
   * Component Public Methods ------------------------
   *
   */

  /*
   * Component Private Methods -----------------------
   *
   */
  _setEvent = (currEvent) => {
    currEvent =
      currEvent &&
      typeof currEvent === 'object' &&
      Object.keys(currEvent).length
        ? currEvent
        : undefined

    // set event details & open || change event details.
    if ((currEvent && !this.isOpen) || (currEvent && this.isOpen)) {
      // Function to add 'target="_blank"' to links has http or https, not for email link
      const updatedHtmlContent =
        currEvent._def.extendedProps.description.replace(
          /<a(?!.*?mailto:)/g,
          '<a target="_blank"'
        )

      this._currentEvent = {
        source_id: currEvent.source_id,
        calendar_id: currEvent.calendar_id,
        title: cleanHTML(currEvent.title),
        start: currEvent.start,
        end: currEvent.end,
        description: cleanHTML(updatedHtmlContent),
      }

      this.setState({ isOpen: true })
    }

    // close modal.
    else if (!currEvent && this.isOpen) {
      this._currentEvent = {
        source_id: undefined,
        calendar_id: undefined,
        title: undefined,
        start: undefined,
        end: undefined,
        description: undefined,
      }
      this.setState({ isOpen: false })
    }
  }

  _deleteEvent = () => {
    CalendarService.deleteEventsById(this._currentEvent.source_id).finally(
      () => {
        this.props.onDelete()
        this._closeModel()
      }
    )
  }

  _closeModel = () => {
    this._setEvent(undefined)
  }

  /*
   * Component Event Methods -------------------------
   *
   */
  componentDidMount() {
    this.__subscriptions$.add(
      CalendarService.onCurrentEventChange().subscribe((currEvent) =>
        this._setEvent(currEvent)
      )
    )
  }

  componentWillUnmount() {
    this.__subscriptions$.unsubscribe()
  }

  render() {
    const isOpen = this.state.isOpen,
      { calendar_id, title, start, end, description } = this._currentEvent,
      eventSource = calendar_id
        ? CalendarService.getEventSource(calendar_id)
        : undefined
    return (
      <MDBModal
        id="EventViewerComponent"
        isOpen={isOpen}
        toggle={this._closeModel}
        size="fluid"
      >
        <MDBModalHeader
          toggle={this._closeModel}
          style={eventSource ? eventSource.style : null}
        >
          {title}
          {this.props.canDelete ? (
            <button className="delete-btn" onClick={this._deleteEvent}>
              Delete Calendar Event
            </button>
          ) : (
            <></>
          )}
        </MDBModalHeader>
        <MDBModalBody>
          <div
            className={
              'cal-summary ' + (this.state.canAdd ? 'can-add-event' : '')
            }
          >
            <div className="cal-dates">
              <strong>Event Date & Time:</strong>
              <div className="content">
                <div className="cal-date-boundry">
                  <span className="cal-label">Start Date:</span>
                  <span className="cal-time">
                    {moment(start).format('hh:mm a')}
                  </span>
                  <span className="cal-date">
                    {moment(start).format('MM/DD/YYYY')}
                  </span>
                </div>
                <div className="cal-date-boundry">
                  <span className="cal-label">End Date:</span>
                  <span className="cal-time">
                    {moment(end).format('hh:mm a')}
                  </span>
                  <span className="cal-date">
                    {moment(end).format('MM/DD/YYYY')}
                  </span>
                </div>
              </div>
            </div>
            <div className="cal-add-btn">
              <div>
                <strong>Add to Your Calendar:</strong>
                <ToolTip
                  placement={'bottom'}
                  content={
                    <>
                      Office 365 Event will add the event to your USABG
                      Corporate Calendar.
                      <br />
                      <br />
                      Google Event will add to your Google Calendar.
                      <br />
                      <br />
                      Office 365 & Google Calendars may prompt you to login.
                      <br />
                      <br />
                      Download Event will download an ics file to your device
                      which you'll need to open with your Calendar app.
                    </>
                  }
                  iconPosition="right"
                />
              </div>
              <div className="content">
                <AddToCalendarButtons
                  eventId={this._currentEvent.source_id}
                  onCanAdd={(canAdd) => {
                    if (this.state.canAdd !== canAdd) this.setState({ canAdd })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="cal-description">
            <strong>Event Description:</strong>
            <div className="content">
              {description && (
                <div dangerouslySetInnerHTML={{ __html: description }} />
              )}
            </div>
          </div>
          <div className="event-footer">
            <MDBBtn color="secondary" size={'sm'} onClick={this._closeModel}>
              Close
            </MDBBtn>
          </div>
        </MDBModalBody>
      </MDBModal>
    )
  }
}

export default EventViewer
