import React, { useEffect, useState, useCallback } from 'react'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import AgentWelcomeIntro from './components/AgentWelcomeIntro/AgentWelcomeIntro'
import AgentOnboarder from './../../components/onboarding/AgentOnboarder/AgentOnboarder.component'
import UserProfileService from '../../shared/services/UserProfile.service'
import TrainingService from '../../shared/services/Training.service'
import LoadingSpinner from '../../components/shared/LoadingSpinner.component'
import UserService from '../../shared/services/User.service'
import OnboardingStore from '../../components/onboarding/Onboarding.store'
import AgentProfileStore from '../../components/agents/AgentProfileIntake/AgentProfile.store'

import './WelcomePage.scss'

const WelcomePage = () => {
  const [onboarding, setOnboarding] = useState(false)
  const isAffiliateAgent = UserProfileService.isA([
      'affiliate-agent',
      'affiliate-manager',
    ]),
    isAssociateAgent = UserProfileService.isA([
      'associate-agent',
      'associate-manager',
    ]),
    isSignatureAgent = !isAffiliateAgent && !isAssociateAgent

  const getAndStoreUserDetails = async () => {
    await UserProfileService.fetchAndStoreUserDetails()
    OnboardingStore.getStages(true)
    AgentProfileStore.setValidity(
      'eopolicy',
      !UserProfileService.isA([
        'affiliate-agent',
        'affiliate-manager',
        'associate-agent',
        'associate-manager',
      ])
    )
  }

  const getReqType = useCallback(() => {
    return isAffiliateAgent
      ? null // affiliate_agent_onboarding
      : isAssociateAgent
      ? null // associate_agent_onboarding
      : isSignatureAgent
      ? {
          req_type: 'sig_agent_onboarding',
        }
      : null
  }, [isAffiliateAgent, isAssociateAgent, isSignatureAgent])

  const fetchVideo = useCallback(() => {
    const onboardingType = getReqType()
    return new Promise((resolve, reject) => {
      TrainingService.search({ pagination: false })
        .then((trainings) => {
          let t = trainings.models.filter((t) => {
            if (t.training_filters && typeof t.training_filters === 'string') {
              t.training_filters = JSON.parse(t.training_filters)
            }
            return t.training_filters && t.training_filters.onboarding
          })
          if (!onboardingType) {
            resolve(t.shift())
          } else {
            const filteredTrainings = t.filter((training) => {
              return training.training_filters.onboarding.includes(
                onboardingType.req_type.replace('_onboarding', '')
              )
            })
            if (filteredTrainings.length > 0) {
              resolve(filteredTrainings.shift())
            } else {
              resolve(t.shift())
            }
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }, [getReqType])

  const hasCompletedTraining = useCallback(() => {
    const reqType = getReqType()
    return new Promise((resolve, reject) => {
      if (!reqType) resolve(false)
      UserService.hasCompletedTrainings(UserProfileService.get('id'), reqType)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }, [getReqType])

  const init = useCallback(async () => {
    try {
      await getAndStoreUserDetails()
      const training = await fetchVideo()
      if (!training) throw new Error('Could not find training video')
      const hasCompleted = await hasCompletedTraining()
      if (hasCompleted === false) {
        setOnboarding({
          ...training,
          hasCompleted: {
            current: true,
          },
        })
      } else {
        const reqType = getReqType()
        setOnboarding({
          ...training,
          hasCompleted: hasCompleted[reqType.req_type],
        })
      }
    } catch (err) {
      setOnboarding(null)
    }
  }, [fetchVideo, getReqType, hasCompletedTraining]) // Ensure this dependency array matches your actual dependencies

  useEffect(() => {
    init()
  }, [init])

  return (
    <React.Fragment>
      <DashboardLayout>
        {onboarding === false ? (
          <LoadingSpinner isActive={true} size="md" />
        ) : (
          <>
            <main id="WelcomePage" className="mainSection">
              <MDBContainer fluid className="mt-5">
                <h2 className="text-center">
                  <strong>Welcome to USABG</strong>
                </h2>
                <hr />
                {onboarding ? (
                  <MDBRow>
                    <MDBCol size="12">
                      <AgentWelcomeIntro
                        isAffiliateAgent={isAffiliateAgent}
                        isAssociateAgent={isAssociateAgent}
                        isSignatureAgent={isSignatureAgent}
                        video={onboarding}
                        init={init}
                      />
                    </MDBCol>
                  </MDBRow>
                ) : (
                  <></>
                )}
              </MDBContainer>
            </main>
            <br />
            {onboarding &&
            (!onboarding.training_code || onboarding.hasCompleted.current) ? (
              <main className="mainSection">
                <div className="mainSection-fullWidth">
                  <AgentOnboarder />
                </div>
              </main>
            ) : (
              <></>
            )}
          </>
        )}
      </DashboardLayout>
    </React.Fragment>
  )
}

export default WelcomePage
