import React from 'react'
import { MDBInput, MDBBtn } from 'mdbreact'
import UserProfileService from './../../shared/services/UserProfile.service'
import UserService from './../../shared/services/User.service'

import './UserNotesWidget.scss'

class UserNotesWidget extends React.Component {
  state = {
    note: '',
    label: 'Fetching notes ...',
    saving: false,
    saved: false,
    error: '',
    text_area_rows: 2,
  }

  #userId = null
  #metaKey = 'profile---public-notes'
  #originalNote = null
  #saveTimerId = null
  #errorTimerId = null

  #onChange = (note) => {
    this.setState({ note })
  }

  #handleErr = (error) => {
    // clear error timer.
    if (this.#errorTimerId) {
      clearTimeout(this.#errorTimerId)
      this.#errorTimerId = null
    }

    // reset success notification in a couple seconds.
    if (error) {
      this.setState({ error })
      this.#errorTimerId = setTimeout(() => this.setState({ error: '' }), 80000)
    }
  }

  #getLabel = () => {
    return this.#isPrivate()
      ? 'Administrative Notes (Private)'
      : [35, 36, 219, 222].indexOf(
          parseInt(
            UserProfileService.getCurrentUserTypeId(true) ||
              UserProfileService.getCurrentUserTypeId()
          )
        ) > -1
      ? 'Agent Notes (Public)'
      : 'USABG Agent Notes'
  }

  #fetchAgentNotes = async () => {
    if (!this.#userId) return

    UserService.getUserMetas(this.#metaKey, this.#userId)
      .then((result) => {
        result = result && result.length ? result.shift() : null
        if (result && result.meta_value) {
          this.setState({ note: result.meta_value })
          this.#originalNote = result.meta_value

          const number_of_lines_by_content = parseInt(
            this.state.note.length / 100
          )
          const number_of_lines_by_line_break =
            this.state.note.split(/\r\n|\r|\n/).length
          let rows = this.state.text_area_rows
          if (number_of_lines_by_content > number_of_lines_by_line_break) {
            rows = number_of_lines_by_content
          } else {
            rows = number_of_lines_by_line_break
          }
          this.setState({ text_area_rows: rows })
        }
      })
      .catch((error) => this.handleErr(error.message))
      .finally(() => this.setState({ label: this.#getLabel() }))
  }

  #save = () => {
    if (this.state.saving || !this.#userId) return

    if (!this.#originalNote && !this.state.note) {
      this.#handleErr('Please enter a note before saving')
      return
    }

    this.setState({ saving: true, error: '', saved: false })

    UserService.upsertUserMeta(
      this.#metaKey,
      `${this.state.note}`.trim(),
      this.#userId
    )
      .then((result) => {
        this.setState({ saving: false, saved: true }, () => {
          // clear notification timer.
          if (this.#saveTimerId) {
            clearTimeout(this.#saveTimerId)
            this.#saveTimerId = null
          }

          // reset success notification in a couple seconds.
          this.#saveTimerId = setTimeout(
            () => this.setState({ saving: false, saved: false }),
            5000
          )
        })
      })
      .catch((error) => {
        this.setState({ saving: false })
        this.#handleErr(error.message)
      })
  }

  #isPrivate = () => {
    try {
      return this.props.private === true || parseInt(this.props.private) === 1
    } catch (ex) {
      return false
    }
  }

  #build = () => {
    if (!this.#userId) {
      if (parseInt(this.props.userId) > 0) {
        this.#userId = parseInt(this.props.userId)
        this.#fetchAgentNotes()
      }
    }
  }

  componentDidMount() {
    this.#metaKey = this.#isPrivate()
      ? 'profile---private-notes'
      : 'profile---public-notes'
    this.#build()
  }

  componentDidUpdate() {
    this.#build()
  }

  #textAreaHeight = (event) => {
    if (
      this.state.text_area_rows !== parseInt(event.target.scrollHeight / 35)
    ) {
      this.setState({
        text_area_rows: parseInt(event.target.scrollHeight / 35),
      })
    }
  }

  render() {
    const { label, note, saving, saved } = this.state
    return (
      <div id="UserNotesWidget">
        <div className="user-notes">
          <MDBInput
            label={label}
            type="textarea"
            name="u_private_notes"
            value={note}
            color="indigo"
            onChange={(event) => {
              this.#textAreaHeight(event)
            }}
            getValue={(n) => this.#onChange(n)}
            rows={this.state.text_area_rows}
            group
            validate
          />
          <div className="btn-wrapper">
            <MDBBtn
              disabled={saving || saved}
              color="primary"
              onClick={this.#save}
            >
              {saving ? 'Saving...' : 'Save'}
            </MDBBtn>
          </div>
          <div
            className={
              'notification-bar ' +
              (this.state.error ? 'error' : this.state.saved ? 'success' : '')
            }
          >
            <div>
              {this.state.saved
                ? 'Success.  The agent notes have been saved.'
                : this.state.error
                ? this.state.error
                : ''}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UserNotesWidget
