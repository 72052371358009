import { observer } from 'mobx-react-lite'
import React from 'react'
import moment from "moment";

import "./ReportOverview.scss";

const toHuman = str => 
	str ? (typeof str === 'string' ? moment(str, 'YYYY-MM-DD').format('MMM DD, YYYY') : str) : null;

const ucwords = str => str && `${str}`.replace(/^([a-z])|[\s_-]+([a-z])/g, $1 => $1.toUpperCase()).replace(/-/g,' ');

const ReportOverview = (props) => 
{
  const renderCurrentDatesHeader = () => 
  {
  	let interval = "";

    switch (props.output_mode)
    {
    	case 'weekly':
    		interval = `: Week ${moment(props.start,'YYYY-MM-DD').isoWeek()}`;
    		break;
    	case 'monthly':
    		interval = `: Month ${parseInt(moment(props.start,'YYYY-MM-DD').month())+1}`;
    		break;
    	case 'quarterly':
    		interval = `: Quarter ${moment(props.start,'YYYY-MM-DD').quarter()}`;
    		break;
    	case 'yearly':
    		interval = `: Year ${moment(props.start,'YYYY-MM-DD').year()}`;
    		break;
    	case 'custom':
    	default:
	    	return 	<>
	    						<h4>
	    							{ucwords(props.report)}
	    						</h4>
	    						<h5>
	    							Custom Date Reporting
	    						</h5>
	    						<h5>
	    							{toHuman(props.start)} thru {toHuman(props.stop)}
	    						</h5>
	    					</>
    }

  	return 	<>
  						<h4>
  							{ucwords(props.report)}
  						</h4>
  						<h5>
  							{props.output_mode.charAt(0).toUpperCase()}{props.output_mode.substr(1)} Reporting{interval}
  						</h5>
  						<h5>
  							{toHuman(props.start)} thru {toHuman(props.stop)}
  						</h5>
  					</>
  }

  return (
    <>
    	{renderCurrentDatesHeader()}
    </>
  )
}

export default observer(ReportOverview)