import React, { Component, Fragment } from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { WebsiteInfoStore } from './stores'
import WebsiteInfoForm from './components/WebsiteInfoForm/WebsiteInfoForm.component'
import BookMeetingForm from './components/BookMeetingForm/BookMeetingForm.component'
import TestimonialForm from './components/TestimonialForm/TestimonialForm.component'
import MarketingForm from './components/MarketingForm/MarketingForm.component'
import AnalyticsForm from './components/AnalyticsForm/AnalyticsForm.component'
import SocialMediaForm from './components/SocialMediaForm/SocialMediaForm.component'
import HowHearOptionsForm from './components/HowHearOptionsForm/HowHearOptionsForm.component'
import { VanityDomainInstructions } from './components/VanityDomainInstructions/VanityDomainInstructions'

class WebsiteInfo extends Component {
  componentDidMount() {
    WebsiteInfoStore.fetchSiteDetails()
  }

  render() {
    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <h2>Website Info</h2>
              <hr />
              <MDBRow>
                <MDBCol size="12" md="7" lg="8">
                  <WebsiteInfoForm />
                  <MarketingForm />
                  <SocialMediaForm />
                  <TestimonialForm />
                </MDBCol>
                <MDBCol size="12" md="5" lg="4">
                  <VanityDomainInstructions />
                  <BookMeetingForm />
                  <HowHearOptionsForm />
                  <AnalyticsForm />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default WebsiteInfo
