import React, { Component } from 'react'
import '../../api/FirebaseAuth'
import env from './../../environments/environment'
import { toast } from 'react-toastify'
import { MDBCol, MDBRow, MDBContainer } from 'mdbreact'
import AdminApi from '../../api/admin-api/admin-api'
import VideoPlayerModal from './../../components/VideoPlayerModal/VideoPlayerModal.component'
import SubsiteBanner from './components/SubsiteBanner/SubsiteBanner.component'
import GetAQuote from './components/GetAQuote/GetAQuote.component'
import ApptBooker from './components/ApptBooker/ApptBooker.component'
import CorporateIdentity from './components/CorporateIdentity/CorporateIdentity.component'
import AtYourService from './components/AtYourService/AtYourService.component'
import SubsiteSidebar from './components/SubsiteSidebar/SubsiteSidebar.component'
import AgentHeader from './components/AgentHeader/AgentHeader.component'
import AgentFooter from './components/AgentFooter/AgentFooter.component'
import ContactForm from './components/ContactForm/ContactForm.component'
import QuoteForm from './components/QuoteForm/QuoteForm.component'
import StateLicenses from './components/StateLicenses/StateLicenses.component'
import ProductListing from './components/ProductListing/ProductListing.component'
import InfoBeforeBuying from './components/InfoBeforeBuying/InfoBeforeBuying.component'
import getQueryParam from './../../shared/utilities/getQueryParam.function'
import SubsiteStore from './stores'
import UserStateLicenseService from '../../shared/services/UserStateLicense.service'
import AgentTestimonials from './components/Testimonials/Testimonials'
import { defaultTestimonials } from '../WebsiteInfo/stores/WebsiteInfo.store'

import './AgentSubsite.page.scss'

const SHOW_OFFERED_PRODUCTS = false

const CUSTOM_POLICY_URL =
  'https://player.vimeo.com/video/607481303?h=3388cce086&badge=0&autopause=0&player_id=0&app_id=58479'

const jsonStrToArray = (str) => {
  if (Array.isArray(str)) return str

  try {
    if (Array.isArray((str = JSON.parse(str)))) return str
  } catch (ex) {}
  return
}

class AgentSubsitePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // prefix: '',
      // suffix: '',
      isLoading: false,
      memberData: '',
      urlParams: '',
      identity: '',
      subsiteData: {},
      showCustomPolicy: false,
      licenses: [],
    }
  }

  getSubsiteIdentity() {
    if (
      this.props.match.params.u_login &&
      `${this.props.match.params.u_login}`.toLowerCase().trim() ===
        'teambanville'
    ) {
      window.location.href = '/jbanville'
      return ''
    }

    return `${this.props.match.params.u_login}`.toLowerCase().trim()
  }

  getPageName() {
    const pageName = this.props.match.params.pageName

    if (
      pageName &&
      [
        'contact',
        'products',
        'quote',
        'info-before-buying',
        'licenses',
      ].includes(`${pageName}`.trim().toLowerCase())
    )
      return `${pageName}`.trim().toLowerCase()

    return 'home'
  }

  getPageBody = () => {
    switch (this.getPageName()) {
      case 'home':
        return (
          <>
            <GetAQuote subsiteData={this.state.subsiteData} />
            <ApptBooker subsiteData={this.state.subsiteData} />
            <CorporateIdentity subsiteData={this.state.subsiteData} />
            <AtYourService />
            {SubsiteStore.isAuth() && SHOW_OFFERED_PRODUCTS ? (
              <div className="offered-products">
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fbg.offered-carriers.jpeg?alt=media`}
                  alt=""
                  className="mx-auto d-block"
                />
              </div>
            ) : (
              <></>
            )}
            {this.state.subsiteData.testimonials &&
              this.state.subsiteData.testimonials.length && (
                <AgentTestimonials data={this.state.subsiteData} />
              )}
          </>
        )
      case 'contact':
        return (
          <>
            <ContactForm subsiteData={this.state.subsiteData} />
          </>
        )
      case 'products':
        return (
          <>
            <ProductListing subsiteData={this.state.subsiteData} />
          </>
        )
      case 'quote':
        return (
          <>
            <QuoteForm
              subsiteData={this.state.subsiteData}
              quoteType={getQueryParam('type', this.props.location.search)}
            />
          </>
        )
      case 'licenses':
        return (
          <>
            <StateLicenses
              licenses={this.state.licenses}
              subsiteData={this.state.subsiteData}
            />
          </>
        )
      case 'info-before-buying':
        return (
          <>
            <InfoBeforeBuying subsiteData={this.state.subsiteData} />
          </>
        )
      default:
        return ''
    }
  }

  getBannerMessage = (asString) => {
    switch (this.getPageName()) {
      case 'home':
        return asString ? 'Welcome' : <>Welcome</>
      case 'contact':
        return asString ? 'Contact Us' : <>Contact Us</>
      case 'products':
        return asString ? 'Our Products' : <>Our Products</>
      case 'quote':
        return asString ? 'Get A Quote' : <>Get A Quote</>
      case 'licenses':
        return asString ? 'Agent Licenses' : <>Agent Licenses</>
      case 'info-before-buying':
        return asString ? (
          'Read Before Purchasing Health Insurance'
        ) : (
          <>Read Before Purchasing Health Insurance</>
        )
      default:
        return ''
    }
  }

  componentDidMount() {
    this.setState({ identity: this.getSubsiteIdentity() }, () => {
      this.fetchAgentData().finally(() => this.setPage())
    })
  }

  componentDidUpdate() {
    SubsiteStore.sendPageView(this.props.location?.pathname)
  }

  setPage = () => {
    const name = [
      this.state.subsiteData?.u_nickname
        ? this.state.subsiteData?.u_nickname
        : this.state.subsiteData?.u_fname,
      this.state.subsiteData?.u_lname,
    ]
      .filter((n) => n)
      .join(' ')
      .trim()
    let title = [`USABG`]

    if (name) title.push(name)

    title.push(`${this.getBannerMessage(true)}`)

    document.title = title.filter((n) => n).join(' - ')

    SubsiteStore.sendPageView(this.props.location?.pathname)
  }

  fetchAgentData = async () => {
    this.setState({ isLoading: true })
    return AdminApi.getUserWebsiteDetailsByUlogin(this.state.identity)
      .then((result) => {
        if (result?.data?.data) {
          const subsiteData = {
            ...result.data.data,
            prefix: result.data.imageUrl.prefix,
            suffix: result.data.imageUrl.suffix,
          }

          subsiteData.testimonials = jsonStrToArray(
            result?.data?.data?.testimonials
          )
          subsiteData.testimonials =
            !subsiteData.testimonials || subsiteData.testimonials.length === 0
              ? defaultTestimonials
              : subsiteData.testimonials
          subsiteData.menu_links =
            jsonStrToArray(result?.data?.data?.menu_links) || []
          subsiteData.resource_links =
            jsonStrToArray(result?.data?.data?.resource_links) || []

          SubsiteStore.set(subsiteData)
          this.setState({ subsiteData })

          UserStateLicenseService.search({
            search: { user_id: subsiteData.user_id },
            pagination: false,
          }).then(
            (r) => {
              this.setState({
                subsiteData,
                isLoading: false,
                licenses: r.models.sort(
                  (a, b) =>
                    a.state_abrev &&
                    `${a.state_abrev}`.localeCompare(b.state_abrev, 'en')
                ),
              })
            },
            () => this.setState({ isLoading: false })
          )
        }
        if (result.data.script) {
          const scriptDom = document.getElementById('site-script')
          if (scriptDom) {
            scriptDom.parentNode.removeChild(scriptDom)
          }
          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.id = 'site-script'
          script.innerHTML = atob(result.data.script)
          document.body.appendChild(script)
        }
        return true
      })
      .catch((error) => {
        toast.error('User not found', { position: toast.POSITION.TOP_RIGHT })
        this.setState({ isLoading: false })
        window.location.href = '/'
        return false
      })
  }

  render() {
    toast.configure()
    const { subsiteData } = this.state,
      pageName = this.getPageName()

    return (
      <div id="AgentSubsitePage">
        <div className="bg-wrapper"></div>
        <AgentHeader subsiteData={subsiteData} />
        <SubsiteBanner
          subsiteData={subsiteData}
          message={this.getBannerMessage()}
        />
        {pageName === 'home' ? (
          <section className="design-custom-policy">
            <MDBContainer>
              <MDBRow
                onClick={() =>
                  this.setState((prevState) => ({
                    ...prevState,
                    showCustomPolicy: !this.state.showCustomPolicy,
                  }))
                }
              >
                <MDBCol size="12" className="font--lato fw--700">
                  How We Custom Design A Policy For You!
                </MDBCol>
                <MDBCol size="12" className="font--lato fw--700">
                  Click here to see!
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
        ) : (
          <></>
        )}
        <section className="subsite-main-body">
          <MDBContainer>
            <MDBRow>
              <MDBCol
                size="12"
                sm="4"
                md="3"
                className="sidebar-content sidebar-content-mobile"
              >
                <SubsiteSidebar
                  subsiteData={subsiteData}
                  licenses={this.state.licenses}
                />
              </MDBCol>
              <MDBCol size="12" sm="8" md="9" className="body-content">
                {this.getPageBody()}
              </MDBCol>
              <MDBCol
                size="12"
                sm="4"
                md="3"
                className="sidebar-content sidebar-content-desktop"
              >
                <SubsiteSidebar
                  subsiteData={subsiteData}
                  licenses={this.state.licenses}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
        <AgentFooter
          subsiteData={subsiteData}
          goTo={(link) => this.props.history.push(link)}
        />
        <VideoPlayerModal
          open={!!this.state.showCustomPolicy}
          onClose={() =>
            this.setState((prevState) => ({
              ...prevState,
              showCustomPolicy: !this.state.showCustomPolicy,
            }))
          }
          videoUrl={CUSTOM_POLICY_URL}
          videoTitle={'How USA Benefits Group Designs Your Custom Policy'}
        />
      </div>
    )
  }
}

export default AgentSubsitePage
