import React from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import PageHeading from './../../components/shared/PageHeading.component'
import CreateBATeamButton from './components/CreateBATeamButton/CreateBATeamButton.component'
import Store from './store'
import BATeamList from './components/BATeamList/BATeamList.component'

import './BATeamsPage.scss'

class ReportsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: false,
    }
  }

  componentDidMount() {
    Store.fetch()
  }

  componentWillUnmount() {
    Store.end()
  }

  render() {
    return (
      <DashboardLayout>
        <main id="BATeamsPage" className="mainSection pb-5">
          <MDBContainer fluid className="mt-5">
            <PageHeading
              label="Benefit Advisor Teams"
              url={this.props?.match?.url}
              from={this.props?.location?.state?.from}
              btnGroup={<CreateBATeamButton />}
            />
          </MDBContainer>
          <BATeamList />
        </main>
      </DashboardLayout>
    )
  }
}

export default ReportsPage
