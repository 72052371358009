import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { MDBBtn } from 'mdbreact'
import MediaUploaderModal from './'

export default observer((props) => {
  const btnProps = { ...props },
    modalConfig = props?.modalConfig || {}

  if (btnProps?.modalConfig) delete btnProps.modalConfig

  const onSuccess =
    typeof props?.onSuccess === 'function' ? props?.onSuccess : () => {}
  if (btnProps?.onSuccess) delete btnProps.onSuccess

  const openModal = (config) =>
    MediaUploaderModal.open(config).catch((err) =>
      err
        ? console.log(
            'Failed to complete uploading agent nipr pdb report.',
            err
          )
        : null
    )

  return (
    <MDBBtn
      {...btnProps}
      onClick={() => {
        if (typeof props?.onClick !== 'function')
          openModal(modalConfig).then((p) => onSuccess(p))
        else if (props.onClick())
          openModal(modalConfig).then((p) => onSuccess(p))
      }}
    >
      {props?.label}
    </MDBBtn>
  )
})
