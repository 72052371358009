import BaseModelFactory from './base.factory'
import ReportService from './../services/Report.service'
import Report from './../models/report.model'

class ReportFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: ReportService, Model: Report }
    )
  static findById = async (id) =>
    await this._findBy({ id }, { Service: ReportService, Model: Report })
  static create = (payload) =>
    this._create(payload, { Service: ReportService, Model: Report })
}

export default ReportFactory
