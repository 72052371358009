import React, { useState } from 'react'
import { LedgerTitle } from './../shared'
import { observer } from 'mobx-react-lite'
import PaginationControls from './../../content/PaginationControls/PaginationControls.component'
import ContentHtml from './../../content/ContentHtml/ContentHtml.component'
import Store from './../store'
import { MDBRow, MDBCol, MDBBtn, MDBIcon, MDBContainer } from 'mdbreact'
import moment from 'moment'
import Transaction from './../Transaction/Transaction.component'

import './TransactionHistory.component.scss'

const TransactionHistory = ({ layout, onSelect, toast }) => {
  const [showValidity, setShowValidity] = useState(false),
    [transShadow, setTransShadow] = useState(null)

  const renderButtons = () => {
    return layout === 'admin' ? (
      <div>
        {Store.Transaction ? (
          <>
            <MDBBtn
              disabled={
                (Store.isLoading || Store.isSearching || Store.isSaving) &&
                Store.Transaction
              }
              className="cancel-btn"
              onClick={() => {
                setShowValidity(false)
                // if (transShadow)
                //   Object.keys(transShadow)
                //     .filter((k) =>
                //       [
                //         'category'
                //         'debit',
                //         'credit',
                //         'purpose',
                //         'files',
                //         'is_expire',
                //       ].includes(k)
                //     )
                //     .forEach((k) =>
                //       Store.Transaction.set(k, transShadow[k])
                //     )
                Store.Transaction = null
              }}
            >
              <MDBIcon icon="times" />
              &nbsp;Cancel
            </MDBBtn>
            <MDBBtn
              disabled={
                (Store.isLoading || Store.isSearching || Store.isSaving) &&
                Store.Transaction
              }
              className="save-btn"
              onClick={async () => {
                if (Store.Transaction) {
                  const isValid = Store.Transaction.isValid()
                  setShowValidity(isValid === true ? false : isValid)
                  if (isValid === true) {
                    try {
                      const isNew = Store.Transaction.isNew()
                      await Store.Transaction.save()
                      if (isNew)
                        Store.LedgerTransactions = [Store.Transaction].concat(
                          Store.LedgerTransactions
                        )
                      setShowValidity(false)
                      Store.Transaction = null
                      Store.Ledger.reload()
                    } catch (ex) {
                      setShowValidity(`${ex}`.replace(/^(error:( )?)+/i, ''))
                      return
                    }
                  }
                }
              }}
            >
              <MDBIcon icon="save" />
              &nbsp;Save
            </MDBBtn>
          </>
        ) : (
          <MDBBtn
            disabled={
              Store.isLoading ||
              Store.isSearching ||
              Store.isSaving ||
              Store.Transaction?.isNew()
            }
            className="new-btn"
            onClick={async () => {
              Store.Transaction = await Store.Ledger.transaction(true).create({
                transact_at: moment().format('YYYY-MM-DD'),
              })
            }}
          >
            <MDBIcon icon="plus" />
            &nbsp;Add New
          </MDBBtn>
        )}
      </div>
    ) : (
      <></>
    )
  }

  return (
    <MDBContainer
      id="TransactionHistoryComponent"
      fluid
      className="ledger-component"
    >
      <MDBRow>
        <MDBCol size="12">
          <LedgerTitle btnGroup={renderButtons()}>
            Transaction History
          </LedgerTitle>
        </MDBCol>
      </MDBRow>
      <MDBContainer fluid>
        <div className="row mb-2">
          <MDBCol className="agent-ledger-how-to">
            <ContentHtml contentSlug="agent-ledger-how-to" />
          </MDBCol>
        </div>
        <MDBRow>
          <MDBCol size="12">
            <ul className={`transactions-list ${layout || 'agent'}-list`}>
              <li>
                <div className="al-id">ID</div>
                <div className="al-category">Category</div>
                <div className="al-type">Type</div>
                <div className="al-source">Source</div>
                <div className="al-purpose">Purpose</div>
                <div className="al-amount">Debit/Credit</div>
                <div className="al-balance">Balance</div>
                <div className="al-date">Date</div>
                {layout === 'admin' ? <div className="al-btns"></div> : <></>}
              </li>
              {Store.LedgerTransactions.filter(
                (Trans) =>
                 Trans 
                 && Trans.get('trans_state') !== 'CANCELED' 
                 && (parseInt(!isNaN(Trans?.get('credit')) ? Trans.get('credit') : 0) + parseInt(!isNaN(Trans?.get('debit')) ? Trans.get('debit') : 0) !== 0)
              ).map((Trans, idx) => (
                <Transaction
                  toast={toast}
                  onSelect={onSelect}
                  layout={layout}
                  Transaction={Trans}
                  key={`lc-trans-list-${Trans.id()}-${idx}`}
                />
              ))}
            </ul>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12">
            <div className="pagination-wrapper">
              <PaginationControls
                pagination={Store.ledgerTransactionPagination}
                onPageSelect={(pagination) =>
                  Store.searchAgentLedgerTransactions({ pagination })
                }
              />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBContainer>
  )
}

export default observer(TransactionHistory)
