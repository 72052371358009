import RestApi from "./../../api/rest-api-client";

const srvcEndpoint = "/integrations/adobe_sign";

const authorize = async (authCode, redirectUri) => 
	await RestApi.request("POST","/integrations/adobe_sign/oauth", {auth_code: authCode, redirect_uri: redirectUri});

export default {
		authorize,
		getDocument: async (id, req, opts) 	=> 		RestApi.request("GET", 		srvcEndpoint, id, req, {endpoint: `${srvcEndpoint}/documents/${id}`}),
		getDocuments: async (req, opts) 	=> 		RestApi.request("GET", 		srvcEndpoint, req, {endpoint: `${srvcEndpoint}/documents`}),

	// search: 		async (req, opts) 			=> 		RestApi.request("GET", 		srvcEndpoint, req, opts),
	// get: 			async (id, req, opts) 	=> 		RestApi.request("GET", 		srvcEndpoint, id, req, opts),
	// store:  		async (data, opts) 			=> 		RestApi.request("POST", 	srvcEndpoint, data, opts),
	// update:  	async (id, data, opts) 	=> 		RestApi.request("PUT", 		srvcEndpoint, id, data, opts),
	// delete: 		async (id, opts)  			=> 		RestApi.request("DELETE", srvcEndpoint, id, opts),
};
