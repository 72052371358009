import React from 'react'
import { observer } from 'mobx-react-lite'
import LoadingSpinner from './../../../../components/shared/LoadingSpinner.component'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import StorageService from './../../../../shared/services/Storage.service'
import moment from 'moment'
import { TermFactory, UserTermFactory } from './../../../../shared/factories'
import { makeAutoObservable } from 'mobx'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCollapse,
  MDBIcon,
  MDBBtn,
} from 'mdbreact'
import { getIp } from './../../../../shared/utilities/getIp.function'
import { isMobile } from 'react-device-detect'

import './LeaderTermsReviewer.component.scss'

const fetchTerms = async (termId) => {
  try {
    return (
      await TermFactory.search({
        search: {
          active: 1,
          is_leader: 1,
          is_hidden: 0,
          ...(termId && !isNaN(termId) ? { id: termId } : {}),
        },
        order_by: { id: 'DESC' },
        pagination: { per_page: 1, page: 0 },
      })
    )?.models.shift()
  } catch (ex) {
    return false
  }
}

const fetchUserTerms = async (userId, termId) => {
  try {
    return (
      await UserTermFactory.search({
        search: { user_id: userId, term_id: termId },
      })
    )?.models.shift()
  } catch (ex) {
    return false
  }
}

const hasSigned = (UserTerm) => !!(UserTerm && UserTerm?.get('userterm_file'))

class TermsStore {
  constructor() {
    makeAutoObservable(this)
  }

  isFetching = false
  isSigning = false
  Term = null
  UserTerm = null
  hasSigned = false

  hasAccepted = () => !!this.UserTerm

  hasAcceptedAndSigned = () =>
    this.hasAccepted() && !!this.UserTerm?.get('userterm_file')

  setTerm = async (termId) => {
    try {
      this.Term = await fetchTerms(termId)
    } catch (ex) {
      console.error(`Failed to load leader guideline terms ${ex}`)
      this.Term = null
    }

    if (!this.Term || !this.Term?.id()) this.Term = null

    if (!!this.Term) {
    }

    return this.Term
  }

  fetch = async (userId) => {
    this.isFetching = true

    if (await this.setTerm()) {
      this.UserTerm = await fetchUserTerms(userId, this.Term.id())
    } else {
      this.UserTerm = false
    }

    this.hasSigned = hasSigned(this.UserTerm)
    this.isFetching = false
  }

  acceptAndSign = async () => {
    this.isSigning = true

    if (!this.UserTerm) {
      this.UserTerm = UserTermFactory.create({
        user_id: UserProfileService.getUserId(),
        term_id: this.Term.id(),
        userterm_term: this.Term.get('term_name'),
        userterm_user: [
          UserProfileService.get('u_fname'),
          UserProfileService.get('u_lname'),
        ]
          .map((n) => n && `${n}`.trim())
          .filter((n) => !!n)
          .join(' ')
          .trim(),
        userterm_signature: [
          UserProfileService.get('u_fname'),
          UserProfileService.get('u_lname'),
        ]
          .map((n) => n && `${n}`.trim())
          .filter((n) => !!n)
          .join(' ')
          .trim(),
      })
      await this.UserTerm.save()

      if (await this.UserTerm.sign(await getIp()))
        this.hasSigned = hasSigned(this.UserTerm)
    }

    // if the leader term has been accepted, clear
    // `user---required-alerts` local storage setting
    // to have the user outstanding requirements
    // refreshed.
    if (!!this.hasSigned) StorageService.clear('user---required-alerts')

    this.isSigning = false
  }

  sign = async () => {
    this.isSigning = true
    if (this.UserTerm) {
      if (await this.UserTerm.sign(await getIp()))
        this.hasSigned = hasSigned(this.UserTerm)
    }
    StorageService.clear('user---required-alerts')
    this.isSigning = false
  }
}

const LeaderTermsStore = new TermsStore()

const renderAcceptance = () => {
  return (
    <>
      {isMobile ? (
        <>
          <MDBBtn
            block
            className="view-term-btn"
            color="secondary"
            tag="a"
            role="button"
            href={LeaderTermsStore.Term?.get('term_link')}
            target="_BLANK"
          >
            <MDBIcon icon="eye" />
            <br />
            View Leader Guidelines
          </MDBBtn>
          <br />
        </>
      ) : (
        <></>
      )}
      <MDBBtn
        block
        disabled={LeaderTermsStore.isFetching || LeaderTermsStore.isSigning}
        onClick={() => LeaderTermsStore.acceptAndSign()}
      >
        {LeaderTermsStore.isSigning ? (
          <i className="fa fa-spin fa-spinner"></i>
        ) : (
          <MDBIcon icon="file-signature" />
        )}
        <br />
        Accept & Sign Leader Guidelines
      </MDBBtn>
    </>
  )
}

const renderSignButton = () => {
  return (
    <>
      {isMobile ? (
        <>
          <MDBBtn
            block
            className="view-term-btn"
            color="secondary"
            tag="a"
            role="button"
            href={LeaderTermsStore.Term?.get('term_link')}
            target="_BLANK"
          >
            <MDBIcon icon="eye" />
            <br />
            View Leader Guidelines
          </MDBBtn>
          <br />
        </>
      ) : (
        <></>
      )}
      <MDBBtn
        block
        disabled={LeaderTermsStore.isFetching || LeaderTermsStore.isSigning}
        onClick={() => LeaderTermsStore.sign()}
      >
        {LeaderTermsStore.isSigning ? (
          <i className="fa fa-spin fa-spinner"></i>
        ) : (
          <MDBIcon icon="file-signature" />
        )}
        <br />
        Sign Leader Guidelines
      </MDBBtn>
    </>
  )
}

const TermsReviewer = () => {
  const showSpinner = !!(
    LeaderTermsStore.isFetching ||
    !LeaderTermsStore.Term ||
    !LeaderTermsStore.Term.get('term_link')
  )

  return (
    <MDBContainer id="TermsReviewerComponent">
      <MDBRow>
        <MDBCol>
          <MDBCollapse isOpen={showSpinner}>
            <div
              className={`terms-loading-wrapper ${
                showSpinner ? 'is-loading' : ''
              }`}
            >
              <LoadingSpinner size="md" isActive={true} />
            </div>
          </MDBCollapse>
          <MDBCollapse isOpen={!showSpinner}>
            {LeaderTermsStore.Term &&
            LeaderTermsStore.Term?.get('term_link') ? (
              <>
                <MDBRow className="mb-4 accept-and-sign">
                  <MDBCol size="12" sm="6" className="text--left">
                    Have you accepted the Leader Guidelines yet?
                  </MDBCol>
                  <MDBCol size="12" sm="6" className="text--right">
                    {LeaderTermsStore.hasAcceptedAndSigned() ? (
                      <ul>
                        <li>
                          Accepted By:{' '}
                          {LeaderTermsStore.UserTerm.get('userterm_signature')}
                        </li>
                        <li>
                          Accepted At:{' '}
                          {moment(
                            LeaderTermsStore.UserTerm.get('userterm_updated')
                          ).format('MM/DD/YYYY, h:mm a')}
                        </li>
                        <li>
                          Accepted IP:{' '}
                          {LeaderTermsStore.UserTerm.get('userterm_ip')}
                        </li>
                      </ul>
                    ) : !LeaderTermsStore.hasAccepted() ? (
                      renderAcceptance()
                    ) : (
                      renderSignButton()
                    )}
                  </MDBCol>
                  <MDBCol size="12">
                    {isMobile ? (
                      LeaderTermsStore.hasAcceptedAndSigned() ? (
                        <div className="text-center mt-5">
                          <MDBBtn
                            block
                            className="view-term-btn"
                            color="secondary"
                            tag="a"
                            role="button"
                            href={LeaderTermsStore.Term?.get('term_link')}
                          >
                            <MDBIcon icon="eye" />
                            <br />
                            View Leader Guidelines
                          </MDBBtn>
                        </div>
                      ) : (
                        <></>
                      )
                    ) : (
                      <iframe
                        title="USA Benefits Group Leader Guidelines"
                        src={LeaderTermsStore.Term?.get('term_link')}
                        width="100%"
                        height="1200px"
                        frameBorder="0"
                      ></iframe>
                    )}
                  </MDBCol>
                </MDBRow>
              </>
            ) : (
              <></>
            )}
          </MDBCollapse>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

const LeaderTermsReviewer = observer(TermsReviewer)

export { LeaderTermsStore, LeaderTermsReviewer }
