import BaseModelFactory from './base.factory'
import ReferralPartnersW9Service from './../services/ReferralPartnersW9.service'
import ReferralPartnerW9 from './../models/referral-partner-w9.model'

class ReferralPartnerW9Factory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: ReferralPartnersW9Service, Model: ReferralPartnerW9 }
    )
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: ReferralPartnersW9Service, Model: ReferralPartnerW9 }
    )
  static findAllByReferralPartnerId = async (id) =>
    await this._findAllBy(
      { referral_partner_id: id },
      { Service: ReferralPartnersW9Service, Model: ReferralPartnerW9 }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: ReferralPartnersW9Service,
      Model: ReferralPartnerW9,
    })
}

export default ReferralPartnerW9Factory
