import React, { useEffect, useState, useCallback } from 'react'
import CONTRACTING from './../../../constants/contracting.constants'
import { observer } from 'mobx-react-lite'
import { MDBCollapse, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import UserProfileService from './../../../shared/services/UserProfile.service'
import UserService from '../../../shared/services/User.service'
import OnboardingCartList from './../OnboardingCartList/OnboardingCartList.component'
import ElectiveCartList from './../ElectiveCartList/ElectiveCartList.component'
import ReplacementCartList from './../ReplacementCartList/ReplacementCartList.component'
import SaveOnboardingCartButton from './../SaveOnboardingCartButton/SaveOnboardingCartButton.component'
import EnableExternalCarrierToggleCheckbox from './../EnableExternalCarrierToggleCheckbox/EnableExternalCarrierToggleCheckbox.component'
import ACMStore from './../AgentContractManager.store'
import UserContractCartItemFactory from './../../../shared/factories/user-contract-cart-item.factory'

import './AgentOnboardingCart.scss'

const CORE_LIMIT = CONTRACTING.onboarding.CORE_LIMIT,
  ELECTIVE_MIN_LIMIT = CONTRACTING.onboarding.ELECTIVE_MIN_LIMIT,
  ELECTIVE_MAX_LIMIT = CONTRACTING.onboarding.ELECTIVE_MAX_LIMIT,
  SIG_AGENT_REQUIRED = CONTRACTING.onboarding.SIG_AGENT_REQUIRED,
  CSR_REQUIRED = CONTRACTING.onboarding.CSR_REQUIRED,
  ASSOC_REQUIRED = CONTRACTING.onboarding.ASSOC_REQUIRED,
  AFFILIATE_REQUIRED = CONTRACTING.onboarding.AFFILIATE_REQUIRED,
  ENABLE_CORE_AUTO_SELECT = CONTRACTING.onboarding.ENABLE_CORE_AUTO_SELECT

const isCartClosed = () =>
  ['CLOSED'].includes(ACMStore?.Cart?.get('cart_status'))

const isCartActive = () =>
  ['ACTIVE'].includes(ACMStore?.Cart?.get('cart_status'))

const getRequiredQuantity = () =>
  UserProfileService.isA(['affiliate-group'])
    ? AFFILIATE_REQUIRED
    : UserProfileService.isA(['associate-group'])
    ? ASSOC_REQUIRED
    : UserProfileService.isA(['csr-agent'])
    ? CSR_REQUIRED
    : SIG_AGENT_REQUIRED

const setCarriers = (spec) => {
  // If the cart is active or closed, do not change carriers.
  if (isCartActive() || isCartClosed()) return

  let coreCarriers = [],
    preferredCarriers = [],
    coreLimit = CORE_LIMIT

  try {
    coreCarriers = (ACMStore.Carriers || []).filter((Carrier) =>
      Carrier.isPriority(spec, UserProfileService.get('usertype_id'), 'core')
    )
    preferredCarriers = (ACMStore.Carriers || []).filter((Carrier) =>
      Carrier.isPriority(
        spec,
        UserProfileService.get('usertype_id'),
        'preferred'
      )
    )
  } catch (ex) {}

  ACMStore.CartItems = []
  while (coreLimit--) {
    if (Array.isArray(coreCarriers) && coreCarriers.length > 0)
      ACMStore.CartItems.push(
        UserContractCartItemFactory.create({
          cart_id: ACMStore.Cart?.id(),
          item_status: 'DRAFT',
          carrier_id: coreCarriers.shift().id(),
        })
      )
    // CarrierStore.onCarrierToggle(coreCarriers.shift(), true)
    else if (Array.isArray(preferredCarriers) && preferredCarriers.length > 0)
      ACMStore.CartItems.push(
        UserContractCartItemFactory.create({
          cart_id: ACMStore.Cart?.id(),
          item_status: 'DRAFT',
          carrier_id: preferredCarriers.shift().id(),
        })
      )
    // CarrierStore.onCarrierToggle(preferredCarriers.shift(), true)
  }

  return (
    coreCarriers.concat(preferredCarriers).map((Carrier) =>
      UserContractCartItemFactory.create({
        cart_id: ACMStore.Cart?.id(),
        item_status: 'DRAFT',
        carrier_id: Carrier.id(),
      })
    ) || []
  )
}

const getIsSelectable = (CartItems) =>
  !!ACMStore?.Cart &&
  Array.isArray(CartItems) &&
  CartItems.length >= getRequiredQuantity()

const AgentOnboardingCart = ({ activeSpecs, onCheckout }) => {
  const requiredItems = getRequiredQuantity(),
    [CartItems, setCartItems] = useState([]),
    [ElectiveCartItems, setElectiveCartItems] = useState([]),
    [SelectedCartItems, setSelectedCartItems] = useState([]),
    [uplines, setUplines] = useState([]),
    setCarrierListBySpec = useCallback((spec) => {
      const ECartItems = setCarriers(spec)
      setCartItems(ACMStore.CartItems)
      setElectiveCartItems(ECartItems)
    }, []),
    isSelectable = getIsSelectable(CartItems)

  const canStartContracting = () =>
    isSelectable && SelectedCartItems.length < getRequiredQuantity()
  
  useEffect(() => {
    getUplines()
  }, [])

  const getUplines = async () => {
    const upline_agents = await UserService.getUpline(
      UserProfileService.getUserId()
    )
    setUplines(upline_agents.map((upline) => `${upline.id}`))
  }

  useEffect(() => {
    setCarrierListBySpec(activeSpecs)

    ACMStore.CartItems.forEach((CartItem) => {
      const matchingCarrier = (ACMStore?.Carriers || [])
          .filter(
            (Carrier) =>
              parseInt(Carrier.id()) === parseInt(CartItem.get('carrier_id'))
          )
          .shift();
  
        if (!matchingCarrier) ACMStore.remove('cartitem', CartItem);
  
        if (parseInt(matchingCarrier.get('c_active')) !== 1) ACMStore.remove('cartitem', CartItem);
  
        if (parseInt(matchingCarrier.get('c_active')) === 1) {
          if (matchingCarrier.get('visibility')?.onboarding?.enable) {
            const usertype_id = parseInt(UserProfileService.getCurrentUserTypeId());
            if ([225].includes(usertype_id) && !matchingCarrier.get('visibility')?.onboarding?.csr_agent) ACMStore.remove('cartitem', CartItem);
            if ([111, 133].includes(usertype_id) && !matchingCarrier.get('visibility')?.onboarding?.affiliate_agent) ACMStore.remove('cartitem', CartItem);
            if ([223, 224].includes(usertype_id) && !matchingCarrier.get('visibility')?.onboarding?.associate_agent) ACMStore.remove('cartitem', CartItem);
            if ([91, 92, 149, 37, 35, 38, 90, 93, 130, 129, 226, 227, 228, 219, 36, 222].includes(usertype_id) && !matchingCarrier.get('visibility')?.onboarding?.sig_agent) ACMStore.remove('cartitem', CartItem);
          } else ACMStore.remove('cartitem', CartItem);
        }

        if (
          matchingCarrier.get('visibility')?.onboarding?.upline_ids?.length > 0
        ) {
          const disabled_carrier = matchingCarrier
            .get('visibility')
            .onboarding.upline_ids.some((upline_id) =>
              uplines.includes(upline_id)
            )
          if (disabled_carrier) ACMStore.remove('cartitem', CartItem);
        }
    })
  }, [setCarrierListBySpec, activeSpecs, uplines])

  const renderWidget = () => {
    if (!ACMStore?.Cart)
      return (
        <MDBContainer fluid id="AgentOnboardingCartComponent">
          <MDBRow>
            <MDBCol></MDBCol>
          </MDBRow>
        </MDBContainer>
      )

    return (
      <div id="AgentOnboardingCartComponent">
        <label className="section-label">
          <MDBIcon icon="shopping-cart" />
          &nbsp;Agent Onboarding - Carrier Contract Shopping Cart
        </label>

        <MDBContainer fluid>
          <MDBRow>
            <MDBCol>
              <OnboardingCartList
                CartItems={CartItems}
                activeSpecs={activeSpecs}
                isSelectable={!ENABLE_CORE_AUTO_SELECT && isSelectable}
                requiredItems={requiredItems}
                SelectedCartItems={SelectedCartItems}
                onSelectCartItem={(CartItem) => {
                  if (
                    SelectedCartItems.map((CI) =>
                      parseInt(CI.get('carrier_id'))
                    ).indexOf(parseInt(CartItem.get('carrier_id'))) < 0
                  )
                    setSelectedCartItems((cItems) =>
                      [...cItems].concat([CartItem])
                    )
                  else {
                    let CartItems = [...SelectedCartItems]
                    setSelectedCartItems((cItems) => {
                      let carrierIds = CartItems.map((CI) =>
                        parseInt(CI.get('carrier_id'))
                      )
                      CartItems.splice(
                        carrierIds.indexOf(
                          parseInt(CartItem.get('carrier_id'))
                        ),
                        1
                      )
                      return CartItems
                    })
                  }
                }}
                maxLimit={CORE_LIMIT}
              />
              <MDBCollapse isOpen={ACMStore?.Cart?.id() > 0}>
                <EnableExternalCarrierToggleCheckbox disabled={!isSelectable} />
              </MDBCollapse>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <MDBCollapse isOpen={ACMStore?.ExternalCarriers?.length > 0}>
                <ReplacementCartList
                  CartItems={CartItems}
                  activeSpecs={activeSpecs}
                  ExternalCarriers={ACMStore?.ExternalCarriers}
                />
              </MDBCollapse>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <ElectiveCartList
                ElectiveCartItems={ElectiveCartItems}
                activeSpecs={activeSpecs}
                isSelectable={isSelectable}
                onSelectCartItem={(carrier) => {
                  const addToCart = (carrierId) => {
                      if (
                        ACMStore.CartItems.map((CartItem) =>
                          parseInt(CartItem.get('carrier_id'))
                        ).indexOf(parseInt(carrierId)) < 0
                      ) {
                        ACMStore.CartItems.push(
                          UserContractCartItemFactory.create({
                            cart_id: ACMStore.Cart?.id(),
                            item_status: 'DRAFT',
                            carrier_id: carrierId,
                          })
                        )
                      }
                    },
                    removeFromCart = (carrierId) => {
                      const CartItem = ACMStore.CartItems.filter(
                        (CartItem) =>
                          parseInt(CartItem.get('carrier_id')) ===
                          parseInt(carrierId)
                      ).shift()
                      if (CartItem) ACMStore.remove('cartitem', CartItem)
                    },
                    carrierId = Object.keys(carrier || {}).shift()

                  if (carrierId && !isNaN(carrierId)) {
                    if (carrier[carrierId]) addToCart(carrierId)
                    else removeFromCart(carrierId)
                  }
                }}
                minLimit={ELECTIVE_MIN_LIMIT}
                maxLimit={ELECTIVE_MAX_LIMIT}
                requiredItems={requiredItems - ACMStore.CartItems.length}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <div className="cart-checkout-contents">
          <MDBCollapse isOpen={ACMStore?.Cart?.id() > 0}>
            <SaveOnboardingCartButton
              disabled={!canStartContracting(SelectedCartItems)}
              SelectedCartItems={SelectedCartItems}
              requiredItems={requiredItems - ACMStore.CartItems.length}
              onCheckout={onCheckout}
            />
          </MDBCollapse>
        </div>
      </div>
    )
  }

  return renderWidget()
}

export default observer(AgentOnboardingCart)
