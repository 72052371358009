import React, { Component, Fragment } from 'react'
import env from './../../environments/environment'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBDataTable,
  MDBRow,
  MDBIcon,
  MDBSwitch,
} from 'mdbreact'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'
import {
  getHeadshot,
  getBATeamLogo,
} from './../../shared/utilities/getHeadshot.function'
import AWARD_LEVELS from './../../constants/award-levels.constants'

import {
  addWeeks,
  endOfWeek,
  format,
  getWeek,
  startOfWeek,
  subWeeks,
} from 'date-fns'

import './FactorsMilestones.scss'
import { AwardingTodayBtn } from './components/AwardingTodayBtn'
import { BATeamFactory, UserMetaFactory } from '../../shared/factories'

const EMPTY_DATATABLE = { columns: [], rows: [] }

class FactorMilestones extends Component {
  _buildColumns = () =>
    AWARD_LEVELS.map((AwardLevel) => {
      let min = `${AwardLevel.min}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        max = `${AwardLevel.max}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') //(AwardLevel.max?AwardLevel.max===999999?<MDBIcon icon='arrow-up' />:`${AwardLevel.max}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','));
      max = max === '999,999' ? <MDBIcon icon="arrow-up" /> : max

      return {
        label: (
          <>
            <div className="text--center">
              <div>{AwardLevel.name}</div>
              <img
                src={`https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Faward-levels%2Flevel.${AwardLevel.level}.png?alt=media`}
                alt={AwardLevel.name}
              />
              <div className="small">
                {min} - {max}
              </div>
            </div>
          </>
        ),
        field: `level${AwardLevel.level}`,
        sort: 'asc',
      }
    })

  _buildRows = ({ payload, Models }) => {
    const renderAwarded = (awarded, team, idx) => {
      return (
        <div
          key={`awarded-level-${idx}-${awarded.u_name.replace(/\s+/g, '-')}`}
          className={`content-wrapper text--center fw--500 mb-3 ${
            awarded.monthly_av === 'jumped' ? 'jumped-level' : ''
          } ${
            !this.state.showCSR &&
            awarded.usertype_id === 225 &&
            'csr-agent-admin-report-element d-none'
          } ${
            this.state.showCSR &&
            awarded.usertype_id === 225 &&
            'csr-agent-admin-report-element'
          }`}
          style={{ padding: '2px' }}
        >
          <p>{team ? team.get('team_name') : awarded.u_name}</p>
          <img
            src={
              team ? getBATeamLogo(team.get('team_logo')) : getHeadshot(awarded)
            }
            alt={team ? team.get('team_name') : awarded.u_name}
            className="img-fluid mx-auto d-block headshot-ratio"
            style={{ height: '80px', marginBottom: '10px' }}
          />
          {awarded.monthly_av === 'jumped' ? (
            <div></div>
          ) : (
            <div>
              {`${parseInt(awarded.monthly_av)}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ','
              )}{' '}
              Points
            </div>
          )}
          <AwardingTodayBtn awarded={awarded} />
        </div>
      )
    }

    let level = 1,
      rows = {},
      max = Math.max(...payload.map((AwardLevel) => AwardLevel.results.length))
    payload.forEach((AwardLevel) => (rows[`level${AwardLevel.level}`] = []))

    do {
      payload.forEach((AwardLevel) => {
        if (AwardLevel.results.length > 0)
          rows[`level${AwardLevel.level}`].push(AwardLevel.results.shift())
      })
      level++
    } while (level <= max)

    for (level in rows)
      rows[level] = (
        <div className="text--center">
          {rows[level].length > 0
            ? rows[level].map((row, idx) => {
                let Model
                if (Models && Array.isArray(Models)) {
                  Model = Models.filter(
                    (m) => m.get('user_id') === row.id
                  ).shift()
                }
                let Team = this.state.baTeams.find(
                  (team) => team.get('owner_id') === row.id
                )
                return renderAwarded(
                  { ...row, level, MetaModel: Model },
                  Team,
                  idx
                )
              })
            : '[No Agents Awarded]'}
        </div>
      )
    return [rows]
  }

  _setStartDates = async () => {
    this.setState(
      {
        dateFrom: startOfWeek(this.state.currentDate, { weekStartsOn: 1 }),
        dateTo: endOfWeek(this.state.currentDate, { weekStartsOn: 1 }),
      },
      () => this.getCurrentData('week')
    )
  }

  _fetchFactorMilestonesAdminReportData = async () => {
    if (this.state.loading) return
    this.setState({ loading: true })

    const results = await AdminApi.fetchFactorMilestonesAdminReport({
      year_start: format(
        startOfWeek(
          subWeeks(this.state.dateFrom, getWeek(this.state.dateFrom) - 1),
          {
            weekStartsOn: 1,
          }
        ),
        'yyyy-MM-dd'
      ),
      last_week_end: format(
        endOfWeek(subWeeks(this.state.dateFrom, 1), { weekStartsOn: 1 }),
        'yyyy-MM-dd'
      ),
      last_of_last_week_end: format(
        endOfWeek(subWeeks(this.state.dateFrom, 2), { weekStartsOn: 1 }),
        'yyyy-MM-dd'
      ),
    })

    let stateUpd = { loading: false }
    if (results && Array.isArray(results?.data?.payload)) {
      const userIds = results.data.payload
        .map((d) => d.results)
        .flat()
        .map((d) => d.id)
      const Models = await UserMetaFactory.search({
        search: {
          user_id: userIds,
          meta_key: 'profile---pip-ack',
        },
        pagination: false,
      })
      stateUpd = {
        ...stateUpd,
        tableData: {
          columns: this._buildColumns(),
          rows: this._buildRows({ payload: results.data.payload, Models }),
        },
      }
    }

    this.setState(stateUpd)
  }

  _fetchAllBATeams = async () => {
    const BATeams = await BATeamFactory.search({ pagination: false })
    this.setState({ baTeams: BATeams })
  }

  componentDidMount() {
    this._setStartDates()
    this._fetchAllBATeams()
  }

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      currentDate: new Date(),
      dateFrom: new Date(),
      dateTo: new Date(),
      timeframe: 'week',
      tableData: null,
      baTeams: [],
      showCSR: false,
    }
  }

  getPreviousData = async (timeframe) => {
    const { dateFrom } = this.state
    this.setState({ customDateFrom: null, customDateTo: null })
    if (timeframe === 'week') {
      const date = subWeeks(dateFrom, 1)
      this.setState(
        {
          timeframe: timeframe,
          dateFrom: startOfWeek(date, { weekStartsOn: 1 }),
          dateTo: endOfWeek(date, { weekStartsOn: 1 }),
        },
        () => {
          this._fetchFactorMilestonesAdminReportData(false)
        }
      )
    }
  }

  getCurrentData = async (timeframe) => {
    if (timeframe === 'week') {
      this.setState(
        {
          timeframe: timeframe,
          dateFrom: startOfWeek(this.state.currentDate, { weekStartsOn: 1 }),
          dateTo: endOfWeek(this.state.currentDate, { weekStartsOn: 1 }),
        },
        () => {
          this._fetchFactorMilestonesAdminReportData(false)
        }
      )
    }
  }

  getNextData = async (timeframe) => {
    const { dateTo } = this.state
    this.setState({ customDateFrom: null, customDateTo: null })
    if (timeframe === 'week') {
      const date = addWeeks(dateTo, 1)
      this.setState(
        {
          timeframe: timeframe,
          dateFrom: startOfWeek(date, { weekStartsOn: 1 }),
          dateTo: endOfWeek(date, { weekStartsOn: 1 }),
        },
        () => {
          this._fetchFactorMilestonesAdminReportData(false)
        }
      )
    }
  }

  convertToCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(Math.round((value + Number.EPSILON) * 100) / 100)
  }

  handleShowCSRtoggle = (value) => {
    this.setState({ showCSR: value })
    // Select all elements with the classname "csr-agent"
    let elements = document.querySelectorAll('.csr-agent-admin-report-element')

    if (!value) {
      // Loop through each element and add the "d-none" classname
      elements.forEach(function (element) {
        element.classList.add('d-none')
      })
    } else {
      elements.forEach(function (element) {
        element.classList.remove('d-none')
      })
    }
  }

  renderCurrentDatesHeader = () => {
    const { dateFrom, dateTo, timeframe } = this.state

    if (timeframe === 'week') {
      const prevFrom = subWeeks(dateFrom, 1),
        prevTo = subWeeks(dateTo, 1)

      return (
        <MDBRow>
          <MDBCol size="12" md="6">
            <div className="factor-explain">
              <h3 className="text-center">
                Points Accomplish Week #{getWeek(prevFrom)}
              </h3>
              <p className="text-center">
                The agents below completed their milestone achievements during
                the previous business week.
              </p>
              <h4 className="text-center">
                {format(prevFrom, 'MMM dd, yyyy')} -{' '}
                {format(prevTo, 'MMM dd, yyyy')}
              </h4>
            </div>
          </MDBCol>
          <MDBCol size="12" md="6">
            <div className="factor-explain">
              <h3 className="text-center">
                Points Awarding Week #{getWeek(dateFrom)}
              </h3>
              <p className="text-center">
                The agents below will be recognized on this week's National
                Sales Meeting (NSM) for {format(dateFrom, 'EEEE, MMMM do')}.
              </p>
              <h4 className="text-center">
                {format(dateFrom, 'MMM dd, yyyy')} -{' '}
                {format(dateTo, 'MMM dd, yyyy')}
              </h4>
            </div>
          </MDBCol>
        </MDBRow>
      )
    }
  }

  render() {
    toast.configure()

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection mt-5 pb-5">
            <MDBContainer fluid id="FactorsMilestones">
              <h3 className="text-center">Admin Report: Factors Milestones</h3>
              <hr />
              {this.state.loading ? (
                <h2>Loading...</h2>
              ) : (
                <>
                  {this.renderCurrentDatesHeader()}
                  <MDBRow className="mt-2 dates-selector">
                    <MDBCol className="text-center">
                      <h5>Previous</h5>
                      <MDBBtn
                        tag="a"
                        flat
                        className="text-primary"
                        onClick={() => {
                          this.getPreviousData('week')
                        }}
                      >
                        Week
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol className="text-center">
                      <h5>Current</h5>
                      <MDBBtn
                        tag="a"
                        flat
                        className="text-primary"
                        onClick={() => {
                          this.getCurrentData('week')
                        }}
                      >
                        Week
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol className="text-center">
                      <h5>Next</h5>
                      <MDBBtn
                        tag="a"
                        flat
                        className="text-primary"
                        onClick={() => {
                          this.getNextData('week')
                        }}
                      >
                        Week
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="mt-2 mb-5">
                    <MDBCol>
                      <MDBSwitch
                        id="showCSRToggle"
                        labelLeft="Show CSR"
                        labelRight=""
                        checked={this.state.showCSR}
                        getValue={this.handleShowCSRtoggle}
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol size="12" className="content-wrapper">
                      <MDBDataTable
                        searching={false}
                        sortable={false}
                        paging={false}
                        displayEntries={false}
                        info={false}
                        noBottomColumns
                        responsive
                        striped
                        bordered
                        data={
                          this.state.tableData
                            ? this.state.tableData
                            : EMPTY_DATATABLE
                        }
                        className="text-capitalize text-truncate"
                      />
                    </MDBCol>
                  </MDBRow>
                </>
              )}
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default FactorMilestones
