import React from 'react'
import { MDBIcon } from 'mdbreact'
import './NavBackOfficeBtn.scss'
import { useLocation } from 'react-router-dom'

function getClasses() {}

function renderHomeLabel() {
  return (
    <div className="inactive-link">
      <span>
        <MDBIcon icon="home" />
        &nbsp; Home Page
      </span>
    </div>
  )
}

function renderBackLink(goTo) {
  return (
    <div className="active-link">
      <a
        href="/#"
        className="text-white"
        onClick={(evt) => {
          evt.preventDefault()
          goTo('/dashboard')
        }}
      >
        <span className="icon-container">
          <MDBIcon icon="chevron-left" />
        </span>
        <span>
          <small>Go back to</small>
          <MDBIcon icon="home" />
          &nbsp;Home Page
        </span>
      </a>
    </div>
  )
}

export const NavBackOfficeBtn = (props) => {
  const location = useLocation()
  return (
    <div id="nav-backoffice-btn">
      {location.pathname === '/dashboard'
        ? renderHomeLabel()
        : renderBackLink(props.goTo)}
    </div>
  )
}
