import React from 'react'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import { MDBContainer } from 'mdbreact'
import KpiGoalManager from './../../components/kpi/KpiGoalManager/KpiGoalManager.component'
import UserGoalFactory from './../../shared/factories/user-goal.factory'
import UserProfileService from './../../shared/services/UserProfile.service'
import LeaderReportCardService from './../../shared/services/LeaderReportCard.service'
import PageHeading from './../../components/shared/PageHeading.component'
import moment from 'moment'

import './KpiGoalsPage.scss'

class KpiGoalsPage extends React.Component {
  state = {
    UserGoal: null,
    UserGoals: [],
    TotalPoints: null,
    TotalEnrolled: null,
  }

  _fetch = async () => {
    let UserGoals

    try {
      UserGoals = await UserGoalFactory.search({
        search: { user_id: UserProfileService.getUserId() },
        pagination: false,
        order_by: { created_at: 'DESC' },
      })
    } catch (ex) {
      console.log('err: ', ex)
    }

    return Array.isArray(UserGoals) ? UserGoals : []
  }

  _group = (UserGoals) => {
    // 1. group into years
    const goalYears = {}
    UserGoals.forEach((UG) => {
      let year = moment(UG.get('created_at').split('T').shift()).format('YYYY')
      if (!goalYears.hasOwnProperty(year)) goalYears[year] = []
      goalYears[year].push(UG)
    })

    // 2. sort each annual group by most recently updated,
    // keeping only the most recently modified from each annual group.
    Object.keys(goalYears).forEach((year) => {
      goalYears[year].sort((goalA, goalB) => {
        let a = goalA.get('updated_at')
            ? new Date(goalA.get('updated_at')).getTime()
            : 0,
          b = goalB.get('updated_at')
            ? new Date(goalB.get('updated_at')).getTime()
            : 0
        return a > b ? -1 : b > a ? 1 : 0
      })

      goalYears[year] = goalYears[year].shift()

      if (!goalYears[year]) delete goalYears[year]
    })

    let UserGoal
    if (goalYears.hasOwnProperty(new Date().getFullYear()))
      UserGoal = goalYears[new Date().getFullYear()]

    return { UserGoals: Object.values(goalYears), UserGoal }
  }

  _create = async ({ UserGoals, UserGoal }) => {
    if (!UserGoal) {
      UserGoal = UserGoalFactory.create({
        user_id: UserProfileService.getUserId(),
        paradigm: 'EARNINGS',
      })
    }

    UserGoal.calculate()

    this.setState({ UserGoals, UserGoal })
  }

  componentDidMount() {
    this._fetch().then((UserGoals) => this._create(this._group(UserGoals)))

    const getLastGrowthReport = async () => {
      const sourceId = UserProfileService.getUserId()
      let sourceType = null

      if (UserProfileService.isA(['divisional-leader', 'agency-owner']))
        sourceType = 'division'
      else if (UserProfileService.isA(['regional-leader']))
        sourceType = 'region'
      else if (UserProfileService.isA(['district-leader']))
        sourceType = 'district'

      const lastGrowthReport = await LeaderReportCardService.getLastReport(
        sourceId,
        sourceType
      )

      this.setState({
        TotalPoints: lastGrowthReport.curr_points,
        TotalEnrolled: lastGrowthReport.curr_enrolled,
      })
    }

    if (
      UserProfileService.isA([
        'agency-owner',
        'divisional-leader',
        'regional-leader',
        'district-leader',
      ])
    )
      getLastGrowthReport()
  }

  render() {
    return (
      <DashboardLayout>
        <main id="KpiGoalsPage" className="mainSection pb-5">
          <MDBContainer fluid className="mt-5">
            <PageHeading
              label="Key Performance Indicator (KPI) Goals"
              url={this.props?.match?.url}
              from={this.props?.location?.state?.from}
              goTo={(url) =>
                this.props.history.push(url, { from: '/kpi-goals' })
              }
            />
          </MDBContainer>
          {this.state.UserGoal ? (
            <KpiGoalManager
              UserGoals={this.state.UserGoals}
              UserGoal={this.state.UserGoal}
              TotalPoints={this.state.TotalPoints}
              TotalEnrolled={this.state.TotalEnrolled}
              goTo={(url) =>
                this.props.history.push(url, { from: '/kpi-goals' })
              }
            />
          ) : (
            <></>
          )}
        </main>
      </DashboardLayout>
    )
  }
}

export default KpiGoalsPage
