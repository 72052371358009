import React, { Component, Fragment } from 'react'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import {
  MDBContainer,
  MDBDataTable,
  MDBIcon,
  MDBBtn,
  MDBModalHeader,
  MDBModalBody,
  MDBModal,
  MDBInput,
  MDBExportToCSV,
} from 'mdbreact'
import { toast } from 'react-toastify'
import AdminApi from './../../api/admin-api/admin-api'
import DateService from './../../shared/services/Date.service'
import { phoneToView } from './../../shared/formatters/telephone.formatter'
import NoteListComponent from './NoteList/NoteList'

import './ProspectsPage.scss'

class ProspectsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      deleting: false,
      notesModal: false,
      prospectList: [],
      currentNotes: '',
      currentEmail: '',
      csvData: {
        columns: [
          {
            field: 'Name',
          },
          {
            field: 'Email',
          },
          {
            field: 'Phone',
          },
          {
            field: 'City',
          },
          {
            field: 'State',
          },
          {
            field: 'Claimed',
          },
          {
            field: 'Notes',
          },
        ],
        rows: [],
      },
      data: {
        columns: [
          {
            label: 'Name',
            field: 'name',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Email',
            field: 'email',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Phone',
            field: 'phone',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'City',
            field: 'city',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'State',
            field: 'state',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Claimed On',
            field: 'claimed',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Notes',
            field: 'notes',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Update',
            field: 'update',
            sort: 'asc',
            width: 270,
          },
          {
            label: 'Delete',
            field: 'delete',
            sort: 'asc',
            width: 270,
          },
        ],
        rows: [],
      },
    }
  }

  componentDidMount() {
    this.fetchProspectsList()
  }

  confirm_editNotes = (data) => {
    this.confirm_toggle(data)
  }

  confirm_toggle = (data = {}) => {
    this.setState({ notesModal: !this.state.notesModal, ...data })
  }

  //calling delete confirm modal
  confirm_DeleteProspects = (data) => {
    this.confirm_delete_toggle(data)
  }

  //Toggle delete confirm modal
  confirm_delete_toggle = (data = {}) => {
    this.setState({ confirmModal: !this.state.confirmModal, ...data })
  }

  // resource data API
  fetchProspectsList = async () => {
    this.setState({ loading: true })
    AdminApi.getProspects()
      .then((result) => {
        if (result && result.data && result.data.data)
          this.setState({
            prospectList: result.data.data.map((row) => ({
              ...row,
              pro_claimed_on: DateService.dateOnly(row.pro_claimed_on),
              pro_phone: phoneToView(row.pro_phone),
            })),
          })
      })
      .then(() => {
        this.setRows()
        this.setState({ loading: false })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  //forming rows for data table from fetched data
  setRows = () => {
    const { prospectList } = this.state
    let _csvData = []
    if (prospectList && prospectList.length) {
      let rows = prospectList.map((row) => {
        const {
          id,
          pro_city,
          pro_claimed_on,
          pro_email,
          pro_fname,
          pro_notes,
          pro_phone,
          pro_state,
        } = row
        const self = this

        function editNotes() {
          self.setState({ currentNotes: pro_notes, currentEmail: pro_email })
          self.confirm_editNotes({ viewdata: row })
        }

        const editClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={editNotes}
            >
              <MDBIcon icon="pencil-alt" size="2x" className="blue-text p-1" />
            </div>
          </div>
        )

        function editProspects() {
          self.props.history.push(`/add-prospects/${id}/edit`)
        }

        function deleteProspects() {
          self.confirm_DeleteProspects({ viewdata: row })
        }

        const editProspectsClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={editProspects}
            >
              <MDBIcon icon="edit" size="2x" className="blue-text p-1" />
            </div>
          </div>
        )

        const deleteClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={deleteProspects}
            >
              <MDBIcon icon="trash" size="2x" className="red-text p-1" />
            </div>
          </div>
        )

        const claimed_on = (
          <span searchvalue={pro_claimed_on ? pro_claimed_on.getTime() : ''}>
            {pro_claimed_on ? DateService.format(pro_claimed_on) : 'N/A'}
          </span>
        )
        _csvData.push({
          name: pro_fname,
          email: pro_email,
          phone: pro_phone,
          city: pro_city,
          state: pro_state,
          claimed: pro_claimed_on ? DateService.format(pro_claimed_on) : 'N/A',
          notes: pro_notes ? `"${pro_notes}"`.replace('\n', '   ') : '',
        })
        return {
          name: pro_fname,
          email: pro_email,
          phone: pro_phone,
          city: pro_city,
          state: pro_state,
          claimed: claimed_on,
          notes: editClick,
          update: editProspectsClick,
          delete: deleteClick,
        }
      })

      this.setState({
        data: { ...this.state.data, rows: rows },
        csvData: { ...this.state.csvData, rows: _csvData },
      })
    }
  }
  validate = async () => {
    const { currentNotes } = this.state

    if (!currentNotes || !currentNotes.trim()) {
      this.setState({
        formError: true,
        errorMessage: 'Prospect notes required',
      })
      return
    }

    this.setState({ formError: false, errorMessage: '' })
  }

  updateProspectNotes = async (id) => {
    await this.validate()
    const { currentNotes, loading, errorMessage, formError } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }

    const data = {
      pro_notes: currentNotes,
      table_name: 'prospects',
      id: id,
    }

    const form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ submitting: true })

    AdminApi.updateProspect(form_data)
      .then((result) => {
        this.setState({ submitting: false })
        // this.props.history.goBack();
        this.confirm_toggle()
        toast.success('Notes updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
        this.fetchProspectsList()
      })
      .catch((error) => {
        this.setState({ submitting: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  _DeleteProspects = async (id) => {
    this.setState({ deleting: true })
    const data = { type: 'prospects', id: id }

    AdminApi.deleteProspects(data)
      .then((result) => {
        this.setState({ deleting: false })
        this.confirm_delete_toggle()
        toast.success('Prospects successfully deleted', {
          position: toast.POSITION.TOP_RIGHT,
        })
        this.fetchProspectsList()
      })
      .catch((error) => {
        this.setState({ deleting: false })
        toast.error(`${error.message}`, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  render() {
    toast.configure()
    const { loading, confirmModal, submitting, data, notesModal, csvData } =
      this.state

    const createNew = () => {
      this.props.history.push('/add-prospects')
    }

    return (
      <Fragment>
        <DashboardLayout>
          <main id="ProspectsPage" className="mainSection">
            <MDBContainer fluid className="mt-5">
              <h2>
                My Prospects
                <div className="d-inline-block">
                  <MDBBtn
                    tag="a"
                    floating
                    gradient="blue"
                    title={'Add New Prospects'}
                    onClick={createNew}
                  >
                    <MDBIcon icon="plus" />
                  </MDBBtn>
                </div>
              </h2>
              <hr />
              <div className="mt-3 mb-3 ml-1">
                <strong>
                  Please Note: Your Prospect will be put on a protect list for
                  60 days!
                </strong>
              </div>
              {loading ? (
                <h4 className={'p4'}>Loading...</h4>
              ) : (
                <div className="content-wrapper">
                  <MDBDataTable
                    sortRows={['claimed']}
                    responsive
                    striped
                    bordered
                    small
                    data={data}
                    className="text-capitalize"
                  />
                  <div className="text-right">
                    <MDBExportToCSV
                      columns={csvData.columns}
                      data={csvData.rows ? csvData.rows : []}
                      color="primary"
                    >
                      Download CSV
                    </MDBExportToCSV>
                  </div>
                </div>
              )}

              <MDBModal size='lg' isOpen={notesModal} toggle={this.confirm_toggle}>
                <MDBModalHeader toggle={this.confirm_toggle}>
                  Prospect Notes
                </MDBModalHeader>
                <MDBModalBody>
                  <h6>Current Notes:</h6>
                  <MDBInput
                    type="textarea"
                    id="pro_notes"
                    value={this.state.currentNotes || ''}
                    getValue={(value) => {
                      this.setState({ currentNotes: value })
                    }}
                  />
                  <MDBBtn
                    color="secondary"
                    onClick={() => {
                      this.updateProspectNotes(this.state.viewdata.id)
                    }}
                  >
                    {submitting ? 'Updating...' : 'Update'}
                  </MDBBtn>
                  <hr />
                  <br />
                  <h6>Past Notes:</h6>
                  <NoteListComponent
                    canCollapse={false}
                    email={this.state.currentEmail}
                    onSelect={(note) => {
                      this.setState({ currentNotes: note })
                    }}
                  />
                </MDBModalBody>
              </MDBModal>

              <MDBModal
                size={'sm'}
                isOpen={confirmModal}
                toggle={this.confirm_delete_toggle}
              >
                <MDBModalHeader toggle={this.confirm_delete_toggle}>
                  Delete Prospects
                </MDBModalHeader>
                <MDBModalBody>
                  <div className="h5">
                    Are you sure you want to delete this Prospects?
                  </div>
                  <MDBBtn
                    color="secondary"
                    size={'sm'}
                    onClick={this.confirm_delete_toggle}
                  >
                    Close
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    size={'sm'}
                    onClick={() => {
                      this._DeleteProspects(this.state.viewdata.id)
                    }}
                  >
                    {this.state.deleting ? 'Deleting...' : 'Delete'}
                  </MDBBtn>
                </MDBModalBody>
              </MDBModal>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default ProspectsPage
