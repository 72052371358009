import React from 'react'
import { MDBCard, MDBCardHeader, MDBCardBody, MDBRow, MDBCol } from 'mdbreact'
import PIPAwardLevel from './../../../../shared/utilities/PipAwardLevel.class'
import placeholder_picture from './../../../../assets/images/user_placeholder.png'
import env from './../../../../environments/environment'
import { getHeadshot, autoLogin, getAgentAccessToHeadshot } from './../../../../shared/utilities/getHeadshot.function'

import './DashboardCard.scss'
import DivisionLogoComponent from '../../../../components/divisions/DivisionLogo/DivisionLogo.component'

class DashboardCard extends React.Component {
  state = {
    av: null,
    color: null,
    divisionLogo: null,
    layout: null,
    list: [],
    profileImg: null,
    recipient: null,
    recruits: null,
    score: null,
    totalAvYearly: null,
    user_id: null
  }

  _setCardData = (data) => {
    switch (data.layout) {
      case 'agent':
        this.setState({
          layout: data.layout,
          title: data.title,
          subTitle: data.name,
          color: data.color,
          profileImg: getHeadshot({ u_picture: data.pic, id: data.user_id }),
          totalAvYearly: data.agent_total_av_yearly,
          av: this._numberFormat(data.av),
          user_id: data.user_id,
        })
        break
      case 'region':
      case 'district':
        this.setState({
          layout: 'agent', //data.layout,
          title: data.title,
          subTitle: data.name,
          color: data.color,
          profileImg: getHeadshot({ u_picture: data.pic, id: data.user_id }),
          totalAvYearly: data.agent_total_av_yearly,
          av: this._numberFormat(data.av),
          user_id: data.user_id,
        })
        break
      case 'division':
        this.setState({
          layout: data.layout,
          title: data.title,
          subTitle: data.name,
          color: data.color,
          divisionLogo: data.d_logo
            ? `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/divisions%2F${data.d_logo}?alt=media`
            : placeholder_picture,
          totalAvYearly: data.agent_total_av_yearly,
          av: this._numberFormat(data.av),
        })
        break
      case 'ba_team':
        this.setState({
          layout: data.layout,
          title: data.title,
          subTitle: data.team_name,
          color: data.color,
          teamLogo: data.team_logo
            ? `https://firebasestorage.googleapis.com/v0/b/${
                env.integrations.firebase.config.storageBucket
              }/o/${encodeURIComponent(data.team_logo)}?alt=media`
            : placeholder_picture,
          totalAvYearly: data.agent_total_av_yearly,
          av: this._numberFormat(data.av),
        })
        break
      case 'recruits':
        this.setState({
          layout: data.layout,
          title: data.title,
          subTitle: data.name,
          color: data.color,
          profileImg: getHeadshot({ u_picture: data.pic, id: data.id }),
          recruits: data.recruits,
          list: data.list,
          user_id: data.id
        })
        break
      case 'list':
        this.setState({
          layout: data.layout,
          title: data.title,
          color: data.color,
          list: data.list,
        })
        break
      default:
        return
    }
  }

  _numberFormat = (value) =>
    new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value)

  componentDidMount() {
    this._setCardData(this.props.data)
  }

  render() {
    return (
      <MDBCard
        className={
          'DashboardCardComponent ' +
          (this.state.color ? 'card-' + this.state.color : '') +
          ' card-' +
          this.state.layout
        }
      >
        <MDBCardHeader>
          <div className="catd-title">{this.state.title}</div>
          {this.state.subTitle && (
            <div className="card-sub-title">{this.state.subTitle}</div>
          )}
        </MDBCardHeader>
        <MDBCardBody>
          <MDBRow>
            {this.state.layout === 'agent' && (
              <>
                <MDBCol size="6" className="agent">
                  <div 
                    className={this.state.user_id && getAgentAccessToHeadshot(this.state.user_id) ? "card-profile-img profile-img-wrapper cursor-pointer" : "card-profile-img profile-img-wrapper"}
                    onClick={() => {
                      if(this.state.user_id && getAgentAccessToHeadshot(this.state.user_id)) autoLogin(this.state.user_id)
                    }}
                  >
                    <img
                      alt={this.state.recipient}
                      src={this.state.profileImg}
                    />
                  </div>
                </MDBCol>
                <MDBCol size="6" className="agent">
                  <div className="card-profile-jewel">
                    {PIPAwardLevel.getAwardBadge(this.state.totalAvYearly, {
                      size: '100px',
                    })}
                    <div className="agent-points">
                      {(this.state.av || 0) + ' Points'}
                    </div>
                  </div>
                </MDBCol>
              </>
            )}
            {this.state.layout === 'division' && (
              <>
                <MDBCol size="12" className="division">
                  <div className="card-profile-img division-img-wrapper">
                    <img
                      alt={this.state.recipient}
                      src={this.state.divisionLogo}
                    />
                  </div>
                  <div className="division-points">
                    {(this.state.av || 0) + ' Points'}
                  </div>
                </MDBCol>
              </>
            )}
            {this.state.layout === 'ba_team' && (
              <>
                <MDBCol size="12" className="ba-team">
                  <div className="card-profile-img ba-team-img-wrapper">
                    <img alt={this.state.recipient} src={this.state.teamLogo} />
                  </div>
                  <div className="ba-team-points">
                    {(this.state.av || 0) + ' Points'}
                  </div>
                </MDBCol>
              </>
            )}
            {this.state.layout === 'list' && (
              <MDBCol size="12">
                <table>
                  <tbody>
                    {(Array.isArray(this.state.list)
                      ? this.state.list
                      : []
                    ).map((item, i) => {
                      return (
                        <tr className="list" key={`rfs-${i}`}>
                          <td className="division-logo" title={item?.team_name}>
                            {item?.team_logo ? (
                              <DivisionLogoComponent
                                divisionLogo={item.team_logo}
                              />
                            ) : (
                              <></>
                            )}
                          </td>
                          <td title={item.label || item.name}>
                            <div>{item.label || item.name}</div>
                          </td>
                          <td>{item.score || item.u_date}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </MDBCol>
            )}
            {this.state.layout === 'recruits' && (
              <>
                <MDBCol size="6" className="recruits">
                  <div
                    className={this.state.user_id && getAgentAccessToHeadshot(this.state.user_id) ? "card-profile-img profile-img-wrapper cursor-pointer" : "card-profile-img profile-img-wrapper"}
                    onClick={() => {
                      if(this.state.user_id && getAgentAccessToHeadshot(this.state.user_id)) autoLogin(this.state.user_id)
                    }}
                  >
                    <img
                      alt={this.state.recipient}
                      src={this.state.profileImg}
                    />
                  </div>
                </MDBCol>
                <MDBCol size="6" className="recruits">
                  <div className="card-score">
                    <div className="label">Last Week</div>
                    <div className="score">
                      {this.state.recruits +
                        ' Agent' +
                        (this.state.recruits > 1 ? 's' : '')}
                    </div>
                  </div>
                </MDBCol>
                <MDBCol
                  size="12"
                  className="recruiter-list"
                  style={{ padding: 0 }}
                >
                  <div className="label">Current Top Recruiters</div>
                  <ul className="list">
                    {this.state.list &&
                    Array.isArray(this.state.list) &&
                    this.state.list.length ? (
                      this.state.list.map((recruiter) => {
                        return (
                          <React.Fragment
                            key={
                              'recruiter-id-' +
                              recruiter.recruiter
                                .trim()
                                .toLowerCase()
                                .replace(/\s/g, '')
                            }
                          >
                            <li>
                              <div>{recruiter.recruiter}</div>
                              <div>{recruiter.recruited}</div>
                            </li>
                          </React.Fragment>
                        )
                      })
                    ) : (
                      <li className="no-recruits">
                        <div>No recruits yet this week.</div>
                      </li>
                    )}
                  </ul>
                </MDBCol>
              </>
            )}
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    )
  }
}

export default DashboardCard
