import React, { Component } from 'react'
import env from './../../environments/environment'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'
import { telephone as phonePattern } from './../../shared/patterns/telephone.pattern'
import './RequestForm.scss'

class RequestForm extends Component {
  regEmailStr = '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$'
  regPhoneStr = '^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$'
  constructor(props) {
    super(props)

    this.regPhone = new RegExp(this.regPhoneStr)
    this.regEmail = new RegExp(this.regEmailStr)

    this.state = {
      requestSubmit: false,
      firstname: '',
      lastname: '',
      email: '',
      mobile: '',
      state: '',
      notes: '',
    }
    this.onChange = this.onChange.bind(this)
    this.CREATEQuote_API = this.CREATEQuote_API.bind(this)
  }

  validate() {
    const { firstname, lastname, email, mobile, state, notes } = this.state

    if (
      !(firstname && firstname.trim()) ||
      !(lastname && lastname.trim()) ||
      !(state && state.trim()) ||
      !(email && email.trim()) ||
      !this.regEmail.test(email) ||
      !(mobile && mobile.trim()) ||
      !this.regPhone.test(mobile)
    ) {
      return false
    }

    return true
  }

  async CREATEQuote_API(event) {
    let targetDom = event.target
    event.preventDefault()
    const { firstname, lastname, email, mobile, state, notes, requestSubmit } =
      this.state
    if (requestSubmit) return
    event.target.className += ' was-validated'
    const isValid = this.validate()
    if (!isValid) {
      toast.error('Invalid form data', { position: toast.POSITION.TOP_RIGHT })
      return
    }
    const data = {
      firstname,
      lastname,
      email,
      mobile,
      state,
      notes,
      req_url: window.location.href,
      ref_url: document.referrer,
    }

    let form_data = new FormData()

    for (let key in data) form_data.append(key, data[key])

    try {
      await AdminApi.createRecruitLeadCapture(form_data).then((response) => {
        this.setState({ requestSubmit: false })
        if (typeof this.props?.onSuccess === 'function')
          this.props.onSuccess({ response })
        if (response.status === 200) {
          this.setState({
            firstname: '',
            lastname: '',
            email: '',
            mobile: '',
            state: '',
            notes: '',
          })
          toast.success('Quote request sent!', {
            position: toast.POSITION.TOP_RIGHT,
          })
          targetDom.className = targetDom.className.replace(
            / was-validated/g,
            ''
          )
          setInterval(() => {
            if (typeof this.props?.onSuccess === 'function')
              this.props.onSuccess()
          }, 2500)
        }
      })
    } catch (error) {
      this.setState({ requestSubmit: false })
      toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      targetDom.className = targetDom.className.replace(/ was-validated/g, '')
    }
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  render() {
    toast.configure()

    const { firstname, lastname, email, mobile, state, notes, requestSubmit } =
      this.state

    return (
      <div
        style={{
          backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fimg.entrepreneur.jpg?alt=media)`,
          backgroundPositionY: 'center',
        }}
        className="RequestFormComponent section-60 section-sm-90 bg-athens-gray bg-image bg-image-sm-hide"
      >
        <div className="shell">
          <div className="range range-sm-right">
            <div className="cell-sm-8 cell-md-7 cell-lg-7">
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="range">
                      <div className="cell-xs-12 text-center">
                        <h2>
                          <span className="sub-head-small">
                            Start your freedom-making future now!
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <form
                      data-form-output="form-output-global"
                      data-form-type="contact"
                      onSubmit={(event) => this.CREATEQuote_API(event)}
                      noValidate
                      className="range range-xs-center range-sm-left rd-mailform"
                    >
                      <div className="cell-sm-6 offset-top-20">
                        <div className="form-group form-group-outside">
                          <input
                            id="firstname"
                            type="text"
                            name="firstname"
                            data-constraints="@Required"
                            className="form-control bg-whisper-lighten border-radius-0 mb-2"
                            value={firstname}
                            onChange={this.onChange}
                            placeholder={'First Name'}
                            required
                          />
                          <div className="invalid-feedback">
                            Please provide first name.
                          </div>
                        </div>
                      </div>
                      <div className="cell-sm-6 offset-top-20">
                        <div className="form-group form-group-outside">
                          <input
                            id="lastname"
                            type="text"
                            name="lastname"
                            data-constraints="@Required"
                            className="form-control bg-whisper-lighten border-radius-0 mb-2"
                            value={lastname}
                            onChange={this.onChange}
                            placeholder={'Last Name'}
                            required
                          />
                          <div className="invalid-feedback">
                            Please provide last name.
                          </div>
                        </div>
                      </div>
                      <div className="cell-sm-6 offset-top-20">
                        <div className="form-group form-group-outside">
                          <input
                            id="email"
                            type="email"
                            name="email"
                            data-constraints="@Required"
                            className="form-control bg-whisper-lighten border-radius-0 mb-2"
                            value={email}
                            autoComplete="off"
                            onChange={this.onChange}
                            placeholder={'E-mail'}
                            required
                          />
                          <div className="invalid-feedback">
                            Please complete or correct the email address.
                          </div>
                        </div>
                      </div>
                      <div className="cell-sm-6 offset-top-20">
                        <div className="form-group form-group-outside">
                          <input
                            id="phone"
                            type="text"
                            name="mobile"
                            data-constraints="@Required @Numeric"
                            className="form-control bg-whisper-lighten border-radius-0 mb-2"
                            value={mobile}
                            onChange={this.onChange}
                            placeholder={'Phone'}
                            required
                            pattern={phonePattern}
                          />
                          <div className="invalid-feedback">
                            Please complete or correct the phone number.
                          </div>
                        </div>
                      </div>
                      <div className="cell-sm-6 offset-top-20"></div>
                      <div className="cell-sm-6 offset-top-20"></div>
                      <div className="cell-sm-6 offset-top-20">
                        <div className="form-group form-group-outside">
                          <input
                            id="state"
                            type="text"
                            name="state"
                            data-constraints="@Required"
                            className="form-control bg-whisper-lighten border-radius-0 mb-2"
                            value={state}
                            onChange={this.onChange}
                            placeholder={'State'}
                            required
                          />
                          <div className="invalid-feedback">
                            Please provide state.
                          </div>
                        </div>
                      </div>
                      <div className="cell-sm-6 offset-top-20"></div>
                      <div className="cell-sm-6 offset-top-20">
                        <div className="form-group form-group-outside">
                          <textarea
                            id="notes"
                            name="notes"
                            className="form-control bg-whisper-lighten border-radius-0 mb-2"
                            value={notes}
                            rows="3"
                            onChange={this.onChange}
                            placeholder={'Comments'}
                            style={{ height: 'auto' }}
                          />
                          <div className="invalid-feedback">
                            Please provide comments.
                          </div>
                        </div>
                      </div>
                      <div className="cell-sm-6 offset-top-20">
                        <div
                          style={{
                            textAlign: 'center',
                            padding: '2px',
                            color: '#666',
                          }}
                        >
                          <small>
                            Not available in all states, including New York.
                          </small>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-icon btn-icon-right btn-primary-contrast btn-custom btn-block"
                          style={{ marginTop: '15px' }}
                        >
                          <span>
                            {requestSubmit ? 'Submitting...' : 'Submit'}
                          </span>
                          <span className="icon icon-xs-smaller fa-angle-right"></span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RequestForm
