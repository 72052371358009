import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout.jsx'
import {
  MDBBtn,
  MDBContainer,
  MDBDataTable,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from 'mdbreact'
import AdminApi from '../../api/admin-api/admin-api.js'
import { toast } from 'react-toastify'

class Resources extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rt_name: '',
      resourceList: '',
      resourceTypeList: '',
      resourceTypeloading: false,
      loading: '',
      error: '',
      modal: false,
      confirmModal: false,
      viewdata: '',
      data: {
        columns: [
          {
            label: 'Resource Name',
            field: 'name',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Resource Type',
            field: 'type',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Resource Description',
            field: 'description',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Link',
            field: 'link',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Update',
            field: 'update',
            sort: 'asc',
            width: 270,
          },
          {
            label: 'Delete',
            field: 'delete',
            sort: 'asc',
            width: 270,
          },
        ],
        rows: [],
        /*rows: [
                    {
                        name: '2020 Goal Sheet',
                        type: 'Getting Started',
                        link: (<a href="">
                            <MDBIcon icon="link" size="2x" className="d-flex justify-content-center green-text" />
                        </a>),
                        update: (
                            <a href="add-resource">
                                <MDBIcon icon="edit" size="2x" className="d-flex justify-content-center blue-text" />
                            </a>
                        ),
                        delete: (
                            <MDBIcon icon="trash" size="2x" className="d-flex justify-content-center red-text" />
                        ),
                    },
                    {
                        name: 'Continuing Education',
                        type: 'Contracting',
                        link: (<a href="">
                            <MDBIcon icon="link" size="2x" className="d-flex justify-content-center green-text" />
                        </a>),
                        update: (
                            <a href="add-resource">
                                <MDBIcon icon="edit" size="2x" className="d-flex justify-content-center blue-text" />
                            </a>
                        ),
                        delete: (
                            <MDBIcon icon="trash" size="2x" className="d-flex justify-content-center red-text" />
                        ),
                    },
                    {
                        name: 'Carrier Contracts Update',
                        type: 'Carrier Resources',
                        link: (<a href="">
                            <MDBIcon icon="link" size="2x" className="d-flex justify-content-center green-text" />
                        </a>),
                        update: (
                            <a href="add-resource">
                                <MDBIcon icon="edit" size="2x" className="d-flex justify-content-center blue-text" />
                            </a>
                        ),
                        delete: (
                            <MDBIcon icon="trash" size="2x" className="d-flex justify-content-center red-text" />
                        ),
                    }
                ]*/
      },
    } // state end
  }

  componentDidMount() {
    this.fetchResourceData()
  }

  //convert date from sec to current date
  toDateTime = (secs) => {
    let t = new Date(1970, 0, 1) // Epoch
    t.setSeconds(secs)
    return t
  }

  //toggle modal
  toggle = () => {
    this.setState({ modal: !this.state.modal, rt_name: '' })
  }

  //calling delete confirm modal
  confirm_DeleteResource = (data) => {
    this.confirm_toggle(data)
  }

  //Toggle delete confirm modal
  confirm_toggle = (data = {}) => {
    this.setState({ confirmModal: !this.state.confirmModal, ...data })
  }

  //forming rows for data table from fetched data
  setRows = () => {
    const { resourceList } = this.state
    if (resourceList && resourceList.length) {
      let rows = resourceList.map((row) => {
        const {
          resource_name,
          resource_link,
          resource_description,
          rt_name,
          id,
        } = row
        // const submittedDate = created_at && this.toDateTime(created_at._seconds).toString();
        const self = this

        function editResource() {
          self.props.history.push(`/add-resource/${id}/edit`)
        }

        function deleteResource() {
          self.confirm_DeleteResource({ viewdata: row })
        }

        const linkClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <a
              href={resource_link}
              target="_blank"
              rel="noopener noreferrer"
              className={'cursor-pointer d-inline-block'}
            >
              <MDBIcon icon="link" size="2x" className="blue-text p-1" />
            </a>
          </div>
        )

        const editClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={editResource}
            >
              <MDBIcon icon="edit" size="2x" className="blue-text p-1" />
            </div>
          </div>
        )

        const deleteClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={deleteResource}
            >
              <MDBIcon icon="trash" size="2x" className="red-text p-1" />
            </div>
          </div>
        )

        return {
          name: resource_name,
          type: rt_name,
          // created: submittedDate,
          description: resource_description,
          link: linkClick,
          update: editClick,
          delete: deleteClick,
        }
      })
      this.setState({
        data: { ...this.state.data, rows: rows },
      })
    }
  }

  //Fetch data
  fetchResourceData = async () => {
    this.setState({ loading: true })
    AdminApi.getResources()
      .then((result) => {
        this.setState({ resourceList: result.data.data || [] })
      })
      .then(() => {
        this.setState({ loading: false })
        this.setRows()
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  //Delete data
  _DeleteResource = async (id) => {
    this.setState({ deleting: true })
    const data = { type: 'resources', id: id }

    AdminApi.deleteResource(data)
      .then((result) => {
        this.setState({ deleting: false })
        this.confirm_toggle()
        toast.success('Resource successfully deleted', {
          position: toast.POSITION.TOP_RIGHT,
        })
        this.fetchResourceData()
      })
      .catch((error) => {
        this.setState({ deleting: false })
        toast.error(`${error.message}`, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  // create resource type
  createResourceType = async () => {
    const { resourceTypeloading } = this.state
    if (resourceTypeloading) {
      return
    }
    const data = {
      rt_name: this.state.rt_name,
      table_name: 'resource_types',
    }

    const form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ resourceTypeloading: true })

    AdminApi.createResourceType(form_data)
      .then((result) => {
        this.setState({ resourceTypeloading: false })
        this.toggle()
        toast.success('Resource Type added successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ resourceTypeloading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  render() {
    toast.configure()
    const { data, confirmModal, loading } = this.state

    const createNew = () => {
      this.props.history.push('/add-resource')
    }

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <h2>
                Resources
                <div className="d-inline-block">
                  <MDBBtn
                    tag="a"
                    floating
                    gradient="blue"
                    title={'Add New Resource'}
                    onClick={createNew}
                  >
                    <MDBIcon icon="plus" />
                  </MDBBtn>
                </div>
                <MDBBtn
                  color="indigo"
                  className="float-right"
                  onClick={this.toggle}
                >
                  Add Resource Type
                </MDBBtn>
              </h2>
              <hr />
              {loading ? (
                <h4 className={'p4'}>Loading...</h4>
              ) : (
                <div className="content-wrapper">
                  <MDBDataTable
                    entriesOptions={[1000, 15000, 2000]}
                    entries={1000}
                    striped
                    bordered
                    small
                    data={data}
                    className="text-capitalize"
                  />
                </div>
              )}
              <MDBModal
                size={'sm'}
                isOpen={confirmModal}
                toggle={this.confirm_toggle}
              >
                <MDBModalHeader toggle={this.confirm_toggle}>
                  Delete Resource
                </MDBModalHeader>
                <MDBModalBody>
                  <div className="h5">
                    Are you sure you want to delete this Resource?
                  </div>
                  <MDBBtn
                    color="secondary"
                    size={'sm'}
                    onClick={this.confirm_toggle}
                  >
                    Close
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    size={'sm'}
                    onClick={() => {
                      this._DeleteResource(this.state.viewdata.id)
                    }}
                  >
                    {this.state.deleting ? 'Deleting...' : 'Delete'}
                  </MDBBtn>
                </MDBModalBody>
              </MDBModal>
            </MDBContainer>
          </main>

          <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
            <MDBModalHeader toggle={this.toggle}>
              Add Resource Type
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <div className="form-group">
                  <label>Resource Type</label>
                  <input
                    type="text"
                    className="form-control text-capitalize"
                    name="rt_name"
                    id="rt_name"
                    value={this.state.rt_name}
                    onChange={(event) => {
                      this.setState({ rt_name: event.target.value })
                    }}
                  />
                </div>

                <MDBBtn
                  color="unique"
                  type="button"
                  onClick={this.createResourceType}
                >
                  {this.state.resourceTypeloading ? 'Saving...' : 'Save'}
                </MDBBtn>
              </form>
            </MDBModalBody>
            <MDBModalFooter></MDBModalFooter>
          </MDBModal>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default Resources
