import React from 'react'
import env from './../../../environments/environment'
import placeholder_picture from './../../../assets/images/user_placeholder.png'
import './teamLogoImage.scss'

const BA_TEAM_LOGO_SRC = `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/{BA_TEAM}?alt=media`

export const teamLogoImage = (BATeam) => {
  return (
    <div className="ba-team-logo-container">
      <div className="img-wrapper">
        <div>
          <div className="ba-team-img-wrapper">
            <img
              src={encodeURIComponent(
                decodeURIComponent(BATeam.get('team_logo'))
              )}
              alt={`${BATeam.get('team_name')} Team Logo`}
              className="mx-auto d-block ba-team-logo-img"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
