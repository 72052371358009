import React, { useState } from 'react'
import env from './../../../environments/environment'
import useStateWithCallback from './../../../shared/hooks/useStateWithCallback.hook'
import { observer } from 'mobx-react-lite'
import {
  MDBAlert,
  MDBSwitch,
  MDBFileInput,
  MDBRow,
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBSelect,
  MDBIcon,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from 'mdbreact'
import { b64toBlob } from './../../../shared/utilities/b64toBlob.function'
import AppConstants from './../../../constants/appConstants'
import moment from 'moment'
import UserStateLicenseService from './../../../shared/services/UserStateLicense.service'
import UserProfileService from './../../../shared/services/UserProfile.service'
import UserService from './../../../shared/services/User.service'
import StateLicenseStore from './../AgentStateLicenses/StateLicense.store'
import { UIDatePickerInput } from './../../../components/forms/form-fields'

import './StateLicenseRow.scss'
import { PDF_LOGO_SVG } from '../../../constants/external-links'
import { toast } from 'react-toastify'
import { allowedSizeByUploadType } from '../../../constants/allowedMediaSize'

let debounceTimer

const convertDate = (value) => {
  value = value && typeof value === 'string' ? value.split('T')[0] : value
  if (value && typeof value === 'string')
    return moment(value, 'YYYY-MM-DD').toDate()
  if (value && typeof value === 'object') return value
}

const StateOpts = AppConstants.States.map((s) => {
  return { value: s.id, text: s.name }
})

const StateLicenseRow = ({ licenseId, NiprPdbButton }) => {
  const debouncedSave = async (data) => {
    if (debounceTimer) {
      window.clearTimeout(debounceTimer)
      debounceTimer = undefined
    }

    debounceTimer = window.setTimeout(() => {
      save(data)
    }, 800)
  }

  const [license, setLicense] = useStateWithCallback(
    StateLicenseStore.getLicense(licenseId),
    (data) => {
      debouncedSave(data)
    }
  )
  const [showDeleteLicenseModal, setShowDeleteLicenseModal] = useState(false)
  const [showDeleteLicenseImageModal, setShowDeleteLicenseImageModal] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [validity, setValidity] = useState({ dates: null })

  const effective_date = convertDate(license?.effective_date)
      ? // ? moment(convertDate(license.effective_date)).format('MM/DD/YYYY')
        // ? moment(convertDate(license.effective_date)).toDate()
        convertDate(license.effective_date)
      : license?.effective_date,
    expiration_date = convertDate(license?.expiration_date)
      ? // ? moment(convertDate(license.expiration_date)).format('MM/DD/YYYY')
        // ? moment(convertDate(license.expiration_date)).toDate()
        convertDate(license.expiration_date)
      : license?.expiration_date

  const effecitveMinDate = new Date(2000, 0, 1),
    effectiveMaxDate = moment().add(3, 'years').toDate(),
    expirationMinDate = moment(effective_date || new Date())
      .add(1, 'days')
      .toDate(),
    expirationMaxDate = moment(expiration_date || expirationMinDate)
      .add(5, 'years')
      .toDate()

  const deleteStateLicense = async () => {
    setIsDeleting(true)
    await StateLicenseStore.deleteLicense(license?.id)
    setIsDeleting(false)
    setShowDeleteLicenseModal(false)
  }

  const deleteLicenseImage = async () => {
    setIsDeleting(true)
    await UserStateLicenseService.update(license?.id, {
      license_img: null,
    }).finally(() =>
      setLicense((prevState) => ({ ...prevState, license_img: '' }))
    )
    setIsDeleting(false)
    setShowDeleteLicenseImageModal(false)
  }

  const downloadPdf = () => {
    if (license?.license_img)
      window.open(
        `https://firebasestorage.googleapis.com/v0/b/${
          env.integrations.firebase.config.storageBucket
        }/o/${encodeURIComponent(
          decodeURIComponent(license?.license_img)
        )}?alt=media`
      )
  }

  const save = async (data) => {
    data = data && typeof data === 'object' ? data : {}

    let required = {
      state_abrev: 'State',
      license_num: 'License Number',
      effective_date: 'Effective Date',
    }
    if (
      (required = Object.keys(required).filter(
        (r) => !data.hasOwnProperty(r) || !data[r]
      )).length
    ) {
      if (required.length > 0) return
    }
    if (validity.dates) return

    let result
    try {
      result = await StateLicenseStore.saveLicense(license)
    } catch (ex) {
      console.error('Faield to save license update. ', ex)
    }

    if (result && result?.id && parseInt(result.id) !== parseInt(license.id)) {
      if (!license.id) {
        setLicense({})
      } else {
        setLicense((prevState) => ({ ...prevState, ...result }))
      }
    }
  }

  const onSelectFile = (file, source) => {
    if (!license?.id) return
    if (file.size > allowedSizeByUploadType['state_license'])
      return toast.error(
        'Selected file exceeds max file size.  Please try another file.'
      )
    const reader = new FileReader()

    reader.addEventListener('load', async () => {
      // license = Object.assign(license, {[`${source}_src`]: {filename: file?.name, data: reader.result}});
      license[`${source}_src`] = { filename: file?.name, data: reader.result }
      setLicense(license)

      // Extract content-type from complete base64 payload.
      let match = license[`${source}_src`].data.split(',')[0].match(/\w+\/\w+/)
      match = match && match.shift()

      // Extract raw base64 data w/out content-type.
      let base64 = license[`${source}_src`].data.split(',')[1]

      // Build payload to send file to the server.
      const formData = new FormData()
      formData.append(source, b64toBlob(base64, match))
      formData.append(`${source}_filename`, file.name)
      formData.append(`${source}_id`, license.id)

      try {
        await UserService.uploadStateLicense(
          UserProfileService.getUserId(),
          formData
        )
        UserStateLicenseService.get(license.id).then((res) =>
          setLicense((ps) => ({ ...ps, license_img: res && res?.license_img }))
        )
      } catch (ex) {
        toast.error(`Failed to upload: ${ex.message}`)
      }
    })

    reader.readAsDataURL(file)
  }

  const checkDateFormat = (key, val) => {
    const year = new Date(val).getFullYear()
    if (
      val &&
      (!moment(val, moment.ISO_8601, true).isValid() ||
        year <= 1600 ||
        year >= 10000)
    ) {
      console.error('Error: Date format is not valid')
      if (key === 'effective_date') {
        setValidity((prev) => ({ ...prev, dates: 'Invalid date format.' }))
        return false
      }
      if (key === 'expiration_date') {
        setValidity((prev) => ({
          ...prev,
          expirationDate: 'Invalid date format.',
        }))
        return false
      }
    }
    if (key === 'effective_date')
      setValidity((prev) => ({ ...prev, dates: null }))
    if (key === 'expiration_date')
      setValidity((prev) => ({ ...prev, expirationDate: null }))
    return true
  }

  const checkDatesValidity = (dates) => {
    if (dates) {
      let eff = dates?.effective_date
          ? convertDate(dates.effective_date)
          : null,
        exp = dates?.expiration_date ? convertDate(dates.expiration_date) : null

      if (
        (eff && !checkDateFormat('effective_date', eff)) ||
        (exp && !checkDateFormat('expiration_date', exp))
      )
        return false

      if (eff && exp) {
        if (exp.getTime() <= eff.getTime()) {
          setValidity((prevValidity) => ({
            ...prevValidity,
            dates: 'Expiration date must occur after Effective date.',
          }))
          return false
        } else {
          setValidity((prevValidity) => ({ ...prevValidity, dates: null }))
          return true
        }
      }
    }
  }

  const onChange = (evt) => {
    if (['effective_date', 'expiration_date'].indexOf(evt.target.name) > -1) {
      let curr = license[evt.target.name],
        change = evt.target.value

      if (
        !change ||
        typeof change !== 'object' ||
        !(change instanceof Date) ||
        isNaN(Date.parse(change))
      ) {
        change = null
        setValidity((prevValidity) => ({
          ...prevValidity,
          dates: null,
          expirationDate: null,
        }))
      } else {
        if (
          checkDatesValidity({
            ...license,
            ...{ [evt.target.name]: change },
          }) === false
        )
          return false
      }

      if (
        (curr && !change) ||
        (!curr && change) ||
        moment(convertDate(curr)).format('MM/DD/YYYY') !==
          moment(convertDate(change)).format('MM/DD/YYYY')
      ) {
        setLicense((prevState) => {
          prevState = { ...prevState, [evt.target.name]: evt.target.value }
          return prevState
        })
      }
    } else if (license[evt.target.name] !== evt.target.value) {
      setLicense((prevState) => {
        prevState = { ...prevState, [evt.target.name]: evt.target.value }
        return prevState
      })
    }
  }

  const renderFileOutput = () => {
    let imgUrl = license?.license_img && `${license.license_img}`.trim()
    imgUrl = imgUrl && `${imgUrl}`.split('/')[0] === 'users' ? imgUrl : false

    const isPDF = imgUrl
      ? imgUrl.split('.').pop(0).toLowerCase() === 'pdf'
      : false

    imgUrl = imgUrl
      ? `https://firebasestorage.googleapis.com/v0/b/${
          env.integrations.firebase.config.storageBucket
        }/o/${encodeURIComponent(isPDF ? PDF_LOGO_SVG : imgUrl)}?alt=media`
      : false

    return (
      <div className="img-wrapper">
        {imgUrl ? (
          <div className="img">
            <img
              src={imgUrl}
              style={{ transform: isPDF ? 'scale(25%)' : undefined }}
              alt="Agent's State License"
            />
            <div className='btn-container'>
              <div onClick={() => downloadPdf()} className="download-btn">
                <MDBIcon icon="download" />
                &nbsp;Download
              </div>
              <div onClick={() => setShowDeleteLicenseImageModal(true)} className="delete-btn">
                <MDBIcon icon="trash" className="remove" />
              </div>
            </div>
          </div>
        ) : (
          <>
            <MDBFileInput
              disabled={!license?.id}
              name="license_img"
              accept="image/*"
              getValue={(value) => onSelectFile(value[0], 'license_img')}
            />
          </>
        )}
      </div>
    )
  }

  return (
    <>
      <MDBRow className="StateLicenseRowComponent">
        <MDBCol size="12" sm="9">
          <MDBRow>
            <MDBCol size="12" sm="6">
              <div
                className={[
                  'input-wrapper input-select-wrapper',
                  !license?.state_abrev ? 'active-input' : '',
                ].join(' ')}
              >
                <MDBSelect
                  label="State"
                  search
                  options={StateOpts.map((s) => ({
                    ...s,
                    checked: s.value === license?.state_abrev,
                  }))}
                  name="state_abrev"
                  value={license?.state_abrev}
                  getValue={(value) =>
                    onChange({
                      target: { name: 'state_abrev', value: value[0] },
                    })
                  }
                  required
                />
              </div>
            </MDBCol>
            <MDBCol size="12" sm="6">
              <div
                className={[
                  'input-wrapper',
                  !license?.license_num ? 'active-input' : '',
                  !license?.id && !license?.state_abrev ? 'disabled-input' : '',
                  license?.id && !license?.license_num ? 'invalid-input' : '',
                ].join(' ')}
              >
                <MDBInput
                  label="License Number"
                  name="license_num"
                  disabled={!license?.state_abrev}
                  value={license?.license_num}
                  onChange={(event) =>
                    onChange({
                      target: {
                        name: 'license_num',
                        value: event.target.value,
                      },
                    })
                  }
                  required
                />
              </div>
            </MDBCol>
            <MDBCol size="12" sm="6">
              <div
                className={[
                  'input-wrapper',
                  !effective_date ? 'active-input' : '',
                  !license?.id && !license?.license_num ? 'disabled-input' : '',
                  (license?.id && !effective_date) || validity?.dates
                    ? 'invalid-input'
                    : '',
                ].join(' ')}
              >
                <UIDatePickerInput
                  name="effective_date"
                  id={`st_lic_effective_date_${license?.id}`}
                  showYearDropdown
                  yearDropdownItemNumber={2}
                  scrollableYearDropdown
                  disabled={!license?.license_num}
                  selected={effective_date}
                  dateFormat="MM/dd/yyyy"
                  onChange={onChange}
                  label="Effective Date"
                  minDate={effecitveMinDate}
                  maxDate={effectiveMaxDate}
                />
                {validity?.dates ? (
                  <small className="text--red">
                    <strong>{validity.dates}</strong>
                  </small>
                ) : (
                  <></>
                )}
              </div>
            </MDBCol>
            <MDBCol size="12" sm="6">
              <div
                className={[
                  'input-wrapper',
                  !expiration_date ? 'active-input' : '',
                  !license?.id && !license?.effective_date
                    ? 'disabled-input'
                    : '',
                  // license?.id && !expiration_date ? 'invalid-input' : '', - NOT CURRENTLY REQUIRED
                ].join(' ')}
              >
                <UIDatePickerInput
                  name="expiration_date"
                  id={`st_lic_expiration_date_${license?.id}`}
                  showYearDropdown
                  yearDropdownItemNumber={5}
                  scrollableYearDropdown
                  disabled={!license?.effective_date}
                  selected={expiration_date}
                  dateFormat="MM/dd/yyyy"
                  onChange={onChange}
                  label="Expiration Date"
                  minDate={expirationMinDate}
                  maxDate={expirationMaxDate}
                />
                {validity?.expirationDate ? (
                  <small className="text--red">
                    <strong>{validity.expirationDate}</strong>
                  </small>
                ) : (
                  <></>
                )}
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol size="12" sm="3">
          <div
            className={[
              'input-wrapper upload-input-wrapper',
              !license?.license_img ? 'active-input' : '',
              !license?.id ? 'disabled-input' : '',
              license?.id && !license?.license_img ? 'invalid-input' : '',
            ].join(' ')}
          >
            {renderFileOutput()}
          </div>
        </MDBCol>
        <MDBCol size="12" className="mt-1">
          {license?.id > 0 ? (
            <MDBRow>
              <MDBCol
                size="12"
                md="6"
                className="no-margin resident-state-switch"
              >
                <MDBSwitch
                  labelLeft={'Resident State?'}
                  labelRight={''}
                  name="is_resident_state"
                  checked={
                    `${StateLicenseStore.residentLicenseId}` ===
                    `${license?.id}`
                      ? true
                      : false
                  }
                  onChange={(event) => {
                    if (
                      `${StateLicenseStore.residentLicenseId}` ===
                      `${license?.id}`
                    ) {
                      StateLicenseStore.residentLicenseId = null
                      setLicense((prevState) => ({
                        ...prevState,
                        is_resident_state: false,
                      }))
                    } else {
                      StateLicenseStore.setResidentId(license)
                    }
                  }}
                />
                {`${StateLicenseStore.residentLicenseId}` ===
                `${license?.id}` ? (
                  <MDBAlert color="success">Yes</MDBAlert>
                ) : (
                  <MDBAlert color="danger">No</MDBAlert>
                )}
                <div>
                  <div 
                    className='delete-license-link'
                    onClick={() => setShowDeleteLicenseModal(true)}
                  >
                    Remove this state license
                  </div>
                </div>
              </MDBCol>
              <MDBCol size="12" md="6" className="no-margin">
                {NiprPdbButton ? (
                  <div className="nipr-pdb-wrapper">{NiprPdbButton}</div>
                ) : (
                  <></>
                )}
              </MDBCol>
            </MDBRow>
          ) : (
            <></>
          )}
        </MDBCol>
      </MDBRow>
      <MDBModal
        isOpen={showDeleteLicenseModal}
        toggle={() => setShowDeleteLicenseModal(false)}
        className="confirm-delete-modal"
      >
        <MDBModalHeader toggle={() => setShowDeleteLicenseModal(false)}>
          Confirm Delete?
        </MDBModalHeader>
        <MDBModalBody>
          <h5 className="text-center">
            <strong>
              Are you sure you want to delete the {license?.state_abrev} state
              license?
            </strong>
          </h5>
          <br />
          <MDBBtn
            block
            disabled={isDeleting}
            onClick={() => deleteStateLicense()}
          >
            Delete State License
          </MDBBtn>
          <MDBBtn
            block
            disabled={isDeleting}
            onClick={() => setShowDeleteLicenseModal(false)}
          >
            Cancel
          </MDBBtn>
        </MDBModalBody>
      </MDBModal>

      <MDBModal
        isOpen={showDeleteLicenseImageModal}
        toggle={() => setShowDeleteLicenseImageModal(false)}
        className="confirm-delete-modal"
      >
        <MDBModalHeader toggle={() => setShowDeleteLicenseImageModal(false)}>
          Confirm Delete?
        </MDBModalHeader>
        <MDBModalBody>
          <h5 className="text-center">
            <strong>
              Are you sure you want to delete the {license?.state_abrev} state
              license image?
            </strong>
          </h5>
          <br />
          {license?.license_img && (
            <MDBBtn
              block
              disabled={isDeleting}
              onClick={() => deleteLicenseImage()}
            >
              Delete License Image
            </MDBBtn>
          )}
          <MDBBtn
            block
            disabled={isDeleting}
            onClick={() => setShowDeleteLicenseImageModal(false)}
          >
            Cancel
          </MDBBtn>
        </MDBModalBody>
      </MDBModal>
    </>
  )
}

export default observer(StateLicenseRow)
