import React from 'react'
import {MDBContainer,MDBRow,MDBCol} from 'mdbreact';
import ContentHtml from './../../../../components/content/ContentHtml/ContentHtml.component'

import './InfoBeforeBuying.component.scss';

const InfoBeforeBuying = ({subsiteData}) => 
{
	return (
      <MDBContainer id="InfoBeforeBuyingComponent" fluid>
      	<MDBRow>
	      	<MDBCol>
	      		<ContentHtml contentId='46' />
	      	</MDBCol>
      	</MDBRow>
      </MDBContainer>
		)
}

export default InfoBeforeBuying;