import React from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import LoadingService from './../../shared/services/Loading.service'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
} from 'mdbreact'
import PaymentMethods from './components/PaymentMethods/PaymentMethods.component'
import SubscriptionPackage from './components/SubscriptionPackage/SubscriptionPackage.component'
import BillingService from './shared/Billing.service'
import UserProfileService from './../../shared/services/UserProfile.service'
import AgentPackages from './../../components/shared/AgentPackages.component'
import BeastModeAccountLink from './components/BeastModeAccountLink/BeastModeAccountLink.component'
import AdminContentEditPopupComponent from './../../components/adminShared/AdminContentEditPopupComponent'

import './AccountBillingInfo.scss'

class AccountBillingInfoPage extends React.Component {
  state = {}

  _customerId = null
  _customer = null

  _loadBillingProfile = async () => {
    await this._fetchCustomerId()

    setTimeout(() => LoadingService.off(), 500)
  }

  _fetchCustomerId = async () => {
    let customerId

    try {
      customerId = await BillingService.fetchCustomerId(
        UserProfileService.getUserId(),
        'sig_agent'
      )
    } catch (ex) {
      console.log('ERROR:  ', ex)
    }

    customerId = customerId ? customerId : await this._createCustomer()
    if (customerId && customerId !== this._customerId)
      await BillingService.fetchCustomer(customerId)

    this._customerId = customerId
  }

  _createCustomer = async () => {
    let userDetails = UserProfileService.getUserDetails(),
      name,
      email,
      phone

    try {
      email = userDetails.u_email
      name = [userDetails.u_fname, userDetails.u_lname].join(' ').trim()
      phone = userDetails.u_phone
    } catch (ex) {
      console.error(
        'ERROR: Failed to extract user details to create stripe customer.',
        ex
      )
      return false
    }

    try {
      this._customer = await BillingService.createCustomer(
        name,
        email,
        phone,
        UserProfileService.getUserId(),
        'sig_agent'
      )
      if (this._customer && this._customer.id) return this._customer.id
    } catch (ex) {
      console.error(
        'ERROR: Failed to create new stripe customer in AccountBillingInfo.',
        ex
      )
    }
  }

  _renderEditButtons = () => {
    if (
      UserProfileService.isA(
        ['system-admin', 'internal-admin', 'internal-staff', 'agency-owner'],
        true
      )
    ) {
      return (
        <>
          <MDBRow>
            <MDBCol size="12" md="6">
              <AdminContentEditPopupComponent
                type="RTE"
                iconPosition="top-left"
                pageId={67}
                color="white"
                btnLabel="Edit Affiliate Downgrade Content"
                btnClass="btn-block"
              />
            </MDBCol>
            <MDBCol size="12" md="6">
              <AdminContentEditPopupComponent
                type="RTE"
                iconPosition="top-left"
                pageId={66}
                color="white"
                btnLabel="Edit Associate Downgrade Content"
                btnClass="btn-block"
              />
            </MDBCol>
          </MDBRow>
        </>
      )
    }
  }

  componentDidMount() {
    this._loadBillingProfile()
  }

  render() {
    return (
      <React.Fragment>
        <DashboardLayout>
          <main id="AccountBillingInfoPage" className="mainSection mt-5 pb-5">
            <MDBContainer fluid>
              <MDBRow>
                <MDBCol size="12" sm="6">
                  <h2>Billing Info</h2>
                </MDBCol>
                <MDBCol size="12" sm="6">
                  {this._renderEditButtons()}
                </MDBCol>
                <MDBCol>
                  <hr />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="12">
                  <MDBCard className="agent-packages-card">
                    <MDBCardHeader>Agent Packages</MDBCardHeader>
                    <MDBCardBody>
                      <AgentPackages />
                    </MDBCardBody>
                  </MDBCard>
                  <br />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="12" md="6">
                  <SubscriptionPackage />
                </MDBCol>
                <MDBCol size="12" md="6">
                  <PaymentMethods />
                  <br />
                  <BeastModeAccountLink />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export default AccountBillingInfoPage
