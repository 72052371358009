import AdminApi from "./../../api/admin-api/admin-api";

const search = async (query, userId) =>
{
	return new Promise((resolve, reject) => {
		AdminApi
				.searchReferrers(query, userId)
				.then(results => {
						try 					{ results = results.data.data; 		}
						catch (ex) 		{ results = []; 									}
						resolve(results);
					})
				.catch(error => reject(error.message));
	});
}

const create = async (referralData) => 
{
	return new Promise((resolve, reject) => {
		AdminApi
				.createReferrer(referralData)
				.then(results => {
						try 					{ results = results.data.data; 		}
						catch (ex) 		{ results = false; 								}
						results ? resolve(results) : reject(results);
					})
				.catch(error => reject(error.message));
	});
}

const update = async (referralId, referralData, agentId) => 
{
	return new Promise((resolve, reject) => {
		AdminApi
				.updateReferrer(referralId, referralData, agentId)
				.then(results => {
						try 					{ results = results.data.data; 		}
						catch (ex) 		{ results = false; 								}
						results ? resolve(results) : reject(results);
					})
				.catch(error => reject(error.message));
	});
}


const getById = async (refId, agentId) => 
{
	return new Promise((resolve, reject) => {
		AdminApi
				.getReferrer(refId, agentId)
				.then(results => {
						try 					{ results = results.data.data.shift(); 		}
						catch (ex) 		{ results = false; 												}
						results ? resolve(results) : reject(results);
					})
				.catch(error => reject(error.message));
	});
}

const ReferralService = {
	search: 											search,
	create: 											create,
	update:  											update,
	getById: 											getById
};

export default ReferralService;
