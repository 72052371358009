import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { UISelect } from './../../forms/form-fields'
import PaginationControls from './../../content/PaginationControls/PaginationControls.component'
import Store from './../store'
import { convertToCurrency } from './../../../shared/formatters/convertToCurrency.formatter'
import { MDBBtn, MDBIcon } from 'mdbreact'
import moment from 'moment'

import './List.component.scss'

const List = ({ onSelect }) => {
  const [ageType, setAgeType] = useState('DATE')

  const onChange = (evt) => {
    const agentId = evt.target.value

    if (agentId === null) {
      if (Store.searchAgentId !== null) {
        Store.searchAgentId = null
        Store.searchAgentLedgers({ pagination: { page: 0 } })
      }
      return
    } else if (!isNaN(agentId) && agentId > 0) {
      if (parseInt(Store.searchAgentId) !== parseInt(agentId)) {
        Store.searchAgentId = agentId
        Store.searchAgentLedgers({ pagination: { page: 0 } })
      }
      return
    }
  }

  const getHumanDate = (prev) => {
    if (prev && (typeof prev === 'string' || typeof prev === 'object')) {
      switch (ageType) {
        case 'DAYS':
          return `${moment().utc().diff(moment(prev).utc(), 'days')} Days`
        case 'DATE':
          return `${moment(prev).format('MM/DD/YYYY')}`
        // case 'HUMAN':
        //   prev = moment().utc().diff(moment(prev).utc(), 'days')
        //   console.log('prev', prev)
        //   if (prev > 30) {
        //     prev = {
        //       y: Math.floor(prev / 365),
        //       m: Math.floor((prev - Math.floor(prev / 365) * 365) / 30),
        //       d:
        //         prev -
        //         (Math.floor(prev / 365) * 365 +
        //           Math.floor((prev - Math.floor(prev / 365) * 365) / 30) * 30),
        //     }

        //     prev.y =
        //       prev.y > 1
        //         ? `${prev.y} years`
        //         : prev.y > 0
        //         ? `${prev.y} year`
        //         : null
        //     prev.m =
        //       prev.m > 1
        //         ? `${prev.m} months`
        //         : prev.m > 0
        //         ? `${prev.m} month`
        //         : null
        //     prev.d =
        //       prev.d > 1
        //         ? `${prev.d} days`
        //         : prev.d > 0
        //         ? `${prev.d} day`
        //         : null

        //     return `${Object.values(prev)
        //       .filter((n) => n)
        //       .join(', ')}`
        //   } else if (prev === 0) {
        //     return `Today`
        //   } else if (prev === 1) {
        //     return `Yesterday`
        //   }

        //   return `${moment().utc().diff(moment(prev).utc(), 'days')} Days`
        default:
          return '--'
      }
    }

    return '--'
  }

  return (
    <div id="ListComponent">
      <ul
        className={`agent-ledger-list ${!Store.Ledger ? 'show-details' : ''}`}
      >
        <li>
          <div className="al-id">ID</div>
          <div className="al-name">Agent</div>
          <div className="al-email">Email</div>
          <div className="al-balance">Balance</div>
          <div className="al-age">Age</div>
          <div className="al-btns"></div>
        </li>
        {Store.Ledgers.map((Ledger, idx) => {
          return (
            <li key={`agent-ledger-list-${Ledger.id()}-${idx}`}>
              <div className="al-id">{Ledger.id()}</div>
              <div className="al-name">
                {Store.getAgentNameByLedger(Ledger)}
              </div>
              <div className="al-email">
                {Store.getAgentEmailByLedger(Ledger)}
              </div>
              <div className="al-balance">
                {convertToCurrency(
                  parseFloat(
                    isNaN(Ledger?.get('balance')) ? 0 : Ledger.get('balance')
                  )
                )}
              </div>
              <div className="al-age">
                <span
                  onClick={() =>
                    setAgeType(ageType === 'DATE' ? 'DAYS' : 'DATE')
                  }
                >
                  {getHumanDate(Ledger.get('updated_at'))}
                </span>
              </div>
              <div className="al-btns">
                <MDBBtn
                  disabled={
                    Store.isLoading ||
                    Store.isSearching ||
                    Store.isSaving ||
                    (Store.Ledger &&
                      parseInt(Store.Ledger.id()) !== parseInt(Ledger.id()))
                  }
                  onClick={() => {
                    Store.edit(Ledger)
                    if (Store.Ledger) onSelect(Store.Ledger.id())
                  }}
                >
                  <MDBIcon far icon="eye" />
                </MDBBtn>
              </div>
            </li>
          )
        })}
      </ul>
      <div className="pagination-wrapper">
        <PaginationControls
          pagination={Store.ledgerPagination}
          onPageSelect={(pagination) =>
            Store.searchAgentLedgers({ pagination })
          }
        />
      </div>
    </div>
  )
}

export default observer(List)
