import TrainingService from './../../../shared/services/Training.service'
import TrainingTypeService from './../../../shared/services/TrainingType.service'
import TrainingAttendedService from './../../../shared/services/TrainingAttended.service'
import UserProfileService from './../../../shared/services/UserProfile.service'
import { BehaviorSubject } from 'rxjs'
import UserTrainingQuizService from '../../../shared/services/UserTrainingQuiz.service'

const TrainingsSubject = new BehaviorSubject([])
const TrainingTypesSubject = new BehaviorSubject([])
const TrainingAttendedSubject = new BehaviorSubject([])
const ViewVideoSubject = new BehaviorSubject(null)
const EditVideoSubject = new BehaviorSubject(null)
const EditTypeSubject = new BehaviorSubject(null)
const UserTrainingQuizSubject = new BehaviorSubject([])

const fetchTrainings = () => {
  TrainingService.search({
    pagination: false,
    order_by: { training_order: 'ASC', training_name: 'ASC' },
  })
    .then((trainings) => TrainingsSubject.next(trainings && trainings?.models))
    .catch((error) => {
      throw error
    })

  return TrainingsSubject
}

const fetchTrainingTypes = () => {
  TrainingTypeService.search({
    pagination: false,
    order_by: { type_order: 'ASC', training_type_name: 'ASC' },
  })
    .then((types) => TrainingTypesSubject.next(types && types?.models))
    .catch((error) => {
      throw error
    })

  return TrainingTypesSubject
}

const fetchTrainingAttended = (userId) => {
  userId = userId ? userId : UserProfileService.getUserId()
  TrainingAttendedService.search({
    pagination: false,
    search: { user_id: userId },
  })
    .then((attended) =>
      TrainingAttendedSubject.next(attended && attended?.models)
    )
    .catch((error) => {
      throw error
    })

  return TrainingTypesSubject
}

const setViewVideo = (training) => ViewVideoSubject.next(training)

const setEditVideo = (training) => EditVideoSubject.next(training)

const setEditType = (type) => EditTypeSubject.next(type)

const submitCode = async (trainingId, code) => {
  let training
  try {
    training = await TrainingService.search({
      search: { id: trainingId, training_code: code },
    })
    training = training && training?.models ? training?.models : null
  } catch (ex) {
    console.error('Failed to fetch trainings in TrainingsPage. ', ex)
  }

  if (!training || !training.length) throw new Error('Invalid code supplied.')

  let attended = await TrainingAttendedService.search({
    search: {
      training_id: trainingId,
      user_id: UserProfileService.getUserId(),
    },
  })
  attended = attended && attended?.models ? attended?.models : null
  if (attended && attended.length) return true

  return await TrainingAttendedService.store({
    training_id: trainingId,
    user_id: UserProfileService.getUserId(),
    training_code: code,
  })
}

const createTraining = async (data) => {
  let results = TrainingService.store(data)
  if (results)
    window.setTimeout(() => {
      setEditVideo(false)
      fetchTrainings()
    }, 1000)
  return results
}

const updateTraining = async (id, data, reorder) => {
  let results = TrainingService.update(id, data)
  if (results) {
    window.setTimeout(async () => {
      setEditVideo(false)
      fetchTrainings()
    }, 1000)
  }
  return results
}

const deleteTraining = async (id) => {
  let results = TrainingService.delete(id)
  if (results)
    window.setTimeout(() => {
      setEditVideo(false)
      fetchTrainings()
    }, 1000)
  return results
}

const createType = async (data) => {
  let results = TrainingTypeService.store(data)
  if (results)
    window.setTimeout(() => {
      setEditType(false)
      fetchTrainingTypes()
    }, 1000)
  return results
}

const updateType = async (id, data) => {
  let results = TrainingTypeService.update(id, data)
  if (results)
    window.setTimeout(() => {
      setEditType(false)
      fetchTrainingTypes()
    }, 1000)
  return results
}

const deleteType = async (id) => {
  let results = TrainingTypeService.delete(id)
  if (results)
    window.setTimeout(() => {
      setEditType(false)
      fetchTrainingTypes()
    }, 1000)
  return results
}

const deleteAttended = async (training_id) => {
  const attended = (
    await TrainingAttendedService.search({
      search: { training_id, user_id: UserProfileService.getUserId() },
    })
  ).models.shift()
  if (attended && attended?.id) {
    await TrainingAttendedService.delete(attended?.id)

    // After Deletoing, Remove the deleted training
    // attendance record from the Training Attended BehaviorSubject.
    let prevAttended = TrainingAttendedSubject.getValue()
    TrainingAttendedSubject.next(
      (Array.isArray(prevAttended) ? prevAttended : []).filter(
        (a) => parseInt(a.id) !== parseInt(attended?.id)
      )
    )

    return attended
  }
}

const deleteUserTrainingQuiz = async (training_id) => {
  const user_training_quiz = (
    await UserTrainingQuizService.search({
      search: {
        training_id: training_id,
        user_id: UserProfileService.getUserId(),
      },
    })
  ).models.shift()

  if (user_training_quiz && user_training_quiz?.id) {
    await UserTrainingQuizService.delete(user_training_quiz?.id)

    // After Deletoing, Remove the deleted training
    // attendance record from the Training Attended BehaviorSubject.
    let prev_user_training_quiz = UserTrainingQuizSubject.getValue()
    UserTrainingQuizSubject.next(
      (Array.isArray(prev_user_training_quiz)
        ? prev_user_training_quiz
        : []
      ).filter((a) => parseInt(a.id) !== parseInt(user_training_quiz?.id))
    )

    return user_training_quiz
  }
}

const TrainingsPageService = {
  getTrainings: () => TrainingsSubject,
  getTrainingTypes: () => TrainingTypesSubject,
  getTrainingAttended: () => TrainingAttendedSubject,
  fetchTrainings: fetchTrainings,
  fetchTrainingTypes: fetchTrainingTypes,
  fetchTrainingAttended: fetchTrainingAttended,

  setViewVideo: setViewVideo,
  getViewVideo: () => ViewVideoSubject,

  setEditVideo: setEditVideo,
  getEditVideo: () => EditVideoSubject,

  setEditType: setEditType,
  getEditType: () => EditTypeSubject,

  submitCode: submitCode,

  createTraining: createTraining,
  updateTraining: updateTraining,
  deleteTraining: deleteTraining,

  createType: createType,
  updateType: updateType,
  deleteType: deleteType,
  deleteAttended: deleteAttended,
  deleteUserTrainingQuiz: deleteUserTrainingQuiz,
  UserTrainingQuizSubject: UserTrainingQuizSubject,
  getUserTrainingQuizes: () => UserTrainingQuizSubject,
}

export default TrainingsPageService
