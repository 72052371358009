import React, { Component, Fragment } from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import PageHeading from './../../components/shared/PageHeading.component'
import { MDBBtn, MDBIcon } from 'mdbreact'
import {
  UIInput,
  UIDatePickerInput,
} from '../../components/forms/form-fields';
import moment from 'moment'
import Store from './store'
import UserProfileService from '../../shared/services/UserProfile.service'
import PromotionCodeService from '../../shared/services/PromotionCode.service'
import ConfirmDeleteModalComponent from '../../components/adminShared/ConfirmDeleteModal/ConfirmDeleteModal.component'

import './PromotionCodes.page.scss'

const effecitveMinDate = new Date(2000, 0, 1),
effectiveMaxDate = moment().add(3, 'years').toDate(),
timezoneOffset = new Date().getTimezoneOffset();

class PromotionCodesPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      isAdding: false,
      isDeleting: false,
      showEditor: false,
      deletedCode: null,
      selectedCode: {
        id: null,
        code: null,
        start_at: null,
        end_at: null,
      },
      changedCode: null,
    }
  }

  create() {
    this.setState({
      showEditor: true,
      isAdding: true,
    });
  }

  edit (PromoteCode) {
    this.setState({
      selectedCode: {
        id: PromoteCode.id(),
        code: PromoteCode.get('code'),
        start_at: PromoteCode.get('start_at'),
        end_at: PromoteCode.get('end_at'),
      },
      showEditor: true
    })
  }

  async delete (PromoteCode) {
    this.setState({
      isDeleting: true,
      deletedCode: PromoteCode.id()
    });
  }

  async deleteCode () {
    await PromotionCodeService.update(this.state.deletedCode, { deleted_at: new Date().toISOString().split('T')[0] });
    this.setState({
      isLoading: true, 
      isDeleting: false,
      selectedCode: {
        id: null,
        code: null,
        start_at: null,
        end_at: null,
      },
    });
    await Store.fetch();
    this.setState({
      isLoading: false,
      showEditor: false,
      isAdding: false,
      deletedCode: null
    });
  }

  async onSubmit (event) {
    event.preventDefault();
    if(this.state.isAdding) {
      await PromotionCodeService.store({
        code: this.state.selectedCode?.code,
        start_at: new Date(this.state.selectedCode?.start_at).toISOString().split('T')[0],
        end_at: new Date(this.state.selectedCode?.end_at).toISOString().split('T')[0],
        created_by: UserProfileService.getUserId(),
      })
      
      this.setState({
        isLoading: true,
        selectedCode: {
          id: null,
          code: null,
          start_at: null,
          end_at: null,
        },
      })
      await Store.fetch();
      this.setState({
        isLoading: false,
        showEditor: false,
        isAdding: false
      })
      return
    } else {
      await PromotionCodeService.update(
        this.state.selectedCode?.id,
        {
          code: this.state.selectedCode?.code,
          start_at: new Date(this.state.selectedCode?.start_at).toISOString().split('T')[0],
          end_at: new Date(this.state.selectedCode?.end_at).toISOString().split('T')[0],
          created_by: UserProfileService.getUserId(),
        }
      )

      this.setState({
        isLoading: true,
        selectedCode: {
          id: null,
          code: null,
          start_at: null,
          end_at: null,
        },
      })
      await Store.fetch();
      this.setState({
        isLoading: false,
        showEditor: false,
      })
    }
  }

  async componentDidMount() {
    this.setState({isLoading: true})
    await Store.fetch();
    this.setState({isLoading: false})
  }

  renderCreateButton() {
    return (
      <MDBBtn
        color="info"
        disabled={this.state.isLoading || this.state.isAdding}
        id="CreatePromoteCodeButtonComponent"
        onClick={() => this.create()}
        floating
        size='sm'
      >
        <MDBIcon icon="plus" />
      </MDBBtn>
    )
  }

  render() {
    return (
      <Fragment>
        <DashboardLayout>
          <main id="PromotionCodesPage" className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <PageHeading
                label="Promotion Codes"
                url={this.props?.match?.url}
                from={this.props?.location?.state?.from}
                btnGroup={this.renderCreateButton()}
              />
            </MDBContainer>
            <MDBContainer fluid>
              {
                this.state.isLoading ? (
                  <h5>Loading...</h5>
                ) : (
                  <MDBRow className={`promoteCodeComponent ${!this.state.showEditor ? 'show-details' : ''}`}>
                    <MDBCol className='list-col'>
                      <div id="PromoteCodes">
                        <ul
                          className={`promote-code-list ${!this.state.showEditor ? 'show-details' : ''}`}
                        >
                          <li>
                            <div className="al-id">ID</div>
                            <div className="al-code">Code</div>
                            <div className="al-start_at">Start</div>
                            <div className="al-end_at">End</div>
                            <div className="al-btns"></div>
                          </li>
                          {!this.state.isLoading && Store.PromoteCodes.map((PromoteCode, idx) => {
                            return (
                              <li key={`promote-code-list-${PromoteCode.id()}-${idx}`}>
                                <div className="al-id">{PromoteCode.id()}</div>
                                <div className="al-code">
                                  {PromoteCode.get('code')}
                                </div>
                                <div className="al-start_at">
                                  {`${moment.utc(PromoteCode.get('start_at')).format('MM/DD/YYYY')}`}
                                </div>
                                <div className="al-end_at">
                                  {`${moment.utc(PromoteCode.get('end_at')).format('MM/DD/YYYY')}`}
                                </div>
                                <div className="al-btns">
                                  <MDBBtn
                                    disabled={
                                      this.state.isLoading ||
                                      this.state.showEditor ||
                                      this.state.isAdding
                                    }
                                    onClick={() => this.edit(PromoteCode)}
                                  >
                                    <MDBIcon far icon="edit" />
                                  </MDBBtn>
                                  <MDBBtn
                                    disabled={
                                      this.state.isLoading ||
                                      this.state.showEditor ||
                                      this.state.isAdding
                                    }
                                    onClick={() => this.delete(PromoteCode)}
                                  >
                                    <MDBIcon far icon="trash-alt" />
                                  </MDBBtn>
                                </div>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </MDBCol>
                    <MDBCol className='form-col'>
                      <div id="Editor">
                        <div className='mb-5 edit-code-title'>
                          <h5 className='mb-0'>{!this.state.isAdding ? `Edit code` : `Create code`}</h5>
                          <div 
                            onClick={()=> this.setState({
                              showEditor: false, 
                              isAdding: false,
                              selectedCode: {
                                id: null,
                                code: null,
                                start_at: null,
                                end_at: null,
                              },
                            })}
                          >
                            <MDBIcon fas icon="times-circle" />
                          </div>
                        </div>
                        <form
                          noValidate
                          onSubmit={(evt) => this.onSubmit(evt)}
                        >
                          <div className='mb-4'>
                            <UIInput
                              label="Code"
                              name="code"
                              className="promotecode-editor-code"
                              value={this.state.selectedCode?.code || ''}
                              onChange={(evt) => (this.setState({selectedCode: {
                                ...this.state.selectedCode, ...{code: evt.target.value}
                              }}))}
                              required={true}
                              rules={{ required: true }}
                              type="text"
                            />
                          </div>
                          <div className='mb-4'>
                            <UIDatePickerInput
                              name="start_at"
                              id={`start_at`}
                              showYearDropdown
                              yearDropdownItemNumber={2}
                              scrollableYearDropdown
                              selected={this.state.selectedCode?.start_at ? new Date(new Date(this.state.selectedCode?.start_at).getTime() + timezoneOffset * 60000) : null}
                              dateFormat="MM/dd/yyyy"
                              onChange={(evt) => (this.setState({selectedCode: {
                                ...this.state.selectedCode, ...{start_at: evt.target.value}
                              }}))}
                              label="Start Date"
                              minDate={effecitveMinDate}
                              maxDate={effectiveMaxDate}
                            />
                          </div>
                          <div className='mb-4'>
                            <UIDatePickerInput
                              name="end_at"
                              id={`end_at`}
                              showYearDropdown
                              yearDropdownItemNumber={2}
                              scrollableYearDropdown
                              selected={this.state.selectedCode?.end_at ? new Date(new Date(this.state.selectedCode?.end_at).getTime() + timezoneOffset * 60000) : null}
                              dateFormat="MM/dd/yyyy"
                              onChange={(evt) => (this.setState({selectedCode: {
                                ...this.state.selectedCode, ...{end_at: evt.target.value}
                              }}))}
                              label="End Date"
                              minDate={effecitveMinDate}
                              maxDate={effectiveMaxDate}
                            />
                          </div>
                          <div>
                            <MDBBtn
                              disabled={
                                this.state.isLoading ||
                                !this.state.selectedCode?.code || 
                                !this.state.selectedCode?.start_at || 
                                !this.state.selectedCode?.end_at
                              }
                              color="primary"
                              type="submit"
                            >
                              {this.state.isLoading ? (
                                <i className="fa fa-spin fa-spinner" />
                              ) : (
                                <>
                                  <MDBIcon icon="save" /> &nbsp;{this.state.isAdding ? `Create` : `Update`}
                                </>
                              )}
                            </MDBBtn>
                          </div>
                        </form>
                      </div>
                    </MDBCol>
                  </MDBRow>
                )
              }
              {this.state.isDeleting ? (
                <ConfirmDeleteModalComponent
                  confirm={this.state.isDeleting}
                  onConfirm={() =>
                    this.deleteCode()
                  }
                  shouldEnable={true}
                  onCancel={() => this.setState({ deletedCode: null, isDeleting: false })}
                />
              ) : (
                ''
              )}
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default PromotionCodesPage
