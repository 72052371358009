import React from 'react'
import env from './../../environments/environment'
import appConstants from './../../constants/appConstants'
import AuthService from './../../shared/services/Auth.service'
import { toast } from 'react-toastify'
import { MDBIcon } from 'mdbreact'
import UserAlertsIndicator from './../users/UserAlertsIndicator/UserAlertsIndicator.component'
import UserProfileService from './../../shared/services/UserProfile.service'
import { getName } from './../../shared/utilities/getName.function'
import { DashboardSearch } from './DashboardSearch.component'
import './DashboardHeader.scss'
import WeeklyMarketingCommitment from '../modals/MarketingCommitmentModal'
import { NavBackOfficeBtn } from './NavBackOfficeBtn'

class DashboardHeader extends React.Component {
  _activateSupportChat = () => {
    const $body = document.querySelector('body')
    if ($body) {
      if (
        UserProfileService.isA(
          [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
            'divisional-leader',
            'district-leader',
            'regional-leader',
            'csr-agent',
            'limited-to-contracting-signature-agent',
            'limited-to-training-signature-agent',
            'signature-agent-in-need-of-pic',
            'signature-agent-complete',
            'career-agent',
            'premier-agent',
            'division-admin',
            'region-admin',
            'district-admin',
          ],
          true
        )
      ) {
        if (!$body.classList.contains('chat-enabled'))
          $body.classList.add('chat-enabled')
        return
      }

      if ($body.classList.contains('chat-enabled'))
        $body.classList.remove('chat-enabled')
    }
  }

  _logout = () =>
    AuthService.logout().finally(() => {
      toast.success('Logout Successful')
      window.location.href = '/login'
    })

  _renderHeadshot = () => (
    <img
      src={UserProfileService.getHeadshot()}
      alt="My Profile"
      title="My Profile"
      className="img-fluid d-block headshot-ratio"
      style={{ objectFit: 'cover' }}
    />
  )

  _renderSocialBtns = () => (
    <div className="social-btns">
      {(typeof appConstants?.social_media === 'object'
        ? Object.values(appConstants.social_media)
        : []
      ).map((social, idx) => (
        <React.Fragment
          key={`header-social-btn-${social.name.replace(/\s/g, '')}`}
        >
          <div className="social-btn">
            <a href={social.link} rel="noopener noreferrer" target="_blank">
              <img
                alt={`${social.name}`}
                src={`https://firebasestorage.googleapis.com/v0/b/${
                  social.bucket ||
                  env.integrations.firebase.config.storageBucket
                }/o/static%2F${social.icon}?alt=media`}
                title={`${social.name}`}
                className="social-icon rounded"
              />
            </a>
          </div>
          {(idx + 1) % 4 === 0 && <div className="line-break"></div>}
        </React.Fragment>
      ))}
    </div>
  )

  componentDidMount() {
    WeeklyMarketingCommitment.check()
    this._activateSupportChat()
  }

  render() {
    return (
      <header
        id="DashboardHeaderComponent"
        className={(this.props.userData && 'can-search') || ''}
      >
        <div className="row">
          <div className="toggle-nav-btn">
            <div onClick={() => this.props.toggleNav()}>
              <MDBIcon icon="bars" color="white" size="2x" />
            </div>
          </div>
          <NavBackOfficeBtn goTo={this.props.goTo} />

          <div className="nav-bar-search">
            <DashboardSearch
              urlParams={this.props?.urlParams}
              permittedScopes={
                this.props.userData
                  ? this.props.userData.can_search
                    ? ['users', 'states', 'languages']
                    : ['states']
                  : []
              }
              onSearch={this.props.goTo}
            />
          </div>
          <div className="nav-bar-links">{this._renderSocialBtns()}</div>
          <div className="alerts-btn">
            <UserAlertsIndicator
              userId={UserProfileService.getUserId()}
              goTo={(link) => link && this.props.goTo(link)}
              location={this.props.location}
            />
          </div>
          <div className="help-btn">
            <div onClick={() => this.props.goTo('/help-support')}>
              <MDBIcon far icon="comments" className="d-inline-inline" />{' '}
              <div className="d-none d-sm-block">Support</div>
            </div>
          </div>
          <div className="profile-btn">
            <div
              onClick={() => this.props.goTo('/account-profile')}
              style={{ padding: 0 }}
            >
              <figure style={{ width: '38px', height: '38px', margin: 'auto' }}>
                {this._renderHeadshot()}
              </figure>
              <div className="profile-name">{getName(this.props.userData)}</div>
            </div>
          </div>
          <div className="logout-btn">
            <div onClick={this._logout}>
              <MDBIcon icon="sign-out-alt" className="d-inline-inline" />
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default DashboardHeader
