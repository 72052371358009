import { observer } from 'mobx-react-lite'
import { makeAutoObservable } from 'mobx'
import React, { useState } from 'react'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import UserService from './../../../../shared/services/User.service'
import OnboardingService from './../../../../shared/services/Onboarding.service'
import {
  MDBAlert,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBSwitch,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
} from 'mdbreact'
import moment from 'moment'

import './RequiredTrainingChart.component.scss'

class RequiredTrainingStore {
  constructor() {
    makeAutoObservable(this)
  }

  isFetching = null
  hasCompleted = null

  fetch = async () => {
    try {
      this.hasCompleted = await UserService.hasCompletedTrainings(
        UserProfileService.getUserId()
      )
    } catch (ex) {
      this.hasCompleted = false
    }

    this.isFetching = false
  }
}

const Store = new RequiredTrainingStore()

const RequiredTrainingChart = ({}) => {
  const meetsRequirement =
    UserProfileService.isA('internal-admin', true) ||
    UserProfileService.isA('system-admin', true) ||
    UserProfileService.isA('agency-owner', true)

  if (meetsRequirement && Store.isFetching === null) {
    Store.isFetching = true
    Store.fetch()
  }

  const renderButton = () => {
    if (!meetsRequirement) return <></>

    const renderTable = () => {
      if (Store.isFetching || Store.isFetching === null)
        return (
          <span>
            <i className="fa fa-spin fa-spinner"></i>
          </span>
        )

      if (Store.hasCompleted === null) return <></>

      if (Store.hasCompleted === false)
        return (
          <MDBAlert color="danger">
            Unable to determine agent's training status.
          </MDBAlert>
        )

      return (
        <div>
          <table>
            <thead>
              <tr>
                <th colSpan="2">Signature Agents</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Meets Current Requirements:</td>
                <td>{Store.hasCompleted?.sig_agent?.current ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <td>Has Previous Completed:</td>
                <td>
                  {Store.hasCompleted?.sig_agent?.past
                    ? moment(Store.hasCompleted.sig_agent.past).format(
                        'MM/DD/YYYY'
                      )
                    : 'No'}
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th colSpan="2">Leaders</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Meets Current Requirements:</td>
                <td>{Store.hasCompleted?.leader?.current ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <td>Has Previous Completed:</td>
                <td>
                  {Store.hasCompleted?.leader?.past
                    ? moment(Store.hasCompleted.leader.past).format(
                        'MM/DD/YYYY'
                      )
                    : 'No'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    }

    return (
      <MDBCard id="RequiredTrainingChartComponent">
        <MDBCardHeader color="indigo">Required Agent Trainings</MDBCardHeader>
        <MDBCardBody>{renderTable()}</MDBCardBody>
      </MDBCard>
    )
  }

  return renderButton()
}

export default observer(RequiredTrainingChart)
