import React from 'react'
import { MDBIcon } from 'mdbreact'
import StateLicenseMap from './../StateLicenseMap/StateLicenseMap.component'

import './SubsiteNavigation.component.scss'

const getLinkUrl = (link) => {
  let url =
    link?.url &&
    link.url
      .replace(/^(https:?\/\/(www\.)?)/i, 'https://www.')
      .replace(/^(http:?\/\/(www\.)?)/i, 'http://www.')
  url = url && !/^((http|https):)|(\/\/)/i.test(url) ? `//${url}` : url
  return url ? url : ''
}

const SubsiteNavigation = ({ subsiteData, licenses }) => {
  const firstName = subsiteData?.u_fname
    ? subsiteData.u_fname.charAt(0).toUpperCase() +
      subsiteData.u_fname.substr(1)
    : null

  const openMenuLink = (event, link) => {
    if (event) event.preventDefault()
    if (link?.url)
      window.open(
        link.url
          .replace(/^(https:?\/\/(www\.)?)/, 'https://www.')
          .replace(/^(http:?\/\/(www\.)?)/, 'http://www.')
      )
    return false
  }

  const LINKS = [
    {
      label: 'Web Conference' + (firstName ? ` with ${firstName}` : ''),
      icon: <MDBIcon icon="user-plus" />,
      link: subsiteData?.s_web_confrence_link || '#',
      onClick: subsiteData?.s_web_confrence_link
        ? (event) => {
            if (event) event.preventDefault()
            window.open(subsiteData.s_web_confrence_link)
            return false
          }
        : false,
    },
    {
      label: 'Book an Appointment',
      icon: '',
      link: subsiteData?.s_book_appt_link || '#',
      onClick: subsiteData?.s_book_appt_link
        ? (event) => {
            if (event) event.preventDefault()
            window.open(subsiteData.s_book_appt_link)
            return false
          }
        : false,
    },
    {
      label: 'Check Best RX Pricing!',
      icon: '',
      link:
        subsiteData?.rx_card_link || 'https://applink.glicrx.com/mkB5JKnTcjb',
      onClick: (event) => {
        if (event) event.preventDefault()
        if (subsiteData?.rx_card_link) {
          window.open(subsiteData.rx_card_link)
          return false
        }
        window.open(`https://applink.glicrx.com/mkB5JKnTcjb`)
        return false
      },
    },
    {
      label: 'Healthcare Blue Book',
      icon: '',
      link: subsiteData?.s_bb_url || '#',
      onClick: subsiteData?.s_bb_url
        ? (event) => {
            return window.open(subsiteData.s_bb_url)
          }
        : false,
    },
  ]

  return (
    <div id="SubsiteNavigationComponent">
      <ul>
        {Object.keys(LINKS)
          .filter((link) => LINKS[link].onClick)
          .map((link, idx) => {
            return (
              <li key={`agent-subsite-nav-link-sl-${idx}`}>
                <a href={LINKS[link].link} onClick={LINKS[link].onClick}>
                  {LINKS[link].icon ? LINKS[link].icon : ''}
                  {LINKS[link].label}
                </a>
              </li>
            )
          })}
        {(subsiteData?.menu_links || []).map((link, idx) => {
          return (
            <li key={`agent-subsite-nav-link-ml-${idx}`}>
              <a
                href={getLinkUrl(link)}
                target="_BLANK"
                rel="noopener noreferrer"
                onClick={(event) => openMenuLink(event, link)}
              >
                {link.displayname}
              </a>
            </li>
          )
        })}
        {subsiteData?.s_state_license_show ? (
          <li>
            <a href={`/${subsiteData?.u_login}/licenses`}>
              <label className="state-lic-link">
                <StateLicenseMap licenses={licenses} clickable={false} />
                View My State Licenses
              </label>
              <br />
              <small>Not available in all states, including New York.</small>
            </a>
          </li>
        ) : (
          <></>
        )}
      </ul>
    </div>
  )
}

export default SubsiteNavigation
