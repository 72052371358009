import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { MDBBtn } from 'mdbreact'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import UserService from './../../../../shared/services/User.service'
import UserMetaService from '../../../../shared/services/UserMeta.service'

const ResyncCorporateEmailButton = ({ onResync }) => {
  const [isLoading, setIsLoading] = useState(false)

  const meetsRequirement =
    UserProfileService.isAssumed() &&
    UserProfileService.isA('system-admin', true)

  const resyncCorpEmail = async () => {
    setIsLoading(true)

    let today = new Date().toISOString().slice(0, 10)

    try {
      await UserService.resyncCorporateEmail(UserProfileService.getUserId())
      UserMetaService.store({
        user_id: UserProfileService.getUserId(),
        meta_key: `profile---resync-corp-email`,
        meta_value: JSON.stringify({
          changed_on: today,
          user_id: UserProfileService.getUserId(true),
        }),
      })
      onResync('resync')
      setIsLoading(false)
    } catch (error) {
      onResync(error?.message)
      setIsLoading(false)
    }
  }

  const removeCorpEmail = async () =>
  {
    setIsLoading(true)

    let today = new Date().toISOString().slice(0, 10)

    try {
      await UserService.deleteCorporateEmail(UserProfileService.getUserId())
      UserMetaService.store({
        user_id: UserProfileService.getUserId(),
        meta_key: `profile---delete-corp-email`,
        meta_value: JSON.stringify({
          changed_on: today,
          user_id: UserProfileService.getUserId(true),
        }),
      })
      onResync('delete')
      setIsLoading(false)
    } catch (error) {
      onResync(error?.message)
      setIsLoading(false)
    }

  }

  const renderConfig = () => {
    if (meetsRequirement)
      return (
      	<>
	        <div className="reset-agent-enrollment-btn">
	          <MDBBtn
	            disabled={isLoading}
	            className="btn-block"
	            onClick={resyncCorpEmail}
	            sm="true"
	            color="danger"
	          >
	            Re-Sync Corporate Email
	          </MDBBtn>
	        </div>
					<div className="remove-agent-enrollment-btn">
					  <MDBBtn
					    disabled={isLoading}
					    className="btn-block"
					    onClick={removeCorpEmail}
					    sm="true"
					    color="danger"
					  >
					    Remove Corporate Email
					  </MDBBtn>
					</div>
	      </>
      )
    return <></>
  }

  return <>{renderConfig()}</>
}

export default observer(ResyncCorporateEmailButton)
