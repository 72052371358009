import BaseModelInstance from './base.model'
import { LeadCreditPackage as ModelDefinition } from './model-definitions'

class LeadCreditPackage extends BaseModelInstance {
  afterSet = (key, value) => {}

  getAdminUrl = () =>
    `https://automation.usabg.com/nova/resources/lead-credit-packages/${this.id()}`

  isOpenAmount = () => {
    const isoa =
      (!isNaN(this.get('is_open_amount')) &&
        parseInt(this.get('is_open_amount')) === 1) ||
      this.get('is_open_amount') === true
        ? true
        : (!isNaN(this.get('is_open_amount')) &&
            parseInt(this.get('is_open_amount')) === 0) ||
          this.get('is_open_amount') === false
        ? false
        : null
    if (typeof isoa === 'boolean') return isoa
    if (
      typeof this.quantities === 'undefined' ||
      this.quantities.filter((QtyPrice) => QtyPrice?.id()).length > 0
    )
      return true
    return false
  }

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }
}

export default LeadCreditPackage
