import React, { Component } from 'react'
import env from './../../../../environments/environment'
import CareerRequestModal from './../../../../components/modals/CareerRequestModal/CareerRequestModal.component'
import appConstants from './../../../../constants/appConstants'
import VideoPlayerModal from './../../../../components/VideoPlayerModal/VideoPlayerModal.component'
import { Link } from 'react-router-dom'
import UserProfileService from '../../../../shared/services/UserProfile.service'
import UsertypeService from '../../../../shared/services/Usertype.service'
import { HashLink } from 'react-router-hash-link'
import { BBB_LINK } from '../../../../constants/external-links'
import { getPhone } from './../../formatters'
import {
  phoneToStorage,
  phoneToView,
} from './../../../../shared/formatters/telephone.formatter'

import './AgentHeader.component.scss'

const OPPORTUNITY_VIDEO_URL = 'https://player.vimeo.com/video/729389367',
  SHOWCASE_VIDEO_URL = 'https://vimeo.com/showcase/8843553',
  ENABLE_OPP_VIDEO = false,
  NAV_LINKS = {
    CAREERS: {
      LEARN_MORE: 'https://careers.usabg.com/usabgcareers',
      OPPORTUNITY_VIDEO:
        'https://player.vimeo.com/video/729389367?h=500b5be574&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      AGENT_TESTIMONIALS: 'https://vimeo.com/showcase/8843553',
    },
  }

const getLinkUrl = (link) => {
  let url =
    link?.url &&
    link.url
      .replace(/^(https:?\/\/(www\.)?)/i, 'https://www.')
      .replace(/^(http:?\/\/(www\.)?)/i, 'http://www.')
  url = url && !/^((http|https):)|(\/\/)/i.test(url) ? `//${url}` : url
  return url ? url : ''
}

class AgentHeader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      siteId: null,
      resourceLinks: [],
      showOppVideo: false,
      careerModalIsOpen: false,
    }
  }

  togglerBtn() {
    var toggleBtn = document.getElementById('toggler-btn'),
      mobileMenu = document.getElementById('menuBar'),
      asideToggle = document.getElementById('asideToggle'),
      asideToggleBtn = document.getElementById('asideToggleBtn')

    if (toggleBtn) toggleBtn.classList.toggle('active')
    if (mobileMenu) mobileMenu.classList.toggle('active')
    if (asideToggle) asideToggle.classList.remove('active')
    if (asideToggleBtn) asideToggleBtn.classList.remove('active')
  }
  submenuToggle() {
    var subMenu = document.querySelector('.rd-navbar-submenu')
    if (subMenu) subMenu.classList.toggle('opened')
  }

  toggleOppVideo = (evt) => {
    if (evt) evt.preventDefault()

    if (!this.state.videoUrl) {
      this.setState({ showOppVideo: true })
    } else {
      this.setState({ showOppVideo: false })
    }

    return false
  }

  opportunityVideo = (id) => {
    const s_recruiting_url =
      this.props.subsiteData && this.props.subsiteData?.s_recruiting_url
        ? this.props.subsiteData.s_recruiting_url
        : false

    if (s_recruiting_url)
      return (
        <a target="_BLANK" href={s_recruiting_url} rel="noopener noreferrer">
          Opportunity Video
        </a>
      )

    return (
      <a href={`#${id}`} onClick={this.toggleOppVideo}>
        Opportunity Video
      </a>
    )
  }

  _setupListeners = () => {
    var target = document.querySelector('.rd-navbar-submenu')
    var target2 = document.querySelector('.rd-navbar-dropdown')
    target.addEventListener('mouseover', mOver, false)
    target.addEventListener('mouseout', mOut, false)
    target2.addEventListener('mouseover', mOver, false)
    target2.addEventListener('mouseout', mOut, false)

    function mOver() {
      target.setAttribute(
        'class',
        'rd-navbar--has-dropdown rd-navbar-submenu opened'
      )
      target2.setAttribute(
        'class',
        'rd-navbar-dropdown rd-navbar-open-right opened'
      )
    }

    function mOut() {
      target.setAttribute('class', 'rd-navbar--has-dropdown rd-navbar-submenu')
    }
  }

  _setMenuLinks = async (resourceLinks) => {
    resourceLinks = Array.isArray(resourceLinks) ? resourceLinks : []
    this.setState({
      siteId: parseInt(this.props.subsiteData.site_id),
      resourceLinks,
    })
  }

  _canEnroll = () => {
    if (this.props.subsiteData?.usertype_id) {
      if (
        UsertypeService.isA(
          'recruiter-group',
          this.props.subsiteData.usertype_id
        )
      )
        return true
      if (
        UsertypeService.isA('agency-owner', this.props.subsiteData.usertype_id)
      )
        return true
      if (
        UsertypeService.isA('system-admin', this.props.subsiteData.usertype_id)
      )
        return true
    }

    return false
  }

  componentDidMount() {
    this._setupListeners()
    this._setMenuLinks(this.props.subsiteData?.resource_links || [])
    this.setState({ phone: getPhone(this.props.subsiteData, phoneToStorage) })
  }

  componentDidUpdate() {
    if (
      this.props.subsiteData?.site_id &&
      parseInt(this.props.subsiteData.site_id) !== parseInt(this.state.siteId)
    )
      this._setMenuLinks(this.props.subsiteData?.resource_links || [])
    else if (this.state.resourceLinks.length > 0) {
      if (
        !this.props.subsiteData?.site_id ||
        isNaN(this.props.subsiteData?.site_id)
      )
        this.setState({ resourceLinks: [] })
    }
    try {
      if (getPhone(this.props.subsiteData, phoneToStorage) !== this.state.phone)
        this.setState({
          phone: getPhone(this.props.subsiteData, phoneToStorage),
        })
    } catch (ex) {}
  }

  render() {
    const { resourceLinks } = this.state

    return (
      <header
        id="AgentHeaderComponent"
        className="page-head opportunity-video-modal"
      >
        <div className="rd-navbar-wrap">
          <nav
            data-layout="rd-navbar-fixed"
            data-sm-layout="rd-navbar-fixed"
            data-md-device-layout="rd-navbar-fixed"
            data-lg-layout="rd-navbar-static"
            data-lg-device-layout="rd-navbar-static"
            data-stick-up-clone="false"
            data-md-stick-up-offset="53px"
            data-lg-stick-up-offset="53px"
            data-md-stick-up="true"
            data-lg-stick-up="true"
            className="rd-navbar rd-navbar-corporate-light rd-navbar-original rd-navbar-static"
          >
            <div id="AgentStickyNavbar">
              <div className="rd-navbar-inner">
                <div className="rd-navbar-group py-3">
                  <div className="rd-navbar-panel">
                    <button
                      onClick={this.togglerBtn}
                      data-rd-navbar-toggle=".rd-navbar-nav-wrap"
                      className="rd-navbar-toggle toggle-original"
                      id="toggler-btn"
                    >
                      <span></span>
                    </button>
                    <Link
                      to={`/${this.props.subsiteData?.u_login}`}
                      className="rd-navbar-brand brand"
                    >
                      <img
                        alt="USABG"
                        src={appConstants.logos.plain.md}
                        className="mainLogo img-fluid"
                      />
                      <div className="rd-navbar-statement">
                        A non-government site powered by Richard Banville LLC, a
                        health insurance agency.
                      </div>
                    </Link>
                    {this.state.phone ? (
                      <div className="cta-container">
                        <div className="cta-wrapper">
                          <a
                            className="d-sm-none d-md-none d-lg-none d-xl-none"
                            href={`tel:${this.state.phone}`}
                          >
                            &nbsp;Call a Licensed Agent!&nbsp;
                            <wbr />
                            <span className="phone-number">
                              {this.state.phone}
                            </span>
                          </a>
                          <a
                            className="d-none d-sm-inline d-md-inline d-lg-inline d-xl-inline"
                            href={`tel:${this.state.phone}`}
                          >
                            &nbsp;Call a Licensed Agent!&nbsp;
                            <wbr />
                            <span className="phone-number">
                              {phoneToView(this.state.phone)}
                            </span>
                          </a>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    className="rd-navbar-nav-wrap toggle-original-elements"
                    id="menuBar"
                  >
                    <div className="rd-navbar-nav-inner">
                      <ul className="rd-navbar-nav">
                        <li>
                          <a href={`/${this.props.subsiteData?.u_login}`}>
                            Home
                          </a>
                        </li>
                        <li>
                          <a
                            href={`/${this.props.subsiteData?.u_login}/products`}
                            name="products"
                          >
                            Products
                          </a>
                        </li>
                        {resourceLinks && resourceLinks.length ? (
                          <li className="rd-navbar--has-dropdown rd-navbar-submenu">
                            <a href="#/">Resources</a>
                            <ul className="rd-navbar-dropdown rd-navbar-open-right">
                              {resourceLinks.map((link, i) => {
                                let url = getLinkUrl(link)
                                return (
                                  <li key={'asub-menu' + i}>
                                    <a
                                      href={url}
                                      target="_BLANK"
                                      rel="noopener noreferrer"
                                    >
                                      {link.displayname}
                                    </a>
                                  </li>
                                )
                              })}
                            </ul>
                            <span
                              className="rd-navbar-submenu-toggle"
                              onClick={this.submenuToggle}
                            ></span>
                          </li>
                        ) : (
                          ''
                        )}
                        {`${this.props.subsiteData?.u_login}`
                          .trim()
                          .toLowerCase() === 'rbanville' ? (
                          <li className="rd-navbar--has-dropdown rd-navbar-submenu">
                            <a href="#menuBar">Careers</a>
                            <ul className="rd-navbar-dropdown rd-navbar-open-right">
                              <li>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    this.setState({ careerModalIsOpen: true })
                                  }
                                >
                                  Learn More
                                </Link>
                              </li>
                              {ENABLE_OPP_VIDEO ? (
                                <li
                                  onClick={() =>
                                    this.setState({ showOppVideo: true })
                                  }
                                >
                                  <HashLink to="/#opportunityVideo">
                                    Opportunity Video
                                  </HashLink>
                                </li>
                              ) : (
                                <></>
                              )}
                              <li>
                                <Link
                                  to={{
                                    pathname:
                                      NAV_LINKS.CAREERS.AGENT_TESTIMONIALS,
                                  }}
                                  target="_BLANK"
                                  rel="noopener noreferrer"
                                >
                                  Agent Testimonials
                                </Link>
                              </li>
                            </ul>
                            <span
                              className="rd-navbar-submenu-toggle"
                              onClick={this.submenuToggle}
                            ></span>
                          </li>
                        ) : (
                          <li className="rd-navbar--has-dropdown rd-navbar-submenu">
                            <a href="#menuBar">Careers</a>
                            <ul className="rd-navbar-dropdown rd-navbar-open-right">
                              {/*<li><a href="#our-mission">Learn More</a></li>*/}
                              {ENABLE_OPP_VIDEO ? (
                                <li>{this.opportunityVideo('menuBar')}</li>
                              ) : (
                                <></>
                              )}
                              <li>
                                <a
                                  target="_BLANK"
                                  href={SHOWCASE_VIDEO_URL}
                                  rel="noopener noreferrer"
                                >
                                  Agent Testimonials
                                </a>
                              </li>
                              {this._canEnroll() ? (
                                <li>
                                  <a
                                    href={`/${this.props.subsiteData?.u_login}/enroll`}
                                  >
                                    Enroll
                                  </a>
                                </li>
                              ) : (
                                <></>
                              )}
                            </ul>
                            <span
                              className="rd-navbar-submenu-toggle"
                              onClick={this.submenuToggle}
                            ></span>
                          </li>
                        )}
                        <li>
                          <a
                            href={`/${this.props.subsiteData?.u_login}/contact`}
                            name="contact"
                          >
                            Contact
                          </a>
                        </li>
                        <li className="supplemental">
                          <div>
                            {UserProfileService.getUserId() ? (
                              <a
                                href={BBB_LINK}
                                target="_BLANK"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={`https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Flogos%2Flogo.bbb.png?alt=media`}
                                  alt=""
                                  className="bbb"
                                />
                              </a>
                            ) : (
                              <img
                                src={`https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Flogos%2Flogo.bbb.png?alt=media`}
                                alt=""
                                className="bbb"
                              />
                            )}
                          </div>
                          <div className="yearsField">
                            <img
                              src={appConstants.badges.experience}
                              className="years"
                              alt=""
                            />
                          </div>
                          {this.state.phone ? (
                            <div className="cta-wrapper">
                              <a
                                className="d-sm-none d-md-none d-lg-none d-xl-none"
                                href={`tel:${this.state.phone}`}
                              >
                                &nbsp;Call a Licensed Agent!&nbsp;
                                <wbr />
                                <span className="phone-number">
                                  {this.state.phone}
                                </span>
                              </a>
                              <a
                                className="d-none d-sm-inline d-md-inline d-lg-inline d-xl-inline"
                                href={`tel:${this.state.phone}`}
                              >
                                &nbsp;Call a Licensed Agent!&nbsp;
                                <wbr />
                                <span className="phone-number">
                                  {phoneToView(this.state.phone)}
                                </span>
                              </a>
                            </div>
                          ) : (
                            <></>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <CareerRequestModal isOpen={this.state.careerModalIsOpen} />
        <VideoPlayerModal
          open={this.state.showOppVideo}
          onClose={() => this.setState({ showOppVideo: false })}
          videoUrl={OPPORTUNITY_VIDEO_URL}
          videoTitle={'USABG Opportunity Video'}
        />
      </header>
    )
  }
}

export default AgentHeader
