import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import StateLicenseRow from './../StateLicenseRow/StateLicenseRow.component'
import StateLicenseStore from './StateLicense.store'
import AgentProfileStore from './../AgentProfileIntake/AgentProfile.store'
import AgentNiprPdbReportButton from './../AgentNiprPdbReportButton/AgentNiprPdbReportButton.component'

import './AgentStateLicenses.scss'

const AgentStateLicenses = ({ hideHeader }) => {
  const isValid = StateLicenseStore.getValidLicenses(true).length > 0

  const renderIsComplete = (field) => {
    switch (field) {
      case 'state_license':
        return (
          <span className={'validity ' + (isValid ? 'valid' : 'invalid')}>
            <MDBIcon icon={isValid ? 'check' : 'times'} />
          </span>
        )
      default:
        return <></>
    }
  }

  const renderHeader = () => {
    if (hideHeader) return <></>

    return (
      <>
        <MDBCol size="12">
          <div>
            <h5>
              {renderIsComplete('state_license')}&nbsp;Input your state
              licenses.
            </h5>
          </div>
        </MDBCol>
        <MDBCol size="12">
          <hr className="mt-2" />
        </MDBCol>
      </>
    )
  }

  const [fetched, setFetched] = useState(false)
  const [isStateLicensesPage, setIsStateLicensesPage] = useState(false)
  const location = useLocation()

  const niprPdbButton = (rowIndex) => {
    if (rowIndex === 0) return <AgentNiprPdbReportButton />
  }

  useEffect(() => {
    if(location.pathname === '/state-licenses') setIsStateLicensesPage(true);
  }, [location]);

  useEffect(() => {
    if (!fetched) {
      StateLicenseStore.fetchLicenses()
      setFetched(true)
    }
  }, [fetched])

  useEffect(() => {
    AgentProfileStore.setValidity('state_license', isValid)
  }, [isValid])

  return (
    <div id="AgentStateLicenses">
      <MDBRow>
        {renderHeader()}
        <MDBCol size="12" md="6">
          <div>Which states will you be selling insurance in?</div>
        </MDBCol>
        <MDBCol size="12" md="6" className='nipr-pdb-wrapper-top'>
          {
            isStateLicensesPage && niprPdbButton(0)
          }
        </MDBCol>
        <MDBCol size="12">
          <div className="pt-2">
            <MDBContainer fluid>
              {StateLicenseStore.getLicenses().map((item, idx) => {
                return (
                  <div key={'license-idx-' + item.id}>
                    <StateLicenseRow
                      licenseId={parseInt(item.id)}
                      NiprPdbButton={niprPdbButton(isStateLicensesPage && idx === 0 ? idx+1 : idx)}
                    ></StateLicenseRow>
                  </div>
                )
              })}
              <StateLicenseRow source={true}></StateLicenseRow>
            </MDBContainer>
          </div>
        </MDBCol>
      </MDBRow>
    </div>
  )
}

export default observer(AgentStateLicenses)
