import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from 'mdbreact'
import ActivityStore from './../shared/stores/activity.store'

import './InactivityTimer.scss'
import { useEffect } from 'react'

const InactivityTimer = () => {
  useEffect(() => {
    document.addEventListener('visibilitychange', (e) => {
      if (document.visibilityState === 'visible') {
        ActivityStore.checkActivity()
      }
    })
  }, [])
  return (
    <MDBModal
      id="InactivityTimerComponent"
      isOpen={ActivityStore.showWarningModal}
    >
      <MDBModalHeader>
        <MDBIcon icon="exclamation-triangle" />
        &nbsp;<h4>You are about to be logged out!</h4>
      </MDBModalHeader>
      <MDBModalBody>
        <strong>You have been inactive for an hour.</strong>
        <br />
        You will be logged out in <strong>
          {ActivityStore.remaining}
        </strong>{' '}
        seconds.
        <br />
        <MDBBtn block onClick={ActivityStore.cancelWarning}>
          Stay Logged In
        </MDBBtn>
      </MDBModalBody>
    </MDBModal>
  )
}

export default observer(InactivityTimer)
