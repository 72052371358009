import BaseModelInstance from './base.model'
import { Coverage as ModelDefinition } from './model-definitions'

class Coverage extends BaseModelInstance {
  afterSet = (key, value) => {}

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }
}

export default Coverage
