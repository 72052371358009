function getName(profile, format) 
{
  const {u_nickname,u_fname,u_lname} = (profile || {})
  switch (format) {
    case 'formal':    return [u_fname, u_lname].filter(n => n).join(' ').trim();
    default:          return [u_nickname && `${u_nickname}`.trim() && `${u_nickname}`.trim().length > 0 ? u_nickname : u_fname, u_lname].filter(n => n).join(' ').trim();
  }
}

function getAgentName(str) {
  str = `${str && typeof str === 'string' ? str : ''}`.trim().toLowerCase();
  return str.replace(/(^([a-zA-Z\p{M}]))|([ -'][a-zA-Z\p{M}])/g, (s) =>
    s.toUpperCase()
  );
}

export { getName, getAgentName }
