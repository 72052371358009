import React from 'react'
import {observer} from 'mobx-react-lite'
import LoadingSpinner from './../../components/shared/LoadingSpinner.component'
import UserProfileService from './../../shared/services/UserProfile.service';
import Store from './AgentPackages.store';

import './AgentPackages.scss'

const AgentPackages = ({canEnroll, pkgSelectors}) => 
{
	if (Store._fetched === null) {
		Store._fetched = false;
		Store.fetch();
	}

	const getPackages = () =>
	{
		return Store.Packages.filter(Package => {
			if (canEnroll === true)
				return parseInt(Package.get('can_enroll')) === 1;

			if (canEnroll === false)
				return parseInt(Package.get('can_enroll')) !== 1;

			if (parseInt(Package.get('mgr_only')) === 1)
				return UserProfileService.isA(['system-admin','internal-admin','recruiter-group']);

			return true;
		});
	}

	const renderIsIncluded = (Package,Feature) =>
	{
    return Store.packageIncludesFeature(Package,Feature) ? (
      <span style={{ color: "#00c851" }}>✔</span>
    ) : (
      <span style={{ color: "red" }}>✘</span>
    );
	}

	const renderTableRow = (Feature, idx) =>
	{
		return (
				<tr key={`admin-package-feature-${Feature.id()}-${idx}`}>
					<td>{Feature.get('feature')}</td>
					{
						getPackages().map((Package, idx) => {
							return (
									<td key={`package-feature-toggle-${Package.id()}-${idx}`}>{renderIsIncluded(Package, Feature)}</td>
								)
						})
					}
				</tr>
			)
	}

	const renderTableHead = () =>
	{
		return (
				<thead>
					<tr>
						<th rowSpan='2'>Feature:</th>
						{
							getPackages().map((Package, idx) => {
								return (
										<th key={`package-feature-${Package.id()}-${idx}`}>{Package.get('pkg_name')}</th>
									)
							})
						}
					</tr>
					<tr>
						{
							getPackages().map((Package, idx) => {
								return (
										<th key={`package-pkg-descriptor-${Package.id()}-${idx}`}>{Package.get('pkg_descriptor')}</th>
									)
							})
						}
					</tr>
				</thead>
			)
	}

	const renderTableBody = () =>
	{
		return (
				<tbody>{Store.Features.map(renderTableRow)}</tbody>
			)
	}

	const renderTableFoot = () =>
	{
		return (
				<tfoot>
					<tr>
						<td></td>
						{
							getPackages().map((Package, idx) => {
								return (
										<td key={`package-price-descriptor-${Package.id()}-${idx}`}>{Package.get('price_descriptor')}</td>
									)
							})
						}
					</tr>
					{
						pkgSelectors
							?	(
									<tr>
										<td></td>
										{
											getPackages().map((Package, idx) => {

												if (pkgSelectors && pkgSelectors.hasOwnProperty(Package.get('usertype_id'))) {
													return (
															<td key={`package-selector-${Package.id()}-${idx}`}>{pkgSelectors[Package.get('usertype_id')]}</td>
														)
												}
												return (
														<td key={`package-selector-${Package.id()}-${idx}`}></td>
													)
											})
										}
									</tr>
								)
							: <></>
					}
				</tfoot>
			)
	}

	const renderTable = () =>
	{
		return (
				<div className='table-wrapper'>
					<table width='100%' className='feature-table'>
						{renderTableHead()}
						{renderTableBody()}
						{renderTableFoot()}
					</table>
				</div>
			)
	}


	return (
			<>
        <div className={'AgentPackagesComponent '+(Store.isFetching?'is-loading':'')}>
	        <div className="loading-spinner">
	          <LoadingSpinner size="md" isActive={true} />
	        </div>
					{renderTable()}
				</div>
			</>
		)
}

export default observer(AgentPackages)