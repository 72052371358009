import { makeAutoObservable } from 'mobx'
import ChangeLogService from './ChangeLog.service'
import UserProfileService from './../../../shared/services/UserProfile.service'
import StorageService from './../../../shared/services/Storage.service'

const USE_COOKIE_VERSION = false

async function refreshCacheAndReload(availVersion) {
  setCookie('cookie_app_version', availVersion, 365)
  StorageService.clear('check_version')

  try {
    if (caches) {
      let names = await caches.keys()
      await Promise.all(names.map((name) => caches.delete(name)))
    }
  } catch (ex) {
    console.log(`Unable to clear cache via global 'caches'. ${ex}`)
  }

  window.location.reload(true)
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  let expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

function getCookie(cname) {
  let name = cname + '=',
    i,
    c,
    ca = document.cookie.split(';')

  for (i = 0; i < ca.length; i++) {
    c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1)
    if (c.indexOf(name) === 0) return c.substring(name.length, c.length)
  }

  return ''
}

function semverHasChanged(versionA, versionB) {
  const versionsA = versionA
      .split(/\.|-/g)
      .map((x) => parseInt(x))
      .join('-'),
    versionsB = versionB
      .split(/\.|-/g)
      .map((x) => parseInt(x))
      .join('-')

  return !!(versionsA !== versionsB)
}

class ChangeLogStore {
  constructor() {
    makeAutoObservable(this)
  }

  ChangeLogs = []
  unacknowledgedExist = false
  acknowledging = false
  safetyNet = false

  fetchVersionMeta = async () => {
    return new Promise((resolve, reject) => {
      fetch(`/meta.json?v=${new Date().getTime()}`, {
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((response) => resolve(response.json()))
        .catch((err) => {
          console.error('Meta.json version could not be fetched.')
          resolve({})
        })
    })
  }

  fetchChangeLog = async () => {
    if (UserProfileService.getUserId() > 0) {
      let ChangeLogs = []
      try {
        ChangeLogs = await ChangeLogService.search({
          order_by: { released_at: 'DESC' },
        })
      } catch (ex) {
        console.log(`Failed to fetch change logs.  ${ex}`)
      }

      if (ChangeLogs && ChangeLogs?.models) this.ChangeLogs = ChangeLogs?.models

      if (ChangeLogs) {
        let unacknowledgedExist =
          this.ChangeLogs.filter((ChangeLog) => {
            return (
              ChangeLog?.is_acknowledged === false ||
              parseInt(ChangeLog?.is_acknowledged) === 0
            )
          }).length > 0
        this.unacknowledgedExist = unacknowledgedExist
      }

      return true
    }
  }

  acknowledge = async () => {
    this.acknowledging = true

    try {
      await ChangeLogService.acknowledge()
      this.unacknowledgedExist = false
    } catch (ex) {}

    this.acknowledging = false

    this.fetchChangeLog()
  }

  USE_COOKIE_VERSION = USE_COOKIE_VERSION
  setCookie = setCookie
  getCookie = getCookie
  refreshCacheAndReload = refreshCacheAndReload
  semverHasChanged = semverHasChanged
}

export default new ChangeLogStore()
