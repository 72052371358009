import BaseModelInstance from './base.model'
import { Term as ModelDefinition } from './model-definitions'
import TermService from './../services/Term.service'

class Term extends BaseModelInstance {
  afterSet = (key, value) => {}

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }

  resetAll = async () => {
    try {
      return TermService.resetAll(this.id())
    } catch (ex) {
      console.error(ex)
      return Promise.reject(
        `Oh no! Failed to reset all leader guidelines. ${ex}`
      )
    }
  }
}

export default Term
