import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  UIInput,
  UIInputCheckbox,
} from './../../../../components/forms/form-fields'
import {
  MDBCollapse,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBBtn,
  MDBIcon,
} from 'mdbreact'
import ReportService from './../../../../shared/services/Report.service'
import Store from './../../shared/Referrals.store'
import moment from 'moment'

import './EditForm.component.scss'

const EditForm = () => {
  const [showValidity, setShowValidity] = useState(false),
    [formValidity, setFormValidity] = useState({})

  const onChange = (event) =>
    Store.ReferralPartner.set(event.target.name, event.target.value)

  const onSubmit = async (event) => {
    event.preventDefault()

    if (Object.values(formValidity).filter((v) => v === false).length) {
      setShowValidity(true)
      return false
    }

    Store.isSaving = true
    await Store.ReferralPartner.save()
    Store.ReferralPartners = Store.ReferralPartners.map((RP) =>
      parseInt(RP.id()) !== parseInt(Store.ReferralPartner.id())
        ? RP
        : Store.ReferralPartner
    )
    Store.isSaving = false

    return false
  }

  const onClose = () => {
    Store.ReferralPartner = null
  }

  const onDownload = async () => {
    Store.isLoading = true
    const exportUrl = await Store.ReferralPartner.downloadStatment()

    if (exportUrl) window.open(exportUrl)

    Store.isLoading = false
  }

  const onEmail = async () => {
    Store.isLoading = true
    await Store.ReferralPartner.emailStatement()
    Store.isLoading = false
  }

  return (
    <form id="RefEditFormComponent" onSubmit={onSubmit} noValidate>
      <MDBCollapse
        isOpen={!!Store.ReferralPartner && Store.ReferralPartner.id() > 0}
      >
        <MDBCard>
          <MDBCardHeader>
            Update Referral Partner
            <button type="button" onClick={() => onClose()}>
              <MDBIcon icon="times" />
            </button>
          </MDBCardHeader>
          <MDBCardBody>
            <MDBRow>
              <MDBCol size="12">
                <UIInput
                  label="Partner Name"
                  name="name"
                  onChange={onChange}
                  value={Store.ReferralPartner?.get('name')}
                  required={true}
                  onValidityChange={(validity) =>
                    setFormValidity((prevValidity) => ({
                      ...prevValidity,
                      name: validity.isValid,
                    }))
                  }
                  showValidity={showValidity}
                />
              </MDBCol>
              <MDBCol size="12">
                <UIInput
                  label="Company"
                  name="company"
                  onChange={onChange}
                  value={Store.ReferralPartner?.get('company')}
                  required={true}
                  onValidityChange={(validity) =>
                    setFormValidity((prevValidity) => ({
                      ...prevValidity,
                      company: validity.isValid,
                    }))
                  }
                  showValidity={showValidity}
                />
              </MDBCol>
              <MDBCol size="12">
                <UIInput
                  label="Email"
                  name="email"
                  onChange={onChange}
                  value={Store.ReferralPartner?.get('email')}
                  required={true}
                  onValidityChange={(validity) =>
                    setFormValidity((prevValidity) => ({
                      ...prevValidity,
                      email: validity.isValid,
                    }))
                  }
                  showValidity={showValidity}
                />
              </MDBCol>
              <MDBCol size="12">
                <UIInput
                  label="Phone"
                  name="phone"
                  onChange={onChange}
                  value={Store.ReferralPartner?.get('phone')}
                  required={true}
                  onValidityChange={(validity) =>
                    setFormValidity((prevValidity) => ({
                      ...prevValidity,
                      phone: validity.isValid,
                    }))
                  }
                  showValidity={showValidity}
                />
              </MDBCol>
              <MDBCol size="12">
                <UIInput
                  label="Products Sold"
                  name="products_sold"
                  onChange={onChange}
                  value={Store.ReferralPartner?.get('products_sold')}
                  required={false}
                  onValidityChange={(validity) =>
                    setFormValidity((prevValidity) => ({
                      ...prevValidity,
                      products_sold: validity.isValid,
                    }))
                  }
                  showValidity={showValidity}
                  type="textarea"
                />
              </MDBCol>
              <MDBCol size="12">
                <UIInputCheckbox
                  label={'Does referral partner have an insurance license?'}
                  name={`is_licensed`}
                  id={`is_licensed`}
                  onChange={(evt) =>
                    Store.ReferralPartner.set(
                      'is_licensed',
                      parseInt(Store.ReferralPartner.get('is_licensed')) === 1
                        ? 0
                        : 1
                    )
                  }
                  checked={
                    parseInt(Store.ReferralPartner?.get('is_licensed')) === 1
                  }
                />
              </MDBCol>
              <MDBCol size="12">
                <MDBBtn
                  block
                  disabled={!Store.ReferralPartner?.hasChanged}
                  type="submit"
                >
                  <MDBIcon icon="save" />
                  &nbsp;Save Referral Partner
                </MDBBtn>
              </MDBCol>
              {Store.ReferralPartner?.get('email') ? (
                <>
                  <MDBCol size="6">
                    <MDBBtn
                      block
                      disabled={
                        Store.isSearching || Store.isLoading || Store.isSaving
                      }
                      type="button"
                      onClick={() => onDownload()}
                    >
                      <MDBIcon icon="download" />
                      &nbsp;Download Statment
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol size="6">
                    <MDBBtn
                      block
                      disabled={
                        Store.isSearching || Store.isLoading || Store.isSaving
                      }
                      type="button"
                      onClick={() => onEmail()}
                    >
                      <MDBIcon far icon="envelope" />
                      &nbsp;Email Statment
                    </MDBBtn>
                  </MDBCol>
                </>
              ) : (
                <></>
              )}
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </MDBCollapse>
    </form>
  )
}

export default observer(EditForm)
