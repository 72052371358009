import { makeAutoObservable, toJS } from 'mobx'
import UsersCarrierContractService from './../../shared/services/UsersCarrierContract.service'
import CarrierContractService from './../../shared/services/CarrierContract.service'

class ContractStore {
  constructor() {
    makeAutoObservable(this)
  }

  CarrierContracts = {}

  isSendable = []
  isLoading = []
  isSending = []
  hasSent = []

  contractType = (carrierId) => {
    if (this.CarrierContracts.hasOwnProperty(carrierId))
      if (this.CarrierContracts[carrierId])
        return this.CarrierContracts[carrierId]?.contract_type
  }

  fetchCarrierContract = async (carrierId, contractId) => {
    if (toJS(this.isLoading).indexOf(parseInt(carrierId)) > -1) return
    if (contractId && toJS(this.isSending).indexOf(parseInt(contractId)) > -1)
      return
    if (this.CarrierContracts.hasOwnProperty(carrierId)) return

    this.isLoading.push(parseInt(carrierId))

    let CarrierContract
    try {
      CarrierContract = (
        await CarrierContractService.search({
          search: { carrier_id: carrierId },
          pagination: false,
        })
      ).models.shift()
    } catch (ex) {
      console.log(ex)
    }

    this.CarrierContracts[carrierId] = CarrierContract

    if (this.isLoading.indexOf(parseInt(carrierId)) > -1)
      this.isLoading.splice(this.isLoading.indexOf(parseInt(carrierId)), 1)
  }

  send = async (Contract) => {
    if (toJS(this.isSending).indexOf(parseInt(Contract.id())) > -1) return

    this.isSending.push(parseInt(Contract.id()))
    let result, error

    try {
      result = await UsersCarrierContractService.sendContractToCarrier(
        Contract.id()
      )
    } catch (ex) {
      error = `${ex}`
      console.error('Failed to send contract to carrier.  ', ex)
    }

    let idx = toJS(this.isSending).indexOf(parseInt(Contract.id()))
    if (idx > -1) this.isSending.splice(idx, 1)

    if (result && parseInt(result?.id) === parseInt(Contract.id())) return true

    if (error && typeof error === 'string') return error

    return false
  }
}

export default new ContractStore()
