import React from "react";
import DashboardLayout from "../../components/Admin/DashboardLayout";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import AgentTerms from "./../../components/agents/AgentTerms/AgentTerms.component";
import UserProfileService from "./../../shared/services/UserProfile.service";

import "./AccountTerms.scss";

class AccountTermsPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <DashboardLayout>
          <main id="AccountTermsPage" className="mainSection">
            <MDBContainer fluid className="mt-5 pb-5">
              <MDBRow>
                <MDBCol>
                  <h2>Agent Terms</h2>
                  <hr />
                </MDBCol>
                <MDBCol size="12" className="mb-5">
                	<AgentTerms userId={UserProfileService.getUserId()} />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </React.Fragment>
    );
  }
}

export default AccountTermsPage;
