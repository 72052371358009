import React from 'react'
import { MDBContainer } from 'mdbreact'
import { Subscription } from 'rxjs'
import LoadingService from './../../shared/services/Loading.service'

import './LoadingSpinner.scss'

class LoadingSpinner extends React.Component {
  state = {
    isActive: false,
    label: null,
  }

  #_subscriptions$ = new Subscription()

  #setLabel = (label) => {
    if (!!label !== !!this.state.label) {
      if (label !== this.state.label) this.setState({ label })
    }
  }

  #setActive = (isActive) => {
    if (isActive !== this.state.isActive) this.setState({ isActive })
  }

  componentDidMount() {
    if (!this.props.hasOwnProperty('isActive')) {
      this.#_subscriptions$.add(
        LoadingService.getLabel().subscribe((label) => this.#setLabel(label))
      )
      this.#_subscriptions$.add(
        LoadingService.getActive().subscribe((isActive) =>
          this.#setActive(isActive)
        )
      )
    } else {
      if (this.state.isActive !== !!this.props.isActive) {
        this.setState(
          {
            isActive: !!this.props.isActive,
            label: this.props.label || this.state.label,
          }
          // , () => {console.log("new l-logger - ",this.state);}
        )
      }
    }
  }

  componentDidUpdate() {
    if (this.props.hasOwnProperty('isActive'))
      if (this.state.isActive !== !!this.props.isActive) {
        this.setState(
          {
            isActive: !!this.props.isActive,
            label: this.props.label || this.state.label,
          }
          // , () => {console.log("upd l-logger - ",this.state);}
        )
      }
  }

  componentWillUnmount() {
    if (!this.props.hasOwnProperty('isActive'))
      this.#_subscriptions$.unsubscribe()
  }

  render() {
    const size =
      'loading-size-' +
      (['xs', 'sm', 'md', 'lg'].indexOf(
        `${this.props.size}`.trim().toLowerCase()
      ) > -1
        ? `${this.props.size}`.trim().toLowerCase()
        : 'sm')

    return (
      <React.Fragment>
        <MDBContainer
          fluid
          className={
            'LoadingSpinnerComponent ' +
            size +
            ' ' +
            (this.state.isActive ? 'loader-on' : '')
          }
        >
          <div className="loading-wrapper">
            <div className="loading-content">
              <div className="loading-wheel">
                <svg preserveAspectRatio="xMidYMid">
                  <circle className="outer-ring" cx="40" cy="40" r="32">
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      dur="2.0408163265306123s"
                      repeatCount="indefinite"
                      keyTimes="0;1"
                      values="0 40 40;360 40 40"
                    ></animateTransform>
                  </circle>
                  <circle className="inner-ring" cx="40" cy="40" r="23">
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      dur="2.0408163265306123s"
                      repeatCount="indefinite"
                      keyTimes="0;1"
                      values="0 40 40;-360 40 40"
                    ></animateTransform>
                  </circle>
                </svg>
              </div>
              <div className="loading-copy">
                <div>
                  <span>Loading ...</span>
                  {this.props.page || this.state.label ? (
                    <>
                      <br />
                      <span>{this.props.page || this.state.label}</span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </MDBContainer>
      </React.Fragment>
    )
  }
}

export default LoadingSpinner
