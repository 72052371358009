import React from 'react'
import {
  MDBBtn, MDBIcon, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter
} from 'mdbreact'
import UserProfileService from "./../../../../shared/services/UserProfile.service";
import TechToolsService from './../../shared/TechTools.service';
import { Subscription } from 'rxjs';

import "./CorporateEmailModal.scss";

class CorporateEmailModal extends React.Component {

	state = {
		isOpen: 		false,
		status: 		null,
		title:  		"",
		body: 			<></>
	}

	__subscriptions$ = new Subscription();

	_setUsabgEmailStatus = status =>
	{
		let stateUpd 				= 	{};

		if (status === 'exists') {
			let email 				= 	[UserProfileService.get('u_company_email'), UserProfileService.get('u_email'), UserProfileService.get('u_quote_email')];
			email 						= 	email.filter(n => !!n).map(n => `${n}`.trim()).filter(n => /@usabg\.(com|net)$/.test(`${n}`));
			email 						= 	email && email.shift();

			stateUpd.status 	= 	false;
			stateUpd.title 		= 	"Corporate Email Exists";

			if (email !== true) {
				stateUpd.body 	=		<>
															You already have the following USABG corporate email address: <br/>
															<strong>{email}</strong>
														</>
			}
			else {
				stateUpd.body 	=		<>
															You already have a corporate email.  Contact Broker Support team for further details.
														</>
			}
		}

		else if (status === 'success') {
			stateUpd.status 	= 	true;
			stateUpd.title 		= 	"Corporate Email Created";
				stateUpd.body 	=		<>
															Great! Your USABG corporate email address has been created: <br/>
															<strong>{UserProfileService.get('u_company_email')}</strong><br /><br />
															We'll email instructions to you at the following email: <br />
															<strong>{UserProfileService.get('u_email')}</strong>
														</>
		}


		else if (status) {
			stateUpd.status 	= 	false;
			stateUpd.title 		= 	"Error Creating Corporate Email";
			stateUpd.body 		=		<>
															<table>
																<tbody>
																	<tr>
																		<td valign='middle'><div className="h5"><MDBIcon icon="exclamation-triangle" className="text-danger" /></div></td>
																		<td valign='middle'>{status}</td>
																	</tr>
																</tbody>
															</table>
														</>
		}

		if (typeof stateUpd?.status === 'boolean') {
			stateUpd.isOpen = true;
			this.setState({...stateUpd})
		}
		else if (this.state.isOpen)
			this.setState({isOpen: false})
	}

	_closeModal = () => TechToolsService.resetUsabgEmailStatus()

	componentDidMount () 
	{
		this.__subscriptions$.add(TechToolsService.getUsabgEmailStatus().subscribe(status => this._setUsabgEmailStatus(status)));
	}

	componentWillUnmount ()
	{
		this.__subscriptions$.unsubscribe();
	}

	render ()
	{
		return (
				<>
					<MDBModal id="CorporateEmailModal" size='lg' isOpen={this.state.isOpen} toggle={this._closeModal} className={[(this.state.status === false ? "error-modal" : ""), (this.state.status === true ? "success-modal" : "")].join(' ')}>
						<MDBModalHeader>
							{this.state.title}
						</MDBModalHeader>
						<MDBModalBody>
							{<><div className="modal-message">{this.state.body}</div></>}
						</MDBModalBody>
						<MDBModalFooter>
							<MDBBtn className="btn-block" onClick={this._closeModal}>CLOSE</MDBBtn>
						</MDBModalFooter>
					</MDBModal>
				</>
			)
	}
}

export default CorporateEmailModal;
