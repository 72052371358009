import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { MDBBtn, MDBRow, MDBCol } from 'mdbreact'
import { months } from './../../variables'
import { getDefaultStart, getDefaultStop } from './../../helpers'
import DatePicker from 'react-datepicker'

import './ReportDatesSetter.scss'

const ReportDatesSetter = (props) => {
  const [dates, setDates] = useState({ start: props.start, stop: props.stop })

  const customDatePickerFrom = () => {
    let customDateFrom = moment(dates.start, 'YYYY-MM-DD').toDate()
    let Start = new Date('January 1, 1900 00:00:00')
    let yearsRange = moment(new Date()).diff(Start, 'years')
    let years = []
    for (let year = 0; year <= yearsRange; year++)
      years.push(Start.getFullYear() + year)
    years.reverse()

    return (
      <DatePicker
        className="form-control-plaintext"
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {'<'}
            </button>
            <select
              value={moment(date).format('YYYY')}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[parseInt(moment(date).format('M')) - 1]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              type="button"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {'>'}
            </button>
          </div>
        )}
        popperClassName="customDatepickerZIndex"
        dateFormat="MM/dd/yyyy"
        selected={customDateFrom}
        onChange={(date) =>
          setDates((dates) => ({
            ...dates,
            start: moment(date).format('YYYY-MM-DD'),
          }))
        }
      />
    )
  }

  const customDatePickerTo = () => {
    // const { customDateTo } = this.state
    let customDateTo = moment(dates.stop, 'YYYY-MM-DD').toDate()
    let Start = new Date('January 1, 1900 00:00:00')
    let yearsRange = moment(new Date()).diff(Start, 'years')
    let years = []
    for (let year = 0; year <= yearsRange; year++)
      years.push(Start.getFullYear() + year)
    years.reverse()

    return (
      <DatePicker
        className="form-control-plaintext"
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {'<'}
            </button>
            <select
              value={moment(date).format('YYYY')}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[parseInt(moment(date).format('M')) - 1]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              type="button"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {'>'}
            </button>
          </div>
        )}
        popperClassName="customDatepickerZIndex"
        dateFormat="MM/dd/yyyy"
        selected={customDateTo}
        onChange={(date) =>
          setDates((dates) => ({
            ...dates,
            stop: moment(date).format('YYYY-MM-DD'),
          }))
        }
      />
    )
  }

  const onChange = () => {
    let changed = {}
    if (
      dates.start !== (props.start || getDefaultStart()) ||
      dates.stop !== (props.stop || getDefaultStop())
    ) {
      changed.start = dates.start
      changed.stop = dates.stop
    }

    if (Object.keys(changed).length)
      props.onChange({ ...changed, output_mode: 'custom' })
  }

  useEffect(() => {
    if (
      (dates.start !== props.start || dates.stop !== props.stop) &&
      props.isFetching === true
    )
      setDates({ start: props.start, stop: props.stop })
  }, [props.isFetching, dates.start, dates.stop, props.start, props.stop])

  return (
    <div id="ReportDatesSetter" className="mb-4">
      <h4>Custom Timeframe</h4>
      <form>
        <MDBRow className="custom-datepicker">
          <MDBCol>
            <label className="grey-text">From</label>
            <div>{customDatePickerFrom()}</div>
          </MDBCol>
          <MDBCol>
            <label className="grey-text">To</label>
            <div>{customDatePickerTo()}</div>
          </MDBCol>
        </MDBRow>
        <MDBBtn
          disabled={props.isFetching}
          color="indigo"
          className="btn-block"
          onClick={onChange}
        >
          Submit
        </MDBBtn>
      </form>
    </div>
  )
}

export default observer(ReportDatesSetter)
