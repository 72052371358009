import React, { Component } from 'react'
import { observer } from 'mobx-react-lite'
import {
  MDBBtn,
  MDBIcon,
  MDBDataTable,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
} from 'mdbreact'
import AdminApi from './../../../api/admin-api/admin-api'
import { toast } from 'react-toastify'
import UserProfileService from './../../../shared/services/UserProfile.service'
import UserTermSetFactory from './../../../shared/factories/user-term-set.factory'
import TermSetFactory from './../../../shared/factories/term-set.factory'
import moment from 'moment'
import Store from './AgentTerms.store'

import './TermSetReport.scss'

const ENABLE_EXPORT = false

const TermSetReport = ({ hideHeader }) => {
  const renderHeader = () => {
    if (hideHeader) return <></>

    return <MDBCardHeader>Accepted User Term Checklists</MDBCardHeader>
  }

  const renderSignature = (UserTermSet) => {
    if (UserTermSet) {
      if (Store.isTermSetSigned(UserTermSet)) return <div>Signed</div>

      return (
        <MDBBtn
          disabled={Store.isSigningTermSet}
          className="sign-btn"
          onClick={() => Store.signTermSet(UserTermSet)}
        >
          {Store.isSigningTermSet ? (
            <>
              <i className="fa fa-spin fa-spinner"></i>
              <br />
              Signing ...
            </>
          ) : (
            <>
              <MDBIcon icon="file-signature" />
              <br />
              Sign Terms
            </>
          )}
        </MDBBtn>
      )
    }
  }

  const renderBody = () => {
    if (Store.isLoading) {
      return (
        <MDBCardBody>
          <h2>Loading...</h2>
        </MDBCardBody>
      )
    }

    return (
      <MDBCardBody>
        <MDBDataTable
          searching={false}
          sortRows={['acceptance_date']}
          paging={false}
          striped
          responsive
          bordered
          data={{
            columns: [
              { label: 'Accepted By', field: 'accepted' },
              { label: 'Term Set', field: 'terms' },
            ],
            rows: (Store.UserTermSets || []).map((UserTermSet) => {
              let TermSet = Store.TermSets.filter(
                  (TermSet) =>
                    parseInt(TermSet.id()) ===
                    parseInt(UserTermSet.get('set_id'))
                ).shift(),
                terms =
                  TermSet && TermSet.get('term_set') && TermSet.get('term_set')
              return {
                accepted: (
                  <div>
                    <p>
                      <strong>Accepted By:</strong>
                      <br />
                      {UserTermSet.get('accepted_by')}
                      <br />
                      <br />
                      <strong>Accepted At:</strong>
                      <br />
                      {moment()
                        .utc(UserTermSet.get('accepted_at'))
                        .format('MM/DD/YYYY h:mm a')}
                      <br />
                      <br />
                      <strong>Accepted IP:</strong>
                      <br />
                      {UserTermSet.get('accepted_ip')}
                    </p>
                    {renderSignature(UserTermSet)}
                  </div>
                ),
                terms: (
                  <ul>
                    {terms.map((term, idx) => (
                      <li
                        key={`agent-term-set-${UserTermSet.get(
                          'set_id'
                        )}-${idx}`}
                        dangerouslySetInnerHTML={{ __html: term }}
                      ></li>
                    ))}
                  </ul>
                ),
              }
            }),
          }}
          entries={50}
          entriesOptions={[50, 100, 150]}
        />
        {ENABLE_EXPORT && Store.UserTermSets && Store.UserTermSets.length ? (
          [35, 36, 219, 222].indexOf(
            parseInt(UserProfileService.getCurrentUserTypeId(true))
          ) > -1 && (
            <div className="row justify-content-between">
              <div className="pl-3"></div>
              <div className="mr-2">
                <MDBBtn
                  color="primary"
                  onClick={() => Store.exportToCSV('agent_term_stmts_report')}
                >
                  {Store.isExporting ? 'Exporting...' : 'Export CSV'}
                </MDBBtn>
              </div>
            </div>
          )
        ) : (
          <></>
        )}
      </MDBCardBody>
    )
  }

  return (
    <MDBCard className="TermSetReportComponent">
      {renderHeader()}
      {renderBody()}
    </MDBCard>
  )
}

export default observer(TermSetReport)
