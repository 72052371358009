import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { RouterStage } from './core/router'
import { ChangeLogStore, CacheBuster } from './core/versioning'
import SystemSettingStore from './shared/stores/systemSetting.store'
import UserProfileService from './shared/services/UserProfile.service'
import UserService from './shared/services/User.service'
import { AnalyticsTracker, ErrorBoundary } from './hoc'
import InactivityTimer from './hoc/InactivityTimer.component'
import ScrollToTop from './components/shared/ScrollToTop.component'

import './index.scss'

function App() {
  const routeChange = () => {}

  return (
    <CacheBuster>
      {({ isLoading, isLatestVersion, availVersion, runningVersion }) => {
        if (isLoading) return null
        else if (UserProfileService.getUserId() > 0) {
          if (!isLatestVersion && availVersion && runningVersion) {
            console.log(`Version has changed: ${availVersion}. Please refresh`)
            ChangeLogStore.refreshCacheAndReload(availVersion)
          } else if (!isLatestVersion && availVersion) {
            console.log(`Version is new: ${availVersion}. Please refresh`)
            ChangeLogStore.refreshCacheAndReload(availVersion)
          } else if (
            parseInt(UserProfileService.get('completed_enroll')) !== 1
          ) {
            if (/\/[A-Za-z0-9\-]+\/enroll/.test(window.location.pathname)) {
              UserService.hasCompletedEnroll(
                UserProfileService.getUserId()
              ).then((res) => {
                if (res && res?.completed_enroll === true) {
                  UserProfileService.set('completed_enroll', 1)
                  window.location.href =
                    `${UserProfileService.get('has_onboarded')}` === '1'
                      ? '/dashboard'
                      : '/welcome'
                }
              })
            }
          } else {
            console.log(
              `You already have the latest version - ${availVersion}. No refresh needed.`
            )
            ChangeLogStore.fetchChangeLog({
              isLatestVersion,
              availVersion,
              runningVersion,
            })
          }
        }

        SystemSettingStore.load()

        return (
          <ErrorBoundary>
            <InactivityTimer />
            <AnalyticsTracker />
            <Router onChange={routeChange}>
              <ScrollToTop />
              <RouterStage />
            </Router>
          </ErrorBoundary>
        )
      }}
    </CacheBuster>
  )
}

export default App
