import { toast } from 'mdbreact'
import { makeAutoObservable, toJS } from 'mobx'
import AdminApi from './../../api/admin-api/admin-api.js'
import AgencyContentService from './../../shared/services/AgencyContent.service'

class AgencyContentStore {
  pageContent = ''
  pageTitle = ''
  // usertypeId = null;
  loading = false
  // adminArr = [35, 36, 219, 222];
  // isAdmin = false;

  fetching = {}
  content = {}

  constructor() {
    makeAutoObservable(this)
  }

  fetchByContentId = async (id, force) => {
    if (!force) {
      let c = toJS(this.content)
      if (c && c.hasOwnProperty(id)) return true
    }

    this.loading = true

    try {
      const result = await AdminApi.getContentById(id)
      if (result?.data?.data && typeof result.data.data === 'object')
        this.content[id] = result.data.data
      this.loading = false
      return toJS(this.content[id])
    } catch (e) {
      this.loading = false
      toast.error(e.message, { position: toast.POSITION.TOP_RIGHT })
      return false
    }
  }

  fetchPageData = async (id, usertypeId) => {
    // this.usertypeId = usertypeId ? usertypeId : this.usertypeId
    // const findUsertypeId = (element) => element === this.usertypeId
    // this.isAdmin = !!this.adminArr.some(findUsertypeId);
    this.loading = true
    try {
      const result = await AdminApi.getContentById(id)
      this.pageTitle = result?.data?.data?.page_name || ''
      this.pageContent = result?.data?.data?.content || ''
      this.loading = false
      return toJS(this.pageContent)
    } catch (e) {
      this.loading = false
      toast.error(e.message, { position: toast.POSITION.TOP_RIGHT })
      return false
    }
  }

  fetchById = async (id) => {
    if (!id || isNaN(id)) return

    let Row
    this.fetching[id] = true

    try {
      Row = await AgencyContentService.get(id)
    } catch (ex) {
      console.error(`Failed to fetch agency content by id: '${id}'.  ex: ${ex}`)
    }

    this.fetching[id] = false
    if (Row && typeof Row === 'object' && Object.values(Row).length > 0)
      return Row
  }

  fetchBySlugs = async (slugs) => {
    if (!slugs || !Array.isArray(slugs)) return
    if (!slugs.length) return []

    let Rows,
      fetching = {}
    slugs.forEach((slug) => (fetching[slugs] = true))
    this.fetching = { ...this.fetching, ...fetching }

    try {
      Rows = (
        await AgencyContentService.search({
          search: { content_slug: slugs },
          pagination: false,
        })
      ).models
    } catch (ex) {
      console.error(
        `Failed to fetch agency content by slugs: '${slugs.join(
          ', '
        )}'.  ex: ${ex}`
      )
    }

    Object.keys(fetching).forEach((slug) => (fetching[slug] = false))
    this.fetching = { ...this.fetching, ...fetching }
    return Rows && Array.isArray(Rows) ? Rows : []
  }

  fetchBySlug = async (slug) => {
    if (!slug) return

    let Rows
    this.fetching[slug] = true

    try {
      Rows = (
        await AgencyContentService.search({
          search: { content_slug: slug },
          pagination: false,
        })
      ).models
    } catch (ex) {
      console.error(
        `Failed to fetch agency content by slug: '${slug}'.  ex: ${ex}`
      )
    }

    this.fetching[slug] = false
    return Rows && Array.isArray(Rows) ? Rows : []
  }

  fetchByContentSlugs = async ({ contentSlugs, limit }) => {
    limit = limit && !isNaN(limit) ? parseInt(limit) : null
    let results = await this.fetchBySlugs(contentSlugs)
    results =
      limit && Array.isArray(results) ? results.slice(0, limit) : results
    ;(Array.isArray(results) ? results : []).forEach((entry) => {
      if (this.content[entry.id] && toJS(this.content[entry.id]))
        this.content[entry.id] = entry
    })

    let content = {}
    contentSlugs.forEach(
      (contentSlug) =>
        (content[contentSlug] = (Array.isArray(results) ? results : [])
          .filter((row) => row.content_slug === contentSlug)
          ?.shift())
    )

    this.content = { ...this.content, ...content }

    if (content) return content

    return false
  }

  fetchByContentObj = async ({ contentId, contentSlug, limit }) => {
    limit = limit && !isNaN(limit) ? parseInt(limit) : null
    let content

    if (contentId) {
      content = await this.fetchById(contentId)

      // if there is the same content record stored
      // by content slug (match by id), replace the obj.
      if (content && content?.content_slug) {
        let entries = toJS(this.content[content.content_slug])
        if (entries && Array.isArray(entries))
          this.content[content.content_slug] = entries.map((entry) =>
            `${entry?.id}` === `${contentId}` ? content : entry
          )
      }

      this.content[contentId] = await this.fetchById(contentId)
    } else if (contentSlug) {
      let results = await this.fetchBySlug(contentSlug)
      results =
        limit && Array.isArray(results) ? results.slice(0, limit) : results
      ;(Array.isArray(results) ? results : []).forEach((entry) => {
        if (this.content[entry.id] && toJS(this.content[entry.id]))
          this.content[entry.id] = entry
      })

      this.content[contentSlug] = Array.isArray(results) ? results : []
    }

    if (
      contentId &&
      typeof this.content[contentId] === 'object' &&
      this.content[contentId]
    )
      return toJS(this.content[contentId])
    if (contentSlug && Array.isArray(this.content[contentSlug]))
      return toJS(this.content[contentSlug])
    return false
  }
}

export default new AgencyContentStore()
