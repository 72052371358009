import React from 'react'
import LoadingSpinner from './../../shared/LoadingSpinner.component'
import { MDBCollapse, MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import SpecializationSelector from './../SpecializationSelector/SpecializationSelector.component'
import AgentContractingCart from './../AgentContractingCart/AgentContractingCart.component'
import ContractingCheckout from './../ContractingCheckout/ContractingCheckout.component'
import OnboardingCheckout from './../OnboardingCheckout/OnboardingCheckout.component'
import OnboardingWritingNumbers from './../OnboardingWritingNumbers/OnboardingWritingNumbers.component'
import AgentContractEditor from './../AgentContractEditor/AgentContractEditor.component'
import AgentOnboardingCart from './../AgentOnboardingCart/AgentOnboardingCart.component'
import UserProfileService from './../../../shared/services/UserProfile.service'
import ACMStore from './../AgentContractManager.store'
import { toast } from 'react-toastify'

import './AgentContractManager.scss'

class AgentContractManager extends React.Component {
  state = {
    isOnboarding: null,
    preventCheckingOut: false,
    isShopping: this.props.isShopping,
    isLoading: false,
    isCheckingOut: false,
    specStr: '',
  }

  _onSpecChange = () => {
    if (this._getSpecStr() !== this.state.specStr)
      this.setState({ specStr: this._getSpecStr() })
  }

  _renderSpecSelector = () => {
    return (
      <MDBRow>
        <MDBCol size="12" className="mb-5">
          <SpecializationSelector
            Store={this.state.Store}
            inputType={this.props?.specInputType || 'checkbox'}
            isOnboarding={!!this.props?.isOnboarding}
            readOnly={
              this.state.isLoading ||
              this.state.isCheckingOut ||
              ACMStore?.Cart?.get('cart_status') === 'CLOSED'
            }
            onChange={() => this._onSpecChange()}
          />
        </MDBCol>
      </MDBRow>
    )
  }

  _getSpecStr = () => {
    if (this.state.isCheckingOut) return this.state.specStr

    let specs
    try {
      specs = JSON.parse(ACMStore?.Specs?.get('meta_value'))?.requested
    } catch (ex) {
      return ''
    }

    return [
      !!specs?.HEALTH ? 'HEALTH' : false,
      !!specs?.LIFE ? 'LIFE' : false,
      !!specs?.SENIOR ? 'SENIOR' : false,
    ]
      .sort()
      .filter((n) => !!n)
      .join('-')
  }

  _loadCart = async (opts) => {
    // Fetch the Contracting Cart based on User ID.
    await ACMStore.setUserId(UserProfileService.getUserId(), opts)

    // Next, load the dependencies based on the Agent & Cart.
    await ACMStore.loadDepend()

    // ....
    // before letting the components load
    // check if the cart can be/should be completed.
    // this helps to bring past contracting records
    // up-to-date (serving as a fail-safe for old records).
    await ACMStore.reconcileCart()

    return true
  }

  _loadOnboardingCart = async () => {
    this.setState((prevState) => ({
      ...prevState,
      isLoading: true,
      isOnboarding: true,
    }));
  
    try {
      await this._loadCart({ isOnboarding: true });
      this.setState((prevState) => ({
        ...prevState,
        isLoading: false,
        specStr: this._getSpecStr(),
        isCheckingOut: ['ACTIVE', 'CLOSED'].includes(
          ACMStore?.Cart?.get('cart_status')
        ),
      }));
    } catch (error) {
      // Handle any errors if necessary
      console.error("Error loading onboarding cart:", error);
    }
  };

  _loadContractingCart = async () => {
    this.setState((prevState) => ({
      ...prevState,
      isLoading: true,
      isOnboarding: false,
    }));
  
    try {
      await this._loadCart();
      this.setState((prevState) => ({
        ...prevState,
        isLoading: false,
        specStr: this._getSpecStr(),
        isCheckingOut: ACMStore?.Cart?.get('cart_status') === 'ACTIVE',
      }));
    } catch (error) {
      // Handle any errors if necessary
      console.error("Error loading contracting cart:", error);
    }
  };

  _renderOnboardingCart = () => {
    if (this.props.validationKey === 'requested')
      return (
        <MDBRow>
          <MDBCol size="12" className="contract-cart-list">
            <AgentOnboardingCart
              activeSpecs={this.state.specStr}
              onCheckout={async (Carriers) => {
                this.setState(
                  (prevState) => ({
                    ...prevState,
                    isCheckingOut: true,
                    isLoading: true,
                  }),
                  () => {
                    ACMStore.checkout({ Carriers })
                      .then(() =>
                        this.setState((prevState) => ({
                          ...prevState,
                          isLoading: false,
                        }))
                      )
                      .catch((error) => {
                        this.setState((prevState) => ({
                          ...prevState,
                          isCheckingOut: false,
                          isLoading: false,
                        }))
                        toast.error(error.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        })
                      })
                  }
                )
              }}
            />
          </MDBCol>
        </MDBRow>
      )
    else if (this.props.validationKey === 'completed') {
      if (
        !!ACMStore?.Cart &&
        (ACMStore?.Cart?.isNew() === true || !isNaN(ACMStore?.Cart?.id()))
      )
        return (
          <MDBRow>
            <MDBCol size="12" className="contract-cart-list">
              <OnboardingWritingNumbers
                onValidityChange={this.props.onValidityChange}
                activeSpecs={this.state.specStr}
              />
            </MDBCol>
          </MDBRow>
        )
    }
  }

  _renderContractingCart = () => {
    return (
      <MDBRow>
        <MDBCol size="12" className="contract-cart-list">
          <AgentContractingCart
            activeSpecs={this.state.specStr}
            onCheckout={async (Carriers) => {
              this.setState(
                (prevState) => ({
                  ...prevState,
                  isCheckingOut: true,
                  isLoading: true,
                }),
                () => {
                  ACMStore.checkout({ Carriers }).finally(() =>
                    this.setState((prevState) => ({
                      ...prevState,
                      isLoading: false,
                    }))
                  )
                }
              )
            }}
          />
        </MDBCol>
      </MDBRow>
    )
  }

  _renderCart = () => {
    return !!this.state.isOnboarding
      ? this._renderOnboardingCart()
      : this._renderContractingCart()
  }

  _renderOnboardingCheckout = () => {
    if (this.props.validationKey === 'requested')
      return (
        <OnboardingCheckout onValidityChange={this.props.onValidityChange} />
      )
  }

  _renderContractingCheckout = () => {
    return (
      <>
        <ContractingCheckout
          onValidityChange={this.props.onValidityChange}
          onCheckingOutToggle={(isCheckingOut) => {
            if (
              typeof isCheckingOut === 'boolean' &&
              isCheckingOut !== this.state.isCheckingOut
            )
              this.setState({ isCheckingOut })
          }}
        />
      </>
    )
  }

  _renderCheckout = () =>
    !!this.state.isOnboarding
      ? this._renderOnboardingCheckout()
      : this._renderContractingCheckout()

  componentDidMount() {
    if (!!this.props?.isOnboarding) this._loadOnboardingCart()
    // .finally(() => console.log("loading 'onboarding' complete."))
    else this._loadContractingCart()
    // .finally(() => console.log("loading 'contracting' complete."))
  }

  render() {
    return (
      <MDBContainer id="AgentContractManager" fluid>
        {this._renderSpecSelector()}
        <MDBRow>
          <MDBCol
            size="12"
            className={
              'mb-5 acm-body-pane ' + (this.state.isLoading ? 'is-loading' : '')
            }
          >
            <div className="acm-loading-wrapper">
              <LoadingSpinner
                size="md"
                isActive={this.state.isLoading}
                label={
                  this.state.isCheckingOut
                    ? 'Preparing Your Carrier Contracts'
                    : 'Getting Contracts'
                }
              />
            </div>
            <div className="acm-content-wrapper">
              <MDBCollapse
                isOpen={
                  !this.state.isCheckingOut ||
                  (this.state.isCheckingOut &&
                    this.props.validationKey === 'completed')
                }
              >
                {this._renderCart()}
              </MDBCollapse>
              <MDBCollapse isOpen={this.state.isCheckingOut}>
                {this._renderCheckout()}
              </MDBCollapse>
            </div>
          </MDBCol>
        </MDBRow>
        <AgentContractEditor />
      </MDBContainer>
    )
  }
}

export default AgentContractManager
