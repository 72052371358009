import BaseModelFactory from './base.factory'
import CarrierContractLinkService from './../services/CarrierContractLink.service'
import CarrierContractLink from './../models/carrier-contract-link.model'

class CarrierContractLinkFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: CarrierContractLinkService, Model: CarrierContractLink }
    )
  static findAll = async () =>
    await this._findAllBy(
      {},
      { Service: CarrierContractLinkService, Model: CarrierContractLink }
    )
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: CarrierContractLinkService, Model: CarrierContractLink }
    )
  static findAllById = async (id) =>
    await this._findAllBy(
      { id: id },
      { Service: CarrierContractLinkService, Model: CarrierContractLink }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: CarrierContractLinkService,
      Model: CarrierContractLink,
    })
}

export default CarrierContractLinkFactory
