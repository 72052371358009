import React from 'react'
import { observer } from 'mobx-react-lite'
import ContentHtml from '../../../components/content/ContentHtml/ContentHtml.component'
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact'

const PointsBreakdown = ({ coverages, isLoading }) => {
  const formatNumber = (points, pos) => {
    points = `${parseFloat(points)}`.split('.')
    let remainder = points.length === 1 ? '000' : points.pop().substr(0, 3)

    for (let i = pos - `${remainder}`.length; i > 0; i--) remainder += '0'

    return [points, remainder].join('.')
  }

  return (
    <MDBCard narrow id="PointsBreakdownComponent" className="points-component">
      <MDBCardBody>
        <MDBCardTitle>Points Details</MDBCardTitle>
        <MDBRow>
          <MDBCol size="12" className="pip-details">
            <ContentHtml contentId="49" />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12">
            <table className="points-payout">
              <thead>
                <tr>
                  <th>Market</th>
                  <th>Coverage</th>
                  <th>Point Factor</th>
                  <th>Fixed Points</th>
                </tr>
              </thead>
              <tbody>
                {coverages.map((coverage) => (
                  <tr key={'coverage-points-' + coverage.id}>
                    <td>{coverage.coverage_category}</td>
                    <td>{coverage.coverage_name}</td>
                    <td>
                      {formatNumber(
                        parseFloat(coverage.points.factor1) *
                          parseInt(coverage.points.factor2),
                        3
                      )}
                    </td>
                    <td>{coverage.points.setpoints || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  )
}

export default observer(PointsBreakdown)
