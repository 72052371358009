import React from 'react'
import { observer } from 'mobx-react-lite'
import Store from '../store'
import env from '../../../environments/environment'
import { CarrierPrioritiesDataTable } from './CarrierPrioritiesDataTable.component'
import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact'

const CarrierPrioritiesTable = () => {
  const _buildRow = (CarrierPriority) => {
    return {
      logo: CarrierPriority.Carrier.get('c_img') ? (
        <div className="carrier-logo-container">
          <img
            src={`https://firebasestorage.googleapis.com/v0/b/${
              env.integrations.firebase.config.storageBucket
            }/o/${encodeURIComponent(
              CarrierPriority.Carrier.get('c_img').includes('carriers/')
                ? CarrierPriority.Carrier.get('c_img')
                : 'carriers/' + CarrierPriority.Carrier.get('c_img')
            )}?alt=media`}
            alt={CarrierPriority.Carrier.get('c_name')}
          />
        </div>
      ) : (
        <></>
      ),
      name: CarrierPriority.Carrier.get('c_name'),
      description: CarrierPriority.Carrier.get('c_description'),
    }
  }
  const _getTableData = () => {
    return Store.filteredCarrierPriorities.map((cp) => {
      return {
        ...cp,
        data: cp.data.map(_buildRow),
      }
    })
  }
  const _renderDatatable = (priorities, idx) => {
    return (
      <MDBCard key={idx} className="mb-4">
        <MDBCardHeader>{priorities.header}</MDBCardHeader>
        <MDBCardBody>
          <CarrierPrioritiesDataTable priorities={priorities.data} />
        </MDBCardBody>
      </MDBCard>
    )
  }
  return _getTableData().map(_renderDatatable)
}

export default observer(CarrierPrioritiesTable)
