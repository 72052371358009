import AdminApi from "./../../api/admin-api/admin-api";


const getByUser = async (userId, strict = false) => 
{
	return new Promise((resolve, reject) => {
		AdminApi
				.getPermissions(userId, strict?"user":"all")
				.then(result => {
						try 				{ result = result.data.data.map(r => r.name); }
						catch (ex) 	{ result = [];  															}
						resolve(result);
					})
				.catch(error => reject(error.message));
	});
}

const getByUsertype = async (usertypeId) => 
{
	return new Promise((resolve, reject) => {
		AdminApi
				.getPermissions(usertypeId, "usertype")
				.then(result => {
						try 				{ result = result.data.data.map(r => r.name); }
						catch (ex) 	{ result = [];  															}
						resolve(result);
					})
				.catch(error => reject(error.message));
	});
}


/*
* canUser: Determines if user has access bet requested permission key(s)
*
* Arguments:
* 	`permission` 	(string|array<string>) identifies the permissions being evaluated.
* 	`userId` 			(string|int) identifies the user to evaluate.
* 	`andOr` 			if true, all permissions arguments must be true.  if false, only 1 permission argument must be true.
*/
const canUser = async (permission, userId, andOr = false) =>
{
	let userPermissions 		= 		await getByUser(userId);

	permission 							=			(Array.isArray(permission) ? permission : [permission]).filter(p => p);
	if (!permission)
		return 											false;

	if (Array.isArray(userPermissions) && !andOr)
		return 											permission.filter(p => userPermissions.indexOf(p) > -1).length > 0;

	if (Array.isArray(userPermissions) && andOr)
		return 											permission.filter(p => userPermissions.indexOf(p) > -1).length === permission.length;

	throw new Error("Unknown error occured while attempting to invoke PermissionService.getByUser() in PermissionService.canUser().");
}

const getUserTypes = async () =>
{
	return new Promise((resolve, reject) => {
		AdminApi
				.getUserTypes()
				.then(result => {
						try 				{ result = result.data.data; 	}
						catch (ex) 	{ result = [];  							}
						resolve(result);
					})
				.catch(error => reject(error.message));
	});
}


const PermissionService = {
	getByUser: 										getByUser,
	getByUsertype: 								getByUsertype,
	canUser: 											canUser,
	getUserTypes: 								getUserTypes
};

export default PermissionService;
