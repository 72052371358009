import React, { useState } from 'react'
import { MDBSwitch } from 'mdbreact'
import UserExternalCarrierFactory from './../../../shared/factories/user-external-carrier.factory.js'
import UserProfileService from '../../../shared/services/UserProfile.service'
import ACMStore from './../AgentContractManager.store'

import './ExternalCarrierToggle.scss'

const searchById = async (carrier_id) => {
  let UEC
  try {
    UEC = await UserExternalCarrierFactory.search({
      search: { user_id: UserProfileService.getUserId(), carrier_id },
    })
  } catch (error) {
    console.error(
      'An error occurred while attempting to search external carrier data: ',
      error
    )
  }
  return (UEC && Array.isArray(UEC?.models) ? UEC.models : []).shift()
}

const ExternalCarrierToggle = ({
  ExternalCarrier,
  carrier_id,
  onToggle,
  disabled,
}) => {
  const [isChecked, setIsChecked] = useState(!!ExternalCarrier),
    [isToggling, setIsToggling] = useState(false)

  const fetchUEC = async () => {
    if (ExternalCarrier) return ExternalCarrier

    ExternalCarrier = ACMStore.ExternalCarriers.filter(
      (UEC) => parseInt(UEC.get('carrier_id')) === parseInt(carrier_id)
    ).shift()
    if (ExternalCarrier) return ExternalCarrier

    if (carrier_id) return await searchById(carrier_id)
  }

  const activator = async (UEC) => {
    UEC = UEC
      ? UEC
      : await UserExternalCarrierFactory.create({
          user_id: UserProfileService.getUserId(),
          carrier_id,
        })
    if (UEC.isNew()) await UEC.save()
    ACMStore.add('ExternalCarriers', UEC)
    setIsChecked(true)
  }

  const deactivator = async (UEC) => {
    ACMStore.remove('ExternalCarriers', UEC)
    UEC.delete()
    setIsChecked(false)
  }

  const toggle = async (activate) => {
    if (isNaN(carrier_id) || carrier_id === null) return
    setIsToggling(true)
    activate
      ? await activator(await fetchUEC())
      : await deactivator(await fetchUEC())
    if (typeof onToggle === 'function') onToggle(!!activate)
    setIsToggling(false)
  }

  return (
    <div
      className={
        'ExternalCarrierToggleComponent ' +
        (isToggling ? 'is-toggling' : '') +
        (disabled ? 'is-disabled' : '')
      }
    >
      <span className="uec-spinner">
        <i className="fa fa-spin fa-spinner" />
      </span>
      <MDBSwitch
        disabled={isToggling || !!disabled}
        labelLeft={''}
        labelRight={''}
        checked={isChecked}
        onChange={() => toggle(!isChecked)}
      />
    </div>
  )
}

export default ExternalCarrierToggle
