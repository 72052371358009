import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import Store from './../store'
import { MDBCol, MDBRow } from 'mdbreact'
import { convertToCurrency } from './../../../shared/formatters/convertToCurrency.formatter'

import './AgentWalletPreview.component.scss'

const AgentWalletPreview = () => {
  const getUsabgValue = () => {
    const id = Store.AgentLedger?.id()
    return id && Store.metricsLoaded[id] === true
      ? Store.metrics?.[id] || {}
      : {}
  }

  const metricsExist =
    Store.AgentLedger?.isNew() === false &&
    Store.metricsLoaded[Store.AgentLedger.id()] === true

  if (Store.AgentLedger?.isNew() === false) {
    if (!Store.metricsLoaded[Store.AgentLedger.id()]) Store.getLedgerMetrics()
  }

  const { cumm_bonus, ytd_bonus } = getUsabgValue()
  if (!metricsExist) return <></>

  return (
    <div id="AgentWalletPreviewComponent">
      <MDBRow>
        <MDBCol size="12" md="4">
          <div className="wallet-table">
            <div>My Wallet Balance</div>
            <div>
              {Store.AgentLedger?.isNew() === false ? (
                convertToCurrency(
                  Store.AgentLedger?.get('balance') !== null &&
                    !isNaN(Store.AgentLedger.get('balance'))
                    ? Store.AgentLedger.get('balance')
                    : 0
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        </MDBCol>
        {metricsExist &&
        (typeof ytd_bonus === 'number' || !isNaN(ytd_bonus)) ? (
          <MDBCol size="12" md="4">
            <div className="ytd-table">
              <div>YTD Bonuses!</div>
              <div>{convertToCurrency(ytd_bonus)}</div>
            </div>
          </MDBCol>
        ) : (
          <></>
        )}
        {metricsExist &&
        (typeof cumm_bonus === 'number' || !isNaN(cumm_bonus)) ? (
          <MDBCol size="12" md="4">
            <div className="all-time-table">
              <div>All-Time Bonuses!</div>
              <div>{convertToCurrency(cumm_bonus)}</div>
            </div>
          </MDBCol>
        ) : (
          <></>
        )}
      </MDBRow>
    </div>
  )
}

export default observer(AgentWalletPreview)
