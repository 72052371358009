import React, { Component } from 'react'
import {
  MDBCol,
  MDBSelect,
  MDBContainer,
  MDBRow,
  MDBInput,
  MDBBtn,
} from 'mdbreact'
import { Subscription } from 'rxjs'
import { toast } from 'react-toastify'
import TrainingsPageService from './../../shared/TrainingsPage.service'

import './TrainingVideoForm.scss'

const outputOpts = [
  { text: 'Video Player', value: 'player' },
  { text: 'External Link', value: 'external_link' },
]

const TRAINING_FILTER_OPTS = {
  spec: ['HEALTH', 'SENIOR', 'LIFE'],
  onboarding: ['sig_agent'], //, 'associate_agent', 'affiliate_agent']
}

class TrainingVideoForm extends Component {
  state = {
    trainingTypes: [],
    errorsExist: false,
    saving: false,
    training: {
      id: null,
      training_name: '',
      training_link: '',
      training_type_id: null,
      training_order: '',
      training_code: '',
      training_age: null,
      training_output: '',
      training_filters: {
        spec: [],
      },
      is_required: false,
      requires_quiz: false,
    },
    order: {
      from: null,
      to: null,
    },
  }

  _errors = {}
  __subscriptions$ = new Subscription()

  componentDidMount() {
    this.__subscriptions$.add(
      TrainingsPageService.fetchTrainingTypes().subscribe((types) =>
        this._setTrainingTypes(types)
      )
    )

    if (
      this.props.training &&
      typeof this.props.training === 'object' &&
      Object.keys(this.props.training).length
    ) {
      this.setState({
        training: {
          ...this.props.training,
          training_filters: this.props.training.training_filters
            ? typeof this.props.training.training_filters === 'string'
              ? JSON.parse(this.props.training.training_filters)
              : this.props.training.training_filters
            : { spec: [] },
        },
        order: {
          from: this.props.training?.training_order || 999,
          to: this.props.training?.training_order || 999,
        },
      })
    }
  }

  componentWillUnmount() {
    this.__subscriptions$.unsubscribe()
  }

  _setTrainingTypes = (types) => this.setState({ trainingTypes: types })

  _renderErrorField = (field) => {
    if (field && this._errors.hasOwnProperty(field) && this.state.errorsExist)
      return <span className="error-msg">{this._errors[field]}</span>
    return <></>
  }

  _onChange = (event) => {
    const field = event.target.name,
      order = this.state.order

    if (
      (typeof this.state.training[field] === 'object' &&
        JSON.stringify(this.state.training[field]) ===
          JSON.stringify(event.target.value)) ||
      (typeof this.state.training[field] !== 'object' &&
        `${this.state.training[field]}` === `${event.target.value}`)
    )
      return

    let training = { ...this.state.training }
    training[field] = event.target.value

    if (field === 'training_code') {
      training.is_required =
        training[field] && `${training[field]}`.trim().length > 0
          ? parseInt(training.is_required) === 0
            ? 1
            : training.is_required
          : parseInt(training.is_required) === 1
          ? 0
          : training.is_required
    } else if (field === 'training_order') {
      if (training[field] && !isNaN(training[field]))
        order.to = parseInt(training[field])
    }

    this.setState({ training, order })
  }

  _isValid = () => {
    let errors = {}
    Object.keys(this.state.training).forEach((field) => {
      switch (field) {
        case 'training_name':
        case 'training_link':
        case 'training_type_id':
        case 'training_output':
          if (
            !this.state.training[field] ||
            !`${this.state.training[field]}`.trim().length
          )
            errors[field] = 'Required.'
          break
        case 'training_order':
          if (
            !this.state.training[field] ||
            !`${this.state.training[field]}`.trim().length
          )
            break
          else if (
            !new RegExp(/^\d+$/).test(`${this.state.training[field]}`.trim())
          )
            errors[field] = 'Invalid.'
          break
        case 'training_code':
          if (
            !this.state.training[field] ||
            !`${this.state.training[field]}`.trim().length
          )
            break
          if (`${this.state.training[field]}`.trim().length < 6)
            errors[field] = 'Too short.'
          else if (`${this.state.training[field]}`.trim().length > 6)
            errors[field] = 'Too long.'
          else if (
            !new RegExp(/^[A-Za-z0-9]{6}$/).test(
              `${this.state.training[field]}`.trim()
            )
          )
            errors[field] = 'Invalid.'
          break
        case 'training_age':
          if (
            this.state.training[field] === null ||
            this.state.training[field] === ''
          )
            break
          if (this.state.training[field] <= 0) {
            errors[field] = 'Days to Retain can not be 0 or negative.'
            break
          }
          if (!Number.isInteger(this.state.training[field] * 1)) {
            errors[field] = 'Days to Retain should be an integer.'
            break
          }
          break
        default:
          break
      }
    })

    this._errors = errors

    if (Object.keys(errors).length === 0) {
      if (this.state.errorsExist) this.setState({ errorsExist: false })
      return true
    }

    if (!this.state.errorsExist) this.setState({ errorsExist: true })

    return false
  }

  _onSubmit = () => {
    if (!this._isValid()) return

    this.setState({ saving: true })
    try {
      parseInt(this.state.training.id) > 0
        ? TrainingsPageService.updateTraining(
            this.state.training.id,
            {
              ...this.state.training,
              training_filters: JSON.stringify({
                ...(typeof this.props.training.training_filters === 'string'
                  ? JSON.parse(this.props.training.training_filters)
                  : this.props.training.training_filters),
                ...this.state.training.training_filters,
              }),
            },
            this.state.order.from !== this.state.order.to
              ? this.state.order
              : null
          )
        : TrainingsPageService.createTraining({
            ...this.state.training,
            training_filters: JSON.stringify(
              this.state.training.training_filters
            ),
          })
      toast.success('Training video saved.', {
        position: toast.POSITION.TOP_RIGHT,
      })
    } catch (ex) {
      toast.error(
        'An error occurred attempting to save the training video.  ' + ex,
        { position: toast.POSITION.TOP_RIGHT }
      )
    }

    this.setState({ saving: false })
    return !!false
  }

  render() {
    const { saving, training } = this.state

    return (
      <MDBContainer id="TrainingVideoForm">
        <MDBRow>
          <MDBCol size="12" md="4">
            <MDBInput
              label="Training Video Title"
              name="training_name"
              value={training.training_name}
              onChange={this._onChange}
              required
            />
            {this._renderErrorField('training_name')}
          </MDBCol>
          <MDBCol size="12" md="4">
            <MDBSelect
              label="Training Category"
              search
              options={this.state.trainingTypes.map((type) => ({
                text: type.training_type_name,
                value: `${type.id}`,
                checked:
                  parseInt(type.id) === parseInt(training.training_type_id),
              }))}
              name="training_type_name"
              value={training.training_type_id}
              getValue={(val) =>
                this._onChange({
                  target: { name: 'training_type_id', value: val[0] },
                })
              }
              required
            />
            {this._renderErrorField('training_type_id')}
          </MDBCol>
          <MDBCol size="12" md="4">
            <MDBSelect
              label="Training Filters"
              multiple
              options={Object.keys(TRAINING_FILTER_OPTS)
                .map((filter) => {
                  return TRAINING_FILTER_OPTS[filter].map((opt) => {
                    switch (filter) {
                      case 'spec':
                        return {
                          text: `Agent Spec: ${opt}`,
                          value: opt,
                          checked: training.training_filters.spec
                            ? training.training_filters.spec.includes(opt)
                            : false,
                        }
                      default:
                        return {
                          text: `${
                            filter.charAt(0).toUpperCase() +
                            filter.toLowerCase().substr(1)
                          }: ${opt.replace('_', ' ').toUpperCase()}`,
                          value: opt,
                          checked: training.training_filters.onboarding
                            ? training.training_filters.onboarding.includes(opt)
                            : false,
                        }
                    }
                  })
                })
                .flat()}
              name="training_filters"
              value={[
                ...(training.training_filters.spec || []),
                ...(training.training_filters.onboarding || []),
              ]}
              getValue={(val) => {
                this._onChange({
                  target: {
                    name: 'training_filters',
                    value: {
                      spec: val.filter((v) =>
                        TRAINING_FILTER_OPTS.spec.includes(v)
                      ),
                      onboarding: val.filter((v) =>
                        TRAINING_FILTER_OPTS.onboarding.includes(v)
                      ),
                    },
                  },
                })
              }}
            />
            {this._renderErrorField('training_filters')}
          </MDBCol>
          <MDBCol size="12" md="2">
            <MDBSelect
              label="Is Required"
              search
              options={[
                {
                  text: 'No',
                  value: '0',
                  checked: parseInt(training?.is_required) !== 1,
                },
                {
                  text: 'Yes',
                  value: '1',
                  checked: parseInt(training?.is_required) === 1,
                },
              ]}
              name="is_required"
              getValue={(v) => {
                v = Array.isArray(v) && parseInt(v.shift())
                if (
                  training &&
                  parseInt(training?.is_required) !== v &&
                  (v === 1 || v === 0)
                )
                  this._onChange({
                    target: { name: 'is_required', value: v },
                  })
              }}
              required
            />
            {this._renderErrorField('is_required')}
          </MDBCol>
          <MDBCol size="12" md="2">
            <MDBSelect
              label="Requires Quiz"
              search
              options={[
                {
                  text: 'No',
                  value: '0',
                  checked: parseInt(training?.requires_quiz) !== 1,
                },
                {
                  text: 'Yes',
                  value: '1',
                  checked: parseInt(training?.requires_quiz) === 1,
                },
              ]}
              name="requires_quiz"
              getValue={(v) => {
                v = Array.isArray(v) && parseInt(v.shift())
                if (
                  training &&
                  parseInt(training?.requires_quiz) !== v &&
                  (v === 1 || v === 0)
                )
                  this._onChange({
                    target: { name: 'requires_quiz', value: v },
                  })
              }}
              required
            />
            {this._renderErrorField('requires_quiz')}
          </MDBCol>
          <MDBCol size="12" md="2">
            <MDBInput
              label="Video Code"
              name="training_code"
              value={training.training_code}
              onChange={this._onChange}
            />
            {this._renderErrorField('training_code')}
          </MDBCol>
          {parseInt(training?.is_required) !== 1 ? (
            <MDBCol size="12" md="4">
              <MDBInput
                type="number"
                label="Days to Retain"
                name="training_age"
                value={training.training_age}
                onChange={this._onChange}
              />
              {this._renderErrorField('training_age')}
            </MDBCol>
          ) : (
            <></>
          )}
        </MDBRow>
        <MDBRow>
          <MDBCol size="12" md="6">
            <MDBInput
              label="Training Link"
              name="training_link"
              value={training.training_link}
              onChange={this._onChange}
              required
            />
            {this._renderErrorField('training_link')}
          </MDBCol>
          <MDBCol size="12" md="3">
            <MDBSelect
              label="State"
              search
              options={outputOpts.map((i) => ({
                ...i,
                checked: i.value === training.training_output,
              }))}
              name="training_output"
              value={training.training_output}
              getValue={(v) => {
                v = Array.isArray(v) ? v.shift() : v
                if (!v) return
                if (training.training_output !== v)
                  this._onChange({
                    target: { name: 'training_output', value: v },
                  })
              }}
              required
            />
            {this._renderErrorField('training_output')}
          </MDBCol>
          <MDBCol size="12" md="3">
            <MDBInput
              label="Video Order"
              name="training_order"
              value={training.training_order}
              onChange={this._onChange}
              required
            />
            {this._renderErrorField('training_order')}
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12">
            <MDBBtn
              className="btn-block"
              color="unique"
              type="button"
              onClick={() => this._onSubmit()}
            >
              {saving ? 'Saving...' : 'Save'}
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default TrainingVideoForm
