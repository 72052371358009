import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import {
  MDBContainer,
  MDBListGroup,
  MDBListGroupItem,
  MDBRow,
  MDBCol,
} from 'mdbreact'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'

class InternalResources extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      resourcesList: [],
    }
  }

  componentDidMount() {
    this.fetchResourcesData()
  }

  fetchResourcesData = async () => {
    this.setState({ loading: true })
    AdminApi.getAdminResources()
      .then((result) => {
        if (result && result.data && result.data.data) {
          this.setState({ resourcesList: result.data.data })
        }
      })
      .then(() => {
        this.setState({ loading: false })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  render() {
    toast.configure()
    const { loading, resourcesList } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              <MDBRow>
                <MDBCol size="12">
                  <h2>Internal Resources</h2>
                  <hr />
                </MDBCol>
                <MDBCol lg="8">
                  {loading ? (
                    <h2>Loading...</h2>
                  ) : (
                    <>
                      <MDBListGroup>
                        {resourcesList && resourcesList.length ? (
                          resourcesList.map((item, i) => {
                            return (
                              <MDBListGroupItem key={item.id}>
                                <a
                                  href={item.resource_link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {item.resource_name}
                                </a>
                              </MDBListGroupItem>
                            )
                          })
                        ) : (
                          <MDBListGroupItem>N/A</MDBListGroupItem>
                        )}
                      </MDBListGroup>
                    </>
                  )}
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default InternalResources
