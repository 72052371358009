import React, { Component } from 'react'
import { MDBListGroupItem } from 'mdbreact'

import './MilestoneWidget.scss'
import { autoLogin, getAgentAccessToHeadshot } from '../../../../shared/utilities/getHeadshot.function'

class MilestoneWidget extends Component {
  render() {
    return (
      <MDBListGroupItem
        className={`MilestoneWidget ${this.props.className || ''}`}
      >
        <table>
          <tbody>
            <tr>
              <td valign="middle">
                {this.props.data.data.map((i, x) => (
                  <div key={this.props.name + '-' + x}>{i}</div>
                ))}
              </td>
              <td valign="middle">
                <div
                  className={this.props.data.user_id && getAgentAccessToHeadshot(this.props.data.user_id) ? "img-wrapper cursor-pointer" : "img-wrapper"}
                  onClick={() => {
                    if(this.props.data.user_id && getAgentAccessToHeadshot(this.props.data.user_id)) autoLogin(this.props.data.user_id)
                  }}
                >
                  <img
                    src={this.props.data.image}
                    className="mx-auto d-block img-fluid"
                    alt=""
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </MDBListGroupItem>
    )
  }
}

export default MilestoneWidget
