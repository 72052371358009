import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { MDBBtn, MDBIcon, MDBAlert } from 'mdbreact' // , MDBAlert, MDBAlertHeader, MDBAlertBody
import ACMStore from './../AgentContractManager.store'
import UserProfileService from './../../../shared/services/UserProfile.service'
import { getMetaValue } from './../getMetaValue.function'
import ContractStore from './../Contract.store'
import UsersCarrierContractFactory from './../../../shared/factories/users-carrier-contract.factory'
import { toast } from 'react-toastify'

import './SendToCarrierButton.scss'

const SendToCarrierButton = ({ Contract, onViewDeliveries }) => {
  const [outcome, setOutcome] = useState(null)

  const contractId = Contract && Contract?.id()

  const isLoading = () =>
    contractId > 0 && ContractStore.isLoading.indexOf(contractId) > -1

  const isSending = () =>
    contractId > 0 && ContractStore.isSending.indexOf(contractId) > -1

  const viewHistoryBtn = () => 
  {
    if (onViewDeliveries && typeof onViewDeliveries === 'function') {
      return (
          <MDBBtn className="history-btn" onClick={onViewDeliveries}>
            <MDBIcon icon="question" /><MDBIcon far icon="envelope-open" />
          </MDBBtn>
        )
    }

    return <></>
  }

  const buttonWrapper = (contents, canViewHistory) =>
  {
    if (canViewHistory) {
      return contents && typeof contents === 'string' ? (
        buttonWrapper(<MDBAlert color="secondary">{contents}</MDBAlert>, canViewHistory)
      ) : (
        <div className="SendToCarrierButtonComponent">{contents}{canViewHistory?viewHistoryBtn():''}</div>
      )      
    }

    return contents && typeof contents === 'string' ? (
      buttonWrapper(<MDBAlert color="secondary">{contents}</MDBAlert>, canViewHistory)
    ) : (
      <div className="SendToCarrierButtonComponent">{contents}{canViewHistory?viewHistoryBtn():''}</div>
    )
  }


  const success = () => (
    <MDBAlert color="success">
      <i className="fa fa-check"></i>&nbsp;Sent!
    </MDBAlert>
  )

  const error = () => (
    <MDBAlert color="danger">
      <i className="fa fa-times"></i>&nbsp;Failed!
    </MDBAlert>
  )

  const loadingSpinner = () => (
    <MDBAlert>
      <i className="fa fa-spin fa-spinner"></i>&nbsp;Loading...
    </MDBAlert>
  )

  const sendingSpinner = () => (
    <MDBAlert>
      <i className="fa fa-spin fa-spinner"></i>&nbsp;Sending...
    </MDBAlert>
  )

  const send = async () => {
    let result, error
    try {
      result = await ContractStore.send(Contract)
      if (result && result !== true) {
        error = result
        result = false
      } else result = !!result
    } catch (ex) {
      error = `${ex}`
      result = false
    }

    if (error)
      toast.error(`${error}`.replace(/error:/gi, '').trim(), {
        position: toast.POSITION.TOP_RIGHT,
      })

    setOutcome(!!result)

    if (result === true) {
      try {
        Contract.reload().then(async (Contract) =>
          ACMStore.set(
            'contracts',
            await UsersCarrierContractFactory.create(Contract)
          )
        )
      } catch (ex) {}
    }

    const timer = setTimeout(() => {
      setOutcome(null)
      clearTimeout(timer)
    }, 5000)
  }

  const button = (contractType) => {
    return (
      <MDBBtn className="send-btn" onClick={() => send(Contract)}>
        <MDBIcon icon="paper-plane" />
        &nbsp;
        <span>
          {Contract.get('pwk_sent_to_carrier_at') ? 'Resend' : 'Send'}&nbsp;
          {contractType}
        </span>
      </MDBBtn>
    )
  }

  const carrierId = Contract && Contract?.get('carrier_id'),
    isAdmin =
      carrierId &&
      UserProfileService.isA(['system-admin', 'internal-admin'], true),
    disposition = isAdmin && Contract?.get('disposition'),
    agreementId = isAdmin && Contract?.get('agreement_id'),
    isLegacy = Contract?.get('contract_id') === 'legacy',
    isSalesTracker = Contract?.get('contract_id') === 'sales_tracker',
    isOverride =
      Contract?.get('contract_id') === 'override' &&
      !!Contract?.get('agreement_id')

  useEffect(() => {
    if (
      carrierId &&
      !isNaN(carrierId) &&
      !['unsent', 'pending', 'expired'].includes(disposition)
    )
      ContractStore.fetchCarrierContract(carrierId, contractId)
  }, [carrierId, disposition])

  // Notify user of send attempt results
  if (typeof outcome === 'boolean')
    return buttonWrapper(outcome ? success() : error(), false)

  // Return empty button wrapper if invalid carrier id OR invalid disposition.
  if (!carrierId || !disposition || ['unset', 'pending'].includes(disposition))
    return buttonWrapper(null, false)

  // Return spinners if we've started fetching/sending content.
  if (isLoading() || isSending())
    return buttonWrapper(isLoading() ? loadingSpinner() : sendingSpinner(), false)

  // Prevent sending non-sendable contract IDs
  if (isLegacy) return buttonWrapper(`Can't send legacy contracts.`, false)
  if (isSalesTracker)
    return buttonWrapper(`Can't send sales-tracker (incomplete) contracts.`,false)

  // Make sure an adobe agreement exists.
  if (!agreementId && !isOverride)
    return buttonWrapper(`Can't send non-Adobe contracts.`,false)

  // If the contract type is not sendable, stop & report.
  const contractType = isOverride
    ? 'PDF'
    : ContractStore.contractType(carrierId)
  if (contractType && !['ACR', 'PDF'].includes(contractType))
    return buttonWrapper(
      contractType
        ? `Can't send ${contractType} contracts.`
        : `Can't send this contract type.`,
      false
    )

  return buttonWrapper(button(contractType), true)
}

export default observer(SendToCarrierButton)
