import React, { useState } from 'react'
import { observer } from 'mobx-react-lite';
import { MDBSwitch } from 'mdbreact'
import ACMStore from './../AgentContractManager.store'

import './ElectiveCarrierToggle.scss'

const ElectiveCarrierToggle = ({
  carrier_id,
  onToggle,
  disabled,
  isExternal
}) => {

  const isChecked = !!isExternal || ACMStore.CartItems.filter(CI => parseInt(CI.get('carrier_id')) === parseInt(carrier_id)).length > 0;

  return (
    <div
      className={
        'ElectiveCarrierToggleComponent ' + (disabled ? 'is-disabled' : '')
      }
    >
      <span className="uec-spinner">
        <i className="fa fa-spin fa-spinner" />
      </span>
      <MDBSwitch
        disabled={!!disabled}
        labelLeft={''}
        labelRight={''}
        checked={isChecked}
        onChange={() => onToggle(!isChecked)}
      />
    </div>
  )
}

export default observer(ElectiveCarrierToggle)
