import BaseModelFactory from './base.factory'
import UserContractCartService from './../services/UserContractCart.service'
import UserContractCart from './../models/user-contract-cart.model'

class UserContractCartFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: UserContractCartService, Model: UserContractCart }
    )
  static findById = async (cartId) =>
    await this._findBy(
      { id: cartId },
      { Service: UserContractCartService, Model: UserContractCart }
    )
  static findAllByUserId = async (userId) =>
    await this._findAllBy(
      { user_id: userId },
      { Service: UserContractCartService, Model: UserContractCart }
    )
  static findByUserId = async (userId) =>
    await this._findBy(
      { user_id: userId },
      { Service: UserContractCartService, Model: UserContractCart }
    )
  static findOpenByUserId = async (userId) =>
    await this._findBy(
      { user_id: userId, cart_status: 'OPEN' },
      { Service: UserContractCartService, Model: UserContractCart }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: UserContractCartService,
      Model: UserContractCart,
    })
}

export default UserContractCartFactory
