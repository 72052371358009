import React, { Component } from 'react'
import { MDBModalHeader, MDBModalBody, MDBBtn, MDBModal } from 'mdbreact'
import moment from 'moment'
import UsersCarrierContractService from './../../../../shared/services/UsersCarrierContract.service'
import CarrierService from './../../../../shared/services/Carrier.service'
import CarrierContractService from './../../../../shared/services/CarrierContract.service'

import './CarrierDocDatesSetter.scss'

const formatDateFields = (date) => {
  if (
    (date = date
      ? moment(moment.utc(new Date(date)).toDate())
          .local()
          .format('h:mm a|M/DD/YYYY')
          .split('|')
      : '') &&
    Array.isArray(date)
  ) {
    return (
      <>
        {date.shift()}
        <br />
        {date.shift()}
      </>
    )
  }
}

class CarrierDocDatesSetter extends Component {
  state = {
    isFetching: false,
    isOpen: false,
    contracts: [],
  }

  #toggleModal = () => {
    const { isOpen } = this.state

    if (!isOpen) this.#fetchContractsData()
    else this.setState({ isOpen: !this.state.isOpen, updatedList: [] })
  }

  #fetchContractsData = async () => {
    const getContracts = async () =>
        this.props.id
          ? (
              await UsersCarrierContractService.search({
                search: { user_id: this.props.id },
                pagination: false,
              })
            )?.models
          : [],
      getCarriers = async (carrierIds) =>
        carrierIds.length
          ? (
              await CarrierService.search({
                search: { id: carrierIds },
                pagination: false,
              })
            )?.models
          : [],
      getContractTypes = async (carrierIds) =>
        carrierIds.length
          ? (
              await CarrierContractService.search({
                search: { carrier_id: carrierIds },
                pagination: false,
              })
            )?.models
          : []

    if (this.state.isFetching === false) this.setState({ isFetching: true })

    let contracts = [],
      carriers = [],
      types = []

    try {
      contracts = await getContracts()
      carriers = await getCarriers(
        Array.isArray(contracts)
          ? contracts.map((Contract) => Contract.carrier_id)
          : []
      )
      types = await getContractTypes(
        Array.isArray(contracts)
          ? contracts.map((Contract) => Contract.carrier_id)
          : []
      )
    } catch (ex) {
      contracts = false
    }

    if (contracts === false) {
      this.setState({ isFetching: false })
    } else {
      contracts = contracts.map((contract) => {
        contract.carrier = carriers
          .filter((carrier) => `${carrier.id}` === `${contract.carrier_id}`)
          .shift()

        if (contract.carrier) {
          contract.description = contract.carrier?.c_description
          contract.carrier = contract.carrier?.c_name
        }

        contract.contract_type = types
          .filter((type) => `${type.carrier_id}` === `${contract.carrier_id}`)
          .shift()?.contract_type

        contract.pwk_sent_to_agent_at = formatDateFields(
          contract.pwk_sent_to_agent_at
        )
        contract.pwk_signed_at = formatDateFields(contract.pwk_signed_at)
        contract.pwk_sent_to_carrier_at = formatDateFields(
          contract.pwk_sent_to_carrier_at
        )
        contract.pwk_completed_at = formatDateFields(contract.pwk_completed_at)

        return contract
      })

      contracts = {
        acr: contracts.filter(
          (c) => c.contract_type === 'ACR' && c.disposition !== 'expired'
        ),
        pdf: contracts.filter(
          (c) => c.contract_type === 'PDF' && c.disposition !== 'expired'
        ),
        link: contracts.filter(
          (c) => c.contract_type === 'LINK' && c.disposition !== 'expired'
        ),
      }

      this.setState({
        contracts,
        isOpen:
          contracts.acr.length + contracts.pdf.length + contracts.link.length >
          0,
        isFetching: false,
      })
    }
  }

  #renderTable = () => {
    let tables = []

    if (this.state.contracts?.pdf && this.state.contracts.pdf.length > 0) {
      tables.push(
        <table
          className="carrier-contracts-setter-table"
          key={'contract-modal-table-pdf'}
        >
          <thead>
            <tr>
              <th colSpan="6">PDF Contracts</th>
            </tr>
            <tr>
              <th>Carrier</th>
              <th>Agent Requested Pwk At</th>
              <th>Agent Signed PDF</th>
              <th>Pwk Sent to Carrier</th>
              <th>Agent Completed</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.contracts.pdf.map((contract, idx) => (
              <tr
                key={'pdf-modal-row-' + idx}
                className={
                  contract.pwk_completed_at ? 'alert alert-success' : ''
                }
              >
                <td>
                  {contract.carrier}
                  <br />
                  <small>{contract.description}</small>
                  {contract.pwk_completed_at ? (
                    contract?.agent_code ? (
                      <>
                        <br />
                        <span className="agent-writing-code">
                          Agent Code: {contract.agent_code}
                        </span>
                      </>
                    ) : (
                      <>
                        <br />
                        <span className="agent-writing-code-missing">
                          Agent Code Missing!
                        </span>
                      </>
                    )
                  ) : (
                    <></>
                  )}
                </td>
                <td>{contract.pwk_sent_to_agent_at}</td>
                <td>{contract.pwk_signed_at}</td>
                <td>{contract.pwk_sent_to_carrier_at}</td>
                <td>{contract.pwk_completed_at}</td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    }

    if (this.state.contracts?.acr && this.state.contracts.acr.length > 0) {
      tables.push(
        <table
          className="carrier-contracts-setter-table"
          key={'contract-modal-table-acr'}
        >
          <thead>
            <tr>
              <th colSpan="6">ACR Contracts</th>
            </tr>
            <tr>
              <th>Carrier</th>
              <th>Agent Requested Pwk At</th>
              <th>Agent Signed ACR</th>
              <th>Pwk Sent to Carrier</th>
              <th>Agent Completed</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.contracts.acr.map((contract, idx) => (
              <tr
                key={'acr-modal-row-' + idx}
                className={
                  contract.pwk_completed_at ? 'alert alert-success' : ''
                }
              >
                <td>
                  {contract.carrier}
                  <br />
                  <small>{contract.description}</small>
                  {contract?.agent_code ? (
                    <>
                      <br />
                      <span className="agent-writing-code">
                        Agent Code: {contract?.agent_code}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
                <td>{contract.pwk_sent_to_agent_at}</td>
                <td>{contract.pwk_signed_at}</td>
                <td>{contract.pwk_sent_to_carrier_at}</td>
                <td>{contract.pwk_completed_at}</td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    }

    if (this.state.contracts?.link && this.state.contracts.link.length > 0) {
      tables.push(
        <table
          className="carrier-contracts-setter-table"
          key={'contract-modal-table-link'}
        >
          <thead>
            <tr>
              <th colSpan="6">LINK Contracts</th>
            </tr>
            <tr>
              <th>Carrier</th>
              <th>Agent Requested Pwk At</th>
              <th>Agent Clicked Contract Link</th>
              <th>Pwk Sent to Carrier</th>
              <th>Agent Completed</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.contracts.link.map((contract, idx) => (
              <tr
                key={'link-modal-row-' + idx}
                className={
                  contract.pwk_completed_at ? 'alert alert-success' : ''
                }
              >
                <td>
                  {contract.carrier}
                  <br />
                  <small>{contract.description}</small>
                  {contract?.agent_code ? (
                    <>
                      <br />
                      <span className="agent-writing-code">
                        Agent Code: {contract?.agent_code}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
                <td>{contract.pwk_sent_to_agent_at}</td>
                <td>{contract.pwk_signed_at}</td>
                <td>{contract.pwk_sent_to_carrier_at}</td>
                <td>{contract.pwk_completed_at}</td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    }

    return tables
  }

  render() {
    return (
      <>
        <div className="CarrierDocDatesSetterComponent">
          <MDBBtn
            size="sm"
            className="btn-block modal-trigger"
            disabled={this.state.isFetching}
            color="indigo"
            onClick={this.#toggleModal}
          >
            {this.state.isFetching ? (
              <i className="fa fa-spin fa-spinner"></i>
            ) : (
              this.props.label
            )}
          </MDBBtn>
          <MDBModal
            size="lg"
            disableBackdrop={true}
            isOpen={this.state.isOpen}
            toggle={this.#toggleModal}
          >
            <MDBModalHeader color="indigo" toggle={this.#toggleModal}>
              {this.props.name + "'s "} Contracting
            </MDBModalHeader>
            <MDBModalBody>
              {this.state.isFetching ? (
                <h2>Loading...</h2>
              ) : (
                this.#renderTable()
              )}
            </MDBModalBody>
          </MDBModal>
        </div>
      </>
    )
  }
}

export default CarrierDocDatesSetter
