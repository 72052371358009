import React from 'react'
import { observer } from 'mobx-react-lite'
import MetricsIntakeStore from './../../store'
import { MDBRow, MDBCol, MDBInput } from 'mdbreact'

import './PerformanceForm.scss'

const PerformanceForm = () => {
  return (
    <form id="PerformanceForm">
      <MDBRow className="metric-intake">
        <MDBCol size="10">
          <label className="text-indigo" htmlFor="hours_worked">
            Number of Hours Worked:
          </label>
          <br />
          <span>{MetricsIntakeStore.kpiDefs?.hours_worked}</span>
        </MDBCol>
        <MDBCol size="2">
          <MDBInput
            type="text"
            value={MetricsIntakeStore.performance?.hours_worked || '0'}
            name="hours_worked"
            onChange={MetricsIntakeStore.onMetricChange}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="metric-intake">
        <MDBCol size="10">
          <label className="text-indigo" htmlFor="calls">
            Number of Calls Completed:
          </label>
          <br />
          <span>{MetricsIntakeStore.kpiDefs?.calls}</span>
        </MDBCol>
        <MDBCol size="2">
          <MDBInput
            type="text"
            name="calls"
            value={MetricsIntakeStore.performance?.calls || '0'}
            onChange={MetricsIntakeStore.onMetricChange}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="metric-intake">
        <MDBCol size="10">
          <label className="text-indigo" htmlFor="events_attended">
            Number of Events Attended:
          </label>
          <br />
          <span>{MetricsIntakeStore.kpiDefs?.events_attended}</span>
        </MDBCol>
        <MDBCol size="2">
          <MDBInput
            type="text"
            name="events_attended"
            value={MetricsIntakeStore.performance?.events_attended || '0'}
            onChange={MetricsIntakeStore.onMetricChange}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="metric-intake">
        <MDBCol size="10">
          <label className="text-indigo" htmlFor="spoken_to">
            Number of Customers Spoken To:
          </label>
          <br />
          <span>{MetricsIntakeStore.kpiDefs?.spoken_to}</span>
        </MDBCol>
        <MDBCol size="2">
          <MDBInput
            type="text"
            name="spoken_to"
            value={MetricsIntakeStore.performance?.spoken_to || '0'}
            onChange={MetricsIntakeStore.onMetricChange}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="metric-intake">
        <MDBCol size="10">
          <label className="text-indigo" htmlFor="fact_finders">
            Number of Fact Finders Collected:
          </label>
          <br />
          <span>{MetricsIntakeStore.kpiDefs?.fact_finders}</span>
        </MDBCol>
        <MDBCol size="2">
          <MDBInput
            type="text"
            name="fact_finders"
            value={MetricsIntakeStore.performance?.fact_finders || '0'}
            onChange={MetricsIntakeStore.onMetricChange}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="metric-intake">
        <MDBCol size="10">
          <label className="text-indigo" htmlFor="appts_set">
            Appointments Set:
          </label>
          <br />
          <span>{MetricsIntakeStore.kpiDefs?.appts_set}</span>
        </MDBCol>
        <MDBCol size="2">
          <MDBInput
            type="text"
            name="appts_set"
            value={MetricsIntakeStore.performance?.appts_set || '0'}
            onChange={MetricsIntakeStore.onMetricChange}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="metric-intake">
        <MDBCol size="10">
          <label className="text-indigo" htmlFor="presentations_offered">
            Number of Presentations Offered:
          </label>
          <br />
          <span>{MetricsIntakeStore.kpiDefs?.presentations_offered}</span>
        </MDBCol>
        <MDBCol size="2">
          <MDBInput
            type="text"
            name="presentations_offered"
            value={MetricsIntakeStore.performance?.presentations_offered || '0'}
            onChange={MetricsIntakeStore.onMetricChange}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="metric-intake">
        <MDBCol size="10">
          <label className="text-indigo" htmlFor="referrals_requested">
            Number of Referrals Requested:
          </label>
          <br />
          <span>{MetricsIntakeStore.kpiDefs?.referrals_requested}</span>
        </MDBCol>
        <MDBCol size="2">
          <MDBInput
            type="text"
            name="referrals_requested"
            value={MetricsIntakeStore.performance?.referrals_requested || '0'}
            onChange={MetricsIntakeStore.onMetricChange}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="metric-intake">
        <MDBCol size="10">
          <label className="text-indigo" htmlFor="life_quoted">
            Number of Life Insurance Quotes:
          </label>
          <br />
          <span>{MetricsIntakeStore.kpiDefs?.life_quoted}</span>
        </MDBCol>
        <MDBCol size="2">
          <MDBInput
            type="text"
            name="life_quoted"
            value={MetricsIntakeStore.performance?.life_quoted || '0'}
            onChange={MetricsIntakeStore.onMetricChange}
          />
        </MDBCol>
      </MDBRow>
    </form>
  )
}

export default observer(PerformanceForm)
