import React, { useEffect, useState } from 'react'
import { MDBBtn } from 'mdbreact'
import UserProfileService from '../../../../shared/services/UserProfile.service'
import { UserMetaFactory } from '../../../../shared/factories'
import { AwardListModal } from '../AwardListModal/AwardListModal'

export const ViewAwardButton = () => {
  const isAdmin = UserProfileService.isA(
    ['system-admin', 'agency-owner', 'internal-admin', 'internal-staff'],
    true
  )
  const [isLoading, setIsLoading] = useState(true)
  const [awardData, setAwardData] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const fetchUserMeta = async () => {
    const UserMeta = (
      await UserMetaFactory.search({
        search: {
          user_id: UserProfileService.getUserId(),
          meta_key: 'profile---pip-ack',
        },
        pagination: false,
      })
    ).shift()
    if (UserMeta && typeof UserMeta.get('meta_value') === 'string') {
      setAwardData(JSON.parse(UserMeta.get('meta_value')))
    }
    setIsLoading(false)
  }
  useEffect(() => {
    fetchUserMeta()
  }, [])
  const openAwardModal = async () => {
    setIsOpen(true)
  }

  if (!isAdmin) return <></>

  return (
    <>
      <MDBBtn
        disabled={isLoading}
        className="btn-block"
        onClick={() => openAwardModal()}
        sm="true"
        color="danger"
      >
        {isLoading ? 'Loading ...' : 'View Milestone Awards'}
      </MDBBtn>
      <AwardListModal data={awardData} show={isOpen} onClose={setIsOpen} />
    </>
  )
}
