import BaseModelInstance from './base.model'
import { ReferralPartnerW9 as ModelDefinition } from './model-definitions'
import ReferralPartnersW9Service from './../services/ReferralPartnersW9.service'

class ReferralPartnerW9 extends BaseModelInstance {
  afterSet = (key, value) => {}

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }

  download = async () => {
    if (!this.isNew()) {
      try {
        return await ReferralPartnersW9Service.download(this.id())
      } catch (ex) {
        console.error(`${ex}`)
        return false
      }
    }

    return
  }
}

export default ReferralPartnerW9

// const imgUrl = 'https://raw.githubusercontent.com/ianare/exif-samples/master/jpg/Canon_40D.jpg'
// const getBase64Img = async () => {
//   const image = await axios.get(imgUrl, { responseType: "arraybuffer" })
//   return 'data:image/jpeg;base64,' + Buffer.from(image.data).toString('base64')
// }
