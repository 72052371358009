import React, { Component, Fragment } from 'react'
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBSpinner,
} from 'mdbreact'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'

import './AddResourceTypePage.scss'

class AddResourceTypePage extends Component {
  state = {
    loading: false,
    breakWidth: 1300,
    windowWidth: 0,
    rt_name: '',
    resourcetype_id: '',
    errorMessage: '',
    formError: false,
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params })
    if (params && params.id) this.fetchResourceTypeData(params.id)
  }

  #onChange = (event) =>
    this.setState({ [event.target.name]: event.target.value })

  fetchResourceTypeData = async (id) => {
    this.setState({ loading: true })
    AdminApi.getResourcesTypeById(id)
      .then((result) => {
        this.setState({ loading: false })
        if (result && result.data && result.data.data) {
          const content = result.data.data
          this.setState({
            rt_name: content.rt_name,
          })
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  validate = async () => {
    const { rt_name } = this.state
    if (!rt_name || !rt_name.trim()) {
      this.setState({
        formError: true,
        errorMessage: 'Resource Type Required!',
      })
      return
    }
    this.setState({ formError: false, errorMessage: '' })
  }

  createResourceType = async () => {
    await this.validate()
    const { errorMessage, formError, loading, rt_name } = this.state
    const id = this.state.urlParams.id
    if (loading) {
      return
    }

    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }

    const data = {
      rt_name: rt_name,
      table_name: 'resource_types',
    }
    if (id) {
      data['id'] = id
    }

    let form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })

    AdminApi.createResourceType(form_data)
      .then((result) => {
        this.setState({ loading: false })
        this.props.history.push('/admin-resourceType')
        toast.success(
          `Resource Type ${id ? 'updated' : 'created'} successfully`,
          { position: toast.POSITION.TOP_RIGHT }
        )
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  render() {
    toast.configure()
    const { urlParams, loading } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main id="AddResourceTypePage" className="mainSection">
            <MDBContainer fluid className="mt-5">
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>
                  <a href="/admin-resourceType">List</a>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>
                  {!!(urlParams && urlParams.id)
                    ? 'Edit Resource Type'
                    : 'Add Resource Type'}
                </MDBBreadcrumbItem>
              </MDBBreadcrumb>
              <h2>
                {!!(urlParams && urlParams.id)
                  ? 'Edit Resource Type'
                  : 'Add Resource Type'}
              </h2>
              <hr />
              {loading ? (
                <div className="loadingOverlay">
                  <h4 className={'loader-text p4'}>
                    <MDBSpinner />
                  </h4>
                </div>
              ) : (
                ''
              )}
              <MDBRow>
                <MDBCol size="6">
                  <form className="content-wrapper">
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="rt_name">
                          Resource Type
                        </label>
                        <input
                          type="text"
                          className="form-control text-capitalize"
                          name="rt_name"
                          id="rt_name"
                          value={this.state.rt_name}
                          onChange={(event) => {
                            this.setState({ rt_name: event.target.value })
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-row text-center mt-4">
                      <MDBBtn
                        color="unique"
                        type="button"
                        className="btn-block"
                        onClick={this.createResourceType}
                      >
                        {loading ? 'Saving...' : 'Save'}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AddResourceTypePage
