import React, { Component, Fragment } from 'react'
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBSpinner,
} from 'mdbreact'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'

class AddPoints extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      coverage_id: '',
      carrier_id: '',
      factor1: '',
      factor2: '',
      setpoints: '',
      formError: false,
      errorMessage: '',
      coveragesList: [],
      carriersList: [],
    }
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params })

    this.fetchCoverages()
  }

  //Change event for input fields
  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  validate = async () => {
    const { coverage_id, carrier_id, factor1, factor2 } = this.state

    if (!coverage_id || !parseInt(coverage_id)) {
      this.setState({ formError: true, errorMessage: 'Coverage Required' })
      return
    }

    if (!carrier_id || !parseInt(carrier_id)) {
      this.setState({ formError: true, errorMessage: 'Carrier Required' })
      return
    }

    if (!(factor1 && factor1.trim())) {
      this.setState({ formError: true, errorMessage: 'Factor 1 Required' })
      return
    }

    if (!(factor2 && factor2.trim())) {
      this.setState({ formError: true, errorMessage: 'Factor 2 Required' })
      return
    }

    this.setState({ formError: false, errorMessage: '' })
  }

  //create points
  createPoint = async () => {
    await this.validate()
    const {
      coverage_id,
      carrier_id,
      factor1,
      factor2,
      setpoints,
      loading,
      formError,
      errorMessage,
    } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }
    const data = {
      coverage_id: coverage_id,
      carrier_id: carrier_id,
      factor1: factor1,
      factor2: factor2,
      table_name: 'points',
    }

    if (setpoints && setpoints.trim()) {
      data['setpoints'] = setpoints
    }

    const form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })

    AdminApi.createPoint(form_data)
      .then((result) => {
        this.setState({ loading: false })
        this.props.history.push('/admin-points')
        toast.success('Point created successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchPointsData = async (id) => {
    AdminApi.getPointById(id)
      .then((result) => {
        this.setState({ loading: false })
        if (result && result.data && result.data.data) {
          const content = result.data.data
          this.setState({
            coverage_id: content.coverage_id,
            carrier_id: content.carrier_id,
            factor1: content.factor1,
            factor2: content.factor2,
            setpoints: content.setpoints,
          })
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  updatePoint = async () => {
    await this.validate()
    const id = this.state.urlParams.id
    const {
      coverage_id,
      carrier_id,
      factor1,
      factor2,
      setpoints,
      loading,
      formError,
      errorMessage,
    } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }
    const data = {
      coverage_id: coverage_id,
      carrier_id: carrier_id,
      factor1: factor1,
      factor2: factor2,
      setpoints: setpoints ? setpoints.trim() : '',
      table_name: 'points',
      id: id,
    }

    const form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })
    AdminApi.updatePoint(form_data)
      .then((result) => {
        this.setState({ loading: false })
        this.props.history.push('/admin-points')
        toast.success('Point updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchCoverages = async () => {
    this.setState({ loading: true })
    AdminApi.getCoverages()
      .then((result) => {
        if (result && result.data && result.data.data) {
          const content = result.data.data
          this.setState({
            coveragesList: content,
          })
        }
        this.fetchCarriers()
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchCarriers = async () => {
    const {
      match: { params },
    } = this.props
    AdminApi.getCarriers()
      .then((result) => {
        if (result && result.data && result.data.data) {
          const content = result.data.data
          this.setState({
            carriersList: content,
          })

          if (params && params.id) {
            this.fetchPointsData(params.id)
          } else {
            this.setState({ loading: false })
          }
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  render() {
    toast.configure()
    const {
      coverage_id,
      carrier_id,
      factor1,
      factor2,
      setpoints,
      coveragesList,
      carriersList,
      urlParams,
      loading,
    } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>
                  <a href="/admin-points">List</a>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>
                  {!!(urlParams && urlParams.id) ? 'Edit Points' : 'Add Points'}
                </MDBBreadcrumbItem>
              </MDBBreadcrumb>
              <h2>
                {!!(urlParams && urlParams.id) ? 'Edit Points' : 'Add Points'}
              </h2>
              <hr />
              {loading ? (
                <div className="loadingOverlay">
                  <h4 className={'loader-text p4'}>
                    <MDBSpinner />
                  </h4>
                </div>
              ) : (
                ''
              )}
              <MDBRow>
                <MDBCol size="4">
                  <form className="content-wrapper">
                    <label className="grey-text" htmlFor="coverage_id">
                      Coverages
                    </label>
                    <select
                      className="form-control"
                      name="coverage_id"
                      id="coverage_id"
                      value={coverage_id}
                      onChange={this.onChange}
                    >
                      <option value="0">Select Coverages</option>
                      {coveragesList.length > 0 &&
                        coveragesList.map((item, i) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.coverage_name}
                            </option>
                          )
                        })}
                    </select>
                    <br />

                    <label className="grey-text" htmlFor="carrier_id">
                      Carrier
                    </label>
                    <select
                      className="form-control"
                      name="carrier_id"
                      id="carrier_id"
                      value={carrier_id}
                      onChange={this.onChange}
                    >
                      <option value="0">Select Carriers</option>
                      {carriersList.length > 0 &&
                        carriersList.map((item, i) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.c_name}
                            </option>
                          )
                        })}
                    </select>
                    <br />

                    <label className="grey-text" htmlFor="factor1">
                      Factor 1
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="factor1"
                      id="factor1"
                      value={factor1}
                      onChange={this.onChange}
                    />

                    <label className="grey-text" htmlFor="factor2">
                      Factor 2
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="factor2"
                      id="factor2"
                      value={factor2}
                      onChange={this.onChange}
                    />

                    <label className="grey-text" htmlFor="setpoints">
                      Set Points
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="setpoints"
                      id="setpoints"
                      value={setpoints}
                      onChange={this.onChange}
                    />

                    <div className="text-center mt-4">
                      <MDBBtn
                        color="unique"
                        type="button"
                        className="btn-block"
                        onClick={() => {
                          urlParams && urlParams.id
                            ? this.updatePoint()
                            : this.createPoint()
                        }}
                      >
                        {loading ? 'Saving...' : 'Save'}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AddPoints
