import React, { useState } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import DraggableList from './../../../../components/shared/DraggableList/DraggableList.component'
import { UIInput } from './../../../../components/forms/form-fields'
import { WebsiteInfoStore } from './../../stores'
import { MDBIcon, MDBContainer, MDBRow, MDBCol, MDBCollapse } from 'mdbreact'

import './MenuBuilder.component.scss'

const isJson = (str) => {
  try {
    JSON.parse(str)
    return true
  } catch (ex) {
    return false
  }
}

const MenuBuilder = ({ linkType }) => {
  const [isFormOpen, setIsFormOpen] = useState(false),
    [showValidity, setShowValidity] = useState(false),
    [newLink, setNewLink] = useState({ url: '', displayname: '' }),
    [links, setLinks] = useState(null),
    [isSaving, setIsSaving] = useState(false)

  const getInstance = () => {
    switch (linkType) {
      case 'navigation':
        return WebsiteInfoStore.MenuSiteLinks
      case 'resources':
        return WebsiteInfoStore.ResourceSiteLinks
      default:
        return {}
    }
  }

  const getType = () => {
    switch (linkType) {
      case 'navigation':
        return 'Menu'
      case 'resources':
        return 'Resource'
      default:
        return ''
    }
  }

  if (!Array.isArray(links) && getInstance()) {
    if (
      getInstance()?.get('links') &&
      (typeof getInstance().get('links') === 'object' ||
        isJson(getInstance().get('links')))
    )
      setLinks(
        typeof getInstance().get('links') === 'string'
          ? JSON.parse(getInstance().get('links'))
          : getInstance().get('links')
      )
  }

  const getIds = () =>
    Array.isArray(links)
      ? links.map((l) => l?.id).filter((l) => l && !isNaN(l))
      : []

  const getMaxId = () => Math.max([...getIds()]) || 0

  const createOrUpdate = (link) => {
    // update
    if (link?.id)
      setLinks((prevLinks) => {
        prevLinks = prevLinks.map((l) =>
          `${l.id}` === `${link.id}` ? link : l
        )
        if (JSON.stringify(prevLinks) !== getInstance().get('links'))
          getInstance().set('links', JSON.stringify(prevLinks))
        return prevLinks
      })
    // add
    else {
      link.id = getMaxId() + 1
      setLinks((prevLinks) => {
        prevLinks = (Array.isArray(prevLinks) ? prevLinks : []).concat([link])
        if (JSON.stringify(prevLinks) !== getInstance().get('links'))
          getInstance().set('links', JSON.stringify(prevLinks))
        return prevLinks
      })
    }
  }

  const saveLinks = async () => {
    setIsSaving(true)
    if (JSON.stringify(links) !== getInstance().get('links'))
      getInstance().set('links', JSON.stringify(links))
    await getInstance().save()
    setIsSaving(false)
  }

  const editLink = (link) => {
    setNewLink(link)
    setShowValidity(false)
    setIsFormOpen(true)
  }

  const deleteLink = async (link) => {
    setLinks((prevLinks) => {
      prevLinks = prevLinks.filter((l) => `${l.id}` !== `${link.id}`)
      if (JSON.stringify(prevLinks) !== getInstance().get('links'))
        getInstance().set('links', JSON.stringify(prevLinks))
      return prevLinks
    })
  }

  const isDisabled = isSaving || !getInstance()?.hasChanged

  return (
    <MDBContainer id="MenuBuilderComponent" className="container-fluid mt-5">
      <MDBRow>
        <MDBCol className="list-header">
          <h5 className="text--blue font--lato fw--500">
            Agent Subsite {getType()} Links
          </h5>
          {isFormOpen ? (
            <span>
              <button
                onClick={() => {
                  setNewLink({ url: '', displayname: '' })
                  setShowValidity(false)
                  setIsFormOpen(false)
                }}
                className="btn btn-sm add-link-btn close"
              >
                <MDBIcon icon="times" />
                &nbsp;Close
              </button>
            </span>
          ) : (
            <span>
              <button
                onClick={() => setIsFormOpen(true)}
                className="btn btn-sm add-link-btn"
              >
                <MDBIcon icon="plus" />
                &nbsp;Add Link
              </button>
            </span>
          )}
        </MDBCol>
      </MDBRow>
      <MDBCollapse isOpen={isFormOpen}>
        <div className="link-form">
          <MDBRow>
            <MDBCol size="12" sm="5">
              <UIInput
                name="displayname"
                label="Link Label"
                value={newLink.displayname}
                rules={{ required: true, minLength: 2 }}
                onChange={(evt) =>
                  setNewLink((link) => ({
                    ...link,
                    [evt.target.name]: evt.target.value,
                  }))
                }
                showValidity={showValidity}
                required
              />
            </MDBCol>
            <MDBCol size="12" sm="5">
              <UIInput
                name="url"
                label="Link URL"
                value={newLink.url}
                rules={{ required: true, minLength: 2 }}
                onChange={(evt) =>
                  setNewLink((link) => ({
                    ...link,
                    [evt.target.name]: evt.target.value,
                  }))
                }
                showValidity={showValidity}
                required
              />
            </MDBCol>
            <MDBCol size="12" sm="2">
              <button
                className="btn btn-sm"
                onClick={() => {
                  if (newLink.displayname && newLink.url) {
                    createOrUpdate(newLink)
                    setNewLink({ url: '', displayname: '' })
                    setShowValidity(false)
                    setIsFormOpen(false)
                  } else {
                    setShowValidity(true)
                  }
                }}
              >
                <MDBIcon icon="arrow-right" />
              </button>
            </MDBCol>
          </MDBRow>
        </div>
      </MDBCollapse>
      <MDBRow>
        <MDBCol>
          <div className="list-col">
            {Array.isArray(links) ? (
              <DraggableList
                onReorder={(links) => {
                  setLinks(links.map((l) => l.link))
                  getInstance().set(
                    'links',
                    JSON.stringify(links.map((l) => l.link))
                  )
                }}
                items={(links || []).map((link, idx) => ({
                  id: `link-${idx}`,
                  link: { ...link, id: `link-${idx}` },
                  content: (
                    <>
                      <table className="menu-link-row" width="100%">
                        <tbody>
                          <tr>
                            <td>{link.displayname}</td>
                            <td>
                              {isFormOpen ? (
                                <></>
                              ) : (
                                <button
                                  className="alert-warning"
                                  onClick={() => editLink(link)}
                                >
                                  <MDBIcon icon="edit" />
                                </button>
                              )}
                            </td>
                            <td>
                              {isFormOpen ? (
                                <></>
                              ) : (
                                <button
                                  className="alert-danger"
                                  onClick={() => deleteLink(link)}
                                >
                                  <MDBIcon icon="trash" />
                                </button>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ),
                }))}
              />
            ) : (
              <></>
            )}
          </div>
        </MDBCol>
      </MDBRow>
      <br />
      <MDBRow>
        <MDBCol>
          <button
            disabled={isDisabled}
            onClick={() => saveLinks()}
            className="btn btn-sm btn-block btn-success"
          >
            {isSaving ? (
              <>
                <i className="fa fa-spin fa-spinner"></i>&nbsp;Saving{' '}
                {getType()} Links
              </>
            ) : isDisabled ? (
              <>No Changes</>
            ) : (
              <>
                <MDBIcon icon="save" />
                &nbsp;Save {getType()} Links
              </>
            )}
          </button>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(MenuBuilder)
