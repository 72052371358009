import React from 'react'
import Navbar from './../../components/newhome/Navbar'
import Banner from './../../components/newhome/Banner'
import Footer from './../../components/newhome/Footer'
import {MDBContainer,MDBRow,MDBCol} from 'mdbreact';

import './PrivacyPolicy.page.scss';

class PrivacyPolicyPage extends React.Component {
  render () 
  {
    return (
      <div id="PrivacyPolicyPage" className="page">
        <Navbar />
        <Banner pageTitle='Privacy Policy' />
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <p className='mt-5'>At USA Benefits Group (USABG.com), we respect the privacy of our customers and all visitors to our system. USA Benefits Group is committed to ensuring the security of personal data and providing full disclosure with regard to information gathering and dissemination practices related to the USA Benefits Group system. This Privacy Statement explains how personal information about users of the system is collected, used, shared and safeguarded.</p>
              <p>USA Benefits Group has established this Privacy Policy to protect and manage our customers' personal information. USA Benefits Group may revise the Privacy Policy at any time by updating this posting. You should visit this page periodically to review this Privacy Policy.</p>
              <br />
              <ul className='privacy-policy-list'>
                <li className='primary'>
                  1. INFORMATION AUTOMATICALLY LOGGED AND ACCOUNTABILITY<br />
                  USA Benefits Group may collect, at the time of your registration and your sign-on to its system, certain "personal information" (information that personally identifies you) such as, but not limited to, your name, email address, mailing address, telephone number, and information about your computer hardware and software (e.g. IP address, operating system, browser type, domain name, URL, access times, and referring system addresses).<br />
                  <br />
                  USA Benefits Group has implemented this Privacy Policy to protect personal information received from its customers, and to respond to any inquiries and complaints as required by law. The Privacy Policy also provides that USA Benefits Group will use appropriate contractual means to establish a comparable level of protection for personal information, which is sent for processing by third parties on USA Benefits Group's behalf.<br />
                </li>
                <li className='primary'>
                  2. IDENTIFIED PURPOSES<br />
                  USA Benefits Group collects and uses personal information for the following Identified Purposes:<br />
                  <ul>
                    <li className='secondary'>
                      (a) to understand customer needs regarding USA Benefits Group's services;
                    </li>
                    <li className='secondary'>
                      (b) to develop and provide products and services for USA Benefits Group's customers;
                    </li>
                    <li className='secondary'>
                      (c) to communicate with customers, and to inform customers of upgrades, products and services available from USA Benefits Group and its affiliates;
                    </li>
                    <li className='secondary'>
                      (d) to allow customers to access limited-entry areas of USA Benefits Group's site;
                    </li>
                    <li className='secondary'>
                      (e) to personalize some of USA Benefits Group's services and products for you;
                    </li>
                    <li className='secondary'>
                      (f) to bill accounts and maintain payment records;
                    </li>
                    <li className='secondary'>
                      (g) to satisfy any applicable law, regulation, legal process or government request;
                    </li>
                    <li className='secondary'>
                      (h) to respond to a legitimate claim that you are violating the rights of any third party;
                    </li>
                    <li className='secondary'>
                      (i) to protect the services, products or rights of USA Benefits Group; and
                    </li>
                    <li className='secondary'>
                      (j) to identify and resolve technical problems concerning USA Benefits Group's products and services.
                    </li>
                  </ul>
                  USA Benefits Group also uses personal information in aggregate form for business analysis, operational, marketing and other promotional purposes. If we hire other companies to provide products or services on our behalf, we will only provide those companies the personal information they need for the Identified Purposes.
                </li>
                <li className='primary'>
                  3. CONSENT FOR COLLECTION, USE AND DISCLOSURE<br />
                  Registration, for USA Benefits Group products and services, by a customer, will constitute the customer's consent for USA Benefits Group to collect, use and disclose personal information under this Privacy Policy. USA Benefits Group will normally seek a customer's consent for any new uses and disclosures of the customer's personal information, either at the same time it collects the personal information or after it has been collected but before it is used or disclosed for a new purpose. In certain circumstances however, USA Benefits Group will not be required to inform and obtain the consent of the customer, such as for the investigation of a breach of an agreement, contravention of laws, an emergency where the life, health or security of an individual is threatened, or the collection of a debt.<br />
                  <br />
                  A customer may withdraw such consent for collection, use and disclosure at any time, on prior written notice, provided that USA Benefits Group may on such withdrawal suspend or terminate its products and services.
                </li>
                <li className='primary'>
                  4. LIMITS ON THE COLLECTION OF PERSONAL INFORMATION<br />
                  USA Benefits Group limits its collection of personal information to only that information which is necessary for the Identified Purposes.<br />
                  <br />
                  USA Benefits Group may use cookies to tailor the content of the system to your interests and, if you so choose, to save your personal information so that USA Benefits Group may automate your next visit. In addition USA Benefits Group may use cookies to track USA Benefits Group sales made to customers referred by members of our Marketing Partner Program websites. If you choose to not have your browser accept cookies from the USA Benefits Group system, you may not be able to view all the text on the screens, or to experience a personalized visit, or to subscribe to certain service and product offerings on the USA Benefits Group site.
                </li>
                <li className='primary'>
                  5. DISCLOSURE, PROCESSING AND RETENTION<br />
                  USA Benefits Group does not sell, rent or disclose your personal information to anyone else except, when the Identified Purposes require USA Benefits Group to disclose the personal information to:<br />
                  <ul>
                    <li className='secondary'>
                      (a) an agent of the customer;
                    </li>
                    <li className='secondary'>
                      (b) agents of USA Benefits Group acting on its behalf for the Identified Purposes;
                    </li>
                    <li className='secondary'>
                      (c) a public authority, to avoid or minimize danger to life or property; and
                    </li>
                    <li className='secondary'>
                      (d) a third party to whom disclosure is required by any law, regulation, legal process or government request.
                    </li>
                  </ul>
                  Your information may be stored and processed in the United States, or in any other country in which USA Benefits Group or its affiliates, subsidiaries or agents maintain facilities. By using this system, you consent to any such transfer of information outside of your country. USA Benefits Group abides by the "safe harbor" framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of data from the European Union.<br />
                  <br />
                  After your account becomes inactive, USA Benefits Group will keep your personal information only for those purposes that are established by law, and for archival record purposes.
                </li>
                <li className='primary'>
                  6. ACCURACY OF PERSONAL INFORMATION<br />
                  USA Benefits Group will use reasonable efforts to keep our customer's personal information accurate for the Identified Purposes, and for minimizing the possibility of making inappropriate customer decisions based on such information. Customers are responsible for informing USA Benefits Group about changes to their personal information. USA Benefits Group will update personal information when necessary to keep it accurate or USA Benefits Group will provide customers the facility to make such updates.
                </li>
                <li className='primary'>
                  7. SECURITY SAFEGUARDS<br />
                  USA Benefits Group has instituted security measures to protect the loss, misuse, and alteration of information under our control. Any information you provide to USA Benefits Group in registration forms and other such interfaces is transmitted via a secure ("SSL") network connection and stored in a secure database. Please assist us in our security efforts by selecting passwords that cannot be easily guessed, reproduced or accessed by unauthorized persons.
                </li>
                <li className='primary'>
                  8. OPENNESS OF POLICY<br />
                  This Privacy Policy, and all updates to the Privacy Policy made from time to time, are published and made available to USA Benefits Group's customers on its system. It is the customer's obligation to periodically check the USA Benefits Group system for Privacy Policy updates.
                </li>
                <li className='primary'>
                  9. ACCESS TO PERSONAL INFORMATION<br />
                  USA Benefits Group will afford you a reasonable opportunity to review the personal information in your file, in understandable format, either by direct access or upon request to support made by you after providing sufficient identification. Support may be contacted at <a href='mailto:broker.support@usabg.com'>broker.support@usabg.com</a>.<br />
                  <br />
                  If USA Benefits Group is not able to provide access to some aspect of a customer's personal information, it will provide reasons for denying access such as; that by doing so would likely reveal personal information about a third party, or that it is confidential commercial information or attorney-client privileged communications, or that the information relates to a breach of an agreement or a contravention of law, or that its disclosure could reasonably be expected to threaten the life or security of another individual.<br />
                  <br />
                  Customers have the right to request that inaccurate or incomplete information be amended as appropriate, and USA Benefits Group will promptly correct such personal information or provide the means for you to do so directly.
                </li>
                <li className='primary'>
                  10. USE OF INFORMATION<br />
                  USA Benefits Group does not currently share, sell, trade, lend or otherwise disclose personal information to unaffiliated third parties without consent.
                </li>
                <li className='primary'>
                  11. EXTERNAL LINKS<br />
                  This USA Benefits Group system contains links to external sites that may be of interest to our customers and partners and external sites may contain links to the USA Benefits Group site. USA Benefits Group is not responsible for the content or privacy practices of these sites.
                </li>
                <li className='primary'>
                  12. OPTING OUT<br />
                  If at any time, you decide that you do not wish to receive information from USA Benefits Group or its Partners, please send us an e-mail to that effect at: <a href='mailto:broker.support@usabg.com'>broker.support@usabg.com</a>. Please include your name and e-mail address in all correspondence.
                </li>
                <li className='primary'>
                  13. COMPLIANCE<br />
                  USA Benefits Group wants to ensure that its Privacy Policy meets its customers' needs. If you feel that the Privacy Policy needs improvement, or believe that USA Benefits Group is not following its Privacy Policy, please contact support at <a href='mailto:broker.support@usabg.com'>broker.support@usabg.com</a>.<br />
                  <br />
                  USA Benefits Group will investigate your suggestions and complaints concerning the USA Benefits Group Privacy Policy. If a complaint is found to be justified, USA Benefits Group will take appropriate measures to resolve the complaint including, if necessary, amending its policies and procedures. Each customer will be informed of the outcome of the investigation regarding the customer's complaint.
                </li>
              </ul>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <Footer />
      </div>
    )
  }
}

export default PrivacyPolicyPage
