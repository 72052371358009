import { BehaviorSubject } from 'rxjs'
import AdminApi from './../../../api/admin-api/admin-api'
import moment from 'moment'
import UserProfileService from './../../../shared/services/UserProfile.service'
import CalendarEventService from './../../../shared/services/CalendarEvent.service'
import { getFontColorFromBgColor } from '../../../shared/utilities/getFontColor.function'

let eventSources = []

const currentEventSubject = new BehaviorSubject(undefined),
  currentEventsSubject = new BehaviorSubject([]),
  eventSourcesSubject = new BehaviorSubject(),
  isFetchingSubject = new BehaviorSubject(false)

const setEventSources = (sources) => {
  eventSources = (
    Array.isArray(sources) ? eventSources.concat(sources) : [sources]
  ).filter(
    (val, idx, self) =>
      self.map((s) => parseInt(s.id)).indexOf(parseInt(val.id)) === idx
  )

  eventSourcesSubject.next(eventSources)
}

const fetchEventSources = () => {
  isFetchingSubject.next(true)

  const extractSources = (calDatas) => {
    if ((calDatas = Array.isArray(calDatas) ? calDatas : null))
      setEventSources(
        calDatas
          .filter((c) => c && c?.calendar_id)
          .map((cal) => ({
            id: cal.id,
            name: cal.cal_name,
            style: {
              color: getFontColorFromBgColor(
                cal.cal_color ? cal.cal_color : '#767676' // default color
              ),
              backgroundColor: cal.cal_color ? cal.cal_color : '#767676', // default color
            },
          }))
      )
  }

  eventSourcesSubject.next((eventSources = []))

  Promise.all([
    AdminApi.getCalendarByDivisionId(
      [UserProfileService.get('u_devision')].filter(
        (id) => !isNaN(parseInt(id))
      )
    ),
    AdminApi.getCalendarByUsertypeId(
      [UserProfileService.getCurrentUserTypeId()].filter(
        (id) => !isNaN(parseInt(id))
      )
    ),
  ])
    .then(
      (res) => {
        let source1 = res[0] && res[0].data && res[0].data.data,
          source2 = res[1] && res[1].data && res[1].data.data

        source1 = Array.isArray(source1) ? source1 : []
        source2 = Array.isArray(source2) ? source2 : []

        extractSources(source1.concat(source2))
      },
      (rej) =>
        console.log(
          'ERROR: Failed to fetch calendars by usertype or division in Calendar.service.',
          rej
        )
    )
    .catch((err) =>
      console.log(
        'ERROR: Exception thrown while attempting to fetch calendars by usertype or division in Calendar.service.',
        err
      )
    )
    .finally(() => isFetchingSubject.next(false))

  return isFetchingSubject.asObservable()
}

const toggleEventSource = (eventSourceId) => {
  let activeEventSources = eventSourcesSubject.getValue(),
    eventSource = eventSources
      .filter((evt) => parseInt(evt.id) === parseInt(eventSourceId))
      .shift()

  // 1. is the eventSourceId currently active?
  let isActive =
    Array.isArray(activeEventSources) &&
    activeEventSources
      .map((evt) => parseInt(evt.id))
      .indexOf(parseInt(eventSourceId)) > -1

  // 2. if the eventSourceId is active, is there
  // another event source currently active?
  if (isActive && activeEventSources.length === 1) return false

  if (isActive) {
    eventSourcesSubject.next(
      activeEventSources.filter(
        (evt) => parseInt(evt.id) !== parseInt(eventSourceId)
      )
    )
    return true
  }

  if (eventSource)
    eventSourcesSubject.next(
      (Array.isArray(activeEventSources) ? activeEventSources : []).concat([
        eventSource,
      ])
    )

  return true
}

const calendarColors = {
  bg: {},
  border: {},
  text: {},
}

const getBackgroundColor = (calId) => {
  if (!calendarColors.bg.hasOwnProperty(`${calId}`)) {
    let es = eventSources.filter((e) => `${e.id}` === `${calId}`).shift()
    calendarColors.bg[`${calId}`] = es ? es.style.backgroundColor : ''
  }
  return calendarColors.bg[`${calId}`]
}

const getBorderColor = (calId) => {
  if (!calendarColors.border.hasOwnProperty(`${calId}`)) {
    let es = eventSources.filter((e) => `${e.id}` === `${calId}`).shift()
    calendarColors.border[`${calId}`] = es ? es.style.backgroundColor : ''
  }
  return calendarColors.border[`${calId}`]
}

const getTextColor = (calId) => {
  if (!calendarColors.text.hasOwnProperty(`${calId}`)) {
    let es = eventSources.filter((e) => `${e.id}` === `${calId}`).shift()
    calendarColors.text[`${calId}`] = es ? es.style.color : ''
  }
  return calendarColors.text[`${calId}`]
}

const fetchEvents = (start, end) => {
  isFetchingSubject.next(true)

  CalendarEventService.getByDateSpan(start, end)
    .then((events) => {
      events = events.map((event) => {
        return {
          id: event.event_id,
          allDay: false,
          title: event.summary,
          start: moment(event.starts_at).toDate(),
          end: moment(event.ends_at).toDate(),
          editable: false,
          display: 'block',
          overlap: true,
          backgroundColor: getBackgroundColor(event.calendar_id),
          borderColor: getBorderColor(event.calendar_id),
          textColor: getTextColor(event.calendar_id),
          extendedProps: {
            calendar_id: event.calendar_id,
            description: event.description,
            event_id: event.event_id,
          },
        }
      })

      isFetchingSubject.next(false)
      currentEventsSubject.next(events)
    })
    .catch((ex) => {
      isFetchingSubject.next(false)
      currentEventsSubject.next([])
    })
}

const deleteEventsById = async (eventId) =>
  await CalendarEventService.delete(eventId)

const CalendarService = {
  onCurrentEventChange: () => currentEventSubject.asObservable(),
  onIsFetchingSubject: () => isFetchingSubject.asObservable(),
  onEventSourcesChange: () => eventSourcesSubject.asObservable(),

  viewEvent: (currEvent) => currentEventSubject.next(currEvent),
  fetchEventSources: fetchEventSources,
  toggleEventSource: toggleEventSource,
  getAllEventSources: () => eventSources,
  getEventSource: (sourceId) =>
    eventSources
      .filter((evt) => parseInt(evt.id) === parseInt(sourceId))
      .shift(),

  // new
  fetchEvents: fetchEvents,
  getEvents: () => currentEventsSubject,
  isSourceActive: (calendarId) => [],

  deleteEventsById: deleteEventsById,
}

export default CalendarService

/*
	========================================================
	FullCalendar Event Object Schema
	========================================================

	id	
	String. A unique identifier of an event. Useful for getEventById.

	groupId	
	String. Events that share a groupId will be dragged and resized together automatically.

	allDay	
	Boolean (true or false). Determines if the event is shown in the “all-day” section of relevant views. In addition, if true the time text is not displayed with the event.

	start	
	Date object that obeys the current timeZone. When an event begins.

	end	
	Date object that obeys the current timeZone. When an event ends. It could be null if an end wasn’t specified.

	Note: This value is exclusive. For example, an event with the end of 2018-09-03 will appear to span through 2018-09-02 but end before the start of 2018-09-03. See how events are are parsed from a plain object for further details.

	startStr	An ISO8601 string representation of the start date. If the event is all-day, there will not be a time part.
	endStr	An ISO8601 string representation of the end date. If the event is all-day, there will not be a time part.
	title	
	String. The text that will appear on an event.

	url	
	String. A URL that will be visited when this event is clicked by the user. For more information on controlling this behavior, see the eventClick callback.

	classNames	
	An array of strings like [ 'myclass1', myclass2' ]. Determines which HTML classNames will be attached to the rendered event.

	editable	
	Boolean (true or false) or null. The value overriding the editable setting for this specific event.

	startEditable	
	Boolean (true or false) or null. The value overriding the eventStartEditable setting for this specific event.

	durationEditable	
	Boolean (true or false) or null. The value overriding the eventDurationEditable setting for this specific event.

	resourceEditable	
	Boolean (true or false) or null. The value overriding the eventResourceEditable setting for this specific event.

	display	
	The rendering type of this event. Can be 'auto', 'block', 'list-item', 'background', 'inverse-background', or 'none'. See eventDisplay.

	overlap	
	The value overriding the eventOverlap setting for this specific event. If false, prevents this event from being dragged/resized over other events. Also prevents other events from being dragged/resized over this event. Does not accept a function.

	constraint	
	The eventConstraint override for this specific event.

	backgroundColor	
	The eventBackgroundColor override for this specific event.

	borderColor	
	The eventBorderColor override for this specific event.

	textColor	
	The eventTextColor override for this specific event.

	extendedProps	
	A plain object holding miscellaneous other properties specified during parsing. Receives properties in the explicitly given extendedProps hash as well as other non-standard properties.

	source	
	A reference to the Event Source this event came from. If the event was added dynamically via addEvent, and the source parameter was not specified, this value will be null.
*/
