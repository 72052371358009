import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import UserService from './../../../../shared/services/User.service'
import OnboardingService from './../../../../shared/services/Onboarding.service'
import { MDBContainer, MDBRow, MDBCol, MDBSwitch } from 'mdbreact'

function canRecruit() {
  return `${UserProfileService.get('can_recruit')}` === '1'
}

const IsTeamRecruiterToggleSwitch = ({ onToggle }) => {
  const [isLoading, setIsLoading] = useState(false)

  const meetsRequirement =
      UserProfileService.isA('internal-staff', true) ||
      UserProfileService.isA('internal-admin', true) ||
      UserProfileService.isA('system-admin', true) ||
      UserProfileService.isA('agency-owner', true),
    isChecked = canRecruit()

  const toggleRecruiter = async () => {
    setIsLoading(true)

    try {
      await UserService.update(UserProfileService.getUserId(), {
        can_recruit: canRecruit() ? 0 : 1,
      })
      UserProfileService.set('can_recruit', canRecruit() ? 0 : 1)
      onToggle(!canRecruit())
    } catch (ex) {
      console.error(`${ex}`)
    }

    setIsLoading(false)
  }

  const renderButton = () => {
    if (!meetsRequirement) return <></>

    return (
      <MDBContainer
        fluid
        style={{ backgroundColor: 'rgba(255,255,255,0.5)', paddingTop: '10px' }}
      >
        <MDBRow>
          <MDBCol size="6" sm="7" md="9" lg="6">
            <label>
              Enable Team Recruiter?&nbsp;
              {isLoading ? (
                <>
                  <span>
                    <i className="fa fa-spin fa-spinner"></i>
                  </span>
                </>
              ) : (
                <></>
              )}
            </label>
          </MDBCol>
          <MDBCol size="6" sm="5" md="3" lg="6" style={{ textAlign: 'right' }}>
            <MDBSwitch
              labelLeft={'No'}
              labelRight={'Yes'}
              disabled={!!isLoading}
              checked={isChecked}
              onChange={toggleRecruiter}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }

  return renderButton()
}

export default observer(IsTeamRecruiterToggleSwitch)
