import { makeAutoObservable, toJS } from 'mobx'
import UserProfileService from './../../../shared/services/UserProfile.service'
import AvService from './../../../shared/services/Av.service'
import AvFactory from './../../../shared/factories/av.factory'
import CarrierFactory from './../../../shared/factories/carrier.factory'
import CoverageFactory from './../../../shared/factories/coverage.factory'
import LeadSourceFactory from './../../../shared/factories/lead-source.factory'
import UserService from './../../../shared/services/User.service'
import UsersCarrierContractFactory from './../../../shared/factories/users-carrier-contract.factory'
import { PointOverrideFactory } from './../../../shared/factories'
import AgentWritingCodeModal from './../components/AgentWritingCodeModal/AgentWritingCodeModal.component'
import { create } from 'react-modal-promise'
import moment from 'moment'
import submitSound from './../assets/submit-av-play-sound.mp3'
import { TrackJS } from 'trackjs'

const AgentWritingCodeModalPromise = create(AgentWritingCodeModal),
  CHECK_FORM_VALIDITY = true,
  CHECK_WRITING_CODES = true,
  CLIENT_FIELD_NAMES = {
    av_client: 'Client Name',
    av_client_email: 'Client Email',
    av_client_phone: 'Client Phone',
    av_state: 'Client State',
  },
  SUBMISSION_FIELD_NAMES = {
    av_date: 'Date to Carrier (AV Date)',
    leadsource_id: 'Lead Source',
    selling_agent_id: 'Agent of Record',
  },
  POLICY_FIELD_NAMES = {
    carrier_id: 'Carrier',
    coverage_id: 'Coverage',
    av_product: 'Product',
    av_term_length: 'Term Length',
    av_status: 'Application Status',
    av_appid: 'Policy # or D.O.B.',
    av_fee: 'Application Fee',
    av_premium: 'Premium',
    av_eff_date: 'Effective Date',
    av_comments: 'Comments',
  }

const getClientFields = () => Object.keys(CLIENT_FIELD_NAMES)

const getSubmissionFields = () => Object.keys(SUBMISSION_FIELD_NAMES)

const getPolicyFields = () => Object.keys(POLICY_FIELD_NAMES)

const playChaChing = () => {
  try {
    new Audio(submitSound).play()
    return new Promise((resolve, reject) => setTimeout(resolve, 2500))
  } catch (ex) {
    console.error(ex)
    return Promise.resolve()
  }
}

const mergeErrors = (errors) => {
  errors = Array.isArray(errors) ? Object.values(errors) : []

  if (errors.length === 1) return errors[0]

  if (errors.length === 2) return `${errors[0]} and ${errors[1]}`

  if (errors.length > 2) {
    let e = errors.pop()
    return `${errors.join(', ')} and ${e}`
  }

  return ''
}

const recordTrackJs = (msg, payload) => {
  let user_id = UserProfileService.getUserId()
  if (window.TrackJS) {
    TrackJS.console.debug(
      `[SALES-TRACKER] User ${user_id}: Sales Tracker Log: ${msg}`,
      payload
    )
  } else {
    console.error(`[SALES-TRACKER] User ${user_id}: Sales Tracker Log: ${msg}`)
    console.error(payload)
  }
}
const compileErrorMessages = (errors) => {
  const defaultMsg =
    'Errors exist.  Please check all Client & Policy form fields and try again.'
  let clentErrorMsgs = [],
    submissionErrorMsgs = [],
    policyErrorMsgs = {},
    policyErrors

  Object.keys(errors).forEach((policyIdx) => {
    const invalid = Object.keys(errors[policyIdx]).filter(
      (key) => errors[policyIdx][key] === false
    )
    clentErrorMsgs = clentErrorMsgs.concat(
      invalid.filter((key) => getClientFields().includes(key))
    )
    submissionErrorMsgs = submissionErrorMsgs.concat(
      invalid.filter((key) => getSubmissionFields().includes(key))
    )
    policyErrors = invalid.filter((key) => getPolicyFields().includes(key))
    if (policyErrors.length > 0) policyErrorMsgs[policyIdx] = policyErrors
  })

  if (errors.length)
    recordTrackJs('compileErrorMessages: First breakpoint ', { errors })

  clentErrorMsgs = mergeErrors(
    clentErrorMsgs
      .filter((val, idx, self) => self.indexOf(val) === idx)
      .map((field) => CLIENT_FIELD_NAMES[field])
  )
  clentErrorMsgs = clentErrorMsgs.length
    ? `Please complete or correct the following fields in 'Client Details' section: ${clentErrorMsgs}`
    : null

  submissionErrorMsgs = mergeErrors(
    submissionErrorMsgs
      .filter((val, idx, self) => self.indexOf(val) === idx)
      .map((field) => SUBMISSION_FIELD_NAMES[field])
  )
  submissionErrorMsgs = submissionErrorMsgs.length
    ? `Please complete or correct the following fields in 'Submission Details' section: ${submissionErrorMsgs}`
    : null

  policyErrorMsgs = Object.keys(policyErrorMsgs)
    .map((policyIdx) => {
      let errMsg = mergeErrors(
        policyErrorMsgs[policyIdx].map((field) => POLICY_FIELD_NAMES[field])
      )
      return errMsg.length
        ? `Please complete or correct the following fields on policy #${
            parseInt(policyIdx) + 1
          }: ${errMsg}.`
        : null
    })
    .filter((n) => !!n)

  const errMsgs = [clentErrorMsgs, submissionErrorMsgs]
    .concat(policyErrorMsgs)
    .filter((n) => !!n)

  if (errMsgs.length)
    recordTrackJs('compileErrorMessages: Second breakpoint ', { errMsgs })
  else recordTrackJs('compileErrorMessages: Third breakpoint ', { defaultMsg })

  return (errMsgs.length > 0 && errMsgs) || [defaultMsg]
}

class SalesTrackerStore {
  isFetching = false
  isSubmitting = false
  isDeleting = false
  showErrors = false
  responseErrors = false
  errMsg = null
  errMsgs = null

  Policy = AvFactory.create({ user_id: UserProfileService.getUserId() })
  Policies = []
  Coverages = []
  Carriers = []
  CoveragesByCarrier = {}
  LeadSources = []
  PointOverrides = []
  PointOverrideFields = {}
  AOR = { sellingAgentList: [], sellingAgentId: null, avSellingAgent: '' }
  policyErrors = {}
  isPreviousPolicy = false
  previousPolicyHasEffDate = false

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.Policy = {}
    this.Policies = []

    const Policy = AvFactory.create({
      user_id: UserProfileService.getUserId(),
      av_appid: null,
      av_client: null,
      av_client_email: null,
      av_client_phone: null,
      av_fee: null,
      av_premium: null,
      av_product: null,
      av_state: null,
      lives: 0,
      av_comments: null,
      av_date: moment().format('YYYY-MM-DD'),
      av_eff_date: null,
      av_term_length: null,
      pts_override_id: null,
    })
    this.Policy = Policy
    this.Policies = [Policy]
    this.PointOverrides = []
    this.PointOverrideFields = {}

    // this.policyErrors = {}
    this.AOR = {
      sellingAgentList: [],
      sellingAgentId: null,
      avSellingAgent: '',
    }
    this.showErrors = false
    this.responseErrors = false
    this.errMsg = null
    this.errMsgs = null
    this.isPreviousPolicy = false
    this.previousPolicyHasEffDate = false
  }

  fetchById = async (avId) => {
    this.isFetching = true

    if (this.avId && avId) {
      if (parseInt(this.avId) !== parseInt(avId)) this.reset()
    }

    this.avId = avId

    if (!avId || isNaN(avId)) {
      this.reset()

      /**/
      // Testing Content
      //
      // const payload = {
      //  av_appid        :   "19/18/24",
      //  av_carrier      :   "Allstate (National General)",
      //  av_client       :   "jane faksf",
      //  av_client_email :   "adf!gmail@gmail.com",
      //  av_client_phone :   "234234i3u44sdfsdfasdf",
      //  av_coverage     :   "Defined Benefit Health Plans",
      //  // av_date          :   "2023-03-01",
      //   av_date         :   "",
      //  av_fee          :   342,
      //  av_leadsource   :   "Gametime Leads",
      //  av_points       :   0,
      //  av_premium      :   372.22,
      //  av_product      :   "Classic ",
      //  av_state        :   "FL",
      //  av_status       :   "Withdrawn",
      //  av_term_length  :   "7",
      //  av_type         :   null,
      //  av_user         :   "Lews Rudkin",
      //  carrier_id      :   790,
      //  coverage_id     :   83,
      //  leadsource_id   :   723,
      //  lives           :   0,
      //  r_partner_id    :   null,
      //  sub_agent_id    :   null,
      //  user_id         :   9367,
      // };
      // Object.keys(payload).forEach(field => this.Policy.set(field, payload[field]))

      // console.log('save:: ',await Policy.save());
      // console.log('id: ',Policy.id());
      // console.log('reload:: ',await Policy.reload());
      // console.log('group id: ',Policy.get('av_group_id'),Policy.get('av_date'),Policy.get('av_status'));
      /**/

      this.isFetching = false
      return
    }

    let Policy = null,
      Policies = []
    try {
      Policies = await AvFactory.search({
        search: { av_id: avId },
        pagination: false,
        order_by: { av_id: 'ASC' },
      })
    } catch (ex) {
      Policies = []
      console.error(`Failed to fetch AV Policies ${ex}`)
    }

    if (Policies.length) {
      Policy = Policies.shift()
      if (Policy) Policies = [Policy].concat(Policies)
    }

    // Set Carriers by Coverage.
    await Promise.all(
      Policies.map((Policy) =>
        this.fetchCoveragesByCarrier(Policy.get('carrier_id'))
      )
    )

    this.Policy = Policy
    this.isPreviousPolicy = true
    if (Policy && Policy?.get('av_eff_date'))
      this.previousPolicyHasEffDate = true
    this.Policies = Policies
    if (!Policy.isNew()) {
      let idx = 0
      this.AOR.sellingAgentId = Policy.get('user_id')
      Policies.forEach((Policy) => {
        this.setPointOverrideOptions(
          this.getCoverage(Policy.get('coverage_id')),
          this.getCarrier(Policy.get('carrier_id')),
          idx
        )
        idx++
      })
    }

    this.isFetching = false
  }

  fetchCoverages = async () => {
    try {
      this.Coverages = (await CoverageFactory.findAll()).sort((A, B) =>
        `${A.get('coverage_name')}`.localeCompare(`${B.get('coverage_name')}`)
      )
    } catch (ex) {
      this.Coverages = []
      console.error(`Failed to fetch Coverages ${ex}`)
    }

    return this.Coverages
  }

  fetchCarriers = async () => {
    try {
      this.Carriers = (await CarrierFactory.findAll()).sort((A, B) =>
        `${A.get('c_name')}`.localeCompare(`${B.get('c_name')}`)
      )
    } catch (ex) {
      this.Carriers = []
      console.error(`Failed to fetch Carriers ${ex}`)
    }

    return this.Carriers
  }

  fetchPointOverrides = async () => {
    this.PointOverrides = []
    this.PointOverrideFields = {}

    let PointOverrides
    try {
      PointOverrides = await PointOverrideFactory.findAll()
    } catch (ex) {
      PointOverrides = []
      console.error(`Failed to fetch point overrides. ${ex}`)
    }

    return (this.PointOverrides = PointOverrides)
  }

  fetchLeadSources = async () => {
    try {
      this.LeadSources = (await LeadSourceFactory.findAll()).sort((A, B) =>
        `${A.get('ls_name')}`.localeCompare(`${B.get('ls_name')}`)
      )
    } catch (ex) {
      this.LeadSources = []
      console.error(`Failed to fetch Lead Sources ${ex}`)
    }

    return this.LeadSources
  }

  fetchCoveragesByCarrier = async (carrierId) => {
    if (
      this.CoveragesByCarrier[carrierId] &&
      Array.isArray(this.CoveragesByCarrier[carrierId]) &&
      this.CoveragesByCarrier[carrierId].length > 0
    )
      return true

    let Coverages
    try {
      Coverages = await CoverageFactory.search({
        search: { carrier_id: carrierId },
        pagination: false,
      })
    } catch (ex) {
      console.error(`Failed to fetch Carriers by Coverage ID. ${ex}`)
    }

    if (Coverages && Array.isArray(Coverages))
      this.CoveragesByCarrier[carrierId] = Coverages
    return
  }

  setPointOverrideOptions = (Coverage, Carrier, idx) => {
    // Ensure that this.PointOverrideFields[idx] is initialized
    if (!this.PointOverrideFields[idx]) {
      this.PointOverrideFields[idx] = {}
    }
    const POverrides = this.PointOverrides.filter(
        (P) =>
          (P.get('coverage_id') &&
            `${P.get('coverage_id')}` === `${Coverage?.id()}`) ||
          (P.get('carrier_id') &&
            `${P.get('carrier_id')}` === `${Carrier?.id()}`)
      ),
      fields = {}

    POverrides.forEach((P) => {
      if (!fields.hasOwnProperty(P.get('field'))) fields[P.get('field')] = []
      fields[P.get('field')].push(P)
    })

    Object.keys(fields).forEach(
      (acField) => (this.PointOverrideFields[idx][acField] = fields[acField])
    )
  }

  getCarrier = (carrierId) =>
    toJS(
      this.Carriers.filter(
        (Carrier) => parseInt(Carrier.id()) === parseInt(carrierId)
      ).shift()
    )

  getCoverage = (coverageId) =>
    toJS(
      this.Coverages.filter(
        (Coverage) => parseInt(Coverage.id()) === parseInt(coverageId)
      ).shift()
    )

  getSellingAgent = async (aId) => {
    let A = Array.isArray(this.AOR.sellingAgentList)
      ? this.AOR.sellingAgentList
          .filter((u) => parseInt(u.value) === parseInt(aId))
          .shift()
      : null
    if (A) return A

    let agentDetails = await UserService.getUserDetails(aId)
    if (!agentDetails) return

    return [
      {
        text: [agentDetails.u_fname, agentDetails.u_lname].join(' ').trim(),
        value: aId,
      },
    ]
  }

  getAvSellingAgent = async (aId) => {
    try {
      return (await this.getSellingAgent(aId)).shift().text
    } catch (ex) {
      return undefined
    }
  }

  setValidity = (policyIdx, field, isValid) => {
    if (!this.policyErrors[policyIdx]) this.policyErrors[policyIdx] = {}

    this.policyErrors[policyIdx][field] = isValid
  }

  checkValidity = () => {
    if (this.policyErrors) {
      // Check if any policy error contains a false value
      const errorDetails = Object.entries(this.policyErrors)
        .filter(([policyIdx, policyErrs]) =>
          Object.values(policyErrs).includes(false)
        )
        .map(([policyIdx, policyErrs]) => {
          const fieldsWithErrors = Object.entries(policyErrs)
            .filter(([, isValid]) => !isValid)
            .map(([field]) => field)
          return { policyIdx, fields: fieldsWithErrors }
        })

      if (errorDetails.length > 0) {
        errorDetails.forEach(({ policyIdx, fields }) => {
          console.log(
            `Policy Index: ${policyIdx}, Fields with errors: ${fields.join(
              ', '
            )}`
          )
        })
        return false
      }
    }

    return true
  }

  verifyWritingCodes = async () => {
    // Returns false if agent needs to supply a writing number.
    const verifyWritingCode = async (Policy, idx) => {
      try {
        if (Policy.changed?.hasOwnProperty('carrier_id')) {
          const Contract = await Policy.verifyWritingCode({
            user_id: UserProfileService.getUserId(),
            carrier_id: Policy.get('carrier_id'),
          })

          if (Contract) return true

          return await AgentWritingCodeModalPromise({
            carrier_id: Policy.get('carrier_id'),
            av_carrier: Policy.get('av_carrier'),
            isOpen: true,
          })
        }

        return true
      } catch (ex) {
        console.error(`Reject Modal ${ex}`)
        return false
      }
    }

    let results = []
    for (let Policy of this.Policies)
      results.push(await verifyWritingCode(Policy))

    return results.length > 0 && results.filter((n) => n === false).length === 0
  }

  saveAncillaryPolicies = async () => {
    // Make sure all the attached policies have matching client/agent fields.
    const Policies = this.Policies.map((Policy) => {
      ;[
        'av_group_id',
        'av_client',
        'av_client_email',
        'av_client_phone',
        'av_state',
        'av_date',
        'av_leadsource',
        'leadsource_id',
        'r_partner_id',
        'selling_agent_id',
      ].forEach((field) => Policy.set(field, this.Policy.get(field)))
      return Policy
    })

    // Attempt to save the ancillary policies.
    try {
      await Promise.all(Policies.map(async (Policy) => Policy.save()))
      this.Policies = Policies
      return true
    } catch (ex) {
      return false
    }
  }

  saveMasterPolicy = async () => {
    let avId = this.Policy.id(),
      isNew = this.Policy.isNew(),
      responseErrors = null,
      success = false,
      hasChanged = toJS(this.Policy.changed)

    // determine if the model has any changes.
    hasChanged = !(
      !hasChanged ||
      typeof hasChanged !== 'object' ||
      !Object.keys(hasChanged).length
    )

    this.Policy.set('selling_agent_id', this.AOR.sellingAgentId)

    try {
      if (isNew || hasChanged) await this.Policy.save()
      if (isNew) await this.Policy.reload()
      if (isNew) avId = this.Policy.id()
      success = true
    } catch (ex) {
      responseErrors = { 0: `${ex}` }
    }

    if (this.Policy.failed) responseErrors = { 0: toJS(this.Policy.failed) }

    if (
      responseErrors &&
      typeof responseErrors === 'object' &&
      Object.values(responseErrors).length
    ) {
      responseErrors[0] = Object.values(responseErrors)[0]
      if (responseErrors[0] && typeof responseErrors[0] === 'object')
        Object.keys(responseErrors[0]).forEach((key) => {
          if (!responseErrors[0][key] || !responseErrors[0][key].length) {
            delete responseErrors[0][key]
            return
          }

          responseErrors[0][key] = false
        })
    }

    this.responseErrors = responseErrors

    if (!isNew || (isNaN(avId) && avId)) this.avId = avId

    return success
  }

  onSuccess = async (isNew) => {
    // Play the cash-register sound, indicating vectory!
    await playChaChing()

    if (isNew) window.location.href = `/submit-sales/${this.Policy.id()}/edit`
  }

  onError = (msgs) => {
    if (!msgs) {
      let policyErrors = toJS(this.policyErrors),
        responseErrors = toJS(this.responseErrors),
        policyIdxs = (responseErrors && Object.keys(responseErrors)) || []

      policyIdxs.forEach(
        (idx) =>
          (policyErrors[idx] = {
            ...(policyErrors[idx] || {}),
            ...responseErrors[idx],
          })
      )
      msgs = compileErrorMessages(toJS((this.policyErrors = policyErrors)))
    }

    this.errMsgs = Array.isArray(msgs) ? msgs : [msgs]
    this.errMsg = this.errMsgs.join(`<br />`)
    this.showErrors = true
  }

  submit = async () => {
    let results = false,
      isNew = this.Policy?.isNew()
    this.errMsg = this.errMsgs = null

    // 1. Check Policy validity locally.
    if (CHECK_FORM_VALIDITY) {
      if (!this.checkValidity()) {
        recordTrackJs('submit(): Error at CHECK_FORM_VALIDITY', {
          policyErrors: this.policyErrors,
        })
        return this.onError()
      }
    }

    // 2. Check Agent for writing codes.
    if (CHECK_WRITING_CODES && !(await this.verifyWritingCodes()))
      return this.onError([
        'You must have an agent writing code for all carriers.  Please update any missing & required agent writing codes, then try again.',
      ])

    // 3. Activate the submitting flag to disable to submit button.
    this.isSubmitting = true

    // 4. Save Policies.
    try {
      if (await this.saveMasterPolicy()) {
        this.Policy.set('selling_agent_id', this.AOR.sellingAgentId)
        results = await this.saveAncillaryPolicies()
      }
    } catch (error) {
      recordTrackJs('submit(): Policy Saved Failed', { error })
    }

    // 5. Handle submission outcome.
    if (!!results) {
      this.onSuccess(isNew)
    } else {
      // Error at Handle submission outcome
      recordTrackJs('submit(): Error at Handle submission outcome', { results })
      this.onError()
    }

    // 6. Deactivate the submitting flag.
    this.isSubmitting = false

    return !!results
  }

  startPolicy = () =>
    this.Policies.push(
      AvFactory.create({
        user_id: UserProfileService.getUserId(),
        av_appid: null,
        av_client: null,
        av_client_email: null,
        av_client_phone: null,
        av_fee: null,
        av_premium: null,
        av_product: null,
        av_state: null,
        lives: 0,
        av_comments: null,
        av_eff_date: null,
        av_term_length: null,
      })
    )
}

export default new SalesTrackerStore()
