import env from './../../environments/environment'
import { makeAutoObservable } from 'mobx'
import AuthService from './../../shared/services/Auth.service'
import StorageService from './../../shared/services/Storage.service'
import emailPattern from './../../shared/patterns/email.pattern'
import { BehaviorSubject } from 'rxjs'
import { getIp } from './../../shared/utilities/getIp.function'
import { TermSetFactory, TermFactory } from './../../shared/factories'

const validateEmail = (email) => {
  email = email && `${email}`.trim().split('@')

  if (!email || email.length !== 2) return false

  let local = email.shift(),
    domain = email.shift()

  if (!local || !domain) return false

  // check for leading, trailing or consecutive periods.
  if (local.indexOf('.') > -1) {
    if (/^\./.test(local) || /\.$/.test(local) || /\.{2}/.test(local))
      return false
  }

  if ([2, 3].indexOf(domain.split('.').filter((d) => !!d).length) < 0)
    return false
  if ([2, 3, 4, 5, 6].indexOf(domain.split('.').pop().length) < 0) return false
  if (
    `${domain}`.charAt(`${domain}`.length - 1) === '.' ||
    `${domain}`.charAt(0) === '.'
  )
    return false

  return new RegExp(emailPattern).test([local, domain].join('@'))
    ? [local, domain].join('@')
    : false
}

const authStateSubject = new BehaviorSubject('login')

const getStoredUserDetails = (getChild) => {
  let userDetails =
    getChild === true
      ? StorageService.get('child_details')
      : StorageService.get('user_details')
  try {
    userDetails =
      typeof userDetails === 'string' ? JSON.parse(userDetails) : userDetails
  } catch (ex) {
    userDetails = {}
  }

  if (
    userDetails === null ||
    typeof userDetails === 'undefined' ||
    userDetails === ''
  )
    return false

  if (typeof userDetails !== 'object') return {}

  return userDetails
}

const isAuthenticated = () => {
  if (AuthService.getToken()) {
    const userDetails = getStoredUserDetails()
    return (
      userDetails &&
      typeof userDetails === 'object' &&
      !isNaN(userDetails?.usertype_id) &&
      parseInt(userDetails.usertype_id) > 0
    )
  }
}

const isAssumed = () => {
  if (isAuthenticated()) {
    let childDetails = getStoredUserDetails(true),
      userDetails = getStoredUserDetails()

    try {
      if (childDetails && userDetails)
        return parseInt(childDetails?.id) !== parseInt(userDetails?.id)
    } catch (ex) {}
  }

  return false
}

const isEnrolled = (getChild) => {
  if (isAuthenticated()) {
    const userDetails = getStoredUserDetails(getChild === true)
    return userDetails && parseInt(userDetails?.completed_enroll) === 1
  }

  return false
}

const getUsertypeId = (getChild) => {
  if (isAuthenticated()) {
    const userDetails = getStoredUserDetails(getChild === true)
    return userDetails && !isNaN(userDetails?.usertype_id)
      ? parseInt(userDetails.usertype_id)
      : false
  }

  return false
}

const getUserId = (getChild) => {
  if (isAuthenticated()) {
    const userDetails = getStoredUserDetails(getChild === true)
    return userDetails && !isNaN(userDetails?.id)
      ? parseInt(userDetails.id)
      : false
  }

  return false
}

class AuthStore {
  constructor() {
    makeAutoObservable(this)
  }

  state = null
  loading = false
  email = env?._dev?.credentials?.auth?.email || ''
  password = env?._dev?.credentials?.auth?.password || ''
  error = null
  success = null
  confirm_password = null
  reset_code = null
  reset_mode = null
  reset_api_key = null

  onStateChange = () => authStateSubject

  isAuthenticated = isAuthenticated
  isAssumed = isAssumed
  isEnrolled = isEnrolled
  getUsertypeId = getUsertypeId
  getUserId = getUserId
  unassume = AuthService.unassumeUser

  TermSets = {}
  isFetchingTerms = false

  goToState = (state) => {
    if (this.state !== state) {
      this.state = state
      this.success = null
      this.error = null
      authStateSubject.next(state)
    }
  }

  validateRequest = (event) => {
    const login = () => {
      this.error = ''
      let email

      if (!this.email && !this.password)
        this.error = 'Please complete both email and password.'
      else if (!this.email) this.error = 'Please complete email.'
      else if (!this.password) this.error = 'Please complete password.'
      else if (!(email = validateEmail(this.email)))
        this.error = 'Email address is invalid.'

      return { email, password: this.password }
    }

    const forgotPassword = () => {
      this.error = ''
      let email

      if (!this.email) this.error = 'Please complete email.'
      else if (!(email = validateEmail(this.email)))
        this.error = 'Email address is invalid.'

      return { email }
    }

    const resetPassword = () => {
      if (!this.reset_code || !this.reset_api_key)
        this.error = 'Invalid reset url.'

      if (!this.password && !this.confirm_password)
        this.error =
          'Please complete both password & matching confirmation password.'
      else if (!this.password) this.error = 'Please complete password.'
      else if (!this.password)
        this.error = 'Please complete matching confirmation password.'
      else if (this.password.length < 6) this.error = 'Password is too short.'
      else if (this.password !== this.confirm_password)
        this.error = 'Confirmation Password does not match.'

      return {
        reset_code: this.reset_code,
        password: this.password,
        confirm_password: this.confirm_password,
        reset_api_key: this.reset_api_key,
      }
    }

    switch (event) {
      case 'login':
        return login()
      case 'forgot-password':
        return forgotPassword()
      case 'reset-password':
        return resetPassword()
      default:
        return { email: false, password: false }
    }
  }

  handleLogin = async () => {
    const { email, password } = this.validateRequest('login')
    if (this.error) return false

    this.loading = true

    let userIp
    try {
      userIp = await getIp()
    } catch (ex) {}

    try {
      let userDetails = await AuthService.login(email, password, userIp)
      this.success = 'Success.  Taking you to your back office.'
      return userDetails
    } catch (ex) {
      this.error = `Oh no! We can't log you in. ${
        `${ex}`.replace(/(error:)+/gi, '') || 'Please try again later.'
      }`
      this.loading = false
      return false
    }
  }

  handleSendResetLink = async () => {
    if (this.success) return true

    const { email } = this.validateRequest('forgot-password')
    if (this.error) return false

    this.loading = true
    try {
      await AuthService.sendResetPasswordLink(email)
      this.success = `We've sent an email out to ${email}.  Check your inbox, junk or spam for further instructions and a link to enter your new password.`
      this.loading = false
      return true
    } catch (ex) {
      this.error = `Oh no! We can't send your email. ${
        `${ex}`.replace(/(error:)+/gi, '') || 'Please try again later.'
      }`
      this.loading = false
      return false
    }
  }

  handleResetPassword = async () => {
    if (this.success) return true

    const { reset_code, reset_api_key, password, confirm_password } =
      this.validateRequest('reset-password')
    if (this.error) return false

    this.loading = true
    try {
      await AuthService.resetPassword(
        reset_code,
        reset_api_key,
        password,
        confirm_password
      )
      this.success = `Your password has been reset.  We'll redirect you back to login.`
      this.loading = false
      return true
    } catch (ex) {
      this.error = `Oh no! We can't reset your password. ${
        `${ex}`.replace(/(error:)+/gi, '') || 'Please try again later.'
      }`
      this.loading = false
      return false
    }
  }

  setInitialState = (currentPath) => {
    currentPath = currentPath
      ? `${currentPath}`
          .trim()
          .toLowerCase()
          .split('/')
          .filter((p) => !!p)
          .join('/')
      : null

    switch (currentPath) {
      case 'login':
        break
      case 'forgot-password':
        break
      case 'reset-password':
        break
      default:
        break
    }

    if (this.state !== currentPath);
    this.goToState(currentPath)

    return authStateSubject
  }

  redirectAuthenticated = async () => {
    try {
      return await AuthService.validateAuthenticated()
    } catch (ex) {
      StorageService.clearAllKeys({
        except: ['modals.quote-popup', 'modals.marketing-investment'],
      })
      return false
    }
  }

  fetchTerms = async () => {
    const fetchTermDocs = async () => {
      try {
        return await TermFactory.search({
          search: { is_enroll: 1 },
          pagination: false,
        })
      } catch (ex) {
        console.error('Failed to load enrollment terms.', ex)
      }
    }

    const fetchTermSets = async () => {
      try {
        return await TermSetFactory.search({
          search: { usertype_id: [91] },
          pagination: false,
        })
      } catch (ex) {
        console.error('Failed to load enrollment term sets.', ex)
      }
    }

    this.isFetchingTerms = true

    const Response = await Promise.all([fetchTermDocs(), fetchTermSets()]),
      Terms = Response.shift(),
      TermSets = (Response.shift() || []).filter((TermSet, idx) => idx === 0)

    TermSets.forEach((TermSet) => {
      TermSet.parseTermStmt({ Terms })
      this.TermSets[TermSet.get('usertype_id')] = TermSet
    })

    this.isFetchingTerms = false
  }
}

export default new AuthStore()
