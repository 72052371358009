import BaseModelFactory from './base.factory'
import ReferralPartnerService from './../services/ReferralPartner.service'
import ReferralPartner from './../models/referral-partner.model'

class ReferralPartnerFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: ReferralPartnerService, Model: ReferralPartner }
    )
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: ReferralPartnerService, Model: ReferralPartner }
    )
  static findAllByUserId = async (id) =>
    await this._findAllBy(
      { user_id: id },
      { Service: ReferralPartnerService, Model: ReferralPartner }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: ReferralPartnerService,
      Model: ReferralPartner,
    })
}

export default ReferralPartnerFactory
