import React from "react";
import env from "./../environments/environment";
import { TrackJS } from "trackjs";

export class ErrorBoundary extends React.Component 
{
  // state = {
  //   hasError: false,
  // };
  
  // static getDerivedStateFromError(error) {
  //   return {hasError: true};
  // };

  componentDidCatch (error, errorInfo) 
  {
    if (errorInfo && errorInfo.componentStack) {
    	if (!env?.env || (env?.env && ["beta","production"].indexOf(env.env) < 0))
      	console.error(errorInfo.componentStack);
    }
		if (window.TrackJS && env?.integrations?.track_js)
			TrackJS.track(error);
		// If we decide to add additional error handling
		// in future updates, we'll want to make the
		// error data available to those components.
    // this.setState({ error });
  }
  render () 
  {
  	// Provide fallback UI to prevent WSOD
  	// return this.state.hasError ? <FallbackUI /> : this.props.children;
    return this.props.children
  }
}