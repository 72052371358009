import env from './../../environments/environment'
import placeholder_picture from './../../assets/images/user_placeholder.png'
import UserProfileService from '../services/UserProfile.service'
import AuthService from '../services/Auth.service'
import { toast } from 'react-toastify'

function getHeadshot(profile) {
  profile = profile
    ? typeof profile === 'string'
      ? { u_picture: profile }
      : profile
    : false

  if (!profile) return placeholder_picture

  if (typeof profile === 'object' && profile?.u_picture) {
    if (
      profile?.id &&
      !/^users(\/|%2F)\d{3,6}(\/|%2F)/.test(`${profile.u_picture}`)
    )
      profile.u_picture = encodeURIComponent(
        `users/${profile?.id}/${decodeURIComponent(profile.u_picture)}`
      )
    profile = profile.u_picture
  } else if (typeof profile === 'object') {
    return placeholder_picture
  }

  return profile
    ? `https://firebasestorage.googleapis.com/v0/b/${
        env.integrations.firebase.config.storageBucket
      }/o/${encodeURIComponent(decodeURIComponent(profile))}?alt=media`
    : placeholder_picture
}


function getBATeamLogo(url) {
  if (!url) return placeholder_picture

  return `https://firebasestorage.googleapis.com/v0/b/${
    env.integrations.firebase.config.storageBucket
  }/o/${encodeURIComponent(decodeURIComponent(url))}?alt=media`
}

async function autoLogin(userId) {
  if(userId) {
    AuthService.assumeUser(userId)
    .then(
      () => (window.location.href = '/account-profile'),
      (err) => toast.error('Unable to login as selected user.', { position: toast.POSITION.TOP_RIGHT })
    )
    .finally(() => {})
    .catch((err) => toast.error('Unable to login as selected user.', { position: toast.POSITION.TOP_RIGHT })
    )
  }
}

function getAgentAccessToHeadshot(user_id) {
  if(
    UserProfileService.isA([
      'system-admin',
      'internal-staff',
      'internal-admin',
    ]) || UserProfileService.isA(
      ['recruiter-group']
    ) && ! UserProfileService.isA(
      ['agency-owner']
    ) && user_id !== UserProfileService.getUserId()
  ) return true;
}

export { getHeadshot, autoLogin, getBATeamLogo, getAgentAccessToHeadshot }
