import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  UIInput,
  UIInputButton,
  UISelect,
} from './../../../../components/forms/form-fields'
import { MDBBtn, MDBIcon } from 'mdbreact'
import Store from './../../shared/Referrals.store'

import './Search.component.scss'

const Search = () => {
  const onSearchKeyDown = (evt) => {
    if (evt.key === 'Enter' && Store.searchTerm.length > 0)
      Store.searchReferralPartners({})
  }

  const onChange = (event) => {
    console.log('onChange: ', event)
  }

  const onSubmit = (event) => {
    event.preventDefault()
    return false
  }

  const SearchInput = () => {
    return (
      <UIInput
        type="text"
        label="Search Referral Partners"
        value={Store.searchTerm}
        onKeyDown={onSearchKeyDown}
        onChange={(e) => (Store.searchTerm = e.target.value)}
        style={{ color: '#fff' }}
      />
    )
  }

  const SearchButton = () => {
    return (
      <MDBBtn
        disabled={Store.isLoading || Store.isSearching}
        onClick={() => Store.searchReferralPartners({})}
      >
        <MDBIcon icon="search" />
      </MDBBtn>
    )
  }

  return (
    <form id="SearchComponent" onSubmit={onSubmit}>
      <UIInputButton
        input={SearchInput()}
        button={SearchButton()}
        btnPosition={'right'}
      />
    </form>
  )
}

export default observer(Search)
