import { makeAutoObservable } from 'mobx'
import ReferralPartnersW9Service from '../../../shared/services/ReferralPartnersW9.service'
import AvReferralsService from '../../../shared/services/AvReferrals.service'

const parseNotes = (notes) => {
  if (notes) {
    if (typeof notes === 'string') {
      try {
        notes = JSON.parse(notes)
      } catch (ex) {
        notes = []
      }
    }
  }
  if (!Array.isArray(notes)) notes = []
  return notes
}

class ReferralAdminStore {
  constructor() {
    makeAutoObservable(this)
  }
  _id = null
  isLoading = {
    payment: false,
    w9: false,
  }
  errors = {
    payment: false,
    w9: false,
  }
  w9s = []
  payment = null
  payments = []

  fetch = async (avId, referralPartnerId) => {
    if (this._id === `${avId}.${referralPartnerId}`) return
    this._id = `${avId}.${referralPartnerId}`

    this.isLoading.w9 = true
    ReferralPartnersW9Service.search({
      search: { referral_partner_id: referralPartnerId },
    })
      .then((success) => {
        this.w9s = (
          success && Array.isArray(success?.models) ? success.models : []
        ).sort((a, b) => (a?.w9_year < b?.w9_year ? 1 : -1))
        this.isLoading.w9 = false
      })
      .catch((error) => {
        this.errors.w9 = error
        this.isLoading.w9 = false
      })

    this.isLoading.payment = true
    AvReferralsService.search({ search: { av_id: avId } })
      .then((success) => {
        let payments =
          success && Array.isArray(success?.models)
            ? success.models.sort((a, b) => b.id - a.id)
            : []

        if (payments) {
          payments.forEach((payment) => {
            payment.notes = parseNotes(payment?.notes)
          })
        }

        this.payments = payments
        this.payment = payments.shift() || null
        this.isLoading.payment = false
      })
      .catch((error) => {
        this.errors.payment = error
        this.isLoading.payment = false
      })
  }

  storeW9 = async (w9) => {
    const result = await ReferralPartnersW9Service.store(w9)
    this.w9s.push({ ...result, ...w9 })
    return { ...result, ...w9 }
  }

  updateW9 = async (w9) => {
    const result = await ReferralPartnersW9Service.update(w9.id, {
      ...w9,
      id: undefined,
    })
    this.w9s = this.w9s.map((prevW9) =>
      `${prevW9.id}` === `${w9.id}` ? w9 : prevW9
    )
    return { ...result, ...w9 }
  }

  storePayment = async (payment) => {
    const result = await AvReferralsService.store(payment)
    payment = { ...payment, ...result, notes: parseNotes(payment?.notes) }
    this.payment = payment
    this.payments.push(payment)
    return payment
  }

  updatePayment = async (payment) => {
    const result = await AvReferralsService.update(payment.id, {
      ...payment,
      id: undefined,
    })
    payment = { ...result, ...payment, notes: parseNotes(payment?.notes) }
    this.payment = payment
    this.payments = this.payments.map((pt) => {
      if (pt.id === payment.id) return payment
      return pt
    })
    return payment
  }

  deletePayment = async (paymentId) => {
    const result = await AvReferralsService.delete(paymentId)
    this.payments = this.payments.filter((p) => p.id !== paymentId)
    this.payment = this.payments[0]
    return result
  }
}

export default new ReferralAdminStore()
