import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  MDBAlert,
  MDBCol,
  MDBRow,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCollapse,
  MDBBtn,
} from 'mdbreact'
import Store from './../store'
import ToolTip from './../../../components/shared/ToolTip/ToolTip.component'
import { convertToCurrency } from './../../../shared/formatters/convertToCurrency.formatter'
import { convertToCurrencyWithoutSign } from './../../../shared/formatters/convertToCurrencyWithoutSign.formatter'

import './LeadPackageCard.component.scss'

const TRANS_DELAY_DISCLAIMER =
  'It can take 24-48 for transferred lead credits to appear in lead vendor accounts.'

const LeadPackageCard = ({ Package, agentLedger, leadPkgOption }) => {
  const isLoading = false,
    isPurchasing = false,
    isTransferring = false,
    [isOpen, setIsOpen] = useState(false),
    [transferAmount, setTransferAmount] = useState({
      Package: {},
      LeadVendor: {},
    }),
    [purchase, setPurchase] = useState({
      Package: {},
      QtyPrice: {},
      approved: false,
    }),
    LeadVendor =
      Package?.get('lead_vendor_id') &&
      Store.LeadVendors.filter(
        (LeadVendor) =>
          parseInt(LeadVendor.id()) === parseInt(Package.get('lead_vendor_id'))
      ).shift()

  const agentHasBalance = ({ QtyPrice, Ledger, amount }) => {
    if (QtyPrice) {
      return (
        Store?.AgentLedger.get('balance') >= QtyPrice.get('price') &&
        !isNaN(Store?.AgentLedger.get('balance')) &&
        !isNaN(QtyPrice.get('price'))
      )
    }
  }

  // this.setState({ purchase: { Package, QtyPrice, approved: false } })
  const purchasePkgQtyPrice = (Package, QtyPrice) =>
    setPurchase({ Package, QtyPrice, approved: false })

  const renderOpenAmountOpt = () => {
    const show = (() => {
      // if (this.state.purchase && !this.state.purchase?.approved)
      //   return !!this.state.purchase?.Package
      return false
    })()

    const render = () => {
      return (
        <tr>
          <td colSpan="4">
            <MDBAlert color="warning">
              <table className="are-you-sure">
                <tbody>
                  <tr>
                    <td>
                      Are you sure you would like to transfer{' '}
                      {convertToCurrencyWithoutSign(0)} USABG BUCK$ ?
                    </td>
                    <td>
                      <MDBBtn
                        color="success"
                        className="btn-approve"
                        disabled={isLoading || isPurchasing || isTransferring}
                        onClick={() => console.log('approveTransfer')}
                      >
                        <MDBIcon icon="thumbs-up" />
                      </MDBBtn>
                    </td>
                    <td>
                      <MDBBtn
                        color="danger"
                        className="btn-disapprove"
                        disabled={isLoading || isPurchasing || isTransferring}
                        onClick={() => console.log('disapproveTransfer')}
                      >
                        <MDBIcon icon="thumbs-down" />
                      </MDBBtn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </MDBAlert>
          </td>
        </tr>
      )
    }

    return (
      <React.Fragment key={`lead-vendor-tx-${LeadVendor.id()}-${Package.id()}`}>
        <tr
          className={
            show ? 'is-expanded' : ''
            // this.agentHasBalance(QtyPrice)
            //   ? show
            //     ? 'is-expanded'
            //     : ''
            //   : 'is-invalid'
          }
        >
          <td valign="middle"></td>
          <td valign="middle">{convertToCurrencyWithoutSign(0)}</td>
          <td valign="middle">{convertToCurrency(0)}</td>
          <td valign="middle">
            <MDBBtn
              disabled={
                isLoading ||
                isPurchasing ||
                isTransferring ||
                !Store?.AgentLedger
              }
              className="support-btn btn-block mt-3"
              color="primary"
              onClick={() => setTransferAmount({ Package, LeadVendor })}
              target="_blank"
            >
              <span className="valid">
                {isTransferring &&
                transferAmount.LeadVendor?.id &&
                transferAmount.LeadVendor.id() === LeadVendor?.id()
                  ? 'Transferring'
                  : 'Transfer'}
              </span>
              <span className="invalid">
                <MDBIcon icon="ban" />
              </span>
            </MDBBtn>
          </td>
        </tr>
        {show ? render() : <></>}
      </React.Fragment>
    )
  }

  const renderQtyPriceOpt = (Package, QtyPrice, idx) => {
    const show = (() => {
      // if (this.state.purchase && !this.state.purchase?.approved) {
      //   if (this.state.purchase?.Package && this.state.purchase?.QtyPrice)
      //     return (
      //       QtyPrice?.id() &&
      //       QtyPrice.id() === this.state.purchase?.QtyPrice.id()
      //     )
      // }
      return false
    })()

    const render = () => {
      return (
        <tr>
          <td colSpan="4">
            <MDBAlert color="warning">
              <table className="are-you-sure">
                <tbody>
                  <tr>
                    <td>
                      Are you sure you would like to purchase{' '}
                      {convertToCurrencyWithoutSign(QtyPrice.get('quantity'))}{' '}
                      leads for {convertToCurrency(QtyPrice.get('price'))} ?
                    </td>
                    <td>
                      <MDBBtn
                        color="success"
                        className="btn-approve"
                        disabled={isLoading || isPurchasing || isTransferring}
                        onClick={() => console.log('approvePurchase')}
                      >
                        <MDBIcon icon="thumbs-up" />
                      </MDBBtn>
                    </td>
                    <td>
                      <MDBBtn
                        color="danger"
                        className="btn-disapprove"
                        disabled={isLoading || isPurchasing || isTransferring}
                        onClick={() => console.log('disapprovePurchase')}
                      >
                        <MDBIcon icon="thumbs-down" />
                      </MDBBtn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </MDBAlert>
          </td>
        </tr>
      )
    }

    return (
      <React.Fragment key={`lead-buy-btn-${QtyPrice.id()}`}>
        <tr
          className={
            agentHasBalance({ QtyPrice })
              ? show
                ? 'is-expanded'
                : ''
              : 'is-invalid'
          }
        >
          <td valign="middle">{idx + 1}.&nbsp;</td>
          <td valign="middle">
            {convertToCurrencyWithoutSign(QtyPrice.get('quantity'))}
          </td>
          <td valign="middle">{convertToCurrency(QtyPrice.get('price'))}</td>
          <td valign="middle">
            <MDBBtn
              disabled={
                isLoading ||
                isPurchasing ||
                isTransferring ||
                !Store?.AgentLedger ||
                !agentHasBalance({ QtyPrice })
              }
              className="support-btn btn-block mt-3"
              color="primary"
              onClick={() => purchasePkgQtyPrice(Package, QtyPrice)}
              target="_blank"
            >
              <span className="valid">
                {isPurchasing &&
                this?.state?.purchase?.QtyPrice?.id() === QtyPrice?.id()
                  ? 'Purchasing'
                  : 'Purchase'}
              </span>
              <span className="invalid">
                <MDBIcon icon="ban" />
              </span>
            </MDBBtn>
          </td>
        </tr>
        {show ? render() : <></>}
      </React.Fragment>
    )
  }

  const renderPurchaseOptions = () => {
    // DISABLE THE 'OPEN AMOUNT' FEATURE UNTIL FURTHER DECISIONS ARE MADE ON THE FUNCTIONALITY.
    const isOpenAmount = false && !!LeadVendor?.id() && Package?.isOpenAmount(),
      doQuantitiesExist = Package.quantities && Package.quantities.length

    return (
      <div className="lead-vendor-purchase-option-set">
        {isOpenAmount ? (
          <table className="pkg-amount-selector mt-3 mb-4">
            {renderOpenAmountOpt()}
          </table>
        ) : (
          <></>
        )}
        <div className="row">
          <MDBCol size="12" className="mb-3">
            <a href="/account-ledger" className="lead-trans-btn">
              <span>Transfer</span>
              <br />
              <span className="golden">USABG BUCK$</span>
              <span>to Lead Vendor</span>
            </a>
          </MDBCol>
          <MDBCol size="12" className="mb-3">
            {Package?.get('package_url') ? (
              <>
                <a
                  href={Package.get('package_url')}
                  target="_BLANK"
                  className="lead-vendor-btn"
                >
                  Visit Lead Vendor Site
                </a>
                <small>
                  <strong>* {TRANS_DELAY_DISCLAIMER}</strong>
                </small>
              </>
            ) : (
              <></>
            )}
          </MDBCol>
        </div>
        {doQuantitiesExist ? (
          <table className="pkg-qty-prices mt-3 mb-4">
            <thead>
              <tr>
                <th></th>
                <th>Quantity:</th>
                <th>Price:</th>
                <th>
                  <MDBIcon icon="cart" />
                </th>
              </tr>
            </thead>
            <tbody>
              {Package.quantities
                .filter((QtyPrice) => QtyPrice?.id())
                .map((QtyPrice, idx) =>
                  renderQtyPriceOpt(Package, QtyPrice, idx)
                )}
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
    )
  }

  if (!Package && leadPkgOption) {
    return (
      <MDBCard className="mb-3 mt-3 LeadPackageCardComponent">
        <MDBCardHeader color="indigo">
          <div>{leadPkgOption.page_name}</div>
          <div>
            <MDBBtn
              onClick={() => setIsOpen(!isOpen)}
              className={`toggle-open-btn ${isOpen ? 'is-open' : ''}`}
            >
              <MDBIcon icon="angle-up" />
            </MDBBtn>
          </div>
        </MDBCardHeader>
        <MDBCollapse isOpen={!!isOpen}>
          <MDBCardBody>
            <div
              dangerouslySetInnerHTML={{ __html: leadPkgOption.content }}
            ></div>
            {(typeof leadPkgOption.links === 'string'
              ? JSON.parse(leadPkgOption.links)
              : leadPkgOption.links
            )
              .filter((filterItem, k) => filterItem.tl_type === 'Button')
              .map((link, j) => (
                <MDBBtn
                  key={'lead-btn-' + j}
                  className="support-btn btn-block mt-3"
                  color="primary"
                  href={link.tl_link}
                  target="_blank"
                >
                  {link.tl_title}
                </MDBBtn>
              ))}
          </MDBCardBody>
        </MDBCollapse>
      </MDBCard>
    )
  }

  if (Package) {
    return (
      <MDBCard className="mb-3 mt-3 LeadPackageCardComponent">
        <MDBCardHeader color="indigo">
          <div className="wallet-icon">
            <ToolTip
              icon={'wallet'}
              placement="top"
              content="Easily transfer your USABG BUCK$!"
            />
            {Package.get('name')}
          </div>
          <div>
            <MDBBtn
              onClick={() => setIsOpen(!isOpen)}
              className={`toggle-open-btn ${isOpen ? 'is-open' : ''}`}
            >
              <MDBIcon icon="angle-up" />
            </MDBBtn>
          </div>
        </MDBCardHeader>
        <MDBCollapse isOpen={!!isOpen}>
          <MDBCardBody>
            <div
              dangerouslySetInnerHTML={{ __html: Package.get('description') }}
            ></div>
            <MDBCollapse isOpen={true}>
              {renderPurchaseOptions()}
            </MDBCollapse>
          </MDBCardBody>
        </MDBCollapse>
      </MDBCard>
    )
  }

  return <></>
}

export default observer(LeadPackageCard)
