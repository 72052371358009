import BaseModelFactory from './base.factory'
import ReportHistoryService from './../services/ReportHistory.service'
import ReportHistory from './../models/report-history.model'

class ReportHistoryFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: ReportHistoryService, Model: ReportHistory }
    )
  static findById = async (id) =>
    await this._findBy(
      { id },
      { Service: ReportHistoryService, Model: ReportHistory }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: ReportHistoryService,
      Model: ReportHistory,
    })
}

export default ReportHistoryFactory
