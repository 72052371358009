import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import AdminContentEditPopupComponent from './../../../components/adminShared/AdminContentEditPopupComponent'
import AgencyContentStore from './../../../shared/stores/agencyContent.store'
import { renderVideo } from './renderVideo'

import './ContentVideo.scss'

const ContentVideo = ({ contentId, contentSlug, onLoad, editor }) => {
  // Agency-Content Selector ---------------------------------------
  const { fetchByContentObj } = AgencyContentStore
  useEffect(() => {
    fetchByContentObj({ contentId, contentSlug })
      .then((res) => (typeof onLoad === 'function' ? onLoad(res) : null))
      .catch(() => (typeof onLoad === 'function' ? onLoad(false) : null))
  }, [fetchByContentObj, contentId, contentSlug])
  // ---------------------------------------------------------------

  const get = () =>
    AgencyContentStore.content[contentId || contentSlug]
      ? Array.isArray(AgencyContentStore.content[contentId || contentSlug])
        ? AgencyContentStore.content[contentId || contentSlug]
        : [AgencyContentStore.content[contentId || contentSlug]]
      : []

  return (
    <div className="ContentVideoComponent">
      {get().map((entry, idx) => {
        const { id, name, content } = entry

        if (!entry.content) {
          return (
            <>
              {id || contentId ? (
                <AdminContentEditPopupComponent
                  key={`content-video-entry-${idx}`}
                  type="RTE"
                  color="blue"
                  pageId={id || contentId}
                  color={editor && editor?.iconColor}
                  iconPosition={(editor && editor?.iconPosition) || 'top-right'}
                  iconStyle={
                    (editor && editor?.iconStyle) || {
                      transform: 'translate(-3px, 3px)',
                    }
                  }
                  callback={(contentId) => fetchByContentObj({ contentId })}
                />
              ) : (
                <></>
              )}
            </>
          )
        }

        return (
          <div
            className="content-video-inner-wrapper"
            key={`content-video-entry-${idx}`}
          >
            {renderVideo(content, name)}
            {id || contentId ? (
              <AdminContentEditPopupComponent
                type="RTE"
                color="blue"
                pageId={id || contentId}
                color={editor && editor?.iconColor}
                iconPosition={(editor && editor?.iconPosition) || 'top-right'}
                iconStyle={
                  (editor && editor?.iconStyle) || {
                    transform: 'translate(-3px, 3px)',
                  }
                }
                callback={(contentId) => fetchByContentObj({ contentId })}
              />
            ) : (
              <></>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default observer(ContentVideo)
