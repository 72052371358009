import React from 'react';
import {Subscription} from "rxjs";
import {toast} from "react-toastify";
import DashboardLayout from './../../components/Admin/DashboardLayout';
import AdobeSignAuthorizaton from './components/AdobeSignAuthorizaton/AdobeSignAuthorizaton.component';
import AdobeSignOauthStore from './stores/AdobeSignOauth.store';
import {MDBContainer, MDBRow, MDBCol} from "mdbreact";

import './OauthFormsPage.scss';

class OauthFormsPage extends React.Component
{
	state = {
		docs: [],
		isAuth: false
	}

	#_subscriptions$ = new Subscription();

	#setDocs = docs => {
		if (typeof docs === 'string')
			toast.error(docs, {position: toast.POSITION.TOP_RIGHT});
		this.setState(prevState => ({...prevState, docs: docs?.models||[]}));
	}

	#setIsAuth = isAuth => {
		if (typeof isAuth === 'string')
			toast.error(isAuth, {position: toast.POSITION.TOP_RIGHT});
		this.setState(prevState => ({...prevState, isAuth}));
	}

	componentDidMount () 
	{
		this.#_subscriptions$.add(AdobeSignOauthStore.getAuthChange().subscribe(this.#setIsAuth));
		this.#_subscriptions$.add(AdobeSignOauthStore.getDocuments().subscribe(this.#setDocs));
	}

	componentWillUnmount () 
	{
		this.#_subscriptions$.unsubscribe();
	}

	render ()
	{
		toast.configure();

	  return (
	    <React.Fragment>
	      <DashboardLayout>
	        <main id="OauthFormsPage" className="mainSection">
	          <MDBContainer fluid className="mt-5">
	            <h2 className="text-center">Oauth Integrations</h2>
	            <hr/>
	            <MDBRow>
	            	<MDBCol size="12" md="6">
	            		<AdobeSignAuthorizaton props={this.props} />
	            	</MDBCol>
	            </MDBRow>
	            <MDBRow>
	            	<MDBCol size="12">
	            		<table>
	            			<tbody>
	            				{this.state.docs.map(doc => <tr key={'doc-'+doc.id}><td>{doc.id}</td><td>{doc.name}</td></tr>)}
	            			</tbody>
	            		</table>
	            	</MDBCol>
	            </MDBRow>
	          </MDBContainer>
	        </main>
	      </DashboardLayout>
	    </React.Fragment>
	  )
	}
}

export default OauthFormsPage;
