import React, { Component, Fragment } from 'react'
import {MDBContainer, MDBRow,  MDBCol} from 'mdbreact'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import PackagesTable from './components/PackagesTable/PackagesTable.component';
import PackageFeatureTable from './components/PackageFeatureTable/PackageFeatureTable.component';
import PageHeading from './../../components/shared/PageHeading.component'
import Store from './store'

import './AdminPackages.page.scss';

class AdminPackagesPage extends Component {

  constructor (props) 
  {
    super(props)
    this.state = {}
  }

  componentDidMount () 
  {
  	Store.fetch();
  }

  render () 
  {
    return (
      <Fragment>
        <DashboardLayout>
          <main id="AdminPackagesPage" className="mainSection pb-5">
	          <MDBContainer fluid className="mt-5">
	            <PageHeading
	              label="Agent Packages & Features"
	              url={this.props?.match?.url}
	              from={this.props?.location?.state?.from}
	              goTo={(url) =>
	                this.props.history.push(url, { from: '/kpi-goals' })
	              }
	            />
	          </MDBContainer>
	          <MDBContainer fluid>
	          	<MDBRow>
	          		<MDBCol>
	          			<PackagesTable />
	          			<br />
	          			<PackageFeatureTable />
	          		</MDBCol>
	          	</MDBRow>
	          </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AdminPackagesPage