import RestApi from './../../api/rest-api-client'
const srvcEndpoint = '/ledgers'
export default {
  search: async (req, opts) => RestApi.request('GET', srvcEndpoint, req, opts),
  get: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, id, req, opts),
  store: async (data, opts) =>
    RestApi.request('POST', srvcEndpoint, data, opts),
  update: async (id, data, opts) =>
    RestApi.request('PUT', srvcEndpoint, id, data, opts),
  delete: async (id, opts) => RestApi.request('DELETE', srvcEndpoint, id, opts),

  updateBalance: async (id) =>
    RestApi.request(
      'POST',
      srvcEndpoint,
      id,
      {},
      { endpoint: `/update-balance/${id}` }
    ),
  metrics: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, id, req, {
      endpoint: `/${id}/metrics`,
    }),
  upcomingExpirations: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, id, req, {
      endpoint: `/${id}/upcoming-expirations`,
    }),
}
