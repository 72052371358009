import React from 'react'
import { observer } from 'mobx-react-lite'
import { MDBBtn, MDBIcon, MDBBtnGroup, MDBCollapse } from 'mdbreact'
import { UIInputCheckbox } from './../../../../components/forms/form-fields'
import LRCStore from './../../store/LeaderReportCard.store'
import './LeaderReportSelector.scss'

const LeaderReportSelector = ({ viewMode }) => (
  <div id="LeaderReportSelectorComponent">
    <MDBCollapse isOpen={viewMode === 'reports'}>
      <MDBBtnGroup>
        <MDBBtn
          onClick={() => LRCStore.setSourceType('division')}
          className={LRCStore.sourceType === 'division' ? 'active' : ''}
          color="info"
          size="lg"
        >
          {LRCStore.sourceType === 'division' ? (
            <MDBIcon icon="check-circle" />
          ) : (
            <MDBIcon far icon="circle" />
          )}
          &nbsp;Division
        </MDBBtn>
        <MDBBtn
          onClick={() => LRCStore.setSourceType('region')}
          className={LRCStore.sourceType === 'region' ? 'active' : ''}
          color="info"
          size="lg"
        >
          {LRCStore.sourceType === 'region' ? (
            <MDBIcon icon="check-circle" />
          ) : (
            <MDBIcon far icon="circle" />
          )}
          &nbsp;Region
        </MDBBtn>
        <MDBBtn
          onClick={() => LRCStore.setSourceType('district')}
          className={LRCStore.sourceType === 'district' ? 'active' : ''}
          color="info"
          size="lg"
        >
          {LRCStore.sourceType === 'district' ? (
            <MDBIcon icon="check-circle" />
          ) : (
            <MDBIcon far icon="circle" />
          )}
          &nbsp;District
        </MDBBtn>
        <MDBBtn
          onClick={() => LRCStore.setSourceType('career')}
          className={LRCStore.sourceType === 'career' ? 'active' : ''}
          color="info"
          size="lg"
        >
          {LRCStore.sourceType === 'career' ? (
            <MDBIcon icon="check-circle" />
          ) : (
            <MDBIcon far icon="circle" />
          )}
          &nbsp;Career
        </MDBBtn>
        <MDBBtn
          onClick={() => LRCStore.setSourceType('recruiter')}
          className={LRCStore.sourceType === 'recruiter' ? 'active' : ''}
          color="info"
          size="lg"
        >
          {LRCStore.sourceType === 'recruiter' ? (
            <MDBIcon icon="check-circle" />
          ) : (
            <MDBIcon far icon="circle" />
          )}
          &nbsp;Recruiter
        </MDBBtn>
      </MDBBtnGroup>
      <UIInputCheckbox
        className="lgr-10x-filter"
        id={`lgr_filter_10x`}
        name={`lgr_filter_10x`}
        checked={!!LRCStore.sourceFilter?.is_10x}
        label="10x Recruiters Only"
        onChange={(e) => {
          LRCStore.sourceFilter.is_10x = !LRCStore.sourceFilter?.is_10x
          // LRCStore.onSourceFilterChange({ is_10x: !LRCStore.sourceFilter?.is_10x })
        }}
      />
    </MDBCollapse>
  </div>
)

export default observer(LeaderReportSelector)
