import React, { useEffect } from 'react'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import AdminReferralForm from './../AdminReferralForm/AdminReferralForm.component'
import AdminW9Form from './../AdminW9Form/AdminW9Form.component'
import Store from './../../shared/ReferralAdmin.store'

import { MDBCollapse, MDBContainer, MDBRow, MDBCol } from 'mdbreact'

const ReferralAdmin = ({
  avId,
  referralPartnerId,
  onPaymentChange,
  onW9Change,
}) => {
  const isAdmin = UserProfileService.isA(
      ['system-admin', 'internal-admin', 'agency-owner'],
      true
    ),
    userId = UserProfileService.getUserId(),
    isOpen = isAdmin && !!avId && !!referralPartnerId

  useEffect(() => {
    if (isOpen) Store.fetch(avId, referralPartnerId)
  }, [isOpen, avId, referralPartnerId])

  return (
    <MDBCollapse isOpen={isOpen}>
      <MDBContainer id="ReferralAdminComponent" fluid>
        <MDBRow>
          <MDBCol size="12" lg="6">
            {isOpen ? (
              <AdminReferralForm
                avId={avId}
                referralPartnerId={referralPartnerId}
                userId={userId}
                Store={Store}
                onPaymentChange={onPaymentChange}
              />
            ) : (
              <></>
            )}
          </MDBCol>
          <MDBCol size="12" lg="6">
            {isOpen ? (
              <AdminW9Form
                referralPartnerId={referralPartnerId}
                Store={Store}
                onW9Change={onW9Change}
              />
            ) : (
              <></>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBCollapse>
  )
}

export default ReferralAdmin
