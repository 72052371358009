import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import {
  MDBBtn,
  MDBContainer,
  MDBDataTable,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from 'mdbreact'
import AdminApi from '../../api/admin-api/admin-api'
import { toast } from 'react-toastify'
import moment from 'moment'
import { phoneToView } from './../../shared/formatters/telephone.formatter'

class WebsiteQuoteRequests extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      confirmModal: false,
      summaryModal: false,
      viewdata: '',
      quoteRequests: [],

      data: {
        columns: [
          {
            label: 'Name',
            field: 'name',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Email',
            field: 'email',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Phone',
            field: 'phone',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Date',
            field: 'date',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'View',
            field: 'view',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Delete',
            field: 'delete',
            sort: 'asc',
            width: 270,
          },
        ],
        rows: [],
      },
    } //state
  }
  componentDidMount() {
    this.fetchQuoteContactRequest()
  }

  confirm_DeleteRequest = (data) => {
    this.confirm_toggle(data)
  }
  confirm_toggle = (data = {}) => {
    this.setState({ confirmModal: !this.state.confirmModal, ...data })
  }

  confirm_ViewRequest = (data) => {
    this.confirm_summarytoggle(data)
  }

  confirm_summarytoggle = (data = {}) => {
    this.setState({ summaryModal: !this.state.summaryModal, ...data })
  }

  fetchQuoteContactRequest = async () => {
    this.setState({ loading: true })
    AdminApi.getQuoteContactRequest('quote')
      .then((result) => {
        if (result && result.data && result.data.data) {
          this.setState(
            { quoteRequests: result.data.data || [], loading: false },
            this.setRows
          )
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  setRows = () => {
    const { quoteRequests } = this.state
    if (quoteRequests && quoteRequests.length) {
      const self = this
      let rows = quoteRequests.map((row) => {
        const { q_fname, q_lname, q_email, q_mobilephone, q_submitted } = row

        function viewRequestSummary() {
          self.confirm_ViewRequest({ viewdata: row })
        }

        function deleteRequest() {
          self.confirm_DeleteRequest({ viewdata: row })
        }

        const viewButton = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={viewRequestSummary}
            >
              <MDBIcon icon="search" size="2x" className="blue-text p-1" />
            </div>
          </div>
        )

        const deleteClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={deleteRequest}
            >
              <MDBIcon icon="trash" size="2x" className="red-text p-1" />
            </div>
          </div>
        )

        const submitted_date = (
          <span
            searchvalue={
              q_submitted ? moment(q_submitted).format('YYYY/MM/DD') : ''
            }
          >
            {q_submitted ? moment(q_submitted).format('MM/DD/YYYY') : 'N/A'}
          </span>
        )

        return {
          name: `${q_fname} ${q_lname ? q_lname : ''}`,
          email: q_email ? q_email : 'N/A',
          phone: q_mobilephone ? phoneToView(q_mobilephone) : 'N/A',
          date: submitted_date,
          view: viewButton,
          delete: deleteClick,
        }
      })
      this.setState({
        data: { ...this.state.data, rows: rows },
      })
    }
  }

  _DeleteRequest = async (id) => {
    this.setState({ deleting: true })

    AdminApi.deleteQuoteContactRequest('quote', id)
      .then((result) => {
        this.setState({ deleting: false })
        this.confirm_toggle()
        toast.success('Request successfully deleted', {
          position: toast.POSITION.TOP_RIGHT,
        })
        this.fetchQuoteContactRequest()
      })
      .catch((error) => {
        this.setState({ deleting: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  exportToCSV = () => {
    const { quoteRequests } = this.state
    const csvRows = []

    // Header row
    csvRows.push(['Name', 'Email', 'Phone', 'Date', 'Type'])

    // Data rows
    quoteRequests.forEach((quote) => {
      const name = `${quote.q_fname} ${quote.q_lname}`
      const email = quote.q_email
      const phone = quote.q_mobilephone
      const date = new Date(quote.q_submitted).toLocaleDateString()
      const type = quote.insurance_type
      csvRows.push([name, email, phone, date, type])
    })

    // Combine rows into CSV format
    const csvData = csvRows.map((row) => row.join(',')).join('\n')

    // Create a blob containing the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' })

    // Create a temporary link and click it to trigger the download
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = 'quote_requests.csv'
    link.click()
  }

  render() {
    toast.configure()
    const { loading, confirmModal, summaryModal, data, viewdata } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              {loading ? (
                <h2>Loading...</h2>
              ) : (
                <>
                  <h2>Quote Requests</h2>
                  <MDBDataTable
                    sortRows={['date']}
                    responsive
                    striped
                    bordered
                    small
                    data={data}
                    className="text-capitalize text-truncate"
                  />
                  <div className="d-flex justify-content-end">
                    <MDBBtn
                      className="btn-block w-auto export-csv-button"
                      disabled={this.state.updating}
                      color="info"
                      onClick={this.exportToCSV}
                    >
                      Download CSV
                    </MDBBtn>
                  </div>
                </>
              )}
              <MDBModal
                size={'sm'}
                isOpen={confirmModal}
                toggle={this.confirm_toggle}
              >
                <MDBModalHeader toggle={this.confirm_toggle}>
                  Delete Request
                </MDBModalHeader>
                <MDBModalBody>
                  <div className="h5">
                    Are you sure you want to delete this Request?
                  </div>
                  <MDBBtn
                    color="secondary"
                    size={'sm'}
                    onClick={this.confirm_toggle}
                  >
                    Close
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    size={'sm'}
                    onClick={() => {
                      this._DeleteRequest(this.state.viewdata.id)
                    }}
                  >
                    {this.state.deleting ? 'Deleting...' : 'Delete'}
                  </MDBBtn>
                </MDBModalBody>
              </MDBModal>

              <MDBModal
                isOpen={summaryModal}
                toggle={this.confirm_summarytoggle}
              >
                <MDBModalHeader toggle={this.confirm_summarytoggle}>
                  Summary
                </MDBModalHeader>
                <MDBModalBody>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        viewdata && viewdata.q_summary
                          ? viewdata.q_summary.replace(/\n/g, '<br />')
                          : 'N/A',
                    }}
                  />
                  <MDBBtn
                    color="secondary"
                    size={'sm'}
                    onClick={this.confirm_summarytoggle}
                  >
                    Close
                  </MDBBtn>
                </MDBModalBody>
              </MDBModal>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default WebsiteQuoteRequests
