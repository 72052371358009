class ClarityService {
  tag(key, value) {
    if (window.clarity) window.clarity('set', key, value)
  }

  login = (customDetails) => {
    const { userId, sessionId, pageId, friendlyName } =
      (typeof customDetails === 'object' && customDetails) || {}

    if (userId && !isNaN(userId))
      window.clarity('identify', userId, sessionId, pageId, friendlyName)
  }
}

const Clarity = new ClarityService(),
  ClarityTag = Clarity.tag,
  ClarityLogin = Clarity.login

export { ClarityTag, ClarityLogin }
