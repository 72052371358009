import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { MDBBtn, MDBContainer, MDBIcon } from 'mdbreact'
import MessagesList from './components/MessagesList/MessagesList.component'
import MessageForm from './components/MessageForm/MessageForm.component'
import MessageService from './../../shared/services/Message.service'
import UserProfileService from './../../shared/services/UserProfile.service'

import './MessageEditor.scss'

class MessageEditor extends Component {
  state = {
    isEditing: false,
    isCreating: false,
    messageId: null,
    canCreate: false,
  }

  #isEnabled =
    UserProfileService.isA([
      'internal-admin',
      'system-admin',
      'agency-owner',
    ]) ||
    (parseInt(this.props?.division_id) > 0 &&
      UserProfileService.isA('divisional-leader'))

  #createForm = () => (
    <MessageForm
      onSubmit={(reload) => this.#formCompleted(reload)}
      division_id={this.props?.division_id}
    />
  )

  #editForm = () => (
    <MessageForm
      onSubmit={(reload) => this.#formCompleted(reload)}
      division_id={this.props?.division_id}
      msgId={this.state.messageId}
    />
  )

  #onEdit = (msgId) =>
    this.setState({ isCreating: false, isEditing: true, messageId: msgId })

  #onCreate = () => this.setState({ isCreating: true, isEditing: false })

  #formCompleted = (reload) => {
    this.setState({ isCreating: false, isEditing: false })

    if (reload) MessageService.getMessages(0, 10, this.props?.division_id)
  }

  #renderHeader = () => {
    if (this.props?.hideHeader) {
      if (
        !this.state.isEditing &&
        !this.state.isCreating &&
        this.state.canCreate
      ) {
        return (
          <div
            style={{
              zIndex: 1,
              position: 'absolute',
              right: '20px',
              top: '30px',
            }}
          >
            <MDBBtn
              tag="a"
              gradient="blue"
              title={'Add New Message'}
              onClick={() => this.#onCreate()}
            >
              <MDBIcon icon="plus" /> Create New Message
            </MDBBtn>
          </div>
        )
      }
      return <></>
    }

    return (
      <>
        <h2>
          {this.state.isCreating
            ? 'Create New Message'
            : this.state.isEditing
            ? 'Update Message'
            : 'Message Administration'}
          {!this.state.isEditing &&
            !this.state.isCreating &&
            this.state.canCreate && (
              <div className="d-inline-block">
                <MDBBtn
                  tag="a"
                  floating
                  gradient="blue"
                  title={'Add New Message'}
                  onClick={() => this.#onCreate()}
                >
                  <MDBIcon icon="plus" />
                </MDBBtn>
              </div>
            )}
        </h2>
        <hr />
      </>
    )
  }

  #renderMessageComponent = () => {
    if (this.state.isCreating) return this.#createForm()

    if (this.state.isEditing) return this.#editForm()

    return (
      <MessagesList
        onEdit={(msgId) => this.#onEdit(msgId)}
        division_id={this.props?.division_id}
      />
    )
  }

  #checkUserCanModifyMessages = async () => {
    try {
      this.setState({
        canCreate: UserProfileService.canUser('messages.dashboard.create'),
      })
    } catch (ex) {
      console.error(
        'ERROR: Failed to check user permissions in MessageEditor.  ',
        ex
      )
    }
  }

  componentDidMount() {
    this.#checkUserCanModifyMessages();
    
    const queryParams = new URLSearchParams(this.props.location.search);
    const id = queryParams.get('id');
    if(id) this.#onEdit(id)
  }

  render() {
    if (!this.#isEnabled) return <></>

    return (
      <MDBContainer fluid className="mt-0">
        {this.#renderHeader()}
        {this.#renderMessageComponent()}
      </MDBContainer>
    )
  }
}

export default withRouter(MessageEditor)
