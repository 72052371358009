import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import {
  defineSubNavOpt,
  categoryHasReports,
  getDefaultReport,
} from './../../helpers'

import './ReportSelector.scss'

const ucwords = (str) =>
  str
    .replace(/^([a-z])|[\s_-]+([a-z])/g, ($1) => $1.toUpperCase())
    .replace(/-/g, ' ')

const ReportSelector = ({ category, report, onChange }) => {
  const [subNavOpt, setSubNavOpt] = useState(
    defineSubNavOpt(category, report).subNavOpt
  )

  const isFetching = false

  const changeReport = (event, report) => {
    event.preventDefault()
    onChange({ report })
    return false
  }

  const getName = (str) =>
    ucwords(`${str ? str : ''}`).replace(/All Time$/, '(All Time)')

  useEffect(() => {
    setSubNavOpt(defineSubNavOpt(category, report).subNavOpt)

    // is report valid?
    if (categoryHasReports(category) && !report) {
      let r = getDefaultReport(category)
      if (r) onChange({ report: r })
    }
  }, [category, report, onChange])

  return (
    <ul id="ReportSelector" className="row mb-4">
      {subNavOpt && typeof subNavOpt === 'object' ? (
        Object.keys(subNavOpt).map((key) => (
          <li
            className={[
              isFetching ? 'disabled' : '',
              key === report ? 'active' : '',
            ].join(' ')}
            key={'reporting-opt-' + key.replace(/\//g, '-')}
          >
            <a
              onClick={(event) => changeReport(event, key)}
              href={`/reporting/${category}/${key}`}
            >
              {getName(key)}
            </a>
          </li>
        ))
      ) : (
        <></>
      )}
    </ul>
  )
}

export default observer(ReportSelector)
