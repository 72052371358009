import StripeCustomerService from './../../../shared/services/StripeCustomer.service'
import StripeSubscriptionService from './../../../shared/services/StripeSubscription.service'
import AdminApi from './../../../api/admin-api/admin-api'
import { BehaviorSubject } from 'rxjs'
import { getIp } from './../../../shared/utilities/getIp.function'

const customerSubject = new BehaviorSubject(null)
const subscriptionSubject = new BehaviorSubject(null)

const setDefaultPaymentMethod = async (customerId, paymentMethodId) => {
  if (!customerId)
    throw new Error(
      'Invalid customerId argument supplied to BillingService.setDefaultPaymentMethod().'
    )
  if (!paymentMethodId)
    throw new Error(
      'Invalid paymentMethodId argument supplied to BillingService.setDefaultPaymentMethod().'
    )

  try {
    await StripeCustomerService.setDefaultPaymentMethod(customerId, {
      payment_method_id: paymentMethodId,
    })
  } catch (ex) {
    throw new Error(ex)
  }

  return await fetchCustomer(customerId)
}

const deletePaymentMethod = async (customerId, paymentMethodId) => {
  if (!customerId)
    throw new Error(
      'Invalid customerId argument supplied to BillingService.deletePaymentMethod().'
    )
  if (!paymentMethodId)
    throw new Error(
      'Invalid paymentMethodId argument supplied to BillingService.deletePaymentMethod().'
    )

  try {
    await StripeCustomerService.deletePaymentMethod(customerId, {
      payment_method_id: paymentMethodId,
    })
  } catch (ex) {
    throw new Error(ex)
  }

  return await fetchCustomer(customerId)
}

const createSubscription = async (customerId, data) => {
  if (!customerId)
    return Promise.reject(
      'Invalid customerId argument supplied to BillingService.createSubscription().'
    )

  let subscription
  try {
    subscription = await StripeSubscriptionService.store({
      customer: customerId,
      ...data,
      subscribingIp: await getIp(),
    })
    if (subscription && subscription.id) fetchSubscription(subscription.id)
  } catch (ex) {
    return Promise.reject(ex)
  }

  subscription = subscription ? subscription : undefined
  subscriptionSubject.next(subscription)
  return subscription
}

const cancelSubscription = async (subscriptionId) => {
  if (!subscriptionId)
    return Promise.reject(
      'Invalid subscriptionId argument supplied to BillingService.cancelSubscription().'
    )

  let subscription
  try {
    subscription = await StripeSubscriptionService.delete(subscriptionId)
  } catch (ex) {
    return Promise.reject(ex)
  }

  fetchSubscription(subscriptionId)
  subscription = subscription ? subscription : undefined
  return subscription
}

const fetchCustomerId = async (userId, subClass) => {
  if (!userId)
    return Promise.reject(
      'Invalid userId argument supplied to BillingService.fetchCustomerId().'
    )

  let customerId
  try {
    customerId = await StripeCustomerService.search({
      search: { user_id: userId, sub_class: subClass },
    })
    customerId =
      customerId && customerId?.models && customerId.models.length
        ? customerId.models.shift().id
        : null
  } catch (ex) {
    return Promise.reject(ex)
  }

  return customerId && customerId.length ? customerId : undefined
}

const createCustomer = async (name, email, phone, userId, subClass) => {
  if (!userId)
    return Promise.reject(
      'Invalid userId argument supplied to BillingService.createCustomer().'
    )

  let customer
  try {
    customer = await StripeCustomerService.createByUserId(userId, {
      subClass,
      customerIp: await getIp(),
    })
    if (customer && customer.id) fetchCustomer(customer.id)
  } catch (ex) {
    return Promise.reject(ex)
  }

  customer = customer ? customer : undefined
  customerSubject.next(customer)
  return customer
}

const fetchCustomer = async (customerId) => {
  if (!customerId)
    return Promise.reject(
      'Invalid customerId argument supplied to BillingService.fetchCustomer().'
    )

  let customer
  try {
    customer = await StripeCustomerService.get(customerId, {
      expand: ['customer', 'paymentMethod'],
    })
  } catch (ex) {
    return Promise.reject(ex)
  }

  customer = customer ? customer : undefined
  customerSubject.next(customer)
  return customer
}

const fetchSubscriptionId = async (customerId) => {
  if (!customerId)
    return Promise.reject(
      'Invalid customerId argument supplied to BillingService.fetchSubscriptionId().'
    )

  let subscriptionId
  try {
    subscriptionId = await StripeSubscriptionService.search({
      search: { customer_id: customerId },
      order_by: { started_at: 'DESC' },
    })
    subscriptionId =
      subscriptionId && subscriptionId?.models && subscriptionId.models.length
        ? subscriptionId.models.shift().id
        : null
  } catch (ex) {
    return Promise.reject(ex)
  }

  return subscriptionId && subscriptionId.length ? subscriptionId : undefined
}

const fetchSubscription = async (subscriptionId) => {
  if (!subscriptionId)
    return Promise.reject(
      'Invalid subscriptionId argument supplied to BillingService.fetchSubscription().'
    )

  let subscription
  try {
    subscription = await StripeSubscriptionService.get(subscriptionId, {
      expand: ['subscription'],
    })
  } catch (ex) {
    return Promise.reject(ex)
  }

  subscription = subscription ? subscription : undefined
  subscriptionSubject.next(subscription)
  return subscription
}

const notifyBrokerSupport = async (params) => {
  // old admin api 'downgradeToAffiliate' has been updated
  // to downgrade the agents account & send a notification
  // email.  The notification is CC  to broker support &
  // the agents upline.
  return await AdminApi.downgradeToAffiliate(params)
}

const getStatus = () => {
  let sub = subscriptionSubject.getValue()
  return sub && sub.sub_status
}

const isSubscriptionCanceled = () => getStatus() === 'canceled'
const isSubscriptionActive = () => getStatus() === 'active'
const isSubscriptionInactive = () =>
  ['incomplete', 'incomplete_expired', 'trialing', 'unpaid'].indexOf(
    getStatus()
  ) > -1
const isSubscriptionPastDue = () => getStatus() === 'past_due'

const notifySysAdminPayment = async (customerId) => {
  if (!customerId)
    throw new Error(
      'Invalid customerId argument supplied to BillingService.notifySysAdminPayment().'
    )

  try {
    return await StripeCustomerService.notifyPaymentAdded(customerId)
  } catch (ex) {
    throw new Error(ex)
  }
}

const BillingService = {
  // Customers -----------------------------------
  fetchCustomerId: fetchCustomerId,
  fetchCustomer: fetchCustomer,
  getCustomer: () => customerSubject,
  createCustomer: createCustomer,

  // Payment Methods -----------------------------
  setDefaultPaymentMethod: setDefaultPaymentMethod,
  deletePaymentMethod: deletePaymentMethod,

  // Subscriptions -------------------------------
  fetchSubscriptionId: fetchSubscriptionId,
  fetchSubscription: fetchSubscription,
  getSubscription: () => subscriptionSubject,
  createSubscription: createSubscription,
  cancelSubscription: cancelSubscription,
  notifyBrokerSupport: notifyBrokerSupport,
  isCanceled: isSubscriptionCanceled,
  isActive: isSubscriptionActive,
  isInactive: isSubscriptionInactive,
  isPastDue: isSubscriptionPastDue,

  /*TODO:REMOVE*/
  //Temp while migrating cart32 / shift4shop / iboom clients.
  notifySysAdminPayment,
}

export default BillingService
