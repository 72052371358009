import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { MDBIcon, MDBBadge } from 'mdbreact'
import UserAlertsStore from './UserAlerts.store'

const UserAlertsIndicator = ({ userId, goTo, location }) => {
  const [count, setCount] = useState(UserAlertsStore.count('unacknowledged')),
    [currPath, setCurrPath] = useState(null)

  const getCurrPath = () =>
    location?.pathname + (location?.search ? location.search : '')

  useEffect(() => {
    if (userId)
      UserAlertsStore.fetch(userId).finally(() => {
        let c = UserAlertsStore.count('unacknowledged')
        if (!isNaN(c) && parseInt(c) !== parseInt(count)) setCount(parseInt(c))
      })
  }, [userId, count, UserAlertsStore.numberOfAlerts])

  if (
    userId &&
    !isNaN(userId) &&
    !UserAlertsStore.isReqModalOpen &&
    typeof location === 'object' &&
    Object.keys(location).length > 0
  ) {
    if (
      UserAlertsStore.currPath !== getCurrPath() &&
      UserAlertsStore.nextPath !== getCurrPath()
    ) {
      UserAlertsStore.checkRequirements(userId, getCurrPath()).then(
        (response) => {
          if (response && typeof response === 'string') goTo(response)
        }
      )
    }
  }

  return (
    <div
      onClick={() =>
        typeof goTo === 'function' ? goTo('/account-alerts') : undefined
      }
    >
      <div>
        <MDBIcon icon="bell" className="d-inline-inline" />
      </div>
      <div className="d-none d-sm-block">Alerts</div>
      {count > 0 ? (
        <div>
          <MDBBadge color="danger" className="ml-2">
            {count}
          </MDBBadge>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default observer(UserAlertsIndicator)
