import React from 'react'
import { observer } from 'mobx-react-lite'
import ContentHtml from '../../../components/content/ContentHtml/ContentHtml.component'
import UserProfileService from '../../../shared/services/User.service'
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact'
import { useState } from 'react'
import { useEffect } from 'react'
import { MDBAlert } from 'mdbreact'
import LoadingSpinner from '../../../components/shared/LoadingSpinner.component'

const PointsRedemtion = () => {
  const [enableRymax, setEnableRymax] = useState(false)
  const [loading, setLoading] = useState(true)

  const getPipLevel = async () => {
    const response = await UserProfileService.getPipLevel()
    setEnableRymax(response.level >= 8)
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    const fullYear = new Date().getFullYear()
    if (fullYear < 2024) {
      setEnableRymax(true)
      setLoading(false)
    } else {
      getPipLevel()
    }
  }, [])

  return (
    <MDBCard narrow id="PointsRedemptionComponent" className="points-component">
      <MDBCardBody className="pip-points-redemption">
        <MDBCardTitle>USABG BUCK$ Redemption</MDBCardTitle>
        <MDBRow>
          <MDBCol size="12">
            <ContentHtml contentSlug="pip-points-redemption-usabg" />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12">
            {!enableRymax && !loading ? (
              <MDBAlert color="danger">
                You must reach Diamond level to access this feature.
              </MDBAlert>
            ) : (
              <></>
            )}
          </MDBCol>
          <MDBCol size="12">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
                <div className={enableRymax ? '' : 'pip-locked'}>
                  <ContentHtml contentSlug="pip-points-redemption-rymax" />
                </div>
              </>
            )}
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  )
}

export default observer(PointsRedemtion)
