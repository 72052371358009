import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  UIInput,
  UIDatePickerInput,
} from './../../../../components/forms/form-fields'
import MediaUploaderModal from './../../../../components/modals/MediaUploaderModal'
import {
  MDBAlert,
  MDBCollapse,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBBtn,
  MDBIcon,
} from 'mdbreact'
import ReferralPartnerW9Factory from './../../../../shared/factories/referral-partner-w9.factory'
import Store from './../../shared/Referrals.store'
import moment from 'moment'

import './W9Manager.component.scss'

const W9Manager = () => {
  const [formData, setFormData] = useState({ w9_year: '', received_at: null }),
    [formValidity, setFormValidity] = useState({
      w9_year: false,
      received_at: false,
    }),
    [showValidity, setShowValidity] = useState(false),
    [formError, setFormError] = useState(null),
    [isSaving, setIsSaving] = useState(false)

  const isValid = () =>
    Object.values(formValidity).filter((v) => v === false).length === 0

  const onKeyDown = (evt) => {
    try {
      if (evt.key.toLowerCase() === 'enter' && !showValidity) onSubmit()
    } catch (ex) {}
  }

  const onSubmit = async (event) => {
    if (event) event.preventDefault()

    if (!isValid()) {
      setShowValidity(true)
      return false
    }

    if (formError) setFormError(null)

    const W9 =
        formData?.id && !isNaN(formData.id)
          ? Store.W9s.filter(
              (W9) => parseInt(W9.id()) === parseInt(formData.id)
            ).shift()
          : ReferralPartnerW9Factory.create({
              referral_partner_id: Store.ReferralPartner.id(),
            }),
      isNew = W9 && W9.isNew()

    W9.set('w9_year', formData.w9_year)
    W9.set('received_at', moment(formData.received_at).format('YYYY-MM-DD'))

    setIsSaving(true)
    try {
      await W9.save()
      setIsSaving(false)
    } catch (ex) {
      setFormError(`${ex}`)
      setIsSaving(false)
      return false
    }

    if (isNew) Store.W9s.push(W9)
    else
      Store.W9s = Store.W9s.map((W) =>
        parseInt(W.id()) === parseInt(W9.id()) ? W9 : W
      )

    if (parseInt(Store.ReferralPartner.get('w9_onfile')) !== 1) {
      Store.ReferralPartner.set('w9_onfile', 1)
      Store.ReferralPartner.save()
    }

    setFormData({ w9_year: '', received_at: moment().toDate() })
    setFormValidity({ w9_year: false, received_at: false })
    setShowValidity(false)

    return false
  }

  const onUpload = (W9) => {
    MediaUploaderModal.open({
      uploadType: 'referral_partner_w9',
      modalContentTop: (
        <>
          <p className="text--center fw--500">
            <h6 className="fw--500">
              Upload W9 for {Store.ReferralPartner.get('name')}.
            </h6>
          </p>
        </>
      ),
      appendFormData: {
        w9_id: W9.id(),
        referral_partner_id: Store.ReferralPartner.id(),
      },
      validationRules: { contentType: ['application/pdf'] },
      preventAutoClose: true,
      onSuccess: () => {
        console.log('media uploader success')
      },
    })
      .then(async (filePath) => {
        console.log('W9 uploaded to: ', filePath)
      })
      .catch((err) => {
        if (err)
          console.log('Failed to complete uploading referral partner W9.', err)
      })
  }

  const onDownload = async (W9) => {
    let base64
    try {
      base64 = await W9.download()
    } catch (ex) {
      console.error(`${ex}`)
    }

    if (base64) {
      const downloadLink = document.createElement('a')
      const fileName = decodeURIComponent(W9.get('w9_filepath'))
        .split('/')
        .pop()
      downloadLink.href = base64.base64
      downloadLink.download = fileName || 'w9.pdf'
      downloadLink.click()
    }
  }

  const onEdit = async (W9) => {
    setFormData({
      id: W9.id(),
      w9_year: W9.get('w9_year'),
      received_at: W9.get('received_at')
        ? moment(`${W9.get('received_at')}`.split(/\T|\ /).shift()).toDate()
        : null,
    })
    setFormValidity({ w9_year: false, received_at: false })
    setShowValidity(false)
  }

  const onDelete = async (W9) => {
    try {
      const id = parseInt(W9.id())
      await W9.delete()
      Store.W9s = Store.W9s.filter((W9) => parseInt(W9.id()) !== id)
    } catch (ex) {
      console.error(`Failed to delete W9 record ${ex}`)
    }
  }

  return (
    <div id="W9ManagerComponent">
      <MDBCollapse
        isOpen={!!Store.ReferralPartner && Store.ReferralPartner.id() > 0}
      >
        <MDBCard>
          <MDBCardHeader>Referral Partner W9s</MDBCardHeader>
          <MDBCardBody>
            <MDBRow>
              <MDBCol>
                <h6>{formData?.id ? 'Update Existing ' : 'Create New '}W9</h6>
              </MDBCol>
            </MDBRow>
            <form className="row w9-form" noValidate onSubmit={onSubmit}>
              <MDBCol>
                <UIInput
                  maxlength="4"
                  label="W-9 Year *"
                  value={formData.w9_year}
                  name="w9_year"
                  required={true}
                  onChange={(e) =>
                    setFormData((prevW9) => ({
                      ...prevW9,
                      w9_year: e?.target?.value,
                    }))
                  }
                  onValidityChange={(validity) =>
                    setFormValidity((prevValidity) => ({
                      ...prevValidity,
                      w9_year: validity.isValid,
                    }))
                  }
                  showValidity={showValidity}
                  onKeyDown={onKeyDown}
                />
              </MDBCol>
              <MDBCol>
                <UIDatePickerInput
                  label="Date Received"
                  name="received_at"
                  id="date_received_at"
                  onChange={(e) =>
                    setFormData((prevW9) => ({
                      ...prevW9,
                      received_at: e?.target?.value,
                    }))
                  }
                  required={true}
                  dateFormat="MM/dd/yyyy"
                  maxDate={new Date()}
                  selected={formData.received_at}
                  rules={{ required: true }}
                  showValidity={showValidity}
                  onValidityChange={(validity) =>
                    setFormValidity((prevValidity) => ({
                      ...prevValidity,
                      received_at: validity.isValid,
                    }))
                  }
                  onKeyDown={onKeyDown}
                />
              </MDBCol>
              <MDBCol>
                <MDBBtn
                  disabled={
                    !isValid() ||
                    isSaving ||
                    Store.isLoading ||
                    Store.isSearching ||
                    Store.isSaving
                  }
                  type="submit"
                >
                  {isSaving ? (
                    <i className="fa fa-spin fa-spinner" />
                  ) : (
                    <MDBIcon icon="save" />
                  )}
                </MDBBtn>
              </MDBCol>
              <MDBCol>
                <MDBBtn
                  disabled={
                    isSaving ||
                    Store.isLoading ||
                    Store.isSearching ||
                    Store.isSaving
                  }
                  type="button"
                  onClick={() =>
                    setFormData({ w9_year: '', received_at: null })
                  }
                >
                  <MDBIcon icon="times" />
                </MDBBtn>
              </MDBCol>
            </form>
            <MDBRow>
              <MDBCol>
                <h6>Existing W9s</h6>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="12">
                {Store.isLoading ? (
                  <i className="fa fa-spin fa-spinner" />
                ) : (
                  <></>
                )}
                {!Store.isLoading && (Store.W9s.length === 0 || formError) ? (
                  <MDBAlert color="danger">
                    <MDBIcon icon="exclamation-triangle" />
                    <strong>
                      {formError
                        ? formError
                        : 'This referral partner does not have a w9 on file.'}
                    </strong>
                  </MDBAlert>
                ) : (
                  <></>
                )}
                <ul className="rp-w9-list">
                  {Store.W9s.length > 0 ? (
                    <li>
                      <div className="w9-year">W9 Year</div>
                      <div className="w9-received-at">Received Date</div>
                      <div className="w9-upload">Upload</div>
                      <div className="w9-download">Download</div>
                      <div className="w9-edit">Edit</div>
                      <div className="w9-delete">Delete</div>
                    </li>
                  ) : (
                    <></>
                  )}
                  {Store.W9s.map((W9, idx) => {
                    return (
                      <li key={`rp-w9-${W9.id()}-${idx}`}>
                        <div className="w9-year">{W9.get('w9_year')}</div>
                        <div className="w9-received-at">
                          {W9.get('received_at')
                            ? moment(
                                `${W9.get('received_at')}`
                                  .split(/\T|\ /)
                                  .shift()
                              ).format('MM/DD/YYYY')
                            : null}
                        </div>
                        <div className="w9-upload">
                          <MDBBtn block onClick={() => onUpload(W9)}>
                            <MDBIcon icon="upload" />
                          </MDBBtn>
                        </div>
                        <div className="w9-download">
                          <MDBBtn
                            block
                            disabled={!W9.get('w9_filepath')}
                            onClick={() => onDownload(W9)}
                          >
                            <MDBIcon icon="download" />
                          </MDBBtn>
                        </div>
                        <div className="w9-edit">
                          <MDBBtn
                            block
                            disabled={
                              isSaving ||
                              Store.isLoading ||
                              Store.isSearching ||
                              Store.isSaving
                            }
                            onClick={() => onEdit(W9)}
                          >
                            <MDBIcon icon="edit" />
                          </MDBBtn>
                        </div>
                        <div className="w9-delete">
                          <MDBBtn
                            block
                            disabled={
                              isSaving ||
                              Store.isLoading ||
                              Store.isSearching ||
                              Store.isSaving
                            }
                            onClick={() => onDelete(W9)}
                          >
                            <MDBIcon icon="trash" />
                          </MDBBtn>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </MDBCollapse>
    </div>
  )
}

export default observer(W9Manager)
