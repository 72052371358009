import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { MDBContainer, MDBDataTable, MDBIcon } from 'mdbreact'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'

class ProductLinks extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      productLinksList: [],
      data: {
        columns: [
          {
            label: 'Coverage',
            field: 'coverage',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Carrier',
            field: 'carrier',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Link',
            field: 'link',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Update',
            field: 'update',
            sort: 'asc',
            width: 270,
          },
        ],
        rows: [
          /*{
                        coverage: 'Accident',
                        carrier: 'VBA',
                        link: 'www.goggle.com',
                        update: (
                            <a href="/edit-product-link">
                                <MDBIcon icon="edit" size="2x" className="d-flex justify-content-center blue-text" />
                            </a>
                        )
                    },
                    {
                        coverage: 'Accident',
                        carrier: 'VBA',
                        link: 'www.goggle.com',
                        update: (
                            <a href="/edit-product-link">
                                <MDBIcon icon="edit" size="2x" className="d-flex justify-content-center blue-text" />
                            </a>
                        )
                    },
                    {
                        coverage: 'Accident',
                        carrier: 'VBA',
                        link: 'www.goggle.com',
                        update: (
                            <a href="/edit-product-link">
                                <MDBIcon icon="edit" size="2x" className="d-flex justify-content-center blue-text" />
                            </a>
                        )
                    }*/
        ],
      },
    } // state
  }

  componentDidMount() {
    this.fetchProductLinksList()
  }

  fetchProductLinksList = async () => {
    this.setState({ loading: true })
    AdminApi.getProductLinksList()
      .then((result) => {
        if (result && result.data && result.data.data) {
          this.setState(
            { productLinksList: result.data.data || [] },
            this.setRows
          )
        }
        this.setState({ loading: false })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  setRows = () => {
    const { productLinksList } = this.state
    if (productLinksList && productLinksList.length) {
      const self = this
      let rows = productLinksList.map((row) => {
        const { usercarrier_id, coverage_name, c_name, c_website_link } = row

        function editLeadSource() {
          self.props.history.push(`/product-links/${usercarrier_id}/edit`)
        }

        const editClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={editLeadSource}
            >
              <MDBIcon icon="edit" size="2x" className="blue-text p-1" />
            </div>
          </div>
        )

        return {
          coverage: coverage_name,
          carrier: c_name,
          link: c_website_link ? c_website_link : '',
          update: editClick,
        }
      })
      this.setState({
        data: { ...this.state.data, rows: rows },
      })
    }
  }

  render() {
    toast.configure()
    const { loading, data } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              {loading ? (
                <h2>Loading...</h2>
              ) : (
                <>
                  <h2>Product Links</h2>
                  <MDBDataTable
                    responsive
                    striped
                    bordered
                    small
                    data={data}
                    className="text-capitalize text-truncate"
                  />
                </>
              )}
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default ProductLinks
