import React from 'react'
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBModalHeader,
  MDBModalBody,
  MDBModal,
  MDBSwitch,
} from 'mdbreact'
import AdminApi from '../../api/admin-api/admin-api'
import StorageService from './../../shared/services/Storage.service'
import { toast } from 'react-toastify'

import './AdminContentEditPopup.scss'

class ChangeUserStatusInListComponent extends React.Component {
  state = {
    updating: false,
    showModal: false,
  }

  onToggle = () => {
    if (parseInt(this.props.selectedRow?.u_active) === 1)
      this.toggleModal({ viewdata: this.props.selectedRow })
    else {
      this.setState({ viewdata: this.props.selectedRow }, () =>
        this.updateUserStatus()
      )
    }
  }

  toggleModal = (data = {}) =>
    this.setState({ showModal: !this.state.showModal, ...data })

  updateUserStatus = () => {
    if (this.state.updating) return

    this.setState({ updating: true })

    const userData = JSON.parse(StorageService.get('user_details')),
      data = {
        id: this.state.viewdata.id,
        u_active: parseInt(this.state.viewdata.u_active) !== 1 ? 1 : 0,
        u_changed: StorageService.get('parent_id')
          ? StorageService.get('parent_id')
          : (userData || {})?.id,
        u_changed_name: StorageService.get('parent_name')
          ? StorageService.get('parent_name')
          : `${(userData || {})?.u_fname ? userData.u_fname : ''} ${
              (userData || {})?.u_lname ? userData.u_lname : ''
            }`,
        // address_flag: 					0,
      },
      form_data = new FormData(),
      msg = data.u_active
        ? 'Backlist removed from agent account.'
        : 'Agent account has been blacklisted.'

    Object.keys(data).forEach((key) => form_data.append(key, data[key]))

    AdminApi.updateProfile(form_data)
      .then((result) => {
        this.setState({ updating: false }, this.props.callback)
        this.toggleModal('', false)
        toast.success(msg, { position: toast.POSITION.TOP_RIGHT })
      })
      .catch((error) => {
        this.setState({ showModal: false, updating: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  render() {
    const { showModal } = this.state
    const { u_active } = this.props.selectedRow

    return (
      <>
        <MDBSwitch
          labelLeft={'No'}
          labelRight={'Yes'}
          checked={parseInt(u_active) !== 1}
          onChange={this.onToggle}
        />
        <MDBModal
          size={'md'}
          isOpen={showModal}
          toggle={this.toggleModal}
          className="preventTextOverflow"
        >
          <MDBModalHeader toggle={this.toggleModal} className="red">
            Update Agent Status
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            <div className="h5" style={{ padding: '25px' }}>
              Are you sure you want to blacklist this Agent's account?
            </div>
            <MDBRow>
              <MDBCol size="12" sm="6">
                <MDBBtn
                  className="btn-block"
                  disabled={this.state.updating}
                  color="secondary"
                  size={'sm'}
                  onClick={this.toggleModal}
                >
                  No, Don't Blacklist
                </MDBBtn>
              </MDBCol>
              <MDBCol size="12" sm="6">
                <MDBBtn
                  className="btn-block"
                  disabled={this.state.updating}
                  color="primary"
                  size={'sm'}
                  onClick={() => {
                    this.updateUserStatus()
                  }}
                >
                  {this.state.updating ? (
                    <>
                      <i className="fa fa-spin fa-spinner"></i>&nbsp;Updating
                      ...
                    </>
                  ) : (
                    'Yes, Blacklist Agent.'
                  )}
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
      </>
    )
  }
}

export default ChangeUserStatusInListComponent
