import RestApi from './../../api/rest-api-client'
const srvcEndpoint = '/calendar_events'
export default {
  search: async (req, opts) => RestApi.request('GET', srvcEndpoint, req, opts),
  get: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, id, req, opts),
  store: async (data, opts) =>
    RestApi.request('POST', srvcEndpoint, data, opts),
  update: async (id, data, opts) =>
    RestApi.request('PUT', srvcEndpoint, id, data, opts),
  delete: async (id, opts) => RestApi.request('DELETE', srvcEndpoint, id, opts),

  detail: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, id, req, {
      endpoint: `/${id}/details`,
    }),
  getBefore: async (date, req, opts) =>
    RestApi.request('GET', srvcEndpoint, req, {
      endpoint: `/events/before/${date}`,
    }),
  getByDateSpan: async (start, end, opts) =>
    RestApi.request(
      'GET',
      srvcEndpoint,
      {},
      { endpoint: `/events/${start}/${end}` }
    ),
  dayByDate: async (date, req, opts) =>
    RestApi.request('GET', srvcEndpoint, req, {
      endpoint: `/dates/day/${date}`,
    }),
  syncEvents: async (data, opts) =>
    RestApi.request('POST', srvcEndpoint, data, { endpoint: `/sync` }),
}
