import BaseModelInstance from './base.model'
import { TermSet as ModelDefinition } from './model-definitions'
import { getValue } from './helpers'

const replaceTerms = (termStmt, Terms, TermSet) =>
{
	termStmt = termStmt && `${termStmt}`.trim()

	if (termStmt) {
		let matches = termStmt.split('[').map(s => `[${s}`).map(m => m.match(/(\[term_\d+\])/)).filter(s => s).map(s => s.shift());
		if (Array.isArray(matches) && matches.length > 0) {
			matches.forEach(match => {

				let Term = Terms.filter(Term => parseInt(Term.id()) === parseInt(match.match(/\[term_(\d+)\]/)[1])).shift();
				if (Term) {
					TermSet.termIds.push(Term.id());
					termStmt = termStmt.replace(`${match}`, `<a href='${Term.get('term_link')}' rel="noopener noreferrer" target='_BLANK'>${Term.get('term_name')}</a>`);
				}
				else {
					termStmt = termStmt.replace(`${match}`, '');
				}
			});
		}
	}

	return termStmt;
}

const replaceLinks = (termStmt) =>
{
	termStmt = termStmt && `${termStmt}`.trim()

	if (termStmt) {
		let matches = termStmt.split('[').map(s => `[${s}`).map(m => m.match(/(\[.+\])/)).filter(s => s).map(s => s.shift()); 
		if (Array.isArray(matches) && matches.length > 0) {
			matches.forEach(match => {
				if (/\[.+\|.+\]/.test(match)) {
					termStmt = termStmt.replace(`${match}`, `<a href='${match.match(/\|(.+)\]/)[1]}' rel="noopener noreferrer" target='_BLANK'>${match.match(/\[(.+)\|/)[1]}</a>`);
				}
				else {
					termStmt = termStmt.replace(`${match}`, `<a href='${match.match(/\[(.+)\]/)[1]}' rel="noopener noreferrer" target='_BLANK'>${match.match(/\[(.+)\]/)[1]}</a>`);
				}
			});
		}
	}

	return termStmt;
}

class TermSet extends BaseModelInstance {
  afterSet = (key, value) => {}

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }


  parseTermStmt = ({Terms}) =>
  {
  	this.termIds = [];
  	this.set('term_set', getValue(this.get('term_set'), 'array').map(termStmt => replaceTerms(termStmt, Terms, this)).map(termStmt => replaceLinks(termStmt)));
  }
}

export default TermSet
