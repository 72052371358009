import React, { useState } from 'react'
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact'
import ContentHtml from '../../../../components/content/ContentHtml/ContentHtml.component'

export const VanityDomainInstructions = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  return (
    <>
      <MDBBtn
        color="indigo"
        onClick={() => setIsOpenModal(true)}
        className="mt-5 mx-0 btn-block"
      >
        Vanity Domain Instructions
      </MDBBtn>
      <MDBModal isOpen={isOpenModal} toggle={() => setIsOpenModal(false)}>
        <MDBModalHeader>Vanity Domain Instructions</MDBModalHeader>
        <MDBModalBody>
          <ContentHtml contentSlug="subsite-vanity-domain-howto" />
        </MDBModalBody>
      </MDBModal>
    </>
  )
}
