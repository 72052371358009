import { makeAutoObservable } from 'mobx'
import AgentPackageFactory from './../../shared/factories/agent-package.factory';
import AgentPackageFeatureFactory from './../../shared/factories/agent-package-feature.factory';
import AgentPackagesFeaturesFactory from './../../shared/factories/agent-packages-features.factory';

const getPackageFeatures = (Package, Feature, PackageFeatures) => 
	PackageFeatures.filter(PF => parseInt(PF.get('feature_id')) === parseInt(Feature.id()) && parseInt(PF.get('pkg_id')) === parseInt(Package.id()));

const getPackageFeature = (Package, Feature, PackageFeatures) => 
	getPackageFeatures(Package, Feature, PackageFeatures).shift();

class AgentPackagesStore {
  constructor() {
    makeAutoObservable(this)
  }

  _fetched = null;
  _saving = [];
  isFetching = false;

  Packages = [];
  Features = [];
  PackageFeatures = [];

  packageIncludesFeature = (Package, Feature) =>
  	this.PackageFeatures.filter(PF => parseInt(PF.get('feature_id')) === parseInt(Feature.id()) && parseInt(PF.get('pkg_id')) === parseInt(Package.id())).length > 0;

  deleteFeature = async () =>
  {
  	if (this.DeleteFeature) {
  		this.Features = this.Features.filter(Feature => parseInt(Feature.id()) !== parseInt(this.DeleteFeature.id()));

  		const PackageFeatures = this.Packages.map(Package => getPackageFeature(Package, this.DeleteFeature))
  		if (PackageFeatures && Array.isArray(PackageFeatures) && PackageFeatures.length > 0)
  			await Promise().all(PackageFeatures.map((Pkg) => this.removePackageFeature(Pkg, this.DeleteFeature)))

  		this.DeleteFeature.delete();
  		this.DeleteFeature = null;
  	}
  }

  fetch = async () => 
  {
  	this.isFetching = true;
  	const [Packages, Features, PackageFeatures] = await Promise.all([
  			AgentPackageFactory.search({pagination: false}),
  			AgentPackageFeatureFactory.search({pagination: false}),
  			AgentPackagesFeaturesFactory.search({pagination: false})
  		]);

  	this.Packages = Packages.map(Package => {
  		if (Package.get('price_ids') && typeof Package.get('price_ids') === 'string')
  			Package.set('price_ids',JSON.parse(Package.get('price_ids')).join(','))
  		return Package;
  	}).sort((a,b) => `${a.get('position')}`.localeCompare(`${b.get('position')}`, 'en', {numeric: true}));

  	this.Features = (Features||[]).sort((a,b) => `${a.get('position')}`.localeCompare(`${b.get('position')}`, 'en', {numeric: true}));
  	this.PackageFeatures = PackageFeatures||[];

  	this._fetched = true;
  	this.isFetching = false;
  }
}

export default new AgentPackagesStore()