import { makeAutoObservable } from 'mobx'

class UsertypeBlockModalStore {
  isOpen = false
  isReadonly = false
  pageName = ''

  constructor() {
    makeAutoObservable(this)
  }

  setIsOpen = (isOpen, pageName) => {

    if (isOpen && typeof isOpen === 'string') {
      this.errMsg = isOpen;
      isOpen = true;
    }
    else {
      this.errMsg = "";
    }

    this.isOpen = isOpen
    this.isReadonly = isOpen
    this.pageName = pageName
  }

  toggle = () => {
    this.isOpen = !this.isOpen
  }
}

export default new UsertypeBlockModalStore()
