import React from 'react'
import { observer } from 'mobx-react-lite'
import AgentProfileStore from './../AgentProfileIntake/AgentProfile.store'
import { MDBIcon } from 'mdbreact'
import './IntakeStepIndicator.scss'

const IntakeStepIndicator = ({ fieldLabel, fieldName }) => (
  <h5 className="IntakeStepIndicator">
    <span
      className={
        'validity ' +
        (AgentProfileStore.validity[fieldName] ? 'valid' : 'invalid')
      }
    >
      <MDBIcon
        icon={!!AgentProfileStore.validity[fieldName] ? 'check' : 'times'}
      />
    </span>
    &nbsp;{fieldLabel}:
  </h5>
)

export default observer(IntakeStepIndicator)
