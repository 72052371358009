import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  MDBInput,
  MDBAlert,
  MDBCollapse,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from 'mdbreact'
import CarrierStore from './../Carrier.store'
import DatePicker from 'react-datepicker'
import ACMStore from './../AgentContractManager.store'
import moment from 'moment'
import { isAdminUser } from './../functions'

import './AgentContractEditor.scss'

const toDateObj = (dateStr) =>
  dateStr && (typeof dateStr === 'string' || typeof dateStr === 'object')
    ? moment(dateStr).toDate()
    : dateStr

const toDateStr = (dateObj) =>
  dateObj ? `${moment(dateObj).format('MM/DD/YYYY h:mm a')}` : ''

const AgentContractEditor = ({ onSubmit }) => {
  const useACM = !isNaN(ACMStore?.Contract?.id()),
    getOriginalValues = () => {
      let original = useACM
        ? ACMStore.Contract.all()
        : CarrierStore.getExaminedAgentContract()
      return original &&
        typeof original === 'object' &&
        Object.values(original).length > 0
        ? original
        : false
    }

  const originalValues = getOriginalValues(),
    [formValues, setFormValues] = useState({}),
    [errorMsg, setErrorMsg] = useState(''),
    [successMsg, setSuccessMsg] = useState(''),
    contractType = originalValues && originalValues?.c_type

  const renderDateTimePicker = (field) => {
    const DateInput = React.forwardRef((props, ref) => (
      <MDBInput ref={ref} {...props} />
    ))

    return (
      <>
        <DatePicker
          showYearDropdown
          showTimeSelect
          disabled={
            !!(
              !isAdminUser() ||
              CarrierStore.getIsLoading('update.agent-contract') ||
              successMsg
            )
          }
          maxDate={new Date()}
          scrollableYearDropdown
          selected={toDateObj(getFormVal(field))}
          dateFormat="MM/dd/yyyy h:mm a"
          onChange={(v) => onChange(field, v)}
          customInput={<DateInput />}
          name={field}
        />
      </>
    )
  }

  const getFormVal = (field) => {
    let value =
      formValues && formValues.hasOwnProperty(field)
        ? formValues[field]
        : originalValues && originalValues.hasOwnProperty(field)
        ? originalValues[field]
        : null
    switch (field) {
      case 'agent_code':
        return value ? value : ''
      case 'pwk_sent_to_agent_at':
      case 'pwk_signed_at':
      case 'pwk_sent_to_carrier_at':
        return toDateObj(value)
      default:
        return
    }
  }

  const close = () => {
    const contractId =
        ACMStore.Contract && !isNaN(ACMStore?.Contract?.id())
          ? parseInt(ACMStore.Contract.id())
          : false,
      agentCode = formValues && formValues?.agent_code

    if (contractId > 0) {
      // so, right here, we need to check.
      // if the contract is complete & the cart item is not
      // - complete the cart item.
      let CartItem = ACMStore.CartItems.filter(
        (CartItem) => parseInt(CartItem.get('user_contract_id')) === contractId
      ).shift()
      if (
        agentCode &&
        CartItem &&
        CartItem.get('item_status') !== 'COMPLETED'
      ) {
        CartItem.set('item_status', 'COMPLETED')
        CartItem.save()
      }
    }

    ACMStore.Contract = null
    CarrierStore.examineAgentContract(false)

    setFormValues({})
    setErrorMsg('')
    setSuccessMsg('')
  }

  const onChange = (field, value) => {
    if (errorMsg) setErrorMsg('')

    const setMins = (v) => {
      let z = 0

      try {
        if (['12:00', '00:00'].indexOf(moment(v).format('hh:mm')) > -1)
          z = moment().hour() * 60 + (30 - (moment().minute() % 30))
      } catch (ex) {}

      return z ? moment(v).add(z, 'minutes') : v
    }

    if (
      [
        'pwk_sent_to_agent_at',
        'pwk_signed_at',
        'pwk_sent_to_carrier_at',
      ].indexOf(field) > -1
    ) {
      let l = toDateObj(getFormVal(field))
      if ((!l || typeof l !== 'object') && value)
        setFormValues((prevState) => ({
          ...prevState,
          ...{ [field]: setMins(value) },
        }))
      else if (l && value && l.getTime() !== value.getTime())
        setFormValues((prevState) => ({
          ...prevState,
          ...{ [field]: setMins(value) },
        }))
      else if (l && !value)
        setFormValues((prevState) => ({ ...prevState, ...{ [field]: null } }))
    } else if (!formValues.hasOwnProperty(field))
      setFormValues((prevState) => ({ ...prevState, ...{ [field]: value } }))
    else if (
      `${value}`.trim().toLowerCase() !==
      `${formValues[field]}`.trim().toLowerCase()
    )
      setFormValues((prevState) => ({ ...prevState, ...{ [field]: value } }))
  }

  const handleSuccess = async () => {
    if (useACM) await ACMStore.Contract.reload()

    setErrorMsg('')
    setSuccessMsg('Great! The agent contract has been updated.')
    setTimeout(() => close(), 1000)
  }

  const handleError = (msg) => {
    setSuccessMsg('')
    setErrorMsg(
      `Oh no! An error occurred while attempting to update the agent contract.  ${msg}`
    )
  }

  const submitChanged = () => {
    let changed = {}

    Object.keys(formValues).forEach((field) => {
      switch (field) {
        case 'agent_code':
          changed[field] = formValues[field]
            ? `${formValues[field]}`.trim()
            : ''
          break
        case 'pwk_sent_to_agent_at':
        case 'pwk_signed_at':
        case 'pwk_sent_to_carrier_at':
          changed[field] =
            formValues[field] && moment(formValues[field]).isValid()
              ? moment(formValues[field]).utc().format('YYYY-MM-DD HH:mm:ss')
              : null
          break
        default:
          return
      }
    })

    if (Object.keys(changed).length) {
      CarrierStore.updateAgentContract(originalValues.id, changed).then(
        async (res) => {
          if (res === true) handleSuccess()
          else if (res) handleError(res)
          if (useACM) await ACMStore.Cart.reload()
        }
      )
    }
  }

  return (
    <MDBModal
      id="AgentContractEditorComponent"
      isOpen={!!originalValues}
      toggle={() => close()}
      className="update-carrier-contract"
    >
      <MDBModalHeader toggle={() => close()}>
        Update Carrier Contract
      </MDBModalHeader>
      <MDBModalBody>
        <h6>{originalValues && originalValues?.c_name}</h6>
        <table>
          <tbody>
            <tr>
              <td>
                <label>Agent Writing Number</label>
              </td>
              <td></td>
              <td className="form-input">
                <MDBInput
                  hint={'Enter Agent Writing Number'}
                  value={getFormVal('agent_code')}
                  name="agent_code"
                  disabled={
                    !!(
                      CarrierStore.getIsLoading('update.agent-contract') ||
                      successMsg
                    )
                  }
                  readOnly={
                    !!(
                      CarrierStore.getIsLoading('update.agent-contract') ||
                      successMsg
                    )
                  }
                  onChange={(value) =>
                    onChange('agent_code', value.target.value)
                  }
                />
              </td>
            </tr>
            {isAdminUser() && originalValues ? (
              <>
                <tr>
                  <td>
                    <label>Paperwork Sent to Agent for Signature</label>
                  </td>
                  <td></td>
                  <td className="form-input">
                    {renderDateTimePicker(
                      'pwk_sent_to_agent_at',
                      'Set Paperwork'
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Agent E-Signed Contracts</label>
                  </td>
                  <td></td>
                  <td className="form-input">
                    {renderDateTimePicker('pwk_signed_at')}
                  </td>
                </tr>
                {contractType !== 'LINK' ? (
                  <tr>
                    <td>
                      <label>Contracts Submitted to Carrier</label>
                    </td>
                    <td></td>
                    <td className="form-input">
                      {renderDateTimePicker('pwk_sent_to_carrier_at')}
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <tr>
                  <td>
                    <label>Paperwork Sent to Agent for Signature</label>
                  </td>
                  <td></td>
                  <td className="form-value">
                    {toDateStr(getFormVal('pwk_sent_to_agent_at'))}
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Agent E-Signed Contracts</label>
                  </td>
                  <td></td>
                  <td className="form-value">
                    {toDateStr(getFormVal('pwk_signed_at'))}
                  </td>
                </tr>
                {contractType !== 'LINK' ? (
                  <tr>
                    <td>
                      <label>Contracts Submitted to Carrier</label>
                    </td>
                    <td></td>
                    <td className="form-value">
                      {toDateStr(getFormVal('pwk_sent_to_carrier_at'))}
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
              </>
            )}
          </tbody>
        </table>
        <MDBRow>
          <MDBCol size="12">
            <MDBCollapse isOpen={!!errorMsg || !!successMsg}>
              <MDBAlert color={errorMsg ? 'danger' : 'success'}>
                {errorMsg || successMsg}
              </MDBAlert>
            </MDBCollapse>
          </MDBCol>
          <MDBCol size="6">
            <MDBBtn
              disabled={
                !!(
                  successMsg ||
                  CarrierStore.getIsLoading('update.agent-contract')
                )
              }
              onClick={() => close()}
              block
            >
              Cancel&nbsp;
              <MDBIcon icon="times" />
            </MDBBtn>
          </MDBCol>
          <MDBCol size="6">
            <MDBBtn
              disabled={
                !!(
                  successMsg ||
                  Object.keys(formValues).length === 0 ||
                  CarrierStore.getIsLoading('update.agent-contract')
                )
              }
              color="indigo"
              onClick={submitChanged}
              block
            >
              Save&nbsp;
              <MDBIcon icon="save" />
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBModalBody>
    </MDBModal>
  )
}

export default observer(AgentContractEditor)
