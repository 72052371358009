import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import UserProfileService from '../../../../shared/services/UserProfile.service'
import { MDBContainer, MDBRow, MDBCol, MDBSwitch } from 'mdbreact'
import UserMetaService from '../../../../shared/services/UserMeta.service'
import UpdatePaymentMethodModalStore from '../../../../components/modals/UpdatePaymentMethodModal/UpdatePaymentMethodModal.store'

const UpdatePaymentToggleSwitch = ({ onToggle }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [updatePaymentMetaData, setUpdatePaymentMetaData] = useState(null)

  const meetsRequirement =
      UserProfileService.isA('internal-staff', true) ||
      UserProfileService.isA('internal-admin', true) ||
      UserProfileService.isA('system-admin', true) ||
      UserProfileService.isA('agency-owner', true)

  useEffect(() => {
    const getUserUpdatePaymentMethodMetaData = async () => {
      const metaData = await UserMetaService.search({
        search: {
          user_id: UserProfileService.getUserId(),
          meta_key: `billing---update-payment-method`,
        },
      })

      if (metaData?.models?.length === 0) {
        const res = await UserMetaService.store({
          user_id: UserProfileService.getUserId(),
          meta_key: 'billing---update-payment-method',
          meta_value: "0"
        })
        setUpdatePaymentMetaData({id: res.id, meta_value: "0"})
      } else setUpdatePaymentMetaData(metaData?.models[0])
    }

    getUserUpdatePaymentMethodMetaData();
  }, [])

  const toggleUpdatePaymentMethod = async () => {
    setIsLoading(true)

    try {
      await UserMetaService.update(updatePaymentMetaData.id, {
        meta_value: updatePaymentMetaData.meta_value === "1" ? "0" : "1",
      })
      setUpdatePaymentMetaData(
        {
          ...updatePaymentMetaData, 
          ...{meta_value: updatePaymentMetaData.meta_value === "1" ? "0" : "1"}
        }
      )
      onToggle(!(updatePaymentMetaData.meta_value === "1"))
      UpdatePaymentMethodModalStore.initCalled = 0;
    } catch (ex) {
      console.error(`${ex}`)
      onToggle('Something went wrong!')
    }

    setIsLoading(false)
  }

  const renderButton = () => {
    if (!meetsRequirement) return <></>

    return (
      <MDBContainer
        fluid
        style={{ backgroundColor: 'rgba(255,255,255,0.5)', paddingTop: '10px' }}
      >
        <MDBRow>
          <MDBCol size="6" sm="7" md="9" lg="6">
            <label>
              Show Update Payment Method Modal?&nbsp;
              {isLoading ? (
                <>
                  <span>
                    <i className="fa fa-spin fa-spinner"></i>
                  </span>
                </>
              ) : (
                <></>
              )}
            </label>
          </MDBCol>
          <MDBCol size="6" sm="5" md="3" lg="6" style={{ textAlign: 'right' }}>
            <MDBSwitch
              labelLeft={'No'}
              labelRight={'Yes'}
              disabled={!!isLoading}
              checked={(updatePaymentMetaData?.meta_value === "1")}
              onChange={toggleUpdatePaymentMethod}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }

  return renderButton()
}

export default observer(UpdatePaymentToggleSwitch)
