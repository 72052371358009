import React from 'react'
import env from './../../environments/environment'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import AwardLevelsShowcase from './components/AwardLevelsShowcase'
import SpecialtyPoints from './components/SpecialtyPoints'
import PointsBreakdown from './components/PointsBreakdown'
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCol,
  MDBContainer,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBTabContent,
  MDBTabPane,
} from 'mdbreact'
import CoverageService from './../../shared/services/Coverage.service'
import PointOverrideService from './../../shared/services/PointOverride.service'
import PointService from './../../shared/services/Point.service'
import CarrierService from './../../shared/services/Carrier.service'
import PointsRedemtion from './components/PointsRedemption'

import './PointsDetailsPage.scss'

class PointsDetailsPage extends React.Component {
  state = {
    isLoadingCoverages: false,
    isLoadingSpecialty: false,
    items: { vertical: '1' },
    carriers: [],
    coverages: [],
    overrides: [],
  }

  togglePills = (type, tab) => (e) => {
    e.preventDefault()
    if (this.state.items[type] !== tab) {
      let items = { ...this.state.items }
      items[type] = tab
      this.setState({ items })
    }
  }

  fetchCoveragePoints = async () => {
    if (this.state.isLoadingCoverages) return

    this.setState({ isLoadingCoverages: true })

    let points = null,
      coverages = null

    try {
      points = await PointService.search({ pagination: false })
    } catch (ex) {
      console.error('ERROR: Failed to fetch points in PointsDetailsPage.', ex)
      return false
    }

    points = points && points?.models ? points.models : []
    let coverageIds =
      points && Array.isArray(points)
        ? points
            .map((point) => parseInt(point.coverage_id))
            .filter((val, idx, self) => self.indexOf(val) === idx)
        : []

    try {
      coverages = await CoverageService.search({
        pagination: false,
        search: { id: coverageIds },
      })
    } catch (ex) {
      console.error(
        'ERROR: Failed to fetch coverages in PointsDetailsPage.',
        ex
      )
      return false
    }

    coverages = (coverages && coverages?.models ? coverages.models : [])
      .map((coverage) => {
        coverage.points = points
          .filter(
            (point) => parseInt(point.coverage_id) === parseInt(coverage.id)
          )
          .shift()
        return coverage
      })
      .filter((coverage) => !!coverage.points)
      .sort((a, b) =>
        a.coverage_name > b.coverage_name
          ? 1
          : a.coverage_name < b.coverage_name
          ? -1
          : 0
      )

    this.setState({ coverages, isLoadingCoverages: false })
  }

  fetchSpecialtyPoints = async () => {
    if (this.state.isLoadingSpecialty) return

    this.setState({ isLoadingSpecialty: true })

    let overrides = null

    try {
      overrides = await PointOverrideService.search({ pagination: false })
    } catch (ex) {
      console.error(
        'ERROR: Failed to fetch point overrides in PointsDetailsPage.',
        ex
      )
      return false
    }

    overrides = overrides && overrides?.models ? overrides.models : []
    let carrierIds = (
      overrides && Array.isArray(overrides)
        ? overrides
            .filter(
              (override) =>
                override.carrier_id !== null && !isNaN(override.carrier_id)
            )
            .map((override) => override.carrier_id)
        : []
    ).filter((val, idx, self) => self.indexOf(val) === idx)

    let carriers = null

    try {
      carriers = carrierIds.length
        ? await CarrierService.search({
            search: { id: carrierIds },
            pagination: false,
          })
        : []
    } catch (ex) {
      console.error(
        'ERROR: Failed to fetch override carriers in PointsDetailsPage.',
        ex
      )
      return false
    }

    carriers = carriers && carriers?.models ? carriers.models : []

    this.setState({ overrides, carriers, isLoadingSpecialty: false })
  }

  componentDidMount() {
    this.fetchCoveragePoints()
    this.fetchSpecialtyPoints()
  }

  render() {
    return (
      <React.Fragment>
        <DashboardLayout>
          <main id="PointsDetailsPage" className="adminStyle mainSection">
            <MDBContainer fluid className="mt-3">
              <div className="buck-logo pb-3 d-flex justify-content-center">
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Flogos%2Fusabg-bucks.logo.png?alt=media`}
                  alt="buck-logo"
                />
              </div>
              <MDBCard narrow>
                <MDBCardImage
                  cascade
                  className="view view-cascade gradient-card-header blue-gradient"
                  tag="div"
                >
                  <h2 className="h2-responsive">Points Details</h2>
                </MDBCardImage>
                <MDBCardBody cascade>
                  <MDBRow>
                    <MDBCol size="12">
                      <MDBNav
                        pills
                        color="primary"
                        className="justify-content-center"
                      >
                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            active={this.state.items['vertical'] === '1'}
                            onClick={this.togglePills('vertical', '1')}
                          >
                            Award Levels & Info
                          </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            active={this.state.items['vertical'] === '2'}
                            onClick={this.togglePills('vertical', '2')}
                          >
                            Points Details
                            {this.state.isLoadingCoverages ||
                            this.state.isLoadingSpecialty ? (
                              <>
                                &nbsp;
                                <span>
                                  <i className="fa fa-spin fa-spinner"></i>
                                </span>
                              </>
                            ) : (
                              ''
                            )}
                          </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            active={this.state.items['vertical'] === '3'}
                            onClick={this.togglePills('vertical', '3')}
                          >
                            USABG BUCK$ Redemption
                          </MDBNavLink>
                        </MDBNavItem>
                      </MDBNav>
                    </MDBCol>

                    <MDBCol size="12">
                      <MDBTabContent activeItem={this.state.items['vertical']}>
                        <MDBTabPane tabId="1">
                          <AwardLevelsShowcase />
                        </MDBTabPane>
                        <MDBTabPane tabId="2">
                          <PointsBreakdown
                            isLoading={this.state.isLoadingCoverages}
                            coverages={this.state.coverages}
                          />
                          <SpecialtyPoints
                            isLoading={this.state.isLoadingSpecialty}
                            coverages={this.state.coverages}
                            carriers={this.state.carriers}
                            overrides={this.state.overrides}
                          />
                        </MDBTabPane>
                        <MDBTabPane tabId="3">
                          <PointsRedemtion />
                        </MDBTabPane>
                      </MDBTabContent>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export default PointsDetailsPage
