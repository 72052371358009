import React from 'react'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
} from 'mdbreact'
import PIPAwardLevel from './../../../../shared/utilities/PipAwardLevel.class'
import moment from 'moment'
import PerformanceRecordService from './../../../../shared/services/PerformanceRecord.service'
import { renderTeamLogo } from './../../../../shared/utilities/getTeamLogo.function'

import './CurrentTopPipBATeams.scss'

class CurrentTopPipBATeams extends React.Component {
  state = {
    topFive: null,
  }

  #setPerformanceReports = async () => {
    let results = await this.#fetchTopFive(),
      topFive = null
    results = results && results.length ? results.shift() : null
    if (!results) return

    if (results?.payload) topFive = results?.payload
    if (topFive && typeof topFive === 'string') topFive = JSON.parse(topFive)

    topFive =
      topFive && Array.isArray(topFive)
        ? topFive.map((t) => {
            t.av = t.av ? this.#numberFormat(t.av) : 0
            return t
          })
        : topFive

    // while (topFive.length > 0 && topFive.length < 5) {
    //   topFive.push({ name: 'Available!', pic: null, av: 0 })
    // }

    this.setState({ topFive })
  }

  #fetchTopFive = async () => {
    try {
      return await PerformanceRecordService.search({
        search: {
          name: ['currentYearTopPointsBATeams'],
          date: moment().format('YYYY-MM-DD'),
        },
      })
    } catch (ex) {
      return false
    }
  }

  #numberFormat = (value) =>
    new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value)

  componentDidMount() {
    this.#setPerformanceReports()
  }

  render() {
    return (
      <MDBContainer id="CurrentTopPipBATeamsComponent" fluid>
        {this.state.topFive &&
        Array.isArray(this.state.topFive) &&
        this.state.topFive.length ? (
          <MDBCard className="mt-3 mb-5 top-5-pip-agents">
            <MDBCardHeader
              color="light-blue darken-4"
              tag="h4"
              className="text-center"
            >
              {moment().format('YYYY')} YTD: Top{' '}
              {this.state.topFive.length || 0} Benefit Advisor Teams
            </MDBCardHeader>
            <MDBCardBody>
              <MDBRow>
                {this.state.topFive.map((item, i) => (
                  <React.Fragment key={'task-result-' + i}>
                    <MDBCol size="12" className="mobile-card mr-auto ml-auto">
                      <MDBRow>
                        <MDBCol size="5">
                          <div className="team-logo-img-wrapper">
                            {renderTeamLogo(item)}
                          </div>
                        </MDBCol>
                        <MDBCol size="7">
                          <div className="agent-name">
                            <span>{item.name}</span>
                          </div>
                          <div className="agent-points">
                            <span>{item.av} Points</span>
                          </div>
                          <div className="agent-jewel-img">
                            {item.av ? (
                              PIPAwardLevel.getAwardBadge(
                                (`${item.av}` || 0).replace(/[^\d]/g, ''),
                                {
                                  size: '64px',
                                  display: 'block',
                                  margin: 'auto',
                                }
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol
                      lg="2"
                      md="2"
                      sm="6"
                      className="desktop-card mr-auto ml-auto"
                    >
                      <div className="agent-name">
                        <span>{item.name}</span>
                      </div>
                      <div className="team-logo-img-wrapper">
                        {renderTeamLogo(item)}
                      </div>
                      <div className="agent-points">
                        <span>{item.av} Points</span>
                      </div>
                      <div className="agent-jewel-img">
                        {item.av ? (
                          PIPAwardLevel.getAwardBadge(
                            (`${item.av}` || 0).replace(/[^\d]/g, ''),
                            { size: '64px', display: 'block', margin: 'auto' }
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    </MDBCol>
                  </React.Fragment>
                ))}
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        ) : (
          <></>
        )}
      </MDBContainer>
    )
  }
}

export default CurrentTopPipBATeams
