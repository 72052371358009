import React from 'react'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import { MDBCol, MDBContainer } from 'mdbreact'
import TechToolWidget from './components/TechToolWidget/TechToolWidget.component'
import { Subscription } from 'rxjs'
import AdminApi from './../../api/admin-api/admin-api'
import CorporateEmailModal from './components/CorporateEmailModal/CorporateEmailModal.component'
import DeniedAccessModal from './components/DeniedAccessModal/DeniedAccessModal.component'

import './TechToolsPage.scss'

const sortTools = (tools) => {
  let fs =
      tools && Array.isArray(tools) ? tools.filter((t) => t.fullscreen) : [],
    rg = tools && Array.isArray(tools) ? tools.filter((t) => !t.fullscreen) : []

  return fs
    .sort((a, b) =>
      a.tool_title > b.tool_title
        ? 1
        : a.tool_title.toLowerCase().charAt(0) ===
          b.tool_title.toLowerCase().charAt(0)
        ? 0
        : -1
    )
    .concat(
      rg.sort((a, b) =>
        a.tool_title > b.tool_title
          ? 1
          : a.tool_title.toLowerCase().charAt(0) ===
            b.tool_title.toLowerCase().charAt(0)
          ? 0
          : -1
      )
    )
}

class TechToolsPage extends React.Component {
  state = {
    isLoading: true,
    imageUris: {},
    techToolsList: [],
  }

  #_subscriptions$ = new Subscription()

  #fetchTechToolsData = async () => {
    let techToolsList = {
      imageUris: { prefix: null, suffix: null },
      techToolsList: [],
    }
    try {
      let response = await AdminApi.getTechToolsList()
      techToolsList = {
        techToolsList: response?.data?.data || [],
        imageUris: response?.data?.imageUrl,
      }
    } catch (ex) {}

    this.setState({
      imageUris: techToolsList.imageUris,
      techToolsList: sortTools(
        Array.isArray(techToolsList.techToolsList)
          ? techToolsList.techToolsList
          : []
      ),
      isLoading: false,
    })
  }

  componentDidMount() {
    this.#fetchTechToolsData()
  }

  render() {
    const { techToolsList, isLoading, imageUris } = this.state

    return (
      <React.Fragment>
        <DashboardLayout>
          <main id="TechToolsPage" className="mainSection">
            <CorporateEmailModal />
            <DeniedAccessModal />
            <MDBContainer fluid className="mt-5">
              <h2 className="text-center">Tech Tools for Success</h2>
              <hr />
              <div className="header-img">
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREABSE_STORAGE_BUCKET}/o/static%2Fimg.header.tech-tools.jpg?alt=media`}
                  alt="Not Yo Grandpa's Tools!"
                />
              </div>
            </MDBContainer>
            {isLoading ? (
              <MDBContainer fluid className="mt-5">
                <h2>Loading...</h2>
              </MDBContainer>
            ) : (
              <MDBContainer fluid className="mt-5">
                <div className="row">
                  {techToolsList && techToolsList.length ? (
                    techToolsList.map((item, i) => (
                      <MDBCol
                        key={i}
                        md={item.fullscreen ? '12' : '6'}
                        className="mb-4"
                      >
                        <TechToolWidget source={item} imageUris={imageUris} />
                      </MDBCol>
                    ))
                  ) : (
                    <h2>No Tools available</h2>
                  )}
                </div>
              </MDBContainer>
            )}
          </main>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export default TechToolsPage
