import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import AdminApi from './../../../../api/admin-api/admin-api'
import ContentHtml from './../../../../components/content/ContentHtml/ContentHtml.component'
import { WebsiteInfoStore } from './../../stores'
import {
  MDBBtn,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBCollapse,
  MDBAlert,
} from 'mdbreact'

import './SocialMediaForm.component.scss'

const saveTimer = {}

const SocialMediaForm = () => {
  const [formError, setFormError] = useState(null),
    [formSuccess, setFormSuccess] = useState(null)

  const validate = () => {
    const { siteDetails } = WebsiteInfoStore

    ;[
      's_facebook_link',
      's_linkedin_link',
      's_instagram_link',
      's_twitter_link',
    ].forEach((link) => {
      if (siteDetails[link] && siteDetails[link].trim())
        WebsiteInfoStore.siteDetails[link] = `${siteDetails[link]}`
          .split(/\.(in|com)\//)
          .pop()
      if (
        WebsiteInfoStore.siteDetails[link] === null ||
        WebsiteInfoStore.siteDetails[link] === 'null'
      )
        WebsiteInfoStore.siteDetails[link] = ''
    })

    return true
  }

  const updateInfo = async () => {
    if (!(await validate())) return false

    if (WebsiteInfoStore.isLoading || WebsiteInfoStore.isSaving) return

    const data = {
      s_facebook_link: WebsiteInfoStore.siteDetails?.s_facebook_link,
      s_linkedin_link: WebsiteInfoStore.siteDetails?.s_linkedin_link,
      s_instagram_link: WebsiteInfoStore.siteDetails?.s_instagram_link,
      s_twitter_link: WebsiteInfoStore.siteDetails?.s_twitter_link,
      id: WebsiteInfoStore.siteDetails?.site_id,
    }

    let form_data = new FormData()
    for (let key in data) form_data.append(key, data[key])

    WebsiteInfoStore.isSaving = true

    AdminApi.updateWebsiteInfo(form_data)
      .then((result) => {
        setFormSuccess('Successfully updated social media links.')

        if (saveTimer.timer) {
          window.clearTimeout(saveTimer.timer)
          saveTimer.timer = null
        }

        saveTimer.timer = setTimeout(() => setFormSuccess(null), 4000)

        WebsiteInfoStore.isSaving = false
      })
      .catch((error) => {
        setFormError(error)
        WebsiteInfoStore.isSaving = false
      })
  }

  return (
    <form
      id="MarketingFormComponent"
      noValidate
      className="container-fluid mt-5"
    >
      <MDBRow>
        <MDBCol size="12">
          <h5 className="text--blue font--lato fw--500">
            Subsite Social Media Links
          </h5>
        </MDBCol>
        <MDBCol size="12">
          <ContentHtml contentSlug="subsite-social-links-howto" />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol className="form-group" size="12" sm="6" lg="3">
          <div className="quote-icon">
            <MDBIcon icon="facebook" />
          </div>
          <label className="quote-label">Facebook</label>
          <input
            type="text"
            className="form-control"
            name="s_facebook_link"
            id="s_facebook_link"
            value={WebsiteInfoStore.siteDetails?.s_facebook_link || ''}
            onChange={(event) =>
              (WebsiteInfoStore.siteDetails.s_facebook_link =
                event.target.value)
            }
          />
        </MDBCol>
        <MDBCol className="form-group" size="12" sm="6" lg="3">
          <div className="quote-icon">
            <MDBIcon icon="linkedin" />
          </div>
          <label className="quote-label">Linked In</label>
          <input
            type="text"
            className="form-control"
            name="s_linkedin_link"
            id="s_linkedin_link"
            value={WebsiteInfoStore.siteDetails?.s_linkedin_link || ''}
            onChange={(event) =>
              (WebsiteInfoStore.siteDetails.s_linkedin_link =
                event.target.value)
            }
          />
        </MDBCol>
        <MDBCol className="form-group" size="12" sm="6" lg="3">
          <div className="quote-icon">
            <MDBIcon icon="instagram" />
          </div>
          <label className="quote-label">Instagram</label>
          <input
            type="text"
            className="form-control"
            name="s_instagram_link"
            id="s_instagram_link"
            value={WebsiteInfoStore.siteDetails?.s_instagram_link || ''}
            onChange={(event) =>
              (WebsiteInfoStore.siteDetails.s_instagram_link =
                event.target.value)
            }
          />
        </MDBCol>
        <MDBCol className="form-group" size="12" sm="6" lg="3">
          <div className="quote-icon">
            <MDBIcon icon="twitter" />
          </div>
          <label className="quote-label">Twitter</label>
          <input
            type="text"
            className="form-control"
            name="s_twitter_link"
            id="s_twitter_link"
            value={WebsiteInfoStore.siteDetails?.s_twitter_link || ''}
            onChange={(event) =>
              (WebsiteInfoStore.siteDetails.s_twitter_link = event.target.value)
            }
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol className="form-group">
          <MDBBtn color="indigo" type="button" onClick={updateInfo}>
            {WebsiteInfoStore.isSaving ? 'Saving...' : 'Save'}
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBCollapse isOpen={!!formError}>
            <MDBAlert color="danger">{formError}</MDBAlert>
          </MDBCollapse>
          <MDBCollapse isOpen={!!formSuccess}>
            <MDBAlert color="success">{formSuccess}</MDBAlert>
          </MDBCollapse>
        </MDBCol>
      </MDBRow>
    </form>
  )
}

export default observer(SocialMediaForm)
