import React, { useState, useEffect, useRef } from 'react'
import { makeAutoObservable, toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBAlert,
  MDBIcon,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBCollapse,
} from 'mdbreact'
import { getGrowth, getGrowthV2 } from './../../helpers'
import LoadingSpinner from './../../../../components/shared/LoadingSpinner.component'
import AdminContentEditPopupComponent from './../../../../components/adminShared/AdminContentEditPopupComponent'
import { UIInput } from './../../../../components/forms/form-fields'
import LeaderReportCardService from './../../../../shared/services/LeaderReportCard.service'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import AuthService from './../../../../shared/services/Auth.service'
import moment from 'moment'
import {
  TableCellSplitter,
  ColumnSplitter,
  ColumnHeader,
  ColumnDescriptor,
  ColumnTimespan,
  ColumnKeyIndicator,
  StandardCell,
  GradedCell,
  ColumnSorter,
} from './LeaderReportTable.classes'
import LeaderReportSelector from './LeaderReportSelector.component'
import LeaderDateSelector from './LeaderDateSelector.component'
import ViewModeSelector from './ViewModeSelector.component'
import LeaderReportStore from './../../store/LeaderReportCard.store'
import LeaderGoalStore from './../../store/LeaderGoalCard.store'
import { convertToCurrencyWithoutSign } from './../../../../shared/formatters/convertToCurrencyWithoutSign.formatter'
import { reportCardFields } from '../../variables'
import './LeaderReportTable.scss'
import './LeaderSortIndicator.scss'
import './LeaderGoalsCard.scss'

const Sorter = new ColumnSorter()

const IMPLEMENTED = [
  'prev_enrolled',
  'curr_enrolled',
  'prev_completed',
  'curr_completed',
  'prev_converted',
  'curr_converted',
]

const GoalCardInputComponent = ({ value, onChange, onBlur }) => {
  return (
    <>
      $
      <UIInput
        type="number"
        className="placeholder-color-white"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </>
  )
}

const GoalCardInput = observer(GoalCardInputComponent)

class LgrDrillDownStore {
  isOpen = false
  isFetching = false
  request = null
  columns = []
  rows = []
  results = []
  isScrolling = false

  constructor() {
    makeAutoObservable(this)
  }

  fetchAndOpen = async (ddRequest) => {
    this.isFetching = true
    this.request = ddRequest
    let Result
    if (this.request?.id && IMPLEMENTED.includes(this.request?.metric)) {
      this.isOpen = true

      try {
        Result = await LeaderReportCardService.drill(
          this.request.id,
          this.request.metric
        )
      } catch (ex) {
        console.error(ex)
      }
    }

    this.rows = Array.isArray(Result) ? Result : []

    if (Result && Result.length) this.columns = Object.keys(Result[0])

    this.isFetching = false
    return (this.results = Result)
  }

  close = () => {
    this.isOpen = false
  }

  renderTable = ({ goTo }) => {
    /* this should be changed out to just return a component */
    /* the component shouldnt come from this class to prevent circular dependency */
    let counter = 0,
      counted = []

    const getCell = (cell, idx) => {
      if (
        typeof cell !== 'object' &&
        typeof cell !== 'undefined' &&
        cell !== null
      )
        cell = `${cell}`.trim()

      if (
        typeof cell === 'string' &&
        /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/.test(cell)
      )
        cell = moment.utc(cell).format('h:mm a MM/DD/YY')

      if (!isNaN(idx) && idx !== null)
        return <span key={`dd-cell-`}>{cell}</span>

      return cell
    }

    const renderSingleRow = (row, idx) => {
      return (
        <li
          key={`ddrow-${row?.id}-${idx}-${this.request?.metric_name}`}
          className="dd-row dd-single"
        >
          <div
            onClick={async () => {
              if (!isNaN(row?.user_id) && !UserProfileService.isAssumed()) {
                DDStore.isFetching = true
                AuthService.assumeUser(row.user_id).finally(() => {
                  DDStore.isFetching = false
                  if (typeof goTo === 'function') goTo('/account-profile')
                })
              }
            }}
            className="dd-cell"
          >
            {`${parseInt(idx) + 1}`}.
          </div>
          {Object.values(row).map((cell, cdx) => (
            <div className="dd-cell" key={`ddcell-${row?.id}-${idx}-${cdx}`}>
              {getCell(cell)}
            </div>
          ))}
        </li>
      )
    }

    const renderEnrolledRow = (row, idx) => {
      // 0: row.id
      // 1: row.outcome
      // 2: row.user_id
      // 3: row.agent_name
      // 4: row.upgrade_id
      // 5: row.usertype_id
      // 6: row.downgrade_id
      // 7: row.enroll_event
      // 8: row.enroll_event_id
      // 9: row.enroll_event_start

      let color = ''
      if (idx !== false) {
        color = 'secondary'
        if (`${row?.outcome}`.includes('PASSED')) {
          color = 'success'
          counter++
        } else if (`${row?.outcome}`.includes('FAILED')) {
          color = 'danger'
        } else if (`${row?.outcome}`.includes('IGNORE')) {
          color = 'secondary'
        } else if (idx) {
          color = 'warning'
        }
      }

      return (
        <li
          key={`ddrow-${row?.id}-${idx}-${this.request?.metric_name}`}
          className={`alert alert-${color} dd-row dd-single`}
        >
          <div
            onClick={async () => {
              if (!isNaN(row?.user_id) && !UserProfileService.isAssumed()) {
                DDStore.isFetching = true
                AuthService.assumeUser(row.user_id).finally(() => {
                  DDStore.isFetching = false
                  if (typeof goTo === 'function') goTo('/account-profile')
                })
              }
            }}
            className="dd-cell"
          >{`${
            isNaN(idx) || idx === null || idx === false
              ? ''
              : parseInt(idx) + 1 + '.'
          }`}</div>
          <div className="dd-cell">{getCell(row.id)}</div>
          <div className="dd-cell">{getCell(row.user_id)}</div>
          <div className="dd-cell">{getCell(row.agent_name)}</div>
          <div className="dd-cell">{getCell(row.enroll_event_id)}</div>
          <div className="dd-cell">{getCell(row.enroll_event)}</div>
          <div className="dd-cell">{getCell(row.enroll_event_start)}</div>
          <div className="dd-cell">
            {getCell(
              row.outcome && row.outcome.includes('PASSED')
                ? `(${counter}) ${row.outcome}`
                : `${row.outcome}`
            )}
          </div>
        </li>
      )
    }

    const renderCompletedRow = (row, idx) => {
      let color = ''
      if (idx !== false) {
        color = 'secondary'
        if (
          parseInt(row.days_since) === 0 &&
          row.is_sig_agent &&
          `${row?.outcome}`.includes('PASSED')
        ) {
          color = 'success'
          if (counted.indexOf(`${row.user_id}`) < 0) {
            counter++
            counted.push(`${row.user_id}`)
          } else {
            row.outcome = 'SAME_DAY_SIG_AGENT'
            color = 'secondary'
          }
        } else {
          if (
            parseInt(row.days_since) === 0 &&
            !`${row?.outcome}`.includes('PASSED')
          ) {
            color = 'danger'
          } else if (row.is_sig_agent) {
            row.outcome = 'PREVIOUS_SIG_AGENT'
            color = 'warning'
          } else {
            row.outcome = 'N/A'
            color = 'secondary'
          }
        }
      }

      return (
        <li
          key={`ddrow-${row?.id}-${idx}-${this.request?.metric_name}`}
          className={`alert alert-${color} dd-row dd-single`}
        >
          <div
            onClick={async () => {
              if (!isNaN(row?.user_id) && !UserProfileService.isAssumed()) {
                DDStore.isFetching = true
                AuthService.assumeUser(row.user_id).finally(() => {
                  DDStore.isFetching = false
                  if (typeof goTo === 'function') goTo('/account-profile')
                })
              }
            }}
            className="dd-cell"
          >{`${
            isNaN(idx) || idx === null || idx === false
              ? ''
              : parseInt(idx) + 1 + '.'
          }`}</div>
          <div className="dd-cell">{getCell(row.user_id)}</div>
          <div className="dd-cell">{getCell(row.agent_name)}</div>
          <div className="dd-cell">{getCell(row.usertype_id)}</div>
          <div className="dd-cell">{getCell(row.changed_at)}</div>
          <div className="dd-cell">
            {getCell(
              idx === false
                ? row.days_since
                : `${row.days_since > 0 ? '-' : ''}${row.days_since} days`
            )}
          </div>
          <div className="dd-cell">
            {getCell(
              idx === false ? row.is_sig_agent : row.is_sig_agent ? 'Yes' : 'No'
            )}
          </div>
          <div className="dd-cell">
            {getCell(
              idx !== false &&
                row.is_sig_agent &&
                row.outcome &&
                row.outcome.includes('PASSED')
                ? `(${counter}) ${row.outcome}`
                : `${row.outcome}`
            )}
          </div>
        </li>
      )
    }

    const renderConvertedRow = (row, idx) => {
      let color = idx !== false ? 'success' : 'secondary'
      if (idx !== false) counter++

      return (
        <li
          key={`ddrow-${row?.id}-${idx}-${this.request?.metric_name}`}
          className={`alert alert-${color} dd-row dd-single`}
        >
          <div
            onClick={async () => {
              if (!isNaN(row?.user_id) && !UserProfileService.isAssumed()) {
                DDStore.isFetching = true
                AuthService.assumeUser(row.user_id).finally(() => {
                  DDStore.isFetching = false
                  if (typeof goTo === 'function') goTo('/account-profile')
                })
              }
            }}
            className="dd-cell"
          >{`${
            isNaN(idx) || idx === null || idx === false
              ? ''
              : parseInt(idx) + 1 + '.'
          }`}</div>
          <div className="dd-cell">{getCell(row.av_id)}</div>
          <div className="dd-cell">{getCell(row.user_id)}</div>
          <div className="dd-cell">{getCell(row.agent_name)}</div>
          <div className="dd-cell">{getCell(row.av_client)}</div>
          <div className="dd-cell">{getCell(row.min_date)}</div>
          <div className="dd-cell">
            {getCell(idx !== false ? `(${counter}) PASSED` : row.outcome)}
          </div>
        </li>
      )
    }

    switch (this.request?.metric) {
      case 'curr_enrolled':
      case 'prev_enrolled':
        return (
          <ul className="lgr-dd-table metric-enrolled">
            {renderEnrolledRow(
              {
                id: 'Audit ID',
                user_id: 'Agent ID',
                agent_name: 'Agent Name',
                enroll_event_id: 'Usertype Record ID',
                enroll_event: 'Subscription Change',
                enroll_event_start: 'Subscription Change Timestamp',
                outcome: 'Meets Enrolled Criteria?',
              },
              false
            )}
            {this.rows.map(renderEnrolledRow)}
          </ul>
        )
      case 'curr_completed':
      case 'prev_completed':
        return (
          <ul className="lgr-dd-table metric-completed">
            {renderCompletedRow(
              {
                user_id: 'Agent ID',
                agent_name: 'Agent Name',
                usertype_id: 'Agent Usertype',
                changed_at: 'Usertype Change Timestamp',
                days_since: 'Usertype Change Aging',
                is_sig_agent: 'Is Sig Agent?',
                outcome: 'Meets Completed Criteria?',
              },
              false
            )}
            {this.rows.map(renderCompletedRow)}
          </ul>
        )
      case 'curr_converted':
      case 'prev_converted':
        return (
          <ul className="lgr-dd-table metric-completed">
            {renderConvertedRow(
              {
                av_id: 'Audit ID',
                user_id: 'Agent ID',
                agent_name: 'Agent Name',
                av_client: 'AV Client Name',
                min_date: 'Date of First Sale',
                outcome: 'Meets Converted Criteria?',
              },
              false
            )}
            {this.rows.map(renderConvertedRow)}
          </ul>
        )
      default:
        break
    }

    return <ul className="lgr-dd-table">{this.rows.map(renderSingleRow)}</ul>
  }
}

const DDStore = new LgrDrillDownStore()

const LeaderGoalsCardComponent = () => {
  const {
      teamGoals,
      personalGoals,
      goalDate,
      getAgencyContent,
      slotInvest,
      SlotInvestMeta,
      usabgInvest,
      UsabgInvestMeta,
    } = LeaderGoalStore,
    goalYear = goalDate
      ? parseInt(moment(goalDate, 'YYYY-MM-DD').format('YYYY'))
      : '',
    compYear = goalYear ? goalYear - 1 : ''

  const getValue = (year, prop, investData) => {
    try {
      return investData[year][prop]
    } catch (ex) {
      return
    }
  }

  return (
    <div id="LeaderGoalsCardComponent">
      <div className="container-fluid goal-container">
        <MDBRow className="goal-row goal-row-header">
          <MDBCol className="goal-cell goal-cell-metric">&nbsp;</MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-prev-ytd">
            Personal
            <br />
            Year {compYear}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-prev-ytd">
            Team
            <br />
            Year {compYear}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-goal-ytd">
            Personal Monthly Goal {goalYear}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-goal-mtd">
            Personal Yearly {goalYear}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-goal-mtd">
            Team Monthly Goal {goalYear}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-goal-mtd">
            Team Yearly Goal {goalYear}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-notes"></MDBCol>
        </MDBRow>
        <MDBRow className="goal-row goal-row-descrip">
          <MDBCol className="goal-cell goal-cell-metric">Category:</MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-prev-ytd"></MDBCol>
          <MDBCol className="goal-cell goal-cell-team-prev-ytd"></MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-goal-mtd"></MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-goal-ytd"></MDBCol>
          <MDBCol className="goal-cell goal-cell-team-goal-mtd"></MDBCol>
          <MDBCol className="goal-cell goal-cell-team-goal-ytd"></MDBCol>
          <MDBCol className="goal-cell goal-cell-notes">Notes:</MDBCol>
        </MDBRow>
        <MDBRow className="goal-row goal-row-points">
          <MDBCol className="goal-cell goal-cell-metric">Total Points:</MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-prev-ytd">
            {convertToCurrencyWithoutSign(
              Math.round(personalGoals?.previous_total_points || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-prev-ytd">
            {convertToCurrencyWithoutSign(
              Math.round(teamGoals?.previous_total_points || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-goal-mtd">
            {convertToCurrencyWithoutSign(
              Math.round(personalGoals?.previous_monthly_goal_points || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-goal-ytd">
            {convertToCurrencyWithoutSign(
              Math.round(personalGoals?.previous_yearly_goal_points || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-goal-mtd">
            {convertToCurrencyWithoutSign(
              Math.round(teamGoals?.previous_monthly_goal_points || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-goal-ytd">
            {convertToCurrencyWithoutSign(
              Math.round(teamGoals?.previous_yearly_goal_points || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-notes">
            <AdminContentEditPopupComponent
              type="RTE"
              color="blue"
              pageId={getAgencyContent('leader-goals-points-notes', 'id')}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: getAgencyContent(
                  'leader-goals-points-notes',
                  'content'
                ),
              }}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="goal-row goal-row-enrolled">
          <MDBCol className="goal-cell goal-cell-metric">
            Enrolled Signature Agents:
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-prev-ytd">
            {convertToCurrencyWithoutSign(
              Math.round(personalGoals?.previous_total_enrolled || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-prev-ytd">
            {convertToCurrencyWithoutSign(
              Math.round(teamGoals?.previous_total_enrolled || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-goal-mtd">
            {convertToCurrencyWithoutSign(
              Math.round(personalGoals?.previous_monthly_goal_enrolled || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-goal-ytd">
            {convertToCurrencyWithoutSign(
              Math.round(personalGoals?.previous_yearly_goal_enrolled || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-goal-mtd">
            {convertToCurrencyWithoutSign(
              Math.round(teamGoals?.previous_monthly_goal_enrolled || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-goal-ytd">
            {convertToCurrencyWithoutSign(
              Math.round(teamGoals?.previous_yearly_goal_enrolled || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-notes">
            <AdminContentEditPopupComponent
              type="RTE"
              color="blue"
              pageId={getAgencyContent('leader-goals-enrolled-notes', 'id')}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: getAgencyContent(
                  'leader-goals-enrolled-notes',
                  'content'
                ),
              }}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="goal-row goal-row-completed">
          <MDBCol className="goal-cell goal-cell-metric">
            Completed Signature Agents:
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-prev-ytd">
            {convertToCurrencyWithoutSign(
              Math.round(personalGoals?.previous_total_completed || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-prev-ytd">
            {convertToCurrencyWithoutSign(
              Math.round(teamGoals?.previous_total_completed || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-goal-mtd">
            {convertToCurrencyWithoutSign(
              Math.round(personalGoals?.previous_monthly_goal_completed || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-goal-ytd">
            {convertToCurrencyWithoutSign(
              Math.round(personalGoals?.previous_yearly_goal_completed || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-goal-mtd">
            {convertToCurrencyWithoutSign(
              Math.round(teamGoals?.previous_monthly_goal_completed || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-goal-ytd">
            {convertToCurrencyWithoutSign(
              Math.round(teamGoals?.previous_yearly_goal_completed || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-notes">
            <AdminContentEditPopupComponent
              type="RTE"
              color="blue"
              pageId={getAgencyContent('leader-goals-completed-notes', 'id')}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: getAgencyContent(
                  'leader-goals-completed-notes',
                  'content'
                ),
              }}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="goal-row goal-row-converted">
          <MDBCol className="goal-cell goal-cell-metric">
            Converted Signature Agents:
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-prev-ytd">
            {convertToCurrencyWithoutSign(
              Math.round(personalGoals?.previous_total_converted || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-prev-ytd">
            {convertToCurrencyWithoutSign(
              Math.round(teamGoals?.previous_total_converted || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-goal-mtd">
            {convertToCurrencyWithoutSign(
              Math.round(personalGoals?.previous_monthly_goal_converted || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-goal-ytd">
            {convertToCurrencyWithoutSign(
              Math.round(personalGoals?.previous_yearly_goal_converted || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-goal-mtd">
            {convertToCurrencyWithoutSign(
              Math.round(teamGoals?.previous_monthly_goal_converted || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-goal-ytd">
            {convertToCurrencyWithoutSign(
              Math.round(teamGoals?.previous_yearly_goal_converted || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-notes">
            <AdminContentEditPopupComponent
              type="RTE"
              color="blue"
              pageId={getAgencyContent('leader-goals-converted-notes', 'id')}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: getAgencyContent(
                  'leader-goals-converted-notes',
                  'content'
                ),
              }}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="goal-row goal-row-slot-invest">
          <MDBCol className="goal-cell goal-cell-metric">
            Slot Investment:
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-prev-ytd">
            <GoalCardInput
              value={getValue(compYear, 'personal_previous_slots', slotInvest)}
              onChange={(e) =>
                LeaderGoalStore.setInvestment(
                  {
                    year: compYear,
                    personal_previous_slots: parseFloat(e.target.value),
                  },
                  SlotInvestMeta
                )
              }
              onBlur={(e) =>
                LeaderGoalStore.saveMeta(
                  LeaderGoalStore.SlotInvestMeta,
                  slotInvest
                )
              }
            />
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-prev-ytd">
            <GoalCardInput
              value={getValue(compYear, 'team_previous_slots', slotInvest)}
              onChange={(e) =>
                LeaderGoalStore.setInvestment(
                  {
                    year: compYear,
                    team_previous_slots: parseFloat(e.target.value),
                  },
                  SlotInvestMeta
                )
              }
              onBlur={(e) =>
                LeaderGoalStore.saveMeta(
                  LeaderGoalStore.SlotInvestMeta,
                  slotInvest
                )
              }
            />
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-goal-mtd">
            <GoalCardInput
              value={getValue(
                goalYear,
                'personal_monthly_goal_slots',
                slotInvest
              )}
              onChange={(e) =>
                LeaderGoalStore.setInvestment(
                  {
                    year: goalYear,
                    personal_monthly_goal_slots: parseFloat(e.target.value),
                  },
                  SlotInvestMeta
                )
              }
              onBlur={(e) =>
                LeaderGoalStore.saveMeta(
                  LeaderGoalStore.SlotInvestMeta,
                  slotInvest
                )
              }
            />
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-goal-ytd">
            <GoalCardInput
              value={getValue(
                goalYear,
                'personal_yearly_goal_slots',
                slotInvest
              )}
              onChange={(e) =>
                LeaderGoalStore.setInvestment(
                  {
                    year: goalYear,
                    personal_yearly_goal_slots: parseFloat(e.target.value),
                  },
                  SlotInvestMeta
                )
              }
              onBlur={(e) =>
                LeaderGoalStore.saveMeta(
                  LeaderGoalStore.SlotInvestMeta,
                  slotInvest
                )
              }
            />
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-goal-mtd">
            <GoalCardInput
              value={getValue(goalYear, 'team_monthly_goal_slots', slotInvest)}
              onChange={(e) =>
                LeaderGoalStore.setInvestment(
                  {
                    year: goalYear,
                    team_monthly_goal_slots: parseFloat(e.target.value),
                  },
                  SlotInvestMeta
                )
              }
              onBlur={(e) =>
                LeaderGoalStore.saveMeta(
                  LeaderGoalStore.SlotInvestMeta,
                  slotInvest
                )
              }
            />
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-goal-ytd">
            <GoalCardInput
              value={getValue(goalYear, 'team_yearly_goal_slots', slotInvest)}
              onChange={(e) =>
                LeaderGoalStore.setInvestment(
                  {
                    year: goalYear,
                    team_yearly_goal_slots: parseFloat(e.target.value),
                  },
                  SlotInvestMeta
                )
              }
              onBlur={(e) =>
                LeaderGoalStore.saveMeta(
                  LeaderGoalStore.SlotInvestMeta,
                  slotInvest
                )
              }
            />
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-notes">
            <AdminContentEditPopupComponent
              type="RTE"
              color="blue"
              pageId={getAgencyContent(
                'leader-goals-slot-investment-notes',
                'id'
              )}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: getAgencyContent(
                  'leader-goals-slot-investment-notes',
                  'content'
                ),
              }}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="goal-row goal-row-upline-invest">
          <MDBCol className="goal-cell goal-cell-metric">
            USABG/Upline Investment:
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-prev-ytd">
            <GoalCardInput
              value={getValue(compYear, 'personal_previous_slots', usabgInvest)}
              onChange={(e) =>
                LeaderGoalStore.setInvestment(
                  {
                    year: compYear,
                    personal_previous_slots: parseFloat(e.target.value),
                  },
                  UsabgInvestMeta
                )
              }
              onBlur={(e) =>
                LeaderGoalStore.saveMeta(
                  LeaderGoalStore.UsabgInvestMeta,
                  usabgInvest
                )
              }
            />
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-prev-ytd">
            <GoalCardInput
              value={getValue(compYear, 'team_previous_slots', usabgInvest)}
              onChange={(e) =>
                LeaderGoalStore.setInvestment(
                  {
                    year: compYear,
                    team_previous_slots: parseFloat(e.target.value),
                  },
                  UsabgInvestMeta
                )
              }
              onBlur={(e) =>
                LeaderGoalStore.saveMeta(
                  LeaderGoalStore.UsabgInvestMeta,
                  usabgInvest
                )
              }
            />
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-goal-mtd">
            <GoalCardInput
              value={getValue(
                goalYear,
                'personal_monthly_goal_slots',
                usabgInvest
              )}
              onChange={(e) =>
                LeaderGoalStore.setInvestment(
                  {
                    year: goalYear,
                    personal_monthly_goal_slots: parseFloat(e.target.value),
                  },
                  UsabgInvestMeta
                )
              }
              onBlur={(e) =>
                LeaderGoalStore.saveMeta(
                  LeaderGoalStore.UsabgInvestMeta,
                  usabgInvest
                )
              }
            />
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-goal-ytd">
            <GoalCardInput
              value={getValue(
                goalYear,
                'personal_yearly_goal_slots',
                usabgInvest
              )}
              onChange={(e) =>
                LeaderGoalStore.setInvestment(
                  {
                    year: goalYear,
                    personal_yearly_goal_slots: parseFloat(e.target.value),
                  },
                  UsabgInvestMeta
                )
              }
              onBlur={(e) =>
                LeaderGoalStore.saveMeta(
                  LeaderGoalStore.UsabgInvestMeta,
                  usabgInvest
                )
              }
            />
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-goal-mtd">
            <GoalCardInput
              value={getValue(goalYear, 'team_monthly_goal_slots', usabgInvest)}
              onChange={(e) =>
                LeaderGoalStore.setInvestment(
                  {
                    year: goalYear,
                    team_monthly_goal_slots: parseFloat(e.target.value),
                  },
                  UsabgInvestMeta
                )
              }
              onBlur={(e) =>
                LeaderGoalStore.saveMeta(
                  LeaderGoalStore.UsabgInvestMeta,
                  usabgInvest
                )
              }
            />
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-goal-ytd">
            <GoalCardInput
              value={getValue(goalYear, 'team_yearly_goal_slots', usabgInvest)}
              onChange={(e) =>
                LeaderGoalStore.setInvestment(
                  {
                    year: goalYear,
                    team_yearly_goal_slots: parseFloat(e.target.value),
                  },
                  UsabgInvestMeta
                )
              }
              onBlur={(e) =>
                LeaderGoalStore.saveMeta(
                  LeaderGoalStore.UsabgInvestMeta,
                  usabgInvest
                )
              }
            />
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-notes">
            <AdminContentEditPopupComponent
              type="RTE"
              color="blue"
              pageId={getAgencyContent(
                'leader-goals-usabg-investment-notes',
                'id'
              )}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: getAgencyContent(
                  'leader-goals-usabg-investment-notes',
                  'content'
                ),
              }}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="goal-row goal-row-sig-agents">
          <MDBCol className="goal-cell goal-cell-metric">
            Total Signature Agents:
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-prev-ytd">
            {convertToCurrencyWithoutSign(
              Math.round(personalGoals?.previous_total_sig_agents || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-prev-ytd">
            {convertToCurrencyWithoutSign(
              Math.round(teamGoals?.previous_total_sig_agents || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-goal-mtd">
            {convertToCurrencyWithoutSign(
              Math.round(personalGoals?.previous_monthly_goal_sig_agents || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-indv-goal-ytd">
            {convertToCurrencyWithoutSign(
              Math.round(personalGoals?.previous_yearly_goal_sig_agents || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-goal-mtd">
            {convertToCurrencyWithoutSign(
              Math.round(teamGoals?.previous_monthly_goal_sig_agents || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-team-goal-ytd">
            {convertToCurrencyWithoutSign(
              Math.round(teamGoals?.previous_yearly_goal_sig_agents || 0)
            )}
          </MDBCol>
          <MDBCol className="goal-cell goal-cell-notes">
            <AdminContentEditPopupComponent
              type="RTE"
              color="blue"
              pageId={getAgencyContent('leader-goals-sig-agents-notes', 'id')}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: getAgencyContent(
                  'leader-goals-sig-agents-notes',
                  'content'
                ),
              }}
            />
          </MDBCol>
        </MDBRow>
      </div>
    </div>
  )
}

const LeaderGoalsCard = observer(LeaderGoalsCardComponent)

const validateTableData = (tableData) =>
  !!(
    tableData &&
    typeof tableData === 'object' &&
    Array.isArray(tableData) &&
    tableData.length > 0
  )

const renderReportRow = (row, showPrev, onViewModeChange) => {
  const isTotals = `${row?.source_name}`.toLowerCase().includes('totals'),
    sourceName = row?.source_name && `${row.source_name}`,
    canViewGoals = () =>
      UserProfileService.isA(
        ['system-admin', 'agency-owner', 'internal-admin', 'internal-staff'],
        true
      ) &&
      !(
        sourceName &&
        /(Division|Region|District|Career|Recruiter) Totals/.test(sourceName)
      )

  let onClickHandler = () => {}
  if (canViewGoals()) {
    onClickHandler = async (id, ownerName) => {
      LeaderReportStore.isLoading = true

      try {
        const userId = (await LeaderReportStore.getReportById(id))?.get(
          'source_id'
        )
        if (userId && !isNaN(userId)) {
          LeaderReportStore.isLoading = false
          return onViewModeChange('goals', { userId, ownerName })
        }
      } catch (ex) {}

      LeaderReportStore.isLoading = false
    }
  }

  return (
    <ul className={`lgr-row ${showPrev ? 'show-prev' : ''}`}>
      <StandardCell index={0} />
      <StandardCell
        data={
          <>
            <div className="lgr-source-wrapper">
              <div
                className="lgr-source-name"
                onClick={() => onClickHandler(row?.id, row?.source_name)}
              >
                {row?.source_name}
              </div>
              {row?.source_is_10x ? (
                <MDBAlert className="source-is-10x" color="danger">
                  <MDBIcon icon="fire" />
                  10x
                </MDBAlert>
              ) : (
                <></>
              )}
            </div>
          </>
        }
        alignment="left"
        index={1}
      />
      {showPrev ? (
        <>
          <StandardCell data={row?.prev_points} alignment="left" index={2} />
          <StandardCell data={row?.curr_points} alignment="left" index={3} />
          {/* row.timespan,row.start_at,row.end_at */}
          <GradedCell
            data={row?.growth_points}
            alignment="left"
            suffix={'%'}
            toFixed={2}
            index={4}
            field={reportCardFields.totalPoints}
          />
          <TableCellSplitter />
          <StandardCell
            data={row?.prev_enrolled}
            alignment="left"
            index={5}
            onClick={
              isTotals
                ? undefined
                : (data) => {
                    DDStore.fetchAndOpen({
                      id: row?.id,
                      metric: 'prev_enrolled',
                      data,
                    })
                  }
            }
          />
          <StandardCell
            data={row?.curr_enrolled}
            alignment="left"
            index={6}
            onClick={
              isTotals
                ? undefined
                : (data) => {
                    DDStore.fetchAndOpen({
                      id: row?.id,
                      metric: 'curr_enrolled',
                      data,
                    })
                  }
            }
          />
          <GradedCell
            data={row?.growth_enrolled}
            alignment="left"
            suffix={'%'}
            toFixed={2}
            index={7}
            field={reportCardFields.enrolledAgents}
          />
          <TableCellSplitter />
          <StandardCell
            data={row?.prev_completed}
            alignment="left"
            index={8}
            onClick={
              isTotals
                ? undefined
                : (data) => {
                    DDStore.fetchAndOpen({
                      id: row?.id,
                      metric: 'prev_completed',
                      data,
                    })
                  }
            }
          />
          <StandardCell
            data={row?.curr_completed}
            alignment="left"
            index={9}
            onClick={
              isTotals
                ? undefined
                : (data) => {
                    DDStore.fetchAndOpen({
                      id: row?.id,
                      metric: 'curr_completed',
                      data,
                    })
                  }
            }
          />
          <GradedCell
            data={row?.enrolled_converted}
            alignment="left"
            suffix={'%'}
            toFixed={2}
            index={10}
            field={reportCardFields.sigAgentsCompleted}
          />
          <TableCellSplitter />
          <StandardCell
            data={row?.prev_converted}
            alignment="left"
            index={11}
            onClick={
              isTotals
                ? undefined
                : (data) => {
                    DDStore.fetchAndOpen({
                      id: row?.id,
                      metric: 'prev_converted',
                      data,
                    })
                  }
            }
          />
          <StandardCell
            data={row?.curr_converted}
            index={12}
            onClick={
              isTotals
                ? undefined
                : (data) => {
                    DDStore.fetchAndOpen({
                      id: row?.id,
                      metric: 'curr_converted',
                      data,
                    })
                  }
            }
          />
          <GradedCell
            data={row?.completed_converted}
            alignment="left"
            suffix={'%'}
            toFixed={2}
            index={13}
            field={reportCardFields.sigAgentsConversion}
          />
          <TableCellSplitter />
          <StandardCell data={row?.prev_pending} alignment="left" index={14} />
          <StandardCell data={row?.curr_pending} alignment="left" index={15} />
          <StandardCell
            data={row?.prev_signature}
            alignment="left"
            index={16}
          />
          <StandardCell
            data={row?.curr_signature}
            alignment="left"
            index={17}
          />
          <GradedCell
            data={row?.growth_signature}
            alignment="left"
            suffix={'%'}
            toFixed={2}
            index={18}
            field={reportCardFields.signatureAgents}
          />
          {/* <StandardCell data={row?.prev_active} alignment="left" index={19} />
          <StandardCell data={row?.curr_active} alignment="left" index={20} />
          <GradedCell
            data={row?.growth_active}
            alignment="left"
            suffix={'%'}
            toFixed={2}
            index={21}
            field={reportCardFields.activeAgents}
          /> */}
          <StandardCell data={row?.prev_writing} alignment="left" index={22} />
          <StandardCell
            data={row?.curr_writing}
            alignment="left"
            isLast={true}
            index={23}
          />
          <GradedCell
            data={row?.growth_writing}
            alignment="left"
            suffix={'%'}
            toFixed={2}
            index={24}
            field={reportCardFields.writingAgents}
          />
        </>
      ) : (
        <>
          <StandardCell data={row?.curr_points} alignment="left" index={2} />
          <GradedCell
            data={row?.growth_points}
            alignment="left"
            suffix={'%'}
            toFixed={2}
            index={3}
            field={reportCardFields.totalPoints}
          />
          <TableCellSplitter />
          <StandardCell
            data={row?.curr_enrolled}
            alignment="left"
            index={4}
            onClick={
              isTotals
                ? undefined
                : (data) => {
                    DDStore.fetchAndOpen({
                      id: row?.id,
                      metric: 'curr_enrolled',
                      data,
                    })
                  }
            }
          />
          <GradedCell
            data={row?.growth_enrolled}
            alignment="left"
            suffix={'%'}
            toFixed={2}
            index={5}
            field={reportCardFields.enrolledAgents}
          />
          <TableCellSplitter />
          <StandardCell
            data={row?.curr_completed}
            index={6}
            onClick={
              isTotals
                ? undefined
                : (data) => {
                    DDStore.fetchAndOpen({
                      id: row?.id,
                      metric: 'curr_completed',
                      data,
                    })
                  }
            }
          />
          <GradedCell
            data={row?.enrolled_converted}
            alignment="left"
            suffix={'%'}
            toFixed={2}
            index={7}
            field={reportCardFields.sigAgentsCompleted}
          />
          <TableCellSplitter />
          <StandardCell
            data={row?.curr_converted}
            index={8}
            onClick={
              isTotals
                ? undefined
                : (data) => {
                    DDStore.fetchAndOpen({
                      id: row?.id,
                      metric: 'curr_converted',
                      data,
                    })
                  }
            }
          />
          <GradedCell
            data={row?.completed_converted}
            alignment="left"
            suffix={'%'}
            toFixed={2}
            index={9}
            field={reportCardFields.sigAgentsConversion}
          />
          <TableCellSplitter />
          <StandardCell data={row?.curr_pending} alignment="left" index={10} />
          <StandardCell
            data={row?.curr_signature}
            alignment="left"
            index={11}
          />
          <GradedCell
            data={row?.growth_signature}
            alignment="left"
            suffix={'%'}
            toFixed={2}
            index={12}
            field={reportCardFields.signatureAgents}
          />
          {/* <StandardCell data={row?.curr_active} alignment="left" index={13} />
          <GradedCell
            data={row?.growth_active}
            alignment="left"
            suffix={'%'}
            toFixed={2}
            index={14}
            field={reportCardFields.activeAgents}
          /> */}
          <StandardCell
            data={row?.curr_writing}
            alignment="left"
            isLast={true}
            index={15}
          />
          <GradedCell
            data={row?.growth_writing}
            alignment="left"
            suffix={'%'}
            toFixed={2}
            index={16}
            field={reportCardFields.writingAgents}
          />
        </>
      )}
    </ul>
  )
}

const renderTableHeader = (sourceType, showPrev) => {
  return (
    <ul className={`lgr-header ${showPrev ? 'show-prev' : ''}`}>
      <ColumnHeader index={0} />
      <ColumnHeader
        label={
          ['district', 'region', 'career'].includes(sourceType)
            ? `${sourceType} Leaders`
            : `${sourceType}s`
        }
        index={1}
      />
      <ColumnHeader label={reportCardFields.totalPoints} index={2} />
      <ColumnSplitter />
      <ColumnHeader label={reportCardFields.enrolledAgents} index={3} />
      <ColumnSplitter />
      <ColumnHeader label={reportCardFields.sigAgentsCompleted} index={4} />
      <ColumnSplitter />
      <ColumnHeader label={reportCardFields.sigAgentsConversion} index={5} />
      <ColumnSplitter />
      <ColumnHeader label={reportCardFields.pendingAgents} index={6} />
      <ColumnHeader label={reportCardFields.signatureAgents} index={7} />
      {/* <ColumnHeader label={reportCardFields.activeAgents} index={8} /> */}
      <ColumnHeader label={reportCardFields.writingAgents} index={9} />
    </ul>
  )
}

const renderTableDescriptor = (sourceType, showPrev) => {
  return (
    <ul className={`lgr-descriptor ${showPrev ? 'show-prev' : ''}`}>
      <ColumnDescriptor index={0} />
      <ColumnDescriptor index={1} />
      <ColumnDescriptor
        descriptor={`Total points accumulated by the entire ${sourceType}`}
        index={2}
      />
      <ColumnSplitter />
      <ColumnDescriptor
        descriptor={
          'Total agents that enrolled during the reporting period (incl. all agent usertypes)'
        }
        index={3}
      />
      <ColumnSplitter />
      <ColumnDescriptor
        descriptor={`Total agents that have completed all Signature Agent requirements`}
        index={4}
      />
      <ColumnSplitter />
      <ColumnDescriptor
        descriptor={`Total completed Signature Agents that have sold business`}
        index={5}
      />
      <ColumnSplitter />
      <ColumnDescriptor
        descriptor={
          'Total Signature Agents currently in the onboarding process'
        }
        index={6}
      />
      <ColumnDescriptor
        descriptor={'Total Signature Agents (& above)'}
        index={7}
      />
      {/* <ColumnDescriptor
        descriptor={
          'Total active agents, including Pending Agents & Signature Agents.'
        }
        index={8}
      /> */}
      <ColumnDescriptor
        descriptor={'Total Signature Agents (& above) writing business.'}
        index={9}
      />
    </ul>
  )
}

const TableTimespanComponent = ({ dataSet, showPrev, onSortChange }) => {
  const currStartAt = dataSet.timespan?.current?.start
      ? moment(dataSet.timespan.current.start, 'YYYY-MM-DD HH:mm:ss').format(
          'M/D/YYYY'
        )
      : '',
    currEndAt = dataSet.timespan?.current?.end
      ? moment(dataSet.timespan.current.end, 'YYYY-MM-DD HH:mm:ss').format(
          'M/D/YYYY'
        )
      : '',
    compStartAt = dataSet.timespan?.compare?.start
      ? moment(dataSet.timespan.compare.start, 'YYYY-MM-DD HH:mm:ss').format(
          'M/D/YYYY'
        )
      : '',
    compEndAt = dataSet.timespan?.compare?.end
      ? moment(dataSet.timespan.compare.end, 'YYYY-MM-DD HH:mm:ss').format(
          'M/D/YYYY'
        )
      : ''

  if (onSortChange) {
    Sorter.callback = () => {
      onSortChange({
        direction: Sorter.getDirection(),
        column: Sorter.getColumn(),
      })
    }
  }

  const columnMap = !showPrev
    ? [
        null,
        null,
        'curr_points',
        'growth_points',
        'curr_enrolled',
        'growth_enrolled',
        'curr_completed',
        'enrolled_converted',
        'curr_converted',
        'completed_converted',
        'curr_pending',
        'curr_signature',
        'growth_signature',
        'curr_active',
        'growth_active',
        'curr_writing',
        'growth_writing',
      ]
    : [
        null,
        null,
        'prev_points',
        'curr_points',
        'growth_points',
        'prev_enrolled',
        'curr_enrolled',
        'growth_enrolled',
        'prev_completed',
        'curr_completed',
        'enrolled_converted',
        'prev_converted',
        'curr_converted',
        'completed_converted',
        'prev_pending',
        'curr_pending',
        'prev_signature',
        'curr_signature',
        'growth_signature',
        'prev_active',
        'curr_active',
        'growth_active',
        'prev_writing',
        'curr_writing',
        'growth_writing',
      ]

  return (
    <ul className={`lgr-timespan ${showPrev ? 'show-prev' : ''}`}>
      {!showPrev ? (
        <>
          <ColumnTimespan index={0} />
          <ColumnTimespan index={1} />
          <ColumnTimespan
            timespan={`${currStartAt} thru ${currEndAt}`}
            index={2}
            Sorter={
              columnMap[2] && onSortChange
                ? { Sorter, column: columnMap[2] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`Points growth since ${compStartAt} thru ${compEndAt}`}
            index={3}
            Sorter={
              columnMap[3] && onSortChange
                ? { Sorter, column: columnMap[3] }
                : {}
            }
          />
          <ColumnSplitter />
          <ColumnTimespan
            timespan={`${currStartAt} thru ${currEndAt}`}
            index={4}
            Sorter={
              columnMap[4] && onSortChange
                ? { Sorter, column: columnMap[4] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`Enrolled Agents growth since ${compStartAt} thru ${compEndAt}`}
            index={5}
            Sorter={
              columnMap[5] && onSortChange
                ? { Sorter, column: columnMap[5] }
                : {}
            }
          />
          <ColumnSplitter />
          <ColumnTimespan
            timespan={`${currStartAt} thru ${currEndAt}`}
            index={6}
            Sorter={
              columnMap[6] && onSortChange
                ? { Sorter, column: columnMap[6] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`Sig Agents Completed ${currStartAt} thru ${currEndAt}`}
            index={7}
            Sorter={
              columnMap[7] && onSortChange
                ? { Sorter, column: columnMap[7] }
                : {}
            }
          />
          <ColumnSplitter />
          <ColumnTimespan
            timespan={`${currStartAt} thru ${currEndAt}`}
            index={8}
            Sorter={
              columnMap[8] && onSortChange
                ? { Sorter, column: columnMap[8] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`Completed Agents Converted ${currStartAt} thru ${currEndAt}`}
            index={9}
            Sorter={
              columnMap[9] && onSortChange
                ? { Sorter, column: columnMap[9] }
                : {}
            }
          />
          <ColumnSplitter />
          <ColumnTimespan
            timespan={`${currStartAt} thru ${currEndAt}`}
            index={10}
            Sorter={
              columnMap[10] && onSortChange
                ? { Sorter, column: columnMap[10] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`${currStartAt} thru ${currEndAt}`}
            index={11}
            Sorter={
              columnMap[11] && onSortChange
                ? { Sorter, column: columnMap[11] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`Sig Agents growth since ${compStartAt} thru ${compEndAt}`}
            index={12}
            Sorter={
              columnMap[12] && onSortChange
                ? { Sorter, column: columnMap[12] }
                : {}
            }
          />
          {/* <ColumnTimespan
            timespan={`${currStartAt} thru ${currEndAt}`}
            index={13}
            Sorter={
              columnMap[13] && onSortChange
                ? { Sorter, column: columnMap[13] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`Active Agents growth since ${compStartAt} thru ${compEndAt}`}
            index={14}
            Sorter={
              columnMap[14] && onSortChange
                ? { Sorter, column: columnMap[14] }
                : {}
            }
          /> */}
          <ColumnTimespan
            timespan={`${currStartAt} thru ${currEndAt}`}
            index={15}
            Sorter={
              columnMap[15] && onSortChange
                ? { Sorter, column: columnMap[15] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`Writing Agents growth since ${compStartAt} thru ${compEndAt}`}
            index={16}
            Sorter={
              columnMap[16] && onSortChange
                ? { Sorter, column: columnMap[16] }
                : {}
            }
          />
        </>
      ) : (
        <>
          <ColumnTimespan index={0} />
          <ColumnTimespan index={1} />
          <ColumnTimespan
            timespan={`${compStartAt} thru ${compEndAt}`}
            index={2}
            Sorter={
              columnMap[2] && onSortChange
                ? { Sorter, column: columnMap[2] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`${currStartAt} thru ${currEndAt}`}
            index={3}
            Sorter={
              columnMap[3] && onSortChange
                ? { Sorter, column: columnMap[3] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`Points growth since ${compStartAt} thru ${compEndAt}`}
            index={4}
            Sorter={
              columnMap[4] && onSortChange
                ? { Sorter, column: columnMap[4] }
                : {}
            }
          />
          <ColumnSplitter />
          <ColumnTimespan
            timespan={`${compStartAt} thru ${compEndAt}`}
            index={5}
            Sorter={
              columnMap[5] && onSortChange
                ? { Sorter, column: columnMap[5] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`${currStartAt} thru ${currEndAt}`}
            index={6}
            Sorter={
              columnMap[6] && onSortChange
                ? { Sorter, column: columnMap[6] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`Enrolled Agents growth since ${compStartAt} thru ${compEndAt}`}
            index={7}
            Sorter={
              columnMap[7] && onSortChange
                ? { Sorter, column: columnMap[7] }
                : {}
            }
          />
          <ColumnSplitter />
          <ColumnTimespan
            timespan={`${compStartAt} thru ${compEndAt}`}
            index={8}
            Sorter={
              columnMap[8] && onSortChange
                ? { Sorter, column: columnMap[8] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`${currStartAt} thru ${currEndAt}`}
            index={9}
            Sorter={
              columnMap[9] && onSortChange
                ? { Sorter, column: columnMap[9] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`Sig Agents Completed ${currStartAt} thru ${currEndAt}`}
            index={10}
            Sorter={
              columnMap[10] && onSortChange
                ? { Sorter, column: columnMap[10] }
                : {}
            }
          />
          <ColumnSplitter />
          <ColumnTimespan
            timespan={`${compStartAt} thru ${compEndAt}`}
            index={11}
            Sorter={
              columnMap[11] && onSortChange
                ? { Sorter, column: columnMap[11] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`${currStartAt} thru ${currEndAt}`}
            index={12}
            Sorter={
              columnMap[12] && onSortChange
                ? { Sorter, column: columnMap[12] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`Completed Agents Converted ${currStartAt} thru ${currEndAt}`}
            index={13}
            Sorter={
              columnMap[13] && onSortChange
                ? { Sorter, column: columnMap[13] }
                : {}
            }
          />
          <ColumnSplitter />
          <ColumnTimespan
            timespan={`${compStartAt} thru ${compEndAt}`}
            index={14}
            Sorter={
              columnMap[14] && onSortChange
                ? { Sorter, column: columnMap[14] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`${currStartAt} thru ${currEndAt}`}
            index={15}
            Sorter={
              columnMap[15] && onSortChange
                ? { Sorter, column: columnMap[15] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`${compStartAt} thru ${compEndAt}`}
            index={16}
            Sorter={
              columnMap[16] && onSortChange
                ? { Sorter, column: columnMap[16] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`${currStartAt} thru ${currEndAt}`}
            index={17}
            Sorter={
              columnMap[17] && onSortChange
                ? { Sorter, column: columnMap[17] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`Sig Agents growth since ${compStartAt} thru ${compEndAt}`}
            index={18}
            Sorter={
              columnMap[18] && onSortChange
                ? { Sorter, column: columnMap[18] }
                : {}
            }
          />
          {/* <ColumnTimespan
            timespan={`${compStartAt} thru ${compEndAt}`}
            index={19}
            Sorter={
              columnMap[19] && onSortChange
                ? { Sorter, column: columnMap[19] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`${currStartAt} thru ${currEndAt}`}
            index={20}
            Sorter={
              columnMap[20] && onSortChange
                ? { Sorter, column: columnMap[20] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`Active Agents growth since ${compStartAt} thru ${compEndAt}`}
            index={21}
            Sorter={
              columnMap[21] && onSortChange
                ? { Sorter, column: columnMap[21] }
                : {}
            }
          /> */}
          <ColumnTimespan
            timespan={`${compStartAt} thru ${compEndAt}`}
            index={22}
            Sorter={
              columnMap[22] && onSortChange
                ? { Sorter, column: columnMap[22] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`${currStartAt} thru ${currEndAt}`}
            index={23}
            Sorter={
              columnMap[23] && onSortChange
                ? { Sorter, column: columnMap[23] }
                : {}
            }
          />
          <ColumnTimespan
            timespan={`Writing Agents growth since ${compStartAt} thru ${compEndAt}`}
            index={24}
            Sorter={
              columnMap[24] && onSortChange
                ? { Sorter, column: columnMap[24] }
                : {}
            }
          />
        </>
      )}
    </ul>
  )
}

const TableTimespan = observer(TableTimespanComponent)

const TableBodyComponent = ({ dataSet, showPrev, onViewModeChange }) => {
  const totals = {
      source_name: `${dataSet.sourceType
        .charAt(0)
        .toUpperCase()}${dataSet.sourceType.substr(1)} Totals`,
    },
    sourceFilter = dataSet?.sourceFilter

  const renderTotals = () => {
    Object.keys(totals)
      .filter((key) => key.indexOf('growth_') > -1)
      .forEach((key) => {
        totals[key] = getGrowthV2(
          totals[key.replace('growth_', 'prev_')],
          totals[key.replace('growth_', 'curr_')]
        )
      })

    totals.enrolled_converted = getGrowth(
      totals.curr_enrolled,
      totals.curr_completed
    )
    totals.completed_converted = getGrowth(
      totals.curr_completed,
      totals.curr_converted
    )

    return (
      <li
        className={`lgr-row-wrapper totals-row fw--500 ${
          showPrev ? 'show-prev' : ''
        }`}
      >
        {renderReportRow(totals, showPrev, onViewModeChange)}
      </li>
    )
  }

  return (
    <ul className={`lgr-body ${showPrev ? 'show-prev' : ''}`}>
      <ColumnKeyIndicator tKey={dataSet?.key} />
      {dataSet?.rows
        ?.filter((row) => {
          if (sourceFilter && !!sourceFilter?.is_10x)
            return (
              row.source_is_10x === true || parseInt(row.source_is_10x) === 1
            )
          return true
        })
        .map((row, idx) => {
          Object.keys(row).forEach((key) => {
            let val =
              `${row[key]}`.length > 0 && !isNaN(row[key])
                ? parseFloat(row[key])
                : false
            if (val !== false) {
              if (!totals.hasOwnProperty(key)) totals[key] = 0

              totals[key] = totals[key] + parseFloat(row[key])
            }
          })

          return (
            <li
              key={`lgr-row-${dataSet?.sourceType}-${idx}`}
              className={`lgr-row-wrapper ${showPrev ? 'show-prev' : ''}`}
            >
              {renderReportRow(row, showPrev, onViewModeChange)}
            </li>
          )
        })}
      {renderTotals()}
    </ul>
  )
}

const TableBody = observer(TableBodyComponent)

const organizeTableData = (tableData) => {
  let mtd = {
      rows: tableData.filter((row) => row.timespan === 'mtd'),
      timespan: null,
      key: 'mtd',
    },
    ytd = {
      rows: tableData.filter((row) => row.timespan === 'ytd'),
      timespan: null,
      key: 'ytd',
    },
    sortColumn = Sorter && Sorter.getColumn(),
    sortDirection = Sorter && Sorter.getDirection()

  if (mtd.rows.length > 0) {
    mtd.timespan = {
      current: {
        start: mtd.rows[0].start_at,
        end: mtd.rows[0].end_at,
      },
      compare: {
        start: mtd.rows[0].prev_start_at,
        end: mtd.rows[0].prev_end_at,
      },
    }

    if (sortColumn && sortDirection) {
      mtd.rows = mtd.rows.sort((a, b) => {
        a = parseFloat(a[sortColumn])
        b = parseFloat(b[sortColumn])

        if (parseInt(a) === 9999) a = a * -1
        if (parseInt(b) === 9999) b = b * -1

        if (sortDirection === 'ASC') return a < b ? 1 : a > b ? -1 : 0
        if (sortDirection === 'DESC') return a > b ? 1 : a < b ? -1 : 0
        return 0
      })
    }
  }

  if (ytd.rows.length > 0) {
    ytd.timespan = {
      current: {
        start: ytd.rows[0].start_at,
        end: ytd.rows[0].end_at,
      },
      compare: {
        start: ytd.rows[0].prev_start_at,
        end: ytd.rows[0].prev_end_at,
      },
    }

    if (sortColumn && sortDirection) {
      ytd.rows = ytd.rows.sort((a, b) => {
        a = parseFloat(a[sortColumn])
        b = parseFloat(b[sortColumn])

        if (parseInt(a) === 9999) a = a * -1
        if (parseInt(b) === 9999) b = b * -1

        if (sortDirection === 'ASC') return a < b ? 1 : a > b ? -1 : 0
        if (sortDirection === 'DESC') return a > b ? 1 : a < b ? -1 : 0
        return 0
      })
    }
  }

  return { mtd, ytd }
}

const ReportTableComponent = ({ onSortChange, goTo, onViewModeChange }) => {
  const { showPrev, reportData, sourceType, sourceFilter } = LeaderReportStore
  const [organizedData, setOrganizedData] = useState({ mtd: {}, ytd: {} })
  const wrapperRef = useRef(null)

  useEffect(() => {
    if (wrapperRef.current) {
      const cardBodyElement = document.getElementById('leader-report-card-body')

      const handleScroll = () => {
        if (cardBodyElement.scrollLeft === 0 && DDStore.isScrolling === true)
          DDStore.isScrolling = false
      }

      const handleScrollStart = () => {
        if (cardBodyElement.scrollLeft > 0 && DDStore.isScrolling === false)
          DDStore.isScrolling = true
      }

      if (cardBodyElement) {
        cardBodyElement.addEventListener('scroll', handleScroll)
        cardBodyElement.addEventListener('scroll', handleScrollStart)
      }
    }
  }, [])

  useEffect(() => {
    if (reportData) {
      const { mtd, ytd } = organizeTableData(reportData)
      setOrganizedData({ mtd, ytd })
    }
  }, [reportData])

  const handleSortChange = (sortParams) => {
    // Your custom sort change logic here, if any
    if (onSortChange) {
      onSortChange(sortParams)
    }
    // Reorganize the data based on new sort parameters
    if (reportData) {
      const { mtd, ytd } = organizeTableData(reportData)
      setOrganizedData({ mtd, ytd })
    }
  }

  if (validateTableData(reportData)) {
    return (
      <div id="lgr-table-wrapper" ref={wrapperRef}>
        {renderTableHeader(sourceType, showPrev)}
        {renderTableDescriptor(sourceType, showPrev)}
        <div className="lgr-dataset">
          <TableTimespan
            dataSet={{ ...organizedData?.mtd, sourceType }}
            showPrev={showPrev}
            onSortChange={handleSortChange}
          ></TableTimespan>
          <TableBody
            dataSet={{ ...organizedData?.mtd, sourceType, sourceFilter }}
            showPrev={showPrev}
            onViewModeChange={(vm, opts) => onViewModeChange(vm, opts)}
          ></TableBody>
        </div>
        <div className="lgr-dataset">
          <TableTimespan
            dataSet={{ ...organizedData?.ytd, sourceType }}
            showPrev={showPrev}
            onSortChange={handleSortChange}
          ></TableTimespan>
          <TableBody
            dataSet={{ ...organizedData?.ytd, sourceType, sourceFilter }}
            showPrev={showPrev}
            onViewModeChange={(vm, opts) => onViewModeChange(vm, opts)}
          ></TableBody>
        </div>
        <div className="lgr-modals">
          <MDBModal
            isOpen={DDStore.isOpen}
            toggle={DDStore.close}
            size="fullscreen"
          >
            <MDBModalHeader toggle={DDStore.close}>
              Leader Report Card - Drill Down
            </MDBModalHeader>
            <MDBModalBody>
              {DDStore.isFetching ? (
                <div>
                  <span>
                    <i className="fa fa-spin fa-spinner" />
                    &nbsp;Fetching Data ...
                  </span>
                </div>
              ) : (
                DDStore.renderTable({ goTo })
              )}
            </MDBModalBody>
          </MDBModal>
        </div>
      </div>
    )
  }

  return <></>
}

const ReportTable = observer(ReportTableComponent)

const LeaderReportTable = ({
  onViewModeChange,
  onDateChange,
  viewMode,
  goTo,
  ownerName,
}) => {
  // `sortBy` is flagged as not used.
  // Table columns are sorting by the Sorter instance object
  // that reports back to this useState() on change to update DOM.
  // eslint-disable-next-line

  const { reportDate, showPrev } = LeaderReportStore,
    isLoading = LeaderReportStore.isLoading || LeaderGoalStore.isLoading,
    [sortBy, onSortChange] = useState({ column: null, direction: null })

  return (
    <MDBContainer
      className={
        'LeaderReportTableComponent ' +
        (isLoading ? 'is-loading ' : '') +
        (LeaderReportStore.isEmpty() ? 'is-empty ' : '') +
        (DDStore.isScrolling ? 'isScrolled' : '')
      }
      fluid
    >
      <div className="loading-spinner">
        <LoadingSpinner size="md" isActive={true} />
      </div>
      <MDBRow>
        <MDBCol size="12" className="table-wrapper">
          <MDBCard>
            <MDBCardHeader>
              <div className="headerField">
                <div className="report-selector-column">
                  <LeaderReportSelector viewMode={viewMode} />
                  <MDBCollapse isOpen={viewMode === 'goals'}>
                    {ownerName
                      ? `Viewing ${ownerName}'s Goals`
                      : `Viewing Goals`}
                  </MDBCollapse>
                </div>
                <div className="report-date-column">
                  <LeaderDateSelector
                    viewMode={viewMode}
                    isDisabled={isLoading}
                    reportDate={
                      viewMode === 'goals'
                        ? LeaderGoalStore.goalDate
                        : LeaderReportStore.reportDate
                    }
                    onDateChange={(reportDate) => onDateChange(reportDate)}
                  />
                </div>
                <div className="report-toggle-column">
                  <ViewModeSelector
                    viewMode={viewMode}
                    onViewModeChange={(vm) =>
                      onViewModeChange(vm, { userId: null })
                    }
                  />
                </div>
              </div>
            </MDBCardHeader>
            {viewMode === 'reports' ? (
              <MDBCardBody id="leader-report-card-body">
                {LeaderReportStore.isEmpty() ? (
                  <MDBAlert color="warning">
                    There are no report results for{' '}
                    {reportDate
                      ? moment(reportDate).format('MM/DD/YYYY')
                      : reportDate}
                    .
                  </MDBAlert>
                ) : (
                  <ReportTable
                    onSortChange={onSortChange}
                    goTo={goTo}
                    onViewModeChange={(vm, vmOpts) =>
                      onViewModeChange(vm, vmOpts)
                    }
                  ></ReportTable>
                )}
              </MDBCardBody>
            ) : (
              <></>
            )}
            {viewMode === 'goals' ? (
              <MDBCardBody>
                <LeaderGoalsCard />
              </MDBCardBody>
            ) : (
              <></>
            )}
          </MDBCard>
          {!isLoading ? (
            <MDBBtn
              className="show-prev-btn"
              onClick={() => LeaderReportStore.onTogglePrev()}
            >
              {showPrev ? 'Hide Comparison Data' : 'Show Comparison Data'}
            </MDBBtn>
          ) : (
            <></>
          )}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(LeaderReportTable)
