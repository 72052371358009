import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import {
  MDBRow, 
  MDBCol, 
  MDBContainer
} from 'mdbreact'
import MenuBuilder from './components/MenuBuilder/MenuBuilder.component';
import { WebsiteInfoStore } from './stores'

class MenuLinks extends Component 
{
  componentDidMount () 
  {
    WebsiteInfoStore
        .fetchSiteDetails()
        .then(() => WebsiteInfoStore.fetchSiteLinks());
  }

  render () 
  {
    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">              
              <MDBRow>
                <MDBCol size='12' sm='6'>
                  <MenuBuilder linkType='navigation' />
                </MDBCol>
                <MDBCol size='12' sm='6'>
                  <MenuBuilder linkType='resources' />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default MenuLinks
