import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBCollapse } from 'mdbreact'
import ACMStore from './../AgentContractManager.store'
import AgentContractsTable from './../AgentContractsTable/AgentContractsTable.component'
import SaveContractingCartButton from './../SaveContractingCartButton/SaveContractingCartButton.component'
import ContractingViewStateChangeButton from './../ContractingViewStateChangeButton/ContractingViewStateChangeButton.component'
import ContractingCartItemList from './../ContractingCartItemList/ContractingCartItemList.component'
import AvailableContractsList from './../AvailableContractsList/AvailableContractsList.component'

import './AgentContractingCart.scss'

const AgentContractingCart = ({ activeSpecs, onCheckout }) => {
  const [view, setView] = useState({ name: null, goto: null, is_open: false })

  // const hasClosed = () =>
  // {
  // 	if (view.goto && view.goto !== view.name)
  // 		setView(prevState => ({...prevState, name: prevState.goto, is_open: true}));
  // }

  const renderWidget = () => {
    if (!ACMStore?.Cart)
      return (
        <MDBContainer fluid id="AgentContractingCartComponent">
          <MDBRow>
            <MDBCol></MDBCol>
          </MDBRow>
        </MDBContainer>
      )

    return (
      <MDBContainer fluid id="AgentContractingCartComponent">
        <label className="section-label">
          <MDBIcon icon="shopping-cart" />
          &nbsp;Agent Contracting - Carrier Contract Shopping Cart
        </label>

        <MDBRow className="mb-5 match-height">
          <MDBCol size="12" md="6">
            <ContractingViewStateChangeButton
              activeView={view.name}
              onToggle={(goto, is_open) => {
                setView((prevState) => {
                  // Use this logic for a single collapse container that changes its content on close.
                  // is_open = !!is_open
                  // if (is_open && !view.name)
                  // 	return {...prevState, name: goto, is_open}
                  // return {...prevState, goto, is_open}

                  return {
                    ...prevState,
                    name: goto,
                    goto,
                    is_open: !!is_open || prevState.is_open,
                  }
                })
              }}
            />
          </MDBCol>
          <MDBCol size="12" md="6">
            <ContractingCartItemList activeSpecs={activeSpecs} />
            <SaveContractingCartButton onCheckout={onCheckout} />
          </MDBCol>
        </MDBRow>

        {/*
					// Use this logic for a single collapse container that changes its content on close.
					<MDBCollapse isOpen={!!view.is_open} onClosed={hasClosed} >
				    <MDBRow>
				      <MDBCol>
				        {view.name ? (view.name === 'shop' ? <AvailableContractsList activeSpecs={activeSpecs} /> : <AgentContractsTable />) : <></>}
				      </MDBCol>
				    </MDBRow>
					</MDBCollapse>
				*/}

        <MDBCollapse isOpen={!!view.is_open && view.name === 'shop'}>
          <MDBRow>
            <MDBCol>
              <AvailableContractsList activeSpecs={activeSpecs} />
            </MDBCol>
          </MDBRow>
        </MDBCollapse>

        <MDBCollapse isOpen={!!view.is_open && view.name === 'manage'}>
          <MDBRow>
            <MDBCol>
              <AgentContractsTable activeSpecs={activeSpecs} />
            </MDBCol>
          </MDBRow>
        </MDBCollapse>
      </MDBContainer>
    )
  }

  return renderWidget()
}

export default observer(AgentContractingCart)
