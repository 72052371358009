import BaseModelInstance from './base.model'
import { KnowledgebaseCategory as ModelDefinition } from './model-definitions'
import { KnowledgebaseArticleFactory } from './../factories'

class KnowledgebaseCategory extends BaseModelInstance {
  afterSet = (key, value) => {}

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }

  #children = {
    article: [],
  }

  article = (useApi) => {
    if (useApi === true) {
      return {
        all: async () =>
          (await KnowledgebaseArticleFactory.search({
            search: { category_id: this.id() },
            pagination: false,
          })) ||
          {} ||
          [],
        create: async (data) =>
          await KnowledgebaseArticleFactory.create({
            ...(data ? data : {}),
            user_id: this.id(),
          }),
      }
    }

    return Array.isArray(this.#children?.article) ? this.#children.article : []
  }

  overview = async () => {
    return {
      name: this.get('c_name'),
      articles: await this.article(true).all(),
      // await Category.article(true).all()
    }
  }
}

export default KnowledgebaseCategory
