import React from 'react'
import { useHistory } from 'react-router-dom';
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
import {
  MDBAlert,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBIcon
} from 'mdbreact'
import UserProfileService from '../../../../shared/services/UserProfile.service';

import './AlertMessage.component.scss'

class AlertStore {
  message = ''
  length = 0
  alertType = ''
  showModal = false
  constructor() {
    makeAutoObservable(this)
  }
  setMessage(message) {
    this.length =
      message && typeof message === 'string'
        ? `${message}`.replace(/<[^>]*>?/gm, '').length
        : 0
    if (this.length > 0) this.message = message
  }
}

const Store = new AlertStore()

const AlertMessage = ({ message, alertType }) => {
  const history = useHistory();
  Store.alertType = alertType
  Store.setMessage(message)

  const renderAlert = () => {
    if (Store.length === 0) return <></>
  
    if (Store.length > 150)
      return (
        <MDBAlert color={Store.alertType} className="mt-3 alert-container">
          <div
            className="d-inline-block"
            dangerouslySetInnerHTML={{
              __html: Store.message.substring(0, 200) + '...',
            }}
          />
          <MDBBtn
            size={'sm'}
            color={Store.alertType}
            type="button"
            onClick={() => (Store.showModal = true)}
          >
            View More
          </MDBBtn>
          {
            UserProfileService.isA(
              [
                'internal-admin',
                'internal-staff',
                'system-admin',
              ],
              true
            ) && (
              <MDBIcon 
                fas 
                icon="edit" 
                className='alert-edit' 
                onClick={(event) => history.push('/manage-messaging#alerts-md')} 
              />
            )
          }
        </MDBAlert>
      )
  
    return (
      <MDBAlert color={Store.alertType} className="mt-3 alert-container">
        <div dangerouslySetInnerHTML={{ __html: Store.message }} />
        {
          UserProfileService.isA(
            [
              'internal-admin',
              'internal-staff',
              'system-admin',
            ],
            true
          ) && (
            <MDBIcon 
              fas 
              icon="edit" 
              className='alert-edit' 
              onClick={(event) => history.push('/manage-messaging#alerts-md')} 
            />
          )
        }
      </MDBAlert>
    )
  }
  
  const renderModal = () => {
    return (
      <MDBModal isOpen={Store.showModal} toggle={() => (Store.showModal = false)}>
        <MDBModalHeader toggle={() => (Store.showModal = false)}>
          Alert
        </MDBModalHeader>
        <MDBModalBody>
          <p
            className="text-justify"
            dangerouslySetInnerHTML={{ __html: Store.message }}
          />
          <MDBBtn
            color={Store.alertType}
            size={'sm'}
            onClick={() => (Store.showModal = false)}
          >
            OK
          </MDBBtn>
        </MDBModalBody>
      </MDBModal>
    )
  }

  return (
    <div id="DashboardAlertMessageComponent">
      {renderAlert()}
      {renderModal()}
    </div>
  )
}

export default observer(AlertMessage)
