import { makeAutoObservable, toJS } from 'mobx'
import CarrierContractLink from './../../shared/services/CarrierContractLink.service'
import {
  UserFactory,
  CarrierContractLinkFactory,
} from './../../shared/factories'
import UserService from './../../shared/services/User.service'
import UsertypeService from './../../shared/services/Usertype.service'
import CarrierMgmtStore from './CarrierMgmt.store'

const fetchLeaders = async () => {
  try {
    return await UserFactory.search({
      search: {
        usertype_id: UsertypeService.nameToId('recruiter-group'),
        u_active: 1,
      },
      pagination: false,
    })
  } catch (ex) {
    console.log(`Failed to fetch carrier contract leaders. ex: ${ex}`)
    return false
  }
}

const fetchContractLinks = async (carrier_id, user_id) => {
  try {
    return await CarrierContractLinkFactory.search({
      search: { carrier_id, user_id },
      pagination: false,
    })
  } catch (ex) {
    console.log(`Failed to fetch carrier contract links. ex: ${ex}`)
    return false
  }
}

class CarrierLinkBank {
  constructor() {
    makeAutoObservable(this)
  }

  carrierId = null
  leaderId = null
  Leader = null
  Leaders = []

  userContractLinks = {}
  ContractLinks = []

  loading = {
    'fetch.links': false,
    'fetch.users': false,
    'fetch.user': false,
    'update.comm-links': false,
  }

  get hasChanged() {
    return (
      Object.values(this.userContractLinks).filter((Link) => Link.hasChanged)
        .length > 0
    )
  }

  fetchByCarrierId = async (carrierId) => {
    let links = [],
      carrierChanged = parseInt(this.carrierId) !== parseInt(carrierId)
    this.loading['fetch.links'] = true

    const ContractLinks = await fetchContractLinks(carrierId)

    try {
      links = await CarrierContractLink.search({
        search: { carrier_id: carrierId },
        pagination: false,
      })
    } catch (ex) {
      console.error(`Failed to fetch carrier contract link bank. ex: ${ex}`)
    }

    this.links = links && Array.isArray(links?.models) ? links?.models : []
    this.ContractLinks = ContractLinks || []

    if (carrierChanged) this.fetchLeaders((this.carrierId = carrierId))

    this.loading['fetch.links'] = false
  }

  fetchLeaders = async () => {
    let users = []
    this.loading['fetch.users'] = true

    const Leaders = await fetchLeaders()

    try {
      users = (
        await UserService.search({
          search: {
            usertype_id: UsertypeService.nameToId('recruiter-group'),
            u_active: 1,
          },
          pagination: false,
        })
      ).models
    } catch (ex) {
      console.log(`Failed to fetch carrier contract leaders. ex: ${ex}`)
    }

    users = users && Array.isArray(users) ? users : []
    users.sort((a, b) =>
      a.u_fname > b.u_fname ? 1 : a.u_fname < b.u_fname ? -1 : 0
    )

    this.Leaders = (Leaders || []).sort((A, B) =>
      A.get('u_fname') > B.get('u_fname')
        ? 1
        : A.get('u_fname') < B.get('u_fname')
        ? -1
        : 0
    )
    this.loading['fetch.users'] = false
  }

  fetchLeaderCommLinks = async () => {
    let userContractLinks = {},
      carrierId = toJS(this.carrierId)

    if (isNaN(carrierId)) return false

    this.loading['fetch.user'] = true

    const ContractLinks = await fetchContractLinks(carrierId, this.leaderId)

    let commLevels = toJS(CarrierMgmtStore.commLevels)
    ;(commLevels && typeof commLevels === 'object'
      ? Object.values(commLevels)
      : []
    ).forEach((commLevel) => {
      let ContractLink = ContractLinks.filter(
        (ContractLink) =>
          parseInt(ContractLink.get('commission_id')) === parseInt(commLevel.id)
      ).shift()
      userContractLinks[commLevel.com_code] = ContractLink
        ? ContractLink
        : CarrierContractLinkFactory.create({
            carrier_id: carrierId,
            user_id: this.leaderId,
            commission_id: commLevel.id,
            link: null,
            mode: 'LINK',
          })
    })

    // this.userCommLinks = userCommLinks
    this.userContractLinks = userContractLinks
    this.loading['fetch.user'] = false

    return userContractLinks
  }

  saveLeaderCommLinks = async () => {
    /*
    const isValid = (attribs) => 
    {
      const { carrier_id, user_id, commission_id, link, document_id, mode } =
        attribs || {}
      if (
        carrier_id !== null &&
        !isNaN(carrier_id) &&
        user_id !== null &&
        !isNaN(user_id) &&
        commission_id !== null &&
        !isNaN(commission_id)
      )
        if ((mode === 'LINK' && link) || (mode === 'DOCUMENT' && document_id))
          return true
    }
    */

    const ContractLinks = Object.values(this.userContractLinks),
      ChangedContractLinks = ContractLinks.filter(
        (ContractLink) => ContractLink.hasChanged
      )

    this.loading['update.comm-links'] = true
    await Promise.all(
      ChangedContractLinks.map((ContractLink) => ContractLink.save())
    )
    this.loading['update.comm-links'] = false
  }
}

export default new CarrierLinkBank()
