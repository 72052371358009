import BaseModelFactory from './base.factory'
import UserService from './../services/User.service'
import User from './../models/user.model'

class UserFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: UserService, Model: User }
    )
  static findById = async (id) =>
    await this._findBy({ id }, { Service: UserService, Model: User })
  static create = (payload) =>
    this._create(payload, { Service: UserService, Model: User })
}

export default UserFactory
