import React, { useState } from 'react'
import {observer} from 'mobx-react-lite';
import ContentHtml from './../../../../components/content/ContentHtml/ContentHtml.component'
import {WebsiteInfoStore} from './../../stores';
import AdminApi from './../../../../api/admin-api/admin-api'
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCollapse,
  MDBAlert
} from 'mdbreact'

import './BookMeetingForm.component.scss';

const saveTimer = {};

const BookMeetingForm = () =>
{
	const [formError, setFormError] = useState(null),
				[formSuccess, setFormSuccess] = useState(null);

	const onSelectChange = event => 
		WebsiteInfoStore.siteDetails[event.target.name] = event.target.value;

	const validate = () =>
	{
    const { siteDetails } = WebsiteInfoStore,
    			regHttpLink = /^(http(s)?:\/\/).*/i

    if (
      siteDetails.s_book_appt_link &&
      siteDetails.s_book_appt_link.trim() &&
      !regHttpLink.test(siteDetails.s_book_appt_link)
    ) {
      const { s_book_appt_link } = siteDetails
    	WebsiteInfoStore.siteDetails.s_book_appt_link = `http://${s_book_appt_link}`;
    }

    setFormError(null);
    return true;
	}

	const updateInfo = async () =>
	{
    if (!(await validate()))
    	return false;

    if (WebsiteInfoStore.isLoading || WebsiteInfoStore.isSaving)
      return

    const data = {
    	s_book_appt_link: WebsiteInfoStore.siteDetails?.s_book_appt_link,
      id: WebsiteInfoStore.siteDetails?.site_id
    }

    let form_data = new FormData()
    for (let key in data)
      form_data.append(key, data[key])

    WebsiteInfoStore.isSaving = true;

    AdminApi.updateWebsiteInfo(form_data)
      .then((result) => {
      	setFormSuccess('Successfully updated book a meeting link.')

      	if (saveTimer.timer) {
      		window.clearTimeout(saveTimer.timer)
      		saveTimer.timer = null;
      	}

      	saveTimer.timer = setTimeout(() => setFormSuccess(null), 4000);

      	WebsiteInfoStore.isSaving = false;
      })
      .catch((error) => {setFormError(error); WebsiteInfoStore.isSaving = false;})
	}

  return (
		  <MDBContainer fluid id="BookMeetingFormComponent" className="mt-5">
		    <MDBRow>
		    	<MDBCol>
		    		<h5 className='text--blue font--lato fw--500'>Setup Your Book A Meeting Link</h5>
		    	</MDBCol>
		    </MDBRow>
		    <MDBRow>
		    	<MDBCol>
		    		<ContentHtml contentSlug='subsite-book-meeting-howto' />
		    	</MDBCol>
		    </MDBRow>
		    <MDBRow>
		    	<MDBCol className="form-group">
	          <label>Book a Meeting Link</label>
	          <input
	            type="text"
	            className="form-control"
	            name="s_book_appt_link"
	            id="s_book_appt_link"
	            value={WebsiteInfoStore.siteDetails?.s_book_appt_link || ''}
	            onChange={onSelectChange}
	          />
		    	</MDBCol>
		    </MDBRow>
		    <MDBRow>
		    	<MDBCol className="form-group">
	          <MDBBtn
	            color="indigo"
	            type="button"
	            onClick={updateInfo}
	          >
	            {WebsiteInfoStore.isSaving ? 'Saving...' : 'Save'}
	          </MDBBtn>
		    	</MDBCol>
		    </MDBRow>
		    <MDBRow>
		    	<MDBCol>
          	<MDBCollapse isOpen={!!formError}><MDBAlert color='danger'>{formError}</MDBAlert></MDBCollapse>
          	<MDBCollapse isOpen={!!formSuccess}><MDBAlert color='success'>{formSuccess}</MDBAlert></MDBCollapse>
		    	</MDBCol>
		    </MDBRow>
		  </MDBContainer>
  	)
}

export default observer(BookMeetingForm)