import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import TrainingAnswer from '../TrainingAnswer/TrainingAnswer.component'

import './TrainingQuestion.scss'

class TrainingQuestion extends Component {
  state = {
    question: null,
    answers: [],
    answer: false,
    valid: [],
  }

  componentDidMount() {
    this.setState({
      question: this.props.question,
      answers: this.props.answers,
    })
  }

  componentDidUpdate() {
    this.setValid()
  }

  onClickAnswer(answer) {
    this.setState({ answer })
    this.props.onClickAnswer(answer)
  }

  getQuestionId() {
    return this.state.question && this.state.question?.id
      ? parseInt(this.state.question?.id)
      : false
  }

  setValid() {
    const questionId = this.getQuestionId(),
      validAnswers =
        questionId &&
        (Array.isArray(this.props.validAnswers) ? this.props.validAnswers : [])
          .filter((answer) => answer?.answer && !isNaN(answer?.answer))
          .map((answer) => parseInt(answer.answer))

    if (this.state.valid.join('') !== validAnswers.join(''))
      this.setState({ valid: validAnswers })
  }

  render() {
    const isSubmitted = !!this.props?.submitted,
      isAnswered = !!this.state.answer,
      isValid =
        isAnswered && this.state.valid.includes(parseInt(this.state.answer?.id))

    return (
      <MDBContainer
        className={`TrainingQuestionComponent ${
          isSubmitted && isAnswered
            ? 'answered ' + (isValid ? 'correct' : 'incorrect')
            : ''
        }`}
      >
        <MDBRow>
          <MDBCol size="12">
            <p className="training-question">
              <MDBIcon
                fas
                icon={
                  isSubmitted && isAnswered
                    ? isValid
                      ? 'check-circle'
                      : 'times-circle'
                    : 'chevron-circle-right'
                }
              />
              {this.state.question?.question}
            </p>
          </MDBCol>
          <MDBCol size="12">
            <MDBRow>
              {this.state.answers?.map((answer, index) => {
                return (
                  <TrainingAnswer
                    key={`training-answer-${answer.id}-${index}`}
                    answer={answer}
                    onClickAnswer={(answer) => this.onClickAnswer(answer)}
                  ></TrainingAnswer>
                )
              })}
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default TrainingQuestion
