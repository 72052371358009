import SystemSettingStore from './../../shared/stores/systemSetting.store'
import LeaderboardsPageStore from '../../pages/Reports/store/LeaderboardsPage.store'
import moment from 'moment/moment'
import { useMemo } from 'react'

export const useBreakTheBarSetting = (agentScope) => {
  const startDate = useMemo(() => {
    return (
      LeaderboardsPageStore.fetchParams.start ||
      moment().startOf('isoweek').format('YYYY-MM-DD')
    )
  }, [LeaderboardsPageStore.fetchParams.start])

  const threshold = useMemo(() => {
    const BtbThresholds = SystemSettingStore.get(
      'usertypes---break-the-bar-thresholds'
    )
    let thresholdHistory = []

    if (BtbThresholds && Array.isArray(BtbThresholds[agentScope]?.history))
      thresholdHistory = BtbThresholds[agentScope].history

    if (!thresholdHistory || !thresholdHistory.length) return 0

    thresholdHistory = thresholdHistory.sort((a, b) => {
      let diff = moment(a.start, 'YYYY-MM-DD').diff(
        moment(b.start, 'YYYY-MM-DD'),
        'days'
      )
      return diff > 0 ? -1 : diff < 0 ? 1 : 0
    })

    let initHist = thresholdHistory[0]
    thresholdHistory = thresholdHistory.filter((th) => {
      return moment(startDate, 'YYYY-MM-DD').diff(
        moment(th.start, 'YYYY-MM-DD'),
        'days'
      ) >= 0
        ? true
        : false
    })

    if (thresholdHistory.length === 0) thresholdHistory.push(initHist)

    return parseInt(
      thresholdHistory[0]?.value !== null && !isNaN(thresholdHistory[0]?.value)
        ? thresholdHistory[0].value
        : 0
    )
  }, [
    SystemSettingStore.Settings['usertypes---break-the-bar-thresholds'],
    startDate,
    agentScope,
  ])

  return { threshold, startDate }
}
