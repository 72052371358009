import React from 'react'
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBContainer,
  MDBFileInput,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBIcon,
  MDBBtn,
} from 'mdbreact'
import AgentEandOManager from './../AgentEandOManager/AgentEandOManager.component'
import { setGroupEandO } from './../../../shared/utilities/setGroupEandO.function'
import env from './../../../environments/environment'
import { b64toBlob } from './../../../shared/utilities/b64toBlob.function'
import AgentStateLicenses from './../AgentStateLicenses/AgentStateLicenses.component'
import AgentHeadshotManager from './../AgentHeadshotManager/AgentHeadshotManager.component'
import UserProfileService from './../../../shared/services/UserProfile.service'
import UserService from './../../../shared/services/User.service'
import UserMetaService from './../../../shared/services/UserMeta.service'
import AgentProfileStore from './AgentProfile.store'
import IntakeStepIndicator from './../IntakeStepIndicator/IntakeStepIndicator.component'
import { Subscription } from 'rxjs'
import exampleVoidedCheck from './assets/imgs/img.voided-check.png'
import './AgentProfileIntake.scss'
import { toast } from 'react-toastify'
import { allowedSizeByUploadType } from '../../../constants/allowedMediaSize'

class AgentProfileIntake extends React.Component {
  state = {
    loading: true,
    npn: '',
    voided_check_url: null,
    voided_check_id: null,
    eopolicy: false,
    routing_number: '',
    account_number: '',
    show_delete_modal: false,
    is_deleting: false,
    is_creating: false,
  }

  __subscriptions$ = new Subscription()

  _onSelectFile = (file, source) => {
    if (file.size > allowedSizeByUploadType[source])
      return toast.error(
        'Selected file exceeds max file size.  Please try another file.'
      )
    const reader = new FileReader()
    reader.addEventListener('load', () =>
      this.setState(
        (prevState) => ({
          ...prevState,
          [`${source}_src`]: { filename: file?.name, data: reader.result },
        }),
        async () => {
          // Extract content-type from complete base64 payload.
          let match = this.state[`${source}_src`].data
            .split(',')[0]
            .match(/\w+\/\w+/)
          match = match && match.shift()

          // Extract raw base64 data w/out content-type.
          let base64 = this.state[`${source}_src`].data.split(',')[1]

          // Build payload to send file to the server.
          const formData = new FormData()
          formData.append(source, b64toBlob(base64, match))
          formData.append(`${source}_filename`, file.name)

          try {
            await UserService.uploadBlankCheck(
              UserProfileService.getUserId(),
              formData
            )
            this._fetchUserVoidedCheck().then((voided_check) =>
              this.setState(
                (prevState) => ({ ...prevState, ...voided_check }),
                () =>
                  AgentProfileStore.setValidity(
                    'voided_check',
                    !!voided_check?.voided_check_url
                  )
              )
            )
          } catch (ex) {
            toast.error(`Failed to upload: ${ex.message}`)
          }
        }
      )
    )

    reader.readAsDataURL(file)
  }

  _onCreateBlankCheck = async () => {
    if (
      !isNaN(this.state.routing_number) &&
      !isNaN(this.state.account_number)
    ) {
      this.setState((prevState) => ({ ...prevState, is_creating: true }))
      try {
        await UserService.createBlankCheck(UserProfileService.getUserId(), {
          routing_number: this.state.routing_number,
          account_number: this.state.account_number,
        })
        this._fetchUserVoidedCheck()
          .then((voided_check) =>
            this.setState(
              (prevState) => ({
                ...prevState,
                ...voided_check,
                is_creating: false,
              }),
              () =>
                AgentProfileStore.setValidity(
                  'voided_check',
                  !!voided_check?.voided_check_url
                )
            )
          )
          .catch(() =>
            this.setState(
              (prevState) => ({ ...prevState, is_creating: false }),
              () =>
                AgentProfileStore.setValidity(
                  'voided_check',
                  !!this.state.voided_check_url
                )
            )
          )
      } catch (ex) {
        console.error('Failed to upload: ', ex)
        this.setState(
          (prevState) => ({ ...prevState, is_creating: false }),
          () =>
            AgentProfileStore.setValidity(
              'voided_check',
              !!this.state.voided_check_url
            )
        )
      }
    }
  }

  _onNpnChange = (e) => {
    let npn = e.target.value
    this.setState({ npn }, () => AgentProfileStore.setValidity('npn', !!npn))
    UserProfileService.set('npn', npn)
    UserService.update(UserProfileService.getUserId(), { npn })
  }

  _onBankChange = (e) => {
    let change = { [e.target.name]: e.target.value }
    if (
      Object.values(change).shift() !== this.state[Object.keys(change).shift()]
    )
      this.setState((prevState) => ({ ...prevState, ...change }))
  }

  _numericKeypress = (event) => {
    if (event.which >= 48 && event.which <= 57) return true

    event.preventDefault()
    return false
  }

  _renderVoidedCheck = () => {
    if (!this.state.voided_check_url)
      return (
        <>
          <div className="no-check">Not Yet Uploaded</div>
        </>
      )

    return (
      <>
        {this.state.voided_check_id ? (
          <div
            onClick={() =>
              this.setState((prevState) => ({
                ...prevState,
                show_delete_modal: true,
              }))
            }
            className="delete-btn"
          >
            <MDBIcon icon="trash" className="remove" />
          </div>
        ) : (
          <></>
        )}
        <img
          src={this.state.voided_check_url}
          alt="Agent's Blank Voided Check"
        />
      </>
    )
  }

  _deleteVoidedCheck = async () => {
    if (this.state.voided_check_id) {
      this.setState((prevState) => ({ ...prevState, is_deleting: true }))
      try {
        UserMetaService.delete(this.state.voided_check_id)
        this.setState(
          (prevState) => ({
            ...prevState,
            is_deleting: false,
            voided_check_id: null,
            voided_check_url: null,
            show_delete_modal: false,
          }),
          () =>
            AgentProfileStore.setValidity(
              'voided_check',
              !!this.state.voided_check_url
            )
        )
      } catch (ex) {
        console.log({ ex })
        this.setState(
          (prevState) => ({
            ...prevState,
            is_deleting: false,
            show_delete_modal: false,
          }),
          () =>
            AgentProfileStore.setValidity(
              'voided_check',
              !!this.state.voided_check_url
            )
        )
      }
    }
  }

  _fetchUserVoidedCheck = async () => {
    let u = null,
      path = null,
      id = null

    try {
      u = (
        await UserProfileService.getUserMetas('profile---voided-check')
      ).shift()
      path = u && u?.meta_value ? u.meta_value : null
      id = u && u?.id ? u.id : null
    } catch (ex) {
      console.log('ex: ', ex)
      return null
    }

    return {
      voided_check_id: id || null,
      voided_check_url: path
        ? `https://firebasestorage.googleapis.com/v0/b/${
            env.integrations.firebase.config.storageBucket
          }/o/${encodeURIComponent(path)}?alt=media`
        : null,
    }
  }

  _fetchUserData = async () => {
    setGroupEandO()

    let stateUpd = { loading: false, ...(await this._fetchUserVoidedCheck()) }

    if (UserProfileService.get('npn'))
      stateUpd.npn = UserProfileService.get('npn')

    AgentProfileStore.setValidity('npn', !!stateUpd?.npn)
    AgentProfileStore.setValidity('voided_check', !!stateUpd?.voided_check_url)

    return new Promise((resolve, reject) => {
      this.setState(stateUpd, () => {
        resolve()
      })
    })
  }

  _renderNpnInput = () => {
    if (this.state.loading) return <></>

    return (
      <MDBRow className="mt-1 mb-5 npn-input-wrapper">
        <MDBCol size="12" md="6">
          <div className="npn-text">
            <IntakeStepIndicator
              fieldName="npn"
              fieldLabel="Enter NPN number"
            />
          </div>
        </MDBCol>
        <MDBCol size="12" md="6">
          <MDBInput
            label="NPN Number"
            type="text"
            name="npn"
            value={this.state.npn}
            onChange={this._onNpnChange}
            required
            validate
          />
        </MDBCol>
        <MDBCol size="12">
          <hr />
        </MDBCol>
        <MDBCol size="12" md="6">
          <div className="npn-text">
            Your National Producer Number.
            <br />
            Misplaced NPN number?{' '}
            <a
              href="https://nipr.com/help/look-up-your-npn"
              target="_BLANK"
              rel="noopener noreferrer"
            >
              Look it up here!
            </a>
          </div>
        </MDBCol>
        <MDBCol size="12" md="6"></MDBCol>
      </MDBRow>
    )
  }

  _renderVoidedCheckInput = () => {
    if (this.state.loading) return <></>

    return (
      <MDBRow className="mb-5 blank-check-input-wrapper">
        <MDBCol size="12" md="6">
          <div className="npn-text">
            <IntakeStepIndicator
              fieldName="voided_check"
              fieldLabel="Upload a voided blank check"
            />
          </div>
        </MDBCol>
        <MDBCol size="12" md="6">
          <MDBFileInput
            name="voided_check"
            accept="image/*"
            getValue={(value) => this._onSelectFile(value[0], 'voided_check')}
          />
        </MDBCol>
        <MDBCol size="12">
          <hr />
        </MDBCol>
        <MDBCol size="12" md="6">
          <div className="npn-text">
            Your blank check is used for automated commission payments.
          </div>
        </MDBCol>
        <MDBCol size="12" md="6"></MDBCol>
        <MDBCol size="12" md="6" className="mb-3">
          <strong>Example: </strong>
          <div className="voided-check-img-wrapper">
            <img src={exampleVoidedCheck} alt="Example voided check" />
          </div>
        </MDBCol>
        <MDBCol size="12" md="6">
          <strong>Your Voided Check: </strong>
          <div className="voided-check-img-wrapper">
            {this._renderVoidedCheck()}
          </div>
          {this.state.voided_check_url ? (
            <></>
          ) : (
            <>
              <strong>Don't have checks? Create a voided check: </strong>
              <div className="create-check-wrapper">
                <MDBRow>
                  <MDBCol size="12" md="9">
                    <MDBInput
                      label="Routing Number"
                      type="text"
                      name="routing_number"
                      value={this.state.routing_number}
                      onChange={this._onBankChange}
                      onKeyPress={this._numericKeypress}
                      required
                      validate
                    />
                    <MDBInput
                      label="Account Number"
                      type="text"
                      name="account_number"
                      value={this.state.account_number}
                      onChange={this._onBankChange}
                      onKeyPress={this._numericKeypress}
                      required
                      validate
                    />
                  </MDBCol>
                  <MDBCol size="12" mdmd="3">
                    <MDBBtn
                      disabled={
                        this.state.is_creating ||
                        !this.state.routing_number ||
                        !this.state.account_number
                      }
                      onClick={this._onCreateBlankCheck}
                      block
                    >
                      {this.state.is_creating
                        ? 'Creating ... '
                        : 'Create Voided Check'}
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </div>
            </>
          )}
        </MDBCol>
        <hr />
      </MDBRow>
    )
  }

  _renderHeadshotInput = () => {
    if (this.state.loading) return <></>

    return (
      <MDBRow className="mb-5 headshot-input-wrapper">
        <MDBCol size="12">
          <div className="npn-text">
            <IntakeStepIndicator
              fieldName="headshot"
              fieldLabel="Upload Your Professional Headshot"
            />
          </div>
        </MDBCol>
        <MDBCol size="12">
          <hr />
        </MDBCol>
        <MDBCol size="12">
          <div className="npn-text">
            Your headshot can set you apart from your competition. Look
            professional!
          </div>
          <div className="npn-text">
            Want to remove the background from your photo?{' '}
            <a
              href="https://www.remove.bg/"
              target="_BLANK"
              rel="noopener noreferrer"
            >
              Click Here!
            </a>
          </div>
        </MDBCol>
        <MDBCol size="12">
          <AgentHeadshotManager />
        </MDBCol>
      </MDBRow>
    )
  }

  _renderEandOInput = () => {
    if (this.state.loading) return <></>

    if (
      UserProfileService.isA([
        'affiliate-agent',
        'affiliate-manager',
        'associate-agent',
        'associate-manager',
      ])
    )
      return (
        <MDBRow className="mb-5 blank-check-input-wrapper">
          <MDBCol size="12">
            <div className="npn-text">
              <IntakeStepIndicator
                fieldName="eopolicy"
                fieldLabel="Upload Your E&O Policy"
              />
            </div>
          </MDBCol>
          <MDBCol size="12">
            <hr />
          </MDBCol>
          <MDBCol size="12">
            <div className="npn-text">
              An active, valid E&O Policy is a requirement for all licensed
              insurance agents.
            </div>
          </MDBCol>
          <MDBCol size="12">
            <AgentEandOManager />
          </MDBCol>
        </MDBRow>
      )

    return <></>
  }

  _renderStateLicensesInput = () => {
    if (this.state.loading) return <></>

    return (
      <MDBRow className="mb-5 state-licenses-input-wrapper">
        <MDBCol size="12">
          <AgentStateLicenses />
        </MDBCol>
      </MDBRow>
    )
  }

  _subscribeToValidityEvents = () => {
    const validityCalback =
      typeof this.props.onValidityChange === 'function'
        ? (isValid) => {
            this.props.onValidityChange(isValid)
            return isValid
          }
        : (isValid) => {
            return isValid
          }

    this.__subscriptions$.add(
      AgentProfileStore.subscribe((isValid) => validityCalback(isValid))
    )
  }

  componentDidMount() {
    this._fetchUserData()
    this._subscribeToValidityEvents()
  }

  componentWillUnmount() {
    this.__subscriptions$.unsubscribe()
  }

  render() {
    return (
      <MDBContainer id="AgentProfileIntake" fluid>
        <MDBRow className="onboarding-stage-label">
          <MDBCol size="12" sm="10" className="offset-sm-1">
            <h2>
              <span className="intro-step">1</span>Complete Your Agent Profile
            </h2>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12">
            <div className="content-wrapper">
              {this._renderNpnInput()}
              {this._renderVoidedCheckInput()}
              {this._renderHeadshotInput()}
              {this._renderEandOInput()}
              {this._renderStateLicensesInput()}
            </div>
          </MDBCol>
        </MDBRow>
        <MDBModal
          isOpen={this.state.show_delete_modal}
          toggle={() =>
            this.setState((prevState) => ({
              ...prevState,
              show_delete_modal: false,
            }))
          }
          className="confirm-delete-modal"
        >
          <MDBModalHeader
            toggle={() =>
              this.setState((prevState) => ({
                ...prevState,
                show_delete_modal: false,
              }))
            }
          >
            Confirm Delete?
          </MDBModalHeader>
          <MDBModalBody>
            <h5 className="text-center">
              <strong>
                Are you sure you want to delete your voided check image?
              </strong>
            </h5>
            <br />
            <MDBBtn
              block
              disabled={this.state.is_deleting}
              onClick={() => this._deleteVoidedCheck()}
            >
              {this.state.is_deleting ? 'Deleting ... ' : 'Delete Voided Check'}
            </MDBBtn>
            <MDBBtn
              block
              disabled={this.state.is_deleting}
              onClick={() =>
                this.setState((prevState) => ({
                  ...prevState,
                  show_delete_modal: false,
                }))
              }
            >
              Cancel
            </MDBBtn>
          </MDBModalBody>
        </MDBModal>
      </MDBContainer>
    )
  }
}

export default AgentProfileIntake
