import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  MDBBtnGroup,
  MDBCollapse,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBCardImage,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
  MDBRow,
  MDBCol,
} from 'mdbreact'
import { UIInput } from './../../../../components/forms/form-fields'
import Store from './../../stores/Knowledgebase.store'

import './KbCategoryForm.component.scss'

const KbCategoryForm = ({}) => {
  const [showValidity, setShowValidity] = useState(false)

  const _onKeyDown = (event) => {
    try {
      if (event.key === 'Enter') _save()
    } catch (ex) {}
  }

  const _cancel = async () => {
    if (Store.Category.isNew() === false) {
      if (Object.keys(Store.Category.changed).length > 0)
        Store.Category.reload()
    }

    Store.Category = null
  }

  const _save = async () => {
    if (Store.Category?.get('c_name')) {
      Store.isSaving = true
      const isNew = Store.Category.isNew()
      await Store.Category.save()
      console.log('saved ', Store.Category.id())
      if (isNew) {
        Store.Categories[Store.Category.id()] = Store.Category
      }
      Store.isSaving = false
      Store.Category = null
    }
  }

  return (
    <form id="KbCategoryFormComponent" noValidate>
      <UIInput
        type="text"
        label="Knowledgebase Category Name"
        className="placeholder-color-white"
        value={Store.Category?.get('c_name')}
        onKeyDown={_onKeyDown}
        onChange={(e) => Store.Category.set('c_name', e.target.value)}
        style={{ color: '#fff' }}
      />
      {Store.Category?.isNew() === true ? (
        <p>
          This is a new Knowledgebase Category with 0 Knowledgebase Articles.
          Enter the new Knowledgebase Category name above & click SAVE.
        </p>
      ) : (
        <p>
          This Knowledgebase Category currently has{' '}
          {Store.categoryDetail?.articles} Knowledgebase Articles.
        </p>
      )}
      <MDBBtnGroup>
        <MDBBtn
          className="btn-secondary"
          disabled={Store.isFetching || Store.isSaving || Store.isLoading}
          onClick={() => _cancel()}
        >
          Cancel
        </MDBBtn>
        <MDBBtn
          className="btn-success"
          color="success"
          disabled={
            !Store.Category?.get('c_name') ||
            Store.isFetching ||
            Store.isSaving ||
            Store.isLoading
          }
          onClick={() => _save()}
        >
          Save
        </MDBBtn>
      </MDBBtnGroup>
    </form>
  )
}

export default observer(KbCategoryForm)
