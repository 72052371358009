import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { MDBContainer, MDBDataTable } from 'mdbreact'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'
import moment from 'moment'

class AvApplications extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      formError: false,
      errorMessage: '',
      confirmModal: false,
      salesList: [],
      data: {
        columns: [
          {
            label: 'Agent',
            field: 'agent',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Date',
            field: 'date',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Client',
            field: 'client',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Carrier',
            field: 'carrier',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Policy # or D.O.B.',
            field: 'policy',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Premium',
            field: 'premium',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Term Length',
            field: 'term',
            sort: 'asc',
            width: 270,
          },
          /*{
                label: 'AV',
                field: 'av',
                sort: 'asc',
                width: 270
              },*/
          {
            label: 'Points',
            field: 'points',
            sort: 'asc',
            width: 270,
          },
          {
            label: 'Comments',
            field: 'comments',
            sort: 'asc',
            width: 270,
          },
        ],
        rows: [],
      },
    } // state
  }

  componentDidMount() {
    this.fetchAllAVSales()
  }

  fetchAllAVSales = async () => {
    this.setState({ loading: true })
    AdminApi.getAllAvSales()
      .then((result) => {
        if (result && result.data && result.data.data) {
          this.setState({ salesList: result.data.data })
        }
      })
      .then(() => {
        this.setRows()
        this.setState({ loading: false })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  //forming rows for data table from fetched data
  setRows = () => {
    const { salesList } = this.state
    if (salesList && salesList.length) {
      let rows = salesList.map((row) => {
        const {
          av_user,
          av_date,
          av_appid,
          av_carrier,
          av_client,
          av_comments,
          av_premium,
          av_points,
          av_term_length,
        } = row

        const date_av = (
          <span
            searchvalue={av_date ? moment(av_date).format('YYYY/MM/DD') : ''}
          >
            {av_date ? moment(av_date).format('MM/DD/YYYY') : 'N/A'}
          </span>
        )

        return {
          agent: av_user,
          date: date_av,
          client: av_client,
          carrier: av_carrier,
          policy: av_appid,
          premium: av_premium,
          term: av_term_length,
          /*av: '3,856.44',*/
          points: av_points,
          comments: av_comments,
        }
      })
      this.setState({
        data: { ...this.state.data, rows: rows },
      })
    }
  }

  render() {
    toast.configure()
    const { loading, data } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <h2>Admin Report: AV Applications</h2>
              <hr />
              {loading ? (
                <h2>Loading...</h2>
              ) : (
                <>
                  <div className="content-wrapper">
                    <MDBDataTable
                      sortRows={['date']}
                      striped
                      bordered
                      small
                      order={['agent', 'asc']}
                      data={data}
                      exportToCSV
                    />
                  </div>
                </>
              )}
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AvApplications
