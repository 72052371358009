import { ReferralProgramPage } from './index'

import { RouteFactory } from './../../shared/router/Route.factory'
import { RequiresAuthGuard } from './../../shared/router/guards'

export default [
  RouteFactory.create(ReferralProgramPage, '/referral-program', [
    new RequiresAuthGuard(true),
  ], {
    name: 'Referral Program'
  }),
]
