import React from 'react'
import env from './../../../environments/environment'
import { observer } from 'mobx-react-lite'

import './DivisionLogo.scss'

const DivisionLogo = ({ divisionName, divisionLogo, divisionId, className }) => {

	className = className ? `${Array.isArray(className) ? className.join(' ') : className}` : '';

	if (divisionLogo)
	  return (
	  	<img src={`https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/divisions%2F${divisionLogo}?alt=media`} alt={divisionName||divisionId||'Division Logo'} className={`mx-auto d-block division-logo-img ${className}`} />
	  )

	return <></>
}

export default observer(DivisionLogo)
