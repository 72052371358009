import RestApi from './../../api/rest-api-client'
const srvcEndpoint = '/ledger_transaction_rules'
export default {
  search: async (req, opts) => RestApi.request('GET', srvcEndpoint, req, opts),
  get: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, id, req, opts),
  store: async (data, opts) =>
    RestApi.request('POST', srvcEndpoint, data, opts),
  update: async (id, data, opts) =>
    RestApi.request('PUT', srvcEndpoint, id, data, opts),
  delete: async (id, opts) => RestApi.request('DELETE', srvcEndpoint, id, opts),

  getAutoAwards: async (ledgerId) =>
    RestApi.request(
      'GET',
      srvcEndpoint,
      {},
      { endpoint: `/getAutoAwards${ledgerId ? '/' + ledgerId : ''}` }
    ),
  enableAutoAwards: async (ledgerId) =>
    RestApi.request(
      'POST',
      srvcEndpoint,
      {},
      { endpoint: `/enableAutoAwards${ledgerId ? '/' + ledgerId : ''}` }
    ),
  removeAutoAwards: async (ledgerId) =>
    RestApi.request(
      'DELETE',
      srvcEndpoint,
      {},
      { endpoint: `/removeAutoAwards${ledgerId ? '/' + ledgerId : ''}` }
    ),
  getWalletLocked: async (ledgerId) =>
    RestApi.request(
      'GET',
      srvcEndpoint,
      {},
      { endpoint: `/getWalletLocked${ledgerId ? '/' + ledgerId : ''}` }
    ),
  lockWallet: async (ledgerId) =>
    RestApi.request(
      'POST',
      srvcEndpoint,
      {},
      { endpoint: `/lockWallet${ledgerId ? '/' + ledgerId : ''}` }
    ),
  unlockWallet: async (ledgerId) =>
    RestApi.request(
      'DELETE',
      srvcEndpoint,
      {},
      { endpoint: `/unlockWallet${ledgerId ? '/' + ledgerId : ''}` }
    ),
}
