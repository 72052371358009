import BaseModelFactory from './base.factory'
import UserMetaService from './../services/UserMeta.service'
import UserMeta from './../models/user-meta.model'

class UserMetaFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: UserMetaService, Model: UserMeta }
    )
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: UserMetaService, Model: UserMeta }
    )
  static findAllByUserId = async (id) =>
    await this._findAllBy(
      { user_id: id },
      { Service: UserMetaService, Model: UserMeta }
    )
  static create = (payload) =>
    this._create(payload, { Service: UserMetaService, Model: UserMeta })
}

export default UserMetaFactory
