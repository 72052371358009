import React, { useState } from 'react'
import AdminApi from './../../../../api/admin-api/admin-api'
import { MDBContainer, MDBRow, MDBCol, MDBAlert, MDBCollapse } from 'mdbreact'
import { getPhone, getEmail } from './../../formatters'
import {
  phoneToStorage,
  phoneToView,
} from './../../../../shared/formatters/telephone.formatter'
import ReCAPTCHA from 'react-google-recaptcha'

import './ContactForm.component.scss'

const ContactForm = ({ subsiteData }) => {
  const recaptchaRef = React.createRef()

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    message: '',
    captcha: null,
  })
  const [formError, setFormError] = useState(null)
  const [isSending, setIsSending] = useState(false)
  const [hasSent, setHasSent] = useState(false)

  const onChange = (event) => {
    let name = event.target.name,
      value = event.target.value
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const onCaptchaChange = () =>
    setFormData((prevData) => ({ ...prevData, captcha: !formData.captcha }))

  const validate = async () => {
    const { firstname, lastname, email, phone, message, captcha } = formData
    const regPhone = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
    const regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

    if (!firstname || !firstname.trim()) {
      setFormError('First name required')
      return false
    }
    if (!lastname || !lastname.trim()) {
      setFormError('Last name required')
      return false
    }
    if (!email || !email.trim()) {
      setFormError('E-mail address required')
      return false
    }

    if (!regEmail.test(email)) {
      setFormError('Invalid E-mail format')
      return false
    }

    if (!phone || !phone.trim()) {
      setFormError('Phone number required')
      return false
    }

    if (!regPhone.test(phone)) {
      setFormError('Invalid Phone Number')
      return false
    }

    if (!message || !message.trim()) {
      setFormError('Message required')
      return false
    }

    if (!captcha) {
      setFormError('Captcha required')
      return false
    }

    if (formError) setFormError(null)

    return true
  }

  const createContactRequest = async (event) => {
    event.preventDefault()
    const passedValidation = await validate()
    if (!passedValidation || isSending) return false

    const { firstname, lastname, email, phone, message } = formData,
      data = {
        co_fname: firstname,
        co_lname: lastname,
        co_email: email,
        co_phone: phone,
        co_message: message,
        co_summary: `<html><body><table>
                            <tr><td>First Name:</td><td>${firstname}</td></tr>
                            <tr><td>Last name:</td><td>${lastname}</td></tr>
                            <tr><td>e-mail:</td><td>${email}</td></tr>
                            <tr><td>Phone:</td><td>${phoneToView(
                              phone
                            )}</td></tr>
                            <tr><td>Message:</td><td>${message}</td></tr>
                          </table></body></html>`,
        site_id: subsiteData.site_id,
        user_id: subsiteData.user_id,
        req_url: window.location.href,
        ref_url: document.referrer,
      }

    try {
      await AdminApi.createAgentsContactQuoteRequest(data, 'contact').then(
        (response) => {
          setIsSending(true)
          if (response.status === 200) {
            setHasSent(true)
            setFormData({
              firstname: '',
              lastname: '',
              email: '',
              phone: '',
              message: '',
              captcha: null,
            })
            setFormError(null)
            window.grecaptcha.reset()
          }
          setIsSending(false)
        }
      )
    } catch (error) {
      setIsSending(false)
    }
  }

  return (
    <MDBContainer id="ContactFormComponent" fluid>
      <MDBRow>
        <MDBCol size="12" md="7">
          <h3 className="text-spacing--25 font--lato">Get in Touch</h3>
          <form
            data-form-output="form-output-global"
            data-form-type="contact"
            onSubmit={createContactRequest}
            noValidate
          >
            <MDBCollapse isOpen={hasSent}>
              <MDBAlert color="success">
                <strong>Contact Request Sent!</strong>
              </MDBAlert>
            </MDBCollapse>
            <div className="form-input-cell">
              <div className="form-group">
                <input
                  id="contact-name"
                  type="text"
                  name="firstname"
                  value={formData.firstname}
                  onChange={onChange}
                  data-constraints="@Required"
                  className="form-control"
                  placeholder="First Name"
                />
              </div>
            </div>

            <div className="form-input-cell">
              <div className="form-group">
                <input
                  id="contact-name"
                  type="text"
                  name="lastname"
                  value={formData.lastname}
                  onChange={onChange}
                  data-constraints="@Required"
                  className="form-control"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="form-input-cell">
              <div className="form-group">
                <input
                  id="contact-email"
                  type="email"
                  name="email"
                  autoComplete="off"
                  value={formData.email}
                  onChange={onChange}
                  data-constraints="@Email @Required"
                  className="form-control"
                  placeholder="Email"
                />
              </div>
            </div>

            <div className="form-input-cell">
              <div className="form-group">
                <input
                  id="contact-phone"
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={onChange}
                  data-constraints="@Required"
                  className="form-control"
                  placeholder="Phone"
                />
              </div>
            </div>

            <div className="form-textarea-cell">
              <div className="form-group">
                <div className="textarea-lined-wrap">
                  <textarea
                    id="contact-message"
                    data-constraints="@Required"
                    name="message"
                    value={formData.message}
                    onChange={onChange}
                    className="form-control"
                    placeholder="message"
                  />
                </div>
              </div>
            </div>

            <div
              className="mt-4"
              style={{ flex: '0 0 100%', marginLeft: '8px' }}
            >
              <div className="form-group">
                <div className="textarea-lined-wrap">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6Lc_aeIZAAAAACSfGY2OqRXdIzJ1jPOIW__6gq11"
                    onChange={(event) => onCaptchaChange()}
                  />
                </div>
              </div>
            </div>

            <div
              style={{ flex: '0 0 100%', maxWidth: '152px', marginLeft: '4px' }}
            >
              <button type="submit" className="btn btn-primary btn-block">
                {isSending ? 'Submitting...' : 'Send'}
              </button>
            </div>

            <div className="cell-xs-12 text--left text--red fw--500">
              {formError}
            </div>

            <div className="cell-xs-12 mt-3 text-spacing-0 font-default">
              By submitting my contact information, I verify that the contact
              information entered is correct and is my personal information and
              that I am over 18 years of age.
            </div>
          </form>
        </MDBCol>
        <MDBCol size="12" md="5">
          <div className="cell-sm-10 cell-md-12">
            <h3 className="text-spacing--25 font--lato">How to Find Us</h3>
            <p className="offset-md-top-40 text-gray-base">
              If you have any questions, just fill in the contact form, and we
              will answer you shortly.
            </p>
          </div>
          <div className="cell-sm-6 cell-md-12 offset-top-30 offset-sm-top-45">
            <h5 className="text-spacing--25 font--lato fw--500 mt-5">
              Reach Us:
            </h5>
            <address className="contact-info">
              <p className="text-uppercase text-gray-base">
                {subsiteData.u_address1 && subsiteData.s_fulladdress
                  ? `${subsiteData.u_address1}, `
                  : ''}{' '}
                {subsiteData.u_address2 && subsiteData.s_fulladdress
                  ? `${subsiteData.u_address2}, `
                  : ''}{' '}
                {subsiteData.u_city ? `${subsiteData.u_city}, ` : ''}{' '}
                {subsiteData.u_state ? `${subsiteData.u_state}, ` : ''}{' '}
                {subsiteData.u_zip ? `${subsiteData.u_zip}` : ''}
              </p>
              <dl className="list-terms-inline">
                <dt>Telephone</dt>
                <dd>
                  {getPhone(subsiteData) ? (
                    <a
                      href={`callto:${getPhone(subsiteData, phoneToStorage)}`}
                      className="link-primary"
                    >
                      {getPhone(subsiteData, phoneToView)}
                    </a>
                  ) : (
                    <></>
                  )}
                </dd>
              </dl>
              <dl className="list-terms-inline">
                <dt>E-mail</dt>
                <dd>
                  {getEmail(subsiteData) ? (
                    <a
                      href={`mailto:${getEmail(subsiteData)}`}
                      className="link-primary"
                    >
                      {getEmail(subsiteData)}
                    </a>
                  ) : (
                    <></>
                  )}
                </dd>
              </dl>
            </address>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default ContactForm
