export default {
  env: 'production',
  version: "1.4.39",
  api: {
    base_url:
      'https://us-central1-totemic-bonus-239316.cloudfunctions.net/api/',
    automation_url: 'https://automation.usabg.com'
  },
  integrations: {
    stripe: {
      key: 'pk_live_51J0X48CB1dfLO0v77FinczCym6LeZTr9kSeF8R2xZOMbmorQFE0U4STO8pw3ppQ8JlMVp4EGuQEu86gMbQmoAF8N00bnZedRlK',
      secret:
        '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',
    },
    track_js: {
      token: '6d9aba7876784482b2ddd727803a0b1f',
      application: 'ww0geqrvjird0rt4hjs3gkdh9',
      console: { display: false },
    },
    firebase: {
      config: {
        apiKey: 'AIzaSyAz_70O4sDQ9Vc7cmVDdJ-WikgC7R1Uvaw',
        authDomain: 'totemic-bonus-239316.firebaseapp.com',
        projectId: 'totemic-bonus-239316',
        storageBucket: 'totemic-bonus-239316.appspot.com',
        messagingSenderId: '1013363213877',
      },
    },
    adobe_sign: {
      client_id: 'CBJCHBCAABAAT5hSWPjrVaMSDlB78FLxzsM24XEvT0s5',
    },
    google_tag_manager: {
      tag: 'GTM-W3M2CQ7K',
    },
    google_analytics: {
      tag: 'G-STKT5NDS2X'
    }
  },
}
