
const formatTelephone_toStorage = phone =>
{
	let p 									= 		phone && `${phone}`.trim().length && `${phone}`.replace(/[^\d]/g, '');
	if (!p) 											return phone;

	if (p.charAt(0) !== "1" && p.length === 10)
		p 										=			`1${p}`;

	return 												p;
}

const formatTelephone_fromStorage = phone =>
{
	let p 									= 		phone && `${phone}`.trim().length && `${phone}`.replace(/[^\d]/g, '');
	if (!p) 											return phone;

	if (`${p}`.charAt(0) === "1")
		return 										[1, `(${p.substr(1,3)})`, [`${p.substr(4,3)}`, `${p.substr(7,4)}`].join('-')].join(' ');
	return 											[1, `(${p.substr(0,3)})`, [`${p.substr(3,3)}`, `${p.substr(6,4)}`].join('-')].join(' ');
}

exports.phoneToStorage 		= 	formatTelephone_toStorage;
exports.phoneToView 			= 	formatTelephone_fromStorage;