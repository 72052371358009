import React from 'react'
import {observer} from 'mobx-react-lite'
import UserEvent from './UserEvent.component';
import Store from './UserEvent.store';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import PaginationControls from './../../../../components/content/PaginationControls/PaginationControls.component';

import "./UserEvents.component.scss";

const UserEvents = (props) => 
{
  return (
    <>
      <ul id="UserEventsComponent" className={Store.isFetching?'is-loading':''}>
        <li>
          <MDBContainer>
            <MDBRow className='ulog-list-header'>
              <MDBCol className='ulog-event-type'>
                Event Type:
              </MDBCol>
              <MDBCol className='ulog-event-descrip'>
                Event Description/Notes: 
              </MDBCol>
              <MDBCol className='ulog-related-model'>
                Related Records:
              </MDBCol>
              <MDBCol className='ulog-payload'>
                Data:
              </MDBCol>
              <MDBCol className='ulog-timestamp'>
                Created At:
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </li>
        {
          Store.Events.map((Event,idx) => {
            return (<li key={`history-event-${Event.id()}-${idx}`}><UserEvent Event={Event}></UserEvent></li>)
          })
        }
      </ul>
      <PaginationControls pagination={Store.pagination} onPageSelect={pagination => { 
        Store.pagination = pagination;
        Store.fetch();
      }} />
    </>
  )
}

export default observer(UserEvents)