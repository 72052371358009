import React from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout.jsx'
import { MDBBtn, MDBRow, MDBCol, MDBContainer, MDBIcon } from 'mdbreact'
import CarrierMgmtStore from './CarrierMgmt.store'
import CarrierTable from './components/CarrierTable/CarrierTable.component'
import CarrierTableFilter from './components/CarrierTableFilter/CarrierTableFilter.component'
import FMOEditorForm from './components/FMOEditorForm/FMOEditorForm.component'

import './CarrierManagement.scss'

class CarrierManagementPage extends React.Component {
  state = { filters: { priority: null, spec: null } }

  componentDidMount() {
    CarrierMgmtStore.fetchCarriers()
    CarrierMgmtStore.fetchFMOs()
  }

  render() {
    return (
      <React.Fragment>
        <DashboardLayout>
          <main id="CarrierManagementPage" className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <MDBRow>
                <MDBCol size="12">
                  <h2>
                    Carrier Management
                    <div className="d-inline-block">
                      <MDBBtn
                        tag="a"
                        floating
                        gradient="blue"
                        title={'Add New Carrier'}
                        onClick={() =>
                          this.props.history.push('/admin-carriers/new')
                        }
                      >
                        <MDBIcon icon="plus" />
                      </MDBBtn>
                    </div>
                  </h2>
                </MDBCol>
              </MDBRow>
              <hr />
              <MDBRow>
                <MDBCol size="12">
                  <div className="content-wrapper">
                    <FMOEditorForm />
                    <CarrierTableFilter
                      filters={this.state.filters}
                      onChange={(filters) =>
                        this.setState((prevState) => ({
                          ...prevState,
                          filters: { ...prevState.filters, ...filters },
                        }))
                      }
                    />
                    <CarrierTable
                      filters={this.state.filters}
                      goTo={(carrierId) =>
                        this.props.history.push(`/admin-carriers/${carrierId}`)
                      }
                    />
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export default CarrierManagementPage
