import React,{useEffect, useState} from 'react'
import { MDBInput } from 'mdbreact'
import { checkValidity, getErrorMessage, getSuccessMessage, isDirty } from './../shared/validator.function'

const isEmpty = (value) =>
	typeof value === 'undefined' || value === null || value === ''

const UIInputCheckbox = ({
  className,
  disabled,
  id,
  name,
  label,
  size,
  getValue,
  onFocus,
  onChange,
  onValidityChange,
  required,
  hint,
  value,
  onKeyDown,
  rules,
  showValidity,
  checked
}) => {

	rules = rules && typeof rules === 'object' ? rules : {};
	if (required)
		rules.required = true;


  const [uiState, setUiState] = useState({
  	isDirty: 			false,
  	isPristine: 	true,
  	isTouched: 		false,
  	validity: 		{isValid: false, errors: []}
  });


  const _onFocus = onFocus;
  onFocus = (evt) => 
  {
  	if (!uiState.isTouched)
  		setUiState(prevState => ({...prevState, isTouched: true}));
  	(typeof _onFocus === 'function' ? _onFocus : (evt) => {})(evt);
  }


  const _onChange = onChange;
  onChange = async (evt) => 
  {
  	evt = {target: {name: evt?.target?.name, value: evt?.target?.value}};

		setUiState(prevState => ((!uiState.isDirty && isDirty(value, evt.target.value)) ? {...prevState, isDirty: true, isPristine: false} : {...prevState, isDirty: false}));
  	(typeof _onChange === 'function' ? _onChange : (evt) => {})(evt);

  	checkValidity(evt.target.value, rules).then(validity => {
  		setUiState(prevState => ({...setUiState, validity}));
  		(typeof onValidityChange === 'function' ? onValidityChange : (validity) => {})(validity);
  	})
  }

  onKeyDown 	= 	typeof onChange === 'function' ? onKeyDown : () => {}
  required 		= 	(required && parseInt(required) === 1) || !!required
  checked 		=		checked && (checked === true || parseInt(checked) === 1);

  const inputProps = {
    className,
    disabled,
    id,
    name,
    label,
    size,
    getValue,
    onFocus,
    onChange,
    required,
    hint,
    value,
    onKeyDown,
    showValidity,
    checked
  }
  Object.keys(inputProps)
    .filter((key) => typeof inputProps[key] === 'undefined')
    .forEach((key) => delete inputProps[key])

  if (inputProps?.label && inputProps.required) {
    inputProps.label = (
      <>
        {inputProps.label}
        {<small>&nbsp;*</small>}
      </>
    )
  }

  return (
    <div className={['ui-input','ui-input-checkbox',
				(uiState.validity.isValid?'is-valid':'is-invalid'),
				(uiState.isDirty ? 'is-dirty' : ''),
				(uiState.isPristine ? 'is-pristine' : ''),
				(uiState.isTouched ? 'is-touched' : ''),
				(disabled ? 'is-disabled' : ''),
				(showValidity ? 'show-validity' : ''),
    	].join(' ')}>
      <MDBInput {...inputProps} type='checkbox' />
      <div className='ui-input-validation'>
      	<span className='success-msg'>{showValidity?getSuccessMessage():''}</span>
      	<span className='error-msg'>{showValidity?getErrorMessage(uiState.validity.errors):''}</span>
      </div>
    </div>
  )
}

export default UIInputCheckbox