import BaseModelInstance from './base.model'
import { Ledger as ModelDefinition } from './model-definitions'
import LedgerTransactionFactory from './../factories/ledger-transaction.factory'
import LedgerService from './../services/Ledger.service'
import moment from 'moment'

function buildLeadCreditPackagePurchase(Ledger, props) {
  const { Package, QtyPrice } = props
  return {
    ledger_id: Ledger.id(),
    purpose: `USABG Backoffice Purchase: ${Package.get(
      'name'
    )} (qty: ${QtyPrice.get('quantity')})`,
    category: 'EXPENSE',
    type: 'LeadCreditTransfer',
    trans_state: 'PENDING',
    debit: QtyPrice.get('price'),
    credit: 0,
    transact_at: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
    price_id: QtyPrice.id(),
  }
}

class Ledger extends BaseModelInstance {
  afterSet = (key, value) => {}

  #children = {
    transaction: [],
  }

  getMetrics = async () => {
    let response = {}

    try {
      response = { ...(await LedgerService.metrics(this.id())) }
    } catch (ex) {
      console.error(`${ex}`)
    }

    return response
  }

  getUpcomingExpirations = async () => {
    let response = []

    try {
      response = { ...(await LedgerService.upcomingExpirations(this.id())) }
    } catch (ex) {
      console.error(`${ex}`)
    }

    return response
  }

  purchase = async (props) => {
    const { Package, QtyPrice } = props || {}
    let payload = { ledger_id: this.id() }

    if (Package && QtyPrice && QtyPrice.get('package_id') === Package.id()) {
      try {
        return {
          ...payload,
          ...(await this.transaction(true).create(
            buildLeadCreditPackagePurchase(this, props)
          )),
        }
      } catch (ex) {
        console.error(`Failed to purchase BUCK$. ${ex}`)
        return Promise.reject(`${ex}`)
      }
    }

    return false
  }

  updateBalance = async () => {
    try {
      return await LedgerService.updateBalance(this.id())
    } catch (ex) {
      return Promise.reject(`${ex}`)
    }
  }

  transaction = (useApi) => {
    if (useApi === true) {
      return {
        all: async () =>
          (await LedgerTransactionFactory.search({
            search: { ledger_id: this.id() },
            pagination: false,
          })) || [],
        search: async ({ search, pagination }) =>
          (await LedgerTransactionFactory.search({
            search: search && typeof search === 'object' ? search : undefined,
            pagination:
              pagination && typeof pagination === 'object'
                ? pagination
                : typeof pagination === 'boolean'
                ? pagination
                : undefined,
          })) || [],
        create: async (data) =>
          await LedgerTransactionFactory.create({
            ...(data ? data : {}),
            ledger_id: this.id(),
          }),
      }
    }

    return Array.isArray(this.#children?.transaction)
      ? this.#children.transaction
      : []
  }

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }
}

export default Ledger
