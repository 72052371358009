import React from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import AgentStateLicenses from './../../components/agents/AgentStateLicenses/AgentStateLicenses.component'
import { MDBContainer, MDBIcon } from 'mdbreact'

class StateLicensesPage extends React.Component {
  state = {
    from: '',
  }

  componentDidMount() {
    if (this.props?.location?.state?.from)
      this.setState({ from: this.props.location.state.from })
  }

  render() {
    return (
      <React.Fragment>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              <h2>Agent State Licenses</h2>
              <hr />
              {this.state.from ? (
                <a
                  style={{ marginBottom: '16px' }}
                  href="/"
                  onClick={(event) => {
                    event.preventDefault()
                    this.props.history.push(this.state.from)
                  }}
                >
                  <MDBIcon icon="arrow-left" />
                  &nbsp;Go back to{' '}
                  {(this.state.from ? this.state.from.substr(1) : '')
                    .replace(/^([a-z])|[\s_-]+([a-z])/g, ($1) =>
                      $1.toUpperCase()
                    )
                    .replace(/-/g, ' ')}
                </a>
              ) : (
                <></>
              )}
              <AgentStateLicenses hideHeader={true} />
            </MDBContainer>
          </main>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export default StateLicensesPage
