import React from 'react'
import {
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
} from 'mdbreact'
import { useState } from 'react'

export const DowngradeModal = (props) => {
  const [state, setState] = useState()
  const onChange = ({ target }) => {
    setState((prev) => {
      return {
        ...(prev || {}),
        [target.name]: target.value,
      }
    })
  }
  return (
    <MDBModal
      className="downgrade-modal"
      size="lg"
      isOpen={props.showDowngradeModal}
      toggle={props.toggle}
    >
      <MDBModalHeader toggle={props.toggle}>
        {props.pageName}
        <br />
        Are You Sure?
      </MDBModalHeader>
      <MDBModalBody>
        <div
          className=""
          dangerouslySetInnerHTML={{
            __html: props.content,
          }}
        />
        <MDBRow>
          <MDBCol size="12" md="6">
            <MDBInput
              label="Why are you downgrading today?"
              type="textarea"
              name="why_downgrade"
              value={state?.why_downgrade || ''}
              onChange={onChange}
              validate
            />
          </MDBCol>
          <MDBCol size="12" md="6">
            <MDBInput
              label="What could USABG have done better?"
              type="textarea"
              name="what_better"
              value={state?.what_better || ''}
              onChange={onChange}
              validate
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12" md="6">
            <MDBBtn
              className="btn-block"
              color="danger"
              disabled={!(state && state.why_downgrade && state.what_better)}
              onClick={() => props.downgrade(state)}
            >
              Yes, I want to give up my benefits.
              <br />
              Downgrade my account.
            </MDBBtn>
          </MDBCol>
          <MDBCol size="12" md="6">
            <MDBBtn
              className="btn-block"
              color="secondary"
              onClick={props.toggle}
            >
              I'd like to keep my benefits.
              <br />
              Let's keep my subscription.
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBModalBody>
    </MDBModal>
  )
}
