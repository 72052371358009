import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import moment from 'moment'
import { MDBContainer, MDBBtn, MDBRow, MDBCol } from 'mdbreact'
// import ReportsStore from './../../store/ReportsPage.store';
// import UserProfileService from './../../../../shared/services/UserProfile.service';

import './ReportDatesSelector.scss'

const DEFAULT_TIMESPAN = 'weekly'
const today = moment()
const getDefaultStart = () => {
  switch (DEFAULT_TIMESPAN) {
    case 'weekly':
      return moment(today).startOf('isoweek').format('YYYY-MM-DD')
    case 'monthly':
      return moment(today).startOf('month').format('YYYY-MM-DD')
    default:
      break
  }
}

const getDefaultStop = () => {
  switch (DEFAULT_TIMESPAN) {
    case 'weekly':
      return moment(today).endOf('isoweek').format('YYYY-MM-DD')
    case 'monthly':
      return moment(today).endOf('month').format('YYYY-MM-DD')
    default:
      break
  }
}

const ReportDatesSelector = (props) => {
  const [config, setConfig] = useState({
    start: props.start || getDefaultStart(),
    stop: props.stop || getDefaultStop(),
    output_mode: DEFAULT_TIMESPAN,
  })

  const isFetching = false

  const getPreviousData = (change) => {
    if (/*ReportsStore.*/ isFetching) return

    switch (change) {
      case 'week':
        onChange({
          start: moment(config.start, 'YYYY-MM-DD')
            .subtract(1, 'weeks')
            .startOf('isoweek')
            .format('YYYY-MM-DD'),
          stop: moment(config.start, 'YYYY-MM-DD')
            .subtract(1, 'weeks')
            .endOf('isoweek')
            .format('YYYY-MM-DD'),
          output_mode: 'weekly',
        })
        break
      case 'monthly':
        onChange({
          start: moment(config.start, 'YYYY-MM-DD')
            .subtract(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD'),
          stop: moment(config.start, 'YYYY-MM-DD')
            .subtract(1, 'months')
            .endOf('month')
            .format('YYYY-MM-DD'),
          output_mode: 'monthly',
        })
        break
      case 'quarterly':
        onChange({
          start: moment(config.start, 'YYYY-MM-DD')
            .subtract(1, 'quarter')
            .startOf('quarter')
            .format('YYYY-MM-DD'),
          stop: moment(config.start, 'YYYY-MM-DD')
            .subtract(1, 'quarter')
            .endOf('quarter')
            .format('YYYY-MM-DD'),
          output_mode: 'quarterly',
        })
        break
      case 'year':
        onChange({
          start: moment(config.start, 'YYYY-MM-DD')
            .subtract(1, 'year')
            .startOf('year')
            .format('YYYY-MM-DD'),
          stop: moment(config.start, 'YYYY-MM-DD')
            .subtract(1, 'year')
            .endOf('year')
            .format('YYYY-MM-DD'),
          output_mode: 'yearly',
        })
        break
      default:
        break
    }
  }

  const getCurrentData = (change) => {
    if (/*ReportsStore.*/ isFetching) return

    switch (change) {
      case 'week':
        onChange({
          start: moment(today).startOf('isoweek').format('YYYY-MM-DD'),
          stop: moment(today).endOf('isoweek').format('YYYY-MM-DD'),
          output_mode: 'weekly',
        })
        break
      case 'monthly':
        onChange({
          start: moment(today).startOf('month').format('YYYY-MM-DD'),
          stop: moment(today).endOf('month').format('YYYY-MM-DD'),
          output_mode: 'monthly',
        })
        break
      case 'quarterly':
        onChange({
          start: moment(today).startOf('quarter').format('YYYY-MM-DD'),
          stop: moment(today).endOf('quarter').format('YYYY-MM-DD'),
          output_mode: 'quarterly',
        })
        break
      case 'year':
        onChange({
          start: moment(today).startOf('year').format('YYYY-MM-DD'),
          stop: moment(today).endOf('year').format('YYYY-MM-DD'),
          output_mode: 'yearly',
        })
        break
      default:
        break
    }
  }

  const getNextData = (change) => {
    if (/*ReportsStore.*/ isFetching) return

    switch (change) {
      case 'week':
        onChange({
          start: moment(config.stop, 'YYYY-MM-DD')
            .add(1, 'weeks')
            .startOf('isoweek')
            .format('YYYY-MM-DD'),
          stop: moment(config.start, 'YYYY-MM-DD')
            .add(1, 'weeks')
            .endOf('isoweek')
            .format('YYYY-MM-DD'),
          output_mode: 'weekly',
        })
        break
      case 'monthly':
        onChange({
          start: moment(config.stop, 'YYYY-MM-DD')
            .add(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD'),
          stop: moment(config.stop, 'YYYY-MM-DD')
            .add(1, 'months')
            .endOf('month')
            .format('YYYY-MM-DD'),
          output_mode: 'monthly',
        })
        break
      case 'quarterly':
        onChange({
          start: moment(config.stop, 'YYYY-MM-DD')
            .add(1, 'quarter')
            .startOf('quarter')
            .format('YYYY-MM-DD'),
          stop: moment(config.stop, 'YYYY-MM-DD')
            .add(1, 'quarter')
            .endOf('quarter')
            .format('YYYY-MM-DD'),
          output_mode: 'quarterly',
        })
        break
      case 'year':
        onChange({
          start: moment(config.stop, 'YYYY-MM-DD')
            .add(1, 'year')
            .startOf('year')
            .format('YYYY-MM-DD'),
          stop: moment(config.stop, 'YYYY-MM-DD')
            .add(1, 'year')
            .endOf('year')
            .format('YYYY-MM-DD'),
          output_mode: 'yearly',
        })
        break
      default:
        break
    }
  }

  const onChange = (changed) => {
    setConfig(changed)
    props.onChange(changed)
  }

  return (
    <MDBContainer id="ReportDatesSelector" className="mb-4">
      <MDBRow>
        <MDBCol lg="4" className="text-center">
          <h5>Previous</h5>
          <MDBBtn
            tag="a"
            flat
            size="sm"
            className="p-0 pr-2 text-primary"
            onClick={() => {
              getPreviousData('week')
            }}
          >
            Week
          </MDBBtn>{' '}
          |
          <MDBBtn
            tag="a"
            flat
            size="sm"
            className="p-0 pl-2 pr-2 text-primary"
            onClick={() => {
              getPreviousData('monthly')
            }}
          >
            Month
          </MDBBtn>{' '}
          |
          <MDBBtn
            tag="a"
            flat
            size="sm"
            className="p-0 pl-2 pr-2 text-primary"
            onClick={() => {
              getPreviousData('quarterly')
            }}
          >
            Quarter
          </MDBBtn>{' '}
          |
          <MDBBtn
            tag="a"
            flat
            size="sm"
            className="p-0 pl-2 text-primary"
            onClick={() => {
              getPreviousData('year')
            }}
          >
            Year
          </MDBBtn>
        </MDBCol>
        <MDBCol lg="4" className="text-center">
          <h5>Current</h5>
          <MDBBtn
            tag="a"
            flat
            size="sm"
            className="p-0 pr-2 text-primary"
            onClick={() => {
              getCurrentData('week')
            }}
          >
            Week
          </MDBBtn>{' '}
          |
          <MDBBtn
            tag="a"
            flat
            size="sm"
            className="p-0 pl-2 pr-2 text-primary"
            onClick={() => {
              getCurrentData('monthly')
            }}
          >
            Month
          </MDBBtn>{' '}
          |
          <MDBBtn
            tag="a"
            flat
            size="sm"
            className="p-0 pl-2 pr-2 text-primary"
            onClick={() => {
              getCurrentData('quarterly')
            }}
          >
            Quarter
          </MDBBtn>{' '}
          |
          <MDBBtn
            tag="a"
            flat
            size="sm"
            className="p-0 pl-2 text-primary"
            onClick={() => {
              getCurrentData('year')
            }}
          >
            Year
          </MDBBtn>
        </MDBCol>
        <MDBCol lg="4" className="text-center">
          <h5>Next</h5>
          <MDBBtn
            tag="a"
            flat
            size="sm"
            className="p-0 pr-2 text-primary"
            onClick={() => {
              getNextData('week')
            }}
          >
            Week
          </MDBBtn>{' '}
          |
          <MDBBtn
            tag="a"
            flat
            size="sm"
            className="p-0 pl-2 pr-2 text-primary"
            onClick={() => {
              getNextData('monthly')
            }}
          >
            Month
          </MDBBtn>{' '}
          |
          <MDBBtn
            tag="a"
            flat
            size="sm"
            className="p-0 pl-2 pr-2 text-primary"
            onClick={() => {
              getNextData('quarterly')
            }}
          >
            Quarter
          </MDBBtn>{' '}
          |
          <MDBBtn
            tag="a"
            flat
            size="sm"
            className="p-0 pl-2 text-primary"
            onClick={() => {
              getNextData('year')
            }}
          >
            Year
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(ReportDatesSelector)
