import React from 'react'
import StorageService from './../../../shared/services/Storage.service'

/*
	autoOpen={false|INT}
	onSuccess
	onError
	onClose
	onCancel
*/

const _isJson = (str) => {
  try {
    return !!JSON.parse(str)
  } catch (ex) {
    return false
  }
}

const _getStored = (modalId) => {
  let stored = StorageService.get(modalId)
  return stored && _isJson(stored) ? JSON.parse(stored) : false
}

const _setStored = (modalId, payload) => {
  StorageService.set(modalId, payload)
}

const DEFAULT_PARAMS = {
  autoOpen: false,
  bodyClass: null,
}

class ModalsController {
  _modalsParams = {}
  _modalsStatus = {}
}

ModalsController.prototype.init = function (modalId, params) {
  this._modalsParams[modalId] = {
    ...DEFAULT_PARAMS,
    ...(params && typeof params === 'object' ? params : {}),
  }
  return this
}

ModalsController.prototype.params = function (modalId, param) {
  try {
    return this._modalsParams[modalId][param]
  } catch (ex) {
    console.error(
      `Failed to get modal param in ModalController. [MODAL_ID: ${modalId}] ${ex}`
    )
  }
}

ModalsController.prototype.checkCanOpen = function (modalId) {
  if (this.params(modalId, 'autoOpen') !== false)
    return (
      Object.values(this._modalsStatus).filter((n) => n === true).length === 0
    )
  return true
}

ModalsController.prototype.isOpen = function (modalId) {
  return (
    this._modalsStatus.hasOwnProperty(modalId) && this._modalsStatus[modalId]
  )
}

ModalsController.prototype.open = function (modalId) {
  // if this modal is non-blocking, make sure it can open first.
  if (!this.checkCanOpen(modalId))
    throw new Error('Modal blocked by another modal previously opened.')

  // get current state.
  const wasOpen = this.isOpen(modalId)

  // set state to true.
  this._modalsStatus[modalId] = true

  // add body class if not exists.
  const bodyClass = this.params(modalId, 'bodyClass')
  if (bodyClass && !document.body.classList.contains(bodyClass))
    document.body.classList.add(bodyClass)

  return this
}

ModalsController.prototype.close = function (modalId) {
  // set state to false.
  this._modalsStatus[modalId] = false

  // remove body class if exists.
  const bodyClass = this.params(modalId, 'bodyClass')
  if (bodyClass) document.body.classList.remove(bodyClass)

  return this
}

export default new ModalsController()
