import BaseModelFactory from './base.factory'
import TermService from './../services/Term.service'
import Term from './../models/term.model'

class TermFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: TermService, Model: Term }
    )
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: TermService, Model: Term }
    )
  static findAllById = async (id) =>
    await this._findAllBy(
      { id: id },
      { Service: TermService, Model: Term }
    )
  static findByUsertypeId = async (usertypeId) =>
    await this._findBy(
      { usertype_id: usertypeId },
      { Service: TermService, Model: Term }
    )
  static create = (payload) =>
    this._create(payload, { Service: TermService, Model: Term })
}

export default TermFactory
