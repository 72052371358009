import React from 'react'
import getTheme from './../../getTheme.function'

import './SubsiteBanner.component.scss';

const SubsiteBanner = ({subsiteData, message}) => 
{
	return (
      <div id="SubsiteBannerComponent">
        <section className="bg-gray-dark page-title-wrap"
          style={{
            backgroundImage: `url(${getTheme(subsiteData?.theme_name)})`,
          }}
        >
        {
        	message
        		? 	<div className="shell" style={{ position: 'relative' }}>
                  <div className="page-title">
                    <h2 className={'text-white'}>{message}</h2>
                  </div>
                </div>
            : 	<></>
        }
        </section>
      </div>
		)
}

export default SubsiteBanner;