import BaseModelFactory from './base.factory'
import CoverageService from './../services/Coverage.service'
import Coverage from './../models/coverage.model'

class CoverageFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: CoverageService, Model: Coverage }
    )
  static findAll = async () =>
    await this._findAllBy({}, { Service: CoverageService, Model: Coverage })
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: CoverageService, Model: Coverage }
    )
  static findAllById = async (id) =>
    await this._findAllBy(
      { id: id },
      { Service: CoverageService, Model: Coverage }
    )
  static create = (payload) =>
    this._create(payload, { Service: CoverageService, Model: Coverage })
}

export default CoverageFactory
