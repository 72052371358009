import { makeAutoObservable, toJS } from 'mobx'
import UserProfileService from './../../shared/services/UserProfile.service'
import CarrierService from './../../shared/services/Carrier.service'
import CarrierContractService from './../../shared/services/CarrierContract.service'
import UserCertificationService from './../../shared/services/UserCertification.service'
import filterCarriers from './filterCarriers.function'
import UsersCarrierContractService from './../../shared/services/UsersCarrierContract.service'
import UserMetaService from './../../shared/services/UserMeta.service'

let creatingSpecs
const createAgentSpecs = async (specs) => {
  let userMeta

  if (!Object.values(specs).filter((n) => !!n).length) specs.HEALTH = true

  try {
    userMeta = await UserMetaService.store({
      user_id: UserProfileService.getUserId(),
      meta_key: 'profile---specializations',
      meta_value: JSON.stringify({
        requested: specs,
        active: { ACA: false, HEALTH: true, SENIOR: false, LIFE: false },
      }),
    })
    if (userMeta && userMeta?.id)
      userMeta = await UserMetaService.search({ search: { id: userMeta.id } })
    return userMeta && userMeta?.models ? userMeta.models.shift() : null
  } catch (ex) {}
}

const _listeners = []

const getContractTypeByCarrierId = (carrierId, carriers) => {
  let c = (carriers || []).filter((c) => `${c.id}` === `${carrierId}`).shift()
  return c && (c?.contract_type || c?.c_type)
}

const dispositionTranslation = (carrierContract) => {
  let contractType =
      (carrierContract && carrierContract?.contract_type) || null,
    carrierId = (carrierContract && carrierContract?.carrier_id) || null,
    disposition = (carrierContract && carrierContract?.disposition) || null

  return { contractType, carrierId, disposition }
}

const dispositionToWaitingOn = (disposition) => {
  switch (`${disposition}`.trim().toLowerCase()) {
    case 'pdf-unsent':
    case 'pdf-pending':
    case 'pdf-awaiting':
    case 'acr-unsent':
    case 'acr-pending':
    case 'acr-awaiting':
    case 'link-unsent':
    case 'link-pending':
    case 'link-awaiting':
    case 'unsent':
    case '-unsent':
    case 'pending':
    case '-pending':
    case 'awaiting':
    case '-awaiting':
      return 'agent'

    case 'reviewing':
    case '-reviewing':
    case 'pdf-reviewing':
      return 'usabg'

    default:
      return ''
  }
}

const dispositionToStatus = (disposition) => {
  switch (`${disposition}`.trim().toLowerCase()) {
    case 'pdf-unsent':
      return 'Waiting on agent to start contract'
    case 'pdf-pending':
      return 'Waiting on agent to start contract'
    case 'pdf-reviewing':
      return 'Quality control reviewing'
    case 'pdf-awaiting':
      return 'Awaiting agent writing number'
    case 'pdf-completed':
      return 'Contract Completed'

    case 'acr-unsent':
      return 'Waiting on agent to start contract'
    case 'acr-pending':
      return 'Waiting on agent signature'
    case 'acr-reviewing':
      return 'Quality control reviewing'
    case 'acr-awaiting':
      return 'Awaiting agent writing number'
    case 'acr-completed':
      return 'Contract Completed'

    case 'link-unsent':
      return 'Waiting on agent to start contract'
    case 'link-pending':
      return 'Waiting on agent to start contract'
    case 'link-reviewing':
      return 'Quality control reviewing'
    case 'link-awaiting':
      return 'Awaiting agent writing number'
    case 'link-completed':
      return 'Contract Completed'

    case 'unsent':
    case '-unsent':
      return 'Waiting on agent to start contract'
    case 'pending':
    case '-pending':
      return 'Waiting on agent signature'
    case 'reviewing':
    case '-reviewing':
      return 'Quality control reviewing'
    case 'awaiting':
    case '-awaiting':
      return 'Awaiting agent writing number'
    case 'completed':
    case '-completed':
      return 'Contract Completed'
    default:
      return ''
  }
}

class CarrierStore {
  constructor() {
    makeAutoObservable(this)
  }

  isOnboarding = false
  maxContracts = false
  downloading = false
  sending = false
  pdfBase64 = null

  _isLoading = {
    'fetch.carriers': false,
    'met.requirements': false,
    'fetch.agent-carriers': false,
    'update.agent-specs': false,
    'fetch.agent-specs': false,
    'update.agent-contract': false,
    'fetch.carrier-contracts': false,
  }

  _specializations = {
    ACA: false,
    HEALTH: false,
    SENIOR: false,
    LIFE: false,
  }
  _required = {
    usertype: 0,
    core: 0,
    preferred: 0,
    ACA: 0,
    HEALTH: 0,
    SENIOR: 0,
    LIFE: 0,
  }

  _carriers = []
  _carrierContracts = {}
  _carrierContractTypes = {}
  _filteredCarriers = {
    core: [],
    preferred: [],
    additional: [],
  }
  _selectedCarriers = []

  _agentCarriers = []

  _userSpecMeta = null

  _requirements = {}

  _examineContract = false

  _validity = {
    pending: undefined,
    completed: undefined,
  }

  // --------------------------------------------------------
  // Carrier Management

  fetchCarriers = async () => {
    this._isLoading['fetch.carriers'] = true

    return new Promise((resolve, reject) => {
      CarrierService.getWithPriorities()
        .then((res) => {
          let carriers = {}

          ;(res.models || []).forEach((carrier) => {
            if (!carriers.hasOwnProperty(`${carrier.id}`)) {
              carriers[`${carrier.id}`] = {
                id: carrier.id,
                c_name: carrier.c_name,
                c_description: carrier.c_description,
                c_active: carrier.c_active,
                c_email: carrier.contract_email,
                c_type: carrier.contract_type,
                priorities: {},
              }
            } else if (
              !carriers[`${carrier.id}`].c_type &&
              carrier.contract_type
            )
              carriers[`${carrier.id}`].c_type = carrier.contract_type

            if (carrier.contract_type)
              this._carrierContractTypes[`${carrier.id}`] =
                carrier.contract_type

            if (carrier.spec) {
              carriers[`${carrier.id}`].priorities[carrier.spec] = {
                contract_type: carrier.contract_type,
                is_affiliate_core: carrier.is_affiliate_core,
                is_affiliate_preferred: carrier.is_affiliate_preferred,
                is_associate_core: carrier.is_associate_core,
                is_associate_preferred: carrier.is_associate_preferred,
                is_signature_core: carrier.is_signature_core,
                is_signature_preferred: carrier.is_signature_preferred,
              }
            }
          })

          this._carriers = Object.keys(carriers).map((c) => carriers[c])
          this.setFilteredCarriers()
          this.hasMetRequirements()
          this.setAgentCarrierNames()
          this._isLoading['fetch.carriers'] = false
          resolve(this._carriers)
        })
        .catch((err) => {
          console.error('Error Loading Carriers', err)
          resolve([])
          this._isLoading['fetch.carriers'] = false
        })
    })
  }

  getCarriers = () => toJS(this._carriers)

  getFilteredCarriers = (table) => toJS(this._filteredCarriers[table])

  setFilteredCarriers = () => {
    let selected = this.getSelectedCarriers().map((c) => parseInt(c.id)),
      contracted = this.getAgentCarriers(),
      usertype = this.getUsertype()

    return Object.keys(this._filteredCarriers).forEach((table) => {
      let carriers = filterCarriers(
        this.getCarriers(),
        this.getSpec(),
        this.getUsertype() === 'csr' ? 'signature' : this.getUsertype(),
        table
      )
      this._filteredCarriers[table] = carriers
        .filter((carrier) => parseInt(carrier.c_active) === 1)
        .map((carrier) => {
          // Set carrier priority labels (ie: 'HEALTH, SENIOR')
          if (!carrier.priority_label) {
            carrier.priority_label = []
            if (typeof carrier.priorities === 'object') {
              Object.keys(carrier.priorities).forEach((k) => {
                if (
                  carrier.priorities[k].hasOwnProperty(
                    `is_${usertype}_${table}`
                  )
                ) {
                  if (
                    parseInt(
                      carrier.priorities[k][`is_${usertype}_${table}`]
                    ) === 1
                  )
                    carrier.priority_label.push(k)
                }
              })
            }
            carrier.priority_label = Array.isArray(carrier.priority_label)
              ? carrier.priority_label.join(', ')
              : ''
          }

          carrier.status =
            selected.indexOf(parseInt(carrier.id)) > -1
              ? 'Added to contracting cart'
              : null
          if (!carrier.status) {
            let c = contracted
              .filter((t) => parseInt(t.carrier_id) === parseInt(carrier.id))
              .shift()
            carrier.status = c ? this.dispositionToStatus(c) : undefined
          }

          if (!carrier.status) carrier.status = 'Available'
          return carrier
        })
    })
  }

  getSelectedCarriers = () => toJS(this._selectedCarriers)

  clearSelectedCarriers = () => (this._selectedCarriers = [])

  removeSelectedCarrier = (carrier) =>
    (this._selectedCarriers = this.getSelectedCarriers().filter(
      (c) => parseInt(c.id) !== parseInt(carrier.id)
    ))

  isCarrierCore = (carrier) => {
    let a = [],
      id = false

    if (carrier?.carrier_id && !isNaN(carrier.carrier_id))
      id = parseInt(carrier.carrier_id)
    else if (carrier?.id && !isNaN(carrier.id)) id = parseInt(carrier.id)

    a = id && id > 0 ? toJS(this._filteredCarriers.core) : []

    return a.filter((c) => parseInt(c.id) === id).length > 0
  }

  isCarrierPreferred = (carrier) => {
    let a = [],
      id = false

    if (carrier?.carrier_id && !isNaN(carrier.carrier_id))
      id = parseInt(carrier.carrier_id)
    else if (carrier?.id && !isNaN(carrier.id)) id = parseInt(carrier.id)

    a = id && id > 0 ? toJS(this._filteredCarriers.preferred) : []

    return a.filter((c) => parseInt(c.id) === id).length > 0
  }

  isCarrierSelected = (carrier) =>
    this.getSelectedCarriers()
      .map((c) => parseInt(c.id))
      .indexOf(parseInt(carrier.id)) > -1

  unselectCarrier = (carrier) =>
    (this._selectedCarriers = this.getSelectedCarriers().filter(
      (c) => parseInt(c.id) !== parseInt(carrier.id)
    ))

  onCarrierToggle = (carrier, prevReq) => {
    // disregard carriers already contracted.
    if (this.isCarrierContracted(carrier, true)) {
      return
    }

    const shouldIncludeCarrier = (carrier) => {
      // This function may need to be updated to
      // take other factors into consideration.

      // The intent of this function is to determine
      // if the passed carrier should be included
      // within the max-carriers count.
      //
      return (
        carrier?.disposition !== 'expired' &&
        (this.isCarrierCore(carrier) || this.isCarrierPreferred(carrier))
      )
    }

    let isSelected = this.isCarrierSelected(carrier)
    if (!isSelected) {
      if (
        !isNaN(this.maxContracts) &&
        this.maxContracts >
          this.getSelectedCarriers().length +
            this.getAgentCarriers().filter(shouldIncludeCarrier).length
      )
        this._selectedCarriers = this.getSelectedCarriers().concat([carrier])
      else if (!this.maxContracts || isNaN(this.maxContracts))
        this._selectedCarriers = this.getSelectedCarriers().concat([carrier])
      else return 'EXCEED_MAX'
    } else {
      this.unselectCarrier(carrier)
    }

    this.setFilteredCarriers()

    if (prevReq !== true) this.hasMetRequirements()
  }

  getRequirements = () => toJS(this._requirements)

  hasMetRequirements = async () => {
    this._isLoading['met.requirements'] = true
    let outcome = this.getRequirements()

    if (!outcome.hasOwnProperty('usertype')) outcome.usertype = null

    outcome.LIFE = null
    outcome.HEALTH = null
    outcome.ACA = null
    outcome.SENIOR = null

    const include = { include: this.getSelectedCarriers().map((c) => c.id) }

    try {
      let result = await UsersCarrierContractService.hasMetRequirements(
        UserProfileService.getUserId(),
        include
      )

      outcome = { ...outcome, ...result.outcome }
      this._required = {
        ...{
          usertype: 0,
          core: 0,
          preferred: 0,
          ACA: 0,
          HEALTH: 0,
          SENIOR: 0,
          LIFE: 0,
        },
        ...result.outstanding,
      }
    } catch (ex) {}

    this._requirements = outcome
    this.setValidity(this.testValidity())
    this._isLoading['met.requirements'] = false

    return this.getRequirements()
  }

  getCarrierContracts = async () => toJS(this._carrierContracts)

  fetchCarrierContracts = async (carrierIds) => {
    this._isLoading['fetch.carrier-contracts'] = true

    let contracts = []
    try {
      contracts = await CarrierContractService.search({
        search: { carrier_id: carrierIds },
        pagination: false,
      })
      contracts = (contracts && contracts?.models) || []
    } catch (ex) {
      console.error(
        `Fatal error attempting to fetch carrier contracts in CarrierStore.  ${ex}`
      )
    }

    ;[...contracts].forEach(
      (contract) =>
        (contracts = {
          ...(Array.isArray(contracts) ? {} : contracts),
          [contract.carrier_id]: contract,
        })
    )

    this._carrierContracts = contracts
    this._isLoading['fetch.carrier-contracts'] = false

    return this.getCarrierContracts()
  }
  // --------------------------------------------------------

  // --------------------------------------------------------
  // Carrier Management
  fetchAgentCarriers = async () => {
    this._isLoading['fetch.agent-carriers'] = true

    let agentCarriers = []

    try {
      agentCarriers = await UsersCarrierContractService.search({
        search: { user_id: UserProfileService.getUserId() },
        pagination: false,
      })
      agentCarriers =
        agentCarriers && Array.isArray(agentCarriers.models)
          ? agentCarriers.models
          : []
    } catch (ex) {
      agentCarriers = []
    }

    this._agentCarriers = agentCarriers
    this.setAgentCarrierNames()

    this.setValidity(this.testValidity())
    this._isLoading['fetch.agent-carriers'] = false
  }

  getAgentCarrier = (carrierId) =>
    (this.getAgentCarriers() || [])
      .filter((c) => parseInt(c.carrier_id) === parseInt(carrierId))
      .shift()

  getAgentCarriers = () => toJS(this._agentCarriers)

  isCarrierContracted = (carrier, excludeExpired) =>
    this.getAgentCarriers()
      .filter((c) =>
        !!excludeExpired && c?.disposition === 'expired' ? false : true
      )
      .map((c) => parseInt(c.carrier_id))
      .indexOf(parseInt(carrier.id)) > -1

  setAgentCarrierNames = () => {
    let src
    this._agentCarriers = this.getAgentCarriers()
      .map((carrier) => {
        if (
          (src = this.getCarriers()
            .filter((c) => parseInt(c.id) === parseInt(carrier.carrier_id))
            .shift()) &&
          src?.c_name
        ) {
          carrier.c_name = src.c_name
          carrier.c_description = src.c_description
            ? src.c_description
            : carrier?.c_description
            ? carrier.c_description
            : ''
        }
        return carrier
      })
      .sort((a, b) => {
        a = `${a?.c_name}`.charAt(0)
        b = `${b?.c_name}`.charAt(0)
        return a > b ? 1 : b > a ? -1 : 0
      })
  }

  attachCarriers = async () => {
    if (this.getSelectedCarriers().length) {
      let results = await UsersCarrierContractService.attach(
        UserProfileService.getUserId(),
        {
          carrier_ids: this.getSelectedCarriers().map((carrier) =>
            parseInt(carrier.id)
          ),
        }
      )

      // notify the agent onboarding service
      // of the success validity.
      this.setValidity(this.testValidity())

      return results
    }
  }

  examineAgentContract = (Contract) => (this._examineContract = Contract)

  getExaminedAgentContract = () => toJS(this._examineContract)

  updateAgentContract = async (id, changed) => {
    try {
      this._isLoading['update.agent-contract'] = true
      await UsersCarrierContractService.update(id, changed)
      this._isLoading['update.agent-contract'] = false
      this.fetchAgentCarriers()
      return Promise.resolve(true)
    } catch (ex) {
      this._isLoading['update.agent-contract'] = false
      return Promise.resolve(`${ex}`.replace(/^error:/i, ''))
    }
  }

  getContractTypeByCarrierId = (carrier) =>
    carrier && carrier?.id ? getContractTypeByCarrierId(carrier.id) : null

  fetchContractPdf = async (contract) => {
    this.downloading = true

    let base64,
      id = contract.id
    id = id && typeof id === 'function' ? id() : id
    try {
      base64 = await UsersCarrierContractService.downloadContract(id)
    } catch (ex) {}

    base64 = (base64 && base64?.base64) || null

    this.downloading = false
    this.pdfBase64 = base64 ? { contract, base64 } : null
  }

  downloadContract = async () => {
    if (this.pdfBase64 && this.pdfBase64?.base64) {
      const linkSource = `data:application/pdf;base64,${this.pdfBase64.base64}`
      const downloadLink = document.createElement('a')
      const fileName = this.pdfBase64?.contract?.agreement_id
        ? `${this.pdfBase64.contract.agreement_id}.pdf`
        : `contract_${this.pdfBase64.contract.id()}_${this.pdfBase64.contract.get(
            'user_id'
          )}.pdf`
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    }
  }

  sendContractToCarrier = async (contract) => {
    this.sending = true

    try {
      let result = await UsersCarrierContractService.sendContractToCarrier(
        contract.id
      )
      this.sending = false
      return result
    } catch (ex) {
      console.error('Failed to send contract to carrier.  ', ex)
      this.sending = false
      return `${ex}`
    }
  }

  // --------------------------------------------------------

  // --------------------------------------------------------
  // Agent Specializations

  setSpec = (spec) => {
    if (spec && typeof spec === 'object') {
      Object.keys(this._specializations).forEach(
        (key) => (this._specializations[key] = !!spec[key])
      )
      this.setFilteredCarriers()
      this.hasMetRequirements()
    }
  }

  getSpec = () => toJS(this._specializations)

  isSpecActive = (spec) => !!this.getSpec()[spec]

  onSpecToggle = async (spec, inputType) => {
    if (inputType === 'radio') {
      if (this._specializations[spec] !== true) this._selectedCarriers = []

      this._specializations[spec] = true
      Object.keys(this._specializations)
        .filter((s) => s !== spec)
        .forEach((spec) => (this._specializations[spec] = false))
    } else {
      this._specializations[spec] = !this.isSpecActive(spec)
    }

    this.setFilteredCarriers()
    if (this.isOnboarding === true) {
      // if the agent is onboarding, we need to
      // remove carrier contracts that are not
      // core or preferred to the current spec.

      this.getSelectedCarriers()
        .filter(
          (carrier) =>
            !this.isCarrierCore(carrier) && !this.isCarrierPreferred(carrier)
        )
        .forEach((carrier) => this.unselectCarrier(carrier))
    }

    await this.updateAgentSpecs()
  }

  updateAgentSpecs = async () => {
    let userMeta = this._userSpecMeta ? toJS(this._userSpecMeta) : {},
      metaId = userMeta && userMeta?.id,
      metaValue = userMeta && userMeta?.meta_value

    if (metaId) {
      this._isLoading['update.agent-specs'] = true
      if (
        await UserMetaService.update(metaId, {
          meta_value: JSON.stringify({
            ...metaValue,
            requested: this.getSpec(),
          }),
        })
      ) {
        this._isLoading['update.agent-specs'] = false
        await this.hasMetRequirements()
      } else {
        this._isLoading['update.agent-specs'] = false
      }
    }
  }

  fetchAgentSpecs = async () => {
    this._isLoading['fetch.agent-specs'] = true

    let userMeta

    try {
      userMeta = await UserMetaService.search({
        search: {
          user_id: UserProfileService.getUserId(),
          meta_key: 'profile---specializations',
        },
        pagination: false,
      })
      userMeta = userMeta && userMeta?.models ? userMeta.models.shift() : null
    } catch (ex) {}

    if (!userMeta) {
      if (!creatingSpecs) creatingSpecs = createAgentSpecs(this.getSpec())
      if (creatingSpecs) userMeta = await creatingSpecs
    }

    if (userMeta && userMeta?.meta_key) {
      let u = {}
      try {
        u = JSON.parse(userMeta.meta_value)
      } catch (ex) {}
      userMeta.meta_value =
        u && typeof u === 'object'
          ? u
          : {
              requested: {
                ACA: false,
                HEALTH: false,
                SENIOR: false,
                LIFE: false,
              },
              active: { ACA: false, HEALTH: false, SENIOR: false, LIFE: false },
            }
    }

    this._userSpecMeta = userMeta

    if (
      this._userSpecMeta &&
      this._userSpecMeta.meta_value &&
      this._userSpecMeta.meta_value?.requested
    ) {
      Object.keys(this._userSpecMeta.meta_value.requested).forEach(
        (s) =>
          (this._specializations[s] =
            !!this._userSpecMeta.meta_value.requested[s])
      )
      this.setFilteredCarriers()
    }

    this._isLoading['fetch.agent-specs'] = false
    return userMeta
  }

  fetchAgentCertifications = async () => {
    const certificationData = await UserCertificationService.search({
      search: { user_id: UserProfileService.getUserId() },
    })
    return certificationData
  }

  // --------------------------------------------------------
  dispositionToWaitingOn = (carrierContract) => {
    let { contractType, carrierId, disposition } =
      dispositionTranslation(carrierContract)

    if (disposition) {
      contractType =
        !contractType && carrierId
          ? getContractTypeByCarrierId(carrierId)
          : contractType

      return dispositionToWaitingOn(
        [
          contractType && typeof contractType === 'string'
            ? contractType
            : null,
          disposition,
        ]
          .filter((n) => !!n)
          .join('-')
      )
    }

    return ''
  }

  dispositionToStatus = (carrierContract) => {
    let { contractType, carrierId, disposition } =
      dispositionTranslation(carrierContract)

    if (disposition) {
      contractType =
        !contractType && carrierId
          ? getContractTypeByCarrierId(carrierId)
          : contractType

      return dispositionToStatus(
        [
          contractType && typeof contractType === 'string'
            ? contractType
            : null,
          disposition,
        ]
          .filter((n) => !!n)
          .join('-')
      )
    }

    return ''
  }

  getUsertype = () => {
    if (UserProfileService.isA(['affiliate-agent', 'affiliate-manager']))
      return 'affiliate'
    if (UserProfileService.isA(['associate-agent', 'associate-manager']))
      return 'associate'
    if (UserProfileService.isA(['csr-agent']))
      return 'csr'
    return 'signature'
  }

  getIsLoading = (eventName) => {
    const l = toJS(this._isLoading)
    return (
      l.hasOwnProperty(`${eventName}`.trim().toLowerCase()) &&
      !!l[`${eventName}`.trim().toLowerCase()]
    )
  }

  setValidity = (isValid) => {
    isValid = isValid && typeof isValid === 'object' ? isValid : {}

    let hasPendingChanged = !!this._validity?.pending !== !!isValid?.pending,
      hasCompletedChanged = !!this._validity?.completed !== !!isValid?.completed

    this._validity = { ...toJS(this._validity), ...isValid }

    if (hasPendingChanged || hasCompletedChanged) this.broadcastValidity()
  }

  listenToValidityChange = (cb) => {
    if (typeof cb === 'function') _listeners.push(cb)
  }

  stopListeningToValidityChange = (cb) => {
    if (_listeners.indexOf(cb) > -1)
      _listeners.splice(1, _listeners.indexOf(cb))
  }

  testValidity = () => {
    const testPending = () => {
      // To Pass Onboarding Stage 2,
      // the Agent must have:

      // 	1. met the usertype requirements.
      if (`${this.getRequirements()?.usertype}`.indexOf('unmet') > -1)
        return false // didnt meet usertype requirements.

      // 	2. signed OR started min core || preferred contracts.
      let validCarrierIds = this._agentCarriers
          .filter((ac) => !!ac.pwk_signed_at)
          .map((ac) => `${ac.carrier_id}`),
        count = this._filteredCarriers.core.filter(
          (c) => validCarrierIds.indexOf(`${c.id}`) > -1
        ).length,
        required = 8

      switch (this.getUsertype()) {
        case 'affiliate':
          required = 1
          break
        case 'associate':
          required = 5
          break
        case 'csr':
          required = 8
        default:
      }

      // true if agent has signed or started the min num of core or preferred contracts.
      return !!(required > count
        ? count +
            this._filteredCarriers.preferred.filter(
              (c) => validCarrierIds.indexOf(`${c.id}`) > -1
            ).length >
          count
        : true)
    }

    const testCompleted = () => {
      // To Pass Onboarding Stage 5,
      // the Agent must have
      // 	have dates & entered writing numbers for min core || preferred contracts.
      let validCarrierIds = this._agentCarriers
          .filter((ac) => `${ac?.disposition}`.indexOf('complete') > -1)
          .map((ac) => `${ac.carrier_id}`),
        count = this._filteredCarriers.core.filter(
          (c) => validCarrierIds.indexOf(`${c.id}`) > -1
        ).length,
        required = 5

      if (this.getUsertype() === 'affiliate') required = 1

      // true if agent has completed the min num of core or preferred contracts.
      return !!(required > count
        ? count +
            this._filteredCarriers.preferred.filter(
              (c) => validCarrierIds.indexOf(`${c.id}`) > -1
            ).length >
          count
        : true)
    }

    return { pending: testPending(), completed: testCompleted() }
  }

  broadcastValidity = () => {
    _listeners.forEach((listener) => listener(this._validity))
  }
}

export default new CarrierStore()
