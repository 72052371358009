import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import {
  MDBCollapse,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBBtnGroup,
  MDBBtn,
  MDBCardImage,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
  MDBRow,
  MDBCol,
} from 'mdbreact'
import { UIDataTable } from './../../../../components/content'
import Store from './../../stores/Knowledgebase.store'

import './KbCategoriesList.component.scss'

const KbCategoriesList = () => {
  const [tableColumns, setTableColumns] = useState([
      {
        label: 'Category',
        field: 'c_name',
        sort_col: 'c_name',
      },
      {
        label: 'Actions',
        field: 'actions',
      },
    ]),
    [orderBy, setOrderBy] = useState({ col: 'id', dir: 'DESC' })

  const _renderColumnHeader = (col) => {
    const getOrderBy = () => toJS(Store.articlesOrderBy)

    const getSortCol = () =>
      (col?.sort_col ? col.sort_col : col?.field) || false

    const getSortDir = (sortCol) => {
      const orderBy = getOrderBy()
      if (orderBy && orderBy.hasOwnProperty(sortCol) && orderBy[sortCol])
        return orderBy[sortCol]
      return false
    }

    const isColSorted = (sortCol) => {
      return !!getSortDir(sortCol)
    }

    const onSortUp = () => {
      const sortCol = getSortCol(),
        sortDir = getSortDir(sortCol)

      if (sortDir === false || sortDir === 'DESC') {
        // not currently sorted OR sorted DESC.
        // change to ASC
        return Store.searchCategories({ orderBy: { [sortCol]: 'ASC' } })
      } else if (sortDir === 'ASC') {
        // already sorted ASC, remove sorting.
        return Store.searchCategories({ orderBy: { id: 'ASC' } })
      }
    }

    const onSortDown = () => {
      const sortCol = getSortCol(),
        sortDir = getSortDir(sortCol)

      if (sortDir === false || sortDir === 'ASC') {
        // not currently sorted OR sorted ASC.
        // change to DESC
        return Store.searchCategories({ orderBy: { [sortCol]: 'DESC' } })
      } else if (sortDir === 'DESC') {
        // already sorted DESC, remove sorting.
        return Store.searchCategories({ orderBy: { id: 'DESC' } })
      }
    }

    const sortCol = getSortCol(),
      sortDir = getSortDir(sortCol)

    return (
      <div
        className={`table-col-sorter ${col?.sort_col ? 'is-sortable' : ''} ${
          sortDir ? 'sort-' + sortDir.toLowerCase() : ''
        }`}
      >
        <label>{col.label}</label>
        <div className="sorter">
          <div onClick={() => onSortUp()} className="up">
            <MDBIcon icon="angle-up" />
          </div>
          <div onClick={() => onSortDown()} className="down">
            <MDBIcon icon="angle-down" />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div id="KbCategoriesListComponent">
      <MDBRow>
        <MDBCol>
          <h6>Knowledgebase Categories</h6>
        </MDBCol>
        <MDBCol>
          <MDBBtn
            className="kbc-new"
            color="info"
            disabled={
              Store.isSaving ||
              Store.isFetching ||
              Store.isLoading ||
              !!Store.Article
            }
            onClick={() => Store.startNewCategory()}
          >
            <MDBIcon icon="plus" />
            &nbsp;New
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <UIDataTable
        hideSearch
        hidePerPage
        hidePagination
        columns={tableColumns.map((col) => ({
          label: _renderColumnHeader(col),
          field: col.field,
        }))}
        rows={(Object.values(Store?.Categories || {}) || []).map(
          (Category, idx) => ({
            c_name: Category.get('c_name'),
            action: (
              <MDBBtnGroup className="kbc-action-btns">
                <MDBBtn
                  className="kbc-edit"
                  disabled={
                    Store.isFetching || Store.isSaving || !!Store.Category
                  }
                  type="button"
                  onClick={() => Store.editCategory(Category)}
                >
                  <MDBIcon icon="edit" />
                  &nbsp;Edit
                </MDBBtn>
              </MDBBtnGroup>
            ),
          })
        )}
        pagination={Store.articlesPagination}
        onPageSelect={Store.searchCategories}
        onSearch={Store.searchCategories}
        onChange={Store.searchCategories}
      />
    </div>
  )
}

export default observer(KbCategoriesList)
