import { observer } from 'mobx-react-lite'
import React from 'react'
import { MDBRow, MDBCol, MDBAlert, MDBBtn } from 'mdbreact'
import ContractingPacket from './../ContractingPacket/ContractingPacket.component'
import ACMStore from './../AgentContractManager.store'
import UserProfileService from './../../../shared/services/UserProfile.service'
import ContentHtml from './../../content/ContentHtml/ContentHtml.component'
import CartResetter from './../CartResetter/CartResetter.component'

import './ContractingCheckout.scss'

const ContractingCheckout = ({ onCheckingOutToggle }) => (
  <div id="ContractingCheckoutComponent">
    <MDBRow>
      <MDBCol size="12">
        <ContentHtml contentId="103" />
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol size="12">
        {ACMStore.packets.map((packetId, idx) => (
          <ContractingPacket
            key={`contracting-packet-${idx}`}
            packetId={packetId}
            Packet={ACMStore?.Packet}
            onCheckingOutToggle={onCheckingOutToggle}
          />
        ))}
      </MDBCol>
    </MDBRow>
    <CartResetter
      cartId={ACMStore.Cart?.id()}
      Cart={ACMStore.Cart}
      onCheckingOutToggle={onCheckingOutToggle}
    />
  </div>
)

export default observer(ContractingCheckout)
