import BaseModelInstance from './base.model'
import { Report as ModelDefinition } from './model-definitions'
import ReportHistoryFactory from './../factories/report-history.factory'

class Report extends BaseModelInstance {
  afterSet = (key, value) => {}

  #children = {
    history: [],
  }

  history = (useApi) => {
    if (useApi === true) {
      return {
        all: async () =>
          (await ReportHistoryFactory.search({
            search: { report_id: this.id() },
            pagination: false,
          })) || [],
        search: async ({ search, pagination }) =>
          (await ReportHistoryFactory.search({
            search: search && typeof search === 'object' ? search : undefined,
            pagination:
              pagination && typeof pagination === 'object'
                ? pagination
                : typeof pagination === 'boolean'
                ? pagination
                : undefined,
          })) || [],
        create: async (data) =>
          await ReportHistoryFactory.create({
            ...(data ? data : {}),
            report_id: this.id(),
          }),
      }
    }

    return Array.isArray(this.#children?.history) ? this.#children.history : []
  }

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }
}

export default Report
