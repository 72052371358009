import React from 'react'
import {observer} from 'mobx-react-lite'
import ConfirmDeleteModalComponent from './../../../../components/adminShared/ConfirmDeleteModal/ConfirmDeleteModal.component'
import {UIInput, UIInputButton, UIInputToggleSwitch} from './../../../../components/forms/form-fields'
import {MDBIcon,MDBBtn,MDBAlert} from 'mdbreact'
import Store from './../../store';

import './PackageFeatureTable.component.scss'

const PackageFeatureTable = () => 
{

	const onConfirmed = async () =>
		await Store.deleteFeature()

	const renderEditFeature = (Feature) =>
	{
		if (Store.EditFeature)
			return <td></td>

		return (
				<td>
					<MDBBtn className='edit-feature-btn' onClick={() => Store.EditFeature = Feature}><MDBIcon icon='edit' /></MDBBtn>
				</td>
			)
	}

	const renderDeleteFeature = (Feature) =>
	{
		return (
				<td>
					<MDBBtn className='delete-feature-btn' onClick={() => Store.DeleteFeature = Feature}><MDBIcon icon='trash' /></MDBBtn>
				</td>
			)
	}

	const renderToggleSwitch = (Package,Feature) =>
	{
		const isSaving = Store.isSaving(Package,Feature);

		return <UIInputToggleSwitch 
									width='80'
									disabled={isSaving}
									labelOn={isSaving?<i className='fa fa-spin fa-spinner'></i>:'Yes'}
									labelOff={isSaving?<i className='fa fa-spin fa-spinner'></i>:'No'}
									value={Store.packageIncludesFeature(Package,Feature)}
									onChange={evt => {
										if (evt)
											Store.addPackageFeature(Package,Feature);
										else 
											Store.removePackageFeature(Package,Feature);
									}}
								/>
	}

	const renderTableRow = (Feature, idx) =>
	{
		return (
				<tr key={`admin-package-feature-${Feature.id()}-${idx}`}>
					{
						Store.EditFeature && parseInt(Store.EditFeature.id()) === parseInt(Feature.id())
							?	<>
									<td>
										<UIInput 
											name='edit_position'
											required
											value={Feature.get('position')}
											onChange={evt => {
												const position = evt.target.value;
												if (position === '' || (!isNaN(position) && position > 0))
													Feature.set('position', position);
											}}
										/>
									</td>
									<td>
						        <UIInputButton
						          input={
												<UIInput 
													name='edit_feature'
													required
													value={Feature.get('feature')}
													onChange={evt => Feature.set('feature', evt.target.value)}
												/>
						          }
						          btnPosition={'right'}
						          button={<MDBBtn className='save-feature-btn' disabled={(`${Feature.get('feature')}`.trim().length === 0 || isNaN(Feature.get('position')) || Feature.get('position') < 1)} onClick={() => Store.saveFeature(Feature)}><MDBIcon icon='save' /></MDBBtn>}
						        />
									</td>
								</>
							: <>
									<td>{Feature.get('position')}</td>
									<td>{Feature.get('feature')}</td>
								</>
					}
					{
						renderEditFeature(Feature)
					}
					{
						Store.Packages.map((Package, idx) => {
							return (
									<td key={`package-feature-toggle-${Package.id()}-${idx}`}>{renderToggleSwitch(Package, Feature)}</td>
								)
						})
					}
					{
						renderDeleteFeature(Feature)
					}
				</tr>
			)
	}

	const renderTableHead = () =>
	{
		return (
				<thead>
					<tr>
						<th>Order</th>
						<th>Feature</th>
						<th>Edit</th>
						<th colSpan={Store.Packages.length}>Agent Packages</th>
						<th>Delete</th>
					</tr>
					<tr>
						<th></th>
						<th></th>
						<th></th>
						{
							Store.Packages.map((Package, idx) => {
								return (
										<th key={`package-feature-${Package.id()}-${idx}`}>{Package.get('pkg_name')}</th>
									)
							})
						}
						<th></th>
					</tr>
					<tr>
						<th></th>
						<th></th>
						<th></th>
						{
							Store.Packages.map((Package, idx) => {
								return (
										<th key={`package-pkg-descriptor-${Package.id()}-${idx}`}>{Package.get('pkg_descriptor')}</th>
									)
							})
						}
						<th></th>
					</tr>
				</thead>
			)
	}

	const renderTableBody = () =>
	{
		return (
				<tbody>{Store.Features.map(renderTableRow)}</tbody>
			)
	}

	const renderTableFoot = () =>
	{
		return (
				<tfoot>
					<tr>
						<td></td>
						<td>
							<div className='create-feature'>
				        <UIInputButton
				          input={
										<UIInput 
											name='create_feature'
											label='Create New Agent Package Feature'
											required
											value={Store.NewFeature.get('feature')}
											onChange={evt => Store.NewFeature.set('feature', evt.target.value)}
										/>
				          }
				          btnPosition={'right'}
				          button={<MDBBtn className='save-feature-btn' disabled={!Store.NewFeature || `${Store.NewFeature.get('feature')}`.trim().length === 0} onClick={() => Store.createFeature(Store.NewFeature)}><MDBIcon icon='save' /></MDBBtn>}
				        />
							</div>
						</td>
						<td></td>
						{
							Store.Packages.map((Package, idx) => {
								return (
										<td key={`package-price-descriptor-${Package.id()}-${idx}`}>{Package.get('price_descriptor')}</td>
									)
							})
						}
						<td></td>
					</tr>
					<tr>
						<td></td>
						<td></td>
						<td></td>
						{
							Store.Packages.map((Package, idx) => {
								return (
										<td key={`package-price-can-enroll-${Package.id()}-${idx}`}><MDBAlert color={(parseInt(Package.get('can_enroll')) === 1 || Package.get('can_enroll') === true ? 'success' : 'danger')}>{(parseInt(Package.get('can_enroll')) === 1 || Package.get('can_enroll') === true ? 'Can Enroll' : 'Can NOT Enroll')}</MDBAlert></td>
									)
							})
						}
						<td></td>
					</tr>
				</tfoot>
			)
	}

	const renderTable = () =>
	{
		return (
				<div className='table-wrapper'>
					<h5>Package Features</h5>
					<table width='100%' className='feature-table'>
						{renderTableHead()}
						{renderTableBody()}
						{renderTableFoot()}
					</table>
				</div>
			)
	}


	return (
			<>
        <div id="PackageFeatureTableComponent" className={Store.isFetching?'is-loading':''}>
					{renderTable()}
				</div>
	      <ConfirmDeleteModalComponent
	        confirm={Store.DeleteFeature && Store.DeleteFeature?.get('feature')}
	        onConfirm={onConfirmed}
	        shouldEnable={true}
	        onCancel={() => Store.DeleteFeature = null}
	      />
			</>
		)
}
// params={expire}
export default observer(PackageFeatureTable)