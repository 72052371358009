import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import AppConstants from './../../../../constants/appConstants'
import {
  UIInput,
  UIDatePickerInput,
  UISelect,
} from './../../../../components/forms/form-fields'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact'
import Store from './../../shared/Enrollment.store'
import { commissionLevels } from '../../../../constants/commisionLevels'

import './IntakeFormProfile.scss'

const MAX_DOB = moment().subtract(18, 'years').toDate()

const FIELDS = [
  'u_phone',
  'u_birthday',
  'u_address1',
  'u_city',
  'u_state',
  'u_zip',
  'commission_id',
  'u_enroll_source',
]

const IntakeFormProfile = ({ upline, onComplete }) => {
  const [submitted, setSubmitted] = useState(false)
  const commission_levels = [
    commissionLevels.agent,
    commissionLevels.seniorBenefitsCounselor,
  ].concat(
    Store.upline?.commission_id === 130
      ? [commissionLevels.region, commissionLevels.district]
      : Store.upline?.commission_id === 129
      ? [commissionLevels.district]
      : []
  )

  const reqEnrollSourceDetail = () =>
    parseInt(Store.intake.u_enroll_source) === 1

  const getFields = () => {
    if (reqEnrollSourceDetail())
      return FIELDS.concat(['u_enroll_source_detail'])
    return FIELDS
  }

  const onKeyDown = (evt) => {
    try {
      if (evt.key.toLowerCase() === 'enter' && !submitted) onSubmit()
    } catch (ex) {}
  }

  const onSubmit = (evt) => {
    if (evt) evt.preventDefault()

    if (!submitted) setSubmitted(true)

    if (Store.isValid(getFields())) onComplete('IntakeFormPackageSelection')

    return false
  }

  return (
    <MDBContainer
      tag="form"
      fluid
      id="IntakeFormProfileComponent"
      className="intake-form"
      onSubmit={onSubmit}
      noValidate
    >
      <MDBRow>
        <MDBCol size="12" className="stage-heading">
          <h3>
            <strong>Profile Details</strong>
          </h3>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="12" sm="6">
          <UIInput
            label="Phone Number"
            name="u_phone"
            type="text"
            onChange={(evt) => (Store.intake.u_phone = evt.target.value)}
            required={true}
            value={Store.intake.u_phone}
            rules={{ required: true, minLength: 2, maxLength: 255 }}
            showValidity={submitted}
            onValidityChange={(validity) => (Store.validity.u_phone = validity)}
            onKeyDown={onKeyDown}
          />
        </MDBCol>
        <MDBCol size="12" sm="6">
          <UIDatePickerInput
            label="Date of Birth"
            name="u_birthday"
            id="enroll_u_birthday"
            onChange={(evt) => (Store.intake.u_birthday = evt.target.value)}
            required={true}
            showYearDropdown
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            dateFormat="MM/dd/yyyy"
            maxDate={MAX_DOB}
            selected={Store.intake.u_birthday}
            rules={{ required: true }}
            showValidity={submitted}
            onValidityChange={(validity) =>
              (Store.validity.u_birthday = validity)
            }
            onKeyDown={onKeyDown}
          />
        </MDBCol>
        <MDBCol size="12" sm="6">
          <UIInput
            label="Street Address"
            name="u_address1"
            type="text"
            onChange={(evt) => (Store.intake.u_address1 = evt.target.value)}
            required={true}
            value={Store.intake.u_address1}
            rules={{ required: true, minLength: 2, maxLength: 255 }}
            showValidity={submitted}
            onValidityChange={(validity) =>
              (Store.validity.u_address1 = validity)
            }
            onKeyDown={onKeyDown}
          />
        </MDBCol>
        <MDBCol size="12" sm="6">
          <UIInput
            label="City"
            name="u_city"
            type="text"
            onChange={(evt) => (Store.intake.u_city = evt.target.value)}
            required={true}
            value={Store.intake.u_city}
            rules={{ required: true, minLength: 2, maxLength: 255 }}
            showValidity={submitted}
            onValidityChange={(validity) => (Store.validity.u_city = validity)}
            onKeyDown={onKeyDown}
          />
        </MDBCol>
        <MDBCol size="12" sm="6" md="4">
          <UISelect
            label="State"
            name="u_state"
            options={AppConstants.States.map((s) => ({
              value: s.id,
              text: s.name,
              checked: Store.intake.u_state === s.id,
            }))}
            onChange={(evt) => {
              if (
                typeof evt.target.value !== 'undefined' &&
                Store.intake.u_state !== evt.target.value
              )
                Store.intake.u_state = evt.target.value
            }}
            required={true}
            value={Store.intake.u_state}
            rules={{ required: true }}
            showValidity={submitted}
            onValidityChange={(validity) => (Store.validity.u_state = validity)}
          />
        </MDBCol>
        <MDBCol size="12" sm="6" md="4">
          <UIInput
            label="Zip Code"
            name="u_zip"
            type="text"
            onChange={(evt) => (Store.intake.u_zip = evt.target.value)}
            required={true}
            value={Store.intake.u_zip}
            rules={{ required: true, minLength: 2, maxLength: 255 }}
            showValidity={submitted}
            onValidityChange={(validity) => (Store.validity.u_zip = validity)}
            onKeyDown={onKeyDown}
          />
        </MDBCol>
        <MDBCol size="12" sm="6" md="4">
          <UISelect
            label="Code: Internal Use Only"
            name="commission_id"
            options={Store.commissionLevels
              .filter((s) => commission_levels.includes(s.com_code))
              .map((s) => ({
                value: `${s.id}`,
                text: `${s.com_name} (${s.com_code})`,
                checked:
                  parseInt(Store.intake.commission_id) === parseInt(s.id),
              }))}
            onChange={(evt) => {
              if (
                typeof evt.target.value !== 'undefined' &&
                `${Store.intake.commission_id}` !== `${evt.target.value}`
              )
                Store.intake.commission_id = `${evt.target.value}`
            }}
            required={true}
            value={Store.intake.commission_id}
            rules={{ required: true }}
            showValidity={submitted}
            onValidityChange={(validity) =>
              (Store.validity.commission_id = validity)
            }
          />
        </MDBCol>
        <MDBCol size="12" sm="6">
          <UISelect
            label="How did you hear about us?"
            name="u_enroll_source"
            options={Store.RecruitingLeadSources.map((R) => ({
              value: R.id(),
              text: R.get('ls_name'),
              checked:
                parseInt(Store.intake.u_enroll_source) === parseInt(R.id()),
            }))}
            onChange={(evt) => {
              if (
                typeof evt.target.value !== 'undefined' &&
                Store.intake.u_enroll_source !== evt.target.value
              )
                Store.intake.u_enroll_source = evt.target.value
            }}
            required={true}
            value={Store.intake.u_enroll_source}
            rules={{ required: true }}
            showValidity={submitted}
            onValidityChange={(validity) =>
              (Store.validity.u_enroll_source = validity)
            }
          />
        </MDBCol>
        {reqEnrollSourceDetail() ? (
          <MDBCol size="12" sm="6">
            <UIInput
              label="Please tell us how you heard of us"
              name="u_enroll_source_detail"
              type="text"
              onChange={(evt) =>
                (Store.intake.u_enroll_source_detail = evt.target.value)
              }
              required={true}
              value={Store.intake.u_enroll_source_detail}
              rules={{ required: true, minLength: 2, maxLength: 255 }}
              showValidity={submitted}
              onValidityChange={(validity) =>
                (Store.validity.u_enroll_source_detail = validity)
              }
              onKeyDown={onKeyDown}
            />
          </MDBCol>
        ) : (
          <></>
        )}
        {Store.upline?.is_team_recruiter ? (
          <MDBCol size="12" sm="6">
            <UISelect
              label="Select Upline"
              name="upline_override"
              options={Store.leaders.map((L) => ({
                value: L.id,
                text: [L.u_fname, L.u_lname].join(' ').trim(),
                checked: Store.intake.upline_override === L.id,
              }))}
              onChange={(evt) => {
                if (
                  typeof evt.target.value !== 'undefined' &&
                  Store.intake.upline_override !== evt.target.value
                )
                  Store.intake.upline_override = evt.target.value
              }}
              required={true}
              value={Store.intake.upline_override}
              rules={{ required: true }}
              showValidity={submitted}
              onValidityChange={(validity) =>
                (Store.validity.upline_override = validity)
              }
            />
          </MDBCol>
        ) : (
          <></>
        )}
      </MDBRow>
      <MDBRow className="intake-action-btns">
        <MDBCol size="12" md="6">
          <MDBBtn
            type="button"
            className="intake-action-btn"
            onClick={() => onComplete('IntakeFormIdentity')}
          >
            <MDBIcon icon="angle-double-left" size="lg" />
            &nbsp;Back to Account Profile
          </MDBBtn>
        </MDBCol>
        <MDBCol size="12" md="6">
          <MDBBtn
            type="submit"
            className={
              'intake-action-btn ' +
              (!Store.isValid(FIELDS) ? 'appear-disabled' : '')
            }
          >
            Next: Agent Packages&nbsp;
            <MDBIcon icon="angle-double-right" size="lg" />
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(IntakeFormProfile)
