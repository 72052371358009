import BaseModelFactory from './base.factory'
import PointOverrideService from './../services/PointOverride.service'
import PointOverride from './../models/point-override.model'

class PointOverrideFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: PointOverrideService, Model: PointOverride }
    )
  static findById = async (id) =>
    await this._findBy(
      { id },
      { Service: PointOverrideService, Model: PointOverride }
    )
  static findAll = async () =>
    await this._findAllBy(
      {},
      { Service: PointOverrideService, Model: PointOverride }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: PointOverrideService,
      Model: PointOverride,
    })
}

export default PointOverrideFactory
