import React from "react";
import { observer } from 'mobx-react-lite'
import { MDBBtn, MDBIcon } from "mdbreact";
import { create } from 'react-modal-promise'
import ChangeLogModal from './../modals/ChangeLogModal.component'
import Store from './../store'

import './VersionIndicator.component.scss';

const ChangeLogModalPromise = create(ChangeLogModal);

const VersionIndicator = () => 
{
	const openModal = () => 
    ChangeLogModalPromise({}).then(() => {
    	Store.acknowledge()
    	window.location.href = '/clear.html'
    }).catch(() => {
    	Store.acknowledge()
    });

  const safetyNet = Store.safetyNet === true,
  			unacknowledgedExist = Store.unacknowledgedExist === true

  return (
			<div id="VersionIndicatorComponent">
        {global?.appVersion ? (
          <small className="version-indicator">
            USABG.com Version {global.appVersion}
          </small>
        ) : (
          <></>
        )}
        <MDBBtn block className={`view-features-btn ${safetyNet?'safety-net-exist':''} ${unacknowledgedExist?'new-features-exist':''}`} onClick={() => openModal()}>
        	<div>View Latest Features</div>
        	{
        		safetyNet === true
        			? <div className='new-features'>New Version Available&nbsp;<MDBIcon icon="exclamation-triangle" /></div>
        			:  <></>
        	}
        	{
        		unacknowledgedExist
        			? <div className='new-features'>New Features Released&nbsp;<MDBIcon icon="exclamation-triangle" /></div>
        			: <></>
        	}
        </MDBBtn>
      </div>
		)
}

export default observer(VersionIndicator);