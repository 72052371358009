const determineOutput = (output) => {
  output = output ? `${output}`.trim().toLowerCase() : null
  if (output && ['json', 'object', 'array', 'boolean'].indexOf(output) < 0)
    output = null
  return output
}

const isString = (str) => typeof str === 'string'

const isObject = (str) => typeof str === 'object'

const isArray = (str) => Array.isArray(str)

const isUndefined = (str) => typeof str === 'undefined'

const isJson = (str) => {
  try {
    return typeof JSON.parse(str) === 'object'
  } catch (ex) {
    return false
  }
}

const valueToArray = (value) => {
  value = valueToObject(value)
  if (value && isObject(value))
    return isArray(value) ? value : [value]

  return []
}

const valueToObject = (value) => {
  if (typeof value === 'object') return value

  if (isString(value) && isJson(value)) return JSON.parse(value)

  return {}
}

const valueToJsonString = (value) => {
  if (isString(value))
    return isJson(value) ? value : JSON.stringify(value)

  return JSON.stringify(isUndefined(value) ? {} : value)
}

const valueToString = (value) =>
  `${typeof value === 'undefined' || value === null ? '' : value}`

const valueToBoolean = (value) =>
  !!(['1', 'true'].indexOf(valueToString(value).toLowerCase()) > -1)

const getValue = (value, output) => {
  switch ((output = determineOutput(output))) {
    case 'array':
      return valueToArray(value)

    case 'object':
      return valueToObject(value)

    case 'json':
      return valueToJsonString(value)

    case 'string':
      return valueToString(value)

    case 'boolean':
      return valueToBoolean(value)

    default:
      return value
  }
}

export default getValue