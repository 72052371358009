import {BehaviorSubject} from 'rxjs';

const loadingLabelSubject = new BehaviorSubject(null);
const activeSubject = new BehaviorSubject(false);

const setLabel = label => loadingLabelSubject.next(label);

const LoadingService = {
	getLabel: 										() => loadingLabelSubject,
	getActive: 										() => activeSubject,
	setLabel: 										setLabel,
	isOn:  												() => !!activeSubject.getValue(),
	on:  													() => {activeSubject.next(true)},
	off:  												() => {activeSubject.next(false)}
};

export default LoadingService;
