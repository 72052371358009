import React, { Component } from 'react'
import {
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCollapse,
  MDBBadge,
} from 'mdbreact'
import { Subscription } from 'rxjs'
import TrainingsPageService from './../../shared/TrainingsPage.service'
import ConfirmDeleteModalComponent from '../../../../components/adminShared/ConfirmDeleteModal/ConfirmDeleteModal.component'

import './TrainingList.scss'
import UserProfileService from '../../../../shared/services/UserProfile.service'

class TrainingList extends Component {
  state = {
    pillColors: {
      HEALTH: 'primary',
      SENIOR: 'danger',
      LIFE: 'success',
    },
    trainings: [],
    attended: [],
    deleteAttended: null,
    isOpen: false,
    quizes: [],
  }

  __subscriptions$ = new Subscription()

  componentDidMount() {
    this.__subscriptions$.add(
      TrainingsPageService.getTrainings().subscribe((trainings) =>
        this._setTrainings(trainings)
      )
    )
    this.__subscriptions$.add(
      TrainingsPageService.getTrainingAttended().subscribe((attended) =>
        this._setHasAttended(attended)
      )
    )
    this.__subscriptions$.add(
      TrainingsPageService.getUserTrainingQuizes().subscribe((quizes) =>
        this.setState({ quizes: quizes })
      )
    )
  }

  componentWillUnmount() {
    this.__subscriptions$.unsubscribe()
  }

  _canDeleteAttended = () => {
    return UserProfileService.isA(
      ['system-admin', 'agency-owner', 'internal-admin', 'internal-staff'],
      true
    )
  }

  _setTrainings = (trainings) => {
    trainings = Array.isArray(trainings)
      ? trainings.filter(
          (training) =>
            this.props.type &&
            parseInt(training.training_type_id) === parseInt(this.props.type.id)
        )
      : []
    this.setState({ trainings: trainings })
  }

  _setHasAttended = (attended) => {
    attended =
      attended && Array.isArray(attended)
        ? attended
            .filter((a) => a.training_id)
            .map((a) => parseInt(a.training_id))
        : []
    this.setState({ attended })
  }

  _hasAttended = (training) =>
    this.state.attended.indexOf(parseInt(training.id)) > -1 ||
    this.state.quizes?.findIndex(
      (quiz) => quiz?.training_id === training.id && quiz?.passed === 1
    ) > -1

  _openTraining = (item) => {
    if (item?.training_link) {
      if (item?.training_output === 'external_link')
        window.open(item.training_link)
      else TrainingsPageService.setViewVideo(item)
    }
  }

  _renderLabel = (item) => {
    switch (item && item?.training_output) {
      case 'external_link':
        return (
          <>
            <MDBIcon icon="external-link-alt" className="training-icon" />
            &nbsp;{item?.training_name}
          </>
        )
      default:
        return (
          <>
            <MDBIcon icon="play" className="training-icon" />
            &nbsp;{item?.training_name}
          </>
        )
    }
  }

  _deleteCompleted = async (id) => {
    if (!!id) {
      try {
        await TrainingsPageService.deleteAttended(id)
        await TrainingsPageService.deleteUserTrainingQuiz(id)
      } catch (error) {
        console.error(
          'An error occurred while deleting completed status: ',
          error
        )
      }
    }

    this.setState({ deleteAttended: null })
  }

  _renderPills = (training) => {
    if (training.training_filters) {
      if (
        training.training_filters.spec &&
        training.training_filters.spec.length
      ) {
        return training.training_filters.spec.map((item) => {
          return (
            <MDBBadge pill color={this.state.pillColors[item]}>
              {item}
            </MDBBadge>
          )
        })
      }
    }
    return <></>
  }

  render() {
    return (
      <MDBCard
        className={
          'TrainingListComponent ' +
          (this.state.isOpen ? 'is-open ' : '') +
          (this.props.canEdit ? 'can-edit ' : '') +
          (this.props.canEdit && this.state.trainings.length === 0
            ? 'can-trash '
            : '')
        }
      >
        <MDBCardHeader color="indigo">
          <div className="label">
            {this.props.type && this.props.type.training_type_name}
          </div>
          <div
            className={`cursor-pointer ${
              this.state.isOpen ? 'caret-close-btn' : 'caret-open-btn'
            }`}
            onClick={() => this.setState({ isOpen: !this.state.isOpen })}
          >
            <MDBIcon icon={`angle-down`} />
          </div>
          <div
            className="edit-btn"
            onClick={() => TrainingsPageService.setEditType(this.props.type)}
          >
            <MDBIcon icon="edit" />
          </div>
          <div
            className="trash-btn"
            onClick={() => TrainingsPageService.deleteType(this.props.typeId)}
          >
            <MDBIcon icon="trash" />
          </div>
        </MDBCardHeader>
        <MDBCardBody>
          <MDBCollapse
            isOpen={
              this.state.trainings.filter((item) =>
                item.training_name
                  .toLowerCase()
                  .includes(this.props.inputValue.toLowerCase())
              ).length > 0 || this.state.isOpen
            }
          >
            <MDBListGroup>
              {this.state.trainings.map((item, i) =>
                (!!this.props.inputValue &&
                  item.training_name
                    .toLowerCase()
                    .includes(this.props?.inputValue.toLowerCase())) ||
                this.state.isOpen ? (
                  <MDBListGroupItem
                    className={
                      (this._hasAttended(item) ? 'has-attended' : '') +
                      (this._canDeleteAttended() ? ' can-delete-attended ' : '')
                    }
                    key={`${i}-${item.id}`}
                  >
                    <div
                      className="label"
                      onClick={() => this._openTraining(item)}
                      key={`training-${i}-${item.id}`}
                    >
                      {this._renderLabel(item)}
                      <div className="training-pills">
                        {this._renderPills(item)}
                      </div>
                    </div>
                    {this.props.canEdit || this._canDeleteAttended() ? (
                      <div
                        className="attended-tag"
                        onClick={() =>
                          !this.state.deleteAttended
                            ? this.setState({
                                deleteAttended: {
                                  id: item.id,
                                  name: item.training_name,
                                },
                              })
                            : this.setState({ deleteAttended: null })
                        }
                      >
                        COMPLETED <MDBIcon icon="times" />
                      </div>
                    ) : (
                      <div className="attended-tag">COMPLETED </div>
                    )}
                    <div
                      className="edit-btn"
                      onClick={() => TrainingsPageService.setEditVideo(item)}
                    >
                      <MDBIcon icon="edit" />
                    </div>
                    <div
                      className="trash-btn"
                      onClick={() =>
                        TrainingsPageService.deleteTraining(item.id)
                      }
                    >
                      <MDBIcon icon="trash" />
                    </div>
                  </MDBListGroupItem>
                ) : (
                  <div key={`training-list-closed-${i}-${item.id}`}></div>
                )
              )}
            </MDBListGroup>
          </MDBCollapse>
        </MDBCardBody>
        {this.state.deleteAttended ? (
          <ConfirmDeleteModalComponent
            confirm={this.state.deleteAttended?.name}
            onConfirm={() =>
              this._deleteCompleted(this.state.deleteAttended?.id)
            }
            shouldEnable={true}
            onCancel={() => this.setState({ deleteAttended: null })}
          />
        ) : (
          ''
        )}
      </MDBCard>
    )
  }
}

export default TrainingList
