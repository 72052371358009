import React, { Component } from 'react'
import SignatureAgentBenefitsTable from './inc/SignatureAgentBenefits.table'

/*
 * NOTE:
 * This page is a duplicate of `SignatureAgentBenefits`
 * page WITHOUT any authenticated resources.  This page
 * is used for internal USABG purposes.
 */

class SigAgentBenefits extends Component {
  render() {
    return (
      <main className="adminStyle mainSection">
        <SignatureAgentBenefitsTable insecure={true} />
      </main>
    )
  }
}

export default SigAgentBenefits
