function getGuard(name, guards) {
  return (guards || [])
    .filter(
      (Guard) =>
        (typeof Guard === 'function' && Guard.name === name) ||
        (typeof Guard === 'object' &&
          (Guard.constructor.name === name ||
            Guard.constructor.name === `guards_${name}`))
    )
    .shift()
}

function matches(pathname) {
  return (
    !!(this.path && this.path === pathname) ||
    !!(this.pattern && this.pattern.test(pathname))
  )
}

function requiresAuth() {
  const Guard = getGuard('RequiresAuthGuard', this.guards)
  return !!Guard && Guard?.isRequired
}

function authorize() {
  const _override = () => {
    for (let i in this.overrides) {
      if (typeof this.overrides[i] === 'object') {
        if (
          this.overrides[i]?.override === 'function' &&
          this.overrides[i].override() === true
        )
          return true
      } else if (typeof this.overrides[i] === 'function') {
        if (new this.overrides[i]().override() === true) return true
      }
    }
  }

  const _authorize = () => {
    let i, result
    for (i in this.guards) {
      if (typeof this.guards[i] === 'object') {
        if (
          typeof this.guards[i]?.authorize === 'function' &&
          typeof (result = this.guards[i].authorize()) === 'boolean'
        )
          if (result === false) return false
      } else if (typeof this.guards[i] === 'function') {
        if (typeof (result = new this.guards[i]().authorize()) === 'boolean')
          if (result === false) return false
      }
    }
  }

  return _authorize() === false ? _override() === true : true
}

class RouteDefinition {
  #_config = {}
  #_guards = []
  #_overrides = []
  #_readonly = []
  #_name = null
  #_path = null
  #_pattern = null
  #_component = null

  get config() {
    return this.#_config
  }

  set config(config) {
    this.#_config = config && typeof config === 'object' ? config : {}
  }

  get guards() {
    return this.#_guards
  }

  set guards(guards) {
    const _g = [],
      _o = []

    ;(Array.isArray(guards) ? guards : []).forEach((g) => {
      if (
        `${g?.name || g?.constructor?.name}`.substr(-5).toLowerCase() ===
        'guard'
      )
        _g.push(g)
      else if (
        `${g?.name || g?.constructor?.name}`.substr(-8).toLowerCase() ===
        'override'
      )
        _o.push(g)
    })

    this.#_guards = _g
    this.#_overrides = _o
  }

  get readonly() {
    return this.#_readonly
  }

  set readonly(readonly) {
    this.#_readonly = readonly
  }

  get overrides() {
    return this.#_overrides
  }

  get name() {
    return this.#_name
  }

  set name(name) {
    this.#_name = name ? `${name}`.trim() : null
  }

  get path() {
    return this.#_path
  }

  set path(path) {
    this.#_path = path ? `${path}`.trim() : null
  }

  get pattern() {
    return this.#_pattern
  }

  set pattern(pattern) {
    this.#_pattern = pattern ? pattern : null
  }

  get component() {
    return this.#_component
  }

  set component(component) {
    this.#_component =
      component && typeof component === 'function' ? component : null
  }

  get isValid() {
    return true
  }

  get redirectsTo() {
    return this.#_config && this.#_config?.redirectsTo
  }
}

RouteDefinition.prototype.matches = matches
RouteDefinition.prototype.requiresAuth = requiresAuth
RouteDefinition.prototype.authorize = authorize

export default RouteDefinition
