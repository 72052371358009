import React from 'react'

const UIInputButton = ({ input, button, btnPosition, btnContent, onClick }) => {
  btnPosition =
    btnPosition && `${btnPosition}`.trim().toLowerCase() === 'left'
      ? 'left'
      : 'right'

  button = !button ? (
    <button
      type="button"
      className="ui-input-btn"
      onClick={() => (typeof onClick === 'function' ? onClick : () => {})()}
    >
      {btnContent}
    </button>
  ) : (
    button
  )

  return (
    <div className={`ui-input-button btn-${btnPosition}`}>
      {btnPosition === 'left' ? (
        <div className="ui-ib-button ui-ib-button-left">{button}</div>
      ) : (
        <></>
      )}
      <div className="ui-ib-input">{input}</div>
      {btnPosition === 'right' ? (
        <div className="ui-ib-button ui-ib-button-right">{button}</div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default UIInputButton
