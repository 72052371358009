import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import PaginationControls from './../PaginationControls/PaginationControls.component'
import { UISelect, UIInput, UIInputButton } from './../../forms/form-fields'
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBIcon,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from 'mdbreact'

import './UIDataTable.component.scss'

const PER_PAGE_OPTS = [50, 100, 150, 250, 500, 1000]
const CAN_EXPORT = false

const DataTable = ({
  rows,
  columns,
  isFetching,
  isExporting,

  onPageSelect,
  pagination,

  onSearch,
  hideSearch,
  hidePerPage,
  hidePagination,
}) => {
  const [searchTerm, setSearchTerm] = useState(''),
    rowsCount = 0

  onSearch = onSearch && typeof onSearch === 'function' ? onSearch : () => {}
  onPageSelect =
    onPageSelect && typeof onPageSelect === 'function' ? onPageSelect : () => {}

  return (
    <div
      className={`ui-datatable-component ui-datatable-wrapper ${
        isFetching ? 'is-fetching' : ''
      }`}
    >
      <MDBContainer fluid className="table-filters">
        <MDBRow>
          <MDBCol size="12" md={CAN_EXPORT ? '5' : '6'}>
            {hidePerPage ? (
              <></>
            ) : (
              <UISelect
                label="Number of Rows"
                disabled={!!isFetching || isExporting}
                options={PER_PAGE_OPTS.concat([parseInt(rowsCount)])
                  .filter((n) => n && !isNaN(n))
                  .filter((val, idx, self) => self.indexOf(val) === idx)
                  .map((entry) => ({
                    value: `${entry}`,
                    text: `${entry}`,
                    checked:
                      pagination &&
                      pagination?.per_page &&
                      parseInt(pagination.per_page) === parseInt(entry),
                  }))}
                search
                name="per_page"
                value={pagination && pagination?.per_page}
                getValue={(v) => {
                  v = Array.isArray(v) ? v.shift() : v
                  if (!v) return
                  let updPagination = {}
                  if (!!pagination?.per_page !== !!v) {
                    // should we go back to page 1?
                    updPagination = { ...(pagination || {}), per_page: v }
                    if (
                      !isNaN((pagination || {})?.total) &&
                      pagination.total > 0
                    ) {
                      if (
                        !isNaN((pagination || {})?.page) &&
                        pagination.page >= 1
                      )
                        if (pagination.total < v * pagination.page)
                          updPagination.page = 0
                    }

                    onPageSelect({ pagination: updPagination })
                  } else if (
                    !!pagination?.per_page &&
                    !!v &&
                    parseInt(pagination.per_page) !== parseInt(v)
                  ) {
                    // should we go back to page 1?
                    updPagination = { ...(pagination || {}), per_page: v }
                    if (
                      !isNaN((pagination || {})?.total) &&
                      pagination.total > 0
                    ) {
                      if (
                        !isNaN((pagination || {})?.page) &&
                        pagination.page >= 1
                      )
                        if (pagination.total < v * pagination.page)
                          updPagination.page = 0
                    }

                    onPageSelect({ pagination: updPagination })
                  }
                }}
              />
            )}
          </MDBCol>
          <MDBCol size={CAN_EXPORT ? '8' : '12'} md={CAN_EXPORT ? '5' : '6'}>
            {hideSearch ? (
              <></>
            ) : (
              <UIInputButton
                input={
                  <UIInput
                    disabled={!!isFetching || isExporting}
                    name="report_search"
                    label="Search Report"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={(evt) => {
                      let submit = false
                      try {
                        submit = `${evt.key}`.toLowerCase() === 'enter'
                      } catch (ex) {}

                      if (submit && searchTerm && `${searchTerm}`.length >= 3)
                        onSearch({
                          search: { _global: searchTerm },
                          pagination: { ...(pagination || {}), page: 0 },
                        })
                      else if (
                        submit &&
                        (!searchTerm || `${searchTerm}`.length === 0)
                      )
                        onSearch({ search: { _global: searchTerm } })
                    }}
                  />
                }
                button={
                  <MDBBtn
                    disabled={searchTerm && `${searchTerm}`.length < 3}
                    onClick={() => {
                      if (searchTerm && `${searchTerm}`.length >= 3)
                        onSearch({ search: { _global: searchTerm } })
                      else if (!searchTerm || `${searchTerm}`.length === 0)
                        onSearch({ search: { _global: searchTerm } })
                    }}
                    disabled={!!isFetching || isExporting}
                    className="btn btn-sm"
                  >
                    <MDBIcon icon="search" />
                  </MDBBtn>
                }
                btnPosition={'right'}
              />
            )}
          </MDBCol>
          {CAN_EXPORT ? (
            <MDBCol size="4" md="2">
              <MDBBtn
                block
                disabled={!!isFetching || isExporting}
                className="btn btn-sm export-btn"
                onClick={() => {
                  /*this.props.onExport*/
                }}
              >
                Export Results&nbsp;
                {isExporting ? (
                  <i className="fa fa-spin fa-spinner" />
                ) : (
                  <MDBIcon icon="download" />
                )}
              </MDBBtn>
            </MDBCol>
          ) : (
            <></>
          )}
        </MDBRow>
      </MDBContainer>
      <div className="table-visibility-wrapper">
        <MDBTable>
          <MDBTableHead columns={columns}></MDBTableHead>
          <MDBTableBody rows={rows}></MDBTableBody>
        </MDBTable>
      </div>
      <MDBContainer fluid className="table-pagination">
        <MDBRow>
          <MDBCol size="12">
            {hidePagination ? (
              <></>
            ) : (
              <PaginationControls
                pagination={pagination}
                onPageSelect={(p) => onPageSelect({ pagination: p })}
              />
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  )
}

export default observer(DataTable)
