import React from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'
import { Subscription, of } from 'rxjs'
import SubmitSalesService from './../../shared/SubmitSales.service'
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardFooter,
  MDBListGroup,
  MDBListGroupItem,
} from 'mdbreact'

import './RecentApplications.scss'

class RecentApplications extends React.Component {
  // BehaviorSubject Subscriptions.
  __subscriptions$ = new Subscription()

  state = {
    recentSales: [],
  }

  /*
   * Component Accessor Methods ----------------------
   *
   */

  /*
   * Component Public Methods ------------------------
   *
   */

  /*
   * Component Private Methods -----------------------
   *
   */
  _handleErr = (msg, err) => {
    if (err) console.error(err)
    if (msg) toast.error(msg, { position: toast.POSITION.TOP_RIGHT })

    return of(undefined)
  }

  _viewSelectedAV = async (avId) => {
    SubmitSalesService.setActiveSale(avId)
  }

  /*
   * Component Event Methods -------------------------
   *
   */
  componentDidMount() {
    this.__subscriptions$.add(
      SubmitSalesService.getRecentSales().subscribe((recentSales) =>
        this.setState({ recentSales })
      )
    )

    SubmitSalesService.fetchRecentSales()
  }

  componentWillUnmount() {
    this.__subscriptions$.unsubscribe()
  }

  renderAvDate(av) {
    let { av_date } = av || {}

    if (av_date && typeof av_date === 'string')
      av_date = av_date.trim().split(/T| /).shift()

    return (
      <span className="text-center">
        {moment(av_date || av?.av_date).format('MMM Do')} - {av.av_client}
      </span>
    )
  }

  render() {
    toast.configure()

    const { recentSales } = this.state

    return (
      <div id="RecentApplications">
        <MDBCard>
          <MDBCardHeader color="indigo">
            <h5 className="white-text mt-2 text-center">
              Recent
              <br />
              Applications
            </h5>
          </MDBCardHeader>
          <MDBCardBody>
            <MDBListGroup>
              {recentSales && recentSales.length ? (
                recentSales.map((item, i) => {
                  return (
                    <MDBListGroupItem className="text-center" key={i}>
                      {this.renderAvDate(item)}
                      <br />
                      <span className="text-center">{item.av_carrier} </span>
                      <br />
                      <span className="text-center">
                        <strong>(Pts: {item.av_points})</strong>
                      </span>
                      <br />
                      <span className="text-center">
                        <span
                          className="text-info cursor-pointer"
                          onClick={() => this.props.goTo(item.av_id)}
                        >
                          View
                        </span>
                      </span>
                    </MDBListGroupItem>
                  )
                })
              ) : (
                <p className="text-center mt-3">
                  <strong>No Recent Sales</strong>
                </p>
              )}
            </MDBListGroup>
          </MDBCardBody>
          <MDBCardFooter>
            <MDBListGroup>
              <MDBListGroupItem className="text-center">
                <a href="/reporting/my-production">View Past Sales</a>
              </MDBListGroupItem>
            </MDBListGroup>
          </MDBCardFooter>
        </MDBCard>
      </div>
    )
  }
}

export default RecentApplications
