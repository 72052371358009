import BaseModelInstance from './base.model'
import { UsersCarrierContract as ModelDefinition } from './model-definitions'
import UsersCarrierContractService from './../services/UsersCarrierContract.service'

class UsersCarrierContract extends BaseModelInstance {
  afterSet = (key, value) => {}

  contractType = null

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }

  getDeliveryAttempts = async () =>
  {
    try {
      return await UsersCarrierContractService.getDeliveryAttempts(this.id())
    }
    catch (ex) {
      console.log(`Failed to fetch previous delivery attempts.  ${ex}`)
      return [];
    }
  }
}

export default UsersCarrierContract
