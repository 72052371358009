import React, { useState } from "react";
import {observer} from 'mobx-react-lite'
import { TextField } from "@material-ui/core";
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import { Elements, useStripe, useElements, CardExpiryElement, CardCvcElement, CardNumberElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeInput from './StripeInput.component';
import env from "./../../../environments/environment";
import SubscriptionService from './../../../shared/services/Subscription.service';
import PaymentService from "./../../../shared/services/Payment.service";
import UserMetaService from "../../../shared/services/UserMeta.service";
import UserProfileService from "../../../shared/services/UserProfile.service";

import "./PaymentMethodForm.scss";


const PaymentMethodForm = (props) =>
{
	const stripe 						= 		loadStripe(env.integrations.stripe.key);
  return (
    <Elements stripe={stripe}>
      <CheckoutForm formType={props.formType} userId={props.userId} customerId={props.customerId} onSuccess={props.onSuccess} onError={props.onError} billingDetails={props?.billingDetails} userIp={props?.userId} />
    </Elements>
  );
};


function CheckoutForm (props)
{
  const [isPaymentLoading, setPaymentLoading] = useState(false),
  			[hasPaid, setHasPaid] = useState(false),
  			stripe 						= 		useStripe(),
  			elements 					= 		useElements(),
  			clientSecret 			=			SubscriptionService.getSubscriptionData().client_secret,

  			className 				=			(props.formType && props.formType === 'SaveCard' ? 'payment-method' : 'checkout-form'),

  			onSuccess 				=			(props.onSuccess && typeof props.onSuccess === 'function' ? props.onSuccess : () => {}),
  			onError 					=			(props.onError && typeof props.onError === 'function' ? props.onError : () => {}),

			updateUserPaymentMethodMetaData = async () => {
				const metaData = await UserMetaService.search({
					search: {
					  user_id: UserProfileService.getUserId(),
					  meta_key: `billing---update-payment-method`,
					},
				  }) 
				if (metaData?.models?.length === 0) {
					await UserMetaService.store({
						user_id: UserProfileService.getUserId(),
						meta_key: 'billing---update-payment-method',
						meta_value: "0"
					  })
				} else {
					await UserMetaService.update(metaData?.models[0].id, {
						meta_value: "0",
					})
				}
			},

  			payMoney 					= 		async (e) => {

															    e.preventDefault();
															    if (!stripe || !elements) { return; }

															    setPaymentLoading(true);
															    const paymentResult = SubscriptionService.confirmPayment(stripe, elements.getElement(CardNumberElement), props?.billingDetails, props?.userIp);
															    paymentResult
															    		.then(
															    				() => {
															    					setHasPaid(true);
															    					document.getElementById("payment-error").style.display = "none";
															    					document.getElementById("payment-success").innerHTML = "Payment Complete.  Setting up your account.";
															    					onSuccess();
															    				}, 
															    				err => document.getElementById("payment-error").innerHTML = err)
															    		.finally(() => setPaymentLoading(false));
															  },

		    saveCard 					= 		async (e) => {

															    e.preventDefault();
															    if (!stripe || !elements) { return; }

															    setPaymentLoading(true);

															    PaymentService
															    		.storePaymentMethod(props.userId, props.customerId, stripe, elements.getElement(CardNumberElement))
															    		.then(res => {
															    				setPaymentLoading(false);
																				updateUserPaymentMethodMetaData();
																	    		document.getElementById("payment-error").style.display = "none";
																	    		document.getElementById("payment-success").innerHTML = "Payment method successfully updated.";
																					elements.getElement(CardNumberElement).clear();
																					elements.getElement(CardExpiryElement).clear();
																					elements.getElement(CardCvcElement).clear();
																					onSuccess();
															    			},
															    			rej => {
															    				setPaymentLoading(false);
															    				onError(rej);
															    				document.getElementById("payment-error").innerHTML = rej;
															    			})
															    		.catch(err => {
															    				setPaymentLoading(false);
															    				onError(err);
															    				document.getElementById("payment-error").innerHTML = err;
															    			});
														      };

	return (
	    <form
	    	id="PaymentMethodFormComponent"
	      style={{display: "block", width: "100%"}}
	      className={className}
	      onSubmit = {props.formType && props.formType === 'SaveCard' ? saveCard : payMoney}
	    >
	    	<MDBRow>

	    		<MDBCol size="12" className="cc-input-wrapper">
	          <TextField
	              label="Credit Card Number"
	              name="ccnumber"
	              id="cardNumber"
	              variant="outlined"

	              required
	              fullWidth
	              InputProps={{
	                inputProps: {component: CardNumberElement},
	                inputComponent: StripeInput
	              }}
	              InputLabelProps={{ shrink: true }}
	          />
	        </MDBCol>

	    		<MDBCol size="6" className="cc-input-wrapper">
	          <TextField
	              label="Expiration Date"
	              name="ccexp"
	              id="cardExpiry"
	              variant="outlined"
	              required
	              fullWidth
	              InputProps={{
	                inputProps: {component: CardExpiryElement},
	                inputComponent: StripeInput
	              }}
	              InputLabelProps={{ shrink: true }}
	          />
	        </MDBCol>

	    		<MDBCol size="6" className="cc-input-wrapper">
	          <TextField
	              label="CVC"
	              name="cvc"
	              id="cardCvc"
	              variant="outlined"
	              required
	              fullWidth
	              InputProps={{
	                inputProps: {component: CardCvcElement},
	                inputComponent: StripeInput
	              }}
	              InputLabelProps={{ shrink: true }}
	          />
	        </MDBCol>

	    		<MDBCol size="12">
					{
						props.formType && props.formType === 'SaveCard' ?
							<MDBBtn onClick={saveCard} color="primary" className="submit-button" disabled={isPaymentLoading}>
								{isPaymentLoading?"Saving ...":"Save Card"}
							</MDBBtn> :
							<MDBBtn onClick={payMoney} className="submit-button" disabled={isPaymentLoading || !clientSecret || hasPaid}>
								{isPaymentLoading?"Processing ...":"Complete Payment"}
							</MDBBtn>
					}
	      		<br />
	      		<div id="payment-error"></div>
	      		<div id="payment-success"></div>
	        </MDBCol>
	    	</MDBRow>
			</form>
		);
}

export default observer(PaymentMethodForm);
