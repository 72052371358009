import AdminApi from "./../../api/admin-api/admin-api";
import { BehaviorSubject } from 'rxjs';

const Messages = new BehaviorSubject([]);

const getMessages = (page, perPage, divisionId) =>
{
	AdminApi
			.getMessages({page, per_page: perPage, division_id: divisionId?divisionId:null})
			.then(result => Messages.next(result && result.data && result.data.data ? {messages: result.data.data, pagination: result.data.pagination} : null));

	return 												Messages;
}

const getHomepageMessages = async (page, perPage, divisionId) =>
	{
		return new Promise((resolve, reject) => {
			AdminApi
					.getMessages({page, per_page: perPage, division_id: divisionId?divisionId:null})
					.then(result => {
							if (result && result.data && result.data.data)
								return 	resolve(result && result.data && result.data.data ? {messages: result.data.data, pagination: result.data.pagination} : null)
							return reject("ERROR: No messages found");
						})
					.catch(error => reject("ERROR: "+error.message));
		});
	}

const getMessageById = async (msgId) =>
{
	return new Promise((resolve, reject) => {
		AdminApi
				.getMessageById(msgId)
				.then(result => {
						if (result && result.data && result.data.data)
							return 	resolve(result.data.data)
						return reject("ERROR: No messages found by the selected ID.");
					})
				.catch(error => reject("ERROR: "+error.message));
	});
}


const searchMessages = async (search, divisionId) =>
{
	return new Promise((resolve, reject) => {
		AdminApi
				.getMessages({search: search ? search : null, division_id: divisionId?divisionId:null})
				.then(result => {
						if (result && result.data && result.data.data) {
							Messages.next(result && result.data && result.data.data ? {messages: result.data.data, pagination: result.data.pagination} : null);
							return resolve({messages: result.data.data, pagination: result.data.pagination})
						}
						return reject("ERROR: No messages found.");
					})
				.catch(error => reject("ERROR: "+error.message));
	});
}

const getMessageCategories = async (divisionId) =>
{
	return new Promise((resolve, reject) => {
		AdminApi
				.getMessageCategories({division_id: divisionId?divisionId:null})
				.then(result => {
						if (result && result.data && result.data.data)
							return 	resolve(result.data.data.map(m => m.category))
						return reject("ERROR: No messages found.");
					})
				.catch(error => reject("ERROR: "+error.message));
	});
}

const createMessage = async (data) => 
{
  const formData 					= 		new FormData();
  Object.keys(data).forEach(key => formData.append(key, data[key]));

	return new Promise((resolve, reject) => {
    AdminApi
    		.insertDataAccTable(formData)
    		.then(result => resolve(result.data.data))
    		.catch(error 	=> 	reject(error.message));
	});
}

const updateMessage = async (id, data) =>
{
	if (!id || isNaN(parseInt(id)))
		return 											Promise.reject("Invalid message ID supplied.");

	data.id 								=			parseInt(id);
  const formData 					= 		new FormData();
  Object.keys(data).forEach(key => formData.append(key, data[key]));

	return new Promise((resolve, reject) => {
    AdminApi
    		.insertDataAccTable(formData)
    		.then(result => resolve(result.data.data))
    		.catch(error 	=> 	reject(error.message));
	});
}

const setDivisionOverride = async (divisionId) =>
{
	getMessages(0, 10, divisionId);
}

const MessageService = {
	getMessages: 									getMessages,
	getHomepageMessages:                        getHomepageMessages,
	searchMessages:  							searchMessages,
	getMessageById: 							getMessageById,
	getMessageCategories: 				getMessageCategories,
	createMessage: 								createMessage, 
	updateMessage:  							updateMessage,
	setDivisionOverride:  				setDivisionOverride,
	clearMessages: 								() => { Messages.next([]); }
};

export default MessageService;