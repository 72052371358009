import React, { useEffect } from 'react'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
import { cart as CART_CONFIG } from './../../../constants/contracting.constants'
import { MDBBtn, MDBAlert, MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import { UIInput } from './../../forms/form-fields'
import UserProfileService from './../../../shared/services/UserProfile.service'
import ACMStore from './../AgentContractManager.store'
import { toast } from 'react-toastify'

import './CartResetter.component.scss'

function isAdmin() {
  try {
    if (Array.isArray(CART_CONFIG.resetter.permitted)) {
      const usertypes = CART_CONFIG.resetter.permitted.filter(
        (n) => n && typeof n === 'string'
      )
      if (usertypes.length) return UserProfileService.isA(usertypes, true)
    }
  } catch (ex) {}

  return false
}

function isSuperAdmin() {
  if (isAdmin()) {
    try {
      if (Array.isArray(CART_CONFIG.resetter?.destructive?.permitted)) {
        const usertypes = CART_CONFIG.resetter.destructive.permitted.filter(
          (n) => n && typeof n === 'string'
        )
        if (usertypes.length) return UserProfileService.isA(usertypes, true)
      }
    } catch (ex) {}

    return (
      ['stevew@usabg.com', 'melvin@usabg.com'].indexOf(
        `${UserProfileService.get('u_email', true)}`.toLowerCase().trim()
      ) > -1
    )
  }

  return false
}

function isClearable(Cart) {
  return ACMStore.CartItems.every(
    (CartItem) =>
      (CartItem.get('item_status') === 'DRAFT' ||
        CartItem.get('item_status') === 'ABANDONED') &&
      `${CartItem.get('cart_id')}` === `${Cart.id()}`
  )
}

function isOnboarding() {
  return (
    parseInt(UserProfileService.get('has_onboarded')) === 0 &&
    parseInt(UserProfileService.get('usertype_id')) !== 149
  )
}

class CRStore {
  reason = ''
  cartId = null
  Cart = null
  isAdmin = false
  isSuperAdmin = false
  isClearable = false
  isOnboarding = false

  constructor() {
    makeAutoObservable(this)
  }

  evaluate(cartId) {
    const getCart = () =>
        cartId &&
        `${this.cartId}` !== `${cartId}` &&
        `${ACMStore.Cart?.id()}` === `${cartId}`
          ? ACMStore.Cart
          : false,
      Cart = getCart()
    this.Cart = null
    if (!Cart) return

    this.cartId = Cart.id()
    this.Cart = Cart

    this.isAdmin = isAdmin()
    this.isSuperAdmin = isSuperAdmin()
    this.isClearable = isClearable(Cart)
    this.isOnboarding = isOnboarding()
  }
}

const Store = new CRStore()

const CartResetter = ({ cartId, onCheckingOutToggle }) => {
  const notify = async (pass, msg) => {
    toast[pass ? 'success' : 'error'](msg, {
      position: toast.POSITION.TOP_RIGHT,
    })

    return new Promise((resolve, reject) => {
      setTimeout(resolve, 4000)
    })
  }

  const closeOutCart = async (resetOnboarding) => {
    try {
      await Store.Cart?.close(true, {
        requested_by: UserProfileService.getUserId(true),
        reason: Store.reason,
        reset_onboarding: !!resetOnboarding,
      })
      await notify(true, 'Contracting cart has been cleared')
      window.location.reload(false)
      return true
    } catch (ex) {
      await notify(false, `Contracting cart COULD NOT be cleared (${ex}).`)
      console.error(`${ex}`)
      return false
    }
  }

  const clearOutCart = async () => {
    await Promise.all(
      ACMStore.CartItems.map(async (CartItem) => {
        const isNew =
            typeof CartItem?.isNew === 'function' && !!CartItem.isNew(),
          contractId =
            !isNew && !isNaN(CartItem?.get('user_contract_id'))
              ? parseInt(CartItem?.get('user_contract_id'))
              : null,
          Contract =
            contractId > 0
              ? ACMStore.Contracts.filter(
                  (Contract) => parseInt(Contract.id()) === contractId
                ).shift()
              : null

        // can not delete started contracts.
        if (Contract) {
          if (Contract.get('disposition') !== 'unsent') {
            alert(
              'Can not delete this contract cart item since the contract has already been started.'
            )
            return
          }
        }

        ACMStore.remove('CartItem', CartItem)
        await CartItem.delete()

        if (Contract) await Contract.delete()
        return CartItem
      })
    )

    if (ACMStore.Cart.get('cart_status') !== 'OPEN') {
      ACMStore.Cart.set('cart_status', 'OPEN')
      await ACMStore.Cart.save()
    }

    ACMStore.PacketItems = []
    ACMStore.CartItems = []
  }

  const renderBtn = () => {
    const adminCloseBtn = () => (
      <MDBCol size="12">
        <MDBAlert color="warning" className="m-1">
          <MDBRow>
            <MDBCol size="12" md="8" className="text--center fs--125rem">
              <strong>No contracts have been started in this cart.</strong>
              <br />
              It can be cleared out so the agent can start contracting over.
            </MDBCol>
            <MDBCol size="12" md="4">
              <MDBBtn block color="danger" onClick={() => clearOutCart()}>
                Clear out & Reset Agent's cart
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBAlert>
      </MDBCol>
    )

    const superAdminCloseBtn = () => {
      if (canResetOnboarding() || (!canResetOnboarding() && !Store.isClearable))
        return (
          <MDBCol key="super-admin-close-cart-btn" size="12">
            <MDBAlert color="warning" className="m-1">
              <MDBRow>
                <MDBCol size="12" md="8" className="text--center fs--125rem">
                  <strong>
                    This cart should not be closed under normal circumstances.
                  </strong>
                  <br />
                  <div className="content-wrapper">
                    <UIInput
                      label="Detailed reason to force close cart"
                      name="reason"
                      onChange={(e) => (Store.reason = e.target.value)}
                      value={Store.reason}
                      required
                    />
                  </div>
                </MDBCol>
                <MDBCol size="12" md="4">
                  {canResetOnboarding() ? (
                    <MDBBtn
                      disabled={!Store.reason || `${Store.reason}`.length < 20}
                      block
                      color="danger"
                      onClick={() => closeOutCart(true)}
                    >
                      <strong>
                        Reset Stage 2 Onboarding & Clear Agent's cart
                      </strong>
                    </MDBBtn>
                  ) : (
                    <></>
                  )}
                  <MDBBtn
                    disabled={!Store.reason || `${Store.reason}`.length < 20}
                    block
                    color="danger"
                    onClick={() => closeOutCart(false)}
                  >
                    <strong>Force clear out & Reset Agent's cart</strong>
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBAlert>
          </MDBCol>
        )

      return false
    }

    const hideCartBtn = () => (
      <MDBCol key="admin-hide-cart-btn" size="12">
        <MDBAlert className="m-1">
          <MDBRow>
            <MDBCol size="12" md="8" className="text--center fs--125rem">
              Agent started contracts. The cart items must be completed before
              changing specialization or carriers.
            </MDBCol>
            <MDBCol size="12" md="4" className="text--center fs--125rem">
              <MDBBtn
                block
                onClick={() => {
                  if (
                    onCheckingOutToggle &&
                    typeof onCheckingOutToggle === 'function'
                  )
                    onCheckingOutToggle(false)
                }}
              >
                Hide Contracting Cart
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBAlert>
      </MDBCol>
    )

    const canResetOnboarding = () =>
      Store.isOnboarding &&
      Store.isSuperAdmin &&
      parseInt(UserProfileService.get('usertype_id')) !== 149

    const buttons = {
      superAdminCloseBtn: Store.isSuperAdmin ? superAdminCloseBtn() : false,
      hideCartBtn:
        (!canResetOnboarding() && !Store.isClearable && Store.isSuperAdmin) ||
        (!Store.isClearable && Store.isAdmin)
          ? hideCartBtn()
          : false,
    }

    if (Object.values(buttons).every((n) => n === false)) {
      if (Store.isClearable && Store.isAdmin)
        return <MDBRow>{adminCloseBtn()}</MDBRow>
    } else if (Object.values(buttons).length)
      return <MDBRow>{Object.values(buttons)}</MDBRow>

    return <></>
  }

  useEffect(() => {
    Store.evaluate(cartId)
  }, [cartId])

  return (
    <MDBContainer id="CartResetterComponent">
      <MDBRow>
        <MDBCol size="12">{renderBtn()}</MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(CartResetter)
