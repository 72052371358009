function filterByPriority (carriers, specializations, userType, priority) 
{
	let s;

	return carriers.filter(c => {
		for (s in specializations) {
			if (!specializations[s]) continue;
			if (c.priorities.hasOwnProperty(s) && parseInt(c.priorities[s][`is_${userType}_${priority}`]) === 1)
				return true;
		}
		return false;
	});
}

export default function filterCarriers (carriers, specializations, userType, table) 
{
	if (table === 'core')
		return filterByPriority(carriers, specializations, userType, table);

	if (table === 'preferred')
		return filterByPriority(carriers, specializations, userType, table);

	if (table === 'additional') {
		let prioritized = filterByPriority(carriers, specializations, userType, 'core').concat(filterByPriority(carriers, specializations, userType, 'preferred')).map(c => parseInt(c.id));
		return carriers.filter(c => prioritized.indexOf(c.id) < 0);
	}

	return [];
}