import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact'
import AdminContentEditPopupComponent from './../../../components/adminShared/AdminContentEditPopupComponent'
import AgencyContentStore from './../../../shared/stores/agencyContent.store'

import './ContentHtmlCard.scss'

const ContentHtmlCard = ({
  contentId,
  onLoad,
  fullHeight,
  appendContent,
  editor,
}) => {
  const content =
      AgencyContentStore.content[contentId] &&
      AgencyContentStore.content[contentId].content,
    name =
      AgencyContentStore.content[contentId] &&
      AgencyContentStore.content[contentId].page_name

  // Agency-Content Selector ---------------------------------------
  const { fetchByContentId } = AgencyContentStore
  useEffect(() => {
    fetchByContentId(contentId)
      .then((res) => (typeof onLoad === 'function' ? onLoad(res) : null))
      .catch(() => (typeof onLoad === 'function' ? onLoad(false) : null))
  }, [fetchByContentId, contentId, onLoad])
  // ---------------------------------------------------------------

  return (
    <MDBCard
      className="ContentHtmlCardComponent"
      style={fullHeight ? { height: '100%' } : {}}
    >
      <MDBCardHeader>
        <div className="bg--usabg--blue">
          <div dangerouslySetInnerHTML={{ __html: name }} />
        </div>
      </MDBCardHeader>
      <MDBCardBody>
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          style={{ padding: '1rem' }}
        />
        {appendContent &&
        ['object', 'string'].includes(typeof appendContent) ? (
          <>{appendContent}</>
        ) : (
          <></>
        )}
        <AdminContentEditPopupComponent
          type="RTE"
          color="white"
          pageId={contentId}
          color={editor && editor?.iconColor}
          iconPosition={(editor && editor?.iconPosition) || 'top-right'}
          iconStyle={
            (editor && editor?.iconStyle) || {
              transform: 'translate(-3px, 3px)',
            }
          }
          callback={(id) => fetchByContentId(id, true)}
        />
      </MDBCardBody>
    </MDBCard>
  )
}

export default observer(ContentHtmlCard)
