import RestApi from './../../api/rest-api-client'
const srvcEndpoint = '/user_contracting_carts'
export default {
  search: async (req, opts) => RestApi.request('GET', srvcEndpoint, req, opts),
  get: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, id, req, opts),
  store: async (data, opts) =>
    RestApi.request('POST', srvcEndpoint, data, opts),
  update: async (id, data, opts) =>
    RestApi.request('PUT', srvcEndpoint, id, data, opts),
  delete: async (id, opts) => RestApi.request('DELETE', srvcEndpoint, id, opts),

  preview: async (cart_id, carrier_ids) =>
    RestApi.request(
      'POST',
      srvcEndpoint,
      { query: { carrier_ids } },
      { endpoint: `/user_contracting_carts/preview/${cart_id}` }
    ),
  checkout: async (cart_id, item_group) =>
    RestApi.request(
      'POST',
      srvcEndpoint,
      { query: { item_group } },
      { endpoint: `/user_contracting_carts/checkout/${cart_id}` }
    ),
  open: async (cart_id, item_group, link) =>
    RestApi.request(
      'POST',
      srvcEndpoint,
      { query: { item_group, link } },
      { endpoint: `/user_contracting_carts/open/${cart_id}` }
    ),
  complete: async (cart_id, item_group) =>
    RestApi.request(
      'POST',
      srvcEndpoint,
      { query: { item_group } },
      { endpoint: `/user_contracting_carts/complete/${cart_id}` }
    ),
  close: async (cart_id, request) =>
    RestApi.request('POST', srvcEndpoint, request || {}, {
      endpoint: `/user_contracting_carts/close/${cart_id}`,
    }),
  expireAdobeAgreement: async (cart_id, agreement_id, request) =>
    RestApi.request('POST', srvcEndpoint, request || {}, {
      endpoint: `/user_contracting_carts/expire/${cart_id}/${agreement_id}`,
    }),
}
