import React, { Component } from 'react'
import {
  MDBCardHeader,
  MDBCardBody,
  MDBCard,
  MDBSelect,
  MDBInput,
  MDBBtn,
} from 'mdbreact'
import { toast } from 'react-toastify'
import { Subscription } from 'rxjs'
import TrainingsPageService from './../../shared/TrainingsPage.service'
import StorageService from './../../../../shared/services/Storage.service'
import usabgUniversityLogoImg from './../../assets/logo.usabg-university.jpg'
import ContentHtml from '../../../../components/content/ContentHtml/ContentHtml.component'

import './TrainingValidator.scss'

class TrainingValidator extends Component {
  state = {
    submitting: false,
    code: '',
    training_id: null,
    training_type_id: null,
    errorsExist: false,
    trainings: [],
    trainingTypes: [],
  }

  _errors = {}
  __subscriptions$ = new Subscription()

  componentDidMount() {
    if (Array.isArray(this.props?.trainings)) {
      this.setState({
        trainings: this.props.trainings,
        training_id: this.props.isOnboarding
          ? this.props.trainings[0].id
          : null,
      })
    } else {
      this.__subscriptions$.add(
        TrainingsPageService.getTrainings().subscribe((trainings) => {
          trainings =
            trainings && trainings.filter((training) => training.training_code)
          this.setState({ trainings })
        })
      )
    }
    if (this.props?.trainingTypes && Array.isArray(this.props?.trainingTypes)) {
      this.setState({ trainingTypes: this.props.trainingTypes })
    } else {
      this.__subscriptions$.add(
        TrainingsPageService.getTrainingTypes().subscribe((trainingTypes) => {
          this.setState({ trainingTypes })
        })
      )
    }
  }

  componentWillUnmount() {
    this.__subscriptions$.unsubscribe()
  }

  _onChange = (event) => {
    if (`${this.state[event.target.name]}` !== `${event.target.value}`)
      this.setState({ [event.target.name]: event.target.value })
  }

  _renderErrorField = (field) => {
    if (field && this._errors.hasOwnProperty(field) && this.state.errorsExist)
      return <span className="error-msg">{`${this._errors[field]}`}</span>
    return <></>
  }

  _isValid = () => {
    this._errors = {}

    if (!this.state.code) this._errors.code = 'Required'
    else if (`${this.state.code}`.trim().length !== 6)
      this._errors.code = 'Invalid length'

    if (isNaN(parseInt(this.state.training_id)))
      this._errors.training_id = 'Required'

    if (Object.keys(this._errors).length) {
      this.setState({ errorsExist: true })
      return false
    }

    this.setState({ errorsExist: false })
    return true
  }

  _onSubmit = async () => {
    if (!this._isValid()) return

    this.setState({ submitting: true })

    let result = null
    try {
      result = await TrainingsPageService.submitCode(
        this.state.training_id,
        this.state.code
      )

      if (!result) {
        this._errors.submit = 'Incorrect Training Code'
        return this.setState({ errorsExist: true, submitting: false })
      }

      toast.success('Training code saved.', {
        position: toast.POSITION.TOP_RIGHT,
      })
      if (typeof this.props?.onSuccess === 'function') this.props.onSuccess()
      StorageService.clear('user---required-alerts')
      window.setTimeout(
        () => this.setState({ training_id: null, code: '' }),
        500
      )
    } catch (ex) {
      console.log('training err: ', ex)
      this._errors.submit = ex
      return this.setState({ errorsExist: true, submitting: false })
    }

    this.setState({ submitting: false })
    return !!false
  }

  render() {
    const {
      submitting,
      trainings,
      training_id,
      code,
      trainingTypes,
      training_type_id,
    } = this.state
    const trainingTypeIds = trainings.map((t) => t.training_type_id)
    const trainingTypeOptions = trainingTypes
      .filter((tt) => trainingTypeIds.includes(tt.id))
      .map((tt) => ({
        text: tt.training_type_name,
        value: `${tt.id}`,
        checked: parseInt(this.state.training_type_id) === parseInt(tt.id),
      }))
    return (
      <MDBCard id="TrainingValidator">
        <MDBCardHeader color="white" className="usabg-university-logo">
          {this.props.isOnboarding ? (
            <ContentHtml contentSlug="onboarding-training-instruction" />
          ) : (
            <img src={usabgUniversityLogoImg} alt="usabg-university-logo" />
          )}
        </MDBCardHeader>
        <MDBCardBody>
          {trainingTypeOptions.length <= 1 ? (
            <></>
          ) : (
            <div className="form-group">
              <MDBSelect
                label="Training Type"
                search
                options={trainingTypeOptions}
                name="training_type"
                value={training_type_id}
                getValue={(val) =>
                  this._onChange({
                    target: { name: 'training_type_id', value: val[0] },
                  })
                }
                required
              />
              {this._renderErrorField('training_type_id')}
            </div>
          )}
          {trainingTypeOptions.length > 1 && !training_type_id ? (
            <></>
          ) : (
            <div className="form-group">
              <MDBSelect
                label="Training Video"
                search
                options={trainings
                  .filter((t) =>
                    training_type_id
                      ? parseInt(t.training_type_id) ===
                        parseInt(training_type_id)
                      : true
                  )
                  .map((training) => ({
                    text: training.training_name,
                    value: `${training.id}`,
                    checked:
                      parseInt(this.state.training_id) ===
                      parseInt(training.id),
                  }))}
                name="is_manager"
                value={training_id}
                getValue={(val) =>
                  this._onChange({
                    target: { name: 'training_id', value: val[0] },
                  })
                }
                required
              />
              {this._renderErrorField('training_id')}
            </div>
          )}
          <div className="form-group">
            <MDBInput
              label="Enter Your Training Code Here"
              value={code || ''}
              getValue={(value) => this.setState({ code: value })}
            />
            {this._renderErrorField('code')}
          </div>
          <div className="submit-btn">
            <MDBBtn
              disabled={submitting}
              color="indigo"
              type="button"
              className="btn-block"
              onClick={this._onSubmit}
            >
              {submitting ? 'Submitting...' : 'Submit Code'}
            </MDBBtn>
            {this._renderErrorField('submit')}
          </div>
        </MDBCardBody>
      </MDBCard>
    )
  }
}

export default TrainingValidator
