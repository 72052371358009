import React from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import AgentEandOManager from './../../components/agents/AgentEandOManager/AgentEandOManager.component'
import { MDBContainer, MDBIcon } from 'mdbreact'

class EoPolicyPage extends React.Component {
  state = {
    from: '',
  }

  componentDidMount() {
    if (this.props?.location?.state?.from)
      this.setState({ from: this.props.location.state.from })
  }

  render() {
    return (
      <React.Fragment>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              <h2>Agent E&O Policy</h2>
              <hr />
              {this.state.from ? (
                <a
                  style={{ marginBottom: '16px' }}
                  href="/"
                  onClick={(event) => {
                    event.preventDefault()
                    this.props.history.push(this.state.from)
                  }}
                >
                  <MDBIcon icon="arrow-left" />
                  &nbsp;Go back to{' '}
                  {(this.state.from ? this.state.from.substr(1) : '')
                    .replace(/^([a-z])|[\s_-]+([a-z])/g, ($1) =>
                      $1.toUpperCase()
                    )
                    .replace(/-/g, ' ')}
                </a>
              ) : (
                <></>
              )}
              <div
                style={{
                  border: 'thin solid white',
                  borderRadius: '0.5rem',
                  backgroundColor: '#FFF',
                  padding: '10px',
                }}
              >
                <AgentEandOManager />
              </div>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export default EoPolicyPage
