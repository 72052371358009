import BaseModelFactory from './base.factory'
import LedgerService from './../services/Ledger.service'
import Ledger from './../models/ledger.model'

class LedgerFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: LedgerService, Model: Ledger }
    )
  static findById = async (id) =>
    await this._findBy({ id }, { Service: LedgerService, Model: Ledger })
  static create = (payload) =>
    this._create(payload, { Service: LedgerService, Model: Ledger })
}

export default LedgerFactory
