import BaseModelFactory from './base.factory'
import UserContractCartMetaService from './../services/UserContractCartMeta.service'
import UserContractCartMeta from './../models/user-contract-cart-meta.model'

class UserContractCartMetaFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: UserContractCartMetaService, Model: UserContractCartMeta }
    )
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: UserContractCartMetaService, Model: UserContractCartMeta }
    )
  static findAllByUserId = async (id) =>
    await this._findAllBy(
      { user_id: id },
      { Service: UserContractCartMetaService, Model: UserContractCartMeta }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: UserContractCartMetaService,
      Model: UserContractCartMeta,
    })
}

export default UserContractCartMetaFactory
