import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import {
  MDBContainer,
  MDBRow,
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from 'mdbreact'
import OnboardingStore from './../Onboarding.store'
import UserProfileService from './../../../shared/services/UserProfile.service'
import ContentHtml from './../../content/ContentHtml/ContentHtml.component'
import './OnboardingStageManager.scss'
import { toast } from 'react-toastify'

const OnboardingStageManager = ({ validity }) => {
  const { upline_fname, upline_lname, upline_email, d_name } =
      UserProfileService.getUserDetails(),
    canAdvance = OnboardingStore.canAdvance(),
    [showModal, setShowModal] = useState(false)

  const history = useHistory()

  const canComplete = () => {
    if (`${UserProfileService.get('has_onboarded')}` !== '1') {
      let validity = toJS(OnboardingStore.validity)
      return (
        validity &&
        typeof validity === 'object' &&
        OnboardingStore.getStages().length ===
          toJS(OnboardingStore.getStages())
            .map((n) => n.name)
            .filter(
              (n) => validity && validity.hasOwnProperty(n) && validity[n]
            ).length
      )
    }

    return true
  }

  const completeOnboarding = async () => {
    try {
      await OnboardingStore.complete()
      UserProfileService.set('has_onboarded', 1)

      // Update usertype_id value on localstorage manually
      UserProfileService.set('usertype_id', 92)

      setShowModal(true)
    } catch (err) {
      toast.error(err.message)
    }
  }

  const renderStageNextIcon = () => (
    <li className="stage-next">
      <div className="ind-bg">
        <MDBIcon icon="arrow-right" />
      </div>
    </li>
  )

  const renderStageIndicator = (stage) => (
    <li
      className={
        'stage-indicator ' +
        [
          OnboardingStore.validity[stage?.name] ? 'is-valid' : 'is-invalid',
          OnboardingStore.activeStage.name === stage?.name
            ? 'is-active'
            : 'is-inactive',
        ].join(' ')
      }
      onClick={() => {
        if (
          OnboardingStore.validity[stage?.name] ||
          OnboardingStore.canGoTo(stage)
        )
          OnboardingStore.setActiveStage(stage)
      }}
    >
      <div
        className={
          'ind-bg ' +
          (OnboardingStore.activeStage.name === stage?.name
            ? 'alert-primary'
            : OnboardingStore.validity[stage?.name]
            ? 'alert-success'
            : 'alert-danger')
        }
      >
        <span className="stage-name">
          {stage?.index}. {stage?.label}
        </span>
      </div>
      <div
        className={
          'stage-validity ' +
          (OnboardingStore.activeStage.name === stage?.name
            ? 'alert-primary'
            : OnboardingStore.validity[stage?.name]
            ? 'alert-success'
            : 'alert-danger')
        }
      >
        {OnboardingStore.activeStage.name === stage?.name
          ? 'current'
          : OnboardingStore.validity[stage?.name]
          ? 'complete'
          : 'incomplete'}
      </div>
      <div className="description">{stage?.description}</div>
    </li>
  )

  const renderStageIndicators = () => {
    let lis = []
    OnboardingStore.getStages().forEach((stage, idx) => {
      lis.push(renderStageIndicator(stage))
      if (idx + 1 < OnboardingStore.getStages().length)
        lis.push(renderStageNextIcon(stage))
    })

    return lis.map((li, idx) => (
      <React.Fragment key={'stage-indicator-idx-' + idx}>{li}</React.Fragment>
    ))
  }

  // const renderNextSteps = () =>
  //   canAdvance ? (
  //     <div className="can-advance">
  //       You've completed the {OnboardingStore.activeStage.label}.<br />
  //       You can now start {OnboardingStore.getNext(true)?.label}. Click Start
  //       Next Stage.
  //     </div>
  //   ) : (
  //     <div className="cant-advance">
  //       Complete the form below to finish{' '}
  //       {OnboardingStore.getReadableName(OnboardingStore.activeStage.name)}.
  //     </div>
  //   )

  const renderUpline = () => (
    <table className="current-upline-table">
      <thead>
        <tr>
          <th colSpan="2">
            Got Questions? Need Guidance? Reach Your Upline Agent Here!
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>My Upline Agent:</td>
          <td>
            {[upline_fname, upline_lname].join(' ').trim()},{' '}
            <a href={'mailto:' + upline_email}>{upline_email}</a>
          </td>
        </tr>
        <tr>
          <td>My Division: </td>
          <td>{d_name}</td>
        </tr>
      </tbody>
    </table>
  )

  const renderButtons = () => {
    const isFirst = parseInt(OnboardingStore.activeStage.index) === 1,
      isLast =
        parseInt(OnboardingStore.activeStage.index) ===
        OnboardingStore.getStages().length,
      onlyOne = toJS(OnboardingStore.getStages()).length === 1

    if (onlyOne) {
      return (
        <>
          {canComplete() ? (
            <MDBBtn
              block
              className="btn-block btn-short can-advance"
              onClick={completeOnboarding}
            >
              Complete USABG Onboarding
            </MDBBtn>
          ) : (
            <MDBBtn
              block
              className="btn-block btn-short cant-advance"
              disabled={true}
            >
              Complete{' '}
              {OnboardingStore.getReadableName(OnboardingStore.activeStage)}{' '}
              below to advance.
            </MDBBtn>
          )}
        </>
      )
    } else if (isFirst && !isLast)
      return (
        <>
          {canAdvance ? (
            <MDBBtn
              block
              className="btn-block can-advance"
              onClick={() =>
                OnboardingStore.goTo(OnboardingStore.getNext(true))
              }
            >
              Start Next Stage&nbsp;
              <MDBIcon icon="arrow-right" />
            </MDBBtn>
          ) : (
            <MDBBtn block className="btn-block cant-advance" disabled={true}>
              Complete{' '}
              {OnboardingStore.getReadableName(OnboardingStore.activeStage)}{' '}
              below to advance.
            </MDBBtn>
          )}
        </>
      )
    else if (!isFirst && isLast)
      return (
        <>
          {canComplete() ? (
            <MDBBtn
              block
              className="btn-block btn-short can-advance"
              onClick={completeOnboarding}
            >
              Complete USABG Onboarding
            </MDBBtn>
          ) : (
            <MDBBtn block className="btn-block btn-short" disabled={true}>
              You're almost done
            </MDBBtn>
          )}

          <MDBBtn
            block
            className="btn-block btn-short go-back"
            disabled={!OnboardingStore.canRewind()}
            onClick={() => OnboardingStore.goTo(OnboardingStore.getPrev(true))}
          >
            Go Back To{' '}
            {OnboardingStore.getReadableName(OnboardingStore.getPrev(true))}
          </MDBBtn>
        </>
      )

    return (
      <>
        {canAdvance ? (
          <MDBBtn
            block
            className="btn-block can-advance"
            onClick={() => OnboardingStore.goTo(OnboardingStore.getNext(true))}
          >
            Start Next Stage&nbsp;
            <MDBIcon icon="arrow-right" />
          </MDBBtn>
        ) : (
          <MDBBtn
            block
            className="btn-block btn-short cant-advance"
            disabled={true}
          >
            Complete{' '}
            {OnboardingStore.getReadableName(OnboardingStore.activeStage)} below
            to advance.
          </MDBBtn>
        )}
        <MDBBtn
          block
          className="btn-block btn-short go-back"
          disabled={!OnboardingStore.canRewind()}
          onClick={() => OnboardingStore.goTo(OnboardingStore.getPrev(true))}
        >
          Go Back To{' '}
          {OnboardingStore.getReadableName(OnboardingStore.getPrev(true))}
        </MDBBtn>
      </>
    )
  }

  return (
    <MDBContainer id="OnboardingStageManagerComponent">
      <MDBRow>
        <MDBCol size="12">
          <ul>{renderStageIndicators()}</ul>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="12" md="5" lg="6">
          {renderUpline()}
        </MDBCol>
        <MDBCol size="12" md="7" lg="6">
          {renderButtons()}
        </MDBCol>
      </MDBRow>
      <MDBModal isOpen={showModal} toggle={() => setShowModal(false)}>
        <MDBModalHeader toggle={() => setShowModal(false)}>
          You Did It!
        </MDBModalHeader>
        <MDBModalBody>
          <ContentHtml contentId="99" />
          <MDBBtn
            color="indigo"
            block
            onClick={() => history.push('/dashboard')}
          >
            Take me to the Back Office homepage.
          </MDBBtn>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  )
}

export default observer(OnboardingStageManager)
