import React from 'react'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import { toast } from 'react-toastify'
import {
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBIcon,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBBtn,
  MDBInput,
} from 'mdbreact'
import CarrierSerivce from './../../shared/services/Carrier.service'
import PaginationComponent from './../../components/adminShared/Pagination.component'

import './ServiceConsumptionExamplePage.scss'

// We use a debouncer on search inputs to prevent
// time-consuming HTTP calls on the API on every
// keypress.  This makes sure to wait 300 milliseconds
// after each keypress before invoking API request.
let timer
const debouncer = (func) => {
  if (timer) clearTimeout(timer)
  timer = setTimeout(() => func(), 300)
}

// Helper search function.
const searchCarriers = async (search, orderBy, pagination) => {
  search = typeof search !== 'object' ? {} : search
  orderBy = typeof orderBy !== 'object' ? {} : orderBy

  // If `pagination` is false when passed to the CarrierService.search()
  // method, that instructs the API to disregard any pagination and
  // to return ALL carrier records found.  If `false` IS NOT passed,
  // but no pagination object is passed either, the back-end will
  // default to 20 records.
  if (pagination !== false)
    pagination = typeof pagination !== 'object' ? {} : pagination

  // Updated or current pagination data will be included
  // in the search request response payload.
  return await CarrierSerivce.search({
    search,
    orderBy,
    pagination,
    expand: ['priority'],
  })
}

/*
 *	This function is just here to simplify this demo &
 * would not normally be used in a component.
 *
 * The `Carrier` model has many required default
 * values that are no set in the back-end.  These would
 * normally be set in the production-carrier creation
 * form.  This can be disregarded for the intent of
 * this Service Consumption demo page.
 */
const cleanUpCarrierModel = (carrier) => {
  if (
    carrier?.c_img === null ||
    typeof carrier?.c_img === 'undefined' ||
    carrier?.c_img === ''
  )
    carrier.c_img = 'img.jpg'
  if (
    carrier?.c_term_length === null ||
    typeof carrier?.c_term_length === 'undefined' ||
    carrier?.c_term_length === ''
  )
    carrier.c_term_length = 12
  if (
    carrier?.c_term_length_min === null ||
    typeof carrier?.c_term_length_min === 'undefined' ||
    carrier?.c_term_length_min === ''
  )
    carrier.c_term_length_min = 1
  if (
    carrier?.c_term_length_max === null ||
    typeof carrier?.c_term_length_max === 'undefined' ||
    carrier?.c_term_length_max === ''
  )
    carrier.c_term_length_max = 12
  if (
    carrier?.needs_approval === null ||
    typeof carrier?.needs_approval === 'undefined' ||
    carrier?.needs_approval === ''
  )
    carrier.needs_approval = 0
  if (
    carrier?.c_contracting_url === null ||
    typeof carrier?.c_contracting_url === 'undefined' ||
    carrier?.c_contracting_url === ''
  )
    carrier.c_contracting_url = 'https://google.com'
  if (
    carrier?.c_use_recruiting_number === null ||
    typeof carrier?.c_use_recruiting_number === 'undefined' ||
    carrier?.c_use_recruiting_number === ''
  )
    carrier.c_use_recruiting_number = 0
  if (
    carrier?.c_active === null ||
    typeof carrier?.c_active === 'undefined' ||
    carrier?.c_active === ''
  )
    carrier.c_active = 1
  return carrier
}

class ServiceConsumptionExamplePage extends React.Component {
  // The following state properties REQUIRE the
  // user-interface is re-rendered anytime the
  // property values change.
  state = {
    // True when carrier model is being created,
    // updated or deleted via Async API request.
    isModifying: false,

    // True when fetching carrier models from
    // Async API request.
    isSearching: true,

    // Array of carrier models in search results.
    carriers: [],

    // Individual carrier model when
    // creating or updating.
    carrier: null,

    // Boolean flag to turn on/turn off pagination
    // controls & show all carrier records.
    enablePagination: true,

    // Carrier name search term
    searchTerm: null,

    // Carrier search requests ordering.
    orderBy: { c_name: 'DESC' },
  }

  // Pagination object w/ required properties.
  // Note that this object IS NOT in the state object.
  // We leave this class property outside of the
  // state object because there is no need to
  // re-render the DOM when changing the properties
  // help within this object.
  #pagination = { page: 0, per_page: 20, total: 0 }

  // Carrier sort column accessor method.
  get sortColumn() {
    if (this.state.orderBy && Object.keys(this.state.orderBy).length)
      return Object.keys(this.state.orderBy).pop()
    return null
  }

  // Carrier sort direction accessor method.
  get sortDirection() {
    let c = this.sortColumn
    return c ? this.state.orderBy[c] : undefined
  }

  // Async method to fetch available carriers.
  #fetchCarriers = async () => {
    // If this method is called in error while
    // already searching, stop now & prevent
    // duplicate API requests.
    if (!this.state.isSearching) this.setState({ isSearching: true })

    let carrierData = {},
      search = this.state.searchTerm
        ? { c_name: `%${'' + this.state.searchTerm.trim().toLowerCase()}%` }
        : {},
      orderBy = this.state.orderBy,
      pagination = this.state.enablePagination ? this.#pagination : false

    try {
      carrierData = await searchCarriers(search, orderBy, pagination)
    } catch (ex) {
      return toast.error(`Failed to fetch Carriers!  ${ex}`, {
        position: toast.POSITION.TOP_RIGHT,
      })
      // this.setState({isSearching: false, isModifying: false});
      // return;
    }

    // If the response includes a pagination value
    // that is NOT FALSE, then update the local
    // pagination object with the results.
    if (carrierData?.pagination) this.#pagination = carrierData?.pagination

    this.setState({
      isSearching: false,
      isModifying: false,
      carriers: Array.isArray(carrierData?.models) ? carrierData?.models : [],
    })
  }

  // Carrier list sort handler.
  #onChangeSort = (column, direction) => {
    if (column !== this.sortColumn || direction !== this.sortDirection)
      this.setState({ orderBy: { [column]: direction } }, this.#fetchCarriers)
  }

  // Carrier list pagination handler.
  #onPageSelect = async (page, perPage) => {
    this.#pagination.per_page = perPage
    this.#pagination.page = page
    this.#fetchCarriers()
  }

  // Carrier form input change handler.
  #onChange = (event) =>
    this.setState({
      carrier: {
        ...this.state.carrier,
        [event.target.name]: event.target.value,
      },
    })

  // Create new (empty) carrier model (object literal).
  #createCarrier = () =>
    this.setState({
      carrier: {
        id: null,
        c_name: '',
        c_contact_name: '',
        c_contact_email: '',
      },
    })

  // Set the current `carrier` model to be modified.
  #editCarrier = async (carrier) => this.setState({ carrier })

  // Delete the passed in carrier model.
  #deleteCarrier = async (carrier) => {
    // Make sure we have an ID value to make
    // delete request.
    if (carrier?.id) {
      try {
        // Complete request to delete Carrier model
        // by ID value.  Checking in the Network pane
        // of the browser's Developer Console, you
        // will find an HTTP 204 response for success.
        // HTTP 204 indicates success without response data.
        await CarrierSerivce.delete(carrier.id)
      } catch (ex) {
        toast.error(`Failed to delete Carrier! ${ex}`, {
          position: toast.POSITION.TOP_RIGHT,
        })
        return this.setState({ isModifying: false })
      }

      toast.success(`Great! The ${carrier.c_name}'s record has been deleted.`, {
        position: toast.POSITION.TOP_RIGHT,
      })

      // Check the local carriers array for the deleted
      // carrier model.  If the carrier model is found
      // here, remove it from the array.  If the carrier
      // model is not found in the carrier array, research
      // the API.
      if (
        this.state.carriers.filter(
          (c) => parseInt(c.id) === parseInt(carrier.id)
        ).length
      )
        return this.setState({
          carrier: null,
          isModifying: false,
          carriers: this.state.carriers.filter(
            (c) => parseInt(c.id) !== parseInt(carrier.id)
          ),
        })

      this.#fetchCarriers()
    }
  }

  // Handle save / submit event.
  #onSubmit = async (event) => {
    event.preventDefault()

    // Make sure there is a valid Carrier name
    // before saving.  This is a very simple
    // form validation measure.  Before to use
    // a more complete validation process in
    // real-world components.
    if (!this.state.carrier?.c_name)
      return toast.error(`Invalid Carrier Name.`, {
        position: toast.POSITION.TOP_RIGHT,
      })

    // If the onSubmit event handler was
    // accidentally called twice, stop now to
    // prevent duplicate API calls to save or
    // create Carrier model.
    if (!this.state.isModifying) this.setState({ isModifying: true })

    let carrier = this.state.carrier,
      isNew = !carrier?.id

    /* ------------------------------------------ */
    // Prep the carrier model for saving.  This
    // step is only needed in this demo application.
    carrier = cleanUpCarrierModel(carrier)
    /* ------------------------------------------ */

    try {
      // Either STORE (create) OR UPDATE (modify) carrier models.
      // Update carrier models with an ID value.
      isNew
        ? await CarrierSerivce.store(carrier)
        : await CarrierSerivce.update(carrier.id, carrier)
    } catch (ex) {
      toast.error(`Failed to modify Carrier! ${ex}`, {
        position: toast.POSITION.TOP_RIGHT,
      })
      return this.setState({ isModifying: false })
    }

    toast.success(
      `Great! The ${carrier.c_name}'s record has been ${
        isNew ? 'created' : 'updated'
      }.`,
      { position: toast.POSITION.TOP_RIGHT }
    )

    // Search the local Carriers array for the updated
    // carrier model.  If the udpated carrier model is found
    // in the carrier array, update the model in the array
    // to prevent an unnecessary API call.  If the carrier
    // model is not found the in the carrier array, make
    // API request to search all carrier models again.
    if (
      !isNew &&
      this.state.carriers.filter((c) => parseInt(c.id) === parseInt(carrier.id))
        .length
    )
      return this.setState({
        carrier: null,
        isModifying: false,
        carriers: this.state.carriers.map((c) =>
          parseInt(c.id) === parseInt(carrier.id) ? carrier : c
        ),
      })

    this.#fetchCarriers()
  }

  // Search term change handler.
  #onSearch = async (event) => {
    let runSearch =
      `${this.state.searchTerm}`.trim().toLowerCase() !==
        `${event.target.value}`.trim().toLowerCase() ||
      (this.state.searchTerm && !event.target.value)

    if (!runSearch) return

    this.#pagination = { page: 0, per_page: 20, total: 0 }
    this.setState({ carriers: [], searchTerm: event.target.value }, () =>
      debouncer(() => this.#fetchCarriers())
    )
  }

  // Turn pagination on/off.  Turning pagination off
  // results in ALL carrier models being displayed at once.
  // This could be a time-consuming request for some
  // models where a lot of records exist.
  #togglePagination = () =>
    this.setState(
      { enablePagination: !this.state.enablePagination },
      this.#fetchCarriers
    )

  componentDidMount() {
    this.#fetchCarriers()
  }

  render() {
    toast.configure()
    const { carriers, carrier, searchTerm } = this.state

    return (
      <React.Fragment>
        <DashboardLayout>
          <main id="ServiceConsumptionExamplePage" className="mainSection">
            <MDBContainer fluid className="mt-5">
              <h2 className="text-center">Service Consumption Examples</h2>
              <hr />
            </MDBContainer>
            <MDBContainer fluid className="mt-5">
              <MDBRow>
                <MDBCol size="12" md="6">
                  <MDBCard>
                    <MDBCardHeader>
                      Search Carriers&nbsp;
                      {this.state.isSearching && (
                        <span>
                          <i className="fa fa-spin fa-spinner" />
                        </span>
                      )}
                      <MDBBtn
                        onClick={this.#togglePagination}
                        className="card-header-btn"
                      >
                        <MDBIcon fas="true" icon="list-ol" />
                      </MDBBtn>
                    </MDBCardHeader>
                    <MDBCardHeader>
                      <form noValidate onSubmit={this.#onSubmit}>
                        <MDBInput
                          label="Carrier Search"
                          type="text"
                          name="search_term"
                          value={searchTerm || ''}
                          onChange={this.#onSearch}
                        />
                      </form>
                    </MDBCardHeader>
                    <MDBCardBody>
                      <table className="carriers-table">
                        <thead>
                          <tr>
                            <th valign="middle">ID</th>
                            <th
                              valign="middle"
                              className={
                                'has-sorter ' +
                                (this.sortColumn === 'c_name'
                                  ? 'sorted-' +
                                    `${this.sortDirection}`.trim().toLowerCase()
                                  : '')
                              }
                            >
                              <div>
                                <div className="label">Name</div>
                                <div className="sorter">
                                  <div
                                    onClick={() =>
                                      this.#onChangeSort('c_name', 'ASC')
                                    }
                                    className="up"
                                  >
                                    <MDBIcon icon="angle-up" />
                                  </div>
                                  <div
                                    onClick={() =>
                                      this.#onChangeSort('c_name', 'DESC')
                                    }
                                    className="down"
                                  >
                                    <MDBIcon icon="angle-down" />
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th valign="middle">Edit</th>
                            <th valign="middle">Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {carriers.map((carrier) => (
                            <tr key={'carrier-id-' + carrier.id}>
                              <td>{carrier.id}</td>
                              <td>{carrier.c_name}</td>
                              <td>
                                <MDBBtn
                                  disabled={!carrier}
                                  onClick={() => this.#editCarrier(carrier)}
                                >
                                  <MDBIcon icon="edit" />
                                </MDBBtn>
                              </td>
                              <td>
                                <MDBBtn
                                  disabled={!carrier}
                                  onClick={() => this.#deleteCarrier(carrier)}
                                >
                                  <MDBIcon icon="trash" />
                                </MDBBtn>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {this.state.enablePagination && carriers.length ? (
                        <div className="pagination-wrapper">
                          <PaginationComponent
                            pagination={this.#pagination}
                            onPageSelect={(page, perPage) =>
                              this.#onPageSelect(page, perPage)
                            }
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
                <MDBCol size="12" md="6">
                  <MDBCard>
                    <MDBCardHeader>
                      {!carrier?.id ? 'Create' : 'Update'} Carrier
                      <MDBBtn
                        disabled={!!carrier}
                        onClick={this.#createCarrier}
                        className="card-header-btn"
                      >
                        New&nbsp;
                        <MDBIcon icon="plus" />
                      </MDBBtn>
                    </MDBCardHeader>
                    <MDBCardBody>
                      <form noValidate onSubmit={this.#onSubmit}>
                        <MDBInput
                          readOnly={!carrier}
                          disabled={!carrier}
                          label="Carrier Name"
                          type="text"
                          name="c_name"
                          value={carrier?.c_name || ''}
                          onChange={this.#onChange}
                          autoComplete="off"
                        />
                        <MDBInput
                          readOnly={!carrier}
                          disabled={!carrier}
                          label="Contact Name"
                          type="text"
                          name="c_contact_name"
                          value={carrier?.c_contact_name || ''}
                          onChange={this.#onChange}
                        />
                        <MDBInput
                          readOnly={!carrier}
                          disabled={!carrier}
                          label="Contact Email"
                          type="text"
                          name="c_contact_email"
                          value={carrier?.c_contact_email || ''}
                          onChange={this.#onChange}
                        />
                        <MDBRow>
                          <MDBCol
                            size={
                              !carrier || this.state.isModifying ? '12' : '8'
                            }
                          >
                            <MDBBtn
                              disabled={
                                !carrier?.c_name || this.state.isModifying
                              }
                              className="btn-block"
                              type="submit"
                            >
                              {this.state.isModifying ? (
                                <span>
                                  Saving...&nbsp;
                                  <i className="fa fa-spin fa-spinner" />
                                </span>
                              ) : (
                                'Save Carrier'
                              )}
                            </MDBBtn>
                          </MDBCol>
                          {carrier && !this.state.isModifying ? (
                            <MDBCol size="4">
                              <MDBBtn
                                onClick={() => this.setState({ carrier: null })}
                                className="btn-block"
                                type="button"
                              >
                                Cancel
                              </MDBBtn>
                            </MDBCol>
                          ) : (
                            <></>
                          )}
                        </MDBRow>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export default ServiceConsumptionExamplePage
