import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import {
  MDBCollapse,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBIcon,
  MDBBtnGroup,
} from 'mdbreact'

import './CarrierTableFilter.scss'

const CarrierTableFilter = ({ filters, onChange }) => {
  const set = (field, val) => onChange({ [field]: val })

  return (
    <div className="container-fluid" id="CarrierTableFilterComponent">
      <MDBRow className="ctfc-filter-row">
        <MDBCol size="12">
          <h5>Carrier Filters</h5>
        </MDBCol>
      </MDBRow>
      <MDBRow className="ctfc-filter-row">
        <MDBCol size="12" md="3">
          <label className="text--center">Agent Specialization:</label>
        </MDBCol>
        <MDBCol size="12" md="9">
          <MDBBtnGroup style={{ width: '100%' }}>
            <MDBBtn
              block
              className={filters?.spec === null ? 'active' : ''}
              onClick={() => set('spec', null)}
            >
              All
            </MDBBtn>
            <MDBBtn
              block
              className={filters?.spec === 'HEALTH' ? 'active' : ''}
              onClick={() => set('spec', 'HEALTH')}
            >
              HEALTH
            </MDBBtn>
            <MDBBtn
              block
              className={filters?.spec === 'SENIOR' ? 'active' : ''}
              onClick={() => set('spec', 'SENIOR')}
            >
              SENIOR
            </MDBBtn>
            <MDBBtn
              block
              className={filters?.spec === 'LIFE' ? 'active' : ''}
              onClick={() => set('spec', 'LIFE')}
            >
              LIFE
            </MDBBtn>
          </MDBBtnGroup>
        </MDBCol>
      </MDBRow>
      <MDBCollapse
        isOpen={['HEALTH', 'LIFE', 'SENIOR'].includes(filters?.spec)}
      >
        <MDBRow className="ctfc-filter-row">
          <MDBCol size="12" md="3">
            <label>Carrier Priority Level:</label>
          </MDBCol>
          <MDBCol size="12" md="9">
            <MDBBtnGroup style={{ width: '100%' }}>
              <MDBBtn
                block
                className={filters?.priority === null ? 'active' : ''}
                onClick={() => set('priority', null)}
              >
                All
              </MDBBtn>
              <MDBBtn
                block
                className={filters?.priority === 'core' ? 'active' : ''}
                onClick={() => set('priority', 'core')}
              >
                Core ($$$)
              </MDBBtn>
              <MDBBtn
                block
                className={filters?.priority === 'preferred' ? 'active' : ''}
                onClick={() => set('priority', 'preferred')}
              >
                Preferred ($$)
              </MDBBtn>
              <MDBBtn
                block
                className={filters?.priority === 'additional' ? 'active' : ''}
                onClick={() => set('priority', 'additional')}
              >
                Additional ($)
              </MDBBtn>
            </MDBBtnGroup>
          </MDBCol>
        </MDBRow>
      </MDBCollapse>
    </div>
  )
}

export default observer(CarrierTableFilter)
