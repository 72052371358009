import RestApi from './../../api/rest-api-client'
const srvcEndpoint = '/usertypes'

const map = {
  // Agent Usertypes --------------------------------
  'affiliate-agent': 111,
  'affiliate-leader': 133,
  'associate-agent': 223,
  'associate-leader': 224,
  'csr-agent': 225,
  'limited-to-contracting-signature-agent': 91,
  'limited-to-training-signature-agent': 149,
  'signature-agent-in-need-of-pic': 37,
  'signature-agent-complete': 92,
  'career-agent': 130,
  'premier-agent': 129,
  'district-leader': 93,
  'regional-leader': 38,
  'divisional-leader': 90,
  'agency-owner': 35,

  // NON-Agent, External Usertypes ----------------------------
  'division-admin': 226,
  'region-admin': 227,
  'district-admin': 228,

  // Grouped / Generalized Usertypes ----------------
  'affiliate-group': [111, 133],
  'associate-group': [223, 224],
  'signature-group': [225, 91, 149, 37, 92],
  'signature-higher-group': [91, 149, 37, 92, 35, 38, 90, 93, 130, 129],
  'management-group': [35, 38, 90, 93, 133, 224, 129, 130],
  'division-group': [35, 90],
  'recruiter-group': [35, 38, 90, 93, 133, 224, 129, 130],

  // NON-Agent, Internal Usertypes ----------------------------
  'internal-staff-usabg': 222,
  'internal-staff': 222,
  'internal-admin-usabg': [35, 36],
  'internal-admin': [35, 36],
  'system-admin': 219,
  internal: [219, 35, 36, 222],

  // Agent vs Non-Agent Groups ----------------------
  agent: [
    111, 133, 223, 224, 225, 91, 149, 37, 92, 130, 129, 93, 38, 90, 35, 225,
  ],
  'non-agent': [222, 36, 219, 226, 227, 228],
}

const nameToId = (name) =>
  name && map.hasOwnProperty(name) ? map[name] : undefined

// const idToName = usertypeId =>
// 	Object.keys(map).filter(m => parseInt(map[m]) === parseInt(usertypeId)).shift();
const idToName = (usertypeId) =>
  Object.keys(map)
    .filter((m) =>
      Array.isArray(map[m])
        ? map[m].indexOf(parseInt(usertypeId)) > -1
        : parseInt(map[m]) === parseInt(usertypeId)
    )
    .shift()

const isA = (usertype, usertypeId) => {
  let usertypeIds = nameToId(`${usertype}`.trim().toLowerCase())
  return (
    (Array.isArray(usertypeIds) ? usertypeIds : [usertypeIds]).indexOf(
      parseInt(usertypeId, 10)
    ) > -1
  )

  /*
	switch (`${usertype}`.trim().toLowerCase())
	{

		// Agent Usertypes --------------------------------
		case "affiliate-agent":
			return 111 === parseInt(usertypeId, 10);
		case "affiliate-leader":
			return 133 === parseInt(usertypeId, 10);
		case "associate-agent":
			return 223 === parseInt(usertypeId, 10);
		case 'associate-leader':
			return 224 === parseInt(usertypeId, 10);
		case "limited-to-contracting-signature-agent":
			return 91 === parseInt(usertypeId, 10);
		case "limited-to-training-signature-agent":
			return 149 === parseInt(usertypeId, 10);
		case "signature-agent-in-need-of-pic":
			return 37 === parseInt(usertypeId, 10);
		case "signature-agent-complete":
			return 92 === parseInt(usertypeId, 10);
		case "career-agent":
			return 130 === parseInt(usertypeId, 10);
		case "premier-agent":
			return 129 === parseInt(usertypeId, 10);
		case "district-leader":
			return 93 === parseInt(usertypeId, 10);
		case "regional-leader":
			return 38 === parseInt(usertypeId, 10);
		case "divisional-leader":
			return 90 === parseInt(usertypeId, 10);
		case "agency-owner":
			return 35 === parseInt(usertypeId, 10);


		// Grouped / Generalized Usertypes ----------------
		case "affiliate-group":
			return [111,133].indexOf(parseInt(usertypeId, 10)) > -1;
		case "associate-group":
			return [223,224].indexOf(parseInt(usertypeId, 10)) > -1;
		case "signature-group":
			return [91,149,37,92].indexOf(parseInt(usertypeId, 10)) > -1;
		case "management-group":
			return [35,38,90,93,133].indexOf(parseInt(usertypeId, 10)) > -1;
		case "division-group":
			return [35,90].indexOf(parseInt(usertypeId, 10)) > -1;
		case "recruiter-group":
			return [35,38,90,93,133].indexOf(parseInt(usertypeId, 10)) > -1;


		// NON-Agent Usertypes ----------------------------
		case "internal-staff-usabg":
		case "internal-staff":
			return 222 === parseInt(usertypeId, 10);
		case "internal-admin-usabg":
		case "internal-admin":
			return [35,36].indexOf(parseInt(usertypeId, 10)) > -1;
		case "system-admin":
			return 219 === parseInt(usertypeId, 10);


		// GLOBAL Usertypes -------------------------------
		case "agent":
			return [111,133,223,224,91,149,37,92,130,129,93,38,90,35].indexOf(parseInt(usertypeId, 10)) > -1;
		case "non-agent":
			return [222,36,219].indexOf(parseInt(usertypeId, 10)) > -1;


		default:
			return false;
	}
	*/
}

export default {
  search: async (req, opts) => RestApi.request('GET', srvcEndpoint, req, opts),
  get: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, id, req, opts),
  update: async (id, data, opts) => RestApi.request("PUT", srvcEndpoint, id, data, opts),
  isA: isA,
  nameToId: nameToId,
  idToName: idToName,
}
