import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import env from './environments/environment'
import { TrackJS } from 'trackjs'
import UserProfileService from './shared/services/UserProfile.service'
import registerServiceWorker from './registerServiceWorker'
import packageJson from '../package.json'

import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'
import './index.scss'

// Set the application version for global access.
// global.appVersion = `${process.env.REACT_APP_VERSION}`
global.appVersion = packageJson.version

// Instruct React to build the DOM
ReactDOM.render(<App />, document.getElementById('root'))

// Enable TrackJS monitoring if identified in env
if (env?.integrations?.track_js) {
  let trackJsConfig = {
      ...env.integrations.track_js,
      version: global.appVersion,
    },
    userId = UserProfileService.getUserId(true)
  if (!isNaN(userId) && userId !== null)
    trackJsConfig.userId = userId.toString()
  TrackJS.install(trackJsConfig)
}

// Register application service worker (CacheBustin)
registerServiceWorker()
