import React from 'react'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import moment from 'moment'
import {
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from 'mdbreact'

import './AgentWritingCodeModal.scss'
import UsersCarrierContractService from '../../../../shared/services/UsersCarrierContract.service'

class AgentWritingCodeModal extends React.Component {
  #onClose = (outcome) => {
    // // Resolve/reject modal promise.
    // onResolve,
    // onReject,
    this.props.onResolve(!!outcome)
  }
  #errors = {}

  state = {
    is_open: false,
    //carrier_id: 									null,
    av_carrier: null,
    //usercarrier_id: 							null,
    agent_contract: false,
    submitting: false,
  }

  /*
   * Component Accessor Methods ----------------------
   *
   */

  /*
   * Component Public Methods ------------------------
   *
   */

  /*
   * Component Private Methods -----------------------
   *
   */
  #renderErrorField = (field) => {
    if (field && this.#errors.hasOwnProperty(field) && this.state.errorsExist)
      return <span className="error-msg">{this.#errors[field]}</span>
    return <></>
  }

  #toggleAgentWritingCodeModal = (r) => {
    this.setState({ is_open: !this.state.is_open })
    if (this.state.is_open) this.#onClose(r)
  }

  #onChange = (event) =>
    this.setState({ [event.target.name]: event.target.value })

  #validateAgentCode = () => {
    this.#errors = {}

    if (!this.state.uc_agent_code || !`${this.state.uc_agent_code}`.trim())
      this.#errors.uc_agent_code = 'Agent Writing Number Required'

    if (
      this.#errors &&
      typeof this.#errors === 'object' &&
      Object.keys(this.#errors).length
    ) {
      this.setState({ errorsExist: true })
      return false
    }

    this.setState({ errorsExist: false })
    return true
  }

  #onSubmit = async () => {
    if (!this.#validateAgentCode()) return
    this.setState({ submitting: true })

    if (this.state.agent_contract === true) {
      try {
        this.#toggleAgentWritingCodeModal(
          await UsersCarrierContractService.store({
            contract_id: 'sales_tracker',
            agent_code: this.state.uc_agent_code,
            carrier_id: this.props.carrier_id,
            user_id: UserProfileService.getUserId(),
            disposition: 'completed',
            pwk_completed_at: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
          })
        )
      } catch (error) {
        console.log(error)
      }
    } else if (
      this.state.agent_contract &&
      this.state.agent_contract?.carrier_id &&
      this.state.agent_contract?.id
    ) {
      try {
        this.#toggleAgentWritingCodeModal(
          await UsersCarrierContractService.update(
            this.state.agent_contract.id,
            { agent_code: this.state.uc_agent_code }
          )
        )
      } catch (error) {
        console.log(error)
      }
    }
  }

  /*
   * Component Event Methods -------------------------
   *
   */
  componentDidMount() {
    if (this.props.on_close && typeof this.props.on_close === 'function')
      this.#onClose = this.props.on_close
  }

  componentDidUpdate() {
    if (!!this.state.is_open !== !!this.props.open)
      this.setState({
        is_open: !!this.props.open,
        agent_contract: this.props.open,
        av_carrier: this.props.av_carrier,
        submitting: false,
      })
  }

  componentWillUnmount() {}

  render() {
    return (
      <MDBModal
        id="AgentWritingCodeModal"
        size="lg"
        isOpen={this.state.is_open}
        toggle={() => this.#toggleAgentWritingCodeModal(false)}
      >
        <MDBModalHeader>
          Oh no! We can't Submit Sale just yet ...
        </MDBModalHeader>
        <MDBModalBody>
          <div className="h6">
            It appears we do not have your Agent Writing Code for the following
            carrier on file:
            <br />
            <p>
              <u>{this.state.av_carrier}</u>
              <br />
              Please enter your writing number now.
            </p>
            <div className="form-row">
              <MDBCol size="12">
                <MDBInput
                  label="Agent Writing Number"
                  name="uc_agent_code"
                  id={
                    this.state.uc_agent_code
                      ? this.state.uc_agent_code.toString()
                      : null
                  }
                  value={this.state.uc_agent_code || ''}
                  onChange={this.#onChange}
                />
                {this.#renderErrorField('uc_agent_code')}
              </MDBCol>
            </div>
            <br />
            If you don’t have your Agent Writing Code readily available, please
            come back and complete entering your sale once you’ve obtained your
            agent writing number.
          </div>
          <div className="form-row">
            <MDBCol size="12" md="6">
              <MDBBtn
                disabled={this.state.submitting}
                color="primary"
                size="sm"
                onClick={() => this.#onSubmit()}
                className="btn-block"
              >
                {this.state.submitting ? 'Submitting...' : 'Submit'}
              </MDBBtn>
            </MDBCol>
            <MDBCol size="12" md="6">
              <MDBBtn
                disabled={this.state.submitting}
                color="secondary"
                size="sm"
                onClick={() => this.#toggleAgentWritingCodeModal(false)}
                className="btn-block"
              >
                Cancel
              </MDBBtn>
            </MDBCol>
          </div>
        </MDBModalBody>
      </MDBModal>
    )
  }
}

export default AgentWritingCodeModal
