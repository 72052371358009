import BaseModelFactory from './base.factory'
import UserContractCartItemService from './../services/UserContractCartItem.service'
import UserContractCartItem from './../models/user-contract-cart-item.model'

class UserContractCartItemFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: UserContractCartItemService, Model: UserContractCartItem }
    )
  static findAllByCartGroup = async (cartId, group) =>
    await this._findAllBy(
      { cart_id: cartId, item_group: group },
      { Service: UserContractCartItemService, Model: UserContractCartItem }
    )
  static findAllByCartId = async (cartId) =>
    await this._findAllBy(
      { cart_id: cartId },
      { Service: UserContractCartItemService, Model: UserContractCartItem }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: UserContractCartItemService,
      Model: UserContractCartItem,
    })
}

export default UserContractCartItemFactory
