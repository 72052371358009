const CHARACTERS = {
    alphabets: 'qwertyuiopasdfghjklzxvcbnm',
    numbers: '1234567890',
    upperCase: 'QWERTYUIOPASDFGHJKLZXCVBNM',
    specialCharacters: '!@#$^&*_-?'
};

function getRandomPassword(length = 8, options = {
    alphabets: true,
    numbers: true,
    upperCase: true,
    specialCharacters: true
}) {
    let password = '';
    let components = 0;
    if (options.alphabets) {
        components++;
    }
    if (options.upperCase) {
        components++;
    }
    if (options.specialCharacters) {
        components++;
    }
    if (options.numbers) {
        components++;
    }
    if (components > length) {
        return false;
    }
    while (password.length !== length) {
        for (let c in options) {
            if (options[c] && CHARACTERS[c] && password.length < length) {
                password += getRandomString(Math.floor(Math.random() * (length - password.length - components + 1)) + 1, CHARACTERS[c]);
                components--;
            }
        }
    }

    return shuffleCharacters(password);
}

function shuffleCharacters(str) {
    const a = str.split(""), n = a.length;

    for (let i = n - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const tmp = a[i];
        a[i] = a[j];
        a[j] = tmp;
    }
    return a.join("");
}

function getRandomString(length, characters) {
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

module.exports = {
    getRandomPassword
};

// Example
// console.log(getRandomPassword(8, {numbers: true, alphabets: true}));
