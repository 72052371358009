import { makeAutoObservable } from 'mobx'
import { CarrierFactory, CarrierPriorityFactory } from '../../shared/factories'
import CarrierService from '../../shared/services/Carrier.service'

const DEFAULT_SPEC = 'HEALTH'

class CarrierPriorityStore {
  isLoading = false
  CarrierPriorities = []
  filteredCarrierPriorities = []
  spec = ''

  constructor() {
    makeAutoObservable(this)
  }

  init = async () => {
    this.isLoading = true

    let _CarrierPriorities = await this.getCarrierPriorities()
    const carrierIds = _CarrierPriorities.map((CarrierPriority) =>
      CarrierPriority.get('carrier_id')
    )
    const Carriers = await this.getCarriersByIds(carrierIds)
    const CarriersById = {}

    Carriers.forEach((Carrier) => {
      CarriersById[Carrier.id()] = Carrier
      CarriersById[Carrier.id()].addPriority(Carrier.raw())
    })
    _CarrierPriorities = _CarrierPriorities.map((CarrierPriority) => {
      CarrierPriority.Carrier = CarriersById[CarrierPriority.get('carrier_id')]
      return CarrierPriority
    })

    this.CarrierPriorities = [
      {
        header: 'Core Carriers $$$',
        data: _CarrierPriorities.filter(
          (CarrierPriority) =>
            parseInt(CarrierPriority.get('is_signature_core')) === 1
        ),
      },
      {
        header: 'Preferred Carriers $$',
        data: _CarrierPriorities.filter(
          (CarrierPriority) =>
            parseInt(CarrierPriority.get('is_signature_core')) === 0
        ),
      },
    ]

    if (DEFAULT_SPEC) {
      this.setSpec(DEFAULT_SPEC)
    } else {
      this.filteredCarrierPriorities = this.CarrierPriorities
    }

    this.isLoading = false
  }

  setSpec = (_spec) => {
    this.spec = _spec
    if (!_spec) {
      this.filteredCarrierPriorities = this.CarrierPriorities
      return
    }
    this.filteredCarrierPriorities = this.CarrierPriorities.map((c) => {
      return {
        ...c,
        data: c.data.filter((CarrierPriority) => {
          return CarrierPriority.get('spec') === _spec
        }),
      }
    })
  }

  getCoverageCategory = async () => {
    const CarrierWithCoverageCategory =
      await CarrierService.getWithCoverageCategory()
    return CarrierWithCoverageCategory.models
  }

  getCarrierPriorities = async () => {
    return CarrierPriorityFactory.findAll()
  }

  getCarriersByIds = async (carrierIds) => {
    return CarrierFactory.search({
      search: { id: carrierIds },
      pagination: false,
    })
  }
}

export default new CarrierPriorityStore()
