import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  MDBBtnGroup,
  MDBCollapse,
  MDBBtn,
  MDBIcon,
  MDBRow,
  MDBCol,
} from 'mdbreact'
import {
  UIInput,
  UIInputButton,
  UISelect,
} from './../../../../components/forms/form-fields'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import Store from './../../stores/Knowledgebase.store'
import './KbArticlesForm.component.scss'

const isJson = (str) => {
  try {
    JSON.parse(str)
    return true
  } catch (ex) {
    return false
  }
}

const strToTag = (str) =>
  `${str || ''}`
    .replace(/[^a-z- ]+/gi, '')
    .trim()
    .toLowerCase()
    .replace(/ +/g, '-')

const KbArticlesForm = () => {
  const [validity, setValidity] = useState({}),
    [showValidity, setShowValidity] = useState(false),
    [newKeyword, setNewKeyword] = useState('')

  const _onKeyDown = (event) => {
    try {
      if (event.key === 'Enter') _save()
    } catch (ex) {}
  }

  const _cancel = async () => {
    if (Store.Article.isNew() === false) {
      if (Object.keys(Store.Article.changed).length > 0) Store.Article.reload()
    }

    Store.Article = null
  }

  const _validate = () =>
    Object.values(validity).length === 4 &&
    Object.values(validity).every((v) => v === true)

  const _save = async () => {
    const isValid = _validate()

    if (isValid && showValidity) setShowValidity(false)
    else if (!isValid && !showValidity) setShowValidity(true)

    if (!isValid) return

    Store.isSaving = true

    const isNew = Store.Article.isNew()

    if (isNew)
      Store.Article.set('author_id', UserProfileService.getUserId(true))

    await Store.Article.save()

    if (isNew) Store.Articles.push(Store.Article)

    Store.isSaving = false
    Store.Article = null
  }

  const renderKeywords = () => {
    const keywords =
      Store.Article?.get('keywords') && isJson(Store.Article?.get('keywords'))
        ? JSON.parse(Store.Article?.get('keywords'))
        : []

    return (
      <ul className="kw-list-form">
        {keywords.map((kw, idx) => (
          <li
            key={`kw-list-form-${idx}-${kw}`}
            onClick={() => {
              Store.Article.set(
                'keywords',
                JSON.stringify(keywords.filter((k) => k !== kw))
              )
            }}
          >
            {kw.replace(/-/g, ' ')}&nbsp;
            <MDBIcon icon="times" />
          </li>
        ))}
      </ul>
    )
  }

  return (
    <div id="KbArticlesFormComponent">
      <MDBCollapse
        isOpen={!!(Store.Article?.isNew() || Store.Article?.id() > 0)}
      >
        <form noValidate className="container-fluid">
          <MDBRow>
            <MDBCol size="12" sm="4">
              <UISelect
                name="category_id"
                label="Category"
                options={Object.values(Store.Categories).map((Category) => ({
                  value: `${Category.id()}`,
                  text: Category.get('c_name'),
                  checked:
                    parseInt(Category.id()) ===
                    parseInt(Store.Article?.get('category_id')),
                }))}
                value={Store.Article?.get('category_id') || ''}
                onChange={(evt) => {
                  if (evt.target.value && Store.Article) {
                    if (
                      !Store.Article.get('category_id') ||
                      `${evt.target.value}` !==
                        `${Store.Article.get('category_id')}`
                    )
                      Store.Article.set('category_id', evt.target.value)
                  }
                }}
                onValidityChange={(v) => {
                  if (Store.Article && validity?.category_id !== v.isValid)
                    setValidity((prevValidity) => ({
                      ...prevValidity,
                      category_id: v.isValid,
                    }))
                }}
                showValidity={showValidity}
                required
              />
            </MDBCol>
            <MDBCol size="12" sm="4">
              <UIInput
                type="text"
                label="Subject"
                value={Store.Article?.get('subject') || ''}
                onKeyDown={_onKeyDown}
                onChange={(e) => Store.Article.set('subject', e.target.value)}
                showValidity={showValidity}
                onValidityChange={(v) => {
                  if (Store.Article && validity?.subject !== v.isValid)
                    setValidity((prevValidity) => ({
                      ...prevValidity,
                      subject: v.isValid,
                    }))
                }}
                required
                rules={{ minLength: 1, maxLength: 255, required: true }}
              />
            </MDBCol>
            <MDBCol size="12" sm="4">
              <UIInput
                type="text"
                label="Question"
                value={Store.Article?.get('question') || ''}
                onKeyDown={_onKeyDown}
                onChange={(e) => Store.Article.set('question', e.target.value)}
                showValidity={showValidity}
                onValidityChange={(v) => {
                  if (Store.Article && validity?.question !== v.isValid)
                    setValidity((prevValidity) => ({
                      ...prevValidity,
                      question: v.isValid,
                    }))
                }}
                required
                rules={{ required: true }}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12" sm="8">
              <UIInput
                type="textarea"
                label="Answer/Content"
                value={Store.Article?.get('content') || ''}
                onKeyDown={_onKeyDown}
                onChange={(e) => Store.Article.set('content', e.target.value)}
                showValidity={showValidity}
                onValidityChange={(v) => {
                  if (Store.Article && validity?.content !== v.isValid)
                    setValidity((prevValidity) => ({
                      ...prevValidity,
                      content: v.isValid,
                    }))
                }}
                required
                rules={{ required: true }}
              />
            </MDBCol>
            <MDBCol size="12" sm="4">
              <UIInputButton
                input={
                  <UIInput
                    type="text"
                    label="Keywords"
                    value={newKeyword}
                    onKeyDown={_onKeyDown}
                    onChange={(e) => setNewKeyword(e.target.value)}
                  />
                }
                button={
                  <MDBBtn
                    className="kba-add-keyword"
                    color="info"
                    onClick={() => {
                      const kw = newKeyword && strToTag(newKeyword)
                      if (kw) {
                        Store.Article.set(
                          'keywords',
                          Store.Article.get('keywords')
                            ? JSON.stringify(
                                (
                                  JSON.parse(Store.Article.get('keywords')) ||
                                  []
                                ).concat([kw])
                              )
                            : JSON.stringify([kw])
                        )
                        setNewKeyword('')
                      }
                    }}
                  >
                    <MDBIcon icon="plus" />
                    &nbsp;Add
                  </MDBBtn>
                }
                btnPosition={'right'}
              />
              {renderKeywords()}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <MDBBtnGroup>
                <MDBBtn
                  className="btn-secondary"
                  disabled={
                    Store.isFetching || Store.isSaving || Store.isLoading
                  }
                  onClick={() => _cancel()}
                >
                  Cancel
                </MDBBtn>
                <MDBBtn
                  className="btn-success"
                  color="success"
                  disabled={
                    Store.isFetching || Store.isSaving || Store.isLoading
                  }
                  onClick={() => _save()}
                >
                  Save
                </MDBBtn>
              </MDBBtnGroup>
            </MDBCol>
          </MDBRow>
        </form>
      </MDBCollapse>
    </div>
  )
}

export default observer(KbArticlesForm)
