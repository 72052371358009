import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  MDBCollapse,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBCardImage,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
  MDBRow,
  MDBCol,
} from 'mdbreact'
import KbCategoriesList from './../KbCategoriesList/KbCategoriesList.component'
import KbCategoryForm from './../KbCategoryForm/KbCategoryForm.component'
import KbArticlesList from './../KbArticlesList/KbArticlesList.component'
import Store from './../../stores/Knowledgebase.store'

import './KbAdmin.component.scss'

const KbAdmin = ({}) => {
  const isEditingCat = () =>
    !!(Store.Category?.isNew() || Store.Category?.id() > 0)

  return (
    <MDBContainer id="KbAdminComponent" fluid>
      <div className="content-wrapper">
        <MDBRow className={'cat-row ' + (isEditingCat() ? 'form-is-open' : '')}>
          <MDBCol sm="12" md={isEditingCat() ? '6' : '12'} className="cat-list">
            <KbCategoriesList />
          </MDBCol>
          <MDBCol sm="12" md={isEditingCat() ? '6' : '12'} className="cat-form">
            {isEditingCat() ? <KbCategoryForm /> : <></>}
          </MDBCol>
        </MDBRow>
      </div>
      <br />
      <div className="content-wrapper">
        <MDBRow>
          <MDBCol>
            <KbArticlesList />
          </MDBCol>
        </MDBRow>
      </div>
    </MDBContainer>
  )
}

export default observer(KbAdmin)
