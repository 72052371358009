import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite'
import LoadingSpinner from '../../shared/LoadingSpinner.component'
import ContentHtml from '../../content/ContentHtml/ContentHtml.component'
import {
  MDBBtn,
  MDBModalHeader,
  MDBModalBody,
  MDBModal,
  MDBCollapse
} from 'mdbreact'
import UpdatePaymentMethodModalStore from './UpdatePaymentMethodModal.store'

import './UpdatePaymentMethodModal.component.scss'

const UpdatePaymentMethodModal = () => {
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory();

  useEffect(() => {
    UpdatePaymentMethodModalStore.init()
  }, [])

  const toggleModal = () => {
  	UpdatePaymentMethodModalStore.toggleModal()
  }

  return (
    <div className="UpdatePaymentMethodModalComponent">
      <MDBModal size="lg" isOpen={UpdatePaymentMethodModalStore.isOpen} toggle={toggleModal}>
        <MDBModalHeader toggle={toggleModal}>Update Payment Method</MDBModalHeader>
        <MDBModalBody className={isLoading ? 'is-loading' : ''}>
	        <div className="loading-wrapper">
	          <LoadingSpinner size="md" isActive={true} />
	        </div>
	        <MDBCollapse isOpen={!isLoading} className='content-modal-wrapper'>
						<ContentHtml 
              contentSlug="billing---update-payment-method" 
              onLoad={contentResponse => {
                UpdatePaymentMethodModalStore.isOpen && setIsLoading(false);
              }} 
            />
						<br />
						<MDBBtn 
              block 
              className='btn btn-info'
              onClick={() => {
                toggleModal();
                history.push('/account-billinginfo');
              }} 
            >
              OK
            </MDBBtn>
	        </MDBCollapse>
        </MDBModalBody>
      </MDBModal>
    </div>
  )
}

export default observer(UpdatePaymentMethodModal)
