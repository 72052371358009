import React from 'react'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import ReferralService from './../../../../shared/services/Referral.service'
import { Subscription, BehaviorSubject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import {
  MDBCol,
  MDBInput,
  MDBSelect,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBAlert,
  MDBCollapse,
} from 'mdbreact'

import './ReferralPartnerInput.scss'

class ReferralPartnerInput extends React.Component {
  #_subscriptions$ = new Subscription()
  #_querySubject = new BehaviorSubject(null)

  #agentId = null

  #onClose = () => {}
  #errors = {}

  state = {
    is_open: false,

    referral_partner: null,
    referral_partner_id: null,

    rp_name: null,
    rp_company: null,
    rp_email: null,
    rp_phone: null,
    rp_products_sold: null,
    rp_is_licensed: null,

    referral_partners: [],
    submitting: false,
    canCreate: true,
    canEdit: false,
    cenDelete: false,
    errorsExist: false,
  }

  /*
   * Component Accessor Methods ----------------------
   *
   */

  /*
   * Component Public Methods ------------------------
   *
   */

  /*
   * Component Private Methods -----------------------
   *
   */

  #resetForm = () => {
    this.setState({
      is_open: false,

      referral_partner: null,
      referral_partner_id: null,

      rp_name: null,
      rp_company: null,
      rp_email: null,
      rp_phone: null,
      rp_products_sold: null,
      rp_is_licensed: null,

      referral_partners: [],
      submitting: false,
      canCreate: true,
      canEdit: false,
      cenDelete: false,
      errorsExist: false,
    })
  }

  #toggleReferralModal = () => this.setState({ is_open: !this.state.is_open })

  #onSearch = async (event) => {
    if (this.state.referral_partner_id > 0) this.#clearReferral()

    if (event.target.value || `${event.target.value.trim()}`.length > 1)
      this.#_querySubject.next(event.target.value.trim())

    let state = {}

    if (!event.target.value) {
      state.show_results = false
      state.referral_partners = []
    }

    if (
      (event.target.value &&
        this.state.referral_partner !== event.target.value.trim()) ||
      (event.target.value && !this.state.referral_partner) ||
      (!event.target.value && this.state.referral_partner)
    )
      state.referral_partner = event.target.value
        ? event.target.value.trim()
        : null

    if (Object.keys(state).length) this.setState(state)
  }

  #handleSearch = async (term) =>
    this.#agentId
      ? this.setState({
          show_results: true,
          referral_partners: await ReferralService.search(term, this.#agentId),
        })
      : null

  #handleAgentChange = () => {
    let agentId = parseInt(
      this.props.agent_id || UserProfileService.getUserId()
    )
    if (agentId && parseInt(this.#agentId) !== agentId) {
      this.#agentId = agentId
      if (this.#_querySubject.getValue())
        this.#handleSearch(this.#_querySubject.getValue())
    }
  }

  #renderErrorField = (field) => {
    if (field && this.#errors.hasOwnProperty(field) && this.state.errorsExist)
      return <span className="error-msg">{this.#errors[field]}</span>
    return <></>
  }

  #selectReferral = (rId) => {
    if (parseInt(rId) > 0) this.#setReferral(parseInt(rId))
  }

  #setReferral = async (rId) => {
    if (rId && parseInt(rId)) {
      this.props.set_referral_partner(rId)
      let referralPartner = await ReferralService.getById(rId, this.#agentId)
      this.setState({
        canCreate: false,
        canEdit: !!referralPartner,
        show_results: false,
        referral_partner: referralPartner && referralPartner.name,
        referral_partner_id: parseInt(rId),
        referral_partners: [],

        rp_name: referralPartner && referralPartner.name,
        rp_company: referralPartner && referralPartner.company,
        rp_email: referralPartner && referralPartner.email,
        rp_phone: referralPartner && referralPartner.phone,
        rp_products_sold: referralPartner && referralPartner.products_sold,
        rp_is_licensed: referralPartner && referralPartner.is_licensed,
      })
    }
  }

  #editReferral = async () => {
    if (this.state.referral_partner_id) this.setState({ is_open: true })
  }

  #clearReferral = () => {
    this.props.set_referral_partner(null)
    this.setState({
      canCreate: true,
      canEdit: false,
      referral_partner: null,
      referral_partner_id: null,
      referral_partners: [],
    })
    this.#resetForm()
  }

  #onChange = (event) =>
    this.setState({ [event.target.name]: event.target.value })

  #setFormVal = async (name, value) => {
    switch (name) {
      case 'rp_is_licensed':
        return typeof value !== 'undefined' &&
          parseInt(value) !== parseInt(this.state[name])
          ? this.setState({ rp_is_licensed: parseInt(value) })
          : null
      default:
        break
    }
  }

  #validateReferralPartner = () => {
    let errors = {}
    const regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      msgs = {
        rp_name: 'Name Required',
        rp_email: 'Email',
        rp_phone: 'Phone',
        rp_company: 'Company Required',
      }

    Object.keys(msgs).forEach((key) => {
      switch (key) {
        case 'rp_email':
          if (!this.state[key] || !`${this.state[key]}`.trim())
            errors[key] = msgs[key] + ' Required'
          else if (this.state[key] && !regEmail.test(this.state[key]))
            errors[key] = msgs[key] + ' Invalid'
          break

        case 'rp_phone':
          if (!this.state[key] || !`${this.state[key]}`.trim())
            errors[key] = msgs[key] + ' Required'
          else if (
            Array.isArray(`${this.state[key]}`.match(/\d+/g)) &&
            `${this.state[key]}`.match(/\d+/g).join().length < 10
          )
            errors[key] = msgs[key] + ' Invalid'
          else if (
            this.state[key] &&
            `${this.state[key]}`.match(/\d+/g) === null
          )
            errors[key] = msgs[key] + ' Invalid'
          break

        case 'rp_name':
          if (!this.state[key] || !`${this.state[key]}`.trim())
            errors[key] = msgs[key]
          break

        default:
          break
      }
    })

    // No errors exist ----------------------------
    if (Object.keys(errors).length === 0) {
      this.#errors = {}
      if (this.state.errorsExist) this.setState({ errorsExist: false })
      return true
    }

    // Errors exist -------------------------------
    this.#errors = errors
    this.setState({ errorsExist: true })
    return false
  }

  #onSubmit = async () => {
    // Validate Sale.
    if (!this.#validateReferralPartner()) return

    // Passed Validation.
    // Set submitting flag.
    this.setState({ submitting: true })

    // Prepare data object.
    const data = {
      agent_id: this.#agentId,
      name: this.state.rp_name,
      company: this.state.rp_company,
      email: this.state.rp_email,
      phone: this.state.rp_phone,
      products_sold: this.state.rp_products_sold,
      is_licensed: this.state.rp_is_licensed,
    }

    // Make API call to store AvSale data.
    let result = this.state.referral_partner_id
      ? ReferralService.update(
          this.state.referral_partner_id,
          data,
          this.#agentId
        )
      : ReferralService.create(data)
    result
      .then((referrer) => {
        if (referrer && referrer.id) {
          this.#setReferral(referrer.id)
          this.#toggleReferralModal()
          this.#resetForm()
        }
      })
      .catch((err) => {
        console.log('REF ERR:  ', err)
        return
      })
      .finally(() => this.setState({ submitting: false }))
  }

  /*
   * Component Event Methods -------------------------
   *
   */

  componentDidMount() {
    if (this.props.on_close && typeof this.props.on_close === 'function')
      this.#onClose = this.props.on_close

    this.#_subscriptions$.add(
      this.#_querySubject
        .pipe(debounceTime(250), distinctUntilChanged())
        .subscribe((term) => this.#handleSearch(term))
    )
  }

  componentDidUpdate() {
    this.#handleAgentChange()

    if (
      this.props.referral_partner_id &&
      parseInt(this.props.referral_partner_id, 10) !==
        parseInt(this.state.referral_partner_id, 10)
    )
      this.#setReferral(this.props.referral_partner_id)
  }

  componentWillUnmount() {
    this.#_subscriptions$.unsubscribe()
  }

  render() {
    const referral_partners = this.state.referral_partners.map((p) => ({
        id: p.id,
        name: p.name,
      })),
      { canCreate, canEdit } = this.state,
      isRefLicensed =
        [1, 0].indexOf(parseInt(this.state.rp_is_licensed)) > -1
          ? [
              {
                text: 'Yes',
                value: '1',
                checked: parseInt(this.state.rp_is_licensed) === 1,
              },
              {
                text: 'No',
                value: '0',
                checked: parseInt(this.state.rp_is_licensed) === 0,
              },
            ]
          : [
              { text: 'Yes', value: '1', checked: true },
              { text: 'No', value: '0', checked: false },
            ]

    return (
      <div
        id="ReferralPartnerInput"
        className={[
          !this.props.disabled && canCreate ? 'can-create' : null,
          !this.props.disabled && canEdit ? 'can-edit' : null,
        ]
          .filter((c) => c)
          .join(' ')}
      >
        <MDBInput
          label="Referral Partner"
          containerClass="search-input"
          name="referral_partner_id"
          value={this.state.referral_partner || ''}
          onChange={this.#onSearch}
          disabled={this.props.disabled}
        />
        {!this.props.disabled && canCreate && (
          <MDBBtn
            color="primary"
            className="search-btn"
            size="sm"
            onClick={() => this.#toggleReferralModal()}
          >
            <MDBIcon icon="plus-circle" />
          </MDBBtn>
        )}
        {!this.props.disabled && canEdit && (
          <MDBBtn
            color="warning"
            className="search-btn"
            size="sm"
            onClick={() => this.#editReferral()}
          >
            <MDBIcon icon="pencil-alt" />
          </MDBBtn>
        )}
        {!this.props.disabled && canEdit && (
          <MDBBtn
            color="danger"
            className="search-btn"
            size="sm"
            onClick={() => this.#clearReferral()}
          >
            <MDBIcon icon="times-circle" />
          </MDBBtn>
        )}
        <ul
          id="referralSearchResults"
          className={
            !this.props.disabled &&
            this.state.show_results &&
            this.state.referral_partner &&
            referral_partners.length
              ? 'open'
              : ''
          }
        >
          {referral_partners.map((p) => (
            <li key={p.id} onClick={() => this.#selectReferral(p.id)}>
              {p.name}
            </li>
          ))}
        </ul>
        <MDBModal
          id="ReferralPartnerModal"
          size="lg"
          isOpen={!this.props.disabled && this.state.is_open}
          toggle={() => this.#toggleReferralModal()}
        >
          <MDBModalHeader>
            {this.state.referral_partner_id ? 'Update' : 'Create'} Referral
            Partner
          </MDBModalHeader>
          <MDBModalBody>
            <div className="form-row">
              <MDBCol size="12" lg="6">
                <MDBInput
                  label="Referral Partner Name"
                  name="rp_name"
                  id={this.state.rp_name ? this.state.rp_name.toString() : null}
                  value={this.state.rp_name || ''}
                  onChange={this.#onChange}
                />
                {this.#renderErrorField('rp_name')}
              </MDBCol>
              <MDBCol size="12" lg="6">
                <MDBInput
                  label="Referral Partner Company"
                  name="rp_company"
                  id={
                    this.state.rp_company
                      ? this.state.rp_company.toString()
                      : null
                  }
                  value={this.state.rp_company || ''}
                  onChange={this.#onChange}
                />
                {this.#renderErrorField('rp_company')}
              </MDBCol>
            </div>
            <div className="form-row">
              <MDBCol size="12" lg="6">
                <MDBInput
                  label="Referral Partner Email"
                  name="rp_email"
                  id={
                    this.state.rp_email ? this.state.rp_email.toString() : null
                  }
                  value={this.state.rp_email || ''}
                  onChange={this.#onChange}
                />
                {this.#renderErrorField('rp_email')}
              </MDBCol>
              <MDBCol size="12" lg="6">
                <MDBInput
                  label="Referral Partner Phone"
                  name="rp_phone"
                  id={
                    this.state.rp_phone ? this.state.rp_phone.toString() : null
                  }
                  value={this.state.rp_phone || ''}
                  onChange={this.#onChange}
                />
                {this.#renderErrorField('rp_phone')}
              </MDBCol>
            </div>
            <div className="form-row">
              <MDBCol size="12" lg="6">
                <MDBInput
                  label="Products Sold"
                  name="rp_products_sold"
                  id={
                    this.state.rp_products_sold
                      ? this.state.rp_products_sold.toString()
                      : null
                  }
                  value={this.state.rp_products_sold || ''}
                  onChange={this.#onChange}
                />
                {this.#renderErrorField('rp_products_sold')}
              </MDBCol>
              <MDBCol size="12" lg="6">
                <MDBSelect
                  options={isRefLicensed}
                  value={this.state.rp_is_licensed || '1'}
                  label="Is Referral Partner Licensed?"
                  getValue={(value) =>
                    this.#setFormVal('rp_is_licensed', value[0])
                  }
                />
                {this.#renderErrorField('rp_is_licensed')}
              </MDBCol>
            </div>
            <MDBCol>
              <MDBCollapse isOpen={this.state.errorsExist}>
                <MDBAlert color="danger">
                  Please correct all invalid inputs fields before attempting to
                  save your Referral Partner.
                </MDBAlert>
              </MDBCollapse>
            </MDBCol>
            <div className="form-row">
              <MDBCol size="12" md="6">
                <MDBBtn
                  disabled={this.state.submitting}
                  color="primary"
                  size="sm"
                  onClick={() => this.#onSubmit()}
                  className="btn-block"
                >
                  {this.state.submitting ? 'Submitting...' : 'Submit'}
                </MDBBtn>
              </MDBCol>
              <MDBCol size="12" md="6">
                <MDBBtn
                  disabled={this.state.submitting}
                  color="secondary"
                  size="sm"
                  onClick={() => this.#toggleReferralModal()}
                  className="btn-block"
                >
                  Cancel
                </MDBBtn>
              </MDBCol>
            </div>
          </MDBModalBody>
        </MDBModal>
      </div>
    )
  }
}

export default ReferralPartnerInput
