const MEGA_BYTE = 1024 * 1024

export const allowedSizeByUploadType = {
  nipr_pdb_report: 10 * MEGA_BYTE,
  contract_add_doc: 10 * MEGA_BYTE,
  contract_override: 10 * MEGA_BYTE,
  referral_partner_w9: 10 * MEGA_BYTE,
  state_license: 10 * MEGA_BYTE,
  voided_check: 10 * MEGA_BYTE,
}
