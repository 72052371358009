import AdminApi from './../../api/admin-api/admin-api'

const storePaymentMethod = async (
  userId,
  customerId,
  stripeObj,
  cardElement
) => {
  let tokenReq, token

  try {
    tokenReq = await stripeObj.createToken(cardElement)
  } catch (ex) {
    console.error(
      'ERROR: Failed to obtain exchange token for stripe payment method.',
      ex
    )
    throw ex
  }

  if (tokenReq.error) throw tokenReq.error

  try {
    tokenReq = await AdminApi.stripeSaveCard({
      user_id: userId,
      customer_id: customerId,
      source: { source: tokenReq.token.id },
    })
    if (tokenReq.data.apistatus) return tokenReq.data.card
  } catch (ex) {
    console.error(
      'ERROR: Failed to store exchange token for stripe payment method.',
      ex,
      customerId,
      token
    )
    throw ex
  }

  throw tokenReq.data && !tokenReq.data.apistatus
    ? tokenReq.data.msg
    : 'An unknown error occurred attemptng to update the payment method.'
}

const PaymentService = {
  storePaymentMethod: storePaymentMethod,
}

export default PaymentService
