import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../../components/Admin/DashboardLayout'
import { MDBContainer, MDBDataTable } from 'mdbreact'

class ViewGoToMeeting extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const data = {
      columns: [
        {
          label: 'Name',
          field: 'name',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Duration',
          field: 'duration',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'Location',
          field: 'location',
          sort: 'asc',
          width: 270,
        },
      ],
      rows: [
        {
          name: 'Jane Doe',
          duration: '10:49 - 11:57 am',
          location: 'Miami',
        },
        {
          name: 'Jane Doe',
          duration: '10:49 - 11:57 am',
          location: 'Miami',
        },
        {
          name: 'Jane Doe',
          duration: '10:49 - 11:57 am',
          location: 'Miami',
        },
      ],
    }
    return (
      <Fragment>
        <DashboardLayout>
          <main className="adminStyle mainSection">
            <MDBContainer fluid className="mt-5">
              <h2>March 1st - 10:43 am - 83 Min</h2>
              <h3>Attendees</h3>
              <MDBDataTable
                striped
                bordered
                small
                data={data}
                className="text-capitalize"
                entries={50}
                entriesOptions={[50, 100, 150]}
                exportToCSV
              />
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default ViewGoToMeeting
