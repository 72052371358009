import React, { Component } from 'react'
import { MDBBtn, MDBCard, MDBCardHeader, MDBCardBody, MDBCollapse, MDBCollapseHeader } from 'mdbreact'
import { toast } from 'react-toastify'
import AdminApi from '../../../api/admin-api/admin-api'
import moment from "moment"

import './NoteList.scss'

const renderDate = dateStr =>
{
  dateStr = (dateStr ? `${dateStr}`.split(/T| /).shift() : '')
  if (dateStr)
    return moment(dateStr, "YYYY-MM-DD").format("MM/DD/YYYY");
}

class NoteListComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loadingNotes: false,
      noteList: [],
    }
  }

  componentDidMount() {
    if (this.props.email) {
      this.fetchNoteList(this.props.email)
    }
  }

  fetchNoteList = async (email) => {
    if (!email) return
    this.setState({ loadingNotes: true })
    AdminApi.getProspectsNotes({ email })
      .then((result) => {
        if (result && result.data && result.data.notes)
          this.setState({ noteList: result.data.notes })
      })
      .then(() => {
        this.setState({ loadingNotes: false })
      })
      .catch((error) => {
        this.setState({ loadingNotes: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  onClick(note) {
    if (this.props.onSelect && typeof this.props.onSelect === 'function') {
      this.props.onSelect(note)
    }
  }

  renderCollapse = (item, index) => 
  {
    return (
      <React.Fragment key={`prospect-note-${index}`}>
        <MDBCollapseHeader
          onClick={() => {
            this.setState({ noteIdx: index })
          }}
        >
          <div className="note-header">{item.note}</div>
        </MDBCollapseHeader>
        <MDBCollapse isOpen={index === this.state.noteIdx}>
          <div>{item.note}</div>
        </MDBCollapse>
      </React.Fragment>
    )
  }

  renderCard = (item, index) =>
  {
    return (
      <MDBCard key={`prospect-note-${index}`}>
        <MDBCardHeader>
          <div className="note-header">
            <div>
              <small><strong>Recruiter:</strong></small><br />
              {item.recruiter}
            </div>
            <div>
              <small><strong>Claimed On:</strong></small><br />
              {renderDate(item.claimed_at)}
            </div>
          </div>
        </MDBCardHeader>
        <MDBCardBody>
          <div>{item.note}</div>
        </MDBCardBody>
      </MDBCard>
    )
  }

  render() {
    return (
      <div id="NoteListComponent">
        {this.state.loadingNotes ? (
          <div>Loading ...</div>
        ) : (
          this.state.noteList
            .filter(
              (item, idx, self) =>
                !!item.note &&
                self.map((i) => i.note).indexOf(item.note) === idx
            )
            .map(this.props.canCollapse ? this.renderCollapse : this.renderCard)
        )}
      </div>
    )
  }
}

export default NoteListComponent
