import BaseModelFactory from './base.factory'
import PromotionCodeService from './../services/PromotionCode.service'
import PromotionCode from './../models/promotion-code.model'

class PromotionCodeFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: PromotionCodeService, Model: PromotionCode }
    )
  static findAll = async () =>
    await this._findAllBy(
      {},
      { Service: PromotionCodeService, Model: PromotionCode }
    )
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: PromotionCodeService, Model: PromotionCode }
    )
  static findAllById = async (id) =>
    await this._findAllBy(
      { id: id },
      { Service: PromotionCodeService, Model: PromotionCode }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: PromotionCodeService,
      Model: PromotionCode,
    })
}

export default PromotionCodeFactory
