import React, { Component, Fragment } from 'react'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import {
  MDBContainer,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCol,
  MDBRow,
} from 'mdbreact'
import noImage from './../../assets/images/no_contest_image_placeholder.png'
import { toast } from 'react-toastify'
import AdminApi from './../../api/admin-api/admin-api'
import DateService from './../../shared/services/Date.service'

import './LeadPrograms.scss'

class LeadProgramsPage extends Component {
  isActive = (tabName) => this.state.tabName === tabName

  change = (tabName) => {
    if (!this.isActive(tabName)) this.setState({ tabName })
  }

  filterPrograms = (contestList) => {
    const current_time = new Date().getTime()
    return {
      activeContestList: contestList.filter((item) => {
        const con_enddate = new Date(item.con_enddate)
        return con_enddate > current_time
      }),
      expiredContestList: contestList.filter((item) => {
        const con_enddate = new Date(item.con_enddate)
        return con_enddate < current_time
      }),
    }
  }

  fetchProgramData = async () => {
    this.setState({ loading: true })
    AdminApi.getLeadPrograms()
      .then((result) =>
        this.setState({
          ...this.filterPrograms(
            Array.isArray(result?.data?.data) ? result.data.data : []
          ),
          loading: false,
        })
      )
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  renderTab = (tabName) => {
    return (
      <div
        className={
          'tab-pane fade ' + (this.isActive(tabName) ? 'show active' : '')
        }
        role="tabpanel"
      >
        <MDBRow>
          {(this.isActive('active')
            ? this.state.activeContestList
            : this.state.expiredContestList
          ).map((item, i) => {
            return (
              <MDBCol
                lg="4"
                md="6"
                className="mb-4 contest-box-wrapper-col"
                key={i}
              >
                <MDBCard>
                  <MDBCardImage
                    className="img-fluid"
                    src={item.con_image || noImage}
                    waves
                    style={{ width: '100%' }}
                  />
                  <MDBCardBody>
                    <MDBCardTitle>{item.con_name}</MDBCardTitle>
                    <h6>
                      {DateService.format(
                        DateService.dateOnly(item.con_startdate),
                        'MMM D, YYYY'
                      )}{' '}
                      -{' '}
                      {DateService.format(
                        DateService.dateOnly(item.con_enddate),
                        'MMM D, YYYY'
                      )}
                    </h6>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.con_description }}
                    ></div>
                    <MDBBtn
                      color="indigo"
                      href={item.con_file_info || ''}
                      target="_blank"
                    >
                      View Details
                    </MDBBtn>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            )
          })}
        </MDBRow>
      </div>
    )
  }

  constructor(props) {
    super(props)
    this.state = {
      tabName: 'active',
      loading: false,
      activeContestList: [],
      expiredContestList: [],
    }
  }

  componentDidMount() {
    this.fetchProgramData()
  }

  render() {
    toast.configure()
    const { loading } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection" id="LeadProgramsPage">
            <MDBContainer fluid className="mt-5">
              {loading ? (
                <h2>Loading...</h2>
              ) : (
                <>
                  <h2 className="mt-4">
                    Lead Credit Programs for USABG Signature Agents Only
                  </h2>
                  <hr />
                  <MDBRow>
                    <MDBCol size="12">
                      <ul
                        className="nav nav-tabs md-tabs"
                        id="myTabMD"
                        role="tablist"
                      >
                        <li className="nav-item waves-effect waves-light">
                          <a
                            className={
                              'nav-link ' +
                              (this.isActive('active') ? 'active' : '')
                            }
                            id="current-tab-md"
                            data-toggle="tab"
                            href="#current-md"
                            role="tab"
                            aria-controls="home-md"
                            aria-selected={this.isActive('active')}
                            onClick={() => this.change('active')}
                          >
                            Current
                          </a>
                        </li>
                        <li className="nav-item waves-effect waves-light">
                          <a
                            className={
                              'nav-link ' +
                              (this.isActive('history') ? 'active' : '')
                            }
                            id="history-tab-md"
                            data-toggle="tab"
                            href="#history-md"
                            role="tab"
                            aria-controls="profile-md"
                            aria-selected={this.isActive('history')}
                            onClick={() => this.change('history')}
                          >
                            Past
                          </a>
                        </li>
                      </ul>
                      <div
                        className="tab-content card pt-5"
                        id="mymdbTabContentMD"
                      >
                        {this.renderTab('active')}
                        {this.renderTab('history')}
                      </div>
                    </MDBCol>
                  </MDBRow>
                </>
              )}
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default LeadProgramsPage
