import React from 'react'
import { observer } from 'mobx-react-lite'
import { UIInput, UIInputButton } from './../../forms/form-fields'
import { MDBBtn, MDBIcon } from 'mdbreact'
import Store from './../store'

import './Search.component.scss'

const Search = () => {
  const onSearchKeyDown = (evt) => {
    if (evt.key === 'Enter' && Store.searchTerm.length > 0)
      Store.searchAgentLedgers({})
  }

  const onSubmit = (event) => {
    event.preventDefault()
    return false
  }

  const SearchInput = () => {
    return (
      <UIInput
        type="text"
        label="Search Agent Wallets"
        value={Store.searchTerm}
        onKeyDown={onSearchKeyDown}
        onChange={(e) => (Store.searchTerm = e.target.value)}
        style={{ color: '#fff' }}
      />
    )
  }

  const SearchButton = () => {
    return (
      <>
        <MDBBtn
          disabled={Store.isLoading || Store.isSearching || !!Store.Ledger}
          onClick={() => Store.searchAgentLedgers({})}
        >
          <MDBIcon icon="search" />
          <small>
            Search
            <wbr />
            Wallets
          </small>
        </MDBBtn>
        <MDBBtn
          disabled={Store.isLoading || Store.isSearching || !!Store.Ledger}
          onClick={() => Store.createAgentLedger()}
        >
          <MDBIcon icon="plus" />
          <small>
            New
            <wbr />
            Wallet
          </small>
        </MDBBtn>
      </>
    )
  }

  return (
    <form id="SearchComponent" onSubmit={(event) => onSubmit(event)}>
      <UIInputButton
        input={SearchInput()}
        button={SearchButton()}
        btnPosition={'right'}
      />
    </form>
  )
}

export default observer(Search)
