import UserProfileService from './../services/UserProfile.service';
import UserEopolicyService from './../services/UserEopolicy.service';
import SystemSettingService from './../services/SystemSetting.service';

async function setGroupEandO ()
{
	if (UserProfileService.isA(['signature-group'])) {
		let Policy, vendor;
		try {
			Policy = ((await UserEopolicyService.search({search: {user_id: UserProfileService.getUserId()}, pagination: false, orderBy: {expiration_date: 'DESC'}}))?.models||[]).shift();
		}
		catch (ex) {
			console.log('ex ',ex);
		}

		if (Policy)
			return Policy;

		try {
			vendor = ((await SystemSettingService.search({search: {setting_key: 'vendors---eo-policy-coverage'}}))?.models||[]).shift()
			vendor = vendor ? JSON.parse(vendor.setting_value) : false;
			if (vendor) {
				Policy = await UserEopolicyService.store({user_id: UserProfileService.getUserId(), ...vendor});
				return {...vendor, ...Policy};
			}
		}
		catch (ex) {}
	}	

	return false;
}

export {setGroupEandO}