import React from 'react'
import UsertypeService from './../../../../shared/services/Usertype.service'
import { UIInputToggleSwitch } from './../../../../components/forms/form-fields'
import moment from 'moment'
import {
  MDBBtn,
  MDBContainer,
  MDBDataTable,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from 'mdbreact'

import './AdminTermsTable.component.scss'

export const TERM_SLUGS = {
  'ach-auth-agreement': 'ACH Auth Agreement',
  'leader-guidelines': 'Leader Guidelines',
  'non-captive-non-solicit': 'Non-Captive/Non-Solicit',
  'pip-rules': 'USABG BUCK$ Rules',
  'release-policy': 'Release Policy',
  'sales-tracker-rules': 'Sales Tracker Rules',
  'sig-agent-terms': 'Sig Agent Terms',
  'sig-producer': 'Sig Producer',
  'sig-producer-eo': 'Sig Producer E&O',
  'subscriber-agreement': 'Subscriber Agreement',
}

const TABLE_COLUMNS = [
  {
    label: 'Term Name',
    field: 'term_name',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'Signatures',
    field: 'signatures',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'Is Enrollment Term',
    field: 'is_enroll',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'Is Leader Term',
    field: 'is_leader',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'Is 10x Term',
    field: 'is_10x',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'Usertype Specific',
    field: 'usertype_id',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'Term Type',
    field: 'term_slug',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'Link',
    field: 'link',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'Update',
    field: 'update',
    sort: 'asc',
    width: 270,
  },
  {
    label: 'Delete',
    field: 'delete',
    sort: 'asc',
    width: 270,
  },
  {
    label: 'Created',
    field: 'created_at',
    sort: 'asc',
    width: 270,
  },
]

const AdminTermsTable = ({
  rows,
  usertypes,
  onViewSigs,
  onChange,
  onEdit,
  onDelete,
}) => {
  const renderSigsBtn = (row) => {
    return (
      <div className={'d-flex align-items-center justify-content-center'}>
        <div
          className={'cursor-pointer d-inline-block'}
          role={'button'}
          onClick={() => onViewSigs(row.id)}
        >
          <MDBIcon icon="pencil-alt" size="2x" className="purple-text p-1" />
        </div>
      </div>
    )
  }

  const renderLink = (row) => {
    if (row?.term_link)
      return (
        <div className={'d-flex align-items-center justify-content-center'}>
          <a
            href={row?.term_link}
            target="_blank"
            rel="noopener noreferrer"
            className={'cursor-pointer d-inline-block'}
          >
            <MDBIcon icon="link" size="2x" className="green-text p-1" />
          </a>
        </div>
      )
  }

  const renderEditBtn = (row) => {
    return (
      <div className={'d-flex align-items-center justify-content-center'}>
        <div
          className={'cursor-pointer d-inline-block'}
          role={'button'}
          onClick={() => onEdit(row.id)}
        >
          <MDBIcon icon="edit" size="2x" className="blue-text p-1" />
        </div>
      </div>
    )
  }

  const renderDeleteBtn = (row) => {
    return (
      <div className={'d-flex align-items-center justify-content-center'}>
        <div
          className={'cursor-pointer d-inline-block'}
          role={'button'}
          onClick={() => onDelete(row)}
        >
          <MDBIcon icon="trash" size="2x" className="red-text p-1" />
        </div>
      </div>
    )
  }

  const renderToggle = (field, row) => {
    let checked = row.hasOwnProperty(field) && !!row[field]

    return (
      <UIInputToggleSwitch
        id={`admin_term_${field}_${row?.id}`}
        name={`admin_term_${field}_${row?.id}`}
        value={checked}
        width="70"
        labelOn="ON"
        labelOff="OFF"
        onChange={(e) =>
          onChange({ id: row.id, [field]: !!e.target.value ? 1 : 0 })
        }
      />
    )
  }

  const renderDropdown = (row) => {
    return (
      <MDBDropdown>
        <MDBDropdownToggle>
          {!row?.usertype_id
            ? 'All Usertypes'
            : UsertypeService.idToName(row.usertype_id)}
        </MDBDropdownToggle>
        <MDBDropdownMenu>
          <MDBDropdownItem
            onClick={() => onChange({ id: row.id, usertype_id: null })}
          >
            All Usertypes
          </MDBDropdownItem>
          {usertypes.map((usertype, idx) => (
            <MDBDropdownItem
              onClick={() => onChange({ id: row.id, usertype_id: usertype.id })}
              key={`admin-term-usertype-${usertype?.id}-${idx}`}
            >
              {usertype?.displayname}
            </MDBDropdownItem>
          ))}
        </MDBDropdownMenu>
      </MDBDropdown>
    )
  }

  const renderTermSlug = (row) => {
    return (
      <MDBDropdown>
        <MDBDropdownToggle>
          {!row?.term_slug
            ? 'Please Select Term Type'
            : TERM_SLUGS[row.term_slug]}
        </MDBDropdownToggle>
        <MDBDropdownMenu>
          {Object.keys(TERM_SLUGS).map((termSlug, idx) => (
            <MDBDropdownItem
              onClick={() => onChange({ id: row.id, term_slug: termSlug })}
              key={`admin-term-termslug-${termSlug}-${idx}`}
            >
              {TERM_SLUGS[termSlug]}
            </MDBDropdownItem>
          ))}
        </MDBDropdownMenu>
      </MDBDropdown>
    )
  }

  const formatRow = (row) => ({
    ...row,
    signatures: renderSigsBtn(row),
    is_enroll: renderToggle('is_enroll', row),
    is_leader: renderToggle('is_leader', row),
    is_10x: renderToggle('is_10x', row),
    usertype_id: renderDropdown(row),
    term_slug: renderTermSlug(row),
    link: renderLink(row),
    update: renderEditBtn(row),
    delete: renderDeleteBtn(row),
    created_at: moment(row.created_at).local().format('MM/DD/YYYY'),
  })

  return (
    <div id="AdminTermsTableComponent">
      <MDBDataTable
        entries={25}
        entriesOptions={[25, 50, 100]}
        striped
        bordered
        small
        data={{ columns: TABLE_COLUMNS, rows: (rows || []).map(formatRow) }}
        className="text-capitalize"
      />
    </div>
  )
}

export default AdminTermsTable
