import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import MetricsIntakeStore from './../../store'
import PaginationComponent from './../../../../components/adminShared/Pagination.component'
import moment from 'moment'
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBSwitch,
} from 'mdbreact'

import './MetricsHistoryTable.scss'

const MetricsHistoryTable = (props) => {
  const [type, setType] = useState('performance')
  const [models, setModels] = useState([])
  const [pagination, setPagination] = useState({})
  const { fetch } = MetricsIntakeStore

  const formatEvents = (entries) => {
    let rows = [],
      dates = [],
      events = [],
      attended = []

    // 1. get all dates.
    entries.events.models
      .map((event) =>
        moment.tz(event.starts_at, event.timezone).format('MM/DD/YYYY')
      )
      .forEach((date) => {
        if (dates.indexOf(date) < 0) dates.push(date)
      })

    // 2. get all events.
    // entries.events, entries.attendance
    entries.events.models.forEach((event) => {
      if (events.indexOf(event.summary) < 0) events.push(event.summary)
    })

    // 3. get all attended event IDs.
    attended = entries.attendance.map((e) => e.event_id)

    // 4. build rows.
    rows = dates.map((date) => {
      let evts = entries.events.models.filter(
        (e) => moment.tz(e.starts_at, e.timezone).format('MM/DD/YYYY') === date
      )
      return [date].concat(
        events.map((event) => {
          let evt = evts.filter((e) => e.summary === event).shift()
          if (!evt) return ''
          return attended.indexOf(evt.event_id) > -1 ? (
            <>
              <div className="cell-wrapper attended">Yes</div>
            </>
          ) : (
            <>
              <div className="cell-wrapper unattended">No</div>
            </>
          )
        })
      )
    })

    return { rows, columns: ['Attendance Date'].concat(events.concat([''])) }
  }

  const onPageChange = (page, per_page) => {
    fetch(type, { page, per_page }).then((entries) => {
      if (type === 'performance') {
        setModels(entries.models)
        setPagination((pagination) => ({
          ...pagination,
          performance: entries.pagination,
        }))
      } else if (type === 'attendance') {
        setModels(formatEvents(entries))
        setPagination((pagination) => ({
          ...pagination,
          attendance: entries.events.pagination,
        }))
      }
    })
  }

  const removeDuplicateRecords = (models) => {
    const arr = []
    models.reduce((acc, curr) => {
      if (acc.indexOf(curr.entry_date) === -1) {
        acc.push(curr.entry_date)
        arr.push(curr)
      }
      return acc
    }, [])
    return arr
  }

  useEffect(() => {
    fetch(type).then((entries) => {
      if (type === 'performance') {
        setModels(removeDuplicateRecords(entries.models))
        setPagination((pagination) => ({
          ...pagination,
          performance: entries.pagination,
        }))
      } else if (type === 'attendance') {
        setModels(formatEvents(entries))
        setPagination((pagination) => ({
          ...pagination,
          attendance: entries.events.pagination,
        }))
      }
    })
  }, [fetch, type])

  const renderTypeToggle = (event) => (
    <MDBSwitch
      labelLeft={'Performance'}
      labelRight={'Attendance'}
      checked={type === 'attendance'}
      onChange={(e) =>
        setType((type) =>
          type === 'performance' ? 'attendance' : 'performance'
        )
      }
    />
  )

  return (
    <>
      <MDBCard id="MetricsHistoryTable">
        <MDBCardHeader>
          <MDBRow>
            <MDBCol>Historical Metrics&nbsp;</MDBCol>
            <MDBCol>{renderTypeToggle()}</MDBCol>
          </MDBRow>
        </MDBCardHeader>
        <MDBCardBody>
          <div className="table-responsive">
            <table className="table table-bordered text-center">
              <thead>
                <tr>
                  {type === 'performance' ? (
                    <>
                      <th>Performance Date</th>
                      <th>
                        HW<small>Hours Worked</small>
                      </th>
                      <th>
                        CA<small>Calls Completed</small>
                      </th>
                      <th>
                        EA<small>Events Attended</small>
                      </th>
                      <th>
                        ST<small>Customers Spoken To</small>
                      </th>
                      <th>
                        FF<small>Fact Finders Entered</small>
                      </th>
                      <th>
                        AS<small>Appointments Set</small>
                      </th>
                      <th>
                        PO<small>Presentations Offered</small>
                      </th>
                      <th>
                        RR<small>Referrals Requested</small>
                      </th>
                      <th>
                        LI<small>Lives Quoted</small>
                      </th>
                      <th>
                        <small>Edit</small>
                      </th>
                    </>
                  ) : (
                    <></>
                  )}
                  {type === 'attendance' ? (
                    <>
                      {models?.columns &&
                        models.columns.map((event) => (
                          <th key={'event-' + event}>{event}</th>
                        ))}
                    </>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                {type === 'performance' &&
                  Array.isArray(models) &&
                  models.map((metric) => (
                    <tr key={'perf-metric-' + metric.id}>
                      <td>
                        <div className={'cell-wrapper'}>
                          {moment(metric.entry_date.split('T')[0]).format(
                            'M/DD/YYYY'
                          )}
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            'cell-wrapper ' +
                            (metric.hours_worked > 0 ? ' completed' : '')
                          }
                        >
                          {metric.hours_worked}
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            'cell-wrapper ' +
                            (metric.calls > 0 ? ' completed' : '')
                          }
                        >
                          {metric.calls}
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            'cell-wrapper ' +
                            (metric.events_attended > 0 ? ' completed' : '')
                          }
                        >
                          {metric.events_attended}
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            'cell-wrapper ' +
                            (metric.spoken_to > 0 ? ' completed' : '')
                          }
                        >
                          {metric.spoken_to}
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            'cell-wrapper ' +
                            (metric.fact_finders > 0 ? ' completed' : '')
                          }
                        >
                          {metric.fact_finders}
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            'cell-wrapper ' +
                            (metric.appts_set > 0 ? ' completed' : '')
                          }
                        >
                          {metric.appts_set}
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            'cell-wrapper ' +
                            (metric.presentations_offered > 0
                              ? ' completed'
                              : '')
                          }
                        >
                          {metric.presentations_offered}
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            'cell-wrapper ' +
                            (metric.referrals_requested > 0 ? ' completed' : '')
                          }
                        >
                          {metric.referrals_requested}
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            'cell-wrapper ' +
                            (metric.life_quoted > 0 ? ' completed' : '')
                          }
                        >
                          {metric.life_quoted}
                        </div>
                      </td>
                      <td
                        onClick={() => {
                          props.editByDate(
                            moment(
                              metric.entry_date.split(/T|\s/).shift()
                            ).format('YYYY-MM-DD')
                          )
                        }}
                      >
                        <div className={'cell-wrapper'}>
                          <i className="fa fa-pencil-alt blue-text p-1 cursor-pointer"></i>
                        </div>
                      </td>
                    </tr>
                  ))}
                {type === 'attendance' &&
                  models?.rows &&
                  models.rows.map((row, r) => (
                    <tr key={'perf-metric-' + r}>
                      {row.map((r, i) => (
                        <td key={'td-' + i}>{r}</td>
                      ))}
                      <td onClick={() => props.editByDate(row[0])}>
                        <div className={'cell-wrapper'}>
                          <i className="fa fa-pencil-alt blue-text p-1 cursor-pointer"></i>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td
                    colSpan={
                      type === 'performance'
                        ? 9
                        : type === 'attendance' && models?.rows
                        ? models.rows.length + 3
                        : 9
                    }
                  >
                    {pagination.performance && type === 'performance' && (
                      <PaginationComponent
                        pagination={pagination.performance}
                        onPageSelect={onPageChange}
                      />
                    )}
                    {pagination.attendance && type === 'attendance' && (
                      <PaginationComponent
                        pagination={pagination.attendance}
                        onPageSelect={onPageChange}
                      />
                    )}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </MDBCardBody>
      </MDBCard>
    </>
  )
}

export default observer(MetricsHistoryTable)
