import React from 'react'
import {
  MDBBtn, MDBIcon, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter
} from 'mdbreact'
import TechToolsService from './../../shared/TechTools.service';
import { Subscription } from 'rxjs';

import "./DeniedAccessModal.scss";

class DeniedAccessModal extends React.Component {

	state = {
		isOpen: 		false,
		title:  		"",
		body: 			<></>
	}

	#_subscriptions$ = new Subscription();

	#upgrade = () => 
	{
		window.location = "/account-billinginfo";
	}

  #toggleDenialModal = denial =>
  {
  	if (!denial || denial === null) {
  		if (this.state.isOpen)
  			this.setState({isOpen: false});
  		return;
  	}

  	const getFeature = () =>
  	{
  		if (denial?.tool_title && denial?.tool_subtitle)
  			return <>{denial?.tool_title}<br />{denial?.tool_subtitle}</>
  		return <>{denial?.tool_title}</>
  	}

  	this.setState({
  		isOpen: 		true,
  		title: 			denial.denial,
  		body: 			<>
										<table>
											<tbody>
												<tr>
													<td valign='middle' colSpan='2'>We're sorry, but your account does not provide access to the following feature:</td>
												</tr>
											</tbody>
										</table>
										<br />
										<table>
											<tbody>
												<tr>
													<td valign='middle'><div className="h5"><MDBIcon icon="exclamation-triangle" className="text-danger" /></div></td>
													<td valign='middle'>&nbsp;{getFeature()}</td>
												</tr>
											</tbody>
										</table>
										<br />
										<table>
											<tbody>
												<tr>
													<td valign='middle' colSpan='2'><MDBBtn onClick={() => this.#upgrade()} className="upgrade-btn">Click Here to see Agent Package Benefits & upgrade your account!</MDBBtn></td>
												</tr>
											</tbody>
										</table>
									</>
  	});
  }

	#closeModal = () => TechToolsService.resetAccessDenied()

	componentDidMount () 
	{
		this.#_subscriptions$.add(TechToolsService.getDeniedAccessEvent().subscribe(denial => this.#toggleDenialModal(denial)));
	}

	componentWillUnmount ()
	{
		this.#_subscriptions$.unsubscribe();
	}

	render ()
	{
		return (
				<>
					<MDBModal id="DeniedAccessModal" size='lg' isOpen={this.state.isOpen} toggle={this.#closeModal} className="error-modal">
						<MDBModalHeader>
							{this.state.title}!
						</MDBModalHeader>
						<MDBModalBody>
							{<><div className="modal-message">{this.state.body}</div></>}
						</MDBModalBody>
						<MDBModalFooter>
							<MDBBtn className="btn-block" onClick={this.#closeModal}>CLOSE</MDBBtn>
						</MDBModalFooter>
					</MDBModal>
				</>
			)
	}
}

export default DeniedAccessModal;
