import React from 'react'
import { MDBContainer, MDBDataTable, MDBIcon, MDBInput } from "mdbreact";
import {toast} from "react-toastify";
import moment from "moment";
import { Subscription, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import MessageService from "./../../../../shared/services/Message.service";
import PaginationComponent from "./../../../../components/adminShared/Pagination.component";

import "./MessagesList.scss";

const timezoneOffset = new Date().getTimezoneOffset();

class MessagesList extends React.Component 
{
	state = {
	  loading: 					true,
	  messagesList: 		[],
	  searched: false,
	  messages: 				[],
	  pagination: 			{},

	  search: 					"",
	  tableData: {
		  columns: [
		    {
				label: 'Message Title',
				field: 'title',
				sort: 'asc',
				width: 150
		    },
		    {
				label: 'Category',
				field: 'category',
				sort: 'asc',
				width: 150
		    },
		    {
				label: 'Updated Date',
				field: 'updated_at',
				sort: 'asc',
				width: 150
		    },
			{
				label: 'Start Date',
				field: 'start_date',
				sort: 'asc',
				width: 150
			},
			{
				label: 'End Date',
				field: 'end_date',
				sort: 'asc',
				width: 150
			},
		    {
				label: 'Edit',
				field: 'edit',
				sort: 'disabled',
				width: 150
		    }
		  ],
	    rows: [],
	  }
	}


	#_searchSubject = new BehaviorSubject("");
	#_subscriptions$ = new Subscription();

	componentDidMount ()
	{
		this.#_subscriptions$.add(MessageService.getMessages(0,10, this.props?.division_id).subscribe(messages => this.#onMessageFetch(messages)));
		this.#_subscriptions$.add(this.#_searchSubject.pipe(debounceTime(250), distinctUntilChanged()).subscribe(term => this.#handleSearch(term)));
	}

	componentWillUnmount ()
	{
		this.#_subscriptions$.unsubscribe();
	}

	#onMessageFetch = async (messages) => 
	{
		if (typeof messages === 'object' && Object.keys(messages).length)
			this.setState(Object.assign({loading: false}, messages), this.#setRows);
	}

	#fetchMessagesData = async (page = 0, perPage = 10) => 
	{
		MessageService.getMessages(page, perPage, this.props?.division_id)
	}

	#editMessage = id => 
	{
		if (typeof this.props.onEdit === 'function')
			this.props.onEdit(id);
	}

	#buildRows = () =>
	{
		const {messages} 			= 		this.state;

		return messages && Array.isArray(messages)
									? 	messages.map((row) => {
													const {title, category, updated_at, id, start_date, end_date, deleted_at} = row,
																editBtn = (
																	<div className={'d-flex align-items-center justify-content-center'}>
																		<div className={'cursor-pointer d-inline-block'} role={'button'} onClick={() => this.#editMessage(id)}>
																			<MDBIcon icon={deleted_at?'eye':'edit'} size="2x" className="blue-text p-1" />
																		</div>
																	</div>
																),
																formatedDate = (
																	<span searchvalue={updated_at ? moment(updated_at).format('YYYY/MM/DD') : ''}>
																		{updated_at ? moment(updated_at).format('MM/DD/YYYY') : 'N/A'}
																	</span>
																),
																formatedStartDate = (
																	<span searchvalue={start_date ? moment(new Date(new Date(start_date).getTime() + timezoneOffset * 60000)).format('YYYY/MM/DD') : ''}>
																		{start_date ? moment(new Date(new Date(start_date).getTime() + timezoneOffset * 60000)).format('MM/DD/YYYY') : 'N/A'}
																	</span>
																),
																formatedEndDate = (
																	<span searchvalue={end_date ? moment(new Date(new Date(end_date).getTime() + timezoneOffset * 60000)).format('YYYY/MM/DD') : ''}>
																		{end_date ? moment(new Date(new Date(end_date).getTime() + timezoneOffset * 60000)).format('MM/DD/YYYY') : 'N/A'}
																	</span>
																)

													return {
														title: title,
														category: category,
														updated_at: formatedDate,
														start_date:	formatedStartDate,
														end_date: formatedEndDate,
														edit: editBtn
													}
												})
									: 	[];
	}

	#setRows = () => 
		this.setState({tableData: {...this.state.tableData, rows: this.#buildRows()}});

	#handleSearch = async (term) => {
		if (this.state.searched) {
			term ? MessageService.searchMessages(term, this.props?.division_id) : MessageService.getMessages(0,10, this.props?.division_id);
		}
	}

	#searchMessages = async (term) => {
		this.setState({ searched: true });
		this.setState({search: term.target.value}, () => {this.#_searchSubject.next(this.state.search)});
	}

	#onPageChange = (page) =>
	{
		this.setState({loading: true});
		this.#fetchMessagesData(page);
	}

  render () 
  {
		toast.configure();

		const {tableData, loading, pagination, search} 	= 		this.state;

    return (
			<MDBContainer id="MessagesListComponent" fluid>
			{
				loading 
					? <h4 className={'p4'}>Loading...</h4> 
					: <div className="table-wrapper">
							<MDBInput label="Search Messages"
												name="search"
												id='manage-msg-search'
												value={search}
												onChange={this.#searchMessages} />
							<MDBDataTable searching={false} paging={false} sortRows={['updated_at', 'start_date', 'end_date']} striped bordered small data={tableData} className="text-capitalize" />
							{
								(pagination && parseInt(pagination.total)) && 
										<><div className="pagination-wrapper"><PaginationComponent pagination={pagination} onPageSelect={(page, perPage) => this.#onPageChange(page, perPage)} /></div></>
							}
						</div>
			}
			</MDBContainer>
		);
	}
}

export default MessagesList;
