import { PromotionCodesPage } from './index'

import { RouteFactory } from './../../shared/router/Route.factory'
import {
  RequiresAuthGuard,
  RequiresUsertypesGuard,
} from './../../shared/router/guards'

export default [
  RouteFactory.create(
    PromotionCodesPage,
    '/admin/promotion-codes',
    [
      new RequiresAuthGuard(true),
      new RequiresUsertypesGuard([
        'agency-owner',
        'internal-admin',
        'internal-staff',
        'system-admin',
      ]),
    ],
    {
      name: 'Promotion Codes',
    }
  ),
]
