import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { MDBBtn } from 'mdbreact'
import UserProfileService from '../../../../shared/services/UserProfile.service'
import UserService from '../../../../shared/services/User.service'

const ResetUplineSignatureButton = ({ onReset }) => {
  const [isLoading, setIsLoading] = useState(false)

  const meetsRequirement =
    UserProfileService.isAssumed() &&
    (UserProfileService.isA('internal-admin', true) ||
      UserProfileService.isA('system-admin', true) ||
      UserProfileService.isA('agency-owner', true))

  const resetUplineSignature = async () => {
    setIsLoading(true)

    try {
      await UserService.resetUplineSignature(UserProfileService.getUserId())
      onReset(true)
      setIsLoading(false)
      UserProfileService.track({
        event_type: 'user.reset-upline-signature.success',
      })
    } catch (ex) {
      onReset(ex?.message || `${ex}`)
      setIsLoading(false)
      UserProfileService.track({
        event_type: 'user.reset-upline-signature.failure',
      })
    }
  }

  const renderConfig = () => {
    if (meetsRequirement)
      return (
        <div className="reset-agent-upline-btn">
          <MDBBtn
            disabled={isLoading}
            className="btn-block"
            onClick={() => resetUplineSignature()}
            sm="true"
            color="danger"
          >
            {isLoading ? (
              <>
                <i className="fa fa-spin fa-spinner"></i>&nbsp;Resetting ...
              </>
            ) : (
              'Reset Upline Signature'
            )}
          </MDBBtn>
        </div>
      )
    return <></>
  }

  return <>{renderConfig()}</>
}

export default observer(ResetUplineSignatureButton)
