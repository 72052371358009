import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { MDBBtn, MDBIcon } from 'mdbreact'
import ACMStore from './../AgentContractManager.store'
import { getMetaValue } from './../getMetaValue.function'

import './ContractingViewStateChangeButton.scss'

const DEFAULT_VIEW = 'manage'

const ContractingViewStateChangeButton = ({
  activeView,
  onToggle,
  isShopping,
}) => {
  const metaIsSet = !!ACMStore.ActiveView,
    [view, setView] = useState({
      name: null,
      goto: null,
      is_open: false,
      is_initialized: false,
    })

  const renderButtonLabel = () => (
    <table>
      <tbody>
        <tr>
          <td valign="middle" rowSpan="2">
            <MDBIcon
              icon={activeView === 'shop' ? 'shopping-cart' : 'file-pdf'}
            />
          </td>
          <td valign="middle">
            {activeView === 'shop' ? 'View My' : 'Shop New'}
          </td>
        </tr>
        <tr>
          <td valign="middle">Contracts</td>
        </tr>
      </tbody>
    </table>
  )

  // Initialize View Toggler
  useEffect(() => {
    if (metaIsSet && !view.is_initialized) {
      setView((prevState) => ({
        ...prevState,
        name: getMetaValue(ACMStore?.ActiveView, 'string') || DEFAULT_VIEW,
        is_initialized: true,
        is_open: true,
      }))
      onToggle(
        getMetaValue(ACMStore?.ActiveView, 'string') || DEFAULT_VIEW,
        true
      )
    }
  }, [metaIsSet, view.is_initialized, onToggle])

  return (
    <div id="ContractingViewStateChangeButtonComponent">
      <MDBBtn
        onClick={() => {
          setView((prevState) => ({
            ...prevState,
            name: getMetaValue(ACMStore?.ActiveView, 'string') || DEFAULT_VIEW,
            is_open: true,
          }))
          onToggle(activeView === 'shop' ? 'manage' : 'shop')
        }}
        block
        color="indigo"
        size="lg"
        className="shop-contracts-btn"
      >
        {renderButtonLabel()}
      </MDBBtn>
    </div>
  )
}

export default observer(ContractingViewStateChangeButton)
