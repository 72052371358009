import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  MDBAlert,
  MDBIcon,
  MDBSwitch,
  MDBRow,
  MDBCol,
  MDBContainer,
} from 'mdbreact'

import './ToggleWalletLock.component.scss'

const ToggleWalletLock = ({ ControlStore }) => {
  const toggle = async () => {
    try {
      await ControlStore.toggleWalletLock()
    } catch (ex) {
      console.error(`${ex}`)
    }
  }

  const getColor = () => {
    if (ControlStore.isLoading) return 'warning'
    if (ControlStore.isWalletLocked === false) return 'success'
    if (ControlStore.isWalletLocked === true) return 'danger'
    return 'secondary'
  }

  return (
    <MDBAlert color={getColor()}>
      <MDBContainer
        fluid
        id="ToggleWalletLockComponent"
        className={
          ControlStore.isLoading
            ? 'is-loading'
            : ControlStore.isWalletLocked === true
            ? 'is-activated'
            : 'is-deactivated'
        }
      >
        <MDBRow>
          <MDBCol size="12" className="label-wrapper">
            <label>
              Master Wallet Lock&nbsp;
              {ControlStore.isLoading ? (
                <>
                  <span>
                    <i className="fa fa-spin fa-spinner"></i>
                  </span>
                </>
              ) : (
                <></>
              )}
              <br />
              <small>
                <strong>
                  (Wallet is{' '}
                  {ControlStore.isWalletLocked === true ? (
                    <>
                      LOCKED <MDBIcon icon="lock" />
                    </>
                  ) : (
                    <>
                      UNLOCKED <MDBIcon icon="unlock" />
                    </>
                  )}
                  )
                </strong>
              </small>
            </label>
          </MDBCol>
          <MDBCol size="12" className="switch-wrapper">
            <MDBSwitch
              labelLeft={
                ControlStore.isWalletLocked === true ? (
                  <span style={{ color: '#a10a0a', fontWeight: 600 }}>
                    Locked
                  </span>
                ) : (
                  <span>Lock</span>
                )
              }
              labelRight={
                ControlStore.isWalletLocked === false ? (
                  <span style={{ color: '#1c802b', fontWeight: 600 }}>
                    Unlocked
                  </span>
                ) : (
                  <span>Unlock</span>
                )
              }
              disabled={!!ControlStore.isLoading}
              checked={ControlStore.isWalletLocked === false}
              onChange={toggle}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBAlert>
  )
}

export default observer(ToggleWalletLock)
