import BaseModelFactory from './base.factory'
import KnowledgebaseCategoryService from './../services/KnowledgebaseCategory.service'
import KnowledgebaseCategory from './../models/knowledgebase-category.model'

class KnowledgebaseCategoryFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: KnowledgebaseCategoryService, Model: KnowledgebaseCategory }
    )
  static findById = async (id) =>
    await this._findBy(
      { id },
      { Service: KnowledgebaseCategoryService, Model: KnowledgebaseCategory }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: KnowledgebaseCategoryService,
      Model: KnowledgebaseCategory,
    })
}

export default KnowledgebaseCategoryFactory
