import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { MDBCollapse, MDBContainer, MDBRow, MDBIcon } from 'mdbreact'
import AgentContractManagerStore from './../AgentContractManager.store'
import { getMetaValue } from './../getMetaValue.function'

import './EnableExternalCarrierToggleCheckbox.scss'

const EnableExternalCarrierToggleCheckbox = ({disabled}) => {
  let CartMeta = (AgentContractManagerStore?.CartMetas || [])
    .filter(
      (CartMeta) => CartMeta.get('meta_key') === 'cart---enable-ext-carriers'
    )
    .shift()

  const shouldBeVisible =
      AgentContractManagerStore?.Cart &&
      !isNaN(AgentContractManagerStore?.Cart?.id()),
    shouldBeOpen = shouldBeVisible
      ? AgentContractManagerStore.ExternalCarriers.length === 0
      : false,
    [isVisible, setIsVisible] = useState(false),
    [isOpen, setIsOpen] = useState(false),
    [isSaving, setIsSaving] = useState(false),
    [isChecked, setIsChecked] = useState(
      !!(
        (shouldBeVisible || isVisible) &&
        CartMeta &&
        getMetaValue(CartMeta, 'boolean') === true
      )
    )

  const enable = async () => {
    if (isSaving) return

    const Cart =
      AgentContractManagerStore?.Cart?.isNew() === false
        ? AgentContractManagerStore?.Cart
        : false
    if (!Cart) return
    setIsSaving(true)

    if (!CartMeta)
      CartMeta = await Cart.meta(true).create({
        meta_key: 'cart---enable-ext-carriers',
      })

    CartMeta.set('meta_value', true)
    await CartMeta.save()
    AgentContractManagerStore.set('CartMetas', CartMeta)
    setIsChecked(true)
    setIsSaving(false)
  }

  const disable = async () => {
    if (isSaving) return

    if (CartMeta) {
      setIsSaving(true)
      CartMeta.set('meta_value', false)
      await CartMeta.save()
      AgentContractManagerStore.set('CartMetas', CartMeta)
    }
    setIsChecked(false)
    setIsSaving(false)
  }

  useEffect(() => {
    if (shouldBeVisible && !isVisible) setIsVisible(true)
    else if (!shouldBeVisible && isVisible) setIsVisible(false)
  }, [shouldBeVisible, isVisible])

  useEffect(() => {
    if (shouldBeOpen && !isOpen) setIsOpen(true)
    else if (!shouldBeOpen && isOpen) setIsOpen(false)
  }, [shouldBeOpen, isOpen])

  if (isVisible)
    return (
      <MDBCollapse
        isOpen={isOpen && disabled !== true}
        id="EnableExternalCarrierToggleCheckboxComponent"
      >
        <MDBContainer fluid className={isOpen ? 'is-open' : ''}>
          <MDBRow>
            <div className="ext-carrier-checkbox">
              <div
                className={
                  'ext-state-indicator ' + (isSaving ? 'is-saving' : '')
                }
                onClick={() =>
                  isVisible ? (isChecked ? disable() : enable()) : null
                }
              >
                {isSaving ? (
                  <i className="fa fa-spin fa-spinner" />
                ) : (
                  <MDBIcon far icon={isChecked ? 'check-square' : 'square'} />
                )}
                <span>{isChecked ? 'Yes' : 'No'}</span>
              </div>
              <label>
                Do you have an existing contract with a carrier listed above
                that you <strong>can not</strong> move to USABG?
              </label>
            </div>
          </MDBRow>
        </MDBContainer>
      </MDBCollapse>
    )

  return <></>
}

export default observer(EnableExternalCarrierToggleCheckbox)
