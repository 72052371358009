import RestApi from './../../api/rest-api-client'
const srvcEndpoint = '/user_performance_entries'
export default {
  fetchDataByEntryDate: async (req, opts) =>
    RestApi.request('POST', srvcEndpoint + '/fetchDataByEntryDate', req, opts),
  search: async (req, opts) => RestApi.request('GET', srvcEndpoint, req, opts),
  get: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, id, req, opts),
  store: async (data, opts) =>
    RestApi.request('POST', srvcEndpoint, data, opts),
  update: async (id, data, opts) =>
    RestApi.request('PUT', srvcEndpoint, id, data, opts),
  delete: async (id, opts) => RestApi.request('DELETE', srvcEndpoint, id, opts),
  report: async (req, opts) =>
    RestApi.request('GET', srvcEndpoint, req, { endpoint: '/reports' }),
}
