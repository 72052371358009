import React from 'react'
import { observer } from 'mobx-react-lite'
import { MDBBtn, MDBIcon } from 'mdbreact'

import './PageHeading.scss'

const PageHeading = ({ url, from, goTo, label, btnGroup }) => {
  const renderLabel = () => {
    return (
      <div className="col ph-label">
        <h3 className="label">{label}</h3>
      </div>
    )
  }

  const renderButtonGroup = () => {
    return <div className="col ph-btn-grp">{btnGroup ? btnGroup : <></>}</div>
  }

  const renderGoBack = () =>
    from && typeof goTo === 'function' ? (
      <MDBBtn
        style={{ marginBottom: '16px' }}
        onClick={(event) => {
          event.preventDefault()
          goTo(from)
        }}
        className="go-back-link"
      >
        <MDBIcon icon="arrow-left" />
        &nbsp;Go back to{' '}
        {(from ? from.substr(1) : '')
          .replace(/^([a-z])|[\s_-]+([a-z])/g, ($1) => $1.toUpperCase())
          .replace(/-/g, ' ')}
      </MDBBtn>
    ) : (
      <></>
    )

  return (
    <div className="PageHeadingComponent">
      <div className="row ph-row">
        {renderLabel()}
        {renderButtonGroup()}
      </div>
      <hr />
      {renderGoBack()}
    </div>
  )
}

export default observer(PageHeading)
