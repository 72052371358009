export default [
  {
    text: 'Submitted',
    value: 'Submitted',
  },
  {
    text: 'Issued',
    value: 'Issued',
  },
  {
    text: 'Plan Transfer',
    value: 'Transferred',
  },
  {
    text: 'Withdrawn',
    value: 'Withdrawn',
  },
  {
    text: 'Cancelled',
    value: 'Cancelled',
  },
  {
    text: 'Declined',
    value: 'Declined',
  },
]
