import { BehaviorSubject } from 'rxjs'
import { makeAutoObservable, toJS } from 'mobx'
import { distinctUntilChanged } from 'rxjs/operators'
import UserProfileService from './../../../shared/services/UserProfile.service'

const AgentProfileSubject = new BehaviorSubject(false)

class AgentProfileStore {
  constructor() {
    makeAutoObservable(this)
  }

  validity = {
    state_license: false,
    npn: false,
    voided_check: false,
    eopolicy: !UserProfileService.isA([
      'affiliate-agent',
      'affiliate-manager',
      'associate-agent',
      'associate-manager',
    ]),
    headshot: false,
  }

  setValidity = (fieldName, isValid) => {
    this.validity[fieldName] = isValid
    let validity = toJS(this.validity)

    AgentProfileSubject.next(
      Object.keys(validity).length ===
        Object.values(validity).filter((v) => v).length
    )
  }

  subscribe = (cb) =>
    AgentProfileSubject.pipe(distinctUntilChanged()).subscribe(cb)
}

export default new AgentProfileStore()
