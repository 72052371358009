import React, { Component, Fragment } from 'react'
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBFileInput,
  MDBIcon,
  MDBSpinner,
} from 'mdbreact'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { MDBDatePicker } from 'mdbreact'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import AdminApi from '../../api/admin-api/admin-api'
import { toast } from 'react-toastify'
import CKEditor from 'ckeditor4-react'
import moment from 'moment'
import DateManager from './../../shared/utilities/DateManager.class'

class AddCarrierContest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      contest: '',
      con_name: '',
      con_startdate: new Date(),
      con_enddate: new Date(),
      con_description: '',
      con_url: '',
      contest_image: '',
      contest_info_file: '',
      errorMessage: '',
      formError: false,
    }
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params })
    // const editorState = EditorState.createEmpty();

    if (params && params.id) {
      this.fetchContestData(params.id)
    }
  }

  //Change event for input fields
  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  validate = async () => {
    const {
      con_name,
      con_startdate,
      con_enddate,
      con_url,
      contest_image,
      contest_info_file,
    } = this.state
    const regHttpLink = /^(http(s)?:\/\/).*/i
    const allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i
    const allowedFileExtensions = /(\.pdf)$/i

    if (!(con_name && con_name.trim())) {
      this.setState({ formError: true, errorMessage: 'Contest Name Required' })
      return
    }

    if (!con_startdate) {
      this.setState({
        formError: true,
        errorMessage: 'Contest Start Date Required',
      })
      return
    }

    if (!con_enddate) {
      this.setState({
        formError: true,
        errorMessage: 'Contest End Date Required',
      })
      return
    }

    if (
      contest_image &&
      typeof contest_image !== 'string' &&
      !allowedImageExtensions.test(contest_image.name)
    ) {
      this.setState({
        formError: true,
        errorMessage: 'Invalid file type for image',
      })
      return
    }

    if (
      contest_info_file &&
      typeof contest_info_file !== 'string' &&
      !allowedFileExtensions.test(contest_info_file.name)
    ) {
      this.setState({
        formError: true,
        errorMessage: 'Invalid file type of a file (.pdf only)',
      })
      return
    }

    /*if (con_url && !regLink.test(con_url)) {
            this.setState({formError: true, errorMessage: 'Invalid URL'});
            return;
        }*/

    if (con_url && !regHttpLink.test(con_url)) {
      const { con_url } = this.state
      this.setState({ con_url: `http://${con_url}` })
    }

    this.setState({ formError: false, errorMessage: '' })
  }

  //create contest
  createContest = async () => {
    await this.validate()
    const {
      con_name,
      con_startdate,
      con_enddate,
      con_url,
      con_description,
      contest_image,
      contest_info_file,
      loading,
      errorMessage,
      formError,
    } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }

    const data = {
      con_name: con_name,
      con_startdate: moment(con_startdate).format('YYYY-MM-DD'),
      con_enddate: moment(con_enddate).format('YYYY-MM-DD'),
    }

    if (con_description && con_description.trim()) {
      data['con_description'] = con_description
    }

    if (con_url && con_url.trim()) {
      data['con_url'] = con_url
    }

    if (contest_image) {
      data['contest_image'] = contest_image
    }

    if (contest_info_file) {
      data['contest_info_file'] = contest_info_file
    }

    let form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })

    AdminApi.createCarrierContest(form_data)
      .then((result) => {
        this.setState({ loading: false })
        // this.props.history.goBack();
        this.props.history.push('/admin-carrier-contests')
        toast.success('Contest created successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchContestData = async (id) => {
    this.setState({ loading: true })
    AdminApi.getCarrierContestById(id)
      .then((result) => {
        this.setState({ loading: false })
        if (result && result.data && result.data.data) {
          const data = result.data.data
          this.setState({
            editData: result.data.data || [],
            con_name: data.con_name,
            con_startdate: DateManager.dateOnlyUTCToLocal(data.con_startdate),
            con_enddate: DateManager.dateOnlyUTCToLocal(data.con_enddate),
            con_url: data.con_url || '',
            con_description: data.con_description,
            contest_image: data.con_image,
            contest_info_file: data.con_file_info,
          })
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  updateContest = async () => {
    await this.validate()
    const id = this.state.urlParams.id
    const {
      con_name,
      con_startdate,
      con_enddate,
      con_url,
      con_description,
      contest_image,
      contest_info_file,
      formError,
      errorMessage,
      loading,
    } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }
    const data = {
      con_name: con_name,
      con_startdate: moment(con_startdate).format('YYYY-MM-DD'),
      con_enddate: moment(con_enddate).format('YYYY-MM-DD'),
      con_description: con_description ? con_description.trim() : '',
      con_url: con_url ? con_url.trim() : '',
      id: id,
    }

    if (contest_image && typeof contest_image !== 'string') {
      data['contest_image'] = contest_image
    }

    if (contest_info_file && typeof contest_info_file !== 'string') {
      data['contest_info_file'] = contest_info_file
    }

    let form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })
    AdminApi.createCarrierContest(form_data)
      .then((result) => {
        this.setState({ loading: false })
        this.props.history.push('/admin-carrier-contests')
        toast.success('Contest updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  render() {
    toast.configure()
    const {
      con_name,
      con_startdate,
      con_enddate,
      con_url,
      con_description,
      contest_image,
      contest_info_file,
      urlParams,
      loading,
    } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>
                  <a href="/admin-carrier-contests">List</a>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>
                  {!!(urlParams && urlParams.id)
                    ? 'Edit Carrier Contest'
                    : 'Add Carrier Contest'}
                </MDBBreadcrumbItem>
              </MDBBreadcrumb>
              <h2>
                {!!(urlParams && urlParams.id) ? 'Edit Carrier Contest' : 'Add Carrier Contest'}
              </h2>
              <hr />

              {loading ? (
                <div className="loadingOverlay">
                  <h4 className={'loader-text p4'}>
                    <MDBSpinner />
                  </h4>
                </div>
              ) : (
                ''
              )}
              <MDBRow>
                <MDBCol size="6">
                  <form className="content-wrapper">
                    <div className="form-row">
                      <label className="grey-text">Carrier Contest Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="con_name"
                        id="con_name"
                        value={con_name}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="grey-text">Start Date</label>
                        <MDBDatePicker
                          name="con_startdate"
                          format="MM/DD/YYYY"
                          value={con_startdate}
                          getValue={(value) => {
                            this.setState({ con_startdate: value })
                          }}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="grey-text">End Date</label>
                        <MDBDatePicker
                          name="con_enddate"
                          format="MM/DD/YYYY"
                          value={con_enddate}
                          getValue={(value) => {
                            this.setState({ con_enddate: value })
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="grey-text">
                          Upload Contest Image
                        </label>
                        <MDBFileInput
                          name="contest_image"
                          value={contest_image}
                          getValue={(value) => {
                            this.setState({ contest_image: value[0] })
                          }}
                        />
                        {!!(
                          urlParams &&
                          urlParams.id &&
                          this.state.contest_image &&
                          typeof this.state.contest_image == 'string'
                        ) && (
                          <div>
                            <a
                              href={this.state.contest_image}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="d-inline-block"
                            >
                              <img
                                src={this.state.contest_image}
                                style={{ maxWidth: '50px' }}
                                alt="contest"
                              />
                            </a>
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-6">
                        <label className="grey-text">Upload Contest Info</label>
                        <MDBFileInput
                          name="contest_info_file"
                          value={contest_info_file}
                          getValue={(value) => {
                            this.setState({ contest_info_file: value[0] })
                          }}
                        />
                        {!!(
                          urlParams &&
                          urlParams.id &&
                          contest_info_file &&
                          typeof contest_info_file == 'string'
                        ) && (
                          <div>
                            <a
                              href={contest_info_file}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="d-inline-block"
                            >
                              <MDBIcon
                                icon="file"
                                size="2x"
                                className="blue-text p-1"
                              />
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <label className="grey-text" htmlFor="con_url">
                        Contest URL
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="con_url"
                        id="con_url"
                        value={con_url}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="form-row">
                      <label className="grey-text">Description</label>
                      {/*<Editor
                                        editorState={editorState}
                                        wrapperClassName="wrapper-class editor-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={(e) => {
                                            this.setState({editorState: e});
                                        }}
                                    />*/}
                      <CKEditor
                        config={{
                          allowedContent: true,
                        }}
                        onChange={({ name, editor }) => {
                          const data = editor.getData()
                          this.setState({ con_description: data })
                        }}
                        data={con_description}
                      />
                    </div>
                    <div className="form-row text-center mt-4">
                      <MDBBtn
                        color="unique"
                        type="button"
                        className="btn-block"
                        onClick={() => {
                          this.state.urlParams && this.state.urlParams.id
                            ? this.updateContest()
                            : this.createContest()
                        }}
                      >
                        {loading ? 'Saving...' : 'Save'}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AddCarrierContest
