import React, { useEffect, useState, useRef } from 'react'
import { MDBIcon, MDBSpinner } from 'mdbreact'
import UserMessageService from '../../../../shared/services/UserMessage.service'
import UserProfileService from '../../../../shared/services/UserProfile.service'
import { toast } from 'react-toastify'
import moment from 'moment'

import './UserKpiMessaging.component.scss'

const UserKpiMessaging = () => {
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    // const [loggedUser, setLoggedUser] = useState();
    const [uplineUser, setUplineUser] = useState();
    const [loading, setLoading] = useState(true);
    const messageFieldRef = useRef(null);
    const prevMessagesLengthRef = useRef(0);

    useEffect(() => {
        // setLoggedUser(UserProfileService.getUserId(true));
        setUplineUser(UserProfileService.get('u_upline_id'));
        getMessages();

        const intervalId = setInterval(() => {
            getMessages();
        }, 60000);

        // Cleanup function to clear the interval when the component unmounts
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        // Scroll to bottom only if new messages are added
        if (messages.length > prevMessagesLengthRef.current && messageFieldRef.current) {
            messageFieldRef.current.scrollTop = messageFieldRef.current.scrollHeight;
        }
        // Update the previous messages length to current messages length
        prevMessagesLengthRef.current = messages.length;
    }, [messages]);

    const sendMessage = async () => {
        const sending_message = message;
        const data = {
            user_id: UserProfileService.getUserId(),
            author_id : UserProfileService.getUserId(true),
            message: sending_message.trim(),
            channel: 'kpi-coaching',
        }

        setMessage('');
        try {
            setLoading(true);
            await UserMessageService.store(data);
            getMessages();
        } catch (error) {
            setMessage(sending_message);
            setLoading(false);
            toast.error(
                "Failed to send a message!",
                { position: toast.POSITION.TOP_RIGHT }
            );
        }
    };

    const getMessages = async () => {
        try {
            const old_messages = await UserMessageService.search({
                pagination: false,
                search: { user_id: UserProfileService.getUserId() },
                expand: ['author'],
            });
    
            setMessages(old_messages.models);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(
                "Failed to fetch messages!",
                { position: toast.POSITION.TOP_RIGHT }
            );
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            if(message) sendMessage();
        }
    };

    return (
        <div 
            id="UserKpiMessagingComponent"
            className='m-3'
        >
            <div id='message-field' ref={messageFieldRef}>
                {
                    loading && (
                        <div className='messages-spinner'>
                            <MDBSpinner />
                        </div>
                    )
                }
                {
                    messages.length > 0 && messages.map((message, index) => {
                        switch (message?.author_id) {
                            case message?.user_id:
                                return (
                                    <div key={index} className='message-container'>
                                        <div className='message user-message'>
                                            {message?.message}
                                        </div>
                                        <div className='message-date'>{moment(message?.created_at).format('MMM D, h:mm A')}</div>
                                    </div>
                                );
                            
                            case uplineUser:
                                return (
                                    <div key={index} className='message-container'>
                                        <div key={index} className='message direct-upline-message'>
                                            {message.message}
                                        </div>
                                        <div className='message-author'>{message?.expands?.author?.u_fname + ' ' + message?.expands?.author?.u_lname}</div>
                                        <div className='message-date non-own-date'>{moment(message?.created_at).format('MMM D, h:mm A')}</div>
                                    </div>
                                );
                            
                            default:
                                return(
                                    <div key={index} className='message-container'>
                                        <div key={index} className='message other-message'>
                                            {message.message}
                                        </div>
                                        <div className='message-author'>{message?.expands?.author?.u_fname + ' ' + message?.expands?.author?.u_lname}</div>
                                        <div className='message-date non-own-date'>{moment(message?.created_at).format('MMM D, h:mm A')}</div>
                                    </div>
                                );
                        }
                    })
                }
            </div>
            <div id='message-editor'>
                <textarea
                    placeholder='Enter Message'
                    cols={3}
                    value={message}
                    onChange={(evt) => setMessage(evt.target.value)}
                    onKeyDown={handleKeyDown}
                ></textarea>
                <div className='pl-2 pr-1'>
                    <MDBIcon 
                        far 
                        icon="arrow-alt-circle-right" 
                        size='xl' 
                        onClick={(evt) => sendMessage()}
                    />
                </div>
            </div>
        </div>
    )
}

export default UserKpiMessaging;