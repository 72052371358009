import React from 'react'
import { getWeek, format, sub } from 'date-fns'
import { UIDropdown } from './../../../../components/forms/form-fields'
import ProductionReportWidgetService from '../../../../shared/services/ProductionReportWidget.service'
import { fetchReportData } from './functions'

import './ProductionWidget.scss'

export default class ProductionWidget extends React.Component {
  _searchMethod = null
  _searchDownline = false
  _searchUserId = null

  _formatMetric = (value, canFloat) => {
    if (value !== null && isNaN(value)) return value

    switch (this.state.metric) {
      case 'av':
      case 'life_av':
        return this._convertToCurrency(value)
      case 'aca_lives':
      case 'ma_lives':
      case 'points':
      case 'enrolled':
        return canFloat ? parseFloat(value).toFixed(2) : parseInt(value)
      default:
        return value
    }
  }

  _convertToCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(Math.round((value + Number.EPSILON) * 100) / 100)
  }

  _canAdvanceDate = () => {
    if (this.state.reportDate) {
      if (format(this.state.reportDate, 'yyyy') < format(new Date(), 'yyyy'))
        return true
      if (format(this.state.reportDate, 'yyyy') === format(new Date(), 'yyyy'))
        return (
          getWeek(this.state.reportDate, { weekStartsOn: 1 }) <
          getWeek(new Date(), { weekStartsOn: 1 })
        )
      return false
    }

    return true
  }

  testAnnualDates = (currYear, prevYear) => {
    // store current values.
    let cD = this.Calc.getCurrDate(),
      cY = this.Calc.getCurrYear(),
      currSearch,
      prevSearch

    // update values to end of year.
    this.Calc.setCurrReportDate(new Date(currYear, 11, 31))
    this.Calc.setPrevReportYear(prevYear)

    // iterate day-to-day
    let results = []
    while (`${this.Calc.getCurrYear()}` === `${currYear}`) {
      // print findings.
      currSearch = { search: this.Calc.getCurrSearch() }
      prevSearch = { search: this.Calc.getPrevSearch() }
      results.push([
        currSearch.search.report_date,
        prevSearch.search.report_date,
        prevSearch.search.week,
        prevSearch.search.month,
        prevSearch.search.quarter,
        prevSearch.search.year,
      ])
      this.Calc.setCurrReportDate(sub(this.Calc.getCurrDate(), { days: 1 }))
    }

    // revert values.
    this.Calc.setCurrReportDate(cD)
    this.Calc.setPrevReportYear(cY)
    return results
  }

  testDates = () => {
    this.Calc.setCurrReportDate(new Date(2024, 11, 31))

    const results = this.testAnnualDates(2024, 2023)
      .concat(this.testAnnualDates(2024, 2022))
      .concat(this.testAnnualDates(2024, 2021))
      .concat(this.testAnnualDates(2024, 2020))
      .concat(this.testAnnualDates(2023, 2022))
      .concat(this.testAnnualDates(2023, 2021))
      .concat(this.testAnnualDates(2023, 2020))
      .concat(this.testAnnualDates(2022, 2021))
      .concat(this.testAnnualDates(2022, 2020))
      .concat(this.testAnnualDates(2021, 2020))

    console.log(results)
  }

  _fetch = () => {
    if (this._searchMethod) {
      if (!isNaN(this._searchUserId) && this._searchUserId > 0)
        return this._fetchByDownline()

      this.setState({ ...this.Calc.getStateObj(), fetching: true })
      fetchReportData(this.Calc, {
        search: async (search) => {
          return await ProductionReportWidgetService[this._searchMethod](search)
        },
      }).then((data) => {
        this.setState({ data, fetching: false, ...this.Calc.getStateObj() })
      })

      // 1. Enable this for comparative date testing.
      // 2. 'Copy Object' by right-clicking the console.log statement.
      // 3. Format as csv & save it to automation.usabg.com storage/apps folder.
      // 4. Visit automation.usabg.com/tests/date-comparisons, when logged in.
      // this.testDates();
    }
  }

  _fetchByDownline = () => {
    if (this._searchMethod) {
      this.setState({ ...this.Calc.getStateObj(), fetching: true })
      fetchReportData(this.Calc, {
        search: async (search) => {
          search.search.user_id = this._searchUserId
          return await ProductionReportWidgetService[this._searchMethod](search)
        },
      }).then((data) => {
        this.setState({ data, fetching: false, ...this.Calc.getStateObj() })
      })
    }
  }

  _onDateChange = (reportDate) => {
    if (this.Calc.setCurrReportDate(reportDate)) {
      this._searchDownline ? this._fetchByDownline() : this._fetch()
    }
  }

  _onPreviousYearChange = (e) => {
    if (this.Calc.setPrevReportYear(parseInt(e.target.value))) {
      this._searchDownline ? this._fetchByDownline() : this._fetch()
    }
  }

  _renderPrevYearDropDown = () => {
    let year = format(this.state.reportDate, 'yyyy') - 1
    const years = Array.from(new Array(5), (v, i) => {
      return {
        text: year - i,
        value: year - i,
        checked: year - i === parseInt(this.state.prevYear),
      }
    })
    return (
      <UIDropdown
        options={years}
        selected={parseInt(this.state.prevYear)}
        onChange={this._onPreviousYearChange}
        value={parseInt(this.state.prevYear)}
      />
    )
  }
}
