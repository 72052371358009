import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import {
  MDBContainer,
  MDBListGroup,
  MDBListGroupItem,
  MDBBtn,
  MDBIcon,
} from 'mdbreact'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'

class MaintainProducts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      coveragesList: [],
      userRoles: {},
    }
  }
  componentDidMount() {
    this.fetchUserDetails()
  }

  fetchUserDetails = async () => {
    this.setState({ loading: true })
    AdminApi.fetchUserDetailsAuth()
      .then((result) => {
        if (result && result.data && result.data.data) {
          this.setState({ userRoles: result.data.data }, () => {
            this.fetchCoveragesList(result.data.data.id)
          })
        }
      })
      .catch((error) => {
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
        this.setState({ loading: false })
      })
  }

  fetchCoveragesList = async () => {
    // AdminApi.showProductLinks({user_id: id})
    AdminApi.getCoveragesSortByName()
      .then((result) => {
        if (result && result.data && result.data.data) {
          this.setState({ coveragesList: result.data.data || [] })
        }
        this.setState({ loading: false })
      })
      .catch((error) => {
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
        this.setState({ loading: false })
      })
  }

  redirectToProductList = (coverageId) => {
    this.props.history.push(`/my-products/${coverageId}`)
  }

  render() {
    toast.configure()
    const { loading, coveragesList } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            {loading ? (
              <h2>
                <MDBIcon
                  className="red-text ml-3"
                  icon="cog"
                  spin
                  size="2x"
                  fixed
                />
                <span className="sr-only">Loading...</span>
              </h2>
            ) : (
              <MDBContainer fluid className="mt-5">
                <h2>My Coverages & Products</h2>
                <MDBListGroup>
                  {coveragesList && coveragesList.length ? (
                    coveragesList.map((item, i) => {
                      return (
                        <MDBListGroupItem>
                          <MDBBtn
                            color="indigo"
                            onClick={() => {
                              this.redirectToProductList(item.id)
                            }}
                          >
                            {item.coverage_name ? item.coverage_name : ''}
                          </MDBBtn>
                        </MDBListGroupItem>
                      )
                    })
                  ) : (
                    <MDBListGroupItem>
                      <MDBBtn color="indigo">No Coverage Found</MDBBtn>
                    </MDBListGroupItem>
                  )}
                </MDBListGroup>
              </MDBContainer>
            )}
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default MaintainProducts
