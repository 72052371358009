import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import UserTermService from '../../../../shared/services/UserTerm.service'
import { MDBBtn } from 'mdbreact'
import { LeaderTermsStore } from '../LeaderTermsReviewer/LeaderTermsReviewer.component'
import UserProfileService from '../../../../shared/services/UserProfile.service'
import { toast } from 'react-toastify'
import LoadingSpinner from '../../../../components/shared/LoadingSpinner.component'

export const ResetAllGuidelineButton = observer(({ term }) => {
  const [loading, setLoading] = useState(false)

  const resetGuidelines = async () => {
    setLoading(true)

    try {
      let newTerm = await LeaderTermsStore.Term.resetAll()
      await LeaderTermsStore.setTerm(newTerm?.id)
      await LeaderTermsStore.fetch(UserProfileService.getUserId())
    } catch (err) {
      toast.error(err.message)
    }

    setLoading(false)
  }

  if (
    UserProfileService.isA(['system-admin']) &&
    UserProfileService.isA(['system-admin'], true)
  )
    return (
      <MDBBtn
        tag="a"
        disabled={loading || !LeaderTermsStore.Term}
        color="danger"
        onClick={resetGuidelines}
      >
        <strong>
          Reset ALL Leader Guidelines {loading ? <LoadingSpinner /> : <></>}
        </strong>
        &nbsp;{`{ID: ${LeaderTermsStore.Term?.id()}}`}
      </MDBBtn>
    )

  return <></>
})
