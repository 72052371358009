import { MilestonesPage } from './index'

import { RouteFactory } from './../../shared/router/Route.factory'
import { RequiresAuthGuard } from './../../shared/router/guards'

export default [
  RouteFactory.create(MilestonesPage, '/hall-of-fame', [
    new RequiresAuthGuard(true),
  ], {
    name: 'Hall of Fame'
  }),
  RouteFactory.create(MilestonesPage, '/milestones', [], {redirectsTo: '/hall-of-fame', name: 'Hall of Fame'}),
]
