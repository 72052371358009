import React from 'react'
import { observer } from 'mobx-react-lite'
import MediaUploaderButton from './../../../../components/modals/MediaUploaderModal/button.component'
import { MDBIcon } from 'mdbreact'

import './UploadBATeamLogoButton.scss'

export default observer(({ BATeam }) => {
  if (BATeam?.id()) {
    return (
      <MediaUploaderButton
        block
        label={
          <>
            Upload Benefit Advisor Team Logo &nbsp;
            <MDBIcon icon="plus" />
          </>
        }
        modalConfig={{
          uploadType: 'ba_team_logo',
          modalContentTop: (
            <div className="text--center fw--500">
              <h6 className="fw--500">Upload Benefit Advisor Team Logo.</h6>
            </div>
          ),
          appendFormData: { ba_team_id: BATeam.id() },
          validationRules: { contentType: ['image/png'] },
        }}
        onSuccess={(filepath) => BATeam.set('team_logo', filepath)}
      />
    )
  }

  return <></>
})
