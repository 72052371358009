import React from 'react'
import { observer } from 'mobx-react-lite'
import { MDBIcon, MDBBtn } from 'mdbreact'
import Store from './../../shared/Enrollment.store';
import './EnrollmentStageIndicator.scss'

const EnrollmentStageIndicator = () =>
{
  return (
    <div id="EnrollmentStageIndicatorComponent">
      <div className="stepper-container">
        <div className={`step-wrapper ${Store.intakeStage === 'IntakeFormIdentity' ? 'stage-active' : 'stage-incomplete'}`}>
          <div className="step-indicator">
            <MDBBtn tag="a" floating>
              <MDBIcon icon="user" />
            </MDBBtn>
          </div>
          <div className="step-description">
            <div className="step-label">
							Account Profile
            </div>
            <div className="step-content">
              Tell us a little bit about who you are & we'll start by getting
              your USABG Agent account created.
            </div>
          </div>
        </div>
        <div className={`step-wrapper ${Store.intakeStage === 'IntakeFormProfile' ? 'stage-active' : 'stage-incomplete'}`}>
          <div className="step-indicator">
            <MDBBtn tag="a" floating>
              <MDBIcon icon="map-marker" />
            </MDBBtn>
          </div>
          <div className="step-description">
            <div className="step-label">
							Profile Details
            </div>
            <div className="step-content">
              Complete additional details including your address, date of
              birth and phone number used for your carrier contracts.
            </div>
          </div>
        </div>        
        <div className={`step-wrapper ${Store.intakeStage === 'IntakeFormPackageSelection' ? 'stage-active' : 'stage-incomplete'}`}>
          <div className="step-indicator">
            <MDBBtn tag="a" floating>
              <MDBIcon icon="file-alt" />
            </MDBBtn>
          </div>
          <div className="step-description">
            <div className="step-label">
							Agent Package Selection
            </div>
            <div className="step-content">
            	Select your designed Agent Package & get ready to launch your new career.
            </div>
          </div>
        </div>
        <div className={`step-wrapper ${Store.intakeStage === 'IntakeFormAcceptance' ? 'stage-active' : 'stage-incomplete'}`}>
          <div className="step-indicator">
            <MDBBtn tag="a" floating>
              <MDBIcon icon="shopping-cart" />
            </MDBBtn>
          </div>
          <div className="step-description">
            <div className="step-label">
  						Terms & Conditions
            </div>
            <div className="step-content">
            	Read our Signature Producer Agreement & Non-Solicitation Agreement to learn the Terms & Conditions of our program.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(EnrollmentStageIndicator)
