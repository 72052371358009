import React from 'react'
import {observer} from 'mobx-react-lite'

const DEFAULT_TOGGLE_WIDTH = 170

const UIInputToggleSwitch = ({
  className,
  disabled,
  id,
  onChange,
  value,
  name,
  labelOn,
  labelOff,
  width,
}) => {
  onChange = typeof onChange === 'function' ? onChange : () => {}
  width = width && !isNaN(width) ? parseInt(width) : DEFAULT_TOGGLE_WIDTH

  const innerWidth = width - 38,
    		innerMinWidth = width - 24

  return (
    <div className={`ui-input ui-input-toggle-switch`}>
      <div
        className="toggle-container"
        onClick={() => {
        	if (!disabled) {
	          value = !value
	          onChange(name ? { target: { name, value } } : value)
        	}
        }}
        style={{ width: `${width}px` }}
      >
        <div
          className={`dialog-button ${!!value ? '' : 'disabled'} ${
            disabled ? 'disabled' : ''
          }`}
          style={{
            width: `${innerWidth}px`,
            minWidth: `${innerMinWidth}px`,
          }}
        >
          {!!value ? labelOn || 'Yes' : labelOff || 'No'}
        </div>
      </div>
    </div>
  )
}

export default observer(UIInputToggleSwitch)
