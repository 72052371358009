import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from 'mdbreact'
import ContentHtml from './../../content/ContentHtml/ContentHtml.component'
import ReplacementCarrierSelector from './../ReplacementCarrierSelector/ReplacementCarrierSelector.component'
import { create } from 'react-modal-promise'
import ACMStore from './../AgentContractManager.store'

import './ReplacementCartList.scss'

// TODO:
// REMOVE AN AGENTS ABILITY TO TOGGLE A CARRIER CONTRACT ITEM WHERE THE AGENT HAS ALREADY STARTED CONTRACTING.
// OR - REMOVE TOGGLE ENTIRELY ONCE AGENT STARTS ANY CONTRACTS (FOR SIMPLICITY).

const ConfirmReplaceCarrierModal = ({
  isOpen,
  ExternalCarrier,
  ReplacementCarrier,
  onResolve,
  onReject,
}) => {
  return (
    <MDBModal
      size="lg"
      isOpen={isOpen || !!(ExternalCarrier && ReplacementCarrier)}
      toggle={() => onReject()}
      className="ConfirmReplaceCarrierModalComponent"
    >
      <MDBModalHeader toggle={() => onReject()}>
        Confirm Carrier Selection?
      </MDBModalHeader>
      <MDBModalBody>
        <div>
          You've set <strong>{`${ExternalCarrier.get('c_name')}`}</strong> as a
          carrier contract you can not move to USBAG.
        </div>
        <br />
        <div>
          You're selecting to contract with{' '}
          <strong>{`${ReplacementCarrier.get('c_name')}`}</strong> in place of{' '}
          <strong>{`${ExternalCarrier.get('c_name')}`}</strong>.
        </div>
        <br />
        <div>
          By proceeding, we'll remove{' '}
          <strong>{`${ExternalCarrier.get('c_name')}`}</strong> from your
          Contract Shopping Cart and replace it with your chosen carrier above.
        </div>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn onClick={() => onResolve(true)}>Yes, Confirm Selection.</MDBBtn>
        <MDBBtn onClick={() => onReject()}>No, Cancel Change.</MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  )
}

const carrierReplacementModalPromise = create(ConfirmReplaceCarrierModal)

const ReplacementCartList = ({ activeSpecs }) => {
  const externalCarrierIds = (ACMStore?.ExternalCarriers || []).map((ExtCar) =>
      parseInt(ExtCar.get('carrier_id'))
    ),
    confirmReplaceCarrier = async (ExternalCarrier, ReplacementCarrier) =>
      ExternalCarrier && ReplacementCarrier
        ? new Promise((resolve, reject) =>
            carrierReplacementModalPromise({
              ExternalCarrier,
              ReplacementCarrier,
            })
              .then(resolve)
              .catch(reject)
          )
        : null

  const renderCartItem = (CartItem) => {
    const Carrier = ACMStore.Carriers.filter(
      (Carrier) =>
        parseInt(Carrier.id()) === parseInt(CartItem.get('carrier_id'))
    ).shift()

    return (
      <li
        key={`replacement-cart-list-item-carrier-${CartItem.get('carrier_id')}`}
      >
        <div className="cart-item cart-item-external">
          <div>{Carrier?.get('c_name')}</div>
          {Carrier?.get('c_description') ? (
            <div>{Carrier?.get('c_description')}</div>
          ) : (
            ''
          )}
        </div>
        <div className="cart-item cart-item-transition">
          <MDBIcon icon="arrow-right" />
        </div>
        <div className="cart-item cart-item-replacement">
          <ReplacementCarrierSelector
            Store={ACMStore}
            CartItem={CartItem}
            activeSpecs={activeSpecs}
            onConfirm={confirmReplaceCarrier}
          />
        </div>
      </li>
    )
  }

  const renderCartItemList = () => {
    return (
      <ul className="replace-cart-item-list">
        <li>
          <div className="cart-item">
            Replacement Carrier Selection
          </div>
        </li>
        <li>
          <div className="cart-item cart-item-external">
            Existing Carrier Contract(s) Not Transferable to USABG:
          </div>
          <div className="cart-item cart-item-transition">
            <MDBIcon icon="arrow-right" />
          </div>
          <div className="cart-item cart-item-replacement">
            Replacement Carrier Contract(s):
          </div>
        </li>
        {(ACMStore?.CartItems || [])
          .filter(
            (CartItem) =>
              externalCarrierIds.indexOf(parseInt(CartItem.get('carrier_id'))) >
              -1
          )
          .map(renderCartItem)}
      </ul>
    )
  }

  return (
    <MDBContainer id="ReplacementCartListComponent" fluid>
      <MDBRow>
        <MDBCol size="12">
          <ContentHtml contentSlug="replacement-cart-list-how-to" />
        </MDBCol>
        <MDBCol size="12">{renderCartItemList()}</MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(ReplacementCartList)
