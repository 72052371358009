import BaseModelFactory from './base.factory'
import LeaderGoalCardService from './../services/LeaderGoalCard.service'
import LeaderGoalCard from './../models/leader-goal-card.model'

class LeaderGoalCardFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: LeaderGoalCardService, Model: LeaderGoalCard }
    )
  static goal = async ({ start_at, end_at, source_type }) =>
    await this._search(
      {
        search: { source_type, start_at, end_at },
        pagination: false,
        order_by: {},
      },
      { Service: LeaderGoalCardService, Model: LeaderGoalCard }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: LeaderGoalCardService,
      Model: LeaderGoalCard,
    })
}

export default LeaderGoalCardFactory
