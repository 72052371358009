import { TrainingQuizesPage } from './index'

import { RouteFactory } from '../../shared/router/Route.factory'
import {
  RequiresAuthGuard,
  RequiresUsertypesGuard,
} from '../../shared/router/guards'

export default [
  RouteFactory.create(
    TrainingQuizesPage,
    '/admin/training-quizes',
    [
      new RequiresAuthGuard(true),
      new RequiresUsertypesGuard([
        'agency-owner',
        'internal-admin',
        'internal-staff',
        'system-admin',
      ]),
    ],
    {
      name: 'Training Quizzes',
    }
  ),
]
