import React, { Component, Fragment } from 'react';
import DashboardLayout from "../../components/Admin/DashboardLayout";
import {MDBBtn, MDBContainer, MDBDataTable, MDBIcon} from "mdbreact";
import {toast} from "react-toastify";
import AdminApi from "../../api/admin-api/admin-api";


class UserTypeReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            exporting: false,
            userTypesList: [],
            data: {
                columns: [
                    {
                        label: 'UserType',
                        field: 'usertype',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Users',
                        field: 'users',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'View',
                        field: 'view',
                        sort: 'asc',
                        width: 270
                    }
                ],
                rows: []
            }
        }
    }
    componentDidMount() {
        this.fetchUsertypeWithUsersNumber();
    }

    _exportToCSV = async () => {
        this.setState({exporting: true});
        AdminApi.userTypeCSV().then((result) => {
            if(result && result.data && result.data.data && result.data.data.url){
                window.location.href=result.data.data.url;
            }
            this.setState({exporting: false});
        }).catch(error => {
            this.setState({exporting: false});
            toast.error(error.message, {position: toast.POSITION.TOP_RIGHT});
        });
    }


    fetchUsertypeWithUsersNumber = async () => {
        this.setState({loading: true});
        AdminApi.getUsertypeWithUsersNumber().then((result) => {
            if(result && result.data && result.data.data){
                this.setState({userTypesList: result.data.data || []}, this.setRows);
            }
            this.setState({loading: false});
        }).catch(error => {
            this.setState({loading: false});
            toast.error(error.message, {position: toast.POSITION.TOP_RIGHT});
        });
    }

    setRows = () => {
        const {userTypesList} = this.state;
        if(userTypesList && userTypesList.length) {
            let rows = userTypesList.map((row) => {
                const {usertype_id, total_usertype, usertype_name} = row;
                const self = this;

                function viewTotalUsers() {
                    self.props.history.push(`/${usertype_id}/view-usertype-reports`);
                }

                const viewClick = (
                    <div className={'d-flex align-items-center justify-content-center'}>
                        <div className={'cursor-pointer d-inline-block'} role={'button'} onClick={viewTotalUsers}>
                            <MDBIcon icon="binoculars" size="2x" className="d-flex justify-content-center blue-text" />
                        </div>
                    </div>
                );


                return {
                    usertype: usertype_name ? usertype_name : 'N/A',
                    users: total_usertype ? total_usertype : 0,
                    view: viewClick
                }
            });
            this.setState({
                data: {...this.state.data, rows: rows}
            });
        }
    }

    render() {
        toast.configure();
        const {loading, exporting, data, userTypesList} = this.state;

        return (
            <Fragment>
                <DashboardLayout>
                <main className="mainSection pb-5">
                    <MDBContainer fluid className="mt-5">
                    	<h2>Admin Report: UserType Reports</h2>
                    	<hr />
                        {
                            loading ? <h2>Loading...</h2> :
                                <>
                                    <div className="content-wrapper"><MDBDataTable order={['usertype','asc']} responsive striped bordered small data={data} className="text-capitalize"
                                                  entries={50} entriesOptions={[50, 100, 150]} />
                                    {
                                        userTypesList && userTypesList.length
                                            ? <div className="row justify-content-between">
                                                <div className="pl-3"></div>
                                                <div className="mr-2">
                                                    <MDBBtn color="primary" onClick={this._exportToCSV}>
                                                        {
                                                            exporting ? 'Downloading...' : 'Download CSV'
                                                        }
                                                    </MDBBtn>
                                                </div>
                                            </div>
                                            : <></>
                                    }
                                    </div>
                                </>
                        }
                    </MDBContainer>
                </main>
                </DashboardLayout>
            </Fragment>
          );
    }
}

export default UserTypeReports;
