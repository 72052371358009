import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import {
  MDBInput,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
} from 'mdbreact'
import CarrierMgmtStore from './../../CarrierMgmt.store'
import {
  phoneToStorage,
  phoneToView,
} from './../../../../shared/formatters/telephone.formatter'

import './CarrierContactsForm.scss'

const CarrierContactsForm = ({ table }) => {
  const [changes, setChanges] = useState(null)
  const [contact, setContact] = useState(null)

  const getValue = (fld) => {
    let val = ''

    if (changes && changes.hasOwnProperty(fld))
      val =
        typeof changes[fld] === 'undefined' || changes[fld] === null
          ? ''
          : changes[fld]
    else if (contact && contact.hasOwnProperty(fld))
      val =
        typeof contact[fld] === 'undefined' || contact[fld] === null
          ? ''
          : contact[fld]

    if (`${val}`.trim().length > 0 && fld === 'phone') val = phoneToView(val)

    return val
  }

  const onChange = (event) => {
    let changed = { ...changes, [event.target.name]: event.target.value }
    if (
      JSON.stringify(changes[event.target.name]) !==
      JSON.stringify(changed[event.target.name])
    )
      setChanges(changed)
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    let result = CarrierMgmtStore.saveContact({
      ...contact,
      ...changes,
      phone: phoneToStorage(getValue('phone')),
    })
    if (result && result.id && parseInt(result.id) !== parseInt(changes.id))
      setChanges((prevChanges) => ({ ...prevChanges, ...result }))
    return false
  }

  const renderLockOut = () => {
    if (
      !CarrierMgmtStore.carrier ||
      (CarrierMgmtStore.carrier && isNaN(CarrierMgmtStore.carrier?.id))
    )
      return <div className="lockout-window">lockout</div>
  }

  const renderContactForm = () => {
    return (
      <form noValidate onSubmit={onSubmit}>
        <MDBRow>
          <MDBCol size="12">
            <MDBInput
              label="Contact Name"
              type="text"
              name="name"
              value={getValue('name')}
              onChange={onChange}
              autoComplete="off"
            />
          </MDBCol>
          <MDBCol size="12" sm="6">
            <MDBInput
              label="Title or Position"
              type="text"
              name="title"
              value={getValue('title')}
              onChange={onChange}
              autoComplete="off"
            />
          </MDBCol>
          <MDBCol size="12" sm="6">
            <MDBInput
              label="Department"
              type="text"
              name="department"
              value={getValue('department')}
              onChange={onChange}
              autoComplete="off"
            />
          </MDBCol>
          <MDBCol size="12">
            <MDBInput
              label="Email"
              type="text"
              name="email"
              value={getValue('email')}
              onChange={onChange}
              autoComplete="off"
            />
          </MDBCol>
          <MDBCol size="9">
            <MDBInput
              label="Phone"
              type="text"
              name="phone"
              value={getValue('phone')}
              onChange={onChange}
              autoComplete="off"
            />
          </MDBCol>
          <MDBCol size="3">
            <MDBInput
              label="Extension"
              type="text"
              name="phone_ext"
              value={getValue('phone_ext')}
              onChange={onChange}
              autoComplete="off"
            />
          </MDBCol>
          <MDBCol size="12">
            <MDBInput
              label="Notes"
              type="textarea"
              name="notes"
              value={getValue('notes')}
              onChange={onChange}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12" sm="7">
            <MDBBtn
              className="btn-block"
              type="submit"
              disabled={!getValue('name')}
            >
              {CarrierMgmtStore.getIsLoading('update.contact') ? (
                <span>
                  Saving...&nbsp;
                  <i className="fa fa-spin fa-spinner" />
                </span>
              ) : (
                'Save Contact'
              )}
            </MDBBtn>
          </MDBCol>
          <MDBCol size="12" sm="5">
            <MDBBtn
              className="btn-block"
              onClick={() => {
                setContact(null)
                setChanges(null)
              }}
            >
              Done
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </form>
    )
  }

  const renderContactTable = () => {
    return (
      <table className="carriers-table">
        <thead>
          <tr>
            <th valign="middle">ID</th>
            <th valign="middle">
              <div>
                <div className="label">Name</div>
              </div>
            </th>
            <th valign="middle">Edit</th>
            <th valign="middle">Delete</th>
          </tr>
        </thead>
        <tbody>
          {CarrierMgmtStore.contacts.map((cnt) => (
            <tr key={'contacts-id-' + cnt.id}>
              <td>{cnt.id}</td>
              <td>{cnt.name}</td>
              <td>
                <MDBBtn
                  disabled={!cnt}
                  onClick={() => {
                    setChanges({})
                    setContact(cnt)
                  }}
                >
                  <MDBIcon icon="edit" />
                </MDBBtn>
              </td>
              <td>
                <MDBBtn
                  disabled={!cnt}
                  onClick={() => CarrierMgmtStore.deleteContact(cnt.id)}
                >
                  <MDBIcon icon="trash" className="red-text" />
                </MDBBtn>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  const renderLoadingWheel = () => {
    if (CarrierMgmtStore.getIsLoading('fetch.contacts'))
      return (
        <span>
          <i className="fa fa-spin fa-spinner" />
        </span>
      )
  }

  return (
    <MDBCard id="CarrierContactsFormComponent">
      {renderLockOut()}
      <MDBCardHeader>
        Carrier Contacts&nbsp;
        {renderLoadingWheel()}
        <MDBBtn
          className="card-header-btn"
          disabled={!!(contact || !CarrierMgmtStore.carrier?.id)}
          onClick={() => {
            setChanges({})
            setContact({})
          }}
        >
          <MDBIcon icon="plus" />
        </MDBBtn>
      </MDBCardHeader>
      <MDBCardBody>
        {changes && typeof changes === 'object'
          ? renderContactForm()
          : renderContactTable()}
      </MDBCardBody>
    </MDBCard>
  )
}

export default observer(CarrierContactsForm)
