import React, { useState, useEffect, useCallback } from 'react'
import env from './../../../environments/environment'
import { observer } from 'mobx-react-lite'
import {
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBAlert,
  MDBRow,
  MDBCol,
  MDBContainer,
} from 'mdbreact'
import {
  UIInput,
  UIInputToggleSwitch,
} from './../../../components/forms/form-fields'
import NiprPdbReportStore from './AgentNiprPdbReportList.store'

import './AgentNiprPdbReportList.scss'

const AgentNiprPdbReportList = ({ userId, updateParams, isAdmin }) => {
  const getDocParams = (Document) => {
    let params = { send_to_carrier: null }

    if (Document) {
      try {
        params = JSON.parse(Document.get('params'))
      } catch (ex) {
        console.log('cant read params', ex)
      }
    }

    return params
  }

  const list = () => {
    if (NiprPdbReportStore.hasFetched !== true) return <></>

    if (NiprPdbReportStore.isFetching) {
      return (
        <ul className="documents-list loader">
          <li>
            <div>
              <i className="fa fa-spin fa-spinner"></i>&nbsp;Loading
            </div>
          </li>
        </ul>
      )
    }

    return (
      <ul className="documents-list">
        <li>
          <div>Document title</div>
          <div>{isAdmin ? 'Send to Carrier' : ''}</div>
          <div>Save</div>
          <div>View</div>
          <div>Delete</div>
        </li>
        {NiprPdbReportStore.Documents.map((Document, idx) => {
          let params = getDocParams(Document),
            isDeleting = NiprPdbReportStore.isDeleting(Document.get('id')),
            isViewable = !!(
              Document.get('filename') && Document.get('filepath')
            ),
            isSaving = NiprPdbReportStore.isSaving(Document.get('id')),
            isSaved =
              !isSaving && NiprPdbReportStore.isSaved(Document.get('id'))

          return (
            <li key={`aval-user-doc-${idx}`}>
              <div>
                {
                  <UIInput
                    name="description"
                    required={true}
                    disabled={isSaving}
                    value={Document.get('description')}
                    onChange={(e) => {
                      Document.set('description', e.target.value)
                    }}
                  />
                }
              </div>
              <div>
                {isAdmin ? (
                  <UIInputToggleSwitch
                    id={`send_to_carrier_${Document.get('id')}`}
                    name={`send_to_carrier_${Document.get('id')}`}
                    value={!!params?.send_to_carrier}
                    width="70"
                    labelOn="ON"
                    labelOff="OFF"
                    disabled={isSaving || isDeleting}
                    onChange={(e) => {
                      if (!isSaving) {
                        e = !!e.target.value
                        Document.set(
                          'params',
                          JSON.stringify({ ...params, send_to_carrier: e })
                        )
                      }
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div>
                <MDBBtn
                  block
                  size="sm"
                  disabled={
                    !Document.get('description') ||
                    isSaving ||
                    isDeleting ||
                    !Document.hasChanged
                  }
                  color="success"
                  onClick={() => NiprPdbReportStore.save(Document)}
                >
                  {isSaving ? (
                    <span>
                      <i className="fa fa-spin fa-spinner"></i>
                    </span>
                  ) : (
                    <MDBIcon icon={isSaved ? 'check' : 'save'} />
                  )}
                </MDBBtn>
              </div>
              {isViewable ? (
                <div>
                  <MDBBtn
                    tag="a"
                    block
                    size="sm"
                    disabled={isSaving || isDeleting}
                    color="info"
                    onClick={() =>
                      window.open(
                        `https://firebasestorage.googleapis.com/v0/b/${
                          env.integrations.firebase.config.storageBucket
                        }/o/${encodeURIComponent(
                          Document.get('filepath')
                        )}?alt=media`
                      )
                    }
                  >
                    <MDBIcon icon="download" />
                  </MDBBtn>
                </div>
              ) : (
                <></>
              )}
              <div>
                <MDBBtn
                  block
                  size="sm"
                  disabled={isSaving || isDeleting}
                  color="danger"
                  onClick={() => NiprPdbReportStore.delete(Document)}
                >
                  {isDeleting ? (
                    <span>
                      <i className="fa fa-spin fa-spinner"></i>
                    </span>
                  ) : (
                    <MDBIcon icon="trash" />
                  )}
                </MDBBtn>
              </div>
            </li>
          )
        })}
      </ul>
    )
  }

  useEffect(() => {
    if (userId) NiprPdbReportStore.fetch(userId)
  }, [userId])

  return (
    <MDBContainer className="AgentNiprPdbReportListComponent" fluid>
      {list()}
    </MDBContainer>
  )
}

export default observer(AgentNiprPdbReportList)
