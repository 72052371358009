import RestApi from "./../../api/rest-api-client";
const srvcEndpoint = "/kpi_metrics";
export default {
	search: 		async (req, opts) 			=> 		RestApi.request("GET", 		srvcEndpoint, req, opts),
	get: 				async (id, req, opts) 	=> 		RestApi.request("GET", 		srvcEndpoint, id, req, opts),
	store:  		async (data, opts) 			=> 		RestApi.request("POST", 	srvcEndpoint, data, opts),
	update:  		async (id, data, opts) 	=> 		RestApi.request("PUT", 		srvcEndpoint, id, data, opts),
	delete: 		async (id, opts)  			=> 		RestApi.request("DELETE", srvcEndpoint, id, opts),

	getMyKpi: 				async (id, req, opts) 	=> 		RestApi.request("GET", 		srvcEndpoint, req, {endpoint: `/agents/${id}`}),
	getDivisionKpi: 	async (id, req, opts) 	=> 		RestApi.request("GET", 		srvcEndpoint, req, {endpoint: `/divisions/${id}`}),
};