import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import AdminApi from './../../../../api/admin-api/admin-api'
import ContentHtml from './../../../../components/content/ContentHtml/ContentHtml.component'
import { WebsiteInfoStore } from './../../stores'
import {
  MDBBtn,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBCollapse,
  MDBAlert,
} from 'mdbreact'

import './MarketingForm.component.scss'

const saveTimer = {}

const MarketingForm = ({ s_agent_picture }) => {
  const [formError, setFormError] = useState(null),
    [formSuccess, setFormSuccess] = useState(null)

  const validate = () => {
    const { siteDetails } = WebsiteInfoStore,
      regHttpLink = /^(http(s)?:\/\/).*/i

    ;[
      'quote_link_health',
      'quote_link_life',
      'quote_link_senior',
      'quote_link_aca',
    ].forEach((link) => {
      if (
        siteDetails[link] &&
        siteDetails[link].trim() &&
        !regHttpLink.test(siteDetails[link])
      )
        WebsiteInfoStore.siteDetails[link] = `http://${siteDetails[link]}`
    })

    return true
  }

  const updateInfo = async () => {
    if (!(await validate())) return false

    if (WebsiteInfoStore.isLoading || WebsiteInfoStore.isSaving) return

    const data = {
      quote_link_health: WebsiteInfoStore.siteDetails?.quote_link_health,
      quote_link_life: WebsiteInfoStore.siteDetails?.quote_link_life,
      quote_link_senior: WebsiteInfoStore.siteDetails?.quote_link_senior,
      quote_link_aca: WebsiteInfoStore.siteDetails?.quote_link_aca,
      id: WebsiteInfoStore.siteDetails?.site_id,
    }

    let form_data = new FormData()
    for (let key in data) form_data.append(key, data[key])

    WebsiteInfoStore.isSaving = true

    AdminApi.updateWebsiteInfo(form_data)
      .then((result) => {
        setFormSuccess('Successfully updated quoting details.')

        if (saveTimer.timer) {
          window.clearTimeout(saveTimer.timer)
          saveTimer.timer = null
        }

        saveTimer.timer = setTimeout(() => setFormSuccess(null), 4000)

        WebsiteInfoStore.isSaving = false
      })
      .catch((error) => {
        setFormError(error)
        WebsiteInfoStore.isSaving = false
      })
  }

  return (
    <form
      id="MarketingFormComponent"
      noValidate
      className="container-fluid mt-5"
    >
      <MDBRow>
        <MDBCol size="12">
          <h5 className="text--blue font--lato fw--500">Subsite Quoting</h5>
        </MDBCol>
        <MDBCol size="12">
          <ContentHtml contentSlug="subsite-quote-links-howto" />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol className="form-group" size="12" sm="6" lg="3">
          <div className="quote-icon">
            <MDBIcon icon="medkit" />
          </div>
          <label className="quote-label">Health Insurance Quotes</label>
          <input
            type="text"
            className="form-control"
            name="quote_link_health"
            id="quote_link_health"
            value={WebsiteInfoStore.siteDetails?.quote_link_health || ''}
            onChange={(event) =>
              (WebsiteInfoStore.siteDetails.quote_link_health =
                event.target.value)
            }
          />
        </MDBCol>
        <MDBCol className="form-group" size="12" sm="6" lg="3">
          <div className="quote-icon">
            <MDBIcon icon="heartbeat" />
          </div>
          <label className="quote-label">Life Insurance Quotes</label>
          <input
            type="text"
            className="form-control"
            name="quote_link_life"
            id="quote_link_life"
            value={WebsiteInfoStore.siteDetails?.quote_link_life || ''}
            onChange={(event) =>
              (WebsiteInfoStore.siteDetails.quote_link_life =
                event.target.value)
            }
          />
        </MDBCol>
        <MDBCol className="form-group" size="12" sm="6" lg="3">
          <div className="quote-icon">
            <MDBIcon icon="user-md" />
          </div>
          <label className="quote-label">Senior Insurance Quotes</label>
          <input
            type="text"
            className="form-control"
            name="quote_link_senior"
            id="quote_link_senior"
            value={WebsiteInfoStore.siteDetails?.quote_link_senior || ''}
            onChange={(event) =>
              (WebsiteInfoStore.siteDetails.quote_link_senior =
                event.target.value)
            }
          />
        </MDBCol>
        <MDBCol className="form-group" size="12" sm="6" lg="3">
          <div className="quote-icon">
            <MDBIcon icon="hospital" />
          </div>
          <label className="quote-label">
            Affordable Care Act (ACA) Quotes
          </label>
          <input
            type="text"
            className="form-control"
            name="quote_link_aca"
            id="quote_link_aca"
            value={WebsiteInfoStore.siteDetails?.quote_link_aca || ''}
            onChange={(event) =>
              (WebsiteInfoStore.siteDetails.quote_link_aca = event.target.value)
            }
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol className="form-group">
          <MDBBtn color="indigo" type="button" onClick={updateInfo}>
            {WebsiteInfoStore.isSaving ? 'Saving...' : 'Save'}
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBCollapse isOpen={!!formError}>
            <MDBAlert color="danger">{formError}</MDBAlert>
          </MDBCollapse>
          <MDBCollapse isOpen={!!formSuccess}>
            <MDBAlert color="success">{formSuccess}</MDBAlert>
          </MDBCollapse>
        </MDBCol>
      </MDBRow>
    </form>
  )
}

export default observer(MarketingForm)
