import React, { Component, Fragment } from 'react'
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBFileInput,
  MDBIcon,
  MDBSpinner,
} from 'mdbreact'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import AdminApi from '../../api/admin-api/admin-api'
import { toast } from 'react-toastify'

class AddCommission extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      saving: false,
      commission: '',
      com_name: '',
      com_code: '',
      com_url: '',
      com_schedule: '',
      com_rate: '',
      com_file: '',
      errorMessage: '',
      formError: false,
    }
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params })

    if (params && params.id) {
      this.fetchCommissionData(params.id)
    }
  }

  //Change event for input fields
  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  validate = async () => {
    const { com_name, com_url, com_schedule } = this.state
    const regHttpLink = /^(http(s)?:\/\/).*/i
    const allowedExtensions = /(\.pdf)$/i

    if (!(com_name && com_name.trim())) {
      this.setState({ formError: true, errorMessage: 'Name Required' })
      return
    }

    /*if (this.state.com_url && !regLink.test(this.state.com_url)) {
            this.setState({formError: true, errorMessage: 'Invalid URL'});
            return;
        }*/

    if (!(com_url && com_url.trim())) {
      this.setState({ formError: true, errorMessage: 'URL Required' })
      return
    }

    if (com_url && !regHttpLink.test(com_url)) {
      const { com_url } = this.state
      this.setState({ com_url: `http://${com_url}` })
    }

    /*if (!this.state.com_schedule) {
            this.setState({formError: true, errorMessage: 'Schedule Required'});
            return;
        }*/

    if (
      com_schedule &&
      typeof com_schedule !== 'string' &&
      !allowedExtensions.test(com_schedule.name)
    ) {
      this.setState({ formError: true, errorMessage: 'Invalid file type' })
      return
    }
    this.setState({ formError: false, errorMessage: '' })
  }

  //create commission
  createCommission = async () => {
    await this.validate()
    const {
      com_name,
      com_code,
      com_url,
      com_schedule,
      com_rate,
      errorMessage,
      formError,
      loading,
    } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }
    const data = {
      com_name: com_name,
    }

    if (com_code && com_code.trim()) {
      data['com_code'] = com_code
    }
    if (com_url && com_url.trim()) {
      data['com_url'] = com_url
    }
    if (com_rate && com_rate.trim()) {
      data['com_rate'] = com_rate
    }
    if (com_schedule) {
      data['com_schedule'] = com_schedule
    }

    let form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })
    this.setState({ saving: true })

    AdminApi.createCommission(form_data)
      .then((result) => {
        this.setState({ loading: false })
        this.setState({ saving: false })
        this.props.history.push('/admin-commissions')
        toast.success('Commission created successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        this.setState({ saving: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchCommissionData = async (id) => {
    this.setState({ loading: true })
    AdminApi.getCommissionById(id)
      .then((result) => {
        this.setState({ loading: false })
        if (result && result.data && result.data.data) {
          const content = result.data.data

          this.setState({
            editData: result.data.data || [],
            com_name: content.com_name,
            com_code: content.com_code,
            com_rate: content.com_rate,
            com_url: content.com_url,
            com_schedule: content.com_schedule,
          })
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  updateCommission = async () => {
    await this.validate()
    const id = this.state.urlParams.id
    const {
      com_name,
      com_code,
      com_rate,
      com_url,
      com_schedule,
      errorMessage,
      formError,
      loading,
    } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }
    const data = {
      com_name: com_name,
      com_code: com_code ? com_code.trim() : '',
      com_url: com_url ? com_url.trim() : '',
      com_rate: com_rate ? com_rate.trim() : 0,
      id: id,
    }

    if (com_schedule && typeof com_schedule !== 'string') {
      data['com_schedule'] = com_schedule
    }

    let form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })
    this.setState({ saving: true })
    AdminApi.createCommission(form_data)
      .then((result) => {
        this.setState({ loading: false })
        this.setState({ saving: false })
        this.props.history.push('/admin-commissions')
        toast.success('Commission updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        this.setState({ saving: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  render() {
    toast.configure()
    const {
      com_name,
      com_code,
      com_url,
      com_schedule,
      com_rate,
      urlParams,
      loading,
      saving,
    } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>
                  <a href="/admin-commissions">List</a>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>
                  {!!(urlParams && urlParams.id)
                    ? 'Edit Commission Level'
                    : 'Add Commission Level'}
                </MDBBreadcrumbItem>
              </MDBBreadcrumb>
              <h2>
                {!!(urlParams && urlParams.id)
                  ? 'Edit Commission Level'
                  : 'Add Commission Level'}
              </h2>
              <hr />

              {loading ? (
                <div className="loadingOverlay">
                  <h4 className={'loader-text p4'}>
                    <MDBSpinner />
                  </h4>
                </div>
              ) : (
                ''
              )}
              <MDBRow>
                <MDBCol size="6">
                  <form className="content-wrapper">
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="com_name">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="com_name"
                          id="com_name"
                          value={com_name}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="com_code">
                          Code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="com_code"
                          id="com_code"
                          value={com_code}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="com_rate">
                          Rate
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="com_rate"
                          id="com_rate"
                          value={com_rate}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="com_url">
                          URL
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="com_url"
                          id="com_url"
                          value={com_url}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    {/*<div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label className="grey-text">Commission URL</label>
                                        <MDBFileInput />
                                    </div>
                                </div>*/}
                    {/*<div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label className="grey-text" htmlFor="com_schedule">Schedule</label>
                                        <input type="text"
                                               className="form-control"
                                               name="com_schedule"
                                               id="com_schedule"
                                               value={com_schedule}
                                               onChange={this.onChange} />
                                    </div>
                                </div>*/}

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="grey-text" htmlFor="com_schedule">
                          Schedule (.pdf)
                        </label>
                        <MDBFileInput
                          id="com_schedule"
                          name="com_schedule"
                          value={com_schedule}
                          getValue={(value) => {
                            this.setState({ com_schedule: value[0] })
                          }}
                        />
                        {!!(
                          urlParams &&
                          urlParams.id &&
                          com_schedule &&
                          typeof com_schedule == 'string'
                        ) && (
                          <div>
                            <a
                              href={com_schedule}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="d-inline-block"
                            >
                              <MDBIcon
                                icon="file"
                                size="2x"
                                className="blue-text p-1"
                              />{' '}
                              Download
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-row text-center mt-4">
                      <MDBBtn
                        color="unique"
                        type="button"
                        className="btn-block"
                        onClick={
                          this.state.urlParams && this.state.urlParams.id
                            ? this.updateCommission
                            : this.createCommission
                        }
                      >
                        {saving ? 'Saving...' : 'Save'}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AddCommission
