import React from 'react'
import { observer } from 'mobx-react-lite'
import ContentHtml from '../../../components/content/ContentHtml/ContentHtml.component'
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact'

const SpecialtyPoints = ({ overrides, coverages, carriers, isLoading }) => {
  const groups = {}

  const getCarrier = (carrierId) => {
    const fld = 'c_name'
    let name =
      carrierId && Array.isArray(carriers)
        ? carriers
            .filter((carrier) => parseInt(carrier.id) === parseInt(carrierId))
            .shift()?.[fld]
        : null
    return name ? name : null
  }

  const getCoverage = (coverageId) => {
    const fld = 'coverage_name'
    let name = coverageId
      ? Array.isArray(coverages)
        ? coverages
            .filter(
              (coverage) => parseInt(coverage.id) === parseInt(coverageId)
            )
            .shift()?.[fld]
        : null
      : null
    return name ? name : null
  }

  const getField = (field) => {
    switch (field) {
      case 'ac_family':
        return 'Family Selection'
      default:
        return ''
    }
  }

  overrides.forEach((override) => {
    let name = getCarrier(override?.carrier_id)
    name = name ? `Carrier: ${name}` : name
    if (!name && (name = getCoverage(override?.coverage_id)))
      name = `Coverage: ${name}`

    if (!groups.hasOwnProperty(name)) groups[name] = []

    groups[name].push(override)
  })

  return (
    <MDBCard narrow id="SpecialtyPointsComponent" className="points-component">
      <MDBCardBody>
        <MDBCardTitle>Specialty Points Assignments</MDBCardTitle>
        <MDBRow>
          <MDBCol size="12">
            <ContentHtml contentSlug="av-points-overrides-detail" />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12">
            <table className="points-overrides">
              <thead>
                <tr>
                  <th>Carrier / Coverage:</th>
                  <th>Field:</th>
                  <th>Option:</th>
                  <th>Points:</th>
                </tr>
              </thead>
              {Object.keys(groups).map((group, idx) => {
                let rows = groups[group].length,
                  row1 = groups[group].shift()

                return (
                  <tbody key={`pts-detail-group-${group}-${idx}`}>
                    <tr>
                      <td rowSpan={rows}>
                        <strong>{group}</strong>
                      </td>
                      <td rowSpan={rows}>
                        <i>{getField(row1?.field)}</i>
                      </td>
                      <td>{row1?.label}</td>
                      <td>{row1?.points}</td>
                    </tr>
                    {groups[group].map((item, idx) => {
                      return (
                        <tr key={`pts-item-${item?.label}-${item?.id}-${idx}`}>
                          <td>{item?.label}</td>
                          <td>{item?.points}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                )
              })}
            </table>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  )
}

export default observer(SpecialtyPoints)
