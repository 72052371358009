import { observer } from 'mobx-react-lite'
import React from 'react'
import { MDBBtn, MDBIcon } from 'mdbreact'
import BATeamForm from './../BATeamForm/BATeamForm.component'
import Store from './../../store'

import './CreateBATeamButton.scss'

const CreateBATeamButton = (props) => (
  <MDBBtn
    color="info"
    disabled={Store.isFetching || !Store.canCreate()}
    id="CreateBATeamButtonComponent"
    onClick={() => Store.create()}
  >
    Create BA Team &nbsp;
    <MDBIcon icon="plus" />
  </MDBBtn>
)

export default observer(CreateBATeamButton)
