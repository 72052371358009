import React from 'react'
import { observer } from 'mobx-react-lite'
import NavigationStore from './Navigation.store'
import UserProfileService from './../../shared/services/UserProfile.service'
import { MDBSideNavCat, MDBSideNavNav, MDBIcon } from 'mdbreact'
import { canAccessLink } from './../../shared/utilities/linkAccessFilter.function'

import './AdminNavLinks.scss'

const AdminNavLinks = ({ goTo }) => {
  const renderLink = (link, idx) => {
    let url = link?.url
      ? /^(http:|https:)/.test(link.url)
        ? link.url
        : link.url.replace(':userId', UserProfileService.get('u_login'))
      : ''

    if (url) {
      return (
        <li
          key={`i-a-${idx}`}
          className={
            'Ripple-parent ' + (!canAccessLink(link) ? 'is-hidden-link' : '')
          }
        >
          <a
            target="_BLANK"
            rel="noopener noreferrer"
            href={url}
            className="Ripple-parent"
            onClick={(event) => {
              event.preventDefault()
              goTo(url)
              return false
            }}
          >
            {link.label}
            <MDBIcon icon="lock" />
          </a>
        </li>
      )
    }
  }

  const renderNavLinks = (link) => (
    <MDBSideNavCat
      id={`nav-opt---${
        link?.label
          ? link.label.replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()
          : 'void'
      }`}
      name={link?.label}
      key={`${link?.label}`}
      icon={link?.icon}
    >
      {(Array.isArray(link?.children) ? link.children : []).map(renderLink)}
    </MDBSideNavCat>
  )

  return (
    <MDBSideNavNav id="AdminNavLinksComponent">
      {NavigationStore.minor.map((link) => renderNavLinks(link))}
    </MDBSideNavNav>
  )
}

export default observer(AdminNavLinks)
