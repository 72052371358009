import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { MDBBtn } from 'mdbreact'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import UserMetaService from '../../../../shared/services/UserMeta.service'

const AddAdminFeeWaiverButton = ({ onWaived }) => {
  const [isLoading, setIsLoading] = useState(false)

  const meetsRequirement =
    UserProfileService.isAssumed() &&
    (UserProfileService.isA('system-admin', true) ||
      UserProfileService.isA('agency-owner', true) ||
      [9654].indexOf(parseInt(UserProfileService.getUserId(true))) > -1)

  const waiveFee = async () => {
    setIsLoading(true)

    let today = new Date().toISOString().slice(0, 10)

    try {
      let res = await UserMetaService.search({
        search: {
          user_id: UserProfileService.getUserId(),
          meta_key: `subscription---waive-admin-fee`,
        },
        orderBy: { created_at: 'DESC' },
      })
      res =
        res && Array.isArray(res?.models)
          ? res.models.filter(
              (r) => r.meta_key && r.meta_value.indexOf(today) > -1
            )
          : false

      if (!res || !res.length)
        UserMetaService.store({
          user_id: UserProfileService.getUserId(),
          meta_key: `subscription---waive-admin-fee`,
          meta_value: JSON.stringify({
            created_on: today,
            user_id: UserProfileService.getUserId(true),
          }),
        })

      onWaived(true)
      setIsLoading(false)
      UserProfileService.track({ event_type: 'user.waive-admin-fee.success' })
    } catch (error) {
      onWaived(error?.message)
      setIsLoading(false)
      UserProfileService.track({ event_type: 'user.waive-admin-fee.failure' })
    }
  }

  const renderConfig = () => {
    if (meetsRequirement)
      return (
        <div className="add-admin-fee-waiver-btn">
          <MDBBtn
            disabled={isLoading}
            className="btn-block"
            onClick={waiveFee}
            sm="true"
            color="danger"
          >
            Waive Admin Fee
          </MDBBtn>
        </div>
      )
    return <></>
  }

  return <>{renderConfig()}</>
}

export default observer(AddAdminFeeWaiverButton)
