import { observer } from 'mobx-react-lite'
import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import CarrierDetailsForm from './../CarrierDetailsForm/CarrierDetailsForm.component'
import CarrierSiblingsForm from './../CarrierSiblingsForm/CarrierSiblingsForm.component'
import CarrierContactsForm from './../CarrierContactsForm/CarrierContactsForm.component'
import CarrierContractingInstructionsForm from './../CarrierContractingInstructionsForm/CarrierContractingInstructionsForm.component'
import CarrierContractsForm from './../CarrierContractsForm/CarrierContractsForm.component'
import CarrierPrioritiesForm from './../CarrierPrioritiesForm/CarrierPrioritiesForm.component'
import CarrierContractLinkBank from './../CarrierContractLinkBank/CarrierContractLinkBank.component'
import CarrierCoveragesForm from './../CarrierCoveragesForm/CarrierCoveragesForm.component'
import CarrierCommissionCodesForm from './../CarrierCommissionCodesForm/CarrierCommissionCodesForm.component'
import CarrierDivisionsForm from './../CarrierDivisionsForm/CarrierDivisionsForm.component'

import './CarrierEditorForms.scss'
import CarrierNotesForm from '../CarrierNotesForm/CarrierNotesForm.component'

const CarrierEditorForms = ({ table, goTo }) => {
  return (
    <div id="CarrierEditorFormsComponent" className="container-fluid">
      <MDBRow>
        <MDBCol size="12" md="6">
          <CarrierDetailsForm />
        </MDBCol>
        <MDBCol size="12" md="6">
          <CarrierSiblingsForm goTo={goTo} />
          <CarrierContactsForm />
          <CarrierPrioritiesForm />
          <CarrierNotesForm />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol size="12" md="6" className="children-2">
          <CarrierContractsForm />
        </MDBCol>
        <MDBCol size="12" md="6">
          <CarrierContractLinkBank />
          <CarrierContractingInstructionsForm />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol size="12" md="6">
          <CarrierCoveragesForm />
        </MDBCol>
        <MDBCol size="12" md="6">
          <CarrierCommissionCodesForm />
          <CarrierDivisionsForm />
        </MDBCol>
      </MDBRow>
    </div>
  )
}

export default observer(CarrierEditorForms)
