export const convertToCurrency = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(
    Math.round(
      ((!isNaN(value) && typeof value === 'string'
        ? parseFloat(value)
        : value) +
        Number.EPSILON) *
        100
    ) / 100
  )
}
