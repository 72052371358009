import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import React, { useState } from 'react'
import {
  MDBInputGroup,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
  MDBSelect,
  MDBBtn,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
} from 'mdbreact'
import CarrierMgmtStore from './../../CarrierMgmt.store'
import CarrierLinkBankStore from './../../CarrierLinkBank.store'

import './CarrierContractLinkBank.scss'

const ucwords = (str) =>
  str.replace(/^([a-z])|[\s_]+([a-z])/g, ($1) => $1.toUpperCase())

const CARRIER_COMM_CODES = [
  'USABG505',
  'USABG405',
  'USABG305',
  'USABG205',
  'USABG30',
  'USABG20',
  'USABG10',
]

const CarrierContractLinkBank = ({ table }) => {
  const [load, setLoad] = useState(false)

  const onSubmit = async (event) => {
    event.preventDefault()
    await CarrierLinkBankStore.saveLeaderCommLinks()
  }

  const onClose = () => {
    CarrierLinkBankStore.leaderId = null
    CarrierLinkBankStore.user = {}
    CarrierLinkBankStore.userContractLinks = {}
    CarrierLinkBankStore.Leader = null
  }

  const viewLeader = (leaderId) => {
    leaderId = isNaN(leaderId) ? null : parseInt(leaderId)
    CarrierLinkBankStore.leaderId = leaderId
    CarrierLinkBankStore.Leader = toJS(CarrierLinkBankStore.Leaders)
      .filter((Leader) => parseInt(Leader.id()) === parseInt(leaderId))
      .shift()
    if (leaderId) CarrierLinkBankStore.fetchLeaderCommLinks()
  }

  const renderLockOut = () => {
    if (
      !CarrierMgmtStore.carrier ||
      (CarrierMgmtStore.carrier && isNaN(CarrierMgmtStore.carrier?.id))
    )
      return <div className="lockout-window">lockout</div>
    if (load === false) setLoad(true)
  }

  const renderCommLinkInput = (comCode) => {
    const modes = {
      link: 'link',
      acr: 'document_id',
      pdf: 'document_id',
    }

    let commLevel = CarrierMgmtStore.commLevels[comCode],
      UserContractLink = CarrierLinkBankStore.userContractLinks[comCode],
      mode = UserContractLink?.getMode() || ''

    const getValue = () => UserContractLink?.getModeValue() || ''

    const setValue = (value) => {
      if (mode && modes[mode] && UserContractLink)
        UserContractLink.set(modes[mode], value)
    }

    if (commLevel && UserContractLink) {
      return (
        <MDBRow key={`carr-comm-code-row-${comCode}`}>
          <MDBCol className="contract-link-setter">
            <label>
              <strong>{commLevel.com_name}</strong>
              {UserContractLink.hasChanged ? (
                UserContractLink.isValid() ? (
                  <MDBIcon
                    far
                    icon="check-circle"
                    className="green-text pl-2"
                  />
                ) : (
                  <MDBIcon far icon="times-circle" className="red-text pl-2" />
                )
              ) : (
                <></>
              )}
              &nbsp;<small style={{ color: '#999' }}>({comCode})</small>
            </label>
            <MDBInputGroup
              value={getValue()}
              material
              containerClassName="mb-3 mt-0"
              size="sm"
              onChange={(evt) => setValue(evt.target.value || '')}
              append={
                <MDBDropdown>
                  <MDBDropdownToggle
                    outline
                    color="info"
                    size="sm"
                    className="m-0 px-3 z-depth-0"
                  >
                    {UserContractLink?.get('mode') || '---'}{' '}
                    <MDBIcon icon="caret-down" className="ml-1" />
                  </MDBDropdownToggle>
                  <MDBDropdownMenu color="info">
                    <MDBDropdownItem
                      onClick={() => UserContractLink.set('mode', 'LINK')}
                    >
                      <strong>Link</strong>
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      onClick={() => UserContractLink.set('mode', 'PDF')}
                    >
                      <strong>Adobe PDF</strong>
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      onClick={() => UserContractLink.set('mode', 'ACR')}
                    >
                      <strong>Adobe ACR</strong>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              }
            />
          </MDBCol>
        </MDBRow>
      )
    }
  }

  const renderInputs = () => CARRIER_COMM_CODES.map(renderCommLinkInput)

  const renderForm = () => {
    return (
      <MDBCollapse isOpen={!!CarrierLinkBankStore.leaderId}>
        <form noValidate onSubmit={onSubmit}>
          {!CarrierLinkBankStore.loading['fetch.user'] ? renderInputs() : <></>}
          <MDBRow>
            <MDBCol size="12" md="8">
              <MDBBtn
                className="btn-block btn-submit"
                type="submit"
                disabled={!CarrierLinkBankStore.hasChanged}
              >
                {CarrierLinkBankStore.loading['update.comm-links'] ? (
                  <span>
                    Saving Links...&nbsp;
                    <i className="fa fa-spin fa-spinner" />
                  </span>
                ) : (
                  <>
                    Save Carrier-Agent Commission Links&nbsp;
                    <MDBIcon icon="save" />
                  </>
                )}
              </MDBBtn>
            </MDBCol>
            <MDBCol size="12" md="4">
              <MDBBtn
                block
                className="btn-cancel"
                onClick={onClose}
                type="button"
              >
                Done&nbsp;
                <MDBIcon icon="times" />
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </form>
      </MDBCollapse>
    )
  }

  return (
    <MDBCard id="CarrierContractLinkBankComponent">
      {renderLockOut()}
      <MDBCardHeader>Carrier Contract Link Bank</MDBCardHeader>
      <MDBCardBody>
        <MDBSelect
          options={CarrierLinkBankStore.Leaders.map((Leader, idx) => ({
            text: ucwords(`${Leader.get('u_fname')} ${Leader.get('u_lname')}`),
            value: `${Leader.id()}`,
            checked: `${Leader.id()}` === `${CarrierLinkBankStore.leaderId}`,
          }))}
          disabled={CarrierLinkBankStore.leaderId > 0}
          label="Choose Leader to access their Link Bank"
          name="user_id"
          value={'Choose Leader'}
          selected={'Choose Leader'}
          getValue={(evt) => {
            if (
              (evt = evt && evt.shift()) &&
              `${evt}` !== `${CarrierLinkBankStore.leaderId}`
            )
              viewLeader(evt)
          }}
        />
        {renderForm()}
      </MDBCardBody>
    </MDBCard>
  )
}

export default observer(CarrierContractLinkBank)
