import React from 'react'
import { observer } from 'mobx-react-lite'
import { MDBBtn, MDBIcon } from 'mdbreact'
import AgentContractManagerStore from './../AgentContractManager.store'
import { getMetaValue } from './../getMetaValue.function'
import { ClarityTag } from './../../../core/tracking/Clarity.functions'

import './SaveOnboardingCartButton.scss'

const SaveOnboardingCartButton = ({
  disabled,
  onCheckout,
  SelectedCartItems,
  requiredItems,
}) => {
  const CartMeta_ExtCarriers = (AgentContractManagerStore?.CartMetas || [])
      .filter(
        (CartMeta) => CartMeta.get('meta_key') === 'cart-items---ext-carriers'
      )
      .shift(),
    getCarrierByCartItem = (CartItem) =>
      (AgentContractManagerStore?.Carriers || [])
        .filter(
          (Carrier) =>
            parseInt(Carrier.id()) === parseInt(CartItem.get('carrier_id'))
        )
        .shift(),
    getExtCarrierByCartItem = (CartItem) =>
      (AgentContractManagerStore?.ExternalCarriers || [])
        .filter(
          (Carrier) =>
            parseInt(Carrier.get('carrier_id')) ===
            parseInt(CartItem.get('carrier_id'))
        )
        .shift(),
    getReplacementCarrierIdByExtCarrier = (ExternalCarrier) =>
      CartMeta_ExtCarriers &&
      ExternalCarrier?.get('carrier_id') &&
      getMetaValue(CartMeta_ExtCarriers, 'array')
        .filter(
          (e) =>
            parseInt(e.external_id) ===
            parseInt(ExternalCarrier.get('carrier_id'))
        )
        .shift()?.replacement_id,
    getReplacementCarrierById = (replacementId) =>
      (AgentContractManagerStore?.Carriers || [])
        .filter((Carrier) => parseInt(Carrier.id()) === parseInt(replacementId))
        .shift(),
    verifyCartItemCarrier = (CartItem) => {
      const Carrier = getCarrierByCartItem(CartItem),
        ExternalCarrier = getExtCarrierByCartItem(CartItem),
        replacementId = getReplacementCarrierIdByExtCarrier(ExternalCarrier),
        ReplacementCarrier = getReplacementCarrierById(replacementId)

      if (!ExternalCarrier && !ReplacementCarrier && Carrier) return Carrier
      else if (ReplacementCarrier) return ReplacementCarrier
      return false
    },
    MinimumCartItems = AgentContractManagerStore?.CartItems.map(
      verifyCartItemCarrier
    ).filter((CartItem) => !!CartItem)
  const getCheckoutItems = () => {
    if (SelectedCartItems && SelectedCartItems.length > 0)
      return SelectedCartItems.map(verifyCartItemCarrier).filter(
        (CartItem) => !!CartItem
      )
    return MinimumCartItems
  }

  return (
    <div id="SaveOnboardingCartButtonComponent">
      <MDBBtn
        block
        className="start-checkout-btn"
        onClick={() => {
          ClarityTag('onboarding', 'start-contracting')
          onCheckout
            ? onCheckout(getCheckoutItems())
            : AgentContractManagerStore.Cart.checkout(getCheckoutItems())
        }}
        disabled={!MinimumCartItems || MinimumCartItems.length < 8 || disabled}
      >
        {!MinimumCartItems || MinimumCartItems.length < 8 || disabled ? (
          requiredItems > 0 ? (
            `Select More ${requiredItems} contracts to get started.`
          ) : (
            'Select required carriers above to get started.'
          )
        ) : (
          <>
            Start Contracting&nbsp;
            <MDBIcon icon="arrow-right" />
          </>
        )}
      </MDBBtn>
    </div>
  )
}

export default observer(SaveOnboardingCartButton)
