import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import UserMetaFactory from './../../../../shared/factories/user-meta.factory'

const getGraphId = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const UserMeta = (
        (
          await UserMetaFactory.search({
            search: { meta_key: 'graph---user-id', user_id: userId },
          })
        ).models || []
      ).shift()
      resolve(UserMeta && UserMeta.get('meta_value'))
    } catch (ex) {
      console.log(ex)
      resolve('')
    }
  })
}

const AgentCorporateEmailLink = (props) => {
  const meetsRequirement = UserProfileService.isA(
    ['system-admin', 'agency-owner', 'internal-admin'],
    true
  )

  const [fetched, setFetched] = useState(false),
    [fetching, setFetching] = useState(false),
    [graphId, setGraphId] = useState(null)

  if (!fetched && !fetching && props?.userId) {
    setFetching(true)
    getGraphId(props?.userId)
      .then((graphId) => setGraphId(graphId))
      .finally(() => {
        setFetching(false)
        setFetched(true)
      })
  }

  if (!meetsRequirement) return <></>

  return (
    <>
      {graphId ? (
        <div
          style={{
            fontSize: '14px',
            fontWeight: '500',
            color: 'rgba(120,120,120,0.8)',
            textAlign: 'center',
          }}
        >
          <a
            href={`https://admin.microsoft.com/?source=applauncher#/users/:/UserDetails/${graphId}/Account`}
            target="_BLANK"
            rel="noopener noreferrer"
          >
            View Office 365 Profile
          </a>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default observer(AgentCorporateEmailLink)
