import React, { useEffect, useState } from 'react'
import { MDBBtn } from 'mdbreact'
import './AwardingTodayBtn.scss'
import { UserMetaFactory } from '../../../shared/factories'
import UserMetaService from '../../../shared/services/UserMeta.service'
import awardLevelsConstants from '../../../constants/award-levels.constants'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import UserProfileService from '../../../shared/services/UserProfile.service'

export const AwardingTodayBtn = (props) => {
  const [loading, setLoading] = useState(false)
  const [isAwarded, setIsAwarded] = useState(false)
  const [awardedData, setAwardedData] = useState(null)
  const [currentLevel, setCurrentLevel] = useState(null)
  let UserMeta
  const awardToday = async () => {
    if (isAwarded) {
      return
    }
    setLoading(true)
    UserMeta = props.awarded.MetaModel
    let meta_value = awardedData
    if (!meta_value) {
      meta_value = {}
    }
    if (!meta_value[props.awarded.year]) {
      meta_value[props.awarded.year] = {}
    }
    meta_value[props.awarded.year] = {
      ...meta_value[props.awarded.year],
      [currentLevel.name]: {
        ack_on: format(new Date(), 'yyyy-MM-dd'),
        ack_by: UserProfileService.getUserId(),
      },
    }
    if (!UserMeta) {
      UserMeta = await UserMetaFactory.create({
        meta_key: 'profile---pip-ack',
        user_id: props.awarded.id,
        meta_value: JSON.stringify(meta_value),
      })
      UserMeta = await UserMeta.save()
    } else {
      await UserMetaService.update(UserMeta.id(), {
        meta_value: JSON.stringify(meta_value),
      })
    }
    setLoading(false)
    setAwardedData(meta_value)
    setIsAwarded(true)
  }
  useEffect(() => {
    const levelId = parseInt(props.awarded.level.replace('level', ''))
    const level = awardLevelsConstants
      .filter((l) => l.level === levelId)
      .shift()
    setCurrentLevel(level)
    if (!props.awarded.MetaModel) {
      setIsAwarded(false)
      return
    }

    const metaValue = props.awarded.MetaModel.get('meta_value')
    if (!metaValue) {
      setIsAwarded(false)
      return
    }

    if (typeof metaValue === 'string') {
      const data = JSON.parse(metaValue)
      setAwardedData(data)
      if (!data[props.awarded.year]) {
        setIsAwarded(false)
        return
      }

      if (!data[props.awarded.year][level.name]) {
        setIsAwarded(false)
        return
      }

      setIsAwarded(true)
    } else {
      toast.error('Invalid meta value.')
    }
  }, [])
  return (
    <>
      {loading ? (
        <i className="fa fa-spin fa-spinner" />
      ) : isAwarded ? (
        <></>
      ) : (
        <MDBBtn
          color="indigo"
          className="awarding-today-btn"
          onClick={awardToday}
        >
          Awarding Today
        </MDBBtn>
      )}
    </>
  )
}
