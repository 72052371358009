import React, { useState } from 'react'
import UserTermService from '../../../../shared/services/UserTerm.service'
import { MDBBtn } from 'mdbreact'
import { LeaderTermsStore } from '../LeaderTermsReviewer/LeaderTermsReviewer.component'
import UserProfileService from '../../../../shared/services/UserProfile.service'
import { toast } from 'react-toastify'
import LoadingSpinner from '../../../../components/shared/LoadingSpinner.component'

export const ResetGuidelineButton = ({ term }) => {
  const [loading, setLoading] = useState(false)

  const reestGuideline = async () => {
    setLoading(true)

    try {
      await LeaderTermsStore.UserTerm.delete()
      await LeaderTermsStore.fetch(UserProfileService.getUserId())
    } catch (err) {
      toast.error(err.message)
    }

    setLoading(false)
  }

  if (
    UserProfileService.isA(
      ['system-admin', 'internal-admin', 'agency-owner'],
      true
    ) &&
    LeaderTermsStore.hasAccepted()
  )
    return (
      <MDBBtn
        tag="a"
        disabled={loading}
        color="danger"
        onClick={reestGuideline}
      >
        Reset Leader Guidelines {loading ? <LoadingSpinner /> : <></>}
      </MDBBtn>
    )

  return <></>
}
