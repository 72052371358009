import BaseModelInstance from './base.model'
import { UserCertification as ModelDefinition } from './model-definitions'

class UserCertification extends BaseModelInstance {
  afterSet = (key, value) => {}

  hasCertified = () => {
    const getDate = (fld) => `${this.get(fld) || ''}`
    return (
      (!getDate('effective_date') ||
        new Date(getDate('effective_date')).getTime() <=
          new Date().getTime()) &&
      (!getDate('expiration_date') ||
        new Date(getDate('expiration_date')).getTime() > new Date().getTime())
    )
  }

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }
}

export default UserCertification
