import React from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import LedgersStore from './../store'
import { MDBAlert, MDBIcon, MDBBtn, MDBCol, MDBCollapse } from 'mdbreact'
import moment from 'moment'

import './UpcomingExpirations.component.scss'

const UpcomingExpirations = observer(() => {
  const getExpirationTable = () => {
    if (LedgersStore.upcomingExpirations === true)
      return (
        <table>
          <tbody>
            <tr>
              <td valign="middle" style={{ textAlign: 'center' }}>
                Great! You have 0 credits pending expiration.
              </td>
            </tr>
          </tbody>
        </table>
      )

    const rows = (
      LedgersStore.upcomingExpirations !== true &&
      LedgersStore.upcomingExpirations
        ? Object.keys(LedgersStore.upcomingExpirations).map((days) => ({
            days,
            date: moment().add(days, 'days').format('MM/DD/YYYY'),
            amount:
              typeof LedgersStore.upcomingExpirations[days] === 'number' ||
              typeof LedgersStore.upcomingExpirations[days] === 'string'
                ? parseFloat(
                    `${LedgersStore.upcomingExpirations[days]}`.replace(
                      /[^0-9.]/g,
                      ''
                    )
                  )
                : LedgersStore.upcomingExpirations[days],
          }))
        : []
    ).filter(
      (r) => r && r?.amount !== null && !isNaN(r?.amount) && r.amount > 0
    )

    if (rows.length) {
      const makeRows = () =>
        rows.map((row) => (
          <tr key={`upcoming-expire-days-${row.days}`}>
            <td>
              ${row.amount} expires on {row.date}.
            </td>
            <td>({row.days} days)</td>
          </tr>
        ))
      return (
        <table>
          <tbody>{makeRows()}</tbody>
        </table>
      )
    }

    return
  }

  const getColor = () => {
    if (LedgersStore.updatingUpcomingExpirations) return 'warning'
    if (LedgersStore.upcomingExpirations === true) return 'success'
    if (Object.keys(LedgersStore.upcomingExpirations).length) return 'danger'
  }

  return (
    <div id="UpcomingExpirationsComponent" className="container-fluid">
      <MDBAlert color={getColor()}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h6>
                <strong>Upcoming Expirations:</strong>
              </h6>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {LedgersStore.updatingUpcomingExpirations ? (
                <div>
                  <strong>
                    Loading <i className="fa fa-spin fa-spinner"></i>
                  </strong>
                </div>
              ) : (
                getExpirationTable()
              )}
            </div>
          </div>
        </div>
      </MDBAlert>
    </div>
  )
})

export default UpcomingExpirations
