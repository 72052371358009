import { makeAutoObservable } from 'mobx'
import RestApi from './../../api/rest-api-client'
import UserProfileService from './../services/UserProfile.service'
import StorageService from './../services/Storage.service'

const getDate = () => {
  return [
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    new Date().getDate(),
  ].join('-')
}

const fetch = async (keys) => {
  try {
    return (
      (
        await RestApi.request('GET', '/system_settings', {
          search: { setting_key: keys },
          pagination: false,
        })
      ).models || []
    )
  } catch (ex) {
    console.error(`Failed to load system settings. ${ex}`)
  }

  return []
}

const Settings = {}

class SystemSettingStore {
  Settings = {}

  constructor() {
    makeAutoObservable(this)
  }

  save = async (settingKey) => {
    if (
      UserProfileService.isA(['system-admin', 'internal-admin', 'agency-owner'])
    ) {
      let Settings
      try {
        Settings = await fetch(settingKey)
      } catch (ex) {
        return false
      }

      if (Array.isArray(Settings) && Settings.length) {
        await Promise.all(
          Settings.map((S) =>
            RestApi.request('PUT', '/system_settings', S.id, {
              setting_value: JSON.stringify(this.get(settingKey)),
            })
          )
        )
        StorageService.set('_app_settings', Settings)
      }

      return true
    }
  }

  set = (settingKey, settingValue) => {
    if (
      UserProfileService.isA(['system-admin', 'internal-admin', 'agency-owner'])
    ) {
      this.Settings[settingKey] = settingValue
      Settings[settingKey] = settingValue
    }

    return false
  }

  get = (settingKey) => {
    if (
      Settings.hasOwnProperty(settingKey) &&
      typeof Settings[settingKey] === 'object'
    )
      return Settings[settingKey]
    return {}
  }

  load = async () => {
    if (UserProfileService.getUserId()) {
      // if (!this._getFromLocal()) {
      if (await this._getFromRemote()) {
        StorageService.set('_app_settings', Settings)
      }
      // }
    }
  }

  _getFromLocal = () => {
    try {
      const _settings = JSON.parse(StorageService.get('_app_settings'))
      if (!_settings || typeof _settings !== 'object') return false

      if (_settings?._fetched !== getDate()) return false

      Object.keys(Settings).forEach((setting) => delete Settings[setting])

      Object.keys(_settings).forEach(
        (setting) => (Settings[setting] = _settings[setting])
      )

      this.Settings = Settings

      return true
    } catch (ex) {}
  }

  _getFromRemote = async () => {
    const _settings = { _fetched: getDate() }

    ;(
      await fetch([
        'usertypes---break-the-bar-thresholds',
        'vendors---eo-policy-coverage',
        'beastmode---signup-links',
      ])
    ).forEach(
      (setting) =>
        (_settings[setting.setting_key] =
          typeof setting.setting_value === 'string'
            ? JSON.parse(setting.setting_value)
            : setting.setting_value)
    )

    Object.keys(Settings).forEach((setting) => delete Settings[setting])

    Object.keys(_settings).forEach(
      (setting) => (Settings[setting] = _settings[setting])
    )

    if (typeof _settings === 'object' && Object.keys(_settings).length > 1) {
      this.Settings = Settings

      return true
    }
  }
}

export default new SystemSettingStore()
