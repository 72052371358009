import React, { useEffect, useState } from 'react'
// import { observer } from 'mobx-react-lite'
import { MDBInput } from 'mdbreact'
import {
  checkValidity,
  getErrorMessage,
  getSuccessMessage,
  isDirty,
} from './../shared/validator.function'

const isEmpty = (value) =>
  typeof value === 'undefined' || value === null || value === ''

const UIInput = ({
  className,
  disabled,
  id,
  name,
  label,
  size,
  type,
  getValue,
  onBlur,
  onFocus,
  onChange,
  onValidityChange,
  required,
  hint,
  value,
  onKeyDown,
  onKeyPress,
  rules,
  showValidity,
  autoComplete,
}) => {
  rules = rules && typeof rules === 'object' ? rules : {}
  if (required) rules.required = true

  // Modify input for numeric types (integer, currency).
  switch (type && `${type}`.toLowerCase()) {
    case 'integer':
      if (!onKeyPress)
        onKeyPress = (evt) => {
          if (!/^\d$/.test(evt.key)) {
            evt.preventDefault()
            return false
          }
        }
      type = 'number'
      break
    case 'currency':
      type = 'number'
      break
  }

  const [uiState, setUiState] = useState({
    isDirty: false,
    isPristine: true,
    isTouched: false,
    validity: { isValid: false, errors: [] },
  })

  const _onFocus = onFocus
  onFocus = (evt) => {
    if (!uiState.isTouched)
      setUiState((prevState) => ({ ...prevState, isTouched: true }))
    ;(typeof _onFocus === 'function' ? _onFocus : (evt) => {})(evt)
  }

  const _onChange = onChange
  onChange = async (evt) => {
    evt = { target: { name: evt?.target?.name, value: evt?.target?.value } }

    setUiState((prevState) =>
      !uiState.isDirty && isDirty(value, evt.target.value)
        ? { ...prevState, isDirty: true, isPristine: false }
        : { ...prevState, isDirty: false }
    )
    ;(typeof _onChange === 'function' ? _onChange : (evt) => {})(evt)

    checkValidity(evt.target.value, rules).then((validity) => {
      setUiState((prevState) => ({ ...setUiState, validity }))
      ;(typeof onValidityChange === 'function'
        ? onValidityChange
        : (validity) => {})(validity)
    })
  }

  value = value === null || typeof value === 'undefined' ? '' : value
  onKeyDown = typeof onKeyDown === 'function' ? onKeyDown : () => {}
  onKeyPress = typeof onKeyPress === 'function' ? onKeyPress : () => {}
  required = (required && parseInt(required) === 1) || !!required
  autoComplete =
    typeof autoComplete === 'undefined'
      ? autoComplete
      : autoComplete === true ||
        (autoComplete &&
          ['on', '1', 'true'].includes(`${autoComplete}`.toLowerCase()))
      ? 'on'
      : 'off'

  const inputProps = {
    className,
    disabled,
    id,
    name,
    label,
    size,
    type,
    getValue,
    onBlur,
    onFocus,
    onChange,
    required,
    hint,
    value,
    onKeyDown,
    onKeyPress,
    autoComplete,
  }
  Object.keys(inputProps)
    .filter((key) => typeof inputProps[key] === 'undefined')
    .forEach((key) => delete inputProps[key])

  if (inputProps?.label && inputProps.required) {
    inputProps.label = (
      <>
        {inputProps.label}
        {<small>&nbsp;*</small>}
      </>
    )
  }

  useEffect(() => {
    checkValidity(value, rules).then((validity) => {
      setUiState((prevState) => ({ ...setUiState, validity }))
      ;(typeof onValidityChange === 'function'
        ? onValidityChange
        : (validity) => {})(validity)
    })
  }, [value])

  return (
    <div
      className={[
        'ui-input',
        uiState.validity.isValid ? 'is-valid' : 'is-invalid',
        uiState.isDirty ? 'is-dirty' : '',
        uiState.isPristine ? 'is-pristine' : '',
        uiState.isTouched ? 'is-touched' : '',
        disabled ? 'is-disabled' : '',
        showValidity ? 'show-validity' : '',
      ].join(' ')}
    >
      <MDBInput {...inputProps} />
      <div className="ui-input-validation">
        <span className="success-msg">
          {showValidity ? getSuccessMessage() : ''}
        </span>
        <span className="error-msg">
          {showValidity ? getErrorMessage(uiState.validity.errors) : ''}
        </span>
      </div>
    </div>
  )
}

export default UIInput

/*
	MDBInput properties:
		background
		checked
		className
		containerClass
		counter
		disabled
		error
		filled
		gap
		group
		hint
		icon
		iconBrand
		iconClass
		iconLight
		iconRegular
		iconSize
		id
		inputRef
		outline
		label
		labelClass
		labelId
		size
		success
		tag
		type
		validate
		value
		valueDefault

	MDBInput methods:
		getValue
		onBlur
		onChange
		onFocus
		onIconClick
		onIconMouseEnter
		onIconMouseLeave
		onInput
*/
