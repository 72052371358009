import { hex2Rgb } from './hex2rgb.function'

export function getFontColorFromBgColor(bgHexColor) {
  let rgbColor = hex2Rgb(bgHexColor)

  var o = Math.round(
    (parseInt(rgbColor.r) * 299 +
      parseInt(rgbColor.g) * 587 +
      parseInt(rgbColor.b) * 114) /
      1000
  )

  if (o > 125) {
    return 'black'
  }

  return 'white'
}
