import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import AuthService from './../../../../shared/services/Auth.service'
import Store from './../../shared/Enrollment.store'
import {
  MDBCollapse,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBAlert,
  MDBIcon,
} from 'mdbreact'

const EnrollingAgentBar = ({ goTo }) => {
  const [isLoading, setIsLoading] = useState(false)

  const getAgent = () => {
    if (
      (Store.isAuthenticated() && Store.isAssumed()) ||
      (UserProfileService.getUserId() && UserProfileService.isAssumed())
    )
      return `${UserProfileService.get(
        'u_fname',
        true
      )} ${UserProfileService.get('u_lname', true)}`.trim()
    return false
  }

  const backToOffice = async () => {
    setIsLoading(true)

    if (UserProfileService.isAssumed()) {
      Store.reset()
      try {
        await AuthService.unassumeUser()
      } catch (ex) {
        console.error(`${ex}`)
        setIsLoading(false)
        return false
      }
    }

    try {
      goTo('/dashboard')
      return true
    } catch (ex) {
      console.error(`${ex}`)
      setIsLoading(true)
      return false
    }
  }

  return (
    <MDBCollapse isOpen={!!getAgent()}>
      <MDBAlert color="info">
        <MDBContainer fluid>
          <MDBRow>
            <MDBCol size="12" md="6" className="text-left">
              <strong>Currently logged in as {getAgent()}.</strong>
            </MDBCol>
            <MDBCol size="12" md="6" className="text-right">
              <button disabled={isLoading} onClick={() => backToOffice()}>
                <strong>
                  Return to Back Office&nbsp;
                  {isLoading ? (
                    <i className="fa fa-spin fa-spinner"></i>
                  ) : (
                    <MDBIcon icon="arrow-right" />
                  )}
                </strong>
              </button>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBAlert>
    </MDBCollapse>
  )
}

export default observer(EnrollingAgentBar)
