import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import AdminApi from './../../../../api/admin-api/admin-api'
import ContentHtml from './../../../../components/content/ContentHtml/ContentHtml.component'
import { WebsiteInfoStore } from './../../stores'
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCollapse,
  MDBAlert,
} from 'mdbreact'

import './AnalyticsForm.component.scss'

const saveTimer = {}

const AnalyticsForm = ({ s_agent_picture }) => {
  const [formError, setFormError] = useState(null),
    [formSuccess, setFormSuccess] = useState(null)

  const updateInfo = async () => {
    if (WebsiteInfoStore.isLoading || WebsiteInfoStore.isSaving) return

    const data = {
      google_tag: WebsiteInfoStore.siteDetails?.google_tag,
      id: WebsiteInfoStore.siteDetails?.site_id,
    }

    let form_data = new FormData()
    for (let key in data) form_data.append(key, data[key])

    WebsiteInfoStore.isSaving = true

    AdminApi.updateWebsiteInfo(form_data)
      .then((result) => {
        setFormSuccess('Successfully updated analytics settings.')

        if (saveTimer.timer) {
          window.clearTimeout(saveTimer.timer)
          saveTimer.timer = null
        }

        saveTimer.timer = setTimeout(() => setFormSuccess(null), 4000)

        WebsiteInfoStore.isSaving = false
      })
      .catch((error) => {
        setFormError(error)
        WebsiteInfoStore.isSaving = false
      })
  }

  return (
    <form
      id="AnalyticsFormComponent"
      noValidate
      className="container-fluid mt-5"
    >
      <MDBRow>
        <MDBCol size="12">
          <h5 className="text--blue font--lato fw--500">Subsite Analytics</h5>
        </MDBCol>
        <MDBCol size="12">
          <ContentHtml contentSlug="subsite-analytics-howto" />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol className="form-group">
          <label>Google Analytics Tracking ID</label>
          <input
            type="text"
            className="form-control"
            name="google_tag"
            id="google_tag"
            value={WebsiteInfoStore.siteDetails?.google_tag || ''}
            onChange={(event) =>
              (WebsiteInfoStore.siteDetails.google_tag = event.target.value)
            }
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol className="form-group">
          <MDBBtn color="indigo" type="button" onClick={updateInfo}>
            {WebsiteInfoStore.isSaving ? 'Saving...' : 'Save'}
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBCollapse isOpen={!!formError}>
            <MDBAlert color="danger">{formError}</MDBAlert>
          </MDBCollapse>
          <MDBCollapse isOpen={!!formSuccess}>
            <MDBAlert color="success">{formSuccess}</MDBAlert>
          </MDBCollapse>
        </MDBCol>
      </MDBRow>
    </form>
  )
}

export default observer(AnalyticsForm)
