import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact'
import { UIInput, UIDropdown } from './../../../../components/forms/form-fields'
import Store from './../../store'
import { teamLogoImage } from './../../helpers/teamLogoImage.function'
import UploadBATeamLogoButton from './../UploadBATeamLogoButton/UploadBATeamLogoButton.component'

import './BATeamForm.scss'

const BATeamForm = ({ BATeam }) => {
  const [isSaving, setIsSaving] = useState(false),
    [showValidity, setShowValidity] = useState(false)

  const handleSubmit = async (evt) => {
    if (evt.preventDefault) evt.preventDefault()

    if (!Store.isValid(BATeam)) {
      if (!showValidity) setShowValidity(true)
      return false
    }

    if (showValidity) setShowValidity(false)
    setIsSaving(true)

    try {
      await BATeam.save()
      setIsSaving(false)
      return true
    } catch (ex) {
      console.log('FAIL', ex)
      setIsSaving(false)
      return false
    }
  }

  return (
    <form
      className="content-wrapper container-fluid mb-4 BATeamFormComponent"
      noValidate
      onSubmit={(evt) => handleSubmit(evt)}
    >
      <div className="row">
        <div className="col">
          <div className="mb-3">{teamLogoImage(BATeam)}</div>
          <UploadBATeamLogoButton
            BATeam={BATeam}
            block
            size="sm"
            type="button"
            disabled={Store.isFetching || isSaving}
            label={
              <>
                Upload Benefit Advisor Team Logo &nbsp;
                <MDBIcon icon="cloud-upload-alt" />
              </>
            }
          />
        </div>
        <div className="col">
          <div>
            <UIInput
              label="BA Team Name"
              name="team_name"
              value={BATeam?.get('team_name') || ''}
              onChange={(evt) => {
                if (BATeam) BATeam.set('team_name', evt.target.value)
              }}
            />
          </div>
          <div>
            <UIDropdown
              label="BA Team Leader"
              name="owner_id"
              options={Store.Agents.map((Agent) => ({
                text: `${Agent.get('u_fname')} ${Agent.get('u_lname')}`.trim(),
                value: `${Agent.id()}`,
                checked: `${Agent.id()}` === `${BATeam?.get('owner_id')}`,
              }))}
              onChange={(evt) => {
                if (
                  `${evt.target.value}` !== `${BATeam?.get('owner_id')}` &&
                  evt.target.value
                )
                  BATeam.set('owner_id', evt.target.value)
              }}
              required={true}
              value={BATeam.get('owner_id')}
              rules={{ required: true }}
              showValidity={showValidity}
              onValidityChange={(validity) => {
                /* SalesTrackerStore.setValidity(idx, 'av_term_length', validity.isValid) */
              }}
            />
          </div>
          <div>
            <MDBBtn block type="submit" disabled={Store.isFetching || isSaving}>
              {BATeam?.isNew() ? 'Save' : 'Update'} Benefit Advisor Team &nbsp;
              <MDBIcon icon="save" />
            </MDBBtn>
          </div>
        </div>
      </div>
    </form>
  )
}

export default observer(BATeamForm)
