import { email } from './../../../shared/patterns/email.pattern'
import { name } from '../../../shared/patterns/name.pattern'

const checkValidity = async (value, rules) => {
  let validity = {
    isValid: true,
    errors: [],
  }

  if (
    !!rules?.required &&
    (value === null || typeof value === 'undefined' || value === '')
  ) {
    validity.isValid = false
    validity.errors.push('required')
    return validity
  }

  if (
    !isNaN(rules?.minLength) &&
    rules.minLength !== null &&
    rules.minLength > 0
  ) {
    if (
      typeof value === 'string' &&
      `${value}`.length &&
      `${value}`.length < rules.minLength
    ) {
      validity.isValid = false
      validity.errors.push('minLength')
      return validity
    }
  }

  if (
    !isNaN(rules?.maxLength) &&
    rules.maxLength !== null &&
    rules.maxLength > 0
  ) {
    if (typeof value === 'string' && `${value}`.length > rules.maxLength) {
      validity.isValid = false
      validity.errors.push('maxLength')
      return validity
    }
  }

  if (!isNaN(rules?.minValue) && rules.minValue !== null) {
    if (!isNaN(value) && value !== null) {
      if (rules.minValue > value) {
        validity.isValid = false
        validity.errors.push('minValue')
        return validity
      }
    }
  }

  if (!isNaN(rules?.maxValue) && rules.maxValue !== null) {
    if (!isNaN(value) && value !== null) {
      if (rules.maxValue < value) {
        validity.isValid = false
        validity.errors.push('maxValue')
        return validity
      }
    }
  }

  if (rules?.pattern && rules.pattern instanceof RegExp) {
    if (
      typeof value === 'string' &&
      !new RegExp(rules.pattern).test(`${value}`)
    ) {
      validity.isValid = false
      validity.errors.push('pattern')
      return validity
    }
  }

  if (rules?.format) {
    switch (rules?.format) {
      case 'email':
        if (typeof value === 'string' && !new RegExp(email).test(`${value}`)) {
          validity.isValid = false
          validity.errors.push('format')
          return validity
        }
        break
      case 'name':
        if (typeof value === 'string' && !new RegExp(name).test(`${value}`)) {
          validity.isValid = false
          validity.errors.push('format')
          return validity
        }
        break
      default:
        break
    }
  }

  if (rules?.callback && typeof rules?.callback === 'function') {
    let result = await rules?.callback({ value, rules })
    if (result) return result
  }

  return validity
}

const getErrorMessage = (errors) => {
  if (Array.isArray(errors)) {
    if (errors.length && errors[0] && typeof errors[0] === 'object')
      return errors[0]

    return errors
      .map((error) => {
        switch (error) {
          case 'required':
            return 'Required'
          case 'minLength':
            return 'Too Short'
          case 'maxLength':
            return 'Too Long'
          case 'minValue':
            return 'Must be greater'
          case 'maxValue':
            return 'Exceeds Max'
          case 'format':
          case 'pattern':
            return 'Invalid Format'
          default:
            if (error && typeof error === 'string')
              return error.charAt(0).toUpperCase() + error.substr(1)
            if (error) return error
            return 'Invalid Input'
        }
      })
      .join(', ')
  }

  return ''
}

const getSuccessMessage = () => {}

const isDirty = (sourceValue, changeValue) => {
  if (
    (typeof sourceValue === 'undefined' || sourceValue === null) &&
    (typeof changeValue === 'undefined' || changeValue === null)
  ) {
    /* no trackable change */
  } else if (`${sourceValue}` !== `${changeValue}`) return true
  return false
}

export { checkValidity, getErrorMessage, getSuccessMessage, isDirty }
