import { makeAutoObservable, toJS } from 'mobx'
import AdminApi from './../../../api/admin-api/admin-api'
import UserProfileService from './../../../shared/services/UserProfile.service'
import {
  LedgerFactory,
  LeadVendorFactory,
  LeadCreditPackageFactory,
  LeadCreditQuantityPriceFactory,
} from './../../../shared/factories'
import LeadVendorService from './../../../shared/services/LeadVendor.service'

async function fetchAgentLeadsData(search) {
  try {
    return (await AdminApi.getAgencyContentBySlug(search))?.data?.data
  } catch (ex) {
    return null
  }
}

class OrderLeadsStore {
  purchase = {
    Package: null,
    QtyPrice: null,
    approved: false,
  }
  metrics = {}
  metricsLoaded = {}
  LeadVendors = []
  LeadCreditPackages = []
  leadOptions = null
  AgentLedger = null

  constructor() {
    makeAutoObservable(this)
  }

  init = async () => {
    this.isLoading = true

    const canEdit = UserProfileService.isA(
        ['agency-owner', 'system-admin', 'internal-admin'],
        true
      ),
      [RespA, RespB] = await Promise.all([
        LeadVendorService.pages('order-leads'),
        fetchAgentLeadsData({ slug: 'order-leads', active: canEdit ? 0 : 1 }),
      ])
    this.leadOptions = RespB

    let { LeadVendors, Ledger, LeadCreditPackages } = RespA || {}
    this.AgentLedger = Ledger ? LedgerFactory.create(Ledger) : null
    this.LeadVendors = (Array.isArray(LeadVendors) ? LeadVendors : [])
      .map((Vendor) => LeadVendorFactory.create(Vendor))
      .filter((Vendor) => !!Vendor.id() && !Vendor.get('deleted_at'))
    this.LeadCreditPackages = (
      Array.isArray(LeadCreditPackages) ? LeadCreditPackages : []
    )
      .map((Pkg) => LeadCreditPackageFactory.create(Pkg))
      .filter((Pkg) => !!Pkg.id() && !Pkg.get('deleted_at'))

    this.isLoading = false
    return
  }

  getLedgerMetrics = async () => {
    if (this.AgentLedger?.isNew() === false) {
      if (!this.metricsLoaded[this.AgentLedger.id()]) {
        this.metrics[this.AgentLedger.id()] = {}
        this.metricsLoaded[this.AgentLedger.id()] = true
        this.metrics[this.AgentLedger.id()] =
          await this.AgentLedger.getMetrics()
      }
    }
  }

  reset = () => {
    if (this.AgentLedger) {
      try {
        this.AgentLedger.reload()
      } catch (ex) {
        this.AgentLedger = null
      }
    }

    this.purchase = {
      Package: null,
      QtyPrice: null,
      approved: false,
    }
    this.metrics = {}
    this.metricsLoaded = {}
  }
}

export default new OrderLeadsStore()
