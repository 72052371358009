import React from 'react'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import { MDBCollapse, MDBContainer } from 'mdbreact'
import PageHeading from './../../components/shared/PageHeading.component'
import UserProfileService from './../../shared/services/UserProfile.service'
import Packages from './components/Packages/Packages.component'
import Terms from './components/Terms/Terms.component'
import { BeastModeTermsStore } from './components/TermsReviewer/TermsReviewer.component'

import './BeastModeSignUpPage.scss'

class BeastModeSignUpPage extends React.Component {
  state = {
    view: 'packages',
    package: null,
  }

  componentDidMount() {
    BeastModeTermsStore.fetch(UserProfileService.getUserId())
  }

  render() {
    return (
      <DashboardLayout>
        <main id="BeastModeSignUpPage" className="mainSection">
          <MDBContainer fluid className="mt-5">
            <PageHeading
              label="BeastMode 10x CRM Signup"
              url={this.props?.match?.url}
              from={this.props?.location?.state?.from}
            />
          </MDBContainer>
          <MDBCollapse
            isOpen={this.state.view === 'packages'}
            onClosed={() => this.setState({ view: 'terms' })}
          >
            <Packages
              onSelect={(pkg) =>
                this.setState({ package: pkg, view: 'next.terms' })
              }
            />
          </MDBCollapse>
          <MDBCollapse
            isOpen={this.state.view === 'terms'}
            onClosed={() => this.setState({ view: 'packages' })}
          >
            <Terms
              onCancel={() => this.setState({ view: 'next.packages' })}
              pkg={this.state.package}
            />
          </MDBCollapse>
        </main>
      </DashboardLayout>
    )
  }
}

export default BeastModeSignUpPage
