import env from './../../environments/environment'

// This is a fall-back in the event
// dynamic methods fail or throw exceptions.
//
// Not yet implemented.
const definedKeys = [
  // primary user.
  'user_fb',
  'user_details',
  'user_token',
  // child (assumed) user.
  'child_fb',
  'child_details',
  'child_token',
  'child_assumed_at',
  // assumed env shortcuts
  'parent_name',
  'parent_id',
  'parent_usertype_id',
  'prev_url',
]

const encodeKey = (key) =>
  env?.storage?.encode_keys === false ? key : btoa(key)

const decodeKey = (key) =>
  env?.storage?.encode_keys === false ? key : atob(key)

const encodeVal = (val) =>
  env?.storage?.encode_keys === false ? val : btoa(encodeURIComponent(val))

const decodeVal = (val) =>
  env?.storage?.encode_keys === false
    ? val
    : val
    ? decodeURIComponent(atob(val))
    : ''

const get = (storageKey) => {
  try {
    return decodeVal(localStorage.getItem(encodeKey(storageKey)))
  } catch (ex) {
    console.error(
      'ERROR: Exception thrown attempting to read local storage. ',
      ex
    )
    return undefined
  }
}

const set = (storageKey, storageValue) => {
  try {
    localStorage.setItem(
      encodeKey(storageKey),
      encodeVal(
        typeof storageValue === 'object'
          ? JSON.stringify(storageValue)
          : storageValue
      )
    )
    return true
  } catch (ex) {
    console.error(
      'ERROR: Exception thrown attempting to store local storage. ',
      ex
    )
    return undefined
  }
}

const getAllKeys = () => {
  try {
    return Object.keys(localStorage).map((k) => decodeKey(k))
  } catch (ex) {
    return definedKeys
  }
}

const clear = (key) => localStorage.removeItem(encodeKey(key))

const clearAllKeys = (opts) =>
  getAllKeys()
    .filter(
      (key) =>
        !(opts && Array.isArray(opts?.except) ? opts.except : []).includes(key)
    )
    .forEach((key) => localStorage.removeItem(encodeKey(key)))

const getToken = () => get('child_token') || get('user_token')

const setToken = (token) =>
  !!set(get('child_token') ? 'child_token' : 'user_token', token)

const StorageService = {
  get,
  set,
  getAllKeys,
  clearAllKeys,
  clear,
  setToken,
  getToken,
}

export default StorageService
