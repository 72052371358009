import React, { useState, useEffect } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import {
  UIInput,
  UISelect,
  UIInputButton,
  UIDatePickerInput,
} from './../../forms/form-fields'
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCollapse,
  MDBRow,
  MDBCol,
  MDBAlert,
  MDBContainer,
  MDBBtn,
  MDBIcon,
} from 'mdbreact'
import LedgerStore from './../store'
import Store from './../store/Transfer.store'
import UserProfileService from './../../../shared/services/UserProfile.service'
import moment from 'moment'

import './EditTransferForm.component.scss'

const getStateOptions = (transState) => [
  { value: 'PENDING', text: 'Pending', checked: transState === 'PENDING' },
  { value: 'CLOSED', text: 'Closed', checked: transState === 'CLOSED' },
  { value: 'CANCELED', text: 'Canceled', checked: transState === 'CANCELED' },
]

const getUserOptions = (userId) => {
  return Store.Users.filter(
    (user) => ![111, 133, 223, 224].includes(user.usertype_id)
  ).map((user) => ({
    text: (
      <>
        {user.name + ' '}
        <small>{user.email}</small>|<small>{user.usertype}</small>
      </>
    ),
    value: `${user.id}`,
    checked: !!(userId && `${userId}` === `${user.id}`),
  }))
}

const getCarrierOptions = (carrierId) => {
  return Store.Carriers.map((carrier) => ({
    text: (
      <>
        {carrier.name + ' '}
        <small>{carrier.description}</small>
      </>
    ),
    value: `${carrier.id}`,
    checked: !!(carrierId && `${carrierId}` === `${carrier.id}`),
  }))
}

const getFmoOptions = (fmoId) => {
  return Store.Fmos.map((fmo) => ({
    text: <>{fmo.name + ' '}</>,
    value: `${fmo.id}`,
    checked: !!(fmoId && `${fmoId}` === `${fmo.id}`),
  }))
}

const getCategoryOptions = (category) => {
  return Store.categories(Store.transfer.form).map((cat) => ({
    value: cat,
    text: Store.category(cat),
    checked: category === cat,
  }))
}

const TransferSourceCarrier = observer(() => {
  const ledgerId = Store.transfer.ledger_id || Store.Ledger?.id(),
    onCarrierSelect = async (carrierId) => {
      Store.transfer = {
        ...Store.transfer,
        carrier_id: carrierId,
        carrier_name: getCarrierOptions(carrierId)
          .filter((opt) => opt.checked)
          .shift()?.name,
      }
    }

  return (
    <UISelect
      label="Carrier"
      name="carrier_id"
      disabled={
        Store.isDisabled || Store.Transaction?.get('trans_state') !== 'PENDING'
      }
      options={getCarrierOptions(Store.transfer.carrier_id)}
      value={Store.transfer.carrier_id || ''}
      selected={Store.transfer.carrier_name || ''}
      onChange={(evt) => onCarrierSelect(evt.target.value)}
      required={true}
      rules={{ required: true }}
      showValidity={Store.showErrors}
    />
  )
})

const TransferSourceFmo = observer(() => {
  const ledgerId = Store.transfer.ledger_id || Store.Ledger?.id(),
    onFmoSelect = async (fmoId) => {
      Store.transfer = {
        ...Store.transfer,
        fmo_id: fmoId,
        fmo_name: getFmoOptions(fmoId)
          .filter((opt) => opt.checked)
          .shift()?.name,
      }
    }

  return (
    <UISelect
      label="FMO"
      name="fmo_id"
      disabled={
        Store.isDisabled || Store.Transaction?.get('trans_state') !== 'PENDING'
      }
      options={getFmoOptions(Store.transfer.fmo_id)}
      value={Store.transfer.fmo_id || ''}
      selected={Store.transfer.fmo_name || ''}
      onChange={(evt) => onFmoSelect(evt.target.value)}
      required={true}
      rules={{ required: true }}
      showValidity={Store.showErrors}
    />
  )
})

const TransferAgentRecipient = observer(() => {
  const ledgerId = Store.transfer.ledger_id || Store.Ledger?.id(),
    onAgentSelect = async (agentId) => {
      let Agent
      Store.setAgent(
        (Agent = await Store.findAgentById(agentId)),
        await Store.findOrCreateLedgerByAgent(Agent)
      )
    }

  return (
    <UISelect
      label="Agent"
      name="recipient_id"
      disabled={
        Store.isDisabled || Store.Transaction?.get('trans_state') !== 'PENDING'
      }
      options={getUserOptions(Store.transfer.recipient_id)}
      value={Store.transfer.recipient_id || ''}
      selected={Store.transfer.recipient_name || ''}
      onChange={(evt) => onAgentSelect(evt.target.value)}
      required={true}
      rules={{ required: true }}
      showValidity={Store.showErrors}
    />
  )
})

const TransferAmount = observer(() => {
  return (
    <UIInput
      label="Amount"
      name="amount"
      search
      disabled={
        Store.isDisabled || Store.Transaction?.get('trans_state') !== 'PENDING'
      }
      className="trans-agent-amount"
      value={Store.transfer.amount || ''}
      onChange={(evt) => (Store.transfer.amount = evt.target.value)}
      required={true}
      rules={{ required: true }}
      type="integer"
      showValidity={Store.showErrors}
    />
  )
})

const TransferCategory = observer(() => (
  <UISelect
    label="Category"
    name="category"
    options={getCategoryOptions(Store.transfer.category)}
    selected={Store.category(Store.transfer.category)}
    onChange={(evt) => (Store.transfer.category = evt.target.value)}
    required={true}
    value={Store.transfer.category || ''}
    rules={{ required: true }}
    showValidity={Store.showErrors}
  />
))

const TransferPurpose = observer(() => (
  <UIInput
    label="Reason, purpose or detail"
    name="purpose"
    disabled={
      Store.isDisabled || Store.Transaction?.get('trans_state') !== 'PENDING'
    }
    value={Store.transfer.purpose || ''}
    onChange={(evt) => (Store.transfer.purpose = evt.target.value)}
    value={Store.transfer.purpose || ''}
    onChange={(evt) => (Store.transfer.purpose = evt.target.value)}
    required={true}
    rules={{ required: true }}
    type="text"
    showValidity={Store.showErrors}
  />
))

const SubmitOutcome = observer(() => {
  return (
    <MDBCollapse
      isOpen={
        !!(Store.outcomeMsg && (Store.showErrorMsg || Store.showSuccessMsg))
      }
    >
      <MDBAlert color={Store.showSuccessMsg ? 'success' : 'danger'}>
        <MDBIcon
          icon={Store.showSuccessMsg ? 'dollar-sign' : 'exclamation-triangle'}
        />
        &nbsp;{Store.outcomeMsg}
      </MDBAlert>
    </MDBCollapse>
  )
})

const TransferState = observer(() => {
  const ledgerId = Store.transfer.ledger_id || Store.Ledger?.id(),
    onStateSelect = async (transState) => {
      Store.transfer = {
        ...Store.transfer,
        trans_state: transState,
      }
    }

  return (
    <UISelect
      label="Transaction Status:"
      name="trans_state"
      disabled={
        Store.isDisabled || Store.Transaction?.get('trans_state') !== 'PENDING'
      }
      options={getStateOptions(Store.transfer.trans_state)}
      value={Store.transfer.trans_state || ''}
      selected={Store.transfer.trans_state || 'PENDING'}
      onChange={(evt) => onStateSelect(evt.target.value)}
      required={true}
      rules={{ required: true }}
      showValidity={Store.showErrors}
    />
  )
})

const TransferFormSubmit = observer(() => {
  if (Store.Transaction?.get('trans_state') !== 'PENDING') return <></>

  return (
    <MDBBtn
      disabled={Store.isValidating || Store.isSaving}
      className="transfer-form-submit"
      type="submit"
    >
      {Store.isSaving ? (
        <>
          <i className="fa fa-spin fa-spinner"></i>&nbsp;Saving Transaction
        </>
      ) : (
        <>
          <MDBIcon icon="save" />
          &nbsp;Save Transaction
        </>
      )}
    </MDBBtn>
  )
})

const TransferForm = observer(() => {
  const getForm = () => {
    const getDefault = () => {
      const cats = getCategoryOptions()
      return (
        <>
          <TransferAmount />
          {cats && cats.length === 1 ? <></> : <TransferCategory />}
          <TransferPurpose />
          <SubmitOutcome />
          <TransferState />
          <TransferFormSubmit />
        </>
      )
    }

    switch (Store.transfer.form) {
      case 'AgentAchievement':
        if (
          UserProfileService.isA(
            ['system-admin', 'internal-admin', 'agency-owner'],
            true
          )
        )
          return getDefault()
      case 'AgentTransfer':
        return (
          <>
            <TransferAgentRecipient />
            {getDefault()}
          </>
        )
      case 'CarrierBonus':
        return (
          <>
            <TransferSourceCarrier />
            {getDefault()}
          </>
        )
      case 'FmoBonus':
        return (
          <>
            <TransferSourceFmo />
            {getDefault()}
          </>
        )

      case 'CashDeposit':
      case 'Purchase ':
      case 'RymaxTransfer':
      case 'SwagStoreTransfer':
      case 'TravelRequest':
      case 'LeadCreditImport':
      case 'LeadVendorTransfer':
      case 'CashWithdrawal':
      case 'StripeDeposit':
      case 'TravelRequest':
        return getDefault()

      default:
        return <></>
    }
  }

  return (
    <form
      id="TransferFormComponent"
      noValidate
      onSubmit={async (evt) => {
        evt.preventDefault()
        if ((await Store.validate()) && (await Store.save())) {
          await LedgerStore.searchAgentLedgerTransactions({
            pagination: toJS(LedgerStore.ledgerTransactionPagination),
          })
          LedgerStore.Ledger = await LedgerStore.findLedgerById(
            LedgerStore.Ledger.id()
          )
          LedgerStore.Transaction = null
          Store.Transaction = null
        }
        return false
      }}
    >
      {getForm()}
    </form>
  )
})

const MAX_TRANSACT_DATE = new Date()

const ucwords = (str) =>
  str.replace(/^([a-z])|[\s_]+([a-z])/g, ($1) => $1.toUpperCase())

const EditTransferForm = ({ showValidity, onClose }) => {
  const [formValidity, setFormValidity] = useState({}),
    [transType, setTransType] = useState(),
    transId = Store.Transaction?.id()

  const onChange = (event) => {
    if (['credit', 'debit'].includes(event.target.name)) {
      if (event.target.name === 'debit') {
        if (
          event.target.value &&
          !isNaN(event.target.value) &&
          (isNaN(Store.Transaction.get('credit')) ||
            parseInt(Store.Transaction.get('credit')) > 0)
        )
          Store.Transaction.set('credit', 0)
      } else if (event.target.name === 'credit') {
        if (
          event.target.value &&
          !isNaN(event.target.value) &&
          (isNaN(Store.Transaction.get('debit')) ||
            parseInt(Store.Transaction.get('debit')) > 0)
        )
          Store.Transaction.set('debit', 0)
      }
    }

    Store.Transaction.set(event.target.name, event.target.value)
  }

  const shouldOpen = !!(
    Store.Transaction &&
    (Store.Transaction.isNew() || Store.Transaction.id() > 0)
  )

  return (
    <div id="EditTransferFormComponent">
      <MDBCollapse isOpen={!!Store.Transaction}>
        <MDBCard>
          <MDBCardHeader>
            Update Ledger Transaction
            <button
              type="button"
              onClick={() => {
                Store.end()
                onClose()
              }}
            >
              <MDBIcon icon="times" />
            </button>
          </MDBCardHeader>
          <MDBCardBody>{shouldOpen ? <TransferForm /> : <></>}</MDBCardBody>
        </MDBCard>
      </MDBCollapse>
    </div>
  )
}

export default observer(EditTransferForm)
