import React from 'react'
import { observer } from 'mobx-react-lite'
import VersionsTable from './../components/VersionsTable.component'
import LoadingSpinner from './../../../components/shared/LoadingSpinner.component'
import Store from './../store'
import {
  MDBBtn,
  MDBModalHeader,
  MDBModalBody,
  MDBModal,
  MDBCollapse
} from 'mdbreact'

import './ChangeLogModal.component.scss'

const ChangeLogModal = ({
  isOpen,
  onResolve,
  onReject,
}) => {
  return (
    <MDBModal size="lg" isOpen={isOpen} toggle={onReject} id="ChangeLogModalComponent">
      <MDBModalHeader toggle={onReject}>USABG Tech Releases, Tips & Tricks</MDBModalHeader>
      <MDBModalBody className={Store.isLoading ? 'is-loading' : ''}>
        <div className="loading-wrapper">
          <LoadingSpinner size="md" isActive={true} />
        </div>
        <MDBCollapse isOpen={!Store.isLoading} className='content-modal-wrapper'>
        	<VersionsTable />
        	{
        		Store.safetyNet === true
        			? <MDBBtn block onClick={onResolve} className='btn safety-net-btn'>Log me out & download latest version!</MDBBtn>
        			: <MDBBtn block onClick={onReject} className='btn btn-info'>OK</MDBBtn>
        	}
					
        </MDBCollapse>
      </MDBModalBody>
    </MDBModal>
  )
}

export default observer(ChangeLogModal)
