import React from 'react'
import { observer } from 'mobx-react-lite'
import AuthStore from './../Auth.store'
import { MDBIcon } from 'mdbreact'
import AuthMessages from './../AuthMessages/AuthMessages.component'
import { ClarityTag } from './../../../core/tracking/Clarity.functions'

import './ResetPassword.scss'

const ResetPassword = ({ onSuccess }) => {
  const onReset = async (event) => {
    event.preventDefault()

    ClarityTag('auth', 'reset-password')

    let redirect
    try {
      redirect = await AuthStore.handleResetPassword()
    } catch (ex) {}

    if (redirect) onSuccess()
  }

  return (
    <div id="ResetPasswordComponent">
      <div className="block-shadow text-center">
        <div className="block-inner">
          <p className="h7 text-abbey text-bold">Reset Your Password</p>
          <div className="offset-top-40 offset-sm-top-60">
            <span className="icon icon-xl icon-gray-base material-icons-lock_open"></span>
          </div>
        </div>
        <form
          method="POST"
          className="rd-mailform form-modern form-darker offset-top-40"
          onSubmit={onReset}
          noValidate
        >
          <div className="block-inner">
            <div className="form-group">
              <input
                type="password"
                name="password"
                className="form-control"
                placeholder="Password"
                onKeyPress={() => (AuthStore.error = null)}
                onChange={(e) => (AuthStore.password = e.target.value)}
              />
            </div>
            <div className="form-group offset-top-22">
              <input
                type="password"
                name="confirm_password"
                className="form-control"
                placeholder="Confirm Password"
                onKeyPress={() => (AuthStore.error = null)}
                onChange={(e) => (AuthStore.confirm_password = e.target.value)}
              />
            </div>
          </div>
          <AuthMessages />
          <div className="submit-wrapper">
            <button type="submit" className="btn btn-primary">
              {AuthStore.loading ? 'Resetting...' : 'Reset Password'}
            </button>
          </div>
        </form>
      </div>
      <span className="help-link">
        <a
          href="/login"
          onClick={(e) => {
            e.preventDefault()
            AuthStore.goToState('login')
          }}
        >
          <MDBIcon icon="left-arrow" />
          &nbsp;Go Back to Login
        </a>
      </span>
    </div>
  )
}

export default observer(ResetPassword)
