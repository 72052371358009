import React from 'react'
import { observer } from 'mobx-react-lite'
import stripePrices from './../../../../constants/stripePrices.constants'
import LoadingSpinner from './../../../../components/shared/LoadingSpinner.component'
import PaymentMethodForm from './../../../../components/Stripe/PaymentMethodForm/PaymentMethodForm.component'
import Store from './../../shared/Enrollment.store';
import UserProfileService from './../../../../shared/services/UserProfile.service'
import { convertToCurrency } from './../../../../shared/formatters/convertToCurrency.formatter'
import moment from "moment"
import {
  MDBContainer,
  MDBRow,
  MDBCol
} from 'mdbreact'

import './IntakeFormPayment.scss'

const LINE_ITEMS = {
	one_time: 		{label: 'Onboarding & Account Setup', price: '50.00'},
	recurring: 		{label: 'Signature Agent Fee', 				price: '49.95'},
	crm: 					{label: 'BeastMode 10x CRM', 					price: '79.95'}
}

const IntakeFormPayment = ({upline, onComplete}) =>
{
	const today = moment().format('MMM DD, YYYY')

	const packages = [stripePrices.one_time,stripePrices.recurring];

	const renderPaymentForm = () =>
	{
		if (Store.subscriptionData === false || !Store.subscriptionData?.subscription_id)
			return <LoadingSpinner size='sm' isActive={true} />

		return (
        <PaymentMethodForm billingDetails={{
        	address: {
        		line1: 					UserProfileService.get('u_address1'),
        		city: 					UserProfileService.get('u_city'),
        		state: 					UserProfileService.get('u_state'),
        		postal_code: 		UserProfileService.get('u_zip')
        	},
		    	email: 	UserProfileService.get('u_email'),
		      name: 	[UserProfileService.get('u_fname'),UserProfileService.get('u_lname')].join(' '),
		      phone: 	UserProfileService.get('u_phone')
        }} userId={Store.userIp} onSuccess={onSuccess} />
			)
	}

	const onSuccess = async () =>
	{
		Store.hasPaid = true;
		Store.usertypeId = 91;
		Store.subscriptionId = Store.subscriptionData?.subscription_id;
		onComplete(true);
	}

	if (Store.subscriptionData === false) {
		const payload = {userId: (Store.userId||UserProfileService.getUserId()), usertypeId: (Store.usertypeId||91)};

		if ((payload?.userId && !isNaN(payload?.userId)) && (payload?.usertypeId && !isNaN(payload?.usertypeId))) {
			Store.subscriptionData = {};
	  	Store.initiateSubscription(payload);
		}
	}

  return (
    <MDBContainer fluid id='IntakeFormPaymentComponent' className='intake-form'>
			<MDBRow>
	      <MDBCol size="12" className="stage-heading">
	        <h3>
	          <strong>Billing Profile</strong>
	        </h3>
	      </MDBCol>
			</MDBRow>
    	<MDBRow>
    		<MDBCol size='12' md='6'>
          <div className="invoice">
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>USABG</strong>
                  </td>
                  <td colSpan='2'>{today}</td>
                </tr>
                <tr>
                  <td />
                  <td />
                  <td />
                </tr>
                {
                	packages&&packages.map(priceId => {
                		let prod = Object.keys(stripePrices)[Object.values(stripePrices).indexOf(priceId)];
                		return (
			                <tr key={`item-${priceId}`}>
			                  <td>{LINE_ITEMS[prod]?.label}</td>
			                  <td />
			                  <td>{convertToCurrency(parseFloat(LINE_ITEMS[prod]?.price||0))}</td>
			                </tr>
                		)
                	})
                }
                <tr>
                  <td />
                  <td />
                  <td />
                </tr>
                <tr>
                  <td>
                    <div
                      onClick={() => {
                      	Store.usertypeId = 111;
                      	onComplete('IntakeFormPackageSelection')
                      }}
                      className="revert-plan"
                    >
                      Back to Agent Plans
                    </div>
                  </td>
                  <td>Total:</td>
                  <td>
                  	{
		                	convertToCurrency(packages&&packages.map(priceId => LINE_ITEMS[Object.keys(stripePrices)[Object.values(stripePrices).indexOf(priceId)]]?.price||0).reduce((accum, curr) => parseFloat(accum) + parseFloat(curr), 0))
                  	}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
    		</MDBCol>
    		<MDBCol size='12' md='6'>
          <div className="payment-form">
          	{renderPaymentForm()}
          </div>
    		</MDBCol>
    	</MDBRow>
    </MDBContainer>
  )
}

export default observer(IntakeFormPayment)
