import React, { Component } from 'react'
import { observer } from 'mobx-react-lite'
import env from './../../../environments/environment'
import {
  MDBBtn,
  MDBIcon,
  MDBDataTable,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
} from 'mdbreact'
import { UIInputCheckbox } from './../../forms/form-fields'
import AdminApi from './../../../api/admin-api/admin-api'
import { toast } from 'react-toastify'
import UserProfileService from './../../../shared/services/UserProfile.service'
import UserTermService from './../../../shared/services/UserTerm.service'
import Store from './AgentTerms.store'

import moment from 'moment'

import './TermHistoryReport.scss'

const ENABLE_CSV_EXPORT = false,
  ENABLE_EXPORT_NON_SIGNED = false

const TermHistoryReport = ({ hideHeader }) => {
  const renderActions = (UserTerm) => {
    if (UserTerm.get('userterm_file')) {
      return (
        <a
          href={`https://firebasestorage.googleapis.com/v0/b/${
            env.integrations.firebase.config.storageBucket
          }/o/${encodeURIComponent(UserTerm.get('userterm_file'))}?alt=media`}
          target="_blank"
          rel="noopener noreferrer"
          className="view-btn"
        >
          <div>
            <MDBIcon icon="download" />
          </div>
          <div>View Signed</div>
        </a>
      )
    }

    let Term = Store.Terms.filter(
      (Term) => parseInt(Term.id()) === parseInt(UserTerm.get('term_id'))
    ).shift()
    if (Term) {
      return (
        <a
          href={Term.get('term_link')}
          target="_blank"
          rel="noopener noreferrer"
          className="view-btn"
        >
          <div>
            <MDBIcon icon="binoculars" />
          </div>
          <div>View Accepted</div>
        </a>
      )
    }
  }

  const renderToggleExport = (UserTerm) => {
    if (!UserTerm || !UserTerm.get('userterm_file')) {
      if (!ENABLE_EXPORT_NON_SIGNED || !UserTerm.get('term_id')) return <></>
    }

    return (
      <UIInputCheckbox
        label=" "
        id={`export_term_${UserTerm.id()}_toggle`}
        name={`export_term_${UserTerm.id()}_toggle`}
        checked={Store.willExport(UserTerm)}
        disabled={Store.isExporting}
        onChange={(evt) => {
          Store.toggleWillExport(UserTerm)
        }}
      />
    )
  }

  const renderHeader = () => {
    if (hideHeader) return <></>

    return <MDBCardHeader>Accepted User Term Documents</MDBCardHeader>
  }

  const renderBody = () => {
    if (Store.isLoading) {
      return (
        <MDBCardBody>
          <h2>Loading...</h2>
        </MDBCardBody>
      )
    }

    let isAdmin = UserProfileService.isA(
        ['agency-owner', 'internal-admin', 'system-admin'],
        true
      ),
      columns = [
        {
          label: 'Name',
          field: 'name',
          sort: 'asc',
        },
        {
          label: 'Date of acceptance',
          field: 'acceptance_date',
          sort: 'asc',
        },
        {
          label: 'Document',
          field: 'document',
          sort: 'asc',
        },
        {
          label: 'Actions',
          field: 'actions',
          sort: 'asc',
        },
      ].concat(
        isAdmin ? [{ label: 'Export', field: 'export', width: 100 }] : []
      ),
      rows = (Store.UserTerms || []).map((UserTerm) => {
        return {
          name: UserTerm.get('userterm_user'),
          acceptance_date: (
            <span
              searchvalue={
                UserTerm.get('userterm_updated')
                  ? moment(UserTerm.get('userterm_updated')).format(
                      'YYYY/MM/DD'
                    )
                  : ''
              }
            >
              {UserTerm.get('userterm_updated')
                ? moment(UserTerm.get('userterm_updated')).format('MM/DD/YYYY')
                : 'N/A'}
            </span>
          ),
          document: UserTerm.get('userterm_term'),
          actions: renderActions(UserTerm),
          ...(isAdmin ? { export: renderToggleExport(UserTerm) } : {}),
        }
      })

    return (
      <MDBCardBody>
        <MDBDataTable
          searching={false}
          sortRows={['acceptance_date']}
          paging={false}
          striped
          responsive
          bordered
          data={{ columns, rows }}
          entries={50}
          entriesOptions={[50, 100, 150]}
        />
        {ENABLE_CSV_EXPORT &&
        isAdmin &&
        Store.UserTerms &&
        Store.UserTerms.length ? (
          <div className="row justify-content-between">
            <div className="pl-3"></div>
            <div className="mr-2">
              <MDBBtn
                color="primary"
                onClick={() => Store.exportToCSV('agent_terms_report')}
              >
                {Store.isExporting ? 'Exporting...' : 'Export CSV'}
              </MDBBtn>
            </div>
          </div>
        ) : (
          <></>
        )}
        {isAdmin && Store.canExport() ? (
          <div className="row">
            <div className="col-sm-4 offset-sm-8">
              <MDBBtn
                block
                disabled={Store.isExporting}
                color="primary"
                onClick={() => Store.exportToPDF('agent_terms_report')}
                style={{ margin: 'auto' }}
              >
                {Store.isExporting ? 'Exporting...' : 'Export PDF Packet'}
              </MDBBtn>
              {Store.exportError ? (
                <div className="text--center">
                  <small className="text--red">
                    <strong>{Store.exportError}</strong>
                  </small>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </MDBCardBody>
    )
  }

  return (
    <MDBCard className="TermHistoryReportComponent">
      {renderHeader()}
      {renderBody()}
    </MDBCard>
  )
}

export default observer(TermHistoryReport)
