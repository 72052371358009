import React from 'react'
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBListGroup,
  MDBListGroupItem,
  MDBSpinner,
} from 'mdbreact'
import { Subscription } from 'rxjs'
import TrainingValidator from './../../../pages/TrainingPages/components/TrainingValidator/TrainingValidator.component'
import TrainingService from './../../../shared/services/Training.service'
import TrainingTypeService from './../../../shared/services/TrainingType.service'
import TrainingAttendedService from './../../../shared/services/TrainingAttended.service'
import UserProfileService from './../../../shared/services/UserProfile.service'

import TrainingsPageService from './../../../pages/TrainingPages/shared/TrainingsPage.service'
import TrainingQuiz from '../../../pages/TrainingPages/components/TrainingQuiz/TrainingQuiz.component'
import TrainingQuizService from '../../../shared/services/TrainingQuiz.service'
import TrainingQuestionService from '../../../shared/services/TrainingQuestion.service'
import TrainingAnswerService from '../../../shared/services/TrainingAnswer.service'
import UserTrainingQuizService from '../../../shared/services/UserTrainingQuiz.service'

import './AgentTrainingIntake.scss'

const isJson = (str) => {
  try {
    return typeof JSON.parse(str) === 'object'
  } catch (ex) {
    return false
  }
}

const trainingTypeId = 3

const sortTrainings = (a, b) =>
  a?.order > b?.order ? 1 : a?.order < b?.order ? -1 : 0

class AgentTrainingIntake extends React.Component {
  state = {
    isLoading: true,
    videoTitle: null,
    videoUrl: null,
    trainings: [],
    requires_quiz: null,
    training_quiz: null,
    training_questions: [],
    training_answers: [],
    training_quiz_loading: false,
    quizes: [],
    attended: [],
  }

  __subscriptions$ = new Subscription()

  _onValidityChange = (changed) => {
    if (typeof this.props?.onValidityChange === 'function')
      this.props.onValidityChange(changed)
  }

  fetchTrainings = () => {
    return new Promise((resolve, reject) => {
      TrainingService.search({
        pagination: false,
        search: { training_type_id: trainingTypeId },
        order_by: { training_order: 'ASC', training_name: 'ASC' },
      })
        .then((trainings) => resolve(trainings && trainings?.models))
        .catch((error) => {
          throw error
        })
    })
  }

  fetchTrainingTypes = () => {
    return new Promise((resolve, reject) => {
      TrainingTypeService.search({
        pagination: false,
        search: { id: trainingTypeId },
        order_by: { type_order: 'ASC', training_type_name: 'ASC' },
      })
        .then((types) => resolve(types && types?.models))
        .catch((error) => {
          throw error
        })
    })
  }

  fetchTrainingAttended = (userId) => {
    userId = userId ? userId : UserProfileService.getUserId()
    return new Promise((resolve, reject) => {
      TrainingAttendedService.search({
        pagination: false,
        search: { user_id: userId },
      })
        .then((attended) => resolve(attended && attended?.models))
        .catch((error) => {
          throw error
        })
    })
  }

  hasAttend = (training, quizes) =>
    this.state.attended
      .map((t) => parseInt(t.training_id))
      .indexOf(parseInt(training.id)) > -1 ||
    quizes?.findIndex(
      (quiz) => quiz?.training_id === training.id && quiz?.passed === 1
    ) > -1

  _fetchTrainings = async () => {
    let promises = [
      this.fetchTrainings(),
      this.fetchTrainingTypes(),
      this.fetchTrainingAttended(),
    ]

    try {
      promises = await Promise.all(promises)
    } catch (err) {
      console.error('Failed to load onboarding training resources. ', err)
      promises = false
    }

    let stateUpd = {
      training: 'Required Orientation',
      trainings: [],
      isLoading: false,
      isValid: false,
    }

    if (promises) {
      const [trainings, trainingTypes, attended] = promises,
        didAttend = (training) =>
          attended
            .map((t) => parseInt(t.training_id))
            .indexOf(parseInt(training.id)) > -1 ||
          this.state.quizes?.findIndex(
            (quiz) => quiz?.training_id === training.id && quiz?.passed === 1
          ) > -1
      let userMetaSpecializations = []
      try {
        const UserMetas = await (await UserProfileService.instance())
          .meta(true)
          .key('profile---specializations')
        if (UserMetas.length) {
          if (
            UserMetas[0].all().meta_value &&
            typeof UserMetas[0].all().meta_value === 'string'
          ) {
            let userMeta = JSON.parse(UserMetas[0].all().meta_value)
            if (userMeta.requested) {
              userMetaSpecializations = Object.keys(userMeta.requested).filter(
                (k) => userMeta.requested[k]
              )
            }
          }
        }
      } catch (err) {
        console.log(err)
      }

      stateUpd = {
        trainingTypes,
        training:
          (trainingTypes.length && trainingTypes[0]?.training_type_name) ||
          'Required Orientation',
        trainings: trainings
          .filter((training) => {
            let training_filters = null
            if (training?.training_filters) {
              if (typeof training.training_filters === 'object')
                training_filters = training.training_filters
              else if (
                typeof training.training_filters === 'string' &&
                isJson(training.training_filters)
              )
                training.training_filters = JSON.parse(
                  training.training_filters
                )
            }

            if (training_filters) {
              if (userMetaSpecializations.length && training_filters?.spec)
                return !!training_filters.spec.filter((spec) =>
                  userMetaSpecializations.includes(spec)
                ).length
            }

            // if no filters are defined, permit training, return true.
            // if filters ARE defined, this record failed criteria eval above, return false.
            return !training?.training_filters
          })
          .map((training) => ({ ...training, attended: didAttend(training) }))
          .sort(sortTrainings),
        isLoading: false,
        attended: attended,
      }

      stateUpd.isValid = !!(
        stateUpd.trainings.length ===
        trainings.filter((t) => didAttend(t)).length
      )
    }

    this.setState(stateUpd, () => this._onValidityChange(this.state.isValid))
  }

  _viewVideo = async (video) => {
    if (video) {
      this.setState({
        videoTitle: video.training_name,
        videoUrl: (
          <>
            <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
              <iframe
                src={video.training_link.replace(/(<([^>]+)>)/gi, '')}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                title={video.training_name}
              ></iframe>
            </div>
          </>
        ),
        requires_quiz: video.requires_quiz,
      })

      if (video.requires_quiz) {
        this.setState({ training_quiz_loading: true })

        const training_quiz = (
          await TrainingQuizService.search({
            search: { training_id: video.id },
          })
        )?.models[0]
        const training_questions = (
          await TrainingQuestionService.search({
            search: { quiz_id: training_quiz?.id },
          })
        )?.models
        const question_ids = training_questions.map((question) => question.id)
        const training_answers = (
          await TrainingAnswerService.search({
            search: {
              question_id: question_ids?.length > 0 ? question_ids : null,
            },
          })
        )?.models

        this.setState({
          training_quiz: training_quiz,
          training_questions: training_questions,
          training_answers: training_answers,
          training_quiz_loading: false,
        })
      }
    } else {
      this.setState({
        videoTitle: null,
        videoUrl: <></>,
      })
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      trainings: false,
      training: 'Required Orientation',
    }
  }

  fetchUserTrainingQuizes = async () => {
    const user_training_quizes = (
      await UserTrainingQuizService.search({ pagination: false })
    )?.models
    TrainingsPageService.UserTrainingQuizSubject.next(user_training_quizes)
  }

  componentDidMount() {
    this.__subscriptions$.add(
      TrainingsPageService.getViewVideo().subscribe((training) =>
        this._viewVideo(training)
      )
    )

    this.__subscriptions$.add(
      TrainingsPageService.getUserTrainingQuizes().subscribe((quizes) => {
        let _trainings = this.state.trainings || []
        if (_trainings.length !== 0) {
          this.setState({
            quizes: quizes,
            trainings: _trainings.map((training) => ({
              ...training,
              attended: this.hasAttend(training, quizes),
            })),
          })
        }
      })
    )

    this._fetchTrainings()
    this.fetchUserTrainingQuizes()
  }

  componentWillUnmount() {
    this.__subscriptions$.unsubscribe()
  }

  render() {
    return (
      <MDBContainer id="AgentTrainingIntake" fluid>
        <MDBRow className="onboarding-stage-label">
          <MDBCol size="12" sm="10" className="offset-sm-1">
            <h2>
              <span className="intro-step">{this.props.step}</span>
              {this.props.label}
            </h2>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12" lg="7">
            <div className="content-wrapper mb-5">
              <MDBCard>
                <MDBCardHeader color="indigo">
                  <div className="label">{this.state.training}</div>
                </MDBCardHeader>
                <MDBCardBody>
                  <MDBListGroup>
                    {this.state.isLoading ||
                    !Array.isArray(this.state.trainings) ? (
                      <span>
                        <i className="fa fa-spin fa-spinner"></i>
                      </span>
                    ) : (
                      this.state.trainings.map((item, i) => (
                        <MDBListGroupItem
                          className={item.attended ? 'has-attended' : ''}
                          key={'training-' + i}
                        >
                          <div
                            className="label"
                            onClick={() =>
                              TrainingsPageService.setViewVideo(item)
                            }
                          >
                            {item.training_name}
                          </div>
                          <div className="attended-tag">COMPLETED</div>
                        </MDBListGroupItem>
                      ))
                    )}
                  </MDBListGroup>
                </MDBCardBody>
              </MDBCard>
            </div>
          </MDBCol>
          <MDBCol size="12" lg="5">
            {Array.isArray(this.state.trainings) ? (
              <>
                <TrainingValidator
                  userId={UserProfileService.getUserId()}
                  trainings={this.state.trainings}
                  trainingTypes={this.state.trainingTypes}
                  onSuccess={() => this._fetchTrainings()}
                />
                <br />
              </>
            ) : (
              <></>
            )}
            {`${UserProfileService.get('has_onboarded')}` === '1' ? (
              <MDBBtn block color="indigo" href="/trainings">
                Go to the Complete Training Library
              </MDBBtn>
            ) : (
              <></>
            )}
          </MDBCol>
          <MDBCol size="12" className="mt-3"></MDBCol>
        </MDBRow>
        <MDBModal
          disableBackdrop={true}
          isOpen={!!this.state.videoTitle}
          toggle={() => TrainingsPageService.setViewVideo(null)}
          size="fluid"
        >
          <MDBModalHeader
            toggle={() => TrainingsPageService.setViewVideo(null)}
          >
            USABG Training Video
            <br />
            <span>{this.state.videoTitle}</span>
          </MDBModalHeader>
          <MDBModalBody>
            {this.state.requires_quiz ? (
              <MDBRow>
                <MDBCol size="12" lg="8">
                  {this.state.videoUrl}
                </MDBCol>
                <MDBCol size="12" lg="4" className="mt-4 mt-lg-0">
                  {this.state.training_quiz_loading ? (
                    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                      <MDBSpinner></MDBSpinner>
                    </div>
                  ) : (
                    <TrainingQuiz
                      training_quiz={this.state.training_quiz}
                      training_questions={this.state.training_questions}
                      training_answers={this.state.training_answers}
                      onSuccess={() => {
                        TrainingsPageService.setViewVideo(null)
                        this._fetchTrainings()
                      }}
                    />
                  )}
                </MDBCol>
              </MDBRow>
            ) : (
              <div>{this.state.videoUrl}</div>
            )}
          </MDBModalBody>
        </MDBModal>
      </MDBContainer>
    )
  }
}

export default AgentTrainingIntake
