import BaseModelInstance from './base.model'
import { CarrierContractLink as ModelDefinition } from './model-definitions'

class CarrierContractLink extends BaseModelInstance {
  afterSet = (key, value) => {}

  getMode = () => `${this.get('mode') ? this.get('mode') : ''}`.toLowerCase()

  getModeValue = () => {
    const modes = { link: 'link', acr: 'document_id', pdf: 'document_id' },
      mode = this.getMode()

    if (mode && modes[mode]) return this.get(modes[mode]) || ''
    return ''
  }

  isValid = (show) => {
    const { carrier_id, user_id, commission_id, link, document_id, mode } =
      this.all()
    if (
      carrier_id !== null &&
      !isNaN(carrier_id) &&
      user_id !== null &&
      !isNaN(user_id) &&
      commission_id !== null &&
      !isNaN(commission_id)
    )
      if (
        !!(mode === 'LINK' && link) ||
        !!(['PDF', 'ACR'].includes(mode) && document_id)
      )
        return true
    return false
  }

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }
}

export default CarrierContractLink
