const getQueryProperty = (prop, queryStr) =>
{
	var i, pair, vars;
	queryStr = queryStr ? queryStr : window.location.search;
  if (queryStr && typeof queryStr === 'string')
  	queryStr = queryStr.replace(/^[=|\?]+/g,''); // eslint-disable-line
  vars = queryStr.split('&');

  for (i = 0; i < vars.length; i++) {
    pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === prop)
      return decodeURIComponent(pair[1]);
  }
}

export default getQueryProperty