import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import ReferralsStore from './shared/Referrals.store'
import AdminLayout from './components/AdminLayout/AdminLayout.component'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import PageHeading from './../../components/shared/PageHeading.component'

import './AdminReferrals.page.scss'

class AdminReferralsPage extends Component {
  componentDidMount() {
    ReferralsStore.init()
  }

  render() {
    return (
      <Fragment>
        <DashboardLayout>
          <main id="AdminReferralsPage" className="mainSection pb-5">
            <MDBContainer fluid className="mt-4">
              <PageHeading
                label="Referral Partner Administration"
                url={this.props?.match?.url}
                from={this.props?.location?.state?.from}
                goTo={(url) =>
                  this.props.history.push(url, { from: '/admin-referrals' })
                }
              />
              <AdminLayout />
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AdminReferralsPage
