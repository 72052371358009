import { observer } from 'mobx-react-lite';
import React, {useState} from 'react';
import {MDBSelect, MDBSwitch, MDBBtn, MDBRow, MDBCol, MDBIcon, MDBCard, MDBCardHeader, MDBCardBody} from "mdbreact";
import CarrierMgmtStore from './../../CarrierMgmt.store';

import './CarrierPrioritiesForm.scss';

const specOpts = ['ALL','ACA','HEALTH','SENIOR','LIFE'].map(spec => ({label: spec, value: spec}));

const CarrierPrioritiesForm = ({table}) => 
{
	const [changes, setChanges] = useState(null);

	const renderLockOut = () =>
	{
		if (!CarrierMgmtStore.carrier || (CarrierMgmtStore.carrier && isNaN(CarrierMgmtStore.carrier?.id)))
			return <div className='lockout-window'>lockout</div>
	}

	const create = () => 
	{
		setChanges({})
		CarrierMgmtStore.priority = {carrier_id: CarrierMgmtStore.carrier?.id};
	} 

	const showAvailSpec = spec => 
	{
		if (spec.value === CarrierMgmtStore.priority?.spec)
			return true;

		if (!CarrierMgmtStore.priorities.length)		
			return true;

		return !!CarrierMgmtStore.priorities.filter(p => {
			return p.spec !== spec.value}).length;
	}

	const onChange = event => 
	{
		let name = `${event.target.name}`,
				value = `${event.target.value}`;

		if (value.trim().toLowerCase() === 'false')
			CarrierMgmtStore.priority[name] = false;
		else if (value.trim().toLowerCase() === 'true')
			CarrierMgmtStore.priority[name] = true;
		else 
			CarrierMgmtStore.priority[name] = value;
	}

	const onSubmit = async event => 
	{
		event.preventDefault();
		let result = CarrierMgmtStore.savePriority(changes);
		if (result && result.id && parseInt(result.id) !== parseInt(changes.id))
			setChanges(prevChanges => ({...prevChanges,...result}));
		return false;
	}

	const renderList = () => 
	{
		return <table className="priorities-table">
			        <thead>
			          <tr>
			            <th valign="middle">Specialization:</th>
			            <th valign="middle">Edit</th>
			            <th valign="middle">Delete</th>
			          </tr>
			        </thead>
			        <tbody>
			          {CarrierMgmtStore.priorities.map(cnt => (
			            <tr key={"priority-id-" + cnt.id}>
			              <td>{cnt.spec}</td>
			              <td>
			                <MDBBtn disabled={!cnt} onClick={() => CarrierMgmtStore.priority = cnt}>
			                  <MDBIcon icon="edit" />
			                </MDBBtn>
			              </td>
			              <td>
			                <MDBBtn disabled={!cnt} onClick={() => CarrierMgmtStore.deletePriority(cnt.id)}>
			                  <MDBIcon icon="trash" className="red-text" />
			                </MDBBtn>
			              </td>
			            </tr>
			          ))}
			        </tbody>
			      </table>
	}

	const renderForm = () => 
	{
		return	<form noValidate onSubmit={onSubmit}>
							<MDBRow>
		        		<MDBCol size='12'>
                  <MDBSelect label="Specialization"
                             options={specOpts.filter(showAvailSpec).map(opt => ({...opt,checked:opt.value === CarrierMgmtStore.priority?.spec}))}
                             name="spec"
                             value={CarrierMgmtStore.priority?.spec || ''}
                             getValue={v => {
                             		v = Array.isArray(v) ? v.shift() : v
                             		if (!v) return;
                             		onChange({target: {name: 'spec', value: v}});
                             }}
                             required />
		        		</MDBCol>
		        		<MDBCol size='12'>
		        			<table className="priority-form-table" width='100%'>
		        				<thead>
		        					<tr>
		        						<th>Agent Usertype</th>
		        						<th>Core/Required</th>
		        						<th>Preferred</th>
		        					</tr>
		        				</thead>
		        				<tbody>
		        					<tr>
		        						<td>Affiliate</td>
		        						<td>
					                  <MDBSwitch labelLeft={''}
					                             labelRight={''}
					                             checked={!!CarrierMgmtStore.priority?.is_affiliate_core}
					                             getValue={r => onChange({target:{name:'is_affiliate_core',value:r}})} />
		        						</td>
		        						<td>
					                  <MDBSwitch labelLeft={''}
					                             labelRight={''}
					                             checked={!!CarrierMgmtStore.priority?.is_affiliate_preferred}
					                             getValue={r => onChange({target:{name:'is_affiliate_preferred',value:r}})} />
		        						</td>
		        					</tr>
		        					<tr>
		        						<td>Associate</td>
		        						<td>
					                  <MDBSwitch labelLeft={''}
					                             labelRight={''}
					                             checked={!!CarrierMgmtStore.priority?.is_associate_core}
					                             getValue={r => onChange({target:{name:'is_associate_core',value:r}})} />
		        						</td>
		        						<td>
					                  <MDBSwitch labelLeft={''}
					                             labelRight={''}
					                             checked={!!CarrierMgmtStore.priority?.is_associate_preferred}
					                             getValue={r => onChange({target:{name:'is_associate_preferred',value:r}})} />
		        						</td>
		        					</tr>
		        					<tr>
		        						<td>Signature</td>
		        						<td>
					                  <MDBSwitch labelLeft={''}
					                             labelRight={''}
					                             checked={!!CarrierMgmtStore.priority?.is_signature_core}
					                             getValue={r => onChange({target:{name:'is_signature_core',value:r}})} />
		        						</td>
		        						<td>
					                  <MDBSwitch labelLeft={''}
					                             labelRight={''}
					                             checked={!!CarrierMgmtStore.priority?.is_signature_preferred}
					                             getValue={r => onChange({target:{name:'is_signature_preferred',value:r}})} />
		        						</td>
		        					</tr>
		        				</tbody>
		        			</table>
		        		</MDBCol>
							</MDBRow>
		          <MDBRow>
		            <MDBCol size='12' sm='7'>
		              <MDBBtn className="btn-block" type="submit">
		                {CarrierMgmtStore.getIsLoading('update.priority') ? (
		                  <span>
		                    Saving...&nbsp;
		                    <i className="fa fa-spin fa-spinner" />
		                  </span>
		                ) : "Save Carrier Priority"}
		              </MDBBtn>
		            </MDBCol>
		            <MDBCol size='12' sm='5'>
		              <MDBBtn className="btn-block" onClick={() => CarrierMgmtStore.priority = null}>
		                Done
		              </MDBBtn>
		            </MDBCol>
		          </MDBRow>
						</form>
	}

	return (
		<MDBCard id="CarrierPrioritiesFormComponent">
			{renderLockOut()}
			<MDBCardHeader>
				Carrier Priorities&nbsp;
				{CarrierMgmtStore.getIsLoading('fetch.priorities') && <span><i className="fa fa-spin fa-spinner" /></span>}
				<MDBBtn className='card-header-btn' disabled={!!(CarrierMgmtStore.priority || !CarrierMgmtStore.carrier?.id)} onClick={create}><MDBIcon icon="plus" /></MDBBtn>
			</MDBCardHeader>
			<MDBCardBody>
				{
					CarrierMgmtStore.priority
						? renderForm() 
				    : renderList() 
				}
			</MDBCardBody>
		</MDBCard>
	)
}

export default observer(CarrierPrioritiesForm);