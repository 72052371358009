import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import DatePicker from 'react-datepicker'
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import UserProfileService from '../../../shared/services/UserProfile.service'
import UserCertificationFactory from '../../../shared/factories/user-certification.factory'
import moment from 'moment'

const findCert = async (userId, certification) =>
  (
    await UserCertificationFactory.search({
      order_by: { id: 'DESC' },
      pagination: false,
      search: { user_id: userId, certification },
    })
  ).shift()

const createCert = (data) => UserCertificationFactory.create(data)

const findOrCreateCert = async (userId, certification) => {
  const NewAgentCert = createCert({ user_id: userId, certification }),
    AgentCert = await findCert(userId, certification)
  return AgentCert ? AgentCert : NewAgentCert
}

const SpecUnlock = ({
  AgentCert,
  certification,
  msg,
  field,
  readOnly,
  onChange,
}) => {
  const [isActivating, setIsActivating] = useState(false),
    [isLoading, setIsLoading] = useState(false),
    [effectiveDate, setEffectiveDate] = useState(null),
    // [expirationDate, setExpirationDate] 	= 		useState(null), // uncomment/replace when form is updated to include expiration date.
    [expirationDate] = useState(null),
    [hasCertified, setHasCertified] = useState(
      AgentCert && AgentCert.hasCertified()
    ),
    getDate = (fld) => `${(AgentCert && AgentCert.get(fld)) || ''}`,
    dateSplit = (dateStr) => `${dateStr || ''}`.trim().split(/(T| )/)[0] || '',
    dateToHuman = (dateStr) =>
      dateStr && moment(`${dateStr}`.trim(), 'YYYY-MM-DD').format('MM/DD/YYYY'),
    effDate = dateSplit(getDate('effective_date'))
  // expDate = dateSplit(getDate('expiration_date'))

  const saveCertificationDate = async () => {
    if (
      !effectiveDate ||
      (effectiveDate && !/^\d{4}-\d{2}-\d{2}/.test(`${effectiveDate}`))
    )
      return false

    if (!AgentCert && readOnly !== true)
      AgentCert = await findOrCreateCert(
        UserProfileService.getUserId(),
        certification
      )

    if (AgentCert && readOnly !== true) {
      setIsLoading(true)

      if (dateSplit(getDate('effective_date')) !== dateSplit(effectiveDate))
        AgentCert.set('effective_date', dateSplit(effectiveDate))
      if (dateSplit(getDate('expiration_date')) !== dateSplit(expirationDate))
        AgentCert.set('expiration_date', dateSplit(expirationDate))

      await AgentCert.save()

      if (typeof onChange === 'function') onChange(AgentCert)

      setHasCertified(AgentCert && AgentCert.hasCertified())
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (effDate && dateSplit(effDate)) setEffectiveDate(effDate)
  }, [effDate])

  // useEffect(() => {
  //   if (expDate && dateSplit(expDate)) setExpirationDate(expDate)
  // }, [expDate])

  const renderUnlockState = () => {
    return (
      <MDBRow>
        <MDBCol size="12">
          <div
            className="close-form"
            onClick={() => {
              if (isActivating) setIsActivating(false)
            }}
          >
            {isActivating ? <MDBIcon icon="times" /> : <></>}
          </div>
          <table>
            <tbody>
              <tr>
                <td colSpan="2" valign="middle">
                  {field}
                </td>
              </tr>
              <tr>
                <td>
                  <DatePicker
                    disabled={readOnly === true}
                    readOnly={readOnly === true}
                    isClearable={true}
                    selected={
                      effectiveDate &&
                      new RegExp(/^\d{4}-\d{2}-\d{2}/).test(`${effectiveDate}`)
                        ? moment(effectiveDate, 'YYYY-MM-DD').toDate()
                        : null
                    }
                    value={effectiveDate ? dateToHuman(effectiveDate) : ''}
                    onChange={(e) => {
                      if (effectiveDate !== moment(e).format('YYYY-MM-DD'))
                        setEffectiveDate(moment(e).format('YYYY-MM-DD'))
                    }}
                    className="input"
                  />
                </td>
                <td>
                  {isLoading ? (
                    <div>
                      <i className="fa fa-spin fa-spinner"></i>&nbsp;Saving...
                    </div>
                  ) : (
                    <div onClick={() => saveCertificationDate()}>
                      <MDBIcon
                        title="Save Date to Unlock"
                        className="m-1 save"
                        size="lg"
                        icon="save"
                      />
                      &nbsp;Save
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </MDBCol>
      </MDBRow>
    )
  }

  const renderLockState = () => {
    return (
      <MDBRow>
        <MDBCol size="12">
          <table>
            <tbody>
              <tr>
                <td valign="middle">
                  <MDBIcon className="m-1 icon" size="2x" icon="lock" />
                </td>
                <td valign="middle">{msg}</td>
              </tr>
            </tbody>
          </table>
        </MDBCol>
      </MDBRow>
    )
  }

  return (
    <MDBContainer
      className={'SpecUnlockComponent ' + (hasCertified ? 'has-certified' : '')}
    >
      <div className="spec-lock-down">
        <MDBContainer
          fluid
          onClick={() => {
            readOnly !== true &&
              isActivating !== true &&
              !hasCertified &&
              setIsActivating(true)
          }}
        >
          {hasCertified || isActivating
            ? renderUnlockState()
            : renderLockState()}
          {readOnly ? (
            <small className="read-only fw--500">
              Certifications can not be changed while actively contracting.
            </small>
          ) : (
            ''
          )}
        </MDBContainer>
      </div>
    </MDBContainer>
  )
}

export default observer(SpecUnlock)
