import BaseModelFactory from './base.factory'
import AvService from './../services/Av.service'
import Av from './../models/av.model'

class AvFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: AvService, Model: Av }
    )
  static findById = async (id) =>
    await this._findBy({ id: id }, { Service: AvService, Model: Av })
  static findAllByGroupId = async (avGroupId) =>
    await this._findAllBy(
      { av_group_id: avGroupId },
      { Service: AvService, Model: Av }
    )
  static findAllByUserId = async (userId) =>
    await this._findAllBy(
      { user_id: userId },
      { Service: AvService, Model: Av }
    )
  static create = (payload) =>
    this._create(payload, { Service: AvService, Model: Av })
}

export default AvFactory
