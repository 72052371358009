import React from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import PageHeading from './../../components/shared/PageHeading.component'
import MilestoneList from './components/MilestoneList/MilestoneList.component'
import KOTHYearly from './components/KOTHYearly/KOTHYearly.component'
import KOTHWeekly from './components/KOTHWeekly/KOTHWeekly.component'
// import RTTR from './components/RTTR/RTTR.component'
import _ from 'lodash'

import './Milestones.page.scss'

class MilestonesPage extends React.Component {
  constructor(props) {
    super(props)
    this.resetHH = this.resetHH.bind(this)
  }
  componentDidMount() {
    if (window) {
      window.addEventListener('resize', this.resetHH)
    }
  }
  componentWillUnmount() {
    if (window) {
      window.removeEventListener('resize', this.resetHH)
    }
  }
  resetHH() {
    let headers = document.querySelectorAll('.MilestoneList .card-header')
    _.chunk(
      headers,
      window.innerWidth < 576 ? 1 : window.innerWidth < 768 ? 2 : 3
    ).map((hds) => {
      let max = _.max(
        hds
          .map((h) => {
            h.classList.add('h-auto')
            return h
          })
          .map((h) => h.offsetHeight)
      )
      hds
        .map((h) => {
          h.classList.remove('h-auto')
          return h
        })
        .map((h) => {
          h.style.height = max + 'px'
        })
    })
  }
  render() {
    return (
      <React.Fragment>
        <DashboardLayout>
          <main id="MilestonesPage" className="mainSection">
            <MDBContainer fluid className="mt-5">
              <PageHeading
                label="USABG Hall of Fame"
                url={this.props?.match?.url}
                from={this.props?.location?.state?.from}
              />
            </MDBContainer>
            <MDBContainer fluid className="mt-2">
              {/* <RTTR /> */}
              <KOTHWeekly source={'agent'} />
              <KOTHYearly source={'agent'} />
              <KOTHYearly source={'ba'} />
              {/*<KOTHWeekly source={'ba'} />*/}
              <br />
              <MDBRow>
                <MDBCol size="12" sm="6" md="4">
                  <MilestoneList
                    listType="bar_streak"
                    listTitle={'"Break the Bar" Streaks'}
                  />
                </MDBCol>
                <MDBCol size="12" sm="6" md="4">
                  <MilestoneList
                    listType="streak"
                    listTitle={'Weekly Sales Streaks'}
                  />
                </MDBCol>
                <MDBCol size="12" sm="6" md="4">
                  <MilestoneList
                    listType="anniversary"
                    listTitle={'Anniversaries'}
                  />
                </MDBCol>
                <MDBCol size="12" sm="6" md="4">
                  <MilestoneList
                    listType="first_bar"
                    listTitle={'1st to Break the Bar'}
                  />
                </MDBCol>
                <MDBCol size="12" sm="6" md="4">
                  <MilestoneList listType="birthday" listTitle={'Birthdays'} />
                </MDBCol>
                <MDBCol size="12" sm="6" md="4">
                  <MilestoneList
                    listType="first_sale"
                    listTitle={'Recent First Sales'}
                  />
                </MDBCol>
                <MDBCol size="12" sm="6" md="4">
                  <MilestoneList
                    listType="rick_ring_race"
                    listTitle={'Executive Race to the Royal Ring'}
                    subTitle="5MM Points (approx. $100MM AV)"
                  />
                </MDBCol>
                <MDBCol size="12" sm="6" md="4">
                  <MilestoneList
                    listType="leader_ring_race"
                    listTitle={'Division Leader’s Race to the Royal Ring'}
                    subTitle="2.5MM Points (approx. $50MM AV) since 01/01/2021"
                  />
                </MDBCol>
                <MDBCol size="12" sm="6" md="4">
                  <MilestoneList
                    listType="agent_ring_race"
                    listTitle={'Agent’s Race to the Royal Ring'}
                    subTitle="250,000 Points (approx. $5MM AV) since 01/01/2021"
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export default MilestonesPage
