import React from 'react'
import { observer } from 'mobx-react-lite'
import { MDBRow, MDBCol } from 'mdbreact'
import Store from './../../shared/Referrals.store'
import Search from './../Search/Search.component'
import List from './../List/List.component'
import EditForm from './../EditForm/EditForm.component'
import W9Manager from './../W9Manager/W9Manager.component'

import './AdminLayout.component.scss'

const AdminLayout = () => {
  return (
    <div
      id="AdminLayoutComponent"
      className={!Store.ReferralPartner ? 'show-list-details' : ''}
    >
      <MDBRow>
        <MDBCol className="list-column">
          <div className="content-wrapper">
            <Search />
            <List />
          </div>
        </MDBCol>
        <MDBCol className="form-column">
          <EditForm />
          <W9Manager />
        </MDBCol>
      </MDBRow>
    </div>
  )
}

export default observer(AdminLayout)
