import React, { Component, Fragment } from 'react'
import DashboardLayout from '../components/Admin/DashboardLayout'
import {
  MDBBtn,
  MDBContainer,
  MDBDataTable,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from 'mdbreact'
import { toast } from 'react-toastify'
import AdminApi from '../api/admin-api/admin-api'

class AdminCalendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      calendarsList: '',
      loading: '',
      error: '',
      modal: false,
      confirmModal: false,
      viewdata: '',
      data: {
        columns: [
          {
            label: 'Calendar Name',
            field: 'name',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Provider',
            field: 'provider',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Update',
            field: 'update',
            sort: 'asc',
            width: 270,
          },
          {
            label: 'Delete',
            field: 'delete',
            sort: 'asc',
            width: 270,
          },
        ],
        rows: [],
      },
    } //state end
  }

  componentDidMount() {
    this.fetchCalendarsData()
  }

  //convert date from sec to current date
  toDateTime = (secs) => {
    let t = new Date(1970, 0, 1) // Epoch
    t.setSeconds(secs)
    return t
  }

  //toggle modal
  toggle = () => {
    this.setState({ modal: !this.state.modal })
  }

  //calling delete confirm modal
  confirm_DeleteCalendar = (data) => {
    this.confirm_toggle(data)
  }

  //Toggle delete confirm modal
  confirm_toggle = (data = {}) => {
    this.setState({ confirmModal: !this.state.confirmModal, ...data })
  }

  //forming rows for data table from fetched data
  setRows = () => {
    const { calendarsList } = this.state
    if (calendarsList && calendarsList.length) {
      let rows = calendarsList.map((row) => {
        const { cal_name, cal_provider, id } = row
        const self = this

        function editCalendar() {
          self.props.history.push(`/add-calendar/${id}/edit`)
        }

        function deleteCalendar() {
          self.confirm_DeleteCalendar({ viewdata: row })
        }

        const editClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={editCalendar}
            >
              <MDBIcon icon="edit" size="2x" className="blue-text p-1" />
            </div>
          </div>
        )

        const deleteClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={deleteCalendar}
            >
              <MDBIcon icon="trash" size="2x" className="red-text p-1" />
            </div>
          </div>
        )

        return {
          name: cal_name,
          provider: cal_provider,
          update: editClick,
          delete: deleteClick,
        }
      })
      this.setState({
        data: { ...this.state.data, rows: rows },
      })
    }
  }

  //Fetch data
  fetchCalendarsData = async () => {
    this.setState({ loading: true })
    AdminApi.getCalendars()
      .then((result) => {
        this.setState({ calendarsList: result.data.data || [] })
      })
      .then(() => {
        this.setState({ loading: false })
        this.setRows()
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  //Delete data
  _DeleteCalendar = async (id) => {
    this.setState({ deleting: true })
    const data = { type: 'calendars', id: id }

    AdminApi.deleteCalendar(data)
      .then((result) => {
        this.setState({ deleting: false })
        this.confirm_toggle()
        toast.success('Calendar successfully deleted', {
          position: toast.POSITION.TOP_RIGHT,
        })
        this.fetchCalendarsData()
      })
      .catch((error) => {
        this.setState({ deleting: false })
        toast.error(`${error.message}`, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  render() {
    toast.configure()
    const { data, loading, confirmModal } = this.state

    const createNew = () => {
      this.props.history.push('/add-calendar')
    }

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              <h2>
                Calendars
                <div className="d-inline-block">
                  <MDBBtn
                    tag="a"
                    floating
                    gradient="blue"
                    title={'Add New Calendar'}
                    onClick={createNew}
                  >
                    <MDBIcon icon="plus" />
                  </MDBBtn>
                </div>
              </h2>
              <hr />
              {loading ? (
                <h2>Loading...</h2>
              ) : (
                <div className="content-wrapper">
                  <MDBDataTable
                    striped
                    bordered
                    small
                    data={data}
                    className="text-capitalize"
                  />
                </div>
              )}

              <MDBModal
                size={'sm'}
                isOpen={confirmModal}
                toggle={this.confirm_toggle}
              >
                <MDBModalHeader toggle={this.confirm_toggle}></MDBModalHeader>
                <MDBModalBody>
                  <div className="h5">
                    Are you sure you want to delete this Calendar?
                  </div>
                  <MDBBtn
                    color="secondary"
                    size={'sm'}
                    onClick={this.confirm_toggle}
                  >
                    Close
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    size={'sm'}
                    onClick={() => {
                      this._DeleteCalendar(this.state.viewdata.id)
                    }}
                  >
                    {this.state.deleting ? 'Deleting...' : 'Delete'}
                  </MDBBtn>
                </MDBModalBody>
              </MDBModal>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AdminCalendar
