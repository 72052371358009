import React, { useState } from 'react'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
import ToggleAutoAwards from './../ToggleAutoAwards/ToggleAutoAwards.component'
import ToggleCashWithdrawalByUsertype from '../ToggleCashWithdrawalByUsertype/ToggleCashWithdrawalByUsertype.component'
import { MDBCol, MDBCollapse, MDBRow } from 'mdbreact'
import LedgerTransactionRuleService from './../../../shared/services/LedgerTransactionRule.service'
import UserProfileService from './../../../shared/services/UserProfile.service'

import './GlobalLedgerControls.component.scss'

const ucwords = (str) =>
  str.replace(/^([a-z])|[\s_]+([a-z])/g, ($1) => $1.toUpperCase())

class GlobalLedgerControlsStore {
  isLoading = null
  hasLoaded = false
  preventAutoAwards = null

  constructor() {
    makeAutoObservable(this)
  }

  hasInit = () => {
    return this.hasLoaded || [true, false].includes(this.isLoading)
  }

  init = async () => {
    this.isLoading = true
    this.preventAutoAwards = await this.getAutoAwards()
    this.hasLoaded = true
    this.isLoading = false
  }

  getAutoAwards = async () => {
    return await LedgerTransactionRuleService.getAutoAwards()
  }

  toggleAutoAwards = async () => {
    if (this.isLoading) return

    this.isLoading = true

    if (this.preventAutoAwards) {
      await LedgerTransactionRuleService.enableAutoAwards()
    } else {
      await LedgerTransactionRuleService.removeAutoAwards()
    }

    this.preventAutoAwards = await this.getAutoAwards()
    this.isLoading = false
  }
}

const ControlStore = new GlobalLedgerControlsStore()

const GlobalLedgerControls = ({}) => {
  const isAdmin = UserProfileService.isA(
    [
      'divisional-leader-admin',
      'system-admin',
      'internal-admin',
      'internal-staff',
    ],
    true
  )

  if (!ControlStore.hasInit()) ControlStore.init()

  return (
    <div id="GlobalLedgerControlsComponent" className="container-fluid">
      <MDBCollapse isOpen={isAdmin}>
        <MDBRow className="ledger-settings">
          <MDBCol size='12'>
            <ToggleAutoAwards ControlStore={ControlStore} />
          </MDBCol>
          <MDBCol size='12'>
            <ToggleCashWithdrawalByUsertype userType="Divisions" />
          </MDBCol>
          <MDBCol size='12'>
            <ToggleCashWithdrawalByUsertype userType="Regions" />
          </MDBCol>
          <MDBCol size='12'>
            <ToggleCashWithdrawalByUsertype userType="Districts" />
          </MDBCol>
        </MDBRow>
      </MDBCollapse>
    </div>
  )
}

export default observer(GlobalLedgerControls)
