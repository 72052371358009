import AWARD_LEVELS from '../../constants/award-levels.constants'

export const createCummulative = (levelObj) => {
  if (!levelObj) return null
  if (levelObj.level === 1) return levelObj.credits
  return AWARD_LEVELS.filter((l) => l.level <= levelObj.level)
    .map((l) => l.credits)
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue
    })
}
