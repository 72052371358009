import RouteRegistry from './RouteRegistry'
import RouteMap from './route.map'
import { observable } from 'mobx'
import { RouteFactory } from './../../shared/router/Route.factory'
import UserProfileService from './../../shared/services/UserProfile.service'
import UsertypeBlockModalStore from '../../components/modals/UsertypeBlockModal/UsertypeBlockModal.store'

const RouteObserver = observable({ to: null, from: null })

const isUserAuthenticated = () =>
  parseInt(UserProfileService.getCurrentUserTypeId()) > 0

const isUserOnboarded = () =>
  `${UserProfileService.get('has_onboarded')}` === '1'

const isUserEnrolled = () =>
  `${UserProfileService.get('completed_enroll')}` === '1'

const isPermitted = (route) => {
  if (!route) return false

  let usertypeId = parseInt(UserProfileService.getCurrentUserTypeId())

  // 1. protect routes that require authenticated users.
  if ((!usertypeId || isNaN(usertypeId)) && route?.guards?.requiresAuth)
    return false

  // 2. protect from users that have not completed enrolling.
  if (usertypeId && !isUserEnrolled() && route?.guards?.requiresAuth)
    return false

  // 2. protect routes that require specific usertypes.
  if (Array.isArray(route?.guards?.reqUsertypeIds))
    if (route.guards.reqUsertypeIds.indexOf(usertypeId) < 0) return false

  // 3. protect routes that block specific usertypes.
  if (Array.isArray(route?.guards?.blockedUsertypeIds))
    if (route.guards.blockedUsertypeIds.indexOf(usertypeId) > -1) return false

  // 4. protect routes that require onboarding to be complete.
  if (route?.guards?.requiresOnboarding === true && !isUserOnboarded())
    return false

  return route
}

const isReadOnly = (RouteDef) => {
  if (RouteDef?.readonly && RouteDef.readonly?.usertypes) {
    if (Array.isArray(RouteDef.readonly.usertypes)) {
      if (RouteDef.readonly.usertypes.length)
        return UserProfileService.isA(RouteDef.readonly.usertypes)
      return true
    }
    if (typeof RouteDef.readonly.usertypes === 'function') {
      return RouteDef.readonly.usertypes({
        RouteDef,
        ProfileSrvc: UserProfileService,
      })
    }
  }

  return false
}

function getRouteDefFromRouteMap(pathname) {
  const matchPath = (r) => {
      return r.path.length > 1 && r.path === pathname
        ? true
        : r?.pattern && r.pattern.test(pathname)
    },
    dRoute = (
      (pathname && `${pathname}`.trim() === '/'
        ? [RouteMap.defaultNonAuthRoute]
        : RouteMap.routes.filter((r) => matchPath(r))) || []
    ).shift()

  // Incomplete.
  // RouteMap.errors.unauthorized (403)
  // RouteMap.errors.not_found (404)
  // RouteMap.errors.fatal (500)
  return isPermitted(dRoute) && dRoute
}

function RouteMatcher(pathname) {
  let RouteDef
  if (typeof pathname === 'string')
    RouteDef =
      pathname &&
      RouteRegistry.routes.filter((Route) => Route.matches(pathname)).shift()

  RouteDef = RouteFactory.create(
    RouteDef ? RouteDef : getRouteDefFromRouteMap(pathname)
  )

  if (RouteDef)
    UsertypeBlockModalStore.setIsOpen(isReadOnly(RouteDef), RouteDef.name)

  if (isUserAuthenticated() && !isUserEnrolled() && !RouteDef?.authorize())
    window.location.href = `/${UserProfileService.get('upline_login')}/enroll`

  if (!RouteDef?.authorize()) {
    RouteDef = RouteFactory.create(
      isUserAuthenticated()
        ? isUserOnboarded()
          ? RouteMap.defaultAuthRoute
          : RouteMap.defaultOnboardRoute
        : RouteMap.defaultNonAuthRoute
    )
  }

  return RouteDef
}

export { RouteObserver, RouteMatcher }
