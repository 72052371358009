import React, { Component, Fragment } from 'react';
import DashboardLayout from "./../../components/Admin/DashboardLayout";
import {MDBContainer, MDBDataTable, MDBIcon, MDBRow, MDBCol, MDBCard, MDBCardHeader, MDBCardBody} from "mdbreact";
import AdminApi from "./../../api/admin-api/admin-api";
import {toast} from "react-toastify";

import "./AdminDashboardNotificationEmails.scss";

class NotificationEmails extends Component {

	constructor (props)
	{
		super(props);
		this.state = {
			loading: true,
			tables: {},
			data: {
				columns: [
					{
						label: 'E-mail Type',
						field: 'type',
						sort: 'asc',
						width: 150
					},
					{
						label: 'Send To',
						field: 'name',
						sort: 'asc',
						width: 150
					},
					{
						label: 'Send From',
						field: 'sendfrom',
						sort: 'asc',
						width: 270
					},
					{
						label: 'Subject',
						field: 'subject',
						sort: 'asc',
						width: 270
					},
					{
						label: 'Update',
						field: 'update',
						sort: 'disabled',
						width: 270
					}
				],
				rows: [],
			}
		}
	}

	_tableColumns = [
			{
				label: 'E-mail Type',
				field: 'type',
				sort: 'asc',
				width: 150
			},
			{
				label: 'Send To',
				field: 'name',
				sort: 'asc',
				width: 150
			},
			{
				label: 'Send From',
				field: 'sendfrom',
				sort: 'asc',
				width: 270
			},
			{
				label: 'Subject',
				field: 'subject',
				sort: 'asc',
				width: 270
			},
			{
				label: 'Update',
				field: 'update',
				sort: 'disabled',
				width: 270
			}
		];

	_fetchNotificationEmailsData = async () => 
	{
		let emails = [];

		try {
			emails = await AdminApi.getNotificationEmails();
		}
		catch (ex) {
			console.error("ERROR: Failed to fetch email list.",ex);
			toast.error("Failed to load email list.", {position: toast.POSITION.TOP_RIGHT});
		}

		if (emails && Array.isArray(emails?.data?.data))
			this._setTableRows(emails.data.data);
	}

	_setTableRows = emails => 
	{
		let tables = {};
		if (emails && emails.length) {
			emails.sort((emlA,emlB) => `${emlA?.email_group}`.localeCompare(emlB?.email_group, 'en')).forEach(eml => {
				eml.email_group = eml.email_group === 'null' || eml.email_group === null ? 'miscellaneous' : eml.email_group;
				if (!tables.hasOwnProperty(eml.email_group))
					tables[eml.email_group] = [];

				const {email_from, email_name, email_subject, emailtype_name, id} = eml;

				const editClick = (
					<div className={'d-flex align-items-center justify-content-center'}>
						<div className={'cursor-pointer d-inline-block'} role={'button'} onClick={() => this.props.history.push(`/${id}/edit-email`)}>
							<MDBIcon icon="edit" size="2x" className="blue-text p-1" />
						</div>
					</div>
				);

				tables[eml.email_group].push({
					type: emailtype_name,
					name: email_name,
					sendfrom: email_from,
					subject: email_subject,
					update: editClick,
				})
			});
		}

		this.setState({tables, loading: false});
	}

	_renderTables = () => 
	{
		return Object.keys(this.state.tables).map((emlGroup,i) => {

			if (!this.state.tables[emlGroup] || !this.state.tables[emlGroup].length)
				return <></>

				return 	<MDBRow key={`${emlGroup}-${i}`}>
									<MDBCol size="12">
										<MDBCard>
											<MDBCardHeader>
												{emlGroup.replace(/-/g,' ').toLowerCase().replace(/\b[a-z]/g, $1 => $1.toUpperCase())} ({this.state.tables[emlGroup].length})
											</MDBCardHeader>
											<MDBCardBody style={{padding: '0'}}>
												<MDBDataTable noBottomColumns={true} paging={false} searching={false} striped bordered small data={{columns: this._tableColumns, rows: this.state.tables[emlGroup]}} className="text-capitalize" />
											</MDBCardBody>
										</MDBCard>
									</MDBCol>
								</MDBRow>
		});
	}

	componentDidMount ()
	{
		this._fetchNotificationEmailsData();
	}

	render () 
	{
		toast.configure();

		return (
			<Fragment>
				<DashboardLayout>
					<main id="AdminDashboardNotificationEmails" className="mainSection pb-5">
						<MDBContainer fluid className="mt-5">
							<h2>Notification Emails</h2>
							<hr />
							{this.state.loading 
									? <h4 className={'p4'}>Loading...</h4> 
									: this._renderTables()
							}
						</MDBContainer>
					</main>
				</DashboardLayout>
			</Fragment>
		);
	}
}

export default NotificationEmails;
