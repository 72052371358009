import BaseModelFactory from './base.factory'
import SubsiteLinkService from './../services/SubsiteLink.service'
import SubsiteLink from './../models/subsite-link.model'

class SubsiteLinkFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: SubsiteLinkService, Model: SubsiteLink }
    )
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: SubsiteLinkService, Model: SubsiteLink }
    )
  static findAllBySiteId = async (id) =>
    await this._findAllBy(
      { site_id: id },
      { Service: SubsiteLinkService, Model: SubsiteLink }
    )
  static create = (payload) =>
    this._create(payload, { Service: SubsiteLinkService, Model: SubsiteLink })
}

export default SubsiteLinkFactory
