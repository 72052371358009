import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import React, { useState } from 'react'
import {
  MDBSelect,
  MDBBtn,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
} from 'mdbreact'
import { toast } from 'react-toastify'
import CarrierMgmtStore from './../../CarrierMgmt.store'
import { formatUrl } from './../../../../shared/formatters/url.formatter'
import AdobeDocListModal from './../AdobeDocListModal/AdobeDocListModal.component'

import './CarrierContractsForm.scss'

const CONTRACT_TYPES = ['ACR', 'PDF', 'LINK']

const CarrierContractsForm = ({ table }) => {
  const [showModal, setShowModal] = useState(false)
  const [load, setLoad] = useState(false)

  const renderLockOut = () => {
    if (
      !CarrierMgmtStore.carrier ||
      (CarrierMgmtStore.carrier && isNaN(CarrierMgmtStore.carrier?.id))
    )
      return <div className="lockout-window">lockout</div>
    if (load === false) setLoad(true)
  }

  const onChange = (event) => {
    let change = { [event.target.name]: event.target.value }
    CarrierMgmtStore.contract = {
      ...toJS(CarrierMgmtStore.contract),
      ...change,
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault()

    const isUrl = (str) => !!formatUrl(str),
      fields = [
        'default_id',
        'code_usabg505_id',
        'code_usabg405_id',
        'code_usabg305_id',
        'code_usabg205_id',
        'code_usabg30_id',
        'code_usabg20_id',
        'code_usabg10_id',
      ]

    // make sure the contract values
    // match the contract type.
    let err
    switch (CarrierMgmtStore.contract.contract_type) {
      // all fields must be ID string values.
      case 'PDF':
      case 'ACR':
        if (
          fields.filter(
            (fld) =>
              CarrierMgmtStore.contract[fld] &&
              !/CBJCHB[A-Za-z_\-0-9]{37,39}/.test(
                CarrierMgmtStore.contract[fld]
              )
          ).length > 0
        )
          err = `All carrier contract ${getContractTypeFieldLabel(
            ''
          )}s must be valid template ID values.`
        break

      // all fields must be URL values, not abstract strings.
      case 'LINK':
        if (
          fields.filter(
            (fld) =>
              CarrierMgmtStore.contract[fld] &&
              !isUrl(CarrierMgmtStore.contract[fld])
          ).length > 0
        )
          err = `All carrier contract ${getContractTypeFieldLabel(
            ''
          )}s must be valid URLs.`
        break

      // Return unknown error.
      default:
        err = 'Unknown save error.'
        break
    }

    err =
      !err &&
      fields.filter(
        (fld) =>
          CarrierMgmtStore.contract[fld] &&
          `${CarrierMgmtStore.contract[fld]}`.trim().length > 0
      ).length === 0
        ? `Please complete at least one carrier contract ${getContractTypeFieldLabel(
            ''
          )} field.`
        : err

    if (err) return toast.error(err, { position: toast.POSITION.TOP_RIGHT })

    CarrierMgmtStore.saveContract()
  }

  const getContractTypeFieldLabel = (label) =>
    CarrierMgmtStore.contract?.contract_type
      ? `${label} ${
          ['PDF', 'ACR'].indexOf(CarrierMgmtStore.contract.contract_type) > -1
            ? 'Document ID'
            : 'Link'
        }`
      : ''

  return (
    <MDBCard id="CarrierContractsFormComponent">
      {renderLockOut()}
      <MDBCardHeader>
        Contract Linking
        <MDBBtn className="card-header-btn" onClick={() => setShowModal(true)}>
          <MDBIcon icon="list" />
        </MDBBtn>
      </MDBCardHeader>
      <MDBCardBody>
        <form noValidate onSubmit={onSubmit}>
          <MDBRow>
            <MDBCol size="12">
              <MDBSelect
                options={CONTRACT_TYPES.map((i) => ({
                  text: `${i}`,
                  value: `${i}`,
                  checked:
                    `${i}` === `${CarrierMgmtStore.contract?.contract_type}`,
                }))}
                label="Carrier Contract Type"
                name="contract_type"
                value={
                  CarrierMgmtStore.contract?.contract_type ||
                  'Choose Contract Type'
                }
                selected={
                  CarrierMgmtStore.contract?.contract_type ||
                  'Choose Contract Type'
                }
                getValue={(evt) => {
                  if (
                    evt[0] &&
                    evt[0] !== CarrierMgmtStore.contract.contract_type
                  )
                    onChange({
                      target: { name: 'contract_type', value: evt[0] },
                    })
                }}
              />
            </MDBCol>
          </MDBRow>
          {CarrierMgmtStore.contract?.contract_type ? (
            <MDBRow className="contract-inputs">
              <MDBCol size="12">
                <MDBInput
                  label={getContractTypeFieldLabel('Default Contracting')}
                  type="text"
                  name="default_id"
                  value={CarrierMgmtStore.contract?.default_id || ''}
                  onChange={onChange}
                  autoComplete="off"
                />
              </MDBCol>
              <MDBCol size="12">
                <MDBInput
                  label={getContractTypeFieldLabel('Division Leader')}
                  type="text"
                  name="code_usabg505_id"
                  value={CarrierMgmtStore.contract?.code_usabg505_id || ''}
                  onChange={onChange}
                />
              </MDBCol>
              <MDBCol size="12">
                <MDBInput
                  label={getContractTypeFieldLabel(
                    'Regional Leader/Premier Agent'
                  )}
                  type="text"
                  name="code_usabg405_id"
                  value={CarrierMgmtStore.contract?.code_usabg405_id || ''}
                  onChange={onChange}
                />
              </MDBCol>
              <MDBCol size="12">
                <MDBInput
                  label={getContractTypeFieldLabel(
                    'District Manager/Career Agent'
                  )}
                  type="text"
                  name="code_usabg305_id"
                  value={CarrierMgmtStore.contract?.code_usabg305_id || ''}
                  onChange={onChange}
                />
              </MDBCol>
              <MDBCol size="12">
                <MDBInput
                  label={getContractTypeFieldLabel('Agent')}
                  type="text"
                  name="code_usabg205_id"
                  value={CarrierMgmtStore.contract?.code_usabg205_id || ''}
                  onChange={onChange}
                />
              </MDBCol>
              <MDBCol size="12">
                <MDBInput
                  label={getContractTypeFieldLabel('Senior Benefits Counselor')}
                  type="text"
                  name="code_usabg30_id"
                  value={CarrierMgmtStore.contract?.code_usabg30_id || ''}
                  onChange={onChange}
                />
              </MDBCol>
              <MDBCol size="12">
                <MDBInput
                  label={getContractTypeFieldLabel('Benefits Counselor')}
                  type="text"
                  name="code_usabg20_id"
                  value={CarrierMgmtStore.contract?.code_usabg20_id || ''}
                  onChange={onChange}
                />
              </MDBCol>
              <MDBCol size="12">
                <MDBInput
                  label={getContractTypeFieldLabel('Benefits Advisor')}
                  type="text"
                  name="code_usabg10_id"
                  value={CarrierMgmtStore.contract?.code_usabg10_id || ''}
                  onChange={onChange}
                />
              </MDBCol>
            </MDBRow>
          ) : (
            <></>
          )}
          <MDBRow>
            <MDBCol size="12">
              <MDBBtn
                className="btn-block"
                type="submit"
                disabled={!CarrierMgmtStore.contract?.contract_type}
              >
                {CarrierMgmtStore.getIsLoading('update.contract') ? (
                  <span>
                    Saving...&nbsp;
                    <i className="fa fa-spin fa-spinner" />
                  </span>
                ) : (
                  'Link Contract Documents'
                )}
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </form>
      </MDBCardBody>
      <AdobeDocListModal
        isOpen={showModal}
        onClose={() => {
          return showModal && setShowModal(false)
        }}
      />
    </MDBCard>
  )
}

export default observer(CarrierContractsForm)
