import CarrierPrioritiesPage from './CarrierPriorities.page'

import { RouteFactory } from './../../shared/router/Route.factory'
import {
  RequiresAuthGuard,
  RequiresUsertypesGuard,
} from './../../shared/router/guards'

export default [
  RouteFactory.create(
    CarrierPrioritiesPage,
    '/carrier-priorities',
    [
      new RequiresAuthGuard(true),
      new RequiresUsertypesGuard([
        'system-admin',
        'agency-owner',
        'recruiter-group',
        'internal-admin',
        'division-admin',
        'region-admin',
        'district-admin',
      ]),
    ],
    {
      name: 'Carrier Priorities',
    }
  ),
]
