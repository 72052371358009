import React, { Component } from 'react'
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact'
import UserProfileService from '../../../../shared/services/UserProfile.service'
import UserTrainingQuizService from '../../../../shared/services/UserTrainingQuiz.service'
import TrainingsPageService from '../../shared/TrainingsPage.service'
import TrainingQuestion from '../TrainingQuestion/TrainingQuestion.component'
import { toast } from 'react-toastify'

import './TrainingQuiz.scss'

class TrainingQuiz extends Component {
  state = {
    training_quiz: null,
    training_questions: [],
    training_answers: [],
    valid_answers: [],
    agent_answer: null,
    loading: false,
    submitted: false,
  }

  componentDidMount() {
    let answers = this.props.training_answers
    answers.forEach((item) => {
      item.checked = false
    })

    let questions = this.props.training_questions
    const quizs = questions.map((question) => {
      return {
        question: question.id,
        answer: null,
        correct_answer: answers.find(
          (answer) => answer.question_id === question.id && answer.is_correct
        )?.id,
      }
    })

    this.setState({
      training_quiz: this.props.training_quiz,
      training_questions: this.props.training_questions,
      training_answers: answers,
      agent_answer: quizs,
    })
  }

  onClickAnswer = (answer) => {
    let answers = this.state.training_answers
    const updated_answers = answers.map((item) => {
      if (item.id === answer.id) item.checked = true
      else {
        if (item.question_id === answer.question_id) {
          item.checked = false
        }
      }

      return item
    })

    let agent_answer_data = this.state.agent_answer
    agent_answer_data = agent_answer_data.map((data) => {
      if (data.question === answer.question_id) data.answer = answer.id
      return data
    })

    this.setState({
      training_answers: updated_answers,
      agent_answer: agent_answer_data,
    })
  }

  submit = async () => {
    this.setState({ loading: true, submitted: true })

    const agent_answers = this.state.agent_answer

    if (
      agent_answers?.filter(
        (answer) => answer?.answer === answer?.correct_answer
      )?.length === agent_answers.length
    ) {
      try {
        const user_training_quiz = (
          await UserTrainingQuizService.search({
            search: {
              quiz_id: this.state.training_quiz.id,
              training_id: this.state.training_quiz.training_id,
              user_id: UserProfileService.getUserId(),
            },
            pagination: false,
          })
        )?.models

        if (user_training_quiz.length > 0) {
          await UserTrainingQuizService.update(user_training_quiz[0].id, {
            passed: 1,
          })
        } else {
          await UserTrainingQuizService.store({
            quiz_id: this.state.training_quiz.id,
            training_id: this.state.training_quiz.training_id,
            user_id: UserProfileService.getUserId(),
            answers: JSON.stringify(
              agent_answers.map((answer) => {
                return { question: answer.question, answer: answer.answer }
              })
            ),
            passed: 1,
          })
        }

        this.setState({ loading: false, submitted: true })
        toast.success('Your answers are correct!', {
          position: toast.POSITION.TOP_RIGHT,
        })

        const user_training_quizes = (
          await UserTrainingQuizService.search({ pagination: false })
        )?.models
        TrainingsPageService.UserTrainingQuizSubject.next(user_training_quizes)

        // close
        if (typeof this.props?.onSuccess === 'function') this.props.onSuccess()
      } catch (error) {
        this.setState({ loading: false, submitted: true })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    } else {
      try {
        const valid_answers = agent_answers?.filter(
            (answer) => answer?.answer === answer?.correct_answer
          ),
          correct_answer_numbers = valid_answers?.length

        this.setState({ loading: false, submitted: true, valid_answers })
        toast.error(
          `${correct_answer_numbers}/${agent_answers.length} correct!`,
          { position: toast.POSITION.TOP_RIGHT }
        )
      } catch (error) {
        this.setState({ loading: false, submitted: true })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    }
  }

  render() {
    return (
      <div className="training-quiz-field">
        <MDBRow>
          <MDBCol size="12">
            <h5 className="text-center">Training Quiz</h5>
          </MDBCol>
          <MDBCol size="12" className="mt-3">
            {this.state.training_questions?.map((question, index) => {
              return (
                <div
                  key={`training-quiz-${question.id}-${index}`}
                  className="mb-4"
                >
                  <TrainingQuestion
                    question={question}
                    answers={this.state.training_answers?.filter(
                      (answer) => answer.question_id === question.id
                    )}
                    onClickAnswer={this.onClickAnswer}
                    submitted={this.state.submitted}
                    validAnswers={this.state.valid_answers}
                  ></TrainingQuestion>
                </div>
              )
            })}
          </MDBCol>
          <MDBCol size="12">
            <MDBBtn
              color="unique"
              type="button"
              disabled={
                this.state.agent_answer?.filter((answer) => !answer?.answer)
                  .length > 0 || this.state.loading
                  ? true
                  : false
              }
              className="btn-block"
              onClick={(evt) => this.submit(evt)}
            >
              {this.state.loading ? 'Submitting...' : 'Submit'}
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </div>
    )
  }
}

export default TrainingQuiz
