import env from './../../environments/environment'

export default function getTheme(theme) {
  switch (theme) {
    case 'business1':
      return `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fsubsites%2Fimg.business-1.jpg?alt=media`
    case 'business2':
      return `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fsubsites%2Fimg.business-2.jpg?alt=media`
    case 'business3':
      return `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fsubsites%2Fimg.business-3.jpg?alt=media`
    case 'construction1':
      return `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fsubsites%2Fimg.construction-1.jpg?alt=media`
    case 'construction2':
      return `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fsubsites%2Fimg.construction-2.jpg?alt=media`
    case 'doctors':
      return `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fsubsites%2Fimg.doctors.jpg?alt=media`
    case 'farmer':
      return `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fsubsites%2Fimg.farmer.jpg?alt=media`
    case 'mechanic':
      return `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fsubsites%2Fimg.mechanic.jpg?alt=media`
    case 'nurses':
      return `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fsubsites%2Fimg.nurses.jpg?alt=media`
    case 'realEstate':
      return `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fsubsites%2Fimg.real-estate.jpg?alt=media`
    case 'seniors':
      return `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fsubsites%2Fimg.seniors.jpg?alt=media`
    case 'vet':
      return `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fsubsites%2Fimg.vet.jpg?alt=media`
    default:
      return `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fsubsites%2Fimg.premiere-default.jpg?alt=media`
  }
}
