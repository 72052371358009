import BaseModelFactory from './base.factory'
import DivisionService from './../services/Division.service'
import Division from './../models/division.model'

class DivisionFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: DivisionService, Model: Division }
    )
  static findById = async (id) =>
    await this._findBy({ id }, { Service: DivisionService, Model: Division })
  static create = (payload) =>
    this._create(payload, { Service: DivisionService, Model: Division })
}

export default DivisionFactory
