import { KnowledgebasePage, SupportPage } from './index'
import { AdminOverride } from './../../shared/router/overrides'
import { RouteFactory } from './../../shared/router/Route.factory'
import { RequiresAuthGuard } from './../../shared/router/guards'

export default [
  RouteFactory.create(KnowledgebasePage, '/kb-support', [
    new RequiresAuthGuard(true),
  ], {
    name: 'Knowledgebase'
  }),
  RouteFactory.create(SupportPage, '/help-support', [
    new RequiresAuthGuard(true),
  ], {
    name: 'Help & Support'
  }),
]
