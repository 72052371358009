import BaseModelFactory from './base.factory'
import UserGoalService from './../services/UserGoal.service'
import UserGoal from './../models/user-goal.model'

class UserGoalFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: UserGoalService, Model: UserGoal }
    )
  static findByUserId = async (userId) =>
    await this._findBy(
      { user_id: userId },
      { Service: UserGoalService, Model: UserGoal }
    )
  static create = (payload) =>
    this._create(payload, { Service: UserGoalService, Model: UserGoal })
}

export default UserGoalFactory
