import React from 'react'

export const youtubeURLRegex =
  /https:\/\/www.youtube.com\/(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()[]{};:'".,<>?«»“”‘’]))?/

export const renderVideo = (content, name) => {
  let url = content

  if (url && `${url}`.match(/(vimeo\.com)/gi))
    return (
      <>
        <div style={{ padding: '56% 0 0 0', position: 'relative' }}>
          <iframe
            src={url.replace(/(<([^>]+)>)/gi, '')}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
            }}
            title={name || 'USABG Video Player'}
          ></iframe>
        </div>
      </>
    )

  if (
    url &&
    `${url}`.match(/(youtube\.com|ytimg\.com|googlevideo\.com|youtu\.be)/gi)
  )
    return (
      <>
        <iframe
          width="100%"
          height="auto"
          src={url.match(youtubeURLRegex)}
          title={name || 'USABG Video Player'}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </>
    )

  return <></>
}
