import React from 'react'
import { observer } from 'mobx-react-lite'
import { MDBRow, MDBCol } from 'mdbreact'
import Store from './../store'
import Search from './../Search/Search.component'
import List from './../List/List.component'
import GlobalLedgerControls from './../GlobalLedgerControls/GlobalLedgerControls.component'
import AgentLedger from './../AgentLedger/AgentLedger.component'

import './AgentLedgerManager.component.scss'

const AgentLedgerManager = (props) => {
  const { layout, onOpen } = props

  const renderAgent = () => (
    <MDBRow>
      <MDBCol className="form-column">
        <AgentLedger {...props} />
      </MDBCol>
    </MDBRow>
  )

  const renderAdmin = () => (
    <MDBRow>
      <MDBCol className="list-column">
        <div className="content-wrapper">
          <Search />
          <List onSelect={onOpen} />
          <GlobalLedgerControls />
        </div>
      </MDBCol>
      <MDBCol className="form-column">
        <AgentLedger {...props} toast={props?.toast} />
      </MDBCol>
    </MDBRow>
  )

  if (layout) {
    return (
      <div
        id="AgentLedgerManagerComponent"
        className={[
          !Store.Ledger ? 'show-list-details' : '',
          `${layout || 'agent'}-layout`,
        ].join(' ')}
      >
        {layout === 'admin' ? renderAdmin() : renderAgent()}
      </div>
    )
  }

  return <></>
}

export default observer(AgentLedgerManager)
