import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { UIInput } from './../../components/forms/form-fields'
import {
  MDBContainer,
  MDBRow,
  MDBBtn,
  MDBCol,
  MDBCardBody,
  MDBCardHeader,
  MDBCard,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBListGroup,
  MDBListGroupItem,
  MDBIcon,
  MDBSpinner,
  MDBCollapse,
} from 'mdbreact'
import AdminApi from '../../api/admin-api/admin-api'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'

import './ResourceLibraryPage.scss'

class ResourceLibraryPage extends Component {
  _resourceLibrary = []

  state = {
    loading: true,
    resourceTypeList: [],
    resourceTypeId: null,
    resourceGroup: null,
    filteredTypeList: [],
    filteredTypeId: null,
    filteredLibrary: [],
    search: ''
  }

  /*
   * Component Accessor Methods ----------------------
   *
   */

  /*
   * Component Public Methods ------------------------
   *
   */

  /*
   * Component Private Methods -----------------------
   *
   */
  _fetchResourceTypes = () => {
    return new Promise((resolve, reject) => {
      AdminApi.getLibraryResourcesType()
        .then((result) =>
          resolve(
            result &&
              result.data &&
              result.data.data &&
              result.data.data.length > 0
              ? result.data.data
              : []
          )
        )
        .catch((error) => reject(error.message))
    })
  }

  _fetchResourcesByTypeIds = (ids) => {
    return new Promise((resolve, reject) => {
      AdminApi.getResourcesByResourceTypeIds(ids, 0)
        .then((result) =>
          resolve(
            result && result.data && result.data.data ? result.data.data : []
          )
        )
        .catch((error) => reject(error.message))
    })
  }

  _fetchLibrary = async () => 
  {
    this.setState({ loading: true })

    this._fetchResourceTypes()
      .then((resourceTypes) => {
        this._fetchResourcesByTypeIds(resourceTypes.map((rt) => rt.id)).then(
          (resources) => {

            let loadedResourceIds = resources
              .map((r) => r.resourcetype_id)
              .filter((val, idx, self) => self.indexOf(val) === idx)
            resourceTypes = resourceTypes.filter(
              (rType) => loadedResourceIds.indexOf(rType.id) > -1
            )

            this._resourceLibrary = resources

            this.setState({
              loading: false,
              resourceTypeList: resourceTypes,
              resourceTypeId: loadedResourceIds.shift(),
              filteredTypeList: resourceTypes,
              filteredTypeId: loadedResourceIds.shift(),
            })

            // Accessing query parameter 'id' from props.location.search
            const queryParams = new URLSearchParams(this.props.location.search);
            const id = queryParams.get('id');
            if(id) this.setState({resourceTypeId: parseInt(id)})
          }
        )
      })
      .catch((err) => {
        toast.error(err, { position: toast.POSITION.TOP_RIGHT })
        this.setState({ loading: false })
      })
  }

  _toggleResourceGroup = (grp) =>
    this.setState({
      resourceGroup: this.state.resourceGroup === grp ? null : grp,
    })

  _setActiveResourceTypeId = (resourceTypeId) => {
    if (this.state.resourceTypeId !== resourceTypeId) {
      this.setState({ resourceTypeId })
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  _renderResources = (resourceTypeList, resourceTypeId, groupedResourcesList, ungroupedResourcesList) =>
  { 
    let rTypeList = resourceTypeList;

    if (this.state.search) {
      let rTypeIds = this.state.filteredLibrary.map(r => `${r.resourcetype_id}`);
      rTypeList = rTypeList.filter(r => rTypeIds.includes(`${r.id}`));
    }

    return (
      <MDBRow>
        <MDBCol md="3" style={{ padding: 0 }}>
          <MDBNav pills color="primary" className="flex-column">
            {rTypeList && rTypeList.length ? (
              rTypeList.map((item, i) => {
                return (
                  <MDBNavItem key={`rt ${i}`}>
                    <MDBNavLink
                      link
                      to="#"
                      id={item.id}
                      active={item.id === resourceTypeId}
                      onClick={() =>
                        this._setActiveResourceTypeId(item.id)
                      }
                    >
                      {item.rt_name}
                    </MDBNavLink>
                  </MDBNavItem>
                )
              })
            ) : (
              <MDBNavItem>
                <MDBNavLink link to="#">
                  No resources available
                </MDBNavLink>
              </MDBNavItem>
            )}
          </MDBNav>
        </MDBCol>
        <MDBCol md="9">
          <MDBTabContent activeItem={resourceTypeId}>
            {rTypeList && rTypeList.length ? (
              rTypeList.map((rType) => {
                return (
                  <MDBTabPane
                    tabId={rType.id}
                    key={`r-${rType.id}`}
                  >
                    {ungroupedResourcesList.length ? (
                      <>
                        <MDBCard className="resource-group">
                          <MDBCardHeader>
                            {rType.rt_name}
                          </MDBCardHeader>
                          <MDBCardBody className="resource-group">
                            <MDBListGroup>
                              {ungroupedResourcesList.map(
                                (resource) => (
                                  <MDBListGroupItem
                                    key={`ungrp-rr-${resource.id}`}
                                  >
                                    <a
                                      href={resource.resource_link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {resource.resource_name}
                                    </a>
                                  </MDBListGroupItem>
                                )
                              )}
                            </MDBListGroup>
                          </MDBCardBody>
                        </MDBCard>
                      </>
                    ) : (
                      <></>
                    )}
                    {groupedResourcesList &&
                    Object.keys(groupedResourcesList).length ? (
                      Object.keys(groupedResourcesList).map(
                        (grp) => (
                          <React.Fragment key={'grp-' + grp}>
                            <MDBCard
                              className={
                                'resource-group ' +
                                (this.state.resourceGroup === grp
                                  ? 'open'
                                  : '')
                              }
                            >
                              <MDBCardHeader
                                className="toggleable"
                                onClick={() =>
                                  this._toggleResourceGroup(grp)
                                }
                              >
                                {rType.rt_name}:{' '}
                                {
                                  groupedResourcesList[grp][0]
                                    .resource_group
                                }
                                <span className="toggle-group">
                                  <MDBIcon icon="arrow-circle-up" />
                                </span>
                              </MDBCardHeader>
                              <MDBCardBody>
                                <MDBCollapse
                                  isOpen={
                                    this.state.resourceGroup === grp
                                  }
                                >
                                  <MDBListGroup>
                                    {groupedResourcesList[grp].map(
                                      (resource) => (
                                        <MDBListGroupItem
                                          key={`grp-rr-${resource.id}`}
                                        >
                                          <a
                                            href={
                                              resource.resource_link
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {resource.resource_name}
                                          </a>
                                        </MDBListGroupItem>
                                      )
                                    )}
                                  </MDBListGroup>
                                </MDBCollapse>
                              </MDBCardBody>
                            </MDBCard>
                          </React.Fragment>
                        )
                      )
                    ) : (
                      <></>
                    )}
                  </MDBTabPane>
                )
              })
            ) : (
              <MDBTabPane tabId="1">
                <MDBCardBody>
                  <h2>No Resources found</h2>
                </MDBCardBody>
              </MDBTabPane>
            )}
          </MDBTabContent>
        </MDBCol>
      </MDBRow>
      )
  }

  /*
   * Component Event Methods -------------------------
   *
   */

  componentDidMount() {
    this._fetchLibrary()
  }

  componentWillUnmount() {}

  renderSearchInput()
  {
    return (
      <UIInput
        type="text"
        label={"Enter search term"}
        value={this.state.search}
        onChange={(e) => {
            if ((e?.target?.value ? `${e.target.value}` : '') !== this.state.search) {
              e = (e?.target?.value ? `${e.target.value}` : '');
              let filteredResources = this._resourceLibrary.filter(r => ((r?.resource_name && `${r.resource_name}`.toLowerCase().includes(`${e}`.toLowerCase())) || (r?.resource_description && `${r.resource_description}`.toLowerCase().includes(`${e}`.toLowerCase()))));
              this.setState({search: e, filteredLibrary: filteredResources});
            }
          }
        }
        style={{ color: '#fff' }}
      />
    )
  }

  renderSearchButton()
  {
    return (
      <MDBBtn><MDBIcon icon="search" /></MDBBtn>
    )
  }

  render() {
    toast.configure()

    const { loading, resourceTypeList, resourceTypeId } = this.state,
      resourcesList = (this.state.search ? this.state.filteredLibrary : this._resourceLibrary).filter(
        (r) => parseInt(r.resourcetype_id) === parseInt(resourceTypeId)
      ),
      ungroupedResourcesList = resourcesList.filter((r) => !r.resource_group),
      groupedResourcesList = {}
    resourcesList
      .filter((r) => r.resource_group)
      .forEach((r) => {
        if (!groupedResourcesList.hasOwnProperty(r.resource_group))
          groupedResourcesList[r.resource_group] = []
        groupedResourcesList[r.resource_group].push(r)
      })
    Object.keys(groupedResourcesList).forEach(
      (group) =>
        (groupedResourcesList[group] = groupedResourcesList[group].sort(
          (a, b) =>
            parseInt(a.resource_order) > parseInt(b.resource_order)
              ? 1
              : parseInt(a.resource_order) < parseInt(b.resource_order)
              ? -1
              : 0
        ))
    )

    return (
      <Fragment>
        <DashboardLayout>
          <main id="ResourceLibraryPage" className="mainSection">
            <MDBContainer fluid className="mt-5">
              <h2><MDBRow><MDBCol size='12' sm='6'>Resources</MDBCol><MDBCol size='12' sm='6'>
                <div id="resource-library-search">
                  {this.renderSearchInput()}
                </div>
              </MDBCol></MDBRow></h2>
              <hr />
              {loading ? (
                <div className="loadingOverlay not-fixed">
                  <h2>
                    <MDBSpinner />
                    &nbsp;Loading...
                  </h2>
                </div>
              ) : this._renderResources(resourceTypeList, resourceTypeId, groupedResourcesList, ungroupedResourcesList)}
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default withRouter(ResourceLibraryPage)
