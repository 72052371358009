import React, { Component, Fragment } from 'react'
import DashboardLayout from '../components/Admin/DashboardLayout'
import {
  MDBBtn,
  MDBContainer,
  MDBDataTable,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from 'mdbreact'
import { toast } from 'react-toastify'
import AdminApi from '../api/admin-api/admin-api'

class AdminCommissions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      commissionList: '',
      loading: false,
      deleting: false,
      error: '',
      modal: false,
      confirmModal: false,
      viewdata: '',
      data: {
        columns: [
          {
            label: 'Name',
            field: 'name',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Code',
            field: 'code',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'View Schedule',
            field: 'schedule',
            sort: 'disabled',
            width: 150,
          },
          {
            label: 'Edit',
            field: 'edit',
            sort: 'disabled',
            width: 150,
          },
          {
            label: 'Delete',
            field: 'delete',
            sort: 'disabled',
            width: 150,
          },
        ],
        rows: [],
      },
    } // state
  }

  componentDidMount() {
    this.fetchCommissionData()
  }

  //convert date from sec to current date
  toDateTime = (secs) => {
    let t = new Date(1970, 0, 1) // Epoch
    t.setSeconds(secs)
    return t
  }

  //toggle modal
  toggle = () => {
    this.setState({ modal: !this.state.modal })
  }

  //calling delete confirm modal
  confirm_DeleteCommission = (data) => {
    this.confirm_toggle(data)
  }

  //Toggle delete confirm modal
  confirm_toggle = (data = {}) => {
    this.setState({ confirmModal: !this.state.confirmModal, ...data })
  }

  //forming rows for data table from fetched data
  setRows = () => {
    const { commissionList } = this.state
    if (commissionList && commissionList.length) {
      let rows = commissionList.map((row) => {
        const { com_name, com_code, com_url, id } = row
        const self = this

        function editCommission() {
          self.props.history.push(`/add-commission/${id}/edit`)
        }

        function deleteCommission() {
          self.confirm_DeleteCommission({ viewdata: row })
        }

        const scheduleClick = (
          /*<a href="">
                        <MDBIcon icon="binoculars" size="2x" className="d-flex justify-content-center blue-text" />
                    </a>*/
          <div className={'d-flex align-items-center justify-content-center'}>
            <a
              href={com_url}
              className={'cursor-pointer d-inline-block'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MDBIcon icon="binoculars" size="2x" className="blue-text p-1" />
            </a>
          </div>
        )

        const editClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={editCommission}
            >
              <MDBIcon icon="edit" size="2x" className="blue-text p-1" />
            </div>
          </div>
        )

        const deleteClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={deleteCommission}
            >
              <MDBIcon icon="trash" size="2x" className="red-text p-1" />
            </div>
          </div>
        )

        return {
          name: com_name,
          code: com_code,
          schedule: scheduleClick,
          edit: editClick,
          delete: deleteClick,
        }
      })
      this.setState({
        data: { ...this.state.data, rows: rows },
      })
    }
  }

  //Fetch data
  fetchCommissionData = async () => {
    this.setState({ loading: true })
    AdminApi.getCommissions()
      .then((result) => {
        this.setState({ commissionList: result.data.data || [] })
      })
      .then(() => {
        this.setState({ loading: false })
        this.setRows()
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  //Delete data
  _DeleteCommission = async (id) => {
    this.setState({ deleting: true })
    const data = { type: 'commission_levels', id: id }

    AdminApi.deleteCommission(data)
      .then((result) => {
        this.setState({ deleting: false })
        this.confirm_toggle()
        toast.success('Commission successfully deleted', {
          position: toast.POSITION.TOP_RIGHT,
        })
        this.fetchCommissionData()
      })
      .catch((error) => {
        this.setState({ deleting: false })
        toast.error(`${error.message}`, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  render() {
    toast.configure()
    const { data, confirmModal, loading } = this.state

    const createNew = () => {
      this.props.history.push('/add-commission')
    }

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              <h2>
                Commission Levels
                <div className="d-inline-block">
                  <MDBBtn tag="a" floating gradient="blue" onClick={createNew}>
                    <MDBIcon icon="plus" />
                  </MDBBtn>
                </div>
              </h2>
              <hr />
              {loading ? (
                <h4 className={'p4'}>Loading...</h4>
              ) : (
                <div className="content-wrapper">
                  <MDBDataTable
                    striped
                    bordered
                    small
                    data={data}
                    className="text-capitalize"
                  />
                </div>
              )}

              <MDBModal
                size={'sm'}
                isOpen={confirmModal}
                toggle={this.confirm_toggle}
              >
                <MDBModalHeader toggle={this.confirm_toggle}></MDBModalHeader>
                <MDBModalBody>
                  <div className="h5">
                    Are you sure you want to delete this Commission?
                  </div>
                  <MDBBtn
                    color="secondary"
                    size={'sm'}
                    onClick={this.confirm_toggle}
                  >
                    Close
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    size={'sm'}
                    onClick={() => {
                      this._DeleteCommission(this.state.viewdata.id)
                    }}
                  >
                    {this.state.deleting ? 'Deleting...' : 'Delete'}
                  </MDBBtn>
                </MDBModalBody>
              </MDBModal>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AdminCommissions
