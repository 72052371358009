import React from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'

import './AtYourService.component.scss'

const AtYourService = ({ subsiteData }) => {
  return (
    <MDBContainer id="AtYourServiceComponent" fluid>
      <MDBRow>
        <MDBCol size="12">
          <h3 className="font---lato fw--700">At Your Service</h3>
        </MDBCol>
        <MDBCol>
          <div className="font---lato fw--700 srvc-label">
            Info Before Buying
          </div>
          <p>
            Purchasing health insurance can seem like a daunting (and often
            times confusing) process. At USA Benefits Group, our Agents walk you
            through a step by step process of finding the best policy that is
            right for you and your family! Contact us or request a quote & a
            USABG Licensed Agent will provide you with one on one service!
          </p>
        </MDBCol>
        <MDBCol>
          <div className="font---lato fw--700 srvc-label">
            Value of an Agent
          </div>
          <p>
            USA Benefits Group agents have the knowledge and Independent
            Insurance Consultants to help you with your insurance needs. We are
            NOT a call center! Our agents are independent insurance consultants
            and they are licensed in the states where they conduct business.
          </p>
        </MDBCol>
        <MDBCol>
          <div className="font---lato fw--700 srvc-label">Product Info</div>
          <p>
            USA Benefits Group's producers have been providing affordable Health
            and Life insurance to individuals, the self-employed and their
            families since 1988. We also offer Medicare Supplements, Annuities,
            Long Term Care and many other benefits.
          </p>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default AtYourService
