import React from 'react'
import moment from 'moment'
import { MDBRow, MDBCol } from 'mdbreact'
import { convertToCurrency } from './../../shared/formatters/convertToCurrency.formatter'
import UpcomingExpirations from './UpcomingExpirations/UpcomingExpirations.component'
import AgentLedgerControls from './AgentLedgerControls/AgentLedgerControls.component'

export const LedgerTitle = (props) => (
  <div className="ledger-title-component">
    <h6>{props?.children || ''}</h6>
  </div>
)

export const LedgerDetail = (props) => {
  return (
    <MDBRow>
      <MDBCol size='12' md='4'>
        <div className="container-fluid px-0">
          <table width="100%">
            <tbody>
              <tr>
                <td>Agent Wallet:</td>
                <td>{props?.agent}</td>
              </tr>
              <tr>
                <td>Balance:</td>
                <td>
                  {convertToCurrency(
                    !isNaN(props?.balance) && props.balance !== null
                      ? props.balance
                      : 0
                  )}
                </td>
              </tr>
              <tr>
                <td>Last Update:</td>
                <td>
                  {props?.updated_at
                    ? moment(props.updated_at).format('MMMM Do, YYYY')
                    : '--'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </MDBCol>
      <MDBCol size='12' md='4'>
        <UpcomingExpirations />
      </MDBCol>
      <MDBCol size='12' md='4'>
        <AgentLedgerControls ledgerId={props?.ledgerId} />
      </MDBCol>
    </MDBRow>
  )
}
