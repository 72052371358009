import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { MDBBtn, MDBInput } from 'mdbreact'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import StripeSubscriptionService from './../../../../shared/services/StripeSubscription.service'

const SyncSubscription = ({ onSync, subscriptionId }) => {
  let request = {}
  const [isLoading, setIsLoading] = useState(false)

  const meetsRequirement =
    subscriptionId &&
    UserProfileService.isAssumed() &&
    UserProfileService.isA('system-admin', true)

  const onChange = (field, val) => (request[field] = !!val.target.checked)

  const syncSubscription = async () => {
    setIsLoading(true)

    try {
      let res = await StripeSubscriptionService.sync(subscriptionId, request)
      if (res && res?.outcome === 'canceled')
        onSync({
          status: true,
          msg: "The agent's subscription has been canceled.",
        })
      else
        onSync({
          status: true,
          msg: "The agent's subscription has been synced.",
        })
      setIsLoading(false)
    } catch (error) {
      onSync({ status: false, msg: error?.message })
      setIsLoading(false)
    }
  }

  const renderForm = () => {
    if (meetsRequirement)
      return (
        <form
          className="resync-subscription-form mt-3 mb-3 pt-2"
          style={{ border: '1px solid #d3d3d3', backgroundColor: '#FFF' }}
        >
          <h6>&nbsp;Re-Sync Agent's Subscription</h6>
          <hr style={{ margin: '5px 5px 10px 5px' }} />
          <MDBInput
            disabled={isLoading}
            label="If canceled, send agent cancellation email?"
            type="checkbox"
            id="_resync_subscription_notify_agent"
            onChange={(val) => onChange('notify', val)}
          />
          <MDBInput
            disabled={isLoading}
            label="If canceled, downgrade agent account?"
            type="checkbox"
            id="_resync_subscription_change_usertype"
            onChange={(val) => onChange('change_usertype', val)}
          />
          <MDBBtn
            disabled={isLoading}
            className="btn-block"
            onClick={syncSubscription}
            sm="true"
            color="danger"
          >
            Re-Sync Subscription
          </MDBBtn>
        </form>
      )
    return <></>
  }

  return <>{renderForm()}</>
}

export default observer(SyncSubscription)
