import React, { useState, useEffect, useCallback } from 'react'
import { makeAutoObservable, toJS } from 'mobx'
import UserDocumentFactory from './../../../shared/factories/user-document.factory'

class AgentNiprPdbReportListStore {
  constructor() {
    makeAutoObservable(this)
  }

  isFetching = false
  hasFetched = false
  Documents = []
  _isDeleting = []
  _isSaving = []
  _isSaved = []

  fetch = async (userId) => {
    this.isFetching = true

    const Documents = await UserDocumentFactory.search({
      search: {
        user_id: userId,
        relation_model: 'AgentNiprPdbReport',
      },
      pagination: false,
      order_by: { id: 'ASC' },
    })

    this.Documents = Array.isArray(Documents) ? Documents : []

    this.isFetching = false
    this.hasFetched = true
  }

  setIsDeleting = (docId, on) => {
    if (on && !this.isDeleting(docId)) this._isDeleting.push(parseInt(docId))

    if (!on)
      this._isDeleting = this._isDeleting.filter((dId) => dId !== parseInt(docId))
  }

  isDeleting = (docId) => {
    return this._isDeleting.indexOf(parseInt(docId)) > -1
  }

  delete = async (Document) => 
  {
  	this.setIsDeleting(Document.get('id'), true);

  	this.Documents = this.Documents.filter(Doc => parseInt(Doc.id()) !== parseInt(Document.id()));

  	if (Document)
  		await Document.delete();

  	this.setIsDeleting(Document.get('id'), false);
  }

  setIsSaving = (docId, on) => {
    if (on && !this.isSaving(docId)) this._isSaving.push(parseInt(docId))

    if (!on)
      this._isSaving = this._isSaving.filter((dId) => dId !== parseInt(docId))
  }

  isSaving = (docId) => {
    return this._isSaving.indexOf(parseInt(docId)) > -1
  }

  setIsSaved = (docId) => {
    if (!this.isSaved(docId)) {
      this._isSaved.push(parseInt(docId))
      const intT = setTimeout(() => {
        this._isSaved = this._isSaved.filter((dId) => dId !== parseInt(docId))
        window.clearTimeout(intT)
      }, 2500)
    }
  }

  isSaved = (docId) => {
    return this._isSaved.indexOf(parseInt(docId)) > -1
  }

  save = async (Document) => {
    this.setIsSaving(Document.get('id'), true)
    await Document.save()
    this.Documents = this.Documents.map((Doc) =>
      parseInt(Doc.get('id')) === parseInt(Document.get('id')) ? Document : Doc
    )
    this.setIsSaved(Document.get('id'))
    this.setIsSaving(Document.get('id'), false)
  }
}

export default new AgentNiprPdbReportListStore()
