import React from 'react'
import UserProfileService from '../../../../shared/services/UserProfile.service'
import LeaderWidget from './LeaderWidget.component'
import AgentWidget from './AgentWidget.component'

import './GrowthReportWidget.scss'

// We are only showing Personal growth on this report.
const GrowthReportWidget = () =>
  false &&
  UserProfileService.isA([
    'division-leader',
    'regional-leader',
    'district-leader',
    'agency-owner',
    'system-admin',
  ]) ? (
    <LeaderWidget />
  ) : (
    <AgentWidget />
  )

export default GrowthReportWidget
