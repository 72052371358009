import React, { useState, useEffect } from 'react'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
import LoadingSpinner from './../../shared/LoadingSpinner.component'
import ContentHtml from './../../content/ContentHtml/ContentHtml.component'
import {
  MDBFileInput,
  MDBBtn,
  MDBAlert,
  MDBDataTable,
  MDBIcon,
  MDBModalHeader,
  MDBModalBody,
  MDBModal,
  MDBCollapse,
  MDBRow,
  MDBCol,
} from 'mdbreact'
import moment from 'moment'
import { TermFactory, TrainingFactory } from './../../../shared/factories'

import './Modal.component.scss'

class AgentRequirementsStore {
  User = null
  userId = null
  isLoading = false
  requirements = {}

  constructor() {
    makeAutoObservable(this)
  }

  fetch = async (requiredKeys) => {
    if (this.User) {
      this.userId = this.User.id()
      this.isLoading = true

      let requirements = {}
      if ((Array.isArray(requiredKeys) ? requiredKeys : []).length > 0) {
        await Promise.all(
          (Array.isArray(requiredKeys) ? requiredKeys : []).map((rKey) => {
            switch (rKey) {
              case 'leader_trainings':
                return (requirements.leader_trainings =
                  this.User.getRequiredTrainings('leader'))

              case 'leader_terms':
                return (requirements.leader_terms =
                  this.User.getRequiredTerms('leader'))

              default:
                return Promise.resolve()
            }
          })
        )
      }

      if (Object.values(requirements).length)
        await (Array.isArray(requiredKeys) ? requiredKeys : []).map(
          async (rKey) => !!(requirements[rKey] = await requirements[rKey])
        )

      this.requirements = requirements
      this.isLoading = false
    }
  }
}

const Store = new AgentRequirementsStore()

const AgentRequirementsComponent = ({ User, requiredKeys, goTo }) => {
  const getTitleBar = (rKey) => {
    switch (rKey) {
      case 'leader_terms':
        return (
          <>
            <MDBRow>
              <MDBCol>Required Leader Terms</MDBCol>
              <MDBCol>
                <ContentHtml
                  contentSlug={`outstanding-req-${rKey.replace(/\_/g, '-')}`}
                />
              </MDBCol>
            </MDBRow>
          </>
        )
      case 'leader_trainings':
        return (
          <>
            <MDBRow>
              <MDBCol>Required Leader Trainings</MDBCol>
              <MDBCol>
                <ContentHtml
                  contentSlug={`outstanding-req-${rKey.replace(/\_/g, '-')}`}
                />
              </MDBCol>
            </MDBRow>
          </>
        )
      default:
        return ''
    }
  }

  const getRequirement = (rKey, model) => {
    switch (rKey) {
      case 'leader_terms':
        return model && model?.term_name

      case 'leader_trainings':
        return model && model?.training_name

      default:
        return ''
    }
  }

  const renderRequiredList = (rKey) => {
    if (
      Store.requirements[rKey] &&
      typeof Store.requirements[rKey] === 'object'
    ) {
      if (Array.isArray(Store.requirements[rKey]?.leader)) {
        return (
          <ul>
            {Store.requirements[rKey]?.leader.map((requirement, idx) => {
              return (
                <li key={`user-required-${rKey}-${idx}`}>
                  {getRequirement(rKey, requirement)}
                </li>
              )
            })}
          </ul>
        )
      }
    }

    return <></>
  }

  const getTakeMeTo = (rKey, returnLink) => {
    switch (rKey) {
      case 'leader_terms':
        if (returnLink) return '/leader-guidelines'
        return 'Take Me To Leader Guidelines'

      case 'leader_trainings':
        if (returnLink) return '/trainings'
        return 'Take Me To Leader Trainings'

      default:
        return ''
    }
  }

  return (
    <div
      className={`AgentRequirementsComponent ${
        Store.isLoading ? 'is-loading' : ''
      }`}
    >
      <LoadingSpinner size="md" isActive={Store.isLoading} />
      <MDBCollapse isOpen={!Store.isLoading}>
        {requiredKeys.map((rKey, idx) => {
          return (
            <div key={`required-wrapper-${rKey}`} className="required-wrapper">
              <div className="required-label">{getTitleBar(rKey)}</div>
              {renderRequiredList(rKey)}
              <MDBBtn
                color="warning"
                size={'sm'}
                block
                onClick={() => {
                  Store.userId = null
                  Store.requirements = {}
                  goTo(getTakeMeTo(rKey, true))
                }}
                type="button"
                className="mum-btn"
              >
                {getTakeMeTo(rKey)}
              </MDBBtn>
            </div>
          )
          return <div>{rKey}</div>
        })}
      </MDBCollapse>
    </div>
  )
}

const AgentRequirements = observer(AgentRequirementsComponent)

const Modal = ({
  isOpen,

  // Resolve/reject modal promise.
  onResolve,
  onReject,

  // requirements:
  User,
  requiredKeys,

  // Pass string or jsx.
  modalContent,
  // Options (top|bottom), only used with `modalContent` property.
  modalContentPosition,
  // Positional modal content.
  modalContentTop,
  modalContentBottom,
}) => {
  Store.User = User

  const suppressModal = () => {
    const data = {}
    if (Store.requirements) {
      Object.keys(Store.requirements).forEach((key) => {
        let a =
          Store.requirements[key] && typeof Store.requirements[key] === 'object'
            ? Store.requirements[key]
            : {}
        data[key] = Object.keys(a).map((k) => ({
          [k]: (Array.isArray(a[k]) ? a[k] : []).map((i) => i?.id),
        }))
      })
    }
    onReject(data)
  }

  const toggleModal = () => {
    onResolve()
  }

  const canCancel = () => true

  useEffect(() => {
    if (isOpen) Store.fetch(requiredKeys)
  }, [isOpen, requiredKeys])

  return (
    <div id="UserRequirementsModalComponent">
      <MDBModal
        size="lg"
        isOpen={isOpen}
        toggle={suppressModal}
        keyboard={false}
        disableBackdrop
      >
        {canCancel() ? (
          <MDBModalHeader toggle={suppressModal}>
            Outstanding Requirements
          </MDBModalHeader>
        ) : (
          <MDBModalHeader>Outstanding Requirements</MDBModalHeader>
        )}
        <MDBModalBody>
          <AgentRequirements
            User={User}
            requiredKeys={requiredKeys}
            goTo={(link) => (link ? onResolve({ link }) : onReject())}
          />
        </MDBModalBody>
      </MDBModal>
    </div>
  )
}

export default Modal
