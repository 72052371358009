import React, { Component, Fragment } from 'react'
import { MDBContainer, MDBBtn, MDBRow, MDBCol, MDBFileInput } from 'mdbreact'
import DashboardLayout from '../../components/Admin/DashboardLayout.jsx'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api.js'
import appConstants from '../../constants/appConstants.jsx'
import {
  phoneToStorage,
  phoneToView,
} from './../../shared/formatters/telephone.formatter'

class AdminContactInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      a_name: '',
      a_shortname: '',
      a_default_usertype: '',
      a_default_division: '',
      a_default_commission: '',
      a_url: '',
      a_email_domain: '',
      a_address: '',
      a_city: '',
      a_state: '',
      a_zip: '',
      a_email: '',
      a_quote_email: '',
      a_phone: '',
      a_fax: '',
      a_timezone: '',
      a_logo: '',
      userTypeList: [],
      commissionLevelList: [],
      errorMessage: '',
      formError: false,
    }
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params })

    this.fetchUserTypes()
    // this.fetchCommissionLevels();
    // this.fetchAgencyInfo(params.id);

    // this.setState({editorState});
  }

  //Change event for input fields
  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  States = appConstants.States
  StatesList =
    this.States.length > 0 &&
    this.States.map((item, i) => {
      return (
        <option key={i} value={item.id}>
          {item.name}
        </option>
      )
    })

  Timezone = appConstants.Timezone
  TimezonesList =
    this.Timezone.length > 0 &&
    this.Timezone.map((item, i) => {
      return (
        <option key={i} value={item.name}>
          {item.name}
        </option>
      )
    })

  validate = async () => {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i

    if (
      this.state.a_logo &&
      typeof this.state.a_logo !== 'string' &&
      !allowedExtensions.test(this.state.a_logo.name)
    ) {
      this.setState({ formError: true, errorMessage: 'Invalid file type' })
      return
    }
    this.setState({ formError: false, errorMessage: '' })
  }

  fetchAgencyInfo = async () => {
    AdminApi.getAgencyInfo()
      .then((result) => {
        this.setState({ loading: false })
        if (result && result.data && result.data.data) {
          const content = result.data.data
          this.setState({
            a_name: content.a_name,
            a_shortname: content.a_shortname,
            a_default_usertype: content.a_default_usertype,
            a_default_division: content.a_default_division,
            a_default_commission: content.a_default_commission,
            a_url: content.a_url,
            a_email_domain: content.a_email_domain,
            a_address: content.a_address,
            a_city: content.a_city,
            a_state: content.a_state,
            a_zip: content.a_zip,
            a_email: content.a_email,
            a_quote_email: content.a_quote_email,
            a_phone: phoneToView(content.a_phone),
            a_fax: phoneToView(content.a_fax),
            a_timezone: content.a_timezone,
            a_logo: content.a_logo,
          })
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  fetchUserTypes = async () => {
    this.setState({ loading: true })
    AdminApi.getUserTypes()
      .then((result) => {
        if (result && result.data && result.data.data) {
          const content = result.data.data
          this.setState({
            userTypeList: content,
          })
          this.fetchCommissionLevels()
        }
      })
      .catch((error) => {
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  fetchCommissionLevels = async () => {
    const {
      match: { params },
    } = this.props
    AdminApi.getCommissions()
      .then((result) => {
        if (result && result.data && result.data.data) {
          const content = result.data.data
          this.setState({
            commissionLevelList: content,
          })
          this.fetchAgencyInfo(params.id)
        }
      })
      .catch((error) => {
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  updateAgencyInfo = async () => {
    await this.validate()
    const {
      loading,
      errorMessage,
      formError,
      a_name,
      a_shortname,
      a_default_usertype,
      a_default_commission,
      a_address,
      a_city,
      a_state,
      a_zip,
      a_email,
      a_quote_email,
      a_phone,
      a_fax,
      a_timezone,
      a_logo,
    } = this.state
    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }
    const data = {
      a_name: a_name,
      a_shortname: a_shortname,
      a_default_usertype: a_default_usertype,
      // a_default_division: a_default_division,
      a_default_commission: a_default_commission,
      /*a_url: a_url,
            a_email_domain: a_email_domain,*/
      a_address: a_address,
      a_city: a_city,
      a_state: a_state,
      a_zip: a_zip,
      a_email: a_email,
      a_quote_email: a_quote_email,
      a_phone: phoneToStorage(a_phone),
      a_fax: phoneToStorage(a_fax),
      a_timezone: a_timezone,
    }

    if (typeof a_logo !== 'string') {
      data['a_logo'] = a_logo
    }

    const form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    this.setState({ loading: true })
    AdminApi.updateAgencyInfo(form_data)
      .then((result) => {
        this.setState({ loading: false })
        // this.props.history.go('/dashboard');
        toast.success('Contact information updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
        this.fetchAgencyInfo()
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  render() {
    toast.configure()
    const {
      loading,
      a_name,
      a_shortname,
      a_default_usertype,
      a_default_commission,
      a_address,
      a_city,
      a_state,
      a_zip,
      a_email,
      a_quote_email,
      a_phone,
      a_fax,
      a_timezone,
      a_logo,
      userTypeList,
      commissionLevelList,
    } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              <h2>Agency Info</h2>
              <hr />
              {loading ? (
                <h4 className={'p4'}>Loading...</h4>
              ) : (
                <MDBRow>
                  <MDBCol size="6" className="content-wrapper">
                    <form>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="grey-text" htmlFor="a_name">
                            Agency Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="a_name"
                            id="a_name"
                            value={a_name}
                            onChange={this.onChange}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label className="grey-text" htmlFor="a_shortname">
                            Agency Short Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="a_shortname"
                            id="a_shortname"
                            value={a_shortname}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label
                            className="grey-text"
                            htmlFor="a_default_usertype"
                          >
                            Default Usertype
                          </label>
                          <select
                            className="form-control"
                            name="a_default_usertype"
                            id="a_default_usertype"
                            value={a_default_usertype}
                            onChange={this.onChange}
                          >
                            <option>Usertypes Here</option>
                            {userTypeList.length > 0 &&
                              userTypeList.map((item, i) => {
                                return (
                                  <option key={i} value={item.docId}>
                                    {item.displayname}
                                  </option>
                                )
                              })}
                          </select>
                        </div>
                        <div className="form-group col-md-6">
                          <label
                            className="grey-text"
                            htmlFor="a_default_commission"
                          >
                            Default Commission Level
                          </label>
                          <select
                            className="form-control"
                            name="a_default_commission"
                            id="a_default_commission"
                            value={a_default_commission}
                            onChange={this.onChange}
                          >
                            <option>Commission Levels Here</option>
                            {commissionLevelList.length > 0 &&
                              commissionLevelList.map((item, i) => {
                                return (
                                  <option key={i} value={item.docId}>
                                    {item.com_name}
                                  </option>
                                )
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="grey-text" htmlFor="a_address">
                            Address
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="a_address"
                            id="a_address"
                            value={a_address}
                            onChange={this.onChange}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label className="grey-text" htmlFor="a_city">
                            City
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="a_city"
                            id="a_city"
                            value={a_city}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="grey-text" htmlFor="a_state">
                            State
                          </label>
                          <select
                            className="form-control"
                            name="a_state"
                            id="a_state"
                            value={a_state}
                            onChange={this.onChange}
                          >
                            {this.StatesList}
                          </select>
                        </div>
                        <div className="form-group col-md-6">
                          <label className="grey-text" htmlFor="a_zip">
                            Zip
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="a_zip"
                            id="a_zip"
                            value={a_zip}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="grey-text" htmlFor="a_email">
                            Contact E-mail
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="a_email"
                            id="a_email"
                            value={a_email}
                            onChange={this.onChange}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label className="grey-text" htmlFor="a_quote_email">
                            Quote E-mail
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="a_quote_email"
                            id="a_quote_email"
                            value={a_quote_email}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="grey-text" htmlFor="a_phone">
                            Phone
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="a_phone"
                            id="a_phone"
                            value={a_phone}
                            onChange={this.onChange}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label className="grey-text" htmlFor="a_fax">
                            Fax
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="a_fax"
                            id="a_fax"
                            value={a_fax}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="grey-text" htmlFor="a_timezone">
                            Timezone
                          </label>
                          <select
                            className="form-control"
                            name="a_timezone"
                            id="a_timezone"
                            value={a_timezone}
                            onChange={this.onChange}
                          >
                            {this.TimezonesList}
                          </select>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label className="grey-text" htmlFor="a_logo">
                            Agency Logo
                          </label>
                          <MDBFileInput
                            name="a_logo"
                            value={a_logo}
                            getValue={(value) => {
                              this.setState({ a_logo: value[0] })
                            }}
                          />
                          {!!(a_logo && typeof a_logo == 'string') && (
                            <div>
                              <a
                                href={a_logo}
                                target="_blank"
                                className="d-inline-block"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={a_logo}
                                  style={{ maxWidth: '50px' }}
                                  alt="logo"
                                />
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-row text-center mt-4">
                        <MDBBtn
                          color="unique"
                          type="button"
                          onClick={this.updateAgencyInfo}
                          className="btn-block"
                        >
                          Save
                        </MDBBtn>
                      </div>
                    </form>
                  </MDBCol>
                </MDBRow>
              )}
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AdminContactInfo
